import dayjs from "@properate/dayjs";
import { DatapointAggregates, DoubleDatapoint } from "@cognite/sdk";
import { SolarCellProductionPeriod } from "./types";

export function getStartTimestampFromPeriod(period: SolarCellProductionPeriod) {
  if (period === "lastWeek") {
    return dayjs().subtract(7, "day").startOf("hour").valueOf();
  }
  if (period === "last30Days") {
    return dayjs().subtract(30, "day").startOf("day").valueOf();
  }
  return dayjs().subtract(1, "year").startOf("week").valueOf();
}

export function getGranularityFromPeriod(period: SolarCellProductionPeriod) {
  if (period === "lastWeek") {
    return "1h";
  }
  if (period === "last30Days") {
    return "24h";
  }
  return "168h";
}

// Combines datapoints of timeseries by summing the sum of all datapoints w/ the same timestamp
// The returned data points are NOT sorted by timestamp
export function combineTimeseriesDatapoints(
  timeseriesListWithData: DatapointAggregates[],
): DoubleDatapoint[] {
  const allDatapoints = timeseriesListWithData.flatMap(
    ({ datapoints }) => datapoints,
  );
  return allDatapoints.reduce<DoubleDatapoint[]>(
    (combinedDatapointsAcc, currentDatapoint) => {
      const datapointWithSameTimestampIndex = combinedDatapointsAcc.findIndex(
        (datapoint) =>
          datapoint.timestamp.valueOf() ===
          currentDatapoint.timestamp.valueOf(),
      );
      if (datapointWithSameTimestampIndex > -1) {
        const datapointWithSameTimestamp =
          combinedDatapointsAcc[datapointWithSameTimestampIndex];
        return [
          ...combinedDatapointsAcc.slice(0, datapointWithSameTimestampIndex),
          {
            timestamp: currentDatapoint.timestamp,
            value: datapointWithSameTimestamp.value + currentDatapoint.sum!,
          },
          ...combinedDatapointsAcc.slice(datapointWithSameTimestampIndex + 1),
        ];
      }
      return combinedDatapointsAcc.concat({
        timestamp: currentDatapoint.timestamp,
        value: currentDatapoint.sum!,
      });
    },
    [],
  );
}
