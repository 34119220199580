import dayjs from "@properate/dayjs";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { useTheme } from "styled-components";
import { getDateFormatForGranularity } from "../utils";
import type { ScaleTime } from "d3-scale";
import type { ScaleLinearNumeric, SpotPriceGranularity } from "@/utils/types";

interface Props {
  scaleX: ScaleTime<number, number, never>;
  scaleY: ScaleLinearNumeric;
  graphHeight: number;
  granularity: SpotPriceGranularity;
}

export function SpotPriceGraphAxes({
  scaleX,
  scaleY,
  graphHeight,
  granularity,
}: Props) {
  const theme = useTheme();
  return (
    <>
      <AxisLeft
        scale={scaleY}
        stroke={theme.neutral4}
        tickStroke={theme.neutral4}
        tickLabelProps={{
          fill: theme.neutral4,
        }}
        hideAxisLine
        numTicks={5}
      />
      <AxisBottom
        scale={scaleX}
        top={graphHeight}
        stroke={theme.neutral4}
        tickStroke={theme.neutral4}
        tickLabelProps={{
          fill: theme.neutral4,
        }}
        tickFormat={(value) =>
          dayjs(value.valueOf()).format(
            getDateFormatForGranularity(granularity),
          )
        }
        numTicks={5}
      />
    </>
  );
}
