import { browserFirestore } from "@properate/firebase";
import useSWR from "swr";
import { useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, App, Popconfirm, Space, Spin, Checkbox } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { produce } from "immer";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { DEFAULT_MESSAGE_DURATION } from "@/utils/helpers";
import { CompactContent } from "@/components/CompactContent";
import {
  createUser,
  deleteUser,
  listOwners,
  listUsers,
  updateUser,
  NewUser,
  User,
  Role,
} from "@/eepApi";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { SearchInput } from "@/components/SearchIndex";
import { UserForm } from "@/pages/admin/UserForm";
import { parseError } from "@/pages/common/utils";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useHandleApiError } from "@/utils/api";
import { UserRoleSwitcher } from "./components/UserRoleSwitcher";

const Admin = () => {
  const t = useTranslations();
  useBuildingPageTitle(t("admin.title"));

  const user = useUser();
  const [userFormData, setUserFormData] = useState<NewUser | null>(null);
  const [search, setSearch] = useState<string>("");
  const { message } = App.useApp();
  const handleAPIError = useHandleApiError();

  const {
    data: users,
    mutate,
    error,
  } = useSWR((user.email && "listUsers") || null, () => listUsers());
  const { data: owners } = useSWR((user.email && "listOwners") || null, () =>
    listOwners(),
  );

  if (error) {
    handleAPIError(error);
  }

  const searchTerms = search.trim().split(" ");

  const sortedUserList = users?.filter(
    (item) =>
      !searchTerms ||
      searchTerms.every(
        (term: string) =>
          (item.email || "").toLowerCase().includes(term.toLowerCase()) ||
          (item.owner || "").toLowerCase().includes(term.toLowerCase()),
      ),
  );

  const columns: ColumnsType<any> = [
    {
      title: t("admin.table.email"),
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      defaultSortOrder: "ascend",
    },
    {
      sorter: (a, b) => (a.owner || "").localeCompare(b.owner || ""),
      title: t("admin.table.owner"),
      dataIndex: "owner",
    },
    {
      title: t("admin.table.buildings"),
      dataIndex: "asset_external_ids",
      render: (asset_external_ids) => asset_external_ids?.join(", "),
    },
    {
      title: t("admin.table.role"),
      dataIndex: "role",
      render: (role, record) => (
        <UserRoleSwitcher
          onChange={async (newRole: Role) => {
            const next = produce(users, (draft) => {
              if (draft) {
                const userIndex = draft.findIndex(
                  (u) => u.email === record.email,
                );
                if (userIndex >= 0) {
                  draft[userIndex].role = newRole;
                }
              }
            });
            await mutate(next, false);
            await handleUpdateUser({
              email: record.email,
              role: newRole,
              owner: record.owner,
              asset_external_ids: record.asset_external_ids,
            });
          }}
          role={role}
        />
      ),
    },
    {
      title: t("admin.table.access-to-tech-app"),
      dataIndex: "isTechApp",
      align: "center",
      render: (isTechApp, record) =>
        record.role === "no_access" ? null : (
          <Checkbox
            checked={isTechApp}
            onChange={async (e) => {
              const next = produce(users, (draft) => {
                if (draft) {
                  const userIndex = draft.findIndex(
                    (u) => u.email === record.email,
                  );
                  if (userIndex >= 0) {
                    draft[userIndex].isTechApp = e.target.checked;
                  }
                }
              });
              await mutate(next, false);
              await handleUpdateUser({
                email: record.email,
                role: record.role,
                owner: record.owner,
                asset_external_ids: record.asset_external_ids,
                isTechApp: e.target.checked,
              });
            }}
          />
        ),
    },
    {
      title: "",
      dataIndex: "email",
      render: (email) => (
        <Space>
          <Popconfirm
            placement="topRight"
            title={t("admin.table.are-you-sure-to-delete", { email })}
            onConfirm={() => handleDeleteUser(email)}
          >
            <Button>{t("admin.table.delete")}</Button>
          </Popconfirm>
          <Button
            onClick={() => {
              const user = users?.find((user) => user.email === email);
              if (user) {
                setUserFormData(user);
              }
            }}
          >
            {t("admin.table.edit")}
          </Button>
        </Space>
      ),
    },
  ];

  const handleDeleteUser = async (email: string) => {
    const next = produce(users, (draft) => {
      if (draft) {
        return draft.filter((user) => user.email !== email);
      }
    });
    await mutate(next, false);
    try {
      await deleteUser({ email });

      message.open({
        type: "success",
        content: t("admin.user-deleted", { email }),
        duration: 7,
      });
    } catch (error) {
      console.error(
        `${t("admin.failed-to-delete-user", { email })} ${parseError(error)}`,
      );
      message.open({
        type: "error",
        content: `${t("admin.failed-to-delete-user", { email })} ${parseError(
          error,
        )}`,
        duration: DEFAULT_MESSAGE_DURATION,
      });
      await mutate();
    }
  };

  const handleCreateUser = async (user: User) => {
    const next = produce(users, (draft) => {
      if (draft) {
        draft.push({
          email: user.email,
          owner: user.owner,
          asset_external_ids: user.asset_external_ids,
          role: user.role,
          isTechApp: user.role === "no_access" ? false : user.isTechApp,
        });
      }
    });

    await mutate(next, false);

    try {
      const createUserResponse = await createUser(user);

      const userRef = doc(collection(browserFirestore, "user"), user.email);
      const docData = await getDoc(userRef);
      const data = createUserResponse && createUserResponse?.data[user.email];
      if (!docData.exists()) {
        const owner = data?.owner ? data.owner : "all";
        await setDoc(userRef, { customer: owner }, { merge: true });
      }
      message.open({
        type: "success",
        content: t("admin.user-created", {
          email: user.email,
          owner: user.owner,
        }),
        duration: 7,
      });
    } catch (error) {
      console.error(
        t("admin.failed-to-create-user", {
          email: user.email,
          owner: user.owner,
          error: parseError(error),
        }),
      );
      message.open({
        type: "error",
        content: t("admin.failed-to-create-user", {
          email: user.email,
          owner: user.owner,
          error: parseError(error),
        }),
        duration: DEFAULT_MESSAGE_DURATION,
      });
      await mutate();
    }

    setUserFormData(null);
  };

  const handleUpdateUser = async (user: User) => {
    const next = produce(users, (draft) => {
      if (draft) {
        const userIndex = draft.findIndex((u) => u.email === user.email);
        if (userIndex >= 0) {
          draft[userIndex] = {
            email: user.email,
            owner: user.owner,
            asset_external_ids: user.asset_external_ids,
            role: user.role,
            isTechApp: user.role === "no_access" ? false : user.isTechApp,
          };
        }
      }
    });
    await mutate(next, false);

    try {
      await updateUser(user);

      message.open({
        type: "success",
        content: t("admin.user-updated", {
          email: user.email,
          owner: user.owner,
        }),
        duration: 7,
      });
    } catch (error) {
      console.error(
        t("admin.failed-to-update-user", {
          email: user.email,
          owner: user.owner,
          error: parseError(error),
        }),
      );
      message.open({
        type: "error",
        content: t("admin.failed-to-update-user", {
          email: user.email,
          owner: user.owner,
          error: parseError(error),
        }),
        duration: DEFAULT_MESSAGE_DURATION,
      });
      await mutate();
    }
    setUserFormData(null);
  };

  return (
    <>
      <PageHeader
        title={t("admin.title")}
        className="site-page-header"
        extra={
          <>
            <SearchInput
              key={"search"}
              data-testid="search-input"
              allowClear
              style={{ width: 600 }}
              onChange={(event) => setSearch(event.target.value)}
            />
            <Button
              onClick={() => {
                const currentUser = users?.find(
                  ({ email }) => email === user.email,
                );
                setUserFormData({
                  owner: currentUser?.owner || "ALL",
                  role: "user",
                });
              }}
            >
              {t("admin.add")}
            </Button>
            {userFormData && owners && (
              <UserForm
                email={userFormData.email}
                owner={userFormData.owner}
                role={userFormData.role}
                idp={userFormData.idp}
                isTechApp={userFormData.isTechApp}
                asset_external_ids={userFormData.asset_external_ids}
                owners={owners}
                onSave={
                  userFormData.email ? handleUpdateUser : handleCreateUser
                }
                onHide={() => setUserFormData(null)}
              />
            )}
          </>
        }
      />
      <CompactContent>
        {users && (
          <>
            <TableWithoutDefaultSort
              pagination={false}
              columns={columns}
              dataSource={sortedUserList}
              rowKey="email"
            />
          </>
        )}
        {!users && <Spin />}
      </CompactContent>
    </>
  );
};

export default Admin;
