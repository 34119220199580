import { collection, deleteField, doc, setDoc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import {
  useGetFirestoreCollection,
  useGetFirestoreDocument,
} from "@properate/ui";
import { useMemo } from "react";
import { AccessModules, allAccessModules } from "@/features/organizationAdmin";

const organizations = collection(browserFirestore, `organization`);

export type OrganizationFirebase = {
  modules: AccessModules[];
  buildings: {
    [key: number]: {
      modules: AccessModules[];
    };
  };
} & { snapshotId?: string };

export type OrganizationsFirebase = Record<string, OrganizationFirebase>;

export function useOrganizations() {
  const { isLoading, error, data } =
    useGetFirestoreCollection<OrganizationFirebase>(organizations);
  const dataObj = data.reduce(
    (acc, org) => {
      acc[org.snapshotId] = org;
      return acc;
    },
    {} as Record<string, OrganizationFirebase>,
  );
  return { isLoading, error, data: dataObj };
}
export function useOrganizationModulesByName(organizationName?: string) {
  return (
    useGetFirestoreDocument<OrganizationFirebase>(
      useMemo(() => {
        if (!organizationName) {
          console.warn("Organization name is empty", organizationName);
        }
        return doc(organizations, organizationName || "none");
      }, [organizationName]),
    ) || {
      data: undefined,
      isLoading: false,
      error: null,
    }
  );
}

export async function updateOrganizationAccessModules(
  organizationId: string,
  modules: AccessModules[],
  snapshotId?: string,
) {
  const organizationRef = doc(
    collection(browserFirestore, "organization"),
    organizationId,
  );

  if (snapshotId) {
    await setDoc(organizationRef, { modules }, { merge: true });
  } else {
    const newOrganization: OrganizationFirebase = {
      modules,
      buildings: {},
    };
    await setDoc(organizationRef, newOrganization);
  }
}

export async function updateBuildingAccessModules(
  organizationId: string,
  buildingId: number,
  modules: AccessModules[],
  snapshotId?: string,
  shouldBeRemoved?: boolean,
) {
  const organizationRef = doc(
    collection(browserFirestore, "organization"),
    organizationId,
  );

  if (snapshotId) {
    await setDoc(
      organizationRef,
      {
        buildings: {
          [buildingId]: shouldBeRemoved ? deleteField() : { modules },
        },
      },
      { merge: true },
    );
  } else {
    const newOrganization = {
      modules: allAccessModules,
      buildings: {
        [buildingId]: shouldBeRemoved ? deleteField() : { modules },
      },
    };
    await setDoc(organizationRef, newOrganization);
  }
}
