import {
  AlarmRuleTypeName,
  PartialAlarmRule,
  type ThirdPartyAlarmRule,
  type ThirdPartyAlarmVisualTimeseries,
} from "@properate/common";
import { DescriptionListEntry } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { TimeseriesAccordionBox } from "@/pages/alarms/details/components/TimeseriesDescriptionListAccordionBox";
import {
  AlarmDeviationTable,
  DeviationTableColumn,
} from "@/pages/alarms/details/components/AlarmDeviationTable";
import { getSubmitValueEntry } from "../../FormContext/utils";
import {
  booleanValidator,
  numberValidator,
  stringValidator,
} from "../../FormContext/validators";
import { requiredSelect, tKey } from "../common/utils";
import { AlarmRuleType } from "..";
import { ThirdPartyAlarmInfo } from "./components/ThirdPartyAlarmInfo";
import { ThirdPartyAlarmFields } from "./types";

export const ThirdPartyAlarmDefinition: AlarmRuleType = {
  name: AlarmRuleTypeName.ThirdPartyAlarm,
  labelTranslationKey: tKey("third-party-alarm.type-label"),
  lockedSchedule: false,
  getFormFields: (ar) => {
    const alarmRule = ar as ThirdPartyAlarmRule;
    return {
      [ThirdPartyAlarmFields.BaseTimeseries]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.base_timeseries_id ?? undefined,
        getValidator: (t) =>
          numberValidator(
            t(requiredSelect, {
              fieldName: t(
                "alarm-details.alarm-types.third-party-alarm.selected-base-timeseries",
              ).toLowerCase(),
            }),
          ),
      },
      [ThirdPartyAlarmFields.VisualTimeseries]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.visual_timeseries ?? [],
      },
      [ThirdPartyAlarmFields.ZeroIsError]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.zero_is_error || false,
        getValidator: (t) =>
          booleanValidator(
            t(requiredSelect, {
              fieldName: t(
                "alarm-details.alarm-types.third-party-alarm.base-error-required",
              ).toLowerCase(),
            }),
          ),
      },
      [ThirdPartyAlarmFields.AddVisualTimeseriesType]: {
        defaultValue: undefined,
        getValidator: (t) =>
          stringValidator(
            t(requiredSelect, {
              fieldName: t(
                "alarm-details.alarm-types.third-party-alarm.visual-timeseries.type-label",
              ).toLowerCase(),
            }),
          ),
        skipWhenValidatingAll: true,
      },
      [ThirdPartyAlarmFields.AddVisualTimeseriesId]: {
        defaultValue: undefined,
        getValidator: (t) =>
          numberValidator(
            t(requiredSelect, {
              fieldName: t(
                "alarm-details.alarm-types.third-party-alarm.visual-timeseries.timeseries-label",
              ).toLowerCase(),
            }),
          ),
        skipWhenValidatingAll: true,
      },
    };
  },
  getAlarmRuleFields: ({ entries }): PartialAlarmRule => {
    return {
      condition: {
        type_specific: {
          base_timeseries_id: getSubmitValueEntry<number>(
            entries,
            ThirdPartyAlarmFields.BaseTimeseries,
          ),
          visual_timeseries: getSubmitValueEntry<
            ThirdPartyAlarmVisualTimeseries[]
          >(entries, ThirdPartyAlarmFields.VisualTimeseries),
          zero_is_error: getSubmitValueEntry<boolean>(
            entries,
            ThirdPartyAlarmFields.ZeroIsError,
          ),
        },
      },
    };
  },
  getNotificationFieldOverrides: (alarmRule) => {
    return {
      ...alarmRule,
      condition: {
        ...(alarmRule.condition ?? {}),
        type_specific: {
          ...(alarmRule.condition?.type_specific ?? {}),
          schedule: {
            ...(alarmRule.condition?.type_specific?.schedule ?? {}),
            always_active: true,
            active_periods: undefined,
            inactive_periods: undefined,
            active_on_holidays: true,
          },
        },
      },
    };
  },
  formComponent: <ThirdPartyAlarmInfo />,
  summaryContents: {
    generalAlarmMetaFields: <ThirdPartyGeneralAlarmMetaFields />,
    typeSpecificSummary: <ThirdPartyTypeSpecificSummary />,
  },
  getDeviationsTable: (props) => (
    <AlarmDeviationTable
      columns={[
        DeviationTableColumn.Timeframe,
        DeviationTableColumn.Value,
        DeviationTableColumn.Threshold,
      ]}
      {...props}
    />
  ),
};

export function ThirdPartyGeneralAlarmMetaFields() {
  const t = useTranslations();

  return (
    <DescriptionListEntry
      termColon
      term={t("alarm-details.deviations-page.summary.type")}
      description={t(
        "alarm-details.alarm-types.third-party-alarm.summary-info",
      )}
    />
  );
}

export function ThirdPartyTypeSpecificSummary() {
  const [baseTimeseriesId] = useFormValue<number>(
    ThirdPartyAlarmFields.BaseTimeseries,
  );
  const [visualTimeseries] = useFormValue<ThirdPartyAlarmVisualTimeseries[]>(
    ThirdPartyAlarmFields.VisualTimeseries,
  );
  const t = useTranslations("alarm-details.alarm-types.third-party-alarm");
  return (
    <div className="w-full flex flex-col flex-nowrap gap-4">
      <TimeseriesAccordionBox
        timeseriesId={baseTimeseriesId}
        title={t("selected-base-timeseries")}
      />
      {visualTimeseries.map(({ label, value }) => {
        return (
          <TimeseriesAccordionBox
            timeseriesId={value}
            title={t("visual-timeseries.summary-accordion-title", {
              type: t(`visual-timeseries.type.${label}`),
            })}
            key={`${value}_${label}`}
          />
        );
      })}
    </div>
  );
}
