import { useEffect, useMemo, useState } from "react";
import { App, Grid } from "antd";
import { useLoaderData } from "react-router-dom";
import { BuildingSpec, WithSnapshotId } from "@properate/common";
import { arrayMoveImmutable } from "array-move";
import { deleteField } from "firebase/firestore";
import { DashboardWidget } from "@/utils/types";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { mutateUserSettings } from "@/services/userSettings";
import { Gauge } from "@/features/gauges";
import { Analysis } from "@/features/analysis";
import { HeatMap } from "@/features/heatmap";
import { dashboardStandardWidgets } from "@/utils/dashboardWidgets";
import { isErrorWithMessage } from "@/utils/api";
import { useGetTotalEnergyAssets } from "../hooks/useGetTotalEnergyAssets";
import { useGetOrganizationsToTotalEnergyTimeseriesIds } from "../hooks/useGetOrganizationsToTotalEnergyTimeseries";
import { getOrganizations } from "../utils";
import { SortableList } from "./sortable/SortableList";

interface Props {
  selectedWidgets: DashboardWidget[];
  buildingSpec: BuildingSpec;
}

export function DashboardWidgets({ selectedWidgets, buildingSpec }: Props) {
  const { notification } = App.useApp();
  const { gauges, analyses, heatMaps } = useLoaderData() as {
    gauges: WithSnapshotId<Gauge>[];
    analyses: WithSnapshotId<Analysis>[];
    heatMaps: WithSnapshotId<HeatMap>[];
  };
  const [selectedWidgetsSorted, setSelectedWidgetsSorted] = useState<
    DashboardWidget[]
  >([]);
  useEffect(() => {
    setSelectedWidgetsSorted(selectedWidgets);
  }, [selectedWidgets]);
  const building = useCurrentBuilding();
  const { sm = false } = Grid.useBreakpoint();
  const { totalEnergyAssets } = useGetTotalEnergyAssets();
  // Organizations with owner as the first organization
  const organizations = getOrganizations(totalEnergyAssets);
  const {
    organizationsToTotalEnergyTimeseriesIds,
    organizationsToTotalEnergyTempCorrectedETModTimeseriesIds,
    organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds,
  } = useGetOrganizationsToTotalEnergyTimeseriesIds(totalEnergyAssets);
  const availableStandardWidgets = dashboardStandardWidgets.filter(
    (dashboardStandardWidget) =>
      selectedWidgetsSorted.every(({ id }) => id !== dashboardStandardWidget),
  );
  const availableGauges = gauges.filter((gauge) =>
    selectedWidgetsSorted.every(({ id }) => id !== gauge.snapshotId),
  );
  const availableAnalyses = analyses.filter((analysis) =>
    selectedWidgetsSorted.every(({ id }) => id !== analysis.snapshotId),
  );
  const availableHeatMaps = heatMaps.filter((heatMap) =>
    selectedWidgetsSorted.every(({ id }) => id !== heatMap.snapshotId),
  );
  const selectedGauges = useMemo(
    () =>
      gauges.filter((gauge) =>
        selectedWidgetsSorted.some(
          ({ type, id }) => type === "gauge" && id === gauge.snapshotId,
        ),
      ),
    [gauges, selectedWidgetsSorted],
  );
  const selectedAnalyses = useMemo(
    () =>
      analyses.filter((analysis) =>
        selectedWidgetsSorted.some(
          ({ type, id }) => type === "analysis" && id === analysis.snapshotId,
        ),
      ),
    [analyses, selectedWidgetsSorted],
  );
  const selectedHeatMaps = useMemo(
    () =>
      heatMaps.filter((heatMap) =>
        selectedWidgetsSorted.some(
          ({ type, id }) => type === "heatMap" && id === heatMap.snapshotId,
        ),
      ),
    [heatMaps, selectedWidgetsSorted],
  );
  const widgetHeight = sm ? 380 : null;

  async function handleSortEnd({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) {
    document.body.style.cursor = "auto";
    if (oldIndex === newIndex) {
      return;
    }
    const widgetsReSorted = arrayMoveImmutable(
      selectedWidgetsSorted,
      oldIndex,
      newIndex,
    );
    setSelectedWidgetsSorted(widgetsReSorted);
    await mutateUserSettings({
      buildings: {
        [building.id]: {
          dashboard: {
            widgets: widgetsReSorted,
          },
        },
      },
    });
  }

  function handleAddWidgets(widgets: DashboardWidget[]) {
    const widgetsWithNewWidget = selectedWidgetsSorted.concat(widgets);
    setSelectedWidgetsSorted(widgetsWithNewWidget);
    mutateUserSettings({
      buildings: {
        [building.id]: {
          dashboard: {
            widgets: widgetsWithNewWidget,
          },
        },
      },
    });
  }

  async function handleRemoveWidget(widgetToRemove: DashboardWidget) {
    const widgetsWithoutWidgetToRemove = selectedWidgetsSorted.filter(
      (widget) => widget !== widgetToRemove,
    );
    setSelectedWidgetsSorted(widgetsWithoutWidgetToRemove);
    try {
      await mutateUserSettings({
        buildings: {
          [building.id]: {
            dashboard: {
              widgets: widgetsWithoutWidgetToRemove,
            },
          },
        },
      });
      await removeWidgetSettings(widgetToRemove);
    } catch (error) {
      if (isErrorWithMessage(error)) {
        return notification.error({
          message: error.message,
        });
      }
      console.error(error);
    }
  }

  async function removeWidgetSettings(widgetToRemove: DashboardWidget) {
    if (widgetToRemove.id === "solarCellProduction") {
      return mutateUserSettings({
        // @ts-expect-error deleteField is not valid as a type but is the best way to remove a prop
        buildings: {
          [building.id]: {
            settingsSolarCellProduction: deleteField(),
          },
        },
      });
    }
    if (widgetToRemove.id === "spotPrice") {
      return mutateUserSettings({
        // @ts-expect-error deleteField is not valid as a type but is the best way to remove a prop
        buildings: {
          [building.id]: {
            settingsSpotPrice: deleteField(),
          },
        },
      });
    }
    if (widgetToRemove.type === "analysis") {
      return mutateUserSettings({
        // @ts-expect-error deleteField is not valid as a type but is the best way to remove a prop
        buildings: {
          [building.id]: {
            settingsAnalyses: {
              presetTimeSpanPerAnalysis: {
                [widgetToRemove.id]: deleteField(),
              },
            },
          },
        },
      });
    }
  }

  return typeof widgetHeight === "number" ? (
    <SortableList
      useDragHandle
      axis="xy"
      widgetHeight={widgetHeight}
      organizations={organizations}
      organizationsToTotalEnergyTimeseriesIds={
        organizationsToTotalEnergyTimeseriesIds
      }
      organizationsToTotalEnergyTempCorrectedETModTimeseriesIds={
        organizationsToTotalEnergyTempCorrectedETModTimeseriesIds
      }
      organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds={
        organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds
      }
      selectedWidgets={selectedWidgetsSorted}
      selectedGauges={selectedGauges}
      selectedAnalyses={selectedAnalyses}
      selectedHeatMaps={selectedHeatMaps}
      buildingSpec={buildingSpec}
      onSortStart={() => {
        document.body.style.cursor = "grabbing";
      }}
      availableStandardWidgets={availableStandardWidgets}
      availableGauges={availableGauges}
      availableAnalyses={availableAnalyses}
      availableHeatMaps={availableHeatMaps}
      onSortEnd={handleSortEnd}
      helperClass="moving"
      onRemoveWidget={handleRemoveWidget}
      onAddWidgets={handleAddWidgets}
    />
  ) : null;
}
