import useSWR from "swr";
import { timeseriesIndex } from "@/eepApi";
import { useHandleApiError } from "@/utils/api";
import {
  CategoryOption,
  TimeseriesFilters,
  TimeseriesMainFilters,
} from "../types";
import { buildFilterQuery } from "../utils";

export function useAvailableSystems(
  filter: TimeseriesFilters & Pick<TimeseriesMainFilters, "buildingId">,
  categoryOptions: CategoryOption[],
) {
  const handleAPIError = useHandleApiError();

  const { system: systems, ...filterWithoutSystem } = filter;
  const {
    data: timeseriesListForSystems = [],
    error: errorTimeseriesListForSystems,
    isLoading: isLoadingTimeseriesListForSystems,
  } = useSWR(
    filterWithoutSystem,
    async (filter) => {
      const { facetHits: SystemFacets } =
        await timeseriesIndex.searchForFacetValues({
          facetName: "system",
          filter: buildFilterQuery(filter, categoryOptions),
          limit: Number.MAX_SAFE_INTEGER,
        });
      return SystemFacets.map(({ value }) => value);
    },
    {
      keepPreviousData: true,
    },
  );

  if (errorTimeseriesListForSystems) {
    handleAPIError(errorTimeseriesListForSystems);
  }
  return {
    systems: timeseriesListForSystems,
    isLoadingSystems: isLoadingTimeseriesListForSystems,
  };
}
