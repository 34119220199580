import React, { ReactNode } from "react";
import { ReactComponent as Error } from "./icons/error.svg";
import { ReactComponent as Warning } from "./icons/warning.svg";
import { ReactComponent as Info } from "./icons/info.svg";

export const SEVERITY_TITLE: Record<string, ReactNode> = {
  ERROR: "Feil",
  WARNING: "Advarsel",
  INFO: "Info",
};
export const SEVERITY: Record<string, ReactNode> = {
  ERROR: <Error />,
  WARNING: <Warning />,
  INFO: <Info />,
};
