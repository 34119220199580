import { Form, Modal, Select, Space } from "antd";
import { useTranslations } from "@properate/translations";
import { NoteLevel } from "@/features/notes";
import { DEFAULT_NOTES_LEVELS } from "@/utils/helpers";
import { NoteLevelText } from "../../../../notes/NoteLevelText";

type Props = {
  open: boolean;
  onCancel: (value: boolean) => unknown;
  onOK: (value: { noteLevel: string }) => unknown;
  showLevel?: string;
};
export const NotesSettingsModal = ({
  open,
  onCancel,
  onOK,
  showLevel,
}: Props) => {
  const t = useTranslations();
  const [form] = Form.useForm();
  function handleOK() {
    form.submit();
  }

  async function handleSubmit({ noteLevel }: { noteLevel: string }) {
    onOK({
      noteLevel,
    });
  }
  function handleCancel() {
    onCancel(false);
  }
  return (
    <Modal
      open={open}
      title={t("dashboard.widgets.notes.settings")}
      onCancel={handleCancel}
      onOk={handleOK}
      styles={{ body: { height: 140, width: 500 } }}
    >
      <Space direction="vertical" style={{ width: "50%" }}>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Form.Item
            name="noteLevel"
            label={t("dashboard.widgets.notes.show-priority-or-higher-label")}
          >
            <div className="flex">
              <Select
                id="note-level"
                placeholder={t(
                  "dashboard.widgets.notes.select-priority-placeholder",
                )}
                defaultValue={showLevel || "error"}
                options={DEFAULT_NOTES_LEVELS.map((noteLevel) => ({
                  label: <NoteLevelText level={noteLevel as NoteLevel} />,
                  value: noteLevel,
                }))}
                style={{ width: "100%" }}
                onChange={(value: string) =>
                  form.setFieldsValue({ noteLevel: value })
                }
              />
            </div>
          </Form.Item>
        </Form>
      </Space>
      <div className="">
        <p>{t("dashboard.widgets.notes.info-text")}</p>
      </div>
    </Modal>
  );
};
