import { useUser } from "@properate/auth";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TaskTypeProvider } from "@properate/task-manager";
import { Button } from "antd";
import { useTranslations } from "@properate/translations";
import { useRootAssets } from "@/hooks/useRootAssets";
import FullLayout from "@/layout/FullLayout";
import {
  TaskManagerHeading,
  TaskManagerTable,
  TaskManagerTableFiltersProvider,
} from "@/features/task-manager";
import usePageTitle from "@/hooks/usePageTitle";
import { useWindowSize } from "@/hooks/useWindowSize";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

export default function TaskTemplates() {
  const t = useTranslations();
  usePageTitle(t("task.template.organization-task-templates"));
  const user = useUser();
  const navigate = useNavigate();
  const { height: windowHeight } = useWindowSize();
  const rootAssets = useRootAssets();
  const buildingIds = useMemo(
    () => rootAssets.map((asset) => asset.id),
    [rootAssets],
  );

  return (
    <TaskManagerTableFiltersProvider>
      <FullLayout
        pageName={t("task.ui.tabTemplates")}
        hideSearchInput
        headerRight={
          <TaskManagerHeading>
            <Button
              type="primary"
              onClick={() => navigate("/org/task-templates/create")}
              disabled={user.isViewer}
            >
              {t("task.template.new-template")}
            </Button>
          </TaskManagerHeading>
        }
      >
        <TaskTypeProvider taskType="taskTemplates">
          <TaskManagerTable
            onRowClick={
              !user.isViewer
                ? (event) => {
                    navigate(`/org/task-templates/${event.snapshotId}`);
                  }
                : undefined
            }
            tableHeight={
              windowHeight - PAGE_LAYOUT_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT
            }
            buildingIds={buildingIds}
          />
        </TaskTypeProvider>
      </FullLayout>
    </TaskManagerTableFiltersProvider>
  );
}
