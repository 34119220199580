import { InputNumber, Switch } from "antd";
import { useTranslations } from "@properate/translations";
import { ValueLimit } from "../../types";
import { GridForm } from "./elements";

interface Props {
  valueLimit?: ValueLimit;
  onChangeValueLimit: (valueLimit: ValueLimit | undefined) => unknown;
}

export function TimeseriesValueLimit({
  valueLimit,
  onChangeValueLimit,
}: Props) {
  const t = useTranslations();
  const step = 1;

  const [minMinValue, minMaxValue] = [
    Number.MIN_SAFE_INTEGER,
    typeof valueLimit?.max === "number"
      ? valueLimit.max - step
      : Number.MAX_SAFE_INTEGER,
  ];
  const [maxMinValue, maxMaxValue] = [
    typeof valueLimit?.min === "number"
      ? valueLimit.min + step
      : Number.MIN_SAFE_INTEGER,
    Number.MAX_SAFE_INTEGER,
  ];

  function handleChangeMin(min: number | null) {
    if (typeof min === "number") {
      onChangeValueLimit({
        ...valueLimit,
        min,
      });
    }
  }

  function handleChangeMax(max: number | null) {
    if (typeof max === "number") {
      onChangeValueLimit({
        ...valueLimit,
        max,
      });
    }
  }

  function handleChangeValueLimitEnabled(checked: boolean) {
    onChangeValueLimit(checked ? {} : undefined);
  }

  return (
    <>
      <div>{t("analysis.details.timeseries-modal.min-and-max-limits")}</div>
      <GridForm>
        <label htmlFor="valueLimitEnabled">
          {t("analysis.details.timeseries-modal.fixed-y-axis")}
        </label>
        <Switch
          checked={Boolean(valueLimit)}
          onChange={handleChangeValueLimitEnabled}
        />
        {valueLimit && (
          <>
            <label htmlFor="valueLimitMin">
              {t("analysis.details.timeseries-modal.min")}
            </label>
            <InputNumber
              id="valueLimitMin"
              value={valueLimit?.min}
              min={minMinValue}
              max={minMaxValue}
              step={step}
              onChange={handleChangeMin}
              decimalSeparator=","
            />
            <label htmlFor="valueLimitMax">
              {t("analysis.details.timeseries-modal.max")}
            </label>
            <InputNumber
              id="valueLimitMax"
              value={valueLimit?.max}
              min={maxMinValue}
              max={maxMaxValue}
              step={step}
              onChange={handleChangeMax}
              decimalSeparator=","
            />
          </>
        )}
      </GridForm>
    </>
  );
}
