import _ from "lodash";
import {
  AlarmWithSnapshotId,
  ProperateEvent,
  WithSnapshotId,
} from "@properate/common";

export type AlarmIncident = WithSnapshotId<ProperateEvent> & {
  color?: string;
  end?: Date;
};

export const convertToIncidents = (
  alarms: AlarmWithSnapshotId[],
  events: WithSnapshotId<ProperateEvent>[],
) => {
  const alarmMap: Record<string, AlarmWithSnapshotId> = _.keyBy(
    alarms,
    "snapshotId",
  );

  const alarmToEvents = events.reduce(
    (prev: Record<string, AlarmIncident[]>, current) => {
      if (prev[current.alarmId]) {
        prev[current.alarmId] = [...prev[current.alarmId], current];
      } else {
        prev[current.alarmId] = [current];
      }
      return prev;
    },
    {},
  );

  // sort the events by date
  const sortedAlarmToEvents = Object.entries(alarmToEvents).reduce(
    (prev, [key, events]) => {
      prev[key] = events.sort((a, b) => a.date.getTime() - b.date.getTime());
      return prev;
    },
    {} as Record<string, AlarmIncident[]>,
  );
  const SEVERITY_COLORS = {
    CRITICAL: "#FF0000",
    ERROR: "#EE0000",
    WARNING: "#FFD80B",
    INFO: "#18A0FB",
  };

  // add the end date to the alarms
  const eventsWithEnd = Object.entries(sortedAlarmToEvents).reduce(
    (prev, [key, events]) => {
      prev[key] = events.reduce((incidents, incident, index) => {
        if (incident.state === "ALARM") {
          return [
            ...incidents,
            {
              ...incident,
              end: events[index + 1]?.date,
              color: alarmMap[incident.alarmId]?.severity
                ? SEVERITY_COLORS[alarmMap[incident.alarmId].severity]
                : "#858C94",
            },
          ];
        }
        return incidents;
      }, [] as AlarmIncident[]);
      return prev;
    },
    {} as Record<string, AlarmIncident[]>,
  );

  return Object.values(eventsWithEnd)
    .flat()
    .filter((a) => !!alarmMap[a.alarmId]);
};
