export const cdfProject =
  process.env["REACT_APP_PROJECT_ID"] ??
  process.env["NEXT_PUBLIC_COGNITE_PROJECT"] ??
  "";

if (!cdfProject) {
  throw new Error(
    "Cognite project ID not set in the environment variables. " +
      "Please set `REACT_APP_PROJECT_ID` or `NEXT_PUBLIC_COGNITE_PROJECT`.",
  );
}

const apiHost =
  process.env["REACT_APP_API_HOST"] ??
  process.env["NEXT_PUBLIC_API_HOST"] ??
  "api.eepcloud.no";

export const apiUrl = `https://${apiHost}/api/v0.2/`;
