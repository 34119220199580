import { MouseEvent, useCallback, useMemo } from "react";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { Delaunay } from "d3-delaunay";
import {
  SidebarActionType,
  useSidebarActions,
  useSidebarData,
} from "@properate/ui";
import dayjs, { DurationUnitType } from "@properate/dayjs";
import { ScaleLinearNumeric } from "@/utils/types";
import { getItemByProp } from "@/utils/array";
import { NotesSidebarValues, NotesSidebarViewState } from "@/features/notes";
import {
  ScaleWithTimeseriesId,
  ScaleWithUnit,
  ScatterplotPoint,
  SetSelectedAnalysisPoints,
} from "../../types";
import { getGraphMargins, isScalesWithUnit } from "../../utils";
import { ScatterplotGraphTooltip } from "./ScatterplotGraphTooltip";

const graphMargins = getGraphMargins("scatterplot");

interface Props {
  scatterplotPoints: ScatterplotPoint[];
  scaleX: ScaleLinearNumeric;
  scalesWithMetadataY: ScaleWithTimeseriesId[] | ScaleWithUnit[];
  onHoverScatterplotPoint: (value: ScatterplotPoint) => unknown;
  onLeaveHoveredScatterplotPoint: () => unknown;
  isNotesVisible: boolean;
  setSelectedPoints?: SetSelectedAnalysisPoints;
  assetListIdsByTimeseriesIds: Record<number, number>;
  timeseriesX: number;
  granularity: string;
}

export function ScatterplotGraphTooltipContainer({
  scatterplotPoints,
  scaleX,
  scalesWithMetadataY,
  onHoverScatterplotPoint,
  onLeaveHoveredScatterplotPoint,
  isNotesVisible,
  setSelectedPoints,
  assetListIdsByTimeseriesIds,
  timeseriesX,
  granularity,
}: Props) {
  const {
    showTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    hideTooltip,
  } = useTooltip<{
    element: JSX.Element;
    closestPoint: ScatterplotPoint;
  }>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal();
  const scaleXRange = scaleX.range();
  const scaleYRange = scalesWithMetadataY[0].scale.range();
  const { viewState } = useSidebarData<NotesSidebarValues>();

  const getScaleY = useCallback(
    (timeseriesId: number, unit: string) => {
      const { scale } = isScalesWithUnit(scalesWithMetadataY)
        ? getItemByProp(scalesWithMetadataY, unit, "unit")
        : getItemByProp(scalesWithMetadataY, timeseriesId, "timeseriesId");
      return scale;
    },
    [scalesWithMetadataY],
  );

  const delaunayLayout = useMemo(() => {
    return Delaunay.from<ScatterplotPoint>(
      scatterplotPoints,
      (datapoint) => scaleX(datapoint.x),
      (datapoint) => {
        const scaleY = getScaleY(
          datapoint.metadata.timeseriesIdY,
          datapoint.metadata.unitY,
        );
        return scaleY(datapoint.y);
      },
    );
  }, [scaleX, scatterplotPoints, getScaleY]);

  function handleMouseMove(event: MouseEvent) {
    const point = localPoint(event);
    if (point) {
      const closestPointIndex = delaunayLayout.find(
        point.x - graphMargins.left,
        point.y - graphMargins.top,
      );
      const closestPoint = scatterplotPoints[closestPointIndex];
      onHoverScatterplotPoint(closestPoint);
      const scaleY = getScaleY(
        closestPoint.metadata.timeseriesIdY,
        closestPoint.metadata.unitY,
      );
      showTooltip({
        tooltipLeft: scaleX(closestPoint.x) - scaleXRange[0],
        tooltipTop: scaleY(closestPoint.y),
        tooltipData: {
          element: (
            <ScatterplotGraphTooltip
              isNotesVisible={isNotesVisible}
              scatterplotPoint={closestPoint}
            />
          ),
          closestPoint,
        },
      });
    }
  }

  const sidebarDispatch = useSidebarActions();

  const handleMouseClick = (event: MouseEvent) => {
    const point = localPoint(event);
    if (
      point &&
      setSelectedPoints &&
      viewState === NotesSidebarViewState.customContent
    ) {
      const closestPointIndex = delaunayLayout.find(
        point.x - graphMargins.left,
        point.y - graphMargins.top,
      );
      const closestPoint = scatterplotPoints[closestPointIndex];

      const count = Number(granularity.slice(0, -1));
      const granularityUnit = granularity.slice(-1) as DurationUnitType;
      const endDate = dayjs(closestPoint.timestampX).add(
        count,
        granularityUnit,
      );

      const data = {
        viewState: NotesSidebarViewState.create,
        note: {
          assetIds: [
            assetListIdsByTimeseriesIds[closestPoint.metadata.timeseriesIdY],
            assetListIdsByTimeseriesIds[timeseriesX],
          ],
          startTime: closestPoint.timestampX,
          endTime: endDate.valueOf(),
        },
        customContent: undefined,
      };

      sidebarDispatch({
        type: SidebarActionType.mergeData,
        data,
      });
      sidebarDispatch({
        type: SidebarActionType.open,
      });

      setSelectedPoints([
        {
          timestamp: closestPoint.timestampX.valueOf(),
          leftOffset: 0,
        },
      ]);
    }
  };

  function handleMouseLeave() {
    hideTooltip();
    onLeaveHoveredScatterplotPoint();
  }

  return (
    <rect
      ref={containerRef}
      x={scaleXRange[0]}
      width={scaleXRange[1] - scaleXRange[0]}
      height={scaleYRange[0] - scaleYRange[1]}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      fill="transparent"
      cursor="pointer"
    >
      {tooltipOpen && (
        <TooltipInPortal
          key={Math.random()}
          left={tooltipLeft}
          top={tooltipTop}
        >
          {tooltipData?.element}
        </TooltipInPortal>
      )}
    </rect>
  );
}
