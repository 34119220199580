import { Progress, UploadFile } from "antd";
import { PictureOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";

export const ComponentDetailsFileCustomIcon = (file: UploadFile) => {
  const themeContext = useContext(ThemeContext);

  if (file.status === "uploading") {
    return (
      <div className="leading-10">
        <Progress
          size={28}
          type="circle"
          percent={file.percent}
          showInfo={false}
        />
      </div>
    );
  }

  if (file.thumbUrl) {
    return <ImageWithFallback src={file.thumbUrl} alt={file.name} />;
  }

  if (!("mimeType" in file) || !(typeof file.mimeType === "string")) {
    return undefined;
  }

  if (file.mimeType.startsWith("image/")) {
    return (
      <ImageWithFallback src={file.thumbUrl || file.url} alt={file.name} />
    );
  }

  if (file.mimeType.startsWith("video/")) {
    return (
      <VideoCameraOutlined
        style={{
          color:
            file.status === "error"
              ? themeContext.error
              : themeContext.neutral4,
        }}
      />
    );
  }

  return (
    <PictureOutlined
      style={{
        color:
          file.status === "error" ? themeContext.error : themeContext.neutral4,
      }}
    />
  );
};

function ImageWithFallback({
  src,
  alt,
}: {
  src: string | undefined;
  alt: string;
}) {
  const [hasErrored, setHasErrored] = useState(() => !src);
  const themeContext = useContext(ThemeContext);

  if (!hasErrored) {
    return (
      <img
        src={src}
        alt={alt}
        onError={() => setHasErrored(true)}
        className="ant-upload-list-item-image"
      />
    );
  }

  return (
    <PictureOutlined
      style={{
        color: themeContext.error,
      }}
    />
  );
}
