export const LABEL_KEYS = [
  "floor_plan",
  "system_schema",
  "manual",
  "datasheet",
  "service_report",
  "action_list",
  "energy_assessment",
  "energy_analysis_rapport",
  "operation_maintenance_manual",
  "picture",
  "video",
  "u_ventilation_system_report",
  "u_breeam_report",
  "u_topology_schema",
  "u_technical_drawing",
  "u_flow_chart",
  "u_hms_schema",
  "u_work_order",
  "work_order_attachment",
  "work_orders_attachment",
  "report",
  "energy_certificate",
  //"3d_model",
  "other",
  //unclassified,
];

// Veiledninger
// Garantier
// Serviceavtaler
// Vedlikeholdsplaner
// Driftsinstrukser
// Utstyrsregister
// Reservedelsreg
export const FILE_LABELS = [
  "floor_plan",
  "system_schema",
  "manual",
  "datasheet",
  "service_report",
  "action_list",
  "energy_assessment",
  "energy_analysis_rapport",
  "operation_maintenance_manual", //"FDV",
  "u_ventilation_system_report",
  "u_breeam_report",
  "u_topology_schema",
  "u_technical_drawing",
  "u_flow_chart",
  "u_hms_schema",
  "u_work_order",
  "report",
  "energy_certificate",
];

export const COGNITE_FILE_TYPES = [
  "document",
  "pdf",
  "spreadsheet",
  "presentation",
  "image",
  "video",
  "tabular-data",
  "plain-text",
  "compressed",
  "script",
  "other",
];
