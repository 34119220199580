import { ReactNode, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Timeseries } from "@cognite/sdk";
import { genId } from "@properate/ui";
import { message } from "antd";
import { useTranslations } from "@properate/translations";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useSensorCategoryOptions } from "@/pages/common/SchemaView/TechnicalSchema/sensorQueries";
import { Pin } from "./types";
import { useFloorPlanMapApi } from "./FloorPlanMapContext";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";
import { FloorPlanMapPinRoomSelectionModal } from "./FloorPlanMapPinRoomSelectionModal";
import { useFloorPlan } from "./FloorPlanContext";

const DRAG_ITEM_TYPE = "FLOOR_PLAN_MAP_PIN";

type DragItem = {
  type: Pin["type"];
};

type FloorPlanMapPinDragHandlerProps = {
  type: Pin["type"];
  children: ReactNode;
};

export function FloorPlanMapPinDragHandler(
  props: FloorPlanMapPinDragHandlerProps,
) {
  const [{ isDragging }, drag] = useDrag<
    DragItem,
    void,
    { isDragging: boolean }
  >(
    {
      type: DRAG_ITEM_TYPE,
      item: {
        type: props.type,
      },
      collect(monitor) {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    },
    [props.type],
  );

  return (
    <div ref={drag} className={isDragging ? "opacity-50" : ""}>
      {props.children}
    </div>
  );
}

type FloorPlanMapPinDropAreaProps = {
  children: ReactNode;
};

export function FloorPlanMapPinDropArea(props: FloorPlanMapPinDropAreaProps) {
  const t = useTranslations();
  type Draft = Pick<Pin, "type" | "variant" | "position">;

  const mapApi = useFloorPlanMapApi();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [draft, setDraft] = useState<Draft | null>(null);
  const floorPlanUpdate = useFloorPlanUpdate();
  const floorPlan = useFloorPlan();

  const [, drop] = useDrop<DragItem>({
    accept: DRAG_ITEM_TYPE,
    drop(item, monitor) {
      if (mapApi === null || containerRef.current === null) {
        message.warning(t("floor-plan-v2.errors.required-references-not-set"));
        return;
      }

      const dropOffset = monitor.getClientOffset();
      const containerRect = containerRef.current.getBoundingClientRect();

      if (dropOffset === null) {
        message.warning(
          t("floor-plan-v2.errors.drop-offset-failed-to-compute"),
        );
        return;
      }

      const { x: offsetX, y: offsetY } = dropOffset;
      const { left: containerLeft, top: containerTop } = containerRect;

      const { x: panX, y: panY } = mapApi.getPosition();
      const zoom = mapApi.getZoom();

      const x = (offsetX - containerLeft - panX) / zoom;
      const y = (offsetY - containerTop - panY) / zoom;

      setDraft({
        type: item.type,
        variant: "value",
        position: {
          x,
          y,
        },
      });
    },
  });

  async function handleSaveTimeseriesBasedDraft(timeseriesList: Timeseries[]) {
    if (draft === null) {
      message.warning(t("floor-plan-v2.errors.draft-is-empty"));
      return;
    }

    if (timeseriesList.length === 0) {
      message.warning(t("floor-plan-v2.errors.selected-timeseries-empty"));
      return;
    }

    floorPlanUpdate.trigger({
      pins: {
        insert: timeseriesList.map((timeseries, index) => ({
          ...draft,
          type: draft.type as Exclude<Pin["type"], "room">,
          id: genId(),
          timeseriesId: timeseries.id,
          externalId: timeseries.externalId ?? null,
          assetId: timeseries.assetId ?? null,
          alias: null,
          unit: null,
          alarm: null,
          position: {
            x: draft.position.x + index * 10,
            y: draft.position.y + index * 10,
          },
        })),
      },
    });
  }

  async function handleSaveRoomDraft(roomId: number) {
    if (draft === null) {
      message.warning(t("floor-plan-v2.errors.draft-is-empty"));
      return;
    }

    floorPlanUpdate.trigger({
      pins: {
        insert: [
          {
            ...draft,
            id: genId(),
            type: "room",
            variant: "value+name",
            alias: null,
            unit: null,
            alarms: {},
            roomId,
            position: {
              x: draft.position.x,
              y: draft.position.y,
            },
          },
        ],
      },
    });
  }

  const categoryOptions = useSensorCategoryOptions();

  function handleJointRefs(ref: HTMLDivElement | null) {
    containerRef.current = ref;
    drop(ref);
  }

  return (
    <div ref={handleJointRefs} className="w-full h-full overflow-hidden">
      {props.children}
      <TimeseriesSelectionModal
        open={draft !== null && draft.type !== "room"}
        onHide={() => setDraft(null)}
        selectedIds={[]}
        categoryOptions={categoryOptions}
        hiddenFilters={["building"]}
        onOk={handleSaveTimeseriesBasedDraft}
        initialFilters={draft ? { category: draft.type } : undefined}
      />
      <FloorPlanMapPinRoomSelectionModal
        key={floorPlan.pins.length}
        open={draft !== null && draft.type === "room"}
        onHide={() => setDraft(null)}
        onOk={handleSaveRoomDraft}
      />
    </div>
  );
}
