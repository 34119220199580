import { AxisBottom, AxisLeft } from "@visx/axis";
import { MouseEvent, useContext } from "react";
import { ThemeContext } from "styled-components";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { useTranslations } from "@properate/translations";
import { COLOR_PALETTE } from "@/utils/ProperateColors";
import { WasteData } from "./types";
import { formatMeasurement } from "./utils";

interface Props {
  width: number;
  height: number;
  data: WasteData[];
}
// react-dom shows Error: <rect> attribute width: A negative value is not valid.
// This function is used to check if the element has negative value if so returns 0 otherwise returns element value.
function checkNegativeValue(element: number) {
  return element < 0 ? 0 : element;
}
const margin = { top: 10, right: 30, bottom: 40, left: 310 };

export function WasteGraph({ width, height, data }: Props) {
  const t = useTranslations();
  const themeContext = useContext(ThemeContext);
  const colors = COLOR_PALETTE.map((color) => color.code);
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{ description: string; value: string }>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
  });

  let hideTimeout = 0;
  const handleMouseLeave = () => {
    clearTimeout(hideTimeout);
    hideTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  };
  const handleMouseMove = (
    event: MouseEvent<any>,
    barY: number,
    value: number,
    description: string,
  ) => {
    clearTimeout(hideTimeout);
    const coords = localPoint(event);
    if (coords) {
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: barY,
        tooltipData: { description, value: `${formatMeasurement(value)} kg` },
      });
    }
  };

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const yScale = scaleBand({
    domain: data.map((d) => d.description),
    range: [0, yMax],
    round: true,
    padding: 0.1,
  });

  const xScale = scaleLinear({
    domain: [0, Math.max(...data.map((d) => d.value))],
    range: [0, xMax],
    nice: true,
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: [...data.map((d) => d.description)],
    range: colors,
  });

  return (
    <div ref={containerRef}>
      <svg
        width={checkNegativeValue(width)}
        height={checkNegativeValue(height)}
      >
        <Group left={margin.left} top={margin.top}>
          <AxisBottom
            top={yMax}
            scale={xScale}
            stroke={themeContext.neutral5}
            tickStroke={themeContext.neutral5}
            labelClassName="value"
            tickLabelProps={{
              fill: themeContext.neutral5,
            }}
          />
          <AxisLeft
            tickValues={data.map((d) => d.description)}
            scale={yScale}
            stroke={themeContext.neutral5}
            tickStroke={themeContext.neutral5}
            tickLabelProps={{
              fill: themeContext.neutral5,
            }}
          />
          <Group>
            {data.map((d) => {
              const barHeight = checkNegativeValue(yScale.bandwidth());
              const barWidth = checkNegativeValue(xScale(d.value));
              const barY = yScale(d.description) ?? 0;
              const barX = 0;
              return (
                <Bar
                  key={`bar-${d.name}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={colorScale(d.name)}
                  onMouseLeave={handleMouseLeave}
                  onMouseMove={(event) =>
                    handleMouseMove(
                      event,
                      margin.top + barY,
                      d.value,
                      d.description,
                    )
                  }
                />
              );
            })}
          </Group>
          <text
            x={xMax - 20}
            y={yMax - 10}
            fontSize={11}
            fill={themeContext.neutral5}
          >
            {t("waste.weight-kg")}
          </text>
        </Group>
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft}>
          <div>
            <strong>{tooltipData.description}</strong> {tooltipData.value}
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
}
