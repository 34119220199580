const Input = () => (
  <div className="w-full h-8 bg-gray-200 rounded-md animate-pulse"></div>
);

const Area = () => (
  <div className="flex gap-5">
    <div className="bg-gray-200 h-7 w-7 animate-pulse"></div>
    <div className="w-full h-24 bg-gray-200 rounded-md animate-pulse"></div>
  </div>
);

export function DisplayPageLoading() {
  return (
    <div>
      <div className="w-32 h-3 mb-10 bg-gray-200 rounded-md animate-pulse md:mb-16"></div>
      <div className="h-8 mb-10 bg-gray-200 rounded-md animate-pulse md:mb-24"></div>
      <div className="flex flex-col w-full gap-10  md:flex-row-reverse">
        <div className="flex flex-col w-full gap-10 md:w-64 ">
          <div className="flex flex-row gap-10 md:flex-col ">
            <Input />
            <Input />
          </div>
          <div className="flex flex-row gap-10 md:flex-col ">
            <Input />
            <Input />
          </div>
          <Input />
          <Input />
          <Input />
        </div>
        <div className="flex flex-col w-full gap-12">
          <Area />
          <Area />
          <Area />
          <Area />
        </div>
      </div>
    </div>
  );
}
