export function isManualTimeseries(labels?: string[]) {
  return (
    labels !== undefined &&
    (labels.includes("U_manual_upload") ||
      labels.includes("Manuell opplasting"))
  );
}

export function isUserComponent(labels?: string[]) {
  return labels !== undefined && labels.includes("U_user_asset");
}
