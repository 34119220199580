import { browserFirestore } from "@properate/firebase";
import { collection, doc, setDoc } from "firebase/firestore";
import { useMemo } from "react";
import { DeepPartial } from "ts-essentials";
import { useGetFirestoreDocument } from "@properate/ui";
import { convertUnit } from "@/pages/common/utils";

export type TimeseriesSettings = {
  [externalId: string]: {
    unit?: string;
  };
};

const timeseriesCollection = collection(browserFirestore, "timeseries");

export function useTimeseriesSettings(buildingId: number) {
  const { data, isLoading, error } =
    useGetFirestoreDocument<TimeseriesSettings>(
      useMemo(
        () => doc(timeseriesCollection, buildingId.toString()),
        [buildingId],
      ),
    );
  function convertOverrideUnit(
    fromUnit: string | undefined,
    overrideUnit: string | undefined,
    value: number | undefined,
  ) {
    return convertUnit(fromUnit, overrideUnit, value);
  }
  async function handleOverrideUnit(
    buildingId: number,
    externalId: string,
    newUnit: string,
    overrideUnit?: string,
  ) {
    if (
      (overrideUnit && overrideUnit === newUnit) ||
      !buildingId ||
      !externalId
    ) {
      return;
    }

    await mutateTimeseriesSettings(buildingId, {
      [externalId]: {
        unit: newUnit,
      },
    });
  }

  return {
    overrideUnits: data,
    convertOverrideUnit,
    handleOverrideUnit,
    isLoading,
    error,
  };
}

async function mutateTimeseriesSettings(
  buildingId: number,
  data: DeepPartial<TimeseriesSettings>,
) {
  const ref = doc(browserFirestore, `timeseries/${buildingId}`);

  await setDoc(ref, data, { merge: true });
}
