import { Button, Col, Form, Row, Space, Switch, Tooltip } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  formatMeasurement,
  formatTimeseriesName,
  getFractionDigits,
} from "@properate/common";
import { useTranslations } from "@properate/translations";
import {
  Asset,
  CogniteClient,
  ExternalRelationship,
  Timeseries,
} from "@cognite/sdk";
import {
  CheckCircleOutlined,
  CloseOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { WhiteModal } from "@/pages/writable/elements";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useCogniteClient } from "../../context/CogniteClientContext";
import { getAssetById, getTimeseriesByExternalId } from "../../utils/helpers";
import { StatusType } from "./types";

const getNumberFormat = (unit: string) =>
  new Intl.NumberFormat("nb-NO", {
    maximumFractionDigits: getFractionDigits(unit),
  });

interface NamedRelationship extends ExternalRelationship {
  name: string;
}

const getRelationName = (
  rel: ExternalRelationship,
  timeseriesMap: Record<string, Timeseries>,
  valuesMap: Record<string, any>,
) => {
  return `${formatTimeseriesName(
    timeseriesMap[rel.targetExternalId],
  )} (${getNumberFormat(valuesMap[rel.targetExternalId].unit).format(
    valuesMap[rel.targetExternalId].value,
  )} ${valuesMap[rel.targetExternalId].unit || ""})`;
};

export const EditRelationshipModal = ({
  editStatus,
  hide,
}: {
  editStatus: StatusType;
  hide: Function;
}) => {
  const [form] = Form.useForm();
  const { client } = useCogniteClient();
  const [timeseriesMap, setTimeseriesMap] =
    useState<Record<string, Timeseries>>();
  const t = useTranslations();
  const [valuesMap, setValuesMap] = useState<Record<string, any>>();
  const [relationships, setRelationships] = useState<ExternalRelationship[]>(
    editStatus.relationships,
  );
  const [parentAsset, setParentAsset] = useState<Asset>();

  const [showSelectTimeseries, setShowSelectTimeseries] = useState<boolean>();

  const [selectedTimeseries, setSelectedTimeseries] = useState<
    NamedRelationship | undefined
  >();

  useEffect(() => {
    const getParent = async (client: CogniteClient) => {
      const ts = await getTimeseriesByExternalId(client, editStatus.externalId);

      let asset: Asset;
      // noinspection StatementWithEmptyBodyJS
      for (
        asset = await getAssetById(client, ts.assetId!);
        //assets = await client.assets.retrieve([{ id: ts.assetId! }]);
        asset.parentId &&
        (!asset.labels ||
          !asset.labels
            .map((l) => l.externalId)
            .some((label) =>
              ["ventilation_unit", "cooling_system", "heating_system"].includes(
                label,
              ),
            ));
        asset = await getAssetById(client, asset.parentId)
      );
      if (asset) {
        setParentAsset(asset);
      }
    };

    if (client) {
      getParent(client);
    }
  }, [client, editStatus]);

  useEffect(() => {
    const get = async (client: CogniteClient) => {
      const timeseries = await Promise.all(
        editStatus.relationships.map((rel) =>
          getTimeseriesByExternalId(client, rel.targetExternalId),
        ),
      );

      setTimeseriesMap(
        timeseries.reduce(
          (prev, current) => ({ ...prev, [current!.externalId!]: current }),
          {},
        ),
      );
      const values =
        timeseries.length > 0
          ? await client.datapoints.retrieveLatest(
              timeseries.map((ts) => ({ id: ts!.id })),
            )
          : [];
      setValuesMap(
        values.reduce(
          (prev, current) => ({
            ...prev,
            [current.externalId!]: {
              unit: current.unit,
              ...(current.datapoints.length > 0 ? current.datapoints[0] : {}),
            },
          }),
          {},
        ),
      );
    };
    if (client) {
      get(client);
    }
  }, [editStatus.relationships, client]);

  useEffect(() => {
    if (
      timeseriesMap &&
      valuesMap &&
      relationships &&
      relationships.length > 0 &&
      relationships[0].confidence! >= 0.29
    ) {
      const ts = {
        ...relationships[0],
        name: getRelationName(relationships[0], timeseriesMap, valuesMap),
        confidence: relationships[0].confidence!,
      };
      setSelectedTimeseries(ts);
    } else {
      setSelectedTimeseries(undefined);
    }
  }, [timeseriesMap, valuesMap, relationships]);

  const priorityMap = (editStatus["priority-array"] || []).reduce<
    Record<number, number | null>
  >((prev, current) => {
    prev[current.index] = current.value;
    return prev;
  }, {});

  const updateSelectedTimeseries = (rel: any) => {
    const newRelationships = [
      rel,
      ...[
        ...(selectedTimeseries
          ? [
              {
                ...selectedTimeseries,
                confidence:
                  selectedTimeseries.confidence! > 0.6
                    ? selectedTimeseries.confidence! - 0.65
                    : selectedTimeseries.confidence!,
              },
            ]
          : []),
        ...relationships.filter(
          (relationship) =>
            !selectedTimeseries ||
            relationship.externalId !== selectedTimeseries.externalId,
        ),
      ].filter((relationship) => rel.externalId !== relationship.externalId),
    ];
    setRelationships(newRelationships);
  };

  async function handleAddTimeseries(timeseries: Timeseries) {
    setShowSelectTimeseries(undefined);
    setTimeseriesMap((timeseriesMapCurrent) => ({
      ...timeseriesMapCurrent,
      [timeseries.externalId!]: timeseries,
    }));
    const latestDatapoints = await client.datapoints.retrieveLatest([
      { id: timeseries.id },
    ]);
    setValuesMap((valuesMapCurrent) => ({
      ...valuesMapCurrent,
      [timeseries.externalId!]: {
        ...(latestDatapoints[0].datapoints.length > 0
          ? latestDatapoints[0].datapoints[0]
          : {}),
        unit: timeseries.unit || "",
      },
    }));
    updateSelectedTimeseries({
      externalId: `rel_${crypto.randomUUID()}`,
      sourceExternalId: editStatus.externalId,
      targetExternalId: timeseries.externalId,
      name: timeseries.name,
      confidence: 0.95,
      sourceType: "timeSeries" as const,
      targetType: "timeSeries" as const,
      dataSetId: timeseries.dataSetId,
      labels: [{ externalId: "rel_setpt_realval_gen" }],
    });
  }

  async function handleOk(timeseriesList: Timeseries[]) {
    if (timeseriesList.length > 0) {
      await handleAddTimeseries(timeseriesList[0]);
    }
  }

  return (
    <WhiteModal
      title={editStatus.name}
      open
      onCancel={() => {
        form.resetFields();
        hide();
      }}
      onOk={async () => {
        const updatedRelationships = relationships.filter((current) => {
          if (current) {
            const existing = editStatus.relationships.find(
              (rel) => rel.targetExternalId === current.targetExternalId,
            );
            return !existing || existing.confidence !== current.confidence;
          }
          return false;
        });

        const results = await Promise.all(
          updatedRelationships.map(async (rel) => {
            if ((rel as any).createdTime) {
              // no update function available in js api
              try {
                await client.relationships.delete(
                  [{ externalId: rel!.externalId }],
                  {
                    ignoreUnknownIds: false,
                  },
                );
              } catch (error: any) {
                console.error("Delete failed: " + rel!.externalId);
              }
            }
            return client.relationships.create([rel!]);
          }),
        );

        hide({
          ...editStatus,
          relationships: [
            ...editStatus.relationships.filter(
              (rel) =>
                !results
                  .flat()
                  .find((r) => r!.targetExternalId === rel.targetExternalId),
            ),
            ...results.flat(),
          ].sort((a, b) => b.confidence! - a.confidence!),
        });
      }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label={t("writable.edit-relationship-modal.set-point")}>
          {((typeof priorityMap[8] === "number" ||
            typeof priorityMap[16] === "number") &&
            formatMeasurement({
              value:
                typeof priorityMap[8] === "number"
                  ? priorityMap[8]!
                  : priorityMap[16]!,
              unit: editStatus.unit || "",
            })) ||
            ""}
        </Form.Item>
        {timeseriesMap && valuesMap && !selectedTimeseries && (
          <Form.Item
            label={t("writable.edit-relationship-modal.link-to-value")}
          >
            {t("writable.edit-relationship-modal.no-link-selected")}!
          </Form.Item>
        )}
        {selectedTimeseries && timeseriesMap && valuesMap && (
          <Form.Item
            label={t("writable.edit-relationship-modal.link-to-value")}
          >
            <Row>
              <Col flex="auto">{`${selectedTimeseries.name}`}</Col>
              <Col flex="none">
                <Space>
                  {t("writable.edit-relationship-modal.confirm-connection")}
                  <Switch
                    checked={selectedTimeseries.confidence! > 0.6}
                    checkedChildren={<CheckCircleOutlined />}
                    unCheckedChildren={<QuestionCircleOutlined />}
                    onChange={(on) => {
                      if (on) {
                        setRelationships([
                          {
                            ...selectedTimeseries,
                            confidence: selectedTimeseries.confidence! + 0.65,
                          },
                          ...relationships.filter(
                            (rel) =>
                              rel.externalId !== selectedTimeseries.externalId,
                          ),
                        ]);
                      } else {
                        setRelationships([
                          {
                            ...selectedTimeseries,
                            confidence: selectedTimeseries.confidence! - 0.65,
                          },
                          ...relationships.filter(
                            (rel) =>
                              rel.externalId !== selectedTimeseries.externalId,
                          ),
                        ]);
                      }
                    }}
                  />
                  <Tooltip
                    title={t("writable.edit-relationship-modal.remove-link")}
                  >
                    <Button
                      icon={<CloseOutlined />}
                      danger
                      type="link"
                      onClick={() => {
                        //setSelectedTimeseries(undefined);
                        setRelationships(
                          relationships.map((rel) => ({
                            ...rel,
                            confidence: 0.1,
                          })),
                        );
                      }}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        )}
        {timeseriesMap && valuesMap && relationships.length > 1 && (
          <Form.Item
            label={t(
              "writable.edit-relationship-modal.suggestion-alternative-links",
            )}
          >
            {relationships
              .filter(
                (rel) =>
                  rel.targetExternalId !== selectedTimeseries?.targetExternalId,
              )
              .map((rel) => (
                <Button
                  key={rel.targetExternalId}
                  block
                  type="link"
                  style={{ textAlign: "left", padding: 0 }}
                  onClick={() => {
                    updateSelectedTimeseries({
                      ...rel,
                      name: getRelationName(rel, timeseriesMap, valuesMap),
                      confidence: 0.95,
                    });
                  }}
                >
                  {getRelationName(rel, timeseriesMap, valuesMap)}
                </Button>
              ))}
          </Form.Item>
        )}
        {timeseriesMap && valuesMap && (
          <Button
            type="dashed"
            onClick={() => {
              setShowSelectTimeseries(true);
            }}
            block
            icon={<PlusOutlined />}
          >
            {" "}
            {t("writable.edit-relationship-modal.imported-link")}
          </Button>
        )}
      </Form>
      <TimeseriesSelectionModal
        open={Boolean(showSelectTimeseries && parentAsset)}
        hiddenFilters={["building"]}
        initialFilters={{
          category: "technical",
          unit: editStatus.unit,
        }}
        onHide={() => setShowSelectTimeseries(undefined)}
        onOk={handleOk}
        selectedIds={[]}
        max={1}
      />
    </WhiteModal>
  );
};
