import { Col, Divider, App, Row } from "antd";
import { CalendarOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import {
  ColoredProperateCalendar,
  Holiday,
  NewProperateCalendarEvent,
} from "@/utils/types";
import { EditValue } from "@/pages/properateCalendar/components/EditValue";
import { SelectCalendar } from "@/pages/properateCalendar/components/SelectCalendar";
import { getNextHour } from "@/pages/properateCalendar/utils";
import SelectTime from "@/pages/properateCalendar/components/SelectTime";
import {
  CenteredDiv,
  DotDivider,
  StyledModal,
  TitleButtons,
} from "./components/elements";

type Props = {
  calendars: ColoredProperateCalendar[];
  addEvent: (events: NewProperateCalendarEvent) => Promise<void>;
  onHide: () => void;
  date: Date;
  holiday?: Holiday;
  calendarMap: Record<string, ColoredProperateCalendar>;
};

export const AddWeeklyEventModal = ({
  onHide,
  calendars,
  addEvent,
  date,
  calendarMap,
}: Props) => {
  const t = useTranslations();

  const { message } = App.useApp();
  const [start, setStart] = useState(getNextHour(date));
  const [end, setEnd] = useState(
    new Date(
      Math.min(
        dayjs(start).add(1, "hour").valueOf(),
        dayjs(start).startOf("day").add(1, "day").valueOf(),
      ),
    ),
  );
  const [selectedCalendar, setSelectedCalendar] = useState(calendars[0]);

  const [value, setValue] = useState(
    Object.entries(selectedCalendar.valid_values).slice(-1)[0]?.[0],
  );

  useEffect(() => {
    setValue(Object.entries(selectedCalendar.valid_values).slice(-1)[0]?.[0]);
  }, [selectedCalendar]);

  const onSave = async () => {
    if (dayjs(end).isBefore(start)) {
      message.error(t("calendar.start-date-must-be-before-end-date"));
      return;
    }
    await addEvent({
      calendar_id: selectedCalendar.calendar_id,
      start,
      end,
      value,
      schedule: "weekly",
    });
    onHide();
  };

  return (
    <StyledModal
      destroyOnClose
      closable={false}
      open
      onOk={onSave}
      onCancel={onHide}
      title={
        <div style={{ textAlign: "right" }}>
          <TitleButtons type="link" icon={<CloseOutlined />} onClick={onHide} />
        </div>
      }
      width={600}
    >
      <SelectCalendar
        selected={selectedCalendar}
        setSelected={setSelectedCalendar}
        calendars={calendars}
        calendarMap={calendarMap}
      />
      <Row style={{ marginTop: 8 }}>
        <Col span={2}>
          <CenteredDiv>
            <CalendarOutlined />
          </CenteredDiv>
        </Col>
        <Col span={22}>
          {dayjs(start).tz("Europe/Oslo").format("dddd")}
          <DotDivider />
          <SelectTime
            start={start}
            end={end}
            setEnd={setEnd}
            setStart={setStart}
          />
        </Col>
      </Row>
      <Row>
        <Col span={22} offset={2}>
          <Divider />
        </Col>
      </Row>

      <Row>
        <Col span={22} offset={2}>
          <EditValue
            value={value}
            setValue={setValue}
            validValues={selectedCalendar?.valid_values}
            defaultValue={selectedCalendar?.default_value}
          />
        </Col>
      </Row>
    </StyledModal>
  );
};
