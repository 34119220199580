import * as React from "react";
import Highlighter from "react-highlight-words";

type Props = {
  searchWords: string[];
  textToHighlight: string;
  autoEscape?: boolean;
  className?: string;
  title?: string;
};
export const ProperateHighlighter = ({
  searchWords,
  textToHighlight,
  autoEscape,
  className,
  title,
}: Props) => {
  return (
    <Highlighter
      searchWords={searchWords}
      textToHighlight={textToHighlight}
      highlightStyle={{
        backgroundColor: "#ffc069",
        padding: 0,
      }}
      autoEscape={autoEscape}
      className={className}
      title={title}
    />
  );
};
