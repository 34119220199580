import { Statistic } from "antd";
import styled from "styled-components";

export const KpiCard = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 0 0 24px -1px;
  padding-left: 1px;
  background: transparent;
  font-weight: bold;
  position: relative;
  .ant-card-body {
    padding: 0 24px;
  }

  & + &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: ${(props) => props.theme.neutral7};
    top: 0;
    left: -1px;
  }
`;

export const StyledStatistic = styled(Statistic)<{ type: string }>`
  .ant-statistic-content {
    color: ${(props) => {
      if (props.type === "good") {
        return props.theme.success;
      }
      if (props.type === "ok") {
        return props.theme.neutral3;
      }
      if (props.type === "error") {
        return props.theme.error;
      }
      if (props.type === "warning") {
        return props.theme.warning;
      }
    }};
  }
`;

export const TooltipDescription = styled.div<{ type: string }>`
  :before {
    content: "• ";
    color: ${(props) => {
      if (props.type === "good") {
        return props.theme.success;
      }
      if (props.type === "ok") {
        return props.theme.white;
      }
      if (props.type === "error") {
        return props.theme.error;
      }
      if (props.type === "warning") {
        return props.theme.warning;
      }
    }};
    font-size: 40px;
    vertical-align: -10px;
  }
  line-height: 14px;
`;
