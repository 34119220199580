import { ScaleTime } from "d3-scale";
import { TimeSpan } from "@properate/common";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import Color from "color";
import {
  ScaleWithTimeseriesId,
  ScaleWithUnit,
  SelectedAnalysisPoints,
  SimplePointsWithMetadata,
} from "@/features/analysis";
import { SimpleGraphMarkers } from "./SimpleGraphMarkers";

type Props = {
  selectedPoints: SelectedAnalysisPoints;
  graphHeight: number;
  graphMargins: { left: number; right: number; top: number; bottom: number };
  timeScale: ScaleTime<number, number>;
  scalesWithMetadata: ScaleWithTimeseriesId[] | ScaleWithUnit[];
  simplePointsWithMetadataList: SimplePointsWithMetadata[];
  zoomedTimeSpan: TimeSpan;
};
export const SimpleGraphNotesRangeSelection = ({
  selectedPoints,
  graphHeight,
  graphMargins,
  scalesWithMetadata,
  simplePointsWithMetadataList,
  timeScale,
  zoomedTimeSpan,
}: Props) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      {selectedPoints?.[0]?.leftOffset !== undefined && (
        <SimpleGraphMarkers
          isHighlightedStroke
          height={graphHeight}
          leftOffset={selectedPoints?.[0].leftOffset - graphMargins.left}
          date={new Date(selectedPoints[0].timestamp)}
          scalesWithMetadata={scalesWithMetadata}
          simplePointsWithMetadataList={simplePointsWithMetadataList}
          timeScale={timeScale}
          zoomedTimeSpan={zoomedTimeSpan}
        />
      )}
      {selectedPoints?.[1]?.leftOffset !== undefined && (
        <SimpleGraphMarkers
          isHighlightedStroke
          height={graphHeight}
          leftOffset={selectedPoints?.[1].leftOffset - graphMargins.left}
          date={new Date(selectedPoints[1].timestamp)}
          scalesWithMetadata={scalesWithMetadata}
          simplePointsWithMetadataList={simplePointsWithMetadataList}
          timeScale={timeScale}
          zoomedTimeSpan={zoomedTimeSpan}
        />
      )}
      {selectedPoints?.length === 2 && (
        <rect
          x={selectedPoints?.[0]?.leftOffset - graphMargins.left}
          y={0}
          width={
            selectedPoints?.[1]?.leftOffset - selectedPoints?.[0]?.leftOffset
          }
          height={graphHeight}
          fill={Color(themeContext.neutral1).alpha(0.2).toString()}
        />
      )}
    </>
  );
};
