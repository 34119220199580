import {
  CommonSearchable,
  SearchIndex,
  TimeseriesSearchable,
} from "@properate/api";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { FacetHit, Hits } from "meilisearch";
import { useCurrentBuildingId } from "./useCurrentBuildingId";

const PAGE_SIZE = 100;
const SEARCH_DEBOUNCE_MS = 250;

function buildFilter(name: string, filter: string | string[]) {
  if (!(name ?? "").trim()) {
    return "";
  }
  if (Array.isArray(filter)) {
    return filter.map((value) => ` AND ${name} = "${value}"`).join("");
  }
  return filter ? ` AND ${name} = "${filter}"` : "";
}

function buildFilterQuery<T extends CommonSearchable = TimeseriesSearchable>(
  filter: Filter<T>,
  currentBuildingId: number,
) {
  const queryParts = [`buildingId = ${currentBuildingId}`];
  Object.entries(filter).forEach(([name, value]) => {
    queryParts.push(buildFilter(name, value));
  });
  return queryParts.join("");
}

export type FacetSearchHit = Record<
  string,
  {
    search: string;
    hits: FacetHit[];
  }
>;

export type SortBy = {
  key: string;
  order: "asc" | "desc";
};

export type Filter<T extends Record<string, any>> = Partial<T>;

export type SearchIndexResult<T extends CommonSearchable> = {
  facetHits: FacetSearchHit;
  searchHits: Hits<T>;
  page: number;
};

export type SearchIndexProps<
  Input extends Record<string, any>,
  Response extends CommonSearchable,
> = {
  index: SearchIndex<Response>;
  search: string;
  selectedFacets: Filter<Input>;
  facetSearchInput: Filter<Input>;
  sortBy?: SortBy;
  page?: number;
};

export function useSearchIndex<
  Input extends Record<string, any>,
  Response extends CommonSearchable,
>({
  index,
  search,
  selectedFacets,
  facetSearchInput,
  sortBy,
  page = 0,
}: SearchIndexProps<Input, Response>): SearchIndexResult<Response> {
  const [prevFilterQuery, setPrevFilterQuery] = useState<string>();
  const [prevSearch, setPrevSearch] = useState<string>();
  const [facetHits, setFacetHits] = useState<FacetSearchHit>();
  const [prevPage, setPrevPage] = useState<number>();
  const [searchHits, setSearchHits] = useState<Hits<Response>>();
  const currentBuildingId = useCurrentBuildingId();

  const facetFilterTimeout = useRef<number | null>(null);
  const searchTimeout = useRef<number | null>(null);

  function clearTimeoutRef(handle: MutableRefObject<number | null>) {
    if (handle.current !== null) {
      window.clearTimeout(handle.current);
      handle.current = null;
    }
  }

  useEffect(
    () => {
      clearTimeoutRef(facetFilterTimeout);
      const filterQuery = buildFilterQuery(selectedFacets, currentBuildingId);
      facetFilterTimeout.current = window.setTimeout(async () => {
        for (const [name, filter] of Object.entries(facetSearchInput)) {
          if (facetHits?.[name]?.search !== filter) {
            const { facetHits: newFacetHits } =
              await index.searchForFacetValues({
                filter: filterQuery,
                facetName: name,
                facetQuery: filter ?? "",
              });
            setFacetHits((prevFacetHits: FacetSearchHit | undefined) => ({
              ...(prevFacetHits || {}),
              [name]: {
                search: filter,
                hits: newFacetHits,
              },
            }));
          }
        }
      }, SEARCH_DEBOUNCE_MS);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // We only want to trigger this from user-input, more specifically facet searches.
      facetSearchInput,
    ],
  );

  useEffect(
    () => {
      clearTimeoutRef(searchTimeout);
      searchTimeout.current = window.setTimeout(async () => {
        const filterQuery = buildFilterQuery(selectedFacets, currentBuildingId);
        if (
          filterQuery !== prevFilterQuery ||
          search !== prevSearch ||
          page !== prevPage
        ) {
          const { hits: searchHits } = await index.search(search, {
            sort: sortBy ? [`${sortBy.key}:${sortBy.order}`] : undefined,
            filter: filterQuery,
            attributesToHighlight: ["*"],
            limit: PAGE_SIZE,
            offset: page * PAGE_SIZE,
          });
          setSearchHits(searchHits);
          setPrevFilterQuery(filterQuery);
          setPrevSearch(search);
          setPrevPage(page);
        }
      }, SEARCH_DEBOUNCE_MS);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, facetHits, page, selectedFacets],
  );

  return {
    facetHits: facetHits ?? {},
    searchHits: searchHits ?? [],
    page,
  };
}
