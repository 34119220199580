import styled from "styled-components";
import { Input } from "antd";

export const ContrastSearchInput = styled(Input.Search)`
  .ant-input-affix-wrapper {
    background: ${(props) => props.theme.background2};
    border: 1px solid #d9d9d9;
  }

  &.ant-input-affix-wrapper-focused {
    background: ${(props) => props.theme.background2};
  }

  input {
    background: ${(props) => props.theme.background2};
  }

  &.ant-input-affix-wrapper-focused input {
    background: ${(props) => props.theme.background2};
  }
  .ant-input-group-addon button {
    border-color: #d9d9d9;
  }
`;
