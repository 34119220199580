import { Button, InputNumber, Space, Table, DatePicker } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import dayjs, { Dayjs } from "@properate/dayjs";
import { useEffect } from "react";
import { useTranslations } from "@properate/translations";
import {
  DatapointReducerActionType,
  useMeterData,
  useMeterDataDispatch,
} from "./MeterDataContext";
import type { DataPoint } from "./MeterDataContext";

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

function EditableNumericTableCell({
  rowIndex,
  fieldName,
}: {
  rowIndex: number;
  fieldName: keyof DataPoint;
}) {
  const { dataPoints } = useMeterData();
  const dispatch = useMeterDataDispatch();

  useEffect(() => {
    if (dataPoints[rowIndex].focus ?? false) {
      dispatch({
        type: DatapointReducerActionType.clearFocus,
      });
    }
  }, [dataPoints, rowIndex, dispatch]);

  function handleChange(value: number | null) {
    dispatch({
      type: DatapointReducerActionType.editDataPoint,
      rowIndex,
      fieldName,
      fieldValue: value,
    });
  }

  function handlePressEnter() {
    dispatch({
      type: DatapointReducerActionType.addRowAtIndex,
      rowIndex: rowIndex + 1,
    });
  }

  const value =
    dataPoints[rowIndex][fieldName] === null
      ? undefined
      : dataPoints[rowIndex][fieldName];

  return (
    <InputNumber
      defaultValue={value as number | undefined}
      onChange={handleChange}
      onPressEnter={handlePressEnter}
      autoFocus={dataPoints[rowIndex].focus}
      decimalSeparator=","
    />
  );
}

function EditableDateTableCell({
  rowIndex,
  fieldName,
}: {
  rowIndex: number;
  fieldName: keyof DataPoint;
}) {
  const { dataPoints } = useMeterData();
  const dispatch = useMeterDataDispatch();
  const timestamp = dataPoints[rowIndex][fieldName] as DataPoint["timestamp"];

  function handleChange(value: Dayjs | null) {
    dispatch({
      type: DatapointReducerActionType.editDataPoint,
      rowIndex,
      fieldName,
      fieldValue: value === null ? null : value.toDate(),
    });
  }

  return (
    <DatePicker
      showTime
      format={DATE_FORMAT}
      value={timestamp === null ? null : dayjs(timestamp)}
      onChange={handleChange}
      allowClear={false}
    />
  );
}

export function ModalDataPointsDataTable() {
  const t = useTranslations();
  const { dataPoints } = useMeterData();
  const dispatch = useMeterDataDispatch();

  const columns: ColumnsType<DataPoint> = [
    {
      title: t("timeseries.meter-selection-modal.date-time"),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (_, __, index) => (
        <EditableDateTableCell fieldName={"timestamp"} rowIndex={index} />
      ),
    },
    {
      title: t("timeseries.meter-selection-modal.value"),
      dataIndex: "value",
      key: "value",
      render: (_, __, index) => (
        <EditableNumericTableCell fieldName={"value"} rowIndex={index} />
      ),
    },
    {
      title: t("timeseries.meter-selection-modal.actions"),
      key: "actions",
      render: (_, __, index) => {
        return (
          <Space>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() =>
                dispatch({
                  type: DatapointReducerActionType.removeRow,
                  rowIndex: index,
                })
              }
              aria-label={t(
                "timeseries.meter-selection-modal.remove-datapoint",
              )}
            />
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                dispatch({
                  type: DatapointReducerActionType.addRowAtIndex,
                  rowIndex: index + 1,
                })
              }
              aria-label={t("timeseries.meter-selection-modal.add-datapoint")}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      size={"small"}
      columns={columns}
      dataSource={dataPoints}
      scroll={{ y: 400 }}
      pagination={false}
      rowKey={(dataPoint, index) => `${index}_${dataPoint.timestamp}`}
    />
  );
}
