import { useContext, createContext, ReactNode, useMemo } from "react";
import { apiBatch, ProperateApiClient } from "@/context/api/apiBatch";

export const ProperateApiContext = createContext<ProperateApiClient | null>(
  null,
);

interface Props {
  children: ReactNode;
}

export function ProperateApiProvider({ children }: Props) {
  const api = useMemo(() => apiBatch(), []);

  return (
    <ProperateApiContext.Provider value={api}>
      {children}
    </ProperateApiContext.Provider>
  );
}

export const useProperateApiClient = (): ProperateApiClient => {
  const properateApiClient = useContext(ProperateApiContext);
  if (!properateApiClient) {
    throw new Error(
      '"useProperateCogniteClient" was called from a component that does not have a ProperateCogniteClientProvider parent',
    );
  }
  return properateApiClient;
};
