import { browserFirestore } from "@properate/firebase";
import { doc, setDoc } from "firebase/firestore";
import { useMemo } from "react";
import { useGetFirestoreDocument } from "@properate/ui";
import { TrendLineInfo } from "../utils/types";
import type { DeepPartial } from "ts-essentials";

export function useTrendLines(assetId?: string) {
  const { data } = useGetFirestoreDocument<TrendLineInfo>(
    useMemo(
      () => (assetId ? doc(browserFirestore, `trendLines/${assetId}`) : null),
      [assetId],
    ),
  );

  return data;
}

export async function mutateTrendLines(
  assetId: string,
  data: DeepPartial<TrendLineInfo>,
) {
  const ref = doc(browserFirestore, `trendLines/${assetId}`);

  await setDoc(ref, data, { merge: true });
}
