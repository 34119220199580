import { useState } from "react";
import { Row, Col, Select } from "antd";
import dayjs from "@properate/dayjs";
import {
  DayType,
  DayOrWeekendsOrWeekDays,
  Period,
  TimeSpan,
  PERIOD_VALUES,
} from "@properate/common";
import { useUser } from "@properate/auth";
import { MessageKey, useTranslations } from "@properate/translations";
import { RangePicker } from "@/components/RangePicker/RangePicker";
import { getDefaultPeriod } from "../../utils";

interface Props {
  periods: Period[];
  onAdd: (periods: Period[]) => unknown;
}

export function SchedulePaneActivePeriodInput({ periods, onAdd }: Props) {
  const t = useTranslations();

  const user = useUser();
  const [day, setDay] = useState<DayOrWeekendsOrWeekDays | null>(
    getDefaultPeriod(periods),
  );

  function doesPeriodsContainSameDayWithSameStartAndEnd(
    day: DayType,
    startTime: string,
    endTime: string,
  ) {
    return periods.some(
      (period) =>
        period.day === day &&
        period.start === startTime &&
        period.end === endTime,
    );
  }

  function doesPeriodsContainSameWeekdaysOrWeekendWithSameStartAndEnd(
    day: DayOrWeekendsOrWeekDays,
    startTime: string,
    endTime: string,
  ) {
    if (day === "WEEKENDS") {
      return (
        doesPeriodsContainSameDayWithSameStartAndEnd(
          "SATURDAY",
          startTime,
          endTime,
        ) &&
        doesPeriodsContainSameDayWithSameStartAndEnd(
          "SUNDAY",
          startTime,
          endTime,
        )
      );
    }
    if (day === "WEEKDAYS") {
      return (
        doesPeriodsContainSameDayWithSameStartAndEnd(
          "MONDAY",
          startTime,
          endTime,
        ) &&
        doesPeriodsContainSameDayWithSameStartAndEnd(
          "TUESDAY",
          startTime,
          endTime,
        ) &&
        doesPeriodsContainSameDayWithSameStartAndEnd(
          "WEDNESDAY",
          startTime,
          endTime,
        ) &&
        doesPeriodsContainSameDayWithSameStartAndEnd(
          "THURSDAY",
          startTime,
          endTime,
        ) &&
        doesPeriodsContainSameDayWithSameStartAndEnd(
          "FRIDAY",
          startTime,
          endTime,
        )
      );
    }
    return doesPeriodsContainSameDayWithSameStartAndEnd(
      day,
      startTime,
      endTime,
    );
  }

  function handleChange(startAndEndTime: TimeSpan) {
    const [startTime, endTime] = startAndEndTime;
    if (day && startTime !== null && endTime !== null) {
      const startTimeFormatted = dayjs(startTime).format("HH:mm");
      const endTimeFormatted = dayjs(endTime).format("HH:mm");
      if (
        doesPeriodsContainSameWeekdaysOrWeekendWithSameStartAndEnd(
          day,
          startTimeFormatted,
          endTimeFormatted,
        )
      ) {
        return;
      }
      if (day === "WEEKENDS") {
        onAdd([
          {
            day: "SATURDAY",
            start: startTimeFormatted,
            end: endTimeFormatted,
          },
          { day: "SUNDAY", start: startTimeFormatted, end: endTimeFormatted },
        ]);
      } else if (day === "WEEKDAYS") {
        onAdd([
          { day: "MONDAY", start: startTimeFormatted, end: endTimeFormatted },
          {
            day: "TUESDAY",
            start: startTimeFormatted,
            end: endTimeFormatted,
          },
          {
            day: "WEDNESDAY",
            start: startTimeFormatted,
            end: endTimeFormatted,
          },
          {
            day: "THURSDAY",
            start: startTimeFormatted,
            end: endTimeFormatted,
          },
          { day: "FRIDAY", start: startTimeFormatted, end: endTimeFormatted },
        ]);
      } else {
        onAdd([{ day, start: startTimeFormatted, end: endTimeFormatted }]);
      }
      setDay(null);
    }
  }

  return (
    <Row gutter={8}>
      <Col span={9}>
        <Select
          data-testid="select-day"
          disabled={user.isViewer}
          style={{ width: "100%" }}
          onChange={setDay}
          value={day}
        >
          {PERIOD_VALUES.map((day) => (
            <Select.Option
              value={day}
              key={day}
              data-testid={t(
                `common.day-periods.${day.toLowerCase()}` as MessageKey,
              )}
            >
              {t(`common.day-periods.${day.toLowerCase()}` as MessageKey)}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={15}>
        <RangePicker
          picker="time"
          format="HH:mm"
          onChange={handleChange}
          disabled={user.isViewer === true || day === null}
        />
      </Col>
    </Row>
  );
}
