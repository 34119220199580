import { CogniteClient, Timeseries } from "@cognite/sdk";
import {
  formatSubBuildingFromExternalId,
  formatTimeseriesName,
  getSystemCodeFromExternalId,
} from "@properate/common";
import { Col, Radio, Row, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { getBuildingAssets } from "@/utils/helpers";

type Props = {
  onSelectMeter: (change: number) => void;
  selectedMeter: number[];
  selectedOwnerId: number;
  onSelectOwnerId: (selectedOwnerId: number) => void;
  showExternalId: boolean;
};
export const SelectEnergyCorrected = ({
  onSelectMeter,
  selectedMeter,
  showExternalId,
  selectedOwnerId,
  onSelectOwnerId,
}: Props) => {
  const t = useTranslations();

  const { client } = useCogniteClient();
  const building = useCurrentBuilding();

  const [ownerTenants, setOwnerTenants] =
    useState<{ name: string; id: number }[]>();
  const [calculatedTimeSeries, setCalculatedTimeSeries] = useState<
    Timeseries[]
  >([]);
  const [subBuilding, setSubBuilding] = useState("all");

  useEffect(() => {
    const load = async (client: CogniteClient) => {
      const mainPowerMeterAssetsForOwnerAndTenants = (
        await getBuildingAssets({
          client,
          id: building.id,
          labels: ["oe_common_main_meter"],
        })
      ).sort((a, b) =>
        getSystemCodeFromExternalId(a.externalId!).localeCompare(
          getSystemCodeFromExternalId(b.externalId!),
        ),
      );

      setOwnerTenants(
        mainPowerMeterAssetsForOwnerAndTenants.map((asset, index) => ({
          name: t("energy.owner-name", {
            name: asset.metadata?.organization || asset.description,
            index,
          }),
          id: asset.id,
        })),
      );
    };

    if (client) {
      load(client);
    }
  }, [client, building.id, t]);

  useEffect(() => {
    const load = async (client: CogniteClient) => {
      // under the asset for the total consumption for the owner or tenant can have two timeseries for temperature
      // corrected timeseries, one for calculated based on the trendline and one based on a backend algorithm
      const temperatureCorrectedAssets = await client.assets
        .list({
          filter: {
            parentIds: [selectedOwnerId],
            labels: {
              containsAll: [{ externalId: "oe_temperature_corrected" }],
            },
          },
        })
        .autoPagingToArray({ limit: Infinity });

      const timeseriesList = await client.timeseries
        .list({
          filter: {
            assetIds: temperatureCorrectedAssets.map((asset) => asset.id),
          },
        })
        .autoPagingToArray({ limit: Infinity });

      setCalculatedTimeSeries(
        timeseriesList.sort((a, b) =>
          formatTimeseriesName(a).localeCompare(formatTimeseriesName(b)),
        ),
      );
    };
    load(client);
  }, [client, building.id, selectedOwnerId]);

  const subBuildings = useMemo(
    () =>
      [
        ...calculatedTimeSeries.reduce((prev, ts) => {
          prev.add(formatSubBuildingFromExternalId(ts.externalId!)!);
          return prev;
        }, new Set<string>()),
      ].sort((a, b) => a.localeCompare(b)),
    [calculatedTimeSeries],
  );

  return (
    <>
      {ownerTenants && (
        <Select
          style={{ width: 300 }}
          value={selectedOwnerId}
          options={ownerTenants.map((tenant) => ({
            label: tenant.name,
            value: tenant.id,
          }))}
          onChange={(value) => {
            onSelectOwnerId(value);
          }}
        />
      )}
      {subBuildings.length > 1 && (
        <Space>
          <b>{t("energy.sub-buildings")}:</b>
          <Radio.Group
            onChange={(event) => setSubBuilding(event.target.value)}
            value={subBuilding}
          >
            <Radio value="all" key="all">
              {t("energy.show-all")}
            </Radio>
            {subBuildings.map((subBuilding) => (
              <Radio value={subBuilding} key={subBuilding}>
                {subBuilding}
              </Radio>
            ))}
          </Radio.Group>
        </Space>
      )}
      {calculatedTimeSeries && (
        <div style={{ marginBottom: 16, width: "100%", marginTop: 8 }}>
          <Radio.Group
            onChange={(event) => onSelectMeter(event.target.value)}
            value={selectedMeter?.[0]}
            style={{ width: "100%" }}
          >
            {" "}
            <Row>
              {calculatedTimeSeries.map((ts) => (
                <Col span={8} key={ts.id}>
                  <Radio value={ts.id}>
                    {getSystemCodeFromExternalId(ts.externalId!)}
                    {", "}
                    {formatSubBuildingFromExternalId(ts.externalId!)} <br />
                    {ts?.description} <br /> {ts?.name}
                    {showExternalId && (
                      <div>
                        <small>{ts.externalId}</small>
                      </div>
                    )}
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </div>
      )}
    </>
  );
};
