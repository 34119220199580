import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { SchemaKpi } from "@/pages/common/SchemaKpis/SchemaKpi";

interface SortableKPIListProps {
  sensors: any[];
  onChangeTimeseriesInfo: (sensor: any, ts: any) => unknown;
  onDeleteTimeseries: (sensor: any) => unknown;
  onlyAlarm?: boolean;
}

export const SortableKPIList = SortableContainer<SortableKPIListProps>(
  ({
    sensors,
    onChangeTimeseriesInfo,
    onDeleteTimeseries,
    onlyAlarm = false,
  }: SortableKPIListProps) => (
    <div>
      {sensors.map((sensor, index) => (
        <SortableKPI
          key={`${sensor.id}-${sensor.sensor.id}`}
          sensor={sensor}
          index={index}
          onChangeTimeseriesInfo={(ts) => onChangeTimeseriesInfo(sensor, ts)}
          onDeleteTimeseries={() => onDeleteTimeseries(sensor)}
          onlyAlarm={onlyAlarm}
        />
      ))}
    </div>
  ),
);

interface SortableKPIElementProps {
  sensor: any;
  onChangeTimeseriesInfo: (ts: any) => unknown;
  onDeleteTimeseries: () => unknown;
  onlyAlarm: boolean;
}

const SortableKPI = SortableElement<SortableKPIElementProps>(
  ({
    sensor,
    onChangeTimeseriesInfo,
    onDeleteTimeseries,
    onlyAlarm,
  }: SortableKPIElementProps) => (
    <SchemaKpi
      {...sensor.sensor}
      onChangeTimeseriesInfo={onChangeTimeseriesInfo}
      onDeleteTimeseries={onDeleteTimeseries}
      small
      showGraph
      onlyAlarm={onlyAlarm}
    />
  ),
);
