import { useUser } from "@properate/auth";
import { ChangeEvent, FunctionComponent, useState } from "react";
import { Modal, Form, Button, Input } from "antd";
import { Link } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { CalculationFlow } from "../types";
import { BuildingSelect } from "./BuildingSelect";

interface Props {
  open: boolean;
  calculationFlow: CalculationFlow;
  onOk: (name: string) => void;
  onClose: () => void;
}

export const CopyModal: FunctionComponent<Props> = ({
  open,
  calculationFlow,
  onClose,
  onOk,
}) => {
  const t = useTranslations();
  const currentBuildingId = useCurrentBuildingId();
  const user = useUser();

  const [buildingId, setBuildingId] = useState(currentBuildingId);
  const [name, setName] = useState(
    t("calculation-flow.copy-of", {
      name: calculationFlow.name,
    }),
  );

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);
  const handleClick = () => onOk(name);

  const copyUrl =
    calculationFlow.id !== null
      ? `/asset/${buildingId}/${
          calculationFlow.type
        }/copy?id=${encodeURIComponent(
          calculationFlow.id,
        )}&name=${encodeURIComponent(name)}`
      : null;

  return (
    <Modal
      title={t("calculation-flow.copy-flow", {
        flowName: calculationFlow.type,
      })}
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} className="mr-2">
          {t("calculation-flow.cancel")}
        </Button>,
        <Link
          key="copyLink"
          to={copyUrl !== null ? copyUrl : ""}
          target="_blank"
          onClick={handleClick}
        >
          {t("calculation-flow.make-a-copy")}
        </Link>,
      ]}
    >
      <Form layout="vertical" disabled={user.isViewer}>
        <Form.Item label={t("calculation-flow.name")}>
          <Input value={name} onChange={handleNameChange} />
        </Form.Item>
        <BuildingSelect buildingId={buildingId} onChange={setBuildingId} />
      </Form>
    </Modal>
  );
};
