import Color from "color";
import { normal } from "color-blend";

export function blendColors(...colors: string[]) {
  return colors.reduce((blendedColor, color) => {
    if (!blendedColor) {
      return color;
    }

    const { a, ...newColor } = normal(
      {
        ...(Color(color).object() as { r: number; g: number; b: number }),
        a: 0.5,
      },
      {
        ...(Color(blendedColor).object() as {
          r: number;
          g: number;
          b: number;
        }),
        a: 0.5,
      },
    );
    return Color(newColor).hex();
  }, "");
}
