import { IncidentClientSide } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { TableProps } from "antd/es/table/InternalTable";
import { useMemo } from "react";
import {
  IncidentFilter,
  OrgIncidentsLoader,
  useIncidentColumns,
  IncidentAutocompleteSearch,
} from "@/features/alarms";
import FullLayout from "@/layout/FullLayout";
import usePageTitle from "@/hooks/usePageTitle";
import { IncidentTableFilters } from "@/pages/alarmSystem/incidents/IncidentTableFilters";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { Sort } from "@/pages/fileType/types";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useRootAssets } from "@/hooks/useRootAssets";
import { getUrl, useIncidentsData } from "./utils";
import type { SorterResult } from "antd/es/table/interface";

function OrgIncidentsPage() {
  const t = useTranslations();
  usePageTitle(t("org-incidents.title"));
  const pageData = useLoaderData() as OrgIncidentsLoader;
  const navigate = useNavigate();
  const rootAssets = useRootAssets();
  const { incidents, onLoadMore, isLoading } = useIncidentsData();

  const doSort = (sort: Sort) => {
    navigate(
      getUrl(
        pageData.search,
        {
          property: sort.property,
          order: sort.order,
        },
        pageData.filters,
      ),
    );
  };

  const doFilter = (filters: IncidentFilter[]) => {
    navigate(getUrl(pageData.search, pageData.sort, filters));
  };

  const onClick = (id: string, building_external_id: string) => {
    const rootId = rootAssets.filter(
      (asset) => asset.externalId === building_external_id,
    )?.[0]?.id;
    if (!rootId) return;
    navigate(`/asset/${rootId}/incidents/${id}`);
  };

  const onChangeTable = (
    _: any,
    __: any,
    sorter: SorterResult<IncidentClientSide>,
  ) => {
    if (!sorter.columnKey || !sorter.order) return;

    doSort({
      property: sorter.columnKey as string,
      order: sorter.order === "ascend" ? "asc" : "desc",
    });
  };

  const baseIncidentColumns = useIncidentColumns();

  const columns = useMemo(() => {
    const newColumns = [...baseIncidentColumns];
    newColumns.splice(0, 0, {
      title: t("incidents.table.address"),
      key: "building_address",
      render: ({ building_address, building_id }) => (
        <Link
          to={`/asset/${building_id}/building`}
          onClick={(event) => event.stopPropagation()}
        >
          {building_address}
        </Link>
      ),
    });
    return newColumns;
  }, [baseIncidentColumns, t]);

  const { height: windowHeight } = useWindowSize();

  return (
    <FullLayout
      pageName={t("org-incidents.title")}
      headerRight={[
        <IncidentAutocompleteSearch
          key="search"
          defaultSearch={pageData.search}
          onSearchChange={(search) => {
            navigate(getUrl(search, pageData.sort, pageData.filters));
          }}
        />,
        <IncidentTableFilters
          key="filters"
          onChangeFilters={doFilter}
          activeFilters={pageData.filters}
        />,
      ]}
      hideSearchInput
      showNotesButton
    >
      {windowHeight && (
        <TableInfiniteScroll<IncidentClientSide>
          columns={columns}
          dataSource={incidents}
          height={windowHeight - 64 - 30}
          onReachedEndOfPage={onLoadMore}
          rowKey="id"
          loading={isLoading || !windowHeight}
          onRow={(record) => ({
            onClick: () => onClick(record.id!, record.building_external_id),
          })}
          onChange={onChangeTable as TableProps<IncidentClientSide>["onChange"]}
        />
      )}
    </FullLayout>
  );
}

export default OrgIncidentsPage;
