import clearsky_day from "./icons/clearsky_day.svg";
import clearsky_night from "./icons/clearsky_night.svg";
import clearsky_polartwilight from "./icons/clearsky_polartwilight.svg";
import cloudy from "./icons/cloudy.svg";
import fair_day from "./icons/fair_day.svg";
import fair_night from "./icons/fair_night.svg";
import fair_polartwilight from "./icons/fair_polartwilight.svg";
import fog from "./icons/fog.svg";
import heavyrain from "./icons/heavyrain.svg";
import heavyrainandthunder from "./icons/heavyrainandthunder.svg";
import heavyrainshowers_day from "./icons/heavyrainshowers_day.svg";
import heavyrainshowers_night from "./icons/heavyrainshowers_night.svg";
import heavyrainshowers_polartwilight from "./icons/heavyrainshowers_polartwilight.svg";
import heavyrainshowersandthunder_day from "./icons/heavyrainshowersandthunder_day.svg";
import heavyrainshowersandthunder_night from "./icons/heavyrainshowersandthunder_night.svg";
import heavyrainshowersandthunder_polartwilight from "./icons/heavyrainshowersandthunder_polartwilight.svg";
import heavysleet from "./icons/heavysleet.svg";
import heavysleetandthunder from "./icons/heavysleetandthunder.svg";
import heavysleetshowers_day from "./icons/heavysleetshowers_day.svg";
import heavysleetshowers_night from "./icons/heavysleetshowers_night.svg";
import heavysleetshowers_polartwilight from "./icons/heavysleetshowers_polartwilight.svg";
import heavysleetshowersandthunder_day from "./icons/heavysleetshowersandthunder_day.svg";
import heavysleetshowersandthunder_night from "./icons/heavysleetshowersandthunder_night.svg";
import heavysleetshowersandthunder_polartwilight from "./icons/heavysleetshowersandthunder_polartwilight.svg";
import heavysnow from "./icons/heavysnow.svg";
import heavysnowandthunder from "./icons/heavysnowandthunder.svg";
import heavysnowshowers_day from "./icons/heavysnowshowers_day.svg";
import heavysnowshowers_night from "./icons/heavysnowshowers_night.svg";
import heavysnowshowers_polartwilight from "./icons/heavysnowshowers_polartwilight.svg";
import heavysnowshowersandthunder_day from "./icons/heavysnowshowersandthunder_day.svg";
import heavysnowshowersandthunder_night from "./icons/heavysnowshowersandthunder_night.svg";
import heavysnowshowersandthunder_polartwilight from "./icons/heavysnowshowersandthunder_polartwilight.svg";
import lightrain from "./icons/lightrain.svg";
import lightrainandthunder from "./icons/lightrainandthunder.svg";
import lightrainshowers_day from "./icons/lightrainshowers_day.svg";
import lightrainshowers_night from "./icons/lightrainshowers_night.svg";
import lightrainshowers_polartwilight from "./icons/lightrainshowers_polartwilight.svg";
import lightrainshowersandthunder_day from "./icons/lightrainshowersandthunder_day.svg";
import lightrainshowersandthunder_night from "./icons/lightrainshowersandthunder_night.svg";
import lightrainshowersandthunder_polartwilight from "./icons/lightrainshowersandthunder_polartwilight.svg";
import lightsleet from "./icons/lightsleet.svg";
import lightsleetandthunder from "./icons/lightsleetandthunder.svg";
import lightsleetshowers_day from "./icons/lightsleetshowers_day.svg";
import lightsleetshowers_night from "./icons/lightsleetshowers_night.svg";
import lightsleetshowers_polartwilight from "./icons/lightsleetshowers_polartwilight.svg";
import lightsnow from "./icons/lightsnow.svg";
import lightsnowandthunder from "./icons/lightsnowandthunder.svg";
import lightsnowshowers_day from "./icons/lightsnowshowers_day.svg";
import lightsnowshowers_night from "./icons/lightsnowshowers_night.svg";
import lightsnowshowers_polartwilight from "./icons/lightsnowshowers_polartwilight.svg";
import lightssleetshowersandthunder_day from "./icons/lightssleetshowersandthunder_day.svg";
import lightssleetshowersandthunder_night from "./icons/lightssleetshowersandthunder_night.svg";
import lightssleetshowersandthunder_polartwilight from "./icons/lightssleetshowersandthunder_polartwilight.svg";
import lightssnowshowersandthunder_day from "./icons/lightssnowshowersandthunder_day.svg";
import lightssnowshowersandthunder_night from "./icons/lightssnowshowersandthunder_night.svg";
import lightssnowshowersandthunder_polartwilight from "./icons/lightssnowshowersandthunder_polartwilight.svg";
import partlycloudy_day from "./icons/partlycloudy_day.svg";
import partlycloudy_night from "./icons/partlycloudy_night.svg";
import partlycloudy_polartwilight from "./icons/partlycloudy_polartwilight.svg";
import rain from "./icons/rain.svg";
import rainandthunder from "./icons/rainandthunder.svg";
import rainshowers_day from "./icons/rainshowers_day.svg";
import rainshowers_night from "./icons/rainshowers_night.svg";
import rainshowers_polartwilight from "./icons/rainshowers_polartwilight.svg";
import rainshowersandthunder_day from "./icons/rainshowersandthunder_day.svg";
import rainshowersandthunder_night from "./icons/rainshowersandthunder_night.svg";
import rainshowersandthunder_polartwilight from "./icons/rainshowersandthunder_polartwilight.svg";
import sleet from "./icons/sleet.svg";
import sleetandthunder from "./icons/sleetandthunder.svg";
import sleetshowers_day from "./icons/sleetshowers_day.svg";
import sleetshowers_night from "./icons/sleetshowers_night.svg";
import sleetshowers_polartwilight from "./icons/sleetshowers_polartwilight.svg";
import sleetshowersandthunder_day from "./icons/sleetshowersandthunder_day.svg";
import sleetshowersandthunder_night from "./icons/sleetshowersandthunder_night.svg";
import sleetshowersandthunder_polartwilight from "./icons/sleetshowersandthunder_polartwilight.svg";
import snow from "./icons/snow.svg";
import snowandthunder from "./icons/snowandthunder.svg";
import snowshowers_day from "./icons/snowshowers_day.svg";
import snowshowers_night from "./icons/snowshowers_night.svg";
import snowshowers_polartwilight from "./icons/snowshowers_polartwilight.svg";
import snowshowersandthunder_day from "./icons/snowshowersandthunder_day.svg";
import snowshowersandthunder_night from "./icons/snowshowersandthunder_night.svg";
import snowshowersandthunder_polartwilight from "./icons/snowshowersandthunder_polartwilight.svg";

export const WEATHER_SYMBOLS: Record<string, string> = {
  clearsky_day,
  clearsky_night,
  clearsky_polartwilight,
  cloudy,
  fair_day,
  fair_night,
  fair_polartwilight,
  fog,
  heavyrain,
  heavyrainandthunder,
  heavyrainshowers_day,
  heavyrainshowers_night,
  heavyrainshowers_polartwilight,
  heavyrainshowersandthunder_day,
  heavyrainshowersandthunder_night,
  heavyrainshowersandthunder_polartwilight,
  heavysleet,
  heavysleetandthunder,
  heavysleetshowers_day,
  heavysleetshowers_night,
  heavysleetshowers_polartwilight,
  heavysleetshowersandthunder_day,
  heavysleetshowersandthunder_night,
  heavysleetshowersandthunder_polartwilight,
  heavysnow,
  heavysnowandthunder,
  heavysnowshowers_day,
  heavysnowshowers_night,
  heavysnowshowers_polartwilight,
  heavysnowshowersandthunder_day,
  heavysnowshowersandthunder_night,
  heavysnowshowersandthunder_polartwilight,
  lightrain,
  lightrainandthunder,
  lightrainshowers_day,
  lightrainshowers_night,
  lightrainshowers_polartwilight,
  lightrainshowersandthunder_day,
  lightrainshowersandthunder_night,
  lightrainshowersandthunder_polartwilight,
  lightsleet,
  lightsleetandthunder,
  lightsleetshowers_day,
  lightsleetshowers_night,
  lightsleetshowers_polartwilight,
  lightsnow,
  lightsnowandthunder,
  lightsnowshowers_day,
  lightsnowshowers_night,
  lightsnowshowers_polartwilight,
  lightssleetshowersandthunder_day,
  lightssleetshowersandthunder_night,
  lightssleetshowersandthunder_polartwilight,
  lightssnowshowersandthunder_day,
  lightssnowshowersandthunder_night,
  lightssnowshowersandthunder_polartwilight,
  partlycloudy_day,
  partlycloudy_night,
  partlycloudy_polartwilight,
  rain,
  rainandthunder,
  rainshowers_day,
  rainshowers_night,
  rainshowers_polartwilight,
  rainshowersandthunder_day,
  rainshowersandthunder_night,
  rainshowersandthunder_polartwilight,
  sleet,
  sleetandthunder,
  sleetshowers_day,
  sleetshowers_night,
  sleetshowers_polartwilight,
  sleetshowersandthunder_day,
  sleetshowersandthunder_night,
  sleetshowersandthunder_polartwilight,
  snow,
  snowandthunder,
  snowshowers_day,
  snowshowers_night,
  snowshowers_polartwilight,
  snowshowersandthunder_day,
  snowshowersandthunder_night,
  snowshowersandthunder_polartwilight,
};
