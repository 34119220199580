import useSWR from "swr";
import { useEffect, useMemo, useState } from "react";
import {
  getComponentFromExternalId,
  getSystemCodeFromExternalId,
} from "@properate/common";
import { Datapoints, Asset } from "@cognite/sdk";
import { ComponentSearchable } from "@properate/api/src/types";
import { App } from "antd";
import { removeVideo } from "@properate/firebase/functions";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { getAssets, getTimeseriesForAssets } from "@/utils/helpers";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useProperateCogniteClient } from "@/context/ProperateCogniteClientContext";
import { mapSchemaValue } from "@/pages/common/utils";
import {
  formatMeasurementForSchema,
  isValid,
} from "@/pages/common/SchemaView/TechnicalSchema/utils";
import { useHandleApiError, isErrorWithMessage } from "@/utils/api";
import { TranslationFunction } from "@/utils/types";

type TimeseriesValue = {
  title: string;
  description?: string;
  value: string;
  id: number;
};

export const useTimeseriesWithLatestValue = (assetIds: number[]) => {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const {
    isLoading: isLoadingTimeseries,
    data: timeseries,
    error,
  } = useSWR({ assetIds }, ({ assetIds }) =>
    getTimeseriesForAssets(client, assetIds),
  );
  const currentBuildingId = useCurrentBuildingId();
  const { overrideUnits } = useTimeseriesSettings(currentBuildingId);

  const [data, setData] = useState<TimeseriesValue[]>([]);

  const [isLoadingDataPoints, setIsLoadingDataPoints] = useState(false);
  const properateCogniteClient = useProperateCogniteClient();

  useEffect(() => {
    if (timeseries?.length) {
      const timeseriesIds = timeseries.map((item) => item.id);
      setIsLoadingDataPoints(true);
      const getData = async () => {
        const res =
          await properateCogniteClient.getLatestValueMany(timeseriesIds);
        const valuesByIds = res.reduce(
          (acc, item) => {
            acc[item.id] = item;
            return acc;
          },
          {} as Record<number, Datapoints>,
        );
        setData(
          timeseries
            .reduce((acc, item) => {
              const datapoint = valuesByIds[item.id];
              const mappedValue = mapSchemaValue(
                valuesByIds[item.id],
                overrideUnits,
                item,
              );

              const value =
                (mappedValue &&
                  isValid(datapoint.datapoints[0]?.timestamp) &&
                  (mappedValue.textValue
                    ? mappedValue.textValue
                    : formatMeasurementForSchema(mappedValue))) ||
                "";

              const newItem = {
                value,
                title: item.description,
                description: getComponentFromExternalId(item.externalId || ""),
                id: item.id,
              };

              if (
                !acc.some(
                  (accItem) =>
                    accItem.title === newItem.title &&
                    accItem.description === newItem.description &&
                    accItem.value === newItem.value,
                )
              ) {
                acc.push(newItem);
              }

              return acc;
            }, [] as TimeseriesValue[])
            .sort((a, b) =>
              (a.description + a.title).localeCompare(b.description + b.title),
            ),
        );
        setIsLoadingDataPoints(false);
      };
      getData();
    }
    setData([]);
  }, [overrideUnits, properateCogniteClient, timeseries]);

  if (error) {
    handleAPIError(error);
  }

  return useMemo(
    () => ({
      timeSeries: data,
      isLoading: isLoadingTimeseries || isLoadingDataPoints,
    }),
    [data, isLoadingDataPoints, isLoadingTimeseries],
  );
};

export const useVisibleSubComponents = (assetId: number) => {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const { isLoading, data, error } = useSWR({ assetId }, ({ assetId }) =>
    getAssets({ client, parentId: assetId }),
  );

  if (error) {
    handleAPIError(error);
  }

  return useMemo(
    () => ({
      subComponents: data?.filter(
        (item) =>
          !item.labels?.some(
            (label) =>
              label.externalId === "set_point" || label.externalId === "hidden",
          ),
      ),
      isLoading,
    }),
    [data, isLoading],
  );
};

const getComponentFullName = (a: Asset) =>
  `${getComponentFromExternalId(a.externalId || "")}-${
    a.description || ""
  }-${getSystemCodeFromExternalId(a.externalId || "")}`;

export const useSubComponentsAndTimeseriesWithLatestValues = (
  assetId: number,
) => {
  const { isLoading, subComponents } = useVisibleSubComponents(assetId);
  const assetIds = subComponents?.map((component) => component.id) || [];
  const { isLoading: isLoadingTimeseries, timeSeries } =
    useTimeseriesWithLatestValue([...assetIds, assetId]);

  const uniqSubComponents = useMemo(() => {
    const componentsWithTimeseries = [
      ...new Set(
        timeSeries.map((timeseriesItem) => timeseriesItem.description),
      ),
    ];
    return subComponents
      ?.filter((component, index, selfArray) => {
        return (
          !componentsWithTimeseries.includes(
            getComponentFromExternalId(component.externalId || ""),
          ) &&
          index ===
            selfArray.findIndex(
              (y) =>
                getComponentFullName(component) === getComponentFullName(y),
            )
        );
      })
      .sort((a, b) =>
        getComponentFullName(a).localeCompare(getComponentFullName(b)),
      );
  }, [subComponents, timeSeries]);

  return useMemo(
    () => ({
      subComponents: uniqSubComponents,
      timeSeries,
      isLoading: isLoading || isLoadingTimeseries,
    }),
    [isLoading, isLoadingTimeseries, timeSeries, uniqSubComponents],
  );
};

export const getComponentTimeseriesTitle = (
  timeseriesValue: TimeseriesValue,
  component?: string,
) => {
  const componentName =
    component === timeseriesValue.description
      ? ""
      : timeseriesValue.description;
  const description = timeseriesValue.title;
  return componentName && description
    ? `${componentName}: ${description}`
    : componentName || description;
};

export const getComponentTitle = (
  component: Pick<ComponentSearchable, "description" | "component" | "system">,
  t: TranslationFunction,
) => {
  if (component.system && component.component && component.description) {
    return `${component.system} ${component.description} (${component.component})`;
  }

  if (component.system && component.component) {
    return `${component.system} ${component.component}`;
  }

  if (component.system) {
    return `${component.system} ${component.description ?? ""}`;
  }

  return t("components.details.new-component");
};

export const useHandleDeleteComponentFile = (componentId: string) => {
  const { client } = useCogniteClient();
  const { message } = App.useApp();
  const t = useTranslations();

  return async (file: { mimeType?: string; uid: string }) => {
    try {
      if (
        "mimeType" in file &&
        typeof file.mimeType === "string" &&
        file.mimeType?.startsWith("video/")
      ) {
        await removeVideo({
          folder: "components",
          itemId: componentId,
          fileId: "externalId" in file ? file.externalId?.toString() || "" : "",
          cogniteFileId: Number(file.uid),
        });
      } else {
        await client.files.delete([{ id: Number(file.uid) }]);
      }
      return true;
    } catch (error) {
      console.error(error);
      message.error({
        content: t("components.details.failed-to-delete-file", {
          error: isErrorWithMessage(error) ? error.message : (error as string),
        }),
        duration: 7,
      });
      return false;
    }
  };
};
