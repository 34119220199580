import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";

type Props = {
  search: string | string[];
  text: string;
};

export function SearchHighlighter(props: Props) {
  if (!props.text) {
    return <div />;
  }

  return (
    <ProperateHighlighter
      searchWords={
        typeof props.search === "string"
          ? props.search.split(" ")
          : props.search
      }
      autoEscape
      textToHighlight={props.text}
    />
  );
}
