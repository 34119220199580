import { BuildingTenantWithId } from "@properate/common";
import { useGetBuildingSpec } from "@/features/buildings";

export function useGetBuildingTenants(buildingId?: string | number) {
  const spec = useGetBuildingSpec(buildingId);
  const data: BuildingTenantWithId[] = spec.data?.tenants ?? [];

  return {
    data,
    isLoading: spec.isLoading,
    error: spec.error,
  };
}
