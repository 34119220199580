import { Select } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { AnalysisPresetTimeSpan } from "@/utils/types";

const options: Array<{
  labelKey: MessageKey;
  value: AnalysisPresetTimeSpan;
}> = [
  {
    labelKey: "common.timespan-options.last-hour",
    value: "lastHour",
  },
  {
    labelKey: "common.timespan-options.last-3-hours",
    value: "last3Hours",
  },
  {
    labelKey: "common.timespan-options.last-12-hours",
    value: "last12Hours",
  },
  {
    labelKey: "common.timespan-options.today",
    value: "today",
  },
  {
    labelKey: "common.timespan-options.last-24-hours",
    value: "last24Hours",
  },
  {
    labelKey: "common.timespan-options.last-7-days",
    value: "last7Days",
  },
  {
    labelKey: "common.timespan-options.last-week",
    value: "lastWeek",
  },
  {
    labelKey: "common.timespan-options.last-2-weeks",
    value: "last14Days",
  },
  {
    labelKey: "common.timespan-options.last-30-days",
    value: "last30Days",
  },
  {
    labelKey: "common.timespan-options.last-90-days",
    value: "last90Days",
  },
  {
    labelKey: "common.timespan-options.last-180-days",
    value: "last180Days",
  },
  {
    labelKey: "common.timespan-options.last-365-days",
    value: "last365Days",
  },
  {
    labelKey: "common.timespan-options.whole-period",
    value: "complete",
  },
];

interface Props {
  value?: AnalysisPresetTimeSpan;
  onChange: (value: AnalysisPresetTimeSpan) => unknown;
}

export function PresetTimeSpanSelect({ value, onChange }: Props) {
  const t = useTranslations();

  return (
    <Select
      aria-label={t("dashboard.widgets.analysis.period-to-show")}
      value={value}
      onChange={onChange}
      options={options.map(({ labelKey, value }) => ({
        label: t(labelKey),
        value,
      }))}
      style={{ minWidth: 150 }}
    />
  );
}
