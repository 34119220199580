import styled from "styled-components";
import { Radio } from "antd";

export const FilterForm = styled.form`
  display: flex;
  gap: 8px;
  padding-inline: 11px;
`;

export const FilterLabel = styled.label`
  font-weight: bold;
`;

export const FilterFormItem = styled.div<{ $flexGrow?: number }>`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: start;
`;

export const RadioGroupVertical = styled(Radio.Group)`
  display: grid;
  gap: 4px;
  max-height: 200px;
  grid-auto-flow: column;
  grid-template-rows: repeat(8, auto);
`;
