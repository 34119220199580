import styled from "styled-components";
import { Card } from "antd";

export const DraggableCard = styled(Card).attrs({ bordered: false })`
  cursor: default;
  box-sizing: border-box;
  border: 1px solid transparent;
  &:hover,
  .moving & {
    border: 1px solid ${(props) => props.theme.primary};
  }
`;
