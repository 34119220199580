import dayjs from "@properate/dayjs";
import axios from "axios";
import { Node } from "reactflow";
import { ExternalId } from "@cognite/sdk";
import { hasErrorMessage } from "@/utils/axios";
import { NotificationInstance } from "@/utils/types";
import { CalculationFlow, ScheduleItem } from "../types";

export const move_to_start = (array: string[], item: string) => {
  // Move an item in an array to first index position
  const timestamp_idx = array.indexOf(item);
  return [
    array[timestamp_idx],
    ...array.slice(0, timestamp_idx),
    ...array.slice(timestamp_idx + 1),
  ];
};

export const safeRun = async (
  operation: () => Promise<any>,
  errorMessage: string,
  notificationInstance: NotificationInstance,
) => {
  try {
    await operation();
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = errorMessage;
      if (hasErrorMessage(error)) {
        return notificationInstance.error({
          message,
          description: error.response.data.message,
        });
      }
      return notificationInstance.error({
        message,
      });
    }
    console.error(error);
  }
};

export const toSimplifiedCalculationFlow = (
  calculationFlow: CalculationFlow,
) => {
  return {
    ...calculationFlow,
    frequency: Math.abs(calculationFlow.frequency),
    calculation_flow: {
      nodes: calculationFlow.calculation_flow.nodes.map((node) => ({
        data: node.data,
        id: node.id,
      })),
      edges: calculationFlow.calculation_flow.edges,
    },
  };
};

export const parseCalculationFlowDates = (
  calculationFlow: CalculationFlow,
): CalculationFlow => {
  return {
    ...calculationFlow,
    plan: calculationFlow.plan
      ? calculationFlow.plan.map((planItem) => ({
          ...planItem,
          timeRange: [
            dayjs(planItem.timeRange[0]),
            dayjs(planItem.timeRange[1]),
          ],
        }))
      : undefined,
    exceptions: calculationFlow.exceptions
      ? calculationFlow.exceptions.map((planItem) => ({
          ...planItem,
          timeRange: [
            dayjs(planItem.timeRange[0]),
            dayjs(planItem.timeRange[1]),
          ],
        }))
      : undefined,
    calculation_flow: {
      ...calculationFlow.calculation_flow,
      nodes: calculationFlow.calculation_flow.nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          ...("node_plan" in node.data
            ? {
                node_plan: node.data.node_plan.map(
                  (scheduleItem: ScheduleItem) => ({
                    ...scheduleItem,
                    timeRange: [
                      dayjs(scheduleItem.timeRange[0]),
                      dayjs(scheduleItem.timeRange[1]),
                    ],
                  }),
                ),
              }
            : null),
        },
      })),
    },
  };
};

export const formatTableDate = (date: Date) => {
  // The index with maximum possible date in Pandas is (ab-)used as the summary row index
  if (date > new Date(2200, 1)) return "Total";
  return dayjs(date).format("YYYY-MM-DD hh:mm:ss");
};

export function getOutputNodes(nodes: Node[]) {
  return nodes.filter((node) => node.data.operationId.startsWith("write_"));
}

export function getOutputExternalIds(
  calculationFlow: CalculationFlow,
): string[] {
  const outputNodes = getOutputNodes(calculationFlow.calculation_flow.nodes);
  return outputNodes
    .map((node) =>
      node.data.externalIds
        ? node.data.externalIds.map(
            (externalId: ExternalId) => externalId.externalId,
          )
        : node.data.externalId?.externalId,
    )
    .flat()
    .filter((externalId) => !!externalId) as string[];
}
