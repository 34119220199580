import styled from "styled-components";
import { MaximizableModal } from "@/components/MaximizableModal/MaximizableModal";

export const WhiteModal = styled(MaximizableModal)`
  .ant-modal-content,
  .ant-modal-header,
  .ant-modal-footer {
    background-color: ${(props) => props.theme.background2} !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FilterToggleContainer = styled.div`
  display: flex;
  gap: 8px;
`;
