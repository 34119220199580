import { BuildingContact, BuildingContactWithId } from "@properate/common";
import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useRef, useState } from "react";
import { useTranslations, MessageKey } from "@properate/translations";
import { useAddBuildingContact } from "../hooks/useAddBuildingContact";
import { useUpdateBuildingContact } from "../hooks/useUpdateBuildingContact";
import { useRemoveBuildingContact } from "../hooks/useRemoveBuildingContact";
import { contactTypeInputOptions as options } from "./utils/contactTypeInputOptions";
import type { BaseSelectRef } from "rc-select";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  buildingId: number;
  contacts: BuildingContactWithId[];
  contact: BuildingContactWithId | null;
};

export function BuildingContactModal(props: Props) {
  const t = useTranslations();

  const addContact = useAddBuildingContact();
  const updateContact = useUpdateBuildingContact();
  const removeContact = useRemoveBuildingContact();
  const [keepCreating, setKeepCreating] = useState(false);
  const typeFieldRef = useRef<BaseSelectRef>(null);
  const [form] = Form.useForm<BuildingContact>();

  useEffect(() => {
    if (props.isVisible) {
      typeFieldRef.current?.focus();
    }
  }, [props.isVisible]);

  function handleSubmit(values: BuildingContact) {
    if (props.contact) {
      updateContact.trigger({
        buildingId: props.buildingId,
        contact: props.contact,
        patch: values,
      });
    } else {
      addContact.trigger({
        buildingId: props.buildingId,
        contact: values,
      });
    }

    form.resetFields();
    typeFieldRef.current?.focus();

    if (!keepCreating) {
      props.onClose();
    }
  }

  function handleRemove() {
    if (props.contact) {
      removeContact.trigger({
        buildingId: props.buildingId,
        contact: props.contact,
      });
      props.onClose();
    }
  }

  return (
    <Modal
      title={
        props.contact
          ? t("settings.contacts.update-contact")
          : t("settings.contacts.add-new-contact")
      }
      open={props.isVisible}
      onCancel={props.onClose}
      destroyOnClose
      footer={[
        !props.contact && (
          <Checkbox
            key="keep-creating"
            onChange={() => setKeepCreating(!keepCreating)}
            checked={keepCreating}
          >
            {t("settings.contacts.add-many")}
          </Checkbox>
        ),
        <Button key="cancel" onClick={props.onClose}>
          {t("settings.contacts.cancel")}
        </Button>,
        props.contact && (
          <Button key="delete" danger onClick={handleRemove}>
            {t("settings.contacts.remove")}
          </Button>
        ),
        <Button key="submit" type="primary" htmlType="submit" form="modal-form">
          {t("settings.contacts.add")}
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        id="modal-form"
        initialValues={props.contact || { type: "owner" }}
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("settings.contacts.fields.contact-type")}
          name="type"
          rules={[
            { required: true },
            {
              validator(_, value) {
                if (!value || props.contact?.type === value) {
                  return Promise.resolve();
                }

                const similarExistingContacts = props.contacts.filter(
                  (contact) => contact.type === value,
                ).length;
                const max = options.find((o) => o.value === value)?.max ?? 1;

                if (similarExistingContacts < max) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error(
                    t("settings.contacts.fields.type-error", {
                      max,
                    }),
                  ),
                );
              },
            },
          ]}
        >
          <Select
            options={options.map((item) => ({
              ...item,
              label: t(`settings.contacts.types.${item.value}` as MessageKey),
            }))}
            autoFocus
            ref={typeFieldRef}
          />
        </Form.Item>
        <Form.Item
          label={t("settings.contacts.fields.name")}
          name="name"
          rules={[{ required: true, min: 3, max: 100 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("settings.contacts.fields.email")}
          name="email"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input type="mail" />
        </Form.Item>
        <Form.Item
          label={t("settings.contacts.fields.phone")}
          name="phone"
          rules={[
            {
              required: true,
            },
          ]}
          normalize={(value) => {
            try {
              return parsePhoneNumber(value, "NO").formatInternational();
            } catch {
              // The errors at this point are regarding the input not being a number
              // that we can format as an international phone number; that's always
              // the case when we start typing.
              return value;
            }
          }}
        >
          <Input type="phone" />
        </Form.Item>
        <Form.Item label={t("settings.contacts.fields.notes")} name="note">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}
