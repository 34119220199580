import { Col, Row, Select } from "antd";
import { useTranslations } from "@properate/translations";
import { Schedule } from "@/utils/types";

type Props = {
  schedule: Schedule;
  setSchedule: (schedule: Schedule) => void;
  holiday?: string;
  hideWeekly?: boolean;
};
export const EditSchedule = ({
  holiday,
  schedule,
  setSchedule,
  hideWeekly,
}: Props) => {
  const t = useTranslations();

  const scheduleOptions = [
    { label: t("calendar.not-repeat"), value: "single" },
    ...(hideWeekly ? [] : [{ label: t("calendar.weekly"), value: "weekly" }]),
    ...(holiday
      ? [
          {
            label: t("calendar.yearly-on-day", { day: holiday }),
            value: "yearly_on_this_holiday",
          },
        ]
      : [{ label: t("calendar.yearly"), value: "yearly" }]),
  ];

  return (
    <Row style={{ marginTop: 8 }}>
      <Col span={22} offset={2}>
        <Select
          options={scheduleOptions}
          value={schedule}
          style={{ width: 200 }}
          onChange={setSchedule}
        />
      </Col>
    </Row>
  );
};
