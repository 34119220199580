import { useUserSettings } from "@/services/userSettings";
import {
  defaultFeatureFlags,
  FeatureAccessFlags,
} from "@/services/featureAccess/types";
import useOrganizationFeatureAccess from "@/services/featureAccess/useOrganizationFeatureAccess";

export default function useUserFeatureAccess(): FeatureAccessFlags {
  const { data: userSettings } = useUserSettings();
  const organizationFeatureAccess = useOrganizationFeatureAccess();

  return {
    ...defaultFeatureFlags,
    ...organizationFeatureAccess,
    ...(userSettings?.featureAccess || {}),
  };
}
