import { useEffect, useState } from "react";
import dayjs from "@properate/dayjs";
import { Asset, CogniteEvent } from "@cognite/sdk";
import { useTranslations } from "@properate/translations";
import { cogniteClient } from "@/services/cognite-client";
import { DraggableCard, WidgetHeader } from "@/features/dashboard";
import { mutateUserSettings } from "@/services/userSettings";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { NotesSettingsModal } from "./NotesSettingsModal";
import { BuildingNotesTable } from "./BuildingNotesTable";

interface Props {
  width: number;
  height: number;
  onClickRemoveButton: () => unknown;
  showLevel?: string;
}
export function NotesWidget(props: Props) {
  const t = useTranslations();
  const [notes, setNotes] = useState<CogniteEvent[] & { assetList?: Asset[] }>(
    [],
  );
  const currentBuilding = useCurrentBuilding();
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  async function fetchAssetList(assetIds: number[]) {
    if (assetIds.length === 0) return [];
    try {
      return await cogniteClient.assets.retrieve(
        assetIds.map((id) => ({ id })),
      );
    } catch (error) {
      console.error("Error fetching assets:", error);
      return [];
    }
  }
  function getFilteredPreferencesNotes(
    events: CogniteEvent[] & { assetList?: Asset[] },
    noteLevel = "error",
  ) {
    if (!events) return [];
    if (noteLevel === "info") {
      return events;
    }
    if (noteLevel === "warning") {
      return events.filter(
        (event) => event.subtype === "warning" || event.subtype === "error",
      );
    }
    return events.filter((event) => event.subtype === "error");
  }
  useEffect(() => {
    const fetchEvents = async () => {
      const startMax = dayjs().add(7, "days");
      const endMin = dayjs().startOf("day");

      const noteLevel = props?.showLevel;

      try {
        const { items: events } = await cogniteClient.events.list({
          filter: {
            startTime: { max: startMax.valueOf() },
            endTime: { min: endMin.valueOf() },
            type: "note",
          },
        });

        const filteredCurrentBuildingEvents = events.filter(
          (event) => event.dataSetId === currentBuilding.dataSetId,
        );

        const filteredUserPreferencesNotes = getFilteredPreferencesNotes(
          filteredCurrentBuildingEvents,
          noteLevel,
        );

        const assetIds = filteredUserPreferencesNotes.flatMap(
          (note) => note.assetIds || [],
        );

        const uniqueAssetIds = [...new Set(assetIds)];

        const assetList = await fetchAssetList(uniqueAssetIds);
        const assetMap = assetList.reduce(
          (acc, asset) => {
            acc[asset.id] = asset;
            return acc;
          },
          {} as Record<number, Asset>,
        );

        const notesWithAssets = filteredUserPreferencesNotes.map((note) => ({
          ...note,
          assetList: (note.assetIds || []).map((id) => assetMap[id]),
        }));

        setNotes(notesWithAssets);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [currentBuilding.dataSetId, props.showLevel]);

  function handleOK({ noteLevel }: { noteLevel: string }) {
    mutateUserSettings({
      buildings: {
        [currentBuilding.id]: {
          notes: {
            showLevel: noteLevel,
          },
        },
      },
    });
    setIsSettingsModalOpen(false);
  }
  return (
    <>
      <DraggableCard
        bordered={false}
        style={{ width: props.width, height: props.height }}
        styles={{ body: { padding: 24 } }}
        title={
          <WidgetHeader
            text={t("dashboard.widgets.notes.title")}
            onClickRemoveButton={props.onClickRemoveButton}
            onClickSettingsButton={() => setIsSettingsModalOpen(true)}
            isDraggable
          />
        }
      >
        <BuildingNotesTable
          notes={notes.sort((a, b) => {
            const subtypeOrder: { [key: string]: number } = {
              error: 0,
              warning: 1,
              info: 2,
              unknown: 3,
            };
            const subtypeA = (a.subtype || "").toLowerCase();
            const subtypeB = (b.subtype || "").toLowerCase();
            return subtypeOrder[subtypeA] - subtypeOrder[subtypeB];
          })}
        />
      </DraggableCard>
      {isSettingsModalOpen && (
        <NotesSettingsModal
          open={isSettingsModalOpen}
          onCancel={setIsSettingsModalOpen}
          onOK={handleOK}
          showLevel={props?.showLevel}
        />
      )}
    </>
  );
}
