import styled from "styled-components";
import { Button } from "antd";
import { FunctionComponent, DragEvent } from "react";
import Icon from "@ant-design/icons";
import { Node } from "reactflow";
import { ReactComponent as DragHandleIcon } from "@/components/Draggable/icons/drag-handle.svg";

const DragButton = styled(Button)`
  .drag-icon {
    visibility: hidden;
  }
  &:hover .drag-icon {
    visibility: visible;
  }
`;

interface DraggableNodeButton {
  nodeGenerator: () => Node;
  children?: React.ReactNode;
}

export const DragNodeButton: FunctionComponent<DraggableNodeButton> = ({
  nodeGenerator,
  children,
}) => {
  const onDragStart = (event: DragEvent<HTMLElement>, node: Node) => {
    event.dataTransfer.setData("application/reactflow", JSON.stringify(node));
    event.dataTransfer.effectAllowed = "move";
  };

  const newNode = nodeGenerator();
  return (
    <DragButton
      icon={<Icon className="drag-icon" component={DragHandleIcon} />}
      type="text"
      onDragStart={(event) => onDragStart(event, newNode)}
      draggable
    >
      {children}
    </DragButton>
  );
};
