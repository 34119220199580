import { type Dayjs } from "@properate/dayjs";
import { CSSProperties } from "react";
import { TimeSpanObject } from "@properate/common";
import { useUser } from "@properate/auth";
import { RangePicker } from "@/components/RangePicker/RangePicker";

interface Props {
  onAdd: (value: TimeSpanObject) => unknown;
  periods?: TimeSpanObject[];
  style?: CSSProperties;
}

export function SchedulePaneInactivePeriodInput({
  onAdd,
  periods = [],
  style,
}: Props) {
  const user = useUser();

  function isDisabledDate(value: Dayjs) {
    return periods.some((period) => value.isBetween(period.start, period.end));
  }

  return (
    <RangePicker
      onChange={(timeSpan) =>
        onAdd({
          start: timeSpan[0],
          end: timeSpan[1],
        })
      }
      disabledDate={isDisabledDate}
      disabled={user.isViewer}
      style={style}
    />
  );
}
