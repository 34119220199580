import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useGetFirestoreDocument } from "@properate/ui";
import { collection, doc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

type Configs = {
  [externalId: string]: {
    unit: string;
  };
};

const FloorPlanPinConfigsContext = createContext<Configs | null>(null);

export function FloorPlanMapPinConfigProvider(props: PropsWithChildren) {
  const buildingId = useCurrentBuildingId();

  const configs = useGetFirestoreDocument<Configs>(
    useMemo(
      () =>
        doc(collection(browserFirestore, "timeseries"), buildingId.toString()),
      [buildingId],
    ),
  );

  return (
    <FloorPlanPinConfigsContext.Provider value={configs.data ?? null}>
      {props.children}
    </FloorPlanPinConfigsContext.Provider>
  );
}

export function useFloorPlanPinConfig(externalId?: string) {
  const config = useContext(FloorPlanPinConfigsContext);

  if (config === null || !externalId) {
    return null;
  }

  return config[externalId] ?? null;
}
