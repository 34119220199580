import { Form, InputNumber, Space } from "antd";
import { Rule } from "antd/es/form";
import { useTranslations } from "@properate/translations";

const FormItem = Form.Item;

const msPerMin = 60 * 1000;
const msPerHour = 60 * msPerMin;
const msPerDay = 24 * msPerHour;

interface Props {
  title: string;
  tooltip?: string;
  milliseconds: number;
  name?: string | undefined;
  dependencies?: any[] | undefined;
  rules?: Rule[] | undefined;
  onChange: (milliseconds: number) => void;
}

function getDaysHoursMinutesFromMilliseconds(milliseconds: number) {
  const days = Math.trunc(milliseconds / msPerDay);
  const remainingMillisecondsInDay = milliseconds % msPerDay;

  const hours = Math.trunc(remainingMillisecondsInDay / msPerHour);
  const remainingMillisecondsInHour = remainingMillisecondsInDay % msPerHour;

  const minutes = Math.trunc(remainingMillisecondsInHour / msPerMin);

  return { days, hours, minutes };
}

function DaysHoursMinutesForm(params: Props) {
  const t = useTranslations();

  const { days, hours, minutes } = getDaysHoursMinutesFromMilliseconds(
    params.milliseconds,
  );

  const handleDaysChange = (days: number | null) => {
    if (days !== null)
      params.onChange((minutes + 60 * (hours + 24 * days)) * 60 * 1000);
  };
  const handleHoursChange = (hours: number | null) => {
    if (hours !== null)
      params.onChange((minutes + 60 * (hours + 24 * days)) * 60 * 1000);
  };
  const handleMinutesChange = (minutes: number | null) => {
    if (minutes !== null)
      params.onChange((minutes + 60 * (hours + 24 * days)) * 60 * 1000);
  };

  return (
    <FormItem
      label={params.title}
      tooltip={params.tooltip}
      name={params.name}
      dependencies={params.dependencies}
      rules={params.rules}
    >
      <Space>
        <InputNumber
          addonAfter={t("calculation-flow.time-units.days")}
          min={0}
          value={days}
          onChange={handleDaysChange}
          controls={false}
          size="small"
          aria-label={t("calculation-flow.time-units.days")}
        />
        <InputNumber
          addonAfter={t("calculation-flow.time-units.hours")}
          min={0}
          max={23}
          value={hours}
          onChange={handleHoursChange}
          controls={false}
          size="small"
          aria-label={t("calculation-flow.time-units.hours")}
        />
        <InputNumber
          addonAfter={t("calculation-flow.time-units.minutes")}
          min={0}
          max={59}
          value={minutes}
          onChange={handleMinutesChange}
          controls={false}
          size="small"
          aria-label={t("calculation-flow.time-units.minutes")}
        />
      </Space>
    </FormItem>
  );
}

export default DaysHoursMinutesForm;
