import { useState } from "react";
import { Button, Space } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Timeseries } from "@cognite/sdk";
import { useTranslations } from "@properate/translations";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { TimeseriesExtendedDescription } from "@/components/TimeseriesExtendedDescription/TimeseriesExtendedDescription";
// Should have "value" & "onChange" prop to be able to use it as a custom form control
// See https://4x.ant.design/components/form/#components-form-demo-customized-form-controls
interface Props {
  value?: Timeseries;
  onChange?: (value: Timeseries | undefined) => unknown;
}

export function TimeseriesSelectionInput({
  value: timeseries,
  onChange,
}: Props) {
  const t = useTranslations();

  const [isTimeseriesSelectionModalOpen, setIsTimeseriesSelectionModalOpen] =
    useState(false);

  return (
    <>
      <Space>
        {timeseries !== undefined && (
          <TimeseriesExtendedDescription timeseries={timeseries} />
        )}
        <Button
          onClick={() => setIsTimeseriesSelectionModalOpen(true)}
          icon={timeseries === null ? <PlusOutlined /> : <EditOutlined />}
          data-testid="select-timeseries"
        >
          {timeseries === null
            ? t("analysis.gauge.details.select")
            : t("analysis.gauge.details.change")}
        </Button>
      </Space>
      <TimeseriesSelectionModal
        open={isTimeseriesSelectionModalOpen}
        selectedIds={timeseries !== undefined ? [timeseries.id] : []}
        hiddenFilters={["building"]}
        onOk={([timeseries]) => onChange?.(timeseries)}
        onHide={() => setIsTimeseriesSelectionModalOpen(false)}
        max={1}
        disableClear
      />
    </>
  );
}
