import { Alarm } from "@properate/common";
import { List, Popover } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { LineClamp } from "@/components/LineClamp";
import { combinePeriods, formatPeriods } from "../../utils";

interface Props {
  alarm: Alarm;
}

export function AlarmsTablePlanColumn({ alarm }: Props) {
  const t = useTranslations();

  if (alarm.alarmPeriods.length === 0) {
    return null;
  }

  const periods = combinePeriods(alarm.alarmPeriods);

  const cellContent =
    (periods[0]
      ? formatPeriods(
          periods[0].map((period) => ({
            ...period,
            translatedDay: t(
              `common.day-periods.${period.day.toLowerCase()}` as MessageKey,
            ),
          })),
        )
      : "") +
    (periods[1]
      ? ", " +
        formatPeriods(
          periods[1].map((period) => ({
            ...period,
            translatedDay: t(
              `common.day-periods.${period.day.toLowerCase()}` as MessageKey,
            ),
          })),
        )
      : "") +
    (periods.length > 2 ? `, …` : "");

  if (periods.length > 2) {
    return (
      <LineClamp onClick={(event) => event.stopPropagation()}>
        <Popover
          title="Plan"
          content={
            <List
              size="small"
              dataSource={periods}
              renderItem={(item) => (
                <List.Item>
                  {formatPeriods(
                    item.map((period) => ({
                      ...period,
                      translatedDay: t(
                        `common.day-periods.${period.day.toLowerCase()}` as MessageKey,
                      ),
                    })),
                  )}
                </List.Item>
              )}
            />
          }
        >
          {cellContent}
        </Popover>
      </LineClamp>
    );
  }

  return <LineClamp title={cellContent}>{cellContent}</LineClamp>;
}
