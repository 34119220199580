import { SortableContainer } from "react-sortable-hoc";
import { Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { KPIConfiguration, getKPIConfiguration, kpiExists } from "@/utils/kpi";
import { KPI, AlarmKPI, CertificationKPI } from "@/utils/types";
import { SortableKPI } from "./SortableKPI";

interface Props {
  selectedKPIs: readonly Exclude<KPI, AlarmKPI | CertificationKPI>[];
  allKPIValues: Record<string, number>;
  onClickSettingsButton: () => unknown;
  usableFloorArea?: number;
}

export const SortableKPIList = SortableContainer<Props>(
  ({
    selectedKPIs,
    allKPIValues,
    onClickSettingsButton,
    usableFloorArea,
  }: Props) => {
    return (
      <div>
        {selectedKPIs
          .filter((kpi) => kpiExists(kpi))
          .map((kpi, index) => {
            const { asOption, metadata } = getKPIConfiguration(
              kpi,
            ) as KPIConfiguration;
            return (
              <SortableKPI
                key={kpi}
                kpi={kpi}
                index={index}
                label={asOption.label}
                value={metadata.calculate(allKPIValues, usableFloorArea)}
                precision={metadata.fractionDigits}
                unit={metadata.unit}
              />
            );
          })}

        <Button
          type="link"
          icon={<SettingOutlined />}
          onClick={onClickSettingsButton}
          data-testid="open-kpi-settings-button"
        />
      </div>
    );
  },
);
