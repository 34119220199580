import { DefaultTheme } from "styled-components";

export function getColor(index: number, themeContext: DefaultTheme) {
  const fallbackColors = [
    themeContext.accent2,
    themeContext.accent3,
    themeContext.accent4,
    themeContext.accent5,
    themeContext.accent6,
    themeContext.accent7,
    themeContext.accent8,
    themeContext.accent9,
    themeContext.accent10,
  ].reverse();

  return fallbackColors[index % fallbackColors.length];
}

export function getNamedColor(
  name: keyof DefaultTheme,
  themeContext: DefaultTheme,
) {
  return themeContext[name];
}
