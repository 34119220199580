"use client";

import { ComponentSelector } from "@properate/ui";
import { Button, DatePicker, Input, Space } from "antd";
import { useTranslations } from "@properate/translations";
import dayjs from "@properate/dayjs";
import { Task, TaskTemplate } from "../../schemas";
import { useGetTask } from "../../hooks/use-get-task";
import { useUpdateTask } from "../../hooks/use-update-task";
import { useTaskType } from "../../contexts/task-type-context";
import { Checklists } from "./checklists";
import { Files } from "./files";
import { Activities } from "./activities";
import { DescriptionInput } from "./description-input";
import { AssigneeInput } from "./assignee-input";
import { StatusInput } from "./status-input";
import { PriorityInput } from "./priority-input";
import { Actions } from "./actions";
import { DisplayPageLoading } from "./display-page-loading";

type Props = {
  taskId: string;
  task?: TaskTemplate;
  userEmail?: string;
  onDeleted: () => void;
  onCreateComponent?: () => void;
  onClickFile?: (fileId: string | number) => void;
};

export function DisplayPage(props: Readonly<Props>) {
  const { data: task } = useGetTask(props.taskId, props.task as Task);
  const t = useTranslations();
  const updateTask = useUpdateTask();
  const isEditable = !!props.userEmail;
  const taskType = useTaskType();

  if (!task) {
    return <DisplayPageLoading />;
  }

  return (
    <div className="space-y-8 md:space-y-16 pb-36">
      <div>
        <div className="mb-2 text-[13px] tracking-wide">
          {t("task.field.title")}
        </div>
        <Input
          defaultValue={task.title}
          readOnly={!isEditable}
          onBlur={(event) =>
            updateTask.trigger({
              where: { taskId: props.taskId },
              data: { title: event.target.value },
            })
          }
          data-testid="task-title"
        />
      </div>
      <div className="flex flex-col-reverse gap-8 lg:gap-16 lg:flex-row">
        <div className="flex-1 space-y-8 md:space-y-16">
          <DescriptionInput
            taskId={props.taskId}
            description={task.description}
            buildingId={task.buildingId}
            isEditable={isEditable}
          />
          <Checklists task={task} isEditable={isEditable} />
          {taskType === "tasks" && props.onClickFile && (
            <>
              <Files
                taskId={props.taskId}
                files={task.files}
                taskAuthor={task.author}
                onClickFile={props.onClickFile}
              />
              <Activities taskId={props.taskId} task={task} />
            </>
          )}
        </div>
        <div className="grid grid-cols-2 gap-8 lg:w-64 lg:flex lg:flex-col">
          <div>
            <div className="mb-2 text-[13px] tracking-wide">
              {t("task.field.status")}
            </div>
            <div>
              <StatusInput taskId={props.taskId} value={task.status} />
            </div>
          </div>
          {taskType === "tasks" && (
            <>
              <div>
                <div className="mb-2 text-[13px] tracking-wide">
                  {t("task.field.assignee")}
                </div>
                <AssigneeInput
                  buildingId={task.buildingId}
                  value={task.assignee}
                  disabled={!isEditable}
                  onChange={([assignee = null]) =>
                    updateTask.trigger({
                      where: { taskId: props.taskId },
                      data: { assignee },
                    })
                  }
                />
              </div>
              <div>
                <div className="mb-2 text-[13px] tracking-wide">
                  {t("task.field.participants")}
                </div>
                <AssigneeInput
                  multi
                  buildingId={task.buildingId}
                  value={task.participants}
                  disabled={!isEditable}
                  onChange={(participants) =>
                    updateTask.trigger({
                      where: { taskId: props.taskId },
                      data: { participants },
                    })
                  }
                />
              </div>
              <div>
                <div className="mb-2 text-[13px] tracking-wide">
                  {t("task.field.dates")}
                </div>
                <DatePicker.RangePicker
                  className="w-full"
                  value={[
                    task.startDate ? dayjs(task.startDate) : null,
                    task.dueDate ? dayjs(task.dueDate) : null,
                  ]}
                  allowClear
                  allowEmpty={[true, true]}
                  disabled={!isEditable}
                  onChange={(dates) => {
                    if (dates) {
                      const [startDate, endDate] = dates;

                      updateTask.trigger({
                        where: { taskId: props.taskId },
                        data: {
                          startDate: startDate ? Number(startDate) : null,
                          dueDate: endDate ? Number(endDate) : null,
                        },
                      });
                    } else {
                      updateTask.trigger({
                        where: { taskId: props.taskId },
                        data: {
                          startDate: null,
                          dueDate: null,
                        },
                      });
                    }
                  }}
                />
              </div>
            </>
          )}
          <div className="col-span-2">
            <div className="mb-2 text-[13px] tracking-wide">
              {t("task.field.priority")}
            </div>
            <div>
              <PriorityInput
                taskId={props.taskId}
                value={task.priority}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="col-span-2">
            <div className="flex items-center gap-2 mb-2 text-[13px] tracking-wide">
              {t("task.field.component")}
            </div>
            <Space direction="vertical" style={{ width: "100%" }}>
              <ComponentSelector
                buildingId={task.buildingId}
                disabled={!isEditable}
                selected={task.components ? task.components[0] : undefined}
                onSelect={async (component) => {
                  return updateTask.trigger({
                    where: { taskId: props.taskId },
                    data: { components: [component] },
                  });
                }}
              />
              {props.onCreateComponent ? (
                <Button block onClick={() => props.onCreateComponent?.()}>
                  {t("task.field.createNewComponent")}
                </Button>
              ) : null}
            </Space>
          </div>
          <Actions
            taskId={props.taskId}
            task={task}
            userEmail={props.userEmail}
            isEditable={isEditable}
            onDeleted={props.onDeleted}
          />
          {task.recurrenceOf && (
            <div className="col-span-2 text-sm text-muted-foreground">
              This task is an automated recurrence.{" "}
              <a
                href={`/${task.buildingId}/task/${task.recurrenceOf}`}
                className="text-properate hover:underline hover:underline-offset-2"
                target="_blank"
                rel="noreferrer"
              >
                See the original task.
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
