import { BuildingCertification } from "@properate/common";
import { Button, Space, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslations, MessageKey } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { SearchInput } from "@/components/SearchIndex";
import { useGetBuildingCertifications } from "../hooks/useGetBuildingCertifications";
import { BuildingCertificationModal } from "./BuildingCertificationModal";
import { BuildingCertificationsTable } from "./BuildingCertificationsTable";
import {
  filters,
  useCertificationsSieve,
} from "./hooks/useCertificationsSieve";

export function BuildingCertificationsAdminPanel() {
  const t = useTranslations();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCertification, setSelectedCertification] =
    useState<BuildingCertification | null>(null);
  const buildingId = useCurrentBuildingId();
  const certifications = useGetBuildingCertifications(buildingId);
  const { search, setSearch, filter, setFilter, filteredCertifications } =
    useCertificationsSieve(certifications.data);

  return (
    <div>
      <PageHeader
        title={t("settings.certificates.title")}
        extra={[
          <Space key="filter" size={[0, 8]} wrap>
            <Tag.CheckableTag
              checked={filter === "all"}
              onChange={() => setFilter("all")}
            >
              {t("settings.certificates.all")}
            </Tag.CheckableTag>
            {filters.map(({ label }) => (
              <Tag.CheckableTag
                key={label}
                checked={label === filter}
                onChange={() => setFilter(label)}
              >
                {t(`settings.certificates.${label}` as MessageKey)}
              </Tag.CheckableTag>
            ))}
          </Space>,
          <SearchInput
            key="search"
            allowClear
            placeholder={t("settings.certificates.search-placeholder")}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />,
          <Button
            key="new"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            {t("settings.certificates.new-certificate")}
          </Button>,
        ]}
      />
      {isModalVisible && (
        <BuildingCertificationModal
          buildingId={buildingId}
          onClose={() => {
            setSelectedCertification(null);
            setIsModalVisible(false);
          }}
          buildingCertifications={certifications.data}
          certification={selectedCertification}
        />
      )}
      <div style={{ height: 400 }}>
        <BuildingCertificationsTable
          buildingId={buildingId}
          certifications={filteredCertifications}
          search={search}
          scrollY={325}
          onRowClick={(certification) => {
            setSelectedCertification(certification);
            setIsModalVisible(true);
          }}
        />
      </div>
    </div>
  );
}
