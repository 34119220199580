export enum AlarmNotificationFormFields {
  AlwaysActive = "alwaysActive",
  ActivePeriods = "activePeriods",
  ActivePeriodDay = "activePeriodDay",
  ActivePeriodTimeRange = "activePeriodTimeRange",
  Category = "category",
  SubCategory = "subCategory",
  InactivePeriods = "inactivePeriods",
  InactiveDateRange = "inactiveDateRange",
  HolidayAlarms = "holidayAlarms",
  Level = "notificationLevel",
  RequiresReceipt = "notificationRequiresReceipt",
  Recipients = "notificationRecipients",
  RecipientName = "notificationRecipientName",
  RecipientContact = "notificationRecipientContact",
  NotificationPause = "notificationNotificationPause",
}
