import { Row, Col, Button, InputNumber, Tooltip } from "antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ComparisonTimeseries, formatUnit } from "@properate/common";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { AlarmSubtitle } from "./elements";

interface Props {
  value: ComparisonTimeseries;
  onEditClick: () => unknown;
  type: "min" | "max";
  unit: string;
  onChange: (value: ComparisonTimeseries) => unknown;
  buffer?: number;
}

export const AlarmComparisonTimeseries = ({
  value,
  onEditClick,
  type,
  onChange,
  unit,
}: Props) => {
  const t = useTranslations();
  const user = useUser();

  function handleChangeBuffer(buffer: number | null) {
    onChange({
      ...value,
      buffer: buffer === null ? undefined : buffer,
    });
  }

  const description =
    type === "max"
      ? t(
          "common.timeseries-graph-modal.alarm-fields.trigger-when-values-higher-than",
        )
      : t(
          "common.timeseries-graph-modal.alarm-fields.trigger-when-values-lower-than",
        );

  const tooltipTitle =
    type === "max"
      ? t(
          "common.timeseries-graph-modal.alarm-fields.trigger-when-value-higher-than-plus-threshold",
        )
      : t(
          "common.timeseries-graph-modal.alarm-fields.trigger-when-value-lower-than-minus-threshold",
        );

  return (
    <>
      <AlarmSubtitle>
        {t("common.timeseries-graph-modal.alarm-fields.setup")}
      </AlarmSubtitle>
      <Row gutter={[8, 8]} align="middle">
        <Col span={18}>
          {description}&nbsp;
          <strong>{value.name}</strong>
        </Col>
        <Col span={6}>
          <Button
            disabled={user.isViewer}
            icon={<EditOutlined />}
            type="primary"
            onClick={onEditClick}
          >
            {t("common.timeseries-graph-modal.alarm-fields.change")}
          </Button>
        </Col>
        <Col span={8}>
          <label htmlFor="buffer">
            {t("common.timeseries-graph-modal.alarm-fields.threshold-value")}
          </label>
        </Col>
        <Col span={14}>
          <InputNumber
            id="buffer"
            value={value.buffer}
            onChange={handleChangeBuffer}
            addonAfter={formatUnit(unit)}
            style={{ width: "100%" }}
            decimalSeparator=","
          />
        </Col>
        <Col span={2}>
          <Tooltip title={tooltipTitle}>
            <InfoCircleOutlined style={{ fontSize: 18 }} />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
};
