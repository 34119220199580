import styled, { keyframes } from "styled-components";

import { ReactComponent as LogoSVG } from "../logo.svg";

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div<{ hideOverflow: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  ${({ hideOverflow }) => (hideOverflow ? "overflow: hidden;" : "")};

  .ant-page-header {
    padding-block: 4px;
  }
`;

export const Sidebar = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 280px;
  gap: 1rem;
  position: sticky;
  top: 0;
  will-change: width;
  transition: width 0.3s ease;
  padding-right: 15px;

  &[data-collapsed="true"] {
    width: 70px;
    padding-right: 0;

    [role="menuitem"] {
      padding-inline: 0;
      padding: 3px 17px;
    }
  }
`;

export const Logo = styled(LogoSVG)`
  margin-top: 16px;
  margin-left: 24px;
  margin-bottom: 22px;
  transition: transform 0.15s ease;
  .bg,
  .fg {
    transition:
      fill 0.3s ease,
      opacity 0.15s ease;
  }

  .bg,
  .fg,
  & {
    transition-delay: 50ms;
  }

  .dark & {
    .bg {
      fill: ${(props) => props.theme.white};
    }

    .fg {
      fill: ${(props) => props.theme.primary};
    }
  }

  [data-collapsed="true"] & {
    transform: translate(-38px);

    .bg,
    .fg,
    & {
      transition-delay: 0;
    }

    .bg {
      opacity: 0;
    }

    .fg {
      fill: ${(props) => props.theme.primary};
    }
  }
`;

export const ButtonList = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;

  [data-collapsed="true"] & {
    flex-direction: column;
  }
`;

export const MapChangeBuildingButtons = styled.div`
  ul{
    border-inline-end: 0 !important;
     li :hover{
      color: ${
        (props) => props.theme.neutral6 //whitesmoke;
      }
  }

`;

export const MenuContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 48px;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: 16px;
  margin-bottom: 32px;

  :hover {
    overflow-y: auto;
  }

  .ant-menu {
    border-inline-end: 0 !important;
  }
`;

const ToCollapsed = keyframes`
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    flex-direction: column;
    align-items: center;
  }

  to {
    opacity: 1;
    flex-direction: column;
    align-items: center;
  }
`;

const FromCollapsed = keyframes`
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    flex-direction: row;
    align-items: unset;
  }

  to {
    opacity: 1;
    flex-direction: row;
    align-items: unset;
  }
`;

export const SidebarFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem 0.5rem 0.85rem;
  margin: 0 -0.55rem;
  animation: ${FromCollapsed} 500ms linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  [data-collapsed="true"] & {
    animation: ${ToCollapsed} 500ms linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    > div {
      padding: 0 0.85rem;
    }
  }

  svg {
    width: 28px;
    height: 28px;
  }
`;
