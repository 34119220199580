"use client";

import { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import {
  AccordionAction,
  AccordionActionType,
  AccordionFilter,
  AccordionValues,
} from "./types";
import { getIndexForId, setOpenStateForId } from "./utils";

export function accordionReducer(
  { entries, options, filters }: AccordionValues,
  action: AccordionAction,
): AccordionValues {
  function disableAllFilters() {
    for (const [key, filter] of filters) {
      filters.set(key, { ...filter, active: false });
    }
  }
  function enableFilter() {
    const currentAction = action as AccordionAction & {
      type: AccordionActionType.enableFilter;
    };
    if (currentAction.disableOthers) {
      disableAllFilters();
    }
    return filters.set(currentAction.filterId, {
      ...(filters.get(currentAction.filterId) as AccordionFilter),
      active: true,
    });
  }

  switch (action.type) {
    case AccordionActionType.setIsOpen:
      return {
        entries: setOpenStateForId(
          action.itemId,
          entries,
          action.open,
          options,
          action.force,
        ),
        options,
        filters,
      };
    case AccordionActionType.toggleIsOpen:
      return {
        entries: setOpenStateForId(
          action.itemId,
          entries,
          !entries[getIndexForId(entries, action.itemId)].open,
          options,
        ),
        options,
        filters,
      };
    case AccordionActionType.setOptions:
      return {
        entries,
        options: {
          ...action.options,
        },
        filters,
      };
    case AccordionActionType.setEntries:
      return {
        entries: action.entries,
        options,
        filters,
      };
    case AccordionActionType.addFilter:
      return {
        entries,
        options,
        filters: filters.set(action.filterId, {
          id: action.filterId,
          filterFunction: action.filterFunction,
          active: action.enable,
        }),
      };
    case AccordionActionType.removeFilter:
      filters.delete(action.filterId);
      return {
        entries,
        options,
        filters,
      };
    case AccordionActionType.enableFilter:
      if (!filters.get(action.filterId)) {
        throw new Error(
          `Attempting to enable a filter that does not exist: filter-id: ${action.filterId}`,
        );
      }
      return {
        entries,
        options,
        filters: enableFilter(),
      };
    case AccordionActionType.disableFilter:
      return {
        entries,
        options,
        filters: filters.set(action.filterId, {
          ...(filters.get(action.filterId) as AccordionFilter),
          active: false,
        }),
      };
    case AccordionActionType.disableAllFilters: {
      disableAllFilters();
      return {
        entries,
        options,
        filters,
      };
    }
  }
}

export const AccordionContext = createContext<AccordionValues | null>(null);
export const AccordionDispatchContext =
  createContext<Dispatch<AccordionAction> | null>(null);

export function AccordionProvider({ children }: Readonly<PropsWithChildren>) {
  const [values, dispatch] = useReducer(accordionReducer, {
    entries: [],
    options: {},
    filters: new Map<string, AccordionFilter>(),
  });

  return (
    <AccordionContext.Provider value={values}>
      <AccordionDispatchContext.Provider value={dispatch}>
        {children}
      </AccordionDispatchContext.Provider>
    </AccordionContext.Provider>
  );
}
