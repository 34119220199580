import { Row } from "antd";
import styled from "styled-components";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { HeatMapReferenceDateSelection } from "./HeatMapReferenceDateSelection";
import { HeatMapSettings } from "./HeatMapSettings";

export const PreviousArrow = styled(ArrowLeftOutlined)`
  font-size: 18px;
`;

export const NextArrow = styled(ArrowRightOutlined)`
  font-size: 18px;
`;

export const HeatMapReferenceDateSelectionCentered = styled(
  HeatMapReferenceDateSelection,
)`
  width: min(100%, 300px);
`;

export const HeatMapSettingsStyled = styled(HeatMapSettings)`
  padding-inline: 16px;
`;

export const RowWithGutterAndPadding = styled(Row).attrs({ gutter: [8, 8] })`
  padding-inline: 8px;
`;
