import { ReactNode } from "react";
import { Layout } from "antd";

interface Props {
  children: ReactNode;
  className?: string;
}
export function CompactContent({ children, className }: Props) {
  return (
    <Layout.Content
      style={{
        padding: `0px 16px 0px 0`,
        minHeight: 360,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
      className={className}
    >
      {children}
    </Layout.Content>
  );
}
