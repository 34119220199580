import { Popover } from "antd";
import styled, { css } from "styled-components";

const countToSize = (count: number) => Math.min(32 + count * 2, 64) + "px";

const Marker = styled.div<{ faded: boolean }>`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.white};
  color: #000;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.25);
  opacity: ${(props) => (props.faded ? 0.25 : 1)};
`;

export const ClusterMarker = styled(Marker)<{ count: number }>`
  width: ${(props) => countToSize(props.count)};
  height: ${(props) => countToSize(props.count)};
  font-size: 1.25rem;
`;

export const BuildingMarker = styled(Marker)`
  min-width: 48px;
  max-width: 72px;
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 6px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 300px;
`;

export const Aside = styled.div`
  height: calc(100vh - 64px); // 64px is the height of the header
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const BuildingDisplay = styled.div<{ withinPopover?: boolean }>`
  height: 300px;
  border-top: 1px solid ${(props) => props.theme.neutral8};
  display: flex;
  flex-direction: column;

  > dl {
    flex: 1;
    overflow-y: scroll;
    padding: 0.5rem;
    margin: 0;
  }

  ${(props) =>
    props.withinPopover &&
    css`
      border: 0;
      margin: -8px -12px;
      height: auto;
    `}
`;

export const BuildingPopover = styled(Popover)`
  .ant-popover-inner-content {
    background-color: red;
    padding: 0;

    ${BuildingDisplay} {
      border: 0;
    }
  }
`;

export const BuildingListSieve = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: -1rem;
  padding: 0.5rem;
  border-top: 1px solid ${(props) => props.theme.neutral8};
  gap: 1rem;
`;

export const BuildingListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  border-top: 1px solid ${(props) => props.theme.neutral8};
  background-color: ${(props) => props.theme.neutral10};
  padding-bottom: 50px;
`;

export const BuildingListItem = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.neutral8};

  ${(props) =>
    props.onClick &&
    css`
      :hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.neutral7};
      }
    `}
`;

export const BuildingListItemPicture = styled.div`
  width: 96px;
  min-width: 96px;
  height: 60px;
  background-color: ${(props) => props.theme.neutral8};
  border-radius: 6px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
`;

export const BuildingListItemDetails = styled.div`
  overflow: hidden;

  > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
