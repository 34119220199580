import GoogleMap from "google-maps-react-markers";
import pointsCluster, { Cluster, Point } from "points-cluster";
import { useTheme } from "styled-components";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { styles } from "./Map.styles";
import { Bounds, MapApi, MapEvent, MapInstance } from "./types";

function Wrapper(
  props: Point & { children: React.ReactNode; mapInstance?: MapInstance },
) {
  return (
    <div
      onClick={() => {
        if (props.mapInstance) {
          props.mapInstance.setZoom(props.mapInstance.getZoom() + 2);
          props.mapInstance.panTo({ lat: props.lat, lng: props.lng });
        }
      }}
    >
      {props.children}
    </div>
  );
}

type Props<T extends Point> = {
  items: T[];
  renderCluster: (cluster: Cluster<T>) => React.ReactNode;
  renderItem: (item: T) => React.ReactNode;
};

let i = 0;

export const MapPlot = forwardRef(function MapImpl<T extends Point>(
  props: Props<T>,
  ref: React.ForwardedRef<MapInstance | undefined>,
) {
  const [mapInstance, setMapInstance] = useState<MapInstance>();
  const [clusters, setClusters] = useState<Cluster<T>[]>([]);

  if (ref && "current" in ref) {
    ref.current = mapInstance;
  }

  const createClusters = useMemo(
    () => pointsCluster(props.items, {}),
    [props.items],
  );

  const setClusterFromPoint = useCallback(
    (bounds: Bounds, zoom: number) => {
      if (!mapInstance) {
        return;
      }

      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      setClusters(
        createClusters({
          bounds: {
            nw: { lat: ne.lat(), lng: sw.lng() },
            se: { lat: sw.lat(), lng: ne.lng() },
          },
          zoom,
        }),
      );
    },
    [createClusters, mapInstance],
  );

  const onReady = useCallback((api: MapApi) => {
    setMapInstance(api.map);
  }, []);

  const onChange = useCallback(
    (event: MapEvent) => {
      setClusterFromPoint(event.bounds, event.zoom);
    },
    [setClusterFromPoint],
  );

  useEffect(() => {
    if (mapInstance) {
      const bounds = mapInstance.getBounds();

      if (bounds) {
        setClusterFromPoint(bounds, mapInstance.getZoom());
      }
    }
  }, [mapInstance, setClusterFromPoint]);

  const theme = useTheme();

  const options = useMemo(() => {
    return {
      key: i++, // Force re-render when theme changes
      defaultCenter: { lat: 62.92724781641192, lng: 11.865079171830217 }, // Approximatly centering Norway
      defaultZoom: 6, // Aeral view of Norway
      styles: theme.type === "dark" ? styles.dark : styles.light,
      disableDefaultUI: true, // Disable Street View, Map Type, etc.
    };
  }, [theme.type]);

  return (
    <GoogleMap
      key={options.key}
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
      defaultCenter={options.defaultCenter}
      defaultZoom={options.defaultZoom}
      options={options}
      onGoogleApiLoaded={onReady}
      onChange={onChange}
      mapMinHeight="calc(100vh - 64px)"
    >
      {clusters.map((cluster) => {
        if (cluster.numPoints > 1) {
          return (
            <Wrapper
              key={cluster.wx + cluster.wy}
              lat={cluster.wy}
              lng={cluster.wx}
              mapInstance={mapInstance}
            >
              {props.renderCluster(cluster)}
            </Wrapper>
          );
        }

        const item = cluster.points[0];

        return (
          <Wrapper
            key={item.lat + item.lng}
            lat={item.lat}
            lng={item.lng}
            mapInstance={mapInstance}
          >
            {props.renderItem(item)}
          </Wrapper>
        );
      })}
    </GoogleMap>
  );
}) as <T extends Point>(
  props: Props<T> & {
    ref?: {
      current: MapInstance | undefined;
    };
  },
) => JSX.Element;
