import { AlarmType, Alarm } from "@properate/common";

export const ALARM_TYPE_CONFIG: Record<AlarmType, any> = {
  ROOM_TEMPERATURE_MAX: {
    showMax: true,
    generateName: (alarm: Alarm) => `Rom temperatur over ${alarm.max}°C`,
  },
  ROOM_TEMPERATURE_MIN: {
    showMin: true,
    generateName: (alarm: Alarm) => `Rom temperatur under ${alarm.min}°C`,
  },
  VOC_MAX: {
    showMax: true,
    generateName: (alarm: Alarm) => `VOC er over ${alarm.max}ppb`,
  },
  CO2_MAX: {
    showMax: true,
    generateName: (alarm: Alarm) => `Co2 er over ${alarm.max}ppm`,
  },
  EFFECT_GUARD: {
    showMax: true,
    generateName: (alarm: Alarm) => `Maks effekt er over ${alarm.max}kWh/h`,
  },
  EPRED_MAX: {},
  EPRED_MIN: {},
  CONSUMPTION_GUARD: { showMax: true },
  HUMIDITY_ALERT: {},
  SET_POINT_ALERT: { compareWithSetPoint: true },
  CUSTOM_MAX: { selectTimeseries: true, showMax: true, showName: true },
  CUSTOM_MIN: { selectTimeseries: true, showMin: true, showName: true },
  NO_DATAPOINTS: { showName: true },
  CUSTOM_TIMESERIES_MAX: {
    selectTimeseries: true,
    compareWithTsMax: true,
    showName: true,
  },
  CUSTOM_TIMESERIES_MIN: {
    selectTimeseries: true,
    compareWithTsMin: true,
    showName: true,
  },
  LOCKED_THIRD_PARTY_ALARM: {},
  THIRD_PARTY_ALARM: {},
};
