import { useUser } from "@properate/auth";
import { Node, useReactFlow } from "reactflow";
import { Select } from "antd";
import { useTranslations } from "@properate/translations";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import {
  SmallNode,
  NodeOutput,
  NodeInput,
  Header,
  Body,
  NodeOutputLabel,
  NodeInputLabel,
} from "./helpers/NodeComponents";

const { Option } = Select;

const compOperators = ["<", "<=", "==", ">=", ">"];

interface IfElseNodeProps {
  operationId: string;
  compOperator: string;
  compValue: number;
  ifCaseOutput: number;
  elseCaseOutput: number;
}

export const getEmptyIfElseNode = (): Node<IfElseNodeProps> => {
  return {
    id: getNodeId("ifElse"),
    type: "ifElse",
    data: {
      operationId: "if_else",
      compOperator: ">=",
      compValue: 0,
      ifCaseOutput: 0,
      elseCaseOutput: 0,
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function IfElseNode(params: { id: string; data: IfElseNodeProps }) {
  const t = useTranslations();
  const reactFlowInstance = useReactFlow();
  const user = useUser();

  const handleChangeCompOperator = (compOperator: string) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "compOperator",
      compOperator,
    );
  };

  return (
    <SmallNode>
      <Header>{t("calculation-flow.node-types.if-else")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <NodeInput inputId="b">
          <NodeInputLabel>B</NodeInputLabel>
        </NodeInput>
        <NodeInput inputId="c">
          <NodeInputLabel>C</NodeInputLabel>
        </NodeInput>
        <NodeInput inputId="d">
          <NodeInputLabel>D</NodeInputLabel>
        </NodeInput>
        <NodeOutput>
          <NodeOutputLabel>
            {t("calculation-flow.node-types.if")} <b>A</b>
            <Select
              showAction={["focus", "click"]}
              value={params.data.compOperator}
              onChange={handleChangeCompOperator}
              disabled={user.isViewer}
            >
              {compOperators.map((comOp) => (
                <Option key={comOp} value={comOp}>
                  {comOp}
                </Option>
              ))}
            </Select>
            {t("calculation-flow.node-types.if-result")}
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </SmallNode>
  );
}

export default IfElseNode;
