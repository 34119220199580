import useSWR from "swr";
import {
  DatapointAggregate,
  DatapointAggregates,
  DoubleDatapoint,
  DoubleDatapoints,
  Timeseries,
} from "@cognite/sdk";
import { convertElementPropsToUnit } from "@properate/common";
import { all, create } from "mathjs";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useHandleApiError } from "@/utils/api";
import { GaugeAggregateType, Since } from "../types";

function isDoubleDatapoint(
  datapoint: DoubleDatapoint | DatapointAggregate,
): datapoint is DoubleDatapoint {
  return "value" in datapoint;
}

function extractData(
  timeseriesListWithData: DatapointAggregates[] | DoubleDatapoints[],
) {
  if (timeseriesListWithData.length > 0) {
    const [{ datapoints }] = timeseriesListWithData;
    if (datapoints.length > 0) {
      const [datapoint] = datapoints;
      if (isDoubleDatapoint(datapoint)) {
        return datapoint.value;
      }
      return (datapoint.average ??
        datapoint.max ??
        datapoint.min ??
        datapoint.sum)!;
    }
  }
  return null;
}

function extractGranularity(since: Since) {
  const matches = /^\d+[h|m]/.exec(since);
  if (!matches || matches.length === 0) {
    throw new Error(
      `A granularity for the value ${since} could not be extracted`,
    );
  }
  return matches[0];
}

function getValueWithMathExpression(value: number, mathExpression: string) {
  try {
    return math.evaluate(mathExpression, { DP: value });
    // mathExpression is saved onChange, so it may be invalid while the user finishes their input
  } catch (error) {
    return value;
  }
}

const math = create(all);

export function useGetGaugeValue(
  timeseries?: Timeseries,
  aggregate?: GaugeAggregateType,
  since?: Since,
  unit?: string,
  mathExpression?: string,
) {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const {
    data: timeseriesListWithData = [],
    error,
    isLoading,
  } = useSWR(
    timeseries
      ? {
          query: {
            items: [{ id: timeseries.id }],
            aggregates: aggregate ? [aggregate] : undefined,
            granularity: since ? extractGranularity(since) : undefined,
            start: since,
            end: "now",
          },
          type:
            typeof aggregate === "string" && typeof since === "string"
              ? "datapoints.retrieve"
              : "datapoints.retrieveLatest",
        }
      : null,
    ({ query }) => {
      if (
        Array.isArray(query.aggregates) &&
        typeof query.granularity === "string" &&
        typeof query.start === "string" &&
        typeof query.end === "string"
      ) {
        return client.datapoints.retrieve(query);
      }
      return client.datapoints.retrieveLatest(query.items);
    },
    {
      refreshInterval: 60000,
    },
  );
  if (error) {
    handleAPIError(error);
  }
  const value = extractData(
    timeseriesListWithData as DatapointAggregates[] | DoubleDatapoints[],
  );
  const convertedValue =
    unit !== undefined && value !== null && timeseries?.unit !== unit
      ? convertElementPropsToUnit(
          { value },
          { from: timeseries?.unit, to: unit },
        ).value
      : value;
  const valueWithMathExpression =
    mathExpression !== undefined && convertedValue !== null
      ? getValueWithMathExpression(convertedValue, mathExpression)
      : convertedValue;

  return {
    value: valueWithMathExpression,
    error,
    isLoading,
  };
}
