import {
  AccordionActionType,
  useAccordionActions,
  useSetSidebarData,
  useSidebarData,
} from "@properate/ui";
import { useForm } from "antd/lib/form/Form";
import { PropsWithChildren, useState } from "react";
import { App, Form, Spin } from "antd";
import { CogniteEvent, EventFilterRequest, ListResponse } from "@cognite/sdk";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { postNote } from "@/eepApi";
import { getLabelForAsset, repeatUntilSuccessful } from "../utils";
import {
  useAssetList,
  useCogniteEventFilter,
  useCogniteEvents,
} from "../hooks";
import {
  CreateNote,
  EditNote,
  NoteFormValues,
  NotesSidebarValues,
  NotesSidebarViewState,
  PostNoteParams,
} from "../types";
import SomethingWrong from "../SomethingWrong";
import NotesSidebarTitlePortal from "../NotesSidebarTitlePortal";
import EditFormMeta from "./EditFormMeta";
import ContentField from "./ContentField";
import AssetSelectField from "./AssetSelectField";
import LevelSelectField from "./LevelSelectField";
import DateTimeRangePicker from "./DateTimeRangePicker";
import ActionButtons from "./ActionButtons";
import SelectDataSet from "./SelectDataSet";

function Centered({ children }: Readonly<PropsWithChildren>) {
  return (
    <div className={"grow flex flex-col items-center justify-center"}>
      {children}
    </div>
  );
}

export function NoteSidebarForm() {
  const { note, noteSource, viewState, buildings } =
    useSidebarData<NotesSidebarValues>();
  const { setSidebarData } = useSetSidebarData<NotesSidebarValues>();
  const [form] = useForm();
  const { assetList, isLoading, error } = useAssetList();
  const { client } = useCogniteClient();
  const { filter } = useCogniteEventFilter();
  const { mutate } = useCogniteEvents(filter);
  const [showSpinner, setShowSpinner] = useState(false);
  const { message } = App.useApp();
  const [currentDataSetId, setCurrentDataSetId] = useState(() => {
    if (note.dataSetId) {
      return note.dataSetId;
    } else if (buildings.length === 1) {
      return buildings[0].id;
    }
    return null;
  });
  const accordionDispatch = useAccordionActions();
  const t = useTranslations();

  const fieldsDisabled = currentDataSetId === null;

  if (error) {
    const error = t("notes.form.error-messages.load-assets");
    console.error(error);
    message?.error?.(error, 7000);

    return (
      <Centered>
        <SomethingWrong />
      </Centered>
    );
  }

  if (assetList?.length === 0) {
    const error = t("notes.form.error-messages.empty-asset-list");
    console.error(error);
    return (
      <Centered>
        <SomethingWrong extraMessage={error} />
      </Centered>
    );
  }

  if (showSpinner || !assetList || isLoading || !filter) {
    return (
      <Centered>
        <Spin />
      </Centered>
    );
  }

  function makePostData(values: NoteFormValues): PostNoteParams {
    if (currentDataSetId === null) {
      throw new Error(t("notes.form.error-messages.dataset-id-missing"));
    }
    const formNote: CreateNote = {
      content: values["content"],
      assetIds: values["assetIds"].map((assetIdObj) => {
        const assetId = parseInt(
          typeof assetIdObj === "object" ? assetIdObj.value : assetIdObj,
        );
        if (isNaN(assetId)) {
          throw Error(
            `Cannot parse assetId! Got assetId: ${JSON.stringify(assetIdObj)}`,
          );
        }
        return assetId;
      }),
      level: values["level"],
      dataSetId: currentDataSetId,
      startTime: values["dateRange"][0].valueOf(),
      endTime: values["dateRange"][1]?.valueOf() ?? undefined,
      source: noteSource,
    };

    if (viewState === NotesSidebarViewState.edit) {
      return {
        create: false,
        note: {
          ...formNote,
          id: note.id,
        } as EditNote,
      };
    }
    return {
      note: formNote,
      create: true,
    };
  }

  function assertSuccessfulSave(noteEvent: CogniteEvent) {
    return repeatUntilSuccessful<
      ListResponse<CogniteEvent[]>,
      EventFilterRequest,
      number
    >(
      client.events.list,
      filter as EventFilterRequest,
      (events, id) => {
        const savedNote = events?.items.find(
          (event: CogniteEvent) => event.id === id,
        );
        if (!savedNote) {
          return false;
        }
        if (viewState === NotesSidebarViewState.create) {
          return true;
        }
        return (
          viewState === NotesSidebarViewState.edit &&
          savedNote.lastUpdatedTime !== note.lastUpdatedTime
        );
      },
      noteEvent.id,
    );
  }

  async function handleOnFinish(values: NoteFormValues) {
    setShowSpinner(true);
    const data = makePostData(values);

    try {
      const { data: noteEvent } = await postNote(data);
      try {
        const { result, success } = await assertSuccessfulSave(noteEvent);
        if (success) {
          await mutate(result.items, false);
          accordionDispatch({
            type: AccordionActionType.setIsOpen,
            itemId: noteEvent.id,
            open: true,
            force: true,
          });
        }
      } catch (updateError) {
        const errorMessage = t("notes.form.error-messages.update-on-save-note");
        console.error(errorMessage, updateError);
        message?.error?.(errorMessage);
      }
      setSidebarData({
        viewState: NotesSidebarViewState.list,
      });
    } catch (error) {
      const errorMessage = t("notes.form.error-messages.save-note");
      console.error(errorMessage, error);
      message?.error?.(errorMessage);
      setShowSpinner(false);
    }
  }
  function getInitialValue() {
    const assetIds = (assetList ?? [])
      .filter(({ id: assetId }) => (note.assetIds ?? []).includes(assetId))
      .map((asset) => ({
        // id er autogenerert, name litt kryptisk, description gir litt mer info
        label: getLabelForAsset(asset),
        value: asset.id.toString(),
      }));

    if (viewState === NotesSidebarViewState.create) {
      const startDate = note.startTime ? dayjs(note.startTime) : undefined;
      const endDate = note.endTime ? dayjs(note.endTime) : undefined;
      return {
        level: note.level ?? null,
        dateRange: [startDate, endDate],
        assetIds,
      };
    }

    return {
      content: note.content,
      dateRange: [dayjs(note.startTime), dayjs(note.endTime)],
      level: note.level,
      assetIds,
    };
  }

  return (
    <div className={"mt-4 flex flex-col flex-grow"}>
      <NotesSidebarTitlePortal>
        <ActionButtons form={form} disabled={fieldsDisabled} />
      </NotesSidebarTitlePortal>
      <SelectDataSet onChange={setCurrentDataSetId} />
      <Form
        form={form}
        name="create-note-form"
        onFinish={handleOnFinish}
        className={"mr-5 ml-4 flex flex-col flex-grow"}
        layout="vertical"
        initialValues={getInitialValue()}
      >
        <DateTimeRangePicker disabled={fieldsDisabled} form={form} />
        <LevelSelectField disabled={fieldsDisabled} />
        <AssetSelectField
          disabled={fieldsDisabled}
          currentDataSetId={currentDataSetId}
        />
        <ContentField disabled={fieldsDisabled} />
        <EditFormMeta />
      </Form>
    </div>
  );
}
