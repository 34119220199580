import { App, Button, Modal } from "antd";
import { useTranslations } from "@properate/translations";
import { useState } from "react";
import { deleteCalculationFlow } from "@/eepApi";
import { CalculationFlow } from "../types";

interface Props {
  calculationFlow: CalculationFlow;
  onClose: () => void;
  afterDelete: (calculationFlow: CalculationFlow) => void;
}

export const DeleteModal = ({
  calculationFlow,
  onClose,
  afterDelete,
}: Props) => {
  const { notification } = App.useApp();
  const [deleting, setDeleting] = useState(false);
  const t = useTranslations();

  const handleDeleteOk = async () => {
    setDeleting(true);
    if (calculationFlow.id !== null) {
      try {
        await deleteCalculationFlow(calculationFlow.id);
        setDeleting(false);
        notification.success({
          message: t("calculation-flow.virtual-sensor-was-removed"),
        });
      } catch {
        notification.error({
          message: t("calculation-flow.cant-remove-virtual-sensor"),
        });
      }
    }
    afterDelete(calculationFlow);
  };

  return (
    <Modal
      title={t("calculation-flow.table.are-you-sure")}
      open
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t("calculation-flow.table.cancel")}
        </Button>,
        <Button
          key="delete"
          type="primary"
          loading={deleting}
          onClick={handleDeleteOk}
        >
          {t("calculation-flow.table.delete")}
        </Button>,
      ]}
    >
      {t.rich("calculation-flow.table.delete-description", {
        b: (text) => <b>{text}</b>,
        name: calculationFlow.name,
      })}
    </Modal>
  );
};
