import useSWRInfinite from "swr/infinite";
import { Asset, Timeseries } from "@cognite/sdk";
import { WithRequired } from "@properate/common";
import { useEffect } from "react";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useHandleApiError } from "@/utils/api";
import { AssetWithOrganizationAndLabels } from "../types";

const FILTER_LIMIT = 100;

function mapToOrganizationsToTimeseriesIds(
  assets: AssetWithOrganizationAndLabels[],
  timeseriesList: Timeseries[],
) {
  return assets.reduce<Record<string, number>>(
    (organizationsToTimeseriesIdsAcc, asset) => {
      const timeseries = timeseriesList.find(
        (timeseries) => timeseries.assetId === asset.id,
      );
      if (timeseries) {
        return {
          ...organizationsToTimeseriesIdsAcc,
          [asset.metadata.organization]: timeseries.id,
        };
      }
      return organizationsToTimeseriesIdsAcc;
    },
    {},
  );
}

export function useGetOrganizationsToTotalEnergyTimeseriesIds(
  assets: Array<WithRequired<Asset, "labels">>,
) {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const assetsWithOrganizations = assets.filter(
    (asset): asset is AssetWithOrganizationAndLabels =>
      typeof asset.metadata === "object" && "organization" in asset.metadata,
  );
  const {
    data: timeseriesListList = [],
    isLoading,
    error,
    size,
    setSize,
  } = useSWRInfinite(
    (index) => {
      const start = index * FILTER_LIMIT;
      const end = (index + 1) * FILTER_LIMIT;
      const assetsToQuery = assetsWithOrganizations.slice(start, end);
      return assetsToQuery.length > 0
        ? {
            type: "timeseries.list",
            query: {
              filter: {
                assetIds: assetsToQuery.map((asset) => asset.id),
              },
            },
          }
        : null;
    },
    ({ query }) =>
      client.timeseries.list(query).autoPagingToArray({ limit: Infinity }),
  );
  const timeseriesList = timeseriesListList.flat();

  useEffect(() => {
    if (size * FILTER_LIMIT < assetsWithOrganizations.length) {
      setSize(size + 1);
    }
  }, [size, setSize, assetsWithOrganizations.length]);

  if (error) {
    handleAPIError(error);
  }

  const assetsTotalEnergy = isLoading
    ? []
    : assetsWithOrganizations.filter((asset) =>
        asset.labels.every(
          (label) =>
            label.externalId !== "oe_temperature_corrected_etmod" &&
            label.externalId !== "oe_temperature_corrected_graddag",
        ),
      );
  const assetsTotalEnergyTempCorrectedETMod = isLoading
    ? []
    : assetsWithOrganizations.filter((asset) =>
        asset.labels.some(
          (label) => label.externalId === "oe_temperature_corrected_etmod",
        ),
      );
  const assetsTotalEnergyTempCorrectedGraddag = isLoading
    ? []
    : assetsWithOrganizations.filter((asset) =>
        asset.labels.some(
          (label) => label.externalId === "oe_temperature_corrected_graddag",
        ),
      );

  return {
    organizationsToTotalEnergyTimeseriesIds: mapToOrganizationsToTimeseriesIds(
      assetsTotalEnergy,
      timeseriesList,
    ),
    organizationsToTotalEnergyTempCorrectedETModTimeseriesIds:
      mapToOrganizationsToTimeseriesIds(
        assetsTotalEnergyTempCorrectedETMod,
        timeseriesList,
      ),
    organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds:
      mapToOrganizationsToTimeseriesIds(
        assetsTotalEnergyTempCorrectedGraddag,
        timeseriesList,
      ),
    isLoading,
  };
}
