import { isMobile } from "@/utils/responsiveDesign";

export function getGraphMargins(analysisType: "simple" | "scatterplot") {
  if (isMobile()) {
    return {
      top: 40,
      right: 15,
      bottom: 40,
      left: 15,
    };
  }
  return {
    top: 40,
    // Scatterplot graph has no right axis, so needs less margin
    right: analysisType === "simple" ? 60 : 40,
    bottom: 40,
    left: 60,
  };
}
