import { Space, Switch } from "antd";
import { useTranslations } from "@properate/translations";

interface Props {
  value: boolean;
  onChange: (value: boolean) => unknown;
  className?: string;
}

export function DatapointCalculatorSwitch({
  value,
  onChange,
  className,
}: Props) {
  const t = useTranslations();
  return (
    <Space className={className}>
      <label htmlFor="use-math-expression">
        {t("analysis.details.timeseries-modal.use-formula")}
      </label>
      <Switch id="use-math-expression" checked={value} onChange={onChange} />
    </Space>
  );
}
