import { Group } from "@visx/group";
import { AxisLeft, AxisRight } from "@visx/axis";
import { Bar, LinePath } from "@visx/shape";
import { curveStepAfter } from "@visx/curve";
import * as React from "react";
import { memo, useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";
import { PositionScale } from "@visx/shape/lib/types/base";
import { ScaleBand } from "d3-scale";
import dayjs from "@properate/dayjs";
import { Granularity, numberFormatForAxis } from "@/utils/helpers";
import { ScaleLinearNumeric } from "@/utils/types";
import { getDiff } from "./utils";

type Props = {
  data: Record<string, number | string>[];
  yScale: PositionScale;
  temperatureScale: PositionScale;
  granularityBands: ScaleBand<number>;
  showTemperature: boolean;
  xMetricsScale: ScaleLinearNumeric;
  granularity: Granularity;
};
export const DifferenceChartContent = memo(function DifferenceChartContent({
  yScale,
  temperatureScale,
  data,
  granularityBands,
  showTemperature,
  xMetricsScale,
  granularity,
}: Props) {
  const themeContext = useContext(ThemeContext);
  const WEATHER_COLOR = themeContext.neutral0;

  const bars = useMemo(
    () =>
      data
        .filter(
          (d) =>
            !!granularityBands(d.timestamp as number) &&
            d.total !== undefined &&
            d["next-total"] !== undefined,
        )
        .map((d) => {
          const barWidth = granularityBands.bandwidth();
          const difference = getDiff(
            d.total as number,
            d["next-total"] as number,
          );
          const barHeight =
            typeof difference === "number"
              ? difference >= 0
                ? yScale(0)! - yScale(difference)!
                : yScale(difference)! - yScale(0)!
              : 0;
          const barX = granularityBands(d.timestamp as number);
          const barY =
            typeof difference === "number"
              ? difference >= 0
                ? yScale(difference)
                : yScale(0)
              : 0;
          return (
            <Bar
              key={d.timestamp + "_" + d["next-timestamp"]}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill={themeContext.primary}
            />
          );
        }),
    [data, granularityBands, themeContext.primary, yScale],
  );

  const temperatureData = useMemo(
    () => [
      ...data,
      {
        ...data[data.length - 1],
        timestamp: dayjs(data[data.length - 1]?.timestamp)
          .add(1, granularity)
          .valueOf(),
      },
    ],
    [data, granularity],
  );

  return (
    <Group>
      <AxisLeft
        scale={yScale}
        stroke={themeContext.neutral5}
        numTicks={3}
        tickStroke={themeContext.neutral5}
        tickFormat={(value: any) => numberFormatForAxis.format(value)}
        tickLabelProps={{
          fill: themeContext.neutral5,
        }}
      />
      {bars}
      {showTemperature && (
        <AxisRight
          left={innerWidth}
          scale={temperatureScale}
          numTicks={3}
          stroke={themeContext.neutral5}
          tickStroke={themeContext.neutral5}
          tickLabelProps={{
            fill: themeContext.neutral5,
          }}
        />
      )}
      {showTemperature && (
        <LinePath
          stroke={WEATHER_COLOR}
          strokeWidth={1}
          data={temperatureData}
          x={(d) => xMetricsScale(d.timestamp as number)!}
          y={(d) =>
            temperatureScale(
              (d.temperature as number) - (d["next-temperature"] as number),
            )!
          }
          curve={curveStepAfter}
        />
      )}
    </Group>
  );
});
