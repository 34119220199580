import { useState } from "react";
import { Form, Input, Button, Radio, Select, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Timeseries } from "@cognite/sdk";
import {
  convertElementPropsToUnit,
  getUnitWithDerivedUnits,
} from "@properate/common";
import { useTranslations } from "@properate/translations";
import { DatapointCalculator } from "@/components/DatapointCalculator/DatapointCalculator";
import { FormPrompt } from "@/components/FormPrompt/FormPrompt";
import { TimeseriesSelectionInput } from "@/components/TimeseriesSelectionInput";
import { validateMathExpressionForDatapoint } from "@/utils/math";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { MessageKey } from "@/utils/types";
import {
  Gauge,
  GaugeType,
  GaugeViewMode,
  GaugeZones,
  Since,
} from "../../types";
import { GaugeView } from "../GaugeDetails/GaugeView";
import {
  gaugeViewModeOptions,
  getDefaultGaugeZones,
  useGaugeMinMax,
} from "../../utils";
import { GaugeZoneInput } from "./GaugeZoneInput";
import { gaugeTypeOptions, sinceOptions } from "./options";
import {
  DatapointCalculatorSwitchWithBottomMargin,
  GaugeFormWithViewContainer,
} from "./elements";

interface Props {
  name?: string;
  timeseries?: Timeseries;
  type?: GaugeType;
  since?: Since;
  min?: number;
  max?: number;
  viewMode: GaugeViewMode;
  zones?: GaugeZones;
  unit?: string;
  mathExpression?: string;
  onSubmit: (
    values: Omit<Gauge, "created" | "owner" | "buildingId">,
  ) => unknown;
  setLocalTimeseriesId?: (timeseriesId?: number) => void;
}

export function GaugeFormWithView({
  name: nameInitial,
  timeseries: timeseriesInitial,
  type: typeInitial = "latest",
  since: sinceInitial,
  min: minInitial,
  max: maxInitial,
  viewMode: viewModeInitial,
  zones: zonesInitial,
  unit: unitInitial,
  mathExpression: mathExpressionInitial,
  onSubmit,
  setLocalTimeseriesId,
}: Props) {
  const t = useTranslations();

  const currentBuildingId = useCurrentBuildingId();
  const { handleOverrideUnit, overrideUnits } =
    useTimeseriesSettings(currentBuildingId);
  const getGaugeMinMax = useGaugeMinMax();
  const [form] = useForm<
    Omit<
      Gauge,
      "created" | "owner" | "buildingId" | "timeseriesSettings" | "viewMode"
    > & {
      timeseries: Timeseries;
      viewMode: GaugeViewMode;
    }
  >();
  const [isTouched, setIsTouched] = useState(false);
  const min: number | undefined | null = Form.useWatch("min", form);
  const max: number | undefined | null = Form.useWatch("max", form);
  const type: GaugeType = Form.useWatch("type", form);
  const timeseries: Timeseries | undefined = Form.useWatch("timeseries", form);
  const unit: string | undefined = Form.useWatch("unit", form);
  const since: Since | undefined = Form.useWatch("since", form);
  const zones: GaugeZones | undefined = Form.useWatch("zones", form);
  const viewMode: GaugeViewMode = Form.useWatch("viewMode", form);
  const mathExpression: string | undefined = Form.useWatch(
    "mathExpression",
    form,
  );

  const [useMathExpression, setUseMathExpression] = useState(
    typeof mathExpressionInitial === "string",
  );

  const unitSelectOptions =
    typeof timeseries?.unit === "string"
      ? getUnitWithDerivedUnits(timeseries.unit)
      : [];

  function handleChangeTimeseries(timeseries: Timeseries | undefined) {
    setLocalTimeseriesId && setLocalTimeseriesId(timeseries?.id);
    updateUnit(timeseries);
    if (timeseries) {
      updateMinMaxZones(timeseries);
    }
  }
  async function handleBlurMin() {
    if (
      typeof min === "number" &&
      zones !== undefined &&
      min > zones.lower.threshold
    ) {
      form.setFieldsValue({
        zones: {
          ...zones,
          lower: {
            ...zones.lower,
            threshold: min,
          },
        },
      });
    }
  }

  async function handleBlurMax() {
    if (
      typeof max === "number" &&
      zones !== undefined &&
      zones.middle !== undefined &&
      max < zones.middle.threshold
    ) {
      form.setFieldsValue({
        zones: {
          ...zones,
          middle: {
            ...zones.middle,
            threshold: max,
          },
        },
      });
    }
  }

  function handleChangeUseMathExpresssion(value: boolean) {
    setUseMathExpression(value);
    if (!value) {
      form.setFieldsValue({
        mathExpression: undefined,
      });
    }
  }
  function updateUnit(timeseries: Timeseries | undefined) {
    form.setFieldsValue({
      unit: timeseries?.unit,
    });
  }

  function setMinMaxZoneFieldsValue(
    minConverted: number,
    maxConverted: number,
    convertedZones?: GaugeZones,
  ) {
    const zones =
      convertedZones || getDefaultGaugeZones(minConverted, maxConverted);
    form.setFieldsValue({
      min: minConverted,
      max: maxConverted,
      zones,
    });
  }
  async function handleChangeUnit(newUnit: string) {
    if (
      typeof unit === "string" &&
      typeof min === "number" &&
      typeof max === "number"
    ) {
      const { min: minConverted, max: maxConverted } =
        convertElementPropsToUnit(
          { min, max },
          {
            from: unit,
            to: newUnit,
          },
        );
      const convertedZones = zones
        ? convertElementPropsToUnit(
            {
              lower: zones.lower?.threshold,
              middle: zones.middle?.threshold,
            },
            {
              from: unit,
              to: newUnit,
            },
          )
        : undefined;

      const updatedZones = zones ? { ...zones } : undefined;
      if (zones?.lower) {
        updatedZones!.lower!.threshold =
          convertedZones?.lower || zones.lower.threshold!;
      }
      if (zones?.middle) {
        updatedZones!.middle!.threshold =
          convertedZones?.middle || zones.middle.threshold;
      }

      setMinMaxZoneFieldsValue(minConverted, maxConverted, updatedZones);
    }
  }

  async function updateMinMaxZones(timeseries: Timeseries) {
    const { min, max } = await getGaugeMinMax(timeseries);
    const { min: minConverted, max: maxConverted } =
      typeof unit === "string"
        ? convertElementPropsToUnit(
            { min, max },
            {
              from: timeseries?.unit,
              to: unit,
            },
          )
        : { min, max };
    setMinMaxZoneFieldsValue(minConverted, maxConverted);
  }
  const overrideUnit =
    timeseries && overrideUnits && overrideUnits[timeseries.externalId!]?.unit;
  function handleFinish({
    name,
    zones,
    min,
    max,
    timeseries,
    viewMode,
  }: {
    name: string;
    zones: GaugeZones;
    min: number;
    max: number;
    timeseries: Timeseries;
    viewMode: GaugeViewMode;
  }) {
    setIsTouched(false);
    if (unit) {
      handleOverrideUnit(
        currentBuildingId,
        timeseries.externalId!,
        unit,
        overrideUnit,
      );
    }
    onSubmit({
      name,
      timeseriesSettings: {
        id: timeseries.id,
      },
      type,
      zones,
      min,
      max,
      mathExpression,
      ...(since ? { since } : null),
      viewMode,
    });
  }

  return (
    <GaugeFormWithViewContainer>
      <FormPrompt isTouched={isTouched} />
      <Form
        form={form}
        onFinish={handleFinish}
        onFieldsChange={(value) => {
          if (
            value[0].name[0] === "unit" &&
            typeof value[0].value === "string"
          ) {
            handleChangeUnit(value[0].value);
          }
          setIsTouched(true);
        }}
        initialValues={{
          name: nameInitial,
          type: typeInitial,
          timeseries: timeseriesInitial,
          since: sinceInitial,
          min: minInitial,
          max: maxInitial,
          zones: zonesInitial,
          unit: unitInitial,
          mathExpression: mathExpressionInitial,
          viewMode: viewModeInitial,
        }}
      >
        <Form.Item
          label={t("analysis.gauge.details.name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("analysis.gauge.details.please-select-name"),
            },
          ]}
        >
          <Input autoComplete="off" data-testid="gauge-name" />
        </Form.Item>
        <Form.Item
          label={t("analysis.gauge.details.timeseries")}
          name="timeseries"
          rules={[
            {
              required: true,
              message: t("analysis.gauge.details.please-select-timeseries"),
            },
          ]}
        >
          <TimeseriesSelectionInput onChange={handleChangeTimeseries} />
        </Form.Item>
        <Form.Item
          label={t("analysis.gauge.details.unit")}
          name="unit"
          hidden={unitSelectOptions.length <= 1}
          style={{ maxWidth: 200 }}
        >
          <Select options={unitSelectOptions} />
        </Form.Item>
        <Form.Item
          label={t("analysis.gauge.details.type")}
          name="type"
          rules={[
            {
              required: true,
              message: t("analysis.gauge.details.please-select-type"),
            },
          ]}
        >
          <Radio.Group
            options={gaugeTypeOptions.map((item) => ({
              label: t(
                `analysis.gauge.details.types.${item.label}` as MessageKey,
              ),
              value: item.value,
            }))}
            optionType="button"
          />
        </Form.Item>

        <Form.Item
          label={t("analysis.gauge.details.period")}
          name="since"
          rules={[
            {
              required: type !== undefined && type !== "latest",
              message: t("analysis.gauge.details.please-select-period"),
            },
          ]}
          hidden={type === "latest"}
        >
          <Select
            options={sinceOptions.map((item) => ({
              label: t(
                `analysis.gauge.details.periods.${item.label}` as MessageKey,
              ),
              value: item.value,
            }))}
          />
        </Form.Item>

        <DatapointCalculatorSwitchWithBottomMargin
          value={useMathExpression}
          onChange={handleChangeUseMathExpresssion}
        />

        {useMathExpression && (
          <Form.Item
            name="mathExpression"
            label={t("analysis.gauge.details.formula")}
            rules={[
              {
                validator: (_, value) => {
                  if (typeof value === "string") {
                    const { valid, error } =
                      validateMathExpressionForDatapoint(value);
                    if (valid) {
                      return Promise.resolve();
                    }
                    if (error) {
                      return Promise.reject(
                        t(`analysis.invalid-formula-error`, { error }),
                      );
                    }
                    return Promise.reject(t(`analysis.invalid-formula`));
                  }
                  return Promise.resolve();
                },
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <DatapointCalculator />
          </Form.Item>
        )}

        <Form.Item
          label={t("analysis.gauge.details.min")}
          name="min"
          rules={[
            {
              required: true,
              message: t("analysis.gauge.details.please-select-min-value"),
            },
            {
              max,
              type: "number",
              message: t(
                "analysis.gauge.details.please-select-value-lower-than-max",
              ),
            },
          ]}
        >
          <InputNumber
            addonAfter={unit}
            onBlur={handleBlurMin}
            decimalSeparator=","
          />
        </Form.Item>
        <Form.Item
          label={t("analysis.gauge.details.max")}
          name="max"
          rules={[
            {
              required: true,
              message: t("analysis.gauge.details.please-select-max-value"),
            },
            {
              min,
              type: "number",
              message: t(
                "analysis.gauge.details.please-select-value-higher-than-min",
              ),
            },
          ]}
        >
          <InputNumber
            addonAfter={unit}
            onBlur={handleBlurMax}
            decimalSeparator=","
          />
        </Form.Item>

        <Form.Item
          name="zones"
          label={t("analysis.gauge.details.zones")}
          rules={[
            {
              required: true,
              message: t("analysis.gauge.details.please-select-zones"),
            },
          ]}
          hidden={timeseries === undefined || min === null || max === null}
        >
          <GaugeZoneInput min={min} max={max} unit={unit} />
        </Form.Item>

        <Form.Item
          name="viewMode"
          label={t("analysis.gauge.details.show-like")}
        >
          <Select
            options={gaugeViewModeOptions.map((item) => ({
              label: t(
                `analysis.gauge.details.view-mode.${item.labelKey}` as MessageKey,
              ),
              value: item.value,
            }))}
            style={{
              width: 150,
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            data-testid="submit-gauge-form"
            type="primary"
            htmlType="submit"
          >
            {t("analysis.gauge.details.save")}
          </Button>
        </Form.Item>
      </Form>
      {typeof timeseries === "object" &&
        typeof max === "number" &&
        typeof min === "number" &&
        typeof zones === "object" && (
          <GaugeView
            viewMode={viewMode}
            max={max}
            min={min}
            timeseries={timeseries}
            type={type}
            zones={zones}
            since={since}
            unit={unit}
            mathExpression={mathExpression}
          />
        )}
    </GaugeFormWithViewContainer>
  );
}
