import { InputNumber, Radio, Slider, Space } from "antd";
import { formatUnit } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { ColorPicker } from "@/components/ColorPicker/ColorPicker";
import { MessageKey } from "@/utils/types";
import { GaugeZones } from "../../types";
import { zoneAmountOptions } from "./options";

// Should have "value" & "onChange" prop to be able to use it as a custom form control
// See https://4x.ant.design/components/form/#components-form-demo-customized-form-controls
interface Props {
  min: number | null;
  max: number | null;
  value?: GaugeZones;
  onChange?: (value: GaugeZones) => unknown;
  unit?: string;
}

export function GaugeZoneInput({
  onChange,
  min,
  max,
  value: zones,
  unit,
}: Props) {
  const t = useTranslations();

  if (
    zones === undefined ||
    onChange === undefined ||
    min === null ||
    max === null
  ) {
    return null;
  }
  const zoneAmount = Object.keys(zones).length;

  const marks = {
    [min]: `${min}${formatUnit(unit)}`,
    [max]: `${max}${formatUnit(unit)}`,
  };
  function handleChangeColor(color: string, zone: keyof GaugeZones) {
    if (zones && onChange) {
      onChange({
        ...zones,
        [zone]: {
          ...zones[zone],
          color,
        },
      });
    }
  }

  function handleChangeZoneAmount(newZoneAmount: 2 | 3) {
    if (zones && onChange && max !== null && min !== null) {
      onChange(
        newZoneAmount === 2
          ? {
              lower: zones.lower,
              upper: zones.upper,
            }
          : {
              lower: zones.lower,
              middle: {
                color: "yellow",
                threshold:
                  zones.lower.threshold +
                  Math.max(Math.round((max - min) / 3), 1),
              },
              upper: zones.upper,
            },
      );
    }
  }

  function handleChangeValue(
    newValue: number | [number, number] | null,
    zone: "lower" | "middle",
  ) {
    if (typeof newValue === "number" && zones && onChange) {
      onChange({
        ...zones,
        [zone]: {
          ...zones[zone],
          threshold: newValue,
        },
      });
    }
  }

  function handleChangeValues(newValues: number[]) {
    if (zones?.middle && onChange) {
      onChange({
        ...zones,
        lower: {
          ...zones.lower,
          threshold: newValues[0],
        },
        middle: {
          ...zones.middle,
          threshold: newValues[1],
        },
      });
    }
  }

  function tooltipFormatter(value?: number) {
    return typeof value === "number" ? `${value}${formatUnit(unit)}` : null;
  }

  const zoomOptions = zoneAmountOptions.map((option) => ({
    ...option,
    label: t(
      `analysis.gauge.details.zones-selector.${option.labelKey}` as MessageKey,
    ),
  }));

  return (
    <>
      <Space direction="vertical">
        <Radio.Group
          options={zoomOptions}
          value={zoneAmount}
          onChange={(event) => handleChangeZoneAmount(event.target.value)}
          optionType="button"
        />
        <Space>
          <span>{t("analysis.gauge.details.zones-selector.zone-1")}</span>
          <ColorPicker
            value={zones.lower.color}
            onChange={(color) => handleChangeColor(color, "lower")}
          />
          <InputNumber
            value={zones.lower.threshold}
            onChange={(newValue) => handleChangeValue(newValue, "lower")}
            addonAfter={formatUnit(unit)}
            min={min}
            max={max}
            decimalSeparator=","
          />
          <span>{t("analysis.gauge.details.zones-selector.zone-2")}</span>{" "}
          <ColorPicker
            value={
              zones.middle !== undefined
                ? zones.middle.color
                : zones.upper.color
            }
            onChange={(color) =>
              handleChangeColor(
                color,
                zones.middle !== undefined ? "middle" : "upper",
              )
            }
          />
          {zoneAmount === 3 && zones.middle !== undefined && (
            <>
              <InputNumber
                value={zones.middle.threshold}
                onChange={(newValue) => handleChangeValue(newValue, "middle")}
                addonAfter={formatUnit(unit)}
                min={min}
                max={max}
                decimalSeparator=","
              />
              <span>{t("analysis.gauge.details.zones-selector.zone-3")}</span>{" "}
              <ColorPicker
                value={zones.upper.color}
                onChange={(color) => handleChangeColor(color, "upper")}
              />
            </>
          )}
        </Space>
      </Space>
      {zones.middle !== undefined ? (
        <Slider
          value={[zones.lower.threshold, zones.middle.threshold]}
          min={min}
          max={max}
          tooltip={{ formatter: tooltipFormatter }}
          onChange={handleChangeValues}
          range={{ draggableTrack: true }}
          marks={marks}
        />
      ) : (
        <Slider
          value={zones.lower.threshold}
          min={min}
          max={max}
          tooltip={{ formatter: tooltipFormatter }}
          onChange={(value) => handleChangeValue(value, "lower")}
          marks={marks}
        />
      )}
    </>
  );
}
