import {
  DatapointAggregates,
  DoubleDatapoints,
  DatapointsMultiQuery,
} from "@cognite/sdk";
import { TimeSpan } from "@properate/common";
import { all, create } from "mathjs";
import { useEffect, useState } from "react";
import { useHandleApiError } from "@/utils/api";
import { useGetTimeseriesListWithData } from "@/hooks/useGetTimeseriesListWithData";
import { useGetTimeseriesListWithLatestData } from "@/hooks/useGetTimeseriesListWithLatestData";
import { getAmountOfDatapointsPerTimeseries } from "@/utils/datapoints";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { Statistics } from "../types";
import { getMaxGranularity } from "../utils";

function mapToStatistics(
  timeseriesListWithAggregates: DatapointAggregates[],
  timeseriesWithFirstData: DoubleDatapoints[],
  timeseriesWithLatestData: DoubleDatapoints[],
): Statistics | null {
  if (
    timeseriesListWithAggregates.length > 0 &&
    timeseriesListWithAggregates[0].datapoints.length > 0 &&
    timeseriesWithFirstData.length > 0 &&
    timeseriesWithFirstData[0].datapoints.length > 0 &&
    timeseriesWithLatestData.length > 0 &&
    timeseriesWithLatestData[0].datapoints.length > 0
  ) {
    const [
      {
        datapoints: [{ average, min, max, sum }],
      },
    ] = timeseriesListWithAggregates;
    const [
      {
        datapoints: [{ value: latestValue }],
      },
    ] = timeseriesWithFirstData;
    const [
      {
        datapoints: [{ timestamp: latestTimestamp, value: firstValue }],
      },
    ] = timeseriesWithLatestData;
    return {
      average: average!,
      min: min!,
      max: max!,
      sum: sum!,
      firstValue,
      latestValue,
      latestTimestamp,
    };
  }
  return null;
}

const math = create(all);

const compileTimeseriesExpression = (
  timeseriesListWithFirstData: DoubleDatapoints[],
  mathExpression?: string,
) => {
  const mathExpressionCompiled =
    typeof mathExpression === "string" ? math.compile(mathExpression) : null;
  if (mathExpressionCompiled === null) return timeseriesListWithFirstData;

  return [
    {
      ...timeseriesListWithFirstData[0],
      datapoints: [
        {
          ...timeseriesListWithFirstData[0]?.datapoints[0],
          value: mathExpressionCompiled.evaluate({
            DP: timeseriesListWithFirstData[0]?.datapoints[0]?.value,
          }),
        },
      ],
    },
  ] as DoubleDatapoints[];
};

type ReturnDataType = {
  timeseriesListWithAggregates: DatapointAggregates[] | null;
  isLoadingAggregates: boolean;
  errorAggregates: Error | null;
  limitedLocally?: boolean;
} | null;

const getAggregatedLocally = (
  timeseriesListWithoutAggregates: DoubleDatapoints[],
  mathExpression: string,
) => {
  const datapoints = timeseriesListWithoutAggregates[0]?.datapoints ?? [];

  const mathExpressionCompiled = math.compile(mathExpression);

  const timeseriesAggregates: {
    average: number | null;
    min: number | null;
    max: number | null;
    sum: number | null;
  } = {
    average: null,
    min: null,
    max: null,
    sum: null,
  };

  datapoints.forEach((datapoint) => {
    let y = datapoint.value;

    if (mathExpressionCompiled !== null) {
      y = mathExpressionCompiled.evaluate({ DP: y });
    }

    const minValue = y;
    const maxValue = y;

    if (
      minValue !== undefined &&
      (timeseriesAggregates.min === null || minValue < timeseriesAggregates.min)
    ) {
      timeseriesAggregates.min = minValue;
    }
    if (
      maxValue !== undefined &&
      (timeseriesAggregates.max === null || maxValue > timeseriesAggregates.max)
    ) {
      timeseriesAggregates.max = maxValue;
    }

    if (y !== undefined) {
      timeseriesAggregates.sum = timeseriesAggregates.sum
        ? timeseriesAggregates.sum + y
        : y;
    }
  });

  if (timeseriesAggregates.sum !== null && datapoints.length) {
    timeseriesAggregates.average = timeseriesAggregates.sum / datapoints.length;
  }

  const dataPoints: DatapointAggregates["datapoints"] = [
    { ...timeseriesAggregates, timestamp: new Date() },
  ] as DatapointAggregates["datapoints"];

  const timeseriesListWithAggregates = [
    {
      datapoints: dataPoints,
      isStep: false,
      isString: false,
      id: 0,
    },
  ];

  return {
    timeseriesListWithAggregates:
      timeseriesAggregates.min === null || timeseriesAggregates.max === null
        ? null
        : timeseriesListWithAggregates,
    isLoadingAggregates: false,
    errorAggregates: null,
  } as ReturnDataType;
};

const useAggregatedData = ({
  timeseriesId,
  timeSpan,
  mathExpression,
  count,
}: {
  timeseriesId: number;
  timeSpan: TimeSpan;
  mathExpression?: string | null;
  count?: number;
}) => {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const [data, setData] = useState<ReturnDataType>(null);

  useEffect(() => {
    if (count === undefined) {
      setData({
        timeseriesListWithAggregates: null,
        isLoadingAggregates: true,
        errorAggregates: null,
      });
      return;
    }
    const getData = async () => {
      let query: DatapointsMultiQuery = {
        items: [{ id: timeseriesId }],
        start: timeSpan[0],
        end: timeSpan[1],
        limit: 5000,
      };

      const isPossibleToGetStatisticsFromCDF = !mathExpression || count > 5000;

      if (isPossibleToGetStatisticsFromCDF) {
        query = {
          ...query,
          limit: 1,
          aggregates: ["average", "min", "max", "sum"],
          granularity: getMaxGranularity(timeSpan),
        };
      }

      try {
        const response = await client.datapoints.retrieve(query);
        if (!isPossibleToGetStatisticsFromCDF) {
          return setData(
            getAggregatedLocally(
              response as DoubleDatapoints[],
              mathExpression,
            ),
          );
        }
        setData({
          timeseriesListWithAggregates: response as DatapointAggregates[],
          isLoadingAggregates: false,
          errorAggregates: null,
          limitedLocally: !!mathExpression && count > 5000,
        });
      } catch (error) {
        setData({
          timeseriesListWithAggregates: null,
          isLoadingAggregates: false,
          errorAggregates: null,
        });
        handleAPIError(error);
      }
    };
    getData();
  }, [client.datapoints, count, mathExpression, timeSpan, timeseriesId]);

  return {
    ...data,
    isLoadingAggregates: !data || data.isLoadingAggregates,
  };
};

export function useGetStatistics(
  timeseriesId: number,
  timeSpan: TimeSpan,
  mathExpression?: string | null,
) {
  const handleAPIError = useHandleApiError();
  const [count, setCount] = useState<number>();
  const [isCountLoading, setIsCountLoading] = useState(true);

  const timeStart = timeSpan[0];
  const timeEnd = timeSpan[1];

  useEffect(() => {
    setIsCountLoading(true);
    const getCount = async () => {
      const res = await getAmountOfDatapointsPerTimeseries(
        [timeseriesId],
        timeStart,
        timeEnd,
      );
      setIsCountLoading(false);
      setCount(res[0].amountOfDatapoints);
      return res;
    };
    getCount();
  }, [timeStart, timeEnd, timeseriesId]);

  const {
    timeseriesListWithAggregates,
    isLoadingAggregates,
    errorAggregates,
    limitedLocally,
  } = useAggregatedData({
    timeseriesId,
    timeSpan,
    mathExpression,
    count,
  });

  const {
    timeseriesListWithData: timeseriesListWithFirstData,
    isLoading: isLoadingFirstData,
    error: errorFirstData,
  } = useGetTimeseriesListWithData<DoubleDatapoints>({
    items: [{ id: timeseriesId }],
    limit: 1,
    start: timeSpan[0],
    end: timeSpan[1],
  });
  const {
    timeseriesListWithLatestData,
    isLoading: isLoadingLatestData,
    error: errorLatestData,
  } = useGetTimeseriesListWithLatestData([
    { id: timeseriesId, before: timeSpan[1] },
  ]);
  const error = errorAggregates || errorFirstData || errorLatestData;
  if (error) {
    handleAPIError(error);
  }
  return {
    isDatasetToBigToApplyFormula: limitedLocally,
    statistics: mapToStatistics(
      timeseriesListWithAggregates || [],
      limitedLocally || !mathExpression
        ? timeseriesListWithFirstData
        : compileTimeseriesExpression(
            timeseriesListWithFirstData,
            mathExpression,
          ),
      limitedLocally || !mathExpression
        ? timeseriesListWithLatestData
        : compileTimeseriesExpression(
            timeseriesListWithLatestData,
            mathExpression,
          ),
    ),
    isLoading:
      isLoadingAggregates ||
      isLoadingFirstData ||
      isLoadingLatestData ||
      isCountLoading,
  };
}
