import { createContext, RefObject, useContext, useMemo } from "react";
import { PanZoomApi } from "@sasza/react-panzoom/types/types";

export type MoveHandler = (params: {
  position: { x: number; y: number };
  zoom: number;
}) => void;

type Context = {
  current: RefObject<PanZoomApi | null>;
  moveHandlers: Set<MoveHandler>;
};

const FloorPlanMapContext = createContext<Context>({
  current: { current: null },
  moveHandlers: new Set<MoveHandler>(),
});

type Props = {
  apiRef: RefObject<PanZoomApi | null>;
  moveHandlers: Set<MoveHandler>;
  children: React.ReactNode;
};

export function FloorPlanMapProvider(props: Props) {
  const value = useMemo(() => {
    return {
      moveHandlers: props.moveHandlers,
      current: props.apiRef,
    };
  }, [props.moveHandlers, props.apiRef]);

  return (
    <FloorPlanMapContext.Provider value={value}>
      {props.children}
    </FloorPlanMapContext.Provider>
  );
}

export function useFloorPlanMapApi() {
  const { current: apiRef } = useContext(FloorPlanMapContext);

  return apiRef.current;
}

export function useFloorPlanMapMoveHandlers() {
  const { moveHandlers } = useContext(FloorPlanMapContext);

  return moveHandlers;
}
