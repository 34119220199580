import AutoSizer from "react-virtualized-auto-sizer";
import { TimeSpan } from "@properate/common";
import { useEffect } from "react";
import { Timeseries } from "@cognite/sdk";
import dayjs from "@properate/dayjs";
import {
  DatapointAverages,
  getOptimalGranularity,
  mapToSimplePointsWithMetadataList,
  TimeseriesNavigator,
} from "@/features/analysis";
import { useGetTimeseriesListWithData } from "@/hooks/useGetTimeseriesListWithData";
import { AlarmDetailsTimespanFormIds } from "../AlarmDetailsTimespanSelector";
import { useFormValue } from "../../FormContext";

type AlarmTimeseriesNavigatorProps = {
  timeseriesWithDataPointsList: {
    timeseries: Timeseries;
    color: string;
  }[];
};

export enum AlarmDetailsZoomedTimespanFormIds {
  FromTime = "timeSpanSelector__ZoomedFromTime",
  ToTime = "timeSpanSelector__ZoomedToTime",
}

export const AlarmDetailsNavigatorFormDefaults = {
  [AlarmDetailsZoomedTimespanFormIds.FromTime]: {
    defaultValue: dayjs().subtract(1, "year").valueOf(),
  },
  [AlarmDetailsZoomedTimespanFormIds.ToTime]: {
    defaultValue: dayjs().valueOf(),
  },
};

export function AlarmTimeseriesNavigator({
  timeseriesWithDataPointsList,
}: AlarmTimeseriesNavigatorProps) {
  const [fromDate] = useFormValue<number>(AlarmDetailsTimespanFormIds.FromTime);
  const [toDate] = useFormValue<number>(AlarmDetailsTimespanFormIds.ToTime);

  const [zoomedFromDate, setZoomedFromDate] = useFormValue<number>(
    AlarmDetailsZoomedTimespanFormIds.FromTime,
  );
  const [zoomedToDate, setZoomedToDate] = useFormValue<number>(
    AlarmDetailsZoomedTimespanFormIds.ToTime,
  );

  useEffect(
    () => {
      if (fromDate === undefined && toDate === undefined) {
        return;
      }
      if (zoomedFromDate === undefined || zoomedFromDate <= fromDate) {
        setZoomedFromDate(fromDate);
      }
      if (zoomedToDate === undefined || zoomedToDate >= toDate) {
        setZoomedToDate(toDate);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromDate, toDate, zoomedFromDate, zoomedToDate],
  );

  const { timeseriesListWithData } =
    useGetTimeseriesListWithData<DatapointAverages>({
      items: timeseriesWithDataPointsList.map(({ timeseries }) => ({
        id: timeseries?.id,
      })),
      start: fromDate,
      end: toDate,
      aggregates: ["average", "min", "max"],
      granularity: getOptimalGranularity(
        [fromDate, toDate],
        timeseriesWithDataPointsList.length,
        25,
      ),
    });

  function handleChangeZoomedTimeSpan(zoomedTimeSpan: TimeSpan) {
    const newFromDate = zoomedTimeSpan[0];
    const newToDate = zoomedTimeSpan[1];

    if (newFromDate !== zoomedFromDate) {
      setZoomedFromDate(newFromDate);
    }
    if (newToDate !== zoomedToDate) {
      setZoomedToDate(newToDate);
    }
  }

  const simplePointsWithMetadadataList = mapToSimplePointsWithMetadataList(
    timeseriesListWithData,
    timeseriesWithDataPointsList.map(({ timeseries, color }) => ({
      id: timeseries?.id,
      hidden: false,
      color,
      unitSelected: timeseries?.unit ?? "",
    })),
  );

  return (
    <AutoSizer>
      {({ width }) => (
        <TimeseriesNavigator
          simplePointsWithMetadataList={simplePointsWithMetadadataList}
          timeSpan={[fromDate, toDate]}
          zoomedTimeSpan={[zoomedFromDate, zoomedToDate]}
          parentWidth={width}
          onChangeZoomedTimeSpan={handleChangeZoomedTimeSpan}
        />
      )}
    </AutoSizer>
  );
}
