import { Button } from "antd";
import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MaximimalizeButton = styled(Button)<{
  $withMargin: boolean;
  $withTitle: boolean;
}>`
  ${({ $withMargin }) => ($withMargin ? "margin-right: 30px;" : "")}
  ${({ $withTitle }) => ($withTitle ? "" : "margin-left: auto;")}
`;
