import { SortableElement } from "react-sortable-hoc";
import { useTranslations, MessageKey } from "@properate/translations";
import { CardKpi } from "@/components/CardKpi";
import { KPI, AlarmKPI, CertificationKPI } from "@/utils/types";

interface Props {
  kpi: Exclude<KPI, AlarmKPI | CertificationKPI>;
  label: string;
  value: number | null;
  precision: number;
  unit: string;
}

export const SortableKPI = SortableElement<Props>(
  ({ kpi, label, value, precision, unit }: Props) => {
    const t = useTranslations();
    return (
      <CardKpi
        key={kpi}
        title={t(`portfolio-view.kpi.labels.${label}` as MessageKey)}
        value={value || undefined}
        precision={precision}
        suffix={unit}
      />
    );
  },
);
