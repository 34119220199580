import {
  ActiveAlarmPeriod,
  AlertRecipient,
  getNumericPriority,
  getSimplifiedPriority,
  InactiveAlarmPeriod,
  PartialAlarmRule,
  SimplifiedAlarmPriority,
} from "@properate/common";
import { TranslationFunction } from "@properate/translations/src";
import { addSubTypeNotificationFieldOverrides } from "@/pages/alarms/details/AlarmRuleTypes";
import {
  CreateAlarmRuleCommonProps,
  FormContextEntry,
  FormEntryValidator,
} from "../FormContext/types";
import { arrayValidator, stringValidator } from "../FormContext/validators";
import { requiredSelect } from "../AlarmRuleTypes/common/utils";
import { getSubmitValueEntry } from "../FormContext/utils";
import { AlarmNotificationFormFields } from "./types";

function validateAllDayOrActive(
  t: TranslationFunction,
): FormEntryValidator<undefined> {
  return (value, entries) => {
    const alwaysActive =
      (
        entries[
          AlarmNotificationFormFields.AlwaysActive
        ] as FormContextEntry<boolean>
      ).value ?? false;
    const activePeriods =
      (
        entries[AlarmNotificationFormFields.ActivePeriods] as FormContextEntry<
          ActiveAlarmPeriod[]
        >
      ).value ?? [];

    return {
      valid: alwaysActive || activePeriods.length > 0,
      message: t(
        "alarm-details.notifications-page.active.select-all-day-or-active",
      ),
    };
  };
}

export function getAlarmNotificationsFormFields(alarmRule: PartialAlarmRule) {
  return {
    [AlarmNotificationFormFields.AlwaysActive]: {
      defaultValue:
        alarmRule?.condition?.type_specific?.schedule?.always_active ?? false,
      getValidator: validateAllDayOrActive,
    },
    [AlarmNotificationFormFields.ActivePeriods]: {
      defaultValue:
        alarmRule?.condition?.type_specific?.schedule?.active_periods ?? [],
      getValidator: validateAllDayOrActive,
    },
    [AlarmNotificationFormFields.ActivePeriodDay]: {
      defaultValue: undefined,
      skipWhenValidatingAll: true,
      getValidator: (t: TranslationFunction) =>
        stringValidator(
          t(requiredSelect, {
            fieldName: t(
              "alarm-details.notifications-page.active.select-active-period-day",
            ).toLowerCase(),
          }),
        ),
    },
    [AlarmNotificationFormFields.ActivePeriodTimeRange]: {
      defaultValue: undefined,
      skipWhenValidatingAll: true,
      getValidator: (t: TranslationFunction) =>
        arrayValidator(
          t(requiredSelect, {
            fieldName: t(
              "alarm-details.notifications-page.active.select-active-period-timerange",
            ).toLowerCase(),
          }),
          { minLength: 2, maxLength: 2 },
        ),
    },
    [AlarmNotificationFormFields.InactivePeriods]: {
      defaultValue:
        alarmRule?.condition?.type_specific?.schedule?.inactive_periods ?? [],
    },
    [AlarmNotificationFormFields.InactiveDateRange]: {
      defaultValue: undefined,
      skipWhenValidatingAll: true,
      getValidator: (t: TranslationFunction) =>
        arrayValidator(
          t(requiredSelect, {
            fieldName: t(
              "alarm-details.notifications-page.inactive.select-inactive-daterange",
            ).toLowerCase(),
          }),
          { minLength: 2, maxLength: 2 },
        ),
    },
    [AlarmNotificationFormFields.HolidayAlarms]: {
      defaultValue:
        alarmRule?.condition?.type_specific?.schedule?.active_on_holidays ??
        false,
    },
    [AlarmNotificationFormFields.Level]: {
      // Note: we set a default-value here due to an issue with ant designs
      // <Segmented/> that does not support rendering without a value selected.
      defaultValue:
        alarmRule.priority !== undefined
          ? getSimplifiedPriority(alarmRule.priority)
          : SimplifiedAlarmPriority.Medium,
      getValidator: (t: TranslationFunction) =>
        stringValidator(
          t(requiredSelect, {
            fieldName: t(
              "alarm-details.notifications-page.level.header",
            ).toLowerCase(),
          }),
        ),
    },
    [AlarmNotificationFormFields.Recipients]: {
      defaultValue: alarmRule?.condition?.alerts?.recipients ?? [],
    },
    [AlarmNotificationFormFields.Category]: {
      defaultValue: alarmRule?.category ?? undefined,
    },
    [AlarmNotificationFormFields.SubCategory]: {
      defaultValue: alarmRule?.sub_category ?? undefined,
    },
    [AlarmNotificationFormFields.RecipientName]: {
      defaultValue: undefined,
      skipWhenValidatingAll: true,
      getValidator: (t: TranslationFunction) =>
        stringValidator(
          t(requiredSelect, {
            fieldName: t(
              "alarm-details.notifications-page.recipients.name",
            ).toLowerCase(),
          }),
        ),
    },
    [AlarmNotificationFormFields.RecipientContact]: {
      defaultValue: undefined,
      skipWhenValidatingAll: true,
      getValidator: (t: TranslationFunction) =>
        stringValidator(
          t(requiredSelect, {
            fieldName: t(
              "alarm-details.notifications-page.recipients.contact",
            ).toLowerCase(),
          }),
        ),
    },
  };
}

export function getAlarmNotificationsAlarmRuleFields({
  entries,
}: CreateAlarmRuleCommonProps): PartialAlarmRule {
  const simplifiedPriority = getSubmitValueEntry<SimplifiedAlarmPriority>(
    entries,
    AlarmNotificationFormFields.Level,
  );
  const priority = simplifiedPriority
    ? getNumericPriority(simplifiedPriority)
    : undefined;

  const partialAlarmRule = {
    priority,
    condition: {
      type_specific: {
        schedule: {
          always_active: getSubmitValueEntry<boolean>(
            entries,
            AlarmNotificationFormFields.AlwaysActive,
          ),
          active_periods: getSubmitValueEntry<ActiveAlarmPeriod[]>(
            entries,
            AlarmNotificationFormFields.ActivePeriods,
          ),
          inactive_periods: getSubmitValueEntry<InactiveAlarmPeriod[]>(
            entries,
            AlarmNotificationFormFields.InactivePeriods,
          ),
          active_on_holidays: getSubmitValueEntry<boolean>(
            entries,
            AlarmNotificationFormFields.HolidayAlarms,
          ),
        },
      },
      alerts: {
        recipients: getSubmitValueEntry<AlertRecipient[]>(
          entries,
          AlarmNotificationFormFields.Recipients,
        ),
      },
    },
  };

  return addSubTypeNotificationFieldOverrides({ entries, partialAlarmRule });
}
