import { browserFirestore } from "@properate/firebase/src";
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { Analysis } from "@/features/analysis";

const collectionAnalysis = collection(
  browserFirestore,
  "analysis",
) as CollectionReference<Analysis>;

export async function createAnalysis(analysis: Analysis) {
  return addDoc(collectionAnalysis, analysis);
}

export async function deleteAnalysis(snapshotId: string) {
  return deleteDoc(doc(collectionAnalysis, snapshotId));
}

export async function updateAnalysis(
  snapshotId: string,
  analysis: Partial<Analysis>,
) {
  return setDoc(doc(collectionAnalysis, snapshotId), analysis, {
    merge: true,
  });
}
