import useSWR from "swr";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useHandleApiError } from "@/utils/api";
import { SettingsTimeseries } from "../types";
import { useGetAssets } from "./useGetAssets";

export function useGetParentAssets(
  settingsTimeseriesList: SettingsTimeseries[],
) {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const { assets, isLoading } = useGetAssets(settingsTimeseriesList);
  const assetParentIds = Array.from(
    new Set(
      assets
        .map((asset) => asset.parentId)
        .filter(
          (assetParentId): assetParentId is number =>
            typeof assetParentId === "number",
        ),
    ),
  );
  const { data: parentAssets = [], error } = useSWR(
    assetParentIds.length > 0
      ? {
          type: "assets.retrieve",
          assetParentIds,
        }
      : null,
    ({ assetParentIds }) =>
      client.assets.retrieve(
        assetParentIds.map((assetParentId) => ({
          id: assetParentId,
        })),
      ),
  );
  if (error) {
    handleAPIError(error);
  }
  return {
    parentAssets,
    isLoading,
  };
}
