import { useSidebarData } from "@properate/ui";
import { InternalId } from "@cognite/sdk";
import { NotesSidebarProps } from "../types";

export function useNotesDataSetIds() {
  const { buildings } = useSidebarData<NotesSidebarProps>();
  if (!buildings) {
    return [];
  }
  return buildings.map(({ id }) => ({ id })) as InternalId[];
}
