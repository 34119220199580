import dayjs, { Dayjs } from "@properate/dayjs";
import { Tag, DatePicker } from "antd";
import { useRef } from "react";
import { useTranslations } from "@properate/translations";
import { Granularity } from "@/utils/helpers";
import { getClosestGranularity } from "../../utils";

type CompareToPickerProps = {
  onChange: (nextStart: Date) => void;
  value: Date;
  currentStart: Date;
  granularity: Granularity;
};
export const CompareToPicker = ({
  onChange,
  value,
  currentStart,
  granularity,
}: CompareToPickerProps) => {
  const t = useTranslations();

  const ref = useRef<any>(null);
  const startLastYear = getClosestGranularity(
    dayjs(currentStart),
    dayjs(currentStart).subtract(1, "year"),
    granularity,
  );

  const startTwoYearsAgo = getClosestGranularity(
    dayjs(currentStart),
    dayjs(currentStart).subtract(2, "year"),
    granularity,
  );

  const startThreeYearsAgo = getClosestGranularity(
    dayjs(currentStart),
    dayjs(currentStart).subtract(3, "year"),
    granularity,
  );

  const presets: {
    label: string;
    value: Dayjs;
  }[] = [
    {
      label: t("energy.last-year"),
      value: startLastYear,
    },
    {
      label: dayjs().subtract(2, "year").format("YYYY"),
      value: startTwoYearsAgo,
    },
    {
      label: dayjs().subtract(3, "year").format("YYYY"),
      value: startThreeYearsAgo,
    },
  ];

  return (
    <DatePicker
      showToday={false}
      value={dayjs(value)}
      ref={ref}
      onChange={(date) => {
        if (date) {
          onChange(date.toDate());
        }
      }}
      renderExtraFooter={() => (
        <>
          {presets.map(({ label, value }) => (
            <Tag.CheckableTag
              checked={false}
              key={label}
              style={{
                color: "#12cf74",
                background: "#e6ffee",
                borderColor: "#8cf5b7",
              }}
              onClick={() => {
                onChange(value.toDate());
                ref.current?.blur();
              }}
            >
              {label}
            </Tag.CheckableTag>
          ))}
        </>
      )}
    />
  );
};
