import { BuildingSpec, WithSnapshotId } from "@properate/common";
import { useEffect, useMemo, useState } from "react";
import { useUser } from "@properate/auth";
import { useGetTasks } from "@properate/task-manager";
import { useGetAlarms } from "@/features/alarms";
import { useRootAssets } from "@/hooks/useRootAssets";
import { useBuildingsSpec } from "@/services/buildingSpec";
import { useAliasConfig } from "@/services/alias";
import { KPITypeToValues, useKPIs } from "@/services/kpis";
import {
  buildingActivityKPIConfiguration,
  buildingActivityKPIs,
  energyKPIConfiguration,
  energyKPIs,
  indoorClimateKPIConfiguration,
  indoorClimateKPIs,
  waterKPIConfiguration,
  waterKPIs,
  weatherKPIConfiguration,
  weatherKPIs,
} from "@/utils/kpi";
import useGetBuildingsAreal from "@/hooks/useGetBuildingsAreal";

export type BuildingWithKPI = ReturnType<typeof useBuildingsWithKPIs>[number];

export function useBuildingsWithKPIs(enabledKpis: string[]) {
  const { ownerAlias } = useUser();
  const { data: buildingsAreal } = useGetBuildingsAreal();
  const aliasConfig = useAliasConfig(ownerAlias);
  const rootAssets = useRootAssets();
  const [buildingInfo, setBuildingInfo] = useState<Record<
    string,
    BuildingSpec
  > | null>(null);
  const [kpiInfo, setKpiInfo] = useState<Record<
    string,
    WithSnapshotId<KPITypeToValues>
  > | null>(null);

  const buildingsSpec = useBuildingsSpec();
  const kpis = useKPIs();

  const alarms = useGetAlarms(
    enabledKpis.includes("alarms") ? rootAssets.map((asset) => asset.id) : [],
  );

  const tasks = useGetTasks();

  useEffect(() => {
    if (buildingsSpec) {
      setBuildingInfo(
        buildingsSpec.reduce<Record<string, BuildingSpec>>(function (map, obj) {
          map[obj.snapshotId] = obj;
          return map;
        }, {}),
      );
    }
  }, [buildingsSpec]);

  useEffect(() => {
    if (kpis) {
      setKpiInfo(
        kpis.reduce<Record<string, WithSnapshotId<KPITypeToValues>>>(function (
          map,
          obj,
        ) {
          map[obj.snapshotId] = obj;
          return map;
        }, {}),
      );
    }
  }, [kpis]);

  return useMemo(() => {
    if (buildingInfo === null || kpiInfo === null) {
      return [];
    }
    return rootAssets.map((building) => {
      const usableFloorArea = buildingsAreal[building.externalId]?.area;
      return {
        key: building.id,
        image: buildingInfo[building.id]?.imageUrl,
        lat: building.metadata?.Lat ? parseFloat(building.metadata.Lat) : 0,
        lng: building.metadata?.Lon ? parseFloat(building.metadata.Lon) : 0,
        building: {
          metadata: building.metadata,
          name: `${building.metadata!["Adresse"]}, ${
            building.metadata!["Poststed"]
          }`,
          alias: aliasConfig?.buildings?.[building.id],
          owner: building.metadata?.owner,
          buildingAreal: buildingsAreal[building.externalId],
          yearBuilt: buildingInfo[building.id]?.yearBuilt,
        },
        certifications: buildingInfo[building.id]?.certifications,
        area: buildingsAreal[building.externalId]?.area,
        ...energyKPIs.reduce((prev, kpi) => {
          const { calculate } = energyKPIConfiguration[kpi].metadata;
          const energyKPIValues = kpiInfo[building.id]?.energy;
          return {
            ...prev,
            [kpi]: energyKPIValues
              ? calculate(energyKPIValues, usableFloorArea)
              : null,
          };
        }, {}),
        ...waterKPIs.reduce((prev, kpi) => {
          const { calculate } = waterKPIConfiguration[kpi].metadata;
          const waterKPIValues = kpiInfo[building.id]?.water;
          return {
            ...prev,
            [kpi]: waterKPIValues
              ? calculate(waterKPIValues, usableFloorArea)
              : null,
          };
        }, {}),
        ...weatherKPIs.reduce((prev, kpi) => {
          const { calculate } = weatherKPIConfiguration[kpi].metadata;
          const weatherKPIValues = kpiInfo[building.id]?.weather;
          return {
            ...prev,
            [kpi]: weatherKPIValues
              ? calculate(weatherKPIValues, usableFloorArea)
              : null,
          };
        }, {}),
        ...indoorClimateKPIs.reduce((prev, kpi) => {
          const { calculate } = indoorClimateKPIConfiguration[kpi].metadata;
          const indoorClimateKPIValues = kpiInfo[building.id]?.indoor_climate;
          return {
            ...prev,
            [kpi]: indoorClimateKPIValues
              ? calculate(indoorClimateKPIValues, usableFloorArea)
              : null,
          };
        }, {}),
        ...buildingActivityKPIs.reduce((prev, kpi) => {
          const { calculate } = buildingActivityKPIConfiguration[kpi].metadata;
          const buildingActivityKPIValues = kpiInfo[building.id]?.activity;
          return {
            ...prev,
            [kpi]: buildingActivityKPIValues
              ? calculate(buildingActivityKPIValues, usableFloorArea)
              : null,
          };
        }, {}),
        alarms: alarms.data?.filter(
          (alarm) => alarm.buildingId === building.id && !!alarm.activeEvent,
        )?.length,
        errorAlarms: alarms.data?.filter(
          (alarm) =>
            alarm.buildingId === building.id &&
            alarm.severity === "ERROR" &&
            !!alarm.activeEvent,
        )?.length,
        warningAlarms: alarms.data?.filter(
          (alarm) =>
            alarm.buildingId === building.id &&
            alarm.severity === "WARNING" &&
            !!alarm.activeEvent,
        )?.length,
        infoAlarms: alarms.data?.filter(
          (alarm) =>
            alarm.buildingId === building.id &&
            alarm.severity === "INFO" &&
            !!alarm.activeEvent,
        )?.length,
        openTasks: tasks.data?.filter(
          (task) =>
            task.buildingId === building.id && task.status === "InProgress",
        )?.length,
        overdueTasks: tasks.data?.filter(
          (task) =>
            task.buildingId === building.id &&
            task.status === "InProgress" &&
            task.dueDate &&
            task.dueDate < Number(new Date()),
        )?.length,
      };
    });
  }, [
    buildingInfo,
    kpiInfo,
    rootAssets,
    aliasConfig,
    alarms.data,
    tasks.data,
    buildingsAreal,
  ]);
}

export type BuildingWithKPIs = NonNullable<
  ReturnType<typeof useBuildingsWithKPIs>
>[0];
