import { useNavigate, useLoaderData, Await, useSubmit } from "react-router-dom";
import { lazy, Suspense } from "react";
import { ProperateFile } from "@properate/file-viewer/src/components/types";
import { Spin } from "antd";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "@/context/CogniteClientContext";

const FileViewer = lazy(() => import("@properate/file-viewer"));

type FilePreviewPageData = {
  files: Promise<ProperateFile[]>;
  fileId: number;
  search: string;
  hidden: boolean;
};

export const FilePreviewPage = () => {
  const t = useTranslations();
  const page = useLoaderData() as FilePreviewPageData;
  const submit = useSubmit();
  const { client } = useCogniteClient();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(`../files`);
  };

  const onRemoveFile = async () => {
    submit(
      {
        fileId: page.fileId,
      },
      {
        method: "delete",
        encType: "application/json",
      },
    );
  };

  const onGetUrn = async () => {
    submit(null, {
      method: "patch",
      encType: "application/json",
    });
  };

  const handleChangeFile = (fileId: number | string) => {
    const filesParam = page.hidden ? "trash" : "files";
    navigate({
      pathname: `../${filesParam}/${
        page.search ? `${page.search}/` : ""
      }preview/${fileId}`,
      search: window.location.search,
    });
  };

  return (
    <div className="absolute h-screen w-screen left-0 top-0 bg-background">
      <Suspense
        fallback={
          <Spin
            style={{
              height: "100%",
              width: "100%",
              display: "grid",
              placeContent: "center",
            }}
          />
        }
      >
        <Await
          resolve={page.files}
          errorElement={<p>{t("files.error-loading-files")}</p>}
        >
          {(properateFiles: ProperateFile[]) => {
            if (!properateFiles.find((file) => file.id === page.fileId)) {
              handleChangeFile(properateFiles[0].id);
            }
            return (
              <FileViewer
                autoScreenDetection
                files={properateFiles}
                file={
                  properateFiles.find((file) => file.id === page.fileId) ||
                  properateFiles[0]
                }
                onClose={goBack}
                onRemoveFile={onRemoveFile}
                path={["Files"]}
                client={client}
                onGetUrn={onGetUrn}
                onChangeFile={handleChangeFile}
              />
            );
          }}
        </Await>
      </Suspense>
    </div>
  );
};
