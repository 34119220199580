import { useTranslations } from "@properate/translations";
import { Datapoints } from "@cognite/sdk";
import { CalculationFlow } from "@/pages/calculationFlow/types";

interface IProps {
  flows: CalculationFlow[];
  activations: Datapoints[];
}

export function MeasureSummary({ flows, activations }: IProps) {
  const t = useTranslations();

  const getActivation = (flow: CalculationFlow) => {
    const dps = activations.find((dps) => {
      dps.externalId === flow.metadata!.activationExternalId;
    });
    return dps && dps.datapoints.length > 0
      ? (dps.datapoints[0].value as number)
      : 0;
  };

  return (
    <>
      <h3>
        {t("energy-flexing.measures.activated-kw")}:{" "}
        {flows.reduce((acc, flow) => {
          return (
            acc + (getActivation(flow) / 100) * flow.metadata!.potential.maxKW
          );
        }, 0)}
        {" kW"}
      </h3>
      <h3>
        {t("energy-flexing.measures.potential-total-kw")}:{" "}
        {flows.reduce((acc, flow) => acc + flow.metadata!.potential.maxKW, 0)}{" "}
        {"kW"}
      </h3>
    </>
  );
}
