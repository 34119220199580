import { useGetFirestoreDocument } from "@properate/ui";
import { useMemo } from "react";
import { doc, getFirestore } from "firebase/firestore";
import { OrganizationSettings } from "@/utils/types";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";

export function useOrganizationSettings() {
  const currentBuilding = useCurrentBuilding();

  const organization = currentBuilding?.metadata?.owner;

  return useGetFirestoreDocument<OrganizationSettings>(
    useMemo(
      () => doc(getFirestore(), `organizationSettings/${organization}`),
      [organization],
    ),
  );
}
