import { SortableHandle } from "react-sortable-hoc";
import { Tooltip } from "antd";
import Icon from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { ReactComponent as DragHandleIcon } from "./icons/drag-handle.svg";
import { HandleContainer } from "./elements";

export const Handle = SortableHandle(() => {
  const t = useTranslations();
  return (
    <HandleContainer tabIndex={0}>
      <Tooltip placement="top" title={t("dashboard.widgets.drag-and-drop")}>
        <Icon component={DragHandleIcon} />
      </Tooltip>
    </HandleContainer>
  );
});
