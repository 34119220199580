import dayjs from "@properate/dayjs";

export type State = {
  start?: string;
  end?: string;
  startNext?: string;
  endNext?: string;
  granularity?: string;
  selectedIds?: string[];
  energyType?: string;
  tenantRootAssetId: string | null;
  currentBuilding?: string;
  building?: string;
};
export const getEnergyStateFromUrl = (
  pathname: string,
  query: string,
): State | null => {
  const urlParts = pathname.split("/");
  const searchParams = new URLSearchParams(query);
  const tenantRootAssetId = searchParams.get("root");
  const building = urlParts[2];
  const energyPage = urlParts[3];
  if (energyPage === "energyConsumption" || energyPage === "energyCompare") {
    const selectedIds = searchParams.getAll("id");
    const energyConsumptionState = {
      building,
      energyPage,
      energyType: urlParts[4],
      granularity: urlParts[5],
      tenantRootAssetId,
      selectedIds,
      start: urlParts[7],
      end: urlParts[9],
    };
    if (energyPage === "energyConsumption") {
      return energyConsumptionState;
    }
    const startNext = urlParts[11];
    const endNext = urlParts[13];
    return {
      ...energyConsumptionState,
      startNext,
      endNext,
    };
  }
  if (energyPage === "energyFlow") {
    return {
      building,
      tenantRootAssetId,
    };
  }
  return null;
};
export const getEnergyLink = (state: State | null) => {
  if (state && state.start && state.end) {
    if (state.energyType && state.granularity) {
      // navigated to new building
      if (state.currentBuilding && state.building !== state.currentBuilding) {
        return `energyConsumption/${state.energyType}/${state.granularity}/s/${state.start}/e/${state.end}`;
      }
      if (state.selectedIds && state.tenantRootAssetId) {
        return `energyConsumption/${state.energyType}/${state.granularity}/s/${
          state.start
        }/e/${state.end}?root=${state.tenantRootAssetId}&${state.selectedIds
          .map((id) => "id=" + id)
          .join("&")}`;
      }
      return `energyConsumption/${state.energyType}/${state.granularity}/s/${state.start}/e/${state.end}`;
    }
    if (state.tenantRootAssetId) {
      return `energyConsumption/h/h/s/${state.start}/e/${state.end}?root=${state.tenantRootAssetId}`;
    }
    return `energyConsumption/h/h/s/${state.start}/e/${state.end}`;
  }
  return "energyConsumption";
};

export const getEnergyCompareLink = (state: State | null) => {
  if (state && state.start && state.end) {
    const nextStart = state.startNext
      ? state.startNext
      : `${dayjs(Number(state.start)).subtract(365, "d").valueOf()}`;
    const nextEnd = state.endNext
      ? state.endNext
      : `${dayjs(Number(state.end)).subtract(365, "d").valueOf()}`;
    if (state.energyType && state.granularity) {
      // navigated to new building
      if (state.currentBuilding && state.building !== state.currentBuilding) {
        return `energyCompare/${state.energyType}/${state.granularity}/s/${state.start}/e/${state.end}/s/${nextStart}/e/${nextEnd}`;
      }
      if (state.selectedIds && state.tenantRootAssetId) {
        return `energyCompare/${state.energyType}/${
          state.granularity
        }/s/${state.start.valueOf()}/e/${state.end.valueOf()}/s/${nextStart.valueOf()}/e/${nextEnd.valueOf()}?root=${
          state.tenantRootAssetId
        }&${state.selectedIds.map((id) => "id=" + id).join("&")}`;
      }
      return `energyCompare/${state.energyType}/${
        state.granularity
      }/s/${state.start.valueOf()}/e/${state.end.valueOf()}/s/${nextStart.valueOf()}/e/${nextEnd.valueOf()}`;
    }

    if (state.tenantRootAssetId) {
      return `energyCompare/h/h/s/${state.start.valueOf()}/e/${state.end.valueOf()}/s/${nextStart.valueOf()}/e/${nextEnd.valueOf()}?root=${
        state.tenantRootAssetId
      }`;
    }

    return `energyCompare/h/h/s/${state.start.valueOf()}/e/${state.end.valueOf()}/s/${nextStart.valueOf()}/e/${nextEnd.valueOf()}`;
  }

  return "energyCompare";
};

export const getEnergyFlowLink = (state: State | null) => {
  return state && state.start && state.end && state.tenantRootAssetId
    ? `energyFlow/s/${state.start}/e/${state.end}?root=${state.tenantRootAssetId}`
    : "energyFlow";
};
