import { Form } from "./elements";
import { TimeseriesSelectionBuildingFilter } from "./TimeseriesSelectionBuildingFilter";
import { TimeseriesSelectionSearch } from "./TimeseriesSelectionSearch";

interface Props {
  hideBuildingFilter: boolean;
  buildingId: number;
  onChangeSearch: (value: string) => unknown;
  onChangeBuildingId: (value: number) => unknown;
  initialSearch: string;
}

export function TimeseriesSelectionMainFilter({
  hideBuildingFilter,
  buildingId,
  onChangeSearch,
  onChangeBuildingId,
  initialSearch,
}: Props) {
  return (
    // Prevent page refresh
    <Form onSubmit={(event) => event.preventDefault()}>
      <TimeseriesSelectionSearch
        onChange={onChangeSearch}
        defaultValue={initialSearch}
      />
      {!hideBuildingFilter && (
        <TimeseriesSelectionBuildingFilter
          value={buildingId}
          onChange={onChangeBuildingId}
        />
      )}
    </Form>
  );
}
