import { browserFirestore } from "@properate/firebase";
import { collection } from "firebase/firestore";
import { Alarm } from "@properate/common";
import { useGetFirestoreCollection } from "@properate/ui";

const alarmsCollection = collection(browserFirestore, "alarms");

export function useGetAlarms(buildingIds: number[]) {
  const { data, isLoading, error } = useGetFirestoreCollection<Alarm>(
    buildingIds.length > 0 ? alarmsCollection : null,
  );

  return {
    data: data
      ? data.filter((alarm) => buildingIds.includes(alarm.buildingId))
      : undefined,
    isLoading,
    error,
  };
}
