import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import {
  DatapointReducerActionType,
  useMeterDataDispatch,
} from "./MeterDataContext";

export function AddModalRowButton() {
  const t = useTranslations();
  const dispatch = useMeterDataDispatch();

  function handleAddClick() {
    dispatch({
      type: DatapointReducerActionType.addRow,
    });
  }

  return (
    <Button
      htmlType={"button"}
      type="default"
      block
      icon={<PlusOutlined />}
      onClick={handleAddClick}
    >
      {t("timeseries.data-points-input.add")}
    </Button>
  );
}
