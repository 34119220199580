export const contactTypeInputOptions = [
  {
    value: "owner",
    max: 20,
  },
  {
    value: "property-manager",
    max: 20,
  },
  {
    value: "property-management",
    max: 20,
  },
  {
    value: "contact-person",
    max: 20,
  },
  {
    value: "operations-manager",
    max: 20,
  },
  {
    value: "caretaker",
    max: 20,
  },
  {
    value: "key-account-manager",
    max: 20,
  },
  {
    value: "technical-account-manager",
    max: 20,
  },
  {
    value: "technician-ventilation",
    max: 20,
  },
  {
    value: "technician-refrigeration",
    max: 20,
  },
  {
    value: "technician-automation",
    max: 20,
  },
  {
    value: "technician-plumbing",
    max: 20,
  },
  {
    value: "technician-fire-safety",
    max: 20,
  },
  {
    value: "technician-electricity",
    max: 20,
  },
  {
    value: "technician",
    max: 20,
  },
  {
    value: "backend",
    max: 20,
  },
  {
    value: "other",
    max: 40,
  },
];
