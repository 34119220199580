export function getMinWidth(options: Array<{ label: string }> | string[]) {
  const longestLabelLength = options
    .map((option) => (typeof option === "string" ? option : option.label))
    .reduce((longestLabelLengthCurrent, label) => {
      return label.length > longestLabelLengthCurrent
        ? label.length
        : longestLabelLengthCurrent;
    }, Number.MIN_SAFE_INTEGER);
  // Add a buffer
  return `${longestLabelLength + 8}ch`;
}
