import keycloak from "@/keycloak";

/**
 * Get the current user from Keycloak, synchronously and outside of the React
 * context. This is useful for mutations, and it's safe to use inside of
 * components because we only render the UI after the user is loaded.
 *
 * @returns The current user email address
 */
export function getCurrentUser(): string {
  if (!keycloak.idTokenParsed) {
    throw new Error("'getCurrentUser' called before the app was initialized");
  }

  return keycloak.idTokenParsed.email;
}
