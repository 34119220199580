import { Button, TableColumnType } from "antd";
import { Asset, Timeseries } from "@cognite/sdk";
import {
  formatSubBuildingFromExternalId,
  formatUnit,
  getSystemCodeFromExternalId,
  TimeSpan,
} from "@properate/common";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  SettingOutlined,
  DeleteOutlined,
  BarChartOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import { useTranslationsWithFallback } from "@properate/translations";
import { ColorPicker } from "@/components/ColorPicker/ColorPicker";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { MessageKey } from "@/utils/types";
import { getSystemTranslationKey } from "@/utils/tfm";
import { ModalStatistics } from "../ModalStatistics/ModalStatistics";
import { ModalTimeseries } from "../ModalTimeseries/ModalTimeseries";
import { TableRow, Analysis, SettingsTimeseries } from "../../types";
import { useGetTableRows, useActiveModalRow } from "../../hooks";
import { isScatterplotAnalysisWithSettingsTimeseriesX } from "../../utils";
import styles from "./TimeseriesTable.module.css";

const ICON_CELL_WIDTH = 48;
const ROW_HEIGHT = 53;

interface Props {
  analysis: Analysis;
  zoomedTimeSpan: TimeSpan;
  highlightedTimeseriesIds: number[];
  onChangeAnalysis: (analysis: Partial<Analysis>) => unknown;
  onClickHighlight: (timeseriesId: number) => unknown;
}

export const TimeseriesTable = ({
  analysis,
  zoomedTimeSpan,
  highlightedTimeseriesIds,
  onChangeAnalysis,
  onClickHighlight,
}: Props) => {
  const t = useTranslationsWithFallback();
  const { tableRows, hasTimeseriesFromDifferentBuilding } = useGetTableRows(
    isScatterplotAnalysisWithSettingsTimeseriesX(analysis)
      ? [
          {
            ...analysis.settingsTimeseriesX,
            hidden: false,
          },
          ...analysis.settingsTimeseriesList,
        ]
      : analysis.settingsTimeseriesList,
  );
  const {
    activeModalRow: activeModalRowSettings,
    openModal: openModalSettings,
    closeModal: closeModalSettings,
  } = useActiveModalRow(tableRows);
  const {
    activeModalRow: activeModalRowStatistics,
    openModal: openModalStatistics,
    closeModal: closeModalStatistics,
  } = useActiveModalRow(tableRows);

  function getIsRowForTimeseriesX(timeseriesId: number) {
    return (
      isScatterplotAnalysisWithSettingsTimeseriesX(analysis) &&
      analysis.settingsTimeseriesX.id === timeseriesId
    );
  }

  function getRoomName(room: Asset) {
    const description = room.description ? ` ${room.description}` : "";
    return `${room.name} ${description}`;
  }

  function getExtendedDescription(timeseries: Timeseries, room: Asset | null) {
    const roomNumber = timeseries.metadata?.room_no
      ? ` (${timeseries.metadata?.room_no})`
      : "";
    const roomName = room ? getRoomName(room) : null;
    const roomNameAsString = roomName ? ` ${roomName}` : "";
    return `${timeseries.description || ""}${roomNumber}${roomNameAsString}`;
  }

  const columns: Array<TableColumnType<TableRow>> = [
    ...(isScatterplotAnalysisWithSettingsTimeseriesX(analysis)
      ? [
          {
            title: t("analysis.details.table.axis"),
            key: "axis",
            width: 45,
            align: "center",
            render: (_, { settingsTimeseries: { id } }) =>
              analysis.settingsTimeseriesX.id === id ? "X" : "Y",
          } as TableColumnType<TableRow>,
        ]
      : []),
    {
      title: t("analysis.details.table.color"),
      key: "color",
      align: "center",
      width: ICON_CELL_WIDTH + 16,
      onCell: () => ({
        style: {
          // Centering with align: "center" uses "text-align: center", which does not center block elements,
          // hence below workaround
          display: "flex",
          placeContent: "center",
          // By using content-box box sizing, we avoid having to hardcode the padding & border sizes
          boxSizing: "content-box",
          height: "28px",
        },
      }),
      render: (_, { settingsTimeseries: { id, color }, timeseries }) => (
        <ColorPicker
          value={color}
          onChange={(color) => handleChangeColor(id, color)}
          disabled={!timeseries}
        />
      ),
    },
    {
      title: t("analysis.details.table.unit"),
      key: "unit",
      align: "center",
      width: ICON_CELL_WIDTH + 32,
      render: (_, { settingsTimeseries: { unitSelected } }) =>
        formatUnit(unitSelected),
    },
    {
      title: t("analysis.details.table.building"),
      key: "building",
      render: (_, { timeseries }) =>
        timeseries?.externalId &&
        formatSubBuildingFromExternalId(timeseries.externalId),
    },
    ...(hasTimeseriesFromDifferentBuilding
      ? [
          {
            title: t("analysis.details.table.main-building"),
            dataIndex: "mainBuildingAddress",
          } as TableColumnType<TableRow>,
        ]
      : []),
    {
      title: t("analysis.details.table.system"),
      key: "system",
      render: (_, { timeseries }) => {
        const system =
          timeseries?.externalId &&
          getSystemCodeFromExternalId(timeseries.externalId);
        return system ? (
          <div>
            {system}{" "}
            {t.fallback([getSystemTranslationKey(system) as MessageKey])}
          </div>
        ) : null;
      },
    },
    {
      title: t("analysis.details.table.description"),
      key: "descriptionExtended",
      render: (_, { timeseries, room }) =>
        timeseries
          ? getExtendedDescription(timeseries, room)
          : "Denne tidsserien finnes ikke lenger",
    },
    {
      title: t("analysis.details.table.name"),
      render: (_, { timeseries }) => timeseries?.name,
    },
    {
      key: "statistics",
      width: ICON_CELL_WIDTH,
      align: "center",
      render: (_, { settingsTimeseries: { id }, timeseries }) => {
        return (
          timeseries && (
            <Button
              title={t("analysis.details.table.statistics")}
              icon={<BarChartOutlined />}
              onClick={() => openModalStatistics(id)}
            />
          )
        );
      },
    },
    {
      key: "highlight",
      width: ICON_CELL_WIDTH,
      align: "center",
      render: (_, { settingsTimeseries: { id }, timeseries }) => {
        const isRowForTimeseriesX = getIsRowForTimeseriesX(id);
        return (
          timeseries &&
          !isRowForTimeseriesX && (
            <Button
              title={t("analysis.details.table.highlight")}
              icon={<HighlightOutlined />}
              onClick={() => onClickHighlight(id)}
            />
          )
        );
      },
    },
    {
      key: "hidden",
      width: ICON_CELL_WIDTH,
      align: "center",
      render: (_, { settingsTimeseries: { id, hidden }, timeseries }) => {
        const isRowForTimeseriesX = getIsRowForTimeseriesX(id);
        return (
          timeseries &&
          !isRowForTimeseriesX && (
            <Button
              icon={hidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              title={
                hidden
                  ? t("analysis.details.table.show")
                  : t("analysis.details.table.hide")
              }
              onClick={() => toggleTimeseriesVisibility(id, !hidden)}
            />
          )
        );
      },
    },
    {
      key: "modalTimeseries",
      width: ICON_CELL_WIDTH,
      align: "center",
      render: (_, { timeseries }) =>
        timeseries && (
          <Button
            type="link"
            title={t("analysis.details.table.settings")}
            icon={<SettingOutlined />}
            onClick={() => openModalSettings(timeseries.id)}
          />
        ),
    },
    {
      key: "remove",
      align: "center",
      width: ICON_CELL_WIDTH,
      render: (_, { settingsTimeseries: { id } }) => {
        const isRowForTimeseriesX = getIsRowForTimeseriesX(id);
        return (
          !isRowForTimeseriesX && (
            <Button
              icon={<DeleteOutlined />}
              title={t("analysis.details.table.delete")}
              onClick={() => removeTimeseries(id)}
            />
          )
        );
      },
    },
  ];

  function removeTimeseries(id: number) {
    onChangeAnalysis({
      settingsTimeseriesList: analysis.settingsTimeseriesList.filter(
        (settingsTimeseries) => settingsTimeseries.id !== id,
      ),
    });
  }

  function updateSettingsTimeseries(
    id: number,
    settingsTimeseriesUpdated: Partial<SettingsTimeseries>,
  ) {
    if (
      isScatterplotAnalysisWithSettingsTimeseriesX(analysis) &&
      analysis.settingsTimeseriesX.id === id
    ) {
      return onChangeAnalysis({
        settingsTimeseriesX: {
          ...analysis.settingsTimeseriesX,
          ...settingsTimeseriesUpdated,
        },
      });
    }
    onChangeAnalysis({
      settingsTimeseriesList: analysis.settingsTimeseriesList.map(
        (settingsTimeseries) => {
          if (settingsTimeseries.id === id) {
            return {
              ...settingsTimeseries,
              ...settingsTimeseriesUpdated,
            };
          }
          return settingsTimeseries;
        },
      ),
    });
  }

  function toggleTimeseriesVisibility(id: number, hidden: boolean) {
    updateSettingsTimeseries(id, {
      hidden,
    });
  }

  function handleChangeColor(id: number, color: string) {
    updateSettingsTimeseries(id, {
      color,
    });
  }

  function handleOK(settingsTimeseries: SettingsTimeseries) {
    updateSettingsTimeseries(
      activeModalRowSettings!.timeseries!.id,
      settingsTimeseries,
    );
    closeModalSettings();
  }

  return (
    <>
      <ModalTimeseries
        open={Boolean(activeModalRowSettings)}
        timeseries={
          activeModalRowSettings ? activeModalRowSettings.timeseries : null
        }
        settingsTimeseries={
          activeModalRowSettings
            ? activeModalRowSettings.settingsTimeseries
            : null
        }
        onCancel={closeModalSettings}
        onOk={handleOK}
      />
      <ModalStatistics
        open={Boolean(activeModalRowStatistics)}
        timeseries={
          activeModalRowStatistics
            ? activeModalRowStatistics.timeseries!
            : undefined
        }
        settingsTimeseries={
          activeModalRowStatistics
            ? activeModalRowStatistics.settingsTimeseries
            : undefined
        }
        onCancel={closeModalStatistics}
        initialTimeSpan={zoomedTimeSpan}
      />
      <TableWithoutDefaultSort
        dataSource={tableRows}
        columns={columns}
        pagination={false}
        scroll={{
          y: ROW_HEIGHT * 4,
        }}
        rowKey={({ settingsTimeseries }) => settingsTimeseries.id}
        rowClassName={({ settingsTimeseries: { id } }) => {
          const isTimeseriesXRow = getIsRowForTimeseriesX(id);
          if (isTimeseriesXRow) {
            // Timeseries X can't be highlighted, so no need to check further
            return styles["top-row"];
          }
          const isRowHighlighted = highlightedTimeseriesIds.includes(id);
          return isRowHighlighted ? styles["highlighted-row"] : "";
        }}
      />
    </>
  );
};
