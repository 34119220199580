import styled from "styled-components";

export const Beacon = styled.button`
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: rgb(85, 85, 85);
  font-size: 16px;
  line-height: 1;
  padding: 8px;
  appearance: none;
  display: inline-block;
  height: 36px;
  position: relative;
  width: 36px;
  z-index: 999;

  @keyframes joyride-beacon-inner {
    20% {
      opacity: 0.9;
    }

    90% {
      opacity: 0.7;
    }
  }

  @keyframes joyride-beacon-outer {
    0% {
      transform: scale(1);
    }

    45% {
      opacity: 0.7;
      transform: scale(0.75);
    }

    100% {
      opacity: 0.9;
      transform: scale(1);
    }
  }
  .joyride-beacon-inner {
    animation: 1.2s ease-in-out 0s infinite normal none running
      joyride-beacon-inner;
    background-color: rgb(255, 166, 0);
    border-radius: 50%;
    display: block;
    height: 50%;
    left: 50%;
    opacity: 0.7;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
  .joyride-beacon-outer {
    animation: 1.2s ease-in-out 0s infinite normal none running
      joyride-beacon-outer;
    background-color: rgba(255, 166, 0, 0.2);
    border: 2px solid rgb(255, 166, 0);
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    transform-origin: center center;
    width: 100%;
  }
`;
