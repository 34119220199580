import { FunctionComponent } from "react";
import { Select } from "antd";

const Option = Select.Option;

const unitOptions = [
  "",
  "%",
  "W",
  "kW",
  "kWh",
  "MWh",
  "NOK",
  "NOK/kWh",
  "Pa",
  "kPa",
  "co2",
  "dB",
  "dBm",
  "l/h",
  "l/s",
  "lm",
  "minutes",
  "hours",
  "m",
  "m³",
  "m³/h",
  "m³/s",
  "°C",
  "V",
  "A",
];

interface Props {
  onChange: (value: string) => any;
  value: string;
}

export const UnitSelect: FunctionComponent<Props> = ({ onChange, value }) => {
  return (
    <Select
      id="unit"
      showAction={["focus", "click"]}
      showSearch
      style={{ width: "100%" }}
      value={value}
      onChange={onChange}
      size="small"
    >
      {unitOptions.map((unit) => (
        <Option key={unit} value={unit}>
          {unit}
        </Option>
      ))}
    </Select>
  );
};
