import { Select, Space } from "antd";
import { useTranslations } from "@properate/translations";

export const EditValue = ({
  value,
  setValue,
  validValues,
  defaultValue,
}: {
  value: string;
  setValue: (value: string) => void;
  validValues: Record<string, string>;
  defaultValue: string | null;
}) => {
  const t = useTranslations();

  const values = Object.entries(validValues).sort(([keyOne], [keyTwo]) =>
    keyOne.localeCompare(keyTwo),
  );

  return (
    <Space>
      <b>{t("calendar.value")}:</b>
      {values.length === 1 ? (
        `${values[0][1] || values[0][0]}`
      ) : (
        <Select
          value={value}
          onSelect={(value: string) => setValue(value)}
          style={{ width: 160 }}
        >
          {values.map((v) => (
            <Select.Option key={v[0]}>
              {v[1] || v[0]}
              {defaultValue === v[0] ? ` ${t("calendar.default-empty")}` : ""}
            </Select.Option>
          ))}
        </Select>
      )}
    </Space>
  );
};
