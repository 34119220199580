import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { SchemaKpi } from "./SchemaKpi";

interface SortableKPIListProps {
  kpis: any[];
  isSettingsButtonVisible?: boolean;
  isSettingsButtonDisabled?: boolean;
  onChangeTimeseriesInfo: (type: any, timeseries: any) => unknown;
  onDeleteTimeseries: (id: any, timeseries: any) => unknown;
  onClickSettingsButton: () => unknown;
}

export const SortableKPIList = SortableContainer<SortableKPIListProps>(
  ({
    kpis,
    isSettingsButtonVisible = false,
    isSettingsButtonDisabled = false,
    onChangeTimeseriesInfo,
    onDeleteTimeseries,
    onClickSettingsButton,
  }: SortableKPIListProps) => (
    <div style={{ display: "flex", gap: 16, flexWrap: "wrap" }}>
      {kpis.map((kpi, index) => (
        <SortableKPI
          key={kpi.id}
          kpi={kpi}
          index={index}
          onChangeTimeseriesInfo={(timeseries) =>
            onChangeTimeseriesInfo(kpi.type, timeseries)
          }
          onDeleteTimeseries={() => onDeleteTimeseries(kpi.id, kpi.timeseries)}
        />
      ))}
      {isSettingsButtonVisible && (
        <Button
          disabled={isSettingsButtonDisabled}
          type="link"
          icon={<SettingOutlined />}
          onClick={onClickSettingsButton}
        />
      )}
    </div>
  ),
);

interface SortableKPIElementProps {
  kpi: any;
  onChangeTimeseriesInfo: (ts: any) => unknown;
  onDeleteTimeseries: () => unknown;
}

const SortableKPI = SortableElement<SortableKPIElementProps>(
  ({
    kpi: { type, ...otherKPIProps },
    onChangeTimeseriesInfo,
    onDeleteTimeseries,
  }: SortableKPIElementProps) => (
    <SchemaKpi
      {...otherKPIProps}
      onChangeTimeseriesInfo={onChangeTimeseriesInfo}
      onDeleteTimeseries={() => onDeleteTimeseries}
    />
  ),
);
