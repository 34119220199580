import { useEffect, useState } from "react";
import { RoomInfoType } from "@properate/api";
import {
  Asset,
  CogniteClient,
  DoubleDatapoint,
  Timeseries,
} from "@cognite/sdk";
import { SensorList } from "@properate/common";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { getSetPointStatus, roomsIndex } from "@/eepApi";
import {
  TimeseriesSettings,
  useTimeseriesSettings,
} from "@/services/timeseriesSettings";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { convertUnit, getStateDescription } from "../utils";
import {
  formatMeasurementForSchema,
  formatUnit,
} from "../SchemaView/TechnicalSchema/utils";
import { RoomImportedTimeseries, RoomSensors } from "./types";

const getAlarm = (
  value: number,
  unit: string,
  max?: number,
  min?: number,
  maxView = `Over ${max}${formatUnit(unit)}`,
  minView = `Under ${min}${formatUnit(unit)}`,
) => {
  const aboveMax = typeof max === "number" && value > max;
  const belowMin = typeof min === "number" && value < min;
  if (aboveMax) {
    return maxView;
  } else if (belowMin) {
    return minView;
  }
  return undefined;
};

const loadImported = async (
  client: CogniteClient,
  room: RoomInfoType,
  overrideUnits?: TimeseriesSettings,
): Promise<RoomImportedTimeseries[]> => {
  const relationships = await client.relationships
    .list({
      filter: { sourceExternalIds: [room.externalId!] },
      limit: 1000,
    })
    .autoPagingToArray({ limit: Infinity });

  if (relationships.length === 0) {
    return [];
  }

  const relationshipMap = relationships.reduce<Record<string, string>>(
    (prev, r) => ({
      ...prev,
      [r.targetExternalId]: r.externalId,
    }),
    {},
  );

  const externalIds = [
    ...new Set(relationships.map((rel) => rel.targetExternalId!)),
  ];
  const relationshipTimeseries = await client.timeseries.retrieve(
    externalIds.map((externalId) => ({ externalId })),
  );

  const tsAssetsMap = (
    await client.assets.retrieve(
      relationshipTimeseries.map((ts) => ({ id: ts.assetId! })),
    )
  ).reduce<Record<number, Asset>>(
    (prev, asset) => ({ ...prev, [asset.id]: asset }),
    {},
  );

  const writable = relationshipTimeseries.filter(
    (ts) =>
      tsAssetsMap[ts.assetId!].labels?.some(
        (label) => label.externalId === "writable",
      ),
  );
  const readOnly = relationshipTimeseries.filter(
    (ts) =>
      !tsAssetsMap[ts.assetId!].labels?.some(
        (label) => label.externalId === "writable",
      ),
  );

  const writableStatus =
    writable.length > 0
      ? await getSetPointStatus({
          external_ids: writable.map((ts) => ts.externalId!),
        })
      : {};

  const readOnlyValues =
    readOnly.length > 0
      ? await client.datapoints.retrieveLatest(
          readOnly.map((ts) => ({ id: ts.id })),
        )
      : [];

  const readOnlyValuesMap = readOnlyValues.reduce<
    Record<number, number | undefined>
  >(
    (prev, current) => ({
      ...prev,
      [current.id]: current.datapoints
        ? (current.datapoints[0] as DoubleDatapoint)?.value
        : undefined,
    }),
    {},
  );

  return relationshipTimeseries.map((r) =>
    writableStatus[r.externalId!]
      ? {
          timeseriesId: r.id,
          externalId: r.externalId!,
          name: r.name!,
          description: r.description || "",
          relationshipExternalId: relationshipMap[r.externalId!],
          value:
            typeof writableStatus[r.externalId!]["present-value"] === "number"
              ? (writableStatus[r.externalId!]["present-value"] as number)
              : undefined,
          priorityArray: writableStatus[r.externalId!]["priority-array"],
          priority: writableStatus[r.externalId!]["priority-array"]?.find(
            (p) => p.index === 8,
          )
            ? 8
            : 16,
          reliability: writableStatus[r.externalId!].reliability,
          outOfService: writableStatus[r.externalId!]["out-of-service"],
          type: "writable",
          unit:
            (overrideUnits && overrideUnits[r.externalId!]?.unit) ||
            r.unit ||
            "",
          stateDescription: getStateDescription(r.metadata?.state_description),
          maxValue: r.metadata?.max_value
            ? Number(r.metadata.max_value)
            : Number.MAX_SAFE_INTEGER,
          minValue: r.metadata?.min_value
            ? Number(r.metadata.min_value)
            : Number.MIN_SAFE_INTEGER,
        }
      : {
          timeseriesId: r.id,
          externalId: r.externalId!,
          name: r.name!,
          description: r.description || "",
          relationshipExternalId: relationshipMap[r.externalId!],
          value: convertUnit(
            r.unit,
            overrideUnits && overrideUnits[r.externalId!]?.unit,
            readOnlyValuesMap[r.id],
          ).value,
          type: "value",
          unit:
            (overrideUnits && overrideUnits[r.externalId!]?.unit) ||
            r.unit ||
            "",
          stateDescription: getStateDescription(r.metadata?.state_description),
        },
  );
};

const extractMinMaxMeanIds = (room: RoomInfoType) => {
  const minMaxMeanIds: { id: number }[] = [];

  const labels = [
    "VOC",
    "co2",
    "humidity_sensor",
    "motion",
    "temperature",
    "radon",
  ];

  for (const label of labels) {
    const sensor:
      | { value: number }
      | { mean: number; max: number; min: number }
      | {} = room[label as keyof RoomInfoType]
      ? room[label as keyof RoomInfoType]
      : {};

    if ("value" in sensor) {
      minMaxMeanIds.push({ id: sensor.value });
    }
    if ("min" in sensor) {
      minMaxMeanIds.push({ id: sensor.min });
    }
    if ("max" in sensor) {
      minMaxMeanIds.push({ id: sensor.max });
    }
    if ("mean" in sensor) {
      minMaxMeanIds.push({ id: sensor.mean });
    }
  }
  return minMaxMeanIds;
};

type Args = {
  id: number;
  sensors: Record<string, SensorList>;
};

export function useRoomInfo({ id, sensors }: Args) {
  const { client } = useCogniteClient();
  const [newRoom, setNewRoom] = useState<RoomInfoType | undefined>();
  const [data, setData] = useState<RoomSensors>();
  const building = useCurrentBuilding();
  const { isLoading: isLoadingOverrideUnits, overrideUnits } =
    useTimeseriesSettings(building.id);

  const [
    showHistoryForImportedTimeseries,
    setShowHistoryForImportedTimeseries,
  ] = useState<RoomImportedTimeseries>();

  const [importedTimeseriesList, setImportedTimeseriesList] = useState<
    RoomImportedTimeseries[]
  >([]);

  useEffect(() => {
    const get = async (isLoadingOverrideUnits: boolean) => {
      if (isLoadingOverrideUnits) {
        const floorRoomSensorIds = await roomsIndex.getDocument(id);
        setNewRoom(floorRoomSensorIds);

        const infoMap = Object.keys(sensors).reduce<
          Record<
            number,
            {
              min?: number;
              max?: number;
              alarmType: "warning" | "error";
            }
          >
        >((acc, key) => {
          const timeseriesInfo = sensors[key].timeseriesInfo;
          const current = timeseriesInfo.reduce<
            Record<
              number,
              {
                min?: number;
                max?: number;
                alarmType: "warning" | "error";
              }
            >
          >(
            (a: any, c: any) => ({
              ...a,
              [c.id]: {
                min: c.min,
                max: c.max,
                alarmType: c.alarmType,
              },
            }),
            {},
          );
          return { ...acc, ...current };
        }, {});

        const minMaxMeanIds = extractMinMaxMeanIds(floorRoomSensorIds);

        const values = (
          await Promise.all([client.datapoints.retrieveLatest(minMaxMeanIds)])
        ).flat();

        const valueMap: Record<number, any> = values.reduce(
          (prev, current) => ({ ...prev, [current.id]: current.datapoints[0] }),
          {},
        );

        const timeseriesMap: Record<number, Timeseries> = values.reduce(
          (prev, current) => ({
            ...prev,
            [current.id!]: current,
          }),
          {},
        );
        const getAssetValue = (assetId: number) => {
          if (assetId) {
            const ts = timeseriesMap[assetId];
            const overrideUnit =
              (ts && overrideUnits && overrideUnits[ts.externalId!]?.unit) ||
              ts?.unit ||
              "";
            const min = convertUnit(ts.unit, overrideUnit, infoMap[ts.id]?.min)
              ?.value;
            const max = convertUnit(ts.unit, overrideUnit, infoMap[ts.id]?.max)
              ?.value;
            const alarmType = infoMap[ts.id]?.alarmType || "warning";
            const stateDescription = getStateDescription(
              ts.metadata?.state_description,
            );

            const converted = convertUnit(
              ts.unit,
              overrideUnit,
              valueMap[ts.id]?.value,
            );
            return {
              value: formatMeasurementForSchema({
                ...converted,
                stateDescription,
              }),
              alarm: getAlarm(converted.value!, converted.unit || "", max, min),
              alarmType,
              id: ts.id,
            };
          }
          return undefined;
        };

        const getValue = (
          sensor: "humidity_sensor" | "VOC" | "radon" | "temperature" | "co2",
          type: "min" | "max" | "mean",
        ) => {
          const assetId = floorRoomSensorIds[sensor][type];
          return assetId ? getAssetValue(assetId) : undefined;
        };

        const getMotion = () => {
          const assetId = floorRoomSensorIds["motion"]["value"];
          return assetId ? getAssetValue(assetId) : undefined;
        };

        const data = {
          humidity_sensor: {
            min: getValue("humidity_sensor", "min"),
            max: getValue("humidity_sensor", "max"),
            mean: getValue("humidity_sensor", "mean"),
          },
          VOC: {
            min: getValue("VOC", "min"),
            max: getValue("VOC", "max"),
            mean: getValue("VOC", "mean"),
          },
          radon: {
            min: getValue("radon", "min"),
            max: getValue("radon", "max"),
            mean: getValue("radon", "mean"),
          },
          temperature: {
            min: getValue("temperature", "min"),
            max: getValue("temperature", "max"),
            mean: getValue("temperature", "mean"),
          },
          co2: {
            min: getValue("co2", "min"),
            max: getValue("co2", "max"),
            mean: getValue("co2", "mean"),
          },
          motion: getMotion(),
        };

        setData(data);

        const imported = await loadImported(
          client,
          floorRoomSensorIds,
          overrideUnits,
        );

        imported.sort((a, b) => a.name.localeCompare(b.name));

        setImportedTimeseriesList(imported);
      }
    };

    get(!isLoadingOverrideUnits);
  }, [
    id,
    sensors,
    client,
    setImportedTimeseriesList,
    isLoadingOverrideUnits,
    overrideUnits,
  ]);

  async function handleChangeTimeseries(timeseriesList: Timeseries[]) {
    // check if any relationships have been removed
    const removedTimeseriesList = importedTimeseriesList.filter(
      (importedTimeseries) =>
        timeseriesList.every((ts) => ts.id !== importedTimeseries.timeseriesId),
    );
    if (removedTimeseriesList.length > 0) {
      await client.relationships.delete(
        removedTimeseriesList.map((removedTimeseries) => ({
          externalId: removedTimeseries.relationshipExternalId,
        })),
      );
    }

    const newTimeseriesList = timeseriesList.filter(
      (timeseries) =>
        !importedTimeseriesList.some(
          (importedTimeseries) =>
            importedTimeseries.timeseriesId === timeseries.id,
        ),
    );

    await Promise.all(
      newTimeseriesList.map(async (timeseries) => {
        await client.relationships.create([
          {
            sourceExternalId: newRoom!.externalId!,
            targetExternalId: timeseries.externalId!,
            externalId: `rel_${crypto.randomUUID()}`,
            confidence: 0.95,
            sourceType: "asset" as const,
            targetType: "timeSeries" as const,
            dataSetId: newRoom!.dataSetId,
            labels: [{ externalId: "rel_setpt_realval_gen" }],
          },
        ]);
        const imported = await loadImported(client, newRoom!, overrideUnits);
        setImportedTimeseriesList(imported);
      }),
    );
  }

  return {
    data,
    newRoom,
    importedTimeseriesList,
    setImportedTimeseriesList,
    showHistoryForImportedTimeseries,
    setShowHistoryForImportedTimeseries,
    handleChangeTimeseries,
  };
}
