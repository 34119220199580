import { useState } from "react";
import { Button, Popover, Tag } from "antd";
import { ApartmentOutlined } from "@ant-design/icons/lib";
import { useUser } from "@properate/auth";
import { SelectResource } from "@/pages/fileType/SelectResource/SelectResource";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";

type SelectSystemProps = {
  system?: string;
  setFilter: (params: {
    labels?: string[];
    fileTypes?: string[];
    resource?: string;
  }) => void;
  id: number;
};

export const SelectSystemButton = ({
  setFilter,
  system,
  id,
}: SelectSystemProps) => {
  const user = useUser();
  const [visible, setVisible] = useState<boolean>(false);

  const handleFinish = ({ system }: { system: string }) => {
    setFilter({ resource: system || "" });
    setVisible(false);
  };

  return (
    <Popover
      trigger="click"
      open={visible}
      onOpenChange={(visible) => {
        setVisible(visible);
      }}
      destroyTooltipOnHide
      content={
        <SelectResource id={id} handleFinish={handleFinish} selected={system} />
      }
      title="System"
    >
      <Button disabled={user.isViewer}>
        <ApartmentOutlined
          style={{
            color: system ? DEFAULT_GLOBAL_THEME.color.GREEN : undefined,
          }}
        />
        {system ? (
          <Tag color={DEFAULT_GLOBAL_THEME.color.LIGHT_BLUE}>{system}</Tag>
        ) : null}
      </Button>
    </Popover>
  );
};
