import { Axis, Grid, LineSeries, XYChart } from "@visx/xychart";

interface IProps {
  x: number[];
  y: number[];
}

export const XYPlot = ({ x, y }: IProps) => {
  const accessors = {
    xAccessor: (d: { x: number; y: number }) => d.x,
    yAccessor: (d: { x: number; y: number }) => d.y,
  };

  const data = x.map(function (_x, i) {
    return { x: x[i], y: y[i] };
  });

  return (
    <XYChart
      height={300}
      xScale={{ type: "linear" }}
      yScale={{ type: "linear" }}
    >
      <Axis orientation="bottom" />
      <Axis orientation="left" />
      <Grid columns={false} numTicks={4} />
      <LineSeries dataKey="Line" data={data} {...accessors} />
    </XYChart>
  );
};
