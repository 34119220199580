import useSWR from "swr";
import { DatapointAggregates } from "@cognite/sdk";
import { SpotPriceGranularity, SpotPriceTimeSpan } from "@/utils/types";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useHandleApiError } from "@/utils/api";
import { CDF_CONSTANTS } from "@/utils/cdf";
import { fromSpotPriceTimeSpanToTimeSpan } from "../utils";
import { useGetSpotPriceTimeseries } from "./useGetSpotPriceTimeseries";

export function useGetSpotPriceData(
  buildingId: number,
  timeSpan: SpotPriceTimeSpan,
  granularity: SpotPriceGranularity,
) {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const timeSpanFinal = fromSpotPriceTimeSpanToTimeSpan(timeSpan);
  const { spotPriceTimeseries } = useGetSpotPriceTimeseries(buildingId);
  const {
    data: spotPriceDatapoints = [],
    isLoading,
    error,
  } = useSWR(
    spotPriceTimeseries
      ? {
          type: "datapoints.retrieve",
          query: {
            items: [{ id: spotPriceTimeseries.id }],
            start: timeSpanFinal[0],
            end: timeSpanFinal[1],
            aggregates: ["average" as const],
            granularity,
            limit: CDF_CONSTANTS.datapoints.limits.query.aggregates,
          },
        }
      : null,
    ({ query }) =>
      client.datapoints.retrieve(query) as Promise<[DatapointAggregates] | []>,
  );
  if (error !== undefined) {
    handleAPIError(error);
  }
  return {
    // We can assume this list contains 1 data point per hour
    spotPriceDatapoints: spotPriceDatapoints[0]
      ? spotPriceDatapoints[0].datapoints.map((datapoint) => ({
          ...datapoint,
          // Convert NOK to øre
          value: datapoint.average! * 100,
        }))
      : [],
    isLoading,
  };
}
