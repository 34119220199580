import { useState, useRef, Fragment, useEffect } from "react";
import { List, Select, Form, Popover, Button } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import usePageTitle from "@/hooks/usePageTitle";
import FullLayout from "@/layout/FullLayout";
import { KPI } from "@/utils/types";
import { KPIModal } from "@/components/KPIModal/KPIModal";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { allKPIs } from "@/utils/kpi";
import { RenderKpiTitle } from "@/pages/common/RenderKpiTitle/RenderKpiTitle";
import {
  BuildingWithKPIs,
  useBuildingsWithKPIs,
} from "../hooks/useBuildingsWithKPIs";
import { renderKpiValue, renderKpiValueWithUnit } from "../utils/renderKPI";
import { useFilteredBuildings } from "../hooks/useFilteredBuildings";
import {
  ClusterMarker,
  BuildingMarker,
  Grid,
  Aside,
  BuildingDisplay,
  BuildingListContainer,
  BuildingListItem,
  BuildingListItemPicture,
  BuildingListItemDetails,
  BuildingListSieve,
} from "./elements";
import { MapInstance } from "./types";
import { MapPlot } from "./Map";

export function MapPage() {
  const t = useTranslations();
  usePageTitle(t("org-map.org-portfolio-map"));

  const [orderBy, setOrderBy] = useState("name");
  const [isKPIModalOpen, setIsKPIModalOpen] = useState(false);
  const { data: preferences } = useUserSettings();
  const selectedKPI =
    preferences?.portfolio?.selectedMapKPI ?? "meanTemperature";
  const buildings = useBuildingsWithKPIs([selectedKPI]);
  const { filteredBuildings } = useFilteredBuildings(buildings);
  const [selectedKey, setSelectedKey] = useState(0);
  const [hoveredKey, setHoveredKey] = useState(0);
  const mapRef = useRef<MapInstance>();
  const selectedItem = buildings.find((item) => item.key === selectedKey);
  const params = useParams();
  const buildingId = params.buildingId ? parseInt(params.buildingId) : null;
  const navigate = useNavigate();

  useEffect(() => {
    if (buildingId && mapRef.current) {
      const item = filteredBuildings.find((item) => item.key === buildingId);

      if (item) {
        setSelectedKey(buildingId);
        mapRef.current.setZoom(18);
        mapRef.current.panTo({ lat: item.lat, lng: item.lng });
      }
    }
  }, [buildingId, filteredBuildings]);

  function renderBuildingKpiValue(
    kpi: KPI,
    building: (typeof buildings)[0],
    renderer = renderKpiValue,
  ) {
    if (!kpi) {
      return "-";
    }

    try {
      return renderer(kpi, building[kpi as keyof typeof building]);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  function BuildingCard({
    item,
    withinPopover,
  }: {
    item: BuildingWithKPIs;
    withinPopover?: boolean;
  }) {
    return (
      <BuildingDisplay withinPopover={withinPopover}>
        <BuildingListItem>
          <BuildingListItemPicture>
            {item.image && <img src={item.image} alt="" loading="lazy" />}
          </BuildingListItemPicture>
          <BuildingListItemDetails>
            <div>{item.building.alias ?? "-"}</div>
            <div>{item.building.name}</div>
            <div>
              {t("org-map.KPI")} {renderBuildingKpiValue(selectedKPI, item)}
            </div>
          </BuildingListItemDetails>
        </BuildingListItem>
        {!withinPopover && (
          <dl>
            {allKPIs.map((kpi) => (
              <Fragment key={kpi}>
                <dt>
                  <RenderKpiTitle kpi={kpi} />
                </dt>
                <dd>{renderBuildingKpiValue(kpi, item)}</dd>
              </Fragment>
            ))}
          </dl>
        )}
      </BuildingDisplay>
    );
  }

  filteredBuildings.sort((a, b) => {
    if (orderBy === "name") {
      return a.building.name.localeCompare(b.building.name);
    }

    if (orderBy === "alias") {
      return (a.building.alias ?? "").localeCompare(b.building.alias ?? "");
    }

    return 0;
  });

  return (
    <FullLayout
      pageName={t("org-map.title")}
      showSettings={isKPIModalOpen}
      onToggleSettings={setIsKPIModalOpen}
    >
      <KPIModal
        open={isKPIModalOpen}
        title={t("org-map.kpi-modal-title")}
        onHide={() => setIsKPIModalOpen(false)}
        selectedValues={[selectedKPI]}
        onUpdateSelectedValues={(selectedKPIs) => {
          for (const kpi of selectedKPIs) {
            if (kpi !== selectedKPI) {
              mutateUserSettings({
                portfolio: {
                  selectedMapKPI: kpi,
                },
              });

              break;
            }
          }
        }}
      />
      <Grid>
        <MapPlot
          ref={mapRef}
          items={filteredBuildings}
          renderItem={(item) => (
            <Popover
              content={<BuildingCard item={item} withinPopover />}
              mouseEnterDelay={0.5}
            >
              <BuildingMarker
                faded={hoveredKey > 0 && item.key !== hoveredKey}
                onClick={() => navigate(`/map/${item.key}`)}
              >
                {renderBuildingKpiValue(
                  selectedKPI,
                  item,
                  renderKpiValueWithUnit,
                )}
              </BuildingMarker>
            </Popover>
          )}
          renderCluster={(cluster) => (
            <ClusterMarker
              faded={
                hoveredKey > 0 &&
                cluster.points.every((point) => point.key !== hoveredKey)
              }
              count={cluster.numPoints}
            >
              {cluster.numPoints}
            </ClusterMarker>
          )}
        />
        <Aside>
          {selectedItem && <BuildingCard item={selectedItem} />}
          <BuildingListSieve>
            {selectedItem && (
              <Button
                type="primary"
                aria-label={(
                  (selectedItem.building.alias || "") +
                  " " +
                  selectedItem.building.name
                ).trim()}
              >
                <Link to={`/asset/${selectedItem.key}/building`}>
                  {t("org-map.go-to-the-building-button")}
                </Link>
              </Button>
            )}
            <Form layout="horizontal">
              <Form.Item name="sortBy" label="Sorter" initialValue={orderBy}>
                <Select
                  value={orderBy}
                  onChange={setOrderBy}
                  style={{ width: 120 }}
                  options={[
                    { label: t("org-map.alias"), value: "alias" },
                    { label: t("org-map.name"), value: "name" },
                  ]}
                />
              </Form.Item>
            </Form>
          </BuildingListSieve>
          <BuildingListContainer onMouseLeave={() => setHoveredKey(0)}>
            <List
              dataSource={filteredBuildings}
              renderItem={(item) => (
                <BuildingListItem
                  onClick={() => navigate(`/map/${item.key}`)}
                  onMouseOverCapture={() => setHoveredKey(item.key)}
                >
                  <BuildingListItemPicture>
                    {item.image && (
                      <img src={item.image} alt="" loading="lazy" />
                    )}
                  </BuildingListItemPicture>
                  <BuildingListItemDetails>
                    <div>{item.building.alias ?? "-"}</div>
                    <div>{item.building.name}</div>
                    <div>
                      {t("org-map.KPI")}{" "}
                      {renderBuildingKpiValue(selectedKPI, item)}
                    </div>
                  </BuildingListItemDetails>
                </BuildingListItem>
              )}
            />
          </BuildingListContainer>
        </Aside>
      </Grid>
    </FullLayout>
  );
}
