import dayjs from "@properate/dayjs";

type Maybe<T> = T | undefined | null;

export function sortNumber(a: Maybe<number>, b: Maybe<number>) {
  if (typeof a !== "number" && typeof b !== "number") {
    return 0;
  }

  if (typeof a !== "number") {
    return -1;
  }

  if (typeof b !== "number") {
    return 1;
  }

  return a - b;
}

export function sortString(a: Maybe<string>, b: Maybe<string>) {
  const aVal = a ?? "";
  const bVal = b ?? "";

  return aVal.localeCompare(bVal);
}

export function sortNumericString(a: Maybe<string>, b: Maybe<string>) {
  return sortNumber(a ? Number(a) : undefined, b ? Number(b) : undefined);
}

export function sortEnum(a: Maybe<string>, b: Maybe<string>, order: string[]) {
  if (!a && !b) {
    return 0;
  }

  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }

  return order.indexOf(a) - order.indexOf(b);
}

export function sortDate(a: Maybe<Date>, b: Maybe<Date>) {
  if (!a && !b) {
    return 0;
  }

  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }

  return dayjs(a).diff(dayjs(b));
}
