import { Space, Button, Table, DatePicker, Row, Col, Tooltip } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useLoaderData, useNavigate } from "react-router-dom";
import dayjs from "@properate/dayjs";
import { ParentSize } from "@visx/responsive";
import { sortNumber, sortString, ToggleSidebarButton } from "@properate/ui";
import { useEffect, useMemo, useState } from "react";
import { SorterResult } from "antd/lib/table/interface";
import { useTranslations } from "@properate/translations";
import { CompactContent } from "@/components/CompactContent";
import { disabledFutureDates } from "@/utils/helpers";
import {
  getStartAndEndOfTheMonthDates,
  useLastHalfYearPresets,
  useLastXMonthPreset,
  useLastXQuarterPresets,
} from "@/utils/date";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { useWindowSize } from "@/hooks/useWindowSize";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { DateRangeValueType } from "@/utils/types";
import { SortResultType, WasteData, WastePage } from "@/pages/waste/types";
import { FractionsCardKpi, SortDegreeCardKpi } from "@/features/waste";
import { useHelp } from "@/context/HelpContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { formatMeasurement } from "./utils";
import { WasteGraph } from "./WasteGraph";
const ESTIMATED_HEADER_HEIGHT = 240;

export function Waste() {
  const t = useTranslations();
  useBuildingPageTitle(t("waste.waste"));
  const [sorterResult, setSorterResult] = useState<SortResultType>({
    columnKey: "description",
    order: "ascend",
  });
  const waste = useLoaderData() as WastePage;
  const navigate = useNavigate();
  const { height: windowHeight } = useWindowSize();
  const { setHelp } = useHelp();
  const building = useCurrentBuilding();

  const handleDatePickerChange = (dates: DateRangeValueType) => {
    if (dates && dates[0] && dates[1]) {
      const { start, end } = getStartAndEndOfTheMonthDates(dates[0], dates[1]);
      navigate(`../waste/${start.valueOf()}/${end.valueOf()}`);
    }
  };
  const startDate = dayjs(waste.startDate).format("D. MMMM YYYY");
  const endDate = dayjs(waste.endDate).format("D. MMMM YYYY");

  const GAP_BETWEEN_GRAPH_AND_TABLE = 8;
  const HALF_OF_PAGE_HEIGHT = Math.floor(
    (windowHeight - ESTIMATED_HEADER_HEIGHT - GAP_BETWEEN_GRAPH_AND_TABLE) / 2,
  );

  useEffect(() => {
    setHelp({
      title: t("waste.help.title"),
      content: (
        <>
          <h3>{t("waste.help.table-limit-values")}</h3>
          <p>
            {t.rich("waste.help.description", {
              br: () => <br />,
            })}
          </p>
        </>
      ),
    });
  }, [setHelp, t]);

  function updateSortOrder(sorterResult: SorterResult<WasteData[]>) {
    const { columnKey, order } = sorterResult;
    if (typeof columnKey === "string" && order) {
      setSorterResult({ columnKey, order });
    }
  }

  const sortedWasteData = [...waste.data].sort((a: WasteData, b: WasteData) => {
    if (sorterResult.columnKey === "value") {
      if (sorterResult.order === "descend") {
        return sortNumber(b.value, a.value);
      }
      return sortNumber(a.value, b.value);
    }
    if (sorterResult.order === "descend") {
      return sortString(b.description, a.description);
    }
    return sortString(a.description, b.description);
  });

  const last3MonthPreset = useLastXMonthPreset(3);
  const last6MonthPreset = useLastXMonthPreset(6);
  const last12MonthPreset = useLastXMonthPreset(12);
  const last4QuarterPresets = useLastXQuarterPresets(4);
  const lastHalfYearPresets = useLastHalfYearPresets();

  const presets = useMemo(
    () => [
      last3MonthPreset,
      last6MonthPreset,
      last12MonthPreset,
      ...last4QuarterPresets,
      ...lastHalfYearPresets,
    ],
    [
      last3MonthPreset,
      last6MonthPreset,
      last12MonthPreset,
      last4QuarterPresets,
      lastHalfYearPresets,
    ],
  );

  return (
    <>
      <PageHeader
        title={t("waste.title", { startDate, endDate })}
        extra={
          <Space direction="horizontal">
            <Tooltip
              title={`${
                waste.data.length === 0
                  ? t("waste.no-data-with-selected-period", {
                      startDate,
                      endDate,
                    })
                  : t("waste.create-report-button")
              }`}
            >
              <Button
                disabled={waste.data.length === 0}
                type="link"
                onClick={() => {
                  navigate(
                    `../reports/waste/${dayjs(
                      waste.startDate,
                    ).valueOf()}/${dayjs(waste.endDate).valueOf()}`,
                  );
                }}
              >
                {t("waste.create-report-button")}
              </Button>
            </Tooltip>
            <DatePicker.RangePicker
              data-testid="date-picker"
              format="D. MMMM YYYY"
              defaultValue={[waste.startDate, waste.endDate]}
              disabledDate={disabledFutureDates}
              picker="month"
              onChange={handleDatePickerChange}
              presets={presets}
              aria-labelledby={t("waste.select-period")}
            />
            <ToggleSidebarButton
              hiddenWhenSidebarVisible
              sidebarHiddenContent={t("notes.show-notes-button")}
            />
          </Space>
        }
      />
      <CompactContent>
        {waste.data.length === 0 ? (
          <div data-testid="no-data-found-with-selected-period">
            {t("waste.no-data-with-selected-period", {
              startDate,
              endDate,
            })}
          </div>
        ) : (
          <>
            <Row gutter={[24, 24]} justify="space-between">
              <Col span={24}>
                <div style={{ overflow: "hidden" }}>
                  <SortDegreeCardKpi
                    key="sort-degree"
                    title={t("waste.sorting-degree")}
                    value={waste.sortedDegree}
                    unit={"%"}
                  />
                  <FractionsCardKpi
                    key="number-of-fractions"
                    title={t("waste.number-of-fractions")}
                    value={waste.data.length}
                    prefix={"#"}
                  />
                </div>
              </Col>
            </Row>
            <div
              style={{
                display: "grid",
                gridTemplateRows: `${HALF_OF_PAGE_HEIGHT}px ${HALF_OF_PAGE_HEIGHT}px`,
                gap: 16,
              }}
            >
              <ParentSize>
                {({ width }) => (
                  <WasteGraph
                    width={width}
                    height={HALF_OF_PAGE_HEIGHT}
                    data={sortedWasteData}
                  />
                )}
              </ParentSize>
              <TableWithoutDefaultSort
                pagination={false}
                scroll={{
                  y: HALF_OF_PAGE_HEIGHT,
                }}
                columns={[
                  {
                    title: t("waste.name"),
                    width: "80%",
                    sorter: true,
                    dataIndex: "description",
                    key: "description",
                  },
                  {
                    title: t("waste.weight-kg"),
                    key: "value",
                    sorter: true,
                    render: (_, { value }) => formatMeasurement(value, 2),
                  },
                ]}
                dataSource={sortedWasteData}
                onChange={(_, __, sorterResult) =>
                  updateSortOrder(sorterResult as SorterResult<WasteData[]>)
                }
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>
                        <strong>{t("waste.total")}</strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <strong>
                          {formatMeasurement(waste.totalWaste, 2)}
                        </strong>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
                rowKey="name"
              />
            </div>
          </>
        )}
        {building.dataSetId && (
          <NotesSidebar
            startTime={waste.startDate.toDate()}
            endTime={waste.endDate.toDate()}
            noteSource={NoteSource.WEB_WASTE}
            assetFilterMode={NotesAssetFilterMode.AssetList}
            idSet={new Set(waste.assetIds)}
            buildings={[{ id: building.dataSetId, name: building.name }]}
          />
        )}
      </CompactContent>
    </>
  );
}
