import { BuildingCertification } from "@properate/common";
import { certifications } from "./certifications";

type CertificationType = BuildingCertification["type"];

export function getCertificationLevelOptions(type: CertificationType) {
  return certifications
    .find((certification) => certification.type === type)
    ?.levels?.map((level) => ({ value: level, label: level }));
}
