import { DefaultTheme } from "styled-components";
import { MessageKey } from "@properate/translations/src";

export const PROPERATE_THEME_DARK: DefaultTheme = {
  type: "dark",
  neutral10: "#111928",
  neutral9: "#182334",
  neutral8: "#293647",
  neutral7: "#434E5C",
  neutral6: "#6D7580",
  neutral5: "#858C94",
  neutral4: "#A5ABB3",
  neutral3: "#DADEE3",
  neutral2: "#EBEEF2",
  neutral1: "#F4F6F9",
  neutral0: "#FFFFFF",
  white: "#FFFFFF",
  background: "#141414",
  background2: "#182334",
  background_hover: "#1C283C",
  layoutBackground: "#111928",
  backgroundEmphasised: "#0B1019",
  green_100: "#12CF74",
  green_80: "rgb(18, 207, 116,0.8)",
  green_60: "rgb(18, 207, 116,0.6)",
  green_40: "rgb(18, 207, 116,0.4)",
  green_20: "rgb(18, 207, 116,0.2)",
  purple_100: "#26055B",
  purple_80: "rgb(38, 5, 91,0.8)",
  purple_60: "rgb(38, 5, 91,0.6)",
  purple_40: "rgb(38, 5, 91,0.4)",
  purple_20: "rgb(38, 5, 91,0.2)",
  primary: "#12CF74",
  accent1: "#FF7C43",
  accent2: "#FFA600",
  accent3: "#F95D6A",
  accent4: "#D45087",
  accent5: "#A05195",
  accent6: "#7B60B0",
  accent7: "#377CCC",
  accent8: "#45ABE4",
  accent9: "#45DBE4",
  accent10: "#4EFCB3",
  accent11: "#4EFCB3",
  accent12: "#1677FF",
  error: "#EE0000",
  errorBg: "#991C00",
  errorFg: "#FFEAE5",
  warning: "#FFD80B",
  warningBg: "#806A00",
  warningFg: "#FFF6C7",
  info: "#18A0FB",
  infoBg: "#003F66",
  infoFg: "#E0F3FF",
  success: "#12CF74",
  successBg: "rgb(18, 207, 116,0.2)",
  highlightBg: "#FF7C43",
};
export const PROPERATE_THEME_LIGHT: DefaultTheme = {
  type: "light",
  neutral0: "#111928",
  neutral1: "#182334",
  neutral2: "#293647",
  neutral3: "#434E5C",
  neutral4: "#6D7580",
  neutral5: "#858C94",
  neutral6: "#A5ABB3",
  neutral7: "#DADEE3",
  neutral8: "#EBEEF2",
  neutral9: "#F4F6F9",
  neutral10: "#FFFFFF",
  white: "#FFFFFF",
  background: "#F4F6F9",
  background2: "#FFFFFF",
  background_hover: "#F2F2F9",
  layoutBackground: "#f4f6f9",
  backgroundEmphasised: "#F5FBFF",
  green_100: "#12CF74",
  green_80: "rgb(18, 207, 116,0.8)",
  green_60: "rgb(18, 207, 116,0.6)",
  green_40: "rgb(18, 207, 116,0.4)",
  green_20: "rgb(18, 207, 116,0.2)",
  purple_100: "#26055B",
  purple_80: "rgb(38, 5, 91,0.8)",
  purple_60: "rgb(38, 5, 91,0.6)",
  purple_40: "rgb(38, 5, 91,0.4)",
  purple_20: "rgb(38, 5, 91,0.2)",
  primary: "#12CF74",
  accent1: "#FF7C43",
  accent2: "#FFA600",
  accent3: "#F95D6A",
  accent4: "#D45087",
  accent5: "#A05195",
  accent6: "#7B60B0",
  accent7: "#377CCC",
  accent8: "#45ABE4",
  accent9: "#45DBE4",
  accent10: "#4EFCB3",
  accent11: "#4EFCB3",
  accent12: "#1677FF",
  error: "#EE0000",
  errorBg: "#FFEAE5",
  errorFg: "#991C00",
  warning: "#FFD80B",
  warningBg: "#FFF6C7",
  warningFg: "#998000",
  info: "#18A0FB",
  infoBg: "#E0F3FF",
  infoFg: "#005E99",
  success: "#12CF74",
  successBg: "#EDF9F0",
  highlightBg: "#FFC107",
};

export const NEUTRAL0 = "#111928";
export const NEUTRAL1 = "#182334";
export const NEUTRAL2 = "#293647";
export const NEUTRAL3 = "#434E5C";
export const NEUTRAL4 = "#6D7580";
export const NEUTRAL5 = "#858C94";
export const NEUTRAL6 = "#A5ABB3";
export const NEUTRAL7 = "#DADEE3";
export const NEUTRAL8 = "#EBEEF2";
export const NEUTRAL9 = "#F4F6F9";
export const NEUTRAL10 = "#FFFFFF";

export const GREEN100 = "#12CF74";
export const green_80 = "rgb(18, 207, 116,0.8)";
export const green_60 = "rgb(18, 207, 116,0.6)";
export const green_40 = "rgb(18, 207, 116,0.4)";
export const green_20 = "rgb(18, 207, 116,0.2)";

export const PURPLE100 = "#26055B";
export const PURPLE80 = "rgb(38, 5, 91,0.8)";
export const PURPLE60 = "rgb(38, 5, 91,0.6)";
export const PURPLE40 = "rgb(38, 5, 91,0.4)";
export const PURPLE20 = "rgb(38, 5, 91,0.2)";

export const PRIMARY = GREEN100;

export const ERROR = "#EE0000";

export const BACKGROUND = "#F4F6F9";

export const ACCENT1 = PRIMARY;
export const ACCENT2 = "#FF7C43";
export const ACCENT3 = "#FFA600";
export const ACCENT4 = "#F95D6A";
export const ACCENT5 = "#D45087";
export const ACCENT6 = "#A05195";
export const ACCENT7 = "#7B60B0";
export const ACCENT8 = "#377CCC";
export const ACCENT9 = "#45ABE4";
export const ACCENT10 = "#45DBE4";
export const ACCENT11 = "#4EFCB3";

export const COLOR_PALETTE: {
  code: string;
  name: string;
  displayKey: MessageKey;
}[] = [
  { code: ACCENT2, name: "Coral", displayKey: "common.colors.coral" },
  { code: ACCENT3, name: "Orange", displayKey: "common.colors.orange" },
  {
    code: ACCENT4,
    name: "Wild Watermelon",
    displayKey: "common.colors.wild-watermelon",
  },
  { code: ACCENT5, name: "Cranberry", displayKey: "common.colors.cranberry" },
  {
    code: ACCENT6,
    name: "Violet Blue",
    displayKey: "common.colors.violet-blue",
  },
  {
    code: ACCENT7,
    name: "Blue Marguerite",
    displayKey: "common.colors.blue-marguerite",
  },
  {
    code: ACCENT8,
    name: "Curious Blue",
    displayKey: "common.colors.curious-blue",
  },
  { code: ACCENT9, name: "Summer Sky", displayKey: "common.colors.summer-sky" },
  { code: ACCENT10, name: "Turquoise", displayKey: "common.colors.turquoise" },
  {
    code: ACCENT11,
    name: "Aquamarine",
    displayKey: "common.colors.aquamarine",
  },
  { code: ACCENT1, name: "Malachite", displayKey: "common.colors.malachite" },
];
