import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "@properate/dayjs";
import { Select, Space } from "antd";
import { useTranslations } from "@properate/translations";

type Props = {
  setStart: (start: Dayjs) => void;
  setEnd: (start: Dayjs) => void;
};

export const GraphPeriod = ({ setStart, setEnd }: Props) => {
  const t = useTranslations();

  const [period, setPeriod] = useState<string>("2w-ago");
  useEffect(() => {
    const e = dayjs().endOf("hour");

    switch (period) {
      case "today":
        setStart(e.startOf("day"));
        break;
      case "24h-ago":
        setStart(e.subtract(24, "hours"));
        break;
      case "1w-ago":
        setStart(e.subtract(7, "days"));
        break;
      case "2w-ago":
        setStart(e.subtract(14, "days"));
        break;
      case "1m-ago":
        setStart(e.subtract(1, "month"));
        break;
      case "3m-ago":
        setStart(e.subtract(3, "month"));
        break;
      case "6m-ago":
        setStart(e.subtract(6, "month"));
        break;
      case "1y-ago":
        setStart(e.subtract(1, "year"));
        break;
    }

    setEnd(e);
  }, [period, setEnd, setStart]);

  return (
    <Space>
      {t("common.timeseries-graph-modal.period")}
      <Select
        value={period}
        onSelect={(val: string) => setPeriod(val)}
        style={{ width: 100 }}
      >
        <Select.Option value={"today"}>
          {t("common.timeseries-graph-modal.periods.today")}
        </Select.Option>
        <Select.Option value={"24h-ago"}>
          {t("common.timeseries-graph-modal.periods.24-hours")}
        </Select.Option>
        <Select.Option value={"1w-ago"}>
          {t("common.timeseries-graph-modal.periods.1-week")}
        </Select.Option>
        <Select.Option value={"2w-ago"}>
          {t("common.timeseries-graph-modal.periods.2-weeks")}
        </Select.Option>
        <Select.Option value={"1m-ago"}>
          {t("common.timeseries-graph-modal.periods.1-month")}
        </Select.Option>
        <Select.Option value={"3m-ago"}>
          {t("common.timeseries-graph-modal.periods.3-months")}
        </Select.Option>
        <Select.Option value={"6m-ago"}>
          {t("common.timeseries-graph-modal.periods.6-months")}
        </Select.Option>
        <Select.Option value={"1y-ago"}>
          {t("common.timeseries-graph-modal.periods.1-year")}
        </Select.Option>
      </Select>
    </Space>
  );
};
