import { browserFirestore } from "@properate/firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import useSWRMutation from "swr/mutation";
import _ from "lodash";

type TriggerArg = {
  snapshotId: string;
};

export function useDeleteAlarm() {
  return useSWRMutation(`alarms`, async (__, extra: { arg: TriggerArg }) => {
    const { snapshotId } = extra.arg;

    const events = await getDocs(
      query(
        collection(browserFirestore, "events"),
        where("alarmId", "==", snapshotId),
      ),
    );
    /*
     * https://cloud.google.com/firestore/quotas#writes_and_transactions
     * 500 Maximum of field transformations that can be performed on a single document in a Commit operation or in a transaction
     * Alarm can have a thousand events and this caused a bug while deleting an alarm
     * */
    const chunked = _.chunk(events.docs, 400);

    chunked.forEach((chunkEvents) => {
      const batch = writeBatch(browserFirestore);
      chunkEvents.forEach((event) => batch.delete(event.ref));
      batch.commit();
    });
    const batch = writeBatch(browserFirestore);
    batch.delete(doc(browserFirestore, "alarms", snapshotId));
    return batch.commit();
  });
}
