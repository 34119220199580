import { MeterSystemSelect } from "@properate/ui";
import { Button, Form, Space } from "antd";
import { useTranslations } from "@properate/translations";

type Props = {
  id: number;
  selected?: string;
  handleFinish: (values: { system: string }) => void;
};
export const SelectResource = ({ handleFinish, id, selected }: Props) => {
  const t = useTranslations();
  return (
    <Form onFinish={handleFinish}>
      <Space direction="vertical" className="w-[400px]">
        <MeterSystemSelect
          buildingId={id}
          value={selected}
          configuration={{
            allLevelsSelectable: true,
            showNonExistingSystemsCheckbox: false,
            isRequired: false,
          }}
        />
        <Form.Item wrapperCol={{ span: 3, offset: 21 }}>
          <Button type="primary" htmlType="submit">
            {t("tfm.system.ok")}
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
