import { useState, ChangeEvent } from "react";
import { Space, Input, Select } from "antd";
import { useTranslations } from "@properate/translations";

import { PageHeader } from "@ant-design/pro-layout";
import { ToggleSidebarButton } from "@properate/ui";
import { AnomaliesForCurrentBuildingPage } from "./AnomaliesForCurrentBuildingPage";

export function AnomalyPage() {
  const t = useTranslations();

  const [query, setQuery] = useState("");
  const [anomaliesCountPeriod, setAnomaliesCountPeriod] = useState(30);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setQuery(event.target.value);

  function handleAnomaliesCountPeriodChange(value: Number): void {
    setAnomaliesCountPeriod(Number(value));
  }

  enum Period {
    LAST_SEVEN_DAYS = 7,
    LAST_MONTH = 30,
    LAST_HALF_YEAR = 182,
    LAST_YEAR = 365,
  }

  return (
    <>
      <PageHeader
        title={t("anomaly.title")}
        extra={
          <Space direction="horizontal">
            {"Period:"}
            <Select
              onChange={handleAnomaliesCountPeriodChange}
              value={anomaliesCountPeriod}
            >
              <Select.Option value={Period.LAST_SEVEN_DAYS}>
                {t("anomaly.table.timeRange.week")}
              </Select.Option>
              <Select.Option value={Period.LAST_MONTH}>
                {t("anomaly.table.timeRange.month")}
              </Select.Option>
              <Select.Option value={Period.LAST_HALF_YEAR}>
                {t("anomaly.table.timeRange.halfYear")}
              </Select.Option>
              <Select.Option value={Period.LAST_YEAR}>
                {t("anomaly.table.timeRange.year")}
              </Select.Option>
            </Select>
            <Input
              placeholder={t("anomaly.search-placeholder")}
              onChange={handleSearch}
              allowClear
            />
            <ToggleSidebarButton
              hiddenWhenSidebarVisible
              sidebarHiddenContent={t("notes.show-notes-button")}
            />
          </Space>
        }
      />
      <AnomaliesForCurrentBuildingPage
        query={query}
        anomaliesCountPeriod={anomaliesCountPeriod}
      />
    </>
  );
}
