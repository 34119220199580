import { Select } from "antd";
import { useTranslations } from "@properate/translations";

export type Aggregate = "mean" | "min" | "max" | "sum" | "integral" | "cumsum";

interface Props {
  value: Aggregate;
  onChange: (value: Aggregate) => void;
  style?: React.CSSProperties;
}

export function AggregateSelect({ value, onChange, style }: Props) {
  const t = useTranslations();

  return (
    <Select
      showAction={["focus", "click"]}
      value={value}
      onChange={onChange}
      options={[
        {
          value: "mean",
          label: t("calculation-flow.aggregate-select.average"),
        },
        {
          value: "max",
          label: t("calculation-flow.aggregate-select.maximum"),
        },
        {
          value: "min",
          label: t("calculation-flow.aggregate-select.minimum"),
        },
        { value: "sum", label: t("calculation-flow.aggregate-select.sum") },
        {
          value: "cumsum",
          label: t("calculation-flow.aggregate-select.cumsum"),
        },
      ]}
      style={style}
    />
  );
}
