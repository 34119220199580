import { noop } from "lodash";
import { createContext, ReactNode, useContext, useState } from "react";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { UserSettings } from "@/utils/types";

type ContextType = {
  search: string;
  filter: UserSettings["filters"]["tasksSieve"];
  setSearch: (search: string) => void;
  setFilter: (filter: UserSettings["filters"]["tasksSieve"][0]) => void;
};

const TaskManagerTableFiltersContext = createContext<ContextType>({
  search: "",
  filter: [],
  setSearch: noop,
  setFilter: noop,
});

const allAllowedFilters: UserSettings["filters"]["tasksSieve"] = [
  "inProgress",
  "expiresSoon",
  "expired",
  "completed",
  "mine",
  "all",
];

export function TaskManagerTableFiltersProvider(props: {
  children: ReactNode;
}) {
  const [search, setSearch] = useState("");
  const { data } = useUserSettings();
  let filter: UserSettings["filters"]["tasksSieve"] = ["inProgress", "all"];

  if (data?.filters?.tasksSieve) {
    if (typeof data.filters.tasksSieve === "string") {
      const previous: string = data.filters.tasksSieve;

      if (previous === "mine") {
        filter = ["inProgress", "mine"];
      } else if (previous === "all") {
        filter = ["inProgress", "all"];
      } else {
        filter = [previous as any, "all"];
      }
    } else if (
      data.filters.tasksSieve.some((filterName) =>
        allAllowedFilters.includes(filterName),
      )
    ) {
      filter = data.filters.tasksSieve.filter((filterName) =>
        allAllowedFilters.includes(filterName),
      );
    }
  }

  const value = {
    search,
    filter,
    setSearch,
    setFilter: (label: UserSettings["filters"]["tasksSieve"][0]) => {
      const assigneeToggles: UserSettings["filters"]["tasksSieve"] = [
        "all",
        "mine",
      ];

      if (assigneeToggles.includes(label)) {
        return mutateUserSettings({
          filters: {
            tasksSieve: filter
              .filter((f) => !assigneeToggles.includes(f))
              .concat([label]),
          },
        });
      }

      return mutateUserSettings({
        filters: {
          tasksSieve: filter
            .filter((f) => assigneeToggles.includes(f))
            .concat([label]),
        },
      });
    },
  };

  return (
    <TaskManagerTableFiltersContext.Provider value={value}>
      {props.children}
    </TaskManagerTableFiltersContext.Provider>
  );
}

export function useTaskManagerTableFiltersContext() {
  const ctx = useContext(TaskManagerTableFiltersContext);

  if (!ctx) {
    throw new Error("Missing TaskManagerTableFiltersProvider");
  }

  return ctx;
}
