import styled from "styled-components";
import { DatapointCalculatorSwitch } from "@/components/DatapointCalculator/DatapointCalculatorSwitch";

export const GaugeFormWithViewContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
`;

export const DatapointCalculatorSwitchWithBottomMargin = styled(
  DatapointCalculatorSwitch,
)`
  margin-bottom: 16px;
`;
