import { Node as ReactFlowNode } from "reactflow";
import { useTranslations } from "@properate/translations";
import { getNodeId } from "./helpers/Utils";
import {
  NormalNode,
  NodeOutput,
  NodeOutputLabel,
  NodeInput,
  Header,
  Body,
  NodeInputLabel,
} from "./helpers/NodeComponents";

interface LnNodeProps {
  operationId: string;
}

export const getEmptyLnNode = (): ReactFlowNode<LnNodeProps> => {
  return {
    id: getNodeId("ln"),
    type: "ln",
    data: {
      operationId: "ln",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function LnNode() {
  const t = useTranslations();

  return (
    <NormalNode>
      <Header>{t("calculation-flow.node-types.natural-logarithm")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <NodeOutput>
          <NodeOutputLabel>
            {t("calculation-flow.node-types.natural-logarithm-description")}
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </NormalNode>
  );
}

export default LnNode;
