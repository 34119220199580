import { browserFirestore } from "@properate/firebase";
import { BuildingContact } from "@properate/common";
import { arrayRemove, collection, doc, updateDoc } from "firebase/firestore";
import useSWRMutation from "swr/mutation";

type TriggerArg = {
  buildingId: number;
  contact: BuildingContact;
};

export function useRemoveBuildingContact() {
  return useSWRMutation(
    `building-contacts`,
    async (_, extra: { arg: TriggerArg }) => {
      const { buildingId, contact } = extra.arg;
      await updateDoc(
        doc(collection(browserFirestore, "buildings"), buildingId.toString()),
        { contacts: arrayRemove(contact) },
      );
    },
  );
}
