import { useUser } from "@properate/auth";
import { Button, Popover } from "antd";
import { FunctionComponent, useState } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  getEmptyTimeSeriesInputNode,
  getEmptyAbsoluteValueNode,
  getEmptyAddNode,
  getEmptyConstantInputNode,
  getEmptyDivisionNode,
  getEmptyIfElseNode,
  getEmptyScheduleNode,
  getEmptyInverseNode,
  getEmptyClipNode,
  getEmptyPowerNode,
  getEmptyProductNode,
  getEmptyRootNode,
  getEmptyLnNode,
  getEmptyAggregateNode,
  getEmptyRollingWindowNode,
  getEmptySignalGeneratorNode,
  getEmptySubNode,
  getEmptyTimeSeriesOutputNode,
  getEmptySetpointOutputNode,
  getEmptySetpointsOutputNode,
  getEmptyTimeSeriesMeterOutputNode,
  getEmptyMinimumNode,
  getEmptyMaximumNode,
  getEmptyMeanNode,
  getEmptyCommentNode,
  getEmptyTableOutputNode,
} from "../nodes";
import { getEmptyTimeShiftNode } from "../nodes/TimeShift";
import { getEmptyMultiTimeSeriesInputNode } from "../nodes/MultiTimeSeriesInput";
import { getEmptyTransformNode } from "../nodes/Transform";
import { DragNodeButton } from "./DragNodeButton";

export type HideableNodeButtonType =
  | "write_setpoint"
  | "write_timeseries"
  | "write_timeseries_meter"
  | "write_table";

interface Props {
  hiddenNodeButtons: HideableNodeButtonType[];
}

const NodesPopover: FunctionComponent<Props> = ({
  hiddenNodeButtons: hiddenNodes,
}) => {
  const t = useTranslations();
  const currentBuilding = useCurrentBuilding();
  const user = useUser();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const hide = () => {
    setOpen(false);
  };

  const content = (
    <>
      <div style={{ float: "right" }}>
        <Button type="text" icon={<CloseOutlined />} onClick={hide} />
      </div>
      <p>
        <i>{t("calculation-flow.create-node-description")}</i>
      </p>

      <p>
        <b>{t("calculation-flow.input")}</b>
      </p>
      <>
        <DragNodeButton nodeGenerator={getEmptyTimeSeriesInputNode}>
          {t("calculation-flow.node-types.timeseries")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyMultiTimeSeriesInputNode}>
          {t("calculation-flow.node-types.several-timeseries")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyConstantInputNode}>
          {t("calculation-flow.node-types.constant")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptySignalGeneratorNode}>
          {t("calculation-flow.node-types.signal-generator")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyScheduleNode}>
          {t("calculation-flow.node-types.schedule")}
        </DragNodeButton>
      </>
      <p>
        <b>{t("calculation-flow.node-types.mathematics")}</b>
      </p>
      <>
        <DragNodeButton nodeGenerator={getEmptyAddNode}>
          {t("calculation-flow.node-types.addition")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptySubNode}>
          {t("calculation-flow.node-types.subtraction")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyProductNode}>
          {t("calculation-flow.node-types.multiplication")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyDivisionNode}>
          {t("calculation-flow.node-types.division")}
        </DragNodeButton>
        <br />
        <DragNodeButton nodeGenerator={getEmptyMinimumNode}>
          {t("calculation-flow.node-types.minimum")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyMaximumNode}>
          {t("calculation-flow.node-types.maximum")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyMeanNode}>
          {t("calculation-flow.node-types.average")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyAbsoluteValueNode}>
          {t("calculation-flow.node-types.absolute-value")}
        </DragNodeButton>
        <br />
        <DragNodeButton nodeGenerator={getEmptyRootNode}>
          {t("calculation-flow.node-types.root")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyPowerNode}>
          {t("calculation-flow.node-types.power")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyInverseNode}>
          {t("calculation-flow.node-types.inverse")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyLnNode}>
          {t("calculation-flow.node-types.natural-logarithm")}
        </DragNodeButton>
        <br />
        <DragNodeButton nodeGenerator={getEmptyClipNode}>
          {t("calculation-flow.node-types.upper-and-lower-limit")}
        </DragNodeButton>
      </>
      <p>
        <b>{t("calculation-flow.node-types.logics")}</b>
      </p>
      <>
        <DragNodeButton nodeGenerator={getEmptyIfElseNode}>
          {t("calculation-flow.node-types.if-else")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyTransformNode}>
          {t("calculation-flow.node-types.transform")}
        </DragNodeButton>
      </>
      <p>
        <b>{t("calculation-flow.node-types.advanced")}</b>
      </p>
      <>
        <DragNodeButton nodeGenerator={getEmptyAggregateNode}>
          {t("calculation-flow.node-types.aggregate")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyRollingWindowNode}>
          {t("calculation-flow.node-types.rolling-window")}
        </DragNodeButton>
        <DragNodeButton nodeGenerator={getEmptyTimeShiftNode}>
          {t("calculation-flow.node-types.shift-timeseries")}
        </DragNodeButton>
      </>
      <p>
        <b>{t("calculation-flow.node-types.output")}</b>
      </p>
      <>
        {!hiddenNodes.includes("write_timeseries") && (
          <DragNodeButton
            nodeGenerator={() =>
              getEmptyTimeSeriesOutputNode(
                currentBuilding.externalId === undefined
                  ? null
                  : currentBuilding.externalId,
              )
            }
          >
            {t("calculation-flow.node-types.general-timeseries")}
          </DragNodeButton>
        )}
        {!hiddenNodes.includes("write_timeseries_meter") && (
          <>
            <DragNodeButton
              nodeGenerator={() =>
                getEmptyTimeSeriesMeterOutputNode(
                  currentBuilding.externalId!,
                  "water",
                )
              }
            >
              {t("calculation-flow.node-types.water-meter")}
            </DragNodeButton>
            <DragNodeButton
              nodeGenerator={() =>
                getEmptyTimeSeriesMeterOutputNode(
                  currentBuilding.externalId!,
                  "energy",
                )
              }
            >
              {t("calculation-flow.node-types.energy-meter")}
            </DragNodeButton>
          </>
        )}
        {!hiddenNodes.includes("write_setpoint") && (
          <>
            <DragNodeButton nodeGenerator={getEmptySetpointOutputNode}>
              {t("calculation-flow.node-types.setpoint")}
            </DragNodeButton>
            <DragNodeButton nodeGenerator={getEmptySetpointsOutputNode}>
              {t("calculation-flow.node-types.several-setpoints")}
            </DragNodeButton>
          </>
        )}
        {!hiddenNodes.includes("write_table") && (
          <>
            <DragNodeButton nodeGenerator={getEmptyTableOutputNode}>
              {t("calculation-flow.node-types.table")}
            </DragNodeButton>
          </>
        )}
      </>
      <p>
        <b>{t("calculation-flow.node-types.text")}</b>
      </p>
      <DragNodeButton nodeGenerator={() => getEmptyCommentNode()}>
        {t("calculation-flow.node-types.comment")}
      </DragNodeButton>
    </>
  );

  return (
    <Popover
      placement="topLeft"
      content={content}
      trigger="hover"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button icon={<PlusOutlined />} disabled={user.isViewer}>
        {t("calculation-flow.add")}
      </Button>
    </Popover>
  );
};

export default NodesPopover;
