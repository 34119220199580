import { useUser } from "@properate/auth";
import { Node as ReactFlowNode, useReactFlow } from "reactflow";
import { Form } from "antd";
import { useTranslations } from "@properate/translations";
import { ScheduleTable } from "../components/ScheduleTable";
import { ScheduleItem } from "../types";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import {
  NodeOutput,
  Body,
  NodeContent,
  NodeOutputLabel,
  InputHeader,
  LargeNode,
} from "./helpers/NodeComponents";

const FormItem = Form.Item;

interface Props {
  operationId: string;
  node_plan: ScheduleItem[];
}

export const getEmptyScheduleNode = (): ReactFlowNode<Props> => {
  return {
    id: getNodeId("schedule"),
    type: "schedule",
    data: {
      operationId: "schedule",
      node_plan: [],
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function ScheduleNode(params: { id: string; data: Props }) {
  const t = useTranslations();
  const reactFlowInstance = useReactFlow();
  const user = useUser();

  const handlePlanChange = (plan: ScheduleItem[]) => {
    updateReactFlowNodeData(reactFlowInstance, params.id, "node_plan", plan);
  };

  return (
    <LargeNode>
      <InputHeader>{t("calculation-flow.node-types.schedule")}</InputHeader>
      <Body>
        <NodeContent>
          <Form layout="vertical" disabled={user.isViewer}>
            <FormItem
              label={t("calculation-flow.node-types.active-time-periods")}
            >
              <ScheduleTable
                scheduleItems={params.data.node_plan}
                onChange={handlePlanChange}
              />
            </FormItem>
          </Form>
        </NodeContent>
        <NodeOutput>
          <NodeOutputLabel>
            {t("calculation-flow.node-types.schedule-description")}
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </LargeNode>
  );
}

export default ScheduleNode;
