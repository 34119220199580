import {
  AccordionActionType,
  AccordionEntry,
  SidebarActionType,
  useAccordionActions,
  useAccordionValues,
  useSidebarActions,
} from "@properate/ui";
import { useEffect } from "react";
import { Button } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { Note } from "./types";

export type PartialNoteWithId = Partial<Note> & Pick<Note, "id">;

export function NoteSidebarFilterButton({
  notes,
  filterId,
}: {
  notes: PartialNoteWithId[];
  filterId: string;
}) {
  const accordionDispatch = useAccordionActions();
  const { filters } = useAccordionValues();
  const sidebarDispatch = useSidebarActions();

  const isFiltering = filters.get(filterId)?.active ?? false;
  const disabled = notes.length === 0;

  useEffect(() => {
    if (filterId) {
      accordionDispatch({
        type: AccordionActionType.addFilter,
        filterId,
        filterFunction: (entry: AccordionEntry) =>
          notes.some(({ id }) => id === entry.uniqueId),
        enable: false,
      });
    }
    return () => {
      accordionDispatch({
        type: AccordionActionType.removeFilter,
        filterId,
      });
    };
  }, [accordionDispatch, filterId, notes]);

  function handleToggleFilter(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    if (disabled) {
      return;
    }
    if (isFiltering) {
      accordionDispatch({ type: AccordionActionType.disableFilter, filterId });
    } else {
      sidebarDispatch({ type: SidebarActionType.open });
      accordionDispatch({
        type: AccordionActionType.enableFilter,
        filterId,
        disableOthers: true,
      });
    }
  }

  return (
    <div
      className={"w-full h-full flex justify-center items-center"}
      onClick={handleToggleFilter}
    >
      <Button
        icon={<MessageOutlined aria-hidden />}
        disabled={disabled}
        shape="circle"
        className={`${
          isFiltering ? "bg-info-bg border-info text-info-fg" : ""
        }`}
        onClick={handleToggleFilter}
      />
    </div>
  );
}
