import { useNavigate, useParams } from "react-router-dom";
import { type Task, useGetTask } from "@properate/task-manager";
import { useTranslations } from "@properate/translations";
import { lazy, useMemo } from "react";
import { ProperateFile } from "@properate/file-viewer/src/components/types";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { cogniteClient } from "@/services/cognite-client";

const FileViewer = lazy(() => import("@properate/file-viewer"));

type Params = {
  id: string;
  taskId: string;
  fileId: string;
};

export function TaskManagerTaskFilePreviewPage() {
  const t = useTranslations();
  useBuildingPageTitle(t("task.file-preview.title"));

  const { taskId, fileId } = useParams() as Params;
  const { data, isLoading } = useGetTask(taskId);
  const navigate = useNavigate();

  const files = useMemo<
    (Omit<ProperateFile, "id"> & { id: string | number })[]
  >(() => {
    return (
      (data as Task | undefined)?.files.map((file) => ({
        id: file.cogniteFileId || file.id,
        name: file.name,
        modifiedTime: new Date(file.createdAt),
        mimeType: file.type,
        metadata: {
          url: file.url,
          thumbnailUrl: file.thumbnailUrl || "",
        },
      })) || []
    );
  }, [data]);

  const onClose = () => {
    navigate(`../workOrders/${taskId}`);
  };

  const handleChangeFile = (fileId: number | string) => {
    navigate({
      pathname: `../workOrders/${taskId}/files/${fileId}`,
      search: window.location.search,
    });
  };

  if (isLoading) {
    return (
      <SpinnerWithDelay
        className="flex items-center justify-center h-full w-full"
        isLoading
      >
        <div />
      </SpinnerWithDelay>
    );
  }

  return (
    <div className="absolute h-screen w-screen left-0 top-0 bg-background">
      <FileViewer
        autoScreenDetection
        path={[t("task.file-preview.title"), data?.title || ""]}
        files={files}
        file={
          files.find((f) => f.id.toString() === fileId.toString()) || files[0]
        }
        onClose={onClose}
        client={cogniteClient}
        onChangeFile={handleChangeFile}
      />
    </div>
  );
}
