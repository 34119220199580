import { useMemo } from "react";
import { useTheme } from "styled-components";
import { scaleBand } from "@visx/scale";
import { AxisBottom } from "@visx/axis";
import { bottomTickLabelProps } from "@visx/axis/lib/axis/AxisBottom";
import { getTickLabelProps, useWeekdayNames } from "../utils";

interface Props {
  graphWidth: number;
  offsetRight: number;
  compact: boolean;
}

export function HeatMapTopAxis({ graphWidth, offsetRight, compact }: Props) {
  const theme = useTheme();
  const weekDayNames = useWeekdayNames();
  const scale = useMemo(() => {
    return scaleBand({
      range: [0, graphWidth - offsetRight],
      domain: weekDayNames,
    });
  }, [graphWidth, offsetRight, weekDayNames]);

  function isSunday(tick: string) {
    return tick === weekDayNames.at(-1);
  }

  return (
    <AxisBottom
      scale={scale}
      hideAxisLine
      hideTicks
      numTicks={scale.domain().length}
      tickLabelProps={(value) => ({
        ...bottomTickLabelProps,
        ...getTickLabelProps(theme, compact),
        ...(isSunday(value)
          ? {
              fill: theme.error,
            }
          : null),
      })}
    />
  );
}
