import { Select } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { Granularity } from "@/utils/helpers";

type Props = {
  granularity: Granularity;
  setGranularity: (granularity: Granularity) => void;
};

const granularities: Granularity[] = ["h", "d", "w", "M"];
export const SelectGranularity = ({ granularity, setGranularity }: Props) => {
  const t = useTranslations();

  return (
    <Select
      value={granularity}
      onChange={setGranularity}
      style={{ width: 120 }}
    >
      {granularities.map((g) => (
        <Select.Option key={g} value={g}>
          {t(`energy.granularity.${g}` as MessageKey)}
        </Select.Option>
      ))}
    </Select>
  );
};
