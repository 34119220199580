import React from "react";
import { Button } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons/lib";
import { useTranslations } from "@properate/translations";

type ShowReferenceYearProps = {
  showReferenceYear?: boolean;
  setShowHideReferenceYear: Function;
};

export const ShowReferenceYear = ({
  showReferenceYear,
  setShowHideReferenceYear,
}: ShowReferenceYearProps) => {
  const t = useTranslations();

  return (
    <Button
      icon={showReferenceYear ? <EyeInvisibleOutlined /> : <EyeOutlined />}
      onClick={() => setShowHideReferenceYear(!showReferenceYear)}
    >
      {showReferenceYear
        ? t("dashboard.widgets.energy-consumption.hide-measure-curve")
        : t("dashboard.widgets.energy-consumption.show-measure-curve")}
    </Button>
  );
};
