import { useState, useEffect, FunctionComponent } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Col, Row, App, Button } from "antd";
import "../styles.css";
import { ArrowUpOutlined } from "@ant-design/icons";
import { TimeSpan } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { isErrorWithMessage } from "@/utils/api";
import { useInitialCalculationFlow } from "../initialStates";
import { CalculationFlow } from "../types";
import {
  getCalculationFlow,
  runCalculationFlow,
  createOrUpdateCalculationFlow,
} from "../../../eepApi";
import { getEmptyTimeSeriesOutputNode } from "../nodes";
import GraphEditor from "./GraphEditor";
import FlowPageHeader from "./FlowPageHeader";
import RunModal from "./RunModal";
import { safeRun } from "./utils";
import SimulationCard from "./SimulationCard";

interface Props {
  flowType: "cloudAutomation" | "virtualSensor" | "flexAutomation";
}

function getExternalIdsFromNodes(calculationFlow: CalculationFlow) {
  const externalIds: { externalId: string }[] = [];

  calculationFlow.calculation_flow.nodes.forEach((node) => {
    const { data } = node;

    if (data?.externalId) {
      externalIds.push(data.externalId);
    }

    if (data?.externalIds) {
      data?.externalIds.forEach((externalId: { externalId: string }) => {
        externalIds.push(externalId);
      });
    }
  });

  return externalIds;
}

export const CalculationFlowEditor: FunctionComponent<Props> = ({
  flowType,
}) => {
  const t = useTranslations();
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams] = useSearchParams();
  const { notification } = App.useApp();
  const { client } = useCogniteClient();

  useBuildingPageTitle(
    flowType === "cloudAutomation"
      ? t("calculation-flow.cloud-automation-editor")
      : flowType === "virtualSensor"
      ? t("calculation-flow.virtual-sensor-editor")
      : t("calculation-flow.flex-automation-editor"),
  );
  const building = useCurrentBuilding();
  const { calculationFlowId } = useParams() as {
    calculationFlowId: string;
  };

  const [isSimulationIsVisible, setIsSimulationIsVisible] = useState(false);
  const [isRunModalVisible, setIsRunModalVisible] = useState(false);
  const [lastSavedCalculationFlow, setLastSavedCalculationFlow] =
    useState<CalculationFlow>();
  const [calculationFlow, setCalculationFlow] = useState<CalculationFlow>();
  const [timeseriesIds, setTimeseriesIds] = useState(new Set<number>());
  const initialCalculationFlow = useInitialCalculationFlow();

  useEffect(() => {
    async function fetchFlow() {
      if (calculationFlowId === "new") {
        const newFlow = initialCalculationFlow(building, flowType);
        setLastSavedCalculationFlow(newFlow);
      } else if (calculationFlowId === "copy") {
        const id = queryParams.get("id");
        const name = queryParams.get("name");
        if (id === null) throw "Cannot copy a flow without having an id";

        const currentFlow = await getCalculationFlow(id);
        // Remove references to other externalIds/Ids
        const newFlow: CalculationFlow = {
          ...currentFlow,
          id: null,
          building_id: building.id,
          name: name !== null ? name : currentFlow.name,
          calculation_flow: {
            ...currentFlow.calculation_flow,
            nodes: currentFlow.calculation_flow.nodes.map((node) => {
              return {
                ...node,
                data: {
                  ...node.data,
                  ...("externalId" in node.data ? { externalId: null } : null),
                  ...(node.data.operationId === "write_timeseries" &&
                  building.externalId !== undefined
                    ? {
                        parentExternalId: getEmptyTimeSeriesOutputNode(
                          building.externalId,
                        ).data.parentExternalId,
                      }
                    : null),
                  ...("building" in node.data
                    ? { building: building.externalId }
                    : null),
                  ...("subBuilding" in node.data
                    ? { subBuilding: null }
                    : null),
                  ...("system" in node.data ? { system: null } : null),
                },
              };
            }),
          },
        };
        setLastSavedCalculationFlow(newFlow);
      } else {
        try {
          const newFlow = await getCalculationFlow(calculationFlowId);
          const externalIds = getExternalIdsFromNodes(newFlow);
          const timeseries =
            externalIds.length > 0
              ? await client.timeseries.retrieve(
                  [...new Set(externalIds.map((id) => id.externalId))].map(
                    (externalId) => ({ externalId }),
                  ),
                  { ignoreUnknownIds: true },
                )
              : [];
          setTimeseriesIds(new Set(timeseries.map((ts) => ts.id)));
          setLastSavedCalculationFlow(newFlow);
        } catch (error) {
          if (isErrorWithMessage(error)) {
            const errorMessage = error.message;
            notification.error({
              message: t("calculation-flow.error-message", {
                errorMessage,
              }),
            });
          }
          console.error(
            `Failed to get ${flowType} for building: ${building.id}, error: ${error}`,
          );
        }
      }
    }
    fetchFlow();
  }, [
    flowType,
    calculationFlowId,
    notification,
    building,
    queryParams,
    client.timeseries,
    t,
  ]);

  useEffect(() => {
    setCalculationFlow(lastSavedCalculationFlow);
  }, [lastSavedCalculationFlow]);

  const save = async () => {
    if (!calculationFlow) return;
    const newCalculationFlow =
      await createOrUpdateCalculationFlow(calculationFlow);

    // Update address bar
    if (calculationFlow.id === null && newCalculationFlow.id !== null) {
      const baseUrl = location.pathname.substring(
        0,
        location.pathname.lastIndexOf("/"),
      );
      navigate(`${baseUrl}/${newCalculationFlow.id}`);
    }

    // The create API endpoint returns a new flow with
    // the external ids filled in, so we need to update
    // the flow in the React state
    setLastSavedCalculationFlow(newCalculationFlow);
    return newCalculationFlow;
  };

  const handleSave = async () => {
    await safeRun(save, t("calculation-flow.save-failed"), notification);
  };

  const handleRun = async (timeSpan: TimeSpan, purgeDatapoints: boolean) => {
    const saveAndRun = async () => {
      const newCalculationFlow = await save();
      if (newCalculationFlow === undefined || newCalculationFlow.id === null)
        return notification.error({
          message: t("calculation-flow.save-failed"),
          description: t("calculation-flow.no-id-found-after-saving"),
        });
      const runResults = await runCalculationFlow(
        newCalculationFlow.id,
        timeSpan,
        purgeDatapoints,
      );
      setCalculationFlow({
        ...newCalculationFlow,
        notifications: runResults.data.notifications,
      });
    };

    await safeRun(
      saveAndRun,
      t("calculation-flow.calculation-failed"),
      notification,
    );
  };

  const handleChange = (newCalculationFlow: CalculationFlow) => {
    setCalculationFlow({ ...newCalculationFlow });
  };

  const hasUnsavedChanges = calculationFlow !== lastSavedCalculationFlow;

  return (
    <>
      {calculationFlow && lastSavedCalculationFlow && (
        <>
          <FlowPageHeader
            calculationFlow={calculationFlow}
            hasUnsavedChanges={hasUnsavedChanges}
            onChange={handleChange}
            onSave={handleSave}
            onRun={() => setIsRunModalVisible(true)}
          />
          <div className="w-full h-full pb-2" data-testid="flow-canvas">
            <Row style={{ height: isSimulationIsVisible ? "50%" : "100%" }}>
              <Col span={24}>
                <GraphEditor
                  graph={lastSavedCalculationFlow.calculation_flow}
                  onChange={(graph) =>
                    handleChange({
                      ...calculationFlow,
                      calculation_flow: graph,
                    })
                  }
                  hiddenNodeButtons={
                    flowType === "virtualSensor"
                      ? ["write_setpoint"]
                      : ["write_timeseries_meter"]
                  }
                />
              </Col>
            </Row>
            {isSimulationIsVisible && (
              <Row style={{ height: "50%" }}>
                <SimulationCard
                  calculationFlow={calculationFlow}
                  onClose={() => setIsSimulationIsVisible(false)}
                />
              </Row>
            )}
          </div>
        </>
      )}
      {!isSimulationIsVisible && (
        <div style={{ position: "relative" }}>
          <Button
            onClick={() => setIsSimulationIsVisible(true)}
            icon={<ArrowUpOutlined />}
            style={{
              left: "50%",
              position: "absolute",
              transform: "translate(-50%, -100%)",
            }}
          >
            {t("calculation-flow.show-simulation")}
          </Button>
        </div>
      )}
      {building.dataSetId && (
        <NotesSidebar
          noteSource={
            flowType === "virtualSensor"
              ? NoteSource.WEB_VIRTUAL_SENSORS
              : flowType === "cloudAutomation"
              ? NoteSource.WEB_CLOUD_AUTOMATIONS
              : NoteSource.WEB_FLEX_AUTOMATIONS
          }
          assetFilterMode={NotesAssetFilterMode.TimeseriesList}
          idSet={timeseriesIds}
          buildings={[{ id: building.dataSetId, name: building.name }]}
        />
      )}
      <RunModal
        open={isRunModalVisible}
        onClose={() => setIsRunModalVisible(false)}
        showPeriodSelector={flowType === "virtualSensor"}
        onRun={async (timeSpan: TimeSpan, purgeDatapoints: boolean) => {
          await handleRun(timeSpan, purgeDatapoints);
          setIsRunModalVisible(false);
        }}
      ></RunModal>
    </>
  );
};
