import useSWR from "swr";
import { Datapoints, Timeseries } from "@cognite/sdk";
import { useCogniteClient } from "@/context/CogniteClientContext";

export type TimeseriesResponse = {
  timeseries?: Timeseries;
  isLoading: boolean;
  error: any;
};

export type DatapointResponse = {
  datapoints?: Datapoints;
  isLoading: boolean;
  error: any;
};

export type TimeseriesWithDatapointResponse = TimeseriesResponse &
  DatapointResponse & {
    latestDatapoint: string;
  };

export function useTimeseries({
  timeseriesId,
}: {
  timeseriesId: number | undefined;
}): TimeseriesResponse {
  const { client } = useCogniteClient();

  const {
    data: timeseries,
    isLoading,
    error,
  } = useSWR([timeseriesId, "timeseries"], async ([timeseriesId]) => {
    if (timeseriesId === undefined || timeseriesId === null) {
      return undefined;
    }
    const timeseries = await client.timeseries.retrieve([{ id: timeseriesId }]);
    return timeseries.length > 0 ? timeseries[0] : undefined;
  });
  return { timeseries, isLoading, error };
}

export function useDatapointsForTimeseries({
  timeseriesId,
}: {
  timeseriesId: number | undefined;
}): DatapointResponse {
  const { client } = useCogniteClient();

  const {
    data: datapoints,
    isLoading,
    error,
  } = useSWR([timeseriesId, "datapoints"], async ([timeseriesId]) => {
    if (timeseriesId === undefined) {
      return undefined;
    }
    const datapoints = await client.datapoints.retrieveLatest([
      { id: timeseriesId, before: "now" },
    ]);
    return datapoints ?? undefined;
  });

  return {
    datapoints: datapoints?.length ? datapoints[0] : undefined,
    isLoading,
    error,
  };
}

export function getLatestDatapoint({
  timeseries,
  datapoints,
}: {
  timeseries?: Timeseries;
  datapoints?: Datapoints;
}): string {
  if (!timeseries?.unit || !datapoints || datapoints.datapoints.length === 0) {
    return "--";
  }
  return `${datapoints.datapoints[0].value} ${timeseries.unit}`;
}

export function useTimeseriesWithDatapoint({
  timeseriesId,
}: {
  timeseriesId: number | undefined;
}): TimeseriesWithDatapointResponse {
  const {
    timeseries,
    isLoading: timeseriesLoading,
    error: timeseriesError,
  } = useTimeseries({ timeseriesId });

  const {
    datapoints,
    isLoading: datapointLoading,
    error: datapointError,
  } = useDatapointsForTimeseries({ timeseriesId });

  return {
    timeseries,
    datapoints,
    latestDatapoint: getLatestDatapoint({ timeseries, datapoints }),
    isLoading: timeseriesLoading || datapointLoading,
    error: timeseriesError ?? datapointError ?? null,
  };
}
