import { NoteLevel, NoteLevelColors } from "./types";
import { NoteLevelText } from "./NoteLevelText";

export function NoteLevelPill({ level }: Readonly<{ level?: NoteLevel }>) {
  const showLevel = level ?? NoteLevel.UNKNOWN;
  return (
    <div
      className={`inline-flex justify-center items-center py-px px-2 border-solid border rounded ${
        NoteLevelColors[showLevel] || NoteLevelColors[NoteLevel.UNKNOWN]
      }`}
    >
      <NoteLevelText level={showLevel} />
    </div>
  );
}
