import {
  Datapoints,
  DoubleDatapoints,
  DoubleDatapoint,
  DatapointAggregate,
  DatapointAggregates,
  Aggregate,
} from "@cognite/sdk";
import dayjs from "@properate/dayjs";
import { PeriodType } from "@/routes/energy/types";
import { cogniteClient } from "@/services/cognite-client";
import {
  getSummerTimeStartFromDate,
  getWinterTimeStartFromDate,
  Granularity,
  isSameSummerOrWinterTime,
} from "@/utils/helpers";

/**
 * Get the datapoints between the start and the end, if the period crosses over summer/wintertime the split the query
 * into sub queries, if the query will potentially return more than 10 000 points then split it into multiple queries
 * @param ids
 * @param start
 * @param end
 * @param aggregate
 */
export const getDatapointsHoursRaw = async ({
  ids,
  start,
  end,
  aggregate,
}: {
  ids: number[];
  start: Date;
  end: Date;
  aggregate: Aggregate;
}) => {
  return retrieveDatapoints({ ids, start, end, aggregate });
};

const GRANULARITY_TO_HOURS: Record<Granularity, number> = {
  h: 1,
  d: 24,
  w: 168,
  M: 730,
};
const getPeriods = ({
  start,
  end,
  granularity = "h",
}: {
  start: Date;
  end: Date;
  granularity: Granularity;
}) => {
  const periods: PeriodType[] = [];

  let current = dayjs(start);

  while (current.isBefore(end)) {
    if (
      isSameSummerOrWinterTime({
        first: current,
        second: dayjs(end),
        granularity,
      })
    ) {
      periods.push({
        start: current.toDate(),
        end: end,
        granularity: `${GRANULARITY_TO_HOURS[granularity]}h`,
      });
      current = dayjs(end);
    } else {
      const startsOnDaylightSavingTime =
        dayjs(current).utcOffset() > dayjs(current).month(0).utcOffset(); // northern hemisphere

      const DSTChange = (
        startsOnDaylightSavingTime
          ? getWinterTimeStartFromDate(dayjs(current))
          : getSummerTimeStartFromDate(dayjs(current))
      ).startOf(granularity);

      periods.push({
        start: current.toDate(),
        end: DSTChange.toDate(),
        granularity: `${GRANULARITY_TO_HOURS[granularity]}h`,
      });

      const dstHourChange = startsOnDaylightSavingTime ? 1 : -1;

      if (granularity === "h") {
        if (!startsOnDaylightSavingTime) {
          current = DSTChange;
          continue;
        }
        const afterDSTChange = DSTChange.add(2, "h");

        periods.push({
          start: DSTChange.toDate(),
          end: afterDSTChange.toDate(),
          granularity: `2h`,
        });
        current = afterDSTChange;
        continue;
      }

      const afterDSTChange = DSTChange.add(1, granularity);

      periods.push({
        start: DSTChange.toDate(),
        end: afterDSTChange.toDate(),
        granularity: `${GRANULARITY_TO_HOURS[granularity] + dstHourChange}h`,
      });

      current = afterDSTChange.isBefore(end) ? afterDSTChange : dayjs(end);
    }
  }
  // remove invalid periods
  return periods.reduce((prev, period) => {
    if (period.start.valueOf() === period.end.valueOf()) {
      return prev;
    }

    return [...prev, period];
  }, [] as PeriodType[]);
};
const retrieveDatapoints = async ({
  ids,
  start,
  end,
  aggregate,
}: {
  ids: number[];
  start: Date;
  end: Date;
  aggregate: Aggregate;
}): Promise<DoubleDatapoints[]> => {
  if (dayjs(end).isSame(start)) {
    throw Error("End same as start");
  }
  const periods: PeriodType[] = getPeriods({
    start,
    end,
    granularity: "h",
  });

  const datapoints = await periods.reduce<
    Promise<Record<string, DoubleDatapoint[]>>
  >(async (prev, period) => {
    // if we expect too many datapoints then we do a query for each id
    const data = await prev;

    const limit = dayjs(period.end).diff(period.start, "h") + 1;

    const datapoints =
      period.granularity !== "1h"
        ? (
            (await cogniteClient.datapoints.retrieve({
              items: ids.map((id) => ({ id })),
              start: period.start,
              end: period.end,
              aggregates: [aggregate],
              granularity: period.granularity,
              limit: 1000,
            })) as DatapointAggregates[]
          ).map((aggregatedDatapoints) => {
            return {
              ...aggregatedDatapoints,
              datapoints: aggregatedDatapoints.datapoints.map(
                (datapoint) =>
                  ({
                    ...datapoint,
                    value: datapoint[aggregate],
                  }) as DoubleDatapoint,
              ),
            } as Datapoints;
          })
        : limit * ids.length > 10000
        ? ((
            await Promise.all(
              ids.map((id) =>
                cogniteClient.datapoints.retrieve({
                  items: [
                    {
                      id,
                    },
                  ],
                  start: period.start,
                  end: period.end,
                  limit: 10000,
                }),
              ),
            )
          ).flat() as Datapoints[])
        : ((await cogniteClient.datapoints.retrieve({
            items: ids.map((id) => ({ id })),
            start: period.start,
            end: period.end,
            limit,
          })) as Datapoints[]);

    return datapoints.reduce(
      (prev, datapoints) => ({
        ...prev,
        [datapoints.id]: [
          ...(prev[datapoints.id] || []),
          ...datapoints.datapoints,
        ],
      }),
      data,
    );
  }, Promise.resolve({}));

  return ids.map((id) => ({
    id,
    isString: false,
    datapoints: datapoints[id],
  }));
};

export const retrieveAggregateOfDatapointsInPeriod = async ({
  ids,
  start,
  end,
  granularity,
  aggregate,
}: {
  ids: number[];
  start: Date;
  end: Date;
  granularity: Granularity;
  aggregate: Aggregate;
}): Promise<DoubleDatapoints[]> => {
  if (dayjs(start).isSame(end)) {
    throw Error("End same as start");
  }
  if (granularity === "M") {
    let currentStart = dayjs(start);
    let timeseriesCollection = null;
    while (currentStart.valueOf() < end.valueOf()) {
      const nextStart = currentStart.add(1, "M");
      const currentDatapoints = await cogniteClient.datapoints.retrieve({
        items: ids.map((id) => ({ id })),
        start: currentStart.toDate(),
        end: nextStart.toDate(),
        granularity: `${nextStart.diff(currentStart, "h")}h`,
        aggregates: [aggregate],
        limit: ids.length,
      });
      if (!timeseriesCollection) {
        timeseriesCollection = currentDatapoints;
      } else {
        // datapoints has one entry for each id, for subsequent datapoints we just add them to the corresponding
        // entry in datapoints
        timeseriesCollection.forEach((datapointsWithId, index) => {
          datapointsWithId.datapoints = [
            ...datapointsWithId.datapoints,
            ...currentDatapoints[index].datapoints,
          ];
        });
      }

      currentStart = nextStart;
    }
    if (timeseriesCollection === null) {
      return [];
    }
    return timeseriesCollection.map((datapointsWithId) => ({
      id: datapointsWithId.id,
      isString: false,
      datapoints: (datapointsWithId.datapoints as DatapointAggregate[]).map(
        (datapoint) =>
          ({
            ...datapoint,
            value: datapoint[aggregate],
          }) as DoubleDatapoint,
      ),
    }));
  }

  const periods = getPeriods({ start, end, granularity });

  const datapoints = await periods.reduce<
    Promise<Record<string, DatapointAggregate[]>>
  >(async (prev, period) => {
    // if we expect too many datapoints then we do a query for each id
    const limit = dayjs(period.end).diff(period.start, granularity) + 1;

    const datapoints =
      limit * ids.length > 10000
        ? (
            await Promise.all(
              ids.map((id) =>
                cogniteClient.datapoints.retrieve({
                  items: [
                    {
                      id,
                    },
                  ],
                  start: period.start,
                  end: period.end,
                  granularity: period.granularity,
                  aggregates: [aggregate],
                  limit: 10000,
                }),
              ),
            )
          ).flat()
        : ((await cogniteClient.datapoints.retrieve({
            items: ids.map((id) => ({ id })),
            start: period.start,
            end: period.end,
            granularity: period.granularity,
            aggregates: [aggregate],
            limit,
          })) as DatapointAggregates[]);

    const data = await prev;

    return datapoints.reduce(
      (prev, datapoints) => ({
        ...prev,
        [datapoints.id]: [
          ...(prev[datapoints.id] || []),
          ...datapoints.datapoints,
        ],
      }),
      data,
    );
  }, Promise.resolve({}));

  return ids.map((id) => ({
    id,
    isString: false,
    datapoints: datapoints[id].map(
      (datapoint) =>
        ({
          timestamp: datapoint.timestamp,
          value: datapoint[aggregate],
        }) as DoubleDatapoint,
    ),
  }));
};
