import styled from "styled-components";

export const LastWeekAndLast365DaysContainer = styled.div`
  display: flex;
  height: 50%;
  flex-direction: row;
  @media (max-width: 1400px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const Column = styled.div`
  width: 100%;
  height: 100%;
`;

export const StartEndRange = styled.div`
  width: 100%;
  height: 50%;
`;
