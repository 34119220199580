import { App, Button, Input, InputNumber, Modal, Select, Space } from "antd";
import { useTranslations } from "@properate/translations";
import { ChangeEvent, useState } from "react";
import { CalculationFlow } from "@/pages/calculationFlow/types";
import {
  createOrUpdateCalculationFlow,
  createOrUpdateManualTimeseries,
} from "@/eepApi";
import {
  NewVirtualSetpoint,
  VirtualSetpoint,
} from "@/components/MeterSelectionModal/types";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { priorityToString } from "../utils";

interface Props {
  flow: CalculationFlow;
  onClose: () => void;
  onChange: () => void;
}

export const EditMeasureModal = ({ flow, onClose, onChange }: Props) => {
  const t = useTranslations();
  const currentBuilding = useCurrentBuilding();
  const { notification } = App.useApp();
  const [saving, setSaving] = useState(false);
  const [updatedFlow, setUpdatedFlow] = useState<CalculationFlow>(flow);

  const handleSave = async () => {
    setSaving(true);
    try {
      // For new flex automations, create a virtual setpoint as activation input
      if (flow.id === null && currentBuilding.externalId !== undefined) {
        const newVirtualSetpoint: NewVirtualSetpoint = {
          type: "virtual_setpoint",
          description: "Activation input",
          max_value: 100,
          min_value: 0,
          externalId: null,
          building: currentBuilding.externalId,
          subBuilding: "Common",
          system: "000.000",
          unit: "%",
        };
        const virtualSetpoint =
          await createOrUpdateManualTimeseries(newVirtualSetpoint);
        // Add the virtual setpoint externalId to the flow
        updatedFlow.calculation_flow.nodes[1].data.externalId =
          virtualSetpoint.externalId;
        updatedFlow.metadata!.activationExternalId =
          virtualSetpoint.externalId.externalId;
      }
      // Save the updated flow
      await createOrUpdateCalculationFlow(updatedFlow);
      setSaving(false);
      onChange();
      notification.success({
        message: t("energy-flexing.measures.saved"),
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: t("energy-flexing.error-while-saving-measure"),
      });
    }
  };

  function handlePriorityChange(value: string): void {
    setUpdatedFlow({
      ...updatedFlow,
      metadata: { ...updatedFlow.metadata!, priority: parseInt(value) },
    });
  }

  function handleNameChange(event: ChangeEvent<HTMLInputElement>): void {
    setUpdatedFlow({ ...updatedFlow, name: event.target.value });
  }

  function handlePotentialChange(maxKW: number | null): void {
    if (maxKW)
      setUpdatedFlow({
        ...updatedFlow,
        metadata: {
          ...updatedFlow.metadata!,
          potential: { ...updatedFlow.metadata!.potential, maxKW },
        },
      });
  }

  function handleRampupChange(rampUpSec: number | null): void {
    if (rampUpSec)
      setUpdatedFlow({
        ...updatedFlow,
        metadata: {
          ...updatedFlow.metadata!,
          potential: { ...updatedFlow.metadata!.potential, rampUpSec },
        },
      });
  }

  function handleMaxDurationChange(maxDurationSec: number | null): void {
    if (maxDurationSec)
      setUpdatedFlow({
        ...updatedFlow,
        metadata: {
          ...updatedFlow.metadata!,
          potential: { ...updatedFlow.metadata!.potential, maxDurationSec },
        },
      });
  }

  return (
    <Modal
      title={
        flow.id
          ? t("calculation-flow.update-flex-automation")
          : t("calculation-flow.new-flex-automation")
      }
      open
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t("calculation-flow.table.cancel")}
        </Button>,
        <Button key="add" type="primary" loading={saving} onClick={handleSave}>
          {t("energy-flexing.measures.save")}
        </Button>,
      ]}
    >
      <Space direction="vertical">
        <div>
          {"Name: "}
          <Input
            value={updatedFlow.name}
            style={{ width: 240 }}
            onChange={handleNameChange}
          />
        </div>
        <div>
          {" Priority: "}
          <Select
            defaultValue="50"
            onChange={handlePriorityChange}
            options={[...Array(100).keys()].map((i) => ({
              value: (i + 1).toString(),
              label: `${(i + 1).toString()} (${priorityToString(
                i + 1,
                t,
              )} påvirkning)`,
            }))} // 1-100
          />
        </div>
        <div>
          {"Potential: "}
          <InputNumber
            value={updatedFlow.metadata!.potential.maxKW}
            onChange={handlePotentialChange}
          />
          {"kW"}
        </div>
        <div>
          {"Ramp up period: "}
          <InputNumber
            value={updatedFlow.metadata!.potential.rampUpSec}
            onChange={handleRampupChange}
          />
          {"s"}
        </div>
        <div>
          {"Max duration: "}
          <InputNumber
            value={updatedFlow.metadata!.potential.maxDurationSec}
            onChange={handleMaxDurationChange}
          />
          {"s"}
        </div>
      </Space>
    </Modal>
  );
};
