import { Fragment } from "react";
import { Period } from "@properate/common";
import { Row, Col, Button } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { useUser } from "@properate/auth";
import { useTranslations, MessageKey } from "@properate/translations";
import { combinePeriods, formatPeriods } from "../../utils";

interface Props {
  periods: Period[];
  onChange: (value: Period[]) => unknown;
}

export function SchedulePaneActivePeriods({ periods, onChange }: Props) {
  const t = useTranslations();

  const user = useUser();

  return (
    <Row gutter={[0, 8]}>
      {combinePeriods(periods).map((period, index) => (
        <Fragment key={index}>
          <Col span={22}>
            {formatPeriods(
              period.map((period) => ({
                ...period,
                translatedDay: t(
                  `common.day-periods.${period.day.toLowerCase()}` as MessageKey,
                ),
              })),
            )}
          </Col>
          <Col span={2}>
            <Button
              disabled={user.isViewer}
              size="small"
              shape="circle"
              icon={
                <MinusOutlined
                  onClick={() => {
                    onChange(periods.filter((a) => !period.includes(a)));
                  }}
                />
              }
            />
          </Col>
        </Fragment>
      ))}
    </Row>
  );
}
