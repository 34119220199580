import styled from "styled-components";
import { Input, Modal, Button } from "antd";

export const Alarm = styled.span<{
  alarmType: "warning" | "error" | "status" | undefined;
}>`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) =>
    props.alarmType === "error"
      ? "#FF5129"
      : props.alarmType === "status"
      ? "inherit"
      : "#FFD80B"};
`;

export const DescriptionInput = styled(Input)`
  font-size: 2em;
  font-weight: bold;
  padding: 0 5px;
  margin-left: -6px;
  min-width: 666px;
  color: ${(props) => props.theme.neutral4};
  margin-top: -1px;
  margin-bottom: -1px;

  &.ant-input-affix-wrapper-focused {
    background: ${(props) => props.theme.background2};
  }

  &.ant-input-affix-wrapper-focused input {
    background: ${(props) => props.theme.background2};
  }

  input {
    font-weight: 500;
    color: inherit;
    background: ${(props) => props.theme.background};
  }
`;

export const GModal = styled(Modal)`
  & .ant-modal-body {
    padding: 0;
  }

  .ant-btn-link {
    color: ${(props) => props.theme.neutral5};
    & h1 {
      font-size: 22px;
      font-weight: 500;
      font-family: inherit;
    }
  }

  .ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${(props) => props.theme.neutral6};
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-body > div > div + div > div + div {
    width: auto;
    height: auto;
  }

  .ant-modal-close-x {
    width: 44px;
    display: contents;

    height: 44px;
    color: ${(props) => props.theme.neutral5};

    &:hover {
      color: ${(props) => props.theme.neutral1};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.neutral8};
  background: ${(props) => props.theme.neutral9};
  max-height: 624px;
`;

export const GraphSection = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px 32px 16px 16px;
  min-height: 500px;
  position: relative;
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const GraphController = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
`;

export const MinimizeButton = styled(Button)`
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  ${Container}.minimized & {
    transform: rotate(180deg);
  }
`;

export const Details = styled.div`
  transition: max-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 400px;

  ${Container}.minimized & {
    max-width: 60px;
  }

  position: relative;
  background: ${(props) => props.theme.neutral10};
  border-left: 1px solid ${(props) => props.theme.neutral8};

  h2 {
    color: ${(props) => props.theme.neutral4};
  }

  flex: 0 1 auto;
  margin: -16px;
`;

export const DetailsContent = styled.div`
  padding: 18px 50px 32px 50px;
  width: 373px;
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 600px;
`;

export const Minimize = styled.div`
  position: absolute;
  top: 28px;
  left: -26px;
  width: 26px;
  height: 30px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background: ${(props) => props.theme.neutral10};
  border-top: 1px solid ${(props) => props.theme.neutral8};
  border-bottom: 1px solid ${(props) => props.theme.neutral8};
  border-left: 1px solid ${(props) => props.theme.neutral8};
`;
