import { Select } from "antd";
import { useTranslations } from "@properate/translations";

interface Props {
  id: string;
  organization?: string;
  organizations: string[];
  handleChangeOrganization: (organization: string) => void;
}

export function SelectOrganization({
  id,
  organization,
  organizations,
  handleChangeOrganization,
}: Props) {
  const t = useTranslations();
  return (
    <Select
      id={id}
      style={{ width: 200 }}
      options={organizations.map((organization) => ({
        label: t("energy.owner-name", {
          name: organization || "",
          index: organization === organizations[0] ? 0 : 1,
        }),
        value: organization,
      }))}
      value={organization}
      onChange={handleChangeOrganization}
    />
  );
}
