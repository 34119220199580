import { DoubleDatapoint } from "@cognite/sdk";
import { LinePath, Bar } from "@visx/shape";
import { curveStep } from "@visx/curve";
import { useTheme } from "styled-components";
import { isCurrentDatapoint } from "../utils";
import type { ScaleTime, ScaleBand } from "d3-scale";
import type { ScaleLinearNumeric, SpotPriceGranularity } from "@/utils/types";

interface Props {
  datapoints: DoubleDatapoint[];
  barScale: ScaleBand<number>;
  valueScale: ScaleLinearNumeric;
  timeScale: ScaleTime<number, number, never>;
  graphHeight: number;
  activeDatapoint: DoubleDatapoint | null;
  granularity: SpotPriceGranularity;
}

export function SpotPriceGraphChart({
  datapoints,
  barScale,
  valueScale,
  timeScale,
  graphHeight,
  activeDatapoint,
  granularity,
}: Props) {
  const theme = useTheme();
  return (
    <>
      <LinePath
        curve={curveStep}
        data={datapoints}
        strokeWidth={2}
        stroke={theme.primary}
        x={(datapoint) => timeScale(datapoint.timestamp)}
        y={(datapoint) => valueScale(datapoint.value)}
      />
      {datapoints.map((datapoint, i) => {
        const barHeight = graphHeight - valueScale(datapoint.value);
        const isFirstDatapoint = i === 0;
        const isFirstOrLastDatapoint =
          isFirstDatapoint || i === datapoints.length - 1;
        return (
          <Bar
            key={datapoint.timestamp.valueOf()}
            width={
              isFirstOrLastDatapoint
                ? barScale.bandwidth() / 2
                : barScale.bandwidth()
            }
            height={barHeight}
            x={
              isFirstDatapoint
                ? timeScale(datapoint.timestamp)
                : timeScale(datapoint.timestamp) - barScale.bandwidth() / 2
            }
            y={graphHeight - barHeight}
            fill={theme.primary}
            fillOpacity={
              isCurrentDatapoint(datapoint, granularity)
                ? 0.7
                : datapoint === activeDatapoint
                ? 0.5
                : 0.1
            }
          />
        );
      })}
    </>
  );
}
