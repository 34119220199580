import { PlusOutlined } from "@ant-design/icons";
import { Timeseries } from "@cognite/sdk";
import { App, Button } from "antd";
import { useState } from "react";
import { useTranslations } from "@properate/translations";
import { importCalendar } from "@/eepApi";
import { TimeseriesSelectionModal } from "@/features/timeseries";

type Props = {
  refreshCalendarList: () => Promise<void>;
};
export const ImportCalendarButton = ({ refreshCalendarList }: Props) => {
  const t = useTranslations();

  const { message } = App.useApp();
  const [selectSetPointModal, setSelectSetPointModal] = useState(false);

  const doImportCalendar = async (timeseries: Timeseries[]) => {
    if (timeseries.length === 0) {
      return;
    }
    try {
      await importCalendar({
        setPointExternalId: timeseries[0].externalId!,
      });
      await refreshCalendarList();
      message.success(t("calendar.calendar-added-from-setpoint"));
    } catch (e) {
      if (e instanceof Error) {
        message.error(
          t("calendar.could-not-add-calendar-from-setpoint-error", {
            errorMessage: e.message,
          }),
        );
      } else {
        message.error(t("calendar.could-not-add-calendar-from-setpoint"));
      }
    }
  };
  const handleImportCalendar = () => {
    setSelectSetPointModal(true);
  };
  return (
    <>
      <Button icon={<PlusOutlined />} onClick={handleImportCalendar}>
        {t("calendar.add-setpoint-as-calendar")}
      </Button>
      <TimeseriesSelectionModal
        open={selectSetPointModal}
        onHide={() => setSelectSetPointModal(false)}
        onOk={doImportCalendar}
        selectedIds={[]}
        min={1}
        max={1}
        initialFilters={{ category: "setPoint" }}
        hiddenFilters={["category"]}
      />
    </>
  );
};
