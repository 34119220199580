import { useTranslations } from "@properate/translations";

const AnalysisNoteCreationModeCustomContent = ({
  subtitle,
}: {
  subtitle: string;
}) => {
  const t = useTranslations();

  return (
    <dl className="mx-6 flex flex-col content-center text-center">
      <dt className="font-bold text-2xl">
        {t("analysis.notes.sidebar-custom.title")}
      </dt>
      <dd className="mx-0 my-2 text-xl">{subtitle}</dd>
    </dl>
  );
};
export const AnalysisSimpleGraphNoteCreationCustomContent = () => {
  const t = useTranslations();

  return (
    <AnalysisNoteCreationModeCustomContent
      subtitle={t("analysis.notes.sidebar-custom.select-range")}
    />
  );
};
export const AnalysisScatterplotNoteCreationCustomContent = () => {
  const t = useTranslations();

  return (
    <AnalysisNoteCreationModeCustomContent
      subtitle={t("analysis.notes.sidebar-custom.select-dot")}
    />
  );
};
