import { App, Button, DatePicker, Form, Modal, Row, Tooltip } from "antd";
import { useMemo, useState } from "react";
import dayjs, { Dayjs } from "@properate/dayjs";
import {
  DatapointAggregates,
  Datapoints,
  FileInfo,
  Timeseries,
} from "@cognite/sdk";
import { useUser } from "@properate/auth";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { reports } from "@/eepApi";
import { disabledFutureDates, getTimeseriesWithLabels } from "@/utils/helpers";
import {
  useLastHalfYearPresets,
  useLastXMonthPreset,
  useLastXQuarterPresets,
  getStartAndEndOfTheMonthDates,
} from "@/utils/date";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { cogniteClient } from "@/services/cognite-client";
import { ReactComponent as WasteSvg } from "./icon/waste.svg";

type Props = {
  openModal?: boolean;
  lang: "no";
  addFile: (files: FileInfo[]) => void;
  startDate?: string;
  endDate?: string;
  wasteTimeseriesList: Timeseries[];
};
type RangeValueType = [Dayjs | null, Dayjs | null] | null;

const MINIMUM_DAYS_TO_CREATE_GOOD_REPORT = 28;
export const WasteBigButtons = ({
  openModal,
  lang,
  addFile,
  startDate,
  endDate,
  wasteTimeseriesList,
}: Props) => {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const user = useUser();
  const building = useCurrentBuilding();
  const [open, setOpen] = useState(openModal);
  const t = useTranslations();

  function handleChange(dates: RangeValueType) {
    if (dates && dates[0] && dates[1]) {
      const start = dayjs(dates[0].startOf("day"));
      const end = dayjs(dates[1].endOf("day"));
      const duration = end.diff(start, "days") + 1;

      if (duration < MINIMUM_DAYS_TO_CREATE_GOOD_REPORT) {
        notification.warning({
          message: t(
            "reports.waste.create.notification-min-days-to-create-report",
            { MINIMUM_DAYS_TO_CREATE_GOOD_REPORT, duration },
          ),
          duration: 0,
        });
      }
    }
  }

  function showModal() {
    setOpen(true);
  }

  function handleCancel() {
    setOpen(false);
    form.resetFields();
    navigate(`../reports`);
  }

  async function hasDatapointsWithStartAndEndDates(
    buildingId: number,
    periodStart: Dayjs,
    periodEnd: Dayjs,
  ) {
    const wasteTimeseriesList = await getTimeseriesWithLabels(
      cogniteClient,
      buildingId,
      ["waste", "wastehub"],
    );
    if (wasteTimeseriesList.length === 0) {
      return false;
    }

    const { start, end } = getStartAndEndOfTheMonthDates(
      periodStart,
      periodEnd,
    );

    const datapointsRetrieve: Datapoints[] | DatapointAggregates[] =
      await cogniteClient.datapoints.retrieve({
        items: wasteTimeseriesList.map((t) => ({ id: t.id })),
        start: start.toDate(),
        end: end.toDate(),
        granularity: `${end.diff(start, "hour")}h`,
        aggregates: ["sum"],
      });
    return datapointsRetrieve.some((point) => point.datapoints.length > 0);
  }

  async function generateReport() {
    const validated = await form.validateFields();
    if (!validated.errorFields) {
      try {
        const periodStart = dayjs(validated.period[0]).startOf("day");
        const periodEnd = dayjs(validated.period[1]).endOf("day");
        // 2022-01-01T00:00:00
        const data = {
          building_list: [building!.externalId!],
          report_type: "waste_report",
          period_start: periodStart.valueOf(),
          period_end: periodEnd.valueOf(),
          user: user.email,
          language: lang,
        };

        const hasDatapoints = await hasDatapointsWithStartAndEndDates(
          building.id,
          periodStart,
          periodEnd,
        );

        if (!hasDatapoints) {
          const formatPeriodStart = dayjs(periodStart).format("D. MMMM YYYY");
          const formatPeriodEnd = dayjs(periodEnd).format("D. MMMM YYYY");
          notification.warning({
            message: t(
              "reports.waste.create.notification-no-datapoints-found",
              { formatPeriodStart, formatPeriodEnd },
            ),
            duration: 10,
          });
          setOpen(false);
          return;
        }
        const result = await reports(data);
        addFile(result);

        notification.success({
          message: t("reports.waste.create.notification-ordered-report"),
        });
      } catch (error) {
        if (typeof error === "object" && error !== null && "message" in error) {
          console.warn("error", error);
          const errorMessage = String(error.message);
          notification.error({
            message: t("reports.waste.create.failed-to-generate-report", {
              errorMessage,
            }),
          });
        }
      }
      form.resetFields();
      setOpen(false);
      navigate(`../reports`);
    }
  }

  const last3MonthPreset = useLastXMonthPreset(3);
  const last6MonthPreset = useLastXMonthPreset(6);
  const last12MonthPreset = useLastXMonthPreset(12);
  const last3QuarterPresets = useLastXQuarterPresets(3);
  const lastHalfYearPresets = useLastHalfYearPresets();

  const presets = useMemo(
    () => [
      last3MonthPreset,
      last6MonthPreset,
      last12MonthPreset,
      ...last3QuarterPresets,
      ...lastHalfYearPresets,
    ],
    [
      last3MonthPreset,
      last6MonthPreset,
      last12MonthPreset,
      last3QuarterPresets,
      lastHalfYearPresets,
    ],
  );

  return (
    <>
      <Row
        justify="center"
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        data-testid="create-waste-report"
      >
        <Tooltip
          title={
            wasteTimeseriesList.length === 0
              ? t("reports.waste.create.no-timeseries-found")
              : t("reports.waste.create.title")
          }
        >
          <Button
            size="large"
            style={{
              width: "calc(100% - 40px)",
              height: 116,
              margin: "0 10px",
            }}
            onClick={showModal}
            disabled={wasteTimeseriesList.length === 0}
          >
            <div style={{ textAlign: "center" }}>
              <WasteSvg />
              <div>
                <span>{t("reports.waste.create.title")}</span>{" "}
              </div>
            </div>
          </Button>
        </Tooltip>
      </Row>
      <Modal
        title={t("reports.waste.create.title")}
        open={open}
        onOk={generateReport}
        onCancel={handleCancel}
      >
        <Form form={form} requiredMark={false} layout="horizontal">
          <p>{t("reports.waste.create.brief-description")}</p>
          <p>{t("reports.waste.create.detailed-description")}</p>
          <Form.Item
            label={t("reports.waste.create.period")}
            name="period"
            rules={[
              {
                required: true,
                message: t("reports.waste.create.select-period-rules-label"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("period") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Velg periode!"));
                },
              }),
            ]}
            initialValue={
              startDate && endDate
                ? [dayjs(Number(startDate)), dayjs(Number(endDate))]
                : undefined
            }
          >
            <DatePicker.RangePicker
              disabledDate={disabledFutureDates}
              onChange={handleChange}
              format="D. MMMM YYYY"
              presets={presets}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
