import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { ErrorMessage } from "@/components/ErrorMessage/ErrorMessage";
import { useActionOrLoaderData } from "@/hooks/useActionOrLoaderData";

function isEntityWithOwner(entity: unknown): entity is { owner: string } {
  return (
    typeof entity === "object" &&
    entity !== null &&
    "owner" in entity &&
    typeof entity.owner === "string"
  );
}

interface Props {
  children: ReactElement;
}

export function RedirectToImportIfNeeded({ children }: Props) {
  const t = useTranslations();

  const entity = useActionOrLoaderData();
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEntityWithOwner(entity) && entity.owner !== user.email) {
      navigate("import");
    }
  }, [entity, user.email, navigate]);

  if (isEntityWithOwner(entity)) {
    return entity.owner === user.email ? children : <Spin />;
  }

  return <ErrorMessage>{t("pages.importError")}</ErrorMessage>;
}
