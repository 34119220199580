import { AlarmRuleTypeName } from "@properate/common";
import { AlarmDetailsGridLayout } from "../components/layout";
import { alarmRuleTypes } from "../AlarmRuleTypes";
import { useFormValue } from "../FormContext/hooks";
import { AlarmSettingsFormFields } from "../AlarmSettingsPage/types";
import { tKey } from "../AlarmRuleTypes/common/utils";
import { TextInfoBox } from "../components/InfoBox";
import { AlarmDetailsFooter } from "../components/AlarmDetailsFooter";
import { ActiveTimes } from "./ActiveTimes";
import { InactiveTimes } from "./InactiveTimes";
import { Holidays } from "./Holidays";
import { Level } from "./Level";
import { Recipient } from "./Recipient";

function ScheduleFields() {
  return (
    <>
      <ActiveTimes />
      <InactiveTimes />
      <Holidays />
    </>
  );
}

function ScheduleOrLockedMessage() {
  const [type] = useFormValue<AlarmRuleTypeName>(AlarmSettingsFormFields.Type);

  const typeHasLockedSchedule = alarmRuleTypes[type]?.lockedSchedule;

  if (typeHasLockedSchedule) {
    return <TextInfoBox message={tKey("third-party-alarm.schedule-info")} />;
  }

  return <ScheduleFields />;
}

export function AlarmNotificationsPage() {
  return (
    <AlarmDetailsGridLayout
      firstColumn={
        <div className="w-full h-full">
          <ScheduleOrLockedMessage />
        </div>
      }
      secondColumn={
        <div className="w-full h-full">
          <Level />
          <Recipient />
        </div>
      }
      footer={<AlarmDetailsFooter />}
    />
  );
}

export { getAlarmNotificationsFormFields } from "./getAlarmNotificationsFormFields";
