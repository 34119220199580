import React, { createElement, MouseEvent, useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";
import { useTooltipInPortal } from "@visx/tooltip";
import Color from "color";
import { scaleLinear, scaleOrdinal } from "@visx/scale";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { Delaunay } from "d3-delaunay";
import { localPoint } from "@visx/event";
import dayjs from "@properate/dayjs";
import { SidebarActionType, useSidebarActions } from "@properate/ui";
import { MessageKey, useTranslations } from "@properate/translations";
import {
  EnergyNoteInfo,
  DESCRIPTION_LEGEND_VALUES,
  TooltipContent,
  TooltipData,
} from "@/features/energy";
import { TrendLinePoint } from "@/utils/types";
import { Granularity } from "@/utils/helpers";
import { NotesSidebarViewState } from "@/features/notes";
import { ETChartHighlightedCircle } from "./ETChartHighlightedCircle";
import { ETChartContents } from "./ETChartContents";

const margin = { top: 5, right: 20, bottom: 30, left: 30 };

export const ETChart = ({
  width,
  height,
  max,
  min,
  usableFloorArea,
  graphPoints,
  maxTemperature,
  minTemperature,
  showEnergyPerArea,
  onShowTooltip,
  onHideTooltip,
  tooltipOpen,
  tooltipData,
  trendLine,
  latestDatapointDate,
  granularity,
  assetListIds = [],
  noteInfo: {
    notesDataPeriods = [],
    isNoteModeOn = false,
    setSelectedPoints,
    selectedPoints,
  } = {},
}: {
  width: number;
  height: number;
  max: number;
  min: number;
  usableFloorArea: number;
  graphPoints: Record<string, number | string | undefined>[];
  maxTemperature: number;
  minTemperature: number;
  showEnergyPerArea: boolean;
  onShowTooltip: ({ tooltipData }: { tooltipData: TooltipData }) => void;
  onHideTooltip: () => void;
  tooltipOpen: boolean;
  tooltipData?: TooltipData;
  trendLine: TrendLinePoint[];
  latestDatapointDate: Date;
  granularity: Granularity;
  noteInfo?: EnergyNoteInfo;
  assetListIds?: number[];
}) => {
  const t = useTranslations();

  const themeContext = useContext(ThemeContext);
  const { containerRef, TooltipInPortal } = useTooltipInPortal();
  const legendHeight = 22;
  const innerHeight = height - margin.top - margin.bottom - legendHeight;
  const innerWidth = width - margin.left - margin.right;

  const sidebarDispatch = useSidebarActions();

  const OPERATIONAL_COLOR = Color(themeContext.primary).alpha(0.8).toString();
  const NON_OPERATIONAL_COLOR = Color(themeContext.accent1)
    .alpha(0.8)
    .toString();
  const HOLIDAY_COLOR = themeContext.neutral4;
  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        range: [OPERATIONAL_COLOR, NON_OPERATIONAL_COLOR, HOLIDAY_COLOR],
        domain: [
          DESCRIPTION_LEGEND_VALUES.operational,
          DESCRIPTION_LEGEND_VALUES.nonOperational,
          DESCRIPTION_LEGEND_VALUES.holidays,
        ],
      }),
    [OPERATIONAL_COLOR, NON_OPERATIONAL_COLOR, HOLIDAY_COLOR],
  );

  const onlyValidData = useMemo(
    () =>
      graphPoints
        .filter(
          (points) =>
            points.total !== undefined &&
            points.temperature !== undefined &&
            points.timestamp &&
            latestDatapointDate &&
            (points.timestamp as number) <= latestDatapointDate.valueOf(),
        )
        .map((point) => {
          const notes = notesDataPeriods.filter((noteRange) => {
            return (
              noteRange.start <= Number(point.timestamp) &&
              dayjs(noteRange.end).subtract(1, granularity).valueOf() >=
                Number(point.timestamp)
            );
          });
          return {
            ...point,
            ...(notes &&
              notes?.length > 0 && {
                note: {
                  title: notes?.[0]?.noteTitle,
                  count: notes?.length,
                },
              }),
          } as Record<
            string,
            number | string | undefined | boolean | Pick<TooltipData, "note">
          >;
        }),
    [graphPoints, latestDatapointDate, notesDataPeriods, granularity],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [innerHeight, 0],
        domain: [
          showEnergyPerArea ? min / usableFloorArea : min,
          showEnergyPerArea ? max / usableFloorArea : max,
        ],
        nice: true,
        zero: true,
      }),
    [min, max, innerHeight, showEnergyPerArea, usableFloorArea],
  );
  const xScale = useMemo(
    () =>
      scaleLinear({
        range: [0, innerWidth],
        domain: [minTemperature, maxTemperature],
        nice: true,
      }),
    [innerWidth, minTemperature, maxTemperature],
  );

  const delaunayLayout = useMemo(() => {
    return Delaunay.from(
      onlyValidData,
      (point) => xScale(point.temperature as number),
      (point) =>
        yScale(
          showEnergyPerArea
            ? (point.total as number) / usableFloorArea
            : (point.total as number),
        ),
    );
  }, [xScale, yScale, usableFloorArea, showEnergyPerArea, onlyValidData]);

  function handleMouseMove(event: MouseEvent) {
    const point = localPoint(event);

    if (point) {
      const closestPointIndex = delaunayLayout.find(
        point.x - margin.left,
        point.y - margin.top,
      );
      const closestPoint = onlyValidData[closestPointIndex];

      if (!closestPoint) {
        return;
      }

      onShowTooltip({
        tooltipData: {
          timestamp: closestPoint.timestamp as number,
          energy: closestPoint.total as number,
          temperature: closestPoint.temperature as number,
          operational: closestPoint.operational as string,
          note: closestPoint.note as TooltipData["note"] | undefined,
        },
      });
    }
  }

  const onPointClick = (event: MouseEvent) => {
    if (!isNoteModeOn || !assetListIds) return;

    const point = localPoint(event);

    if (point) {
      const closestPointIndex = delaunayLayout.find(
        point.x - margin.left,
        point.y - margin.top,
      );
      const closestPoint = onlyValidData[closestPointIndex];

      if (!closestPoint) {
        return;
      }

      const tooltipData = {
        timestamp: closestPoint.timestamp as number,
        energy: closestPoint.total as number,
        temperature: closestPoint.temperature as number,
        operational: closestPoint.operational as string,
        hasNote: closestPoint.hasNote as boolean,
      };

      if (setSelectedPoints) {
        setSelectedPoints([tooltipData]);
      }

      const startDate = new Date(closestPoint.timestamp as number);

      const data = {
        viewState: NotesSidebarViewState.create,
        note: {
          assetIds: assetListIds,
          startTime: closestPoint.timestamp,
          endTime: dayjs(startDate).add(1, granularity).valueOf(),
        },
        customContent: undefined,
      };

      sidebarDispatch({
        type: SidebarActionType.mergeData,
        data,
      });
      sidebarDispatch({
        type: SidebarActionType.open,
      });
    }
  };

  function handleMouseLeave() {
    onHideTooltip();
  }

  const shapeScale = useMemo(
    () =>
      scaleOrdinal<string, string | React.FC>({
        domain: [
          DESCRIPTION_LEGEND_VALUES.operational,
          DESCRIPTION_LEGEND_VALUES.nonOperational,
          DESCRIPTION_LEGEND_VALUES.holidays,
          DESCRIPTION_LEGEND_VALUES.trendLine,
          DESCRIPTION_LEGEND_VALUES.hasNote,
        ],
        range: [
          colorScale(DESCRIPTION_LEGEND_VALUES.operational),
          colorScale(DESCRIPTION_LEGEND_VALUES.nonOperational),
          colorScale(DESCRIPTION_LEGEND_VALUES.holidays),
          themeContext.accent6,
          () => (
            <svg width={15} height={15} style={{ margin: "5px 0" }}>
              <rect
                style={{
                  fill: "transparent",
                  strokeWidth: 4,
                  stroke: themeContext.accent12,
                }}
                width={15}
                height={15}
              />
            </svg>
          ),
        ],
      }),
    [colorScale, themeContext.accent12, themeContext.accent6],
  );

  return (
    <div ref={containerRef}>
      <svg width={width} height={height - legendHeight}>
        <ETChartContents
          innerHeight={innerHeight}
          usableFloorArea={usableFloorArea}
          data={onlyValidData}
          showEnergyPerArea={showEnergyPerArea}
          trendLine={trendLine}
          xScale={xScale}
          yScale={yScale}
          colorScale={colorScale}
        />
        {/*Hovered circle*/}
        {tooltipData && (
          <ETChartHighlightedCircle
            xScale={xScale}
            yScale={yScale}
            colorScale={colorScale}
            usableFloorArea={usableFloorArea}
            showEnergyPerArea={showEnergyPerArea}
            latestDatapointDate={latestDatapointDate}
            margin={margin}
            tooltipData={tooltipData}
          />
        )}
        {/*Selected circles in prefilling notes mode*/}
        {selectedPoints?.[0] && (
          <ETChartHighlightedCircle
            xScale={xScale}
            yScale={yScale}
            colorScale={colorScale}
            usableFloorArea={usableFloorArea}
            showEnergyPerArea={showEnergyPerArea}
            latestDatapointDate={latestDatapointDate}
            margin={margin}
            tooltipData={selectedPoints?.[0] as TooltipData}
            radius={8}
          />
        )}
        {selectedPoints?.[1] && (
          <ETChartHighlightedCircle
            xScale={xScale}
            yScale={yScale}
            colorScale={colorScale}
            usableFloorArea={usableFloorArea}
            showEnergyPerArea={showEnergyPerArea}
            latestDatapointDate={latestDatapointDate}
            margin={margin}
            tooltipData={selectedPoints?.[1]}
            radius={8}
          />
        )}
        <rect
          ref={containerRef}
          x={margin.left}
          y={margin.top}
          width={innerWidth}
          height={innerHeight}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onClick={onPointClick}
          fill="transparent"
          cursor="pointer"
        />
      </svg>
      {tooltipOpen &&
      tooltipData &&
      tooltipData?.temperature !== undefined &&
      tooltipData?.energy !== undefined &&
      tooltipData.timestamp &&
      latestDatapointDate &&
      tooltipData.timestamp <= latestDatapointDate.valueOf() ? (
        <TooltipInPortal
          key={Math.random()}
          left={margin.left + xScale(tooltipData.temperature)}
          top={
            margin.top +
            yScale(
              showEnergyPerArea
                ? tooltipData.energy / usableFloorArea
                : tooltipData.energy,
            )
          }
        >
          <TooltipContent
            bySquareMeter={showEnergyPerArea ? usableFloorArea : undefined}
            {...tooltipData}
          />
        </TooltipInPortal>
      ) : null}
      <div
        style={{
          flex: "none",
          height: legendHeight,
          width: width,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <LegendOrdinal
            scale={shapeScale}
            direction="row"
            labelMargin="0 15px 0 0"
          >
            {(labels) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {labels.map((label, i) => {
                  const shape = shapeScale(label.datum);
                  return (
                    <LegendItem key={`legend-${i}`} margin="0 5px">
                      {typeof shape === "string" && (
                        <svg width={15} height={15}>
                          <rect fill={shape} width={15} height={15} />
                        </svg>
                      )}
                      {typeof label.value === "function" &&
                        createElement(shape)}
                      <LegendLabel align="left" margin="0 0 0 4px">
                        {t(`energy.legend-values.${label.text}` as MessageKey)}
                      </LegendLabel>
                    </LegendItem>
                  );
                })}
              </div>
            )}
          </LegendOrdinal>
        </div>
      </div>
    </div>
  );
};
