import { Asset } from "@cognite/sdk";
import { useLocation, useRouteLoaderData } from "react-router-dom";

export function useCurrentBuilding(disabled: true): null;
export function useCurrentBuilding(disabled?: false): Asset;
export function useCurrentBuilding(disabled: boolean): Asset | null;
export function useCurrentBuilding(disabled = false): Asset | null {
  const currentBuilding = useRouteLoaderData("building") as Asset | null;
  const { pathname } = useLocation();
  if (disabled) {
    return null;
  }
  if (!currentBuilding) {
    throw new Error(
      `The route you're on does not contain the current building's ID in its path: ${pathname}`,
    );
  }
  return currentBuilding;
}
