import { Space } from "antd";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { InputWithError } from "@/pages/common/SchemaGraph/InputWithError";
import { AlarmSubtitle } from "./elements";

interface Props {
  value?: number;
  unit: string;
  error?: string;
  onChange: (value: number | undefined) => unknown;
  type: "max" | "min";
}

export const AlarmComparisonValue = ({
  value,
  unit,
  error,
  onChange,
  type,
}: Props) => {
  const t = useTranslations();
  const user = useUser();

  function handleChange(value: string) {
    onChange(value === "" ? undefined : Number(value));
  }

  const description =
    type === "max"
      ? t(
          "common.timeseries-graph-modal.alarm-fields.trigger-when-value-higher-than",
        )
      : t(
          "common.timeseries-graph-modal.alarm-fields.trigger-when-value-lower-than",
        );

  return (
    <>
      <AlarmSubtitle>
        {t("common.timeseries-graph-modal.alarm-fields.setup")}
      </AlarmSubtitle>
      <Space direction="vertical">
        <span>{description}</span>
        <InputWithError
          disabled={user.isViewer}
          type="number"
          defaultValue={value}
          error={error}
          suffix={unit}
          placeholder="30"
          onChange={handleChange}
          dataTestId={"alarm_threshold_value"}
        />
      </Space>
    </>
  );
};
