import { ComponentSearchable } from "@properate/api/src/types";
import { Button, Popconfirm, Popover, Space, Tooltip } from "antd";
import { SorterResult } from "antd/es/table/interface";
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { useWindowSize } from "@/hooks/useWindowSize";
import Highlight from "@/components/Highlight";
import { isUserComponent } from "@/utils/timeseries";
import { ComponentTableNotesColumn } from "@/features/components";

const ESTIMATED_PAGE_HEADER_HEIGHT = 58;
const ESTIMATED_TABLE_HEADER_HEIGHT = 31;

interface Props {
  isLoading: boolean;
  componentList: ComponentSearchable[];
  onChangeSortBy: (sorter: SorterResult<ComponentSearchable>) => unknown;
  onReachedEndOfPage: () => unknown;
  onConfirmDeleteUserAsset: (externalId: string) => unknown;
  onClickDetails: (id: number) => unknown;
}

export function ComponentsTable({
  isLoading,
  componentList,
  onReachedEndOfPage,
  onChangeSortBy,
  onConfirmDeleteUserAsset,
  onClickDetails,
}: Readonly<Props>) {
  const t = useTranslations();

  const { height: windowHeight } = useWindowSize();
  const height =
    windowHeight - ESTIMATED_PAGE_HEADER_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT;

  function handleChangeSort(
    sorter:
      | SorterResult<ComponentSearchable>
      | SorterResult<ComponentSearchable>[],
  ) {
    if (!Array.isArray(sorter)) {
      onChangeSortBy(sorter);
    }
  }

  return (
    <TableInfiniteScroll
      height={height}
      rowKey="id"
      loading={isLoading}
      onReachedEndOfPage={onReachedEndOfPage}
      data-testid="components-table"
      columns={[
        {
          title: t("components.table.building"),
          key: "subBuilding",
          sorter: true,
          width: 100,
          render: (_, { _formatted }) =>
            _formatted?.subBuilding && (
              <Highlight
                dangerouslySetInnerHTML={{
                  __html: _formatted?.subBuilding,
                }}
              />
            ),
        },
        {
          title: t("components.table.system"),
          key: "system",
          width: 80,
          sorter: true,
          defaultSortOrder: "ascend",
          render: (_, { _formatted }) =>
            _formatted?.system && (
              <Highlight
                dangerouslySetInnerHTML={{ __html: _formatted?.system }}
              />
            ),
        },
        {
          title: t("components.table.id"),
          key: "component",
          sorter: true,
          width: 60,
          render: (_, { _formatted }) =>
            _formatted?.component && (
              <Highlight
                dangerouslySetInnerHTML={{ __html: _formatted?.component }}
              />
            ),
        },
        {
          title: t("components.table.description"),
          key: "description",
          sorter: true,
          width: 200,
          render: (_, { _formatted }) => {
            return (
              <Highlight
                dangerouslySetInnerHTML={{
                  __html: _formatted?.description || "",
                }}
              />
            );
          },
        },
        {
          title: t("components.table.type"),
          key: "componentTypeTranslated",
          sorter: true,
          width: 120,
          render: (_, { _formatted }) => {
            if (!_formatted?.componentTypeTranslated) {
              return null;
            }
            return (
              <Highlight
                dangerouslySetInnerHTML={{
                  __html: _formatted?.componentTypeTranslated,
                }}
              />
            );
          },
        },
        {
          title: t("components.table.placement"),
          key: "placement",
          sorter: true,
          width: 120,
          render: (_, { _formatted }) => {
            return (
              <Highlight
                dangerouslySetInnerHTML={{
                  __html: _formatted?.placement ?? "",
                }}
              />
            );
          },
        },
        {
          title: t("components.table.product-code"),
          key: "productCode",
          sorter: true,
          width: 180,
          render: (_, { _formatted }) => {
            if (!_formatted?.productCode) {
              return null;
            }
            return (
              <Highlight
                dangerouslySetInnerHTML={{
                  __html: _formatted?.productCode,
                }}
              />
            );
          },
        },
        {
          title: t("components.table.entrepreneur"),
          key: "contractor",
          sorter: true,
          width: 120,
          render: (_, { _formatted }) => {
            if (!_formatted?.contractor) {
              return null;
            }
            return (
              <Highlight
                dangerouslySetInnerHTML={{
                  __html: _formatted?.contractor,
                }}
              />
            );
          },
        },
        {
          title: t("components.table.supplier"),
          key: "supplier",
          sorter: true,
          width: 120,
          render: (_, { _formatted }) => {
            if (!_formatted?.supplier) {
              return null;
            }
            return (
              <Highlight
                dangerouslySetInnerHTML={{
                  __html: _formatted?.supplier,
                }}
              />
            );
          },
        },
        {
          title: "",
          key: "endOfLife",
          width: 44,
          render: (_, { endOfLifeDate, claimDate }) => {
            if (claimDate) {
              return (
                <Tooltip
                  title={t("components.table.returned", {
                    date: dayjs(Number(claimDate)).format("D/M YYYY"),
                  })}
                >
                  <StopOutlined />
                </Tooltip>
              );
            }
            if (
              endOfLifeDate &&
              (endOfLifeDate as number) <
                Date.now() - 1000 * 60 * 60 * 24 * 30 * 6
            ) {
              return (
                <Tooltip
                  title={t("components.table.end-of-life-date", {
                    date: dayjs(endOfLifeDate).format("D/MM YYYY"),
                  })}
                >
                  <WarningOutlined />
                </Tooltip>
              );
            }
            return null;
          },
        },
        {
          title: "",
          key: "actions",
          width: 44,
          onCell: () => ({
            onClick: (event) => {
              event.stopPropagation();
            },
          }),
          render: (_, { id, externalId, labels }) => {
            if (isUserComponent(labels)) {
              return (
                <Popover
                  content={
                    <Space>
                      <Button
                        onClick={() => onClickDetails(id)}
                        icon={<EditOutlined />}
                        aria-label={t("components.table.see-details")}
                      />
                      <Popconfirm
                        title={t("components.table.are-you-sure-to-delete")}
                        onConfirm={() => onConfirmDeleteUserAsset(externalId)}
                      >
                        <Button
                          danger
                          icon={<DeleteOutlined />}
                          aria-label={t("components.table.remove-component")}
                        />
                      </Popconfirm>
                    </Space>
                  }
                >
                  <Button icon={<MoreOutlined />} />
                </Popover>
              );
            }
            return (
              <Button
                data-testid={`component-details-button/${id}`}
                onClick={() => onClickDetails(id)}
                icon={<EditOutlined />}
                aria-label={t("components.table.see-details")}
              />
            );
          },
        },
        {
          key: "checkbox",
          width: 32,
          render: (_, { id }) => <ComponentTableNotesColumn id={id} />,
        },
      ]}
      dataSource={componentList}
      onRow={(record) => ({
        onClick: () => onClickDetails(record.id),
      })}
      locale={{
        emptyText: t("components.table.empty-text"),
      }}
      onChange={(_, __, sorter) => handleChangeSort(sorter)}
    />
  );
}
