import * as React from "react";
import { Modal, Radio, Space } from "antd";
import { useTranslations } from "@properate/translations";
import {
  ProperateCalendarEvent,
  RecurringProperateCalendarEvent,
} from "@/utils/types";
import { describeEventRecurrence } from "@/pages/properateCalendar/utils";

type Props = {
  event: ProperateCalendarEvent;
  onSave: (
    event: ProperateCalendarEvent | RecurringProperateCalendarEvent,
  ) => Promise<void>;
  hide: () => void;
  open: boolean;
};
export const DeleteRecurringModal = ({ event, onSave, hide, open }: Props) => {
  const t = useTranslations();

  const [recurring, setRecurring] = React.useState<boolean>(
    event.schedule !== "single",
  );

  return (
    <Modal
      title={t("calendar.delete-recurring-event")}
      open={open}
      destroyOnClose
      onOk={async () => {
        if (recurring) {
          const { start, end, ...recurringCalendarEvent } = event;

          await onSave(
            recurringCalendarEvent as RecurringProperateCalendarEvent,
          );
        } else {
          await onSave(event);
        }
        hide();
      }}
      onCancel={() => {
        hide();
      }}
    >
      <Radio.Group
        onChange={(event) => {
          setRecurring(event.target.value);
        }}
        value={recurring}
      >
        <Space direction="vertical">
          <Radio value={false}>
            {t("calendar.event-recurrence.only-this-event")}
          </Radio>
          <Radio value>
            {t(
              `calendar.event-recurrence.${describeEventRecurrence(
                event.schedule,
              )}`,
            )}
          </Radio>
        </Space>
      </Radio.Group>
    </Modal>
  );
};
