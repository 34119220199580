import { NewMeter, MeterType } from "./types";

export function isValid(meter: NewMeter) {
  return (
    meter.building !== null &&
    meter.subBuilding !== null &&
    meter.system !== null
  );
}

export const unitOptionsByMeterType: Record<
  MeterType,
  Record<"accumulated" | "instantaneous", string[]>
> = {
  energy: {
    instantaneous: ["W", "kW", "MW", "GW"],
    accumulated: ["Wh", "kWh", "MWh", "GWh"],
  },
  water: {
    instantaneous: ["m³/s", "l/s", "m³/h", "l/h"],
    accumulated: ["m³", "l"],
  },
};

export function getDefaultUnit(meterType: MeterType, isAccumulated: boolean) {
  if (meterType === "energy") {
    return isAccumulated ? "kWh" : "kW";
  }
  return isAccumulated ? "m³" : "m³/s";
}
