import Color from "color";
import * as React from "react";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft, AxisRight } from "@visx/axis";
import { AreaClosed, LinePath } from "@visx/shape";
import { memo, useCallback, useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";
import { curveMonotoneX, curveStepAfter } from "@visx/curve";
import { ScaleOrdinal } from "d3-scale";
import { PositionScale } from "@visx/shape/lib/types/base";
import { useTranslations, useLocale } from "@properate/translations";
import dayjs from "@properate/dayjs";
import { Granularity, numberFormatForAxis } from "@/utils/helpers";
import { DESCRIPTION_LEGEND_VALUES, getTickFormat } from "@/features/energy";
import { ScaleLinearNumeric } from "@/utils/types";
import { OperationalPeriods } from "../../utils";
import { EnergyBars } from "./EnergyBars";
import { MARGIN } from "./utils";

type Props = {
  innerWidth: number;
  innerHeight: number;
  nonOperationalPeriods: OperationalPeriods[];
  notesDataPeriods: OperationalPeriods[];
  holidayPeriods: OperationalPeriods[];
  tickValues?: number[];
  graphPoints: Record<string, number | string>[];
  showNonOperational: boolean;
  showHolidays: boolean;
  showTemperature: boolean;
  showNotes: boolean;
  showEPred: boolean;
  colorScale: ScaleOrdinal<string, string>;
  operationalScale: ScaleOrdinal<string, string>;
  legend: string[];
  highlightedLegend: string | null;
  xScale: PositionScale;
  xMetricsScale: ScaleLinearNumeric;
  yScale: PositionScale;
  granularity?: Granularity;
};
export const EnergyGraphContent = memo(function EnergyGraphContent({
  innerWidth,
  innerHeight,
  nonOperationalPeriods,
  notesDataPeriods,
  holidayPeriods,
  tickValues,
  graphPoints,
  showNonOperational,
  showHolidays,
  showTemperature,
  showNotes,
  showEPred,
  colorScale,
  operationalScale,
  legend,
  highlightedLegend,
  xScale,
  xMetricsScale,
  yScale,
  granularity,
}: Props) {
  const t = useTranslations();
  const locale = useLocale();
  const themeContext = useContext(ThemeContext);

  const temperatureScale = useMemo(() => {
    return scaleLinear<number>({
      range: [innerHeight, 0],
      domain: [
        Math.max(
          0,
          ...graphPoints.map((point) => (point.temperature || 0) as number),
        ),
        Math.min(
          0,
          ...graphPoints.map((point) => (point.temperature || 0) as number),
        ),
      ] as [number, number],
      nice: true,
    });
  }, [graphPoints, innerHeight]);

  const bars = useMemo(
    () => (
      <EnergyBars
        graphPoints={graphPoints}
        legend={legend}
        yScale={yScale}
        xScale={xScale}
        colorScale={colorScale}
        highlightedLegend={highlightedLegend}
      />
    ),
    [graphPoints, legend, yScale, xScale, colorScale, highlightedLegend],
  );

  const metricGraphPoints = useMemo(
    () => [
      ...graphPoints,
      {
        ...graphPoints[graphPoints.length - 1],
        timestamp: dayjs(graphPoints[graphPoints.length - 1]?.timestamp)
          .add(1, granularity)
          .valueOf(),
      },
    ],
    [granularity, graphPoints],
  );

  const temperatureData = useMemo(
    () =>
      metricGraphPoints.filter(
        (d) =>
          d.temperature !== undefined &&
          typeof temperatureScale(d.temperature as number) === "number" &&
          typeof xMetricsScale(d.timestamp as number) === "number" &&
          !Number.isNaN(temperatureScale(d.temperature as number)),
      ),
    [metricGraphPoints, temperatureScale, xMetricsScale],
  );

  const ePred = useMemo(() => {
    const points = graphPoints.filter(
      (point) => point.ePred !== undefined && point.timestamp !== undefined,
    );

    if (points.length === 0) return <Group />;

    return (
      <Group>
        <AreaClosed
          data={points}
          fill={Color(operationalScale("ePred")).alpha(0.5).toString()}
          x={(d) => xScale(d.timestamp as number)!}
          y0={(d) => yScale(d.ePredMax as number)!}
          y1={(d) => yScale(d.ePredMin as number)!}
          yScale={yScale}
          curve={curveMonotoneX}
        />
        <LinePath
          stroke={operationalScale("ePred")}
          strokeWidth={1}
          data={points}
          x={(d) => xScale(d.timestamp as number)!}
          y={(d) => yScale(d.ePred as number)!}
          curve={curveMonotoneX}
        />
      </Group>
    );
  }, [graphPoints, yScale, xScale, operationalScale]);

  const getFormat = useCallback(
    (date: any) => {
      return getTickFormat(date, locale, granularity);
    },
    [locale, granularity],
  );

  return (
    <Group left={MARGIN.left} top={MARGIN.top}>
      <line
        x1={innerWidth}
        x2={innerWidth}
        y1={0}
        y2={innerHeight}
        stroke={themeContext.neutral5}
      />
      {showNotes &&
        notesDataPeriods.map((p, i) => {
          return (
            <rect
              key={"notesDataPeriods_" + i}
              x={xMetricsScale(p.start)}
              width={
                xMetricsScale(p.end) && xMetricsScale(p.start)
                  ? xMetricsScale(p.end)! - xMetricsScale(p.start)!
                  : 0
              }
              y={innerHeight}
              height={9}
              fill={operationalScale(DESCRIPTION_LEGEND_VALUES.hasNote)}
            />
          );
        })}
      {showNonOperational &&
        nonOperationalPeriods.map((p) => (
          <rect
            key={"nonOperational_" + p.start}
            x={xMetricsScale(p.start)}
            width={
              xMetricsScale(p.end) && xMetricsScale(p.start)
                ? xMetricsScale(p.end)! - xMetricsScale(p.start)!
                : 0
            }
            y={innerHeight}
            height={9}
            fill={operationalScale(DESCRIPTION_LEGEND_VALUES.nonOperational)}
          />
        ))}
      {showHolidays &&
        holidayPeriods.map((p) => (
          <rect
            key={"holiday_" + p.start}
            x={xMetricsScale(p.start)}
            width={
              xMetricsScale(p.end) && xMetricsScale(p.start)
                ? xMetricsScale(p.end)! - xMetricsScale(p.start)!
                : 0
            }
            y={innerHeight}
            height={9}
            fill={operationalScale(DESCRIPTION_LEGEND_VALUES.holidays)}
          />
        ))}
      <AxisBottom
        top={innerHeight}
        scale={xScale}
        stroke={themeContext.neutral5}
        tickStroke={themeContext.neutral5}
        tickFormat={getFormat}
        labelClassName="time"
        tickLabelProps={{
          fill: themeContext.neutral5,
        }}
        tickValues={tickValues}
      />
      <AxisLeft
        scale={yScale}
        stroke={themeContext.neutral5}
        tickStroke={themeContext.neutral5}
        tickFormat={(value: any) => numberFormatForAxis.format(value)}
        tickLabelProps={{
          fill: themeContext.neutral5,
        }}
      />
      {showTemperature && (
        <AxisRight
          left={innerWidth}
          scale={temperatureScale}
          tickFormat={(value: any) => numberFormatForAxis.format(value)}
          stroke={themeContext.neutral5}
          tickStroke={themeContext.neutral5}
          tickLabelProps={{
            fill: themeContext.neutral5,
          }}
        />
      )}
      {showTemperature && (
        <text
          x={-70}
          y={innerWidth - 10}
          transform="rotate(-90)"
          fontSize={10}
          fill={themeContext.neutral5}
        >
          {t("energy.temperature", {
            unit: "°C",
          })}
        </text>
      )}
      <text
        x={-55}
        y={15}
        transform="rotate(-90)"
        fontSize={10}
        fill={themeContext.neutral5}
      >
        {t("energy.energy", {
          unit: "kWh",
        })}
      </text>
      {bars}
      {showTemperature && (
        <LinePath
          stroke={operationalScale(DESCRIPTION_LEGEND_VALUES.temperature)}
          strokeWidth={1}
          data={temperatureData}
          x={(d) => xMetricsScale(d.timestamp as number)!}
          y={(d) => temperatureScale(d.temperature as number)}
          curve={curveStepAfter}
        />
      )}
      {showEPred && ePred}
    </Group>
  );
});
