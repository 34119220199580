import { FunctionComponent } from "react";
import { Divider } from "antd";
import AutoSizer from "react-virtualized-auto-sizer";
import { SimulationContent } from "@/pages/calculationFlow/components/SimulationContent";
import { CalculationFlow } from "../types";

interface Props {
  calculationFlow: CalculationFlow;
  onClose: React.MouseEventHandler<HTMLElement>;
}

const SimulationCard: FunctionComponent<Props> = ({
  calculationFlow,
  onClose,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <Divider style={{ margin: "0px" }} />
      <AutoSizer>
        {({ width, height }) => (
          <SimulationContent
            width={width}
            height={height}
            calculationFlow={calculationFlow}
            onClose={onClose}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default SimulationCard;
