import styled from "styled-components";

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
