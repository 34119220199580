import { Button } from "antd";
import { useSetSidebarData } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { NotesSidebarValues, NotesSidebarViewState } from "../types";
import { FormFieldWithFormProps } from "./types";

export default function ActionButtons({
  form,
  disabled,
}: FormFieldWithFormProps) {
  const { setSidebarData } = useSetSidebarData<NotesSidebarValues>();
  const t = useTranslations();

  function handleAbort() {
    form.resetFields();
    setSidebarData({
      viewState: NotesSidebarViewState.list,
    });
  }

  function handleSubmit(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    form.submit();
  }

  return (
    <div className={"ml-4 flex gap-2"}>
      <Button type="default" danger htmlType="button" onClick={handleAbort}>
        {t("notes.form.action-buttons.cancel")}
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        ghost
        disabled={disabled}
        onClick={handleSubmit}
      >
        {t("notes.form.action-buttons.save")}
      </Button>
    </div>
  );
}
