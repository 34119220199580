import styled from "styled-components";

export const Highlight = styled.span`
  em {
    background-color: ${(props) => props.theme.highlightBg};
    font-style: normal;
  }
`;

export const DisplayBlock = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;
