import styled from "styled-components";

export const Container = styled.div`
  height: 300px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const CellWithAction = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
