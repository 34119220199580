import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { Breadcrumb, type BreadcrumbItemProps, Layout } from "antd";
import placeholder from "@/layout/BuildingInfoPlaceholder.png";
import type { BasicProps } from "antd/lib/layout/layout";

export const StyledLink = styled(Link)`
  color: #182334;
  display: inline-block;
`;

export const StyledLayout = styled(Layout as React.FC<BasicProps>)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`;

export const LogoContainer = styled.div`
  margin: 0 24px;

  & svg {
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

    .bg {
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .fg {
      opacity: 1;
      transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .bg {
      fill: ${(props) => props.theme.purple_100};
    }

    .fg {
      fill: ${(props) => props.theme.primary};
    }
  }

  .dark & svg {
    .bg {
      fill: ${(props) => props.theme.white};
    }

    .fg {
      fill: ${(props) => props.theme.primary};
    }
  }
`;

export const HeaderRight = styled.div`
  float: right;
  margin-right: 24px;
`;

export const StyledHeader = styled.div`
  padding: 0;
  display: flex;
  height: 64px;
  align-items: center;
`;

export const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ActiveBreadcrumb = styled(
  Breadcrumb.Item as React.FC<BreadcrumbItemProps>,
)`
  font-weight: bold;
`;

export const BuildingsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
`;
export const BuildingList = styled.div`
  overflow-y: auto;
  height: calc(100vh - 215px);
`;

export const BuildingInfoContainer = styled(Link)`
  color: inherit;
  overflow-x: hidden;

  &:hover,
  &:focus {
    background: lightgray;
    color: inherit;
  }
`;

export const Container = styled.div`
  display: inline-block;
  margin: 0 24px;
  white-space: nowrap;
  vertical-align: top;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  height: 68px;
  position: relative;

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    margin: 0;
  }

  :hover #building-thumbnail {
    transform: scale(1.1);
  }
`;

const BlinkAndSwrink = keyframes`
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    width: 68px;
    height: 68px;
  }

  51% {
    width: 42px;
    height: 42px;
  }

  to {
    opacity: 1;
    width: 42px;
    height: 42px;
  }
`;

const BlinkAndExpand = keyframes`
  from {
    opacity: 1;
    width: 42px;
    height: 42px;
  }

  50% {
    opacity: 0;
    width: 42px;
    height: 42px;
  }

  51% {
    width: 68px;
    height: 68px;
  }

  to {
    opacity: 1;
    width: 68px;
    height: 68px;
  }
`;

export const Thumbnail = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-image: url(${placeholder});
  display: inline-block;
  position: relative;
  vertical-align: top;
  transition: transform 0.3s ease;
  animation-play-state: paused;
  animation: ${BlinkAndExpand} 0.3s ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  [data-collapsed="true"] & {
    animation: ${BlinkAndSwrink} 0.3s ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`;

export const WarningContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  color: ${(props) => props.theme.error};
`;

export const BuildingInfoText = styled.div`
  margin-left: 12px;
  vertical-align: middle;
  display: inline-block;
  opacity: 1;
  white-space: normal;
  transition: opacity 0.15s ease;
  position: absolute;
  width: 170px;
  left: 68px;
  color: ${(props) => props.theme.neutral1};

  [data-collapsed="true"] & {
    opacity: 0;
  }
`;

export const BuildingOwnersNameAndAlias = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  margin: 0;
  color: ${(props) => props.theme.neutral1};
`;
