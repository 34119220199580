import { SchemaType } from "@properate/common";
import { useCallback, useMemo } from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { SchemaKpis } from "@/pages/common/SchemaKpis/SchemaKpis";
import { FloorPlan } from "@/pages/floorPlan/v2/types";
import { useFloorPlan } from "./FloorPlanContext";
import { convertPinToTimeseriesInfo } from "./hooks/useFloorPlanSensors";

export function FloorPlanHeaderKPIs() {
  const floorPlan = useFloorPlan();

  const sensors = floorPlan.pins.reduce(
    (sensors, pin) => {
      if (pin.type === "room") {
        if (pin.alarms) {
          for (const [timeseriesId, alarm] of Object.entries(pin.alarms)) {
            sensors[timeseriesId] = {
              timeseriesInfo: [
                convertPinToTimeseriesInfo(
                  parseInt(timeseriesId, 10),
                  pin,
                  alarm,
                ),
              ],
            };
          }
        }
      } else {
        sensors[pin.timeseriesId] = {
          timeseriesInfo: [
            convertPinToTimeseriesInfo(pin.timeseriesId, pin, pin.alarm),
          ],
        };
      }

      return sensors;
    },
    {} as SchemaType["sensors"],
  );

  const schema: Pick<SchemaType, "selectedSensors" | "sensors"> = useMemo(
    () => ({
      selectedSensors: floorPlan.selectedSensors,
      sensors,
    }),
    [floorPlan.selectedSensors, sensors],
  );

  const update = useCallback(
    async (updatedSchema: Partial<FloorPlan>) => {
      await setDoc(
        doc(collection(browserFirestore, "floor-plans"), floorPlan.snapshotId),
        updatedSchema,
        { merge: true },
      );
      return { ...schema, ...updatedSchema } as FloorPlan;
    },
    [floorPlan.snapshotId, schema],
  );

  return (
    <SchemaKpis
      schema={schema}
      update={update}
      snapshotId={floorPlan.snapshotId}
      type="floor-plans"
    />
  );
}
