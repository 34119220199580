import useSWR from "swr";
import {
  DatapointAggregates,
  DatapointsMultiQuery,
  DoubleDatapoints,
} from "@cognite/sdk";
import dayjs from "@properate/dayjs";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { CDF_CONSTANTS } from "@/utils/cdf";
import { useHandleApiError } from "@/utils/api";

function getLimit({ items, limit, aggregates }: DatapointsMultiQuery) {
  if (typeof limit === "number") {
    return limit;
  }
  return aggregates
    ? Math.floor(
        CDF_CONSTANTS.datapoints.limits.query.aggregates / items.length,
      )
    : Math.floor(CDF_CONSTANTS.datapoints.limits.query.raw / items.length);
}

export interface OptionsGetTimeseriesListWithData {
  keepPreviousData?: boolean;
  disabled?: boolean;
  ignoreUnknownIds?: boolean;
  refresh?: boolean;
  useCurrentTimeZone?: boolean;
}

export function useGetTimeseriesListWithData<
  T extends DoubleDatapoints | DatapointAggregates,
>(query: DatapointsMultiQuery, options?: OptionsGetTimeseriesListWithData) {
  const handleAPIError = useHandleApiError();
  const limit = getLimit(query);
  const { client } = useCogniteClient();

  const swrConfigOptions = {
    keepPreviousData: options?.keepPreviousData ?? false,
    ...(options?.refresh === true && { refreshInterval: 60000 }),
  };

  const {
    data: timeseriesListWithData = [] as T[],
    error,
    isLoading,
  } = useSWR(
    query.items.length > 0 && options?.disabled !== true
      ? {
          query: {
            ...query,
            limit,
            ignoreUnknownIds: options?.ignoreUnknownIds ?? false,
            ...(options?.useCurrentTimeZone && {
              timeZone: dayjs.tz.guess(),
            }),
          },
          type: "datapoints.retrieve",
        }
      : null,
    ({ query }) => client.datapoints.retrieve(query) as Promise<T[]>,
    swrConfigOptions,
  );
  if (error) {
    handleAPIError(error);
  }
  return {
    timeseriesListWithData,
    error,
    isLoading,
  };
}
