"use client";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import useSWRMutation from "swr/mutation";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { notification } from "antd";
import { useTranslations } from "@properate/translations";
import { browserFirestore, getCurrentUser } from "@properate/firebase";
import { removeVideo } from "@properate/firebase/functions";
import { Task } from "../schemas";

export type UploadTaskFileArg = {
  where: {
    taskId: string;
    fileRef: string;
  };
};

export function useRemoveTaskFile() {
  const t = useTranslations();

  return useSWRMutation(
    "uploadTaskFile",
    async (_, extra: { arg: UploadTaskFileArg }) => {
      const user = await getCurrentUser();
      const docRef = doc(browserFirestore, "tasks", extra.arg.where.taskId);
      const storage = getStorage();
      const fileRef = ref(storage, extra.arg.where.fileRef);

      const taskDocSnap = await getDoc(docRef);
      const taskDoc = taskDocSnap.data() as Task | undefined;

      if (taskDoc === undefined) {
        throw new Error("Task not found");
      }

      const file = taskDoc.files.find((f) => f.ref === extra.arg.where.fileRef);

      try {
        await deleteObject(fileRef);
      } catch (error) {
        const isObjectNotFoundError =
          error &&
          typeof error === "object" &&
          "code" in error &&
          error.code === "storage/object-not-found";

        // We don't mind if the file is not found, it means it was already deleted.
        // We can proceed with the deletion of the file from the task object.
        if (!isObjectNotFoundError) {
          throw error;
        }
      }

      if (file?.type?.startsWith("video")) {
        await removeVideo({
          folder: "tasks",
          itemId: extra.arg.where.taskId,
          fileId: file?.id,
          cogniteFileId: file?.cogniteFileId,
        });
      }

      await updateDoc(docRef, {
        files: taskDoc.files.filter((f) => f.ref !== extra.arg.where.fileRef),
        lastModifiedBy: user.email,
      });
    },
    {
      onSuccess() {
        notification.success({
          message: t("task.file.delete.success"),
        });
      },
      onError(err) {
        console.error(err);

        notification.error({
          message: t("task.file.delete.error"),
        });
      },
    },
  );
}
