"use client";

import { useTranslations } from "@properate/translations";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useAccordionActions, useAccordionValues } from "./hooks";
import { AccordionActionType } from "./types";

export function AccordionFilterWarning() {
  const t = useTranslations();
  const { filters } = useAccordionValues();
  const dispatch = useAccordionActions();
  let hasActiveFilters = false;
  for (const [_key, filter] of filters) {
    if (filter.active) {
      hasActiveFilters = true;
      break;
    }
  }
  if (!hasActiveFilters) {
    return null;
  }

  function handleClearFilters() {
    dispatch({
      type: AccordionActionType.disableAllFilters,
    });
  }
  return (
    <div className="pl-4 pr-5 py-2 flex flex-row justify-between bg-info-bg">
      <span className=" text-sm font-sans leading-5">
        {t("accordion.filter-active-message")}
      </span>
      <Button
        size="small"
        onClick={handleClearFilters}
        className="bg-info-bg text-info-fg border-info"
        icon={
          <DeleteOutlined
            onPointerEnterCapture={() => undefined}
            onPointerLeaveCapture={() => undefined}
          />
        }
      >
        {t("accordion.clear-filters-button")}
      </Button>
    </div>
  );
}
