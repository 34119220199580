"use client";

import { useEffect, useRef } from "react";

export function useRunOrTimeout() {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  function runOrTimeout(timeoutMs: number, fn: () => boolean) {
    let attempts = 0;

    const intervalMs = 100;

    timeoutRef.current = setInterval(() => {
      attempts += 1;

      try {
        if (fn()) {
          clearInterval(timeoutRef.current);
        }
      } catch (error) {
        console.error("useRunOrTimeout: Unhandled error", error);

        clearInterval(timeoutRef.current);
      }

      if (attempts > timeoutMs / intervalMs) {
        clearInterval(timeoutRef.current);
      }
    }, intervalMs);
  }

  useEffect(() => {
    return () => clearInterval(timeoutRef.current);
  }, []);

  return runOrTimeout;
}
