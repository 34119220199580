import styled from "styled-components";
import { LineClamp } from "@/components/LineClamp";

export const Badge = styled.div<{ size: number }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const FlexCell = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`;

export const FlexHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CertificationList = styled.ul`
  all: unset;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  height: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CertificationListItem = styled.li<{ isAboutToExpire: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.25rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid
    ${(props) => (props.isAboutToExpire ? props.theme.warning : "transparent")};
`;

export const CertificationListItemTitle = styled(LineClamp)`
  line-height: 1.25rem;
  height: 2.5rem;
  margin-top: 0.5rem;
`;
