import ReactSpeedometer from "react-d3-speedometer";
import { formatUnit } from "@properate/common";
import { useTheme } from "styled-components";
import { formatNonScientific } from "@/utils/format";
import { GaugeZones } from "../../../types";

interface Props {
  zones: GaugeZones;
  min: number;
  max: number;
  value: number;
  unit?: string;
  size: "small" | "medium" | "large";
}

export function GaugeSpeedometer({
  zones,
  min,
  max,
  value,
  unit,
  size,
}: Props) {
  const theme = useTheme();

  const height = size === "large" ? 200 : size === "medium" ? 160 : 120;
  const width = size === "large" ? 300 : size === "medium" ? 240 : 180;
  const labelFontSize =
    size === "large" ? "14" : size === "medium" ? "12" : "11";

  return (
    <ReactSpeedometer
      forceRender
      height={height}
      width={width}
      minValue={min}
      maxValue={max}
      value={value}
      segmentColors={
        zones.middle === undefined
          ? [zones.lower.color, zones.upper.color]
          : [zones.lower.color, zones.middle.color, zones.upper.color]
      }
      customSegmentStops={
        zones.middle === undefined
          ? [min, zones.lower.threshold, max]
          : [min, zones.lower.threshold, zones.middle.threshold, max]
      }
      labelFontSize={labelFontSize}
      needleColor={theme.neutral0}
      needleHeightRatio={0.7}
      textColor={theme.neutral0}
      segmentValueFormatter={(value) => {
        // For some reason, negative values are passed with the below sign (that is not a minus)
        return `${formatNonScientific(Number(value.replace("−", "-")))}`;
      }}
      currentValueText={`${formatNonScientific(value)}${formatUnit(unit)}`}
      valueTextFontWeight="normal"
      needleTransitionDuration={0}
    />
  );
}
