import { useMatches, useParams } from "react-router-dom";
import { useFormValue } from "../FormContext/hooks";
import useAlarmDetailsBaseUrl from "./useAlarmDetailsBaseUrl";

export const NEW_ALARM_URL_FRAGMENT = "new";
export const NEW_ALARM_VIEW_STATE_ENTRY = "new-alarm-viewstate";
export enum NewAlarmViewState {
  Settings = "settings",
  Notifications = "notifications",
  Saving = "saving",
  Done = "done",
}

export enum AlarmDetailsPageId {
  Settings = "alarm-details-settings",
  Notifications = "alarm-details-notifications",
  Deviations = "alarm-details-deviations",
}

export const AlarmDetailsPageUrlFragments: Record<AlarmDetailsPageId, string> =
  {
    [AlarmDetailsPageId.Settings]: "/settings",
    [AlarmDetailsPageId.Notifications]: "/notifications",
    [AlarmDetailsPageId.Deviations]: "",
  };

export const AlarmPageIdForViewState: Record<
  NewAlarmViewState,
  AlarmDetailsPageId
> = {
  [NewAlarmViewState.Settings]: AlarmDetailsPageId.Settings,
  [NewAlarmViewState.Notifications]: AlarmDetailsPageId.Notifications,
  [NewAlarmViewState.Saving]: AlarmDetailsPageId.Notifications,
  [NewAlarmViewState.Done]: AlarmDetailsPageId.Deviations,
};

export function makeAlarmDetailsPageUrl(
  baseUrl: string,
  page: AlarmDetailsPageId,
) {
  return `${baseUrl}${AlarmDetailsPageUrlFragments[page]}`;
}

export function useIsCreateNew() {
  const { alarmId } = useParams();
  return alarmId === NEW_ALARM_URL_FRAGMENT;
}

export function useAlarmPageId() {
  const matches = useMatches();
  const pageIds = [
    AlarmDetailsPageId.Settings,
    AlarmDetailsPageId.Notifications,
    AlarmDetailsPageId.Deviations,
  ];

  const match = matches.find((match) =>
    pageIds.includes(match.id as AlarmDetailsPageId),
  );
  return match ? (match.id as AlarmDetailsPageId) : undefined;
}

export function useCreateNewViewState() {
  const [viewState] = useFormValue<NewAlarmViewState>(
    NEW_ALARM_VIEW_STATE_ENTRY,
  );
  const isCreateNew = useIsCreateNew();

  if (!isCreateNew) {
    return null;
  }
  return viewState;
}

export function useCreateNewRedirectUrl() {
  const viewState = useCreateNewViewState();
  const baseUrl = useAlarmDetailsBaseUrl();
  const alarmPageId = useAlarmPageId();

  const nullOrUndef = (val: unknown) => val === null || val === undefined;

  if (nullOrUndef(viewState) || nullOrUndef(alarmPageId)) {
    return null;
  }

  const pageIdForViewState = AlarmPageIdForViewState[viewState!];
  if (!nullOrUndef(pageIdForViewState) && alarmPageId !== pageIdForViewState) {
    return makeAlarmDetailsPageUrl(baseUrl, pageIdForViewState);
  }
  return null;
}
