import { ScaleLinearNumeric } from "@/utils/types";
import { ScaleWithUnit, ScaleWithTimeseriesId } from "../../types";
import { isScalesWithUnit } from "../../utils";
import { ScatterplotGraphXAxis } from "./ScatterplotGraphXAxis";
import { ScatterplotGraphYAxis } from "./ScatterplotGraphYAxis";

interface Props {
  scaleX: ScaleLinearNumeric;
  colorX: string;
  unitX: string;
  scalesWithMetadataY: ScaleWithTimeseriesId[] | ScaleWithUnit[];
  graphHeight: number;
  tickSize: number;
}

export function ScatterplotGraphAxes({
  scaleX,
  colorX,
  unitX,
  scalesWithMetadataY,
  graphHeight,
  tickSize,
}: Props) {
  return (
    <>
      <ScatterplotGraphXAxis
        scale={scaleX}
        color={colorX}
        unit={unitX}
        graphHeight={graphHeight}
        tickSize={tickSize}
      />
      {isScalesWithUnit(scalesWithMetadataY)
        ? scalesWithMetadataY.map(
            ({ scale, unit, metadata: { color } }, index) => (
              <ScatterplotGraphYAxis
                key={unit}
                scale={scale}
                unit={unit}
                color={color}
                index={index}
                tickSize={tickSize}
              />
            ),
          )
        : scalesWithMetadataY.map(
            ({ scale, timeseriesId, metadata: { color, unit } }, index) => (
              <ScatterplotGraphYAxis
                key={timeseriesId}
                scale={scale}
                unit={unit}
                color={color}
                index={index}
                tickSize={tickSize}
              />
            ),
          )}
    </>
  );
}
