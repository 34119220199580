import { WarningOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import {
  BuildingInfoText,
  BuildingOwnersNameAndAlias,
  Container,
  Thumbnail,
  WarningContainer,
} from "@/layout/elements";
import { cogniteClient } from "@/services/cognite-client";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useBuildingSpec } from "@/services/buildingSpec";
import { useAliasConfig } from "@/services/alias";

export const BuildingInfo = () => {
  const t = useTranslations();
  const building = useCurrentBuilding();
  const currentBuildingId = useCurrentBuildingId();
  const spec = useBuildingSpec(currentBuildingId);
  const user = useUser();
  const aliasConfig = useAliasConfig(user.ownerAlias);
  const [warning, setWarning] = useState<string | null>(null);

  const owner = building?.metadata?.owner;
  const alias =
    (aliasConfig?.buildings && aliasConfig.buildings[currentBuildingId]) || "";
  const address = building?.metadata?.Adresse;
  const zipCode = building?.metadata?.Postnummer;
  const city = building?.metadata?.Poststed;

  useEffect(() => {
    const load = async () => {
      const mainMeterAssets = await cogniteClient.assets
        .list({
          filter: {
            assetSubtreeIds: [{ id: currentBuildingId }],
            labels: { containsAll: [{ externalId: "oe_common_main_meter" }] },
          },
        })
        .autoPagingToArray({ limit: Infinity });

      if (mainMeterAssets.length === 0) {
        setWarning(t("common.sidebar.no-energy-meter-found"));
      } else {
        setWarning(null);
      }
    };

    load();
  }, [currentBuildingId, t]);

  return (
    <Container>
      <Thumbnail
        id="building-thumbnail"
        style={{
          backgroundImage:
            typeof spec?.imageUrl === "string" && spec.imageUrl
              ? `url(${spec.imageUrl})`
              : undefined,
        }}
      >
        {Boolean(warning) && (
          <WarningContainer>
            <Tooltip title={warning}>
              <WarningOutlined />
            </Tooltip>
          </WarningContainer>
        )}
      </Thumbnail>
      <BuildingInfoText>
        <Typography.Text
          ellipsis={{
            tooltip: `${owner} ${alias} ${address} ${zipCode} ${city}`,
          }}
        >
          <BuildingOwnersNameAndAlias>
            {owner} <br /> {alias}
          </BuildingOwnersNameAndAlias>{" "}
          <br />
          {address}
          <br />
          {zipCode}, {city}
        </Typography.Text>
      </BuildingInfoText>
    </Container>
  );
};
