import { useParams } from "react-router-dom";
import { Alarm, AlarmWithId, WithRequired } from "@properate/common";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { ErrorMessage } from "@/components/ErrorMessage/ErrorMessage";
import { AlarmUnsubscribeDetails } from "@/features/alarms";
import { useAlarmWithLoadingErrorState } from "@/services/alarms";
import { isErrorWithMessage } from "@/utils/api";
import { AlarmContainer } from "./elements";

function getErrorMessage(error: any) {
  if (isErrorWithMessage(error)) {
    return error.message;
  }
  return typeof error === "string" ? error : null;
}

function getAlarmErrorMessage(alarm: Alarm | undefined) {
  if (!alarm) {
    return "Vi fant ikke alarmen du ønsker å bli fjernet fra";
  }
  if (alarm.subscriptions === undefined) {
    return "Alarmen har ikke noe abonnementer";
  }
  return null;
}

export function AlarmUnsubscribe() {
  const { alarmId, email, phone } = useParams() as {
    alarmId: string;
    email?: string;
    phone?: string;
  };
  const { alarm, error, isLoading } = useAlarmWithLoadingErrorState(alarmId);

  const alarmErrorMessage = getAlarmErrorMessage(alarm);
  const errorMessage = getErrorMessage(error);
  const errorMessageFinal = alarmErrorMessage || errorMessage;

  return (
    <AlarmContainer>
      <SpinnerWithDelay centerInParent isLoading={isLoading}>
        {errorMessageFinal ? (
          <ErrorMessage>{errorMessageFinal}</ErrorMessage>
        ) : (
          <AlarmUnsubscribeDetails
            alarm={alarm as WithRequired<AlarmWithId, "subscriptions">}
            email={email}
            phone={phone}
          />
        )}
      </SpinnerWithDelay>
    </AlarmContainer>
  );
}
