import useSWR from "swr";
import { IdEither } from "@cognite/sdk";
import { useCogniteClient } from "../context/CogniteClientContext";

export function useGetAssetsFromTimeseriesIds({
  timeseriesIds,
}: Readonly<{ timeseriesIds: number[] }>) {
  const { client } = useCogniteClient();
  const requestIds = timeseriesIds.map((id) => ({ id }));
  const { data: timeseries } = useSWR(requestIds, client.timeseries.retrieve);
  const assetIds: IdEither[] | null = timeseries
    ? timeseries
        .filter(({ assetId }) => !!assetId)
        .map((ts) => ({
          id: ts.assetId!,
        }))
    : null;
  const { data: assets, ...rest } = useSWR(assetIds, client.assets.retrieve);

  return { assets, ...rest };
}
