import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import {
  Axis,
  GlyphSeries,
  Grid,
  LineSeries,
  DataContext,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import dayjs, { Dayjs } from "@properate/dayjs";
import { useContext } from "react";
import { useTranslations } from "@properate/translations";
import { ACCENT2 } from "@/utils/ProperateColors";
import { Datapoint, Timeseries } from "../types";

type InnerTimeseriesPlotProps = {
  data: Timeseries;
  width: number;
  height: number;
  start: Dayjs;
  end: Dayjs;
};

const GapAnnotations = ({
  color,
  annotations,
}: {
  annotations: [Date, Date][];
  color: string;
}) => {
  const { margin, xScale } = useContext(DataContext);
  if (!xScale || !margin) return null;
  return (
    <>
      {annotations.map(([d0, d1], i) => {
        const x0 = xScale(d0)?.valueOf() || 0;
        const x1 = xScale(d1)?.valueOf() || 0;

        return (
          <rect
            key={i}
            x={x0}
            y={margin.top}
            width={Math.abs(x1 - x0)}
            height={10}
            fill={color}
          />
        );
      })}
    </>
  );
};

const VerticalLine = ({ d }: { d: Date }) => {
  const { margin, xScale, height } = useContext(DataContext);
  if (!xScale || !margin || !height) return null;
  const x = xScale(d)?.valueOf();
  if (x === undefined) return null;

  return (
    <line
      x1={x}
      x2={x}
      y1={margin.top}
      y2={height - margin.bottom}
      width={5}
      height={height - margin.top - margin.bottom}
      strokeDasharray={"10,5"}
      stroke={"#434343"}
      strokeWidth={3}
    />
  );
};

const InnerTimeseriesPlot = ({
  data,
  width,
  height,
  start,
  end,
}: InnerTimeseriesPlotProps) => {
  const t = useTranslations();
  const xAccessor = (d: Datapoint | undefined) => d?.timestamp;
  const yAccessor = (d: Datapoint) => d?.value;
  const colorAccessor = () => ACCENT2;
  const dateAccessor = (d: Datapoint) =>
    dayjs(d.timestamp).format("D.MMM.YY HH:mm");

  const gaps = data.datapoints
    .map((datapoint, i) =>
      datapoint.value === null
        ? [datapoint.timestamp, data.datapoints[i + 1]?.timestamp || dayjs()]
        : undefined,
    )
    .filter((gap): gap is [Date, Date] => !!gap);

  return (
    <XYChart
      margin={{ top: 0, right: 50, bottom: 100, left: 50 }}
      width={width}
      height={height}
      xScale={{
        type: "time",
        paddingInner: 0.5,
        domain: [start.toDate(), end.toDate()],
      }}
      yScale={{ type: "linear" }}
    >
      <Grid rows columns />
      <VerticalLine d={dayjs().toDate()} />
      <GapAnnotations annotations={gaps} color="rgba(255,0,0,0.3)" />
      <LineSeries
        dataKey={data.externalId}
        data={data.datapoints}
        xAccessor={xAccessor}
        yAccessor={yAccessor}
        colorAccessor={colorAccessor}
      />
      <GlyphSeries
        dataKey={data.externalId}
        data={data.datapoints}
        xAccessor={xAccessor}
        yAccessor={yAccessor}
        colorAccessor={colorAccessor}
      />
      <Tooltip<Datapoint>
        showVerticalCrosshair
        snapTooltipToDatumX
        snapTooltipToDatumY
        showDatumGlyph
        showSeriesGlyphs
        renderTooltip={({ tooltipData }) => (
          <>
            {(tooltipData?.nearestDatum?.datum &&
              dateAccessor(tooltipData?.nearestDatum?.datum)) ||
              t("calculation-flow.no-date")}
            <br />
            <br />
            {tooltipData?.nearestDatum?.datum &&
            tooltipData?.nearestDatum?.datum.value !== null
              ? `${yAccessor(tooltipData?.nearestDatum?.datum)?.toFixed(2)} ${
                  data.unit
                }`
              : t("calculation-flow.emergency-stop")}
          </>
        )}
      />
      <Axis
        orientation="bottom"
        tickFormat={(value: Date) => dayjs(value).format("D.MMM.YY HH:mm")}
      />
      <Axis orientation="left" />
    </XYChart>
  );
};

type Props = {
  data: Timeseries;
  start: Dayjs;
  end: Dayjs;
  min: number | null;
  max: number | null;
};
export const TimeseriesPlot = ({ data, start, end }: Props) => {
  return (
    <AutoSizer>
      {({ width, height }: Size) => (
        <InnerTimeseriesPlot
          data={data}
          height={height}
          width={width}
          start={start}
          end={end}
        />
      )}
    </AutoSizer>
  );
};
