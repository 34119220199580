import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useState } from "react";
import { BuildingTenantWithId } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { SearchInput } from "@/components/SearchIndex";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useGetBuildingTenants } from "../hooks/useGetBuildingTenants";
import { BuildingTenantModal } from "./BuildingTenantModal";
import { BuildingTenantsTable } from "./BuildingTenantsTable";
import { useTenantsSieve } from "./hooks/useTenantsSieve";
import { BuildingTenantsTableFilters } from "./BuildingTenantsTableFilters";

export function BuildingTenantsAdminPanel() {
  const t = useTranslations();

  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedTenant, setSelectedTenant] =
    useState<BuildingTenantWithId | null>(null);
  const buildingId = useCurrentBuildingId();
  const tenants = useGetBuildingTenants(buildingId);
  const { search, setSearch, filter, setFilter, filteredTenants } =
    useTenantsSieve(tenants.data);

  return (
    <div>
      <PageHeader
        title={t("settings.tenants.title")}
        extra={[
          <BuildingTenantsTableFilters
            key="filter"
            value={filter}
            onChange={setFilter}
          />,
          <SearchInput
            key="search"
            allowClear
            placeholder={t("settings.tenants.search-placeholder")}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />,
          <Button
            key="new"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setSelectedTenant(null);
              setModalVisibility(true);
            }}
          >
            {t("settings.tenants.new-tenant")}
          </Button>,
        ]}
      />
      <BuildingTenantModal
        key={selectedTenant?.id}
        buildingId={buildingId}
        isVisible={isModalVisible}
        onClose={() => {
          setSelectedTenant(null);
          setModalVisibility(false);
        }}
        tenants={tenants.data}
        tenant={selectedTenant}
      />
      <div style={{ height: 400 }}>
        <BuildingTenantsTable
          buildingId={buildingId}
          tenants={filteredTenants}
          scrollY={325}
          search={search}
          onRowClick={(tenant) => {
            setSelectedTenant(tenant);
            setModalVisibility(true);
          }}
        />
      </div>
    </div>
  );
}
