import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridColumns, GridRows } from "@visx/grid";
import styled from "styled-components";
import { EnergyRating } from "@properate/common";
import { scaleBand } from "@visx/scale";
import { TickRendererProps } from "@visx/axis/lib/types";

interface EnergyRatingProps {
  energyRating: string;
  heatingRating: number;
  onSelect: (rating: EnergyRating) => void;
}

const Svg = styled.svg`
  text {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .faded {
    opacity: 0;
    &:hover {
      opacity: 0.9;
    }
  }
  .symbol {
    cursor: pointer !important;
  }
`;
const ENERGY_RATING_HINT = ["G", "F", "E", "D", "C", "B", "A"].reverse();
const ENERGY_RATING_HINT_REVERSED = ["G", "F", "E", "D", "C", "B", "A"];
const ENERGY_HEATING_COLOR = [
  "#d9001f",
  "#ff6f00",
  "#eecc00",
  "#67c421",
  "#00963a",
];
const ALL_POSSIBLE_DATAPOINTS = Array.from(Array(5).keys())
  .map((heating) =>
    Array.from(Array(7).keys()).map((energy) => ({
      energyRating: energy,
      heatingRating: heating,
    })),
  )
  .flat();

const MARGIN = { top: 15, right: 0, bottom: 34, left: 38 };

const GRID_COLOR = "#ccc";

const WIDTH = 300;
const HEIGHT = 300;

const INNER_WIDTH = WIDTH - MARGIN.left - MARGIN.right;

const INNER_HEIGHT = HEIGHT - MARGIN.top - MARGIN.bottom;

const SelectEnergyRating = (props: EnergyRatingProps) => {
  const energyRatingIndex = ENERGY_RATING_HINT.indexOf(
    props.energyRating || "",
  );

  const heatingRating = scaleBand({
    range: [0, INNER_HEIGHT],
    domain: ENERGY_RATING_HINT,
  });

  const colorScale = scaleBand({
    range: [0, INNER_WIDTH],
    domain: ENERGY_HEATING_COLOR,
  });

  return (
    <Svg width={WIDTH} height={HEIGHT}>
      <g transform={`translate(${MARGIN.left},${MARGIN.top})`}>
        <GridRows
          scale={heatingRating}
          stroke={GRID_COLOR}
          width={INNER_WIDTH}
        />
        <GridColumns
          scale={colorScale}
          stroke={GRID_COLOR}
          height={INNER_HEIGHT}
        />
        <AxisLeft
          scale={heatingRating}
          hideAxisLine
          hideTicks
          tickComponent={({ x, y, formattedValue }: TickRendererProps) => {
            const adjust =
              20 - ENERGY_RATING_HINT_REVERSED.indexOf(formattedValue!) * 2;
            return (
              <g transform={`translate(${x},${y})`} className="tick">
                <polygon
                  points={`-28,-10 ${adjust - 23},-10 ${adjust - 14},0 ${
                    adjust - 23
                  },10 -28,10`}
                  fill="#999999"
                />
                <text
                  x={-32 + adjust}
                  y={4}
                  dy={0}
                  fill="#fff"
                  fontSize={13}
                  fontWeight="bold"
                >
                  {formattedValue}
                </text>
              </g>
            );
          }}
        />
        <AxisBottom
          scale={colorScale}
          hideAxisLine
          hideTicks
          top={INNER_HEIGHT}
          tickComponent={({ x, y, formattedValue }: TickRendererProps) => {
            return (
              <rect
                fill={formattedValue}
                x={x - INNER_WIDTH / ENERGY_HEATING_COLOR.length / 2}
                y={y}
                width={INNER_WIDTH / ENERGY_HEATING_COLOR.length}
                height={16}
              />
            );
          }}
        />
        <g>
          {ALL_POSSIBLE_DATAPOINTS.filter(
            (d) =>
              !(
                d.heatingRating === props.heatingRating &&
                d.energyRating === energyRatingIndex
              ),
          ).map((d) => (
            <EnergyRatingSymbol
              key={`${d.energyRating}-${d.heatingRating}`}
              faded
              onSelect={props.onSelect}
              payload={d}
              x={colorScale(ENERGY_HEATING_COLOR[d.heatingRating])!}
              y={heatingRating(ENERGY_RATING_HINT[d.energyRating])!}
            />
          ))}
          {props.energyRating && (
            <EnergyRatingSymbol
              payload={{
                energyRating: ENERGY_RATING_HINT.indexOf(props.energyRating),
                heatingRating: props.heatingRating,
              }}
              x={colorScale(ENERGY_HEATING_COLOR[props.heatingRating])}
              y={heatingRating(props.energyRating)}
            />
          )}
        </g>
      </g>
    </Svg>
  );
};

const EnergyRatingSymbol = ({ x, y, payload, faded, onSelect }: any) => {
  return (
    <g
      transform={`translate(${x - 29 + 50},${y - 25 + 50 - 10})`}
      className={faded ? "faded symbol" : "symbol"}
      onClick={
        onSelect
          ? () =>
              onSelect({
                energyRating: ENERGY_RATING_HINT[payload.energyRating],
                heatingRating: payload.heatingRating,
              })
          : undefined
      }
    >
      <polygon
        points={`-20,-5 5,-25 30,-5 30,25 -20,25`}
        fill={faded ? "#eeeeee" : ENERGY_HEATING_COLOR[payload.heatingRating]}
      />
      <text x={-4} y={13} dy={0} fill="#fff" fontSize={26} fontWeight="bold">
        {ENERGY_RATING_HINT[payload.energyRating]}
      </text>
    </g>
  );
};

export const getEnergyMark = (
  rating: EnergyRating,
  width = 50,
  height = 50,
) => {
  if (!rating.energyRating || typeof rating.heatingRating === "undefined") {
    return null;
  }

  return (
    <svg viewBox="0 0 60 55" width={width} height={height}>
      <EnergyRatingSymbol
        x={5}
        y={15}
        payload={{
          energyRating: ENERGY_RATING_HINT.indexOf(rating.energyRating),
          heatingRating: rating.heatingRating,
        }}
      />
    </svg>
  );
};

export default SelectEnergyRating;
