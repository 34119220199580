import { Form, Input } from "antd";
import { useTranslations } from "@properate/translations";
import { FormFieldProps } from "./types";
const { TextArea } = Input;

export default function ContentField({ disabled }: Readonly<FormFieldProps>) {
  const t = useTranslations();

  return (
    <Form.Item
      name="content"
      label={t("notes.form.content.label")}
      rules={[{ required: true, message: t("notes.form.content.message") }]}
      required={false}
    >
      <TextArea rows={12} disabled={disabled} />
    </Form.Item>
  );
}
