import styled from "styled-components";

interface ItemProps {
  past: boolean;
}

export const GreyOutOld = styled.span<ItemProps>`
  opacity: ${({ past }) => (past ? 0.55 : 1)};
`;

export const Spacer = styled.div`
  display: flex;
  justify-content: space-between;
`;
