import { useEffect, useState } from "react";
import { ConfigProvider, theme as antdTheme } from "antd";
import nbNO from "antd/es/locale/nb_NO";
import enUS from "antd/es/locale/en_US";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import {
  AccordionProvider,
  CustomDndProvider,
  SidebarProvider,
} from "@properate/ui";
import { RouterProvider } from "react-router-dom";
import { AnalyticsContextProvider } from "@properate/analytics";
import { AuthContextProvider } from "@properate/auth";
import { IntlProvider, useUserLanguage } from "@properate/translations";
import enIntlMessages from "@properate/translations/src/messages/en.json";
import noIntlMessages from "@properate/translations/src/messages/no.json";
import dayjs from "@properate/dayjs";
import keycloak from "@/keycloak";
import {
  PRIMARY,
  PROPERATE_THEME_DARK,
  PROPERATE_THEME_LIGHT,
} from "@/utils/ProperateColors";
import { CogniteClientContextProvider } from "@/context/CogniteClientContext";
import { HelpContextProvider } from "@/context/HelpContext";
import { ProperateCogniteClientProvider } from "@/context/ProperateCogniteClientContext";
import { router } from "@/routes/router";
import { BuildingsSearchProvider } from "@/context/BuildingsSearchContext";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import RootContainer from "./RootContainer";

const { locale: localeDayjs } = dayjs;

const GlobalStyle = createGlobalStyle`
  html {
    color-scheme: ${(props) => props.theme.type};
    background-color: ${(props) => props.theme.layoutBackground};
    font-family: "Roboto", sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  h1 {
    font-size: 22px;
    font-weight: 500;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  h3 {
    font-weight: 500;
  }

  .ant-menu-item, .ant-menu-submenu .ant-menu-submenu-title {
    margin: 0 !important;
  }

  .ant-menu-item .ant-menu-title-content, .ant-menu-submenu .ant-menu-title-content  {
    margin-left: 16px !important;
  }

  .ant-menu-item .ant-menu-title-content a {
   position: relative;
  }

  .ant-menu-item .ant-menu-title-content:hover a::after {
    content: "";
    width: 100%;
    height: 2px;
    background: ${(props) => props.theme.neutral1};
    position: absolute;
    top: calc(100% - 8px);
    left: 0;
    margin-top: 6px;
  }

  .ant-menu-item-selected .ant-menu-title-content a::after {
    content: "";
    width: 100%;
    height: 2px;
    background: #12cf74 !important;
    position: absolute;
    top: calc(100% - 8px);
    left: 0;
    margin-top: 6px;
  }

  .ant-table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-picker-input > input {
    font-size: 13px !important;
  }

  .component {
    background-color: ${(props) => props.theme.background2};
    fill: ${(props) => props.theme.background2};
  }

  .label {
    color: ${(props) => props.theme.neutral1};
    fill: ${(props) => props.theme.neutral1};
  }
  .ant-page-header .ant-page-header-heading-title {
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 28px;
  }
  
  .ant-picker-panel-container {
      @media (max-width: 800px) {
          overflow: scroll !important;
          height: 565px;
          .ant-picker-panel-layout {
              flex-direction: column !important;

              .ant-picker-presets {
                  max-width: 100% !important;
                  min-height: 10rem !important;
                  order: 3 !important;
                  
                  ul {
                      height: fit-content !important;
                  }
              }

              .ant-picker-panels,
              .ant-picker-datetime-panel {
                  flex-direction: column !important;
              }
          }
      }
  }
`;

const getAccessToken = () => keycloak.token;
const refreshAccessToken = async () => keycloak.updateToken(30);

export function AppProviders() {
  const [theme, setTheme] = useState(PROPERATE_THEME_LIGHT);

  useEffect(() => {
    if (!keycloak.idTokenParsed) {
      throw Error("keycloak.idTokenParsed is not defined!");
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (window.Ybug) {
      window.Ybug.setUser({
        id: keycloak.idTokenParsed.email,
        email: keycloak.idTokenParsed.email,
        username: keycloak.idTokenParsed.email,
        backend: process.env.REACT_APP_PROJECT_ID,
        firebase: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      });
    }
  }, []);

  useEffect(() => {
    const dark = window.matchMedia("(prefers-color-scheme: dark)");

    const listener = () => {
      if (dark.matches) {
        document.documentElement.className = "dark";
        setTheme(PROPERATE_THEME_DARK);
      } else {
        document.documentElement.className = "light";
        setTheme(PROPERATE_THEME_LIGHT);
      }
    };

    listener();
    dark.addEventListener("change", listener);

    return () => dark.removeEventListener("change", listener);
  }, []);

  const { data: userSettings } = useUserSettings();

  const locale = useUserLanguage({
    setUserLanguage: (language) => {
      return mutateUserSettings({ language });
    },
    userLanguage: userSettings ? userSettings?.language : "no",
  });
  const antdLocale = locale === "en" ? enUS : nbNO;
  const messages = locale === "en" ? enIntlMessages : noIntlMessages;

  useEffect(() => {
    localeDayjs(locale === "en" ? "en" : "nb");
  }, [locale]);

  if (!keycloak.idTokenParsed) {
    return null;
  }

  return (
    <CogniteClientContextProvider>
      <ProperateCogniteClientProvider>
        <AuthContextProvider
          name={keycloak.idTokenParsed.name}
          email={keycloak.idTokenParsed.email}
          getAccessToken={getAccessToken}
          refreshAccessToken={refreshAccessToken}
        >
          <AnalyticsContextProvider>
            <ConfigProvider
              locale={antdLocale}
              theme={{
                token: {
                  colorPrimary: PRIMARY,
                  fontFamily: '"Roboto", sans-serif',
                  fontSize: 13,
                  fontSizeSM: 12,
                  fontSizeLG: 14,
                  colorBgBase: theme.background2,
                  colorBgContainer: theme.background2,
                  colorTextBase: theme.neutral1,
                  colorLink: theme.neutral3,
                  controlHeight: 28,
                  colorBgLayout: theme.layoutBackground,
                },
                components: {
                  Menu: {
                    fontSize: 16,
                    itemSelectedColor: theme.neutral1,
                    itemSelectedBg: "transparent",
                    itemHoverBg: "transparent",
                    colorBgContainer: "transparent",
                    subMenuItemBg: "transparent",
                    iconSize: 28,
                    colorText: theme.neutral4,
                    collapsedIconSize: 28,
                  },
                  Statistic: {
                    colorTextDescription: theme.neutral3,
                    titleFontSize: 14,
                    contentFontSize: 24,
                  },
                  Table: {
                    cellPaddingBlockSM: 4,
                  },
                  Card: {
                    headerHeight: 46,
                  },
                  Button: {
                    onlyIconSizeSM: 12,
                    contentFontSizeSM: 12,
                  },
                },
                algorithm:
                  theme.type === "dark"
                    ? antdTheme.darkAlgorithm
                    : antdTheme.defaultAlgorithm,
              }}
            >
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <BuildingsSearchProvider>
                  <HelpContextProvider>
                    <CustomDndProvider>
                      <AccordionProvider>
                        <SidebarProvider>
                          <RootContainer>
                            <IntlProvider locale={locale} messages={messages}>
                              <RouterProvider router={router} />
                            </IntlProvider>
                          </RootContainer>
                        </SidebarProvider>
                      </AccordionProvider>
                    </CustomDndProvider>
                  </HelpContextProvider>
                </BuildingsSearchProvider>
              </ThemeProvider>
            </ConfigProvider>
          </AnalyticsContextProvider>
        </AuthContextProvider>
      </ProperateCogniteClientProvider>
    </CogniteClientContextProvider>
  );
}
