"use client";

import { useEffect } from "react";
import { AccordionActionType, AccordionOptions } from "./types";
import { useAccordionActions } from "./hooks";

const defaultAccordionOptions = {
  asListItem: true,
};

export default function SynchronizeAccordionOptions(
  inputOptions: AccordionOptions,
) {
  const dispatch = useAccordionActions();

  useEffect(() => {
    dispatch({
      type: AccordionActionType.setOptions,
      options: {
        ...defaultAccordionOptions,
        ...inputOptions,
      },
    });
  }, [// eslint-disable-line react-hooks/exhaustive-deps, prettier/prettier
    inputOptions.allowMultipleOpen,
    inputOptions.hrBetweenEntries,
    inputOptions.openClassName,
    inputOptions.closedClassName,
    inputOptions.className,
  ]);
  return null;
}
