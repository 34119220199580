import { Outlet, useNavigate, useParams } from "react-router-dom";
import { App } from "antd";
import {
  AlarmRule,
  AlarmRuleSource,
  objectMerge,
  PartialAlarmRule,
} from "@properate/common";
import { useTranslations } from "@properate/translations";
import { CenteredSpinner } from "@properate/ui";
import { useState } from "react";
import { useUser } from "@properate/auth";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import SomethingWrong from "@/pages/alarms/details/components/SomethingWrong";
import { createAlarmConfiguration, updateAlarmConfiguration } from "@/eepApi";
import { useAlarmRule } from "./hooks/useAlarmRules";
import { AlarmDetailsNavigatorFormDefaults } from "./components/AlarmTimeseriesGraph/AlarmTimeseriesNavigator";
import { AlarmDetailsTimespanFormDefaults } from "./components/AlarmDetailsTimespanSelector";
import { AlarmTypeSpecificNotesSidebar } from "./components/AlarmDetailsNotesSidebar";
import { AlarmPageHeader } from "./components/AlarmPageHeader";
import {
  AlarmGraphFormDefaults,
  AlarmTimeseriesGraph,
} from "./components/AlarmTimeseriesGraph";
import { FormContextProvider, SubmitValue } from "./FormContext";
import {
  FormContextEntry,
  FormContextProviderEntries,
} from "./FormContext/types";
import { getAlarmSettingsFormFields } from "./AlarmSettingsPage";
import { getAlarmNotificationsFormFields } from "./AlarmNotificationsPage";
import { getAlarmSettingsAlarmRuleFields } from "./AlarmSettingsPage/getAlarmSettingsFormFields";
import { getAlarmNotificationsAlarmRuleFields } from "./AlarmNotificationsPage/getAlarmNotificationsFormFields";
import NewAlarmRedirect from "./components/NewAlarmRedirect";
import {
  NEW_ALARM_URL_FRAGMENT,
  NEW_ALARM_VIEW_STATE_ENTRY,
  NewAlarmViewState,
} from "./hooks/createNewAlarmHooks";

export function AlarmDetailsPage() {
  const { notification } = App.useApp();
  const t = useTranslations();
  const user = useUser();
  const navigate = useNavigate();
  const { id: currentBuildingId } = useCurrentBuilding();
  const { alarmId } = useParams();
  const isNewAlarm = alarmId === NEW_ALARM_URL_FRAGMENT;
  const { alarmRule: existingAlarm, error: alarmRuleError } =
    useAlarmRule(alarmId);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (alarmRuleError) {
    return <SomethingWrong />;
  }

  if ((!isNewAlarm && !existingAlarm) || isSubmitting) {
    return (
      <CenteredSpinner
        message={isSubmitting ? t("ui.saving") : t("ui.loading")}
      />
    );
  }

  function getCreateNewAlarmRuleFields(): PartialAlarmRule {
    return {
      building_id: currentBuildingId,
      source: AlarmRuleSource.User,
    };
  }

  const alarmRule: PartialAlarmRule =
    existingAlarm ?? getCreateNewAlarmRuleFields();

  const entries: FormContextProviderEntries = {
    ...getAlarmSettingsFormFields(alarmRule),
    ...getAlarmNotificationsFormFields(alarmRule),
    ...AlarmDetailsNavigatorFormDefaults,
    ...AlarmDetailsTimespanFormDefaults,
    ...AlarmGraphFormDefaults,
  };

  if (isNewAlarm) {
    entries[NEW_ALARM_VIEW_STATE_ENTRY] = {
      defaultValue: NewAlarmViewState.Settings,
      skipWhenValidatingAll: true,
    } as FormContextEntry<NewAlarmViewState>;
  }

  async function handleFormSubmit(entries: SubmitValue) {
    setIsSubmitting(true);
    let newAlarmRule = objectMerge<AlarmRule>(
      alarmRule,
      getAlarmSettingsAlarmRuleFields({ entries }),
    );
    newAlarmRule = objectMerge<AlarmRule>(
      newAlarmRule,
      getAlarmNotificationsAlarmRuleFields({ entries }),
    );
    if (isNewAlarm) {
      await createAlarmConfiguration(newAlarmRule)
        .then((result) => {
          notification.info({
            message: t(
              "alarm-details.common.notification-messages.created-alarm",
              { alarmName: result.name },
            ),
          });
          navigate(`/asset/${currentBuildingId}/newAlarms`);
        })
        .catch((error: any) => {
          setIsSubmitting(false);
          console.error("Something went wrong: ", error);
          notification.error({
            message: t(
              "alarm-details.common.notification-messages.create-failed",
            ),
          });
        });
    } else {
      await updateAlarmConfiguration(newAlarmRule)
        .then((result) => {
          notification.info({
            message: t(
              "alarm-details.common.notification-messages.updated-alarm",
              { alarmName: result.name },
            ),
          });
          navigate(`/asset/${currentBuildingId}/newAlarms`);
        })
        .catch((error: any) => {
          setIsSubmitting(false);
          console.error("Something went wrong: ", error);
          notification.error({
            message: t(
              "alarm-details.common.notification-messages.update-failed",
            ),
          });
        });
    }
  }

  return (
    <div className="h-full md:h-screen w-full m-0 py-4 box-border">
      <FormContextProvider
        entries={entries}
        onSubmit={handleFormSubmit}
        className="h-full w-full gap-4 box-border pr-4 grid grid-cols-2 content-start @container"
        // TODO: get all form-data represented in query-params in url
        // options={{ useUrlSearchParams: true }}
        options={{
          disabledForm: !user.isAdmin,
        }}
      >
        <AlarmPageHeader />
        <AlarmTimeseriesGraph />
        <Outlet />
        <NewAlarmRedirect />
        <AlarmTypeSpecificNotesSidebar />
      </FormContextProvider>
    </div>
  );
}
