import { ReactNode, useContext, useState } from "react";
import { IoHandRight } from "@react-icons/all-files/io5/IoHandRight";
import { IoHandRightOutline } from "@react-icons/all-files/io5/IoHandRightOutline";
import { ThemeContext } from "styled-components";
import {
  Button,
  Col,
  App,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  InputNumber,
  ModalProps,
} from "antd";
import {
  CloseOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { SensorList } from "@properate/common";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { updateSetPoint } from "@/eepApi";
import { formatMeasurementForSchema } from "@/pages/common/SchemaView/TechnicalSchema/utils";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { AuditLogModal } from "@/components/AuditLog";
import { DEFAULT_MESSAGE_DURATION } from "@/utils/helpers";
import { parseError } from "../utils";
import { useRoomInfo } from "./useRoomInfo";

const ROOM_INFO_URL =
  process.env.REACT_APP_KEYCLOAK_REALM! === "prod"
    ? "https://app.properate.com/room"
    : "https://dev.eepcloud.no/room";

type ValueColProps = {
  value: string;
  alarm?: string;
  alarmType?: "warning" | "error" | "status";
  id: number;
  openGraph: (id: number) => void;
  span?: number;
};

const ValueCol = ({
  value,
  id,
  openGraph,
  span = 12,
  alarm,
  alarmType,
}: ValueColProps) => (
  <Col span={span} style={{ textAlign: "right" }}>
    <Button
      style={{ padding: 0 }}
      type="link"
      onClick={() => {
        openGraph(id);
      }}
    >
      <div className="flex items-center gap-2">
        {alarm && (
          <Tooltip title={alarm}>
            {alarmType === "error" ? (
              <div className="text-red-600">
                <WarningOutlined />
              </div>
            ) : alarmType === "warning" ? (
              <div className="text-yellow-600">
                <WarningOutlined />
              </div>
            ) : (
              <div className="text-blue-600">
                <InfoCircleOutlined />
              </div>
            )}
          </Tooltip>
        )}
        {value}
      </div>
    </Button>
  </Col>
);

type Props = {
  hide: () => void;
  id: number;
  sensors: Record<string, SensorList>;
  openGraph: (id: number) => void;
  noModal?: boolean;
};

export const RoomInfo = ({ hide, id, sensors, openGraph, noModal }: Props) => {
  const t = useTranslations();
  const { message } = App.useApp();
  const { client } = useCogniteClient();
  const user = useUser();
  const themeContext = useContext(ThemeContext);
  const building = useCurrentBuilding();

  const [showAllTimeseries, setShowAllTimeseries] = useState<boolean>();
  const [showWritableTimeseries, setShowWritableTimeseries] =
    useState<boolean>();

  const {
    data,
    importedTimeseriesList,
    setImportedTimeseriesList,
    newRoom,
    showHistoryForImportedTimeseries,
    setShowHistoryForImportedTimeseries,
    handleChangeTimeseries,
  } = useRoomInfo({
    id,
    sensors,
  });

  const showSelectTimeseries = showAllTimeseries || showWritableTimeseries;

  const Wrapper = noModal ? NoModal : Modal;

  return (
    <>
      <Wrapper
        open
        styles={{ body: { background: themeContext.background2 } }}
        onCancel={() => {
          hide();
        }}
        title={`${newRoom?.name}`}
        footer={[
          <Button
            key="import"
            disabled={user.isViewer}
            onClick={() => {
              setShowAllTimeseries(true);
            }}
          >
            {t("room-info.import-measures")}
          </Button>,
          <Button
            key="import"
            disabled={user.isViewer}
            onClick={() => {
              setShowWritableTimeseries(true);
            }}
          >
            {t("room-info.import-setpoint")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              // go through the setpoints and update the ones that have been changed
              importedTimeseriesList.forEach(async (imported) => {
                if (imported.touched) {
                  try {
                    await updateSetPoint({
                      priority: imported.priority,
                      value: imported.value,
                      external_id: imported.externalId,
                      audit_source: "web",
                    });
                  } catch (e) {
                    const errorMessage = parseError(e);
                    message.error(
                      t("room-info.cant-update-setpoint", {
                        error: errorMessage,
                      }),
                    );
                  }
                }
              });
              hide();
            }}
          >
            {t("room-info.ok")}
          </Button>,
        ]}
      >
        <p>
          {newRoom?.subBuilding}{" "}
          {t("room-info.floor", {
            floor: newRoom?.floor,
          })}{" "}
          {user.isAdmin && (
            <Button
              type="link"
              onClick={async () => {
                await navigator.clipboard.writeText(`${ROOM_INFO_URL}/${id}`);
                message.success(t("room-info.link-copied"));
              }}
            >
              <LinkOutlined /> {t("room-info.copy-link")}
            </Button>
          )}
        </p>
        <Row gutter={[12, 12]}>
          {data?.temperature?.mean?.value !== "--" &&
          data?.temperature?.mean?.id ? (
            <>
              <Col span={12}>{t("room-info.temperature")}</Col>
              <ValueCol openGraph={openGraph} {...data.temperature.mean} />
            </>
          ) : null}

          {data?.humidity_sensor?.mean?.value !== "--" &&
          data?.humidity_sensor?.mean?.id ? (
            <>
              <Col span={12}>{t("room-info.moisture")}</Col>
              <ValueCol openGraph={openGraph} {...data.humidity_sensor.mean} />
            </>
          ) : null}

          {data?.co2?.mean?.value !== "--" && data?.co2?.mean?.id ? (
            <>
              <Col span={12}>{t("room-info.CO2")}</Col>
              <ValueCol openGraph={openGraph} {...data.co2.mean} />
            </>
          ) : null}

          {data?.VOC?.mean?.value !== "--" && data?.VOC?.mean?.id ? (
            <>
              <Col span={12}>{t("room-info.VOC")}</Col>
              <ValueCol openGraph={openGraph} {...data.VOC.mean} />
            </>
          ) : null}

          {data?.radon?.mean?.value !== "--" && data?.radon?.mean?.id ? (
            <>
              <Col span={12}>{t("room-info.radon")}</Col>
              <ValueCol openGraph={openGraph} {...data.radon.mean} />
            </>
          ) : null}

          {data?.motion?.value !== "--" && data?.motion?.id ? (
            <>
              <Col span={12}>{t("room-info.motion")}</Col>
              <ValueCol openGraph={openGraph} {...data.motion} />
            </>
          ) : null}
        </Row>
        {importedTimeseriesList.map((imp) => (
          <Row
            gutter={12}
            key={imp.relationshipExternalId}
            style={{ marginBottom: 12, marginTop: 12 }}
          >
            <Col span={15}>
              {imp.name} {imp.description}
            </Col>
            <Col span={7} style={{ textAlign: "right" }}>
              {imp.type === "writable" ? (
                <Space>
                  <div className="inline-flex">
                    <InputNumber
                      disabled={user.isViewer}
                      value={imp.value}
                      suffix={imp.unit !== "" ? imp.unit : undefined}
                      onChange={async (value) => {
                        setImportedTimeseriesList(
                          importedTimeseriesList.map((i) =>
                            i.relationshipExternalId ===
                            imp.relationshipExternalId
                              ? {
                                  ...i,
                                  value: value === null ? undefined : value,
                                  touched: i.value !== value,
                                }
                              : i,
                          ),
                        );
                      }}
                      decimalSeparator=","
                    />
                    <Button
                      disabled={user.isViewer}
                      size="small"
                      style={
                        imp.priority === 8
                          ? { color: themeContext.primary }
                          : {}
                      }
                      icon={
                        imp.priority === 8 ? (
                          <IoHandRight />
                        ) : (
                          <IoHandRightOutline />
                        )
                      }
                      type="link"
                      onClick={async (event) => {
                        event.currentTarget.blur();
                        setImportedTimeseriesList(
                          importedTimeseriesList.map((i) =>
                            i.relationshipExternalId ===
                            imp.relationshipExternalId
                              ? {
                                  ...i,
                                  overrideValue: undefined,
                                }
                              : i,
                          ),
                        );
                        try {
                          await updateSetPoint({
                            priority: 8,
                            value: undefined,
                            external_id: imp.relationshipExternalId,
                            audit_source: "web",
                          });
                        } catch (error) {
                          const errorMessage = parseError(error);
                          message.open({
                            type: "error",
                            content: t(
                              "writable.edit-set-point-modal.error-update-set-point",
                              { errorMessage },
                            ),
                            duration: DEFAULT_MESSAGE_DURATION,
                          });
                        }
                      }}
                    />
                    <Button
                      icon={<HistoryOutlined />}
                      className="ml-2"
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowHistoryForImportedTimeseries(imp);
                      }}
                    />
                  </div>
                </Space>
              ) : (
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => {
                    openGraph(imp.timeseriesId);
                  }}
                >
                  {formatMeasurementForSchema({
                    value: imp.value,
                    unit: imp.unit,
                    stateDescription: imp.stateDescription,
                  })}
                </Button>
              )}
            </Col>
            <Col span={2} style={{ textAlign: "right" }}>
              <Popconfirm
                disabled={user.isViewer}
                title="Er du sikker på at du vil slette koblingen?"
                onConfirm={async () => {
                  await client.relationships.delete([
                    { externalId: imp.relationshipExternalId },
                  ]);
                  setImportedTimeseriesList(
                    importedTimeseriesList.filter(
                      (r) =>
                        r.relationshipExternalId !== imp.relationshipExternalId,
                    ),
                  );
                }}
                okText="Slett"
                cancelText="Avbryt"
              >
                <Button
                  icon={<CloseOutlined />}
                  size="small"
                  disabled={user.isViewer}
                />
              </Popconfirm>
            </Col>
          </Row>
        ))}
        <TimeseriesSelectionModal
          open={Boolean(newRoom && showSelectTimeseries)}
          hiddenFilters={["building"]}
          initialFilters={{
            category: showAllTimeseries ? "default" : "setPoint",
            buildingId: building?.rootId,
            subBuilding: newRoom?.subBuilding ? newRoom.subBuilding : undefined,
          }}
          onHide={() => {
            if (showAllTimeseries) {
              setShowAllTimeseries(false);
            }
            if (showWritableTimeseries) {
              setShowWritableTimeseries(false);
            }
          }}
          selectedIds={importedTimeseriesList.map(
            (imported) => imported.timeseriesId,
          )}
          max={200}
          onOk={handleChangeTimeseries}
          initialSearch={showAllTimeseries ? newRoom?.name : ""}
        />
        {showHistoryForImportedTimeseries && (
          <AuditLogModal
            name={`${showHistoryForImportedTimeseries.name} ${showHistoryForImportedTimeseries.description}`}
            externalId={showHistoryForImportedTimeseries.externalId}
            onHide={() => setShowHistoryForImportedTimeseries(undefined)}
          />
        )}
      </Wrapper>
    </>
  );
};

function NoModal(props: Omit<ModalProps, "footer"> & { footer: ReactNode }) {
  return (
    <div>
      {props.children}
      <div className="flex gap-2 mt-4">{props.footer}</div>
    </div>
  );
}
