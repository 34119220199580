import { Space, Tooltip } from "antd";
import { useTranslations } from "@properate/translations";
import { WasteCardKpiType } from "@/pages/waste/types";
import { KpiCard, StyledStatistic, TooltipDescription } from "./../../elements";

const getType = (value: number) => {
  if (value < 6) {
    return "error";
  }
  if (value >= 6 && value < 10) {
    return "ok";
  }
  return "good";
};
export const FractionsCardKpi = ({
  title,
  value,
  unit,
  prefix,
}: WasteCardKpiType) => {
  const t = useTranslations();

  if (typeof value !== "number") {
    return (
      <KpiCard>
        <div className="ant-card-body">
          <StyledStatistic type={"ok"} title={title} value={"--"} />
        </div>
      </KpiCard>
    );
  }

  const DESCRIPTION_KPI_FRACTIONS_TOOLTIP = (
    <Space direction="vertical">
      <TooltipDescription type="good">
        {t("waste.fraction-card.good")}
      </TooltipDescription>
      <TooltipDescription type="ok">
        {t("waste.fraction-card.ok")}
      </TooltipDescription>
      <TooltipDescription type="error">
        {t("waste.fraction-card.error")}
      </TooltipDescription>
      <section>
        <h4>{t("waste.fraction-card.limit-values-description")}</h4>
        <p>{t("waste.fraction-card.at-least-6-fractions")}</p>
      </section>
    </Space>
  );

  return (
    <Tooltip placement="top" title={DESCRIPTION_KPI_FRACTIONS_TOOLTIP}>
      <KpiCard>
        <div className="ant-card-body">
          <StyledStatistic
            type={getType(value)}
            title={title}
            value={new Intl.NumberFormat("nb-NO", {
              maximumFractionDigits: 0,
            }).format(value)}
            precision={0}
            prefix={prefix}
            suffix={unit}
          />
        </div>
      </KpiCard>
    </Tooltip>
  );
};
