import { Asset } from "@cognite/sdk";
import { get } from "lodash";
import MiniSearch from "minisearch";
import { useEffect } from "react";
import { useUser } from "@properate/auth";
import { useRootAssets } from "@/hooks/useRootAssets";
import { getItemByProp } from "@/utils/array";
import { useAliasConfig } from "@/services/alias";

interface AssetWithAlias extends Asset {
  alias: string | undefined;
}

const miniSearch = new MiniSearch<AssetWithAlias | Asset>({
  fields: ["alias", "metadata.Adresse", "metadata.Poststed", "metadata.owner"],
  extractField: get,
  searchOptions: {
    prefix: true,
    fuzzy: 0.2,
    combineWith: "AND",
  },
});

function compareByAddress(assetOne: Asset, assetTwo: Asset) {
  const assetOneAddress = assetOne.metadata?.Adresse;
  const assetTwoAddress = assetTwo.metadata?.Adresse;
  if (!assetOneAddress) {
    return assetTwoAddress ? 1 : 0;
  }
  if (!assetTwoAddress) {
    return -1;
  }
  return assetOneAddress.localeCompare(assetTwoAddress);
}

function compareByOwnerAndAddress(assetOne: Asset, assetTwo: Asset) {
  const assetOneOwner = assetOne.metadata?.owner;
  const assetTwoOwner = assetTwo.metadata?.owner;
  if (!assetOneOwner) {
    return assetTwoOwner ? 1 : compareByAddress(assetOne, assetTwo);
  }
  if (!assetTwoOwner) {
    return -1;
  }
  const assetOwnerComparison = assetOneOwner.localeCompare(assetTwoOwner);
  if (assetOwnerComparison === 0) {
    return compareByAddress(assetOne, assetTwo);
  }
  return assetOwnerComparison;
}

export function addAliasToRootAssets(
  rootAssets: Asset[],
  buildingIdToAliasMap?: Record<number, string>,
) {
  return rootAssets.map((rootAsset) => ({
    ...rootAsset,
    alias: buildingIdToAliasMap?.[rootAsset.id] as string | undefined,
  }));
}

export function useRootAssetSearch(searchTerm: string) {
  const rootAssets = useRootAssets();
  const { ownerAlias } = useUser();
  const aliasConfig = useAliasConfig(ownerAlias);
  const rootAssetsWithAliases = addAliasToRootAssets(
    rootAssets,
    aliasConfig?.buildings,
  );
  useEffect(() => {
    miniSearch.removeAll();
    miniSearch.addAll(rootAssetsWithAliases);
  }, [rootAssetsWithAliases]);

  if (searchTerm === "") {
    return rootAssetsWithAliases
      .map((asset) => ({
        asset,
        terms: [],
      }))
      .sort(({ asset: assetOne }, { asset: assetTwo }) =>
        compareByOwnerAndAddress(assetOne, assetTwo),
      );
  }
  const searchResults = miniSearch.search(searchTerm);
  return searchResults
    .map(({ id, score, terms }) => ({
      asset: getItemByProp(rootAssetsWithAliases!, id),
      score,
      terms,
    }))
    .sort(
      (
        { score: scoreOne, asset: assetOne },
        { score: scoreTwo, asset: assetTwo },
      ) => {
        const scoreDiff = scoreTwo - scoreOne;
        return scoreDiff === 0
          ? compareByOwnerAndAddress(assetOne, assetTwo)
          : scoreDiff;
      },
    );
}
