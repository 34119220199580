import { useEffect, useState } from "react";
import {
  getSystemCodeFromExternalId,
  formatSubBuildingFromExternalId,
} from "@properate/common";
import { Asset, CogniteClient } from "@cognite/sdk";
import { sortString } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { getEPredAssets } from "@/utils/helpers";

interface Props {
  buildingId: number;
  client: CogniteClient;
  selectedAssets: number[];
  onSelectAsset: (id: number) => unknown;
}

export const SettingModal = ({
  client,
  buildingId,
  selectedAssets,
  onSelectAsset,
}: Props) => {
  const t = useTranslations();

  const [totalEnergyAssets, setTotalEnergyAssets] = useState<Asset[]>([]);

  async function loadData(client: CogniteClient, id: number) {
    const ePredAssets = await getEPredAssets(client, id);
    setTotalEnergyAssets(ePredAssets);
  }

  useEffect(() => {
    loadData(client, buildingId);
  }, [client, buildingId]);

  function getNameWithDescription(asset: Asset) {
    if (typeof asset.description === "string") {
      return `${asset.name} ${asset.description}`;
    }
    return asset.name;
  }

  return (
    <TableWithoutDefaultSort
      dataSource={totalEnergyAssets}
      pagination={false}
      rowKey="id"
      rowSelection={{
        type: "radio",
        onSelect: ({ id }) => onSelectAsset(id),
        selectedRowKeys: selectedAssets,
      }}
      locale={{
        emptyText: t("dashboard.widgets.energy-consumption.no-epred-series"),
      }}
      rowIsHoverable
      onRow={({ id }) => {
        return {
          onClick: () => onSelectAsset(id),
        };
      }}
      columns={[
        {
          title: t("dashboard.widgets.energy-consumption.sub-building"),
          key: "subBuilding",
          width: 100,
          sorter: (
            { parentExternalId: parentExternalIdOne },
            { parentExternalId: parentExternalIdTwo },
          ) => {
            if (parentExternalIdOne === undefined) {
              return parentExternalIdTwo === undefined ? 0 : 1;
            }
            if (parentExternalIdTwo === undefined) {
              return -1;
            }
            const subBuildingOne =
              formatSubBuildingFromExternalId(parentExternalIdOne);
            const subBuildingTwo =
              formatSubBuildingFromExternalId(parentExternalIdTwo);
            return sortString(subBuildingOne, subBuildingTwo);
          },
          render: (_, { parentExternalId }) => {
            if (parentExternalId) {
              const subBuilding =
                formatSubBuildingFromExternalId(parentExternalId);
              return <p>{subBuilding}</p>;
            }
            return null;
          },
        },
        {
          title: t("dashboard.widgets.energy-consumption.system"),
          key: "system",
          width: 100,
          sorter: (
            { parentExternalId: parentExternalIdOne },
            { parentExternalId: parentExternalIdTwo },
          ) => {
            if (parentExternalIdOne === undefined) {
              return parentExternalIdTwo === undefined ? 0 : 1;
            }
            if (parentExternalIdTwo === undefined) {
              return -1;
            }
            const systemCodeOne =
              getSystemCodeFromExternalId(parentExternalIdOne);
            const systemCodeTwo =
              getSystemCodeFromExternalId(parentExternalIdTwo);
            return sortString(systemCodeOne, systemCodeTwo);
          },
          render: (_, { parentExternalId }) => {
            if (parentExternalId) {
              const system = getSystemCodeFromExternalId(parentExternalId);
              return <p>{system}</p>;
            }
            return null;
          },
        },
        {
          title: t("dashboard.widgets.energy-consumption.name"),
          dataIndex: "name",
          width: 586,
          key: "name",
          sorter: (assetOne, assetTwo) => {
            const nameWithDescriptionOne = getNameWithDescription(assetOne);
            const nameWithDescriptionTwo = getNameWithDescription(assetTwo);
            return sortString(nameWithDescriptionOne, nameWithDescriptionTwo);
          },
          render: (_, rowData) => {
            return <p>{getNameWithDescription(rowData)}</p>;
          },
        },
      ]}
    />
  );
};
