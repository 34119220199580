import { useState, useEffect } from "react";
import { ExpandAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { Modal, ModalProps } from "antd";
import { useWindowSize } from "@/hooks/useWindowSize";
import { TitleContainer, MaximimalizeButton } from "./elements";

export function MaximizableModal({
  title,
  open = false,
  ...props
}: ModalProps) {
  const [isMaximized, setIsMaximized] = useState(false);
  const [bodyHeight, setBodyHeight] = useState<number | null>(null);
  const { height: windowHeight } = useWindowSize();

  function handleClickMaximize() {
    setIsMaximized((maximizedCurrent) => !maximizedCurrent);
  }

  useEffect(() => {
    if (isMaximized) {
      const modalHeader = document.querySelector(".ant-modal-header");
      const modalFooter = document.querySelector(".ant-modal-footer");
      const usedHeight = [modalHeader, modalFooter]
        .filter((element): element is Element => element !== null)
        .reduce(
          (usedHeightAcc, element) =>
            usedHeightAcc + element.getBoundingClientRect().height,
          0,
        );
      // We can't use the CSS calc function to specify body height, so we have to resort to using px
      setBodyHeight(windowHeight - usedHeight);
    }
  }, [windowHeight, isMaximized, setBodyHeight]);

  useEffect(() => {
    if (!open && isMaximized) {
      setIsMaximized(false);
    }
  }, [open, isMaximized, setIsMaximized]);

  return (
    <Modal
      title={
        <TitleContainer>
          {title}
          <MaximimalizeButton
            $withMargin={props.closable !== false}
            $withTitle={Boolean(title)}
            onClick={handleClickMaximize}
            icon={isMaximized ? <ShrinkOutlined /> : <ExpandAltOutlined />}
            title={isMaximized ? "Tilbakestill størrelse" : "Maksimer"}
          />
        </TitleContainer>
      }
      {...props}
      open={open}
      width={isMaximized ? "100vw" : props.width}
      style={
        isMaximized
          ? {
              // Override max width specified in Modal component itself
              maxWidth: "100vw",
              // Avoid modal sometimes jumping out of screen (no idea why that happens!)
              position: "absolute",
              inset: 0,
            }
          : props.style
      }
      styles={{
        body: {
          ...props.style,
          ...(isMaximized && typeof bodyHeight === "number"
            ? {
                height: `${bodyHeight}px`,
              }
            : null),
        },
      }}
    />
  );
}
