import { useState } from "react";
import { WithRequired, AlarmWithId } from "@properate/common";
import { App, Button, Space } from "antd";
import { useTranslations } from "@properate/translations";
import { mutateAlarm } from "@/services/alarms";
import { isErrorWithMessage } from "@/utils/api";

interface Props {
  alarm: WithRequired<AlarmWithId, "subscriptions">;
  email?: string;
  phone?: string;
}

export function AlarmUnsubscribeDetails({ alarm, email, phone }: Props) {
  const t = useTranslations();
  const { notification } = App.useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [hasUnsubscribed, setHasUnsubscribed] = useState(false);

  async function removeSubscriptionFromAlarm() {
    try {
      setIsLoading(true);
      await mutateAlarm({
        snapshotId: alarm.snapshotId,
        subscriptions: alarm.subscriptions.map((subscription) => {
          return {
            ...subscription,
            emails: subscription.emails
              ? subscription.emails.filter(
                  (subscriptionEmail) => subscriptionEmail !== email,
                )
              : undefined,
            phones: subscription.phones
              ? subscription.phones.filter(
                  (subscriptionPhone) => subscriptionPhone !== phone,
                )
              : undefined,
          };
        }),
      });
      setHasUnsubscribed(true);
    } catch (error) {
      if (isErrorWithMessage(error)) {
        return notification.error({
          message: error.message,
        });
      }
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const alarmName = alarm.name ? `"${alarm.name}" ` : "";

  return hasUnsubscribed ? (
    <span>
      {t("alarm-details.unsubscribe.unsubscribe-from-alarm", {
        alarm: alarmName,
      })}
    </span>
  ) : (
    <Space>
      <span>
        {t("alarm-details.unsubscribe.remove-from-alarm", {
          alarm: alarmName,
        })}
      </span>
      <Button
        type="primary"
        onClick={removeSubscriptionFromAlarm}
        loading={isLoading}
      >
        {t("alarm-details.unsubscribe.ok")}
      </Button>
    </Space>
  );
}
