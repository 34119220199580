import { Select } from "antd";
import { ThirdPartyAlarmVisualTimeseriesType } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { FormContextItem } from "../../../FormContext";
import { ThirdPartyAlarmFields } from "../types";

export default function SelectVisualTimeseriesType() {
  const t = useTranslations(
    "alarm-details.alarm-types.third-party-alarm.visual-timeseries",
  );

  return (
    <FormContextItem
      id={ThirdPartyAlarmFields.AddVisualTimeseriesType}
      label={t("select-type")}
      labelSrOnly
      antdInput
      className="w-full h-full"
    >
      <Select
        size="small"
        className={"!h-[23px] w-full"}
        placeholder={t("select-type")}
        options={[
          ThirdPartyAlarmVisualTimeseriesType.High,
          ThirdPartyAlarmVisualTimeseriesType.Value,
          ThirdPartyAlarmVisualTimeseriesType.Low,
        ].map((labelType) => ({
          value: labelType,
          label: t(`type.${labelType}`),
        }))}
      />
    </FormContextItem>
  );
}
