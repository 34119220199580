export const getUrl = (
  id: number,
  search: string,
  labels: string[],
  fileTypes: string[],
  sort: { property: string; order: string },
  hidden: boolean,
  resource: string,
  fileId?: number,
) => {
  const args = [];
  if (labels.length > 0) {
    args.push(labels.map((label) => `label=${label}`).join("&"));
  }
  if (fileTypes.length > 0) {
    args.push(fileTypes.map((type) => `fileType=${type}`).join("&"));
  }
  if (resource) {
    args.push(`resource=${resource}`);
  }
  args.push(`sort=${sort.property}&order=${sort.order}`);

  if (fileId) {
    if (search) {
      return `/asset/${id}/${
        hidden ? "trash" : "files"
      }/${search}/preview/${fileId}?${args.join("&")}`;
    }
    return `/asset/${id}/${
      hidden ? "trash" : "files"
    }/preview/${fileId}?${args.join("&")}`;
  }
  return `/asset/${id}/${hidden ? "trash" : "files"}/${search}?${args.join(
    "&",
  )}`;
};

export const FILE_LABELS = [
  "floor_plan",
  "system_schema",
  "manual",
  "datasheet",
  "service_report",
  "action_list",
  "energy_assessment",
  "energy_analysis_rapport",
  "operation_maintenance_manual",
  "u_ventilation_system_report",
  "u_breeam_report",
  "u_topology_schema",
  "u_technical_drawing",
  "u_flow_chart",
  "u_hms_schema",
  "u_work_order",
  "work_order_attachment",
  "report",
  "energy_certificate",
];
