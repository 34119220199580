import { Row, Col, Switch } from "antd";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";

interface Props {
  value: boolean;
  onToggle: () => unknown;
}

export function SchedulePaneHolidayInput({ value, onToggle }: Props) {
  const t = useTranslations();

  const user = useUser();
  return (
    <Row>
      <Col flex={1}>
        {t("common.timeseries-graph-modal.plan.create-alarms-on-holidays")}
      </Col>
      <Col>
        <Switch disabled={user.isViewer} checked={!value} onChange={onToggle} />
      </Col>
    </Row>
  );
}
