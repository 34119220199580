import { MessageKey } from "@properate/translations";
import { TimeseriesType } from "@/features/energy";

export const getTypePath = (type: TimeseriesType) => {
  if (type === "energyMeter") {
    return "i";
  }
  if (type === "generated") {
    return "g";
  }
  if (type === "sources") {
    return "s";
  }
  return "h";
};

export const getTypeNameKey = (type: TimeseriesType): MessageKey => {
  if (type === "energyMeter") {
    return "energy.meter-types.energy-gauges";
  }
  if (type === "generated") {
    return "energy.meter-types.generated-gauges";
  }
  if (type === "sources") {
    return "energy.meter-types.energy-sources";
  }
  return "energy.meter-types.energy-hierarchy";
};
