import { useTranslations } from "@properate/translations";
import { Button, Radio } from "antd";
import { formatTimeseriesName } from "@properate/common";
import ColorDot from "../../../components/ColorDot";
import { useThirdPartyAlarmTimeseriesWithColor } from "../hooks";
import { useFormValue } from "../../../FormContext/hooks";
import { InfoBox } from "../../../components/InfoBox";
import { FormContextItem } from "../../../FormContext";
import { ThirdPartyAlarmFields } from "../types";
import NoSelectedVisualTimeseries from "./NoSelectedVisualTimeseries";
import VisualTimeseriesList from "./VisualTimeseriesList";
import AddNewVisualTimeseries from "./AddNewVisualTimeseries";

enum FormValue {
  ZeroError = "zeroError",
  ZeroNotError = "zeroNotError",
}

export default function ViewThirdPartyAlarm({
  selectBaseTimeseries,
  selectVisualTimeseries,
}: {
  selectBaseTimeseries: () => void;
  selectVisualTimeseries: () => void;
}) {
  const t = useTranslations("alarm-details.alarm-types.third-party-alarm");
  const globalT = useTranslations();
  const [baseTimeseriesId] = useFormValue<number | undefined>(
    ThirdPartyAlarmFields.BaseTimeseries,
  );

  const { timeseries: baseTimeseries, color } =
    useThirdPartyAlarmTimeseriesWithColor(baseTimeseriesId);

  function editBaseTimeseries() {
    selectBaseTimeseries();
  }

  const timeseriesName = baseTimeseries
    ? formatTimeseriesName(baseTimeseries)
    : "--";

  return (
    <>
      <div className="w-full flex flex-col gap-3 border-0 border-b border-solid border-muted">
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <div className="flex flex-row gap-1 items-center mb-2">
              <ColorDot color={color} />
              <h3 className="m-0 p-0">{t("selected-base-timeseries")}:</h3>
            </div>
            <span>{timeseriesName}</span>
          </div>
          <Button size="small" onClick={editBaseTimeseries}>
            {globalT("ui.edit")}
          </Button>
        </div>
        <FormContextItem
          id={ThirdPartyAlarmFields.ZeroIsError}
          label={t("base-error-label")}
          labelSrOnly
          fieldValueFromFormValue={(formValue) => {
            return formValue ? FormValue.ZeroError : FormValue.ZeroNotError;
          }}
          valueFromEvent={(event) => {
            const value = (
              event as unknown as { target?: { value?: FormValue } }
            )?.target?.value;
            return value === FormValue.ZeroError;
          }}
        >
          <Radio.Group>
            <Radio value={FormValue.ZeroError}>{t("base-error-0")}</Radio>
            <Radio value={FormValue.ZeroNotError}>{t("base-error-1")}</Radio>
          </Radio.Group>
        </FormContextItem>
      </div>
      <div className="w-full flex flex-col gap-3">
        <h3 className="m-0">{t("visual-timeseries.heading")}</h3>
        <InfoBox>
          <p className={"small text-secondary m-0 text-xs"}>
            <i>{t("visual-timeseries.subheading")}</i>
          </p>
        </InfoBox>
        <NoSelectedVisualTimeseries />
        <VisualTimeseriesList />
        <AddNewVisualTimeseries
          selectVisualTimeseries={selectVisualTimeseries}
        />
      </div>
    </>
  );
}
