import { CalendarStatusMap } from "@/utils/types";
import { getCalendarStatus } from "@/eepApi";

type Props = {
  buildingId: number;
  handleStatusChange: (status: CalendarStatusMap) => void;
};
export class UpdateStatus {
  timeout = -1;
  #status: CalendarStatusMap = {};

  #buildingId: number;
  #handleStatusChange: (status: CalendarStatusMap) => void;

  #running = true;
  constructor({ buildingId, handleStatusChange }: Props) {
    this.#buildingId = buildingId;
    this.#handleStatusChange = handleStatusChange;
  }
  refresh() {
    clearTimeout(this.timeout);
    getCalendarStatus({ buildingId: this.#buildingId })
      .then((result) => {
        const calendarToStatus = result.reduce(
          (prev, curr) => ({ ...prev, [curr.calendar_id]: curr.status }),
          {},
        );

        if (JSON.stringify(this.#status) !== JSON.stringify(calendarToStatus)) {
          this.#status = calendarToStatus;
          // callback that status has changed
          if (this.#running) {
            this.#handleStatusChange(calendarToStatus);
          }
        }
        this.timeout = window.setTimeout(() => this.refresh(), 30000);
      })
      .catch((e) => {
        this.clear();
        console.error(e);
      });
  }

  clear = () => {
    this.#running = false;
    clearTimeout(this.timeout);
  };
}
