import {
  SetupPage,
  TaskTypeProvider,
  useGetBuildingsWithImages,
} from "@properate/task-manager";
import { useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { PageHeader } from "@ant-design/pro-layout";
import { RightOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useRootAssets } from "@/hooks/useRootAssets";
import FullLayout from "@/layout/FullLayout";

const Container = styled.div<{ $center?: boolean }>`
  padding: 1rem;
  max-width: 1280px;
  width: 100%;
  margin: ${(props) => (props.$center ? "0 auto" : "0")};
`;

type Props = {
  isInsideBuildingLayout?: boolean;
};

function useQueryPresets() {
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);
  return {
    components: params.has("components")
      ? [Number(params.get("components"))]
      : undefined,
  };
}

export function TaskManagerCreateTaskPage(props: Readonly<Props>) {
  const t = useTranslations();
  const buildings = useRootAssets();
  const { components } = useQueryPresets();
  const { id: buildingId } = useParams();
  const navigate = useNavigate();
  const mappedBuildings = useGetBuildingsWithImages(buildings);

  const content = (
    <TaskTypeProvider taskType="tasks">
      <Container $center={!props.isInsideBuildingLayout}>
        <SetupPage
          buildings={mappedBuildings}
          buildingId={buildingId ? Number(buildingId) : undefined}
          onCreated={({ buildingId, snapshotId }) =>
            navigate(`/asset/${buildingId}/workOrders/${snapshotId}`)
          }
          components={components}
        />
      </Container>
    </TaskTypeProvider>
  );

  if (props.isInsideBuildingLayout) {
    return (
      <>
        <PageHeader
          title={
            <>
              <Link to={`/asset/${buildingId}/workOrders`}>
                {t("task.ui.title")}
              </Link>{" "}
              <RightOutlined />
            </>
          }
          subTitle={t("task.template.new-template")}
        />
        {content}
      </>
    );
  }

  return (
    <FullLayout pageName={t("task.template.new-template")} headerRight={<></>}>
      {content}
    </FullLayout>
  );
}
