import { Checkbox, InputNumber } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useTranslations } from "@properate/translations";
import DaysHoursMinutesForm from "./DaysHoursMinutesForm";

interface Props {
  durationBeforeFallback: number;
  fallbackValue: number;
  onDurationBeforeFallbackChange: (milliseconds: number) => void;
  onFallbackValueChange: (value: number) => void;
}

function ErrorHandlingFallbackInput(params: Props) {
  const t = useTranslations();

  const handleFallbackCheckboxChange = (e: CheckboxChangeEvent) => {
    params.onDurationBeforeFallbackChange(
      (e.target.checked ? 1 : -1) * Math.abs(params.durationBeforeFallback),
    );
  };

  const validator = () => {
    if (params.durationBeforeFallback <= 0)
      return Promise.reject(new Error("Tidsperioden må være positive"));
    return Promise.resolve();
  };

  const handleFallbackValueChange = (fallbackValue: number | null) => {
    if (fallbackValue !== null) params.onFallbackValueChange(fallbackValue);
  };

  return (
    <p>
      <Checkbox
        onChange={handleFallbackCheckboxChange}
        checked={params.durationBeforeFallback >= 0}
      >
        {params.durationBeforeFallback >= 0
          ? t("calculation-flow.node-types.fallback-value")
          : t("calculation-flow.node-types.fallback")}
      </Checkbox>
      {params.durationBeforeFallback >= 0 && (
        <>
          <InputNumber
            value={params.fallbackValue}
            onChange={handleFallbackValueChange}
            decimalSeparator=","
          />
          <DaysHoursMinutesForm
            title={t("calculation-flow.node-types.timeseries-before-used")}
            name="durationBeforeFallback"
            milliseconds={Math.abs(params.durationBeforeFallback)}
            onChange={params.onDurationBeforeFallbackChange}
            rules={[
              () => ({
                validator,
              }),
            ]}
          />
        </>
      )}
    </p>
  );
}

export default ErrorHandlingFallbackInput;
