import { Row, Col } from "antd";
import { Alarm, Period, TimeSpanObject } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { SchedulePaneActivePeriods } from "./SchedulePaneActivePeriods";
import { SchedulePaneActivePeriodInput } from "./SchedulePaneActivePeriodInput";
import { SchedulePaneHolidayInput } from "./SchedulePaneHolidayInput";
import { SchedulePaneInactivePeriodInput } from "./SchedulePaneInactivePeriodInput";
import { SchedulePaneInactivePeriods } from "./SchedulePaneInactivePeriods";

type Props = {
  alarm: Alarm;
  onChange: (alarm: Alarm) => unknown;
};

export const SchedulePane = ({ alarm, onChange }: Props) => {
  const t = useTranslations();

  function handleAddActivePeriods(newPeriods: Period[]) {
    onChange({ ...alarm, alarmPeriods: alarm.alarmPeriods.concat(newPeriods) });
  }

  function handleChangeActivePeriods(periods: Period[]) {
    onChange({ ...alarm, alarmPeriods: periods });
  }

  function handleAddInactivePeriod(timeSpan: TimeSpanObject) {
    onChange({
      ...alarm,
      inactivePeriods: alarm.inactivePeriods
        ? alarm.inactivePeriods.concat(timeSpan)
        : [timeSpan],
    });
  }

  function handleRemoveInactivePeriod({
    start: timeSpanToRemoveStart,
    end: timeSpanToRemoveEnd,
  }: TimeSpanObject) {
    onChange({
      ...alarm,
      inactivePeriods: alarm.inactivePeriods!.filter(
        ({ start: inactivePeriodStart, end: inactivePeriodEnd }) =>
          inactivePeriodStart !== timeSpanToRemoveStart ||
          inactivePeriodEnd !== timeSpanToRemoveEnd,
      ),
    });
  }

  function handleToggleAlarmsOnHolidays() {
    onChange({
      ...alarm,
      noAlarmsOnHolidays: !alarm.noAlarmsOnHolidays,
    });
  }

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <h2>{t("common.timeseries-graph-modal.plan.active")}</h2>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            {alarm.alarmPeriods.length > 0 ? (
              <SchedulePaneActivePeriods
                periods={alarm.alarmPeriods}
                onChange={handleChangeActivePeriods}
              />
            ) : (
              <span>
                {t("common.timeseries-graph-modal.plan.all-days-active")}
              </span>
            )}
          </Col>
          <Col span={24}>
            <SchedulePaneActivePeriodInput
              periods={alarm.alarmPeriods}
              onAdd={handleAddActivePeriods}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <h2>{t("common.timeseries-graph-modal.plan.inactive")}</h2>
        <Row gutter={[0, 8]}>
          {alarm.inactivePeriods && alarm.inactivePeriods.length > 0 && (
            <Col span={24}>
              <SchedulePaneInactivePeriods
                periods={alarm.inactivePeriods}
                onRemove={handleRemoveInactivePeriod}
              />
            </Col>
          )}
          <Col span={24}>
            <SchedulePaneInactivePeriodInput
              onAdd={handleAddInactivePeriod}
              periods={alarm.inactivePeriods}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <h2>{t("common.timeseries-graph-modal.plan.holidays")}</h2>
        <SchedulePaneHolidayInput
          value={alarm.noAlarmsOnHolidays || false}
          onToggle={handleToggleAlarmsOnHolidays}
        />
      </Col>
    </Row>
  );
};
