import { Tag } from "antd";
import { useCallback } from "react";
import { MessageKey, useTranslations } from "@properate/translations";
import { IncidentFilter } from "@/features/alarms";

const IncidentTableFilter = (props: {
  checked: boolean;
  onClick: () => void;
  translationKey: string;
  dataTestId: string;
}) => {
  const t = useTranslations();

  return (
    <Tag.CheckableTag
      data-testid={props.dataTestId}
      checked={props.checked}
      onClick={props.onClick}
    >
      {t(`incidents.table-filters.${props.translationKey}` as MessageKey)}
    </Tag.CheckableTag>
  );
};

const INCIDENT_FILTERS: IncidentFilter[] = [
  "all",
  "only_active",
  "mine",
  "resolved",
  "unresolved",
  "in_progress",
];

type Props = {
  activeFilters: IncidentFilter[];
  onChangeFilters: (filters: IncidentFilter[]) => void;
};

export const IncidentTableFilters = (props: Props) => {
  const { activeFilters, onChangeFilters } = props;

  const onChangeFilter = useCallback(
    (filter: IncidentFilter) => {
      const getNewFilters = () => {
        let newFilters = [...activeFilters];
        // If the filter is the last one, do nothing
        if (activeFilters.length === 1 && activeFilters[0] === filter) {
          return newFilters;
        }

        // If "all" is selected and another filter is selected, set filters to "all"
        if (filter === "all" && activeFilters.length > 0) {
          return ["all" as IncidentFilter];
        }

        // If other than "all" is selected, remove "all"
        if (
          filter !== "all" &&
          !activeFilters.includes(filter) &&
          activeFilters.includes("all")
        ) {
          newFilters = activeFilters.filter((f) => f !== "all");
        }

        if (activeFilters.includes(filter)) {
          return newFilters.filter((f) => f !== filter);
        }

        return [...newFilters, filter];
      };
      onChangeFilters(getNewFilters());
    },
    [activeFilters, onChangeFilters],
  );

  return (
    <div className="flex gap-2">
      {INCIDENT_FILTERS.map((filter) => (
        <IncidentTableFilter
          key={filter}
          translationKey={filter}
          onClick={() => onChangeFilter(filter)}
          checked={activeFilters.includes(filter)}
          dataTestId={`incident-filter-${filter}`}
        />
      ))}
    </div>
  );
};
