import { useMemo } from "react";
import { AccessModules } from "@/features/organizationAdmin";
import { ErrorResult } from "@/pages/Error/ErrorResult";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";

import { useOrganizationModulesAccess } from "@/services/featureAccess/useAccessModuleFeatureAccess";

export const ProtectedRouteByOrganizationModulesAccess = ({
  children,
  accessModule,
}: {
  children: React.ReactNode;
  accessModule: AccessModules | AccessModules[];
}) => {
  const { accessModules: organizationAccessModules, isLoading } =
    useOrganizationModulesAccess();

  const hasAccess = useMemo(() => {
    const accessModules = Array.isArray(accessModule)
      ? accessModule
      : [accessModule];

    return accessModules.some(
      (module) => organizationAccessModules?.includes(module),
    );
  }, [accessModule, organizationAccessModules]);

  if (isLoading)
    return (
      <SpinnerWithDelay
        className="flex items-center justify-center h-full"
        isLoading
      >
        <div />
      </SpinnerWithDelay>
    );

  return hasAccess ? <>{children}</> : <ErrorResult status={403} />;
};
