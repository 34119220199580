import { Form, Select } from "antd";
import { useTranslations } from "@properate/translations";
import { getLabelForAsset, sortByLabel } from "../utils";
import { useAssetList } from "../hooks";
import { FormFieldProps } from "./types";

type AssetSelectFieldProps = {
  currentDataSetId: number | null;
} & FormFieldProps;

type AssetOption = {
  value: string;
  label: string;
};

export default function AssetSelectField({
  disabled,
  currentDataSetId,
}: AssetSelectFieldProps) {
  const { assetList } = useAssetList();
  const t = useTranslations();
  const form = Form.useFormInstance();

  const fieldValue = "assetIds";

  const options = (assetList ?? [])
    .filter((asset) => asset.dataSetId === currentDataSetId)
    .map((asset) => {
      return {
        label: getLabelForAsset(asset),
        value: asset.id.toString(),
      };
    })
    .sort(sortByLabel);

  const assets: string[] | AssetOption[] = form.getFieldValue(fieldValue);

  const currentValues = assets.map((item) =>
    typeof item === "string" ? item : item.value,
  );

  if (
    options.length === 1 &&
    form.getFieldValue(fieldValue) !== options[0].value
  ) {
    form.setFieldValue(fieldValue, [options[0].value]);
  } else if (!options.some((option) => currentValues.includes(option.value))) {
    form.setFieldValue(fieldValue, []);
  }

  return (
    <Form.Item
      name={fieldValue}
      label={t("notes.form.asset-select.label")}
      required={false}
      rules={[
        { required: true, message: t("notes.form.asset-select.message") },
      ]}
    >
      <Select
        mode="multiple"
        disabled={disabled}
        options={options}
        allowClear
        showSearch
        filterOption={(input, option) =>
          option
            ? option.label.toLowerCase().includes(input.toLowerCase())
            : false
        }
      />
    </Form.Item>
  );
}
