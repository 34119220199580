import useSWR from "swr";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useHandleApiError } from "@/utils/api";
import { listGroup } from "@/eepApi";

export const useAlertGroupNames = (currentGroupId?: string) => {
  const building = useCurrentBuilding();
  const handleAPIError = useHandleApiError();

  const {
    data: groupNames = [],
    error,
    isLoading: isLoadingGroups,
  } = useSWR(
    ["alertGroups", building.externalId],
    async () => {
      const { items } = await listGroup({
        filter: {
          building_external_id: building.externalId!,
        },
      });
      return (
        currentGroupId
          ? items.filter((group) => group.id !== currentGroupId)
          : items
      ).map((group) => group.name);
    },
    {
      keepPreviousData: true,
    },
  );

  if (error !== undefined) {
    handleAPIError(error);
  }

  return { groupNames, isLoadingGroups };
};
