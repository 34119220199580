"use client";

import dayjs from "@properate/dayjs";
import {
  CalendarIcon,
  ClockIcon,
  MessageCircleIcon,
  PaperclipIcon,
  PencilIcon,
  TargetIcon,
} from "lucide-react";
import { cn } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { Task, TaskTemplate } from "../schemas";
import { ChecklistsProgressDisplay } from "./checklists-progress-display";
import { PriorityBadge } from "./priority-badge";
import {
  TaskListActiveFields,
  defaultValue as defaultVisibleFields,
} from "./task-list-field-picker";
import { StatusTag } from "./status-tag";

type Props = {
  task: Task | TaskTemplate;
  address?: string;
  fields?: TaskListActiveFields;
  isFirst?: boolean;
  isLast?: boolean;
};

export function TaskListItem({
  task,
  address,
  isFirst = true,
  isLast = true,
  fields = defaultVisibleFields,
}: Props) {
  const t = useTranslations();

  function shouldShow(field: keyof TaskListActiveFields) {
    return fields === undefined || fields[field];
  }

  return (
    <div
      className={cn(
        "flex flex-col justify-between overflow-hidden gap-2 px-4 py-3 transition-colors bg-card border-0 border-b border-solid lg:border lg:border-b-0 border-border/50 lg:flex-row hover:bg-accent cursor-pointer",
        {
          "lg:rounded-t-lg": isFirst,
          "lg:rounded-b-lg lg:border-b": isLast,
          "border-t-0": !isFirst,
        },
      )}
    >
      <div className="flex items-center flex-1">
        <div className="flex-1 w-full truncate">
          {task.title || <div className="text-gray-400">{t("ui.empty")}</div>}
        </div>
      </div>
      <div className="flex lg:flex-row-reverse gap-2 lg:gap-4 items-center text-sm flex-wrap">
        {address && (
          <div className="hidden lg:block text-sm flex-1 text-muted-foreground capitalize truncate w-36">
            {address}
          </div>
        )}
        <div className="flex items-center gap-4">
          <StatusTag status={task.status} />
        </div>
        {"dueDate" in task && shouldShow("dueDate") && task.dueDate && (
          <div
            className={cn("rounded-md flex gap-2 items-center", {
              "bg-red-500/25 px-2":
                task.status !== "Completed" &&
                dayjs(task.dueDate).isBefore(dayjs(), "day"),
              "bg-orange-500/25 px-2":
                task.status !== "Completed" &&
                dayjs(task.dueDate).isAfter(dayjs().subtract(4, "days"), "day"),
            })}
          >
            <ClockIcon className="w-3 h-3" />
            {dayjs(task.dueDate).format("DD MMM")}
          </div>
        )}
        {"files" in task && shouldShow("files") && task.files.length > 0 && (
          <div className="flex items-center gap-2">
            <PaperclipIcon className="w-3 h-3" />
            {task.files.length}
          </div>
        )}
        {shouldShow("checklists") && (
          <ChecklistsProgressDisplay
            taskId={task.snapshotId}
            checklists={task.checklists}
          />
        )}
        {"comments" in task &&
          shouldShow("comments") &&
          task.comments.length > 0 && (
            <div className="flex items-center gap-2">
              <MessageCircleIcon className="w-3 h-3" />
              {task.comments.length}
            </div>
          )}
        <PriorityBadge priority={task.priority} />
        {"assignee" in task && shouldShow("assignee") && task.assignee && (
          <div className="flex items-center gap-2">
            <TargetIcon className="w-3 h-3" />
            {trim(task.assignee)}
          </div>
        )}
        {shouldShow("createdAt") && Boolean(task.createdAt) && (
          <div className="flex items-center gap-2">
            <CalendarIcon className="w-3 h-3" />
            {dayjs(task.createdAt).format("D MMM")}
          </div>
        )}
        {shouldShow("author") && task.author && (
          <div className="flex items-center gap-2">
            <PencilIcon className="w-3 h-3" />
            {trim(task.author)}
          </div>
        )}
      </div>
    </div>
  );
}

function trim(str: string, length = 20) {
  if (str.length > length) {
    return `${str.substring(0, length - 3)}...`;
  }

  return str;
}
