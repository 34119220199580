import { formatMeasurement } from "@properate/common";
import * as React from "react";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { useTranslations, MessageKey } from "@properate/translations";
import { TooltipData } from "@/features/energy";
import { formatDateYearAccordingToGranularityShort } from "@/utils/helpers";

export const TooltipContent = ({
  timestamp,
  energy,
  temperature,
  operational,
  bySquareMeter,
  note,
}: TooltipData & { bySquareMeter?: number }) => {
  const t = useTranslations();

  const themeContext = useContext(ThemeContext);

  return (
    <>
      <h3 style={{ textAlign: "right", color: themeContext.neutral5 }}>
        {formatDateYearAccordingToGranularityShort["h"](timestamp)}
      </h3>
      {energy !== undefined
        ? formatMeasurement({
            value:
              bySquareMeter !== undefined ? energy / bySquareMeter : energy,
            unit: bySquareMeter !== undefined ? " kWh/m²" : " kWh",
          })
        : ""}
      <br />
      {temperature !== undefined
        ? formatMeasurement({
            value: temperature,
            unit: "°C",
          })
        : ""}
      <br />
      {t(`energy.legend-values.${operational}` as MessageKey)}
      <br />
      {!!note && (
        <div className="flex gap-1">
          <div className="max-w-md flex">
            <svg width={15} height={15} style={{ marginRight: 5 }}>
              <rect
                fill="transparent"
                stroke={themeContext.accent12}
                strokeWidth={5}
                width={15}
                height={15}
              />
            </svg>
            <div className="flex-1 truncate ...">{note.title}</div>
          </div>
          {note.count - 1 > 0 && <div>(+{note.count - 1})</div>}
        </div>
      )}
    </>
  );
};
