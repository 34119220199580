import styled from "styled-components";
import { DatapointCalculator } from "@/components/DatapointCalculator/DatapointCalculator";

export const GridForm = styled.form`
  display: grid;
  grid-template-columns: 100px 100px;
  justify-items: start;
  align-items: center;
  gap: 8px 4px;
`;

export const ModalContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 16px;
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const DetailsList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 0;
  margin-bottom: 0;
`;

export const DetailHighlight = styled.span`
  font-weight: bold;
`;

export const ModalSubtitle = styled.h4`
  font-size: 13px;
`;

export const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.error};
`;

export const DatapointCalculatorFullWidth = styled(DatapointCalculator)`
  grid-column: 1 / -1;
`;
