import {
  ComponentSearchable,
  RoomInfoType,
  SearchIndex,
  TimeseriesExternalIdSearchable,
  TimeseriesSearchable,
} from "@properate/api/src/types";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { MeiliSearch, SearchParams } from "meilisearch";
import { DoubleDatapoint, FileInfo } from "@cognite/sdk";
import { DateParser } from "@cognite/sdk-core";
import dayjs from "@properate/dayjs";
import {
  AlertGroup,
  AlertGroupClientSide,
  AlertGroupSearchResult,
  getSystemCodeFromExternalId,
  Incident,
  IncidentClientSide,
  IncidentSearchResult,
  type ListSearchParams,
  TimeSpan,
  UserInfo,
  WithRequired,
  SchemaType,
  SchemaTypes,
  AlertRule,
  AlertRuleClientSide,
  AlertRuleSearchResult,
  type GetUnassignedAlarmsRequestParams,
  GetUnassignedAlarmsResponse,
  type ListAlarmSearchParams,
  AlarmRuleSearchResult,
  AlarmRule,
  GetUnresolvedIncidentsCountParams,
  GetUnresolvedIncidentsCountQuery,
  GetGroupDependedDataResponse,
  GetGroupDependedDataParams,
  GetGroupDependedDataQuery,
  ValidateIncidentChangesParams,
  ValidateIncidentChangesResponse,
  ValidateIncidentChangesQuery,
  type GetAlarmSubscriptionFromAlertConfigurationParams,
  GetAlarmSubscriptionFromAlertConfigurationResponse,
  type GetIncidentSearchableValuesResponse,
} from "@properate/common";

import { SearchForFacetValuesParams } from "meilisearch/src/types";
import {
  Holiday,
  NewProperateCalendarEvent,
  ProperateCalendar,
  ProperateCalendarEvent,
  RecurringProperateCalendarEvent,
  StatusList,
} from "@/utils/types";
import {
  CalculationFlow,
  CalculationFlowRunResults,
  SimplifiedCalculationFlow,
} from "@/pages/calculationFlow/types";
import keycloak from "@/keycloak";
import { PostNoteParams } from "@/features/notes";
import { parseCalculationFlowDates } from "./pages/calculationFlow/components/utils";
import hd from "./utils/holidays";
import {
  Meter,
  NewMeter,
  NewVirtualSetpoint,
  VirtualSetpoint,
} from "./components/MeterSelectionModal/types";
import { AnomalyProperty } from "./pages/Anomalies/types";

export const API_HOST = process.env.REACT_APP_API_HOST
  ? process.env.REACT_APP_API_HOST
  : process.env.REACT_APP_PRODUCTION
  ? "api.eepcloud.no"
  : "api.portal.properate.com";

const API_URL = `https://${API_HOST}/api/v0.2/`;

const getAuthHeader = async () => {
  return keycloak.updateToken(30).then(function () {
    return {
      Authorization: `Bearer ${keycloak.token}`,
      "Cdf-project": process.env.REACT_APP_PROJECT_ID!,
    };
  });
};

const getHeaders = async () => {
  return {
    headers: {
      ...(await getAuthHeader()),
    },
  };
};

async function get<T = any>(route: string, config?: AxiosRequestConfig) {
  return axios.get<T>(API_URL + route, { ...(await getHeaders()), ...config });
}

async function post<T = any, D = any>(
  route: string,
  payload: D,
  config?: AxiosRequestConfig,
) {
  return axios.post<T, AxiosResponse<T>, D>(API_URL + route, payload, {
    ...(await getHeaders()),
    ...config,
  });
}

async function del(route: string, config?: AxiosRequestConfig) {
  return axios.delete(API_URL + route, { ...(await getHeaders()), ...config });
}

export type Role = "admin" | "user" | "viewer" | "no_access";
export type AUX_Role = "tapp";
type EEP_Item = Role | AUX_Role;

type Users = Record<
  string,
  {
    owner?: string;
    asset_external_ids?: string[];
    eep?: (Role | AUX_Role)[];
  }
>;

const mapRoles = (roles?: EEP_Item[]): Role => {
  if (!roles?.length) return "no_access";
  if (roles.includes("admin")) return "admin";
  if (roles.includes("user")) return "user";
  return "viewer";
};

export async function listUsers() {
  try {
    const result: AxiosResponse<Users> = await get("users/list");
    return Object.entries(result.data).map(([email, user]) => ({
      email,
      role: mapRoles(user.eep?.filter((e) => e !== "tapp")),
      isTechApp: !!user.eep?.includes("tapp"),
      ...user,
    })) as User[];
  } catch (e) {
    console.error("Could not list users!", { cause: e });
    throw Error("Could not list users!", { cause: e });
  }
}

export type Owners = Record<string, string[]>;
export async function listOwners() {
  try {
    const result = await get("owners/list");
    return result.data as Owners;
  } catch (e) {
    console.error("Could not list owners!", { cause: e });
    throw Error("Could not list owners!", { cause: e });
  }
}

export type SetPointStatusParams = {
  external_ids: readonly string[];
};

export type SetPointStatus = {
  reliability: "no-fault-detected" | string;
  "out-of-service": boolean;
  "priority-array": { index: number; value: number | string }[] | undefined;
  "present-value": number | string;
  protocol: string;
  last_updated: number;
};

export type SetPointStatusMap = Record<string, SetPointStatus>;

export async function getSetPointStatus(
  data: SetPointStatusParams,
): Promise<SetPointStatusMap> {
  const res = await post("timeseries/status", data);
  return res.data;
}

export async function updateSetPoint(data: {
  external_id: string;
  value?: number;
  priority?: number;
  audit_source?: string;
}) {
  return post("timeseries/set", data);
}

export async function updateTimeseries(data: {
  external_id: string;
  description?: string;
  name?: string;
}) {
  if (!data.description && !data.name) {
    throw new Error("No data to update");
  }
  return post("timeseries/update", data);
}

export type NewUser = {
  email?: string;
  owner?: string;
  role: Role;
  asset_external_ids?: string[];
  local_login?: "enable";
  idp?: string | "properate" | "google";
  isTechApp?: boolean;
};
export interface User extends NewUser {
  email: string;
  name?: string;
}

const mapUserToAPI = (user: User) => {
  const { role, isTechApp, ...userData } = user;
  return {
    ...userData,
    access_class: role,
    aux_roles: [
      ...(isTechApp && !(role === "no_access") ? ["tapp" as AUX_Role] : []),
    ],
  };
};

export function createUser(data: User) {
  return post("users/create", mapUserToAPI(data));
}

export function deleteUser(data: { email: string }) {
  return post("users/delete", data);
}

export function updateUser(data: User) {
  return post("users/update", mapUserToAPI(data));
}

export function resetUserPassword({ email }: { email: string }) {
  return post("users/update", { email, local_login: "enable" });
}
export async function getCalendars({
  building_id,
  signal,
}: {
  building_id: number;
  signal?: AbortSignal;
}): Promise<ProperateCalendar[]> {
  try {
    const calendarResponse = await get<Omit<ProperateCalendar, "system">[]>(
      `calendars?building_id=${building_id}`,
      {
        signal,
      },
    );

    return calendarResponse.data.map((cal) => ({
      ...cal,
      system: getSystemCodeFromExternalId(cal.calendar_id),
    }));
  } catch (e) {
    throw Error("Could not get calendars!", { cause: e });
  }
}

const asProperateEvents = (events: any[]) =>
  events.map(
    (event) =>
      ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }) as ProperateCalendarEvent,
  );

export async function updateCalendar(
  data: ProperateCalendar,
): Promise<ProperateCalendar> {
  try {
    const updateCalendarResponse = await post<ProperateCalendar>(
      `calendars/${encodeURIComponent(data.calendar_id)}`,
      { ...data, checksum: "", events: [] },
    );
    return updateCalendarResponse.data;
  } catch (e) {
    throw Error("Could not get calendars!", { cause: e });
  }
}

export async function getCalendarEvents({
  calendarId,
  start,
  end,
  excludes,
  excludeEventsWithDefaultValue,
  signal,
}: {
  calendarId: string;
  start: Date;
  end: Date;
  excludeEventsWithDefaultValue: boolean;
  excludes?: string[];
  signal?: AbortSignal;
}): Promise<ProperateCalendarEvent[]> {
  try {
    const excludesParam = excludes
      ? `&exclude_schedules=${excludes.join(",")}`
      : "";
    const result = await get(
      `calendars/${encodeURIComponent(
        calendarId,
      )}/events?start=${start.getTime()}&end=${end.getTime()}&exclude_events_with_default_value=${
        excludeEventsWithDefaultValue ? 1 : 0
      }${excludesParam}`,
      { signal },
    );
    return asProperateEvents(result.data);
  } catch (e) {
    throw Error("Could not get calendars!", { cause: e });
  }
}

export async function getCalendarStatus({
  buildingId,
  signal,
}: {
  buildingId: number;
  signal?: AbortSignal;
}): Promise<StatusList> {
  try {
    const result = await get(`calendars/status?building_id=${buildingId}`, {
      signal,
    });
    return result.data as StatusList;
  } catch (e) {
    throw Error("Could not get calendar status!", { cause: e });
  }
}
export async function getHolidays({
  start,
  end,
  locale,
}: {
  start: Date;
  end: Date;
  locale: string;
}): Promise<Holiday[]> {
  try {
    let current = dayjs(start).tz("Europe/Oslo").startOf("day");

    const holidays: Holiday[] = [];
    while (current.isBefore(end)) {
      hd.setLanguages(locale);
      const holiday = hd.isHoliday(current.toDate());
      if (holiday && holiday.length > 0) {
        holidays.push({
          name: holiday[0].name,
          date: current.toDate(),
          calendar_id: "holiday",
        });
      }
      current = current.add(1, "day");
    }
    return holidays;

    /*
    we should probably get the holidays from the backend instead of calculating them ourselves
    but currently the backend only return the names in english, so I commented out this code while we wait for a fix

    const result = await get(
      `holidays?start=${start.getTime()}&end=${end.getTime()}`
    );
    return result.data.map(
      (holiday: any) =>
        ({ ...holiday, date: new Date(holiday.date) } as Holiday)
    );*/
  } catch (e) {
    throw Error("Could not get calendars!", { cause: e });
  }
}

export async function addCalendarEvent({
  calendar_id,
  start,
  end,
  schedule,
  value,
}: NewProperateCalendarEvent): Promise<void> {
  try {
    await post(`calendars/${encodeURIComponent(calendar_id)}/events`, {
      start: start.getTime(),
      end: end.getTime(),
      schedule,
      value,
    });
  } catch (e) {
    throw Error("Could not add calendar event!", { cause: e });
  }
}

export async function syncCalendar({
  buildingId,
  calendarIds,
}: {
  buildingId: number;
  calendarIds?: string[];
}): Promise<void> {
  await post(
    `calendars/sync?building_id=${buildingId}`,
    calendarIds && calendarIds.length > 0 ? { calendar_ids: calendarIds } : {},
  );
}

export async function updateCalendarEvent({
  event_ref,
  calendar_id,
  start,
  end,
  schedule,
  value,
}: ProperateCalendarEvent): Promise<void> {
  try {
    await post(
      `calendars/${encodeURIComponent(calendar_id)}/events/${event_ref}`,
      {
        start: start.getTime(),
        end: end.getTime(),
        schedule,
        value,
      },
    );
  } catch (e) {
    throw Error("Could not add calendar event!", { cause: e });
  }
}

export async function deleteCalendarEvent({
  event_ref,
  calendar_id,
  start,
  end,
}: RecurringProperateCalendarEvent & {
  start?: Date;
  end?: Date;
}): Promise<void> {
  try {
    if (start && end) {
      await del(
        `calendars/${encodeURIComponent(
          calendar_id,
        )}/events/${event_ref}?start=${start.getTime()}&end=${end.getTime()}`,
      );
    } else {
      await del(
        `calendars/${encodeURIComponent(calendar_id)}/events/${event_ref}`,
      );
    }
  } catch (e) {
    throw Error("Could not add calendar event!", { cause: e });
  }
}

export async function importCalendar({
  setPointExternalId,
}: {
  setPointExternalId: string;
}): Promise<void> {
  try {
    await post(
      `calendars/import?external_id=${encodeURIComponent(setPointExternalId)}`,
      {},
    );
  } catch (e) {
    throw Error("Could not import software calendar!", { cause: e });
  }
}

export async function deleteImportedCalendar({
  calendarId,
}: {
  calendarId: string;
}): Promise<void> {
  try {
    await del(`calendars/${encodeURIComponent(calendarId)}`);
  } catch (e) {
    throw Error("Could not import software calendar!", { cause: e });
  }
}

export async function deleteComponent({ externalId }: { externalId: string }) {
  try {
    await del(`assets/fom/${encodeURIComponent(externalId)}`);
  } catch (e) {
    throw Error("Could not delete component!", { cause: e });
  }
}

export type ComponentInfo = {
  description: string;
  buildingExternalId: string;
  subBuilding: string;
  componentType: string;
  componentSeqNum: string;
  system: string;
  componentInformation?: string;
  altComponentTag?: string;
  deliveryDate?: Date;
  claimDate?: Date;
  endOfLifeDate?: Date;
  productCode?: string;
  contractor?: string;
  supplier?: string;
  manufacturer?: string;
  placement?: string;
};

export type ComponentInfoWithExternalId = ComponentInfo & {
  externalId: string;
};

const resultToComponentInfo = (result: any): ComponentInfoWithExternalId => ({
  externalId: result.data.external_id,
  description: result.data.description,
  buildingExternalId: result.data.building_id,
  subBuilding: result.data.sub_building,
  componentType: result.data.component_type,
  componentSeqNum: result.data.component_seq_num,
  system: result.data.system,
  componentInformation: result.data.component_information,
  altComponentTag: result.data.alt_component_tag,
  deliveryDate: result.data.delivery_date
    ? new Date(Number(result.data.delivery_date))
    : undefined,
  claimDate: result.data.claim_date
    ? new Date(Number(result.data.claim_date))
    : undefined,
  endOfLifeDate: result.data.end_of_life_date
    ? new Date(Number(result.data.end_of_life_date))
    : undefined,
  productCode: result.data.product_code,
  contractor: result.data.contractor,
  supplier: result.data.supplier,
  manufacturer: result.data.manufacturer,
  placement: result.data.placement,
});

export async function createOrUpdateComponent({
  externalId,
  description,
  buildingExternalId,
  subBuilding,
  componentType,
  componentSeqNum,
  system,
  componentInformation,
  altComponentTag,
  deliveryDate,
  claimDate,
  endOfLifeDate,
  productCode,
  contractor,
  supplier,
  manufacturer,
  placement,
}: ComponentInfo & { externalId?: string }) {
  const result = await post(`assets/fom`, {
    external_id: externalId,
    description,
    building_id: buildingExternalId,
    sub_building: subBuilding,
    component_type: componentType,
    component_seq_num: componentSeqNum,
    system,
    component_information: componentInformation,
    alt_component_tag: altComponentTag,
    delivery_date: deliveryDate?.getTime(),
    claim_date: claimDate?.getTime(),
    end_of_life_date: endOfLifeDate?.getTime(),
    product_code: productCode,
    contractor,
    supplier,
    manufacturer,
    placement,
  });
  return resultToComponentInfo(result);
}

export async function reports(data: {
  report_type: "ventilation" | string;
  building_list: string[];
  period_start: number; // timestamp in UTC time
  period_end: number; // timestamp in UTC time
}): Promise<FileInfo[]> {
  try {
    const res = await post("reports", data);
    return res.data;
  } catch (e) {
    throw Error("Could not create report!", { cause: e });
  }
}

export const timeseriesIndex: SearchIndex<TimeseriesSearchable> = {
  search: async (query?: string | null, options?: SearchParams) => {
    const c = new MeiliSearch({
      host: API_URL,
      requestConfig: {
        ...(await getHeaders()),
      },
    });
    return c.index("timeseries").search<TimeseriesSearchable>(query, options);
  },
  getDocument: async (documentId: string | number) => {
    const c = new MeiliSearch({
      host: API_URL,
      requestConfig: {
        ...(await getHeaders()),
      },
    });
    return c.index("timeseries").getDocument(documentId);
  },
  searchForFacetValues: async (params: SearchForFacetValuesParams) => {
    const c = new MeiliSearch({
      host: API_URL,
      requestConfig: {
        ...(await getHeaders()),
      },
    });
    return c.index("timeseries").searchForFacetValues(params);
  },
};

export const timeseriesExternalIdsIndex: SearchIndex<TimeseriesExternalIdSearchable> =
  {
    search: async (query?: string | null, options?: SearchParams) => {
      const c = new MeiliSearch({
        host: API_URL,
        requestConfig: {
          ...(await getHeaders()),
        },
      });
      return c
        .index("timeseriesExternalIds")
        .search<TimeseriesExternalIdSearchable>(query, options);
    },
    searchForFacetValues: async (params: SearchForFacetValuesParams) => {
      const c = new MeiliSearch({
        host: API_URL,
        requestConfig: {
          ...(await getHeaders()),
        },
      });
      return c.index("timeseriesExternalIds").searchForFacetValues(params);
    },
  };

export const componentsIndex = {
  search: async (query?: string | null, options?: SearchParams) => {
    const c = new MeiliSearch({
      host: API_URL,
      requestConfig: {
        ...(await getHeaders()),
      },
    });
    return c.index("components").search<ComponentSearchable>(query, options);
  },
  getDocument: async (documentId: string | number) => {
    const c = new MeiliSearch({
      host: API_URL,
      requestConfig: {
        ...(await getHeaders()),
      },
    });
    return c.index("components").getDocument(documentId);
  },
  searchForFacetValues: async (params: SearchForFacetValuesParams) => {
    const c = new MeiliSearch({
      host: API_URL,
      requestConfig: {
        ...(await getHeaders()),
      },
    });
    return c.index("components").searchForFacetValues(params);
  },
};

export const assetsIndex = {
  search: async (query?: string | null, options?: SearchParams) => {
    const c = new MeiliSearch({
      host: API_URL,
      ...(await getHeaders()),
    });
    return c.index("assets").search(query, options);
  },
};

export const roomsIndex = {
  search: async (query?: string | null, options?: SearchParams) => {
    const c = new MeiliSearch({
      host: API_URL,
      requestConfig: {
        ...(await getHeaders()),
      },
    });
    return c.index("rooms").search(query, options);
  },
  getDocument: async (documentId: number): Promise<RoomInfoType> => {
    const c = new MeiliSearch({
      host: API_URL,
      requestConfig: {
        ...(await getHeaders()),
      },
    });
    return c.index("rooms").getDocument(documentId);
  },
};

export async function getAnomalyProperties(filterByBuildingId: number) {
  const response = await get<AnomalyProperty[]>(
    `anomaly_properties?filterByBuildingId=${filterByBuildingId}`,
  );

  return response.data;
}

export async function createOrUpdateCalculationFlow(flow: CalculationFlow) {
  const response = await post<CalculationFlow>("calculation_flows", flow);
  return parseCalculationFlowDates(response.data);
}

export async function deleteCalculationFlow(id: string) {
  return del(`calculation_flows/${encodeURIComponent(id)}`);
}

export async function getCalculationFlow(id: string) {
  const response = await get<CalculationFlow>(
    `calculation_flows/${encodeURIComponent(id)}`,
  );
  return parseCalculationFlowDates(response.data);
}

export async function getCalculationFlows(
  filterByBuildingId: number,
  filterByType: string,
) {
  const response = await get<CalculationFlow[]>(
    `calculation_flows?filterByBuildingId=${filterByBuildingId}&filterByType=${filterByType}`,
  );

  return response.data.map((calculationFlow) =>
    parseCalculationFlowDates(calculationFlow),
  );
}

export async function runCalculationFlow(
  id: string,
  timeSpan: TimeSpan,
  purge: boolean,
) {
  const params = [
    `start=${timeSpan[0]}`,
    `end=${timeSpan[1]}`,
    `purge=${purge ? 1 : 0}`,
  ].join("&");
  return get<CalculationFlowRunResults>(
    `calculation_flows/${encodeURIComponent(id)}/run?${params}`,
  );
}

export async function liveRunCalculationFlow(
  flow: SimplifiedCalculationFlow,
  period: number,
  dryrun = true,
) {
  const dateParse = new DateParser(["datapoints"], ["timestamp"]);
  const response = await post<CalculationFlowRunResults>(
    `calculation_flows/run?period=${period}&dryrun=${Number(dryrun)}`,
    flow,
  );
  response.data.timeseries = response.data.timeseries.map((r) =>
    dateParse.parseToDates(r),
  );
  response.data.dataframes = response.data.dataframes.map((r) =>
    dateParse.parseToDates(r),
  );
  return response;
}

type Status = { owner?: string } & Record<string, unknown>;
export async function status() {
  const response = await get(`login/status`);
  return Object.values(response.data)[0] as Status;
}

export type AuditLogSetPointChangeDetails = {
  type: "setpoint_change";
  value: number;
  unit?: string;
  priority: number;
};

export type AuditLogCalculationFlowActionDetails = {
  type: "calculation_flow";
  action: "create" | "update" | "delete" | "run";
  calculation_flow: string;
};

export type AuditLogDetails =
  | AuditLogSetPointChangeDetails
  | AuditLogCalculationFlowActionDetails;

export type AuditLog = {
  source: string;
  message: string;
  timestamp: number;
  user: string;
  details: AuditLogDetails;
};
export async function getAuditLog({ externalId }: { externalId: string }) {
  const auditLog = await get<AuditLog[]>(
    `timeseries/${encodeURIComponent(externalId)}/audit`,
  );
  return auditLog.data;
}

export async function getDatapointsForManualTimeseries(externalId: string) {
  return get<DoubleDatapoint[]>(`timeseries/${externalId}/dps`);
}

export async function changeDatapointsForManualTimeseries(
  externalId: string,
  datapoints: DoubleDatapoint[],
) {
  // CDF does not allow for removing data points by timestamp, so it's easier to first delete all data points, and then add data points
  await removeDatapointsFromManualTimeseries(
    externalId,
    0,
    dayjs().add(1000, "year").endOf("year").valueOf(),
  );
  if (datapoints.length > 0) {
    return addDatapointsToManualTimeseries(externalId, datapoints);
  }
}

export async function addDatapointsToManualTimeseries(
  externalId: string,
  datapoints: DoubleDatapoint[],
) {
  return post(`timeseries/manual/${externalId}/dps`, {
    datapoints: datapoints.map((datapoint) => ({
      ...datapoint,
      timestamp: datapoint.timestamp.valueOf(),
    })),
  });
}

export async function removeDatapointsFromManualTimeseries(
  externalId: string,
  start: number,
  end: number,
) {
  return del(`timeseries/manual/${externalId}/dps?start=${start}&end=${end}`);
}

export async function getManualTimeseries<T extends Meter | VirtualSetpoint>(
  externalId: string,
) {
  const { data: manualTimeseries } = await get<T>(
    `timeseries/manual/${encodeURIComponent(externalId)}`,
  );
  return manualTimeseries;
}

// If no "externalId" is provided, a manual timeseries will be created
// otherwise it will be updated

export async function createOrUpdateManualTimeseries(
  manualTimeseries: NewMeter,
): Promise<Meter>;
export async function createOrUpdateManualTimeseries(
  manualTimeseries: NewVirtualSetpoint,
): Promise<VirtualSetpoint>;
export async function createOrUpdateManualTimeseries(
  manualTimeseries: NewMeter | NewVirtualSetpoint,
): Promise<Meter | VirtualSetpoint> {
  const { data: newManualTimeseries } = await post<Meter | VirtualSetpoint>(
    "timeseries/manual",
    manualTimeseries,
  );
  return newManualTimeseries;
}

export async function deleteManualTimeseries(externalId: string) {
  return del(`timeseries/manual/${encodeURIComponent(externalId)}`);
}

export async function deleteUserAsset(externalId: string) {
  return del(`assets/user/${encodeURIComponent(externalId)}`);
}

export type BuildingArealCalculatedArea = {
  calculated_area?: number;
  calculated_corrected_area?: number;
  area?: number;
};

type BuildingAreal = {
  arealInSquareMeters: number;
};

export async function postBuildingSquareMeter(
  externalId: string,
  data: BuildingAreal,
) {
  return post(`physical_building/${externalId}/area`, {
    area: data.arealInSquareMeters,
  });
}

export async function postRemoveCorrectedValue(externalId: string) {
  // To remove the corrected value, send payload: {'area': 0.0} or empty payload: {}
  return post(`physical_building/${externalId}/area`, {});
}

export async function fetchBuildingsAreal(
  externalIds?: (string | undefined)[],
) {
  const response = await post(`physical_building/area`, {
    external_ids: externalIds,
  });
  return response?.data?.reduce(
    (
      acc: { [externalId: string]: BuildingArealCalculatedArea },
      curr: { [externalId: string]: BuildingArealCalculatedArea },
    ) => {
      const key = Object.keys(curr)[0];
      return { ...acc, [key]: curr[key] };
    },
    {},
  );
}

const notesBaseUrl = "notes";
export async function postNote({ note, create }: PostNoteParams) {
  const url = create ? notesBaseUrl : `${notesBaseUrl}/${note.id}`;
  const requestObj = {
    data_set_id: note.dataSetId,
    content: note.content,
    start_time: note.startTime,
    end_time: note.endTime,
    asset_ids: note.assetIds,
    level: note.level,
    source: note.source,
    sourceId: note.sourceId,
  };
  return post(url, requestObj);
}

export async function deleteNote(noteId: number, dataSetId: number) {
  const url = `${API_URL}${notesBaseUrl}/${noteId}`;
  return axios.delete(url, {
    ...(await getHeaders()),
    data: { data_set_id: dataSetId },
  });
}

const GROUPS_API = `${process.env.REACT_APP_SERVER_URL || ""}/api/v1/groups`;
const GROUPS_LIST_API = `${
  process.env.REACT_APP_SERVER_URL || ""
}/api/v1/groupsList/`;

const INCIDENTS_API = `${
  process.env.REACT_APP_SERVER_URL || ""
}/api/v1/incidents`;
const INCIDENTS_LIST_API = `${
  process.env.REACT_APP_SERVER_URL || ""
}/api/v1/incidentsList/`;

const ALERTS_CONFIGURATION_API = `${
  process.env.REACT_APP_SERVER_URL || ""
}/api/v1/alerts`;
const ALERTS_CONFIGURATION_LIST_API = `${
  process.env.REACT_APP_SERVER_URL || ""
}/api/v1/alertsList/`;

const ALARM_CONFIGURATION_API = `${
  process.env.REACT_APP_SERVER_URL || ""
}/api/v1/alarms`;

const ALARM_CONFIGURATION_LIST_API = `${
  process.env.REACT_APP_SERVER_URL || ""
}/api/v1/alarms/list`;

export async function createGroup(data: AlertGroup) {
  const result = await axios.post(`${GROUPS_API}/`, data, {
    ...(await getHeaders()),
  });
  return AlertGroupClientSide.parse(result.data);
}
export async function updateGroup(id: string, data: AlertGroup) {
  const result = await axios.put(`${GROUPS_API}/${id}`, data, {
    ...(await getHeaders()),
  });
  return AlertGroupClientSide.parse(result.data);
}
export async function getGroup(id: string) {
  const result = await axios.get(`${GROUPS_API}/${id}`, {
    ...(await getHeaders()),
  });
  return AlertGroupClientSide.parse(result.data);
}
export async function deleteGroup(id: string) {
  await axios.delete(`${GROUPS_API}/${id}`, {
    ...(await getHeaders()),
  });
}
export async function listGroup(data: ListSearchParams) {
  const results = await axios.post(GROUPS_LIST_API, data, {
    ...(await getHeaders()),
  });
  return AlertGroupSearchResult.parse(results.data);
}

const SCHEMA_API = `${process.env.REACT_APP_SERVER_URL || ""}/api/v1/schema`;

const SCHEMA_LIST_API = `${
  process.env.REACT_APP_SERVER_URL || ""
}/api/v1/schemaList`;

export async function createSchema(schema: SchemaTypes, data: SchemaType) {
  const result = await axios.post(`${SCHEMA_API}/${schema}/`, data, {
    ...(await getHeaders()),
  });
  return result.data as SchemaType;
}
export async function updateSchema(
  schema: SchemaTypes,
  id: string,
  data: Partial<SchemaType>,
) {
  const result = await axios.put(`${SCHEMA_API}/${schema}/${id}`, data, {
    ...(await getHeaders()),
  });
  return result.data as SchemaType;
}
export async function getSchema(schema: SchemaTypes, id: string) {
  const result = await axios.get(`${SCHEMA_API}/${schema}/${id}`, {
    ...(await getHeaders()),
  });
  return result.data as SchemaType;
}
export async function deleteSchema(schema: SchemaTypes, id: string) {
  await axios.delete(`${SCHEMA_API}/${schema}/${id}`, {
    ...(await getHeaders()),
  });
}
export type SchemaSearchResult = {
  items: SchemaType[];
  nextCursor?: string;
};

export async function listSchemas(
  schema: SchemaTypes,
  data: {
    buildingId: number;
    limit?: number;
    cursor?: string;
    fields?: string[];
  },
) {
  const results = await axios.post(
    `${SCHEMA_LIST_API}/`,
    { ...data, collection: schema },
    {
      ...(await getHeaders()),
    },
  );
  return results.data as SchemaSearchResult;
}

export async function listIncidents(data: ListSearchParams) {
  const results = await axios.post(INCIDENTS_LIST_API, data, {
    ...(await getHeaders()),
  });

  return IncidentSearchResult.parse(results.data);
}

export async function updateIncident(id: string, data: Incident) {
  const result = await axios.put(`${INCIDENTS_API}/${id}`, data, {
    ...(await getHeaders()),
  });
  return IncidentClientSide.parse(result.data);
}

export async function assignIncidentToUser(id: string, data: UserInfo) {
  const result = await axios.put(`${INCIDENTS_API}/${id}/assign`, data, {
    ...(await getHeaders()),
  });
  return IncidentClientSide.parse(result.data);
}

export async function getIncident(id: string) {
  const results = await axios.get(`${INCIDENTS_API}/${id}`, {
    ...(await getHeaders()),
  });

  return IncidentClientSide.parse(results.data);
}

export async function sendIncidentToNextPerson(id: string) {
  const results = await axios.get(`${INCIDENTS_API}/${id}/sendToNextPerson`, {
    ...(await getHeaders()),
  });

  return IncidentClientSide.parse(results.data);
}

export async function createAlertConfiguration(data: AlertRule) {
  const result = await axios.post(`${ALERTS_CONFIGURATION_API}/`, data, {
    ...(await getHeaders()),
  });
  return AlertRuleClientSide.parse(result.data);
}

export async function updateAlertConfiguration(id: string, data: AlertRule) {
  const result = await axios.put(`${ALERTS_CONFIGURATION_API}/${id}`, data, {
    ...(await getHeaders()),
  });
  return AlertRuleClientSide.parse(result.data);
}

export async function getAlertConfiguration(id: string) {
  const result = await axios.get(`${ALERTS_CONFIGURATION_API}/${id}`, {
    ...(await getHeaders()),
  });
  return AlertRuleClientSide.parse(result.data);
}

export async function deleteAlertConfiguration(id: string) {
  await axios.delete(`${ALERTS_CONFIGURATION_API}/${id}`, {
    ...(await getHeaders()),
  });
}

export async function getAlarmSubscribersFromAlertConfiguration(
  alarmId: GetAlarmSubscriptionFromAlertConfigurationParams["alarmId"],
) {
  if (alarmId === "new") {
    return [];
  }
  const result = await axios.get(
    `${ALERTS_CONFIGURATION_API}/getAlarmSubscribers/${alarmId}`,
    {
      ...(await getHeaders()),
    },
  );
  return GetAlarmSubscriptionFromAlertConfigurationResponse.parse(result.data);
}

export async function listAlertConfiguration(data: ListSearchParams) {
  const results = await axios.post(ALERTS_CONFIGURATION_LIST_API, data, {
    ...(await getHeaders()),
  });
  return AlertRuleSearchResult.parse(results.data);
}

export async function listAlarmConfiguration(data: ListAlarmSearchParams) {
  const results = await axios.post(ALARM_CONFIGURATION_LIST_API, data, {
    ...(await getHeaders()),
  });
  return results.data as AlarmRuleSearchResult;
}

export async function deleteAlarmConfiguration(data: { id: string }) {
  await axios.delete(`${ALARM_CONFIGURATION_API}/${data.id}`, {
    ...(await getHeaders()),
  });
}

export async function updateAlarmConfiguration(data: Partial<AlarmRule>) {
  const result = await axios.put(`${ALARM_CONFIGURATION_API}/`, data, {
    ...(await getHeaders()),
  });
  return result.data as AlarmRule;
}

export async function createAlarmConfiguration(data: AlarmRule) {
  const result = await axios.post(`${ALARM_CONFIGURATION_API}/`, data, {
    ...(await getHeaders()),
  });
  return result.data as AlarmRule;
}

export async function getAlarmConfiguration(data: { id: string }) {
  const result = await axios.get(`${ALARM_CONFIGURATION_API}/${data.id}`, {
    ...(await getHeaders()),
  });
  return result.data as AlarmRule;
}

export async function getAlarmsInAlertConfigurationStatistics(
  params: GetUnassignedAlarmsRequestParams,
) {
  const results = await axios.get(
    `${ALERTS_CONFIGURATION_API}/getAlarmsStatistics?building_external_id=${params.building_external_id}`,
    {
      ...(await getHeaders()),
    },
  );
  return GetUnassignedAlarmsResponse.parse(results.data);
}

export async function getUnresolvedIncidentsCountByAlarmId({
  id,
  building_external_id,
}: GetUnresolvedIncidentsCountParams & GetUnresolvedIncidentsCountQuery) {
  const results = await axios.get(
    `${ALERTS_CONFIGURATION_API}/${id}/getUnresolvedIncidentsCount?building_external_id=${building_external_id}`,
    {
      ...(await getHeaders()),
    },
  );

  return results.data;
}

export async function getGroupDependedData({
  id,
  building_external_id,
}: GetGroupDependedDataParams & GetGroupDependedDataQuery) {
  const results = await axios.get(
    `${GROUPS_API}/${id}/validateAlertsAndIncidents?building_external_id=${building_external_id}`,
    {
      ...(await getHeaders()),
    },
  );
  return GetGroupDependedDataResponse.parse(results.data);
}

export async function validateIncidentsInAlarms(
  params: ValidateIncidentChangesParams & ValidateIncidentChangesQuery,
) {
  const results = await axios.get(
    `${ALARM_CONFIGURATION_API}/${params.alarmId}/validateIncidentChanges?building_external_id=${params.building_external_id}`,
    {
      ...(await getHeaders()),
    },
  );

  return ValidateIncidentChangesResponse.parse(results.data);
}

export async function getSearchableIncidentValues(buildingId?: number) {
  const extraParams = buildingId ? `?buildingId=${buildingId}` : "";
  const results = await axios.get(
    `${INCIDENTS_API}/searchableValues${extraParams}`,
    {
      ...(await getHeaders()),
    },
  );

  return results.data as GetIncidentSearchableValuesResponse;
}
