import { MessageKey, useTranslations } from "@properate/translations";
import { ReactNode } from "react";

export type RichTextFormatType = "bold" | "italic" | string;
export type RichTextFormatters = Record<
  RichTextFormatType,
  (content: ReactNode) => ReactNode
>;

export type RichTextFormatProps = {
  message: MessageKey;
  disabledFormatters?: RichTextFormatType[];
  formatOverrides?: RichTextFormatters;
};

export function RichTextFormat({
  message,
  disabledFormatters,
  formatOverrides,
}: RichTextFormatProps) {
  const t = useTranslations();

  function skip(format: RichTextFormatType) {
    return (disabledFormatters || []).includes(format);
  }

  const defaultFormatters: RichTextFormatters = {
    bold: (content: ReactNode) =>
      skip("bold") ? content : <span className="font-bold">{content}</span>,
    italic: (content: ReactNode) =>
      skip("italic") ? content : <span className="italic">{content}</span>,
  };

  const formatters = {
    ...defaultFormatters,
    ...(formatOverrides || {}),
  };

  return t.rich(message, formatters);
}
