import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export function usePreviewChannel() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [previewChannel, setPreviewChannel] = useState("");

  useEffect(() => {
    const previewChannelFromSearchParams = searchParams.get("previewChannel");

    if (previewChannelFromSearchParams) {
      return setPreviewChannel(previewChannelFromSearchParams);
    }
    if (previewChannel) {
      searchParams.set("previewChannel", previewChannel);
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, previewChannel, setSearchParams]);
}
