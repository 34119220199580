import { CogniteClient, Timeseries } from "@cognite/sdk";
import { Col, Row, Select, Tree } from "antd";
import { useEffect, useState } from "react";
import { getSystemCodeFromExternalId } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useCogniteClient } from "@/context/CogniteClientContext";

import { getBuildingAssets } from "@/utils/helpers";
import { TreeContainer } from "./elements";
import { getTimeseriesForAssets } from "./utils";
import { Node } from "./types";

type OrgnizationInfo = {
  description: string;
  organization: string;
  value: number;
};

type Props = {
  selectedSources: number[];
  onSelectEnergySources: (selectedSources: number[]) => void;
  showExternalId: boolean;
};
export const SelectEnergySources = ({
  selectedSources,
  onSelectEnergySources,
  showExternalId,
}: Props) => {
  const t = useTranslations();

  const { client } = useCogniteClient();
  const building = useCurrentBuilding();

  const [energySources, setEnergySources] = useState<Node>();

  const [selectedOwner, setSelectedOwner] = useState<OrgnizationInfo>();

  const [organizations, setOrganizations] = useState<OrgnizationInfo[]>();

  useEffect(() => {
    const get = async (id: number) => {
      const organizations = await getBuildingAssets({
        client,
        id: Number(id),
        labels: ["oe_common_main_meter"],
      });
      const newOrganizations = organizations
        .sort((a, b) =>
          getSystemCodeFromExternalId(a.externalId!).localeCompare(
            getSystemCodeFromExternalId(b.externalId!),
          ),
        )
        .map((organization, index) => ({
          description: t("energy.owner-name", {
            name: organization.metadata?.organization || "",
            index,
          }),
          organization: organization.metadata!.organization,
          value: organization.id,
        }));
      setOrganizations(newOrganizations);
      setSelectedOwner(newOrganizations[0]);
    };
    get(building.id);
  }, [building.id, client, t]);

  useEffect(() => {
    const load = async (
      client: CogniteClient,
      selectedOwner: OrgnizationInfo,
    ) => {
      const rootAsset = (
        await client.assets.retrieve([{ id: selectedOwner.value }])
      )[0];

      const assets = await client.assets
        .list({
          filter: {
            assetSubtreeIds: [{ id: building.id }],
            metadata: { organization: rootAsset.metadata!.organization },
            labels: {
              containsAny: [
                "oe_common_main_meter",
                "oe_common_main_meter_by_energy_source",
              ].map((label) => ({ externalId: label })),
            },
          },
        })
        .autoPagingToArray({ limit: -1 });

      const [mainMeterAsset] = assets.filter(
        (asset) =>
          asset.labels?.find(
            (label) => label.externalId === "oe_common_main_meter",
          ),
      );
      const serialMeterAssets = assets.filter(
        (asset) =>
          asset.labels?.find(
            (label) =>
              label.externalId === "oe_common_main_meter_by_energy_source",
          ),
      );

      const timeseries = await getTimeseriesForAssets(
        client,
        (assets || []).map((asset) => asset.id),
      );

      const assetIdToTimeseries = timeseries.reduce<Record<number, Timeseries>>(
        (acc, ts) => ({ ...acc, [ts.assetId!]: ts }),
        {},
      );

      const root: Node = {
        node: {
          ...assetIdToTimeseries[mainMeterAsset.id],
          description:
            mainMeterAsset.metadata?.organization ||
            assetIdToTimeseries[mainMeterAsset.id].description,
        },
        children: serialMeterAssets.map((node) => ({
          node: assetIdToTimeseries[node.id]!,
        })),
      } as Node;

      setEnergySources(root);
    };

    if (client && selectedOwner) {
      load(client, selectedOwner);
    }
  }, [client, building.id, selectedOwner]);

  return (
    <Row>
      <Col span={24}>
        <h3>{selectedOwner?.description}</h3>
        {organizations && (
          <Select
            style={{ width: 300 }}
            value={selectedOwner?.value}
            options={organizations.map((organization) => ({
              label: organization.description,
              value: organization.value,
            }))}
            onChange={(value) => {
              setSelectedOwner(
                organizations.find((org) => org.value === value),
              );
            }}
          />
        )}
        <TreeContainer>
          <Tree
            checkable
            defaultExpandAll
            checkedKeys={selectedSources}
            onCheck={(checkedKeys) => {
              onSelectEnergySources(
                (checkedKeys as number[]).filter((key) => key > 0),
              );
            }}
            expandedKeys={
              energySources
                ? [
                    -energySources.node.id,
                    ...energySources.children.map((child) => -child.node.id!),
                  ]
                : []
            }
            switcherIcon={<></>}
            treeData={
              energySources && [
                {
                  title:
                    energySources.node.description +
                    (showExternalId ? ` ${energySources.node.externalId}` : ""),
                  key: -energySources.node.id,
                  children: energySources.children.map((child) => ({
                    title:
                      child.node.description +
                      (showExternalId ? ` ${child.node.externalId}` : ""),
                    key: child.node.id,
                  })),
                },
              ]
            }
          />
        </TreeContainer>
      </Col>
    </Row>
  );
};
