import { useSubmit } from "react-router-dom";
import { WithSnapshotId } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { useActionOrLoaderData } from "@/hooks/useActionOrLoaderData";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import {
  removeWidget,
  useUserSettingsForBuilding,
} from "@/services/userSettings";
import { Gauge, GaugeListHeader, GaugeListCard } from "@/features/gauges";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { GridList } from "./elements";

export function GaugeList() {
  const t = useTranslations();

  useBuildingPageTitle(t("analysis.gauge.title"));
  const gauges = useActionOrLoaderData<Array<WithSnapshotId<Gauge>>>();
  const { data: userSettingsForBuilding } = useUserSettingsForBuilding();
  const currentBuildingId = useCurrentBuildingId();
  const submit = useSubmit();

  function handleDelete(snapshotId: string) {
    const widgets = userSettingsForBuilding?.dashboard?.widgets;
    if (widgets) {
      removeWidget(currentBuildingId, widgets, snapshotId);
    }
    submit(
      {
        gaugesCurrent: JSON.stringify(gauges),
        snapshotId,
      },
      {
        method: "delete",
      },
    );
  }

  return (
    <>
      <GaugeListHeader />
      {gauges.length > 0 && (
        <GridList>
          {gauges.map((gauge) => (
            <li key={gauge.snapshotId}>
              <GaugeListCard
                gauge={{ ...gauge, viewMode: gauge.viewMode || "bar" }}
                onDelete={handleDelete}
              />
            </li>
          ))}
        </GridList>
      )}
    </>
  );
}
