import { Node, NodeResizer, useReactFlow } from "reactflow";
import { ChangeEvent } from "react";
import TextArea from "antd/es/input/TextArea";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";

interface Props {
  operationId: string;
  description: string;
}

export const getEmptyCommentNode = (): Node<Props> => {
  return {
    id: getNodeId("comment"),
    type: "comment",
    data: {
      operationId: "comment",
      description: "",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function CommentNode(params: { id: string; data: Props; selected: boolean }) {
  const reactFlowInstance = useReactFlow();

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "description",
      event.target.value,
    );
  };

  return (
    <>
      <NodeResizer isVisible={params.selected} minWidth={100} minHeight={50} />
      <TextArea
        value={params.data.description}
        onChange={handleDescriptionChange}
        style={{ backgroundColor: "#ad7b2a", height: "100%", resize: "none" }}
      />
    </>
  );
}

export default CommentNode;
