export function BellOffIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_24_25502)">
        <path
          d="M9.65366 14.5001C9.53645 14.7021 9.36822 14.8698 9.16581 14.9864C8.9634 15.103 8.73391 15.1644 8.50033 15.1644C8.26674 15.1644 8.03725 15.103 7.83484 14.9864C7.63243 14.8698 7.4642 14.7021 7.34699 14.5001M12.9203 9.16675C12.6237 8.08102 12.4823 6.95878 12.5003 5.83341C12.5014 5.10879 12.3056 4.39748 11.9339 3.77548C11.5621 3.15347 11.0284 2.64412 10.3897 2.30184C9.75105 1.95956 9.03137 1.79721 8.30759 1.83213C7.58381 1.86704 6.8831 2.09791 6.28033 2.50008M4.67366 4.67342C4.55784 5.04916 4.4994 5.44023 4.50033 5.83341C4.50033 10.5001 2.50033 11.8334 2.50033 11.8334H11.8337M1.16699 1.16675L15.8337 15.8334"
          stroke="#B3B3B3"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_24_25502">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
