import { useTranslations } from "@properate/translations";
import { Modal } from "antd";

type Props = {
  onClose: () => void;
};

export const AlertRuleUpdateModal = ({ onClose }: Props) => {
  const t = useTranslations();

  return (
    <Modal
      title={t("alert-rule.warnings.title")}
      open
      onOk={onClose}
      onCancel={onClose}
      footer={(_, { OkBtn }) => <OkBtn />}
    >
      {t("alert-rule.warnings.incidents-update")}
    </Modal>
  );
};
