import { useEffect, useMemo, useState } from "react";
import { CogniteClient, Relationship, Timeseries } from "@cognite/sdk";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Input, Space, TableColumnsType, Tooltip, App } from "antd";
import {
  ExclamationCircleOutlined,
  HistoryOutlined,
  ShareAltOutlined,
  StarFilled,
  StarOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  getSystemCodeFromExternalId,
  formatMeasurement,
  formatSubBuildingFromExternalId,
} from "@properate/common";
import { SorterResult } from "antd/lib/table/interface";
import { produce } from "immer";
import _ from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import {
  sortNumber,
  sortNumericString,
  sortString,
  ToggleSidebarButton,
} from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { convertUnit, parseError } from "@/pages/common/utils";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";

import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { TableNotesColumn } from "@/features/writable";
import { AuditLogModal } from "@/components/AuditLog";
import SystemFacets from "@/components/SystemFacets/SystemFacets";
import { DEFAULT_GLOBAL_THEME } from "../../theme/graph";
import { CompactContent } from "../../components/CompactContent";
import { useCogniteClient } from "../../context/CogniteClientContext";
import { getSetPointStatus } from "../../eepApi";
import {
  DEFAULT_MESSAGE_DURATION,
  getTimeseriesWithLabels,
} from "../../utils/helpers";
import {
  mutateUserSettings,
  useUserSettings,
} from "../../services/userSettings";
import { EditRelationshipModal } from "./EditRelationshipModal";
import { EditSetPointModal } from "./EditSetPointModal";
import { StatusType } from "./types";

const ESTIMATED_TABLE_HEADER_HEIGHT = 55;

const DIGIT_COLUMN_WIDTH = 90;
const SMALL_COLUMN_WIDTH = 48;

export function Writable() {
  const t = useTranslations();
  const { message } = App.useApp();
  useBuildingPageTitle(t("writable.title"));
  const building = useCurrentBuilding();
  const currentBuildingId = building.id;
  const { overrideUnits } = useTimeseriesSettings(currentBuildingId);
  const { client } = useCogniteClient();
  const [search, setSearch] = useState<string>();
  const [editStatus, setEditStatus] = useState<StatusType>();
  const [editRelationship, setEditRelationship] = useState<StatusType>();
  const [showHistory, setShowHistory] = useState<StatusType | null>(null);

  const [valueMap, setValueMap] = useState<Record<string, any>>();
  const [data, setData] = useState<StatusType[]>();
  const [isLoading, setIsLoading] = useState(false);

  const [showExternalId, setShowExternalId] = useState(false);
  useHotkeys("Control+Shift+x", () => setShowExternalId(!showExternalId), [
    showExternalId,
  ]);
  const [systemFilter, setSystemFilter] = useState<string | null>(null);

  const { data: preferences, isLoading: isLoadingPreferences } =
    useUserSettings();
  const sortBy = preferences?.writeable?.sort
    ? preferences.writeable.sort
    : { key: "system", order: "ascend" as const };

  function getRoomName(ts: Timeseries) {
    const roomNO = ts.metadata!.room_no ? ` (${ts.metadata!.room_no})` : "";
    const description = ts.description ? ts.description : "";

    return `${description}${roomNO} ${ts.name}`;
  }

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    const process = async (
      timeseries: Timeseries[],
      status: any,
    ): Promise<any> => {
      const rel = status
        ? (
            await Promise.all(
              _.chunk(Object.keys(status), 1000).map((chunk) =>
                client.relationships
                  .list({
                    filter: {
                      sourceExternalIds: chunk,
                      labels: {
                        containsAll: [{ externalId: "rel_setpt_realval_gen" }],
                      },
                      confidence: { min: 0, max: 1 },
                    },
                  })
                  .autoPagingToArray({ limit: -1 }),
              ),
            )
          ).flat()
        : [];

      const relationMap = rel.reduce<Record<string, Relationship[]>>(
        (prev, current) => {
          if (prev[current.sourceExternalId]) {
            prev[current.sourceExternalId].push(current);
          } else {
            prev[current.sourceExternalId] = [current];
          }
          return prev;
        },
        {},
      );

      return timeseries
        .filter((ts) => ts.externalId!.startsWith("TS_"))
        .map((ts) => {
          const setPointStatus = status[ts.externalId!];
          const relationships = relationMap[ts.externalId!] || [];

          relationships.sort(
            (a, b) => (b.confidence || 0) - (a.confidence || 0),
          );

          return {
            ...setPointStatus,
            externalId: ts.externalId!,
            unit: ts.unit,
            metadata: ts.metadata || {},
            name: getRoomName(ts),
            labels: [], //ts.labels,
            relationships,
            assetId: ts.assetId,
          };
        });
    };

    const get = async (client: CogniteClient) => {
      const timeseries = await getTimeseriesWithLabels(
        client,
        currentBuildingId,
        ["set_point", "writable"],
        ["schedule"],
      );

      if (timeseries.length === 0) {
        if (mounted) {
          setData([]);
        }
      } else {
        if (mounted) {
          setData(
            timeseries
              .filter((ts) => !ts.externalId!.startsWith("TR_"))
              .map((ts) => ({
                externalId: ts.externalId!,
                unit: ts.unit,
                metadata: ts.metadata || {},
                name: getRoomName(ts),
                labels: [], //ts.labels,
                relationships: [],
                "priority-array": [],
                assetId: ts.assetId,
              })),
          );
        }

        if (mounted) {
          try {
            const status = await getSetPointStatus({
              external_ids: timeseries.map((ts) => ts.externalId!),
            });
            const aa = await process(timeseries, status);
            setData(aa);
          } catch (error) {
            console.error(
              "An error occurred while fetching set point status for: " +
                timeseries.map((ts) => ts.externalId).join(),
              error,
            );
            const errorMessage = parseError(error);
            message.open({
              type: "error",
              content: t("writable.retrieve-status-failed", {
                errorMessage,
              }),
              duration: DEFAULT_MESSAGE_DURATION,
            });
          }
        }
      }
    };
    if (client) {
      get(client).finally(() => setIsLoading(false));
    }

    return () => {
      mounted = false;
    };
  }, [client, currentBuildingId, message]);

  useEffect(() => {
    let mounted = true;
    const getValues = async (statusData: StatusType[]) => {
      const valueToStatus = statusData.reduce(
        (prev: Record<string, any>, current: StatusType) => {
          if (current.relationships && current.relationships.length > 0) {
            const relation = current.relationships[0];

            if (prev[relation.targetExternalId]) {
              prev[relation.targetExternalId] = [
                ...prev[relation.targetExternalId],
                {
                  statusExternalId: current.externalId,
                  confidence: relation.confidence,
                },
              ];
              return prev;
            }
            return {
              ...prev,
              [relation.targetExternalId]: [
                {
                  statusExternalId: current.externalId,
                  confidence: relation.confidence,
                },
              ],
            };
          }
          return prev;
        },
        {},
      );

      const externalIds = Object.keys(valueToStatus);
      const batches = _.chunk(externalIds, 100);
      const values =
        (
          await Promise.all(
            batches.map(async (batch) => {
              return await client.datapoints.retrieveLatest(
                batch.map((key: string) => ({
                  externalId: key,
                })),
                { ignoreUnknownIds: true },
              );
            }),
          )
        ).flat() || [];

      if (mounted) {
        setValueMap(
          values.reduce(
            (prev, current) => {
              valueToStatus[current.externalId!].forEach((status: any) => {
                const overrideUnit =
                  status &&
                  overrideUnits &&
                  overrideUnits[status.statusExternalId!]?.unit;
                if (typeof overrideUnit === "string") {
                  const convertedValueToOverrideUnit = current.datapoints[0]
                    .value
                    ? convertUnit(
                        current.unit,
                        overrideUnit,
                        current.datapoints[0].value as number,
                      )
                    : {
                        value: current.datapoints[0].value,
                        unit: current.unit,
                      };
                  prev[status.statusExternalId] = {
                    ...current.datapoints[0],
                    value: convertedValueToOverrideUnit.value,
                    unit: convertedValueToOverrideUnit.unit,
                    confidence: status.confidence,
                  };
                  return prev;
                }
                prev[status.statusExternalId] = {
                  ...current.datapoints[0],
                  unit: current.unit,
                  confidence: status.confidence,
                };
              });
              return prev;
            },
            {} as Record<string, any>,
          ),
        );
      }
    };
    if (data) {
      getValues(data).finally();
    }

    return () => {
      mounted = false;
    };
  }, [overrideUnits, data, client]);
  function getBACNetReliabilityErrorCodeDescription(reliability: string) {
    // NOTE: Argument of type `writable.errorCodes.${string}` is not assignable to parameter of type MessageKeys<{ ui: {...},..., writable: {...}}>
    // MessageKeys expects from @reliability to be the exact word written into en/no.json files,
    // but it is a string type that will have one of the BACNetReliability error codes and this is the reason why i put ts-ignore
    // @ts-ignore
    return t(`writable.errorCodes.${reliability}`);
  }
  const columns: TableColumnsType<StatusType> = [
    {
      key: "fav",
      defaultSortOrder: sortBy.key === "fav" ? sortBy.order : undefined,
      width: SMALL_COLUMN_WIDTH,
      sorter: (
        { externalId: externalIdOne },
        { externalId: externalIdTwo },
      ) => {
        const oneHasPoints =
          preferences?.setPoints?.includes(externalIdOne) ?? false;
        const twoHasPoints =
          preferences?.setPoints?.includes(externalIdTwo) ?? false;
        if (oneHasPoints) {
          return twoHasPoints ? 0 : -1;
        }
        return twoHasPoints ? 1 : 0;
      },
      render: (_, { externalId }) =>
        preferences?.setPoints?.includes(externalId) === true ? (
          <StarFilled
            onClick={async () => {
              await mutateUserSettings({
                setPoints: [...(preferences?.setPoints || [])].filter(
                  (label) => label !== externalId,
                ),
              });
            }}
          />
        ) : (
          <StarOutlined
            onClick={async () => {
              await mutateUserSettings({
                setPoints: [...(preferences?.setPoints || []), externalId],
              });
            }}
          />
        ),
    },
    {
      title: t("writable.table-column-title.sub-building"),
      key: "building",
      width: 100,
      defaultSortOrder: sortBy.key === "building" ? sortBy.order : undefined,
      sorter: (
        { externalId: externalIdOne },
        { externalId: externalIdTwo },
      ) => {
        const subBuildingOne = formatSubBuildingFromExternalId(externalIdOne);
        const subBuildingTwo = formatSubBuildingFromExternalId(externalIdTwo);
        return sortString(subBuildingOne, subBuildingTwo);
      },
      render: (_, { externalId }) => (
        <ProperateHighlighter
          searchWords={search?.split(" ") || [""]}
          autoEscape
          textToHighlight={formatSubBuildingFromExternalId(externalId)}
        />
      ),
    },
    {
      title: t("writable.table-column-title.system"),
      key: "system",
      width: 100,
      defaultSortOrder: sortBy.key === "system" ? sortBy.order : undefined,
      sorter: (
        { externalId: externalIdOne },
        { externalId: externalIdTwo },
      ) => {
        const systemCodeOne = getSystemCodeFromExternalId(externalIdOne);
        const systemCodeTwo = getSystemCodeFromExternalId(externalIdTwo);
        return sortString(systemCodeOne, systemCodeTwo);
      },
      render: (_, { externalId }) => (
        <ProperateHighlighter
          searchWords={search?.split(" ") || [""]}
          autoEscape
          textToHighlight={getSystemCodeFromExternalId(externalId)}
        />
      ),
    },
    {
      title: t("writable.table-column-title.location"),
      key: "location",
      width: 120,
      defaultSortOrder: sortBy.key === "location" ? sortBy.order : undefined,
      sorter: (
        { metadata: { floor: floorOne = "", room_no: roomNoOne = "" } },
        { metadata: { floor: floorTwo = "", room_no: roomNoTwo = "" } },
      ) => {
        const floorComparison = sortString(floorOne, floorTwo);
        if (floorComparison !== 0) {
          return floorComparison;
        }
        return sortString(roomNoOne, roomNoTwo);
      },
      render: (_, { metadata: { room_no = "", floor = "" } }) => (
        <ProperateHighlighter
          searchWords={search?.split(" ") || [""]}
          autoEscape
          textToHighlight={`${floor} ${room_no}`}
        />
      ),
    },
    {
      title: t("writable.table-column-title.name"),
      key: "name",
      width: 300,
      defaultSortOrder: sortBy.key === "name" ? sortBy.order : undefined,
      sorter: ({ name: nameOne }, { name: nameTwo }) =>
        sortString(nameOne, nameTwo),
      render: (_, { name, externalId }) => {
        return showExternalId ? (
          externalId
        ) : (
          <ProperateHighlighter
            searchWords={search?.split(" ") || [""]}
            autoEscape
            textToHighlight={name}
          />
        );
      },
    },
    {
      title: t("writable.table-column-title.status"),
      key: "status",
      width: SMALL_COLUMN_WIDTH,
      defaultSortOrder: sortBy.key === "status" ? sortBy.order : undefined,
      sorter: (
        { "out-of-service": outOfServiceOne = false },
        { "out-of-service": outOfServiceTwo = false },
      ) => {
        if (!outOfServiceOne) {
          return !outOfServiceTwo ? 0 : 1;
        }
        return !outOfServiceTwo ? -1 : 0;
      },
      render: (_, { "out-of-service": outOfService = false }) => (
        <>
          {outOfService ? (
            <span style={{ color: DEFAULT_GLOBAL_THEME.color.RED }}>●</span>
          ) : (
            <span style={{ color: DEFAULT_GLOBAL_THEME.color.GREEN }}>●</span>
          )}
        </>
      ),
    },
    {
      title: t("writable.table-column-title.priority"),
      key: "priority",
      width: DIGIT_COLUMN_WIDTH,
      defaultSortOrder: sortBy.key === "priority" ? sortBy.order : undefined,
      sorter: (
        { "priority-array": priorityArrayOne },
        { "priority-array": priorityArrayTwo },
        sortOrder,
      ) => {
        const priorityOne = priorityArrayOne
          ?.sort(
            ({ index: indexOne }, { index: indexTwo }) => indexOne - indexTwo,
          )
          .find((x) => x !== undefined)?.index;
        const priorityTwo = priorityArrayTwo
          ?.sort(
            ({ index: indexOne }, { index: indexTwo }) => indexOne - indexTwo,
          )
          .find((x) => x !== undefined)?.index;
        if (priorityOne === undefined) {
          if (priorityTwo !== undefined) {
            return sortOrder === "ascend" ? 1 : -1;
          }
          return 0;
        }
        if (priorityTwo === undefined) {
          return sortOrder === "descend" ? 1 : -1;
        }
        return sortNumber(priorityOne, priorityTwo);
      },
      render: (_, { "priority-array": priorityArray }) => {
        if (!priorityArray) {
          return undefined;
        }
        if (priorityArray.length === 0) {
          return undefined;
        }

        const sortedPriorityArray = [...priorityArray].sort(
          ({ index: indexOne }, { index: indexTwo }) => indexOne - indexTwo,
        );

        return sortedPriorityArray[0].index;
      },
    },
    {
      title: t("writable.table-column-title.min"),
      key: "metadata.min_value",
      width: DIGIT_COLUMN_WIDTH,
      defaultSortOrder:
        sortBy.key === "metadata.min_value" ? sortBy.order : undefined,
      sorter: (
        { metadata: { min_value: minValueOne } },
        { metadata: { min_value: minValueTwo } },
        sortDirection,
      ) => {
        if (minValueOne === undefined) {
          if (minValueTwo !== undefined) {
            return sortDirection === "ascend" ? 1 : -1;
          }
          return 0;
        }
        if (minValueTwo === undefined) {
          return sortDirection === "descend" ? 1 : -1;
        }
        return sortNumericString(minValueOne, minValueTwo);
      },
      render: (_, { metadata: { min_value: minValue }, unit }) => (
        <>
          {minValue !== undefined && (
            <>
              {formatMeasurement({
                value: parseFloat(minValue),
                unit: unit,
              })}
            </>
          )}
        </>
      ),
    },
    {
      title: t("writable.table-column-title.max"),
      key: "metadata.max_value",
      width: DIGIT_COLUMN_WIDTH,
      defaultSortOrder:
        sortBy.key === "metadata.max_value" ? sortBy.order : undefined,
      sorter: (
        { metadata: { max_value: maxValueOne } },
        { metadata: { max_value: maxValueTwo } },
        sortDirection,
      ) => {
        if (maxValueOne === undefined) {
          if (maxValueTwo !== undefined) {
            return sortDirection === "ascend" ? 1 : -1;
          }
          return 0;
        }
        if (maxValueTwo === undefined) {
          return sortDirection === "descend" ? 1 : -1;
        }
        return sortNumericString(maxValueOne, maxValueTwo);
      },
      render: (_, { metadata: { max_value: maxValue }, unit }) => (
        <>
          {maxValue !== undefined && (
            <>
              {formatMeasurement({
                value: parseFloat(maxValue),
                unit: unit,
              })}
            </>
          )}
        </>
      ),
    },
    {
      title: t("writable.table-column-title.set-point-value"),
      key: "setPoint",
      width: DIGIT_COLUMN_WIDTH,
      defaultSortOrder: sortBy.key === "setPoint" ? sortBy.order : undefined,
      sorter: (
        { "present-value": presentValueOne },
        { "present-value": presentValueTwo },
      ) => sortNumber(presentValueOne, presentValueTwo),
      render: (_, { "present-value": presentValue, reliability, unit }) => (
        <>
          {presentValue !== undefined && (
            <>
              {typeof reliability === "string" &&
                reliability !== "no-fault-detected" && (
                  <Tooltip
                    title={getBACNetReliabilityErrorCodeDescription(
                      reliability,
                    )}
                  >
                    <WarningOutlined
                      color={DEFAULT_GLOBAL_THEME.color.ORANGE}
                    />
                  </Tooltip>
                )}
              {isNaN(presentValue)
                ? presentValue
                : formatMeasurement({
                    value: presentValue,
                    unit: unit,
                  })}
            </>
          )}
        </>
      ),
    },
    {
      title: t("writable.table-column-title.link"),
      key: "value",
      // A little extra width for the possible tooltip
      width: DIGIT_COLUMN_WIDTH + SMALL_COLUMN_WIDTH,
      defaultSortOrder: sortBy.key === "value" ? sortBy.order : undefined,
      sorter: (
        { externalId: externalIdOne },
        { externalId: externalIdTwo },
        sortOrder,
      ) => {
        if (valueMap) {
          const valueOne = valueMap[externalIdOne]?.value;
          const valueTwo = valueMap[externalIdTwo]?.value;
          if (valueOne === undefined) {
            if (valueTwo! == undefined) {
              return sortOrder === "ascend" ? 1 : -1;
            }
            return 0;
          }
          if (valueTwo === undefined) {
            return sortOrder === "descend" ? 1 : -1;
          }
          const isValueOneRelevant = valueMap[externalIdOne]?.confidence > 0.29;
          const isValueTwoRelevant = valueMap[externalIdTwo]?.confidence > 0.29;
          if (isValueOneRelevant) {
            return isValueTwoRelevant ? sortNumber(valueOne, valueTwo) : -1;
          }
          return isValueTwoRelevant ? 1 : 0;
        }
        return 0;
      },
      render: (_, { externalId }) =>
        valueMap &&
        typeof valueMap[externalId] === "object" && (
          <Space>
            {valueMap[externalId].confidence > 0.29 && (
              <>
                {valueMap[externalId].confidence < 0.6 && (
                  <Tooltip
                    title={t(
                      "writable.table-column-title.unconfirmed-connection",
                    )}
                  >
                    <ExclamationCircleOutlined />
                  </Tooltip>
                )}
                {formatMeasurement({
                  value: valueMap[externalId].value,
                  unit: valueMap[externalId].unit,
                })}
              </>
            )}
          </Space>
        ),
    },
    {
      key: "action",
      width: 160,
      render: (_, status) => (
        <Space direction="horizontal">
          <Button
            disabled={
              status.metadata.max_value === undefined ||
              status.metadata.min_value === undefined
            }
            onClick={() => {
              setEditStatus(status);
            }}
          >
            {t("writable.table-column-title.update")}
          </Button>
          <Button
            icon={<ShareAltOutlined />}
            onClick={() => {
              setEditRelationship(status);
            }}
          />
          <Button
            icon={<HistoryOutlined />}
            onClick={() => {
              setShowHistory(status);
            }}
          />
          {typeof status.assetId === "number" && (
            <TableNotesColumn id={status.assetId} />
          )}
        </Space>
      ),
    },
  ];

  const filteredData = useMemo(() => {
    const searchTerms = (search ?? "").toUpperCase().trim().split(" ");
    const systemFilterTerms = (systemFilter ?? "").split(" ");

    return (
      data?.slice().filter((value) => {
        const matchesSearch = searchTerms.every(
          (term) =>
            value.name.toUpperCase().includes(term) ||
            getSystemCodeFromExternalId(value.externalId).includes(term) ||
            formatSubBuildingFromExternalId(value.externalId).includes(term),
        );
        const matchesSystemFilter = systemFilterTerms.every((term) =>
          getSystemCodeFromExternalId(value.externalId).includes(term),
        );

        return matchesSearch && matchesSystemFilter;
      }) || []
    );
  }, [data, search, systemFilter]);

  async function updateSortOrder(sorterResult: SorterResult<StatusType>) {
    if (typeof sorterResult.columnKey === "string") {
      await mutateUserSettings({
        writeable: {
          sort: {
            key: sorterResult.columnKey,
            order:
              typeof sorterResult.order === "string"
                ? sorterResult.order
                : null,
          },
        },
      });
    }
  }
  const assetIds = filteredData
    ?.filter((ts) => typeof ts?.assetId === "number")
    .map(({ assetId }) => assetId) as number[];

  return (
    <>
      <PageHeader
        title={t("writable.title")}
        extra={
          <>
            <SystemFacets
              onChangeSystemFilter={setSystemFilter}
              translatedLabelsFilter={["Settpunkt", "skrivbar"]}
              subBuildingFilter={null}
            />
            <Input.Search
              allowClear
              style={{ width: 300 }}
              value={search}
              data-testid="search-for-set-points"
              onChange={(event) => setSearch(event.target.value)}
            />

            <ToggleSidebarButton
              hiddenWhenSidebarVisible
              sidebarHiddenContent={t("notes.show-notes-button")}
            />
          </>
        }
      />
      <CompactContent>
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <AutoSizer>
            {({ width, height }: Size) => {
              return (
                <SpinnerWithDelay
                  isLoading={isLoadingPreferences}
                  centerInParent
                >
                  <TableWithoutDefaultSort
                    virtual
                    pagination={false}
                    columns={columns}
                    dataSource={filteredData}
                    style={{ maxWidth: width, width }}
                    rowKey="externalId"
                    scroll={{
                      y: height - ESTIMATED_TABLE_HEADER_HEIGHT,
                      x: width,
                    }}
                    onChange={(_, __, sorterResult) =>
                      updateSortOrder(sorterResult as SorterResult<StatusType>)
                    }
                    loading={isLoading}
                  />
                </SpinnerWithDelay>
              );
            }}
          </AutoSizer>
        </div>
        {editStatus && (
          <EditSetPointModal
            editStatus={editStatus}
            hide={(newStatus?: StatusType) => {
              if (newStatus) {
                setData([
                  ...(data || []).filter(
                    (d) => d.externalId !== newStatus.externalId,
                  ),
                  newStatus,
                ]);
              }
              setEditStatus(undefined);
            }}
          />
        )}
        {editRelationship && (
          <EditRelationshipModal
            editStatus={editRelationship}
            hide={(newStatus?: StatusType) => {
              if (newStatus) {
                setData(
                  produce(data, (draft) => {
                    const index = data?.findIndex(
                      (status) => status.externalId === newStatus.externalId,
                    );
                    if (typeof index === "number") {
                      draft![index] = newStatus;
                    }
                  }),
                );
              }
              setEditRelationship(undefined);
            }}
          />
        )}
        {showHistory && (
          <AuditLogModal
            name={showHistory.name}
            externalId={showHistory.externalId}
            onHide={() => setShowHistory(null)}
          />
        )}
        {building.dataSetId && (
          <NotesSidebar
            noteSource={NoteSource.WEB_SET_POINTS}
            assetFilterMode={NotesAssetFilterMode.AssetList}
            idSet={new Set(assetIds)}
            buildings={[{ id: building.dataSetId, name: building.name }]}
          />
        )}
      </CompactContent>
    </>
  );
}
