import dayjs, { Dayjs } from "@properate/dayjs";
import { SumCount } from "@/features/energy";
import { getClosestDay, Granularity } from "@/utils/helpers";

export const getClosestGranularity = (
  start: Dayjs,
  compareToStart: Dayjs,
  granularity: Granularity,
) => {
  if (granularity === "h" || granularity === "d") {
    return getClosestDay(dayjs(start), compareToStart);
  }
  const before = compareToStart.startOf(granularity);
  const after = compareToStart.endOf(granularity);
  const diffBefore = compareToStart.diff(before);
  const diffAfter = after.diff(compareToStart);
  return diffBefore < diffAfter ? before : after;
};
export const calculateAverage = ({ sum, count }: SumCount) => {
  return sum / count;
};

export const getSumCount = (data: Record<string, number>[], key: string) =>
  data.reduce<SumCount>(
    (prev, current) => {
      if (current[key] === undefined) {
        return prev;
      }
      return {
        sum: prev.sum + current[key],
        count: prev.count + 1,
      };
    },
    { sum: 0, count: 0 },
  );

export const getSum = (data: Record<string, number>[], key: string) =>
  data.reduce<number>((prev, current) => {
    if (current[key] === undefined) {
      return prev;
    }
    return prev + current[key];
  }, 0);

export const getDiff = (a?: number, b?: number) => {
  if (a === undefined || b === undefined) {
    return undefined;
  }
  return a - b;
};
