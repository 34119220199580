import { Fragment } from "react";
import { TimeSpanObject } from "@properate/common";
import dayjs from "@properate/dayjs";
import { Row, Col, Button } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { useUser } from "@properate/auth";

interface Props {
  periods: TimeSpanObject[];
  onRemove: (value: TimeSpanObject) => unknown;
}

export function SchedulePaneInactivePeriods({ periods, onRemove }: Props) {
  const user = useUser();

  function formatPeriod({ start, end }: TimeSpanObject) {
    return `${dayjs(start).format("DD-MM-YYYY")} - ${dayjs(end).format(
      "DD-MM-YYYY",
    )}`;
  }

  return (
    <Row gutter={[0, 8]}>
      {periods.map((period, index) => (
        <Fragment key={index}>
          <Col span={22}>{formatPeriod(period)}</Col>
          <Col span={2}>
            <Button
              disabled={user.isViewer}
              size="small"
              shape="circle"
              icon={
                <MinusOutlined
                  onClick={() => {
                    onRemove(period);
                  }}
                />
              }
            />
          </Col>
        </Fragment>
      ))}
    </Row>
  );
}
