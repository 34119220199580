import { useMemo, MouseEvent, useState } from "react";
import { DoubleDatapoint } from "@cognite/sdk";
import { Group } from "@visx/group";
import { scaleTime, scaleLinear, scaleBand } from "@visx/scale";
import { TooltipWithBounds, useTooltip } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { Space } from "antd";
import { useTheme } from "styled-components";
import dayjs from "@properate/dayjs";
import { getDatapointClosestToDate } from "@/utils/datapoints";
import { SpotPriceGranularity, SpotPriceTimeSpan } from "@/utils/types";
import {
  fromSpotPriceTimeSpanToTimeSpanAbsolute,
  getDateFormatForGranularity,
  getPercentageOfDomainWithData,
} from "../utils";
import { SpotPriceGraphAxes } from "./SpotPriceGraphAxes";
import { SpotPriceGraphChart } from "./SpotPriceGraphChart";
import { SpotPriceMissingData } from "./SpotPriceMissingData";

const MARGINS = {
  vertical: 60,
  horizontal: 70,
};

interface Props {
  width: number;
  height: number;
  datapoints: DoubleDatapoint[];
  granularity: SpotPriceGranularity;
  currentDatapoint?: DoubleDatapoint;
  timeSpan: SpotPriceTimeSpan;
  hasMissingData: boolean;
}

export function SpotPriceGraph({
  width,
  height,
  datapoints,
  granularity,
  currentDatapoint,
  timeSpan,
  hasMissingData,
}: Props) {
  const graphWidth = width - MARGINS.horizontal;
  const graphHeight = height - MARGINS.vertical;
  const theme = useTheme();
  const { showTooltip, hideTooltip, tooltipLeft, tooltipTop, tooltipData } =
    useTooltip<{ timestampRange: string; value: string }>();
  const [activeDatapoint, setActiveDatapoint] =
    useState<DoubleDatapoint | null>(null);
  const datapointValues = useMemo(
    () => datapoints.map((datapoint) => datapoint.value),
    [datapoints],
  );
  const timeSpanAbsolute = useMemo(
    () => fromSpotPriceTimeSpanToTimeSpanAbsolute(timeSpan),
    [timeSpan],
  );
  const percentageOfDomainWithData = getPercentageOfDomainWithData(
    datapoints,
    timeSpan,
    granularity,
  );
  const timeScale = useMemo(
    () =>
      scaleTime({
        range: [0, graphWidth],
        domain: timeSpanAbsolute,
      }),
    [timeSpanAbsolute, graphWidth],
  );

  const valueScale = useMemo(
    () =>
      scaleLinear({
        range: [graphHeight, 0],
        domain: [
          Math.min(...datapointValues) - 1,
          Math.max(...datapointValues) + 1,
        ],
      }),
    [graphHeight, datapointValues],
  );
  const barScale = useMemo(
    () =>
      scaleBand({
        domain: datapoints.map((datapoint) => datapoint.timestamp.valueOf()),
        range: [0, graphWidth * percentageOfDomainWithData],
      }),
    [datapoints, percentageOfDomainWithData, graphWidth],
  );

  function handleMouseMove(event: MouseEvent<SVGRectElement>) {
    const point = localPoint(event);
    if (point) {
      const timestamp = timeScale.invert(point.x - MARGINS.horizontal / 2);
      const datapoint = getDatapointClosestToDate(
        datapoints,
        timestamp,
        granularity === "1h" ? 1000 * 60 * 60 : 1000 * 60 * 60 * 24,
      );
      if (datapoint) {
        const dateFormat = getDateFormatForGranularity(granularity);
        const timeStampRangeEnd = dayjs(datapoint.timestamp).add(
          Number(granularity.match(/^\d+/)![0]), // E.g. 1 or 24
          granularity.at(-1) as "h" | "d",
        );
        showTooltip({
          tooltipLeft: point.x,
          tooltipTop: point.y,
          tooltipData: {
            timestampRange: `${dayjs(datapoint.timestamp).format(
              dateFormat,
            )} - ${timeStampRangeEnd.format(dateFormat)}`,
            value: `${Math.round(datapoint.value)} øre/kWh`,
          },
        });
        return setActiveDatapoint(datapoint);
      }
      setActiveDatapoint(null);
      hideTooltip();
    }
  }

  function handleMouseLeave() {
    hideTooltip();
    setActiveDatapoint(null);
  }

  return width > 0 && height > 0 ? (
    <>
      <svg width={width} height={height}>
        <Group left={MARGINS.horizontal / 2} top={MARGINS.vertical / 2}>
          <SpotPriceGraphChart
            datapoints={datapoints}
            barScale={barScale}
            timeScale={timeScale}
            valueScale={valueScale}
            graphHeight={graphHeight}
            activeDatapoint={activeDatapoint}
            granularity={granularity}
          />
          <SpotPriceGraphAxes
            scaleX={timeScale}
            scaleY={valueScale}
            graphHeight={graphHeight}
            granularity={granularity}
          />
          {hasMissingData && (
            <SpotPriceMissingData
              x={timeScale(datapoints.at(-1)!.timestamp)}
              graphWidth={graphWidth}
              graphHeight={graphHeight}
            />
          )}
          <rect
            height={graphHeight}
            width={graphWidth}
            fill="transparent"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
          {currentDatapoint && (
            // First and last bar are half width, so we need to adjust the data point a little in these cases
            <circle
              cx={
                datapoints.at(-1) === currentDatapoint ||
                datapoints[0] === currentDatapoint
                  ? timeScale(currentDatapoint.timestamp) -
                    barScale.bandwidth() / 4
                  : timeScale(currentDatapoint.timestamp)
              }
              cy={valueScale(currentDatapoint.value)}
              r={5}
              stroke="white"
              fill={theme.primary}
            />
          )}
        </Group>
      </svg>
      {typeof tooltipLeft === "number" &&
        typeof tooltipTop === "number" &&
        typeof tooltipData === "object" && (
          <TooltipWithBounds left={tooltipLeft} top={tooltipTop}>
            <Space direction="vertical">
              <div>{tooltipData.timestampRange}</div>
              <div>{tooltipData.value}</div>
            </Space>
          </TooltipWithBounds>
        )}
    </>
  ) : null;
}
