import { ActionFunctionArgs } from "react-router-dom";
import { WithSnapshotId } from "@properate/common";
import { deleteAnalysis, updateAnalysis } from "@/services/analysis";
import { Analysis } from "@/features/analysis";
import { deleteGauge, updateGauge } from "@/services/gauge";
import { deleteHeatMap, updateHeatMap } from "@/services/heatMap";
import { Gauge } from "@/features/gauges";
import { HeatMap } from "@/features/heatmap";

async function actionUpdateDoc(
  formData: FormData,
  updateInFirestore: (
    snapshotId: string,
    doc: Record<string, unknown>,
  ) => Promise<void>,
) {
  const snapshotId = formData.get("snapshotId");
  const updatedDoc = formData.get("updatedDoc");
  const updatedDocParsed =
    typeof updatedDoc === "string"
      ? (JSON.parse(updatedDoc) as Record<string, unknown>)
      : null;
  if (typeof snapshotId === "string" && updatedDocParsed !== null) {
    await updateInFirestore(snapshotId, updatedDocParsed);
  }
  return updatedDocParsed;
}

async function actionDeleteDoc<T extends { snapshotId: string }>(
  formData: FormData,
  currentEntities: T[],
  deleteInFirestore: (snapshotId: string) => Promise<void>,
): Promise<T[]> {
  const snapshotId = formData.get("snapshotId");
  if (typeof snapshotId === "string") {
    await deleteInFirestore(snapshotId);
  }
  return currentEntities.filter(
    (currentEntity) => currentEntity.snapshotId !== snapshotId,
  );
}

export async function actionUpdateAnalysis({ request }: ActionFunctionArgs) {
  if (request.method === "POST") {
    return actionUpdateDoc(await request.formData(), updateAnalysis);
  }
  return null;
}

export async function actionUpdateGauge({ request }: ActionFunctionArgs) {
  if (request.method === "POST") {
    return actionUpdateDoc(await request.formData(), updateGauge);
  }
  return null;
}

export async function actionUpdateHeatMap({ request }: ActionFunctionArgs) {
  if (request.method === "POST") {
    return actionUpdateDoc(await request.formData(), updateHeatMap);
  }
  return null;
}

function getCurrentParsedEntities<T>(
  formData: FormData,
  currentName: string,
): Array<WithSnapshotId<T>> | null {
  const currentEntities = formData.get(currentName);
  return typeof currentEntities === "string"
    ? (JSON.parse(currentEntities) as Array<WithSnapshotId<T>>)
    : null;
}

export async function actionAnalyses({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const analysesCurrentParsed = getCurrentParsedEntities<Analysis>(
    formData,
    "analysesCurrent",
  );
  if (request.method === "DELETE" && analysesCurrentParsed) {
    return actionDeleteDoc(formData, analysesCurrentParsed, deleteAnalysis);
  }
  return null;
}

export async function actionGauges({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const gaugesCurrentParsed = getCurrentParsedEntities<Gauge>(
    formData,
    "gaugesCurrent",
  );
  if (gaugesCurrentParsed && request.method === "DELETE") {
    return actionDeleteDoc(formData, gaugesCurrentParsed, deleteGauge);
  }
  return null;
}

export async function actionHeatMaps({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const heatMapsCurrentParsed = getCurrentParsedEntities<HeatMap>(
    formData,
    "heatMapsCurrent",
  );
  if (heatMapsCurrentParsed && request.method === "DELETE") {
    return actionDeleteDoc(formData, heatMapsCurrentParsed, deleteHeatMap);
  }
  return null;
}
