import { QueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { useLoaderData } from "react-router-dom";
import { IncidentFilter, OrgIncidentsLoader } from "@/features/alarms";
import { listIncidents } from "@/eepApi";
import type { OrderBy } from "@properate/common";

export const getUrl = (
  search?: OrgIncidentsLoader["search"],
  sort?: { property: string; order: string },
  filters?: IncidentFilter[],
) => {
  const url = `/org/incidents`;
  const searchParams = new URLSearchParams();
  const hasSearch = search?.value && search?.fieldName;

  if (hasSearch) {
    searchParams.append(search.fieldName, search.value);
  }
  if (sort) {
    searchParams.append("sort", sort.property);
    searchParams.append("order", sort.order);
  }
  if (filters) {
    filters.forEach((filter) => {
      searchParams.append("filters", filter);
    });
  }
  const searchParamsString = searchParams.toString();
  return searchParamsString ? `${url}?${searchParamsString}` : url;
};

const PAGE_LIMIT = 50;
const queryClient = new QueryClient();

const getFilters = (filters: IncidentFilter[]) =>
  filters.reduce((acc, filter) => {
    if (filter === "all") {
      return {};
    }
    acc[filter] = true;
    return acc;
  }, {} as any);

const fetchIncidents = async (
  pageData: OrgIncidentsLoader,
  pageParam?: string,
) => {
  {
    const order_by = pageData.sort
      ? (`${pageData.sort
          ?.property}:${pageData.sort?.order.toUpperCase()}` as OrderBy)
      : undefined;

    const filters = getFilters(pageData.filters);

    if (pageData.search?.value && pageData.search?.fieldName) {
      filters[pageData.search.fieldName] = pageData.search.value;
    }

    try {
      const incidents = await listIncidents({
        search: "",
        limit: PAGE_LIMIT,
        filter: filters,
        order_by,
        cursor: pageParam,
      });

      return {
        items: incidents.items,
        nextCursor: incidents.next_cursor,
      };
    } catch (error) {
      console.error("error", error);
    }

    return {
      items: [],
      nextCursor: undefined,
    };
  }
};

const getQuery = (pageData: OrgIncidentsLoader) => ({
  queryKey: ["org-incidents", pageData.search, pageData.sort, pageData.filters],
  queryFn: ({ pageParam }: { pageParam?: string }) =>
    fetchIncidents(pageData, pageParam),
  initialPageParam: undefined,
  getNextPageParam: (lastPage?: { nextCursor: string | undefined }) => {
    return lastPage?.nextCursor;
  },
});

export const useIncidentsData = () => {
  const pageData = useLoaderData() as OrgIncidentsLoader;

  const query = getQuery(pageData);

  const { data, fetchNextPage, isFetchingNextPage, status } = useInfiniteQuery(
    query,
    queryClient,
  );

  const dataTable = data?.pages.flatMap((page) => page.items) || [];

  return {
    incidents: dataTable,
    isLoading: status === "pending" && !isFetchingNextPage,
    onLoadMore: fetchNextPage,
  };
};
