import { useEffect } from "react";
import { useNavigate, useSubmit } from "react-router-dom";
import { Step } from "react-joyride";
import { WithSnapshotId } from "@properate/common";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import {
  Analysis,
  AnalysisList,
  ScatterplotAnalysis,
  AnalysisListHeader,
} from "@/features/analysis";
import { CompactContent } from "@/components/CompactContent";
import { useHelp } from "@/context/HelpContext";
import { useOnboarding } from "@/utils/onboarding";
import { JoyrideWrapper } from "@/components/JoyrideWrapper/JoyrideWrapper";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { createAnalysis } from "@/services/analysis";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useActionOrLoaderData } from "@/hooks/useActionOrLoaderData";
import {
  removeWidget,
  useUserSettingsForBuilding,
} from "@/services/userSettings";
import { TranslationFunction } from "@/utils/types";

const getSteps = (t: TranslationFunction): Step[] => [
  {
    placement: "top",
    target: "[data-step=new-simple-analysis]",
    content: t("analysis.joyride.create-new"),
  },
];

export function PageAnalysisList() {
  const t = useTranslations();

  useBuildingPageTitle(t("analysis.title"));
  const navigate = useNavigate();
  const currentBuildingId = useCurrentBuildingId();
  const { email: userEmail } = useUser();
  const { data: userSettingsForBuilding } = useUserSettingsForBuilding();
  const analyses = useActionOrLoaderData<Array<WithSnapshotId<Analysis>>>();
  const submit = useSubmit();

  const { setHelp } = useHelp();
  const [onboardingSteps, setOnboardingStepCompleted] = useOnboarding(
    getSteps(t),
  );
  useEffect(() => {
    setHelp({
      title: t("analysis.title"),
      content: <p>{t("analysis.help")}</p>,
    });
  }, [setHelp, t]);

  async function createSimpleAnalysis() {
    const { id } = await createAnalysis({
      title: "",
      buildingId: currentBuildingId,
      owner: userEmail,
      settingsTimeseriesList: [],
      created: Date.now(),
      mergeUnits: true,
    });

    navigate(id);
  }

  async function createScatterplotAnalysis() {
    const analysis: ScatterplotAnalysis = {
      title: "",
      buildingId: currentBuildingId,
      owner: userEmail,
      settingsTimeseriesX: null,
      granularity: "1d",
      settingsTimeseriesList: [],
      created: Date.now(),
      mergeUnits: true,
    };
    const { id } = await createAnalysis(analysis);
    navigate(id);
  }

  function handleConfirmRemoval(snapshotId: string) {
    const widgets = userSettingsForBuilding?.dashboard?.widgets;
    if (widgets) {
      removeWidget(currentBuildingId, widgets, snapshotId);
    }
    submit(
      { analysesCurrent: JSON.stringify(analyses), snapshotId },
      { method: "delete" },
    );
  }

  return (
    <>
      <AnalysisListHeader
        onPressCreateSimple={createSimpleAnalysis}
        onPressCreateScatterplot={createScatterplotAnalysis}
      />
      <CompactContent>
        <AnalysisList
          analyses={analyses}
          onConfirmRemoval={handleConfirmRemoval}
        />
        {onboardingSteps.length > 0 && (
          <JoyrideWrapper
            content={onboardingSteps[0]}
            onClose={(target: string) => setOnboardingStepCompleted(target)}
          />
        )}
      </CompactContent>
    </>
  );
}
