import { create, all } from "mathjs";
import { isErrorWithMessage } from "./api";

const math = create(all);

export function validateMathExpressionForDatapoint(expression: string): {
  valid: boolean;
  error?: string;
} {
  try {
    math.evaluate(expression, { DP: 1 });
  } catch (error) {
    if (isErrorWithMessage(error)) {
      return {
        valid: false,
        error: error.message,
      };
    }
    if (typeof error === "string") {
      return {
        valid: false,
        error,
      };
    }
    console.error(error);
    return {
      valid: false,
    };
  }
  return {
    valid: true,
  };
}
