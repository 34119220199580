import { MessageKey, TranslationFunction } from "@/utils/types";
import { GaugeType, Since, GaugeAggregateType } from "../types";

export function getGaugeDescription(
  type: GaugeType,
  t: TranslationFunction,
  since?: Since,
) {
  if (type !== "latest" && typeof since === "string") {
    const { labelKey, amount, error } = formatSince(since);

    return t("analysis.gauge.type-over-time", {
      type: t(
        `analysis.gauge.details.aggregate-format.${formatAggregate(
          type,
        )}` as MessageKey,
      ),
      time: error
        ? since
        : t(`analysis.gauge.details.last-time.${labelKey}` as MessageKey, {
            amount,
          }),
    });
  }

  return t("analysis.gauge.last-datapoint");
}

function formatAggregate(aggregate: GaugeAggregateType) {
  if (aggregate === "average") {
    return "average";
  }
  if (aggregate === "min") {
    return "min-value";
  }
  if (aggregate === "max") {
    return "max-value";
  }
  return "sum";
}

export function formatSince(since?: Since) {
  if (typeof since === "undefined") {
    return { error: true };
  }
  const matches = /^(\d+)([m|h])/.exec(since);
  if (matches === null || matches.length < 3) {
    console.warn(`The since value ${since} could not be formatted`);
    return { error: true };
  }
  const amount = Number(matches[1]);
  const unit = matches[2] as "m" | "h";
  if (amount === 1) {
    return { labelKey: unit === "m" ? "last-minute" : "last-hour" };
  }
  return { labelKey: unit === "m" ? "last-minutes" : "last-hours", amount };
}
