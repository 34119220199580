import styled from "styled-components";

export const BuildingInfoContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const BuildingPic = styled.img`
  width: 100px;
  height: 75px;
  border-radius: 2px;
  object-fit: cover;
`;

export const BuildingOwner = styled.div`
  font-weight: bold;
`;
