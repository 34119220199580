import { Link } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";

export function GaugeListHeader() {
  const t = useTranslations();

  return (
    <PageHeader
      title={t("analysis.gauge.name")}
      extra={
        <Link to="new">
          <Button
            data-testid="new-gauge-graph"
            type="primary"
            icon={<PlusOutlined />}
          >
            {t("analysis.gauge.new")}
          </Button>
        </Link>
      }
    />
  );
}
