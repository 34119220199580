import { useUser } from "@properate/auth";
import { Node, useReactFlow } from "reactflow";
import { ExternalId } from "@cognite/sdk";
import { Divider, Form, Select } from "antd";
import { useTranslations } from "@properate/translations";
import { TimeseriesFilter } from "@/pages/calculationFlow/types";
import { FilterInput } from "@/pages/calculationFlow/nodes/components/timeSeriesInput/FilterInput";
import { getNodeId, updateReactFlowNodeDataPartial } from "./helpers/Utils";
import {
  Body,
  InputHeader,
  LargeNode,
  NodeOutput,
  NodeOutputLabel,
} from "./helpers/NodeComponents";
import MultiTimeSeriesSelector from "./components/MultiTimeSeriesSelector";
import TimeSeriesTable from "./components/TimeSeriesTable";
import ErrorHandlingFallbackInput from "./components/timeSeriesInput/ErrorHandlingFallback";
import ErrorHandlingMaxDataValidityInput from "./components/timeSeriesInput/ErrorHandlingMaxDataValidity";

type Aggregate = "mean" | "min" | "max" | "sum";

interface Props {
  operationId: string;
  externalIds: ExternalId[];
  durationBeforeFallback: number;
  fallbackValue: number;
  maxDataValidity: number;
  aggregate: Aggregate;
  filter: TimeseriesFilter;
  filterValue: number[];
}

const FormItem = Form.Item;

export const getEmptyMultiTimeSeriesInputNode = (): Node<Props> => {
  return {
    id: getNodeId("multiTimeseriesInput"),
    type: "multiTimeseriesInput",
    data: {
      operationId: "read_multi_timeseries",
      externalIds: [],
      durationBeforeFallback: -15 * 60 * 1000,
      fallbackValue: 0,
      maxDataValidity: 10 * 60 * 1000,
      aggregate: "mean",
      filter: "none",
      filterValue: [],
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function MultiTimeSeriesInputNode(params: { id: string; data: Props }) {
  const t = useTranslations();
  const reactFlowInstance = useReactFlow();
  const user = useUser();

  const handleFilterChange = (filter: TimeseriesFilter) => {
    const defaultValue = params.data.filterValue?.[0] || 0;
    const filterValue =
      filter === "between" ? [defaultValue, defaultValue] : [defaultValue];
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      filter,
      filterValue: filter === "none" ? [] : filterValue,
    });
  };

  const handleFilterValueChange = (filterValue: (number | null)[]) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      filterValue,
    });
  };

  const handleAggregateChange = (aggregate: Aggregate) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, { aggregate });
  };

  const handleMaxDataValidityChange = (maxDataValidity: number) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      maxDataValidity,
    });
  };

  const handleDurationBeforeFallbackChange = (
    durationBeforeFallback: number,
  ) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      durationBeforeFallback,
    });
  };

  const handleFallbackValueChange = (fallbackValue: number) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      fallbackValue,
    });
  };

  const handleExternalIdsChange = (externalIds: ExternalId[]) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      externalIds,
    });
  };

  return (
    <LargeNode>
      <InputHeader>
        {t("calculation-flow.node-types.read-several-timeseries")}
      </InputHeader>
      <Body>
        <Form
          layout="vertical"
          style={{ paddingLeft: "6px" }}
          disabled={user.isViewer}
        >
          <TimeSeriesTable
            externalIds={params.data.externalIds}
            onChange={handleExternalIdsChange}
          />
          <MultiTimeSeriesSelector
            externalIds={params.data.externalIds}
            onChange={handleExternalIdsChange}
          />
          <FormItem label={t("calculation-flow.node-types.filter")}>
            <div className="flex gap-2">
              <Select
                className="flex-1"
                showAction={["focus", "click"]}
                value={params.data.filter || "none"}
                onChange={handleFilterChange}
                options={[
                  {
                    value: "none",
                    label: t("calculation-flow.filter.none"),
                  },
                  {
                    value: "greater",
                    label: t("calculation-flow.filter.greater"),
                  },
                  {
                    value: "greaterEqual",
                    label: t("calculation-flow.filter.greaterEqual"),
                  },
                  {
                    value: "less",
                    label: t("calculation-flow.filter.less"),
                  },
                  {
                    value: "lessEqual",
                    label: t("calculation-flow.filter.lessEqual"),
                  },
                  {
                    value: "equal",
                    label: t("calculation-flow.filter.equal"),
                  },
                  {
                    value: "notEqual",
                    label: t("calculation-flow.filter.notEqual"),
                  },
                  {
                    value: "between",
                    label: t("calculation-flow.filter.between"),
                  },
                ]}
              />
              <FilterInput
                filterType={params.data.filter || "none"}
                value={params.data.filterValue || []}
                onChangeValue={handleFilterValueChange}
              />
            </div>
          </FormItem>
          <FormItem label={t("calculation-flow.node-types.aggregate")}>
            <Select
              showAction={["focus", "click"]}
              value={params.data.aggregate}
              onChange={handleAggregateChange}
              options={[
                {
                  value: "mean",
                  label: t("calculation-flow.aggregate-select.average"),
                },
                {
                  value: "max",
                  label: t("calculation-flow.aggregate-select.maximum"),
                },
                {
                  value: "min",
                  label: t("calculation-flow.aggregate-select.minimum"),
                },
                {
                  value: "sum",
                  label: t("calculation-flow.aggregate-select.sum"),
                },
                {
                  value: "count",
                  label: t("calculation-flow.aggregate-select.count"),
                },
              ]}
            />
          </FormItem>
          <Divider plain orientation="left">
            {t("calculation-flow.node-types.error-handling")}
          </Divider>
          <ErrorHandlingFallbackInput
            durationBeforeFallback={params.data.durationBeforeFallback}
            fallbackValue={params.data.fallbackValue}
            onDurationBeforeFallbackChange={handleDurationBeforeFallbackChange}
            onFallbackValueChange={handleFallbackValueChange}
          />
          <ErrorHandlingMaxDataValidityInput
            maxDataValidity={params.data.maxDataValidity}
            durationBeforeFallback={params.data.durationBeforeFallback}
            onMaxDataValidityChange={handleMaxDataValidityChange}
          />
        </Form>
        <NodeOutput>
          <NodeOutputLabel />
        </NodeOutput>
      </Body>
    </LargeNode>
  );
}

export default MultiTimeSeriesInputNode;
