import { Modal } from "antd";
import { Timeseries } from "@cognite/sdk";
import { TimeSpan } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { SettingsTimeseriesSimple } from "../../types";
import { ModalStatisticsBody } from "./ModalStatisticsBody";

interface Props {
  open: boolean;
  onCancel: () => void;
  initialTimeSpan: TimeSpan;
  timeseries?: Timeseries;
  settingsTimeseries?: SettingsTimeseriesSimple;
}

export function ModalStatistics({
  open,
  onCancel,
  initialTimeSpan,
  timeseries,
  settingsTimeseries,
}: Props) {
  const t = useTranslations();

  return (
    <Modal
      title={
        timeseries
          ? t("analysis.details.statistics-modal.title", {
              name: timeseries.name,
            })
          : null
      }
      open={open}
      onCancel={onCancel}
      destroyOnClose
      width={650}
      footer={null}
    >
      {timeseries && settingsTimeseries && (
        <ModalStatisticsBody
          timeseries={timeseries}
          settingsTimeseries={settingsTimeseries}
          initialTimeSpan={initialTimeSpan}
        />
      )}
    </Modal>
  );
}
