import {
  App,
  Button,
  Checkbox,
  Form,
  Modal,
  Radio,
  Select,
  Space,
  Tag,
} from "antd";
import { useState } from "react";
import { useTranslations } from "@properate/translations";
import { ContrastInput } from "@/components/ContrastInput";
import { NewUser, Owners, resetUserPassword, Role, User } from "@/eepApi";
import { useRootAssets } from "@/hooks/useRootAssets";
import { SearchInput } from "@/components/SearchIndex";
import {
  FilterBuildings,
  useBuildingsSearch,
} from "@/context/BuildingsSearchContext";

type Props = {
  email?: string;
  owner?: string;
  role?: Role;
  asset_external_ids?: string[];
  owners: Owners;
  isTechApp?: boolean;
  onSave: (user: User) => Promise<void>;
  onHide: () => void;
  idp: NewUser["idp"];
};
export const UserForm = ({
  email = "",
  owner = "ALL",
  role = "user",
  isTechApp = false,
  asset_external_ids = [],
  onSave,
  onHide,
  owners,
  idp,
}: Props) => {
  const t = useTranslations();
  const { message } = App.useApp();

  const [createUserForm] = Form.useForm();
  const rootAssets = useRootAssets();
  const [showAllBuildings, setShowAllBuildings] = useState(false);
  const [selectedBuildings, setSelectedBuildings] =
    useState<string[]>(asset_external_ids);
  const [selectedOwner, setSelectedOwner] = useState<string>(owner);
  const { search, setSearch } = useBuildingsSearch();
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const hasProperateUserLogin = (!idp || idp === "properate") && !!email;

  const searchTerms = search.trim().split(" ");
  const sortedRootAssetsList =
    rootAssets &&
    rootAssets.filter(
      (item) =>
        !searchTerms ||
        searchTerms.every(
          (term: string) =>
            (item.metadata!["Adresse"] || "")
              .toLowerCase()
              .includes(term.toLowerCase()) ||
            (item.metadata!["Poststed"] || "")
              .toLowerCase()
              .includes(term.toLowerCase()) ||
            (item.metadata!["owner"] || "")
              .toLowerCase()
              .includes(term.toLowerCase()),
        ),
    );

  const onResetPassword = async () => {
    await createUserForm.validateFields();

    setIsResettingPassword(true);
    const email = createUserForm.getFieldValue(["email"]);
    try {
      await resetUserPassword({ email });
      message.success(t("admin.user-form.password-was-reset"));
    } catch (error) {
      message.error({
        content: `${t("admin.user-form.password-reset-error")}: ${
          (error as any).response.data.message
        }`,
        duration: 7,
      });
    }
    setIsResettingPassword(false);
  };

  const handleOk = async () => {
    const values = await createUserForm.validateFields();

    await onSave({
      email: values.email,
      owner: values.owner,
      role: values.role,
      asset_external_ids: selectedBuildings,
      isTechApp: values.isTechApp,
      ...(values.localLogin ? { local_login: "enable" } : null),
    });

    createUserForm.resetFields();
  };

  const handleSelectBuilding = (checked: boolean, buildingId: string) => {
    if (checked) {
      setSelectedBuildings([...selectedBuildings, buildingId]);
    } else {
      setSelectedBuildings(selectedBuildings.filter((b) => b !== buildingId));
    }
  };

  return (
    <Modal
      title={
        email
          ? t("admin.user-form.edit-title")
          : t("admin.user-form.create-title")
      }
      open
      onOk={handleOk}
      onCancel={onHide}
      footer={[
        ...(hasProperateUserLogin
          ? [
              <Button
                loading={isResettingPassword}
                key="reset-password"
                onClick={onResetPassword}
              >
                {t("admin.user-form.reset-password")}
              </Button>,
            ]
          : []),
        <Button key="back" onClick={onHide}>
          {t("admin.user-form.cancel")}
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          {t("admin.user-form.ok")}
        </Button>,
      ]}
    >
      <Form
        name="create user"
        form={createUserForm}
        initialValues={{ email, owner, role, isTechApp }}
      >
        <Form.Item
          label={t("admin.user-form.email")}
          name="email"
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
        >
          <ContrastInput placeholder="name@company.com" />
        </Form.Item>

        {owners["ALL"] && !email && (
          <Form.Item name="localLogin" valuePropName="checked">
            <Checkbox>{t("admin.user-form.create-own-password")}</Checkbox>
          </Form.Item>
        )}

        <Form.Item
          label={t("admin.user-form.owner")}
          name="owner"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select showSearch value={selectedOwner} onChange={setSelectedOwner}>
            {Object.keys(owners)
              .sort()
              .map((owner) => (
                <Select.Option key={owner} value={owner}>
                  {owner}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <div className="space-y-2">
          {selectedBuildings.length === 0 ? (
            <Tag>{t("admin.user-form.all-buildings")}</Tag>
          ) : (
            selectedBuildings.map((building) => (
              <Tag key={building}>{building}</Tag>
            ))
          )}
          <Button
            type="default"
            size="small"
            onClick={() => setShowAllBuildings(true)}
            disabled={!selectedOwner}
          >
            {t("admin.user-form.select-building")}
          </Button>
          <Form.Item label={t("admin.user-form.role")} name="role">
            <Radio.Group size="small">
              <Radio.Button value="admin">
                {t("admin.user-form.roles.admin")}
              </Radio.Button>
              <Radio.Button value="user">
                {t("admin.user-form.roles.user")}
              </Radio.Button>
              <Radio.Button value="viewer">
                {t("admin.user-form.roles.viewer")}
              </Radio.Button>
              <Radio.Button value="no_access">
                {t("admin.user-form.roles.service-desk")}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            shouldUpdate={(
              prevValues: { isTechApp: boolean; role: Role },
              currentValues,
            ) =>
              prevValues.isTechApp !== currentValues.isTechApp ||
              prevValues.role !== currentValues.role
            }
          >
            {({ getFieldValue }) =>
              (getFieldValue("role") as Role) === "no_access" ? null : (
                <Form.Item name="isTechApp" valuePropName="checked">
                  <Checkbox>{t("admin.user-form.roles.tech-app")}</Checkbox>
                </Form.Item>
              )
            }
          </Form.Item>
        </div>
      </Form>
      <Modal
        title={t("admin.user-form.select-building")}
        open={showAllBuildings}
        onOk={() => {
          setShowAllBuildings(false);
        }}
        onCancel={() => {
          setShowAllBuildings(false);
        }}
      >
        <SearchInput
          key={"search"}
          allowClear
          style={{ width: 300, marginBottom: 10 }}
          value={search}
          onChange={(event) =>
            setSearch({
              type: FilterBuildings.filter_buildings,
              payload: event.target.value,
            })
          }
        />
        <div style={{ maxHeight: "calc(100vh - 300px)", overflow: "auto" }}>
          <Space direction="vertical">
            <Checkbox
              key="all"
              checked={selectedBuildings.length === 0}
              onChange={(event) => {
                if (event.target.checked) {
                  setSelectedBuildings([]);
                }
              }}
            >
              {t("admin.user-form.all-buildings")}
            </Checkbox>
            {sortedRootAssetsList
              .filter(
                (asset) => owners[selectedOwner]?.includes(asset.externalId!),
              )
              .map((building) => {
                return (
                  <Checkbox
                    key={building.id}
                    checked={selectedBuildings.includes(building.externalId!)}
                    onChange={(e) =>
                      handleSelectBuilding(
                        e.target.checked,
                        building.externalId!,
                      )
                    }
                  >
                    {`${building.metadata!["Adresse"]}, ${
                      building.metadata!["Poststed"]
                    } - ${building.metadata!["owner"] || ""}`}
                  </Checkbox>
                );
              })}
          </Space>
        </div>
      </Modal>
    </Modal>
  );
};
