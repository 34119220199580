import { useEffect, useState } from "react";
import { AlarmRule } from "@properate/common";
import { getAlarmConfiguration } from "@/eepApi";
import { NEW_ALARM_URL_FRAGMENT } from "../hooks/createNewAlarmHooks";

export function useAlarmRule(alarmId?: string) {
  const [alarmRule, setAlarmRule] = useState<AlarmRule | undefined>();
  const [error, setError] = useState<Error | undefined | unknown>();

  useEffect(() => {
    if (!alarmId || alarmId === NEW_ALARM_URL_FRAGMENT) {
      return;
    }
    getAlarmConfiguration({ id: alarmId })
      .then((alarm) => {
        setAlarmRule(alarm);
      })
      .catch((e) => {
        console.error(
          `Failed to load alarm with id "${alarmId}" from api, got error: `,
          e,
        );
        setError(e);
      });
  }, [alarmId]);

  return { alarmRule, error };
}
