import { useRef, useState } from "react";
import { ColorResult } from "react-color";
import { createPortal } from "react-dom";
import { useTranslations } from "@properate/translations";
import { COLOR_PALETTE } from "@/utils/ProperateColors";
import {
  ColorPickerCover,
  ColorPickerHandle,
  ColorPickerStyled,
} from "./elements";

interface Props {
  value: string;
  onChange: (value: string) => unknown;
  disabled?: boolean;
  handleSize?: number;
}

const presetColorCodes = COLOR_PALETTE.map(({ code }) => code.toLowerCase());

// presetColorCodes are lowercased. color.hex is also lowercase
function isPresetColor(color: ColorResult) {
  return presetColorCodes.includes(color.hex);
}

export function ColorPicker({
  value,
  onChange,
  handleSize = 25,
  disabled = false,
}: Props) {
  const t = useTranslations();

  const [pickerOpen, setPickerOpen] = useState(false);
  const colorPickerHandleRef = useRef<HTMLButtonElement>(null);
  const colorPicker = createPortal(
    colorPickerHandleRef.current ? (
      <>
        <ColorPickerCover onClick={handleClickCover} />
        <ColorPickerStyled
          color={value}
          onChangeComplete={handleChangeComplete}
          presetColors={presetColorCodes}
          $positionHandle={colorPickerHandleRef.current.getBoundingClientRect()}
        />
      </>
    ) : null,
    document.body,
  );

  function handleClick() {
    if (!disabled) {
      setPickerOpen((pickerOpenCurrent) => !pickerOpenCurrent);
    }
  }

  function handleChangeComplete(color: ColorResult) {
    onChange(color.hex);
    if (isPresetColor(color)) {
      setPickerOpen(false);
    }
  }

  function handleClickCover() {
    setPickerOpen(false);
  }

  return (
    <>
      <ColorPickerHandle
        ref={colorPickerHandleRef}
        $size={handleSize}
        $color={value}
        $disabled={disabled}
        onClick={handleClick}
        type="button"
        aria-label={t("common.select-color")}
      />
      {pickerOpen && colorPicker}
    </>
  );
}
