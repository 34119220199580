import { Space, Tag } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { UserSettings } from "@/utils/types";
import { filters } from "./hooks/useTenantsSieve";

type Props = {
  value: UserSettings["filters"]["tenantsSieve"];
  onChange: (value: UserSettings["filters"]["tenantsSieve"]) => void;
};

export function BuildingTenantsTableFilters(props: Props) {
  const t = useTranslations();

  return (
    <Space key="filter" size={[0, 8]} wrap>
      <Tag.CheckableTag
        checked={props.value === "tenant-history"}
        onChange={() => props.onChange("tenant-history")}
      >
        {t("settings.tenants.tenant-history")}
      </Tag.CheckableTag>
      {filters.map(({ label }) => (
        <Tag.CheckableTag
          key={label}
          checked={label === props.value}
          onChange={() => props.onChange(label)}
        >
          {t(`settings.tenants.${label}` as MessageKey)}
        </Tag.CheckableTag>
      ))}
    </Space>
  );
}
