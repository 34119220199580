import { Alarm } from "@properate/common";
import { Button, List } from "antd";
import dayjs, { Dayjs } from "@properate/dayjs";
import { MessageOutlined } from "@ant-design/icons";
import { SidebarActionType, useSidebarActions } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useGetAlarmEvents } from "../../hooks/useGetAlarmEvents";
import { getFormatedStartDate, getRangeAsString } from "../../utils";
import { useGetAssetsFromTimeseriesIds } from "../../../../hooks/useGetAssetsFromTimeseriesIds";
import { NotesSidebarViewState } from "../../../notes/types";
import { getNoteLevelForAlarmSeverity } from "../../../notes";

type Props = {
  alarm: Alarm & { snapshotId?: string };
  showCreateNoteButton?: boolean;
  hideParentModal?: Function;
};

type CreateNoteProps = Pick<Props, "alarm" | "hideParentModal"> & {
  startDate: Dayjs;
  endDate: Dayjs | null;
};
function CreateNoteButton({
  alarm,
  hideParentModal,
  startDate,
  endDate,
}: Readonly<CreateNoteProps>) {
  const sidebarDispatch = useSidebarActions();
  const { assets, isLoading, error } = useGetAssetsFromTimeseriesIds({
    timeseriesIds: alarm?.timeseries?.ids ?? [],
  });

  function openNoteForm() {
    if (isLoading || error) {
      return;
    }

    sidebarDispatch({
      type: SidebarActionType.mergeData,
      data: {
        viewState: NotesSidebarViewState.create,
        note: {
          assetIds: assets?.map(({ id }) => id) ?? [],
          startTime: startDate.valueOf(),
          endTime: endDate?.valueOf() ?? undefined,
          level: getNoteLevelForAlarmSeverity(alarm.severity),
        },
      },
    });
    sidebarDispatch({
      type: SidebarActionType.open,
    });
    hideParentModal?.();
  }

  return (
    <Button
      icon={<MessageOutlined />}
      onClick={openNoteForm}
      loading={isLoading}
      disabled={error}
    />
  );
}

export function AlarmEventsList({
  alarm,
  showCreateNoteButton,
  hideParentModal,
}: Readonly<Props>) {
  const t = useTranslations();
  const events = useGetAlarmEvents(alarm.snapshotId);

  const sortedEvents = events.data
    ? [...events.data].sort((e1, e2) => e1.date.getTime() - e2.date.getTime())
    : [];

  const list: { value?: string; date: Date[] }[] = [];

  for (let i = 0; i < sortedEvents.length; i++) {
    const alarmEvent = sortedEvents[i];

    if (alarmEvent.state === "ALARM") {
      const next = sortedEvents[i + 1];

      list.push({
        value: alarmEvent.value,
        date: [alarmEvent.date, next?.date],
      });
    }
  }

  list.reverse();

  return (
    <List
      loading={events.isLoading}
      dataSource={list}
      renderItem={(item) => {
        const startDate = dayjs(item.date[0]);
        const endDate = item.date[1] ? dayjs(item.date[1]) : null;
        const range = endDate
          ? getRangeAsString(startDate, endDate)
          : t("common.timeseries-graph-modal.deviation.ongoing-event", {
              start: getFormatedStartDate(startDate),
            });

        const duration = endDate
          ? dayjs.duration(endDate.diff(startDate)).humanize()
          : t("common.timeseries-graph-modal.deviation.ongoing");

        return (
          <List.Item style={{ justifyContent: "space-between", gap: "1rem" }}>
            {item.value && <div>{item.value}</div>}
            <div
              title={t("common.timeseries-graph-modal.deviation.duration", {
                duration,
              })}
              className={showCreateNoteButton ? "flex gap-2 items-center" : ""}
            >
              {range}
              {showCreateNoteButton && (
                <CreateNoteButton
                  alarm={alarm}
                  hideParentModal={hideParentModal}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            </div>
          </List.Item>
        );
      }}
    />
  );
}
