import { Select } from "antd";
import { StarOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useRootAssets } from "@/hooks/useRootAssets";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { FormItem } from "./elements";

interface Props {
  value: number;
  onChange: (value: number) => unknown;
}

export function TimeseriesSelectionBuildingFilter({ onChange, value }: Props) {
  const t = useTranslations();

  const currentBuildingId = useCurrentBuildingId();
  const rootAssets = useRootAssets();

  const buildingOptions = rootAssets
    .map((building) => {
      const isCurrentBuilding = building.id === currentBuildingId;
      return {
        label: (
          <span>
            {building.name} {isCurrentBuilding && <StarOutlined />}
          </span>
        ),
        value: building.id,
        labelToFilterOn: building.name,
        isCurrentBuilding,
      };
    })
    .sort((buildingOne, buildingTwo) => {
      if (buildingOne.isCurrentBuilding) {
        return -1;
      }
      if (buildingTwo.isCurrentBuilding) {
        return 1;
      }
      return buildingOne.labelToFilterOn.localeCompare(
        buildingTwo.labelToFilterOn,
      );
    });

  return (
    <FormItem>
      <label htmlFor="timeseries-building">
        {t("common.timeseries-modal.main-building")}
      </label>
      <Select
        id="timeseries-building"
        showSearch
        value={value}
        options={buildingOptions}
        onChange={onChange}
        optionFilterProp="labelToFilterOn"
        style={{
          minWidth: 200,
        }}
      />
    </FormItem>
  );
}
