import { TimeseriesExternalIdSearchable } from "@properate/api/src/types";
import useSWRInfinite, { SWRInfiniteResponse } from "swr/infinite";
import { buildFilterQuery, Filter } from "@/pages/Timeseries/utils";
import { timeseriesExternalIdsIndex } from "@/eepApi";
import { useHandleApiError } from "@/utils/api";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

export interface SortBy {
  key: string;
  order: "asc" | "desc";
}

const PAGE_SIZE = 100;

type FacetDistribution = {
  translatedLabels: Record<string, number>;
  system: Record<string, number>;
  subBuilding: Record<string, number>;
} | null;

type ReturnType = Pick<
  SWRInfiniteResponse,
  "size" | "setSize" | "isLoading" | "mutate"
> & {
  searchableTimeseriesList: TimeseriesExternalIdSearchable[];
  facetDistribution: FacetDistribution;
};

export function useSearchTimeseriesList(
  search: string,
  filter: Filter,
  sortBy: SortBy | null,
): ReturnType {
  const handleAPIError = useHandleApiError();
  const currentBuildingId = useCurrentBuildingId();
  const { data, error, isLoading, size, setSize, mutate } = useSWRInfinite(
    (index) => ({
      search,
      offset: index * PAGE_SIZE,
      filter,
      sortBy,
    }),
    async ({ search, offset, filter, sortBy }) => {
      const { facetHits: systemHits } =
        await timeseriesExternalIdsIndex.searchForFacetValues({
          facetName: "system",
          filter: buildFilterQuery(filter, currentBuildingId),
        });
      const { facetHits: translatedLabelsHits } =
        await timeseriesExternalIdsIndex.searchForFacetValues({
          facetName: "translatedLabels",
          filter: buildFilterQuery(filter, currentBuildingId),
        });
      const { facetHits: subBuildingHits } =
        await timeseriesExternalIdsIndex.searchForFacetValues({
          facetName: "subBuilding",
          filter: buildFilterQuery(filter, currentBuildingId),
        });
      const { hits: searchableTimeseriesList } =
        await timeseriesExternalIdsIndex.search(search, {
          offset,
          sort: sortBy ? [`${sortBy.key}:${sortBy.order}`] : undefined,
          filter: buildFilterQuery(filter, currentBuildingId),
          attributesToHighlight: ["*"],
          limit: PAGE_SIZE,
        });
      return {
        searchableTimeseriesList,
        facetDistribution: {
          translatedLabels: translatedLabelsHits.reduce(
            (p, c) => ({ ...p, [c.value]: c.count }),
            {},
          ),
          subBuilding: subBuildingHits.reduce(
            (p, c) => ({ ...p, [c.value]: c.count }),
            {},
          ),
          system: systemHits.reduce(
            (p, c) => ({ ...p, [c.value]: c.count }),
            {},
          ),
        },
      };
    },
    {
      keepPreviousData: true,
    },
  );

  if (error) {
    handleAPIError(error);
  }

  return {
    searchableTimeseriesList: data
      ? data.flatMap(({ searchableTimeseriesList }) => searchableTimeseriesList)
      : [],
    facetDistribution: data
      ? (data[0].facetDistribution as FacetDistribution)
      : null,
    isLoading,
    size,
    setSize,
    mutate,
  };
}
