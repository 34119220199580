import dayjs from "@properate/dayjs";
import { useTheme } from "styled-components";
import { ScaleBand } from "d3-scale";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { ScaleLinearNumeric } from "@/utils/types";
import { formatCompact } from "@/utils/format";

interface Props {
  offsetLeft: number;
  offsetTop: number;
  graphHeight: number;
  graphWidth: number;
  scaleX: ScaleBand<number>;
  scaleY: ScaleLinearNumeric;
  unit: string;
}

export function BarChartAxes({
  offsetLeft,
  offsetTop,
  graphHeight,
  graphWidth,
  scaleX,
  scaleY,
  unit,
}: Props) {
  const theme = useTheme();
  return (
    <>
      <AxisLeft
        top={offsetTop}
        left={offsetLeft}
        scale={scaleY}
        label={unit}
        tickStroke={theme.neutral4}
        stroke={theme.neutral4}
        hideAxisLine
        hideTicks
        tickLabelProps={{
          fill: theme.neutral4,
        }}
        numTicks={5}
        tickFormat={formatCompact}
      />
      <GridRows
        numTicks={5}
        scale={scaleY}
        width={graphWidth}
        top={offsetTop}
        left={offsetLeft}
        fill={theme.neutral7}
      />
      <AxisBottom
        left={offsetLeft}
        scale={scaleX}
        top={graphHeight + offsetTop}
        stroke={theme.neutral4}
        tickStroke={theme.neutral4}
        tickLabelProps={{
          fill: theme.neutral4,
          scaleToFit: true,
        }}
        numTicks={5}
        tickFormat={(timestamp) => dayjs(timestamp.valueOf()).format("D. MMM")}
      />
    </>
  );
}
