import { useState, useEffect, useMemo } from "react";
import { App } from "antd";

import { useTranslations } from "@properate/translations";

import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { CompactContent } from "@/components/CompactContent";
import GraphModal from "@/pages/common/SchemaGraph";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";

import { GraphModalData } from "@/pages/calculationFlow/types";

import { isErrorWithMessage } from "@/utils/api";
import { getAnomalyProperties } from "../../eepApi";
import { AnomalyPerSystemTable } from "./components/AnomalyPerSystemTable";
import { AnomalySystemCollection } from "./types";

interface IProps {
  query: string;
  anomaliesCountPeriod: number;
}

export function AnomaliesForCurrentBuildingPage({
  query,
  anomaliesCountPeriod,
}: IProps) {
  const { notification } = App.useApp();
  const t = useTranslations();

  const building = useCurrentBuilding();
  const currentBuildingId = building.id;
  const { client } = useCogniteClient();
  const [loading, setLoading] = useState(true);
  const [showTimeseriesData, setShowTimeseriesData] =
    useState<GraphModalData | null>(null);
  const [anomalySystemCollection, setAnomalySystemCollection] = useState<
    AnomalySystemCollection[]
  >([]);
  const [filteredAnomalySystemCollection, setFilteredAnomalySystemCollection] =
    useState<AnomalySystemCollection[]>([]);

  const searchWords = useMemo(
    () => query.split(" ").filter((word) => word !== ""),
    [query],
  );

  useEffect(() => {
    async function fetchAnomalyProperties() {
      setLoading(true);
      try {
        if (currentBuildingId) {
          const anomalyPropertiesList =
            await getAnomalyProperties(currentBuildingId);

          // Get health external ids
          const healthEids = [
            ...new Set(
              anomalyPropertiesList.map(
                (ap) => `TS_${ap.system.substring(3)}-XD000`,
              ),
            ),
          ].map((externalId) => {
            return { externalId };
          });

          // Get health timeseries
          const healthTimeseriesList =
            healthEids.length > 0
              ? await client.timeseries.retrieve(healthEids, {
                  ignoreUnknownIds: true,
                })
              : [];

          // Get anomaly Cognite events in specified time period
          const { items: anomalyEvents } =
            healthEids.length > 0
              ? await client.events.list({
                  filter: {
                    activeAtTime: {
                      min:
                        Date.now() - anomaliesCountPeriod * 24 * 60 * 60 * 1000,
                    },
                    type: "note",
                    assetExternalIds: healthEids.map(
                      (externalId) =>
                        `AH_${externalId.externalId.substring(3)}`,
                    ),
                  },
                })
              : { items: [] };

          // Get latest health value
          const latestHealth =
            healthEids.length > 0
              ? await client.datapoints.retrieveLatest(healthEids, {
                  ignoreUnknownIds: true,
                })
              : [];

          // Get external ids that are associated with the anomalies
          const auxiliaryEids = [
            ...new Set(
              anomalyPropertiesList
                .map((ap) =>
                  "coupled_features" in ap.metadata
                    ? ap.metadata.coupled_features
                    : [],
                )
                .flat(),
            ),
          ].map((externalId) => {
            return { externalId };
          });

          // Get timeseries that are associated with the anomalies
          const auxTs =
            auxiliaryEids.length > 0
              ? await client.timeseries.retrieve(auxiliaryEids, {
                  ignoreUnknownIds: true,
                })
              : [];

          const newAnomalySystemStates: AnomalySystemCollection[] =
            healthTimeseriesList.map((healthTimeseries) => {
              return {
                systemExternalId: healthTimeseries.externalId,
                healthTimeseries,
                latestHealth: latestHealth.find(
                  (lh) => lh.externalId === healthTimeseries.externalId,
                )?.datapoints[0],
                anomalyEvents: anomalyEvents.filter(
                  (event) =>
                    healthTimeseries.assetId !== undefined &&
                    event.assetIds !== undefined &&
                    event.assetIds.includes(healthTimeseries.assetId),
                ),
                anomalyAlgorithms: anomalyPropertiesList
                  .filter(
                    (anomalyProperty) =>
                      `${anomalyProperty.system.substring(3)}-XD000` ===
                      healthTimeseries.externalId?.substring(3),
                  )
                  .map((anomalyProperty) => {
                    return {
                      anomalyProperty,
                      auxiliaryTimeSeries: auxTs.filter(
                        (ts) =>
                          ts.externalId !== undefined &&
                          "coupled_features" in anomalyProperty.metadata &&
                          anomalyProperty.metadata.coupled_features.includes(
                            ts.externalId,
                          ),
                      ),
                    };
                  }),
              };
            });
          setAnomalySystemCollection(newAnomalySystemStates);
        }
      } catch (error) {
        if (isErrorWithMessage(error)) {
          return notification.error({
            message: error.message,
          });
        }
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchAnomalyProperties();
  }, [
    client.datapoints,
    client.events,
    client.events.aggregate,
    client.timeseries,
    currentBuildingId,
    notification,
    anomaliesCountPeriod,
    t,
  ]);

  useEffect(() => {
    if (searchWords.length === 0)
      setFilteredAnomalySystemCollection(anomalySystemCollection);
    else {
      setFilteredAnomalySystemCollection(
        anomalySystemCollection.filter((anomalySystem) => {
          return searchWords.some((query) =>
            (anomalySystem.systemExternalId || "")
              .toLowerCase()
              .includes(query.toLowerCase()),
          );
        }),
      );
    }
  }, [anomalySystemCollection, searchWords]);

  return (
    <>
      <CompactContent>
        <AnomalyPerSystemTable
          loading={loading}
          anomalySystemCollection={filteredAnomalySystemCollection}
          searchWords={searchWords}
          setShowTimeseriesData={setShowTimeseriesData}
        />
        {building.dataSetId && (
          <NotesSidebar
            noteSource={NoteSource.WEB_VIRTUAL_SENSORS}
            assetFilterMode={NotesAssetFilterMode.AssetList}
            idSet={
              new Set(
                filteredAnomalySystemCollection
                  .map((collection) => collection.healthTimeseries)
                  .filter((ts) => ts.assetId !== undefined)
                  .map((ts) => ts.assetId as number),
              )
            }
            buildings={[{ id: building.dataSetId, name: building.name }]}
          />
        )}
      </CompactContent>
      {showTimeseriesData && (
        <GraphModal
          timeseriesInfo={showTimeseriesData}
          setTimeseriesInfo={setShowTimeseriesData}
          hide={() => setShowTimeseriesData(null)}
          expanded
          editable
          showDocuments={false}
          buildingId={currentBuildingId}
        />
      )}
    </>
  );
}
