import { AxiosError, AxiosResponse } from "axios";

interface AxiosErrorWithMessage extends AxiosError {
  response: AxiosResponse<{ message: string }>;
}
export function hasErrorMessage(
  error: AxiosError,
): error is AxiosErrorWithMessage {
  const responseData = error.response?.data;
  if (typeof responseData === "object" && responseData !== null) {
    return "message" in responseData;
  }
  return false;
}
