import { Form, Segmented } from "antd";
import { useTranslations } from "@properate/translations";
import { NoteLevel } from "../types";
import { NoteLevelText } from "../NoteLevelText";
import { FormFieldProps } from "./types";

export default function LevelSelectField({
  disabled,
}: Readonly<FormFieldProps>) {
  const t = useTranslations();

  return (
    <Form.Item
      name="level"
      label={t("notes.form.level-select.label")}
      rules={[
        { required: true, message: t("notes.form.level-select.message") },
      ]}
      required={false}
    >
      <Segmented
        block
        disabled={disabled}
        options={[
          {
            label: <NoteLevelText level={NoteLevel.INFO} />,
            value: NoteLevel.INFO,
          },
          {
            label: <NoteLevelText level={NoteLevel.WARNING} />,
            value: NoteLevel.WARNING,
          },
          {
            label: <NoteLevelText level={NoteLevel.ERROR} />,
            value: NoteLevel.ERROR,
          },
        ]}
      />
    </Form.Item>
  );
}
