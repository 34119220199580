import { useUser } from "@properate/auth";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { useEffect, useMemo } from "react";
import { Button } from "antd";
import { useRootAssets } from "@/hooks/useRootAssets";
import FullLayout from "@/layout/FullLayout";
import {
  TaskManagerHeading,
  TaskManagerTable,
  TaskManagerTableFiltersProvider,
} from "@/features/task-manager";
import { useHelp } from "@/context/HelpContext";
import usePageTitle from "@/hooks/usePageTitle";
import { useWindowSize } from "@/hooks/useWindowSize";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

export default function Tasks() {
  const t = useTranslations();
  usePageTitle(t("task.ui.organization-tasks"));
  const navigate = useNavigate();
  const { setHelp } = useHelp();
  const user = useUser();
  const { height: windowHeight } = useWindowSize();
  const rootAssets = useRootAssets();
  const buildingIds = useMemo(
    () => rootAssets.map((asset) => asset.id),
    [rootAssets],
  );

  useEffect(() => {
    setHelp({
      title: t("task.ui.task"),
      content: t.rich("task.help.view-page", {
        p: (text) => <p>{text}</p>,
      }),
    });
  }, [setHelp, t]);

  return (
    <TaskManagerTableFiltersProvider>
      <FullLayout
        pageName={t("task.ui.title")}
        hideSearchInput
        headerRight={
          <TaskManagerHeading>
            <Button
              onClick={() => navigate("/org/task-templates")}
              disabled={user.isViewer}
            >
              {t("task.ui.goToTaskTemplates")}
            </Button>
            <Button
              type="primary"
              onClick={() => navigate("/org/tasks/create")}
              disabled={user.isViewer}
              data-testid="add-organization-task-button"
            >
              {t("task.ui.newTask")}
            </Button>
          </TaskManagerHeading>
        }
      >
        <TaskManagerTable
          onRowClick={(event) =>
            navigate(
              `/asset/${event.buildingId}/workOrders/${event.snapshotId}`,
            )
          }
          tableHeight={
            windowHeight - PAGE_LAYOUT_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT
          }
          buildingIds={buildingIds}
        />
      </FullLayout>
    </TaskManagerTableFiltersProvider>
  );
}
