import {
  ThirdPartyAlarmVisualTimeseries,
  ThirdPartyAlarmVisualTimeseriesType,
} from "@properate/common";
import { useFormContext, useFormValue } from "../../../FormContext/hooks";
import {
  FormContextActionType,
  ResultCallbackFunction,
} from "../../../FormContext";
import {
  ButtonVariant,
  NotificationRow,
} from "../../../AlarmNotificationsPage/NotificationRow";
import { ThirdPartyAlarmFields } from "../types";
import SelectVisualTimeseriesType from "./SelectVisualTimeseriesType";
import SelectVisualTimeseriesId from "./SelectVisualTimeseriesId";

export default function AddNewVisualTimeseries({
  selectVisualTimeseries,
}: {
  selectVisualTimeseries: () => void;
}) {
  const { dispatch } = useFormContext();
  const [timeseriesId, setTimeseriesId] = useFormValue<number | undefined>(
    ThirdPartyAlarmFields.AddVisualTimeseriesId,
  );
  const [timeseriesType, setTimeseriesType] = useFormValue<
    ThirdPartyAlarmVisualTimeseriesType | undefined
  >(ThirdPartyAlarmFields.AddVisualTimeseriesType);
  const [visualTimeseries, setVisualTimeseries] = useFormValue<
    ThirdPartyAlarmVisualTimeseries[]
  >(ThirdPartyAlarmFields.VisualTimeseries);

  const addVisualValidationCallback: ResultCallbackFunction = ({
    hasError,
  }) => {
    if (
      hasError ||
      timeseriesType === undefined ||
      timeseriesId === undefined
    ) {
      return;
    }

    setVisualTimeseries([
      ...visualTimeseries,
      {
        label: timeseriesType,
        value: timeseriesId,
      },
    ]);
    setTimeseriesId(undefined);
    setTimeseriesType(undefined);
  };

  function handleAddVisualTimeseries() {
    dispatch({
      type: FormContextActionType.validateFields,
      ids: [
        ThirdPartyAlarmFields.AddVisualTimeseriesId,
        ThirdPartyAlarmFields.AddVisualTimeseriesType,
      ],
      resultCallback: addVisualValidationCallback,
      ignoreSkipWhenValidatingAll: true,
    });
  }

  return (
    <>
      <NotificationRow
        colOne={<SelectVisualTimeseriesType />}
        colTwo={
          <SelectVisualTimeseriesId
            selectVisualTimeseries={selectVisualTimeseries}
          />
        }
        buttonVariant={ButtonVariant.Add}
        onClick={handleAddVisualTimeseries}
        outerClassName="gap-1"
        innerClassName="gap-1"
      />
    </>
  );
}
