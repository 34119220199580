import { TimeSpan } from "@properate/common";
import { Line } from "@visx/shape";
import { ScaleTime } from "d3-scale";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { NEUTRAL5 } from "@/utils/ProperateColors";
import {
  getDatapointClosestToDate,
  getOnePercentThreshold,
} from "@/utils/datapoints";
import { getItemByProp } from "@/utils/array";
import { isScalesWithUnit } from "../../utils";
import {
  SimplePointsWithMetadata,
  Marker,
  ScaleWithTimeseriesId,
  ScaleWithUnit,
} from "../../types";

interface Props {
  height: number;
  leftOffset: number;
  date: Date;
  timeScale: ScaleTime<number, number, never>;
  scalesWithMetadata: ScaleWithTimeseriesId[] | ScaleWithUnit[];
  simplePointsWithMetadataList: SimplePointsWithMetadata[];
  zoomedTimeSpan: TimeSpan;
  isHighlightedStroke?: Boolean;
}

export function SimpleGraphMarkers({
  height,
  leftOffset,
  date,
  timeScale,
  scalesWithMetadata,
  simplePointsWithMetadataList,
  zoomedTimeSpan,
  isHighlightedStroke,
}: Props) {
  const themeContext = useContext(ThemeContext);

  const markers = simplePointsWithMetadataList
    .map(({ metadata: { timeseriesId, unit, color }, simplePoints }) => {
      const simplePointClosestToDate = getDatapointClosestToDate(
        simplePoints,
        date,
        getOnePercentThreshold(zoomedTimeSpan),
      );
      if (simplePointClosestToDate) {
        return {
          x: timeScale(simplePointClosestToDate.timestamp),
          y: scaleValueForTimeseries(
            timeseriesId,
            unit,
            simplePointClosestToDate.y,
          ),
          color,
        };
      }
      return null;
    })
    .filter((marker): marker is Marker => marker !== null);

  function scaleValueForTimeseries(
    timeseriesId: number,
    unit: string,
    value: number,
  ): number {
    if (isScalesWithUnit(scalesWithMetadata)) {
      const { scale } = getItemByProp(scalesWithMetadata, unit, "unit");
      return scale(value)!;
    }
    const { scale } = getItemByProp(
      scalesWithMetadata,
      timeseriesId,
      "timeseriesId",
    );
    return scale(value)!;
  }

  return (
    <>
      {markers.map((marker, index) => (
        <circle
          key={index}
          cx={marker.x}
          cy={marker.y}
          r={4}
          fill={marker.color}
          stroke="#666"
          strokeWidth={2}
          pointerEvents="none"
        />
      ))}
      <Line
        from={{ x: leftOffset, y: 0 }}
        to={{ x: leftOffset, y: height }}
        stroke={isHighlightedStroke ? themeContext.accent12 : NEUTRAL5}
        strokeWidth={2}
        pointerEvents="none"
        strokeDasharray={isHighlightedStroke ? "" : "5,2"}
      />
    </>
  );
}
