import useSWR from "swr";
import { Timeseries } from "@cognite/sdk";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useHandleApiError } from "@/utils/api";

export function useGetSpotPriceTimeseries(buildingId: number) {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const {
    data: powerPriceAssets = [],
    isLoading: isLoadingAssets,
    error: errorAssets,
  } = useSWR(
    {
      type: "assets.list",
      query: {
        filter: {
          assetSubtreeIds: [{ id: buildingId }],
          labels: {
            containsAll: [{ externalId: "powerprice" }, { externalId: "cost" }],
          },
        },
      },
    },
    ({ query }) => {
      return client.assets.list(query).autoPagingToArray();
    },
  );
  const {
    data: spotPriceTimeseriesList = [],
    isLoading: isLoadingTimeseriesList,
    error: errorTimeseriesList,
  } = useSWR(
    powerPriceAssets.length > 0
      ? {
          type: "timeseries.list",
          query: {
            filter: {
              assetIds: [powerPriceAssets[0].id],
            },
          },
        }
      : null,
    ({ query }) => {
      return client.timeseries.list(query).autoPagingToArray() as Promise<
        [Timeseries] | []
      >;
    },
  );
  if (errorAssets !== undefined || errorTimeseriesList !== undefined) {
    handleAPIError(
      errorAssets !== undefined ? errorAssets : errorTimeseriesList,
    );
  }
  return {
    spotPriceTimeseries: spotPriceTimeseriesList[0] || null,
    isLoading: isLoadingAssets || isLoadingTimeseriesList,
  };
}
