import { IdEither } from "@cognite/sdk-core/dist/src";

/**
 * We have timeseries-ids in Firestore that no longer exists in Cognite. This causes errors when we attempt to read
 * these timeseries from Cognite...
 * Luckily Cognite errors contain info about which ids failed and which succeeded, including full data for the
 * existing ids, so we just error-log the missing ids, and return the ones that exist.
 *
 * @param error error-response from a cognite sdk call.
 */
export function extractSuccessfulDataFromCogniteError<T>(error: unknown) {
  const err = error as {
    missing?: IdEither[];
    succeded?: IdEither[];
    responses?: { items: T[] }[];
    message: string;
  };
  if (err?.missing && err?.succeded && err?.responses) {
    const succeeded = err.succeded ?? [];
    const response =
      succeeded.length > 0 &&
      (err?.responses ?? []).find(
        ({ items }) => items.length === succeeded.length,
      );
    console.error(err.message);
    if (err.missing.length > 0) {
      console.error(
        "Loading timeseries from ids, was provided unknown ids: ",
        err.missing,
      );
    }
    if (response) {
      return response.items;
    }
    return [];
  }
  throw err;
}

export async function mergedPagination<I, F, R>(
  input: I[],
  inputLimit: number,
  makeFilter: (input: I[]) => F,
  doRequest: (filter: F) => R[] | Promise<R[]>,
) {
  let count = 0;
  const response = [];
  function getFilter() {
    return makeFilter(
      input.slice(count * inputLimit, (count + 1) * inputLimit),
    );
  }
  do {
    const res = await doRequest(getFilter());
    response.push(...res);
    count += 1;
  } while (count * inputLimit < input.length);
  return response;
}
