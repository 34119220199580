import { MessageKey } from "@properate/translations";
import { SolarCellProductionPeriod } from "../types";

export const periodOptions: {
  value: SolarCellProductionPeriod;
  labelKey: MessageKey;
}[] = [
  {
    value: "lastWeek",
    labelKey: "dashboard.widgets.solar-production.last-7-days",
  },
  {
    value: "last30Days",
    labelKey: "dashboard.widgets.solar-production.last-30-days",
  },
  {
    value: "lastYear",
    labelKey: "dashboard.widgets.solar-production.last-365-days",
  },
];
