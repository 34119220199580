import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  arrayRemove,
  arrayUnion,
  initializeFirestore,
} from "firebase/firestore";

export { signInAnonymously, signInWithCustomToken } from "firebase/auth";

export const app = initializeApp({
  apiKey:
    process.env["REACT_APP_FIREBASE_API_KEY"] ??
    process.env["NEXT_PUBLIC_FIREBASE_API_KEY"] ??
    "",
  authDomain:
    process.env["REACT_APP_FIREBASE_AUTH_DOMAIN"] ??
    process.env["NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN"] ??
    "",
  databaseURL:
    process.env["REACT_APP_FIREBASE_DATABASE_URL"] ??
    process.env["NEXT_PUBLIC_FIREBASE_DATABASE_URL"] ??
    "",
  projectId:
    process.env["REACT_APP_FIREBASE_PROJECT_ID"] ??
    process.env["NEXT_PUBLIC_FIREBASE_PROJECT_ID"] ??
    "",
  storageBucket:
    process.env["REACT_APP_FIREBASE_STORAGE_BUCKET"] ??
    process.env["NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET"] ??
    "",
  messagingSenderId:
    process.env["REACT_APP_FIREBASE_MESSAGING_SENDER_ID"] ??
    process.env["NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID"] ??
    "",
  appId:
    process.env["REACT_APP_FIREBASE_APP_ID"] ??
    process.env["NEXT_PUBLIC_FIREBASE_APP_ID"] ??
    "",
  measurementId:
    process.env["REACT_APP_FIREBASE_MEASUREMENT_ID"] ??
    process.env["NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID"] ??
    "",
});

export const browserFirestore = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
  experimentalAutoDetectLongPolling: true,
});

export const auth = getAuth(app);

type User = {
  uid: string;
  email: string;
};

export function getCurrentUser(): Promise<User> {
  const user = getAuth(app).currentUser as User | null;
  if (user) {
    return Promise.resolve(user);
  }
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user as User);
    }, reject);
  });
}

export function getPossibilyCurrentUser() {
  return getAuth(app).currentUser as User | null;
}

export function getArrayDiffOp<T>(patch: T[], current: T[]) {
  if (current.length > patch.length) {
    return arrayRemove(...current.filter((item) => !patch.includes(item)));
  }

  return arrayUnion(...patch.filter((item) => !current.includes(item)));
}
