import { Form, Modal, App, Row, DatePicker, Tooltip, Button } from "antd";
import { useMemo, useState } from "react";
import dayjs, { Dayjs } from "@properate/dayjs";
import { FileInfo } from "@cognite/sdk";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { reports } from "@/eepApi";
import { disabledFutureDates } from "@/utils/helpers";
import {
  useLastHalfYearPresets,
  useLastXMonthPreset,
  useLastXQuarterPresets,
} from "@/utils/date";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { ReactComponent as EnergySVG } from "./icon/energy.svg";

type Props = {
  openModal?: boolean;
  lang: "no";
  addFile: (files: FileInfo[]) => void;
};
type RangeValueType = [Dayjs | null, Dayjs | null] | null;

const MINIMUM_DAYS_TO_CREATE_GOOD_REPORT = 28;
export const EnergyReport = ({ openModal, lang, addFile }: Props) => {
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const user = useUser();
  const building = useCurrentBuilding();
  const [open, setOpen] = useState(openModal);
  const t = useTranslations();

  function handleDatePickerChange(dates: RangeValueType) {
    if (dates && dates[0] && dates[1]) {
      const start = dayjs(dates[0]).startOf("day");
      const end = dayjs(dates[1]).endOf("day");
      // diff returns 0 if start and end is the same day.
      const duration = end.diff(start, "days") + 1;

      if (duration < MINIMUM_DAYS_TO_CREATE_GOOD_REPORT) {
        notification.warning({
          message: t(
            "reports.energy.create.notification-min-days-to-create-report",
            { MINIMUM_DAYS_TO_CREATE_GOOD_REPORT, duration },
          ),
          duration: 0,
        });
      }
    }
  }

  function showModal() {
    setOpen(true);
  }

  function handleCancel() {
    setOpen(false);
    form.resetFields();
  }

  async function generateReport() {
    const validated = await form.validateFields();

    if (!validated.errorFields) {
      try {
        // 2022-01-01T00:00:00
        const data = {
          building_list: [building!.externalId!],
          report_type: "energy",
          period_start: validated.period[0].startOf("hour").utc().valueOf(),
          period_end: validated.period[1].startOf("hour").utc().valueOf(),
          user: user.email,
          language: lang,
        };
        const result = await reports(data);

        addFile(result);

        notification.success({
          message: t("reports.energy.create.notification-ordered-report"),
        });
      } catch (error) {
        if (typeof error === "object" && error !== null && "message" in error) {
          console.warn("error", error);
          const errorMessage = String(error.message);
          notification.error({
            message: t("reports.energy.create.failed-to-generate-report", {
              errorMessage,
            }),
          });
        }
      }
      form.resetFields();
      setOpen(false);
    }
  }

  const last3MonthPreset = useLastXMonthPreset(3);
  const last6MonthPreset = useLastXMonthPreset(6);
  const last12MonthPreset = useLastXMonthPreset(12);
  const last3QuarterPresets = useLastXQuarterPresets(3);
  const lastHalfYearPresets = useLastHalfYearPresets();

  const presets = useMemo(
    () => [
      last3MonthPreset,
      last6MonthPreset,
      last12MonthPreset,
      ...last3QuarterPresets,
      ...lastHalfYearPresets,
    ],
    [
      last3MonthPreset,
      last6MonthPreset,
      last12MonthPreset,
      last3QuarterPresets,
      lastHalfYearPresets,
    ],
  );

  return (
    <>
      <Row
        justify="center"
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        data-testid="create-energy-report"
      >
        <Tooltip title={t("reports.energy.create.title")}>
          <Button
            size="large"
            style={{
              width: "calc(100% - 40px)",
              height: 116,
              margin: "0 10px",
            }}
            onClick={showModal}
          >
            <div style={{ textAlign: "center" }}>
              <EnergySVG />
              <div>
                <span>{t("reports.energy.create.title")}</span>{" "}
              </div>
            </div>
          </Button>
        </Tooltip>
      </Row>
      <Modal
        title={t("reports.energy.create.title")}
        open={open}
        onOk={generateReport}
        onCancel={handleCancel}
      >
        <Form form={form} requiredMark={false} layout="horizontal">
          {t.rich("reports.energy.create.description", {
            p: (text) => <p>{text}</p>,
            MINIMUM_DAYS_TO_CREATE_GOOD_REPORT,
          })}
          <Form.Item
            label={t("reports.energy.create.period-label")}
            name="period"
            rules={[
              {
                required: true,
                message: t("reports.energy.create.select-period-rules-label"),
              },
            ]}
          >
            <DatePicker.RangePicker
              disabledDate={disabledFutureDates}
              onChange={handleDatePickerChange}
              presets={presets}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
