import { useAsyncError } from "react-router-dom";

export function LoadingArray({
  width,
  height,
}: {
  width: number;
  height: number;
}) {
  const error = useAsyncError();
  console.error(error);
  return <div style={{ width, height }}>{(error as any).message}</div>;
}
