import * as React from "react";
import { Modal, Radio, Space } from "antd";
import { useTranslations } from "@properate/translations";
import { ProperateCalendarEvent } from "@/utils/types";
import { describeEventRecurrence } from "@/pages/properateCalendar/utils";

type Props = {
  event: ProperateCalendarEvent;
  hide: () => void;
  onSave: (event: ProperateCalendarEvent) => Promise<void>;
  open: boolean;
};
export const EditRecurringModal = ({ open, event, hide, onSave }: Props) => {
  const t = useTranslations();
  const [recurring, setRecurring] = React.useState<boolean>(true);

  return (
    <Modal
      destroyOnClose
      title={t("calendar.edit-recurring-event")}
      open={open}
      onOk={async () => {
        if (recurring) {
          await onSave(event);
        } else {
          await onSave({
            ...event,
            schedule: "single",
          });
        }
        hide();
      }}
      onCancel={() => {
        hide();
      }}
    >
      <Radio.Group
        onChange={(event) => {
          setRecurring(event.target.value);
        }}
        value={recurring}
      >
        <Space direction="vertical">
          <Radio value={false}>
            {t("calendar.event-recurrence.only-this-event")}
          </Radio>
          <Radio value>
            {t(
              `calendar.event-recurrence.${describeEventRecurrence(
                event.schedule,
              )}`,
            )}
          </Radio>
        </Space>
      </Radio.Group>
    </Modal>
  );
};
