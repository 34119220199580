import { useUser } from "@properate/auth";
import { Node, useReactFlow } from "reactflow";
import { Form, InputNumber, Select } from "antd";
import { useTranslations } from "@properate/translations";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import {
  NormalNode as MediumNode,
  NodeOutput,
  Body,
  Header,
  NodeOutputLabel,
  NodeInput,
  NodeInputLabel,
} from "./helpers/NodeComponents";

type ShiftUnit = "W" | "D" | "H" | "T";

interface Props {
  operationId: string;
  shiftMultiplier: number;
  shiftUnit: ShiftUnit;
}

export const getEmptyTimeShiftNode = (): Node<Props> => {
  return {
    id: getNodeId("timeShift"),
    type: "timeShift",
    data: {
      operationId: "time_shift",
      shiftMultiplier: 1,
      shiftUnit: "H",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function TimeShiftNode(params: { id: string; data: Props }) {
  const t = useTranslations();

  const reactFlowInstance = useReactFlow();
  const user = useUser();

  const handleShiftMultiplierChange = (shiftMultiplier: number | null) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "shiftMultiplier",
      shiftMultiplier ?? 0,
    );
  };

  const handleShiftUnitChange = (shiftUnit: ShiftUnit) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "shiftUnit",
      shiftUnit,
    );
  };

  return (
    <MediumNode>
      <Header>{t("calculation-flow.shift-timeseries")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>

        <Form
          layout="vertical"
          style={{ paddingLeft: "6px" }}
          disabled={user.isViewer}
        >
          <Form.Item label={t("calculation-flow.time-difference")}>
            <InputNumber
              value={params.data.shiftMultiplier}
              onChange={handleShiftMultiplierChange}
              controls={false}
              width="50%"
              decimalSeparator=","
            />
            <Select
              showAction={["focus", "click"]}
              value={params.data.shiftUnit}
              onChange={handleShiftUnitChange}
              options={[
                { value: "W", label: t("calculation-flow.time-units.weeks") },
                { value: "D", label: t("calculation-flow.time-units.days") },
                { value: "H", label: t("calculation-flow.time-units.hours") },
                { value: "T", label: t("calculation-flow.time-units.minutes") },
              ]}
              style={{ width: "50%" }}
            />
          </Form.Item>
        </Form>
        <NodeOutput>
          <NodeOutputLabel>{t("calculation-flow.shift-a")}</NodeOutputLabel>
        </NodeOutput>
      </Body>
    </MediumNode>
  );
}

export default TimeShiftNode;
