import { InputNumber } from "antd";
import { useTranslations } from "@properate/translations";
import { TimeseriesFilter } from "@/pages/calculationFlow/types";

export const FilterInput = ({
  filterType,
  value,
  onChangeValue,
}: {
  filterType: TimeseriesFilter;
  value: number[];
  onChangeValue: (value: number[]) => void;
}) => {
  const t = useTranslations();

  const onChangeBetween = (index: number, newValue: number) => {
    const newValueArray = [...value];
    newValueArray[index] = newValue;
    onChangeValue(newValueArray);
  };
  if (filterType === "none") return null;
  if (filterType === "between")
    return (
      <div className="flex gap-2 items-center">
        <InputNumber
          value={value[0]}
          onChange={(value) => {
            if (value === null) return;
            onChangeBetween(0, value);
          }}
        />
        {t("calculation-flow.filter.between-and")}
        <InputNumber
          value={value[1]}
          onChange={(value) => {
            if (value === null) return;
            onChangeBetween(1, value);
          }}
        />
      </div>
    );
  return (
    <InputNumber
      value={value[0]}
      onChange={(value) => {
        if (value === null) return;
        onChangeValue([value]);
      }}
    />
  );
};
