import styled from "styled-components";
import { WarningOutlined } from "@ant-design/icons";
import { Col, Input, InputNumber, Row, Space, Switch } from "antd";
import { formatMeasurement } from "@properate/common";
import { IoHandRightOutline } from "@react-icons/all-files/io5/IoHandRightOutline";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { SetPoints } from "./types";

export const DetailsSection = styled.div`
  margin-bottom: 8px;
  max-height: 460px;
  overflow-x: auto;

  .ant-row {
    margin-right: 0 !important;
    margin-left: 0 !important;

    .ant-col:first-child {
      padding-left: 0 !important;
    }

    .ant-col:last-child {
      padding-right: 0 !important;
    }
  }
`;

type Props = {
  setPoints: SetPoints[];
  setSetPoints: (s: SetPoints[]) => void;
  errors: Record<string, string>;
};
export const SetPointsPane = ({ setPoints, setSetPoints }: Props) => {
  const t = useTranslations();
  const user = useUser();
  return (
    <>
      <DetailsSection>
        <h2>{t("common.timeseries-graph-modal.setpoints.title")}</h2>
        {setPoints.map((setPoint) => (
          <div key={setPoint.externalId}>
            <Row gutter={8} style={{ marginBottom: 8 }}>
              <Col>{setPoint.description}</Col>
            </Row>
            <Row gutter={8} style={{ marginBottom: 8 }}>
              <Col flex="auto">
                <Space>
                  {setPoint.name}{" "}
                  {setPoint["out-of-service"] ? (
                    <span style={{ color: DEFAULT_GLOBAL_THEME.color.RED }}>
                      ●
                    </span>
                  ) : (
                    <span style={{ color: DEFAULT_GLOBAL_THEME.color.GREEN }}>
                      ●
                    </span>
                  )}
                  {setPoint.reliability !== "no-fault-detected" && (
                    <WarningOutlined
                      color={DEFAULT_GLOBAL_THEME.color.ORANGE}
                    />
                  )}
                  {setPoint["present-value"]
                    ? isNaN(+setPoint["present-value"])
                      ? ` ${setPoint["present-value"]}`
                      : formatMeasurement({
                          value: Number(setPoint["present-value"]),
                          unit: setPoint.unit || "",
                        })
                    : ""}
                </Space>
              </Col>
              <Col flex="none">
                {setPoint["priority-array"] && (
                  <Switch
                    disabled={user.isViewer}
                    checked={setPoint.showOverride}
                    checkedChildren={<IoHandRightOutline />}
                    unCheckedChildren={<IoHandRightOutline />}
                    onClick={() => {
                      const newData = [...setPoints];
                      newData[newData.indexOf(setPoint)].showOverride =
                        !setPoint.showOverride;
                      if (!newData[newData.indexOf(setPoint)].showOverride) {
                        newData[newData.indexOf(setPoint)]["priority-array"] = [
                          ...newData[newData.indexOf(setPoint)][
                            "priority-array"
                          ].filter((p) => p.index !== 8),
                          { index: 8, value: undefined },
                        ];
                      } else {
                        newData[newData.indexOf(setPoint)]["priority-array"] = [
                          ...newData[newData.indexOf(setPoint)][
                            "priority-array"
                          ],
                          { index: 8, value: undefined },
                        ];
                      }
                      setSetPoints(newData);
                    }}
                  />
                )}
              </Col>
            </Row>
            {setPoint.showOverride && (
              <Row gutter={8} style={{ marginBottom: 8 }}>
                <Col span={12}>
                  {t("common.timeseries-graph-modal.setpoints.priority", {
                    priority: 8,
                  })}{" "}
                  <IoHandRightOutline />
                </Col>
                <Col span={12}>
                  <InputNumber
                    disabled={user.isViewer}
                    value={
                      setPoint["priority-array"].find((p) => p.index === 8)
                        ?.value
                    }
                    step=".1"
                    suffix={setPoint.unit}
                    placeholder={
                      setPoint.default_value ||
                      setPoint["present-value"]?.toString() ||
                      ""
                    }
                    onChange={(value) => {
                      if (value) {
                        const newData = [...setPoints];
                        newData[newData.indexOf(setPoint)][
                          "priority-array"
                        ].find((e) => e.index === 8)!.value = value;
                        setSetPoints(newData);
                      }
                    }}
                    decimalSeparator=","
                  />
                </Col>
              </Row>
            )}
            <Row gutter={8} style={{ marginBottom: 8 }}>
              <Col span={12}>
                {t("common.timeseries-graph-modal.setpoints.priority", {
                  priority: 16,
                })}
              </Col>
              <Col span={12}>
                <InputNumber
                  disabled={user.isViewer}
                  value={
                    setPoint["priority-array"].find((p) => p.index === 16)
                      ?.value
                  }
                  suffix={setPoint.unit}
                  step=".1"
                  placeholder={
                    setPoint.default_value ||
                    setPoint["present-value"]?.toString() ||
                    ""
                  }
                  onChange={(value) => {
                    if (value) {
                      const newData = [...setPoints];
                      const p = newData[newData.indexOf(setPoint)][
                        "priority-array"
                      ].find((e) => e.index === 16);
                      if (p) {
                        p.value = value;
                      } else {
                        newData[newData.indexOf(setPoint)][
                          "priority-array"
                        ].push({
                          index: 16,
                          value: value,
                        });
                      }
                      setSetPoints(newData);
                    }
                  }}
                  decimalSeparator=","
                />
              </Col>
            </Row>
            {setPoint["priority-array"]
              .filter((p) => p.index !== 8 && p.index !== 16)
              .map((p) => (
                <Row gutter={8} style={{ marginBottom: 8 }} key={p.index}>
                  <Col span={12}>
                    {t("common.timeseries-graph-modal.setpoints.priority", {
                      priority: p.index,
                    })}{" "}
                    {p.index === 8 && <IoHandRightOutline />}
                  </Col>
                  <Col span={12}>
                    {((p.index === 8 || p.index === 16) && (
                      <InputNumber
                        disabled={user.isViewer}
                        value={p.value}
                        suffix={setPoint.unit}
                        step=".1"
                        placeholder="30"
                        onChange={(value) => {
                          if (value) {
                            const newData = [...setPoints];
                            newData[newData.indexOf(setPoint)][
                              "priority-array"
                            ].find((e) => e.index === p.index)!.value = value;
                            setSetPoints(newData);
                          }
                        }}
                        decimalSeparator=","
                      />
                    )) || (
                      <Input
                        type="text"
                        value={p.value}
                        suffix={setPoint.unit}
                        readOnly
                      />
                    )}
                  </Col>
                </Row>
              ))}
          </div>
        ))}
      </DetailsSection>
    </>
  );
};
