import { TimeSpan } from "@properate/common";
import { GraphScrollArea } from "@/components/GraphNavigator/GraphNavigator";
import { SimplePointsWithMetadata } from "../../types";
import { TimeseriesNavigatorOverviewGraph } from "./TimeseriesNavigatorOverviewGraph";

interface Props {
  parentWidth: number;
  simplePointsWithMetadataList: SimplePointsWithMetadata[];
  timeSpan: TimeSpan;
  zoomedTimeSpan: TimeSpan;
  onChangeZoomedTimeSpan: (value: TimeSpan) => unknown;
  simplePointsWithMetadataX?: SimplePointsWithMetadata;
}

export function TimeseriesNavigator({
  parentWidth,
  simplePointsWithMetadataList,
  timeSpan: [timeSpanStart, timeSpanEnd],
  zoomedTimeSpan: [zoomedTimeSpanStart, zoomedTimeSpanEnd],
  onChangeZoomedTimeSpan,
  simplePointsWithMetadataX,
}: Props) {
  const timeSpanDiff = timeSpanEnd - timeSpanStart;
  const highlight = (zoomedTimeSpanEnd - zoomedTimeSpanStart) / timeSpanDiff;
  const position = Math.min(
    Math.max(zoomedTimeSpanStart - timeSpanStart, 0) / timeSpanDiff,
    1,
  );

  function handleUpdate(position: number, highlight: number) {
    onChangeZoomedTimeSpan([
      Math.floor(timeSpanStart + timeSpanDiff * position),
      Math.floor(
        timeSpanStart + timeSpanDiff * position + timeSpanDiff * highlight,
      ),
    ]);
  }

  return (
    <GraphScrollArea
      width={parentWidth}
      position={position}
      highlight={highlight}
      onUpdate={handleUpdate}
      hideScrollbar
    >
      <TimeseriesNavigatorOverviewGraph
        width={parentWidth - 28}
        simplePointsWithMetadataX={simplePointsWithMetadataX}
        simplePointsWithMetadataList={simplePointsWithMetadataList.filter(
          ({ metadata: { hidden } }) => !hidden,
        )}
        timeSpanStart={timeSpanStart}
        timeSpanEnd={timeSpanEnd}
      />
    </GraphScrollArea>
  );
}
