import React, { useMemo, useState } from "react";
import { Button, Form, FormInstance, Select, TreeSelect } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import {
  AlarmRule,
  AlarmSeverity,
  tfm1SystemNumbers,
  tfm2SystemNumbers,
} from "@properate/common";
import { FormValues } from "@/pages/alarmSystem/ruleDetails/utils";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { AlarmsSimpleTable } from "@/pages/alarmSystem/ruleDetails/AlarmsSimpleTable";

type Props = {
  queryAlarms: {
    data: AlarmRule[];
    isLoading: boolean;
    error: string | Error | null;
  };
  form: FormInstance<FormValues>;
};

const getUniqueValues = (array: string[]) => {
  return [...new Set(array)];
};

export function AlarmsQueryBasedSelector({ queryAlarms: alarms, form }: Props) {
  const t = useTranslations();
  const [showQueryResults, setShowQueryResults] = useState(false);

  const onSelectSeverity = (value: AlarmSeverity) => {
    const { alarm_rule_selector } = form.getFieldsValue();
    const previousValue = alarm_rule_selector?.severity || [];

    if (
      alarm_rule_selector?.severity &&
      alarm_rule_selector.severity.length > 1
    ) {
      return;
    }

    switch (value) {
      case "INFO":
        form.setFieldsValue({
          alarm_rule_selector: {
            ...(form.getFieldsValue().alarm_rule_selector || {}),
            severity: getUniqueValues([
              ...previousValue,
              "INFO",
              "WARNING",
              "ERROR",
              "CRITICAL",
            ]),
          },
        });
        break;
      case "WARNING":
        form.setFieldsValue({
          alarm_rule_selector: {
            ...(form.getFieldsValue().alarm_rule_selector || {}),
            severity: getUniqueValues([
              ...previousValue,
              "WARNING",
              "ERROR",
              "CRITICAL",
            ]),
          },
        });
        break;
      case "ERROR":
        form.setFieldsValue({
          alarm_rule_selector: {
            ...(form.getFieldsValue().alarm_rule_selector || {}),
            severity: getUniqueValues([...previousValue, "ERROR", "CRITICAL"]),
          },
        });
        break;
      default:
    }
  };

  const SEVERITY_OPTIONS: Record<string, string> = {
    INFO: t("alarms.severity.INFO"),
    WARNING: t("alarms.severity.WARNING"),
    ERROR: t("alarms.severity.ERROR"),
    CRITICAL: t("alarms.severity.CRITICAL"),
  };

  const treeCategories = useMemo(() => {
    const level2CategoriesByParentNumber = tfm2SystemNumbers.reduce(
      (acc, category) => {
        const parentNumber = category.substring(0, 1);
        if (!acc[parentNumber]) {
          acc[parentNumber] = [];
        }
        const categoryTranslation = t(
          `tfm.system.buildingPartNumber.${category}` as MessageKey,
        );
        acc[parentNumber].push({
          title: `${category}: ${categoryTranslation}`,
          value: category,
          selectable: true,
        });
        return acc;
      },
      {} as Record<string, {}[]>,
    );

    return tfm1SystemNumbers.map((level1) => {
      const systemTranslation = t(
        `tfm.system.buildingPartNumber.${level1}` as MessageKey,
      );
      return {
        title: `${level1}: ${systemTranslation}`,
        value: level1,
        selectable: true,
        children: level2CategoriesByParentNumber[level1],
      };
    });
  }, [t]);

  return (
    <fieldset className="p-0 border-0 ml-16">
      <div className="max-w-[450px]">
        <Form.Item<FormValues>
          label={t("alert-rule.field-severity")}
          name={["alarm_rule_selector", "severity"]}
        >
          <Select mode="tags" allowClear onSelect={onSelectSeverity}>
            {Object.entries(SEVERITY_OPTIONS).map(([value, label]) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item<FormValues>
          label={t("alert-rule.field-category")}
          name={["alarm_rule_selector", "category"]}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 6) {
                  return Promise.reject(
                    new Error(t("alert-rule.error-category-limit")),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <TreeSelect
            maxTagCount={7}
            treeNodeFilterProp="title"
            multiple
            showSearch
            allowClear
            treeData={treeCategories}
          />
        </Form.Item>
      </div>
      <SpinnerWithDelay centerInParent isLoading={alarms.isLoading}>
        <div className="flex gap-2 items-center mb-4">
          {t("alert-rule.found-alarms", {
            count: alarms.data ? alarms.data.length : 0,
          })}
          <Button
            size="small"
            onClick={() => setShowQueryResults(!showQueryResults)}
          >
            {showQueryResults
              ? t("alert-rule.hide-alarms")
              : t("alert-rule.show-alarms")}
          </Button>
        </div>
        {showQueryResults && (
          <AlarmsSimpleTable
            key={alarms.data.map((alarm) => alarm.alarm_id).join("|")}
            alarms={alarms.data}
            isLoading={alarms.isLoading}
          />
        )}
      </SpinnerWithDelay>
    </fieldset>
  );
}
