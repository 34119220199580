import { QueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { useLoaderData } from "react-router-dom";
import { IncidentFilter, IncidentsLoader } from "@/features/alarms";
import { listIncidents } from "@/eepApi";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useHandleApiError } from "@/utils/api";
import type { OrderBy } from "@properate/common";

export const getUrl = (
  id: string,
  search?: IncidentsLoader["search"],
  sort?: { property: string; order: string },
  filters?: IncidentFilter[],
) => {
  let url = `/asset/${id}/incidents`;
  const hasSearch = search?.value && search?.fieldName;
  if (hasSearch) {
    url += `?${search.fieldName}=${search.value}`;
  }
  if (sort) {
    const divider = hasSearch ? "&" : "?";
    url += `${divider}sort=${sort.property}&order=${sort.order}`;
  }
  if (filters) {
    const divider = hasSearch || sort ? "&" : "?";
    url += `${divider}filters=${filters.join("&filters=")}`;
  }
  return url;
};

const PAGE_LIMIT = 50;
const queryClient = new QueryClient();

const getFilters = (filters: IncidentFilter[]) =>
  filters.reduce((acc, filter) => {
    if (filter === "all") {
      return {};
    }
    acc[filter] = true;
    return acc;
  }, {} as any);

const fetchIncidents = async (
  pageData: IncidentsLoader & {
    buildingExternalId: string;
  },
  pageParam?: string,
) => {
  const order_by = pageData.sort
    ? (`${pageData.sort
        ?.property}:${pageData.sort?.order.toUpperCase()}` as OrderBy)
    : undefined;

  const filters = getFilters(pageData.filters);

  if (pageData.search?.value && pageData.search?.fieldName) {
    filters[pageData.search.fieldName] = pageData.search.value;
  }

  const incidents = await listIncidents({
    search: "",
    limit: PAGE_LIMIT,
    filter: {
      building_external_id: pageData.buildingExternalId,
      ...filters,
    },
    order_by,
    cursor: pageParam,
  });

  return {
    items: incidents.items,
    nextCursor: incidents.next_cursor,
  };
};

const getQuery = (
  pageData: IncidentsLoader & {
    buildingExternalId: string;
  },
) => ({
  queryKey: [
    "incidents",
    pageData.buildingExternalId,
    pageData.search,
    pageData.sort,
    pageData.filters,
  ],
  queryFn: ({ pageParam }: { pageParam?: string }) =>
    fetchIncidents(pageData, pageParam),
  initialPageParam: undefined,
  getNextPageParam: (lastPage?: { nextCursor: string | undefined }) => {
    return lastPage?.nextCursor;
  },
});

export const useIncidentsData = () => {
  const pageData = useLoaderData() as IncidentsLoader;
  const currentBuilding = useCurrentBuilding();
  const handleAPIError = useHandleApiError();

  const query = getQuery({
    ...pageData,
    buildingExternalId: currentBuilding.externalId!,
  });

  const { data, fetchNextPage, isFetchingNextPage, status, error } =
    useInfiniteQuery(query, queryClient);

  if (error) {
    handleAPIError(error);
  }

  const dataTable = data?.pages.flatMap((page) => page.items) || [];

  return {
    incidents: dataTable,
    isLoading: status === "pending" && !isFetchingNextPage,
    onLoadMore: fetchNextPage,
  };
};
