import { PatternLines } from "@visx/pattern";
import { Bar } from "@visx/shape";
import { useTheme } from "styled-components";

interface Props {
  x: number;
  graphHeight: number;
  graphWidth: number;
}

export function SpotPriceMissingData({ x, graphHeight, graphWidth }: Props) {
  const theme = useTheme();
  // See example from Erlend/Morten
  return (
    <>
      <PatternLines
        id="pattern"
        height={12}
        width={12}
        stroke={theme.warning}
        orientation={["diagonal"]}
      />
      <Bar
        fill="url(#pattern)"
        x={x}
        y={0}
        width={graphWidth - x}
        height={graphHeight}
      />
    </>
  );
}
