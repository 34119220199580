import { BuildingCertification } from "@properate/common";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
} from "antd";
import { useRef, useState } from "react";
import dayjs, { Dayjs } from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { useAddBuildingCertification } from "../hooks/useAddBuildingCertification";
import { useUpdateBuildingCertification } from "../hooks/useUpdateBuildingCertification";
import { useRemoveBuildingCertification } from "../hooks/useRemoveBuildingCertification";
import {
  certifications as allCertifications,
  certificationTypeOptions,
} from "./utils/certifications";
import { getCertificationLevelOptions } from "./utils/getCertificationLevelOptions";
import SelectEnergyRating from "./SelectEnergyRating";
import type { BaseSelectRef } from "rc-select";

type FormValues = Omit<BuildingCertification, "expiresAt" | "createdAt"> & {
  awardedAt: Dayjs;
  expiresAt?: Dayjs;
};

type Props = {
  onClose: () => void;
  buildingId: number;
  buildingCertifications: BuildingCertification[];
  certification: BuildingCertification | null;
};

export function BuildingCertificationModal(props: Props) {
  const t = useTranslations();

  const [keepCreating, setKeepCreating] = useState(false);
  const typeFieldRef = useRef<BaseSelectRef>(null);
  const [form] = Form.useForm<FormValues>();
  const certificationType = Form.useWatch("type", form);
  const certificationLevel = Form.useWatch("level", form);
  const updateCertification = useUpdateBuildingCertification();
  const addCertification = useAddBuildingCertification();
  const removeCertification = useRemoveBuildingCertification();

  function handleSubmit({ awardedAt, expiresAt, ...values }: FormValues) {
    const fromDayjsToDate = (dayjs: Dayjs | undefined) =>
      dayjs ? dayjs.toDate() : null;

    if (props.certification) {
      updateCertification.trigger({
        buildingId: props.buildingId,
        certification: props.certification,
        patch: {
          awardedAt: fromDayjsToDate(awardedAt)!,
          expiresAt: fromDayjsToDate(expiresAt),
          ...values,
        },
      });
    } else {
      addCertification.trigger({
        buildingId: props.buildingId,
        certification: {
          awardedAt: fromDayjsToDate(awardedAt)!,
          expiresAt: fromDayjsToDate(expiresAt),
          ...values,
        },
      });
    }

    form.resetFields();
    typeFieldRef.current?.focus();

    if (!keepCreating) {
      props.onClose();
    }
  }

  async function handleRemove() {
    if (props.certification) {
      await removeCertification.trigger({
        buildingId: props.buildingId,
        certification: props.certification,
      });

      props.onClose();
    }
  }

  return (
    <Modal
      title={
        props.certification
          ? t("settings.certificates.update-certificate")
          : t("settings.certificates.add-new-certificate")
      }
      open
      onCancel={props.onClose}
      destroyOnClose
      footer={[
        <Space key="actions">
          {!props.certification && (
            <Checkbox
              key="keep-creating"
              onClick={() => setKeepCreating(!keepCreating)}
              checked={keepCreating}
            >
              {t("settings.certificates.add-many")}
            </Checkbox>
          )}
          <Button key="cancel" onClick={props.onClose}>
            {t("settings.certificates.cancel")}
          </Button>
          {props.certification && (
            <span key="delete" onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                title={t("settings.certificates.are-you-sure-to-delete")}
                okText={t("settings.certificates.yes")}
                onConfirm={handleRemove}
              >
                <Button danger>{t("settings.certificates.remove")}</Button>
              </Popconfirm>
            </span>
          )}
          <Button key="submit" type="primary" onClick={form.submit}>
            {t("settings.certificates.save")}
          </Button>
        </Space>,
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        id="modal-form"
        initialValues={
          props.certification
            ? {
                ...props.certification,
                awardedAt: dayjs(props.certification.awardedAt),
                expiresAt: props.certification.expiresAt
                  ? dayjs(props.certification.expiresAt)
                  : null,
              }
            : {
                type: allCertifications[0].type,
                awardedAt: dayjs(),
              }
        }
        onFinish={handleSubmit}
      >
        <Form.Item
          name="type"
          label={t("settings.certificates.certificates")}
          rules={[
            { required: true },
            {
              validator(_, value) {
                if (
                  !value ||
                  props.certification?.type === value ||
                  value === "Energimerke"
                ) {
                  return Promise.resolve();
                }

                const isDuplicate = props.buildingCertifications.some(
                  (certification) => certification.type === value,
                );

                if (isDuplicate) {
                  return Promise.reject(
                    new Error(
                      t("settings.certificates.certificate-already-exists"),
                    ),
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Select
            ref={typeFieldRef}
            options={certificationTypeOptions}
            disabled={!!props.certification}
            autoFocus
          />
        </Form.Item>
        <Form.Item
          name="level"
          label={t("settings.certificates.level")}
          rules={[{ required: true }]}
        >
          {certificationType === "Energimerke" ? (
            <SelectEnergyRating
              energyRating={certificationLevel?.[0] ?? "G"}
              heatingRating={Number(certificationLevel?.[1] ?? "0")}
              onSelect={(rating) =>
                form.setFieldValue(
                  "level",
                  rating.energyRating + rating.heatingRating,
                )
              }
            />
          ) : (
            <Select options={getCertificationLevelOptions(certificationType)} />
          )}
        </Form.Item>
        <Form.Item
          name="awardedAt"
          label={t("settings.certificates.awarded-date")}
          rules={[
            {
              validator(_, value: Dayjs | undefined) {
                const expiresAt: Dayjs | undefined =
                  form.getFieldValue("expiresAt");

                if (expiresAt && value && value.isAfter(expiresAt)) {
                  return Promise.reject(
                    new Error(
                      t(
                        "settings.certificates.expiry-date-must-be-after-awarded-date",
                      ),
                    ),
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="expiresAt"
          label={t("settings.certificates.expiry-date")}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="note" label={t("settings.certificates.notes")}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}
