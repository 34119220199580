import { useEffect, useState } from "react";

/**
 * Use this in cases where you don't want to show a loading indicator
 * before at least @param delay has passed
 */
export function useIsLoadingWithDelay(isLoading: boolean, delay = 300) {
  const [isLoadingDelayed, setIsLoadingDelayed] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoadingDelayed(isLoading);
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [delay, isLoading, isLoadingDelayed, setIsLoadingDelayed]);
  return isLoadingDelayed;
}
