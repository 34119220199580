import { Fragment, useMemo, MouseEvent, useState } from "react";
import dayjs from "@properate/dayjs";
import { DatapointAggregate, DatapointAggregates } from "@cognite/sdk";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { useTheme } from "styled-components";
import { PatternLines } from "@visx/pattern";
import { TimeSpan } from "@properate/common";
import { formatNonScientific } from "@/utils/format";
import { HeatMapAggregate, HeatMapViewMode } from "../types";

const DAYS_IN_A_WEEK = 7;
const HOURS_IN_A_DAY = 24;

interface Props {
  timeseriesWithData: DatapointAggregates;
  aggregate: HeatMapAggregate;
  viewMode: HeatMapViewMode;
  timeSpan: TimeSpan;
  graphWidth: number;
  graphHeight: number;
  offsetRight: number;
  offsetTop: number;
  colorRange: [string, string];
  onMouseMove: (
    event: MouseEvent<SVGRectElement>,
    datapoint: DatapointAggregate,
  ) => unknown;
  onMouseLeave: () => unknown;
  compact: boolean;
}

export function HeatMapCalendar({
  timeseriesWithData,
  aggregate,
  viewMode,
  timeSpan,
  graphWidth,
  graphHeight,
  offsetRight,
  offsetTop,
  colorRange,
  onMouseMove,
  onMouseLeave,
  compact,
}: Props) {
  const theme = useTheme();
  const [activeDatapoint, setActiveDatapoint] =
    useState<DatapointAggregate | null>(null);
  const colorScale = useMemo(
    () =>
      timeseriesWithData.datapoints.length > 0
        ? scaleLinear({
            range: colorRange,
            domain: [
              Math.min(
                ...timeseriesWithData.datapoints.map(
                  (datapoint) => datapoint[aggregate]!,
                ),
              ),
              Math.max(
                ...timeseriesWithData.datapoints.map(
                  (datapoint) => datapoint[aggregate]!,
                ),
              ),
            ],
          })
        : null,
    [timeseriesWithData.datapoints, colorRange, aggregate],
  );

  const firstDatapointTimestamp =
    timeseriesWithData.datapoints.length > 0
      ? dayjs(timeseriesWithData.datapoints[0].timestamp)
      : null;

  function getAmountOfRows() {
    if (viewMode === "week") {
      return HOURS_IN_A_DAY;
    }
    const firstDayOfMonth = dayjs(timeSpan[0]).startOf("month");
    const lastDayOfMonth = dayjs(timeSpan[0]).endOf("month");
    return Math.ceil(
      (firstDayOfMonth.isoWeekday() - 1 + lastDayOfMonth.date()) / 7,
    );
  }

  function handleMouseMove(
    event: MouseEvent<SVGRectElement>,
    datapoint: DatapointAggregate,
  ) {
    setActiveDatapoint(datapoint);
    onMouseMove(event, datapoint);
  }

  function handleMouseLeave() {
    setActiveDatapoint(null);
    onMouseLeave();
  }

  function getIsSameIsoWeek(
    datapointTimestamp: dayjs.Dayjs,
    rowNumber: number,
  ) {
    if (firstDatapointTimestamp) {
      if (datapointTimestamp.month() === 0) {
        const isoWeeksPreviousYear = datapointTimestamp
          .subtract(1, "year")
          .isoWeeksInYear();
        if (datapointTimestamp.isoWeek() === isoWeeksPreviousYear) {
          return rowNumber === 0;
        }
        return (
          datapointTimestamp.isoWeek() ===
          rowNumber + (firstDatapointTimestamp.isoWeek() % isoWeeksPreviousYear)
        );
      }
      return (
        datapointTimestamp.isoWeek() ===
        rowNumber + firstDatapointTimestamp.isoWeek()
      );
    }
    return false;
  }

  const amountOfRows = getAmountOfRows();
  const amountOfCols = DAYS_IN_A_WEEK;
  const rectWidth = (graphWidth - offsetRight) / amountOfCols;
  const rectHeight = (graphHeight - offsetTop) / amountOfRows;
  const fontSize =
    viewMode === "month" ? (compact ? 10 : 14) : compact ? 8 : 12;
  const fontSizeDayOfMonth = fontSize - 2;

  const rectangles: Array<Array<null>> = Array(amountOfRows).fill(
    Array(amountOfCols).fill(null),
  );
  const patternMissingDataId = `pattern-missing-data-${timeseriesWithData.id}`;

  return (
    <Group top={offsetTop}>
      <PatternLines
        id={patternMissingDataId}
        height={12}
        width={12}
        stroke={theme.warning}
        orientation={["diagonal"]}
      />
      {rectangles.map((cols, rowNumber) => {
        return cols.map((_, colNumber) => {
          const x = (colNumber % amountOfCols) * rectWidth;
          const y = (rowNumber % amountOfRows) * rectHeight;
          const datapoint = timeseriesWithData.datapoints.find((datapoint) => {
            const datapointTimestamp = dayjs(datapoint.timestamp);
            if (viewMode === "week") {
              return (
                datapointTimestamp.hour() === rowNumber &&
                datapointTimestamp.isoWeekday() - 1 === colNumber
              );
            }
            const isSameIsoWeek = getIsSameIsoWeek(
              datapointTimestamp,
              rowNumber,
            );
            return (
              isSameIsoWeek && datapointTimestamp.isoWeekday() - 1 === colNumber
            );
          });
          const datapointTimestamp = datapoint
            ? dayjs(datapoint.timestamp)
            : null;
          return (
            <Fragment key={`${rowNumber}${colNumber}`}>
              <rect
                x={x}
                y={y}
                width={rectWidth}
                height={rectHeight}
                fill={
                  colorScale && datapoint
                    ? colorScale(datapoint[aggregate]!)
                    : `url(#${patternMissingDataId})`
                }
                cursor={datapoint ? "pointer" : undefined}
                stroke="black"
                strokeWidth={datapoint === activeDatapoint ? 1 : 0}
                onMouseMove={
                  datapoint
                    ? (event) => handleMouseMove(event, datapoint)
                    : undefined
                }
                onMouseLeave={handleMouseLeave}
              />
              {viewMode === "month" && datapointTimestamp && (
                <text
                  x={x + 5}
                  y={y + fontSize}
                  fontSize={fontSizeDayOfMonth}
                  fill={
                    datapointTimestamp.day() === 0
                      ? theme.error
                      : theme.neutral0
                  }
                  pointerEvents="none"
                >
                  {datapointTimestamp.date()}
                </text>
              )}
              {(viewMode === "month" || !compact) && datapoint && (
                <text
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  x={x + rectWidth / 2}
                  y={y + rectHeight / 2}
                  fontSize={fontSize}
                  fill={theme.neutral0}
                  pointerEvents="none"
                >
                  {`${formatNonScientific(datapoint[aggregate]!)}`}
                </text>
              )}
            </Fragment>
          );
        });
      })}
    </Group>
  );
}
