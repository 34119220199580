import { useTranslations } from "@properate/translations";
import { AlarmRuleTypeName, PartialAlarmRule } from "@properate/common";
import { DescriptionListEntry } from "@properate/ui";
import { TimeseriesAccordionBox } from "@/pages/alarms/details/components/TimeseriesDescriptionListAccordionBox";
import {
  AlarmDeviationTable,
  DeviationTableColumn,
} from "@/pages/alarms/details/components/AlarmDeviationTable";
import { AlarmGraphFormFields } from "@/pages/alarms/details/components/AlarmTimeseriesGraph";
import { numberValidator } from "../FormContext/validators";
import { TimeseriesSelector } from "../components/TimeseriesSelector";
import { AlarmsSeparator } from "../components/layout";
import { useFormValue } from "../FormContext/hooks";
import { useAlarmTypeDescription, useTimeseriesWithDatapoint } from "../hooks";
import { getSelectTimeseriesEntries } from "../components/TimeseriesSelector/SelectTimeseries";
import { getSubmitValueEntry } from "../FormContext/utils";
import {
  getConditionOffsetAlarmRuleFields,
  getSelectOffsetFieldDefinitions,
  getTypeSpecificOffsetAlarmRuleFields,
  OffsetMode,
  SelectOffset,
} from "./common/SelectOffset";
import { requiredSelect, tKey } from "./common/utils";
import { AlarmRuleType } from "./index";

export enum TimeseriesThresholdFormFields {
  Timeseries = "TimeseriesThresholdTimeseries",
  SubBuilding = "SubBuilding",
}

export const TimeseriesThresholdDefinition: AlarmRuleType = {
  name: AlarmRuleTypeName.TimeseriesThreshold,
  labelTranslationKey: tKey("timeseries-threshold.type-label"),
  getFormFields: (alarmRule) => {
    return {
      [TimeseriesThresholdFormFields.Timeseries]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.base_timeseries_id ?? undefined,
        getValidator: (t) =>
          numberValidator(
            t(requiredSelect, {
              fieldName: t(
                tKey(`timeseries-threshold.timeseries`),
              ).toLowerCase(),
            }),
          ),
      },
      ...getSelectOffsetFieldDefinitions({
        alarmRule,
        mode: OffsetMode.Threshold,
      }),
      ...getSelectTimeseriesEntries(),
    };
  },
  getAlarmRuleFields: ({ entries }): PartialAlarmRule => {
    return {
      condition: {
        ...getConditionOffsetAlarmRuleFields({ entries }),
        type_specific: {
          ...getTypeSpecificOffsetAlarmRuleFields({ entries }),
          base_timeseries_id: getSubmitValueEntry<number>(
            entries,
            TimeseriesThresholdFormFields.Timeseries,
          ),
        },
      },
    };
  },
  formComponent: <TimeseriesThreshold />,
  summaryContents: {
    generalAlarmMetaFields: <TimeseriesThresholdAlarmMetaFields />,
    typeSpecificSummary: <TimeseriesThresholdTypeSpecificSummary />,
  },
  getDeviationsTable: (props) => (
    <AlarmDeviationTable
      columns={[
        DeviationTableColumn.Timeframe,
        DeviationTableColumn.Value,
        DeviationTableColumn.Threshold,
      ]}
      timeseriesIdFormField={TimeseriesThresholdFormFields.Timeseries}
      {...props}
    />
  ),
};

export function TimeseriesThreshold() {
  const t = useTranslations();
  const [timeseriesId] = useFormValue<number | undefined>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const { timeseries } = useTimeseriesWithDatapoint({
    timeseriesId,
  });
  const [_showGraph, setShowGraph] = useFormValue<boolean>(
    AlarmGraphFormFields.GraphVisible,
  );
  const label = t(tKey(`timeseries-threshold.select-timeseries`));
  return (
    <TimeseriesSelector
      name={TimeseriesThresholdFormFields.Timeseries}
      selectLabel={label}
      displayLabel={label}
      onOpen={() => setShowGraph(false)}
      onClose={() => setShowGraph(true)}
    >
      <AlarmsSeparator direction={"horizontal"} />
      <SelectOffset unit={timeseries?.unit ?? ""} />
    </TimeseriesSelector>
  );
}

export function TimeseriesThresholdAlarmMetaFields() {
  const t = useTranslations("alarm-details.deviations-page.summary");
  const [timeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );

  const typeDescription = useAlarmTypeDescription({
    timeseriesId,
    noBold: true,
  });

  return (
    <DescriptionListEntry
      termColon
      term={t("type")}
      description={typeDescription}
    />
  );
}

export function TimeseriesThresholdTypeSpecificSummary() {
  const [timeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const t = useTranslations();
  return (
    <TimeseriesAccordionBox
      timeseriesId={timeseriesId}
      title={t("alarm-details.alarm-types.timeseries-threshold.timeseries")}
    />
  );
}
