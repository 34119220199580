import { Divider, Drawer, Switch } from "antd";
import { deleteField } from "firebase/firestore";
import { useTranslations } from "@properate/translations";
import { useHelp } from "../context/HelpContext";
import { API_HOST } from "../eepApi";
import { mutateUserSettings, useUserSettings } from "../services/userSettings";

type Props = {
  showHelp: boolean;
  setShowHelp: Function;
};

export function Help({ showHelp, setShowHelp }: Props) {
  const t = useTranslations();
  const { help } = useHelp();
  const { data: preferences } = useUserSettings();

  const onChangeHint = async (showHints: boolean) => {
    await mutateUserSettings({ hintsDisabled: !showHints });
    if (!showHints && preferences?.onboarding) {
      await mutateUserSettings({ onboarding: deleteField() as any });
    }
  };
  const showHints = !preferences?.hintsDisabled;
  return (
    <Drawer
      title={help?.title}
      placement="right"
      closable
      width={520}
      onClose={() => setShowHelp(false)}
      open={showHelp}
    >
      {help?.content}
      <Divider />
      {t("common.help.give-hints")}:{" "}
      <Switch checked={showHints} onChange={onChangeHint} />
      <Divider />
      {t.rich("common.help.properate-description", {
        emailTag: (content) => (
          <a href="mailto:support@properate.com">{content}</a>
        ),
        phoneTag: (content) => <a href="tel:004790557360">{content}</a>,
        email: "support@properate.com",
        phone: "+47 90 55 73 60",
      })}
      <Divider />
      <p>
        {t("common.help.version")}:{" "}
        {process.env.REACT_APP_VERSION || t("common.help.unknown")}
      </p>
      {process.env.REACT_APP_VERSION &&
        process.env.REACT_APP_VERSION.indexOf(" ") > 0 && (
          <>
            <p>{`project-id: ${process.env.REACT_APP_PROJECT_ID}`}</p>
            <p>{`firebase: ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`}</p>
            <p>{`api: ${API_HOST}`}</p>
          </>
        )}
    </Drawer>
  );
}
