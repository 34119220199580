import { Radio, RadioChangeEvent, Select, Space } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { Suspense, useCallback } from "react";
import {
  EnergyFlowV2,
  EnergyFlowPage,
  useEnergyNoteByDateFiltering,
} from "@/features/energy";
import { CompactContent } from "@/components/CompactContent";
import { TimeSpanSelection } from "@/components/TimeSpanSelection";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";

export const Flow = () => {
  const t = useTranslations();

  useBuildingPageTitle(t("energy.energy-flow"));
  const page = useLoaderData() as EnergyFlowPage;
  const navigate = useNavigate();
  const currentBuilding = useCurrentBuilding();

  useEnergyNoteByDateFiltering(page.start, page.end);

  const setOfIds = useCallback((assetIds: number[]) => new Set(assetIds), []);

  const handleRadioChange = (event: RadioChangeEvent) => {
    navigate(
      `../${
        event.target.value
      }/s/${page.start.valueOf()}/e/${page.end.valueOf()}?root=${
        page.tenantRootAssetId
      }`,
    );
  };

  return (
    <>
      <PageHeader
        title={t("energy.energy-flow")}
        extra={
          <Space>
            {[
              <Radio.Group
                onChange={handleRadioChange}
                value={page.type}
                key="type"
              >
                <Radio.Button value="energyFlow">
                  {t("energy.consumption")}
                </Radio.Button>
                <Radio.Button
                  data-testid={
                    page.type === "energySourceFlow"
                      ? "energySourceFlow-selected"
                      : "energySourceFlow"
                  }
                  value="energySourceFlow"
                >
                  {t("energy.source")}
                </Radio.Button>
              </Radio.Group>,
              <TimeSpanSelection
                granularity={"d"}
                key="timeSpan"
                onChange={([start, end]) => {
                  navigate(
                    `../${page.type}/s/${start}/e/${end}?root=${page.tenantRootAssetId}`,
                  );
                }}
                value={[page.start.valueOf(), page.end.valueOf()]}
                allowClear={false}
              />,
              <Suspense
                key="organization"
                fallback={
                  <div className="h-full flex justify-center items-center">
                    <SpinnerWithDelay isLoading>
                      <div />
                    </SpinnerWithDelay>
                  </div>
                }
              >
                <Await resolve={page.organizations}>
                  {(organizations: { name: string; id: number }[]) => (
                    <Select
                      style={{ width: 200 }}
                      options={organizations.map((organization) => ({
                        label: `${organization.name || ""} (${
                          organization === organizations[0]
                            ? t("energy.owner")
                            : t("energy.tenant")
                        })`,
                        value: organization.id,
                      }))}
                      value={page.tenantRootAssetId}
                      onChange={(value) => {
                        navigate(
                          `../${
                            page.type
                          }/s/${page.start.valueOf()}/e/${page.end.valueOf()}?root=${value}`,
                        );
                      }}
                    />
                  )}
                </Await>
              </Suspense>,
              <ToggleSidebarButton
                key="notes"
                hiddenWhenSidebarVisible
                sidebarHiddenContent={t("notes.show-notes-button")}
              />,
            ]}
          </Space>
        }
      />
      <CompactContent>
        <Suspense
          fallback={
            <div className="h-full flex justify-center items-center">
              <SpinnerWithDelay isLoading>
                <div />
              </SpinnerWithDelay>
            </div>
          }
        >
          <Await resolve={page.assetIds}>
            {(assetIds) => {
              return (
                <>
                  <EnergyFlowV2 />
                  {currentBuilding.dataSetId && (
                    <NotesSidebar
                      noteSource={NoteSource.WEB_ENERGY}
                      buildings={[
                        {
                          id: currentBuilding.dataSetId,
                          name: currentBuilding.name,
                        },
                      ]}
                      assetFilterMode={NotesAssetFilterMode.TimeseriesList}
                      idSet={setOfIds(assetIds)}
                    />
                  )}
                </>
              );
            }}
          </Await>
        </Suspense>
      </CompactContent>
    </>
  );
};
