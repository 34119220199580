import { Timeseries } from "@cognite/sdk";
import { useRootAssets } from "@/hooks/useRootAssets";

export function useSelectedBuildings(
  timeseriesList?: (Timeseries | undefined)[],
) {
  const rootAssets = useRootAssets();
  return rootAssets.filter((building) =>
    (timeseriesList ?? []).some(
      (timeseries) => timeseries?.dataSetId === building.dataSetId,
    ),
  );
}
