import { formatUnit } from "@properate/common";
import { formatNonScientific } from "@/utils/format";
import { GaugeZones } from "../../../types";
import { getActiveZone } from "../../../utils";
import { GaugeNumberContainer } from "./elements";

interface Props {
  zones: GaugeZones;
  value: number;
  unit?: string;
}

export function GaugeNumber({ zones, value, unit }: Props) {
  const activeZone = getActiveZone(zones, value);
  return (
    <GaugeNumberContainer $color={activeZone.color}>{`${formatNonScientific(
      value,
    )}${formatUnit(unit)}`}</GaugeNumberContainer>
  );
}
