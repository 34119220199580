"use client";

import { AccordionEntry, AccordionEntryId, AccordionOptions } from "./types";

export function markAllAsClosed(entries: AccordionEntry[]) {
  return entries.map((entry) => {
    if (!entry.open) {
      return entry;
    }
    return {
      ...entry,
      open: false,
    };
  });
}

export function setOpenStateForIndex(
  index: number,
  entries: AccordionEntry[],
  open: boolean,
  options: AccordionOptions,
) {
  const newEntries = options.allowMultipleOpen
    ? [...entries]
    : markAllAsClosed(entries);
  newEntries.splice(index, 1, {
    ...newEntries[index],
    open,
  });
  return newEntries;
}

export function getIndexForId(
  entries: AccordionEntry[],
  id: AccordionEntryId,
  throwIfNotFound = true,
): number {
  const index = entries.findIndex(({ uniqueId }) => uniqueId === id);
  if (index < 0 && throwIfNotFound) {
    throw new Error(`Cannot find accordion entry with id: ${id}`);
  }
  return index;
}

export function setOpenStateForId(
  id: AccordionEntryId,
  entries: AccordionEntry[],
  open: boolean,
  options: AccordionOptions,
  createDummyIfMissing = false,
) {
  let index = getIndexForId(entries, id, !createDummyIfMissing);
  const updatedEntries = [...entries];
  if (createDummyIfMissing && index === -1) {
    updatedEntries.push({
      title: "--",
      uniqueId: id,
      textContent: "--",
      open,
    });
    index = updatedEntries.length - 1;
  }
  return setOpenStateForIndex(index, updatedEntries, open, options);
}
