"use client";

import {
  getAnalytics,
  logEvent,
  setUserProperties,
  setUserId,
} from "firebase/analytics";
import { createContext, useEffect } from "react";
import { usePossiblyUser } from "@properate/auth";
import { app } from "@properate/firebase";

/**
 * This is a list of all the common events we track across all apps. Please add
 * to this list if you want to track something new.
 *
 * If the event is specific to a single app, you can override the second type
 * parameter to add the custom event list.
 */
type Events = {
  page_view: {
    page_path: string;
    page_title: string;
    page_location: string;
  };
};

export function track<N extends keyof E, E = Events>(name: N, params: E[N]) {
  logEvent(
    getAnalytics(app),
    name as string,
    params as Record<string, unknown>,
  );
}

const AnalyticsContext = createContext(null);

export function AnalyticsContextProvider(props: { children: React.ReactNode }) {
  const auth = usePossiblyUser();

  useEffect(() => {
    const analytics = getAnalytics(app);

    async function sha256(str: string) {
      const buf = await crypto.subtle.digest(
        "SHA-256",
        new TextEncoder().encode(str),
      );

      return Array.prototype.map
        .call(new Uint8Array(buf), (x) => ("00" + x.toString(16)).slice(-2))
        .join("");
    }

    if (typeof auth.email === "string") {
      sha256(auth.email)
        .then((userId) => {
          const owner =
            auth.ownerAlias === "all" ? "Properate" : auth.ownerAlias;

          setUserId(analytics, userId);
          setUserProperties(analytics, { owner });
        })
        .catch((error) => {
          console.error(
            `Failed to attach the user ID to analytics: \n${error}`,
          );
        });
    }
  }, [auth.email, auth.ownerAlias]);

  return (
    <AnalyticsContext.Provider value={null}>
      {props.children}
    </AnalyticsContext.Provider>
  );
}
