import { AlarmRule, getSimplifiedPriority } from "@properate/common";
import { TableColumnsType } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { sortString } from "@properate/ui";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { LineClamp } from "@/components/LineClamp";

type Props = {
  alarms: AlarmRule[];
  isLoading: boolean;
};

export const AlarmsSimpleTable = ({ alarms, isLoading }: Props) => {
  const t = useTranslations();

  const columns: TableColumnsType<AlarmRule> = [
    {
      key: "priority",
      dataIndex: "priority",
      title: t("org-alarms.table-columns.severity"),
      width: 90,
      align: "center",
      render: (priority) =>
        t(
          `org-alarms.priority.${getSimplifiedPriority(
            priority,
          ).toLowerCase()}` as MessageKey,
        ),
    },
    {
      title: t("org-alarms.table-columns.name"),
      key: "name",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      width: 220,
      render: (name) => {
        return name && <LineClamp title={name}>{name}</LineClamp>;
      },
    },
    {
      title: t("org-alarms.table-columns.description"),
      key: "instruction",
      dataIndex: "instruction",
      render: (instruction) =>
        instruction !== undefined && (
          <LineClamp title={instruction}>{instruction}</LineClamp>
        ),
    },
    {
      title: t("org-alarms.table-columns.category"),
      key: "sub_category",
      dataIndex: "sub_category",
      width: 150,
      render: (subCategory) =>
        subCategory && (
          <LineClamp
            title={t(
              `tfm.system.buildingPartNumber.${subCategory}` as MessageKey,
            )}
          >
            {t(`tfm.system.buildingPartNumber.${subCategory}` as MessageKey)}
          </LineClamp>
        ),
    },
    {
      title: t("org-alarms.table-columns.timeseries"),
      key: "timeseries_name",
      dataIndex: "timeseries_name",
      width: 220,
      sorter: (
        { timeseries_name: timeseriesOne },
        { timeseries_name: timeseriesTwo },
      ) => sortString(timeseriesOne, timeseriesTwo),
      render: (timeseriesName) =>
        timeseriesName && (
          <LineClamp title={timeseriesName}>{timeseriesName}</LineClamp>
        ),
    },
  ];

  return (
    <TableWithoutDefaultSort
      loading={isLoading}
      virtual
      columns={columns}
      pagination={false}
      dataSource={alarms}
      rowKey="alarm_id"
      rowIsHoverable
      size="small"
    />
  );
};
