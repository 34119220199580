import useSWR from "swr";
import { IdEither } from "@cognite/sdk";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useAssetIdSet } from "./useAssetIdSet";

export function useAssetList() {
  const { client } = useCogniteClient();
  const { assetIdSet, error: timeseriesError } = useAssetIdSet();

  const assetIdList = assetIdSet ? [...assetIdSet] : null;

  const {
    data,
    error: assetError,
    isLoading,
    isValidating,
    ...rest
  } = useSWR(assetIdList, (assetIdList) => {
    if (assetIdList.length === 0) {
      return [];
    }
    const retrieveFilter: IdEither[] = [];
    assetIdList.forEach((id) => {
      if (id !== undefined) {
        retrieveFilter.push({ id });
      }
    });
    return client.assets.retrieve(retrieveFilter, {
      ignoreUnknownIds: true,
    });
  });

  const assetList = data ?? (isLoading || isValidating ? data : []);

  return {
    assetList,
    error: timeseriesError || assetError || null,
    isLoading,
    isValidating,
    ...rest,
  };
}
