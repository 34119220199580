import { useFormValue } from "@/pages/alarms/details/FormContext";
import { TimeseriesThresholdFormFields } from "@/pages/alarms/details/AlarmRuleTypes/TimeseriesThreshold";
import { useSubBuildingsForCurrentType } from "@/pages/alarms/details/hooks/useSubBuildingsForCurrentType";

export function useAlarmTimeseriesForCurrentSubBuilding() {
  const { subBuildings, isLoading, error } = useSubBuildingsForCurrentType();
  const [subBuildingId] = useFormValue<number>(
    TimeseriesThresholdFormFields.SubBuilding,
  );

  if (isLoading || error) {
    return { isLoading, error, timeseriesForCurrentSubBuilding: [] };
  }

  const currentSubBuilding = (subBuildings || []).find(
    ({ id }) => id === subBuildingId,
  );

  const timeseriesForCurrentSubBuilding = currentSubBuilding?.timeseries ?? [];
  const currentSubBuildingAvailable = currentSubBuilding?.available ?? false;

  return {
    isLoading,
    error,
    timeseriesForCurrentSubBuilding,
    currentSubBuildingAvailable,
  };
}
