import * as React from "react";
import { Col, Row, Select, Space } from "antd";
import { useTranslations } from "@properate/translations";
import { ColoredProperateCalendar } from "@/utils/types";
import { getItemByProp } from "@/utils/array";

type Props = {
  selected: ColoredProperateCalendar;
  setSelected: (calendar: ColoredProperateCalendar) => void;
  calendars: ColoredProperateCalendar[];
  calendarMap: Record<string, ColoredProperateCalendar>;
};

export const SelectCalendar = ({
  selected,
  setSelected,
  calendars,
  calendarMap,
}: Props) => {
  const t = useTranslations();

  const getDescription = (ts: ColoredProperateCalendar) => {
    return (
      calendarMap[ts.calendar_id].extended_description ||
      `${ts.system} ${ts.name} ${ts.description}`
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Space>
          {t("calendar.select-facility")}{" "}
          <Select
            size="large"
            style={{ width: 404 }}
            value={selected.calendar_id}
            onSelect={(id: string) => {
              const newCal = getItemByProp(calendars, id, "calendar_id");
              setSelected(newCal);
            }}
            options={calendars
              .sort((a, b) =>
                getDescription(a).localeCompare(getDescription(b)),
              )
              .map((cal) => ({
                value: cal.calendar_id,
                label: (
                  <Space>
                    <span
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: 2,
                        background: cal.color,
                        display: "inline-block",
                      }}
                    />{" "}
                    {getDescription(cal)}
                  </Space>
                ),
              }))}
          />
        </Space>
      </Col>
    </Row>
  );
};
