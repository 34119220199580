import { Popconfirm, Button, Space } from "antd";
import { PageHeader } from "@ant-design/pro-layout";

import {
  RightOutlined,
  DeleteOutlined,
  DotChartOutlined,
  LineChartOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { TimeSpan } from "@properate/common";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useMemo } from "react";
import { TimeSpanSelection } from "@/components/TimeSpanSelection";
import { NoteCreationModeButton, useAssetList } from "@/features/notes";
import { Granularity } from "@/utils/helpers";
import {
  AnalysisScatterplotNoteCreationCustomContent,
  AnalysisSimpleGraphNoteCreationCustomContent,
} from "@/pages/AnalysisDetails/NoteCreationModeCustomContent";
import {
  Analysis,
  ScatterplotAnalysis,
  SelectedAnalysisPoints,
  SetSelectedAnalysisPoints,
} from "../../types";
import {
  isScatterplotAnalysis,
  isScatterplotAnalysisWithSettingsTimeseriesX,
} from "../../utils";
import { useDownloadTimeseriesData, useAnalysisTypeRoute } from "../../hooks";
import { GranularitySelection } from "./GranularitySelection";

interface Props {
  analysis: Analysis;
  buildingId: number;
  timeSpan: TimeSpan;
  zoomedTimeSpan: TimeSpan;
  onConfirmDeletion: () => unknown;
  onChangeZoomedTimeSpan: (value: TimeSpan) => unknown;
  onChangeAnalysis: (value: Partial<ScatterplotAnalysis>) => unknown;
  setSelectedPoints: SetSelectedAnalysisPoints;
  selectedPoints: SelectedAnalysisPoints;
}

export function AnalysisHeader({
  analysis,
  buildingId,
  timeSpan,
  zoomedTimeSpan,
  onConfirmDeletion,
  onChangeZoomedTimeSpan,
  onChangeAnalysis,
  selectedPoints,
  setSelectedPoints,
}: Props) {
  const t = useTranslations();

  const { assetList } = useAssetList();

  const assetListIds = useMemo(
    () => assetList?.map(({ id }) => id) ?? [],
    [assetList],
  );
  const isScatterplotAnalysisWithTimeseriesX =
    isScatterplotAnalysisWithSettingsTimeseriesX(analysis);

  const type = useAnalysisTypeRoute();
  const timeseriesIds = analysis.settingsTimeseriesList
    .map(({ id }) => ({ id }))
    .concat(
      isScatterplotAnalysisWithTimeseriesX
        ? { id: analysis.settingsTimeseriesX.id }
        : [],
    );
  const { isLoading: isLoadingDownload, triggerDownload } =
    useDownloadTimeseriesData(
      analysis.title || t("analysis.no-name"),
      [
        ...analysis.settingsTimeseriesList,
        ...(isScatterplotAnalysisWithTimeseriesX
          ? [analysis.settingsTimeseriesX]
          : []),
      ],
      {
        start: zoomedTimeSpan[0],
        end: zoomedTimeSpan[1],
      },
    );

  const granularity = useMemo(
    () =>
      (isScatterplotAnalysis(analysis)
        ? analysis.granularity
        : "d") as Granularity,
    [analysis],
  );

  const zoomedStartDate = zoomedTimeSpan[0];
  const zoomedEndDate = zoomedTimeSpan[1];

  const startDate = useMemo(() => new Date(zoomedStartDate), [zoomedStartDate]);
  const endDate = useMemo(() => new Date(zoomedEndDate), [zoomedEndDate]);

  return (
    <PageHeader
      title={
        <Space>
          <Link to={`/asset/${buildingId}/analysis/${type}`}>
            {type === "simple"
              ? t("analysis.timeseries-graph.title")
              : t("analysis.xy-graph.title")}
          </Link>
          <RightOutlined />
        </Space>
      }
      subTitle={
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span data-testid="analysis-title">
            {analysis.title || t("analysis.no-name")}
          </span>
          {isScatterplotAnalysis(analysis) ? (
            <DotChartOutlined title={t("analysis.xy-graph.title")} />
          ) : (
            <LineChartOutlined title={t("analysis.timeseries-graph.title")} />
          )}
        </div>
      }
      extra={
        <>
          {isScatterplotAnalysis(analysis) && (
            <GranularitySelection
              granularity={analysis.granularity}
              onChangeGranularity={(granularity) =>
                onChangeAnalysis({ granularity })
              }
            />
          )}
          <TimeSpanSelection
            value={zoomedTimeSpan}
            notBefore={timeSpan[0]}
            onChange={onChangeZoomedTimeSpan}
            allowClear={false}
          />
          {timeseriesIds.length > 0 && (
            <Button
              icon={<DownloadOutlined />}
              onClick={triggerDownload}
              loading={isLoadingDownload}
              title={
                isLoadingDownload
                  ? t("analysis.details.downloading")
                  : t("analysis.details.download-for-period")
              }
            />
          )}
          <Popconfirm
            title={t("analysis.are-you-sure-to-delete")}
            onConfirm={onConfirmDeletion}
            okText={t("analysis.delete")}
          >
            <Button
              data-testid="analysis-delete-button"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
          {!!timeseriesIds.length && (
            <>
              <NoteCreationModeButton
                key="selection-mode"
                granularity={granularity}
                startDate={startDate}
                endDate={endDate}
                assetListIds={assetListIds}
                selectedPoints={selectedPoints}
                setSelectedPoints={setSelectedPoints}
                DefaultCustomContent={
                  isScatterplotAnalysisWithTimeseriesX
                    ? AnalysisScatterplotNoteCreationCustomContent
                    : AnalysisSimpleGraphNoteCreationCustomContent
                }
              />
              <ToggleSidebarButton
                key="notes"
                hiddenWhenSidebarVisible
                sidebarHiddenContent={t("notes.show-notes-button")}
              />
            </>
          )}
        </>
      }
    />
  );
}
