import styled from "styled-components";
import { Button } from "antd";
import { DEFAULT_GLOBAL_THEME } from "../../../theme/graph";

export const AddLabelButtonStyled: typeof Button = styled(Button)`
  background-color: ${DEFAULT_GLOBAL_THEME.color.YELLOW};
  color: ${(props) => props.theme.neutral10};
`;

export const AddResourceButtonStyled: typeof Button = styled(Button)`
  background-color: ${DEFAULT_GLOBAL_THEME.color.LIGHT_BLUE};
  color: ${(props) => props.theme.neutral10};
`;

export const TableWithActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Name = styled.span`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  em {
    background-color: #ffc069;
    font-style: normal;
  }
`;

export const DisplayBlock = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;
