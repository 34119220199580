import {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Await, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import dayjs from "@properate/dayjs";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Col, Row, Space } from "antd";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { useTooltip } from "@visx/tooltip";
import { max } from "d3-array";
import Color from "color";
import { scaleOrdinal } from "@visx/scale";
import {
  DownloadOutlined,
  LineChartOutlined,
  SwapOutlined,
  TableOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import {
  ToggleSidebarButton,
  useAccordionValues,
  useSidebarData,
} from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { getTypeNameKey, getTypePath } from "@/pages/energy/unit";
import { useActionOrLoaderData } from "@/hooks/useActionOrLoaderData";
import {
  EnergyComparePage,
  EnergyGraph,
  CompareTable,
  GraphLegend,
  DifferenceChart,
  LoadingArray,
  SelectGranularity,
  GraphTitle,
  GraphTitleRange,
  SelectEnergy,
  CompareToPicker,
  getTickValues,
  getClosestGranularity,
  TooltipData,
  getColorsForAssets,
  ENERGY_NOTE_FILTER_ID,
  useEnergyNoteByDateFiltering,
  getNotePeriods,
  NoteOperationalPeriods,
  SelectedEnergyPoints,
  DESCRIPTION_LEGEND_VALUES,
  Legend,
} from "@/features/energy";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { formatDateYearAccordingToGranularityShort } from "@/utils/helpers";
import { TimeSpanSelection } from "@/components/TimeSpanSelection";
import { NextEnergyGraph } from "@/pages/energy/NextEnergyGraph";
import {
  NoteBuilding,
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
  NotesSidebarValues,
  NotesSidebarViewState,
  useAssetList,
  useNotes,
  NoteCreationModeButton,
} from "@/features/notes";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { EnergyNoteCreationModeCustomContent } from "@/pages/energy/EnergyNoteCreationModeCustomContent";

export const EnergyCompare = ({ table }: { table?: boolean }) => {
  const t = useTranslations();
  useBuildingPageTitle(t("energy.energy-comparison"));

  const { viewState } = useSidebarData<NotesSidebarValues>();
  const [selectedPoints, setSelectedPoints] =
    useState<SelectedEnergyPoints>(null);
  const { assetList } = useAssetList();
  const assetListIds = useMemo(
    () => assetList?.map(({ id }) => id) ?? [],
    [assetList],
  );

  const [showHolidays, setShowHolidays] = useState(true);
  const [showNonOperational, setShowNonOperational] = useState(true);
  const [showTemperature, setShowTemperature] = useState(true);
  const [showNotes, setShowNotes] = useState(true);
  const themeContext = useContext(ThemeContext);
  const [highlightedLegend, setHighlightedLegend] = useState<string | null>(
    null,
  );
  const page = useActionOrLoaderData<EnergyComparePage>();
  const [showActiveMeter, setShowActiveMeter] = useState(false);
  const navigate = useNavigate();
  const { showTooltip, tooltipOpen, tooltipData, hideTooltip } =
    useTooltip<TooltipData>();
  const currentBuilding = useCurrentBuilding();
  const { filters } = useAccordionValues();

  const isFiltering = filters.get(ENERGY_NOTE_FILTER_ID)?.active ?? false;

  useEnergyNoteByDateFiltering(page.start, page.end);

  useEffect(() => {
    if (!isFiltering) {
      setHighlightedLegend(null);
    }
  }, [isFiltering]);

  const colors = useMemo(
    () =>
      [
        themeContext.accent2,
        themeContext.accent3,
        themeContext.accent4,
        themeContext.accent5,
        themeContext.accent6,
        themeContext.accent7,
        themeContext.accent8,
        themeContext.accent9,
        themeContext.accent10,
      ].reverse(),
    [themeContext],
  );
  const WEATHER_COLOR = themeContext.neutral0;
  const NON_OPERATIONAL_COLOR = Color(themeContext.accent1)
    .alpha(0.8)
    .toString();
  const HOLIDAY_COLOR = themeContext.neutral4;
  const NOTE_COLOR = themeContext.accent12;

  const operationalScale = useMemo(
    () =>
      scaleOrdinal<string, string>({
        domain: [
          DESCRIPTION_LEGEND_VALUES.nonOperational,
          DESCRIPTION_LEGEND_VALUES.holidays,
          DESCRIPTION_LEGEND_VALUES.temperature,
          DESCRIPTION_LEGEND_VALUES.hasNote,
        ],
        range: [
          NON_OPERATIONAL_COLOR,
          HOLIDAY_COLOR,
          WEATHER_COLOR,
          NOTE_COLOR,
        ],
      }),
    [NON_OPERATIONAL_COLOR, HOLIDAY_COLOR, WEATHER_COLOR, NOTE_COLOR],
  );

  const colorsForAssets: Record<string, string> = useMemo(() => {
    return getColorsForAssets(colors, page.selectedIds);
  }, [colors, page.selectedIds]);

  const bands = useMemo(() => {
    const bands = [];
    let current = page.start;
    while (current < page.end) {
      bands.push(current);
      current = dayjs(current).add(1, page.granularity).toDate();
    }
    return bands;
  }, [page.start, page.end, page.granularity]);

  const tickValues = useMemo(
    () => getTickValues(page.start, page.end, page.granularity),
    [page.start, page.end, page.granularity],
  );

  const downloadReport = useCallback(async () => {
    const energy = (await page.data) || [];
    const legend = await page.legend;

    if (energy.length === 0) {
      console.error("no data to download");
      return;
    }

    const excelRows = energy.map((entry) => {
      return {
        [t("energy.report.date-a")]: formatDateYearAccordingToGranularityShort[
          page.granularity
        ](entry.timestamp),
        [t("energy.report.date-b")]: formatDateYearAccordingToGranularityShort[
          page.granularity
        ](entry["next-timestamp"]),
        [t("energy.report.temperature-a")]: entry.temperature,
        [t("energy.report.temperature-b")]: entry["next-temperature"],
        ...legend.reduce(
          (prev, legend) => ({
            ...prev,
            [legend.name + " A"]: entry[legend.name],
            [legend.name + " B"]: entry["next-" + legend.name],
          }),
          {},
        ),
        [t("energy.report.total-a")]: entry.total,
        [t("energy.report.total-b")]: entry["next-total"],
      };
    });

    const sheetName = dayjs(energy[0].timestamp).format("DD-MM-YYYY");
    const worksheet = XLSX.utils.json_to_sheet(excelRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    XLSX.writeFile(
      workbook,
      `${t("energy.report.energy-data")} ${sheetName}.xlsx`,
    );
  }, [page, t]);

  const colorScale = useCallback(
    (legend: Legend) =>
      scaleOrdinal<string, string>({
        domain: legend.map((l) => l.name),
        range: colors,
      }),
    [colors],
  );

  const legendKeys = useCallback(
    (legend: Legend) => legend.map((l) => l.name),
    [],
  );

  const inAFewDays = useMemo(
    () => dayjs().endOf("d").add(5, "d").valueOf(),
    [],
  );

  const selectedSet = useMemo(
    () => new Set(page.selectedIds),
    [page.selectedIds],
  );

  const buildings: NoteBuilding[] = useMemo(
    () => [
      {
        id: currentBuilding.dataSetId,
        name: currentBuilding.name,
      } as NoteBuilding,
    ],
    [currentBuilding.dataSetId, currentBuilding.name],
  );

  const { notes } = useNotes();

  const notesDataPeriods: NoteOperationalPeriods[] = useMemo(() => {
    return getNotePeriods({
      granularity: page.granularity,
      startDate: bands[0],
      endDate: bands[bands.length - 1],
      notes,
    });
  }, [bands, notes, page.granularity]);

  const hasNoteByData = useCallback(
    (timestamp: number) => {
      return notesDataPeriods.some(
        (period) => period.start <= timestamp && period.end >= timestamp,
      );
    },
    [notesDataPeriods],
  );

  const promiseData = useMemo(
    () => Promise.all([page.data, page.legend]),
    [page.data, page.legend],
  );

  return (
    <>
      <PageHeader
        title={t("energy.energy-comparison")}
        extra={
          <Space>
            {[
              <TimeSpanSelection
                granularity={page.granularity}
                key="timeSpan"
                notAfter={inAFewDays}
                onChange={([start, end]) => {
                  const granularityStart = dayjs(start).startOf(
                    page.granularity,
                  );

                  const granularityEnd = dayjs(end).endOf(page.granularity);

                  const nextStart = getClosestGranularity(
                    granularityStart,
                    granularityStart.subtract(1, "year"),
                    page.granularity,
                  );

                  const nextEnd = nextStart
                    .add(
                      granularityEnd.diff(granularityStart, page.granularity),
                      page.granularity,
                    )
                    .endOf(page.granularity);

                  navigate(
                    `../energyCompare/${getTypePath(page.type)}/${
                      page.granularity
                    }/s/${granularityStart.valueOf()}/e/${granularityEnd.valueOf()}/s/${nextStart.valueOf()}/e/${nextEnd.valueOf()}${
                      table !== undefined ? "/table" : ""
                    }?root=${page.tenantRootAssetId}&${page.selectedIds
                      .map((id) => "id=" + id)
                      .join("&")}`,
                  );
                }}
                value={[page.start.valueOf(), page.end.valueOf()]}
                allowClear={false}
              />,
              <Space key="compare">
                <div>{t("energy.comparing-to-period-starting")}:</div>
                <CompareToPicker
                  value={page.startNext}
                  onChange={(nextStart) => {
                    const hourDiff = dayjs(page.end).diff(page.start, "hour");
                    const nextEnd = dayjs(nextStart).add(hourDiff, "hour");

                    navigate(
                      `../energyCompare/${getTypePath(page.type)}/${
                        page.granularity
                      }/s/${page.start.valueOf()}/e/${page.end.valueOf()}/s/${nextStart.valueOf()}/e/${nextEnd.valueOf()}?root=${
                        page.tenantRootAssetId
                      }&${page.selectedIds.map((id) => "id=" + id).join("&")}`,
                    );
                  }}
                  currentStart={page.start}
                  granularity={page.granularity}
                />
              </Space>,
              <SelectGranularity
                key="granularity"
                granularity={page.granularity}
                setGranularity={(granularity) => {
                  navigate(
                    `../energyCompare/${getTypePath(
                      page.type,
                    )}/${granularity}/s/${page.start.valueOf()}/e/${page.end.valueOf()}/s/${page.startNext.valueOf()}/e/${page.endNext.valueOf()}?root=${
                      page.tenantRootAssetId
                    }&${page.selectedIds.map((id) => "id=" + id).join("&")}`,
                  );
                }}
              />,
              <Button
                onClick={() => {
                  setShowActiveMeter(true);
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                  }
                }}
                type={"default"}
                key="meter"
                style={{
                  maxWidth: 250,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {t("energy.selected-meter", {
                  meter: t(getTypeNameKey(page.type)),
                })}
              </Button>,
              <NoteCreationModeButton
                key="note-creation"
                setSelectedPoints={setSelectedPoints}
                selectedPoints={selectedPoints}
                assetListIds={assetListIds}
                granularity={page.granularity}
                startDate={page.start}
                endDate={page.end}
                DefaultCustomContent={EnergyNoteCreationModeCustomContent}
              />,
              <ToggleSidebarButton
                key="notes"
                hiddenWhenSidebarVisible
                sidebarHiddenContent={t("notes.show-notes-button")}
              />,
            ]}
          </Space>
        }
      />
      <div
        style={{
          flex: "auto",
          marginBottom: 24,
          marginRight: 16,
          display: "flex",
          flexDirection: "column",
          background: themeContext.background2,
          padding: 12,
          borderRadius: 16,
        }}
      >
        {table ? (
          <div
            style={{
              flexGrow: 2,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <Row>
              <Col flex="auto">
                <GraphTitle
                  title={
                    <Space>
                      <span style={{ color: themeContext.primary }}>
                        {`${dayjs(page.start).format("DD.MM.YYYY")} - ${dayjs(
                          page.end,
                        ).format("DD.MM.YYYY")}`}
                      </span>
                      <SwapOutlined />
                      {`${dayjs(page.startNext).format("DD.MM.YYYY")} - ${dayjs(
                        page.endNext,
                      ).format("DD.MM.YYYY")}`}
                    </Space>
                  }
                />
              </Col>
              <Col flex="none">
                <Space>
                  <Button
                    onClick={() => {
                      navigate(
                        `../energyCompare/${getTypePath(page.type)}/${
                          page.granularity
                        }/s/${page.start.valueOf()}/e/${page.end.valueOf()}/s/${page.startNext.valueOf()}/e/${page.endNext.valueOf()}?root=${
                          page.tenantRootAssetId
                        }&${page.selectedIds
                          .map((id) => "id=" + id)
                          .join("&")}`,
                      );
                    }}
                    icon={<LineChartOutlined />}
                  >
                    {t("energy.show-as-graph")}
                  </Button>
                  <Button icon={<DownloadOutlined />} onClick={downloadReport}>
                    {t("energy.download-as-excel-file")}
                  </Button>
                </Space>
              </Col>
            </Row>
            <div style={{ flexGrow: 1 }}>
              <AutoSizer>
                {({ width, height }: Size) => (
                  <Suspense
                    fallback={
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <SpinnerWithDelay isLoading>
                          <div />
                        </SpinnerWithDelay>
                      </div>
                    }
                  >
                    <Await resolve={promiseData}>
                      {([data, legend]) => {
                        return (
                          <CompareTable
                            data={data}
                            granularity={page.granularity}
                            legend={legend}
                            width={width}
                            height={height}
                            hasNoteByData={hasNoteByData}
                          />
                        );
                      }}
                    </Await>
                  </Suspense>
                )}
              </AutoSizer>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                flexGrow: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row>
                <Col flex="auto">
                  <GraphTitleRange startDate={page.start} endDate={page.end} />
                </Col>
                <Col flex="none">
                  <Space>
                    <Button
                      onClick={() => {
                        navigate(
                          `table?root=${
                            page.tenantRootAssetId
                          }&${page.selectedIds
                            .map((id) => "id=" + id)
                            .join("&")}`,
                        );
                      }}
                      icon={<TableOutlined />}
                    >
                      {t("energy.show-as-table")}
                    </Button>
                    <Button
                      icon={<DownloadOutlined />}
                      onClick={downloadReport}
                    >
                      {t("energy.download-as-excel-file")}
                    </Button>
                  </Space>
                </Col>
              </Row>
              <div style={{ flexGrow: 1 }}>
                <AutoSizer>
                  {({ width, height }: Size) => {
                    if (width === window.innerWidth - 48 - 16) {
                      return <></>;
                    }
                    return (
                      <Suspense
                        fallback={
                          <div
                            style={{
                              height: height,
                              width: width,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <SpinnerWithDelay isLoading>
                              <div />
                            </SpinnerWithDelay>
                          </div>
                        }
                      >
                        <Await
                          resolve={promiseData}
                          errorElement={
                            <LoadingArray height={height} width={width} />
                          }
                        >
                          {([data, legend]: [
                            Record<string, number>[],
                            Legend,
                          ]) => {
                            const m = max(data, (d) =>
                              Math.max(
                                d.total || Number.MIN_VALUE,
                                d["next-total"] || Number.MIN_VALUE,
                              ),
                            )!;
                            return (
                              <>
                                <EnergyGraph
                                  width={width}
                                  height={height}
                                  bands={bands}
                                  max={m}
                                  nonOperationalPeriods={
                                    page.nonOperationalPeriods
                                  }
                                  holidayPeriods={page.holidayPeriods}
                                  tickValues={tickValues}
                                  graphPoints={data}
                                  onShowTooltip={showTooltip}
                                  tooltipOpen={tooltipOpen}
                                  tooltipData={tooltipData}
                                  onHideTooltip={hideTooltip}
                                  colorScale={colorScale(legend)}
                                  operationalScale={operationalScale}
                                  showHolidays={showHolidays}
                                  showTemperature={showTemperature}
                                  showEPred={false}
                                  showNonOperational={showNonOperational}
                                  legend={legendKeys(legend)}
                                  highlightedLegend={highlightedLegend}
                                  showNotes={showNotes}
                                  noteInfo={{
                                    notesDataPeriods,
                                    isNoteModeOn:
                                      viewState ===
                                      NotesSidebarViewState.customContent,
                                    setSelectedPoints: setSelectedPoints,
                                    selectedPoints: selectedPoints,
                                  }}
                                  granularity={page.granularity}
                                />
                              </>
                            );
                          }}
                        </Await>
                      </Suspense>
                    );
                  }}
                </AutoSizer>
              </div>
            </div>
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row>
                <Col flex="auto">
                  <GraphTitle
                    style={{ paddingTop: 8 }}
                    title={t("energy.difference")}
                  />
                </Col>
              </Row>
              <div style={{ flexGrow: 1 }}>
                <AutoSizer>
                  {({ width, height }: Size) => (
                    <Suspense
                      fallback={
                        <div
                          style={{
                            height: height,
                            width: width,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <SpinnerWithDelay isLoading>
                            <div />
                          </SpinnerWithDelay>
                        </div>
                      }
                    >
                      <Await
                        resolve={page.data}
                        errorElement={
                          <LoadingArray height={height} width={width} />
                        }
                      >
                        {(data) => {
                          return (
                            <DifferenceChart
                              showTemperature={showTemperature}
                              width={width}
                              height={height}
                              graphPoints={data}
                              start={page.start}
                              end={page.end}
                              tooltipData={tooltipData}
                              setTooltipData={(data) => {
                                showTooltip({ tooltipData: data });
                              }}
                              granularity={page.granularity}
                            />
                          );
                        }}
                      </Await>
                    </Suspense>
                  )}
                </AutoSizer>
              </div>
            </div>
            <div
              style={{
                flexGrow: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row>
                <Col flex="auto">
                  <GraphTitleRange
                    startDate={page.startNext}
                    endDate={page.endNext}
                  />
                </Col>
              </Row>
              <div style={{ flexGrow: 1 }}>
                <AutoSizer>
                  {({ width, height }: Size) => {
                    if (width === window.innerWidth - 48 - 16) {
                      return <></>;
                    }
                    return (
                      <Suspense
                        fallback={
                          <div
                            style={{
                              height,
                              width,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <SpinnerWithDelay isLoading>
                              <div />
                            </SpinnerWithDelay>
                          </div>
                        }
                      >
                        <Await
                          resolve={promiseData}
                          errorElement={
                            <LoadingArray height={height} width={width} />
                          }
                        >
                          {([data, legend]: [
                            Record<string, number>[],
                            Legend,
                          ]) => {
                            return (
                              <NextEnergyGraph
                                width={width}
                                height={height}
                                startNext={page.startNext}
                                endNext={page.endNext}
                                granularity={page.granularity}
                                graphPoints={data}
                                legend={legendKeys(legend)}
                                nonOperationalPeriodsNext={
                                  page.nonOperationalPeriodsNext
                                }
                                holidayPeriodsNext={page.holidayPeriodsNext}
                                colorScale={colorScale(legend)}
                                operationalScale={operationalScale}
                                showNonOperational={showNonOperational}
                                showHolidays={showHolidays}
                                showTemperature={showTemperature}
                                highlightedLegend={highlightedLegend}
                                onShowTooltip={showTooltip}
                                tooltipOpen={tooltipOpen}
                                onHideTooltip={hideTooltip}
                                tooltipData={tooltipData}
                                notesDataPeriods={notesDataPeriods}
                              />
                            );
                          }}
                        </Await>
                      </Suspense>
                    );
                  }}
                </AutoSizer>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Suspense>
                <Await resolve={promiseData}>
                  {([data, legend]: [Record<string, number>[], Legend]) => {
                    const legendWithTotal = legend.map((legend) => {
                      const total = data.reduce<number>(
                        (prev, current) => prev + (current[legend.name] || 0),
                        0,
                      );
                      const nextTotal = data.reduce<number>(
                        (prev, current) =>
                          prev + (current["next-" + legend.name] || 0),
                        0,
                      );
                      return {
                        ...legend,
                        total,
                        "next-total": nextTotal,
                      };
                    });
                    return (
                      <GraphLegend
                        legend={legendWithTotal}
                        start={page.start}
                        end={page.end}
                        nextStart={page.startNext}
                        nextEnd={page.endNext}
                        legendScale={colorScale(legend)}
                        operationalScale={operationalScale}
                        showTemperature={showTemperature}
                        setShowTemperature={setShowTemperature}
                        showNonOperational={showNonOperational}
                        setShowNonOperational={setShowNonOperational}
                        showHolidays={showHolidays}
                        showEPred={false}
                        setShowEPred={() => null}
                        setShowHolidays={setShowHolidays}
                        onHighlight={(highlight) => {
                          setHighlightedLegend(highlight);
                        }}
                        highlight={highlightedLegend}
                        showNotes={showNotes}
                        setShowNotes={setShowNotes}
                      />
                    );
                  }}
                </Await>
              </Suspense>
            </div>
          </>
        )}
        <SelectEnergy
          root={page.tenantRootAssetId}
          showActiveMeter={showActiveMeter}
          setShowActiveMeter={setShowActiveMeter}
          defaultSelectedHierarchyTimeseries={
            page.type === "hierarchy" ? page.selectedIds : []
          }
          defaultSelectedMeterTimeseries={
            page.type === "energyMeter" ? page.selectedIds : []
          }
          defaultCorrectedMeterTimeseries={
            page.type === "generated" ? page.selectedIds : []
          }
          defaultSelectedEnergySources={
            page.type === "sources" ? page.selectedIds : []
          }
          timeseriesType={page.type}
          selectTimeseries={({ type, timeseries, root }) => {
            navigate(
              `../energyCompare/${getTypePath(type)}/${
                page.granularity
              }/s/${page.start.valueOf()}/e/${page.end.valueOf()}/s/${page.startNext.valueOf()}/e/${page.endNext.valueOf()}?root=${root}&${timeseries
                .map((id) => `id=${id}`)
                .join("&")}`,
            );
          }}
        />
      </div>
      {currentBuilding.dataSetId && (
        <NotesSidebar
          noteSource={NoteSource.WEB_ENERGY}
          buildings={buildings}
          assetFilterMode={NotesAssetFilterMode.TimeseriesList}
          idSet={selectedSet}
          colorOverrides={colorsForAssets}
        />
      )}
    </>
  );
};
