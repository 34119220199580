import { TranslationFunction } from "@properate/translations";
import {
  FormContextAction,
  FormContextEntries,
  FormContextEntry,
  SubmitValue,
  ValidateAllFormContextAction,
  ValidateFieldFormContextAction,
} from "./types";

type FormValidationProps<T = unknown> = {
  entries: FormContextEntries<T>;
  t: TranslationFunction;
  ignoreSkipWhenValidatingAll?: boolean;
};

export function makeValidationResult<T = unknown>({
  entries,
  t,
  id,
}: FormValidationProps<T> & { id: string }): FormContextEntry<T> {
  const validator = entries[id]?.getValidator?.(t);
  if (!validator) {
    return {
      ...entries[id],
      hasError: false,
    };
  }
  const { valid, message } = validator(entries[id].value, entries);
  return {
    ...entries[id],
    hasError: !valid,
    errorMessage: message ?? entries[id].defaultErrorMessage,
  };
}

export function makeEntriesCopy(entries: FormContextEntries, ids?: string[]) {
  const newValues: FormContextEntries = {};
  Object.entries(entries).forEach(([id, value]) => {
    newValues[id] = { ...value };
  });

  if (ids) {
    return Object.fromEntries(
      Object.entries(newValues).filter(([id]) => ids.includes(id)),
    );
  }
  return newValues;
}

export function validateAll<T = unknown>({
  entries,
  t,
  ignoreSkipWhenValidatingAll,
}: FormValidationProps<T>) {
  const result: typeof entries = {};
  let hasError = false;
  const errorIds = [];
  for (const [id, entry] of Object.entries(entries)) {
    if (!entry.skipWhenValidatingAll || ignoreSkipWhenValidatingAll) {
      result[id] = makeValidationResult<T>({ entries, id, t });
      if (result[id].hasError) {
        hasError = true;
        errorIds.push(id);
      }
    }
  }
  return { entries: result, hasError, errorIds };
}

export function resetAllEntries(entries: FormContextEntries) {
  Object.values(entries).forEach((entry) => {
    entry.value = undefined;
    entry.errorMessage = undefined;
    entry.hasError = undefined;
  });
  return entries;
}

export function getSubmitValueEntry<T = unknown>(
  entries: SubmitValue,
  name: string,
): T | undefined {
  return (entries[name] as FormContextEntry<T>).value;
}

export type ValidateFieldProps = {
  entries: FormContextEntries;
  action: FormContextAction;
  t: TranslationFunction;
  ignoreSkipWhenValidatingAll?: boolean;
};

export function validateField({
  entries,
  action,
  t,
}: ValidateFieldProps): FormContextEntries {
  const { id, resultCallback } = action as ValidateFieldFormContextAction;

  const validationResult = makeValidationResult({ entries, t, id });

  if (resultCallback) {
    resultCallback({
      entry: validationResult,
      hasError: validationResult.hasError ?? false,
    });
  }

  return {
    ...entries,
    [id]: validationResult,
  };
}

export function validateAllEntries({
  entries,
  action,
  t,
}: ValidateFieldProps): FormContextEntries {
  const { resultCallback, ignoreSkipWhenValidatingAll } =
    action as ValidateAllFormContextAction;

  const {
    entries: newEntries,
    hasError,
    errorIds,
  } = validateAll({
    entries,
    t,
    ignoreSkipWhenValidatingAll,
  });

  if (resultCallback) {
    resultCallback({ entries: newEntries, hasError, errorIds });
  }

  return newEntries;
}

export function addMissingEntries({
  entries,
  newEntries,
}: {
  entries: FormContextEntries;
  newEntries: FormContextEntries;
}): FormContextEntries {
  const missingEntries: FormContextEntries = {};
  const existingEntryIds = Object.keys(entries);

  for (const [id, entry] of Object.entries(newEntries)) {
    if (!existingEntryIds.includes(id)) {
      missingEntries[id] = entry;
    }
  }

  return {
    ...entries,
    ...missingEntries,
  };
}
