import * as React from "react";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  MenuProps,
  Space,
  Spin,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd";
import dayjs from "@properate/dayjs";
import { useState } from "react";
import useSWR from "swr";
import {
  CloseOutlined,
  SettingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { SimulationCardPlot } from "@/pages/calculationFlow/components/SimulationCardPlot";
import { DataFrameExcelLink } from "@/pages/calculationFlow/components/DataFrameExcelLink";
import { DataFrameTable } from "@/pages/calculationFlow/components/DataFrameTable";
import { toSimplifiedCalculationFlow } from "@/pages/calculationFlow/components/utils";
import { liveRunCalculationFlow } from "@/eepApi";
import { CalculationFlow } from "@/pages/calculationFlow/types";

type Props = {
  width: number;
  height: number;
  onClose: React.MouseEventHandler<HTMLElement>;
  calculationFlow: CalculationFlow;
};
export const SimulationContent = ({
  width,
  height,
  onClose,
  calculationFlow,
}: Props) => {
  const t = useTranslations();
  const [timeSpan, setTimeSpan] = useState(7 * 24 * 60 * 60 * 1000);

  // Remove all computation unrelated attributes from the flow, to avoid
  // unnecessary cache misses in useSWR
  const simplifiedCalculationFlow =
    toSimplifiedCalculationFlow(calculationFlow);
  const {
    data: calculationFlowRunResults,
    error,
    isLoading,
  } = useSWR(
    [
      JSON.stringify(simplifiedCalculationFlow),
      simplifiedCalculationFlow,
      timeSpan,
    ],
    (args) => liveRunCalculationFlow(args[1], args[2]),
  );

  if (error !== undefined) {
    return (
      <Col span={24}>
        <Alert
          message={t("calculation-flow.error")}
          description={error.response.data.message}
          type="error"
          showIcon
          closable
          onClose={onClose}
        />
      </Col>
    );
  }
  const settingItems: MenuProps["items"] = [
    {
      key: "1",
      type: "group",
      label: t("calculation-flow.simulation-period"),
      children: [
        {
          key: 24 * 60 * 60 * 1000,
          label: t("calculation-flow.simulation-period-options.1-day"),
        },
        {
          key: 7 * 24 * 60 * 60 * 1000,
          label: t("calculation-flow.simulation-period-options.7-days"),
        },
        {
          key: 30 * 24 * 60 * 60 * 1000,
          label: t("calculation-flow.simulation-period-options.30-days"),
        },
      ],
    },
  ];
  const operations = (
    <>
      {calculationFlowRunResults &&
        calculationFlowRunResults.data.notifications.length > 0 && (
          <Tooltip
            title={
              <div>
                {calculationFlowRunResults.data.notifications.map(
                  (notification, index) => (
                    <div key={index}>{notification.message}</div>
                  ),
                )}
              </div>
            }
          >
            <Badge
              count={calculationFlowRunResults.data.notifications.length}
              style={{ marginRight: "10px" }}
            >
              <Avatar
                shape="square"
                style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                size="small"
                icon={<WarningOutlined />}
              />
            </Badge>
          </Tooltip>
        )}
      <Dropdown
        menu={{
          items: settingItems,
          onClick: (item) => {
            setTimeSpan(Number(item.key));
          },
        }}
      >
        <Button icon={<SettingOutlined />} />
      </Dropdown>
      <Button icon={<CloseOutlined />} onClick={onClose}></Button>
    </>
  );

  const items: TabsProps["items"] = isLoading
    ? [
        {
          key: "calculating",
          label: (
            <Space>
              <Spin size="small" />
              {t("calculation-flow.calculating")}
            </Space>
          ),
        },
      ]
    : !calculationFlowRunResults ||
      (calculationFlowRunResults.data.timeseries.length === 0 &&
        calculationFlowRunResults.data.dataframes.length === 0)
    ? [
        {
          key: "no-results",
          label: t("calculation-flow.no-simulation-results"),
        },
      ]
    : calculationFlowRunResults.data.timeseries
        .map((timeseries) => {
          return {
            key: timeseries.externalId,
            label: (
              <span>
                {isLoading && <Spin size="small" />}
                {timeseries.externalId}
              </span>
            ),
            children: (
              <Col
                style={{
                  minHeight: "50vh",
                }}
              >
                <SimulationCardPlot
                  key={timeseries.externalId}
                  timeseries={timeseries}
                  start={dayjs().subtract(timeSpan, "milliseconds")}
                  end={dayjs()}
                />
              </Col>
            ),
          };
        })
        .concat(
          calculationFlowRunResults.data.dataframes.map((dataframe) => {
            return {
              key: dataframe.externalId,
              label: (
                <span>
                  {isLoading && <Spin size="small" />}
                  {t("calculation-flow.table-name", {
                    name: dataframe.name,
                  })}
                </span>
              ),
              children: (
                <div style={{ width }}>
                  <div className="w-full flex justify-end">
                    <DataFrameExcelLink dataframe={dataframe} />
                  </div>
                  <DataFrameTable
                    key={dataframe.externalId}
                    dataframe={dataframe}
                    height={height - 80} // 80 is the height of the button bar and the tab bar
                    width={width}
                  />
                </div>
              ),
            };
          }),
        );
  return (
    <div style={{ width: "100%" }}>
      <Tabs
        defaultActiveKey="1"
        type="card"
        items={items}
        tabBarExtraContent={{ right: operations }}
        style={{ width: width - 8 }}
      />
    </div>
  );
};
