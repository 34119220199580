import { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Modal } from "antd";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { Gauge } from "@/features/gauges";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { createGauge } from "@/services/gauge";

export function GaugeImport() {
  const t = useTranslations();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const gauge = useLoaderData() as Gauge;
  const { snapshotId } = useParams() as { snapshotId: string };
  const currentBuildingId = useCurrentBuildingId();
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  async function handleOK() {
    try {
      setIsLoading(true);
      await createGauge({
        ...gauge,
        owner: user.email,
        created: Date.now(),
        importedFrom: snapshotId,
      });
      navigate(`/asset/${currentBuildingId}/analysis/gauge`);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCancel() {
    navigate(`/asset/${currentBuildingId}/analysis/gauge`);
  }

  return (
    <Modal
      open={isModalOpen}
      title={t("analysis.gauge.import-gauge")}
      onOk={handleOK}
      onCancel={handleCancel}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <p>
        {t("analysis.gauge.do-you-want-to-import", {
          name: gauge.name,
        })}
      </p>
      <p>{t("analysis.gauge.after-import-description")}</p>
    </Modal>
  );
}
