import { AxisBottom } from "@visx/axis";
import dayjs from "@properate/dayjs";
import { Scale } from "@visx/brush/lib/types";
import { useTheme } from "styled-components";

interface Props {
  graphWidth: number;
  graphHeight: number;
  scale: Scale<number>;
  tickSize: number;
}

export function SimpleGraphXAxis({
  graphWidth,
  graphHeight,
  scale,
  tickSize,
}: Props) {
  const theme = useTheme();
  function tickFormat(date: Date) {
    const dateDayjs = dayjs(date.valueOf());
    if (dateDayjs.hour() === 0 && dateDayjs.minute() === 0) {
      if (dateDayjs.dayOfYear() === 1) {
        return dateDayjs.format("YYYY");
      }
      if (dateDayjs.date() === 1) {
        return dateDayjs.format("MMM");
      }
      return dateDayjs.format("dd D.");
    }
    return dateDayjs.format("HH:mm");
  }

  return (
    <AxisBottom
      // Leave a small margin at the bottom to allow the graph to be easier to read
      top={graphHeight + 5}
      scale={scale}
      stroke={theme.neutral4}
      tickStroke={theme.neutral4}
      numTicks={graphWidth > 520 ? 10 : 5}
      tickFormat={tickFormat}
      tickLabelProps={{
        fill: theme.neutral4,
        scaleToFit: true,
        fontSize: tickSize,
        style: { userSelect: "none" },
      }}
    />
  );
}
