import { CogniteEvent, Asset } from "@cognite/sdk";
import { ColumnsType } from "antd/lib/table/interface";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { NoteLevel, NoteSource } from "@/features/notes";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { getLabelForAsset } from "../../../../notes/utils";
import { NoteSourceText } from "../../../../notes/NoteSourceText";
import { NoteLevelText } from "../../../../notes/NoteLevelText";
import { NoteLevelColors } from "../../../../notes/types";
import EventTimeWithStatus from "./EventTimeWithStatus";

type NoteType = {
  notes: CogniteEvent[] & { assetList?: Asset[] };
};
export function BuildingNotesTable({ notes }: NoteType) {
  const t = useTranslations();
  const columns: ColumnsType<CogniteEvent & { assetList?: Asset[] }> = [
    {
      title: t("dashboard.widgets.notes.level"),
      key: "subtype",
      width: 80,
      dataIndex: "subtype",
      sorter: (a, b) => {
        const subtypeOrder: { [key: string]: number } = {
          error: 0,
          warning: 1,
          info: 2,
          unknown: 3,
        };
        const subtypeA = (a.subtype || "").toLowerCase();
        const subtypeB = (b.subtype || "").toLowerCase();
        return subtypeOrder[subtypeA] - subtypeOrder[subtypeB];
      },
      render: (subtype: NoteLevel) => {
        const showLevel = subtype ?? "unknown";
        return (
          <div
            className={`inline-flex justify-center items-center py-px px-1 border-solid border rounded ${
              NoteLevelColors[showLevel] || NoteLevelColors["unknown"]
            }`}
          >
            <NoteLevelText level={showLevel} />
          </div>
        );
      },
    },
    {
      title: t("dashboard.widgets.notes.note"),
      key: "metadata",
      dataIndex: "metadata",
      render: (metadata) => <span>{metadata.content}</span>,
    },
    {
      title: t("dashboard.widgets.notes.range-date"),
      key: "timeRange",
      sorter: (a, b) => {
        const endTimeA = dayjs(a.endTime);
        const endTimeB = dayjs(b.endTime);
        if (endTimeA.isBefore(endTimeB)) {
          return -1;
        }
        if (endTimeA.isAfter(endTimeB)) {
          return 1;
        }
        return 0;
      },
      sortDirections: ["ascend", "descend"],
      width: 140,
      render: ({ startTime, endTime }) => (
        <EventTimeWithStatus startTime={startTime} endTime={endTime} />
      ),
    },
  ];
  const renderAssetList = (assetList: Asset[] = []) => (
    <ul className="list-none">
      {assetList.map((asset) => (
        <li key={asset.id}>{renderAsset(asset)}</li>
      ))}
    </ul>
  );
  const renderEventDetails = (
    event: CogniteEvent & { assetList?: Asset[] },
  ) => {
    const { assetList, createdTime, lastUpdatedTime, metadata, source } = event;

    return (
      <>
        {renderAssetList(assetList)}
        <ul className="list-none" style={{ marginLeft: 15, marginTop: 20 }}>
          <li>
            {`${t("dashboard.widgets.notes.created-by")}: ${
              metadata?.created_by ||
              t("dashboard.widgets.notes.user-not-found")
            }, ${dayjs(createdTime).format("DD/MM YYYY HH:mm")}`}
          </li>
          <li>
            {`${t("dashboard.widgets.notes.last-changed-by")}: ${
              metadata?.created_by ||
              t("dashboard.widgets.notes.user-not-found")
            }, ${dayjs(lastUpdatedTime).format("DD/MM YYYY HH:mm")}`}
          </li>
          <li>
            {t("dashboard.widgets.notes.source")}:
            <NoteSourceText source={source as NoteSource} />
          </li>
        </ul>
      </>
    );
  };

  return (
    <TableWithoutDefaultSort
      columns={columns}
      dataSource={notes}
      rowKey="id"
      scroll={{ y: 250 }}
      pagination={false}
      expandable={{
        expandRowByClick: true,
        expandedRowRender: renderEventDetails,
        rowExpandable: () => !!notes.length,
      }}
    />
  );
}

function renderAsset(asset: Asset) {
  return (
    <div className="flex items-center justify-start gap-2">
      <span aria-hidden className="h-2 w-2 grow-0 inline-block" />
      <span className="grow-1">{getLabelForAsset(asset)}</span>
    </div>
  );
}
