"use client";

import { MultiBackend } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { DndProvider, useDrag, useDrop } from "react-dnd";

type Props = {
  children: React.ReactNode;
};

export function CustomDndProvider(props: Props) {
  return (
    <DndProvider
      backend={MultiBackend}
      options={HTML5toTouch}
      context={globalThis}
    >
      {props.children}
    </DndProvider>
  );
}

CustomDndProvider.useDrag = useDrag;
CustomDndProvider.useDrop = useDrop;
