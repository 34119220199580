import { Alert } from "antd";
import { useUser } from "@properate/auth";
import {
  AlarmSeverity as AlarmSeverityType,
  ComparisonTimeseries,
} from "@properate/common";
import { useTranslations } from "@properate/translations";
import { SubscriptionsPerUser } from "../../types";
import { AlarmSubscriptionAddInput } from "./AlarmSubscriptionAddInput";
import { AlarmSeverity } from "./AlarmSeverity";
import { AlarmSubscriptions } from "./AlarmSubscriptions";
import { AlarmComparisonTimeseries } from "./AlarmComparisonTimeseries";
import { AlarmComparisonValue } from "./AlarmComparisonValue";
import { AlarmDelay } from "./AlarmDelay";
import { AlarmMaxTimeSinceLatestDatapoint } from "./AlarmMaxTimeSinceLatestDatapoint";
import { AlarmSubtitle } from "./elements";

interface Props {
  max?: number;
  onChangeMax?: (max: number | undefined) => unknown;
  min?: number;
  onChangeMin?: (min: number | undefined) => unknown;
  comparisonTimeseriesMin: ComparisonTimeseries | undefined;
  onEditComparisonTimeseriesMin?: () => unknown;
  comparisonTimeseriesMax: ComparisonTimeseries | undefined;
  onEditComparisonTimeseriesMax?: () => unknown;
  onChangeComparisonTimeseriesMin?: (value: ComparisonTimeseries) => unknown;
  onChangeComparisonTimeseriesMax?: (value: ComparisonTimeseries) => unknown;
  severity: AlarmSeverityType;
  onChangeSeverity: (severity: AlarmSeverityType) => unknown;
  errors?: Record<string, string>;
  subscription: SubscriptionsPerUser;
  onChangeSubscription: (value: SubscriptionsPerUser) => unknown;
  interval?: string;
  unit: string;
  delay: number;
  onChangeDelay: (delay: number) => unknown;
  maxTimeSinceLatestDatapoint?: number;
  onChangeMaxTimeSinceLatestDatapoint: (value: number | undefined) => unknown;
  onChangeIncompleteSubscriber: (value: boolean) => unknown;
}

export const AlarmPane = ({
  severity,
  onChangeSeverity,
  max,
  onChangeMax,
  min,
  onChangeMin,
  comparisonTimeseriesMin,
  onEditComparisonTimeseriesMin,
  comparisonTimeseriesMax,
  onEditComparisonTimeseriesMax,
  onChangeComparisonTimeseriesMin,
  onChangeComparisonTimeseriesMax,
  subscription,
  onChangeSubscription,
  delay,
  onChangeDelay,
  errors,
  interval,
  unit,
  maxTimeSinceLatestDatapoint,
  onChangeMaxTimeSinceLatestDatapoint,
  onChangeIncompleteSubscriber,
}: Props) => {
  const t = useTranslations();
  const user = useUser();
  const isDailyAlarm = interval === "24h";

  function handleAddSubscription(value: string, type: "email" | "tel") {
    const existingPhoneSubscriptions = subscription[user.email]?.phone || [];
    const existingEmailSubscription = subscription[user.email]?.email || [];
    onChangeSubscription({
      ...subscription,
      [user.email]: {
        phone:
          type === "tel"
            ? existingPhoneSubscriptions.concat(value)
            : existingPhoneSubscriptions,
        email:
          type === "email"
            ? existingEmailSubscription.concat(value)
            : existingEmailSubscription,
      },
    });
    onChangeIncompleteSubscriber(false);
  }

  const comparisonTimeseries =
    comparisonTimeseriesMax || comparisonTimeseriesMin;
  const onEditComparisonTimeseries =
    onEditComparisonTimeseriesMax || onEditComparisonTimeseriesMin;
  const onChangeComparisonTimeseries =
    onChangeComparisonTimeseriesMax || onChangeComparisonTimeseriesMin;
  const onChangeMaxOrMin = onChangeMax || onChangeMin;

  return (
    <>
      {isDailyAlarm && (
        <Alert
          message={t(
            "common.timeseries-graph-modal.alarm-fields.daily-alarm-warning",
          )}
          type="warning"
          style={{ marginBottom: 8 }}
        />
      )}
      <AlarmSeverity value={severity} onChange={onChangeSeverity} />
      {comparisonTimeseries &&
        onEditComparisonTimeseries &&
        onChangeComparisonTimeseries && (
          <AlarmComparisonTimeseries
            unit={unit}
            value={comparisonTimeseries}
            onEditClick={onEditComparisonTimeseries}
            type={
              comparisonTimeseries === comparisonTimeseriesMax ? "max" : "min"
            }
            onChange={onChangeComparisonTimeseries}
          />
        )}
      {onChangeMaxOrMin && (
        <AlarmComparisonValue
          value={onChangeMaxOrMin === onChangeMax ? max : min}
          onChange={onChangeMaxOrMin}
          unit={unit}
          error={onChangeMaxOrMin === onChangeMax ? errors?.max : errors?.min}
          type={onChangeMaxOrMin === onChangeMax ? "max" : "min"}
        />
      )}
      {!isDailyAlarm && maxTimeSinceLatestDatapoint === undefined && (
        <AlarmDelay value={delay} onChange={onChangeDelay} />
      )}
      {typeof maxTimeSinceLatestDatapoint === "number" && (
        <AlarmMaxTimeSinceLatestDatapoint
          value={maxTimeSinceLatestDatapoint}
          onChange={onChangeMaxTimeSinceLatestDatapoint}
        />
      )}
      <AlarmSubtitle>
        {t("common.timeseries-graph-modal.alarm-fields.notification")}
      </AlarmSubtitle>
      <AlarmSubscriptions
        subscriptionsPerUser={subscription}
        onChange={onChangeSubscription}
      />
      <AlarmSubscriptionAddInput
        subscriptionsPerUser={subscription}
        onAdd={handleAddSubscription}
        error={errors?.subscriber}
        onChange={(subscriber) =>
          onChangeIncompleteSubscriber(subscriber.length > 0)
        }
        style={{ marginTop: "8px" }}
      />
    </>
  );
};
