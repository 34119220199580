import { useEffect } from "react";
import { track } from "@properate/analytics";
import { useBuildingSpec } from "@/services/buildingSpec";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

/**
 * Sets the page title; shown in the browser tab.
 */
function usePageTitle(docTitle: string, analyticsTitle?: string) {
  useEffect(() => {
    const properate = process.env.REACT_APP_PRODUCTION ? "Properate" : "Dev";

    if (docTitle) {
      document.title = `${docTitle} – ${properate}`;

      track("page_view", {
        page_path: window.location.pathname,
        page_title: analyticsTitle ?? docTitle,
        page_location: window.location.href,
      });
    }

    return () => {
      document.title = properate;
    };
  }, [docTitle, analyticsTitle]);
}

/**
 * Sets the page title; shown in the browser tab.\
 * Appends the building name to the title.
 */
export function useBuildingPageTitle(title: string) {
  const currentBuildingId = useCurrentBuildingId();

  const buildingsSpec = useBuildingSpec(currentBuildingId);

  usePageTitle(
    buildingsSpec ? `${title} – ${buildingsSpec.name}` : title,
    title,
  );
}

export default usePageTitle;
