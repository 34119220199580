import * as React from "react";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { SchemaKpiTableText } from "@/components/TechnicalSchema/elements";

type Props = {
  name?: string;
  description?: string;
  search?: string;
};

export const KpiDescription = ({ name, description, search }: Props) => {
  const splitText = search?.split(" ") || [""];
  return (
    <>
      <SchemaKpiTableText>
        <ProperateHighlighter
          searchWords={splitText}
          autoEscape
          textToHighlight={`${description} ${name}`}
        />
      </SchemaKpiTableText>
    </>
  );
};
