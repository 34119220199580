import {
  AlarmRule,
  getSimplifiedPriority,
  getUserNameWithEmail,
} from "@properate/common";
import { App, TableColumnsType } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { sortString, useAccordionValues } from "@properate/ui";
import { useUser } from "@properate/auth";
import { LineClamp } from "@/components/LineClamp";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useGetFilteredNewAlarms } from "@/features/alarms";
import { NewAlarmsTableActionColumn } from "./NewAlarmsTableActionColumn";
import { NewAlarmsTableEventsColumn } from "./NewAlarmsTableEventsColumn";
import {
  NewAlarmsTableNotesColumn,
  makeFilterId,
} from "./NewAlarmsTableNotesColumn";

type Props = {
  buildingId?: number;
  onRowClick: (alarm: AlarmRule) => void;
  tableHeight: number;
};

export function NewAlarmsTable({ buildingId, onRowClick, tableHeight }: Props) {
  const user = useUser();
  const { message } = App.useApp();
  const { filteredAlarms, search, deleteAlarm, error, isLoading } =
    useGetFilteredNewAlarms(buildingId);

  const { filters: noteAccordionFilters } = useAccordionValues();
  const t = useTranslations();

  if (error) {
    const errorMessage = t("org-alarms.errors.get-alarms");
    message.error(errorMessage);
    console.error(errorMessage, error);
  }

  const columns: TableColumnsType<AlarmRule> = [
    {
      key: "priority",
      dataIndex: "priority",
      title: t("org-alarms.table-columns.severity"),
      width: 90,
      align: "center",
      sorter: ({ priority: priorityOne }, { priority: priorityTwo }) => {
        return priorityOne - priorityTwo;
      },
      render: (priority) =>
        t(
          `org-alarms.priority.${getSimplifiedPriority(
            priority,
          ).toLowerCase()}` as MessageKey,
        ),
    },
    {
      title: t("org-alarms.table-columns.name"),
      key: "name",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      width: 220,
      sorter: ({ name: nameOne }, { name: nameTwo }) =>
        sortString(nameOne, nameTwo),
      render: (name) => {
        return (
          name && (
            <LineClamp title={name}>
              <SearchHighlighter search={search} text={name} />
            </LineClamp>
          )
        );
      },
    },
    {
      title: t("org-alarms.table-columns.description"),
      key: "instruction",
      dataIndex: "instruction",
      sorter: (
        { instruction: instructionOne },
        { instruction: instructionTwo },
      ) => sortString(instructionOne, instructionTwo),
      render: (instruction) =>
        instruction !== undefined && (
          <LineClamp title={instruction}>
            <SearchHighlighter search={search} text={instruction} />
          </LineClamp>
        ),
    },
    {
      title: t("org-alarms.table-columns.category"),
      key: "sub_category",
      dataIndex: "sub_category",
      width: 150,
      sorter: (
        { sub_category: categoryOne },
        { sub_category: categoryTwo },
      ) => {
        if (categoryOne === categoryTwo) {
          return 0;
        }
        if (!categoryOne) {
          return 1;
        }
        if (!categoryTwo) {
          return -1;
        }
        return Number(categoryOne) - Number(categoryTwo);
      },
      render: (subCategory) =>
        subCategory && (
          <LineClamp
            title={t(
              `tfm.system.buildingPartNumber.${subCategory}` as MessageKey,
            )}
          >
            <SearchHighlighter
              search={search}
              text={t(
                `tfm.system.buildingPartNumber.${subCategory}` as MessageKey,
              )}
            />
          </LineClamp>
        ),
    },
    {
      title: t("org-alarms.table-columns.timeseries"),
      key: "timeseries_name",
      dataIndex: "timeseries_name",
      width: 220,
      sorter: (
        { timeseries_name: timeseriesOne },
        { timeseries_name: timeseriesTwo },
      ) => sortString(timeseriesOne, timeseriesTwo),
      render: (timeseriesName) =>
        timeseriesName && (
          <LineClamp title={timeseriesName}>
            <SearchHighlighter search={search} text={timeseriesName} />
          </LineClamp>
        ),
    },
    {
      title: t("org-alarms.table-columns.created-by"),
      key: "created_by",
      width: 220,
      sorter: ({ created_by: createdByOne }, { created_by: createdByTwo }) =>
        sortString(
          getUserNameWithEmail(createdByOne),
          getUserNameWithEmail(createdByTwo),
        ),
      render: (_, { created_by }) => (
        <SearchHighlighter
          search={search}
          text={getUserNameWithEmail(created_by)}
        />
      ),
    },
    {
      title: t("org-alarms.table-columns.events"),
      key: "alarm_event_count",
      width: 64,
      sorter: (
        { alarm_event_count: eventCountOne },
        { alarm_event_count: eventCountTwo },
      ) => (eventCountOne ?? 0) - (eventCountTwo ?? 0),
      render: (_, alarm) => <NewAlarmsTableEventsColumn alarm={alarm} />,
    },
    {
      key: "action",
      width: 44,
      render: (_, alarm) => (
        <NewAlarmsTableActionColumn
          deleteAlarm={() => deleteAlarm(alarm.alarm_id)}
          disabled={!(user.isAdmin || alarm.created_by.email === user.email)}
        />
      ),
    },
    {
      key: "notes",
      width: 44,
      render: (_, alarm) => <NewAlarmsTableNotesColumn alarm={alarm} />,
    },
  ];

  return (
    <TableWithoutDefaultSort
      virtual
      loading={isLoading}
      columns={columns as any}
      pagination={false}
      dataSource={filteredAlarms}
      rowKey="alarm_id"
      onRow={(alarm) => ({
        onClick: () => onRowClick(alarm),
      })}
      scroll={{
        y: tableHeight,
        x: "100%",
      }}
      rowIsHoverable
      size="small"
      rowClassName={(alarm: AlarmRule) => {
        const shouldHighlight =
          noteAccordionFilters.get(makeFilterId(alarm))?.active ?? false;

        return shouldHighlight ? "bg-info-bg" : "";
      }}
    />
  );
}
