import { Space } from "antd";
import { MouseEvent, useState, useMemo } from "react";
import dayjs from "@properate/dayjs";
import { DoubleDatapoint } from "@cognite/sdk";
import { scaleBand, scaleLinear } from "@visx/scale";
import { localPoint } from "@visx/event";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { formatCompact } from "@/utils/format";
import { getDatapointClosestToDate } from "@/utils/datapoints";
import { BarChartAxes } from "./BarChartAxes";
import { BarChartBars } from "./BarChartBars";

interface Props {
  datapoints: DoubleDatapoint[];
  unit: string;
  color: string;
  width: number;
  height: number;
  tooltipDateFormat?: string;
}

export function BarChart({
  datapoints,
  unit,
  color,
  width,
  height,
  tooltipDateFormat = "D. MMM HH:mm",
}: Props) {
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
  } = useTooltip<{ timestamp: string; value: string }>();
  const [highlightedTimestamp, setHighlightedTimestamp] = useState<
    number | null
  >(null);
  const { containerRef, TooltipInPortal } = useTooltipInPortal();
  const values = datapoints.map(({ value }) => value);
  const offsetLeft = 54;
  const offsetTop = 12;
  const marginBottom = 40;
  // leave some margin on bottom
  const graphHeight = height - offsetTop - marginBottom;
  const graphWidth = width - offsetLeft;
  const scaleX = useMemo(
    () =>
      scaleBand({
        domain: datapoints.map((datapoint) => datapoint.timestamp.valueOf()),
        range: [0, graphWidth],
        padding: 0.4,
      }),
    [datapoints, graphWidth],
  );
  const scaleY = useMemo(
    () =>
      scaleLinear({
        domain: [0, Math.max(...values)],
        range: [graphHeight, 0],
      }),
    [values, graphHeight],
  );

  function handleMouseMove(event: MouseEvent<SVGRectElement>) {
    const point = localPoint(event);
    if (point) {
      // Taken from https://stackoverflow.com/questions/38633082/d3-getting-invert-value-of-band-scales
      const timestampIndex = Math.floor((point.x - offsetLeft) / scaleX.step());
      const timestamp = scaleX.domain()[timestampIndex];
      if (timestamp) {
        setHighlightedTimestamp(timestamp);
        const datapoint = getDatapointClosestToDate(datapoints, timestamp);
        if (datapoint) {
          showTooltip({
            tooltipLeft: point.x,
            tooltipTop: point.y,
            tooltipData: {
              timestamp: dayjs(datapoint.timestamp).format(tooltipDateFormat),
              value: `${formatCompact(datapoint.value)} kWh`,
            },
          });
        }
      }
    }
  }

  function handleMouseLeave() {
    hideTooltip();
    setHighlightedTimestamp(null);
  }

  return (
    <svg width={width} height={height} ref={containerRef}>
      <BarChartAxes
        graphHeight={graphHeight}
        graphWidth={graphWidth}
        offsetLeft={offsetLeft}
        offsetTop={offsetTop}
        scaleX={scaleX}
        scaleY={scaleY}
        unit={unit}
      />
      <BarChartBars
        color={color}
        datapoints={datapoints}
        graphHeight={graphHeight}
        graphWidth={graphWidth}
        offsetLeft={offsetLeft}
        offsetTop={offsetTop}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        scaleX={scaleX}
        scaleY={scaleY}
        highlightedTimestamp={highlightedTimestamp}
      />
      {tooltipOpen && (
        <TooltipInPortal left={tooltipLeft} top={tooltipTop}>
          <Space direction="vertical">
            <div>{tooltipData!.timestamp}</div>
            <div>{tooltipData!.value}</div>
          </Space>
        </TooltipInPortal>
      )}
    </svg>
  );
}
