import { useTranslations } from "@properate/translations";
import { ListIcon, TrashIcon } from "lucide-react";
import React, { useState } from "react";
import { Button, Checkbox, Popconfirm } from "antd";
import { TimeAgo, cn } from "@properate/ui";
import { usePossiblyUser } from "@properate/auth";
import { useTaskChangelog } from "@properate/firebase/functions";
import { Task } from "../../schemas";
import { useUpdateTask } from "../../hooks/use-update-task";
import { CommentInput } from "./comment-input";
import { useChangeDescription } from "./hooks/useChangeDescription";

type Props = {
  taskId: string;
  task: Task;
};

export function Activities(props: Props) {
  const t = useTranslations();
  const user = usePossiblyUser();
  const [showDetails, setShowDetails] = useState(false);
  const updateTask = useUpdateTask();
  const { getChangeDescription } = useChangeDescription();
  const { data: changelog = [] } = useTaskChangelog(props.task);

  type Activity = {
    id: string;
    taskItemId?: string;
    type: string;
    author: string | null;
    description: string | JSX.Element;
    createdAt: number;
  };

  const activities: Activity[] = props.task.comments.map((comment) => ({
    id: comment.id,
    taskItemId: comment.taskItemId,
    type: "comment",
    author: comment.author,
    description: comment.content,
    createdAt: comment.createdAt,
  }));

  if (showDetails) {
    activities.push(
      ...changelog.map((change, index) => ({
        id: index.toString(),
        type: "change",
        author: change.author,
        description: getChangeDescription(change, props.task),
        createdAt: change.timestamp,
      })),
    );
  }

  activities.sort((a, b) => b.createdAt - a.createdAt);

  async function handleDeleteComment(activityId: string) {
    await updateTask.trigger({
      where: {
        taskId: props.taskId,
      },
      data: {
        comments: {
          remove: {
            where: {
              id: activityId,
            },
          },
        },
      },
    });
  }

  return (
    <div>
      <div className="flex items-center justify-between h-8">
        <div className="flex items-center gap-4">
          <ListIcon className="w-6 h-6" />
          <div className="text-[13px] tracking-wide">
            {t("task.ui.activity")}
          </div>
        </div>
        <Button onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? t("task.ui.hideDetails") : t("task.ui.showDetails")}
        </Button>
      </div>
      <div className="flex flex-col gap-4 mt-4 ml-10">
        <CommentInput
          taskId={props.taskId}
          buildingId={props.task.buildingId}
        />
        {activities.map((activity) => (
          <ActivityMetadata
            key={activity.id}
            author={activity.author}
            createdAt={activity.createdAt}
            actions={
              activity.type === "comment" &&
              activity.author === user.email && (
                <Popconfirm
                  trigger="click"
                  title={t("task.ui.deleteComment")}
                  description={t("task.ui.deleteCommentConfirmation")}
                  onConfirm={() => handleDeleteComment(activity.id)}
                >
                  <div className="text-red-500 flex items-center gap-2 cursor-pointer">
                    <TrashIcon className="w-3 h-3 font-red-500" />
                    {t("ui.delete")}
                  </div>
                </Popconfirm>
              )
            }
          >
            {activity.type === "change" && activity.description}
            {activity.type === "comment" &&
              typeof activity.description === "string" && (
                <CommentWithChecklistRef
                  taskItemId={activity.taskItemId}
                  description={activity.description}
                  checklists={props.task.checklists}
                />
              )}
          </ActivityMetadata>
        ))}
      </div>
    </div>
  );
}

type ActivityMetadataProps = {
  author: string | null;
  createdAt: number;
  actions?: React.ReactNode;
  children: React.ReactNode;
};

export function ActivityMetadata(props: ActivityMetadataProps) {
  const t = useTranslations();

  return (
    <div>
      <div className="flex gap-2 mb-1 text-xs text-slate-400">
        {props.author ?? t("task.ui.external")}
        <TimeAgo date={props.createdAt} />
        {props.actions}
      </div>
      {props.children}
    </div>
  );
}

type CommentWithChecklistRefProps = {
  taskItemId?: string;
  description: string;
  checklists: Task["checklists"];
};

function CommentWithChecklistRef(props: CommentWithChecklistRefProps) {
  const [, checklistTaskId] = props.taskItemId
    ? [null, props.taskItemId]
    : /task:\s*([^\s}]+)/.exec(props.description) ?? [];

  const checklistItem = checklistTaskId
    ? Object.values(props.checklists)
        .map((checklist) => checklist.items)
        .flat()
        .filter((item) => item !== null)
        .find((item) => item.id === checklistTaskId)
    : null;

  return (
    <div>
      {checklistItem && (
        <fieldset
          disabled
          className="flex items-center gap-2 p-2 -m-2 border-0 rounded-md"
        >
          <Checkbox
            checked={checklistItem.checked}
            className="pointer-events-none"
          />
          <div className="flex-1 text-sm leading-8 text-foreground">
            {checklistItem.content}
          </div>
        </fieldset>
      )}
      <div className={cn({ "ml-6": Boolean(checklistItem) })}>
        <Comment description={props.description} />
      </div>
    </div>
  );
}

type CommentProps = {
  description: string;
};

export function Comment(props: CommentProps) {
  function removeTaskIdFromDescription(activityDescription: string) {
    const regex = /<p>\{task:[^}]*\}<\/p><p><br><\/p>/g;
    return activityDescription.replace(regex, "");
  }

  return (
    <div
      className="max-w-full p-2 prose-sm prose rounded-md bg-card text-foreground"
      dangerouslySetInnerHTML={{
        __html: removeTaskIdFromDescription(props.description),
      }}
    />
  );
}
