import { useUser } from "@properate/auth";
import { Node as ReactFlowNode, useEdges, useReactFlow } from "reactflow";
import {
  Alert,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useTranslations, MessageKey } from "@properate/translations";
import { EmailReceiversInput } from "./components/tableOutput/EmailReceiversInput";
import { getNodeId, updateReactFlowNodeDataPartial } from "./helpers/Utils";
import {
  NodeInput,
  Body,
  NodeInputLabel,
  NodeContent,
  LargeNode,
  OutputHeader,
} from "./helpers/NodeComponents";
import { ColumnDefinitionInput } from "./components/tableOutput/ColumnDefinition";
import { ColumnDefinition } from "./components/tableOutput/types";
import { GranularitySelect, Granularity } from "./components/GranularitySelect";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const timePeriodOptions = [null, "H", "D", "W", "M", "Q", "Y"] as const;
type TimePeriod = (typeof timePeriodOptions)[number];

interface Props {
  operationId: string;
  name: string;
  description: string;
  columnDefinitions: ColumnDefinition[];
  timePeriod: TimePeriod;
  aggregateActive: boolean;
  granularityMultiplier: number;
  granularity: Granularity;
  emailActive: boolean;
  emailReceivers: string[];
  emailSubject: string;
  emailContent: string;
}

const inputIds = "abcdefghijklmnopqrstuvwxyz".split("");
const handleLabels = inputIds.map((id) => id.toUpperCase());

export const getEmptyTableOutputNode = (): ReactFlowNode<Props> => {
  return {
    id: getNodeId("tableOutput"),
    type: "tableOutput",
    data: {
      columnDefinitions: [],
      name: "",
      description: "",
      operationId: "write_table",
      timePeriod: "W",
      aggregateActive: true,
      granularityMultiplier: 1,
      granularity: "D",
      emailActive: false,
      emailReceivers: [],
      emailSubject: "",
      emailContent: "",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function TableOutputNode(params: { id: string; data: Props }) {
  const t = useTranslations();

  const reactFlowInstance = useReactFlow();
  const user = useUser();
  const edges = useEdges();

  const [connectedHandles, setConnectedHandles] = useState<string[]>([]);

  useEffect(() => {
    const nodeInputEdges = edges.filter((edge) => edge.target === params.id);
    setConnectedHandles(
      nodeInputEdges
        .map((edge) => edge.targetHandle)
        .filter((handler): handler is string => typeof handler === "string"),
    );
  }, [params.id, edges]);

  useEffect(() => {
    if (params.data.columnDefinitions.length < connectedHandles.length)
      updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
        columnDefinitions: [
          ...params.data.columnDefinitions,
          {
            id: connectedHandles.length,
            name: String.fromCharCode(64 + connectedHandles.length),
            aggregate: "mean",
            summaryAggregate: "sum",
          },
        ],
      });
    else if (params.data.columnDefinitions.length > connectedHandles.length) {
      updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
        columnDefinitions: params.data.columnDefinitions.slice(
          0,
          connectedHandles.length,
        ),
      });
    }
  }, [
    params.id,
    connectedHandles,
    params.data.columnDefinitions,
    reactFlowInstance,
  ]);

  const handleColumneDefinitionChange = (
    columneDefintion: ColumnDefinition,
    index: number,
  ) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      columnDefinitions: [
        ...params.data.columnDefinitions.slice(0, index),
        columneDefintion,
        ...params.data.columnDefinitions.slice(index + 1),
      ],
    });
  };

  const nodeInputs = connectedHandles.map((handle, i) =>
    i < params.data.columnDefinitions.length ? (
      <NodeInput key={handle} inputId={handle}>
        <NodeInputLabel>{handleLabels[i]}</NodeInputLabel>
        <ColumnDefinitionInput
          value={params.data.columnDefinitions[i]}
          onChange={(cd) => handleColumneDefinitionChange(cd, i)}
          showLabels={i === 0}
          showAggregates={params.data.aggregateActive}
        />
      </NodeInput>
    ) : (
      <div key={handle}></div>
    ),
  );

  const nextInputIdIndex = inputIds.findIndex(
    (id) => connectedHandles.indexOf(id) === -1,
  );

  const handleAggregateActiveChange = (aggregateActive: boolean) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      aggregateActive,
    });
  };

  const handleGranularityMultiplierChange = (
    granularityMultiplier: number | null,
  ) => {
    if (granularityMultiplier !== null)
      updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
        granularityMultiplier,
      });
  };

  const handleGranularityChange = (granularity: Granularity) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      granularity,
    });
  };

  const handleNameChange = (name: string) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      name,
    });
  };

  const handleDescriptionChange = (description: string) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      description,
    });
  };

  const handleTimePeriodChange = (timePeriod: TimePeriod) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      timePeriod,
    });
  };

  const handleEmailReceiversChange = (emailReceivers: string[]) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      emailReceivers,
    });
  };

  const handleEmailSubjectChange = (emailSubject: string) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      emailSubject,
    });
  };
  const handleEmailContentChange = (emailContent: string) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      emailContent,
    });
  };

  const handleEmailActiveChange = (emailActive: boolean) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      emailActive,
    });
  };

  return (
    <LargeNode>
      <OutputHeader>{t("calculation-flow.node-types.table")}</OutputHeader>
      <Body>
        <Form>
          {nodeInputs}
          {connectedHandles.length < inputIds.length && (
            <NodeInput inputId={inputIds[nextInputIdIndex]}>
              <br />
            </NodeInput>
          )}
        </Form>
        <Divider />
        <NodeContent>
          <Form layout="vertical" disabled={user.isViewer}>
            <FormItem label={t("calculation-flow.node-types.name")}>
              <Input
                value={params.data.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleNameChange(e.target.value)
                }
              />
            </FormItem>
            <FormItem label={t("calculation-flow.node-types.description")}>
              <Input
                value={params.data.description}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleDescriptionChange(e.target.value)
                }
              />
            </FormItem>
            <FormItem label={t("calculation-flow.node-types.time-period")}>
              <Select
                showAction={["focus", "click"]}
                value={params.data.timePeriod}
                onChange={handleTimePeriodChange}
              >
                {timePeriodOptions.map((timePeriod) => (
                  <Option key={timePeriod} value={timePeriod}>
                    {t(
                      `calculation-flow.time-periods.${timePeriod}` as MessageKey,
                    )}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <Checkbox
              checked={params.data.aggregateActive}
              onChange={(e: CheckboxChangeEvent) =>
                handleAggregateActiveChange(e.target.checked)
              }
            >
              {t("calculation-flow.node-types.aggregate-data-points")}
            </Checkbox>
            {params.data.aggregateActive && (
              <FormItem label={t("calculation-flow.node-types.granularity")}>
                <InputNumber
                  value={params.data.granularityMultiplier}
                  min={1}
                  precision={0}
                  onChange={handleGranularityMultiplierChange}
                  controls={false}
                />
                <GranularitySelect
                  value={params.data.granularity}
                  style={{ width: "30%" }}
                  onChange={handleGranularityChange}
                />
              </FormItem>
            )}
            <Divider />
            <Checkbox
              checked={params.data.emailActive}
              onChange={(e: CheckboxChangeEvent) =>
                handleEmailActiveChange(e.target.checked)
              }
            >
              {t("calculation-flow.node-types.send-email")}
            </Checkbox>
            {params.data.emailActive && (
              <>
                <Alert
                  message={t("calculation-flow.node-types.email-warning")}
                  type="warning"
                />
                <FormItem
                  label={t("calculation-flow.node-types.email-recipients")}
                >
                  <EmailReceiversInput
                    values={params.data.emailReceivers}
                    onChange={handleEmailReceiversChange}
                  />
                </FormItem>
                <FormItem label={t("calculation-flow.node-types.subject")}>
                  <Input
                    value={params.data.emailSubject}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleEmailSubjectChange(e.target.value)
                    }
                  />
                </FormItem>
                <FormItem label={t("calculation-flow.node-types.content")}>
                  <TextArea
                    value={params.data.emailContent}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      handleEmailContentChange(e.target.value)
                    }
                  />
                </FormItem>
              </>
            )}
          </Form>
        </NodeContent>
      </Body>
    </LargeNode>
  );
}

export default TableOutputNode;
