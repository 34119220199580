import { useTranslations } from "@properate/translations";
import { Segmented } from "antd";
import {
  keysForAlarmPriority,
  SimplifiedAlarmPriority,
} from "@properate/common";
import { FormContextItem } from "../FormContext";
import { AlarmNotificationFormFields } from "./types";

export function Level() {
  const t = useTranslations();
  const tBase = "alarm-details.notifications-page.level.";

  return (
    <div className="w-full flex flex-col gap-2">
      <h2 className="m-0">{t(`${tBase}header`)}</h2>
      <FormContextItem
        id={AlarmNotificationFormFields.Level}
        labelKey={`${tBase}aria-level`}
        labelSrOnly
        antdInput
      >
        <Segmented
          block
          options={[
            SimplifiedAlarmPriority.Low,
            SimplifiedAlarmPriority.Medium,
            SimplifiedAlarmPriority.High,
            SimplifiedAlarmPriority.Critical,
          ].map((level) => ({
            // @ts-ignore
            label: t(`${keysForAlarmPriority[level]}`),
            value: level,
          }))}
        />
      </FormContextItem>
    </div>
  );
}
