import { browserFirestore } from "@properate/firebase";
import { BuildingCertification } from "@properate/common";
import { arrayUnion, collection, doc, setDoc } from "firebase/firestore";
import useSWRMutation from "swr/mutation";

type TriggerArg = {
  buildingId: number;
  certification: Omit<BuildingCertification, "id">;
};

export function useAddBuildingCertification() {
  return useSWRMutation(
    `building-certifications`,
    async (_, extra: { arg: TriggerArg }) => {
      const { buildingId, certification } = extra.arg;

      const newCertification: BuildingCertification = {
        ...certification,
        id: crypto.randomUUID(), // Generate an ID to be used as a key in the UI
      };

      await setDoc(
        doc(collection(browserFirestore, "buildings"), buildingId.toString()),
        { certifications: arrayUnion(newCertification) },
        { merge: true },
      );
    },
  );
}
