import { timeseriesIndex } from "@properate/api";
import useSWR from "swr";

export function useAllSystemOptions({ buildingId }: { buildingId: number }) {
  const {
    data: timeseriesListForSystems = [],
    error,
    isLoading,
  } = useSWR(
    `allSystems-${buildingId}`,
    async () => {
      const { facetHits: systemsHits } =
        await timeseriesIndex.searchForFacetValues({
          facetName: "system",
          filter: `buildingId = ${buildingId}`,
          limit: Number.MAX_SAFE_INTEGER,
        });
      return systemsHits.map(({ value }) => value);
    },
    {
      keepPreviousData: true,
    },
  );

  return {
    systems: timeseriesListForSystems,
    isLoading,
    error,
  };
}
