import { TreeSelect, Radio } from "antd";
import { useTranslations } from "@properate/translations";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { getMinWidth } from "../../utils";
import { FilterFormItem, FilterLabel, RadioGroupVertical } from "./elements";

interface Props {
  value: string | null;
  options: string[];
  onChange: (value: string | null) => unknown;
  isLoading: boolean;
}

const OPTIONS_PER_COLUMN = 8;

function mapToMainSystems(systems: string[]) {
  return [...new Set(systems.map((system) => system.split(".")[0]).sort())];
}

function getSystemsForRadioButtons(options: string[]) {
  return options.slice().sort();
}

function getSystemsForTreeSelect(options: string[]) {
  return mapToMainSystems(options).map((mainSystem) => {
    const subSystems = options
      .filter(
        (option) => option.includes(".") && option.split(".")[0] === mainSystem,
      )
      .sort()
      .map((option) => ({
        title: option,
        value: option,
      }));
    return {
      value: mainSystem,
      title: mainSystem,
      selectable: options.includes(mainSystem),
      ...(subSystems.length > 0 ? { children: subSystems } : null),
    };
  });
}

export const TimeseriesSelectionSystemFilter = ({
  value,
  options,
  onChange,
  isLoading,
}: Props) => {
  const t = useTranslations();
  const shouldUseRadioButtons = options.length < OPTIONS_PER_COLUMN * 10;
  const hasOneOption = options.length === 1;

  function handleChange(value: string | undefined) {
    onChange(value === undefined ? null : value);
  }

  return (
    <FilterFormItem $flexGrow={2}>
      <FilterLabel htmlFor="system">
        {t("common.timeseries-modal.system")}
      </FilterLabel>
      <SpinnerWithDelay isLoading={isLoading}>
        {shouldUseRadioButtons ? (
          <RadioGroupVertical
            id="system"
            {...(hasOneOption
              ? null
              : {
                  value,
                  onChange: ({ target: { value } }) => onChange(value),
                })}
          >
            {getSystemsForRadioButtons(options).map((system) => (
              <Radio
                key={system}
                {...(hasOneOption ? { checked: true } : { value: system })}
              >
                {system}
              </Radio>
            ))}{" "}
          </RadioGroupVertical>
        ) : (
          <TreeSelect
            id="system"
            style={{ minWidth: getMinWidth(options) }}
            value={value === null ? undefined : value}
            treeData={getSystemsForTreeSelect(options)}
            onChange={handleChange}
            placeholder={t("common.timeseries-modal.select-system")}
            treeLine
            showSearch
            allowClear
          />
        )}
      </SpinnerWithDelay>
    </FilterFormItem>
  );
};
