import { ReactNode } from "react";

export function AlarmTimeseriesGraphLayout({
  graph,
  navigator,
}: {
  graph: ReactNode;
  navigator: ReactNode;
}) {
  return (
    <>
      <div className="w-full h-4/5">{graph}</div>
      <div className="w-full h-1/5 box-border">{navigator}</div>
    </>
  );
}
