import { Button } from "antd";
import { useTranslations } from "@properate/translations";
import { SidebarActionType, ToggleSidebarButtonProps } from "./types";
import { useSidebarActions, useSidebarValues } from "./hooks";

export function ToggleSidebarButton({
  sidebarVisibleContent,
  sidebarHiddenContent,
  hiddenWhenSidebarVisible,
  hiddenWhenSidebarHidden,
  ...rest
}: ToggleSidebarButtonProps) {
  const sidebarValues = useSidebarValues();
  const sidebarDispatch = useSidebarActions();
  const t = useTranslations();
  const ariaLabel = sidebarValues.isOpen
    ? t("notes.hide-notes-button")
    : t("notes.show-notes-button");

  if (hiddenWhenSidebarVisible && sidebarValues.isOpen) {
    return null;
  }
  if (hiddenWhenSidebarHidden && !sidebarValues.isOpen) {
    return null;
  }
  return (
    <Button
      onClick={() => sidebarDispatch({ type: SidebarActionType.toggle })}
      aria-label={ariaLabel}
      {...rest}
    >
      {sidebarValues.isOpen ? sidebarVisibleContent : sidebarHiddenContent}
    </Button>
  );
}
