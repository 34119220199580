import styled from "styled-components";
import { useEffect, useState } from "react";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { getCognitePreviewImage } from "@/getCognitePreviewImage";
import { processImage } from "../SchemaView/SchemaView";

type TimeseriesInfo = {
  id: number;
  view?: string;
  alarmType?: string;
  min?: number;
  max?: number;
};

type SensorsType = {
  timeseriesInfo: TimeseriesInfo[];
};

const ImageContainer = styled.div`
  svg {
    height: 200px;
    width: auto;
  }
  & svg {
    .fg {
      fill: ${(props) => props.theme.neutral5};
    }

    .fg1 {
      fill: ${(props) => props.theme.neutral1};
    }

    .fgStroke {
      stroke: ${(props) => props.theme.neutral5};
    }

    .fgStroke1 {
      stroke: ${(props) => props.theme.neutral1};
    }

    .bg {
      fill: ${(props) => props.theme.neutral10};
    }

    .bg1 {
      fill: ${(props) => props.theme.neutral10};
    }

    .bg2 {
      fill: ${(props) => props.theme.neutral10};
    }

    .status {
      visibility: hidden;
    }
  }
`;
const ShowImage = ({
  image,
  cogniteFileId,
}: {
  image?: string;
  sensors?: Record<string, SensorsType>;
  cogniteFileId?: number;
}) => {
  const [loadedBackgroundImage, setLoadedBackgroundImage] = useState<
    string | undefined
  >();

  useEffect(() => {
    const abortController = new AbortController();

    const getSrc = async () => {
      if (typeof cogniteFileId === "number") {
        const { data: url } = await getCognitePreviewImage(cogniteFileId, {
          controller: abortController,
        });
        if (url) {
          setLoadedBackgroundImage(url);
          return;
        }
      }
      setLoadedBackgroundImage("");
    };

    getSrc();

    return () => {
      abortController.abort();
    };
  }, [cogniteFileId]);

  if (typeof loadedBackgroundImage === "undefined")
    return (
      <SpinnerWithDelay className="w-full m-3" isLoading>
        <div />
      </SpinnerWithDelay>
    );

  return image ? (
    <div style={{ position: "relative" }}>
      <ImageContainer
        dangerouslySetInnerHTML={{
          __html: processImage(image).outerHTML,
        }}
        style={{
          backgroundImage: `url("${loadedBackgroundImage}")`,
          backgroundPosition: "0 0",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      />
    </div>
  ) : (
    <></>
  );
};

export default ShowImage;
