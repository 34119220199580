import { Fragment, useContext, useMemo } from "react";
import { Table } from "antd";
import { formatMeasurement } from "@properate/common";
import { ThemeContext } from "styled-components";
import { useTranslations } from "@properate/translations";
import {
  formatDateYearAccordingToGranularityShort,
  Granularity,
} from "@/utils/helpers";
import {
  calculateAverage,
  getDiff,
  getSum,
  getSumCount,
  Legend,
} from "@/features/energy";
import { PrimaryText } from "./elements";

interface Props {
  data: Record<string, number>[];
  granularity: Granularity;
  legend: Legend;
  width: number;
  height: number;
  hasNoteByData: (timestamp: number) => boolean;
}

const ESTIMATED_TABLE_HEADER_HEIGHT = 134;

export function CompareTable({
  data,
  granularity,
  legend,
  width,
  height,
  hasNoteByData,
}: Props) {
  const t = useTranslations();
  const themeContext = useContext(ThemeContext);

  const legendLength = legend.length;

  const summary = useMemo(() => {
    const sensors = legend.reduce(
      (prev, legend) => ({
        ...prev,
        [legend.name]: getSum(data, legend.name),
        ["next-" + legend.name]: getSum(data, "next-" + legend.name),
      }),
      {},
    );

    return {
      temperature: calculateAverage(getSumCount(data, "temperature")),
      ["next-temperature"]: calculateAverage(
        getSumCount(data, "next-temperature"),
      ),
      total: getSum(data, "total"),
      ["next-total"]: getSum(data, "next-total"),
      ...sensors,
      id: "summary",
    };
  }, [data, legend]);

  const NOTE_STATUS_COLUMN_WIDTH = 50;

  const DATE_COLUMN_WIDTH = 120;

  const TEMPERATURE_COLUMN_WIDTH = 120;

  const DEFAULT_COLUMN_WIDTH = 120;

  const TOTAL_COLUMN_WIDTH = 120;

  const tableWidth =
    DATE_COLUMN_WIDTH * 2 +
    TEMPERATURE_COLUMN_WIDTH * 2 +
    legend.length * DEFAULT_COLUMN_WIDTH * 2 +
    TOTAL_COLUMN_WIDTH * 3;

  const finalWidth = Math.max(width, tableWidth);

  const actualDateColumnWidth =
    finalWidth > tableWidth
      ? DATE_COLUMN_WIDTH + (finalWidth - tableWidth) / 2
      : DATE_COLUMN_WIDTH;

  return (
    <Table
      scroll={{
        y: height - ESTIMATED_TABLE_HEADER_HEIGHT,
        x: finalWidth,
      }}
      size="small"
      virtual
      pagination={false}
      dataSource={data}
      rowKey="timestamp"
      style={{ width, maxWidth: width }}
      columns={[
        {
          title: "",
          key: "action",
          fixed: "left",
          width: NOTE_STATUS_COLUMN_WIDTH,
          render: (_, { timestamp }) => (
            <svg width={14} height={14}>
              <circle
                r={6}
                cx={7}
                cy={7}
                fillOpacity={0.8}
                strokeWidth={2}
                fill={themeContext.neutral4}
                stroke={
                  hasNoteByData(timestamp)
                    ? themeContext.accent12
                    : themeContext.neutral4
                }
              />
            </svg>
          ),
        },
        {
          title: t("energy.table.date"),
          children: [
            {
              key: "timestamp",
              dataIndex: "timestamp",
              width: actualDateColumnWidth,
              fixed: "left",
              align: "left",
              render: (timestamp: number) => (
                <PrimaryText>
                  {formatDateYearAccordingToGranularityShort[granularity!](
                    timestamp,
                  )}
                </PrimaryText>
              ),
            },
            {
              key: "next-timestamp",
              dataIndex: "next-timestamp",
              width: actualDateColumnWidth,
              fixed: "left",
              align: "left",
              render: (timestamp: number) =>
                formatDateYearAccordingToGranularityShort[granularity!](
                  timestamp,
                ),
            },
          ],
        },
        {
          title: t("energy.table.temperature"),
          children: [
            {
              key: "temperature",
              dataIndex: "temperature",
              width: TEMPERATURE_COLUMN_WIDTH,
              align: "right",
              render: (temperature: number) => (
                <PrimaryText>
                  {formatMeasurement({ value: temperature })}
                </PrimaryText>
              ),
            },
            {
              key: "next-temperature",
              dataIndex: "next-temperature",
              width: TEMPERATURE_COLUMN_WIDTH,
              align: "right",
              render: (temperature: number) =>
                formatMeasurement({ value: temperature }),
            },
          ],
        },
        ...legend.flatMap((legend) => [
          {
            title: legend.name,
            children: [
              {
                key: legend.name,
                dataIndex: legend.name,
                width: DEFAULT_COLUMN_WIDTH,
                align: "right" as const,
                render: (value: number) => (
                  <PrimaryText>{formatMeasurement({ value })}</PrimaryText>
                ),
              },
              {
                key: `next-${legend.name}`,
                dataIndex: `next-${legend.name}`,
                width: DEFAULT_COLUMN_WIDTH,
                align: "right" as const,
                render: (value: number) =>
                  formatMeasurement({
                    value,
                  }),
              },
            ],
          },
        ]),
        {
          title: t("energy.table.total"),
          children: [
            {
              key: "total",
              dataIndex: "total",
              width: TOTAL_COLUMN_WIDTH,
              fixed: "right",
              align: "right",
              render: (total: number) => (
                <PrimaryText>{formatMeasurement({ value: total })}</PrimaryText>
              ),
            },
            {
              key: "next-total",
              dataIndex: "next-total",
              fixed: "right",
              align: "right",
              width: TOTAL_COLUMN_WIDTH,
              render: (total: number) => formatMeasurement({ value: total }),
            },
          ],
        },
        {
          title: t("energy.table.difference"),
          key: "diff",
          width: TOTAL_COLUMN_WIDTH,
          fixed: "right",
          align: "right",
          render: (_, rowData) => (
            <PrimaryText>
              {formatMeasurement({
                value: getDiff(rowData.total, rowData["next-total"]),
                unit: "",
              })}
            </PrimaryText>
          ),
        },
      ]}
      summary={() => (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}></Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              <strong>{t("energy.table.summary-total")}</strong>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}></Table.Summary.Cell>
            <Table.Summary.Cell index={3} align="right">
              <PrimaryText>
                {formatMeasurement({ value: summary.temperature })}
              </PrimaryText>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4} align="right">
              {formatMeasurement({
                value: summary["next-temperature"],
                unit: "",
              })}
            </Table.Summary.Cell>
            {legend.map((legend, i) => {
              const index = 5 + i * 2;
              return (
                <Fragment key={legend.name}>
                  <Table.Summary.Cell index={index} align="right">
                    <PrimaryText>
                      {formatMeasurement({
                        value: summary[
                          legend.name as keyof typeof summary
                        ] as number,
                        unit: "",
                      })}
                    </PrimaryText>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={index + 1} align="right">
                    {formatMeasurement({
                      value: summary[
                        `next-${legend.name}` as keyof typeof summary
                      ] as number,
                      unit: "",
                    })}
                  </Table.Summary.Cell>
                </Fragment>
              );
            })}
            <Table.Summary.Cell index={4 + legendLength * 2 + 1} align="right">
              <PrimaryText>
                {formatMeasurement({ value: summary.total })}
              </PrimaryText>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4 + legendLength * 2 + 2} align="right">
              {formatMeasurement({ value: summary["next-total"] })}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4 + legendLength * 2 + 3} align="right">
              <PrimaryText>
                {formatMeasurement({
                  value: summary.total - summary["next-total"],
                })}
              </PrimaryText>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
    />
  );
}
