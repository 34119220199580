import { FunctionComponent } from "react";
import dayjs from "@properate/dayjs";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as XLSX from "xlsx";
import { useTranslations } from "@properate/translations";
import { DataFrame } from "../types";
import { formatTableDate } from "./utils";

interface Props {
  dataframe: DataFrame;
}

export const DataFrameExcelLink: FunctionComponent<Props> = ({ dataframe }) => {
  const t = useTranslations();
  const handleDownloadClick = () => {
    if (dataframe.datapoints.length === 0) return;

    const excelRows = dataframe.datapoints.map((dp) => {
      // Rename and format timestamp column
      const { timestamp, ...cols } = dp;
      return { Dato: formatTableDate(timestamp), ...cols };
    });

    const sheetName = dataframe.name.replace(/[^a-z0-9]/gi, "-").toLowerCase();
    const worksheet = XLSX.utils.aoa_to_sheet([
      [t("calculation-flow.excel-link.name"), dataframe.name],
      [t("calculation-flow.excel-link.description"), dataframe.description],
    ]);
    XLSX.utils.sheet_add_json(worksheet, excelRows, { origin: "A4" });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const fileName = `properate-table-${sheetName}-${dayjs().format(
      "DD-MM-YYYY",
    )}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <Button onClick={handleDownloadClick}>
      {t("calculation-flow.excel-link.download-excel-file")}{" "}
      <DownloadOutlined />
    </Button>
  );
};
