"use client";

import { componentsIndex } from "@properate/api";
import { ComponentSearchable } from "@properate/api/src/types";
import { COMPONENTS } from "@properate/common";
import { Cascader } from "antd";
import { DefaultOptionType } from "rc-tree-select/lib/TreeSelect";
import { useCallback, useEffect, useState } from "react";
import { produce } from "immer";
import { useTranslations, MessageKey } from "@properate/translations";

interface Option {
  value?: string | number;
  label: React.ReactNode;
  children?: Option[];
  isLeaf?: boolean;
}

function findInTree(root: Option, value?: string | number): Option | null {
  if (root.value === value) {
    return root;
  }
  if (!root.children || root.children.length === 0) return null;
  for (const child of root.children) {
    const result = findInTree(child, value);
    if (result) return result;
  }

  return null;
}
function findInForest(
  forest: Option[],
  value?: string | number,
): Option | null {
  for (const tree of forest) {
    const result = findInTree(tree, value);
    if (result) return result;
  }

  return null;
}

type Props = {
  buildingId: number;
  onSelect: (selected: number) => Promise<void>;
  selected?: number;
  disabled?: boolean;
};
export function ComponentSelector({
  buildingId,
  disabled,
  selected,
  onSelect,
}: Readonly<Props>) {
  const t = useTranslations();

  const [options, setOptions] = useState<Option[]>([]);
  const [componentPath, setComponentPath] = useState<(string | number)[]>();

  const loadData = useCallback(
    async (selectedOptions: DefaultOptionType[]) => {
      if (selectedOptions.length < 4) {
        return;
      }

      const updateAlreadyCompleted = selectedOptions[3].children
        ?.slice(1)
        .reduce(
          (acc, child) =>
            acc || (!!child.children && child.children.length > 0),
          false,
        );

      if (updateAlreadyCompleted) return;

      const targetOption = selectedOptions[3];

      const newChildrenForTargetOption = await Promise.all(
        (targetOption.children ?? []).map(async (child) => {
          if (child.isLeaf)
            return {
              label: child.label,
              value: child.value,
              isLeaf: true,
              children: [],
            };
          const facets = await componentsIndex.searchForFacetValues({
            facetName: "componentType",
            filter: `buildingId = ${buildingId} AND subBuilding = "${selectedOptions[0].value}" AND system = "${child.value}"`,
            limit: Number.MAX_SAFE_INTEGER,
          });

          const installation = await componentsIndex.search(null, {
            filter: `buildingId = ${buildingId} AND subBuilding = "${selectedOptions[0].value}" AND system = "${child.value}" AND componentType IS EMPTY`,
            limit: Number.MAX_SAFE_INTEGER,
          });

          const currentInstallation = {
            value: installation.hits[0].id,
            label: `${installation.hits[0].system} ${
              installation.hits[0].description || " Anlegg"
            }`,
            isLeaf: true,
            children: [],
          };

          const children = facets.facetHits
            ? await Promise.all(
                facets.facetHits.map(async (hit) => {
                  const results = await componentsIndex.search(null, {
                    filter: `buildingId = ${buildingId} AND subBuilding = "${selectedOptions[0].value}" AND system = "${child.value}" AND componentType = "${hit.value}"`,
                    limit: Number.MAX_SAFE_INTEGER,
                  });
                  if (!COMPONENTS[hit.value]?.Komponentfunksjon) {
                    console.error(`No component function for ${hit.value}`);
                  }

                  const label = COMPONENTS[hit.value]?.Komponentfunksjon
                    ? `${COMPONENTS[hit.value].Komponentfunksjon} (${
                        hit.value
                      })`
                    : hit.value;

                  return {
                    label,
                    value: hit.value,
                    children: results.hits.map((componentHit) => ({
                      label: `${componentHit.component} ${
                        componentHit.description ?? "System"
                      }`,
                      value: componentHit.id,
                      isLeaf: true,
                    })),
                    isLeaf: false,
                  };
                }),
              )
            : [];
          return {
            value: child.value,
            label: child.label,
            isLeaf: false,
            children: [currentInstallation, ...children],
          };
        }),
      );

      const nextState = produce(options, (draft) => {
        const target = findInForest(draft, targetOption.value);
        if (!target) return;
        target.children = newChildrenForTargetOption;
      });

      setOptions(nextState);
    },
    [buildingId, options],
  );

  useEffect(() => {
    const run = async () => {
      const subBuildings = (
        await componentsIndex.searchForFacetValues({
          facetName: "subBuilding",
          filter: `buildingId = ${buildingId}`,
          limit: Number.MAX_SAFE_INTEGER,
        })
      ).facetHits;

      setOptions(
        await Promise.all(
          subBuildings.map(async (subBuildingHit) => {
            const systems = (
              await componentsIndex.searchForFacetValues({
                facetName: "system",
                filter: `buildingId = ${buildingId} AND subBuilding = "${subBuildingHit.value}"`,
                limit: Number.MAX_SAFE_INTEGER,
              })
            ).facetHits.map((hit) => hit.value);
            const tfm2Options = Array.from(
              new Set(systems.map((system) => system.substring(0, 3))),
            );
            const tfm1Options = Array.from(
              new Set(tfm2Options.map((system) => system.substring(0, 2))),
            );
            const tfm0Options = Array.from(
              new Set(tfm2Options.map((system) => system.substring(0, 1))),
            );
            const assets = await componentsIndex.search(null, {
              filter: `buildingId = ${buildingId} AND subBuilding = "${
                subBuildingHit.value
              }" AND (${systems
                .filter((system) => system.length === 3)
                .map((system) => `system = "${system}"`)
                .join(" OR ")})`,
              limit: Number.MAX_SAFE_INTEGER,
            });
            const systemMap: Record<string, ComponentSearchable> =
              assets.hits.reduce(
                (acc, hit) => ({
                  ...acc,
                  [hit.system]: hit,
                }),
                {},
              );
            return {
              label: subBuildingHit.value,
              value: subBuildingHit.value,
              isLeaf: false,
              children: tfm0Options.map((tfm0) => {
                const filteredTfm1Options = tfm1Options.filter(([tfm1, _]) =>
                  tfm1.startsWith(tfm0),
                );

                return {
                  label: `${tfm0}: ${t(
                    `tfm.system.buildingPartNumber.${Number(
                      tfm0,
                    )}` as MessageKey,
                  )}`,
                  value: tfm0,
                  selectable: false,
                  children: filteredTfm1Options.map((tfm1) => {
                    const filteredTfm2Options = tfm2Options.filter((tfm2) =>
                      tfm2.startsWith(tfm1),
                    );

                    return {
                      label: `${tfm1}: ${t(
                        `tfm.system.buildingPartNumber.${Number(
                          tfm1,
                        )}` as MessageKey,
                      )}`,
                      isLeaf: false,
                      value: tfm1,
                      children: filteredTfm2Options.map((tfm2) => {
                        const filteredSystems = systems.filter((system) =>
                          system.startsWith(tfm2),
                        );
                        return {
                          label: `${tfm2}: ${t(
                            `tfm.system.buildingPartNumber.${Number(
                              tfm2,
                            )}` as MessageKey,
                          )}`,
                          isLeaf: false,
                          value: tfm2,
                          children: [
                            ...filteredSystems.map((system) => {
                              return system.length === 3
                                ? {
                                    label: `${system} ${systemMap[system].description}`,
                                    value: systemMap[system].id,
                                    isLeaf: true,
                                    children: [],
                                  }
                                : {
                                    label: system,
                                    value: system,
                                    isLeaf: false,
                                    children: [],
                                  };
                            }),
                          ],
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          }),
        ),
      );
    };
    run();
  }, [buildingId, t]);

  useEffect(() => {
    const get = async (id: number) => {
      const component = await componentsIndex.getDocument(id);
      if (component) {
        const path: [string, string, string, string] = [
          component.subBuilding,
          component.system.substring(0, 1),
          component.system.substring(0, 2),
          component.system.substring(0, 3),
        ];
        const subBuilding = options.find((o) => o.value === path[0]);
        const system1 = subBuilding?.children?.find((o) => o.value === path[1]);
        const system2 = system1?.children?.find((o) => o.value === path[2]);
        const system3 = system2?.children?.find((o) => o.value === path[3]);

        if (subBuilding && system1 && system2 && system3) {
          await loadData([subBuilding, system1, system2, system3]);
        }

        if (component.system.length === 3) {
          setComponentPath([
            component.subBuilding,
            component.system.substring(0, 1),
            component.system.substring(0, 2),
            component.system.substring(0, 3),
            component.id,
          ]);
          return;
        }
        if (!component.componentType) {
          setComponentPath([
            component.subBuilding,
            component.system.substring(0, 1),
            component.system.substring(0, 2),
            component.system.substring(0, 3),
            component.system,
            component.id,
          ]);
          return;
        }
        setComponentPath([
          component.subBuilding,
          component.system.substring(0, 1),
          component.system.substring(0, 2),
          component.system.substring(0, 3),
          component.system,
          component.componentType,
          component.id,
        ]);
      }
    };
    if (selected && options.length > 0) {
      get(selected);
    }
  }, [selected, options, loadData]);

  return (
    <Cascader
      displayRender={(labels) => labels[labels.length - 1]}
      options={options}
      placeholder={t("common.component-select.select-component")}
      loadData={loadData}
      disabled={disabled}
      onChange={async (selected) => {
        await onSelect(selected[selected.length - 1] as number);
      }}
      value={componentPath}
      style={{ width: "100%" }}
    />
  );
}
