import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import { useTranslations } from "@properate/translations";
import { cn } from "@properate/ui";
import { ETaskPriority } from "../schemas";

type Props = {
  priority: ETaskPriority;
  showMedium?: boolean;
};

export function PriorityBadge(props: Props) {
  const t = useTranslations();

  return (
    <div
      className={cn("flex items-center gap-1 text-xs", {
        "text-red-600": props.priority === "High",
        "text-blue-600": props.priority === "Low",
      })}
    >
      {props.priority === "High" && <ArrowUpIcon className="w-4 h-4" />}
      {props.priority === "Low" && <ArrowDownIcon className="w-4 h-4" />}
      {props.priority === "Medium" && props.showMedium === true && (
        <div className="w-4 h-4" />
      )}
      {
        {
          Low: t("task.enum.priority.Low"),
          High: t("task.enum.priority.High"),
          Medium:
            props.showMedium === true ? t("task.enum.priority.Medium") : "",
        }[props.priority]
      }
    </div>
  );
}
