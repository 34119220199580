import { Select } from "antd";
import { useTranslations } from "@properate/translations";

export type Granularity = "Y" | "Q" | "M" | "W" | "D" | "H" | "T";

interface Props {
  value: Granularity;
  onChange: (value: Granularity) => void;
  style?: React.CSSProperties;
}

export function GranularitySelect({ value, onChange, style }: Props) {
  const t = useTranslations();

  return (
    <Select
      showAction={["focus", "click"]}
      value={value}
      onChange={onChange}
      options={[
        { value: "Y", label: t("calculation-flow.granularity-select.year") },
        { value: "Q", label: t("calculation-flow.granularity-select.quarter") },
        { value: "M", label: t("calculation-flow.granularity-select.month") },
        { value: "W", label: t("calculation-flow.granularity-select.week") },
        { value: "D", label: t("calculation-flow.granularity-select.day") },
        { value: "H", label: t("calculation-flow.granularity-select.hour") },
        { value: "T", label: t("calculation-flow.granularity-select.minute") },
      ]}
      style={style}
    />
  );
}
