import {
  DatapointAggregate,
  DatapointAggregates,
  DoubleDatapoints,
} from "@cognite/sdk";
import { create, all } from "mathjs";
import { convertElementPropsToUnit } from "@properate/common";
import { getItemByProp } from "@/utils/array";
import { getDatapointClosestToDate } from "@/utils/datapoints";
import {
  DatapointAverages,
  SettingsTimeseriesSimple,
  ScatterplotPoint,
  SimplePoint,
  ValueLimit,
  SimplePointsWithMetadata,
  ScatterplotPointsWithMetadata,
  SettingsTimeseriesScatterplot,
  LegendDataForGraphLegend,
} from "../types";

export const NO_UNIT = "n/a";

const math = create(all);

interface UnitsToConvert {
  from?: string;
  to: string;
}

function convertScatterplotPointsToUnit(
  points: ScatterplotPoint[],
  unitsToConvertX: UnitsToConvert,
  unitsToConvertY: UnitsToConvert,
) {
  const pointsWithConvertedXValues = convertElementPropsToUnit(
    points,
    unitsToConvertX,
    ["x"],
  );
  return convertElementPropsToUnit(
    pointsWithConvertedXValues,
    unitsToConvertY,
    ["y"],
  );
}

function filterPointsWithinValueLimit<T extends SimplePoint | ScatterplotPoint>(
  points: T[],
  valueLimit?: ValueLimit,
  accessValue = (point: T) => point.y,
): T[] {
  if (valueLimit) {
    const min =
      typeof valueLimit.min === "number"
        ? valueLimit.min
        : Number.MIN_SAFE_INTEGER;
    const max =
      typeof valueLimit.max === "number"
        ? valueLimit.max
        : Number.MAX_SAFE_INTEGER;
    return points.filter(
      (point) => accessValue(point) <= max && accessValue(point) >= min,
    );
  }
  return points;
}

function filterScatterplotPointsWithinValueLimits(
  points: ScatterplotPoint[],
  valueLimitX?: ValueLimit,
  valueLimitY?: ValueLimit,
): ScatterplotPoint[] {
  const pointsFilteredByValueLimitX = filterPointsWithinValueLimit(
    points,
    valueLimitX,
    (point) => point.x,
  );
  return filterPointsWithinValueLimit(pointsFilteredByValueLimitX, valueLimitY);
}

export function mapToSimplePointsWithMetadataList(
  timeseriesListWithData: (DoubleDatapoints | DatapointAverages)[],
  settingsTimeseriesList: SettingsTimeseriesSimple[],
  { applyValueLimits } = {
    applyValueLimits: false,
  },
): SimplePointsWithMetadata[] {
  return timeseriesListWithData.map(({ id, datapoints, unit }) => {
    const { unitSelected, color, valueLimit, hidden, mathExpression } =
      getItemByProp(settingsTimeseriesList, id);
    const mathExpressionCompiled =
      typeof mathExpression === "string" ? math.compile(mathExpression) : null;
    const simplePoints = datapoints.map((datapoint) => ({
      timestamp: datapoint.timestamp,
      y: "average" in datapoint ? datapoint.average : datapoint.value,
      ...("min" in datapoint ? { yMin: datapoint.min } : null),
      ...("max" in datapoint ? { yMax: datapoint.max } : null),
    }));
    const simplePointsConverted = convertElementPropsToUnit(
      simplePoints,
      {
        from: unit,
        to: unitSelected,
      },
      ["y", "yMin", "yMax"],
    );
    const simplePointsWithMathematicalExpression =
      mathExpressionCompiled !== null
        ? simplePointsConverted.map((simplePoint) => {
            return {
              ...simplePoint,
              y: mathExpressionCompiled.evaluate({ DP: simplePoint.y }),
              ...("yMin" in simplePoint
                ? {
                    yMin: mathExpressionCompiled.evaluate({
                      DP: simplePoint.yMin,
                    }),
                  }
                : null),
              ...("yMax" in simplePoint
                ? {
                    yMax: mathExpressionCompiled.evaluate({
                      DP: simplePoint.yMax,
                    }),
                  }
                : null),
            };
          })
        : simplePointsConverted;
    return {
      simplePoints: applyValueLimits
        ? filterPointsWithinValueLimit(
            simplePointsWithMathematicalExpression,
            valueLimit,
          )
        : simplePointsWithMathematicalExpression,
      metadata: {
        timeseriesId: id,
        color,
        unit: unitSelected,
        hidden,
      },
    };
  });
}

export function mapToScatterplotPointsWithMetadataList(
  timeseriesListWithDataX: DatapointAggregates[],
  timeseriesListWithDataY: DatapointAggregates[],
  {
    color: colorX,
    unitSelected: unitSelectedX,
    aggregate: aggregateX,
    valueLimit: valueLimitX,
    mathExpression: mathExpressionX,
  }: Omit<SettingsTimeseriesScatterplot, "hidden">,
  settingsTimeseriesList: SettingsTimeseriesScatterplot[],
  matchingThreshold: number,
  noteRanges?: LegendDataForGraphLegend["notePeriods"],
): ScatterplotPointsWithMetadata[] {
  const timeseriesWithDataX = timeseriesListWithDataX[0] as
    | DatapointAverages
    | undefined;

  const mathExpressionCompiledX =
    typeof mathExpressionX === "string" ? math.compile(mathExpressionX) : null;
  if (timeseriesWithDataX && timeseriesWithDataX.datapoints.length > 0) {
    return timeseriesListWithDataY
      .filter(({ datapoints }) => datapoints.length > 0)
      .map((timeseriesWithDataY) => {
        const {
          color: colorY,
          unitSelected: unitSelectedY,
          valueLimit: valueLimitY,
          aggregate: aggregateY,
          mathExpression: mathExpressionY,
        } = getItemByProp(settingsTimeseriesList, timeseriesWithDataY.id);
        const mathExpressionCompiledY =
          typeof mathExpressionY === "string"
            ? math.compile(mathExpressionY)
            : null;
        const scatterplotPoints = mapToScatterplotPoints(
          timeseriesWithDataX,
          timeseriesWithDataY,
          {
            unitX: unitSelectedX,
            aggregateX: aggregateX,
            colorY,
            unitY: unitSelectedY,
            aggregateY,
            timeseriesIdY: timeseriesWithDataY.id,
          },
          matchingThreshold,
          noteRanges,
        );
        if (scatterplotPoints.length === 0) {
          return null;
        }
        const scatterplotPointsConverted = convertScatterplotPointsToUnit(
          scatterplotPoints,
          {
            from: timeseriesWithDataX.unit,
            to: unitSelectedX,
          },
          {
            from: timeseriesWithDataY.unit,
            to: unitSelectedY,
          },
        );
        const scatterplotPointsWithMathematicalExpression =
          scatterplotPointsConverted.map((scatterplotPoint) => ({
            ...scatterplotPoint,
            x: mathExpressionCompiledX
              ? mathExpressionCompiledX.evaluate({ DP: scatterplotPoint.x })
              : scatterplotPoint.x,
            y: mathExpressionCompiledY
              ? mathExpressionCompiledY.evaluate({ DP: scatterplotPoint.y })
              : scatterplotPoint.y,
          }));
        const scatterplotPointsWithinValueLimit =
          filterScatterplotPointsWithinValueLimits(
            scatterplotPointsWithMathematicalExpression,
            valueLimitX,
            valueLimitY,
          );
        return {
          scatterplotPoints: scatterplotPointsWithinValueLimit,
          metadata: {
            unitX: unitSelectedX,
            colorX,
            timeseriesIdX: timeseriesWithDataX.id,
            unitY: unitSelectedY,
            colorY,
            timeseriesIdY: timeseriesWithDataY.id,
          },
        };
      })
      .filter(
        (
          scatterplotPointsWithMetadataOrNull,
        ): scatterplotPointsWithMetadataOrNull is ScatterplotPointsWithMetadata =>
          scatterplotPointsWithMetadataOrNull !== null,
      );
  }
  return [];
}

function mapToScatterplotPoints(
  timeseriesWithDataX: DatapointAggregates,
  timeseriesWithDataY: DatapointAggregates,
  metadata: ScatterplotPoint["metadata"],
  matchingThreshold: number,
  noteRanges?: LegendDataForGraphLegend["notePeriods"],
): ScatterplotPoint[] {
  return timeseriesWithDataX.datapoints
    .map(
      ({
        average: averageX,
        sum: sumX,
        min: minX,
        max: maxX,
        timestamp: timestampX,
      }) => {
        const datapointYClosestToDate =
          getDatapointClosestToDate<DatapointAggregate>(
            timeseriesWithDataY.datapoints,
            timestampX,
            matchingThreshold,
          );
        if (datapointYClosestToDate) {
          const {
            average: averageY,
            sum: sumY,
            min: minY,
            max: maxY,
            timestamp: timestampY,
          } = datapointYClosestToDate;

          const notes = noteRanges?.filter(({ start, end, timeseriesIds }) => {
            return (
              timestampX.valueOf() >= start &&
              timestampX.valueOf() <= end &&
              (timeseriesIds.includes(metadata.timeseriesIdY) ||
                timeseriesIds.includes(timeseriesWithDataX.id))
            );
          });
          return {
            x: (averageX ?? sumX ?? minX ?? maxX)!,
            // We assume only one aggregate is set
            y: (averageY ?? sumY ?? minY ?? maxY)!,
            timestampX,
            timestampY,
            metadata,
            ...(notes &&
              notes?.length > 0 && {
                note: {
                  title: notes?.[0]?.noteTitle,
                  count: notes?.length,
                },
              }),
          } as ScatterplotPoint;
        }
        return null;
      },
    )
    .filter(
      (scatterplotPointOrNull): scatterplotPointOrNull is ScatterplotPoint =>
        scatterplotPointOrNull !== null,
    );
}
