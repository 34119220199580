import useSWR from "swr";
import { Timeseries } from "@cognite/sdk";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useHandleApiError } from "@/utils/api";

export function useGetAssetForTimeseries(timeseries: Timeseries) {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();

  const {
    data: assets,
    isLoading,
    error,
  } = useSWR(
    timeseries.assetId
      ? { assetId: timeseries.assetId, type: "assets.retrieve" }
      : null,
    ({ assetId }) => {
      return client.assets.retrieve([{ id: assetId }]);
    },
  );
  if (error) {
    handleAPIError(error);
  }
  return {
    asset: assets ? assets[0] || null : null,
    isLoading,
  };
}
