import { MouseEventHandler } from "react";
import { Button } from "antd";
import { useTranslations } from "@properate/translations";

export const NoteCreationModeRangeContent = ({
  startDate,
  endDate,
  onClick,
}: {
  startDate: string;
  endDate: string | false;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  const t = useTranslations();

  return (
    <section className="mx-6 flex flex-col content-center text-center">
      <dl className="my-2">
        <dt className="font-thin text-xl">
          {t("notes.selection-mode.sidebar-custom.select-time-range")}:
        </dt>
        <dd className="my-2 mx-0 font-bold text-xl">{startDate}</dd>
        <dd className="font-semibold m-0 opacity-60 text-xl">
          {t("notes.selection-mode.sidebar-custom.to")}
        </dd>
        <dd className="mt-2 m-0 mb-10 font-bold text-xl">
          {endDate || t("notes.selection-mode.sidebar-custom.select-end-date")}
        </dd>
        <Button onClick={onClick}>
          {t("notes.selection-mode.sidebar-custom.add-note")}
        </Button>
      </dl>
    </section>
  );
};
