import { Checkbox, Space } from "antd";
import { MessageKey, useTranslations } from "@properate/translations";
import { KPI } from "@/utils/types";
import { KPIOptionWithGroup } from "./options";

interface Props {
  value: KPI[];
  onChange: (values: KPI[]) => unknown;
  options: KPIOptionWithGroup[];
}

export function KPICheckboxGroupWithRows({ value, onChange, options }: Props) {
  const t = useTranslations();
  const optionsPerGroup = options
    .sort((optionOne, optionTwo) => {
      const groupComparison = optionOne.groupLabel.localeCompare(
        optionTwo.groupLabel,
      );
      return groupComparison === 0
        ? optionOne.label.localeCompare(optionTwo.label)
        : groupComparison;
    })
    .reduce<KPIOptionWithGroup[][]>((optionsPerGroupAcc, option) => {
      const optionsForGroupIndex = optionsPerGroupAcc.findIndex(
        (optionsForGroup) =>
          optionsForGroup.some(
            ({ groupLabel }) => groupLabel === option.groupLabel,
          ),
      );
      if (optionsForGroupIndex > -1) {
        return [
          ...optionsPerGroupAcc.slice(0, optionsForGroupIndex),
          optionsPerGroupAcc[optionsForGroupIndex].concat(option),
          ...optionsPerGroupAcc.slice(optionsForGroupIndex + 1),
        ];
      }
      return [...optionsPerGroupAcc, [option]];
    }, []);

  return (
    <Checkbox.Group
      value={value}
      onChange={(values) => onChange(values as KPI[])}
    >
      <Space direction="vertical">
        {optionsPerGroup.map((optionsForGroup) => {
          const { groupLabel } = optionsForGroup[0];
          return (
            <div key={groupLabel}>
              <span style={{ paddingRight: 5 }}>
                {t(
                  `portfolio-view.kpi.group-labels.${groupLabel}` as MessageKey,
                )}
              </span>
              {optionsForGroup.map((option) => {
                return (
                  <Checkbox key={option.value} value={option.value}>
                    {t(
                      `portfolio-view.kpi.short-labels.${option.label}` as MessageKey,
                    )}
                  </Checkbox>
                );
              })}
            </div>
          );
        })}
      </Space>
    </Checkbox.Group>
  );
}
