import { PropsWithChildren, ReactNode } from "react";
import { AlarmDetailsNav } from "@/pages/alarms/details/components/AlarmDetailsNav";

export function AlarmDetailsPreDetails({
  children,
}: Readonly<PropsWithChildren>) {
  return (
    <div className={"box-border w-full h-2/5 flex flex-col gap-3"}>
      {children}
    </div>
  );
}

export function AlarmsSeparator({
  direction,
  className,
}: {
  direction: "horizontal" | "vertical";
  className?: string;
}) {
  return (
    <div
      className={`bg-muted ${
        direction === "horizontal" ? "h-px w-full" : "h-full w-px"
      } ${className ?? ""}`}
    />
  );
}
export function AlarmDetailsGridColumn({
  children,
  className,
}: PropsWithChildren & { className: string }) {
  return (
    <div
      className={`@3xl:col-span-1 col-span-2 box-border @3xl:overflow-y-scroll self-stretch items-stretch flex flex-col gap-3 border-0 border-solid border-muted p-0 ${
        className ?? ""
      }`}
    >
      {children}
    </div>
  );
}

export function AlarmDetailsGridLayout({
  footer,
  firstColumn,
  secondColumn,
}: {
  footer?: ReactNode;
  firstColumn: ReactNode;
  secondColumn: ReactNode;
}) {
  function renderFooter() {
    if (!footer) {
      return null;
    }
    return (
      <div className="col-span-2 self-end h-fit border-0 border-t border-solid border-muted pt-1">
        {footer}
      </div>
    );
  }

  return (
    <div className="rounded-lg bg-background-secondary p-4 pt-0 col-span-2 grid grid-cols-2 gap-4 @3xl:min-h-[50vh] @3xl:max-h-[88vh] h-auto overflow-y-scroll">
      <AlarmDetailsGridColumn className="@3xl:border-r @3xl:pr-4">
        <AlarmDetailsNav />
        {firstColumn}
      </AlarmDetailsGridColumn>
      <AlarmDetailsGridColumn className="border-t @3xl:border-t-0 pt-4 max-h-[90vh] @3xl:h-auto">
        {secondColumn}
      </AlarmDetailsGridColumn>
      {renderFooter()}
    </div>
  );
}
