import { useEffect, useMemo, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useParams } from "react-router-dom";
import { Timeseries } from "@cognite/sdk";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { ToggleSidebarButton } from "@properate/ui";
import { MessageOutlined } from "@ant-design/icons";
import { formatTimeseriesName, getSchedule } from "@properate/common";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import {
  AlarmsTable,
  AlarmsTableFilters,
  AlarmsTableFiltersProvider,
  useGetFilteredAlarms,
} from "@/features/alarms";
import { useWindowSize } from "@/hooks/useWindowSize";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { CompactContent } from "@/components/CompactContent";
import { useHelp } from "@/context/HelpContext";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { deleteAlarm } from "@/services/alarms";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { GraphModal } from "../common/SchemaGraph/GraphModal";
import { NewAlarmButton } from "./NewAlarmButton";
import AlarmGraph from "./AlarmGraph";
import { useShowAlarm } from "./hooks/useShowAlarm";

const GRAPH_HEIGHT = 400;
const GRAPH_TABLE_GAP = 20;
const ESTIMATED_TABLE_HEADER_HEIGHT = 29;
/* eslint react/jsx-no-literals:0 */
//component wll be removed
export function Alarms() {
  useBuildingPageTitle("Avvikshåndtering");
  const { id }: any = useParams();
  const { setHelp } = useHelp();
  const { dataSetId, name: currentBuildingName } = useCurrentBuilding();

  const {
    showAlarm,
    openAlarmModal,
    closeAlarmModal,
    updateAlarmModalLocally,
  } = useShowAlarm();
  const { height: windowHeight } = useWindowSize();

  const [isEditingComparisonTimeseries, setIsEditingComparisonTimeseries] =
    useState(false);
  const comparisonTimeseries =
    showAlarm?.compareWithTsMin || showAlarm?.compareWithTsMax;

  const buildingIds = useMemo(() => [Number(id)], [id]);

  useEffect(() => {
    setHelp({
      title: "Avvikshåndtering",
      content: (
        <>
          <p>
            Du kan lage alarmer som tar sensorer eller aggregerte målinger som
            varmeste rom og sammenligner disse med terskelverdier eller mot
            andre sensorer eller aggregerte målinger.
          </p>
          <p>Du kan sende ut varslinger på alarmer over sms eller e-post.</p>
        </>
      ),
    });
  }, [setHelp]);

  const { filteredAlarms } = useGetFilteredAlarms(buildingIds);
  const sidebarTimeseriesIds = filteredAlarms
    .map((alarm) => alarm.timeseries?.ids[0])
    .filter((id) => id !== undefined) as number[];

  const handleSelectTimeseries = ([timeseries]: Timeseries[]) => {
    if (showAlarm?.type === "CUSTOM_TIMESERIES_MAX") {
      updateAlarmModalLocally({
        compareWithTsMax: {
          ids: [timeseries.id],
          name: formatTimeseriesName(timeseries),
          schedule: getSchedule(timeseries),
        },
      });
    }
    if (showAlarm?.type === "CUSTOM_TIMESERIES_MIN") {
      updateAlarmModalLocally({
        compareWithTsMin: {
          ids: [timeseries.id],
          name: formatTimeseriesName(timeseries),
          schedule: getSchedule(timeseries),
        },
      });
    }
    setIsEditingComparisonTimeseries(false);
  };

  return (
    <AlarmsTableFiltersProvider>
      <PageHeader
        title="Avvikshåndtering"
        extra={
          <>
            <AlarmsTableFilters />
            <NewAlarmButton
              key={id}
              buildingId={Number(id)}
              onOpenAlarmModal={openAlarmModal}
            />
            <ToggleSidebarButton
              hiddenWhenSidebarVisible
              icon={<MessageOutlined aria-hidden />}
              sidebarHiddenContent="Vis merknader"
            />
          </>
        }
      />
      <CompactContent>
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            height: "100%",
            gap: GRAPH_TABLE_GAP,
          }}
        >
          <div
            style={{
              height: `${GRAPH_HEIGHT}px`,
            }}
          >
            <AutoSizer>
              {({ width, height }: Size) => (
                <AlarmGraph
                  containerWidth={width}
                  containerHeight={height}
                  id={Number(id)}
                  openAlarm={openAlarmModal}
                />
              )}
            </AutoSizer>
          </div>
          <AlarmsTable
            buildingIds={buildingIds}
            onRowClick={openAlarmModal}
            tableHeight={
              windowHeight -
              PAGE_LAYOUT_HEIGHT -
              ESTIMATED_TABLE_HEADER_HEIGHT -
              GRAPH_HEIGHT -
              GRAPH_TABLE_GAP
            }
          />
        </div>
      </CompactContent>
      {showAlarm && (
        <GraphModal
          timeseriesInfo={{
            id: showAlarm.timeseries ? showAlarm.timeseries.ids[0]! : 0,
            unit: showAlarm.timeseries?.unit,
          }}
          hide={closeAlarmModal}
          showAlarm={showAlarm}
          deleteTimeseries={async () => {
            if (showAlarm.snapshotId) {
              await deleteAlarm(showAlarm.snapshotId);
            }
            closeAlarmModal();
          }}
          comparisonTimeseriesMin={showAlarm?.compareWithTsMin}
          comparisonTimeseriesMax={showAlarm?.compareWithTsMax}
          onEditMaxTimeseries={() => {
            setIsEditingComparisonTimeseries(true);
          }}
          onEditMinTimeseries={() => {
            setIsEditingComparisonTimeseries(true);
          }}
          expanded
          buildingId={parseInt(id)}
          showCreateNoteButtonInAlarmEventsList
        />
      )}
      <TimeseriesSelectionModal
        initialFilters={{
          buildingId: showAlarm?.buildingId,
          unit: showAlarm?.timeseries?.unit,
        }}
        hiddenFilters={["building"]}
        onHide={() => setIsEditingComparisonTimeseries(false)}
        onOk={handleSelectTimeseries}
        selectedIds={comparisonTimeseries?.ids || []}
        open={Boolean(showAlarm && isEditingComparisonTimeseries)}
        max={1}
      />
      {dataSetId && (
        <NotesSidebar
          noteSource={NoteSource.WEB_ALARMS}
          assetFilterMode={NotesAssetFilterMode.TimeseriesList}
          idSet={new Set(sidebarTimeseriesIds)}
          buildings={[{ id: dataSetId, name: currentBuildingName }]}
        />
      )}
    </AlarmsTableFiltersProvider>
  );
}
