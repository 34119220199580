import { WheelEvent } from "react";
import dayjs from "@properate/dayjs";
import { TimeSpan } from "@properate/common";
import {
  SimplePointsWithMetadata,
  SimplePoint,
  Domain,
  DomainWithTimeseriesId,
  DomainWithUnit,
  ScatterplotPoint,
} from "../types";
import { blendColors } from "./colors";

export function getQuarterTimestampsBetweenStartAndEnd([
  timeSpanStart,
  timeSpanEnd,
]: TimeSpan) {
  if (timeSpanEnd < timeSpanStart) {
    console.error(
      `The end date (${dayjs(
        timeSpanStart,
      ).format()}) comes before the start date (${dayjs(
        timeSpanEnd,
      ).format()})`,
    );
    return [];
  }
  const amountOfQuarters = Math.ceil(
    dayjs(timeSpanEnd).diff(timeSpanStart, "quarter", true),
  );
  return Array(amountOfQuarters)
    .fill(null)
    .reduce<number[]>((timestampsAcc, _, i) => {
      const timestamp = dayjs(timeSpanStart)
        .add(i + 1, "quarter")
        .startOf("quarter")
        .valueOf();
      return [...timestampsAcc, timestamp];
    }, []);
}
export function getMonthTimestampsBetweenStartAndEnd([
  timeSpanStart,
  timeSpanEnd,
]: TimeSpan) {
  if (timeSpanEnd < timeSpanStart) {
    console.error(
      `The end date (${dayjs(
        timeSpanStart,
      ).format()}) comes before the start date (${dayjs(
        timeSpanEnd,
      ).format()})`,
    );
    return [];
  }
  const amountOfMonths = Math.ceil(
    dayjs(timeSpanEnd).diff(timeSpanStart, "month", true),
  );
  return Array(amountOfMonths)
    .fill(null)
    .reduce<number[]>((timestampsAcc, _, i) => {
      const timestamp = dayjs(timeSpanStart)
        .add(i + 1, "month")
        .startOf("month")
        .valueOf();
      return [...timestampsAcc, timestamp];
    }, []);
}

export const getDayTimestampsBetweenStartAndEnd = ([
  timeSpanStart,
  timeSpanEnd,
]: TimeSpan) => {
  if (timeSpanEnd < timeSpanStart) {
    console.error(
      `The end date (${dayjs(
        timeSpanStart,
      ).format()}) comes before the start date (${dayjs(
        timeSpanEnd,
      ).format()})`,
    );
    return [];
  }
  const amountOfDays = Math.ceil(
    dayjs(timeSpanEnd).diff(timeSpanStart, "day", true),
  );
  return Array(amountOfDays)
    .fill(null)
    .reduce<number[]>((timestampsAcc, _, i) => {
      const timestamp = dayjs(timeSpanStart)
        .add(i + 1, "day")
        .startOf("day")
        .valueOf();
      return [...timestampsAcc, timestamp];
    }, []);
};

export const getHourTimestampsBetweenStartAndEnd = ([
  timeSpanStart,
  timeSpanEnd,
]: TimeSpan) => {
  if (timeSpanEnd < timeSpanStart) {
    console.error(
      `The end date (${dayjs(
        timeSpanStart,
      ).format()}) comes before the start date (${dayjs(
        timeSpanEnd,
      ).format()})`,
    );
    return [];
  }
  const amountOfHours = Math.ceil(
    dayjs(timeSpanEnd).diff(timeSpanStart, "hour", true),
  );
  return Array(amountOfHours)
    .fill(null)
    .reduce<number[]>((timestampsAcc, _, i) => {
      const timestamp = dayjs(timeSpanStart)
        .add(i + 1, "hour")
        .startOf("hour")
        .valueOf();
      return [...timestampsAcc, timestamp];
    }, []);
};

const DELTA_MULTIPLIER_INITIAL = 10000000;

function getDeltaMultiplier(
  [timeSpanStart, timeSpanEnd]: TimeSpan,
  [zoomedTimeSpanStart, zoomedTimeSpanEnd]: TimeSpan,
) {
  const zoomedTimeSpanDiff = zoomedTimeSpanEnd - zoomedTimeSpanStart;
  const timeSpanDiff = timeSpanEnd - timeSpanStart;
  // Keep relative zooming the same based on how the user has already zoomed in
  return (zoomedTimeSpanDiff / timeSpanDiff) * DELTA_MULTIPLIER_INITIAL;
}

function getXPositionFactor({
  currentTarget,
  clientX,
}: WheelEvent<SVGElement>) {
  const { width: graphWidth, left: graphLeft } =
    currentTarget.getBoundingClientRect();
  const distanceFromLeftOfGraph = clientX - graphLeft;
  return distanceFromLeftOfGraph / graphWidth;
}

const TEN_MINUTES_MS = 1000 * 60 * 10;

export function zoomOnTimeSpan(
  event: WheelEvent<SVGElement>,
  [timeSpanStart, timeSpanEnd]: TimeSpan,
  [zoomedTimeSpanStart, zoomedTimeSpanEnd]: TimeSpan,
): TimeSpan {
  const zoomMS = Math.round(
    -event.deltaY *
      getDeltaMultiplier(
        [timeSpanStart, timeSpanEnd],
        [zoomedTimeSpanStart, zoomedTimeSpanEnd],
      ),
  );
  const zoomingIn = zoomMS > 0;
  const xPositionFactor = getXPositionFactor(event);
  const zoomedTimeSpanStartChangeMS = Math.round(
    zoomMS * (zoomingIn ? xPositionFactor : 1 - xPositionFactor),
  );
  const zoomedTimeSpanEndChangeMS = Math.round(
    zoomMS * (zoomingIn ? 1 - xPositionFactor : xPositionFactor),
  );
  const newStart = Math.max(
    zoomedTimeSpanStart + zoomedTimeSpanStartChangeMS,
    timeSpanStart,
  );
  const newEnd = Math.min(
    zoomedTimeSpanEnd - zoomedTimeSpanEndChangeMS,
    timeSpanEnd,
  );
  const timeSpanNew = newEnd - newStart;
  if (timeSpanNew < TEN_MINUTES_MS * 2) {
    const zoomedTimeSpanDiff = zoomedTimeSpanEnd - zoomedTimeSpanStart;
    const weighedMiddleOfZoomedTimeSpan = Math.round(
      zoomedTimeSpanDiff * xPositionFactor,
    );
    // Prevent panning when already zoomed in to the max
    if (zoomedTimeSpanDiff === TEN_MINUTES_MS * 2) {
      return [zoomedTimeSpanStart, zoomedTimeSpanEnd];
    }
    const maxStart =
      zoomedTimeSpanStart + weighedMiddleOfZoomedTimeSpan - TEN_MINUTES_MS;
    const maxEnd =
      zoomedTimeSpanEnd -
      (zoomedTimeSpanDiff - weighedMiddleOfZoomedTimeSpan) +
      TEN_MINUTES_MS;
    return [maxStart, maxEnd];
  }
  return [newStart, newEnd];
}

export function getDomain(points: SimplePoint[] | ScatterplotPoint[]): Domain {
  const minValues = points.map((point) =>
    "yMin" in point && typeof point.yMin === "number" ? point.yMin : point.y,
  );
  const maxValues = points.map((point) =>
    "yMax" in point && typeof point.yMax === "number" ? point.yMax : point.y,
  );
  return [Math.min(...minValues), Math.max(...maxValues)];
}

export function mapSimplePointsToDomainsWithMetadata(
  simplePointsWithMetadataList: SimplePointsWithMetadata[],
  options?: { mergeUnits: false },
): DomainWithTimeseriesId[];
export function mapSimplePointsToDomainsWithMetadata(
  simplePointsWithMetadataList: SimplePointsWithMetadata[],
  options?: { mergeUnits: true },
): DomainWithUnit[];
export function mapSimplePointsToDomainsWithMetadata(
  simplePointsWithMetadataList: SimplePointsWithMetadata[],
  { mergeUnits } = {
    mergeUnits: false,
  },
): DomainWithTimeseriesId[] | DomainWithUnit[] {
  if (mergeUnits) {
    return simplePointsWithMetadataList
      .reduce<
        Array<{
          unit: string;
          simplePoints: SimplePoint[];
          metadata: {
            color: string;
          };
        }>
      >(
        (
          simplePointsWithMetadataPerUnitListAcc,
          { simplePoints, metadata },
        ) => {
          const simplePointsWithMetadataForUnitIndex =
            simplePointsWithMetadataPerUnitListAcc.findIndex(
              ({ unit }) => unit === metadata.unit,
            );
          if (simplePointsWithMetadataForUnitIndex > -1) {
            const simplePointsWithMetadataForUnit =
              simplePointsWithMetadataPerUnitListAcc[
                simplePointsWithMetadataForUnitIndex
              ];
            return [
              ...simplePointsWithMetadataPerUnitListAcc.slice(
                0,
                simplePointsWithMetadataForUnitIndex,
              ),
              {
                ...simplePointsWithMetadataForUnit,
                simplePoints:
                  simplePointsWithMetadataForUnit.simplePoints.concat(
                    simplePoints,
                  ),
                metadata: {
                  color: blendColors(
                    simplePointsWithMetadataForUnit.metadata.color,
                    metadata.color,
                  ),
                },
              },
              ...simplePointsWithMetadataPerUnitListAcc.slice(
                simplePointsWithMetadataForUnitIndex + 1,
              ),
            ];
          }
          return [
            ...simplePointsWithMetadataPerUnitListAcc,
            {
              unit: metadata.unit,
              simplePoints,
              metadata: {
                color: metadata.color,
              },
            },
          ];
        },
        [],
      )
      .map(({ simplePoints, unit, metadata }) => ({
        unit,
        domain: getDomain(simplePoints),
        metadata,
      }));
  }
  return simplePointsWithMetadataList.map(({ simplePoints, metadata }) => ({
    timeseriesId: metadata.timeseriesId,
    domain: getDomain(simplePoints),
    metadata: {
      color: metadata.color,
      unit: metadata.unit,
    },
  }));
}
