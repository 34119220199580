import { Badge, Tag, Tooltip } from "antd";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { useTranslations } from "@properate/translations";
import { AccessModules } from "@/features/organizationAdmin";

export const AccessModuleTags = ({
  sortedModules,
  customizedModules,
  modules,
  customizedToolboxText,
}: {
  sortedModules: AccessModules[];
  customizedModules: AccessModules[];
  modules: AccessModules[];
  customizedToolboxText: string;
}) => {
  const themeContext = useContext(ThemeContext);
  const t = useTranslations();

  return (
    <div className="leading-7">
      {sortedModules?.map((module) => {
        const isCustomized =
          customizedModules.includes(module) && !modules.includes(module);
        return (
          <Tooltip
            key={module}
            title={isCustomized ? customizedToolboxText : ""}
          >
            <span className="leading-4" key={module}>
              <Badge
                className="mr-1 leading-4"
                dot={isCustomized}
                color={themeContext.primary}
              >
                <Tag
                  color={themeContext.green_40}
                  className="h-5 leading-4 text-xs mr-0"
                >
                  {t(`organization-admin.access.modules.${module}`)}
                </Tag>
              </Badge>
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
};
