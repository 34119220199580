import { useUser } from "@properate/auth";
import { ChangeEvent, useState } from "react";
import { Button, Space, Input } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useInitialCalculationFlow } from "@/pages/calculationFlow/initialStates";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { EditMeasureModal } from "./EditMeasureModal";

interface IProps {
  onSearch: (searchWords: string[]) => void;
  onChange: () => void;
}

export function MeasuresTableHeader({ onSearch, onChange }: IProps) {
  const t = useTranslations();
  const initialCalculationFlow = useInitialCalculationFlow();
  const building = useCurrentBuilding();

  const [showEditModal, setShowEditModal] = useState(false);
  const newFlow = initialCalculationFlow(building, "flexAutomation");

  const user = useUser();

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    onSearch(event.target.value.split(" ").filter((word) => word !== ""));

  const handleOnChange = () => {
    setShowEditModal(false);
    onChange();
  };

  return (
    <>
      <PageHeader
        title={t("energy-flexing.measures.title")}
        extra={
          <Space direction="horizontal">
            <Input
              placeholder={t("calculation-flow.search-placeholder")}
              onChange={handleSearch}
              allowClear
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowEditModal(true)}
              disabled={user.isViewer}
            >
              {t("calculation-flow.new")}
            </Button>
          </Space>
        }
      />
      {showEditModal && (
        <EditMeasureModal
          flow={newFlow}
          onClose={() => setShowEditModal(false)}
          onChange={handleOnChange}
        />
      )}
    </>
  );
}
