import { useNavigate, useParams, useSubmit } from "react-router-dom";
import { useMemo } from "react";
import { useTranslations } from "@properate/translations";
import { GaugeDetails, Gauge, GaugeHeader } from "@/features/gauges";
import { useActionOrLoaderData } from "@/hooks/useActionOrLoaderData";
import { deleteGauge } from "@/services/gauge";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import {
  removeWidget,
  useUserSettingsForBuilding,
} from "@/services/userSettings";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import {
  NoteBuilding,
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";

export function GaugePage() {
  const t = useTranslations();

  useBuildingPageTitle(t("analysis.gauge.name"));
  const gauge = useActionOrLoaderData<Gauge>();
  const { snapshotId } = useParams() as { snapshotId: string };
  const submit = useSubmit();
  const navigate = useNavigate();
  const currentBuildingId = useCurrentBuildingId();
  const currentBuilding = useCurrentBuilding();
  const { data: userSettingsForBuilding } = useUserSettingsForBuilding();

  const notesBuildings = useMemo(() => {
    return [
      {
        id: currentBuilding.dataSetId,
        name: currentBuilding.name,
      } as NoteBuilding,
    ];
  }, [currentBuilding.dataSetId, currentBuilding.name]);

  function navigateToGaugeOverview() {
    navigate(`/asset/${currentBuildingId}/analysis/gauge`);
  }

  async function handleEdit(update: Partial<Gauge>) {
    submit(
      {
        snapshotId,
        updatedDoc: JSON.stringify({
          ...gauge,
          ...update,
        }),
      },
      {
        method: "post",
      },
    );
    navigateToGaugeOverview();
  }

  async function handleConfirmDeletion() {
    const widgets = userSettingsForBuilding?.dashboard?.widgets;
    if (widgets) {
      removeWidget(currentBuildingId, widgets, snapshotId);
    }
    await deleteGauge(snapshotId);
    navigateToGaugeOverview();
  }

  const timeseriesSetIds = useMemo(() => {
    return new Set([gauge.timeseriesSettings.id]);
  }, [gauge.timeseriesSettings.id]);

  return (
    <>
      <GaugeHeader
        name={gauge.name}
        onConfirmDeletion={handleConfirmDeletion}
      />
      <GaugeDetails
        timeseriesId={gauge.timeseriesSettings.id}
        viewMode={gauge.viewMode || "bar"}
        type={gauge.type}
        since={gauge.since}
        zones={gauge.zones}
        min={gauge.min}
        max={gauge.max}
        name={gauge.name}
        mathExpression={gauge.mathExpression}
        onEdit={handleEdit}
      />
      {currentBuilding.dataSetId && (
        <NotesSidebar
          noteSource={NoteSource.WEB_ANALYSIS}
          buildings={notesBuildings}
          assetFilterMode={NotesAssetFilterMode.TimeseriesList}
          idSet={timeseriesSetIds}
        />
      )}
    </>
  );
}
