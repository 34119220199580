import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCreateNewRedirectUrl } from "../hooks/createNewAlarmHooks";

export default function NewAlarmRedirect() {
  const redirectUrl = useCreateNewRedirectUrl();
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectUrl === null) {
      return;
    }
    navigate(redirectUrl);
  }, [redirectUrl, navigate]);

  return null;
}
