"use client";

import { addDoc, collection } from "firebase/firestore";
import useSWRMutation from "swr/mutation";
import { notification } from "antd";
import { usePossiblyUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { browserFirestore } from "@properate/firebase";
import { useTaskType } from "../contexts/task-type-context";
import { Task, TaskCreate } from "../schemas";

type CreateTaskArg = {
  data: TaskCreate;
};

export function useCreateTask() {
  const user = usePossiblyUser();
  const taskType = useTaskType();
  const t = useTranslations();

  return useSWRMutation(
    "createTask",
    async (_, extra: { arg: CreateTaskArg }) => {
      if (!user.email) {
        throw new Error("Not authenticated");
      }

      const task: Omit<Task, "buildingId" | "snapshotId"> = {
        status: "InProgress",
        priority: "Medium",
        checklists: {},
        files: [],
        comments: [],
        followers: [],
        usersToRemind: [],
        ...extra.arg.data,
        assignee: user.email,
        author: user.email,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        lastModifiedBy: user.email,
      };

      const docRef = await addDoc(collection(browserFirestore, taskType), task);

      return docRef.id;
    },
    {
      onSuccess() {
        notification.success({
          message: t("task.notifications.create.success", {
            taskType,
          }),
        });
      },
      onError(err) {
        console.error(err);

        notification.error({
          message: t("task.notifications.create.error", {
            taskType,
          }),
        });
      },
    },
  );
}
