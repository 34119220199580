import useSWR from "swr";
import { Asset, Timeseries } from "@cognite/sdk";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useHandleApiError } from "@/utils/api";
import { useGetAssetForTimeseries } from "./useGetAssetForTimeseries";

function isRoomAsset(asset: Asset) {
  return (
    asset.labels !== undefined &&
    asset.labels.some((label) => label.externalId === "room")
  );
}
export function useGetRoomAssetForTimeseries(timeseries: Timeseries) {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const { asset } = useGetAssetForTimeseries(timeseries);
  const {
    data: assets,
    isLoading,
    error,
  } = useSWR(
    typeof asset?.parentId === "number"
      ? { assetId: asset.parentId, type: "assets.retrieve" }
      : null,
    ({ assetId }) => {
      return client.assets.retrieve([{ id: assetId }]);
    },
  );
  if (error) {
    handleAPIError(error);
  }

  return {
    roomAsset:
      assets && assets.length > 0 && isRoomAsset(assets[0]) ? assets[0] : null,
    isLoading,
  };
}
