import { Tag } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { GreyOutOld } from "./elements";

const PriorityColor: Record<string, string> = {
  High: DEFAULT_GLOBAL_THEME.color.RED,
  Medium: DEFAULT_GLOBAL_THEME.color.YELLOW,
  Low: DEFAULT_GLOBAL_THEME.color.BLUE,
};

type Props = {
  status?: string;
  priority?: string;
  searchWords: string[];
};

export function TaskManagerPriority(props: Props) {
  const t = useTranslations();

  if (!props.priority) {
    return null;
  }

  return (
    <GreyOutOld past={props.status === "Completed"}>
      <Tag color={PriorityColor[props.priority]}>
        <SearchHighlighter
          search={props.searchWords}
          text={t(
            `task.enum.priority.${props.priority}` as MessageKey,
          ).toUpperCase()}
        />
      </Tag>
    </GreyOutOld>
  );
}
