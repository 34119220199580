import { ReactNode, useState } from "react";
import {
  DescriptionList,
  DescriptionListEntryType,
  DescriptionListStyleOverrides,
} from "../description-list";
import { AccordionItemProps } from "./types";
import { BasicAccordionItem } from "./AccordionItem";

export type AccordionBoxProps = Omit<AccordionItemProps, "open" | "setOpen">;

export type DescriptionListAccordionBoxProps = {
  htmlId: string;
  title: ReactNode;
  alwaysVisibleEntries: DescriptionListEntryType[];
  toggleableEntries: DescriptionListEntryType[];
  ignoreEmptyDescriptions?: boolean;
} & DescriptionListStyleOverrides;

export function AccordionBox(props: AccordionBoxProps) {
  const [open, setOpen] = useState<boolean>(false);

  const finalProps = {
    openClassName: "bg-background-secondary",
    className: "border border-solid border-muted rounded",
    titleWrapperClassName: "border-0 border-b border-solid border-muted",
    open,
    setOpen,
    ...props,
  };

  return <BasicAccordionItem {...finalProps} />;
}

export function DescriptionListAccordionBox({
  alwaysVisibleEntries,
  toggleableEntries,
  ignoreEmptyDescriptions,
  termColon,
  ...rest
}: DescriptionListAccordionBoxProps) {
  const commonDLProps = {
    ignoreEmptyDescriptions,
    termColon,
  };

  return (
    <AccordionBox
      {...rest}
      primaryClosedContent={
        <DescriptionList entries={alwaysVisibleEntries} {...commonDLProps} />
      }
      primaryOpenContent={
        <DescriptionList
          entries={[...alwaysVisibleEntries, ...toggleableEntries]}
          {...commonDLProps}
        />
      }
    />
  );
}
