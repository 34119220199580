import { Select } from "antd";
import { useSidebarData } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { NotesSidebarValues, useAssetList } from "@/features/notes";
import { sortByLabel } from "../utils";

export default function SelectDataSet({
  onChange,
}: {
  onChange: (id: number) => void;
}) {
  const { note, buildings } = useSidebarData<NotesSidebarValues>();
  const { assetList } = useAssetList();
  const t = useTranslations();

  if (buildings.length === 1) {
    return null;
  }
  const buildingsWithAssetsIds = new Set(
    (assetList ?? []).map(({ dataSetId }) => dataSetId),
  );
  return (
    <div className="mr-5 ml-4 mb-6 flex flex-col">
      <label
        htmlFor="building-select"
        style={{
          fontSize: "13px",
          color: "#182334e0",
          paddingBottom: "8px",
          lineHeight: "21px",
        }}
      >
        {t("notes.form.select-dataset.label")}
      </label>
      <Select
        id="building-select"
        disabled={!!note.id && !!note.dataSetId}
        defaultValue={note.dataSetId}
        onChange={onChange}
        options={buildings
          .filter(({ id }) => buildingsWithAssetsIds.has(id))
          .map(({ id, name }) => ({
            value: id,
            label: name,
          }))
          .sort(sortByLabel)}
        allowClear
        showSearch
        filterOption={(input, option) =>
          option
            ? option.label.toLowerCase().includes(input.toLowerCase())
            : false
        }
      />
    </div>
  );
}
