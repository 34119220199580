import {
  Sidebar,
  SidebarActionType,
  useSetSidebarData,
  useSidebarActions,
  useSidebarData,
} from "@properate/ui";
import { useEffect } from "react";
import { useTranslations } from "@properate/translations";
import { NoteSidebarHeaderPortalId } from "./NotesSidebarTitlePortal";
import { NotesAccordion } from "./NotesAccordion";
import { NoteSidebarForm } from "./NoteSidebarForm";
import {
  NotesSidebarProps,
  NotesSidebarValues,
  NotesSidebarViewState,
} from "./types";
import { CustomContent } from "./CustomContent";

function NotesSidebarTitle() {
  const t = useTranslations();
  return (
    <div className={"flex flex-row w-full items-center justify-between"}>
      <h2 className="m-0">{t("notes.sidebar-header")}</h2>
      <div id={NoteSidebarHeaderPortalId} />
    </div>
  );
}

function NotesSidebarContent() {
  const { viewState } = useSidebarData<NotesSidebarValues>();

  switch (viewState) {
    case NotesSidebarViewState.list:
      return (
        <div className="grow flex flex-col justify-between">
          <NotesAccordion />
        </div>
      );
    case NotesSidebarViewState.create:
    case NotesSidebarViewState.edit:
      return <NoteSidebarForm />;
    case NotesSidebarViewState.customContent:
      return <CustomContent />;
  }
}

export function NotesSidebar({
  colorOverrides,
  noteSource,
  filterQuery,
  idSet,
  assetFilterMode,
  buildings,
  startTime,
  endTime,
  customContent,
  ...sidebarProps
}: NotesSidebarProps) {
  const sidebarDispatch = useSidebarActions();
  const { setSidebarData } = useSetSidebarData<NotesSidebarValues>();
  const sidebarData = useSidebarData<NotesSidebarValues>();

  useEffect(() => {
    sidebarDispatch({
      type: SidebarActionType.setTitle,
      title: <NotesSidebarTitle />,
    });
    sidebarDispatch({
      type: SidebarActionType.setContent,
      content: <NotesSidebarContent />,
    });
  }, [sidebarDispatch]);

  useEffect(() => {
    let newData: Partial<NotesSidebarValues> = {
      colorOverrides,
      noteSource,
      filterQuery,
      idSet,
      assetFilterMode,
      buildings,
      startTime,
      endTime,
      customContent,
    };
    Object.keys(newData).forEach(
      (key) =>
        newData[key as keyof typeof newData] === undefined &&
        delete newData[key as keyof typeof newData],
    );
    if (
      !sidebarData.viewState ||
      sidebarData.viewState === NotesSidebarViewState.list
    ) {
      newData = {
        ...sidebarData,
        ...newData,
        viewState: NotesSidebarViewState.list,
        note: {},
      };
    }
    setSidebarData(newData);
  }, [ // eslint-disable-line react-hooks/exhaustive-deps, prettier/prettier
    // eslint complains, but this is a false positive.
    // setSidebarData is a function that is re-declared on every render, so
    // including it here would lead to an infinite loop
    sidebarData.viewState,
    colorOverrides,
    noteSource,
    filterQuery,
    idSet,
    assetFilterMode,
    buildings,
    startTime,
    endTime,
  ]);

  return <Sidebar {...sidebarProps} />;
}
