import { Space } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { SpotPriceTimeSpan } from "@/utils/types";
import { getLabelKeyForSpotPriceOption } from "../utils";
import type { DoubleDatapoint } from "@cognite/sdk";

interface Props {
  datapoints: DoubleDatapoint[];
  timeSpan: SpotPriceTimeSpan;
  currentDatapoint?: DoubleDatapoint;
  className?: string;
}

export function SpotPriceData({
  datapoints,
  timeSpan,
  currentDatapoint,
  className,
}: Props) {
  const t = useTranslations();

  const sum = datapoints.reduce(
    (sumAcc, datapoint) => sumAcc + datapoint.value,
    0,
  );
  const average = Math.round(sum / datapoints.length);
  const timeSpanLabel = t(
    getLabelKeyForSpotPriceOption(timeSpan) as MessageKey,
  );
  const timeSpanLabelFinal = `${timeSpanLabel
    .slice(0, 1)
    .toLowerCase()}${timeSpanLabel.slice(1)}`;
  return (
    <Space className={className} style={{ fontSize: 14 }}>
      <div>
        {t.rich("dashboard.widgets.spot-price.average-price", {
          timePeriod: timeSpanLabelFinal,
          price: average,
          strong: (content) => <strong>{content}</strong>,
          value: (content) => <span>{content}</span>,
        })}
      </div>
      {currentDatapoint && (
        <div>
          {t.rich("dashboard.widgets.spot-price.current-price", {
            price: Math.round(currentDatapoint.value),
            strong: (content) => <strong>{content}</strong>,
            value: (content) => <span>{content}</span>,
          })}
        </div>
      )}
    </Space>
  );
}
