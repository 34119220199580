type SupportedLanguages = "en" | "nb" | "nn";
const supportedLanguages: SupportedLanguages[] = ["en", "nb", "nn"];
const defaultLanguage = "nb";

const getLanguage = (language: string | SupportedLanguages) => {
  if (supportedLanguages.includes(language as SupportedLanguages)) {
    return language as SupportedLanguages;
  }

  return defaultLanguage;
};

export const useUserLanguage = ({
  setUserLanguage,
  userLanguage,
}: {
  setUserLanguage?: (language: SupportedLanguages) => void;
  userLanguage?: string | SupportedLanguages;
}) => {
  const browserLanguageCode = navigator.language.split("-")[0] as
    | SupportedLanguages
    | undefined;

  if (userLanguage) {
    return getLanguage(userLanguage);
  }

  if (browserLanguageCode) {
    setUserLanguage && setUserLanguage(browserLanguageCode);
    return getLanguage(browserLanguageCode);
  }

  setUserLanguage && setUserLanguage(defaultLanguage);
  return getLanguage(defaultLanguage);
};
