import { useState } from "react";
import { useBeforeUnload } from "react-router-dom";
import { Modal } from "antd";
import { useTranslations } from "@properate/translations";

interface Props {
  isTouched: boolean;
}

// This component currently only prevents navigation away from the application
// We want to also prevent navigation away within the application, though currently
// the only way to do this is by either downgrading react-router-dom to v5 or using hacks
// Once react-router-dom@6+ supports prevention of navigation within the application, we should add that here
export function FormPrompt({ isTouched }: Props) {
  const t = useTranslations();
  const [open, setOpen] = useState(false);
  useBeforeUnload((event) => {
    if (isTouched) {
      event.preventDefault();
      event.returnValue = "";
    }
  });

  return (
    <Modal
      title={t("common.leave-form-prevent.unsaved-changes")}
      open={open}
      okText={t("common.leave-form-prevent.yes")}
      onCancel={() => setOpen(false)}
    >
      {t("common.leave-form-prevent.are-you-sure")}
    </Modal>
  );
}
