import { AlarmRuleTypeName, OffsetDirection } from "@properate/common";
import { useEffect, useState } from "react";
import { ThirdPartyAlarmGraph } from "@/pages/alarms/details/AlarmRuleTypes/ThirdPartyAlarm/components/ThirdPartyAlarmGraph";
import { CompareTimeseriesFormFields } from "../../AlarmRuleTypes/CompareTimeseries";
import { TimeseriesThresholdFormFields } from "../../AlarmRuleTypes/TimeseriesThreshold";
import { SelectOffsetType } from "../../AlarmRuleTypes/common/SelectOffset";
import { useFormValue } from "../../FormContext/hooks";
import { AlarmSettingsFormFields } from "../../AlarmSettingsPage/types";
import TwoTimeseriesGraph from "./TwoTimeseriesGraph";
import SingleTimeseriesWithThresholdGraph from "./SingleTimeseriesWithThresholdGraph";
import SingleTimeseriesGraph from "./SingleTimeseriesGraph";
import AlarmTimeseriesEmpty from "./AlarmTimeseriesEmpty";

export default function TimeseriesGraphForAlarmType() {
  const [alarmType] = useFormValue<AlarmRuleTypeName>(
    AlarmSettingsFormFields.Type,
  );

  const [offset] = useFormValue<number>(SelectOffsetType.Value);
  const [offsetDirection] = useFormValue<OffsetDirection>(
    SelectOffsetType.Direction,
  );
  const [mathExpr, setMathExpr] = useState<undefined | string>(undefined);

  useEffect(() => {
    const isEmpty = (val: unknown) => {
      return val === undefined || val === null || val === "";
    };
    if (isEmpty(offset) || isEmpty(offsetDirection)) {
      return setMathExpr(undefined);
    }

    const newMathExpr = `DP${
      offsetDirection === OffsetDirection.BaseGTCompare ? "-" : "+"
    }${offset}`;

    setMathExpr(newMathExpr);
  }, [offset, offsetDirection]);

  switch (alarmType) {
    case AlarmRuleTypeName.CompareTimeseries:
    case AlarmRuleTypeName.CompareTimeseriesPredictedEnergyConsumption:
      return (
        <TwoTimeseriesGraph
          tsOneName={CompareTimeseriesFormFields.BaseTimeseries}
          tsTwoName={CompareTimeseriesFormFields.ComparisonTimeseries}
          tsTwoMathExpression={mathExpr}
        />
      );
    case AlarmRuleTypeName.TimeseriesThreshold:
    case AlarmRuleTypeName.TimeseriesThresholdRoomTemp:
    case AlarmRuleTypeName.TimeseriesThresholdCO2:
    case AlarmRuleTypeName.TimeseriesThresholdVOC:
    case AlarmRuleTypeName.TimeseriesThresholdEffectGuard:
      return (
        <SingleTimeseriesWithThresholdGraph
          tsName={TimeseriesThresholdFormFields.Timeseries}
          thresholdName={SelectOffsetType.Value}
        />
      );
    case AlarmRuleTypeName.MissingData:
    case AlarmRuleTypeName.LeakProtection:
      return (
        <SingleTimeseriesGraph
          tsName={TimeseriesThresholdFormFields.Timeseries}
        />
      );
    case AlarmRuleTypeName.LockedThirdPartyAlarm:
    case AlarmRuleTypeName.ThirdPartyAlarm:
      return <ThirdPartyAlarmGraph />;
    default:
      return <AlarmTimeseriesEmpty />;
  }
}
