import { useUser } from "@properate/auth";
import { Node, useReactFlow } from "reactflow";
import { InputNumber } from "antd";
import { useTranslations } from "@properate/translations";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import {
  SmallNode,
  NodeOutput,
  InputHeader,
  Body,
  NodeOutputLabel,
} from "./helpers/NodeComponents";

interface ConstantInputNodeProps {
  operationId: string;
  value: number;
}

export const getEmptyConstantInputNode = (): Node<ConstantInputNodeProps> => {
  return {
    id: getNodeId("constant"),
    type: "constant",
    data: {
      operationId: "constant",
      value: 0,
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function ConstantInputNode(params: {
  id: string;
  data: ConstantInputNodeProps;
}) {
  const t = useTranslations();
  const user = useUser();
  const reactFlowInstance = useReactFlow();

  const handleChange = (value: number | null) => {
    if (value !== null)
      updateReactFlowNodeData(reactFlowInstance, params.id, "value", value);
  };

  return (
    <SmallNode>
      <InputHeader>{t("calculation-flow.node-types.constant")}</InputHeader>
      <Body>
        <NodeOutput>
          <NodeOutputLabel>
            <InputNumber
              style={{ width: "95%" }}
              value={params.data.value}
              onChange={handleChange}
              controls={false}
              decimalSeparator=","
              disabled={user.isViewer}
            />
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </SmallNode>
  );
}

export default ConstantInputNode;
