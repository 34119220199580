import { DoubleDatapoint } from "@cognite/sdk";
import { isDate } from "@properate/common";
import dayjs from "@properate/dayjs";

function isDoubleDatapoint(datapoint: unknown): datapoint is DoubleDatapoint {
  return (
    typeof datapoint === "object" &&
    datapoint !== null &&
    "value" in datapoint &&
    "timestamp" in datapoint &&
    typeof datapoint.value === "number" &&
    isDate(datapoint.timestamp)
  );
}

const DATE_FORMAT_NORWEGIAN_NO_SECONDS = "DD.MM.YYYY HH:mm";
const DATE_FORMAT_NORWEGIAN = `${DATE_FORMAT_NORWEGIAN_NO_SECONDS}:ss`;
const DATE_FORMAT_US_NO_SECONDS = "MM/DD/YYYY HH:mm";
const DATE_FORMAT_US = `${DATE_FORMAT_US_NO_SECONDS}:ss`;

function tryParseDate(timestamp: string) {
  const dateNorwegian = dayjs(timestamp, DATE_FORMAT_NORWEGIAN, true);
  if (dateNorwegian.isValid()) {
    return dateNorwegian.toDate();
  }
  const dateNorwegianNoSeconds = dayjs(
    timestamp,
    DATE_FORMAT_NORWEGIAN_NO_SECONDS,
    true,
  );
  if (dateNorwegianNoSeconds.isValid()) {
    return dateNorwegianNoSeconds.toDate();
  }
  const dateUS = dayjs(timestamp, DATE_FORMAT_US, true);
  if (dateUS.isValid()) {
    return dateUS.toDate();
  }
  const dateUSNoSeconds = dayjs(timestamp, DATE_FORMAT_US_NO_SECONDS, true);
  if (dateUSNoSeconds.isValid()) {
    return dateUSNoSeconds.toDate();
  }
  const dateNumber = dayjs(Number(timestamp));
  if (dateNumber.isValid()) {
    return dateNumber.toDate();
  }
  const dateISO = dayjs(timestamp);
  if (dateISO.isValid()) {
    return dateISO.toDate();
  }
  return null;
}

export function parseDatapointsFromPaste(data: string) {
  return data
    .split("\n")
    .map((datapointWithTab) => {
      const [timestamp, value] = datapointWithTab.split("\t");
      if (typeof timestamp === "string" && typeof value === "string") {
        const timestampParsed = tryParseDate(timestamp);
        if (timestampParsed) {
          return {
            timestamp: timestampParsed,
            // Replace "," by "."
            // Remove spaces
            value: Number(value.replace(/,/g, ".").replace(/\s/g, "")),
          };
        }
        return null;
      }
      return null;
    })
    .filter(isDoubleDatapoint);
}

// Data points are saved in kWh/m³ by default
export function convertDatapoint(datapoint: DoubleDatapoint, unit: string) {
  if (unit === "Wh") {
    return {
      ...datapoint,
      value: datapoint.value * 1000,
    };
  }
  if (unit === "MWh") {
    return {
      ...datapoint,
      value: datapoint.value / 1000,
    };
  }
  if (unit === "GWh") {
    return {
      ...datapoint,
      value: datapoint.value / 1000000,
    };
  }
  if (unit === "l") {
    return {
      ...datapoint,
      value: datapoint.value * 1000,
    };
  }
  return datapoint;
}
