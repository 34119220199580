"use client";

import { DropTargetMonitor, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { cn } from "../lib/cn";
import { CustomDndProvider } from "./custom-dnd-provider";

type Props = {
  onDrop?: (files: File[]) => void;
};

function FileDropAreaImpl(props: Props) {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: File[] }) {
        props.onDrop?.(item.files);
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props],
  );

  if (typeof document !== "undefined") {
    drop(document.body);
  }

  return (
    <div
      className={cn(
        "fixed inset-0 flex items-center justify-center text-xl transition-opacity opacity-0 -z-50 bg-properate/25",
        {
          "opacity-100 z-10": canDrop && isOver,
        },
      )}
    >
      Slipp filene her for å laste opp
    </div>
  );
}

export function FileDropArea(props: Props) {
  return (
    <CustomDndProvider>
      <FileDropAreaImpl {...props} />
    </CustomDndProvider>
  );
}
