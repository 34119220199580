import { Space, Tag } from "antd";
import { useTranslations } from "@properate/translations";
import { SearchInput } from "@/components/SearchIndex";
import { filters } from "../TaskManagerTable/TaskManagerTable";
import { useTaskManagerTableFiltersContext } from "./TaskManagerTableContext";

type Props = {
  children: React.ReactNode;
};

export function TaskManagerHeading(props: Props) {
  const t = useTranslations();

  const { search, setSearch, filter, setFilter } =
    useTaskManagerTableFiltersContext();

  return (
    <Space>
      {filters.map(({ value }) => (
        <Tag.CheckableTag
          key={value}
          checked={filter.includes(value)}
          onChange={() => setFilter(value)}
        >
          {t(`task.filters.${value}`)}
        </Tag.CheckableTag>
      ))}
      <Space>
        <SearchInput
          style={{ verticalAlign: "middle", width: 400 }}
          allowClear
          placeholder={t("task.ui.search-task")}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </Space>
      <Space>{props.children}</Space>
    </Space>
  );
}
