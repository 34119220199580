import { useState } from "react";
import { Timeseries } from "@cognite/sdk";
import { TimeSpan, convertElementPropsToUnit } from "@properate/common";
import { Alert } from "antd";
import { useTranslations } from "@properate/translations";
import { getTimeSpanOptions } from "@/components/TimeSpanSelection";
import { RangePicker } from "@/components/RangePicker/RangePicker";
import { StatisticalValue } from "@/components/StatisticalValue/StatisticalValue";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { useGetStatistics } from "../../hooks/useGetStatistics";
import { SettingsTimeseries } from "../../types";
import { StatisticsContainer, BodyContainer } from "./elements";

interface Props {
  timeseries: Timeseries;
  initialTimeSpan: TimeSpan;
  settingsTimeseries: SettingsTimeseries;
  timeseriesUnit?: string;
}

export function ModalStatisticsBody({
  timeseries,
  settingsTimeseries,
  initialTimeSpan,
}: Props) {
  const t = useTranslations();
  const timeSpanOptions = getTimeSpanOptions(t);

  const [timeSpan, setTimeSpan] = useState<TimeSpan>(initialTimeSpan);
  const { statistics, isLoading, isDatasetToBigToApplyFormula } =
    useGetStatistics(
      timeseries.id,
      timeSpan,
      settingsTimeseries.mathExpression,
    );
  const statisticsConverted = statistics
    ? convertElementPropsToUnit(
        statistics,
        {
          from: timeseries.unit,
          to: settingsTimeseries.unitSelected,
        },
        ["average", "sum", "min", "max", "firstValue", "latestValue"],
      )
    : null;
  return (
    <BodyContainer>
      <RangePicker
        value={timeSpan}
        onChange={setTimeSpan}
        withTime
        style={{ alignSelf: "start" }}
        presets={timeSpanOptions}
        allowClear={false}
      />
      <SpinnerWithDelay isLoading={isLoading} centerInParent>
        {statisticsConverted ? (
          <>
            {isDatasetToBigToApplyFormula && (
              <Alert
                type="error"
                message={t(
                  "analysis.xy-graph.statistics.limitation-by-formula",
                )}
              />
            )}
            <StatisticsContainer>
              <StatisticalValue
                description={t("analysis.details.statistics-modal.average")}
                value={statisticsConverted.average}
                unit={settingsTimeseries.unitSelected}
              />
              <StatisticalValue
                description={t("analysis.details.statistics-modal.sum")}
                value={statisticsConverted.sum}
                unit={settingsTimeseries.unitSelected}
              />
              <StatisticalValue
                description={t("analysis.details.statistics-modal.min")}
                value={statisticsConverted.min}
                unit={settingsTimeseries.unitSelected}
              />
              <StatisticalValue
                description={t("analysis.details.statistics-modal.max")}
                value={statisticsConverted.max}
                unit={settingsTimeseries.unitSelected}
              />
              <StatisticalValue
                description={t(
                  "analysis.details.statistics-modal.time-of-last-datapoint",
                )}
                value={statisticsConverted.latestTimestamp}
              />
              <StatisticalValue
                description={t(
                  "analysis.details.statistics-modal.different-between-first-and-last-datapoint",
                )}
                value={Math.abs(
                  statisticsConverted.latestValue -
                    statisticsConverted.firstValue,
                )}
                unit={settingsTimeseries.unitSelected}
              />
            </StatisticsContainer>
          </>
        ) : (
          t("analysis.details.statistics-modal.no-data-for-selected-period")
        )}
      </SpinnerWithDelay>
    </BodyContainer>
  );
}
