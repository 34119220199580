import { PageHeader } from "@ant-design/pro-layout";
import { App, Avatar, Button, Form, Input, Popconfirm, Space, Tag } from "antd";
import { useTranslations } from "@properate/translations";
import {
  Link,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { RightOutlined, WarningOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import { parsePhoneNumber } from "libphonenumber-js";
import { useTheme } from "styled-components";
import useSWR from "swr";
import { useUser } from "@properate/auth";
import * as React from "react";
import { CompactContent } from "@/components/CompactContent";
import { AlertGroupAPI, AlertGroupLoader } from "@/features/alarms";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import {
  createGroup,
  deleteGroup,
  getGroupDependedData,
  updateGroup,
} from "@/eepApi";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useAlertGroupNames } from "@/pages/alarmSystem/groupDetails/utils";
import { AlertRuleUpdateModal } from "@/pages/alarmSystem/groupDetails/GroupUpdateModal";
import { GroupDetailsTable } from "./GroupDetailsTable";

type FormValues = {
  name: string;
};

export const AlertGroup = () => {
  const t = useTranslations();
  const theme = useTheme();
  const currentBuilding = useCurrentBuilding();
  useBuildingPageTitle(t("alert-group.title"));
  const { message } = App.useApp();
  const user = useUser();

  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const pageData = useLoaderData() as AlertGroupLoader;

  const { groupNames, isLoadingGroups } = useAlertGroupNames(pageData.id);

  const { data: dependedData, isLoading: isLoadingDependedData } = useSWR(
    ["getDependedData", pageData.id],
    () =>
      pageData.id
        ? getGroupDependedData({
            building_external_id: currentBuilding.externalId!,
            id: pageData.id,
          })
        : undefined,
  );
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [members, setMembers] = useState(pageData.members || []);
  const [form] = Form.useForm<FormValues>();
  const name = Form.useWatch("name", form);

  const hasUnsavedChanges = useMemo(() => {
    const pageMemberEmails =
      pageData.members?.map((member) => member.defaultUserEmail) || [];
    const memberEmails = members.map((member) => member.defaultUserEmail);
    const isMembersSame =
      JSON.stringify(pageMemberEmails) === JSON.stringify(memberEmails);
    const isNameSame = pageData.name === name;
    return !isMembersSame || !isNameSame;
  }, [members, name, pageData.members, pageData.name]);

  const validateMembers = () => {
    return !members.some((member) => {
      if (!member.defaultUserEmail) {
        message.error(t("alert-group.please-select-user"));
        return true;
      }

      if (!member.notifyByPhone && !member.notifyByEmail) {
        message.error(t("alert-group.please-select-notification-method"));
        return true;
      }

      if (member.notificationPhone) {
        try {
          const valueParsedAsPhone = parsePhoneNumber(
            member.notificationPhone,
            "NO",
          );

          if (!valueParsedAsPhone.isValid()) {
            message.error(t("alert-group.invalid-phone-number"));
            return true;
          }
        } catch (e) {
          message.error(t("alert-group.invalid-phone-number"));
          return true;
        }
      }

      if (member.notifyByPhone && !member.notificationPhone) {
        message.error(t("alert-group.please-enter-phone-number"));
        return true;
      }

      return false;
    });
  };

  const onCancel = () => {
    navigate("../alertGroups");
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async () => {
    if (!pageData.id) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteGroup(pageData.id);
    } catch (e) {
      setIsDeleting(false);
      message.error(t("alert-group.delete-failed"));
      console.error("error", e);
      return;
    }

    navigate("../alertGroups");
  };

  const [isUpdating, setIsUpdating] = useState(false);

  const showUpdateValidationWarning =
    pageData.id &&
    (dependedData?.alert_configurations.length ||
      dependedData?.incidents.length);

  const onSubmit = async (values: FormValues) => {
    if (!validateMembers()) {
      return;
    }
    setIsUpdating(true);

    if (!currentBuilding.externalId) return;

    if (pageData.id) {
      try {
        const data = AlertGroupAPI.parse({
          id: pageData.id,
          name: values.name,
          buildingExternalId: currentBuilding.externalId,
          members,
        });

        await updateGroup(pageData.id, data);
        message.success(t("alert-group.update-success"));
        revalidator.revalidate();
      } catch (e) {
        setIsUpdating(false);
        message.error(t("alert-group.update-failed"));
        console.error("error", e);
        return;
      }
    }

    if (!pageData.id) {
      try {
        const data = AlertGroupAPI.parse({
          id: pageData.id,
          name: values.name,
          buildingExternalId: currentBuilding.externalId,
          members,
        });
        const group = await createGroup(data);
        message.success(t("alert-group.update-success"));
        navigate(`../alertGroups/${group.id}`);
      } catch (e) {
        setIsUpdating(false);
        message.error(t("alert-group.create-failed"));
        console.error("error", e);
        return;
      }
    }

    setIsUpdating(false);
  };

  const getTitle = () => {
    if (!pageData.id) {
      return t("alert-group.new-group");
    }
    return pageData.name;
  };

  return (
    <div>
      <PageHeader
        title={
          <Space>
            <Link to={`../alertGroups`}>{t("alert-groups.title")}</Link>
            <RightOutlined />
            {getTitle()}
            {hasUnsavedChanges && (
              <Tag className="ml-2" color={theme.warning}>
                {t("alert-group.unsaved-changes")}
              </Tag>
            )}
          </Space>
        }
        extra={
          user.isAdmin
            ? [
                <Button
                  key="new"
                  onClick={onCancel}
                  aria-label={t("alert-group.cancel")}
                >
                  {t("alert-group.cancel")}
                </Button>,
                ...(pageData.id
                  ? [
                      <Popconfirm
                        key="delete"
                        title={t("alert-groups.delete-confirm")}
                        onConfirm={onDelete}
                      >
                        <Button
                          danger
                          ghost
                          loading={isDeleting}
                          aria-label={t("alert-group.delete")}
                        >
                          {t("alert-group.delete")}
                        </Button>
                      </Popconfirm>,
                      <Button
                        key="save"
                        ghost
                        type="primary"
                        aria-label={t("alert-group.save")}
                        htmlType="submit"
                        form="alert-group"
                        disabled={isLoadingDependedData}
                        loading={
                          isUpdating || isLoadingGroups || isLoadingDependedData
                        }
                      >
                        {t("alert-group.save")}
                      </Button>,
                    ]
                  : [
                      <Button
                        key="create"
                        type="primary"
                        ghost
                        htmlType="submit"
                        form="alert-group"
                        aria-label={t("alert-group.create")}
                        loading={isUpdating || isLoadingGroups}
                      >
                        {t("alert-group.create")}
                      </Button>,
                    ]),
                showUpdateValidationWarning && (
                  <Avatar
                    onClick={() => setShowUpdateModal(true)}
                    shape="square"
                    style={{
                      backgroundColor: theme.warningBg,
                      color: theme.warningFg,
                    }}
                    size="default"
                    icon={<WarningOutlined />}
                  />
                ),
              ]
            : []
        }
      />
      <CompactContent>
        <Form<FormValues>
          form={form}
          name={"alert-group"}
          layout="vertical"
          initialValues={{
            name: pageData.name,
          }}
          onFinish={onSubmit}
          disabled={!user.isAdmin}
        >
          <Form.Item
            label={t("alert-group.name")}
            name="name"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (groupNames.includes(value)) {
                    return Promise.reject(t("alert-group.name-exists"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input required />
          </Form.Item>
          <GroupDetailsTable
            members={members}
            setMembers={setMembers}
            validateMembers={validateMembers}
          />
        </Form>
      </CompactContent>
      {showUpdateModal && (
        <AlertRuleUpdateModal
          onClose={() => {
            setShowUpdateModal(false);
            setIsUpdating(false);
          }}
          alertConfigurations={dependedData?.alert_configurations || []}
          incidents={dependedData?.incidents || []}
        />
      )}
    </div>
  );
};
