import { Button, Col, Divider, Input, Modal, Row, Space } from "antd";
import { DeleteOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslations } from "@properate/translations";
import { InputWithError } from "@/pages/common/SchemaGraph/InputWithError";

type Props = {
  validValues: Record<string, string>;
  setValidValues: (validValues: Record<string, string>) => void;
  originalValidValues: Record<string, string>;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export const SetValidValuesModal = ({
  validValues,
  setValidValues,
  originalValidValues,
  open,
  setOpen,
}: Props) => {
  const t = useTranslations();

  const [localValidValues, setLocalValidValues] = useState(validValues);
  const [addRowActive, setAddRowActive] = useState(false);
  const [addRowValue, setAddRowValue] = useState("");
  const [addRowLabel, setAddRowLabel] = useState("");
  const [addRowError, setAddRowError] = useState<string | null>(null);

  useEffect(() => {
    setLocalValidValues(validValues);
  }, [validValues]);

  const reset = () => {
    setAddRowLabel("");
    setAddRowValue("");
    setAddRowError(null);
    setAddRowActive(false);
  };
  const handleCloseAddRow = () => {
    reset();
    setLocalValidValues(validValues);
  };

  const handleOk = () => {
    if (!_.isEqual(validValues, localValidValues)) {
      setValidValues(localValidValues);
    }
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title={t("calendar.configure-valid-values")}
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      okButtonProps={{ disabled: addRowActive }}
      cancelButtonProps={{ disabled: addRowActive }}
      closable={!addRowActive}
      destroyOnClose
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row>
          <Col span={12}>
            <b>{t("calendar.value")}</b>
          </Col>
          <Col span={12}>
            <b>{t("calendar.label")}</b>
          </Col>
        </Row>
        {Object.keys(localValidValues)
          .sort((a, b) => {
            if (a === "NULL") {
              return -1;
            }
            if (b === "NULL") {
              return 1;
            }
            return a.localeCompare(b);
          })
          .map((key) => (
            <Row key={key} gutter={8}>
              <Col flex="auto">
                <Row gutter={8}>
                  <Col span={12}>
                    <span
                      style={{
                        padding: "5px 7px",
                        lineHeight: "20px",
                        display: "inline-block",
                      }}
                    >
                      {key}
                    </span>
                  </Col>
                  <Col span={12}>
                    <Input
                      value={localValidValues[key]}
                      onChange={(event) => {
                        setLocalValidValues({
                          ...localValidValues,
                          [key]: event.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col flex="none">
                <Button
                  icon={<DeleteOutlined />}
                  disabled={addRowActive}
                  onClick={() => {
                    const { [key]: _, ...validValuesExceptDeleted } =
                      localValidValues;
                    setLocalValidValues(validValuesExceptDeleted);
                  }}
                />
              </Col>
            </Row>
          ))}
        <Row>
          <Col span={24}>
            {addRowActive && (
              <Row key="new" gutter={8}>
                <Col flex="auto">
                  <Row gutter={8}>
                    <Col span={12}>
                      <InputWithError
                        value={addRowValue}
                        placeholder="1"
                        onChange={(value) => setAddRowValue(value || "")}
                        error={addRowError || undefined}
                        marginBottom={8}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        value={addRowLabel}
                        placeholder={t("calendar.on")}
                        onChange={(event) => setAddRowLabel(event.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col flex="none">
                  <Button
                    icon={<CloseOutlined />}
                    onClick={handleCloseAddRow}
                  />
                </Col>
              </Row>
            )}{" "}
            {addRowActive ? (
              <Button
                icon={<PlusOutlined />}
                block
                size="small"
                onClick={() => {
                  if (!addRowValue) {
                    setAddRowError(t("calendar.you-should-enter-value"));
                    return;
                  }
                  if (
                    addRowValue !== "NULL" &&
                    isNaN(Number(addRowValue.trim()))
                  ) {
                    setAddRowError(t("calendar.valid-values-error"));
                    return;
                  }
                  setLocalValidValues({
                    ...localValidValues,
                    [addRowValue.trim()]: addRowLabel.trim() || addRowValue,
                  });
                  reset();
                }}
              >
                {t("calendar.update")}
              </Button>
            ) : (
              <Button
                icon={<PlusOutlined />}
                block
                size="small"
                onClick={() => setAddRowActive(true)}
              >
                {t("calendar.add-value")}
              </Button>
            )}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <Button
              danger
              block
              size="small"
              onClick={() => setLocalValidValues(originalValidValues)}
              disabled={addRowActive}
            >
              {t("calendar.reset-to-BACnet-values")}
            </Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
};
