import { cn } from "@properate/ui";
import { PropsWithChildren, useEffect, useRef } from "react";
import { Pin } from "./types";
import { FloorPlanMapPinSetPointStrategy } from "./FloorPlanMapPinSetPointStrategy";
import { FloorPlanMapPinSensorStrategy } from "./FloorPlanMapPinSensorStrategy";
import { FloorPlanPinProvider } from "./FloorPlanMapPinContext";
import { FloorPlanMapPinValue } from "./FloorPlanMapPinValue";
import { FloorPlanMapPinPopover } from "./FloorPlanMapPinPopover";
import { useFloorPlan, useFloorPlanEditMode } from "./FloorPlanContext";
import { FloorPlanMapPinEditableSign } from "./FloorPlanMapPinEditableSign";
import {
  useFloorPlanMapApi,
  useFloorPlanMapMoveHandlers,
} from "./FloorPlanMapContext";
import { FloorPlanMapPinRoomStrategy } from "./FloorPlanMapPinRoomStrategy";

type Props = {
  pin: Pin;
};

export function FloorPlanMapPin(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isEditing] = useFloorPlanEditMode();
  const api = useFloorPlanMapApi();
  const moveHandlers = useFloorPlanMapMoveHandlers();
  const floorPlan = useFloorPlan();

  let FloorPlanMapPinOnClickStrategy: React.FC<PropsWithChildren>;

  if (props.pin.type === "room") {
    FloorPlanMapPinOnClickStrategy = FloorPlanMapPinRoomStrategy;
  } else {
    if (props.pin.type === "set-point-general") {
      FloorPlanMapPinOnClickStrategy = FloorPlanMapPinSetPointStrategy;
    } else {
      FloorPlanMapPinOnClickStrategy = FloorPlanMapPinSensorStrategy;
    }
  }

  useEffect(() => {
    const handler = ({ zoom }: { zoom: number }) => {
      if (containerRef.current) {
        const scale = Math.max(
          0.25,

          // Scale down the elements for every 1 step in zoom, 0 = 1, 1 = .75, 2 = .5, 3 = .25
          Math.min(1.5, (5 - Math.round(zoom)) * 0.25),
        );

        containerRef.current.style.scale = scale.toFixed(1);
      }
    };

    moveHandlers.add(handler);

    // Set the initial scale
    handler({ zoom: api ? api.getZoom() : 1 });

    return () => {
      moveHandlers.delete(handler);
    };
  }, [
    moveHandlers,
    api,
    floorPlan.background.width,
    floorPlan.background.height,
  ]);

  return (
    <div
      ref={containerRef}
      className={cn(
        "flex gap-4 bg-background text-background-foreground rounded-full hover:bg-card",
        "border border-solid border-black",
        isEditing && "cursor-grab active:cursor-grabbing",
        props.pin.type === "set-point-general" &&
          "border-orange-400 shadow-orange-300/50 text-orange-600 dark:text-orange-300",
      )}
    >
      <FloorPlanPinProvider pin={props.pin}>
        <FloorPlanMapPinPopover>
          <FloorPlanMapPinOnClickStrategy>
            <FloorPlanMapPinEditableSign>
              <FloorPlanMapPinValue />
            </FloorPlanMapPinEditableSign>
          </FloorPlanMapPinOnClickStrategy>
        </FloorPlanMapPinPopover>
      </FloorPlanPinProvider>
    </div>
  );
}
