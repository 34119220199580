import { useTranslations } from "@properate/translations";
import { Button, Modal } from "antd";
import { GetGroupDependedDataResponse } from "@properate/common";
import { ExportOutlined } from "@ant-design/icons";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

type Props = {
  onClose: () => void;
  alertConfigurations: GetGroupDependedDataResponse["alert_configurations"];
  incidents: GetGroupDependedDataResponse["incidents"];
};

export const AlertRuleUpdateModal = ({
  onClose,
  alertConfigurations,
  incidents,
}: Props) => {
  const t = useTranslations();
  const currentBuildingId = useCurrentBuildingId();

  return (
    <Modal
      title={t("alert-group.warnings.title")}
      open
      onCancel={onClose}
      onOk={onClose}
      footer={(_, { OkBtn }) => <OkBtn />}
    >
      <div className="flex flex-col gap-2">
        {!!alertConfigurations.length && (
          <div className="flex flex-col gap-4">
            <div className="font-bold">
              {t("alert-group.warnings.alert-configuration-changes")}
            </div>
            <div>{t("alert-group.warnings.alarm-configuration-message")}</div>
            <div className="max-h-[20vh] overflow-y-scroll">
              {alertConfigurations.map((alert) => (
                <div key={alert.id} className="flex justify-between mr-2">
                  <div className="flex-1">{alert.name}</div>
                  <Button
                    onClick={() => {
                      window.open(
                        `/asset/${currentBuildingId}/alertConfiguration/${alert.id}`,
                        "_blank",
                        "noopener,noreferrer",
                      );
                    }}
                    type="link"
                    icon={<ExportOutlined />}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {!!incidents.length && (
          <div className="flex flex-col gap-4">
            <div className="font-bold">
              {t("alert-group.warnings.incident-changes")}
            </div>
            <div>{t("alert-group.warnings.incidents-message")}</div>
            <div className="max-h-[20vh] overflow-y-scroll">
              {incidents.map((incident) => (
                <div key={incident.id} className="flex justify-between mr-2">
                  <div className="flex-1">{incident.name}</div>
                  <Button
                    onClick={() => {
                      window.open(
                        `/asset/${currentBuildingId}/incidents/${incident.id}`,
                        "_blank",
                        "noopener,noreferrer",
                      );
                    }}
                    type="link"
                    icon={<ExportOutlined />}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
