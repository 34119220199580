import { ReactComponent as SunriseIcon } from "./sunrise.svg";
import { ReactComponent as SunsetIcon } from "./sunset.svg";

interface Props {
  type: "sunrise" | "sunset";
}

export function SunIcon({ type }: Props) {
  return type === "sunrise" ? (
    <SunriseIcon style={{ verticalAlign: "middle" }} />
  ) : (
    <SunsetIcon style={{ verticalAlign: "middle" }} />
  );
}
