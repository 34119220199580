import { useContext, useEffect, useState } from "react";
import {
  sankey,
  SankeyLink,
  sankeyLinkHorizontal,
  SankeyNode,
  SankeyNodeMinimal,
} from "d3-sankey";
import { Tooltip } from "antd";
import { CogniteClient, Timeseries, DatapointAggregate } from "@cognite/sdk";
import { ThemeContext } from "styled-components";
import {
  getDate,
  getEnergyHierarchyForBuilding,
  HierarchyNode,
  linksFromEnergyHierarchy,
  traverseEnergyHierarchy,
} from "../../utils/helpers";
import { PRIMARY } from "../../utils/ProperateColors";
import { useCogniteClient } from "../../context/CogniteClientContext";

const START_FROM_PERIOD = {
  "24h": "24h-ago",
  "168h": "168h-ago",
  "720h": "720h-ago",
};

type SankeyDiagramProps = {
  id: number;
  width: number;
  height: number;
  period?: "24h" | "168h" | "720h";
  start?: string | Date;
  end?: string | Date;
  unit: "kWh" | "%";
  organization: string;
  hierarchyNode?: HierarchyNode;
};
export const SankeyDiagram = ({
  id,
  width,
  height,
  period,
  start,
  end = "now",
  unit,
  organization,
  hierarchyNode,
}: SankeyDiagramProps) => {
  const [data, setData] = useState<{
    nodes: Timeseries[];
    links: any[];
  }>();

  const { client } = useCogniteClient();

  if (!start && !period) {
    console.error("SankeyDiagram: start or period is required");
  }

  useEffect(() => {
    const load = async (client: CogniteClient) => {
      setData(undefined);
      let root = hierarchyNode;
      if (!hierarchyNode) {
        root = await getEnergyHierarchyForBuilding(client, id, organization);
      }
      if (root) {
        // get values
        const nodes = traverseEnergyHierarchy(root);

        const valueMap = (
          await Promise.all(
            await client.datapoints.retrieve({
              items: nodes.map((node) => ({ id: node.id })),
              start: start
                ? getDate(start).toDate()
                : START_FROM_PERIOD[period!],
              end: getDate(end).toDate(),
              granularity:
                period || `${getDate(end).diff(getDate(start), "hours")}h`,
              aggregates: ["sum"],
            }),
          )
        )
          .flat()
          .reduce<Record<number, number>>((prev, result) => {
            if (result.datapoints.length === 0) {
              console.error("No datapoints found for", result.id);
              return prev;
            }
            return {
              ...prev,
              [result.id]: (result.datapoints[0] as DatapointAggregate).sum!,
            };
          }, {});

        const links = linksFromEnergyHierarchy(root)
          .map((link) => ({
            ...link,
            value:
              unit === "kWh"
                ? valueMap[link.target.id]
                : (valueMap[link.target.id] / valueMap[root?.node.id || 0]) *
                  100,
            unit: unit === "kWh" ? "kWh" : "%",
          }))
          .filter((link) => link.value > 0);

        const d = { nodes: nodes.filter((n) => valueMap[n.id] > 0), links };

        // sanity check
        if (
          d.links.every(
            (l) =>
              d.nodes.find((ts) => l.source.id === ts.id) &&
              d.nodes.find((ts) => l.target.id === ts.id),
          )
        ) {
          setData(d);
        } else {
          console.error("Sankey diagram data is invalid");
          setData({ nodes: [], links: [] });
        }
      } else {
        setData({ nodes: [], links: [] });
      }
    };
    if (client) {
      load(client);
    }
  }, [id, client, period, start, end, unit, organization, hierarchyNode]);

  // bounds
  const padding = 24;
  const header = 0;
  const graphWidth = width > 0 ? width - padding * 2 : 0;
  const graphHeight = height - padding * 2 - header;

  return (
    <>
      {data && data.links.length > 0 && data.nodes.length > 0 ? (
        <svg
          width={graphWidth}
          height={graphHeight}
          style={{ marginTop: 35, overflow: "visible" }}
        >
          <g>
            <BuildSankeyDiagram
              data={data}
              width={graphWidth}
              height={graphHeight}
              unit={unit === "kWh" ? "kWh" : "%"}
            />
          </g>
        </svg>
      ) : (
        <></>
      )}
    </>
  );
};

const numberFormat = new Intl.NumberFormat("nb-NO", {
  maximumFractionDigits: 1,
});

const Node = ({
  name,
  value,
  unit,
  x0,
  x1,
  y0,
  y1,
  color,
  textColor,
}: SankeyNode<any, any>) => {
  return (
    <>
      <rect x={x0} y={y0} width={x1 - x0} height={y1 - y0} fill={color} />
      <foreignObject x={x1 + 5} y={(y1 + y0) / 2 - 20} width={250} height={40}>
        <div
          style={{
            color: textColor,
            textOverflow: "ellipsis",
            overflowY: "visible",
            overflowX: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <Tooltip title={name}>{name}</Tooltip>
          <br />
          {value
            ? `${numberFormat.format(value)}${unit === "kWh" ? " kWh" : "%"}`
            : ""}
        </div>
      </foreignObject>
    </>
  );
};

const SankeyLinkElement = ({ link, color }: any) => {
  return (
    <>
      <path
        d={sankeyLinkHorizontal()(link)!}
        style={{
          fill: "none",
          strokeOpacity: ".3",
          stroke: color,
          strokeWidth: Math.max(1, link.width),
        }}
      />
    </>
  );
};

type NodeLinksProps = {
  data: {
    nodes: Timeseries[];
    links: {
      source: Timeseries;
      target: Timeseries;
      value: number;
    }[];
  };
  width: number;
  height: number;
  unit: string;
};

interface SNodeExtra {
  node: number;
  name: string;
}

interface SLinkExtra {
  source: number;
  target: number;
  value: number;
  unit: string;
}

type SNode = SankeyNode<SNodeExtra, SLinkExtra>;
type SLink = SankeyLink<SNodeExtra, SLinkExtra>;

interface DAG {
  nodes: SNode[];
  links: SLink[];
}

const BuildSankeyDiagram = ({ data, width, height, unit }: NodeLinksProps) => {
  const themeContext = useContext(ThemeContext);

  const [nodes, setNodes] = useState<SankeyNodeMinimal<any, any>[]>([]);
  const [links, setLinks] = useState<SankeyNodeMinimal<any, any>[]>([]);

  useEffect(() => {
    const d3Sankey = sankey()
      .nodeWidth(20)
      .nodePadding(80)
      .extent([
        [1, 1],
        [width - 250, height - 45],
      ]);
    const { nodes, links } = d3Sankey({
      nodes: data.nodes.map((ts, index) => ({
        node: index,
        name: ts.description,
      })),
      links: data.links.map((link) => ({
        source: data.nodes.indexOf(link.source),
        target: data.nodes.indexOf(link.target),
        value: link.value,
      })),
    } as DAG);
    setNodes(nodes);
    setLinks(links);
  }, [data, width, height, unit]);

  const color = [
    PRIMARY,
    themeContext.accent2,
    themeContext.accent3,
    themeContext.accent4,
    themeContext.accent5,
    themeContext.accent6,
    themeContext.accent7,
    themeContext.accent8,
    themeContext.accent9,
    themeContext.accent10,
    themeContext.accent11,
  ];

  return (
    <>
      {links &&
        links.length > 0 &&
        links.map((link: any) => {
          return (
            <SankeyLinkElement
              link={link}
              key={link.index}
              color={color[link.source.index]}
            />
          );
        })}
      {nodes &&
        nodes.length > 0 &&
        nodes.map((node: any, i: number) => (
          <Node
            {...node}
            color={color[i]}
            key={node.index}
            textColor={themeContext.neutral1}
            unit={unit}
          />
        ))}
    </>
  );
};
