import { Radio, Space } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { HeatMapViewMode } from "../types";
import { viewModeOptions } from "../utils";

interface Props {
  value: HeatMapViewMode;
  onChange: (value: HeatMapViewMode) => unknown;
}

export function HeatMapViewModeSelection({ value, onChange }: Props) {
  const t = useTranslations();

  return (
    <Space>
      <label htmlFor="viewMode">{t("analysis.heat-map.show")}:</label>
      <Radio.Group
        id="viewMode"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        {viewModeOptions.map((viewModeOption) => (
          <Radio key={viewModeOption.value} value={viewModeOption.value}>
            {t(
              `analysis.heat-map.view-mode.${viewModeOption.value}` as MessageKey,
            )}
          </Radio>
        ))}
      </Radio.Group>
    </Space>
  );
}
