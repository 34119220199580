import {
  DownloadOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { FileUploadResponse } from "@cognite/sdk";
import { useUser } from "@properate/auth";
import { ASSET_DESCRIPTIONS, COMPONENTS } from "@properate/common";
import { Task } from "@properate/task-manager";
import {
  App,
  AutoComplete,
  Button,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Upload,
  UploadFile,
} from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { RcFile } from "antd/lib/upload/interface";
import axios from "axios";
import { Await, Link, useLoaderData, useNavigate } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { Suspense, useCallback, useMemo, useState } from "react";
import { genId, ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { getFileUrl, getIcon } from "@/utils/cdf";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { isAxiosError, isErrorWithMessage } from "@/utils/api";
import {
  ComponentInfo,
  createOrUpdateComponent,
  deleteComponent,
} from "@/eepApi";
import { CompactContent } from "@/components/CompactContent";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";

import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import {
  NoteBuilding,
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import {
  getComponentTitle,
  useHandleDeleteComponentFile,
} from "@/pages/Components/details/utils";
import { ComponentDetailsFileCustomIcon } from "@/pages/Components/details/ComponentDetailsFileCustomIcon";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { SubComponents } from "./SubComponents";
import { ComponentPage } from "./types";

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

const componentCodes = Object.keys(COMPONENTS)
  .filter((code) => code.length === 1)
  .sort((a, b) => a.localeCompare(b))
  .map(
    (code) =>
      ({
        value: code,
        label: COMPONENTS[code].Komponentfunksjon + " (" + code + ")",
        children: Object.keys(COMPONENTS)
          .filter((subCode) => subCode.startsWith(code) && subCode.length > 1)
          .map(
            (subCode) =>
              ({
                value: subCode,
                label:
                  COMPONENTS[subCode].Komponentfunksjon + " (" + subCode + ")",
              }) as Option,
          )
          .sort((a, b) => (a.value as string).localeCompare(b.value as string)),
      }) as Option,
  );

const tfm0Options = Object.entries(ASSET_DESCRIPTIONS).filter(
  ([tfm, _]) => tfm.length == 1,
);
const tfm1Options = Object.entries(ASSET_DESCRIPTIONS).filter(
  ([tfm, _]) => tfm.length == 2 && tfm !== "20",
);
const tfm2Options = Object.entries(ASSET_DESCRIPTIONS).filter(
  ([tfm, _]) => tfm.length == 3,
);

// 2 level
const tfmTreeData = tfm0Options.map(([tfm0, title0]) => {
  const filteredTfm1Options = tfm1Options.filter(([tfm1, _]) =>
    tfm1.startsWith(tfm0),
  );

  return {
    label: `${tfm0}: ${title0}`,
    value: tfm0,
    selectable: false,
    // 21 level
    children: filteredTfm1Options.map(([tfm1, title1]) => {
      const filteredTfm2Options = tfm2Options.filter(([tfm2, _]) =>
        tfm2.startsWith(tfm1),
      );

      return {
        label: `${tfm1}: ${title1}`,
        selectable: false,
        value: tfm1,
        // 211 level
        children: filteredTfm2Options.map(([tfm2, title2]) => {
          const tfm3Options = [...Array(200).keys()].map((nnn) => {
            return tfm2 + "." + String(nnn).padStart(3, "0");
          });
          return {
            label: `${tfm2}: ${title2}`,
            selectable: false,
            value: tfm2,
            key: tfm2,
            children:
              // Suffix level
              tfm3Options.map((value) => {
                return {
                  label:
                    value +
                    ": " +
                    ASSET_DESCRIPTIONS[Number(value.substring(0, 3))],
                  value,
                  key: value,
                };
              }),
          };
        }),
      };
    }),
  };
});

const getPathToRoom = (tree: DefaultOptionType[], id: string) => {
  function traverse(node: DefaultOptionType, path: string[]): string[] | null {
    if (!node) {
      return null;
    }
    // Add the current node to the path
    path.push(node.value as string);

    // Check if the current node is the target
    if (node.value === id) {
      return path;
    }

    if (!node.children) {
      return null;
    }

    // Recursively search in the children
    for (const child of node.children) {
      const resultPath = traverse(child as DefaultOptionType, path.slice()); // Create a copy of the path for each child
      if (resultPath) {
        return resultPath; // Return the path if the target is found in the subtree
      }
    }

    return null; // Return null if the target is not found in the subtree
  }

  // Iterate over each root and apply the finding function
  for (const root of tree) {
    const path = traverse(root, []);
    if (path) {
      return path; // Return the path if the target is found in the tree
    }
  }

  return null; // Return null if the target is not found in any tree
};

const ComponentPageHeader = ({
  page,
  onConfirm,
  loading,
  onClick,
  disabled,
}: {
  page: ComponentPage;
  onConfirm: () => Promise<void>;
  loading: boolean;
  onClick: () => void;
  disabled: boolean;
}) => {
  const t = useTranslations();

  return (
    <PageHeader
      title={
        <Space>
          <Link to={`../components`}>{t("components.details.title")}</Link>
          <RightOutlined />
          {getComponentTitle(page.component, t)}
        </Space>
      }
      extra={
        <Space>
          <Popconfirm
            title={
              page.component.externalId
                ? t("components.details.delete-component", {
                    description: page.component.description,
                  })
                : t("components.details.are-you-sure-to-cancel")
            }
            icon={null}
            onConfirm={onConfirm}
          >
            <Button
              danger
              disabled={
                disabled ||
                (!!page.component.externalId &&
                  page.component.source !== "generated_asset/user_defined")
              }
              loading={loading}
            >
              {page.component.externalId
                ? t("components.details.delete")
                : t("components.details.cancel")}
            </Button>
          </Popconfirm>
          <Button onClick={onClick} disabled={disabled} loading={disabled}>
            {t("components.details.save")}
          </Button>
          <ToggleSidebarButton
            key="notes"
            disabled={!page.component.id}
            hiddenWhenSidebarVisible
            sidebarHiddenContent={t("notes.show-notes-button")}
          />
        </Space>
      }
    />
  );
};

export function ComponentsDetails() {
  const t = useTranslations();
  const { message } = App.useApp();
  const user = useUser();
  const page = useLoaderData() as ComponentPage;
  const navigate = useNavigate();
  useBuildingPageTitle(getComponentTitle(page.component, t));
  const currentBuilding = useCurrentBuilding();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const { client } = useCogniteClient();
  const [deleting, setDeleting] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>(page.files);

  const subBuildingOptions = useCallback(
    (subBuildings: string[]) =>
      subBuildings.map((subBuilding) => ({
        value: subBuilding,
        label: subBuilding,
      })),
    [],
  );

  const saveComponent = async (
    values: ComponentInfo & { externalId?: string; room?: string },
  ) => {
    setSaving(true);
    const { room, ...component } = values;
    try {
      const result = await createOrUpdateComponent({
        ...component,
      });

      if (room && page.room?.roomExternalId !== room) {
        // delete old relationship if changed
        if (page.room) {
          await client.relationships.delete([
            { externalId: page.room.relationshipExternalId },
          ]);
        }
        await client.relationships.create([
          {
            sourceExternalId: room,
            targetExternalId: result.externalId,
            externalId: `rel_${crypto.randomUUID()}`,
            confidence: 1,
            sourceType: "asset" as const,
            targetType: "asset" as const,
            dataSetId: currentBuilding.dataSetId,
            labels: [{ externalId: "U_rel_room_asset" }],
          },
        ]);
      }
      if (result.externalId !== page.component.externalId) {
        const [asset] = await client.assets.retrieve([
          { externalId: result.externalId },
        ]);
        navigate(`../components/${asset.id}`);
      }
    } catch (error) {
      console.error(error);
      if ((error as any)?.response?.data?.message) {
        message.error({
          content: t("components.details.failed-to-update-component", {
            error: (error as any).response.data.message,
          }),
          duration: 7,
        });
      } else {
        message.error({
          content: t("components.details.failed-to-update-component", {
            error: isErrorWithMessage(error)
              ? error.message
              : (error as string),
          }),
          duration: 7,
        });
      }
    } finally {
      setSaving(false);
    }
  };

  function handleAddTask() {
    if (!page.component.id) {
      message.error({
        content: t("components.details.save-before-upload"),
      });
      return;
    }

    navigate(`../workOrders/create?components=${page.component.id}`);
  }

  async function handleUploadFile(file: RcFile) {
    if (!page.component.id) {
      message.error({
        content: t("components.details.save-before-upload"),
      });
      return;
    }
    try {
      if (file.type.startsWith("video/")) {
        const id = genId();

        const inProgressFileList = [
          ...fileList,
          {
            uid: id,
            name: file.name,
            status: "uploading",
            percent: 0,
          } as UploadFile,
        ];

        setFileList(inProgressFileList);

        const storage = getStorage();

        const refPath = `components/${page.component.id}/${id}/${file.name}`;
        const fileRef = storageRef(storage, refPath);

        await uploadBytes(fileRef, file, {
          contentType: file.type,
        });

        setFileList(
          inProgressFileList.map((f) =>
            f.uid === id
              ? {
                  ...f,
                  percent: 50,
                }
              : f,
          ),
        );

        const url = await getDownloadURL(fileRef);

        const cogniteFile = await client.files.upload({
          name: file.name,
          dataSetId: currentBuilding.dataSetId,
          assetIds: [page.component.id],
          mimeType: file.type,
          externalId: id,
          metadata: {
            itemId: page.component.id.toString(),
            fileId: id,
            url,
          },
        });

        setFileList(
          inProgressFileList.map((f) =>
            f.uid === id
              ? {
                  ...f,
                  uid: cogniteFile.id.toString(),
                  status: "done",
                  mimeType: file.type,
                  percent: 100,
                  externalId: id,
                  url,
                }
              : f,
          ),
        );

        return;
      }

      const fileUploadResponse = await client.files.upload({
        name: file.name,
        dataSetId: currentBuilding.dataSetId,
        assetIds: [page.component.id],
        mimeType: file.type,
      });

      const inProgressFileList = [
        ...fileList,
        {
          uid: fileUploadResponse.id.toString(),
          name: file.name,
          status: "uploading",
          percent: 0,
        } as UploadFile,
      ];

      setFileList(inProgressFileList);

      await axios.put(
        (fileUploadResponse as FileUploadResponse).uploadUrl,
        file,
        {
          onUploadProgress: (progressEvent) => {
            setFileList(
              inProgressFileList.map((f) =>
                f.uid === fileUploadResponse.id.toString()
                  ? {
                      ...f,
                      percent: Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100,
                      ),
                    }
                  : f,
              ),
            );
          },
        },
      );
      const newFileList = await Promise.all(
        inProgressFileList.map((f) =>
          f.uid === fileUploadResponse.id.toString()
            ? getIcon(fileUploadResponse.id).then((data) => {
                return getFileUrl(fileUploadResponse.id).then((url) => {
                  return {
                    ...f,
                    percent: 100,
                    status: "done",
                    thumbUrl: data,
                    url,
                  } as UploadFile;
                });
              })
            : Promise.resolve(f),
        ),
      );
      setFileList(newFileList);
    } catch (error) {
      if (isAxiosError(error)) {
        message.error({
          content: t("components.details.failed-to-upload-file", {
            error: (error.response?.data || "") as string,
          }),
          duration: 7,
        });
      } else {
        message.error({
          content: t("components.details.failed-to-upload-file", {
            error: isErrorWithMessage(error)
              ? error.message
              : (error as string),
          }),
          duration: 7,
        });
      }
    }
  }

  const deleteFile = useHandleDeleteComponentFile(
    page.component.id?.toString(),
  );

  async function handleDeleteFile(file: UploadFile) {
    setFileList(
      fileList.map((f) =>
        f.uid === file.uid ? { ...f, status: "removed" } : f,
      ),
    );

    const isRemoved = await deleteFile(file);

    if (isRemoved) {
      setFileList(fileList.filter((f) => f.uid !== file.uid));
    } else {
      setFileList(
        fileList.map((f) =>
          f.uid === file.uid ? { ...f, status: "done" } : f,
        ),
      );
    }
  }

  const handleFinish = async (values: any) => {
    const {
      system,
      componentType,
      room,
      claimDate,
      deliveryDate,
      endOfLifeDate,
      ...component
    } = values;
    const componentTypeLast = componentType[values.componentType.length - 1];
    const systemLast = system[values.system.length - 1];
    const roomLast = room ? room[values.room.length - 1] : undefined;
    await saveComponent({
      ...component,
      externalId: page.component.externalId,
      componentSeqNum: component.componentSeqNum?.toString().padStart(3, "0"),
      buildingExternalId: currentBuilding.externalId,
      componentType: componentTypeLast,
      system: systemLast,
      claimDate: claimDate?.toDate(),
      deliveryDate: deliveryDate?.toDate(),
      endOfLifeDate: endOfLifeDate?.toDate(),
      room: roomLast,
    });
  };

  const handleDelete = async () => {
    if (page.component.externalId) {
      try {
        setDeleting(true);
        await deleteComponent({ externalId: page.component.externalId });
      } catch (error) {
        console.error(error);
        message.error({
          content: t("components.details.failed-to-delete-component", {
            error: isErrorWithMessage(error)
              ? error.message
              : (error as string),
          }),
          duration: 7,
        });
      } finally {
        setDeleting(false);
      }
    }
    navigate(`../components`);
  };

  function getInitialValues(
    page: ComponentPage,
    roomTree: DefaultOptionType[],
  ) {
    return {
      ...page.component,
      system: page.component.system
        ? [
            page.component.system.substring(0, 1),
            page.component.system.substring(0, 2),
            page.component.system.substring(0, 3),
            page.component.system,
          ]
        : "",
      componentType: page.component.component
        ? [
            page.component.component.substring(0, 1),
            page.component.component.substring(0, 2),
          ]
        : "",
      componentSeqNum: page.component.component
        ? Number(page.component.component.substring(2, 5))
        : undefined,
      room: page.room
        ? getPathToRoom(roomTree, page.room.roomExternalId)
        : undefined,
    };
  }

  const handleClearRoom = async () => {
    if (page.room) {
      await client.relationships.delete([
        { externalId: page.room.relationshipExternalId },
      ]);
    }
    form.setFieldsValue({ room: undefined });
  };

  const notesBuildings: NoteBuilding[] = useMemo(
    () =>
      page.component.dataSetId
        ? [{ id: page.component.dataSetId, name: "" }]
        : [],
    [page.component.dataSetId],
  );

  const assetIds = useMemo(
    () => new Set([page.component.id]),
    [page.component.id],
  );

  const promiseData = useMemo(
    () =>
      Promise.all([
        page.tasks,
        page.suppliers,
        page.contractors,
        page.manufacturers,
        page.subBuildings,
        page.roomTree,
      ]),
    [
      page.contractors,
      page.suppliers,
      page.tasks,
      page.manufacturers,
      page.subBuildings,
      page.roomTree,
    ],
  );

  return (
    <>
      <ComponentPageHeader
        page={page}
        onConfirm={handleDelete}
        loading={deleting}
        onClick={() => form.submit()}
        disabled={user.isViewer || saving}
      />
      <Suspense
        fallback={
          <div className="h-full flex justify-center items-center">
            <SpinnerWithDelay isLoading>
              <div />
            </SpinnerWithDelay>
          </div>
        }
      >
        <Await resolve={promiseData}>
          {([
            tasks,
            suppliers,
            contractors,
            manufacturers,
            subBuildings,
            roomTree,
          ]: [
            Task[],
            string[],
            string[],
            string[],
            string[],
            DefaultOptionType[],
          ]) => (
            <CompactContent>
              {page.component.id && (
                <SubComponents
                  assetId={page.component.id}
                  component={page.component.component}
                />
              )}
              <Form
                form={form}
                onFinish={handleFinish}
                layout="vertical"
                initialValues={getInitialValues(page, roomTree)}
                disabled={user.isViewer}
              >
                <Row gutter={12} style={{ paddingRight: 14 }}>
                  <Col span={12}>
                    <Row gutter={14}>
                      <Col span={8}>
                        <Form.Item
                          label={t("components.details.building")}
                          name="subBuilding"
                          rules={[
                            {
                              required: true,
                              message: t("components.details.select-building"),
                            },
                          ]}
                        >
                          <Select
                            options={subBuildingOptions(subBuildings)}
                            disabled={
                              user.isViewer || !!page.component.externalId
                            }
                            placeholder={t(
                              "components.details.select-building",
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item
                          label={t("components.details.system")}
                          name="system"
                          rules={[
                            {
                              required: true,
                              message: t("components.details.select-system"),
                            },
                          ]}
                        >
                          <Cascader
                            displayRender={(labels) =>
                              labels[labels.length - 1]
                            }
                            options={tfmTreeData}
                            placeholder="360.001"
                            showSearch
                            disabled={
                              user.isViewer || !!page.component.externalId
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          label={t("components.details.component-type")}
                          name="componentType"
                          rules={[
                            {
                              required: !page.component.externalId,
                              message: t(
                                "components.details.select-component-type",
                              ),
                            },
                          ]}
                        >
                          <Cascader
                            displayRender={(labels) =>
                              labels[labels.length - 1]
                            }
                            options={componentCodes}
                            placeholder={
                              !page.component.externalId
                                ? t("components.details.fan")
                                : undefined
                            }
                            showSearch
                            disabled={
                              user.isViewer || !!page.component.externalId
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          label={t("components.details.serial-number")}
                          name="componentSeqNum"
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            placeholder={
                              !page.component.externalId ? "1" : undefined
                            }
                            max={999}
                            disabled={
                              user.isViewer || !!page.component.externalId
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={9}>
                        <Form.Item
                          label={t("components.details.product")}
                          name="productCode"
                        >
                          <Input placeholder="XXYY-1-000" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label={t("components.details.room")}
                          name="room"
                        >
                          <Cascader
                            displayRender={(labels) =>
                              labels[labels.length - 1]
                            }
                            options={roomTree}
                            placeholder="402"
                            showSearch
                            onClear={handleClearRoom}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label={t("components.details.alternative-place")}
                          name="placement"
                        >
                          <Input placeholder="Rom 123" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={t("components.details.component-number")}
                          name="altComponentTag"
                        >
                          <Input placeholder="360.XXX" />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          label={t("components.details.component-name")}
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: t(
                                "components.details.select-component-name",
                              ),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              "components.details.component-name-example",
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t("components.details.other-info")}
                          name="componentInformation"
                        >
                          <Input.TextArea
                            rows={20}
                            placeholder={t("components.details.extended-info")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t("components.details.entrepreneur")}
                      name="contractor"
                    >
                      <AutoComplete
                        options={contractors.map((value) => ({
                          value,
                        }))}
                      >
                        <Input
                          placeholder={t(
                            "components.details.entrepreneur-example",
                          )}
                        />
                      </AutoComplete>
                    </Form.Item>
                    <Form.Item
                      label={t("components.details.supplier")}
                      name="supplier"
                    >
                      <AutoComplete
                        options={suppliers.map((value) => ({
                          value,
                        }))}
                      >
                        <Input
                          placeholder={t("components.details.supplier-example")}
                        />
                      </AutoComplete>
                    </Form.Item>
                    <Form.Item
                      label={t("components.details.producer")}
                      name="manufacturer"
                    >
                      <AutoComplete
                        options={manufacturers.map((value) => ({
                          value,
                        }))}
                      >
                        <Input
                          placeholder={t("components.details.producer-example")}
                        />
                      </AutoComplete>
                    </Form.Item>
                    <Row gutter={14}>
                      <Col span={8}>
                        <Form.Item
                          label={t("components.details.delivery-date")}
                          name="deliveryDate"
                        >
                          <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={t("components.details.end-of-life-date")}
                          name="endOfLifeDate"
                        >
                          <DatePicker
                            name="endOfLifeDate"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={t("components.details.return-date")}
                          name="claimDate"
                        >
                          <DatePicker
                            name="claimDate"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <div style={{ padding: "0 0 8px" }}>
                          {t("components.details.tasks")}
                        </div>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          {tasks.length > 0 ? (
                            <Table
                              pagination={tasks.length > 5 && { pageSize: 5 }}
                              size="small"
                              onRow={(record: Task) => ({
                                onClick: (event) => {
                                  event.preventDefault();
                                  navigate(
                                    `../workOrders/${record.snapshotId}`,
                                  );
                                },
                              })}
                              rowKey={(record) => record.snapshotId}
                              columns={[
                                {
                                  title: t("components.details.task"),
                                  dataIndex: "title",
                                  key: "title",
                                },
                                {
                                  title: t("components.details.type"),
                                  dataIndex: "type",
                                  key: "type",
                                },
                              ]}
                              dataSource={tasks}
                            />
                          ) : null}
                          <Button block onClick={handleAddTask}>
                            {t("components.details.add-task")}
                          </Button>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t("components.details.files")}
                          valuePropName="fileList"
                        >
                          <Upload
                            listType="picture"
                            beforeUpload={handleUploadFile}
                            onRemove={handleDeleteFile}
                            onPreview={(file) => {
                              navigate(`preview/${file.uid}`);
                            }}
                            fileList={fileList}
                            isImageUrl={() => {
                              // we don't want to show the image preview via AntD, but
                              // we want to show it via the `iconRender` property.
                              return false;
                            }}
                            iconRender={ComponentDetailsFileCustomIcon}
                            showUploadList={{
                              showDownloadIcon: true,
                              downloadIcon: <DownloadOutlined />,
                            }}
                          >
                            <Button icon={<UploadOutlined />}>
                              {t("components.details.upload")}
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </CompactContent>
          )}
        </Await>
      </Suspense>
      {!!notesBuildings.length && (
        <NotesSidebar
          noteSource={NoteSource.WEB_COMPONENTS}
          buildings={notesBuildings}
          assetFilterMode={NotesAssetFilterMode.AssetList}
          idSet={assetIds}
        />
      )}
    </>
  );
}
