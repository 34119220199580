import { scaleLinear } from "@visx/scale";
import { getItemByProp } from "@/utils/array";
import { ScaleLinearNumeric } from "@/utils/types";
import {
  SimplePointsWithMetadata,
  ScatterplotPointsWithMetadata,
  ScaleWithTimeseriesId,
  ScaleWithUnit,
  Range,
  SettingsTimeseries,
} from "../types";
import { getDomain, mapSimplePointsToDomainsWithMetadata } from "./domain";
import { blendColors } from "./colors";

export function isScalesWithUnit(
  scalesWithUnit: ScaleWithUnit[] | ScaleWithTimeseriesId[],
): scalesWithUnit is ScaleWithUnit[] {
  return scalesWithUnit.some((scale) => "unit" in scale);
}

export function mapSimplePointsToScalesWithMetadata(
  simplePointsWithMetadataList: SimplePointsWithMetadata[],
  range: Range,
  settingsTimeseriesList?: SettingsTimeseries[],
  options?: { mergeUnits: boolean },
): ScaleWithTimeseriesId[] | ScaleWithUnit[] {
  if (options?.mergeUnits) {
    return mapSimplePointsToDomainsWithMetadata(simplePointsWithMetadataList, {
      mergeUnits: true,
    }).map(({ domain, unit, metadata }) => {
      const settingsTimeseries = settingsTimeseriesList
        ? getItemByProp(settingsTimeseriesList, unit, "unitSelected")
        : null;
      return {
        unit,
        scale: scaleLinear({
          domain: [
            settingsTimeseries?.valueLimit?.min ?? domain[0],
            settingsTimeseries?.valueLimit?.max ?? domain[1],
          ],
          range,
          nice: true,
        }),
        metadata,
      };
    });
  }
  return mapSimplePointsToDomainsWithMetadata(simplePointsWithMetadataList, {
    mergeUnits: false,
  }).map(({ domain, timeseriesId, metadata }) => {
    const settingsTimeseries = settingsTimeseriesList
      ? getItemByProp(settingsTimeseriesList, timeseriesId)
      : null;
    return {
      timeseriesId,
      scale: scaleLinear({
        domain: [
          settingsTimeseries?.valueLimit?.min ?? domain[0],
          settingsTimeseries?.valueLimit?.max ?? domain[1],
        ],
        range,
        nice: true,
      }),
      metadata,
    };
  });
}

export function mapToScale<T extends { x: number; y: number }>(
  points: T[],
  range: Range,
  mapValue = (point: T) => point.y,
): ScaleLinearNumeric {
  const values = points.map(mapValue);
  return scaleLinear({
    domain: [Math.min(...values), Math.max(...values)],
    range,
    nice: true,
  });
}

export function mapScatterplotPointsToScalesWithMetadata(
  scatterplotPointsWithMetadataList: ScatterplotPointsWithMetadata[],
  range: Range,
  { mergeUnits } = { mergeUnits: false },
): ScaleWithTimeseriesId[] | ScaleWithUnit[] {
  if (mergeUnits) {
    return scatterplotPointsWithMetadataList.reduce<ScaleWithUnit[]>(
      (
        scaleWithUnitAcc,
        { scatterplotPoints, metadata: { colorY, unitY } },
      ) => {
        const newDomain = getDomain(scatterplotPoints);
        const currentScaleForUnitIndex = scaleWithUnitAcc.findIndex(
          ({ unit }) => unit === unitY,
        );
        if (currentScaleForUnitIndex > -1) {
          const currentScaleForUnit =
            scaleWithUnitAcc[currentScaleForUnitIndex];
          const currentDomain = currentScaleForUnit?.scale.domain();
          return [
            ...scaleWithUnitAcc.slice(0, currentScaleForUnitIndex),
            {
              unit: unitY,
              scale: scaleLinear({
                domain: [
                  Math.min(currentDomain[0], newDomain[0]),
                  Math.max(currentDomain[1], newDomain[1]),
                ],
                range,
                nice: true,
              }),
              metadata: {
                color: blendColors(currentScaleForUnit.metadata.color, colorY),
              },
            },
            ...scaleWithUnitAcc.slice(currentScaleForUnitIndex + 1),
          ];
        }
        return [
          ...scaleWithUnitAcc,
          {
            unit: unitY,
            scale: scaleLinear({
              domain: newDomain,
              range,
              nice: true,
            }),
            metadata: {
              color: colorY,
            },
          },
        ];
      },
      [],
    );
  }
  return scatterplotPointsWithMetadataList.map(
    ({ scatterplotPoints, metadata: { colorY, unitY, timeseriesIdY } }) => {
      const values = scatterplotPoints.map(
        (scatterplotPoint) => scatterplotPoint.y,
      );
      return {
        timeseriesId: timeseriesIdY,
        scale: scaleLinear({
          domain: [Math.min(...values), Math.max(...values)],
          range,
          nice: true,
        }),
        metadata: {
          color: colorY,
          unit: unitY,
        },
      };
    },
  );
}
