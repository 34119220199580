import { Asset } from "@cognite/sdk";
import { useRouteLoaderData } from "react-router-dom";
import { App } from "antd";
import useSWR from "swr";
import { useState } from "react";
import { useTranslations } from "@properate/translations";
import { fetchBuildingsAreal } from "@/eepApi";
import { parseError } from "@/pages/common/utils";
import { DEFAULT_MESSAGE_DURATION } from "@/utils/helpers";

async function getBuildingsAreal(externalIds?: (string | undefined)[]) {
  return await fetchBuildingsAreal(externalIds);
}

const useGetBuildingsAreal = () => {
  const t = useTranslations();
  const rootAssets = useRouteLoaderData("root") as Asset[] | null;
  const [hasError, setHasError] = useState(false);
  const { message } = App.useApp();
  const externalIdsList = rootAssets?.map((asset) => asset.externalId);
  const { data, error, isLoading, mutate } = useSWR(
    externalIdsList,
    getBuildingsAreal,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (retryCount >= 1) {
          setHasError(true);
          return;
        }
        setTimeout(() => revalidate({ retryCount }), 1000);
      },
      onError: (error) => {
        console.error(error);
        setHasError(true);
      },
    },
  );
  if (hasError) {
    message.open({
      type: "error",
      content: t("common.cant-get-use-area", {
        error: parseError(error),
      }),
      duration: DEFAULT_MESSAGE_DURATION,
    });
    setHasError(false);
    console.error(error);
    return { data: {}, loading: false, mutate: null };
  }
  return { data: data || {}, loading: isLoading, mutate };
};
export default useGetBuildingsAreal;
