import styled from "styled-components";

export const AlarmInputHint = styled.div`
  color: ${({ theme }) => theme.neutral5};
  font-size: 0.6rem;
`;

export const AlarmSubtitle = styled.h3`
  margin-top: 4px;
`;
