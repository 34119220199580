import { BuildingTenant } from "@properate/common";
import dayjs from "@properate/dayjs";

type Arg = Pick<BuildingTenant, "tenancyEnd">;

export function isExpired(tenant: Arg) {
  return dayjs(tenant.tenancyEnd).isBefore(dayjs());
}

export function isAboutToExpire(tenant: Arg) {
  return (
    !isExpired(tenant) &&
    dayjs(tenant.tenancyEnd).isSameOrBefore(dayjs().add(3, "month"))
  );
}

export function isActive(tenant: Arg) {
  return !isExpired(tenant);
}
