import { useTranslations } from "@properate/translations";
import { NoteLevel } from "./types";

export function NoteLevelText({ level }: { level: NoteLevel }) {
  const t = useTranslations();

  switch (level) {
    case NoteLevel.INFO:
      return t("notes.level.info");

    case NoteLevel.WARNING:
      return t("notes.level.warning");

    case NoteLevel.ERROR:
      return t("notes.level.error");

    case NoteLevel.UNKNOWN:
    default:
      return t("notes.level.unknown");
  }
}
