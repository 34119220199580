import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { BuildingTenantWithId } from "@properate/common";
import {
  Badge,
  Button,
  Space,
  Tooltip,
  TableColumnsType,
  Popconfirm,
} from "antd";
import {
  sortDate,
  sortNumericString,
  sortString,
  ClipboardButton,
} from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { LineClamp } from "@/components/LineClamp";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useRemoveBuildingTenant } from "../hooks/useRemoveBuildingTenant";
import { BuildingTenantsTableDateColumn } from "./BuildingTenantsTableDateColumn";
import { isAboutToExpire, isExpired } from "./utils/expiration";
import { CellWithAction } from "./elements";

type Props = {
  buildingId: number;
  search: string;
  hideActions?: boolean;
  hideNote?: boolean;
  tenants: BuildingTenantWithId[];
  pageSize?: number;
  onRowClick?: (tenant: BuildingTenantWithId) => void;
  className?: string;
  clipCells?: boolean;
  scrollY?: number;
  size?: "small" | "middle" | "large";
};

export function BuildingTenantsTable({
  buildingId,
  search,
  tenants,
  hideActions = false,
  hideNote = false,
  pageSize = 10,
  clipCells = false,
  onRowClick,
  className,
  scrollY,
  size = "middle",
}: Props) {
  const t = useTranslations();

  const removeTenant = useRemoveBuildingTenant();

  const columns: TableColumnsType<BuildingTenantWithId> = [
    {
      title: t("settings.tenants.table.organization-name"),
      key: "businessName",
      defaultSortOrder: "ascend",
      sorter: (
        { businessName: businessNameOne },
        { businessName: businessNameTwo },
      ) => sortString(businessNameOne, businessNameTwo),
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      render: (_, { businessName, note }) =>
        businessName && (
          <CellWithAction>
            <LineClamp title={businessName}>
              <SearchHighlighter search={search} text={businessName} />
            </LineClamp>
            {hideNote && note && (
              <Tooltip title={note}>
                <InfoCircleOutlined style={{ margin: "0 8px" }} />
              </Tooltip>
            )}
          </CellWithAction>
        ),
    },
    {
      title: t("settings.tenants.table.contact-name"),
      key: "contactName",
      sorter: (
        { contactName: contactNameOne },
        { contactName: contactNameTwo },
      ) => sortString(contactNameOne, contactNameTwo),
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      render: (_, { contactName }) =>
        contactName && (
          <CellWithAction>
            <LineClamp title={contactName}>
              <SearchHighlighter search={search} text={contactName} />
            </LineClamp>
          </CellWithAction>
        ),
    },
    {
      title: t("settings.tenants.table.email"),
      key: "email",
      sorter: ({ email: emailOne }, { email: emailTwo }) =>
        sortString(emailOne, emailTwo),
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      render: (_, { email }) =>
        email && (
          <CellWithAction>
            <LineClamp title={email}>
              <SearchHighlighter search={search} text={email} />
            </LineClamp>
            <ClipboardButton text={email} />
          </CellWithAction>
        ),
    },
    {
      title: t("settings.tenants.table.phone"),
      key: "phone",
      sorter: ({ phone: phoneOne }, { phone: phoneTwo }) =>
        sortString(phoneOne, phoneTwo),
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      render: (_, { phone }) =>
        phone && (
          <CellWithAction>
            <LineClamp title={phone}>
              <SearchHighlighter search={search} text={phone} />
            </LineClamp>
            <ClipboardButton text={phone} />
          </CellWithAction>
        ),
    },
    {
      title: t("settings.tenants.table.tenancy-start"),
      key: "tenancyStart",
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      sorter: (
        { tenancyStart: tenancyStartOne },
        { tenancyStart: tenancyStartTwo },
      ) => sortDate(tenancyStartOne, tenancyStartTwo),
      render: (_, { tenancyStart }) => (
        <BuildingTenantsTableDateColumn date={tenancyStart} search={search} />
      ),
    },
    {
      title: t("settings.tenants.table.tenancy-end"),
      key: "tenancyEnd",
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      sorter: ({ tenancyEnd: tenancyEndOne }, { tenancyEnd: tenancyEndTwo }) =>
        sortDate(tenancyEndOne, tenancyEndTwo),
      render: (_, buildingTenantWithId) => (
        <Space>
          <BuildingTenantsTableDateColumn
            date={buildingTenantWithId.tenancyEnd}
            search={search}
          />
          {isExpired(buildingTenantWithId) && <Badge status="error" />}
          {isAboutToExpire(buildingTenantWithId) && <Badge status="warning" />}
        </Space>
      ),
    },
    {
      title: t("settings.tenants.table.area"),
      key: "area",
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      sorter: ({ area: areaOne }, { area: areaTwo }) =>
        sortNumericString(areaOne, areaTwo),
      render: (_, { area }) => (
        <LineClamp title={area}>
          <SearchHighlighter search={search} text={area ? area + " m²" : ""} />
        </LineClamp>
      ),
    },
  ];

  if (!hideNote) {
    columns.push({
      title: t("settings.tenants.table.notes"),
      key: "note",
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      sorter: ({ note: noteOne }, { note: noteTwo }) =>
        sortString(noteOne, noteTwo),
      render: (_, { note }) =>
        note && (
          <LineClamp title={note}>
            <SearchHighlighter search={search} text={note} />
          </LineClamp>
        ),
    });
  }

  if (!hideActions) {
    columns.push({
      key: "action",
      render: (_, buildingTenant) => (
        <Popconfirm
          title={t("settings.tenants.table.are-you-sure-to-delete")}
          okText={t("settings.tenants.table.yes")}
          onConfirm={(event) => {
            event?.stopPropagation();
            removeTenant.trigger({
              buildingId: buildingId,
              tenant: buildingTenant,
            });
          }}
          onCancel={(event) => event?.stopPropagation()}
        >
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={(event) => event.stopPropagation()}
          />
        </Popconfirm>
      ),
    });
  }

  return (
    <TableWithoutDefaultSort
      columns={columns}
      dataSource={tenants}
      rowKey="id"
      onRow={(buildingTenant) => ({
        onClick() {
          onRowClick?.(buildingTenant);
        },
      })}
      pagination={
        tenants.length > pageSize
          ? {
              pageSize: pageSize,
            }
          : false
      }
      rowIsHoverable={onRowClick !== undefined}
      className={className}
      size={size}
      scroll={{ y: scrollY }}
    />
  );
}
