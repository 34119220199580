import { sortNumber } from "@properate/ui/src/lib/sorters";

export const getFloorType = (
  externalId: string,
): "floor" | "sub_floor" | null => {
  // ex. AH_1366_Fornebuveien_50+FV50=200.K01
  if (externalId.includes("=200.K")) {
    return "sub_floor";
  }

  // ex. AH_1366_Fornebuveien_50+FV50=200.001
  if (externalId.includes("=200.0")) {
    return "floor";
  }

  return null;
};

export const getFloorNumber = (
  externalId: string,
  mezzanineDelta = 0.5,
): number | null => {
  const parse = (id: string) => {
    // Remove "K" (Kjeller) from the floor number of underground floors
    // Remove "M" (Mezzanine) from the floor number of mezzanine floors
    const floorStr = id.replace("K", "").replace("M", "");
    const floor = parseInt(floorStr, 10);

    if (Number.isNaN(floor)) {
      return null;
    }

    const delta = id.endsWith("M") ? mezzanineDelta : 0;

    return floor + delta;
  };

  if (externalId.includes("=200.")) {
    const parts = externalId.split("=200.");
    const floorId = parts.at(-1);

    if (floorId) {
      return parse(floorId);
    }
  }

  return null;
};

export function sortFloors(a: string | undefined, b: string | undefined) {
  if (!a || !b) {
    return 0;
  }

  const aType = getFloorType(a);
  const bType = getFloorType(b);

  if (aType === bType) {
    // Floors are sorted in ascending order, sub-floors in descending order
    const direction = aType === "floor" ? 1 : -1;

    const aNumber = getFloorNumber(a, 0.5 * direction);
    const bNumber = getFloorNumber(b, 0.5 * direction);

    return sortNumber(aNumber, bNumber) * direction;
  }

  return aType === "floor" ? 1 : -1;
}
