import { useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  HistoryOutlined,
  LineChartOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { TimeSpan } from "@properate/common";
import { Timeseries } from "@cognite/sdk";
import { useTranslations } from "@properate/translations";
import GraphModal from "@/pages/common/SchemaGraph";
import { runCalculationFlow } from "@/eepApi";
import { AuditLogModal } from "@/components/AuditLog";
import { formatExtendedTimeseriesDescription } from "@/utils/helpers";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { CalculationFlowNotesColumn } from "@/features/calculationFlow";
import { CalculationFlow } from "../types";
import { getOutputExternalIds } from "./utils";
import RunModal from "./RunModal";
import { DeleteModal } from "./DeleteModal";
import type { MenuProps } from "antd";

type MenuItem = Required<MenuProps>["items"][number];

interface Props {
  calculationFlow: CalculationFlow;
  afterDelete: (calculationFlow: CalculationFlow) => void;
  timeseriesCache: Timeseries[];
}

export const ActionsMenu = ({
  calculationFlow,
  afterDelete,
  timeseriesCache,
}: Props) => {
  const navigate = useNavigate();
  const t = useTranslations();
  const building = useCurrentBuilding();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [runModalOpen, setRunModalOpen] = useState(false);
  const [plotOpen, setPlotOpen] = useState(false);
  const [plotTimeseries, setPlotTimeseries] = useState<Timeseries>();
  const [auditLogTimeseries, setAuditLogTimeseries] = useState<Timeseries>();
  const [auditLogOpen, setAuditLogOpen] = useState(false);

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "delete") setDeleteModalOpen(true);
    else if (e.key === "edit" && calculationFlow.id !== null)
      navigate(encodeURIComponent(calculationFlow.id));
    else if (e.key === "run") setRunModalOpen(true);
    else if (e.key.startsWith("view-")) {
      setPlotOpen(true);
      const targetExternalId = e.key.substring("view-".length);
      setPlotTimeseries(
        timeseriesCache.filter((ts) => ts.externalId === targetExternalId)[0],
      );
    } else if (e.key.startsWith("auditLog-")) {
      setAuditLogOpen(true);
      const targetExternalId = e.key.substring("auditLog-".length);
      setAuditLogTimeseries(
        timeseriesCache.filter((ts) => ts.externalId === targetExternalId)[0],
      );
    }
  };

  const statusExternalIds = calculationFlow.health_ext_id
    ? [calculationFlow.health_ext_id]
    : [];
  const statusTimeseriesList = timeseriesCache.filter((ts) =>
    statusExternalIds.includes(ts.externalId!),
  );
  const outputExternalIds = getOutputExternalIds(calculationFlow);
  const outputTimeseriesList = timeseriesCache.filter((ts) =>
    outputExternalIds.includes(ts.externalId!),
  );

  const subItems = (keyPrefix: string) => {
    const items: MenuItem[] = [
      {
        type: "group",
        label: t("calculation-flow.table.actions-menu.outputs"),
        children: outputTimeseriesList.map((outputTimeseries) => {
          return {
            label: formatExtendedTimeseriesDescription(outputTimeseries),
            key: `${keyPrefix}${outputTimeseries.externalId!}`,
          };
        }),
      },
      {
        type: "group",
        label: t("calculation-flow.table.actions-menu.status"),
        children: statusTimeseriesList.map((outputTimeseries) => {
          return {
            label: formatExtendedTimeseriesDescription(outputTimeseries),
            key: `${keyPrefix}${outputTimeseries.externalId!}`,
          };
        }),
      },
    ];
    return items;
  };

  const items: MenuItem[] = [
    {
      key: "run",
      icon: <PlayCircleOutlined style={{ fontSize: "120%" }} />,
    },
    {
      key: "edit",
      icon: <EditOutlined style={{ fontSize: "120%" }} />,
    },
    {
      label: t("calculation-flow.table.actions-menu.history"),
      key: "view",
      icon: <LineChartOutlined style={{ fontSize: "120%" }} />,
      children: subItems("view-"),
    },
    {
      label: t("calculation-flow.table.actions-menu.log"),
      key: "auditLog",
      icon: <HistoryOutlined style={{ fontSize: "120%" }} />,
      children: subItems("auditLog-"),
    },
    {
      key: "notes",
      icon: (
        <CalculationFlowNotesColumn
          ids={[...statusTimeseriesList, ...outputTimeseriesList].map(
            (ts) => ts.assetId!,
          )}
        />
      ),
    },
    {
      label: t("calculation-flow.table.actions-menu.delete"),
      key: "delete",
      icon: <DeleteOutlined style={{ fontSize: "120%" }} />,
    },
  ];

  return (
    <>
      <Menu
        onClick={onClick}
        mode="horizontal"
        items={items}
        style={{ width: 250, paddingInline: 0 }}
        inlineIndent={1}
        overflowedIndicator={<EllipsisOutlined style={{ fontSize: "120%" }} />}
      />

      {deleteModalOpen && (
        <DeleteModal
          calculationFlow={calculationFlow}
          onClose={() => setDeleteModalOpen(false)}
          afterDelete={function (calculationFlow: CalculationFlow): void {
            setDeleteModalOpen(false);
            afterDelete(calculationFlow);
          }}
        />
      )}
      {runModalOpen && (
        <RunModal
          open={runModalOpen}
          showPeriodSelector={calculationFlow.type === "virtualSensor"}
          onRun={async (timeSpan: TimeSpan, purgeDatapoints: boolean) => {
            if (calculationFlow.id !== null)
              await runCalculationFlow(
                calculationFlow.id,
                timeSpan,
                purgeDatapoints,
              );
            setRunModalOpen(false);
          }}
          onClose={() => {
            setRunModalOpen(false);
          }}
        />
      )}
      {plotOpen && plotTimeseries && (
        <GraphModal
          timeseriesInfo={plotTimeseries}
          setTimeseriesInfo={setPlotTimeseries}
          hide={() => setPlotOpen(false)}
          expanded
          editable
          showDocuments={false}
          buildingId={building.id}
        />
      )}
      {auditLogOpen && auditLogTimeseries && (
        <AuditLogModal
          name={formatExtendedTimeseriesDescription(auditLogTimeseries)}
          externalId={auditLogTimeseries.externalId!}
          onHide={() => setAuditLogOpen(false)}
        />
      )}
    </>
  );
};
