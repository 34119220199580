import { App, Button, Modal } from "antd";
import { useTranslations } from "@properate/translations";
import { useState } from "react";
import { CalculationFlow } from "@/pages/calculationFlow/types";

interface Props {
  flow: CalculationFlow;
  onClose: () => void;
  onDelete: (flow: CalculationFlow) => Promise<void>;
}

export const DeleteModal = ({ flow, onClose, onDelete }: Props) => {
  const { notification } = App.useApp();
  const [deleting, setDeleting] = useState(false);
  const t = useTranslations();

  const handleDeleteOk = async () => {
    setDeleting(true);
    try {
      await onDelete(flow);
      setDeleting(false);
      notification.success({
        message: t("energy-flexing.measures.deleted"),
      });
    } catch {
      notification.error({
        message: t("energy-flexing.error-while-deleting-measure"),
      });
    }
    onDelete(flow);
  };

  return (
    <Modal
      title={t("calculation-flow.table.are-you-sure")}
      open
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t("calculation-flow.table.cancel")}
        </Button>,
        <Button
          key="delete"
          type="primary"
          loading={deleting}
          onClick={handleDeleteOk}
        >
          {t("energy-flexing.measures.delete")}
        </Button>,
      ]}
    >
      {t.rich("energy-flexing.measures.delete-description", {
        b: (text) => <b>{text}</b>,
        name: flow.name,
      })}
    </Modal>
  );
};
