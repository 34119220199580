import { useSidebarData } from "@properate/ui";
import { EventFilterRequest } from "@cognite/sdk";
import { CogniteEventType } from "@properate/common";
import { NotesSidebarValues } from "../types";
import { useAssetIdSet } from "./useAssetIdSet";
import { useNotesDataSetIds } from "./useNotesDataSetIds";

export function useCogniteEventFilter() {
  const { endTime, startTime } = useSidebarData<NotesSidebarValues>();
  const dataSetIds = useNotesDataSetIds();
  const { assetIdSet, error: timeseriesError } = useAssetIdSet();

  if (!assetIdSet || !dataSetIds) {
    if (timeseriesError) {
      return { error: timeseriesError, filter: null };
    }
    return { error: null, filter: null };
  }

  const filter: EventFilterRequest &
    Required<Pick<EventFilterRequest, "filter">> = {
    filter: {
      dataSetIds,
      assetIds: [...assetIdSet],
      type: CogniteEventType.WEB_NOTE,
    },
    sort: { startTime: "asc" },
  };
  if (startTime || endTime) {
    filter.filter.activeAtTime = {};
    if (startTime) {
      filter.filter.activeAtTime.min = startTime;
    }
    if (endTime) {
      filter.filter.activeAtTime.max = endTime;
    }
  }
  return { error: null, filter };
}
