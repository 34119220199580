import { Node } from "reactflow";
import { useTranslations } from "@properate/translations";
import { getNodeId } from "./helpers/Utils";
import {
  Body,
  SmallNode,
  NodeOutput,
  NodeInput,
  Header,
  NodeInputLabel,
  NodeOutputLabel,
} from "./helpers/NodeComponents";

interface AbsoluteValueNodeProps {
  operationId: string;
}

export const getEmptyAbsoluteValueNode = (): Node<AbsoluteValueNodeProps> => {
  return {
    id: getNodeId("absoluteValue"),
    type: "absoluteValue",
    data: {
      operationId: "abs",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function AbsoluteValueNode() {
  const t = useTranslations();

  return (
    <SmallNode>
      <Header>{t("calculation-flow.node-types.absolute-value")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>

        <NodeOutput>
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <NodeOutputLabel>|A|</NodeOutputLabel>
        </NodeOutput>
      </Body>
    </SmallNode>
  );
}

export default AbsoluteValueNode;
