import { AlarmWithId } from "@properate/common";

export function getFlattenedSubscriptions(alarm: AlarmWithId) {
  if (!alarm.subscriptions) {
    return [];
  }

  return alarm.subscriptions.flatMap(({ emails = [], phones = [] }) => [
    ...emails,
    ...phones,
  ]);
}
