import { AlarmDetailsGridLayout } from "@/pages/alarms/details/components/layout";
import { AlarmSummary } from "@/pages/alarms/details/AlarmDeviationsPage/AlarmSummary";
import { DeviationsTableView } from "@/pages/alarms/details/AlarmDeviationsPage/DeviationsTableView";

export function AlarmDeviationsPage() {
  return (
    <AlarmDetailsGridLayout
      firstColumn={<AlarmSummary />}
      secondColumn={<DeviationsTableView />}
    />
  );
}
