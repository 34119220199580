import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Switch, Tooltip } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { ShareButtonWithDialog } from "@/components/ShareButtonWithDialog/ShareButtonWithDialog";
import { Analysis, SettingsTimeseries } from "../../types";
import {
  isScatterplotAnalysis,
  isScatterplotAnalysisWithoutSettingsTimeseriesX,
} from "../../utils/scatterplot";
import { TimeseriesDetailsRow, TimeseriesSwitchContainer } from "./elements";
import { AnalysisDetailsFormTitle } from "./AnalysisDetailsFormTitle";

// Checks whether there are at least 2 timeseries settings with the same unit
function canBeMerged(settingsTimeseriesList: SettingsTimeseries[]) {
  const units = settingsTimeseriesList.map(({ unitSelected }) => unitSelected);
  return units.length !== new Set(units).size;
}
interface Props {
  analysis: Analysis;
  onChangeAnalysis: (details: Partial<Analysis>) => unknown;
  onClickAddTimeseriesY: () => unknown;
  onClickChangeTimeseriesX: () => unknown;
}

export function AnalysisDetails({
  analysis,
  onChangeAnalysis,
  onClickAddTimeseriesY,
  onClickChangeTimeseriesX,
}: Props) {
  const t = useTranslations();

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const location = useLocation();
  const shareLink = `${window.location.origin}${location.pathname}`;

  function handleChangeTitle(title: string) {
    onChangeAnalysis({ title });
    setIsEditingTitle(false);
  }

  return (
    <>
      <TimeseriesDetailsRow>
        <div style={{ flexGrow: 1 }}>
          {isEditingTitle ? (
            <AnalysisDetailsFormTitle
              title={analysis.title}
              onChangeTitle={handleChangeTitle}
            />
          ) : (
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => setIsEditingTitle(true)}
              data-testid="edit-analysis-title"
            >
              {analysis.title || t("analysis.no-name")}{" "}
              <EditOutlined aria-hidden />
            </Button>
          )}
        </div>
        {canBeMerged(analysis.settingsTimeseriesList) && (
          <Tooltip title={t("analysis.details.group-description")}>
            <TimeseriesSwitchContainer>
              <label htmlFor="analysis-merge-units">
                {t("analysis.details.group")}
              </label>
              <Switch
                id="analysis-merge-units"
                checked={analysis.mergeUnits}
                onChange={(val) => onChangeAnalysis({ mergeUnits: val })}
              />
            </TimeseriesSwitchContainer>
          </Tooltip>
        )}
        <ShareButtonWithDialog
          title={t("analysis.share-analysis")}
          shareLink={shareLink}
        >
          {t("analysis.share-text.analysis")}
        </ShareButtonWithDialog>
        {isScatterplotAnalysis(analysis) && (
          <Button
            type="primary"
            icon={
              analysis.settingsTimeseriesX ? <EditOutlined /> : <PlusOutlined />
            }
            onClick={onClickChangeTimeseriesX}
          >
            {t("analysis.details.timeseries-x-axis")}
          </Button>
        )}
        {!isScatterplotAnalysisWithoutSettingsTimeseriesX(analysis) && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={onClickAddTimeseriesY}
          >
            {isScatterplotAnalysis(analysis)
              ? t("analysis.details.timeseries-y-axis")
              : t("analysis.details.timeseries")}
          </Button>
        )}
      </TimeseriesDetailsRow>
    </>
  );
}
