import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatSubBuildingFromExternalId } from "@properate/common";
import { CogniteClient } from "@cognite/sdk";
import { useTranslations } from "@properate/translations";
import SchemaList from "../common/SchemaList";
import { useCogniteClient } from "../../context/CogniteClientContext";
import { getAssetsWithLabels } from "../../utils/helpers";
import { useBuildingPageTitle } from "../../hooks/usePageTitle";

export const TechnicalSchemaList = () => {
  const t = useTranslations();
  useBuildingPageTitle(t("floor-plan.technical-schema-list.title"));
  const { id }: any = useParams();
  const { client } = useCogniteClient();
  const [availableBuildings, setAvailableBuildings] = useState<string[]>();

  useEffect(() => {
    const get = async (client: CogniteClient) => {
      const assets = [
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "ventilation_unit",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "cooling_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "heating_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "sanitary_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "outdoor_heating_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "low_voltage_supply",
        ])),
      ];

      const subbuildings = assets?.reduce((prev, current) => {
        const subBuilding = formatSubBuildingFromExternalId(
          current.externalId!,
        );
        if (subBuilding !== "Common" && !prev.includes(subBuilding)) {
          return [...prev, subBuilding];
        }
        return prev;
      }, [] as string[]);
      setAvailableBuildings(subbuildings);
    };

    if (client) {
      get(client);
    }
  }, [client, id]);

  return (
    <SchemaList
      id={id}
      type="technicalSchema"
      help={{
        title: t("floor-plan.technical-schema-list.title"),
        content: t("floor-plan.technical-schema-list.help.description"),
      }}
      joyrideSteps={[
        {
          placement: "top",
          target: ".systemSchema-new",
          content: t("floor-plan.joyride-steps.system-schema-new"),
        },
        {
          placement: "top",
          target: ".ant-table-row",
          content: t("floor-plan.joyride-steps.ant-table-row"),
        },
      ]}
      title={t("floor-plan.technical-schema-list.title")}
      availableBuildings={availableBuildings}
      showProperateButton
    />
  );
};
