import { useTranslations } from "@properate/translations";
import { Button, Empty, Input } from "antd";
import {
  AlertRecipient,
  getContactFromPhoneOrEmail,
  getPhoneOrEmailFromContact,
} from "@properate/common";
import { ExportOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { CenteredSpinner } from "@properate/ui";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { getAlarmSubscribersFromAlertConfiguration } from "@/eepApi";
import { useHandleApiError } from "@/utils/api";
import { useFormContext, useFormValue } from "../FormContext/hooks";
import { FormContextActionType, FormContextItem } from "../FormContext";
import { AlarmNotificationFormFields } from "./types";
import { ButtonVariant, NotificationRow } from "./NotificationRow";

const tBase = "alarm-details.notifications-page.recipients";

function NoRecipients() {
  const t = useTranslations(tBase);
  const [recipients] = useFormValue<AlertRecipient[]>(
    AlarmNotificationFormFields.Recipients,
  );
  if (recipients?.length > 0) {
    return null;
  }
  return (
    <div className={"w-full flex flex-row justify-center items-center"}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="" />
      <p className="opacity-50">{t(`empty-message`)}</p>
    </div>
  );
}

function Recipients() {
  const [recipients, setRecipients] = useFormValue<AlertRecipient[]>(
    AlarmNotificationFormFields.Recipients,
  );

  if (!recipients || recipients.length === 0) {
    return null;
  }

  return recipients.map((recipient, index) => (
    <NotificationRow
      key={`inactive-period-${index}`}
      colOne={<span>{recipient.name}</span>}
      colTwo={<span>{getContactFromPhoneOrEmail(recipient)}</span>}
      buttonVariant={ButtonVariant.Remove}
      onClick={() => setRecipients(recipients.toSpliced(index, 1))}
    />
  ));
}

const AlertConfigurationGroups = () => {
  const navigate = useNavigate();
  const currentBuildingId = useCurrentBuildingId();
  const { alarmId } = useParams() as { alarmId: string };
  const handleAPIError = useHandleApiError();

  const {
    data: groups = [],
    isLoading,
    error,
  } = useSWR(["alarmSubscribersFromAlerts", alarmId], async () => {
    return await getAlarmSubscribersFromAlertConfiguration(alarmId);
  });

  if (error) {
    handleAPIError(error);
  }

  const onNavigateToGroup = (alertConfigurationId: string) => {
    navigate(
      `/asset/${currentBuildingId}/alertConfiguration/${alertConfigurationId}`,
    );
  };

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return groups.map((group) => (
    <div
      key={`${group.groupId}-${group.alertConfigurationId}`}
      className="flex justify-between"
    >
      <span>
        {group.groupName} ({group.alertConfigurationName})
      </span>
      <Button
        onClick={() => onNavigateToGroup(group.alertConfigurationId)}
        type="link"
        icon={<ExportOutlined />}
      />
    </div>
  ));
};

function AddRecipient() {
  const t = useTranslations(tBase);
  const [recipients, setRecipients] = useFormValue<AlertRecipient[]>(
    AlarmNotificationFormFields.Recipients,
  );
  const [recipientName, setRecipientName] = useFormValue<string>(
    AlarmNotificationFormFields.RecipientName,
  );
  const [recipientContact, setRecipientContact] = useFormValue<string>(
    AlarmNotificationFormFields.RecipientContact,
  );
  const { dispatch } = useFormContext();

  function handleAddRecipient() {
    if (!(recipientName ?? "").trim() || !(recipientContact ?? "").trim()) {
      dispatch({
        type: FormContextActionType.validateFields,
        ids: [
          AlarmNotificationFormFields.RecipientName,
          AlarmNotificationFormFields.RecipientContact,
        ],
      });
      return;
    }

    setRecipients([
      ...(recipients ?? []),
      {
        name: recipientName,
        muted: false,
        ...getPhoneOrEmailFromContact(recipientContact),
      },
    ]);
    setRecipientName("");
    setRecipientContact("");
  }

  return (
    <NotificationRow
      outerClassName="gap-2"
      innerClassName="gap-2"
      colOne={
        <FormContextItem
          labelKey={`${tBase}.aria-name`}
          id={AlarmNotificationFormFields.RecipientName}
          labelSrOnly
        >
          <Input size="small" placeholder={t(`name`)} />
        </FormContextItem>
      }
      colTwo={
        <FormContextItem
          labelKey={`${tBase}.aria-contact`}
          id={AlarmNotificationFormFields.RecipientContact}
          labelSrOnly
        >
          <Input size="small" placeholder={t(`contact`)} />
        </FormContextItem>
      }
      onClick={handleAddRecipient}
      buttonVariant={ButtonVariant.Add}
    />
  );
}

export function Recipient() {
  const t = useTranslations(tBase);
  return (
    <div className="w-full flex flex-col gap-2">
      <h2 className="m-0">{t(`header`)}</h2>
      <h3 className="m-0">{t(`directly-people`)}:</h3>
      <NoRecipients />
      <Recipients />
      <AddRecipient />
      <h3 className="m-0">{t(`from-alert-configurations`)}:</h3>
      <AlertConfigurationGroups />
    </div>
  );
}
