"use client";

import { AccordionProps } from "./types";
import { useAccordionEntryVisible, useAccordionValues } from "./hooks";
import { AccordionItem } from "./AccordionItem";

export default function AccordionEntries({
  htmlId,
}: Readonly<Pick<AccordionProps, "htmlId">>) {
  const { entries } = useAccordionValues();
  const entryVisible = useAccordionEntryVisible();

  return (
    <ul className={"divide-y divide-x-0 divide-border divide-solid"}>
      {entries.map((entry) => {
        if (!entryVisible(entry)) {
          return null;
        }
        const { uniqueId } = entry;
        return (
          <li key={`accordionEntry_${uniqueId}`}>
            <AccordionItem htmlId={`${htmlId}-${uniqueId}`} itemId={uniqueId} />
          </li>
        );
      })}
    </ul>
  );
}
