"use client";

import { Button, Dropdown, MenuProps, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslations } from "@properate/translations";
import { FilesIcon, MoreVerticalIcon, Trash2Icon } from "lucide-react";
import { usePossiblyUser } from "@properate/auth";
import { FileDropArea } from "@properate/ui";
import { TaskFile } from "../../schemas";
import { useUploadTaskFile } from "../../hooks/use-upload-task-file";
import { useRemoveTaskFile } from "../../hooks/use-delete-task-file";

type Props = {
  taskId: string;
  files: TaskFile[];
  taskAuthor: string;
  onClickFile: (fileId: string | number) => void;
};

export function Files(props: Props) {
  const t = useTranslations();
  const { trigger: uploadFile, isMutating: isUploading } = useUploadTaskFile();
  const { trigger: deleteFile } = useRemoveTaskFile();

  const files = useNewFilesOnlyAfterUpload(props.files, isUploading);
  const user = usePossiblyUser();

  function handleDeleteFile(fileRef: string) {
    deleteFile({
      where: {
        taskId: props.taskId,
        fileRef,
      },
    });
  }

  function handleUploadFile(files: File[] | FileList | null) {
    if (!files) {
      return;
    }

    uploadFile({
      where: {
        taskId: props.taskId,
      },
      data: {
        files: Array.from(files),
      },
    });
  }

  const items: MenuProps["items"] = [
    {
      key: "delete-file",
      danger: true,
      label: (
        <div className="flex items-center gap-2">
          Delete file <Trash2Icon className="w-4 h-6" />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between h-8 mb-4">
        <div className="flex items-center gap-4">
          <FilesIcon className="w-6 h-6" />
          <div className="text-[13px] tracking-wide">
            {t("task.field.files")}
          </div>
        </div>
        <div className="relative">
          <Button>{t("ui.attachFile")}</Button>
          <input
            type="file"
            accept="video/*,image/*,application/pdf"
            className="absolute inset-0 opacity-0 cursor-pointer"
            onChange={(event) => handleUploadFile(event.target.files)}
          />
        </div>
      </div>
      {files.length === 0 && !isUploading && (
        <div className="flex items-center justify-center h-24 ml-10 rounded-md bg-card">
          {t("task.ui.noFiles")}
        </div>
      )}
      <ul className="grid grid-cols-4 gap-2 ml-10 md:grid-cols-6">
        {files.map((file) => (
          <li key={file.id} className="relative">
            <div
              onClick={() => props.onClickFile(file.cogniteFileId || file.id)}
            >
              <div className="h-24 overflow-hidden rounded-md bg-card">
                {file.thumbnailUrl && (
                  <img
                    className="object-cover w-full h-full"
                    src={file.thumbnailUrl}
                    alt={file.name}
                  />
                )}
              </div>
            </div>
            {(user.email === props.taskAuthor ||
              user.email === file.author) && (
              <Dropdown
                placement="bottomRight"
                trigger={["click"]}
                menu={{
                  items,
                  onClick: ({ key }) => {
                    if (key === "delete-file") {
                      handleDeleteFile(file.ref);
                    }
                  },
                }}
              >
                <div className="absolute z-10 top-1 right-1">
                  <Button
                    size="small"
                    className="bg-background"
                    icon={<MoreVerticalIcon className="w-4 h-4 mt-[3px]" />}
                  />
                </div>
              </Dropdown>
            )}
          </li>
        ))}
        {isUploading && (
          <li>
            <div className="flex items-center justify-center h-24 rounded-md bg-card">
              <Spin />
            </div>
            <div className="mt-1 text-xs truncate">{t("ui.uploadingFile")}</div>
          </li>
        )}
      </ul>
      <FileDropArea onDrop={handleUploadFile} />
    </div>
  );
}

/**
 * This hook is used to delay the rendering of new files until the upload is
 * completed. It happens that the upload finishes after the _just uploaded_ file
 * appears in the front-end, which causes the file to be rendered twice.
 */
function useNewFilesOnlyAfterUpload(files: TaskFile[], isUploading: boolean) {
  const lastIsUploading = useRef(isUploading);
  const [delayedFiles, setDelayedFiles] = useState(files);

  useEffect(() => {
    // if we we're uploading and we receive a new file, we delay the rendering
    // of the new files until the upload is completed.
    if (lastIsUploading.current && !isUploading) {
      setDelayedFiles(files);
    } else {
      // if we're not uploading and someone else adds a file, we render it
      // immediately.
      if (/* NOSONAR */ !lastIsUploading.current) {
        setDelayedFiles(files);
      }
    }

    lastIsUploading.current = isUploading;
  }, [files, isUploading]);

  return delayedFiles;
}
