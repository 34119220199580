import dayjs from "@properate/dayjs";
import { AnalysisPresetTimeSpan } from "@/utils/types";
import type { TimeSpan } from "@properate/common";

export function fromPresetTimeSpanToTimeSpan(
  presetTimeSpan: Exclude<AnalysisPresetTimeSpan, "complete">,
): TimeSpan {
  switch (presetTimeSpan) {
    case "lastHour":
      return [dayjs().subtract(1, "hour").valueOf(), Date.now()];
    case "last3Hours":
      return [dayjs().subtract(3, "hour").valueOf(), Date.now()];
    case "last6Hours":
      return [dayjs().subtract(6, "hour").valueOf(), Date.now()];
    case "last12Hours":
      return [dayjs().subtract(12, "hour").valueOf(), Date.now()];
    case "today":
      return [dayjs().startOf("day").valueOf(), Date.now()];
    case "last24Hours":
      return [dayjs().subtract(24, "hour").valueOf(), Date.now()];
    case "last7Days":
      return [dayjs().subtract(7, "day").valueOf(), Date.now()];
    case "lastWeek":
      return [
        dayjs().subtract(1, "week").startOf("week").valueOf(),
        dayjs().subtract(1, "week").endOf("week").valueOf(),
      ];
    case "last14Days":
      return [dayjs().subtract(14, "day").valueOf(), Date.now()];
    case "last30Days":
      return [dayjs().subtract(30, "day").valueOf(), Date.now()];
    case "last90Days":
      return [dayjs().subtract(90, "day").valueOf(), Date.now()];
    case "last180Days":
      return [dayjs().subtract(180, "day").valueOf(), Date.now()];
    case "last365Days":
      return [dayjs().subtract(365, "day").valueOf(), Date.now()];
  }
}
