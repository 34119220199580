import styled from "styled-components";
import { Button, Checkbox, CheckboxProps, Modal, Row, Calendar } from "antd";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";

export const DotDivider = () => (
  // eslint-disable-next-line react/jsx-no-literals
  <span style={{ fontWeight: 700, margin: "0 8px" }}>&middot;</span>
);

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 8px 6px 0 6px;
  }

  .ant-modal-body {
    padding: 0 16px;
    font-size: 14px;
  }
`;

export const TitleButtons: typeof Button = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 0;

  .anticon {
    font-size: 20px;
  }
`;

export const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
`;

export const Day = styled.div`
  font-size: 18px;
  letter-spacing: -1.8px;
  border-radius: 100%;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 36px;
  margin-top: -2px;
  &.today {
    background: ${(props) => props.theme.primary};
  }
`;

export const MonthDayName = styled.span`
  font-size: 11px;
  text-transform: uppercase;
`;
export const NoBorderTable: typeof TableWithoutDefaultSort = styled(
  TableWithoutDefaultSort,
)`
  .ant-table {
    margin: 0 !important;
  }
  .ant-table-tbody > tr > td {
    border: none;
  }
  .ant-table-cell {
    padding: 0;
  }
  .ant-table-content > .ant-table-body > table > tbody > tr:hover > td {
    background: unset;
  }
`;

export const NoHoverTable: typeof TableWithoutDefaultSort = styled(
  TableWithoutDefaultSort,
)`
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset; //Change the existing color to \`unset\`
  }
  & .dayRow .hiddenButton {
    margin-top: 8px;
    visibility: hidden;
  }
  & .dayRow:hover .hiddenButton {
    visibility: visible;
  }
`;
export const EventLink = styled.div`
  .dayRow &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.primary};
  }
`;

export const CalenderColorBox = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 8px;
  background: ${(props) => props.color};
  display: inline-block;
`;

export const Group = styled(Checkbox.Group)`
  padding-bottom: 20px;
  width: 100%;
  display: block;
`;

export const StyledCalendar = styled(Calendar)`
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: transparent;
    color: #182334;
  }
`;

type CalendarProps = {
  timeGridHeight: number;
};
export const Container = styled.div<CalendarProps>`
  .container {
    max-width: 100% !important;
  }
  .toastui-calendar-timegrid {
    height: 100%;
  }
  .toastui-calendar-panel.toastui-calendar-time {
    height: ${({ timeGridHeight }) => timeGridHeight - 44}px !important;
  }
  .toastui-calendar-grid-cell-date
    .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator {
    background-color: ${(props) => props.theme.primary};
  }
`;

export const HighlightRow = styled(Row)`
  position: relative;

  padding-left: 5px;

  .showOnHover {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    white-space: nowrap;
  }

  &:hover {
    background-color: ${(props) => props.theme.neutral7};

    .showOnHover {
      display: flex;
    }
  }
`;

type ColorCheckboxProps = CheckboxProps & {
  color: string;
};

// I get an error on the type below in my editor, but it compiles fine and is supposed to be correct
export const ColorCheckbox = styled(Checkbox)<ColorCheckboxProps>`
  line-height: 28px;

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.color};
    border-color: ${(props) => props.color};
  }

  & .ant-checkbox-inner {
    border-color: ${(props) => props.color};
  }

  &:hover .ant-checkbox-inner,
  & .ant-checkbox:hover .ant-checkbox-inner,
  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.color};
  }

  & .ant-checkbox-checked::after {
    border-color: ${(props) => props.color};
  }
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  color: #111928;
  font-size: 11px;
`;

export const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Palette = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background: ${(props) => props.color};
  border: none;
  color: #fff;
  font-size: 12px;
`;

export const AddToPalette = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background: ${(props) => props.color};
  border: 2px dotted #fff;
  color: #fff;
  font-size: 12px;
  & .anticon {
    vertical-align: 0.1em !important;
  }
`;
