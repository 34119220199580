import { BuildingCertification } from "@properate/common";
import { useTheme } from "styled-components";
import { Badge } from "./elements";
import { getEnergyMark } from "./SelectEnergyRating";

type Props = {
  certification: BuildingCertification;
  size: number;
  title?: string;
};

export function BuildingCertificationBadge(props: Props) {
  const theme = useTheme();

  let src = "";
  let alt = "";

  if (props.certification.type === "WELL Certified™ Core") {
    src = `/building-certifications/well-core-${props.certification.level.toLowerCase()}.png`;
    alt = "WELL Certified™ Core Badge";
  }

  if (props.certification.type === "WELL Certified™") {
    src = `/building-certifications/well-${props.certification.level.toLowerCase()}.png`;
    alt = "WELL Certified™ Badge";
  }

  if (props.certification.type === "ISO14001") {
    src = `/building-certifications/iso14001.png`;
    alt = "ISO14001 Badge";
  }

  if (props.certification.type === "Miljøfyrtårn") {
    src = `/building-certifications/miljfyrtarn.png`;
    alt = "ISO14001 Badge";
  }

  if (props.certification.type === "BREEAM") {
    const fileName = `breeam-${props.certification.level}`
      .toUpperCase()
      .replace(" ", "-");
    src = `/building-certifications/${fileName}.png`;
    alt = "BREEAM Badge";
  }

  if (props.certification.type === "BREEAM In-Use") {
    const fileName = `breeam-in-use-${props.certification.level}`
      .toUpperCase()
      .replace(" ", "-");
    const themeSuffix = theme.type === "dark" ? "-WHITE" : "";
    src = `/building-certifications/${fileName}${themeSuffix}.png`;
    alt = "BREEAM In-Use Badge";
  }

  if (props.certification.type === "Energimerke") {
    return (
      <Badge size={props.size}>
        {getEnergyMark(
          {
            energyRating: props.certification.level[0] as any,
            heatingRating: Number(props.certification.level[1]) as any,
          },
          props.size,
          props.size,
        )}
      </Badge>
    );
  }

  return (
    <Badge size={props.size} title={props.title}>
      <img src={src} alt={props.title || alt} />
    </Badge>
  );
}
