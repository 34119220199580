import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { useTranslations, MessageKey } from "@properate/translations";
import {
  Column,
  LastWeekAndLast365DaysContainer,
  StartEndRange,
  GraphTitleRange,
} from "@/features/energy";
import { DATA_PERIOD, HierarchyNode } from "@/utils/helpers";
import SankeyDiagram from "@/components/SankeyDiagram";
import { GraphTitleStyled } from "../GraphTitle/elements";

type Props = {
  start: Date;
  end: Date;
  id: string;
  organization: string;
  hierarchyNode?: HierarchyNode;
};
export const EnergyFlow = ({
  start,
  end,
  id,
  organization,
  hierarchyNode,
}: Props) => {
  const t = useTranslations();

  return (
    <>
      <LastWeekAndLast365DaysContainer>
        <Column>
          <GraphTitleStyled>
            {t(
              `energy.energy-flow-periods.${DATA_PERIOD.LAST_WEEK.name}` as MessageKey,
            )}
          </GraphTitleStyled>
          <AutoSizer>
            {({ width, height }: Size) => (
              <SankeyDiagram
                id={parseInt(id)}
                width={width}
                height={height}
                start={DATA_PERIOD.LAST_WEEK.start}
                end={DATA_PERIOD.LAST_WEEK.end}
                unit={"kWh"}
                organization={organization}
                hierarchyNode={hierarchyNode}
              />
            )}
          </AutoSizer>
        </Column>
        <Column>
          <GraphTitleStyled>
            {t(
              `energy.energy-flow-periods.${DATA_PERIOD.LAST_YEAR_WEEKLY.name}` as MessageKey,
            )}
          </GraphTitleStyled>
          <AutoSizer>
            {({ width, height }: Size) => (
              <SankeyDiagram
                id={parseInt(id)}
                width={width}
                height={height}
                start={DATA_PERIOD.LAST_YEAR_WEEKLY.start}
                end={DATA_PERIOD.LAST_YEAR_WEEKLY.end}
                unit={"kWh"}
                organization={organization}
              />
            )}
          </AutoSizer>
        </Column>
      </LastWeekAndLast365DaysContainer>
      <StartEndRange>
        <GraphTitleRange startDate={start} endDate={end} />
        <AutoSizer>
          {({ width, height }: Size) => (
            <SankeyDiagram
              id={parseInt(id)}
              width={width}
              height={height}
              start={start}
              end={end}
              unit={"kWh"}
              organization={organization}
            />
          )}
        </AutoSizer>
      </StartEndRange>
    </>
  );
};
