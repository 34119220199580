import { Bar } from "@visx/shape";
import { scaleLinear } from "@visx/scale";
import { AxisLeft } from "@visx/axis";
import { Group } from "@visx/group";
import { useTheme } from "styled-components";
import { GaugeZones } from "../../../types";
import { GaugeBarValue } from "./GaugeBarValue";

interface Props {
  zones: GaugeZones;
  min: number;
  max: number;
  value: number;
  unit?: string;
  size: "small" | "medium" | "large";
}

export function GaugeBar({ zones, min, max, value, unit, size }: Props) {
  const theme = useTheme();
  const SVG_WIDTH = size === "small" ? 350 : size === "medium" ? 225 : 300;
  const LEFT = size === "small" ? 140 : size === "medium" ? 67.5 : 60;
  const SVG_HEIGHT = size === "large" ? 400 : size === "medium" ? 300 : 200;
  const BAR_WIDTH = size === "large" ? 100 : size === "medium" ? 75 : 50;

  const barHeight = SVG_HEIGHT - 20;

  const scaleY = scaleLinear({
    domain: [min, max],
    range: [barHeight, 0],
  });

  const topBarHeight = scaleY(min) - scaleY(zones.lower.threshold);
  const middleOrLowerBarHeight =
    zones.middle !== undefined
      ? scaleY(zones.lower.threshold) - scaleY(zones.middle.threshold)
      : scaleY(max) + scaleY(zones.lower.threshold);
  const lowerBarHeight =
    zones.middle !== undefined
      ? scaleY(max) + scaleY(zones.middle.threshold)
      : -1;

  return (
    <svg width={SVG_WIDTH} height={SVG_HEIGHT}>
      <Group top={10}>
        <AxisLeft
          scale={scaleY}
          left={LEFT}
          tickStroke={theme.neutral4}
          stroke={theme.neutral4}
          hideAxisLine
          tickLabelProps={{
            fill: theme.neutral4,
          }}
        />
        {topBarHeight > 0 && (
          <Bar
            x={LEFT}
            y={scaleY(zones.lower.threshold)}
            height={scaleY(min) - scaleY(zones.lower.threshold)}
            width={BAR_WIDTH}
            fill={zones.lower.color}
            stroke={theme.neutral3}
            strokeWidth="1"
          />
        )}
        {middleOrLowerBarHeight > 0 && (
          <Bar
            x={LEFT}
            y={
              zones.middle !== undefined
                ? scaleY(zones.middle.threshold)
                : scaleY(max)
            }
            height={
              zones.middle !== undefined
                ? scaleY(zones.lower.threshold) - scaleY(zones.middle.threshold)
                : scaleY(max) + scaleY(zones.lower.threshold)
            }
            width={BAR_WIDTH}
            fill={
              typeof zones.middle?.color === "string"
                ? zones.middle.color
                : zones.upper.color
            }
            stroke={theme.neutral3}
            strokeWidth="1"
          />
        )}
        {lowerBarHeight > 0 && (
          <Bar
            x={LEFT}
            y={scaleY(max)}
            height={lowerBarHeight}
            width={BAR_WIDTH}
            fill={zones.upper.color}
            stroke={theme.neutral3}
            strokeWidth="1"
          />
        )}
        <GaugeBarValue
          x1={LEFT}
          x2={LEFT + BAR_WIDTH}
          scale={scaleY}
          textX={LEFT + BAR_WIDTH + 10}
          value={value}
          unit={unit}
          size={size}
        />
      </Group>
    </svg>
  );
}
