import { useState } from "react";
import { PlusSquareOutlined } from "@ant-design/icons";
import { Card, Col } from "antd";
import { WithSnapshotId } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { Gauge } from "@/features/gauges";
import { Analysis } from "@/features/analysis";
import { HeatMap } from "@/features/heatmap";
import { DashboardStandardWidget, DashboardWidget } from "@/utils/types";
import { WidgetHeader } from "../widget-components/WidgetHeader";
import { NewWidgetModal } from "./NewWidgetModal";
import { Container } from "./elements";

interface Props {
  height: number;
  availableWidgets: DashboardStandardWidget[];
  availableGauges: WithSnapshotId<Gauge>[];
  availableAnalyses: WithSnapshotId<Analysis>[];
  availableHeatMaps: WithSnapshotId<HeatMap>[];
  onAddWidgets: (widgets: DashboardWidget[]) => unknown;
}

export function AddWidgetsWidget({
  height,
  availableWidgets,
  availableGauges,
  availableAnalyses,
  availableHeatMaps,
  onAddWidgets,
}: Props) {
  const t = useTranslations();
  const [isNewWidgetModalOpen, setIsNewWidgetModalOpen] = useState(false);
  const headerHeight = 54;

  function handleClickOK(widgets: DashboardWidget[]) {
    if (widgets.length > 0) {
      onAddWidgets(widgets);
    }
    setIsNewWidgetModalOpen(false);
  }

  return (
    <Col lg={12} xs={24}>
      <Card
        bordered={false}
        title={<WidgetHeader text={t("dashboard.widgets.add-widget")} />}
        styles={{ body: { height: height - headerHeight } }}
      >
        <Container onClick={() => setIsNewWidgetModalOpen(true)}>
          <PlusSquareOutlined style={{ fontSize: 36 }} />
        </Container>
      </Card>
      <NewWidgetModal
        open={isNewWidgetModalOpen}
        availableWidgets={availableWidgets}
        availableGauges={availableGauges}
        availableAnalyses={availableAnalyses}
        availableHeatMaps={availableHeatMaps}
        onClickOK={handleClickOK}
        onCancel={() => setIsNewWidgetModalOpen(false)}
      />
    </Col>
  );
}
