"use client";

import { ReactNode, forwardRef, useState, useEffect, useMemo } from "react";
import { type ReactQuillProps } from "react-quill";
import { cn } from "../lib/cn";
import type ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type Props = Omit<ReactQuillProps, "theme" | "modules" | "onChange"> & {
  variant?: "normal" | "simple";
  toolbarActions?: ReactNode;
  mentionables?: string[];
  disabled?: boolean;
  onChange?: (value: string) => void;
};

export const RichTextEditor = forwardRef<ReactQuill, Props>((props, ref) => {
  const {
    variant = "normal",
    className,
    disabled,
    onChange,
    ...editorProps
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ReactQuill, setReactQuill] = useState<any | null>(null); // NOSONAR

  useEffect(() => {
    let isMounted = true;

    import("./rich-text-editor-csr")
      .then((ReactQuill) => {
        if (isMounted) {
          setReactQuill(() => ReactQuill.default);
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error("Failed to load react-quill", error);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const variants = useMemo(
    () => ({
      normal: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { indent: "-1" }, { indent: "+1" }],
            ["link"],
          ],
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            async source(
              search: string,
              renderList: (matches: { id: number; value: string }[]) => void,
            ) {
              const searchTerm = search.toLowerCase();

              renderList(
                props.mentionables
                  ? props.mentionables
                      .filter((m) => m.toLowerCase().includes(searchTerm))
                      .filter((_, index) => index < 5)
                      .map((value, id) => ({ id, value }))
                  : [],
              );
            },
          },
        },
      },
      simple: {
        modules: {
          toolbar: [["bold", "italic", "underline"], ["link"], ["clean"]],
        },
      },
    }),
    [props.mentionables],
  );

  return (
    <div
      className={cn(
        `relative bg-card rounded-md ${variant} border border-solid min-h-[90px] rich-text-editor`,
        className,
        disabled && "bg-muted",
      )}
    >
      {ReactQuill !== null && (
        <ReactQuill
          ref={ref}
          theme="snow"
          modules={variants[variant].modules}
          readOnly={editorProps.readOnly || disabled}
          onChange={(value: string) => {
            onChange?.(value === "<p><br></p>" ? "" : value);
          }}
          {...editorProps}
        />
      )}
      <div
        className={cn("absolute right-0 h-10 flex items-center pr-1", {
          "bottom-0": variant === "simple",
          "top-0": variant === "normal",
        })}
      >
        {props.toolbarActions}
      </div>
    </div>
  );
});

RichTextEditor.displayName = "RichTextEditor";
