import { cn } from "@properate/ui";
import { CheckSquareIcon } from "lucide-react";
import { Task } from "../schemas";

type Props = {
  taskId: string;
  checklists: Task["checklists"];
};

export function ChecklistsProgressDisplay(props: Props) {
  if (!props.checklists) {
    return null;
  }

  const checklists = Object.values(props.checklists)
    .flatMap((checklist) => checklist.items)
    .filter((item) => {
      if (item) {
        return true;
      }

      console.warn("Task has an empty checklist item", { ...props, item });

      return false;
    });

  if (checklists.length > 0) {
    const checkedCount = checklists.reduce(
      (count, item) => count + (item.checked ? 1 : 0),
      0,
    );

    const isAllChecked = checkedCount === checklists.length;

    return (
      <div
        className={cn("flex items-center gap-2", {
          "text-green-600": isAllChecked,
        })}
      >
        <CheckSquareIcon className="w-3 h-3" />
        {checkedCount}/{checklists.length}
      </div>
    );
  }

  return null;
}
