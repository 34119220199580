import { Card } from "antd";
import { useTranslations } from "@properate/translations";
import { Analysis } from "../../types";

interface Props {
  analysis: Analysis;
}

export function ErrorBoundaryCard({ analysis }: Props) {
  const t = useTranslations();

  return (
    <Card title={analysis.title || t("analysis.no-name")}>
      {t("analysis.something-went-wrong")}
    </Card>
  );
}
