import { FormEvent } from "react";
import { FormContextActionType, FormContextFormProps } from "./types";
import { useFormContext } from "./hooks";

export function FormContextForm({ className, children }: FormContextFormProps) {
  const { dispatch } = useFormContext();

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    dispatch({
      type: FormContextActionType.submit,
    });
  }

  return (
    <form className={className} onSubmit={handleSubmit}>
      {children}
    </form>
  );
}
