import { getActiveZone } from "../../../utils";
import { GaugeZones } from "../../../types";
import { GaugeCircle } from "./elements";

interface Props {
  zones: GaugeZones;
  value: number;
}

export function GaugeLight({ zones, value }: Props) {
  const activeZone = getActiveZone(zones, value);

  return <GaugeCircle $backgroundColor={activeZone.color} />;
}
