import { useMemo, useState } from "react";
import { Button, Select, Space, TimePicker } from "antd";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import dayjs from "@properate/dayjs";
import { RangeValue } from "rc-picker/lib/interface";
import { useTranslations, MessageKey } from "@properate/translations";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { RangePicker } from "@/components/RangePicker/RangePicker";
import {
  VALIDDATESELECTION,
  ScheduleItem,
  ScheduleItemWithKey,
} from "../types.d";

const timeFormat = "HH:mm";
export const defaultScheduleItem: ScheduleItem = {
  dateSelection: "Everyday",
  timeRange: [dayjs("08:00", timeFormat), dayjs("16:00", timeFormat)],
};

type Props = {
  scheduleItems: ScheduleItem[];
  onChange: (scheduleItems: ScheduleItem[]) => void;
};

export const ScheduleTable = ({ scheduleItems, onChange }: Props) => {
  const t = useTranslations();
  const [editingKey, setEditingKey] = useState<number>();

  const scheduleItemsWithKeys = scheduleItems.map((scheduleItem, key) => {
    return { ...scheduleItem, key };
  });

  const isEditing = (scheduleItem: ScheduleItemWithKey) =>
    scheduleItem.key === editingKey;

  const handleEdit = (
    scheduleItem: Partial<ScheduleItemWithKey> & { key: React.Key },
  ) => {
    setEditingKey(scheduleItem.key);
  };

  const handleDelete = (key: React.Key) => {
    const filteredScheduleItems = scheduleItemsWithKeys.filter(
      (item) => item.key !== key,
    );
    onChange(filteredScheduleItems);
  };

  const handleAdd = () => {
    const newScheduleItems: ScheduleItemWithKey = {
      ...defaultScheduleItem,
      key: scheduleItems.length,
    };
    onChange([...scheduleItems, newScheduleItems]);
    setEditingKey(newScheduleItems.key);
  };

  const handleDateSelectionChange = (key: number, newDataSelection: string) => {
    scheduleItems[key].dateSelection = newDataSelection;
    onChange(scheduleItems);
  };

  const handleTimeRangeChange = (
    key: number,
    newTimeRange: RangeValue<dayjs.Dayjs>,
  ) => {
    if (newTimeRange) {
      scheduleItems[key].timeRange = newTimeRange;
      onChange(scheduleItems);
    }
  };

  const dateSelectOptions = useMemo(
    () =>
      VALIDDATESELECTION.map((e) => ({
        value: e,
        label: t(`calculation-flow.date-options.${e}` as MessageKey),
      })),
    [t],
  );

  const columns = [
    {
      dataIndex: "dateSelection",
      width: "auto",
      render: (_: any, scheduleItem: ScheduleItemWithKey) => {
        const editable = isEditing(scheduleItem);
        return (
          <>
            {editable ? (
              <Select
                showAction={["focus", "click"]}
                style={{ width: "100%" }}
                options={dateSelectOptions}
                value={scheduleItem.dateSelection}
                onChange={(value) => {
                  handleDateSelectionChange(scheduleItem.key, value);
                }}
              />
            ) : (
              t(
                `calculation-flow.date-options.${scheduleItem.dateSelection}` as MessageKey,
              )
            )}
          </>
        );
      },
    },
    {
      dataIndex: "timeRange",
      width: "300px",
      render: (_: any, scheduleItem: ScheduleItemWithKey) => {
        const editable = isEditing(scheduleItem);
        if (scheduleItem.dateSelection == "DateRange")
          return (
            <RangePicker
              value={[
                scheduleItem.timeRange[0].valueOf(),
                scheduleItem.timeRange[1].valueOf(),
              ]}
              onChange={(timeSpan) => {
                handleTimeRangeChange(scheduleItem.key, [
                  dayjs(timeSpan[0]),
                  dayjs(timeSpan[1]),
                ]);
              }}
              disabled={!editable}
              withTime
            />
          );
        return (
          <TimePicker.RangePicker
            value={scheduleItem.timeRange}
            format={timeFormat}
            onChange={(timeRange) => {
              handleTimeRangeChange(scheduleItem.key, timeRange);
            }}
            disabled={!editable}
          />
        );
      },
    },
    {
      dataIndex: "operation",
      width: "80px",
      render: (_: any, scheduleItem: ScheduleItemWithKey) => {
        const editable = isEditing(scheduleItem);
        return editable ? (
          <Button
            onClick={() => setEditingKey(undefined)}
            icon={<CheckOutlined />}
          />
        ) : (
          <Space>
            <Button
              onClick={() => handleEdit(scheduleItem)}
              icon={<EditOutlined />}
            />
            <Button
              onClick={() => handleDelete(scheduleItem.key)}
              icon={<DeleteOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <TableWithoutDefaultSort
        bordered
        dataSource={scheduleItemsWithKeys}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
      />
      <Button onClick={handleAdd} icon={<PlusOutlined />}>
        {t("calculation-flow.new")}
      </Button>
    </>
  );
};
