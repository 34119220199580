import { ThirdPartyAlarmVisualTimeseries } from "@properate/common";
import { useFormValue } from "../../../FormContext/hooks";
import { ThirdPartyAlarmFields } from "../types";
import VisualTimeseriesListEntry from "./VisualTimeseriesListEntry";

export default function VisualTimeseriesList() {
  const [visualTimeseries, setVisualTimeseries] = useFormValue<
    ThirdPartyAlarmVisualTimeseries[]
  >(ThirdPartyAlarmFields.VisualTimeseries);

  if (!visualTimeseries || visualTimeseries.length === 0) {
    return null;
  }

  return (
    <div className="w-full flex flex-col gap-3">
      {visualTimeseries.map((vt, index) => (
        <VisualTimeseriesListEntry
          onDelete={() =>
            setVisualTimeseries(visualTimeseries.toSpliced(index, 1))
          }
          label={vt.label}
          value={vt.value}
          key={`visualTimeseries-${index}`}
        />
      ))}
    </div>
  );
}
