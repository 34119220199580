import React from "react";
import {
  Button,
  Card,
  Form,
  FormInstance,
  Select,
  Switch,
  Typography,
} from "antd";
import { useTranslations } from "@properate/translations";
import { DeleteOutlined } from "@ant-design/icons";
import { FormValues, TIMEOUT_SELECT_OPTIONS, useGroups } from "./utils";

type Props = {
  form: FormInstance<FormValues>;
};

export function AlertRuleDetailsFallbackGroup(props: Props) {
  const t = useTranslations();
  const fallbackGroupRule = Form.useWatch("fallback_group_rule", props.form);
  const showTimeout = !fallbackGroupRule?.notify_by_priority.notify_all;
  const hasFallbackGroup =
    fallbackGroupRule &&
    (!!fallbackGroupRule.group_id ||
      Object.values(fallbackGroupRule.notify_by_priority).some((item) => item));

  const onRemove = () => {
    props.form.setFieldValue("fallback_group_rule", {});
  };

  const groups = useGroups();

  return (
    <div className="space-y-1">
      <Typography.Text strong>
        {t("alert-rule.section-fallback-title")}
      </Typography.Text>
      <Card>
        <div className="flex justify-between -mb-6 gap-2">
          <Form.Item<FormValues>
            name={["fallback_group_rule", "group_id"]}
            className="w-[450px]"
          >
            <Select
              placeholder={t("alert-rule.field-group-placeholder")}
              loading={groups.isLoading}
            >
              {groups.data?.map((group) => (
                <Select.Option key={group.id} value={group.id}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex-1" />
          <Form.Item<FormValues>
            name={["fallback_group_rule", "notify_by_priority", "notify_all"]}
          >
            <Switch
              checkedChildren={t(
                "alert-rule.field-fallback-notify-all-members-true",
              )}
              unCheckedChildren={t(
                "alert-rule.field-fallback-notify-all-members-false",
              )}
            />
          </Form.Item>
          {showTimeout && (
            <Form.Item
              name={["fallback_group_rule", "notify_by_priority", "timeout"]}
              dependencies={["fallback_group_rule", "group_id"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const hasGroupId = !!getFieldValue([
                      "fallback_group_rule",
                      "group_id",
                    ]);
                    if (hasGroupId && (value === undefined || value === null)) {
                      return Promise.reject(
                        new Error(t("alert-rule.timeout-required")),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              className="w-28"
            >
              <Select>
                {TIMEOUT_SELECT_OPTIONS.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {t(option.translationKey)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {hasFallbackGroup && (
            <Button type="text" icon={<DeleteOutlined />} onClick={onRemove} />
          )}
        </div>
      </Card>
    </div>
  );
}
