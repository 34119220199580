import { ReactNode, createContext, useContext, useMemo, useState } from "react";

export interface Help {
  title: string;
  content: ReactNode;
}

const HelpContext = createContext<{
  help: Help;
  setHelp: (help: Help) => void;
} | null>(null);

interface Props {
  children: ReactNode;
}

export function HelpContextProvider({ children }: Props) {
  const [help, setHelp] = useState<Help>({ title: "Hjelp", content: null });

  const value = useMemo(
    () => ({
      help,
      setHelp,
    }),
    [help, setHelp],
  );

  return <HelpContext.Provider value={value}>{children}</HelpContext.Provider>;
}

export function useHelp() {
  const helpContext = useContext(HelpContext);
  if (!helpContext) {
    throw new Error(
      '"useHelp" was called from a component that does not have a HelpContextProvider parent',
    );
  }
  return helpContext;
}
