import { AlarmWithId } from "@properate/common";
import { List, Popover } from "antd";
import { useTranslations } from "@properate/translations";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { PopoverContent } from "./elements";
import { getFlattenedSubscriptions } from "./utils/getFlattenedSubscriptions";

type Props = {
  alarm: AlarmWithId;
  search: string;
};

export function AlarmsTableSubscribersColumn(props: Props) {
  const subscribers = getFlattenedSubscriptions(props.alarm);
  const t = useTranslations();

  if (subscribers.length === 0) {
    return null;
  }

  const searchWords = props.search.split(" ");

  if (subscribers.length < 5) {
    return (
      <SearchHighlighter search={searchWords} text={subscribers.join(", ")} />
    );
  }

  return (
    <Popover
      placement="left"
      title={t("org-alarms.table-columns.subscribers")}
      content={
        <PopoverContent width={300}>
          <List
            dataSource={subscribers}
            renderItem={(item) => (
              <List.Item>
                <SearchHighlighter search={searchWords} text={item} />
              </List.Item>
            )}
          />
        </PopoverContent>
      }
    >
      <SearchHighlighter
        search={searchWords}
        text={`${subscribers.slice(0, 5).join(", ")} +${
          subscribers.length - 5
        }`}
      />
    </Popover>
  );
}
