import { TimeseriesSearchable } from "@properate/api/src/types";
import useSWRInfinite, { SWRInfiniteResponse } from "swr/infinite";
import { timeseriesIndex } from "@/eepApi";
import {
  CategoryOption,
  TimeseriesFilters,
  TimeseriesMainFilters,
} from "@/features/timeseries";
import { useHandleApiError } from "@/utils/api";
import { buildFilterQuery } from "../utils";

const PAGE_SIZE = 100;

type SearchTimeseriesList = Pick<
  SWRInfiniteResponse,
  "size" | "setSize" | "isLoading"
> & { searchableTimeseriesList: TimeseriesSearchable[] };

// Timeseries are indexed in the `indexAssetAndTimeseries` script
export function useSearchTimeseriesList(
  { search, ...filter }: TimeseriesFilters & TimeseriesMainFilters,
  categoryOptions: CategoryOption[],
  sort?: string,
): SearchTimeseriesList {
  const handleAPIError = useHandleApiError();
  const {
    data: searchableTimeseriesList = [],
    error,
    isLoading,
    size,
    setSize,
  } = useSWRInfinite(
    (index) => ({
      search,
      offset: index * PAGE_SIZE,
      filter,
      sort,
    }),
    async ({ search, offset, filter, sort }) => {
      const { hits: searchableTimeseriesList } = await timeseriesIndex.search(
        search,
        {
          offset,
          sort: sort ? [sort] : undefined,
          filter: buildFilterQuery(filter, categoryOptions),
          attributesToHighlight: ["*"],
          limit: PAGE_SIZE,
        },
      );
      return searchableTimeseriesList;
    },
    {
      keepPreviousData: true,
    },
  );

  if (error) {
    handleAPIError(error);
  }

  return {
    searchableTimeseriesList: searchableTimeseriesList.flat(),
    isLoading,
    size,
    setSize,
  };
}
