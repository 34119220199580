import { useState } from "react";
import { Button, Checkbox, Col, Divider, Popover, Row } from "antd";
import { FilterOutlined } from "@ant-design/icons/lib";
import styled from "styled-components";
import { useTranslations } from "@properate/translations";
import { useUser } from "@properate/auth";
import {
  COGNITE_FILE_TYPES,
  FILE_LABELS,
} from "@/pages/showFileType/fileLabels";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { MessageKey } from "@/utils/types";

type CheckboxListProps = {
  labels: string[];
  setFilter: (params: {
    labels?: string[];
    fileTypes?: string[];
    resource?: string;
  }) => void;
  fileTypes: string[];
  setVisible: (visible: boolean) => void;
};
type selectLabelsProps = {
  labels: string[];
  setFilter: (params: {
    labels?: string[];
    fileTypes?: string[];
    resource?: string;
  }) => void;
  fileTypes: string[];
};

const CheckboxBlock = styled(Checkbox)`
  display: block;
  margin-left: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;

  .ant-checkbox {
    display: inline-block;
  }
`;
const LabelCheckbox = ({
  labels,
  setFilter,
  fileTypes,
  setVisible,
}: CheckboxListProps) => {
  const t = useTranslations();
  return (
    <>
      <p>{t("files.filter-on-labels")}</p>
      <Row style={{ maxWidth: 460 }}>
        {FILE_LABELS.sort((a, b) =>
          t(`file_labels.${a}` as MessageKey).localeCompare(
            t(`file_labels.${b}` as MessageKey),
          ),
        ).map((label) => {
          return (
            <Col key={label} span={8}>
              <CheckboxBlock
                value={label}
                defaultChecked={labels.includes(label)}
                style={{ whiteSpace: "nowrap" }}
                onChange={(event) => {
                  if (event.target.checked) {
                    setFilter({ labels: [...labels, label] });
                  } else {
                    setFilter({
                      labels: labels.filter((item) => item !== label),
                    });
                  }
                }}
              >
                <span title={t(`file_labels.${label}` as MessageKey)}>
                  {t(`file_labels.${label}` as MessageKey)}
                </span>
              </CheckboxBlock>
            </Col>
          );
        })}
      </Row>
      <Divider />
      <p>{t("files.filter-on-type")}</p>
      <Row style={{ maxWidth: 460 }}>
        {COGNITE_FILE_TYPES.map((fileType) => {
          return (
            <Col key={fileType} span={8}>
              <CheckboxBlock
                value={fileType}
                defaultChecked={fileTypes.includes(fileType)}
                style={{ whiteSpace: "nowrap" }}
                onChange={(event) => {
                  if (event.target.checked) {
                    setFilter({
                      fileTypes: [...fileTypes, fileType],
                    });
                  } else {
                    setFilter({
                      fileTypes: fileTypes.filter((item) => item !== fileType),
                    });
                  }
                }}
              >
                {t(`file-types.${fileType}` as MessageKey)}
              </CheckboxBlock>
            </Col>
          );
        })}
      </Row>
      <Row justify="end">
        <Col>
          <Button
            onClick={() => {
              setVisible(false);
              setFilter({ labels: [], fileTypes: [] });
            }}
          >
            {t("files.clear")}
          </Button>
        </Col>
      </Row>
    </>
  );
};
export const SelectLabels = ({
  labels,
  setFilter,
  fileTypes,
}: selectLabelsProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const user = useUser();

  return (
    <Popover
      trigger="click"
      open={visible}
      onOpenChange={(visible) => {
        setVisible(visible);
      }}
      destroyTooltipOnHide
      content={
        <LabelCheckbox
          labels={labels}
          setFilter={setFilter}
          fileTypes={fileTypes}
          setVisible={setVisible}
        />
      }
    >
      <Button disabled={user.isViewer}>
        <FilterOutlined
          style={{
            color:
              labels.length !== 0
                ? DEFAULT_GLOBAL_THEME.color.GREEN
                : undefined,
          }}
        />
      </Button>
    </Popover>
  );
};
