"use client";

import { Dispatch, useContext } from "react";
import { AccordionValues, AccordionAction, AccordionEntry } from "./types";
import { AccordionContext, AccordionDispatchContext } from "./AccordionContext";

export function useAccordionValues() {
  return useContext(AccordionContext) as AccordionValues;
}

export function useAccordionEntryVisible(): (entry: AccordionEntry) => boolean {
  const { filters } = useAccordionValues();
  return (entry: AccordionEntry) => {
    if (!entry) {
      return false;
    }
    for (const filter of filters.values()) {
      if (filter.active && !filter.filterFunction(entry)) {
        return false;
      }
    }
    return true;
  };
}

export function useAccordionItem(itemUniqueId: number | string) {
  const { entries, options } = useAccordionValues();
  const item = entries.find(({ uniqueId }) => uniqueId === itemUniqueId);
  return { item, options };
}

export function useAccordionActions() {
  return useContext(AccordionDispatchContext) as Dispatch<AccordionAction>;
}
