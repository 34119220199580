export const maxPixelLengthInStringArray = (
  fontSize: number,
  fontName: string,
  arr: string[],
) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context!.font = `${fontSize}px ${fontName}`;
  return arr.reduce((length, str) => {
    const currentLength = context!.measureText(str).width;
    return length > currentLength ? length : currentLength;
  }, 0);
};
