import { AlarmRuleTypeName } from "@properate/common";
import { AlarmSettingsFormFields } from "../AlarmSettingsPage/types";
import { useFormValue } from "../FormContext";
import { useAvailableAlarmRuleTypes } from "./useAvailableAlarmRuleTypes";

export function useSubBuildingsForCurrentType() {
  const { availableAlarmRuleTypes, isLoading, error } =
    useAvailableAlarmRuleTypes();
  const [alarmRuleType] = useFormValue<AlarmRuleTypeName>(
    AlarmSettingsFormFields.Type,
  );

  function getSubBuildings() {
    return availableAlarmRuleTypes?.[alarmRuleType]?.subBuildings ?? [];
  }

  return {
    isLoading,
    error,
    subBuildings: getSubBuildings(),
  };
}
