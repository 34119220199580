import { browserFirestore } from "@properate/firebase";
import { doc, getDoc } from "firebase/firestore";
import useSWR from "swr";

export function useBuildingContacts(buildingId: number) {
  return useSWR(
    `/building/${buildingId}/contacts`,
    async () => {
      const specDoc = await getDoc(
        doc(browserFirestore, "buildings", String(buildingId))
      );

      const spec = specDoc.data() as
        | { contacts?: { email: string }[] }
        | undefined;

      if (!spec?.contacts) {
        return [];
      }

      return spec.contacts
        .filter((contact) => contact.email)
        .map((contact) => contact.email);
    },
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
      refreshInterval: 0,
      onError: console.error,
    }
  );
}
