import { useTheme } from "styled-components";
import { useRef } from "react";
import { CustomDndProvider } from "@properate/ui";
import { useUser } from "@properate/auth";

type DragItem = {
  id: string;
  index: number;
};

type RowProps = {
  id: string;
  onUpdateOrder: (dragId: string, targetId: string) => void;
  children: React.ReactNode;
};

export const GroupEditableRow = (props: RowProps) => {
  const { background2 } = useTheme();
  const ref = useRef<HTMLTableRowElement>(null);
  const user = useUser();

  const [, drop] = CustomDndProvider.useDrop<
    DragItem,
    void,
    { handlerId: string | symbol | null }
  >({
    accept: "member-item",
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.id;
      const targetIndex = props.id;

      if (dragIndex === targetIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset() ?? { y: 0 };
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < targetIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > targetIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      props.onUpdateOrder(dragIndex, targetIndex);
    },
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
  });

  const [{ isDragging }, drag] = CustomDndProvider.useDrag({
    type: "member-item",
    item: () => ({ id: props.id }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  drag(drop(ref));

  return (
    <tr
      style={{
        opacity: isDragging ? 0.3 : 1,
        background: isDragging ? background2 : "inherit",
      }}
      ref={user.isAdmin ? ref : undefined}
    >
      {props.children}
    </tr>
  );
};
