import useSWR from "swr";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { bisector } from "d3-array";
import { DatapointAggregate } from "@cognite/sdk";
import { useContext, useEffect, useMemo, useState } from "react";
import dayjs from "@properate/dayjs";
import {
  formatMeasurement,
  isOperationalHour,
  OperationalHoursType,
} from "@properate/common";
import { scaleLinear, scaleTime } from "@visx/scale";
import { Area, Line, LinePath } from "@visx/shape";
import { curveMonotoneX } from "@visx/curve";
import { ThemeContext } from "styled-components";
import {
  defaultStyles,
  Tooltip,
  useTooltip,
  useTooltipInPortal,
} from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { App, Modal, Space } from "antd";
import { useTranslations } from "@properate/translations";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import {
  calculateSumOfEnergyDataPoints,
  datapointsRetrieve,
  GraphPoint,
} from "@/utils/helpers";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { ACCENT3, PRIMARY } from "@/utils/ProperateColors";
import { SelectOrganization } from "@/components/SelectOrganization/SelectOrganization";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useCurrentBuildingAreal } from "@/hooks/useCurrentBuildingAreal";
import { DraggableCard } from "../draggable/DraggableCard";
import { WidgetHeader } from "../widget-components/WidgetHeader";

interface RollingGraphPoint extends GraphPoint {
  nonOperational: number;
  etGen?: number | null;
}

interface Props {
  id: number;
  energy: Record<string, number>;
  operationalHours: OperationalHoursType[];
  closedPublicHolidays: boolean;
  buildingId: number;
  width: number;
  height: number;
  organizations: string[];
  onClickRemoveButton: () => unknown;
}

const HOURS_IN_WEEK = 168;
export function RollingAverageWidget({
  id,
  energy,
  closedPublicHolidays,
  operationalHours,
  buildingId,
  width,
  height,
  organizations,
  onClickRemoveButton,
}: Props) {
  const t = useTranslations();

  const { notification } = App.useApp();
  const { client } = useCogniteClient();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const themeContext = useContext(ThemeContext);
  const [data, setData] = useState<RollingGraphPoint[]>();

  const { data: preferences } = useUserSettings();
  const organization =
    preferences?.buildings?.[id]?.rollingAverageGraph?.organization ||
    organizations[0];
  const currentBuilding = useCurrentBuilding();
  const { area: usableFloorArea } = useCurrentBuildingAreal(currentBuilding.id);
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });
  const { data: energyData } = useSWR(
    energy[organization]
      ? JSON.stringify({
          start: "14d-ago",
          granularity: "h",
          items: [energy[organization]],
        })
      : null,
    calculateSumOfEnergyDataPoints(client, notification),
  );

  const { data: ETGen } = useSWR(
    JSON.stringify({
      filter: {
        assetSubtreeIds: [{ id: buildingId }],
        name: "OE168-ETgen",
      },
    }),
    async (params) => {
      if (typeof usableFloorArea === "number") {
        const timeseries = await client.timeseries
          .list(JSON.parse(params))
          .autoPagingToArray();
        const results =
          timeseries.length > 0
            ? await datapointsRetrieve(client)({
                limit: 1000,
                items: timeseries.map((ts) => ({ id: ts.id })),
                granularity: "h",
                aggregates: ["sum"],
                start: "7d-ago",
                includeOutsidePoints: false,
              })
            : [];
        const resultData =
          results && (results[0].datapoints as DatapointAggregate[]);

        return (
          resultData &&
          resultData.map((data) => ({
            time: data.timestamp.valueOf(),
            etGen: data.sum! / usableFloorArea,
          }))
        );
      }
    },
  );

  useEffect(() => {
    const isOptional = (time: number) =>
      isOperationalHour(dayjs(time), operationalHours, closedPublicHolidays);

    if (energyData && typeof usableFloorArea === "number") {
      const results = [];
      let sum = energyData
        .slice(0, HOURS_IN_WEEK)
        .reduce((prev, current) => prev + current.value, 0);
      let nonOperational = energyData
        .slice(0, HOURS_IN_WEEK)
        .reduce(
          (prev, current) =>
            prev + (!isOptional(current.time) ? current.value : 0),
          0,
        );
      for (let i = 0; i < HOURS_IN_WEEK; i++) {
        if (energyData.length > i + HOURS_IN_WEEK) {
          sum = sum - energyData[i].value + energyData[i + HOURS_IN_WEEK].value;
          nonOperational = nonOperational
            ? nonOperational -
              (isOptional(energyData[i].time) ? energyData[i].value : 0) +
              (isOptional(energyData[i + HOURS_IN_WEEK].time)
                ? energyData[i + HOURS_IN_WEEK].value
                : 0)
            : 0;
          const time = energyData[i + HOURS_IN_WEEK].time;
          results.push({
            value: sum / usableFloorArea,
            nonOperational: nonOperational / usableFloorArea,
            time,
          });
        }
      }
      setData(results);
    }
  }, [energyData, usableFloorArea, closedPublicHolidays, operationalHours]);

  const allData = useMemo(() => {
    if (data && ETGen) {
      return data.map((d) => ({
        ...d,
        etGen: ETGen.find((g) => g.time === d.time)?.etGen || null,
      }));
    }
    return data;
  }, [data, ETGen]);

  const padding = 24;
  const headerHeight = 46;
  const graphWidth = width > 0 ? width - padding * 2 : 0;
  const graphHeight = height - padding * 2 - headerHeight;

  const yTop = 104;
  const yAxisWidth = 30;
  const xAxisHeight = 30;

  const xMax = graphWidth > 0 ? graphWidth - yAxisWidth : 0;
  const yMax = graphHeight - yTop - xAxisHeight;

  const timeScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        round: true,
        domain: [dayjs().subtract(7, "d").valueOf(), dayjs().valueOf()],
      }),
    [xMax],
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [
          0,
          Math.ceil(
            Math.max(
              ...[
                ...(allData || []).map((gv) => gv.value || 0),
                ...(allData || []).map((gv) => gv.etGen || 0),
                ...(allData || []).map((gv) => gv.nonOperational || 0),
              ],
            ),
          ),
        ],
      }),
    [yMax, allData],
  );
  const bisectDate = bisector<any, Date>((d) => new Date(d.time)).left;

  const getDataForEvent = (event: any): RollingGraphPoint | undefined => {
    const coords = localPoint(event.target.ownerSVGElement, event) || {
      x: 0,
      y: 0,
    };
    const x0 = timeScale.invert(coords.x - yAxisWidth);
    const index = bisectDate(allData!, x0, 1);
    const d0 = allData![index - 1];
    const d1 = allData![index];
    let d = d0;
    if (d1 && d1.time) {
      d =
        x0.valueOf() - d0.time.valueOf() > d1.time.valueOf() - x0.valueOf()
          ? d1
          : d0;
    }
    return d;
  };

  const handleTooltip = (event: any) => {
    const d = getDataForEvent(event);

    if (d && typeof d.value === "number") {
      showTooltip({
        tooltipData: {
          date: dayjs(d.time).format("dddd H:mm"),
          value: formatMeasurement({ value: d.value, unit: "kWh" }),
          nonOperational:
            d.nonOperational &&
            formatMeasurement({
              value: d.nonOperational,
              unit: "kWh",
            }),
          etGen: formatMeasurement({
            value: d.etGen!,
            unit: "kWh",
          }),
        },
        tooltipLeft:
          yAxisWidth + timeScale(d.time) + Math.ceil(xMax / (24 * 7) / 2 / 2),
        tooltipTop: yScale(d.value) + yTop,
      });
    } else {
      hideTooltip();
    }
  };
  const handleChangeOrganization = async (organization: string) => {
    await mutateUserSettings({
      buildings: {
        [id]: {
          rollingAverageGraph: {
            organization,
          },
        },
      },
    });
  };

  return (
    <div ref={containerRef}>
      <DraggableCard
        style={{ height }}
        bordered={false}
        styles={{
          body: { position: "relative", padding },
        }}
        title={
          <WidgetHeader
            text={`${t(
              "dashboard.widgets.energy-consumption.average-last-7-days",
            )} - ${t("energy.owner-name", {
              name: organization || "",
              index: organization === organizations[0] ? 0 : 1,
            })}`}
            onClickRemoveButton={onClickRemoveButton}
            onClickSettingsButton={() => setIsModalVisible(true)}
            isDraggable
          />
        }
      >
        {typeof usableFloorArea === "number" ? (
          <>
            <svg width={graphWidth} height={graphHeight}>
              <GridRows
                numTicks={5}
                scale={yScale}
                width={xMax}
                top={yTop}
                left={yAxisWidth}
                fill={themeContext.neutral7}
              />

              <Area
                data={allData}
                x={(d: any) => yAxisWidth + timeScale(d.time)}
                y0={(d: any) => yScale(d.value) + yTop}
                y1={yMax + yTop}
                fill={PRIMARY}
                curve={curveMonotoneX}
              />
              <Area
                data={allData}
                x={(d: any) => yAxisWidth + timeScale(d.time)}
                y0={(d: any) => yScale(d.nonOperational || null) + yTop}
                y1={yMax + yTop}
                name={t(
                  "dashboard.widgets.energy-consumption.outside-operating-hours",
                )}
                stroke={ACCENT3}
                fill={ACCENT3}
                curve={curveMonotoneX}
              />
              <g>
                {allData && (
                  <LinePath
                    data={allData}
                    defined={(d: any) => typeof d.etGen === "number"}
                    x={(d: any) => {
                      return yAxisWidth + timeScale(d.time);
                    }}
                    y={(d: any) => yScale(d.etGen || 0) + yTop}
                    stroke={themeContext.neutral5}
                    strokeWidth={2}
                    curve={curveMonotoneX}
                  />
                )}
              </g>
              <AxisLeft
                top={yTop}
                left={yAxisWidth}
                scale={yScale}
                tickStroke={themeContext.neutral4}
                stroke={themeContext.neutral4}
                numTicks={5}
                hideAxisLine
                hideTicks
                tickLabelProps={{
                  fill: themeContext.neutral4,
                }}
              />
              <AxisBottom
                top={yMax + yTop}
                left={yAxisWidth}
                scale={timeScale}
                numTicks={6}
                stroke={themeContext.neutral4}
                tickStroke={themeContext.neutral4}
                tickFormat={(date: any) => {
                  const m = dayjs(date);
                  return m.format("ddd");
                }}
                tickLabelProps={{
                  fill: themeContext.neutral4,
                }}
              />
              <rect
                x={yAxisWidth}
                y={yTop}
                width={xMax}
                height={yMax}
                fill="transparent"
                onMouseMove={(event) => handleTooltip(event)}
                onMouseLeave={() => {
                  hideTooltip();
                }}
              />
              {tooltipData && (
                <g>
                  <Line
                    from={{ x: tooltipLeft, y: yTop }}
                    to={{ x: tooltipLeft, y: yTop + yMax }}
                    stroke={themeContext.neutral5}
                    strokeWidth={2}
                    pointerEvents="none"
                    strokeDasharray="5,2"
                  />
                  <circle
                    cx={tooltipLeft}
                    cy={tooltipTop! + 1}
                    r={4}
                    fill="black"
                    fillOpacity={0.1}
                    stroke="black"
                    strokeOpacity={0.1}
                    strokeWidth={2}
                    pointerEvents="none"
                  />
                  <circle
                    cx={tooltipLeft}
                    cy={tooltipTop}
                    r={4}
                    fill={PRIMARY}
                    stroke="white"
                    strokeWidth={2}
                    pointerEvents="none"
                  />
                </g>
              )}
            </svg>
            {tooltipOpen && (
              <>
                <TooltipInPortal
                  // set this to random so it correctly updates with parent bounds
                  key={Math.random()}
                  top={tooltipTop! - 12}
                  left={tooltipLeft! + 24}
                >
                  <table cellPadding={4}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: themeContext.neutral5,
                            fontSize: "24px",
                          }}
                        >
                          —
                        </td>
                        <td>{(tooltipData as any)?.etGen}</td>
                      </tr>
                      <tr>
                        <td style={{ color: PRIMARY, fontSize: "24px" }}>•</td>
                        <td>{(tooltipData as any)?.value}</td>
                      </tr>
                      <tr>
                        <td style={{ color: ACCENT3, fontSize: "24px" }}>•</td>
                        <td>{(tooltipData as any)?.nonOperational || null}</td>
                      </tr>
                    </tbody>
                  </table>
                </TooltipInPortal>
                <Tooltip
                  top={yTop - 5}
                  left={tooltipLeft}
                  style={{
                    ...defaultStyles,
                    textAlign: "center",
                    transform: "translate(-100%,-100%)",
                    marginLeft: padding,
                  }}
                >
                  {(tooltipData as any)?.date}
                </Tooltip>
              </>
            )}
            <div
              style={{
                position: "absolute",
                top: 18,
                right: 24,
                fontSize: "13px",
                lineHeight: "13px",
                color: themeContext.neutral4,
                textAlign: "right",
              }}
            >
              <table cellPadding={4}>
                <tbody>
                  <tr>
                    <td>
                      {t("dashboard.widgets.energy-consumption.energy-use")}
                    </td>
                    <td style={{ color: PRIMARY, fontSize: "24px" }}>•</td>
                  </tr>
                  <tr>
                    <td>
                      {t(
                        "dashboard.widgets.energy-consumption.outside-operating-hours",
                      )}
                    </td>
                    <td style={{ color: ACCENT3, fontSize: "24px" }}>•</td>
                  </tr>
                  <tr>
                    <td>
                      {t("dashboard.widgets.energy-consumption.last-week")}
                    </td>
                    <td
                      style={{
                        color: themeContext.neutral5,
                        fontSize: "24px",
                      }}
                    >
                      —
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {isModalVisible && (
              <Modal
                title={t(
                  "dashboard.widgets.energy-consumption.settings-for-consumption-last-7-days",
                )}
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
              >
                <Space>
                  <label htmlFor="rollingaverage-organization">
                    {t("dashboard.widgets.energy-consumption.organization")}
                  </label>
                  <SelectOrganization
                    id="rollingaverage-organization"
                    organization={organization}
                    organizations={organizations}
                    handleChangeOrganization={handleChangeOrganization}
                  />
                </Space>
              </Modal>
            )}
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            {t("dashboard.widgets.energy-consumption.set-area-to-see-usage")}
          </div>
        )}
      </DraggableCard>
    </div>
  );
}
