"use client";

import { useTranslations } from "@properate/translations";
import { ETaskStatus } from "../schemas";

type Params = {
  status: ETaskStatus;
};

export const StatusTag = ({ status }: Params) => {
  const t = useTranslations();

  if (status === "InProgress") {
    return (
      <span className="bg-blue-500/25 text-foreground text-xs px-2 py-1 rounded">
        {t(`service-desk.ui.status.InProgress`)}
      </span>
    );
  }
  if (status === "Completed") {
    return (
      <span className="bg-green-500/25 text-foreground text-xs px-2 py-1 rounded">
        {t(`service-desk.ui.status.Completed`)}
      </span>
    );
  }
  if (status === "CompletedWithExceptions") {
    return (
      <span className="bg-red-500/25 text-foreground text-xs px-2 py-1 rounded">
        {t(`service-desk.ui.status.CompletedWithExceptions`)}
      </span>
    );
  }
  if (status === "FollowUpNeeded") {
    return (
      <span className="bg-yellow-500/25 text-foreground text-xs px-2 py-1 rounded">
        {t(`service-desk.ui.status.FollowUpNeeded`)}
      </span>
    );
  }

  return (
    <span className="bg-yellow-500/25 text-foreground text-xs px-2 py-1 rounded">
      {status}
    </span>
  );
};
