import { Button, Space, Tag, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import dayjs from "@properate/dayjs";
import { PlusOutlined } from "@ant-design/icons";
import { ThemeContext } from "styled-components";
import { useTranslations } from "@properate/translations";
import {
  Day,
  EventLink,
  MonthDayName,
  NoBorderTable,
  NoHoverTable,
} from "@/pages/properateCalendar/components/elements";
import { isHoliday } from "@/pages/properateCalendar/utils";
import {
  CalendarEvents,
  ColoredProperateCalendar,
  Holiday,
  ProperateCalendarEvent,
} from "@/utils/types";

type Props = {
  year: number;
  schedule: CalendarEvents;
  installationMap: Record<string, ColoredProperateCalendar>;
  weekSchedule: CalendarEvents;
  height: number;
  disabled?: boolean;
  setShowEditEventModal: (event: ProperateCalendarEvent | null) => void;
  setShowAddWeeklyEventModal: (date: Date) => void;
  setShowAddDayEventModal: (date: Date) => void;
  holidays: Holiday[];
};

type Weekday = {
  name: string;
  isoWeekday: number;
  events: ProperateCalendarEvent[];
};

type Exceptions = {
  date: Date;
  key: string;
  events: ProperateCalendarEvent[];
  holiday?: Holiday;
};

export const EventsPlan = ({
  year,
  schedule,
  installationMap,
  weekSchedule,
  height,
  disabled,
  setShowEditEventModal,
  setShowAddWeeklyEventModal,
  setShowAddDayEventModal,
  holidays,
}: Props) => {
  const t = useTranslations();

  const [weeklyEvents, setWeeklyEvents] = useState<Weekday[] | null>(null);
  const [exceptions, setExceptions] = useState<Exceptions[] | null>(null);
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    const run = async () => {
      const events = Object.values(weekSchedule)
        .flat()
        .reduce<
          Record<
            number,
            {
              name: string;
              events: ProperateCalendarEvent[];
              isoWeekday: number;
            }
          >
        >(
          (prev, event) => {
            const e = event as ProperateCalendarEvent;
            const day = dayjs(e.start).tz("Europe/Oslo").isoWeekday();
            prev[day].events.push(e);
            return prev;
          },
          {
            1: {
              name: t("calendar.day-names.monday"),
              events: [],
              isoWeekday: 1,
            },
            2: {
              name: t("calendar.day-names.tuesday"),
              events: [],
              isoWeekday: 2,
            },
            3: {
              name: t("calendar.day-names.wednesday"),
              events: [],
              isoWeekday: 3,
            },
            4: {
              name: t("calendar.day-names.thursday"),
              events: [],
              isoWeekday: 4,
            },
            5: {
              name: t("calendar.day-names.friday"),
              events: [],
              isoWeekday: 5,
            },
            6: {
              name: t("calendar.day-names.saturday"),
              events: [],
              isoWeekday: 6,
            },
            7: {
              name: t("calendar.day-names.sunday"),
              events: [],
              isoWeekday: 7,
            },
          },
        );

      setWeeklyEvents(
        Object.values(events).sort((a, b) => a.isoWeekday - b.isoWeekday),
      );
    };
    run();
  }, [t, weekSchedule]);

  useEffect(() => {
    const run = async () => {
      const events = Object.values(schedule).flat();

      if (events.length === 0) {
        setExceptions([]);
        return;
      }

      /**
       * This page should list all the events in a year that are not reoccurring weekly.
       * We also add today's date if it is not already in the list, this is so it is
       * easy to add a new event for today, if that was the reason the user went into the calendar.
       */
      const dateToExceptionsAndHolidays = new Map<
        number,
        { events: ProperateCalendarEvent[]; holiday?: Holiday }
      >();

      const onlyEvents = events.filter(
        (event) => !isHoliday(event),
      ) as ProperateCalendarEvent[];

      // Add all the holidays to the map
      holidays.forEach((holiday) => {
        const date = dayjs(holiday.date)
          .tz("Europe/Oslo")
          .startOf("day")
          .valueOf();
        dateToExceptionsAndHolidays.set(date, { holiday, events: [] });
      });

      // if we display the plan for this year we also add today's date
      const today = dayjs().tz("Europe/Oslo").startOf("day");

      if (
        year === today.year() &&
        !dateToExceptionsAndHolidays.has(today.valueOf())
      ) {
        dateToExceptionsAndHolidays.set(today.valueOf(), { events: [] });
      }

      onlyEvents.forEach((event) => {
        const date = dayjs(event.start)
          .tz("Europe/Oslo")
          .startOf("day")
          .valueOf();
        if (!dateToExceptionsAndHolidays.has(date)) {
          dateToExceptionsAndHolidays.set(date, { events: [event] });
        } else {
          dateToExceptionsAndHolidays.get(date)!.events.push(event);
        }
      });

      const data = [...dateToExceptionsAndHolidays.entries()]
        .sort(([keyA], [keyB]) => keyA - keyB)
        .map(([date, data]) => ({
          date: new Date(date),
          key: date + "",
          ...data,
        }));
      // we now have a list of all the dates that are holidays or have events that are not weekly
      setExceptions(data);
    };

    run();
  }, [year, schedule, installationMap, holidays]);

  const weeklyColumns = [
    {
      title: t("calendar.week-columns.day"),
      dataIndex: "name",
      key: "name",
      width: 120,
      render: (name: string) => (
        <>
          <h2>
            <MonthDayName>{name}</MonthDayName>
          </h2>
        </>
      ),
    },
    {
      title: t("calendar.week-columns.plan"),
      dataIndex: "events",
      key: "events",
      render: (events: ProperateCalendarEvent[], record: Weekday) => (
        <>
          {events.length > 0 && (
            <NoBorderTable
              rowKey={(record) => `${record.calendar_id}.${record.event_id}`}
              dataSource={events}
              columns={[
                {
                  title: "time",
                  dataIndex: "start",
                  key: "start",
                  width: 120,
                  align: "left",
                  render: (_, event: ProperateCalendarEvent) => (
                    <>
                      <div
                        style={{
                          display: "inline-block",
                          width: 12,
                          height: 12,
                          background: installationMap[event.calendar_id].color,
                        }}
                      />{" "}
                      {`${dayjs(event.start)
                        .tz("Europe/Oslo")
                        .format("HH:mm")} - ${dayjs(event.end)
                        .tz("Europe/Oslo")
                        .format("HH:mm")}`}
                    </>
                  ),
                },
                {
                  title: "event",
                  dataIndex: "event_id",
                  key: "event_id",
                  align: "left",
                  render: (event_id: string, event: ProperateCalendarEvent) => (
                    <EventLink onClick={() => setShowEditEventModal(event)}>
                      {`${installationMap[event.calendar_id].system} ${
                        installationMap[event.calendar_id].name
                      } ${installationMap[event.calendar_id].description}`}{" "}
                      <strong>
                        {installationMap[event.calendar_id].valid_values[
                          event.value
                        ] || event.value}
                      </strong>
                    </EventLink>
                  ),
                },
              ]}
              pagination={false}
              showHeader={false}
            />
          )}
          <Tooltip
            title={
              disabled
                ? t("calendar.no-building-calendars")
                : t("calendar.add-calendar")
            }
          >
            <Button
              size="small"
              className="hiddenButton"
              disabled={disabled}
              onClick={() =>
                setShowAddWeeklyEventModal(
                  dayjs()
                    .tz("Europe/Oslo")
                    .startOf("day")
                    .isoWeekday(record.isoWeekday)
                    .toDate(),
                )
              }
              icon={<PlusOutlined />}
            >
              {t("calendar.add")}
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  const exceptionColumns = [
    {
      title: t("calendar.exception-columns.date"),
      dataIndex: "date",
      key: "date",
      width: 120,
      render: (date: Date, record: Exceptions) => (
        <>
          <h2>
            <Day
              className={
                dayjs(date)
                  .tz("Europe/Oslo")
                  .startOf("day")
                  .isSame(dayjs().tz("Europe/Oslo").startOf("day"))
                  ? "today"
                  : undefined
              }
            >
              {dayjs(date).tz("Europe/Oslo").format("D")}
            </Day>{" "}
            <MonthDayName>
              {dayjs(date).tz("Europe/Oslo").format("MMM dddd")}
            </MonthDayName>
          </h2>
          {record.holiday ? (
            <Tag color={themeContext.neutral3}>{record.holiday.name}</Tag>
          ) : null}
        </>
      ),
    },
    {
      title: t("calendar.exception-columns.plan"),
      dataIndex: "events",
      key: "events",
      render: (events: ProperateCalendarEvent[], record: Exceptions) => (
        <>
          {events.length > 0 && (
            <NoBorderTable
              rowKey={(record) => `${record.calendar_id}.${record.event_id}`}
              dataSource={events}
              columns={[
                {
                  title: "time",
                  dataIndex: "start",
                  key: "start",
                  width: 120,
                  align: "left",
                  render: (start, event: ProperateCalendarEvent) => (
                    <>
                      <div
                        style={{
                          display: "inline-block",
                          width: 12,
                          height: 12,
                          background: installationMap[event.calendar_id].color,
                        }}
                      />{" "}
                      {`${dayjs(event.start)
                        .tz("Europe/Oslo")
                        .format("HH:mm")} - ${dayjs(event.end)
                        .tz("Europe/Oslo")
                        .format("HH:mm")}`}
                    </>
                  ),
                },
                {
                  title: "event",
                  dataIndex: "event_id",
                  key: "event_id",
                  align: "left",
                  render: (event_id: string, event: ProperateCalendarEvent) => (
                    <EventLink onClick={() => setShowEditEventModal(event)}>
                      {`${installationMap[event.calendar_id].system} ${
                        installationMap[event.calendar_id].name
                      } ${installationMap[event.calendar_id].description}`}{" "}
                      <strong>
                        {installationMap[event.calendar_id].valid_values[
                          event.value
                        ] || event.value}
                      </strong>
                    </EventLink>
                  ),
                },
              ]}
              pagination={false}
              showHeader={false}
            />
          )}
          <Space>
            <Tooltip
              title={
                disabled
                  ? t("calendar.no-building-calendars")
                  : t("calendar.add-calendar")
              }
            >
              <Button
                size="small"
                className="hiddenButton"
                disabled={disabled}
                onClick={() => setShowAddDayEventModal(record.date)}
                icon={<PlusOutlined />}
              >
                {t("calendar.add")}
              </Button>
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: height }}>
      {weeklyEvents !== null && exceptions !== null && (
        <>
          <h1>{t("calendar.every-week")}</h1>
          <NoHoverTable
            rowClassName={() => "dayRow"}
            dataSource={weeklyEvents}
            columns={weeklyColumns}
            pagination={false}
            showHeader={false}
            rowKey="isoWeekday"
          />
          <h1 style={{ marginTop: 8 }}>{t("calendar.exception")}</h1>
          <NoHoverTable
            rowClassName={() => "dayRow"}
            dataSource={exceptions}
            columns={exceptionColumns}
            pagination={false}
            showHeader={false}
          />
          <div style={{ height: 20, width: "100%" }} />
        </>
      )}
    </div>
  );
};
