import { useState } from "react";
import { App, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload/interface";
import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import styled from "styled-components";
import { useTranslations, MessageKey } from "@properate/translations";

function getBase64(img: Blob, callback: Function) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file: RcFile, showError: (key: MessageKey) => void) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    showError("settings.image-upload.format-error");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    showError("settings.image-upload.size-error");
  }
  return isJpgOrPng && isLt2M;
}

interface UploadBuildingImageProps {
  buildingId: string;
  imageUrl?: string;
  onSelect: Function;
}

const StyledUpload = styled(Upload)`
  & .ant-upload-select-picture {
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    padding: 60px;
  }
`;

const UploadBuildingImage = ({
  buildingId,
  imageUrl,
  onSelect,
}: UploadBuildingImageProps) => {
  const t = useTranslations();

  const { notification, message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const [uploadImageUrl, setUploadImageUrl] = useState(imageUrl);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">{t("settings.image-upload.upload")}</div>
    </div>
  );
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
    } else if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as Blob, (imageUrl: string) => {
        setUploadImageUrl(imageUrl);
        setLoading(false);
      });
      onSelect(info.file.xhr);
    } else if (info.file.status === "error") {
      setLoading(false);
      notification.error({
        description: t("settings.image-upload.failed-to-upload-file"),
        message: t("settings.image-upload.upload-failed"),
      });
    }
  };

  const showErrorMessage = (key: MessageKey) => {
    message.error(t(key));
  };

  return (
    <StyledUpload
      name="building"
      listType="picture"
      showUploadList={false}
      customRequest={async ({ onError, onSuccess, file }: any) => {
        try {
          const ref = storageRef(
            getStorage(),
            "buildings/" + buildingId + "/" + file.name,
          );
          const uploaded = await uploadBytes(ref, file);
          const fileUrl = await getDownloadURL(uploaded.ref);
          onSuccess({}, fileUrl);
        } catch (error: any) {
          console.error(error);
          onError(error);
        }
      }}
      beforeUpload={(file) => beforeUpload(file, showErrorMessage)}
      onChange={handleChange}
    >
      {uploadImageUrl ? (
        <img src={uploadImageUrl} alt="avatar" style={{ width: "100%" }} />
      ) : (
        uploadButton
      )}
    </StyledUpload>
  );
};

export default UploadBuildingImage;
