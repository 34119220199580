import { useContext, useEffect, useMemo, useRef } from "react";
import Calendar from "@toast-ui/react-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import "./style.css";
import { ThemeContext } from "styled-components";
import { EventObject, TZDate } from "@toast-ui/calendar";
import _ from "lodash";
import dayjs from "@properate/dayjs";
import { useTranslations, MessageKey } from "@properate/translations";
import {
  CalendarEvents,
  ProperateCalendar,
  ProperateCalendarEvent,
} from "@/utils/types";
import { isHoliday } from "@/pages/properateCalendar/utils";
import { Container } from "@/pages/properateCalendar/components/elements";

const DAY_NAMES: [
  MessageKey,
  MessageKey,
  MessageKey,
  MessageKey,
  MessageKey,
  MessageKey,
  MessageKey,
] = [
  "calendar.day-names.sunday",
  "calendar.day-names.monday",
  "calendar.day-names.tuesday",
  "calendar.day-names.wednesday",
  "calendar.day-names.thursday",
  "calendar.day-names.friday",
  "calendar.day-names.saturday",
];

type Props = {
  mode?: "month" | "week";
  date: Date;
  schedule?: CalendarEvents;
  calendarMap: Record<string, ProperateCalendar & { color: string }>;
  height: number;
  disabled?: boolean;
  setShowEditEventModal: (event: ProperateCalendarEvent | null) => void;
  setShowAddEventModal: (date: Date) => void;
};

const MonthWeekCalendar = ({
  mode,
  date,
  schedule,
  calendarMap,
  height,
  disabled,
  setShowEditEventModal,
  setShowAddEventModal,
}: Props) => {
  const t = useTranslations();
  const themeContext = useContext(ThemeContext);
  const calendarRef = useRef<Calendar>(null);

  useEffect(() => {
    const updateDate = () => {
      calendarRef.current!.calendarInstance!.setDate(date);
    };

    if (calendarRef.current?.calendarInstance) {
      updateDate();
    }
  }, [calendarRef, date]);

  const events = useMemo(() => {
    return !_.isEmpty(calendarMap) && schedule
      ? Object.keys(schedule).reduce<EventObject[]>((acc, key) => {
          const calendar = schedule[key];
          const color = calendarMap[key]?.color || "#8e8e8e";
          return [
            ...acc,
            ...calendar.map((event) => {
              const isHolidayEvent = isHoliday(event);
              return {
                calendarId: key,
                backgroundColor: color,
                dragBackgroundColor: color,
                borderColor: color,
                color: themeContext.white,
                title: isHolidayEvent
                  ? `${event.name}`
                  : `${
                      calendarMap[key]?.valid_values[event.value] || event.value
                    }`,
                start: isHolidayEvent ? event.date : event.start,
                end: isHolidayEvent
                  ? dayjs(event.date).tz("europe/oslo").endOf("day").toDate()
                  : dayjs(event.end)
                      .tz("Europe/Oslo")
                      .subtract(1, "second")
                      .toDate(),
                body: isHolidayEvent
                  ? ""
                  : `${dayjs(event.start.getTime())
                      .tz("Europe/Oslo")
                      .format("HH:mm")} - ${dayjs(event.end?.getTime())
                      .tz("Europe/Oslo")
                      .format("HH:mm")} ${
                      event.schedule !== "single" ? "🔄" : ""
                    }`,
                raw: event,
              } as EventObject;
            }),
          ];
        }, [])
      : [];
  }, [schedule, calendarMap, themeContext.white]);

  const template =
    mode === "week"
      ? {
          timegridDisplayPrimaryTime: ({ time }: { time: TZDate }) => (
            <span role="columnheader">
              {dayjs(time.toDate()).tz("Europe/Oslo").format("HH:mm")}
            </span>
          ),
          time: (event: EventObject) => {
            return `<span style="font-weight: bold;background: ${event.backgroundColor}">${event.title}</span>
                <span style="font-weight:300; background: ${event.backgroundColor}">${event.body}</span>`;
          },
        }
      : {
          timegridDisplayPrimaryTime: ({ time }: { time: TZDate }) =>
            dayjs(time.getTime()).tz("Europe/Oslo").format("HH:mm"),
          monthGridHeaderExceed: (hiddenEvents: number) => {
            return (
              <span>
                {t("calendar.show-all-events", { count: hiddenEvents })}
              </span>
            );
          },
          time: (event: EventObject) => {
            return `<span style="font-weight: bold;">${event.title}</span>
                <span style="font-weight:300;">${event.body}</span>`;
          },
          // it seems the there is a bug in the typing of the template
          // date is a string here but the typing says it is a number
          // I use any to get around this
          monthMoreTitleDate({ day, date }: any) {
            return `<div class="toastui-properate-calendar-day">${Number(
              date,
            )}</div> <span class="toastui-properate-calendar-month-day-name">${t(
              DAY_NAMES[day],
            )}</span>`;
          },
        };

  return (
    <Container timeGridHeight={height}>
      {mode && (
        <Calendar
          calendars={Object.entries(calendarMap).map(([key, value]) => ({
            id: key,
            name: `${value.system} ${value.name} ${value.description}`,
            backgroundColor: value.color,
          }))}
          usageStatistics={false}
          view={mode}
          ref={calendarRef}
          isReadOnly
          month={{
            startDayOfWeek: 1,
            dayNames: DAY_NAMES.map((day) => t(day)) as [
              string,
              string,
              string,
              string,
              string,
              string,
              string,
            ],
          }}
          week={{
            showNowIndicator: true,
            taskView: false,
            eventView: ["time"],
            startDayOfWeek: 1,
            dayNames: DAY_NAMES.map((day) => t(day)) as [
              string,
              string,
              string,
              string,
              string,
              string,
              string,
            ],
          }}
          template={template}
          events={events}
          timezone={{ zones: [{ timezoneName: "Europe/Oslo" }] }}
          theme={
            {
              common: {
                today: { color: "white" },
                holiday: {
                  color: "#EE0000",
                },
              },
              week: {
                timegridOneHour: { height: "50px" },
                nowIndicatorPast: {
                  border: `1px dashed ${themeContext.primary}`,
                },
                nowIndicatorLabel: { color: themeContext.primary },
                nowIndicatorBullet: {
                  backgroundColor: themeContext.neutral5,
                },
                nowIndicatorToday: {
                  border: `1px solid ${themeContext.primary}`,
                },
                //pastTime: { color: themeContext.neutral5 },
                pastDay: { color: themeContext.neutral4 },
              },
            } as any
            // it seems to be a typing error in the toastui calendar that requires me to use any here
          }
          onClickEvent={
            disabled
              ? () => null
              : (e) => {
                  if (isHoliday(e.event.raw)) {
                    setShowAddEventModal(e.event.raw.date);
                  } else {
                    setShowEditEventModal(e.event.raw);
                  }
                }
          }
          height={`${height}px`}
        />
      )}
    </Container>
  );
};

export default MonthWeekCalendar;
