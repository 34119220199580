import { Group } from "@visx/group";
import { getGraphMargins, isScalesWithUnit } from "../../utils";
import {
  LegendDataForGraphLegend,
  ScaleWithTimeseriesId,
  ScaleWithUnit,
} from "../../types";
import { SimpleGraphAxesLegendData } from "./SimpleGraphAxesLegendData";
import { SimpleGraphXAxis } from "./SimpleGraphXAxis";
import { SimpleGraphYAxis } from "./SimpleGraphYAxis";
import type { ScaleTime } from "d3-scale";

const graphMargins = getGraphMargins("simple");

interface Props {
  scalesWithMetadata: ScaleWithTimeseriesId[] | ScaleWithUnit[];
  timeScale: ScaleTime<number, number, never>;
  graphWidth: number;
  graphHeight: number;
  tickSize: number;
  legendData?: LegendDataForGraphLegend;
}

export function SimpleGraphAxes({
  scalesWithMetadata,
  timeScale,
  graphWidth,
  graphHeight,
  tickSize,
  legendData,
}: Props) {
  return (
    <Group left={graphMargins.left} top={graphMargins.top}>
      {isScalesWithUnit(scalesWithMetadata)
        ? scalesWithMetadata.map(
            ({ unit, scale, metadata: { color } }, index) => (
              <SimpleGraphYAxis
                key={unit}
                color={color}
                unit={unit}
                scale={scale}
                graphWidth={graphWidth}
                graphHeight={graphHeight}
                index={index}
                tickSize={tickSize}
              />
            ),
          )
        : scalesWithMetadata.map(
            ({ timeseriesId, scale, metadata: { color, unit } }, index) => {
              return (
                <SimpleGraphYAxis
                  key={timeseriesId}
                  color={color}
                  unit={unit}
                  scale={scale}
                  graphWidth={graphWidth}
                  graphHeight={graphHeight}
                  index={index}
                  tickSize={tickSize}
                />
              );
            },
          )}
      {!!legendData && (
        <SimpleGraphAxesLegendData
          graphHeight={graphHeight}
          graphWidth={graphWidth}
          timeScale={timeScale}
          legendData={legendData}
        />
      )}
      <SimpleGraphXAxis
        graphWidth={graphWidth}
        graphHeight={graphHeight}
        scale={timeScale}
        tickSize={tickSize}
      />
    </Group>
  );
}
