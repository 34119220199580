import { TimeseriesExternalIdSearchable } from "@properate/api/src/types";
import { useEffect, useState } from "react";
import dayjs from "@properate/dayjs";
import { PageHeader } from "@ant-design/pro-layout";
import { SorterResult } from "antd/es/table/interface";
import { WithRequired } from "@properate/common";
import { DoubleDatapoint, DoubleDatapoints } from "@cognite/sdk";
import { App } from "antd";
import { isEqual } from "lodash";
import { useTranslations } from "@properate/translations";
import {
  changeDatapointsForManualTimeseries,
  createOrUpdateManualTimeseries,
  deleteManualTimeseries,
  getManualTimeseries,
} from "@/eepApi";
import { useHelp } from "@/context/HelpContext";
import {
  convertDatapoint,
  TimeseriesTableHeader,
  TimeseriesTable,
  ModalDataPoints,
} from "@/features/timeseries";
import { CompactContent } from "@/components/CompactContent";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { CDF_CONSTANTS } from "@/utils/cdf";
import { MeterSelectionModal } from "@/components/MeterSelectionModal/MeterSelectionModal";
import {
  NewMeter,
  MeterType,
  Meter,
} from "@/components/MeterSelectionModal/types";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { isErrorWithMessage } from "@/utils/api";
import { GraphModal } from "../common/SchemaGraph/GraphModal";
import { SortBy, useSearchTimeseriesList } from "./useSearchTimeseriesList";

function getDefaultMeter(
  type: MeterType,
  buildingExternalId: string,
): NewMeter {
  return {
    type: "meter",
    description: "",
    externalId: null,
    building: buildingExternalId,
    subBuilding: null,
    system: null,
    meterType: type,
    isAccumulated: true,
    source: type === "water" ? "water" : "electricity",
    unit: type === "water" ? "m³" : "kWh",
  };
}

export function TimeseriesPage() {
  const t = useTranslations();
  const { message } = App.useApp();
  useBuildingPageTitle(t("timeseries.title"));
  const { client } = useCogniteClient();
  const { setHelp } = useHelp();
  const [search, setSearch] = useState("");
  const [isLoadingMeterModal, setIsLoadingMeterModal] = useState(false);
  const [isLoadingModalDatatapoints, setIsLoadingModalDatatapoints] =
    useState(false);
  const [loadingEditExternalId, setIsLoadingEditExternalId] = useState<
    string | null
  >(null);
  const [loadingRemoveExternalId, setLoadingRemoveExternalId] = useState<
    string | null
  >(null);
  const [newMeterData, setNewMeterData] = useState<NewMeter | null>(null);
  const [modalDatapointsMeter, setModalDatapointsMeter] = useState<WithRequired<
    NewMeter,
    "description"
  > | null>(null);
  const [modalDatapointsDatapoints, setModalDatapointsDatapoints] = useState<
    DoubleDatapoint[] | null
  >(null);
  const [graphModalData, setGraphModalData] = useState<{
    id: number;
    unit?: string;
  } | null>(null);
  const [translatedLabelsFilter, setTranslatedLabelsFilter] = useState<
    string[]
  >([]);
  const [systemFilter, setSystemFilter] = useState<string | null>(null);
  const [subBuildingFilter, setSubBuildingFilter] = useState<string | null>(
    null,
  );
  const [sortBy, setSortBy] = useState<SortBy | null>({
    key: "externalId",
    order: "asc",
  });
  const currentBuilding = useCurrentBuilding();

  const {
    searchableTimeseriesList,
    facetDistribution,
    isLoading,
    size,
    setSize,
    mutate,
  } = useSearchTimeseriesList(
    search,
    {
      buildingId: currentBuilding.id,
      subBuilding: subBuildingFilter,
      system: systemFilter,
      translatedLabels: translatedLabelsFilter,
    },
    sortBy,
  );

  async function getDatapoints(externalId: string, unit: string) {
    const [{ datapoints }] = (await client.datapoints.retrieve({
      items: [{ externalId }],
      end: dayjs().add(1000, "year").endOf("year").valueOf(),
      limit: CDF_CONSTANTS.datapoints.limits.query.raw,
    })) as DoubleDatapoints[];
    return datapoints.map((datapoint) => convertDatapoint(datapoint, unit));
  }

  async function handleOKNewMeter(meter: NewMeter) {
    try {
      setIsLoadingMeterModal(true);
      const createdMeter = await createOrUpdateManualTimeseries(meter);
      setNewMeterData(null);
      setModalDatapointsMeter(createdMeter);
      setModalDatapointsDatapoints(
        await getDatapoints(
          createdMeter.externalId.externalId,
          createdMeter.unit,
        ),
      );
      mutate();
    } catch (error) {
      const errorMessage = isErrorWithMessage(error)
        ? error.message
        : String(error);
      message.error({
        content: t("timeseries.error-saving-timeseries", { errorMessage }),
        duration: 7,
      });
    } finally {
      setIsLoadingMeterModal(false);
    }
  }

  async function handleOKDatapointsModal({
    meter,
    datapoints,
  }: {
    meter: NewMeter;
    datapoints: DoubleDatapoint[];
  }) {
    if (meter.externalId) {
      try {
        setIsLoadingModalDatatapoints(true);
        const hasChangedMeter =
          modalDatapointsMeter !== null &&
          (meter.unit !== modalDatapointsMeter.unit ||
            meter.description !== modalDatapointsMeter.description);
        const hasChangedDatapoints = !isEqual(
          modalDatapointsDatapoints,
          datapoints,
        );
        await Promise.all([
          hasChangedDatapoints
            ? changeDatapointsForManualTimeseries(
                meter.externalId.externalId,
                datapoints,
              )
            : Promise.resolve(),
          hasChangedMeter
            ? createOrUpdateManualTimeseries(meter)
            : Promise.resolve(),
        ]);
        setModalDatapointsMeter(null);
        setModalDatapointsDatapoints(null);
      } catch (error) {
        const errorMessage = isErrorWithMessage(error)
          ? error.message
          : String(error);
        message.error({
          content: t("timeseries.error-saving-timeseries", { errorMessage }),
          duration: 7,
        });
      } finally {
        setIsLoadingModalDatatapoints(false);
      }
    }
  }

  function handleReachedEndOfPage() {
    setSize(size + 1);
  }

  function handleChangeSortBy(
    sorter: SorterResult<TimeseriesExternalIdSearchable>,
  ) {
    if (
      sorter.order &&
      sorter.column &&
      typeof sorter.column.key === "string"
    ) {
      const order =
        sorter.order === "ascend" ? ("asc" as const) : ("desc" as const);
      return setSortBy({ key: sorter.column.key, order });
    }
    return setSortBy(null);
  }

  async function handleClickEditDatapoints(externalId: string) {
    try {
      setIsLoadingEditExternalId(externalId);
      const meter = await getManualTimeseries<Meter>(externalId);
      setModalDatapointsMeter(meter);
      setModalDatapointsDatapoints(await getDatapoints(externalId, meter.unit));
    } catch (error) {
      if (isErrorWithMessage(error)) {
        return message.error({
          content: error.message,
          duration: 7,
        });
      }
      console.error(error);
    } finally {
      setIsLoadingEditExternalId(null);
    }
  }

  async function handleConfirmDeleteManualTimeseries(externalId: string) {
    try {
      setLoadingRemoveExternalId(externalId);
      await deleteManualTimeseries(externalId);
      mutate();
    } catch (error) {
      if (isErrorWithMessage(error)) {
        return message.error({
          content: error.message,
          duration: 7,
        });
      }
      console.error(error);
    } finally {
      setLoadingRemoveExternalId(null);
    }
  }

  useEffect(() => {
    setHelp({
      title: t("timeseries.title"),
      content: (
        <>
          <p>{t("timeseries.help.brief-description")}</p>
          <p>{t("timeseries.help.detailed-description")}</p>
        </>
      ),
    });
  }, [setHelp, t]);

  return (
    <>
      <PageHeader
        title={t("timeseries.title")}
        extra={
          <TimeseriesTableHeader
            systemFilter={systemFilter}
            subBuildingFilter={subBuildingFilter}
            translatedLabelsFilter={translatedLabelsFilter}
            onChangeSubBuildingFilter={setSubBuildingFilter}
            onChangeSystemFilter={setSystemFilter}
            onChangeTranslatedLabelsFilter={setTranslatedLabelsFilter}
            onSearch={setSearch}
            onClickAddMeter={(type) =>
              setNewMeterData(
                getDefaultMeter(type, currentBuilding.externalId!),
              )
            }
            facetDistribution={facetDistribution || null}
          />
        }
      />
      <CompactContent>
        <TimeseriesTable
          timeseriesList={searchableTimeseriesList}
          isLoading={isLoading}
          loadingEditExternalId={loadingEditExternalId}
          loadingRemoveExternalId={loadingRemoveExternalId}
          onChangeSortBy={handleChangeSortBy}
          onReachedEndOfPage={handleReachedEndOfPage}
          onClickEditDatapoints={handleClickEditDatapoints}
          onConfirmDeleteManualTimeseries={handleConfirmDeleteManualTimeseries}
          onClickDetailsButton={setGraphModalData}
        />
      </CompactContent>
      {graphModalData && (
        <GraphModal
          timeseriesInfo={{
            id: graphModalData.id,
          }}
          hide={() => setGraphModalData(null)}
          expanded
          editable
          showDocuments
          buildingId={currentBuilding.id}
        />
      )}
      <MeterSelectionModal
        open={newMeterData !== null}
        meter={newMeterData}
        onOk={handleOKNewMeter}
        onHide={() => setNewMeterData(null)}
        isLoading={isLoadingMeterModal}
        uiOptions={{
          maskClosable: false,
          width: 700,
          okButtonLabel: t("timeseries.meter-selection-modal.ok-button-label"),
          helpText: t("timeseries.meter-selection-modal.help-text"),
        }}
      />
      <ModalDataPoints
        open={
          modalDatapointsMeter !== null && modalDatapointsDatapoints !== null
        }
        meter={modalDatapointsMeter || undefined}
        datapoints={modalDatapointsDatapoints || undefined}
        onOk={handleOKDatapointsModal}
        onCancel={() => {
          setModalDatapointsMeter(null);
          setModalDatapointsDatapoints(null);
        }}
        isLoading={isLoadingModalDatatapoints}
      />
    </>
  );
}
