import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { SearchInput } from "@/components/SearchIndex";
import { DraggableCard, WidgetHeader } from "@/features/dashboard";
import { useGetBuildingTenants } from "../hooks/useGetBuildingTenants";
import { useTenantsSieve } from "./hooks/useTenantsSieve";
import { BuildingTenantsTableFilters } from "./BuildingTenantsTableFilters";
import { Title } from "./elements";
import { BuildingTenantsTable } from "./BuildingTenantsTable";

type Props = {
  height: number;
  width: number;
  onClickRemoveButton: () => unknown;
};

export function BuildingTenantsWidget(props: Props) {
  const t = useTranslations();
  const buildingId = useCurrentBuildingId();
  const tenants = useGetBuildingTenants(buildingId);
  const headerHeight = 46;

  const { search, setSearch, filter, setFilter, filteredTenants } =
    useTenantsSieve(tenants.data);

  return (
    <DraggableCard
      style={{
        width: props.width,
        height: props.height,
      }}
      styles={{
        body: {
          padding: 24,
          display: "flex",
          flexDirection: "column",
          height: props.height - headerHeight,
        },
      }}
      title={
        <WidgetHeader
          text={t("dashboard.widgets.tenants.title")}
          onClickRemoveButton={props.onClickRemoveButton}
          isDraggable
        />
      }
    >
      <Title>
        <BuildingTenantsTableFilters value={filter} onChange={setFilter} />
        <SearchInput
          style={{ width: 200 }}
          allowClear
          placeholder={t("dashboard.widgets.tenants.search-placeholder")}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </Title>
      <BuildingTenantsTable
        hideActions
        hideNote
        buildingId={buildingId}
        tenants={filteredTenants}
        search={search}
        pageSize={5}
        clipCells
        size="small"
      />
    </DraggableCard>
  );
}
