import * as React from "react";
import { useMemo } from "react";
import dayjs from "@properate/dayjs";
import { CalendarEvents, ColoredProperateCalendar } from "@/utils/types";
import { StyledCalendar } from "@/pages/properateCalendar/components/elements";
import { DaySummaryModal } from "./DaySummaryModal";

type Props = {
  year: number;
  schedule: CalendarEvents;
  installationMap: Record<string, ColoredProperateCalendar>;
};
export const YearlyCalendar = ({ year, schedule, installationMap }: Props) => {
  const [dayModalDate, setDayModalDate] = React.useState<Date | null>(null);

  const monthTimestamps = useMemo(() => {
    const startOfYear = dayjs().year(year).startOf("year");
    return Array(12)
      .fill(null)
      .map((_, index) => startOfYear.month(index));
  }, [year]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      {monthTimestamps.map((month) => (
        <div
          key={month.month()}
          style={{
            width: 300,
            border: "1px solid #f0f0f0",
            display: "inline-block",
            paddingBottom: 20,
          }}
        >
          <StyledCalendar
            fullscreen={false}
            headerRender={({ value }) => (
              <div
                style={{
                  textTransform: "capitalize",
                  paddingLeft: 10,
                  fontSize: 16,
                  whiteSpace: "nowrap",
                }}
              >
                {value.format("MMMM")}
              </div>
            )}
            value={month}
            onSelect={(date) => {
              setDayModalDate(date.toDate());
            }}
          />
        </div>
      ))}
      <DaySummaryModal
        date={dayModalDate}
        schedule={schedule}
        hide={() => setDayModalDate(null)}
        installationMap={installationMap}
      />
    </div>
  );
};
