import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { scaleOrdinal } from "@visx/scale";
import { ThemeContext } from "styled-components";
import Color from "color";
import { useAccordionValues } from "@properate/ui";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import {
  OperationalPeriods,
  TooltipData,
  EnergyGraph,
  GraphLegend,
  Legend,
  EnergyNoteInfo,
  DESCRIPTION_LEGEND_VALUES,
} from "@/features/energy";
import { Granularity } from "@/utils/helpers";
import { ENERGY_NOTE_FILTER_ID } from "../../utils";

type Props = {
  width: number;
  height: number;
  bands: Date[];
  max: number;
  nonOperationalPeriods: OperationalPeriods[];
  holidayPeriods: OperationalPeriods[];
  tickValues?: number[];
  data: Record<string, number>[];
  onShowTooltip: ({ tooltipData }: { tooltipData: TooltipData }) => void;
  onHideTooltip: () => void;
  tooltipOpen: boolean;
  tooltipData?: TooltipData;
  legend: Legend;
  hasEPred: boolean;
  colors: string[];
  noteInfo?: EnergyNoteInfo;
  granularity: Granularity;
};
export const EnergyGraphWithLegend = ({
  width,
  height,
  data,
  onShowTooltip,
  onHideTooltip,
  tooltipOpen,
  tooltipData,
  holidayPeriods,
  tickValues,
  nonOperationalPeriods,
  max,
  bands,
  legend,
  hasEPred,
  colors,
  noteInfo,
  granularity,
}: Props) => {
  const [showHolidays, setShowHolidays] = useState(true);
  const [showEPred, setShowEPred] = useState(false);
  const [showNonOperational, setShowNonOperational] = useState(true);
  const [showTemperature, setShowTemperature] = useState(true);
  const [showNotes, setShowNotes] = useState(true);
  const themeContext = useContext(ThemeContext);
  const { filters } = useAccordionValues();

  const [highlightedLegend, setHighlightedLegend] = useState<string | null>(
    null,
  );

  const isFiltering = filters.get(ENERGY_NOTE_FILTER_ID)?.active ?? false;

  useEffect(() => {
    if (!isFiltering) {
      setHighlightedLegend(null);
    }
  }, [isFiltering]);

  const WEATHER_COLOR = themeContext.neutral0;
  const EPRED_COLOR = "#0d5b37";
  const NON_OPERATIONAL_COLOR = Color(themeContext.accent1)
    .alpha(0.8)
    .toString();
  const HOLIDAY_COLOR = themeContext.neutral4;
  const NOTE_COLOR = themeContext.accent12;

  const colorScale = useMemo(
    () =>
      scaleOrdinal<string, string>({
        domain: legend.map((l) => l.name),
        range: colors,
      }),
    [legend, colors],
  );
  const operationalScale = useMemo(
    () =>
      scaleOrdinal<string, string>({
        domain: hasEPred
          ? [
              DESCRIPTION_LEGEND_VALUES.nonOperational,
              DESCRIPTION_LEGEND_VALUES.holidays,
              DESCRIPTION_LEGEND_VALUES.temperature,
              DESCRIPTION_LEGEND_VALUES.hasNote,
              DESCRIPTION_LEGEND_VALUES.ePred,
            ]
          : [
              DESCRIPTION_LEGEND_VALUES.nonOperational,
              DESCRIPTION_LEGEND_VALUES.holidays,
              DESCRIPTION_LEGEND_VALUES.temperature,
              DESCRIPTION_LEGEND_VALUES.hasNote,
            ],
        range: [
          NON_OPERATIONAL_COLOR,
          HOLIDAY_COLOR,
          WEATHER_COLOR,
          NOTE_COLOR,
          EPRED_COLOR,
        ],
      }),
    [
      NON_OPERATIONAL_COLOR,
      HOLIDAY_COLOR,
      WEATHER_COLOR,
      EPRED_COLOR,
      NOTE_COLOR,
      hasEPred,
    ],
  );
  const keys = useMemo(() => legend.map((l) => l.name), [legend]);
  return (
    <div style={{ display: "flex", flexDirection: "column", width, height }}>
      <div className="flex-1 mb-1">
        <AutoSizer>
          {({ width: graphWidth, height: graphHeight }: Size) => {
            return (
              <EnergyGraph
                width={graphWidth}
                height={graphHeight}
                bands={bands}
                max={max}
                nonOperationalPeriods={nonOperationalPeriods}
                holidayPeriods={holidayPeriods}
                tickValues={tickValues}
                graphPoints={data}
                onShowTooltip={onShowTooltip}
                tooltipOpen={tooltipOpen}
                tooltipData={tooltipData}
                onHideTooltip={onHideTooltip}
                colorScale={colorScale}
                operationalScale={operationalScale}
                showTemperature={showTemperature}
                showNotes={showNotes}
                showEPred={showEPred}
                showNonOperational={showNonOperational}
                showHolidays={showHolidays}
                legend={keys}
                highlightedLegend={highlightedLegend}
                noteInfo={noteInfo}
                granularity={granularity}
              />
            );
          }}
        </AutoSizer>
      </div>
      <GraphLegend
        legend={legend}
        start={bands[0]}
        end={bands[bands.length - 1]}
        legendScale={colorScale}
        operationalScale={operationalScale}
        showTemperature={showTemperature}
        setShowTemperature={setShowTemperature}
        showNotes={showNotes}
        setShowNotes={setShowNotes}
        showNonOperational={showNonOperational}
        setShowNonOperational={setShowNonOperational}
        showHolidays={showHolidays}
        setShowHolidays={setShowHolidays}
        showEPred={showEPred}
        setShowEPred={setShowEPred}
        onHighlight={(value) => {
          setHighlightedLegend(value);
        }}
        highlight={highlightedLegend}
      />
    </div>
  );
};
