import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { SearchInput } from "@/components/SearchIndex";
import { DraggableCard, WidgetHeader } from "@/features/dashboard";
import { useGetBuildingContacts } from "../hooks/useGetBuildingContacts";
import { useContactsSieve } from "./hooks/useContactsSieve";
import { BuildingContactsTableFilters } from "./BuildingContactsTableFilters";
import { Title } from "./elements";
import { BuildingContactsTable } from "./BuildingContactsTable";
type Props = {
  height: number;
  width: number;
  onClickRemoveButton: () => unknown;
};

export function BuildingContactsWidget(props: Props) {
  const t = useTranslations();

  const buildingId = useCurrentBuildingId();
  const contacts = useGetBuildingContacts(buildingId);
  const headerHeight = 46;

  const { search, setSearch, filter, setFilter, filteredContacts } =
    useContactsSieve(contacts.data);

  return (
    <DraggableCard
      style={{
        width: props.width,
        height: props.height,
      }}
      styles={{
        body: {
          padding: 24,
          display: "flex",
          flexDirection: "column",
          height: props.height - headerHeight,
        },
      }}
      title={
        <WidgetHeader
          onClickRemoveButton={props.onClickRemoveButton}
          text={t("dashboard.widgets.contacts.title")}
          isDraggable
        />
      }
    >
      <Title>
        <BuildingContactsTableFilters value={filter} onChange={setFilter} />
        <SearchInput
          style={{ width: 200 }}
          allowClear
          placeholder={t("dashboard.widgets.contacts.search-placeholder")}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </Title>
      <BuildingContactsTable
        hideActions
        hideNote
        buildingId={buildingId}
        contacts={filteredContacts}
        search={search}
        pageSize={5}
        clipCells
        size="small"
      />
    </DraggableCard>
  );
}
