import { PRIMARY } from "../utils/ProperateColors";

const BACKGROUND = "#141414";

const BLUE = "#377CCC";
const CYAN = "#45ABE4";
const GREY = "#5D7092";
const PURPLE = "#7B60B0";
const ORANGE = "#FFA600";
const GREEN = "#12CF74";
const LIGHT_GREEN = "#4efcb3";
const PINK = "#D45087";

const YELLOW = "#F6BD16";
const RED = "#E8684A";
const LIGHT_BLUE = "#45ABE4";

export const COLOR_PLATE_10 = [
  BLUE,
  CYAN,
  GREY,
  YELLOW,
  RED,
  PURPLE,
  ORANGE,
  GREEN,
  LIGHT_GREEN,
  PINK,
];

export const COLOR_PLATE_20 = [
  BLUE,
  "#BDD2FD",
  "#5AD8A6",
  "#BDEFDB",
  "#5D7092",
  "#C2C8D5",
  "#F6BD16",
  "#FBE5A2",
  "#E8684A",
  "#F6C3B7",
  "#6DC8EC",
  "#B6E3F5",
  "#9270CA",
  "#D3C6EA",
  "#FF9D4D",
  "#FFD8B8",
  "#269A99",
  "#AAD8D8",
  "#FF99C3",
  "#FFD6E7",
];

export const DEFAULT_GLOBAL_THEME = {
  defaultColor: PRIMARY,
  background: BACKGROUND,
  colors: COLOR_PLATE_10,
  colors_20: COLOR_PLATE_20,
  color: {
    BLUE,
    CYAN,
    GREY,
    YELLOW,
    RED,
    LIGHT_BLUE,
    PURPLE,
    ORANGE,
    GREEN,
    PINK,
  },
  numberFormat: new Intl.NumberFormat("nb-NO", {}),
  percentFormat: new Intl.NumberFormat("nb-NO", {
    maximumSignificantDigits: 2,
    style: "percent",
  }),
};
