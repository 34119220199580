import { BuildingCertification } from "@properate/common";

export function getCertificationLabel(certification: BuildingCertification) {
  if (
    certification.type === "ISO14001" ||
    certification.type === "Miljøfyrtårn" ||
    certification.type === "Energimerke"
  ) {
    return certification.type;
  }

  return `${certification.type} ${certification.level}`;
}
