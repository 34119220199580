import useSWR from "swr";
import { useMemo } from "react";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFloorPlan } from "../FloorPlanContext";
import { useFloorPlans } from "./useFloorPlans";

// Defines a minimum width for the canvas that can fit a good amount of pins.
// This is also important for sizing and grouping the pins based on the zoom level.
export const MINIMUM_CANVAS_WIDTH = 3_000;

export function useFloorPlanCanvasSize() {
  const floorPlan = useFloorPlan();

  if (
    floorPlan._migration_script_version ||
    floorPlan.background.width < MINIMUM_CANVAS_WIDTH
  ) {
    return {
      width: MINIMUM_CANVAS_WIDTH,
      height:
        floorPlan.background.height *
        (MINIMUM_CANVAS_WIDTH / floorPlan.background.width),
      isAdjusted: true,
    };
  }

  return {
    width: floorPlan.background.width,
    height: floorPlan.background.height,
    isAdjusted: false,
  };
}

export function useFloorPlanBackground() {
  const floorPlan = useFloorPlan();
  const { client: cogniteClient } = useCogniteClient();
  const canvasSize = useFloorPlanCanvasSize();

  return useSWR(
    [
      "floor-plan-img",
      floorPlan.background.cogniteFileId,
      canvasSize.isAdjusted,
    ],
    async ([_, fileId, shouldAdjust]) =>
      cogniteClient.files
        .getDownloadUrls([{ id: fileId }])
        .then(async ([fileLink]) => {
          // We don't use the downloadUrl directly because it expires quickly.
          const response = await fetch(fileLink.downloadUrl);
          const blob = await response.blob();
          const urlCreator = window.URL || window.webkitURL;
          const blobUrl = urlCreator.createObjectURL(blob);

          // If this is not a floor plan migrated from the previous version,
          // we don't need to enforce the CANVAS_WIDTH on it.
          if (!shouldAdjust) {
            return blobUrl;
          }

          // We want all images to be the same width so the pins are always in a predictable size.
          async function getResizedImage() {
            const img = await getImageElement(blobUrl);

            const canvas = document.createElement("canvas");
            canvas.width = MINIMUM_CANVAS_WIDTH;
            canvas.height = img.height * (MINIMUM_CANVAS_WIDTH / img.width);
            const context = canvas.getContext("2d");

            if (context) {
              context.drawImage(img, 0, 0, canvas.width, canvas.height);
            }

            return new Promise<string>((resolve, reject) => {
              canvas.toBlob((blob) => {
                if (blob) {
                  resolve(urlCreator.createObjectURL(blob));
                } else {
                  reject("Failed to resize image using `canvas.toBlob`");
                }
              });
            });
          }

          const resizedImage = await getResizedImage();

          urlCreator.revokeObjectURL(blobUrl);

          return resizedImage;
        }),
    { revalidateOnFocus: false },
  );
}

export function useFloorPlanBackgrounds() {
  const floorPlans = useFloorPlans();
  const { client: cogniteClient } = useCogniteClient();

  const ids = useMemo(
    () =>
      floorPlans.data?.map((floorPlan) => ({
        snapshotId: floorPlan.snapshotId,
        cogniteFileId: floorPlan.background.cogniteFileId,
      })),
    [floorPlans.data],
  );

  return useSWR(
    ids ? ["floor-plan-backgrounds", ids] : null,
    async ([_, ids]) => {
      const files = await cogniteClient.files.getDownloadUrls(
        ids.map((id) => ({
          id: id.cogniteFileId,
        })),
      );

      const filesBySnapshotId: Record<string, string> = {};

      for (const file of files) {
        if ("id" in file) {
          for (const id of ids) {
            if (id.cogniteFileId === file.id) {
              filesBySnapshotId[id.snapshotId] = file.downloadUrl;
            }
          }
        } else {
          console.warn("Ignoring a Cognite file without an id.", file);
        }
      }

      return filesBySnapshotId;
    },
  );
}

async function getImageElement(blobUrl: string) {
  const img = new Image();
  img.src = blobUrl;

  await new Promise<void>((resolve) => {
    img.onload = () => resolve();
  });

  return img;
}
