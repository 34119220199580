import { useTranslations } from "@properate/translations";
import { Empty } from "antd";

export default function AlarmTimeseriesEmpty() {
  const t = useTranslations();
  return (
    <div className="w-full h-full flex justify-center items-center box-border">
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t("alarm-details.graph.empty-message")}
      />
    </div>
  );
}
