import { useState } from "react";
import { ExternalId, Timeseries } from "@cognite/sdk";
import { Button, Tooltip } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { HistoryOutlined } from "@ant-design/icons";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { AuditLogModal } from "@/components/AuditLog";
import { formatExtendedTimeseriesDescription } from "@/utils/helpers";

interface Props {
  externalId: ExternalId | null;
  noExternalIdTooltip?: string;
  style?: React.CSSProperties;
  size?: SizeType;
}

export function AuditLogButton({
  externalId,
  noExternalIdTooltip,
  style = {},
  size,
}: Props) {
  const { client } = useCogniteClient();
  const [auditLogTimeseries, setAuditLogTimeseries] = useState<Timeseries>();

  return (
    <div style={style}>
      <Tooltip
        placement="topLeft"
        title={noExternalIdTooltip}
        trigger={externalId === null ? ["hover"] : []}
      >
        <Button
          onClick={async () => {
            if (externalId) {
              setAuditLogTimeseries(
                (
                  await client.timeseries.retrieve([externalId], {
                    ignoreUnknownIds: true,
                  })
                )[0],
              );
            }
          }}
          size={size}
          disabled={!externalId}
          icon={<HistoryOutlined />}
        />
      </Tooltip>
      {auditLogTimeseries && (
        <AuditLogModal
          name={formatExtendedTimeseriesDescription(auditLogTimeseries)}
          externalId={auditLogTimeseries.externalId!}
          onHide={() => setAuditLogTimeseries(undefined)}
        />
      )}
    </div>
  );
}
