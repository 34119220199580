import { PropsWithChildren, useEffect, useState } from "react";
import { createPortal } from "react-dom";

export const NoteSidebarHeaderPortalId = "id--notes-sidebar-header";

export default function NotesSidebarTitlePortal({
  children,
}: PropsWithChildren) {
  const [portalElement, setPortalElement] = useState(
    document.getElementById(NoteSidebarHeaderPortalId),
  );
  useEffect(() => {
    /*
      Since the portal-element is rendered by sibling-node in React, it will often not exist on first-render of this
      component. To work around this we check for the element in a timeout-loop.
     */
    let timeoutHandle: any = null;
    let counter = 0;
    function checkTimeout() {
      if (timeoutHandle) {
        window.clearTimeout(timeoutHandle);
      }
    }

    if (portalElement) {
      return checkTimeout;
    }

    function checkForElement() {
      checkTimeout();
      if (counter > 5) {
        console.error(
          "Cannot render NotesSidebarTitlePortal, element not found",
        );
        return;
      }
      counter += 1;
      const element = document.getElementById(NoteSidebarHeaderPortalId);
      if (element) {
        setPortalElement(element);
        return checkTimeout();
      }
      timeoutHandle = setTimeout(checkForElement, 100);
    }

    checkTimeout();
    timeoutHandle = setTimeout(checkForElement, 100);
    return checkTimeout;
  }, [portalElement]);

  if (!portalElement) {
    return null;
  }

  return createPortal(children, portalElement);
}
