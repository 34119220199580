import { Space, Row, Col, Divider } from "antd";
import { useTranslations } from "@properate/translations";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { useSunriseSunset } from "@/hooks/useSunriseSunset";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { getItemByProp } from "@/utils/array";
import { SunIcon } from "../icons/SunIcon";
import { useGetTimeseriesProductionStatistics } from "../hooks/useGetTimeseriesProductionStatistics";
import { SolarCellProductionPeriod } from "../types";
import { ProductionSummaryValue } from "./elements";
import { periodOptions } from "./options";

interface Props {
  timeseriesIds: number[];
  period: SolarCellProductionPeriod;
  maxCapacity?: number;
}

export function SolarCellProductionSummary({
  timeseriesIds,
  period,
  maxCapacity,
}: Props) {
  const t = useTranslations();

  const {
    data: {
      productionLastDatapoints,
      productionToday,
      productionTotal,
      productionRelative,
    },
    isLoading: isLoadingProductionStatistics,
  } = useGetTimeseriesProductionStatistics(timeseriesIds, period, maxCapacity);
  const currentBuilding = useCurrentBuilding();
  const {
    sunrise,
    sunset,
    isLoading: isLoadingSunriseSunset,
  } = useSunriseSunset(
    Number(currentBuilding.metadata!.Lat),
    Number(currentBuilding.metadata!.Lon),
  );

  const isLoading = isLoadingProductionStatistics || isLoadingSunriseSunset;

  return (
    <Space direction="vertical">
      <h3>{t("dashboard.widgets.solar-production.production")}</h3>
      <SpinnerWithDelay isLoading={isLoading}>
        <Row align="middle" gutter={8}>
          <Col xs={10}>
            {t("dashboard.widgets.solar-production.last-measured-value")}
          </Col>
          <Col xs={14}>
            <ProductionSummaryValue>
              {productionLastDatapoints.toFixed(1)} kWh
            </ProductionSummaryValue>
          </Col>
          {productionRelative && (
            <>
              <Col xs={10}>
                {t("dashboard.widgets.solar-production.percentage-of-max")}
              </Col>
              <Col xs={14}>
                <ProductionSummaryValue>
                  {productionRelative.toFixed(1)}%
                </ProductionSummaryValue>
              </Col>
            </>
          )}
          <Col xs={10}>
            {t("dashboard.widgets.solar-production.until-today")}
          </Col>
          <Col xs={14}>
            <ProductionSummaryValue>
              {productionToday.toFixed(1)} kWh
            </ProductionSummaryValue>
          </Col>
          <Col xs={10}>
            {t(getItemByProp(periodOptions, period, "value").labelKey)}
          </Col>
          <Col xs={14}>
            <ProductionSummaryValue>
              {productionTotal.toFixed(1)} kWh
            </ProductionSummaryValue>
          </Col>
          <Divider />
          <Col xs={10}>{t("dashboard.widgets.solar-production.sunrise")}</Col>
          <Col xs={14}>
            <ProductionSummaryValue>
              {sunrise} <SunIcon type="sunrise" />
            </ProductionSummaryValue>
          </Col>
          <Col xs={10}>{t("dashboard.widgets.solar-production.sunset")}</Col>
          <Col xs={14}>
            <ProductionSummaryValue>
              {sunset} <SunIcon type="sunset" />
            </ProductionSummaryValue>
          </Col>
        </Row>
      </SpinnerWithDelay>
    </Space>
  );
}
