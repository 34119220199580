import { FormEntryValidator } from "./types";

export function booleanValidator(message: string): FormEntryValidator<boolean> {
  return (value: unknown, _entries) => {
    return {
      valid: typeof value === "boolean",
      message,
    };
  };
}

export function stringValidator(
  message: string,
  options: { minLength?: number; trim?: boolean } = {},
): FormEntryValidator<string> {
  const opt = {
    ...{ minLength: 1, trim: true },
    ...options,
  };
  return (value: unknown, _entries) => {
    return {
      valid:
        typeof value === "string" &&
        (opt.trim ? value.trim() : value).length >= opt.minLength,
      message,
    };
  };
}

export function numberValidator(
  message: string,
  options: { minValue?: number; maxValue?: number } = {},
): FormEntryValidator<number> {
  return (value: unknown, _entries) => {
    return {
      valid:
        typeof value === "number" &&
        (options.maxValue !== undefined ? value <= options.maxValue : true) &&
        (options.minValue !== undefined ? value >= options.minValue : true),
      message,
    };
  };
}

export function selectValidator(
  message: string,
  choices: string[],
): FormEntryValidator<string> {
  return (value: unknown, _entries) => {
    return {
      valid: typeof value === "string" && choices.includes(value),
      message,
    };
  };
}

export function arrayValidator<T>(
  message: string,
  options: {
    minLength?: number;
    maxLength?: number;
    validateEntry?: (value: T, index: number, list: T[]) => boolean;
  } = {},
): FormEntryValidator<T[]> {
  return (values: unknown, _entries) => {
    function isValid(): boolean {
      if (!Array.isArray(values)) {
        return false;
      }
      if (options.minLength && values.length < options.minLength) {
        return false;
      }
      if (options.maxLength && values.length > options.maxLength) {
        return false;
      }
      if (options.validateEntry) {
        values.forEach((value, index) => {
          if (options?.validateEntry?.(value, index, values) === false) {
            return false;
          }
        });
      }
      return true;
    }

    return {
      valid: isValid(),
      message,
    };
  };
}
