import { ButtonProps } from "antd";
import { ReactNode } from "react";

export type SidebarProps = {
  className?: string;
};

export type SidebarValues<T> = {
  isOpen: boolean;
  title: ReactNode;
  content: ReactNode;
  data: T;
  portalDomId: string;
};

export enum SidebarActionType {
  open,
  close,
  toggle,
  setTitle,
  setContent,
  setData,
  mergeData,
  setPortalDomID,
}

export type SidebarAction =
  | {
      type:
        | SidebarActionType.open
        | SidebarActionType.close
        | SidebarActionType.toggle;
    }
  | {
      type: SidebarActionType.setTitle;
      title: ReactNode;
    }
  | {
      type: SidebarActionType.setContent;
      content: ReactNode;
    }
  | {
      type: SidebarActionType.setData | SidebarActionType.mergeData;
      data: Record<string, unknown>;
    }
  | {
      type: SidebarActionType.setPortalDomID;
      portalDomId: string;
    };

export type ToggleSidebarButtonProps = {
  sidebarVisibleContent?: ReactNode;
  sidebarHiddenContent?: ReactNode;
  hiddenWhenSidebarVisible?: boolean;
  hiddenWhenSidebarHidden?: boolean;
} & ButtonProps;
