import { memo, useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridColumns } from "@visx/grid";
import { Circle, LinePath } from "@visx/shape";
import { curveLinear } from "@visx/curve";
import { ScaleOrdinal } from "d3-scale";
import { PositionScale } from "@visx/shape/lib/types/base";
import { useTranslations } from "@properate/translations";
import { numberFormatForAxis } from "@/utils/helpers";
import { TrendLinePoint } from "@/utils/types";
import { TooltipData } from "@/features/energy";

const margin = { top: 5, right: 20, bottom: 30, left: 30 };

type Props = {
  innerHeight: number;
  usableFloorArea: number;
  data: Record<
    string,
    number | string | undefined | boolean | Pick<TooltipData, "note">
  >[];
  showEnergyPerArea: boolean;
  trendLine: TrendLinePoint[];
  xScale: PositionScale;
  yScale: PositionScale;
  colorScale: ScaleOrdinal<string, string>;
};

export const ETChartContents = memo(function ETChartContents({
  innerHeight,
  usableFloorArea,
  data,
  showEnergyPerArea,
  trendLine,
  xScale,
  yScale,
  colorScale,
}: Props) {
  const t = useTranslations();

  const themeContext = useContext(ThemeContext);

  const _unit = useMemo(() => {
    return "kWh" + (showEnergyPerArea ? "/m²" : "");
  }, [showEnergyPerArea]);

  const points = useMemo(
    () =>
      data.map((point) => {
        return (
          <Circle
            key={point.timestamp as number}
            cx={xScale(point.temperature as number)!}
            cy={
              yScale(
                showEnergyPerArea
                  ? (point.total as number) / usableFloorArea
                  : (point.total as number),
              )!
            }
            r={6}
            fill={colorScale(point.operational as string)}
            fillOpacity={0.8}
            strokeWidth={point.note ? 2 : 1}
            stroke={
              point.note
                ? themeContext.accent12
                : colorScale(point.operational as string)
            }
          />
        );
      }),
    [
      data,
      xScale,
      yScale,
      showEnergyPerArea,
      usableFloorArea,
      colorScale,
      themeContext.accent12,
    ],
  );

  const trendLineGraph = useMemo(
    () => (
      <LinePath
        stroke={themeContext.accent6}
        strokeWidth={2}
        data={trendLine}
        x={(d) => xScale(d.temperature)!}
        y={(d) =>
          yScale(showEnergyPerArea ? d.energy / usableFloorArea : d.energy)!
        }
        curve={curveLinear}
      />
    ),
    [
      trendLine,
      xScale,
      yScale,
      showEnergyPerArea,
      usableFloorArea,
      themeContext.accent6,
    ],
  );

  return (
    <Group left={margin.left} top={margin.top}>
      <AxisLeft
        scale={yScale}
        stroke={themeContext.neutral5}
        tickStroke={themeContext.neutral5}
        tickFormat={(value: any) => numberFormatForAxis.format(value)}
        tickLabelProps={{
          fill: themeContext.neutral5,
        }}
      />
      <AxisBottom
        scale={xScale}
        top={innerHeight}
        stroke={themeContext.neutral5}
        tickStroke={themeContext.neutral5}
        tickLabelProps={{
          fill: themeContext.neutral5,
        }}
      />
      <GridColumns
        top={0}
        scale={xScale}
        height={innerHeight}
        strokeOpacity={0.8}
        pointerEvents="none"
      />
      {trendLineGraph}
      <text
        x={(showEnergyPerArea ? -115 : -95) + 40}
        y={15}
        transform="rotate(-90)"
        fontSize={10}
        fill={themeContext.neutral5}
      >
        {t("energy.energy", {
          unit: _unit,
        })}
      </text>
      <text
        x={8}
        y={innerHeight - 5}
        fontSize={10}
        fill={themeContext.neutral5}
        textAnchor={"right"}
      >
        {t("energy.temperature", {
          unit: "°C",
        })}
      </text>
      {points}
    </Group>
  );
});
