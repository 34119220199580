import { BuildingCertification } from "@properate/common";
import { Badge } from "antd";
import {
  getEnergyMark,
  BuildingCertificationBadge,
} from "@/components/building-certifications";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { AlarmKPI, CertificationKPI, KPI } from "@/utils/types";
import { alarmKPIs, certificationKPIs, getKPIConfiguration } from "@/utils/kpi";

function isAlarmKPI(kpi: KPI): kpi is AlarmKPI {
  return alarmKPIs.some((alarmKPI) => alarmKPI === kpi);
}

function isCertificationKPI(kpi: KPI): kpi is CertificationKPI {
  return certificationKPIs.some((alarmKPI) => alarmKPI === kpi);
}

export function renderKpiValue(kpi: KPI, data: any, defaultValue = "-") {
  if (isAlarmKPI(kpi) || isCertificationKPI(kpi)) {
    if (
      kpi === "certifications" &&
      data?.some((cert: BuildingCertification) => cert.type === "Energimerke")
    ) {
      const energyMarks = data.filter(
        (cert: BuildingCertification) => cert.type === "Energimerke",
      );

      return (
        <>
          {energyMarks.map((cert: BuildingCertification, index: number) =>
            index < 2 ? (
              <div key={cert.id}>
                {getEnergyMark(
                  {
                    energyRating: cert.level[0] as any,
                    heatingRating: Number(cert.level[1]) as any,
                  },
                  30,
                  30,
                )}
              </div>
            ) : null,
          )}
          {energyMarks.length > 2 ? "…" : ""}
        </>
      );
    }

    if (isCertificationKPI(kpi)) {
      const certification = data?.find(
        (cert: BuildingCertification) => cert.type === kpi,
      );

      if (certification) {
        return (
          <BuildingCertificationBadge certification={certification} size={32} />
        );
      }

      return defaultValue;
    }

    if (isAlarmKPI(kpi)) {
      if (data === 0) {
        return <>0</>;
      }

      let backgroundColor = DEFAULT_GLOBAL_THEME.color.GREY;

      switch (kpi) {
        case "errorAlarms":
          backgroundColor = DEFAULT_GLOBAL_THEME.color.RED;
          break;
        case "warningAlarms":
          backgroundColor = DEFAULT_GLOBAL_THEME.color.YELLOW;
          break;
        case "infoAlarms":
          backgroundColor = DEFAULT_GLOBAL_THEME.color.BLUE;
          break;
      }

      return (
        <Badge
          count={data}
          showZero
          style={{
            backgroundColor,
            position: "static",
          }}
        />
      );
    }

    return defaultValue;
  }

  if (typeof data === "number") {
    const {
      metadata: { fractionDigits },
    } = getKPIConfiguration(kpi);
    const formatter = new Intl.NumberFormat("nb-NO", {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    });

    return formatter.format(data);
  }

  return defaultValue;
}

export function renderKpiValueWithUnit(
  kpi: KPI,
  data: any,
  defaultValue = "-",
) {
  const value = renderKpiValue(kpi, data, defaultValue);
  const configuration = getKPIConfiguration(kpi);

  if ("metadata" in configuration && value !== defaultValue) {
    return `${value} ${configuration.metadata.unit}`;
  }

  return value;
}
