import { useBuildingsSearch } from "@/context/BuildingsSearchContext";
import { BuildingWithKPIs } from "./useBuildingsWithKPIs";

export function useFilteredBuildings(buildings: BuildingWithKPIs[]) {
  const { search } = useBuildingsSearch();
  const searchTerm = search?.toLowerCase();

  return {
    filteredBuildings: buildings.filter(
      ({ building }) =>
        building.name.toLowerCase().includes(searchTerm) ||
        (building.alias || "").toLowerCase().includes(searchTerm) ||
        (building.owner || "").toLowerCase().includes(searchTerm),
    ),
  };
}
