import { Form, Input } from "antd";
import { useTranslations } from "@properate/translations";
import { RadioGroupUnits } from "@/components/MeterSelectionModal/RadioGroupUnits";
import { unitOptionsByMeterType } from "@/components/MeterSelectionModal/utils";
import {
  DatapointReducerActionType,
  useMeterData,
  useMeterDataDispatch,
} from "./MeterDataContext";

export function ModalDataPointsUnitDescription() {
  const t = useTranslations();
  const { meter } = useMeterData();
  const dispatch = useMeterDataDispatch();

  const unitOptions =
    unitOptionsByMeterType[meter.meterType][
      meter.isAccumulated ? "accumulated" : "instantaneous"
    ];

  function handleChange({
    description,
    unit,
  }: {
    description?: string;
    unit?: string;
  }) {
    if (description) {
      dispatch({
        type: DatapointReducerActionType.editMeter,
        fieldName: "description",
        fieldValue: description,
      });
    }
    if (unit) {
      dispatch({
        type: DatapointReducerActionType.editMeter,
        fieldName: "unit",
        fieldValue: unit,
      });
    }
  }

  return (
    <Form<{ description: string; unit: string }>
      onValuesChange={handleChange}
      initialValues={{ description: meter.description, unit: meter.unit }}
      labelCol={{ span: 6 }}
    >
      <Form.Item
        label={t("timeseries.meter-selection-modal.description")}
        name="description"
        rules={[
          {
            required: true,
            message: t(
              "timeseries.meter-selection-modal.you-must-select-description",
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("timeseries.meter-selection-modal.unit")}
        name="unit"
        rules={[
          {
            required: true,
            message: t("timeseries.meter-selection-modal.you-must-select-unit"),
          },
        ]}
      >
        <RadioGroupUnits options={unitOptions} />
      </Form.Item>
    </Form>
  );
}
