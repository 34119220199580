import { Space } from "antd";
import { useTranslations } from "@properate/translations";
import { ColorPicker } from "@/components/ColorPicker/ColorPicker";

interface Props {
  value: string;
  onChange: (value: string) => unknown;
}

export function HeatMapColorPicker({ value, onChange }: Props) {
  const t = useTranslations();

  return (
    <Space>
      <label htmlFor="color">{t("analysis.heat-map.color")}:</label>
      <ColorPicker value={value} onChange={onChange} />
    </Space>
  );
}
