"use client";

import { Button, Divider, Form, Input, Select, Spin, notification } from "antd";
import { useEffect } from "react";
import { useTranslations } from "@properate/translations";
import { useSessionStorageState } from "ahooks";
import { ArrowLeftIcon } from "lucide-react";
import { useTaskType } from "../contexts/task-type-context";
import { TaskTemplate } from "../schemas";
import { useGetTaskTemplates } from "../hooks/use-get-task-templates";
import { useCreateTask } from "../hooks/use-create-task";
import { TaskListItem } from "../components/task-list-item";
import { type useGetBuildingsWithImages } from "../hooks/use-get-buildings-with-images";

type Props = {
  buildings: ReturnType<typeof useGetBuildingsWithImages>;
  buildingId?: number;
  onCreated: (arg: { buildingId: number; snapshotId: string }) => void;
  components?: number[];
};

export function SetupPage(props: Readonly<Props>) {
  const taskType = useTaskType();

  if (taskType === "tasks") {
    return <TaskSetupPage {...props} />;
  }

  return <TaskTemplateSetupPage {...props} />;
}

export const BUILDING_ID_STORAGE_KEY = "task-setup-building-id";

function TaskSetupPage(props: Readonly<Props>) {
  const t = useTranslations();
  const templates = useGetTaskTemplates();
  const createTask = useCreateTask();
  const taskType = useTaskType();
  const [buildingId, setBuildingId] = useSessionStorageState(
    BUILDING_ID_STORAGE_KEY,
    { defaultValue: props.buildingId },
  );

  useEffect(() => {
    // If there is only one building, select it automatically
    if (props.buildings.length === 1) {
      setBuildingId(props.buildings[0].id);
    }
  }, [props.buildings, setBuildingId]);

  async function handleCreate(data: Partial<Omit<TaskTemplate, "snapshotId">>) {
    const building = props.buildings.find((building) => {
      return building.id === buildingId;
    });

    if (building) {
      const taskSnapshotId = await createTask.trigger({
        data: {
          title: "",
          ...data,
          buildingId: building.id,
          dataSetId: building.dataSetId,
          components: props.components,
        },
      });

      props.onCreated({
        buildingId: building.id,
        snapshotId: taskSnapshotId,
      });
    } else {
      notification.error({
        message: t("task.notifications.create.error", {
          taskType,
        }),
      });
    }
  }

  function handleCreateFromTemplate(template: TaskTemplate) {
    const { snapshotId, ...newTask } = template;
    handleCreate(newTask);
  }

  if (!buildingId) {
    return (
      <section>
        <Form layout="vertical">
          <Form.Item
            label={t("task.field.building")}
            name="buildingId"
            required
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option
                  ? option.searchField
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  : false
              }
              loading={props.buildings.length === 0}
              onChange={setBuildingId}
              options={props.buildings.map((building) => ({
                value: building.id,
                searchField: building.name,
                label: (
                  <div
                    key={building.id}
                    data-testid="select-option"
                    className="flex items-center p-0 m-0 space-x-4 border rounded-md "
                  >
                    <img
                      src={building.imageUrl}
                      className="object-cover w-10 h-10 rounded-md"
                      alt={building.name}
                    />
                    <div>
                      <h1 className="m-0 text-sm font-semibold">
                        {building.name}
                      </h1>
                      <p className="m-0 text-xs text-gray-500">
                        {building.address}
                      </p>
                    </div>
                  </div>
                ),
              }))}
            />
          </Form.Item>
        </Form>
      </section>
    );
  }

  return (
    <div>
      <header className="flex items-center gap-4 mb-8 space-y-1">
        <button
          className="flex p-0 mt-1 bg-transparent border-0 cursor-pointer"
          onClick={() => setBuildingId(undefined)}
        >
          <ArrowLeftIcon className="w-5 h-5" />
        </button>
        <div className="text-lg font-semibold">{t("task.create.title")}</div>
        {createTask.isMutating && <Spin />}
      </header>
      <button
        className="flex flex-col justify-between w-full gap-2 px-4 py-3 text-base text-left transition-colors border border-solid rounded-lg cursor-pointer bg-card lg:flex-row hover:bg-accent"
        onClick={() => handleCreate({})}
        disabled={createTask.isMutating}
        data-testid="empty-task-button"
      >
        <div className="flex-1 truncate">{t("ui.empty")}</div>
      </button>
      <Divider className="my-4" />
      {templates.data.map((template, i) => (
        <button
          key={template.snapshotId}
          onClick={() => handleCreateFromTemplate(template)}
          className="w-full p-0 text-base text-left bg-transparent border-0"
          disabled={createTask.isMutating}
        >
          <TaskListItem
            address=""
            task={template}
            isFirst={i === 0}
            isLast={i === templates.data.length - 1}
          />
        </button>
      ))}
    </div>
  );
}

function TaskTemplateSetupPage(props: Readonly<Props>) {
  const t = useTranslations();
  const createTask = useCreateTask();

  async function handleCreate(data: Pick<TaskTemplate, "title">) {
    const taskTemplateSnapshotId = await createTask.trigger({
      data,
    });

    props.onCreated({
      buildingId: NaN,
      snapshotId: taskTemplateSnapshotId,
    });
  }

  return (
    <section className="flex items-center gap-2">
      <Form
        layout="vertical"
        className="flex-1"
        id="create-form"
        onFinish={handleCreate}
      >
        <Form.Item label={t("task.field.title")} name="title" required>
          <Input />
        </Form.Item>
      </Form>
      <Button
        className="mt-[6px]"
        htmlType="submit"
        form="create-form"
        loading={createTask.isMutating}
      >
        {t("ui.next")}
      </Button>
    </section>
  );
}
