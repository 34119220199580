import { ChangeEvent } from "react";
import { Button, Input, Flex, Space, Form } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { validate } from "email-validator";
import { useTranslations } from "@properate/translations";

interface Props {
  values: string[];
  onChange: (values: string[]) => void;
}

export const EmailReceiversInput = ({ values, onChange }: Props) => {
  const t = useTranslations();

  const handleChange = (email: string, index: number) => {
    onChange([...values.slice(0, index), email, ...values.slice(index + 1)]);
  };

  const handleAdd = () => {
    onChange([...values, ""]);
  };
  const handleDelete = (index: number) => {
    onChange([...values.slice(0, index), ...values.slice(index + 1)]);
  };

  return (
    <>
      <Flex wrap="wrap" gap="small">
        {values.map((email, index) => (
          <Space direction="horizontal" key={index}>
            <Form.Item
              rules={[
                () => ({
                  validator(_, value) {
                    if (validate(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Ugyldig epost addresse"));
                  },
                }),
              ]}
            >
              <Input
                placeholder={t("calculation-flow.email")}
                value={email}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event.target.value, index)
                }
              />
            </Form.Item>
            <Form.Item>
              <Button onClick={() => handleDelete(index)}>
                <DeleteOutlined />
              </Button>
            </Form.Item>
          </Space>
        ))}
        <Button
          type="default"
          shape="circle"
          icon={<PlusOutlined />}
          onClick={handleAdd}
        />
      </Flex>
    </>
  );
};
