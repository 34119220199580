import { Tooltip } from "antd";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { ActiveNoteGreenDot } from "./elements";

interface Props {
  startTime: Date;
  endTime: Date;
}
const EventTimeRange = ({ startTime, endTime }: Props) => {
  const formattedStartTime = dayjs(startTime).format("DD/MM YYYY HH:mm");
  const formattedEndTime = dayjs(endTime).format("DD/MM YYYY HH:mm");

  return (
    <>
      <span>{`${formattedStartTime} ${formattedEndTime}`}</span>
    </>
  );
};

const EventTimeWithStatus = ({ startTime, endTime }: Props) => {
  const t = useTranslations();
  return (
    <>
      <EventTimeRange startTime={startTime} endTime={endTime} />
      <Tooltip title={t("dashboard.widgets.notes.tooltip-active")}>
        <ActiveNoteGreenDot>●</ActiveNoteGreenDot>
      </Tooltip>
    </>
  );
};

export default EventTimeWithStatus;
