import * as React from "react";
import {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";

import classNames from "classnames";
import _ from "lodash";

const Container = styled.div`
  height: 54px;
  svg {
    user-select: none;

    .scrollbar,
    .focusBackground,
    .button {
      cursor: pointer;
    }
    .thumb,
    .focusArea {
      cursor: grab;
    }
    .button:hover {
      fill: #eeeeee;
    }
    .handle {
      cursor: col-resize;
    }
    &.dragging {
      cursor: grabbing;
      .thumb:hover,
      .focusArea:hover {
        cursor: grabbing;
      }
    }
  }
`;

const ScrollBar = ({
  width,
  position,
  thumbWidth,
  startDrag,
  next,
  previous,
  scrollTo,
}: {
  width: number;
  position: number;
  thumbWidth: number;
  startDrag: MouseEventHandler;
  next: MouseEventHandler;
  previous: MouseEventHandler;
  scrollTo: MouseEventHandler;
}) => {
  return (
    <g data-z-index="3" className="scrollbar">
      <rect
        fill="#f2f2f2"
        x="0"
        rx="0"
        ry="0"
        height="14"
        width={width}
        stroke="#f2f2f2"
        strokeWidth={1}
        y={-0.5}
        onClick={scrollTo}
      />
      <g transform={"translate(" + (14 + position) + ",-0.5)"}>
        <rect
          fill="#cccccc"
          className="thumb"
          height="14"
          width={thumbWidth}
          rx={0}
          ry={0}
          stroke="#cccccc"
          strokeWidth={1}
          onMouseDown={startDrag}
        />
        <path
          fill="none"
          d="M -3 3.5 L -3 9.333333333333334 M 0 3.5 L 0 9.333333333333334 M 3 3.5 L 3 9.333333333333334"
          className="rifles"
          stroke="#333333"
          strokeWidth={1}
          transform={"translate(" + thumbWidth / 2 + ",0)"}
        />
      </g>
      <g>
        <rect
          fill="#e6e6e6"
          stroke="#cccccc"
          className="button"
          strokeWidth={1}
          x={-0.5}
          y={-0.5}
          width={14}
          height={14}
          rx={0}
          ry={0}
          onClick={previous}
        />
        <path fill="#333333" d="M 8 4 L 8 10 L 5 7" />
      </g>
      <g transform={"translate(" + (width - 14) + ",0)"}>
        <rect
          fill="#e6e6e6"
          className="button"
          stroke="#cccccc"
          strokeWidth={1}
          x={-0.5}
          y={-0.5}
          width={14}
          height={14}
          rx={0}
          ry={0}
          onClick={next}
        />
        <path fill="#333333" d="M 6 4 L 6 10 L 9 7" />
      </g>
    </g>
  );
};

const Adjust = ({
  pos,
  startAdjust,
}: {
  pos: number;
  startAdjust: MouseEventHandler;
}) => {
  return (
    <path
      fill="#f2f2f2"
      d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
      data-z-index="6"
      className="handle handle-right"
      stroke="#999999"
      strokeWidth="1"
      transform={"translate(" + pos + ",12.5)"}
      onMouseDown={startAdjust}
    />
  );
};

const MiniGraph = ({
  width,
  position,
  thumbWidth,
  scrollTo,
  startDrag,
  startAdjustLeft,
  startAdjustRight,
  children,
}: {
  width: number;
  position: number;
  thumbWidth: number;
  scrollTo: MouseEventHandler;
  startDrag: MouseEventHandler;
  startAdjustLeft: MouseEventHandler;
  startAdjustRight: MouseEventHandler;
  children: ReactNode;
}) => {
  const defaultLastAdjust = position > width / 2 ? "left" : "right";
  const [lastAdjust, setLastAdjust] = useState<"right" | "left">(
    defaultLastAdjust,
  );
  const thumbs = [
    <Adjust
      key="left"
      pos={position}
      startAdjust={(event) => {
        setLastAdjust("left");
        startAdjustLeft(event);
      }}
    />,
    <Adjust
      key="right"
      pos={position + thumbWidth}
      startAdjust={(event) => {
        setLastAdjust("right");
        startAdjustRight(event);
      }}
    />,
  ];
  return (
    <>
      {children}
      <rect
        width={width}
        height={40}
        onClick={scrollTo}
        className="focusBackground"
        fill="transparent"
      />
      <rect
        width={thumbWidth}
        height={40}
        fill="rgba(102, 133, 194, 0.3)"
        stroke="#cccccc"
        y={0}
        x={position}
        onMouseDown={startDrag}
        className="focusArea"
      />
      {lastAdjust === "right" ? thumbs : thumbs.reverse()}
    </>
  );
};

interface Props {
  width: number;
  position: number;
  highlight: number;
  children: ReactNode;
  onUpdate: (position: number, highlight: number) => unknown;
  hideScrollbar?: boolean;
}

export const GraphScrollArea = ({
  width,
  position,
  highlight,
  children,
  onUpdate,
  hideScrollbar = false,
}: Props) => {
  const barWidth = width - 28;
  const [startDragPos, setStartDragPos] = useState<number>();
  const [startAdjustLeftPos, setStartAdjustLeftPos] = useState<number>();
  const [startAdjustRightPos, setStartAdjustRightPos] = useState<number>();
  const [thumbWidth, setThumbWidth] = useState(highlight * barWidth);

  const [start, setStart] = useState<number>();

  useEffect(() => {
    setThumbWidth(highlight * barWidth);
  }, [highlight, setThumbWidth, barWidth]);

  useEffect(() => {
    setStart(Math.min(position * barWidth, barWidth));
  }, [position, setStart, barWidth]);

  const updateStart = useCallback(
    (newStart: number) => {
      onUpdate(newStart / barWidth, thumbWidth / barWidth);
    },
    [barWidth, thumbWidth, onUpdate],
  );

  const updateThumbWidth = useCallback(
    (width: number) => {
      onUpdate(start! / barWidth, width / barWidth);
    },
    [barWidth, onUpdate, start],
  );

  const updateAll = useCallback(
    (newStart: number, width: number) => {
      onUpdate(newStart / barWidth, width / barWidth);
    },
    [barWidth, onUpdate],
  );

  const startAdjustLeft = (event: any) => {
    setStartAdjustLeftPos(event.clientX);
  };
  const startAdjustRight = (event: any) => {
    setStartAdjustRightPos(event.clientX);
  };
  const startDrag = (event: any) => {
    setStartDragPos(event.clientX);
  };
  const scrollTo = (event: any) => {
    const x = event.clientX - event.target.getBoundingClientRect().left - 14;
    const newStart = _.clamp(x - thumbWidth / 2, 0, barWidth - thumbWidth);
    updateStart(newStart);
  };
  const next = () => {
    updateStart(
      Math.min(start! + Math.max(thumbWidth / 2, 10), barWidth - thumbWidth),
    );
  };
  const previous = () => {
    updateStart(Math.max(start! - Math.max(thumbWidth / 2, 10), 0));
  };

  const active =
    typeof startDragPos === "number" ||
    typeof startAdjustLeftPos === "number" ||
    typeof startAdjustRightPos === "number";

  useEffect(() => {
    const mouseMove = (event: any) => {
      if (startDragPos) {
        const newStart = _.clamp(
          start! + (event.clientX - startDragPos),
          0,
          barWidth - thumbWidth,
        );
        //updateStart(newStart);
        setStart(newStart);
        setStartDragPos(event.clientX);
        //throttledUpdate();
      } else if (startAdjustLeftPos) {
        const newStart = _.clamp(
          start + event.clientX - startAdjustLeftPos,
          0,
          start! + thumbWidth - 1,
        );

        const newThumbWidth = thumbWidth + start! - newStart;
        //updateThumbWidth(thumbWidth + start - newStart);
        //updateStart(newStart);
        setThumbWidth(newThumbWidth);
        setStart(newStart);
        setStartAdjustLeftPos(event.clientX);
      } else if (startAdjustRightPos) {
        const newThumbWidth = _.clamp(
          thumbWidth + (event.clientX - startAdjustRightPos),
          1,
          barWidth - start!,
        );

        //updateThumbWidth(newThumbWidth);
        setThumbWidth(newThumbWidth);
        setStartAdjustRightPos(event.clientX);
      }
    };
    const mouseLeave = () => {
      setStartDragPos(undefined);
      setStartAdjustLeftPos(undefined);
      setStartAdjustRightPos(undefined);
      updateAll(start!, thumbWidth);
    };
    if (active) {
      window.addEventListener("mouseup", mouseLeave);
      window.addEventListener("mousemove", mouseMove);
    } else {
      window.removeEventListener("mouseup", mouseLeave);
      window.removeEventListener("mousemove", mouseMove);
    }

    return () => {
      window.removeEventListener("mouseup", mouseLeave);
      window.removeEventListener("mousemove", mouseMove);
    };
  }, [
    active,
    barWidth,
    start,
    startAdjustLeftPos,
    startAdjustRightPos,
    startDragPos,
    thumbWidth,
    updateStart,
    updateThumbWidth,
    updateAll,
  ]);

  return width > 0 && thumbWidth > 0 ? (
    <Container>
      <svg
        width={width}
        height={54}
        className={classNames({
          dragging: !!startDragPos,
          adjusting: startAdjustLeftPos || startAdjustRightPos,
        })}
      >
        {typeof start === "number" && (
          <>
            {!hideScrollbar && (
              <g transform="translate(0,40)">
                <ScrollBar
                  width={width}
                  position={start}
                  scrollTo={scrollTo}
                  thumbWidth={thumbWidth}
                  startDrag={startDrag}
                  next={next}
                  previous={previous}
                />
              </g>
            )}
            <g transform="translate(14,0)">
              <MiniGraph
                width={width - 28}
                position={start}
                scrollTo={scrollTo}
                thumbWidth={thumbWidth}
                startDrag={startDrag}
                startAdjustLeft={startAdjustLeft}
                startAdjustRight={startAdjustRight}
              >
                {children}
              </MiniGraph>
            </g>
          </>
        )}
      </svg>
    </Container>
  ) : null;
};
