import debounce from "lodash/debounce";
import { useMemo } from "react";
import useLatest from "./useLatest";
import useUnmount from "./useUnmount";

type noop = (...args: any[]) => any;

function useDebounceFn<T extends noop>(
  fn: T,
  wait = 1000,
  { cancelOnUnmount = true },
) {
  const fnRef = useLatest(fn);

  const debounced = useMemo(
    () =>
      debounce((...args: Parameters<T>): ReturnType<T> => {
        return fnRef.current(...args);
      }, wait),
    [fnRef, wait],
  );

  useUnmount(() => {
    cancelOnUnmount && debounced.cancel();
  });

  return {
    run: debounced as unknown as T,
    cancel: debounced.cancel,
    flush: debounced.flush,
  };
}

export default useDebounceFn;
