import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { formatValue, isHoliday } from "@/pages/properateCalendar/utils";
import {
  CalendarEvents,
  ColoredProperateCalendar,
  Holiday,
  ProperateCalendarEvent,
} from "@/utils/types";
import {
  CalenderColorBox,
  StyledModal,
  TitleButtons,
} from "./components/elements";

type Props = {
  date: Date | null;
  schedule: CalendarEvents;
  installationMap: Record<string, ColoredProperateCalendar>;
  hide: () => void;
};

const getEventsInDay = (
  allEvents: (ProperateCalendarEvent | Holiday)[],
  day: Date,
) => {
  const endOfDay = dayjs(day).tz("Europe/Oslo").endOf("day").toDate();
  return allEvents.filter((event) =>
    isHoliday(event)
      ? event.date.getTime() >= day.getTime() &&
        event.date.getTime() < endOfDay.getTime()
      : event.start.getTime() >= day.getTime() &&
        event.start.getTime() < endOfDay.getTime(),
  );
};

export const DaySummaryModal = ({
  date,
  schedule,
  hide,
  installationMap,
}: Props) => {
  const t = useTranslations();

  const eventsInDay = date
    ? getEventsInDay(Object.values(schedule).flat(), date)
    : [];

  return (
    <StyledModal
      closable={false}
      open={!!date}
      onCancel={hide}
      title={
        <div style={{ textAlign: "right" }}>
          <TitleButtons
            type="link"
            icon={<CloseOutlined />}
            onClick={() => hide()}
          />
        </div>
      }
      footer={<Button onClick={hide}>{t("calendar.cancel")}</Button>}
    >
      <h1>{dayjs(date).tz("Europe/Oslo").format("dddd DD. MMMM")}</h1>
      {eventsInDay.length === 0 && <p>{t("calendar.no-events-today")}</p>}
      {eventsInDay
        .filter((e) => isHoliday(e))
        .map((event) => (
          <p key={(event as Holiday).name}>
            <CalenderColorBox color={installationMap["holiday"].color} />{" "}
            {(event as Holiday).name}
          </p>
        ))}
      {eventsInDay
        .filter((e) => !isHoliday(e))
        .map((event, index) => (
          <p key={`${index}`}>
            <CalenderColorBox
              color={installationMap[event.calendar_id].color}
            />
            {isHoliday(event)
              ? dayjs(event.date).tz("Europe/Oslo").format("HH:mm")
              : dayjs(event.start).tz("Europe/Oslo").format("HH:mm")}
            {" - "}
            {!isHoliday(event) &&
              dayjs(event.end).tz("Europe/Oslo").format("HH:mm")}{" "}
            {!isHoliday(event) && formatValue(event.value)}
            <br />
            {!isHoliday(event) &&
              `${installationMap[event.calendar_id].system} ${
                installationMap[event.calendar_id].name
              } ${installationMap[event.calendar_id].description}`}
          </p>
        ))}
    </StyledModal>
  );
};
