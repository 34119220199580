import { browserFirestore } from "@properate/firebase";
import { collection, doc } from "firebase/firestore";
import { useMemo } from "react";
import {
  useGetFirestoreCollection,
  useGetFirestoreDocument,
} from "@properate/ui";

export type KPITypeToValues = {
  energy?: Record<string, number>;
  water?: Record<string, number>;
  indoor_climate?: Record<string, number>;
  weather?: Record<string, number>;
  activity?: Record<string, number>;
};

const kpiCollection = collection(browserFirestore, "kpi");

export function useKPIsByBuilding(buildingId: number) {
  const { data } = useGetFirestoreDocument<KPITypeToValues>(
    useMemo(() => doc(kpiCollection, buildingId.toString()), [buildingId]),
  );

  return data;
}

export function useKPIs() {
  const { data } = useGetFirestoreCollection<KPITypeToValues>(kpiCollection);

  return data;
}
