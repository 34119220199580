export const dashboardStandardWidgets = [
  "energyUseThisWeek",
  "energyUseThisWeekTempCorrectedGraddag",
  "energyUseThisWeekTempCorrectedEtmod",
  "energyUseThisWeekEpred",
  "sankeyDiagram",
  "consumptionLast7Days",
  "weather",
  "accumulatedEnergyUse",
  "accumulatedEnergyUseTempCorrectedGraddag",
  "accumulatedEnergyUseTempCorrectedEtmod",
  "alarms",
  "indoorClimate",
  "contacts",
  "certifications",
  "tenants",
  "solarCellProduction",
  "spotPrice",
  "notes",
] as const;
