import { Button, Space } from "antd";
import { ReactNode } from "react";
import { useTranslations } from "@properate/translations";

interface Props {
  okButton: ReactNode;
  isRemoveVisible: boolean;
  onClickRemove: () => unknown;
}

export const TimeseriesSelectionModalFooter = ({
  okButton,
  isRemoveVisible,
  onClickRemove,
}: Props) => {
  const t = useTranslations();
  return (
    <Space>
      {isRemoveVisible && (
        <Button danger onClick={onClickRemove}>
          {t("common.timeseries-modal.remove-timeseries")}
        </Button>
      )}
      {okButton}
    </Space>
  );
};
