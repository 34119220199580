import { BuildingTenantWithId } from "@properate/common";
import dayjs from "@properate/dayjs";
import { useState } from "react";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { UserSettings } from "@/utils/types";
import { DATE_FORMAT } from "../BuildingTenantsTableDateColumn";
import { isActive } from "../utils/expiration";

export const filters = [
  {
    label: "current-tenants",
    check: isActive,
  },
] as const;

function filterTenants(
  tenants: BuildingTenantWithId[],
  search: string,
  filterLabel: string,
) {
  return tenants.filter((tenant) => {
    if (search) {
      const searchTerm = search.toLowerCase();

      const hasSearchTerm =
        tenant.businessName.toLowerCase().includes(searchTerm) ||
        tenant.contactName.toLowerCase().includes(searchTerm) ||
        tenant.email?.toLowerCase().includes(searchTerm) ||
        tenant.phone?.toLowerCase().includes(searchTerm) ||
        tenant.note?.toLowerCase().includes(searchTerm) ||
        tenant.area?.toLowerCase().includes(searchTerm) ||
        dayjs(tenant.tenancyEnd).format(DATE_FORMAT).includes(searchTerm) ||
        dayjs(tenant.tenancyStart).format(DATE_FORMAT).includes(searchTerm);

      if (!hasSearchTerm) {
        return false;
      }
    }

    const filter = filters.find((filter) => filter.label === filterLabel);

    return filter ? filter.check(tenant) : true;
  });
}

const allowFilters: UserSettings["filters"]["tenantsSieve"][] = [
  "current-tenants",
  "tenant-history",
];

export function useTenantsSieve(tenants: BuildingTenantWithId[]) {
  const [search, setSearch] = useState("");
  const { data } = useUserSettings();
  const filter = allowFilters.includes(data?.filters?.tenantsSieve)
    ? data?.filters?.tenantsSieve
    : "current-tenants";

  return {
    search,
    setSearch,
    filter,
    setFilter: (label: UserSettings["filters"]["tenantsSieve"]) =>
      mutateUserSettings({
        filters: {
          tenantsSieve: label,
        },
      }),
    filteredTenants: filterTenants(tenants, search, filter),
  };
}
