import { Col, Space } from "antd";
import { ReactNode } from "react";
import { ColorCheckbox, HighlightRow } from "./elements";
import { ColorSelector } from "./ColorSelector";

type Params = {
  color: string;
  onChangeColor: (color: string) => void;
  children?: ReactNode;
  checkValue: string;
};

const HolidayCalendarInfo = ({
  color,
  children,
  onChangeColor,
  checkValue,
}: Params) => {
  return (
    <HighlightRow>
      <Col flex="auto">
        <ColorCheckbox color={color} value={checkValue}>
          {" "}
          {children}
        </ColorCheckbox>
      </Col>
      <Space className="showOnHover">
        <ColorSelector color={color} onChangeColor={onChangeColor} />
      </Space>
    </HighlightRow>
  );
};

export default HolidayCalendarInfo;
