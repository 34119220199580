import { Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useAnalysisTypeRoute } from "../../hooks";

interface Props {
  onPressCreateSimple: () => unknown;
  onPressCreateScatterplot: () => unknown;
}

export function AnalysisListHeader({
  onPressCreateSimple,
  onPressCreateScatterplot,
}: Props) {
  const type = useAnalysisTypeRoute();
  const t = useTranslations();

  return (
    <PageHeader
      title={
        type === "simple"
          ? t("analysis.timeseries-graph.title")
          : t("analysis.xy-graph.title")
      }
      extra={
        <>
          {type === "simple" && (
            <Button
              data-step="new-simple-analysis"
              type="primary"
              icon={<PlusOutlined />}
              onClick={onPressCreateSimple}
              data-testid="new-analysis-graph"
            >
              {t("analysis.new")}
            </Button>
          )}
          {type === "scatterplot" && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={onPressCreateScatterplot}
              data-testid="new-analysis-graph"
            >
              {t("analysis.new")}
            </Button>
          )}
        </>
      }
    />
  );
}
