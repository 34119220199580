import { formatSubBuildingFromExternalId } from "@properate/common";
import useSWR from "swr";
import { CogniteClient } from "@cognite/sdk";
import { getAssetsWithLabels } from "@/utils/helpers";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useCogniteClient } from "@/context/CogniteClientContext";

type getSubBuildingsProps = {
  currentBuildingId: number;
  client: CogniteClient;
};

export async function getSubBuildings({
  currentBuildingId,
  client,
}: getSubBuildingsProps) {
  return await getAssetsWithLabels(client, currentBuildingId, ["building"]);
}

export async function getNameAndIdForSubBuildings({
  currentBuildingId,
  client,
}: getSubBuildingsProps) {
  const subBuildings = await getSubBuildings({ currentBuildingId, client });
  return subBuildings.map((subBuilding) => ({
    name: formatSubBuildingFromExternalId(subBuilding.externalId!),
    id: subBuilding.id,
  }));
}

export function useSubBuildingsNameAndId() {
  const currentBuildingId = useCurrentBuildingId();
  const { client } = useCogniteClient();

  const { data: subBuildings, ...rest } = useSWR(
    ["useSubBuildingsNameAndId", currentBuildingId],
    ([_, buildingId]) => {
      return getNameAndIdForSubBuildings({
        currentBuildingId: buildingId,
        client,
      });
    },
  );

  return { subBuildings, currentBuildingId, ...rest };
}
