import { Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";

interface Props {
  onClickNew: () => unknown;
}

export function HeatMapListHeader({ onClickNew }: Props) {
  const t = useTranslations();

  return (
    <PageHeader
      title={t("analysis.heat-map.name")}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={onClickNew}
          data-testid="new-heatmap-graph"
        >
          {t("analysis.heat-map.new")}
        </Button>
      }
    />
  );
}
