export const gaugeViewModeOptions = [
  {
    labelKey: "bar",
    value: "bar",
  },
  {
    labelKey: "circle",
    value: "light",
  },
  {
    labelKey: "number",
    value: "number",
  },
  {
    labelKey: "speedometer",
    value: "speedometer",
  },
];

export const GAUGE_VIEW_MODE_KEY_TEMPORARY = "temporary";

function getGaugeViewModeKey(gaugeViewModeKeyUnique: string) {
  return `gauge.viewMode.${gaugeViewModeKeyUnique}`;
}

export function removeGaugeViewMode(guageViewModeKeyUnique: string) {
  localStorage.removeItem(getGaugeViewModeKey(guageViewModeKeyUnique));
}
