import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";

export function RequiresAdminRole(props: { children: React.ReactNode }) {
  const t = useTranslations();
  const user = useUser();

  return user.isAdmin ? (
    <>{props.children}</>
  ) : (
    <div>{t("pages.notAllowed")}</div>
  );
}
export function RequiresSuperAdminRole(props: { children: React.ReactNode }) {
  const t = useTranslations();

  const user = useUser();

  return user.isSuperAdmin ? (
    <>{props.children}</>
  ) : (
    <div>{t("pages.notAllowed")}</div>
  );
}
