import { browserFirestore } from "@properate/firebase";
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  deleteDoc,
  setDoc,
  SetOptions,
} from "firebase/firestore";
import { HeatMap } from "@/features/heatmap";

const collectionHeatMap = collection(
  browserFirestore,
  "heatMap",
) as CollectionReference<HeatMap>;

export async function createHeatMap(heatMap: HeatMap) {
  return addDoc(collectionHeatMap, heatMap);
}

export async function updateHeatMap(
  snapshotId: string,
  heatMap: Partial<HeatMap>,
  setOptions: SetOptions = {},
) {
  return setDoc(doc(collectionHeatMap, snapshotId), heatMap, setOptions);
}

export async function deleteHeatMap(snapshotId: string) {
  return deleteDoc(doc(collectionHeatMap, snapshotId));
}
