import { Button } from "antd";
import React, { SetStateAction, useCallback, useEffect } from "react";
import {
  SidebarActionType,
  useSidebarActions,
  useSidebarData,
} from "@properate/ui";
import { useTranslations } from "@properate/translations";
import dayjs from "@properate/dayjs";
import { Granularity } from "@/utils/helpers";
import {
  NotesSidebarValues,
  NotesSidebarViewState,
  SelectedNodeForPrefilledNote,
  NoteCreationModeRangeContent,
} from "@/features/notes";

type Props<T> = {
  setSelectedPoints: React.Dispatch<
    SetStateAction<(SelectedNodeForPrefilledNote & T)[] | null>
  >;
  selectedPoints: (SelectedNodeForPrefilledNote & T)[] | null;
  assetListIds?: number[];
  granularity: Granularity;
  startDate: Date;
  endDate: Date;
  DefaultCustomContent: React.FC;
};

export function NoteCreationModeButton<T>({
  setSelectedPoints,
  selectedPoints,
  assetListIds,
  granularity,
  startDate,
  endDate,
  DefaultCustomContent,
}: Props<T>) {
  const t = useTranslations();

  const sidebarDispatch = useSidebarActions();
  const { viewState } = useSidebarData<NotesSidebarValues>();

  const setDefaultSidebarView = useCallback(() => {
    sidebarDispatch({
      type: SidebarActionType.mergeData,
      data: {
        viewState: NotesSidebarViewState.list,
        customContent: undefined,
      },
    });
  }, [sidebarDispatch]);

  useEffect(() => {
    return setDefaultSidebarView;
  }, [setDefaultSidebarView]);

  useEffect(() => {
    if (
      viewState !== NotesSidebarViewState.customContent &&
      viewState !== NotesSidebarViewState.create
    ) {
      setSelectedPoints(null);
    }
  }, [setSelectedPoints, viewState]);

  useEffect(() => {
    if (
      selectedPoints?.length &&
      viewState === NotesSidebarViewState.customContent
    ) {
      const startTimestamp = selectedPoints[0]?.timestamp;
      const endTimestamp = selectedPoints[1]?.timestamp;

      const startDate = dayjs(startTimestamp).format("YYYY-MM-DD HH:mm");
      const endDate =
        !!endTimestamp && dayjs(endTimestamp).format("YYYY-MM-DD HH:mm");

      const assetListIds = selectedPoints.flatMap(({ assetIds }) => assetIds);
      const assetIdsFromSelectedPoints = [...new Set(assetListIds)];

      const data = {
        viewState: NotesSidebarViewState.create,
        note: {
          assetIds: assetListIds || assetIdsFromSelectedPoints,
          startTime: startTimestamp,
          endTime: endTimestamp,
          dataSetId: selectedPoints[0]?.dataSetId,
        },
        customContent: undefined,
      };

      const onClick = () => {
        sidebarDispatch({
          type: SidebarActionType.mergeData,
          data,
        });
      };

      sidebarDispatch({
        type: SidebarActionType.mergeData,
        data: {
          viewState: NotesSidebarViewState.customContent,
          customContent: (
            <NoteCreationModeRangeContent
              startDate={startDate}
              endDate={endDate}
              onClick={onClick}
            />
          ),
        },
      });
    }
  }, [selectedPoints, viewState, t, assetListIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const setEmptyCustomSidebar = useCallback(() => {
    sidebarDispatch({
      type: SidebarActionType.mergeData,
      data: {
        viewState: NotesSidebarViewState.customContent,
        customContent: <DefaultCustomContent />,
      },
    });
  }, [sidebarDispatch, DefaultCustomContent]);

  useEffect(() => {
    if (selectedPoints?.length) {
      setSelectedPoints([]);
      setEmptyCustomSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    granularity,
    startDate,
    endDate,
    setSelectedPoints,
    setEmptyCustomSidebar,
  ]);

  const onClick = useCallback(() => {
    if (viewState !== NotesSidebarViewState.customContent) {
      setSelectedPoints([]);

      setEmptyCustomSidebar();

      sidebarDispatch({
        type: SidebarActionType.open,
      });
    } else {
      setSelectedPoints(null);

      setDefaultSidebarView();
    }
  }, [setSelectedPoints, setEmptyCustomSidebar, viewState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Button onClick={onClick}>
        {viewState === NotesSidebarViewState.customContent
          ? t("notes.selection-mode.cancel-note-creation")
          : t("notes.selection-mode.note-creation-mode")}
      </Button>
    </div>
  );
}
