import { DatapointAggregates } from "@cognite/sdk";
import { useGetTimeseriesListWithData } from "@/hooks/useGetTimeseriesListWithData";
import {
  combineTimeseriesDatapoints,
  getGranularityFromPeriod,
  getStartTimestampFromPeriod,
} from "../utils";
import { SolarCellProductionPeriod } from "../types";

export function useCombinedDatapoints(
  timeseriesIds: number[],
  period: SolarCellProductionPeriod,
) {
  const granularity = getGranularityFromPeriod(period);
  const { timeseriesListWithData, isLoading } =
    useGetTimeseriesListWithData<DatapointAggregates>({
      items: timeseriesIds.map((id) => ({ id })),
      aggregates: ["sum"],
      start: getStartTimestampFromPeriod(period),
      end: "now",
      granularity,
    });

  const combinedDatapoints = combineTimeseriesDatapoints(
    timeseriesListWithData,
  );

  return {
    datapoints: combinedDatapoints,
    isLoading,
  };
}
