import { CenteredSpinner } from "@properate/ui";
import { Form, Select } from "antd";
import { AlarmRule } from "@properate/common";
import { FormValues } from "./utils";

type Props = {
  alarms: {
    data: AlarmRule[];
    isLoading: boolean;
    error: string | Error | null;
  };
};

export function AlarmsSelector({ alarms }: Props) {
  return alarms.isLoading ? (
    <CenteredSpinner />
  ) : (
    <Form.Item<FormValues> name={["alarm_rule_ids"]}>
      <Select
        optionFilterProp="label"
        mode="multiple"
        allowClear
        className="w-full ml-16"
        loading={alarms.isLoading}
        options={
          alarms.data?.map((alarm) => ({
            label: alarm.name,
            value: alarm.alarm_id,
          })) || []
        }
      />
    </Form.Item>
  );
}
