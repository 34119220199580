import { useTranslations, MessageKey } from "@properate/translations";
import { getKPIConfiguration } from "@/utils/kpi";
import { KPI } from "@/utils/types";

export const RenderKpiTitle = ({ kpi }: { kpi: KPI }) => {
  const configuration = getKPIConfiguration(kpi);
  const t = useTranslations();

  if ("metadata" in configuration && configuration.metadata.unit) {
    return (
      <span>
        {`${t(
          `portfolio-view.kpi.labels.${configuration.asOption.label}` as MessageKey,
        )} [${configuration.metadata.unit}]`}
      </span>
    );
  }

  return (
    <span>
      {t(
        `portfolio-view.kpi.labels.${configuration.asOption.label}` as MessageKey,
      )}
    </span>
  );
};
