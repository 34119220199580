import { TableColumnsType } from "antd";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { AnalysisCreateRedirectButton } from "@/components/AnalysisCreateRedirectButton/AnalysisCreateRedirectButton";
import { AnomalyAlgorithm, AnomalySystemCollection } from "../types";

export const expandedRowRender = (
  userEmail: string,
  buildingId: number,
  record: AnomalySystemCollection,
  t: any, //ReturnType<typeof useTranslations> // ReturnType does not work as expected.
) => {
  const columns: TableColumnsType<AnomalyAlgorithm> = [
    {
      key: "algorithm",
      title: t("anomaly.table.algorithm"),
      render: (_, { anomalyProperty }) => <>{anomalyProperty.algorithm}</>,
    },
    {
      title: t("anomaly.table.affected_timeseries"),
      key: "auxiliaryTimeSeries",
      render: (_, { auxiliaryTimeSeries }) => (
        <>
          {auxiliaryTimeSeries
            .map(
              (ts) => ts.externalId?.substring(ts.externalId?.indexOf("-") + 1),
            )
            .join(", ")}
        </>
      ),
    },
    {
      title: t("anomaly.table.analysis"),
      key: "analysis",
      render: ({ auxiliaryTimeSeries }) => (
        <AnalysisCreateRedirectButton
          buildingId={buildingId}
          timeseries={record.healthTimeseries}
          auxiliaryTimeseries={auxiliaryTimeSeries}
          userEmail={userEmail}
          colors={["red", "blue", "green"]}
        />
      ),
    },
  ];

  return (
    <TableWithoutDefaultSort
      rowKey={(anomalyProperty) => anomalyProperty.anomalyProperty.key}
      columns={columns}
      dataSource={record.anomalyAlgorithms}
      pagination={false}
    />
  );
};
