import styled from "styled-components";
import { Form } from "antd";

export const TimeseriesDetailsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TimeseriesSwitchContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const FormItemTitle = styled(Form.Item)`
  margin: 0;
  width: 300px;
`;
