import { CogniteClient, Timeseries } from "@cognite/sdk";
import { getSystemCodeFromExternalId } from "@properate/common";
import { Col, Row, Select, Tree } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslations } from "@properate/translations";
import { getBuildingAssets } from "@/utils/helpers";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { TreeContainer } from "./elements";
import { getTimeseriesForAssets } from "./utils";
import { Node } from "./types";

type Props = {
  selectedHierarchy: number[];
  onSelectEnergyHierarchy: (selectedHierarchy: number[]) => void;
  showExternalId: boolean;
  selectedOwnerId: number;
  onSelectOwnerId: (selectedOwnerId: number) => void;
};
export const SelectEnergyHierarchy = ({
  selectedHierarchy,
  onSelectEnergyHierarchy,
  showExternalId,
  selectedOwnerId,
  onSelectOwnerId,
}: Props) => {
  const t = useTranslations();

  const { client } = useCogniteClient();
  const building = useCurrentBuilding();

  const [energyHierarchy, setEnergyHierarchy] = useState<Node[]>();

  const getOwnerName = useMemo(
    () => (index: number) => {
      if (!energyHierarchy || !energyHierarchy[index]) {
        return "";
      }
      return t("energy.owner-name", {
        name: energyHierarchy[index].node.description,
        index,
      });
    },
    [energyHierarchy, t],
  );

  useEffect(() => {
    const load = async (client: CogniteClient) => {
      const mainMeterAssetList = (
        await getBuildingAssets({
          client,
          id: building.id,
          labels: ["oe_common_main_meter"],
        })
      ).sort((a, b) =>
        getSystemCodeFromExternalId(a.externalId!).localeCompare(
          getSystemCodeFromExternalId(b.externalId!),
        ),
      );

      const subSerialMeterAssetList = await Promise.all(
        mainMeterAssetList.map(async (meter) =>
          (
            await getBuildingAssets({
              client,
              id: building.id,
              labels: ["oe_common_sub_serial_meter"],
              metadata: { organization: meter.metadata!.organization },
            })
          ).sort((a, b) =>
            getSystemCodeFromExternalId(a.externalId!).localeCompare(
              b.externalId!,
            ),
          ),
        ),
      );

      const serialMeterAssetList = await Promise.all(
        mainMeterAssetList.map(async (meter) =>
          (
            await getBuildingAssets({
              client,
              id: building.id,
              labels: ["oe_common_serial_meter"],
              metadata: { organization: meter.metadata!.organization },
            })
          ).sort((a, b) =>
            getSystemCodeFromExternalId(a.externalId!).localeCompare(
              b.externalId!,
            ),
          ),
        ),
      );

      const energyHierarchyTimeseriesList = await Promise.all(
        mainMeterAssetList.map(async (meter, index) =>
          (
            await getTimeseriesForAssets(client, [
              meter.id,
              ...subSerialMeterAssetList[index].map((asset) => asset.id),
              ...serialMeterAssetList[index].map((asset) => asset.id),
            ])
          ).reduce<Record<number, Timeseries>>(
            (acc, ts) => ({ ...acc, [ts.assetId!]: ts }),
            {},
          ),
        ),
      );

      const isDescendant = (
        nodeExternalId: string,
        subNodeExternalId: string,
      ) => {
        const nodeCategory = getSystemCodeFromExternalId(nodeExternalId);
        const nodeSubCategory = getSystemCodeFromExternalId(subNodeExternalId);
        return nodeSubCategory.startsWith(nodeCategory.substring(0, 1));
      };

      const roots: Node[] = mainMeterAssetList.map(
        (meter, index) =>
          ({
            node: {
              ...energyHierarchyTimeseriesList[index][meter.id],
              description:
                energyHierarchyTimeseriesList[index][meter.id].metadata
                  ?.organization ||
                energyHierarchyTimeseriesList[index][meter.id].description,
            },
            children: serialMeterAssetList[index].map((node) => ({
              node: energyHierarchyTimeseriesList[index][node.id]!,
              children: subSerialMeterAssetList[index]
                .filter((subNode) =>
                  isDescendant(node.externalId!, subNode.externalId!),
                )
                .map((node) => ({
                  node: energyHierarchyTimeseriesList[index][node.id]!,
                  children: [],
                })),
            })),
          }) as Node,
      );
      setEnergyHierarchy(roots);
    };

    if (client) {
      load(client);
    }
  }, [client, building.id]);

  return (
    <Row>
      <Col span={24}>
        <h3>{getOwnerName(selectedOwnerId)}</h3>
        {energyHierarchy && (
          <Select
            style={{ width: 300 }}
            value={selectedOwnerId}
            options={energyHierarchy.map((energy, index) => ({
              label: t("energy.owner-name", {
                name: energyHierarchy[index].node.description,
                index,
              }),
              value: energy.node.assetId,
            }))}
            onChange={(value) => {
              onSelectOwnerId(value);
              onSelectEnergyHierarchy([]);
            }}
          />
        )}
        <TreeContainer>
          {energyHierarchy &&
            energyHierarchy.map((energy, index) => (
              <Tree
                style={{
                  display:
                    energy.node.assetId === selectedOwnerId ? "block" : "none",
                }}
                key={index}
                checkable
                defaultExpandAll
                checkedKeys={
                  energy.node.assetId === selectedOwnerId
                    ? selectedHierarchy
                    : []
                }
                onCheck={(checkedKeys) => {
                  onSelectEnergyHierarchy(
                    (checkedKeys as number[]).filter((key) => key > 0),
                  );
                }}
                expandedKeys={[
                  -energyHierarchy[index].node.id,
                  ...energyHierarchy[index].children.map(
                    (child) => -child.node.id!,
                  ),
                ]}
                switcherIcon={<></>}
                treeData={[
                  {
                    title:
                      t("energy.owner-name", {
                        name: energyHierarchy[index].node.description,
                        index,
                      }) +
                      (showExternalId
                        ? ` ${energyHierarchy[index].node.externalId}`
                        : ""),
                    key: -energyHierarchy[index].node.id,
                    children: energyHierarchy[index].children.map((child) => ({
                      title:
                        child.node.description +
                        (showExternalId
                          ? ` ${energyHierarchy[index].node.externalId}`
                          : ""),
                      key: -child.node.id,
                      children: child.children.map((grandChild) => ({
                        title:
                          grandChild.node.description +
                          (showExternalId
                            ? ` ${energyHierarchy[index].node.externalId}`
                            : ""),
                        key: grandChild.node.id,
                      })),
                    })),
                  },
                ]}
              />
            ))}
        </TreeContainer>
      </Col>
    </Row>
  );
};
