import { Input, Button, Form } from "antd";
import { useTranslations } from "@properate/translations";

interface Props {
  value: string;
  onChange: (value: string) => unknown;
}

export function HeatMapName({ value, onChange }: Props) {
  const t = useTranslations();
  return (
    <Form layout="inline" onFinish={({ name }) => onChange(name)}>
      <Form.Item name="name" initialValue={value}>
        <Input id="name" value={value} style={{ minWidth: 450 }} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t("analysis.heat-map.ok")}
        </Button>
      </Form.Item>
    </Form>
  );
}
