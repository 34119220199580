import styled from "styled-components";
import { MaximizableModal } from "@/components/MaximizableModal/MaximizableModal";

export const WhiteModal = styled(MaximizableModal)`
  .ant-modal-content,
  .ant-modal-header,
  .ant-modal-footer {
    background-color: ${(props) => props.theme.background2} !important;
  }
`;
