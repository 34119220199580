import { useEffect, useState } from "react";
import { Modal } from "antd";
import { useTranslations } from "@properate/translations";
import { TimeseriesUnitSelect } from "@/components/TimeseriesUnitSelect/TimeseriesUnitSelect";
import { validateMathExpressionForDatapoint } from "@/utils/math";
import { DatapointCalculatorSwitch } from "@/components/DatapointCalculator/DatapointCalculatorSwitch";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { SettingsTimeseries, ValueLimit } from "../../types";
import { getTimeseriesTitle } from "../../utils";
import { TimeseriesValueLimit } from "./TimeseriesValueLimit";
import {
  DatapointCalculatorFullWidth,
  ErrorMessage,
  ModalContent,
  ModalSection,
  ModalSubtitle,
} from "./elements";
import { TimeseriesDetails } from "./TimeseriesDetails";
import { TimeseriesAggregate } from "./TimeseriesAggregate";
import type { Aggregate, Timeseries } from "@cognite/sdk";

interface Props {
  timeseries: Timeseries | null;
  open: boolean;
  settingsTimeseries: SettingsTimeseries | null;
  onCancel: () => unknown;
  onOk: (value: SettingsTimeseries) => unknown;
}

export const ModalTimeseries = ({
  timeseries,
  open,
  settingsTimeseries,
  onCancel,
  onOk,
}: Props) => {
  const t = useTranslations();
  const currentBuilding = useCurrentBuilding();
  const { handleOverrideUnit } = useTimeseriesSettings(currentBuilding.id);
  // settingsTimeseries will initially be null
  const [unitSelected, setUnitSelected] = useState<string | undefined>(
    undefined,
  );
  const [aggregate, setAggregate] = useState<Aggregate | undefined>(undefined);
  const [valueLimit, setValueLimit] = useState<ValueLimit | undefined>(
    undefined,
  );
  const [useMathExpression, setUseMathExpression] = useState(false);
  const [mathExpression, setMathExpression] = useState<string | undefined>(
    undefined,
  );
  const [mathExpressionError, setMathExpressionError] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (settingsTimeseries) {
      setUnitSelected(settingsTimeseries.unitSelected);
      setValueLimit(settingsTimeseries.valueLimit);
      setMathExpression(settingsTimeseries.mathExpression);
      if ("aggregate" in settingsTimeseries) {
        setAggregate(settingsTimeseries.aggregate);
      }
    }
  }, [settingsTimeseries]);

  function handleChangeAggregate(aggregate: Aggregate) {
    setAggregate(aggregate);
    // the value limit that was set will often not make sense for a different aggregate
    setValueLimit(undefined);
  }

  function handleChangeUnitSelected(unitSelected: string) {
    setUnitSelected(unitSelected);
    // the value limit that was set will often not make sense for a different unit
    setValueLimit(undefined);
  }

  function handleOk() {
    if (typeof mathExpression === "string") {
      const { valid, error } =
        validateMathExpressionForDatapoint(mathExpression);
      if (!valid) {
        if (error) {
          setMathExpressionError(
            t(`analysis.invalid-formula-error`, { error }),
          );
        } else {
          setMathExpressionError(t(`analysis.invalid-formula`));
        }
        return;
      }
    }
    setMathExpressionError(null);
    const externalId = timeseries && timeseries.externalId;
    const oldUnit =
      (settingsTimeseries && settingsTimeseries.unitSelected) || "";

    if (externalId && unitSelected) {
      handleOverrideUnit(currentBuilding.id, externalId, unitSelected, oldUnit);
    }
    onOk({
      ...settingsTimeseries!,
      valueLimit,
      mathExpression,
      ...(aggregate !== undefined ? { aggregate } : null),
    });
  }

  function handleCancel() {
    setMathExpressionError(null);
    onCancel();
  }

  function handleChangeUseMathExpression(value: boolean) {
    setUseMathExpression(value);
    if (!value) {
      setMathExpression(undefined);
    }
  }

  return (
    <Modal
      title={timeseries ? <h3>{getTimeseriesTitle(timeseries)}</h3> : null}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
      width={800}
    >
      <ModalContent>
        {settingsTimeseries && (
          <ModalSection>
            <ModalSubtitle>
              {t("analysis.details.timeseries-modal.settings")}
            </ModalSubtitle>
            {timeseries?.unit !== undefined && unitSelected !== undefined && (
              <TimeseriesUnitSelect
                value={unitSelected}
                onChange={handleChangeUnitSelected}
                unitOriginal={timeseries.unit}
              />
            )}
            {"aggregate" in settingsTimeseries && aggregate !== undefined && (
              <TimeseriesAggregate
                value={aggregate}
                onChange={handleChangeAggregate}
              />
            )}
            <TimeseriesValueLimit
              valueLimit={valueLimit}
              onChangeValueLimit={setValueLimit}
            />
            <div>
              {t("analysis.details.timeseries-modal.formula-to-execute")}
            </div>
          </ModalSection>
        )}
        {timeseries && (
          <ModalSection>
            <ModalSubtitle>
              {t("analysis.details.timeseries-modal.details")}
            </ModalSubtitle>
            <TimeseriesDetails timeseries={timeseries} />
          </ModalSection>
        )}
        {mathExpressionError !== null && (
          <ErrorMessage>{mathExpressionError}</ErrorMessage>
        )}
        <DatapointCalculatorSwitch
          value={useMathExpression}
          onChange={handleChangeUseMathExpression}
        />
        {useMathExpression && (
          <DatapointCalculatorFullWidth
            value={mathExpression}
            onChange={setMathExpression}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
