import { useTranslations } from "@properate/translations";
import { ETaskPriority } from "../../schemas";

export function useTaskPriorityTranslations() {
  const t = useTranslations();

  const record: Record<ETaskPriority, string> = {
    High: t("task.enum.priority.High"),
    Medium: t("task.enum.priority.Medium"),
    Low: t("task.enum.priority.Low"),
  };

  const keys = Object.keys(record) as ETaskPriority[];

  return {
    record,
    keys,
  };
}
