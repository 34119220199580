import { Select } from "antd";
import { convertHoursToMS, convertMSToHours } from "@properate/common";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { AlarmInputHint, AlarmSubtitle } from "./elements";

interface Props {
  value: number;
  onChange: (value: number) => unknown;
}

export const AlarmMaxTimeSinceLatestDatapoint = ({
  value,
  onChange,
}: Props) => {
  const t = useTranslations();
  const user = useUser();
  const valueInHours = convertMSToHours(value);
  return (
    <>
      <AlarmSubtitle>
        {t(
          "common.timeseries-graph-modal.alarm-fields.period-without-data.period",
        )}
      </AlarmSubtitle>
      <Select
        disabled={user.isViewer}
        style={{ minWidth: 150 }}
        value={value}
        onChange={onChange}
      >
        <Select.Option value={convertHoursToMS(1)}>
          {t(
            "common.timeseries-graph-modal.alarm-fields.period-without-data.1-hour",
          )}
        </Select.Option>
        <Select.Option value={convertHoursToMS(2)}>
          {t(
            "common.timeseries-graph-modal.alarm-fields.period-without-data.2-hours",
          )}
        </Select.Option>
        <Select.Option value={convertHoursToMS(3)}>
          {t(
            "common.timeseries-graph-modal.alarm-fields.period-without-data.3-hours",
          )}
        </Select.Option>
        <Select.Option value={convertHoursToMS(4)}>
          {t(
            "common.timeseries-graph-modal.alarm-fields.period-without-data.4-hours",
          )}
        </Select.Option>
        <Select.Option value={convertHoursToMS(5)}>
          {t(
            "common.timeseries-graph-modal.alarm-fields.period-without-data.5-hours",
          )}
        </Select.Option>
        <Select.Option value={convertHoursToMS(6)}>
          {t(
            "common.timeseries-graph-modal.alarm-fields.period-without-data.6-hours",
          )}
        </Select.Option>
        <Select.Option value={convertHoursToMS(12)}>
          {t(
            "common.timeseries-graph-modal.alarm-fields.period-without-data.12-hours",
          )}
        </Select.Option>
        <Select.Option value={convertHoursToMS(24)}>
          {t(
            "common.timeseries-graph-modal.alarm-fields.period-without-data.24-hours",
          )}
        </Select.Option>
        <Select.Option value={convertHoursToMS(48)}>
          {t(
            "common.timeseries-graph-modal.alarm-fields.period-without-data.48-hours",
          )}
        </Select.Option>
      </Select>
      <AlarmInputHint>
        {t(
          "common.timeseries-graph-modal.alarm-fields.period-without-data.trigger",
          {
            period: valueInHours,
          },
        )}
      </AlarmInputHint>
    </>
  );
};
