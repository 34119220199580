import { Space, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { AlarmSubtitle } from "./elements";

interface Props {
  value: number;
  onChange: (value: number) => unknown;
}

export const AlarmDelay = ({ value, onChange }: Props) => {
  const t = useTranslations();
  const user = useUser();
  return (
    <>
      <AlarmSubtitle>
        {t("common.timeseries-graph-modal.alarm-fields.response-delay")}
      </AlarmSubtitle>
      <Space>
        <Select
          disabled={user.isViewer}
          style={{ minWidth: 150 }}
          value={value}
          onChange={onChange}
        >
          <Select.Option value={0}>
            {t(
              "common.timeseries-graph-modal.alarm-fields.delay.last-measurement-point",
            )}
          </Select.Option>
          <Select.Option value={5}>
            {t("common.timeseries-graph-modal.alarm-fields.delay.5-minutes")}
          </Select.Option>
          <Select.Option value={10}>
            {t("common.timeseries-graph-modal.alarm-fields.delay.10-minutes")}
          </Select.Option>
          <Select.Option value={15}>
            {t("common.timeseries-graph-modal.alarm-fields.delay.15-minutes")}
          </Select.Option>
          <Select.Option value={30}>
            {t("common.timeseries-graph-modal.alarm-fields.delay.30-minutes")}
          </Select.Option>
          <Select.Option value={60}>
            {t("common.timeseries-graph-modal.alarm-fields.delay.1-hour")}
          </Select.Option>
          <Select.Option value={120}>
            {t("common.timeseries-graph-modal.alarm-fields.delay.2-hours")}
          </Select.Option>
        </Select>
        <Tooltip
          title={t("common.timeseries-graph-modal.alarm-fields.delay.hint")}
        >
          <InfoCircleOutlined style={{ fontSize: 18 }} />
        </Tooltip>
      </Space>
    </>
  );
};
