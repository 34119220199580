import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { BuildingContactWithId } from "@properate/common";
import { Button, Popconfirm, TableColumnsType, Tooltip } from "antd";
import { sortString, ClipboardButton } from "@properate/ui";
import { MessageKey, useTranslations } from "@properate/translations";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { LineClamp } from "@/components/LineClamp";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useRemoveBuildingContact } from "../hooks/useRemoveBuildingContact";
import { CellWithAction } from "./elements";

type Props = {
  buildingId: number;
  search: string;
  hideActions?: boolean;
  hideNote?: boolean;
  contacts: BuildingContactWithId[];
  onRowClick?: (contact: BuildingContactWithId) => void;
  pageSize?: number;
  className?: string;
  clipCells?: boolean;
  scrollY?: number;
  size?: "small" | "middle" | "large";
};

export function BuildingContactsTable({
  buildingId,
  search,
  contacts,
  onRowClick,
  className,
  hideActions = false,
  hideNote = false,
  pageSize = 10,
  clipCells = false,
  scrollY,
  size = "middle",
}: Props) {
  const t = useTranslations();
  const removeContact = useRemoveBuildingContact();

  const columns: TableColumnsType<BuildingContactWithId> = [
    {
      title: t("settings.contacts.fields.type"),
      key: "type",
      sorter: ({ type: typeOne }, { type: typeTwo }) =>
        sortString(typeOne, typeTwo),
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      render: (_, buildingContact) => (
        <LineClamp
          title={t(
            `settings.contacts.types.${buildingContact.type}` as MessageKey,
          )}
        >
          <SearchHighlighter
            search={search}
            text={t(
              `settings.contacts.types.${buildingContact.type}` as MessageKey,
            )}
          />
        </LineClamp>
      ),
    },
    {
      title: t("settings.contacts.fields.name"),
      key: "name",
      defaultSortOrder: "ascend",
      sorter: ({ name: nameOne }, { name: nameTwo }) =>
        sortString(nameOne, nameTwo),
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      render: (_, { name, note }) =>
        name && (
          <CellWithAction>
            <LineClamp title={name}>
              <SearchHighlighter search={search} text={name} />
            </LineClamp>
            {hideNote && note && (
              <Tooltip title={note}>
                <InfoCircleOutlined style={{ margin: "0 8px" }} />
              </Tooltip>
            )}
          </CellWithAction>
        ),
    },
    {
      title: t("settings.contacts.fields.email"),
      key: "email",
      sorter: ({ email: emailOne }, { email: emailTwo }) =>
        sortString(emailOne, emailTwo),
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      render: (_, { email }) =>
        email && (
          <CellWithAction>
            <LineClamp title={email}>
              <SearchHighlighter search={search} text={email} />
            </LineClamp>
            <ClipboardButton text={email} />
          </CellWithAction>
        ),
    },
    {
      title: t("settings.contacts.fields.phone"),
      key: "phone",
      sorter: ({ phone: phoneOne }, { phone: phoneTwo }) =>
        sortString(phoneOne, phoneTwo),
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      render: (_, { phone }) =>
        phone && (
          <CellWithAction>
            <LineClamp title={phone}>
              <SearchHighlighter search={search} text={phone} />
            </LineClamp>
            <ClipboardButton text={phone} />
          </CellWithAction>
        ),
    },
  ];

  if (!hideNote) {
    columns.push({
      title: t("settings.contacts.fields.notes"),
      key: "note",
      ...(clipCells ? { ellipsis: { showTitle: true } } : null),
      sorter: ({ note: noteOne }, { note: noteTwo }) =>
        sortString(noteOne, noteTwo),
      render: (_, { note }) =>
        note && (
          <LineClamp title={note}>
            <SearchHighlighter search={search} text={note} />
          </LineClamp>
        ),
    });
  }

  if (!hideActions) {
    columns.push({
      title: "",
      key: "action",
      render: (_, buildingContact) => (
        <Popconfirm
          title={t("settings.contacts.fields.are-you-sure-to-delete")}
          okText={t("settings.contacts.fields.yes")}
          onConfirm={(event) => {
            event?.stopPropagation();
            removeContact.trigger({
              buildingId: buildingId,
              contact: buildingContact,
            });
          }}
          onCancel={(event) => event?.stopPropagation()}
        >
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={(event) => event.stopPropagation()}
          />
        </Popconfirm>
      ),
    });
  }

  return (
    <TableWithoutDefaultSort
      columns={columns}
      dataSource={contacts}
      rowKey="id"
      rowIsHoverable={onRowClick !== undefined}
      scroll={{ y: scrollY }}
      pagination={
        contacts.length > pageSize
          ? {
              pageSize,
            }
          : false
      }
      onRow={(buildingContact) => ({
        onClick() {
          onRowClick?.(buildingContact);
        },
      })}
      className={className}
      size={size}
    />
  );
}
