import dayjs, { DurationUnitType } from "@properate/dayjs";
import { TimeSpan } from "@properate/common";
import { CDF_CONSTANTS } from "@/utils/cdf";

function getOptimalGranularityForUnit(
  [timeSpanStart, timeSpanEnd]: TimeSpan,
  timeseriesAmount: number,
  unit: "second" | "minute" | "hour" | "day",
  adjustmentFactor: number,
) {
  const timeSpandEndDayjs = dayjs(timeSpanEnd);
  const timeSpanInUnit = timeSpandEndDayjs.diff(timeSpanStart, unit);
  return Math.ceil(
    (timeSpanInUnit / CDF_CONSTANTS.datapoints.limits.query.aggregates) *
      timeseriesAmount *
      adjustmentFactor,
  );
}

export function getOptimalGranularity(
  timeSpan: TimeSpan,
  timeseriesAmount: number,
  adjustmentFactor = 1,
) {
  if (adjustmentFactor < 1) {
    throw new Error("An adjustment factor <1 is not supported!");
  }
  const optimalGranularityInSeconds = getOptimalGranularityForUnit(
    timeSpan,
    timeseriesAmount,
    "second",
    adjustmentFactor,
  );
  if (
    optimalGranularityInSeconds <=
    CDF_CONSTANTS.datapoints.limits.granularity.second
  ) {
    return `${optimalGranularityInSeconds}s`;
  }
  const optimalGranularityInMinutes = getOptimalGranularityForUnit(
    timeSpan,
    timeseriesAmount,
    "minute",
    adjustmentFactor,
  );
  if (
    optimalGranularityInMinutes <=
    CDF_CONSTANTS.datapoints.limits.granularity.minute
  ) {
    return `${optimalGranularityInMinutes}m`;
  }
  const optimalGranularityInHours = getOptimalGranularityForUnit(
    timeSpan,
    timeseriesAmount,
    "hour",
    adjustmentFactor,
  );
  if (
    optimalGranularityInHours <=
    CDF_CONSTANTS.datapoints.limits.granularity.hour
  ) {
    return `${optimalGranularityInHours}h`;
  }
  const optimalGranularityInDays = getOptimalGranularityForUnit(
    timeSpan,
    timeseriesAmount,
    "day",
    adjustmentFactor,
  );
  if (
    optimalGranularityInDays <= CDF_CONSTANTS.datapoints.limits.granularity.day
  ) {
    return `${optimalGranularityInDays}d`;
  }
  throw new Error(
    `It is not possible to aggregate over a time span spanning ${
      timeSpan[1] - timeSpan[0]
    } milliseconds`,
  );
}

export function getMaxGranularity(timeSpan: TimeSpan) {
  const duration = dayjs.duration(timeSpan[1] - timeSpan[0]);
  const durationInSeconds = duration.asSeconds();
  if (
    durationInSeconds > 0 &&
    durationInSeconds <= CDF_CONSTANTS.datapoints.limits.granularity.second
  ) {
    return `${Math.ceil(durationInSeconds)}s`;
  }
  const durationInMinutes = duration.asMinutes();
  if (
    durationInMinutes > 0 &&
    durationInMinutes <= CDF_CONSTANTS.datapoints.limits.granularity.minute
  ) {
    return `${Math.ceil(durationInMinutes)}m`;
  }
  const durationInHours = duration.asHours();
  if (
    durationInHours > 0 &&
    durationInHours <= CDF_CONSTANTS.datapoints.limits.granularity.hour
  ) {
    return `${Math.ceil(durationInHours)}h`;
  }
  const durationInDays = duration.asDays();
  if (
    durationInDays > 0 &&
    durationInDays <= CDF_CONSTANTS.datapoints.limits.granularity.day
  ) {
    return `${Math.ceil(durationInDays)}d`;
  }
  throw new Error(
    `It is not possible to aggregate over a time span spanning ${
      timeSpan[1] - timeSpan[0]
    } milliseconds`,
  );
}

// E.g. 1w, 5d or 120h
export function mapGranularityToMS(granularity: string) {
  return dayjs
    .duration(
      Number(granularity.slice(0, -1)),
      granularity.slice(-1) as DurationUnitType,
    )
    .asMilliseconds();
}
