import { z } from "zod";

export const UserSchema = z.object({
  admin: z.boolean().optional(),
  owner: z.string().optional(), // f.e. "Europris"
  eep: z.string().array(),
  asset_external_ids: z.string().min(1).array().optional(), // f.e. "AH_9300_Ringveien_1"
  groups: z.string().array(), // f.e. "G_ALL_R"
  idp: z.string().optional(), // f.e. "google"
});
