"use client";

import { Button, Card, Dropdown } from "antd";
import { useLocalStorageState } from "ahooks";
import { ChevronDownIcon, SlidersHorizontalIcon } from "lucide-react";
import { useTranslations } from "@properate/translations";

export type TaskListActiveFields = {
  createdAt: boolean;
  assignee: boolean;
  author: boolean;
  comments: boolean;
  files: boolean;
  dueDate: boolean;
  checklists: boolean;
};

export type TaskListActiveFieldsKey = keyof TaskListActiveFields;

type Props = {
  active: TaskListActiveFields;
  reset: () => void;
  onItemClick: (key: keyof TaskListActiveFields) => void;
};

export function TaskListFieldPicker(props: Props) {
  const t = useTranslations();

  return (
    <Dropdown
      dropdownRender={() => <DisplayPanel {...props} />}
      trigger={["click"]}
    >
      <Button>
        <div className="flex items-center gap-1 cursor-pointer">
          <SlidersHorizontalIcon size={14} />
          <span>{t("task.ui.display")}</span>
          <ChevronDownIcon size={14} />
        </div>
      </Button>
    </Dropdown>
  );
}

function DisplayPanel(props: Props) {
  const t = useTranslations();

  const menuItems: { key: TaskListActiveFieldsKey; label: string }[] = [
    { key: "createdAt", label: t("task.field.createdAt") },
    { key: "assignee", label: t("task.field.assignee") },
    { key: "author", label: t("task.field.author") },
    { key: "comments", label: t("task.field.comments") },
    { key: "files", label: t("task.field.files") },
    { key: "dueDate", label: t("task.field.dueDate") },
    { key: "checklists", label: t("task.field.checklists") },
  ];

  return (
    <Card size="small" className=" w-[277px]">
      <span className="font-medium">{t("task.ui.displayProperties")}</span>
      <ul className="flex flex-wrap gap-1 pt-2">
        {menuItems.map((item) => (
          <li key={item.key}>
            <Button
              size="small"
              type={props.active[item.key] ? "primary" : "default"}
              onClick={() => props.onItemClick(item.key)}
            >
              {item.label}
            </Button>
          </li>
        ))}
      </ul>
      <hr className="h-px my-3 bg-gray-200 border-0" />
      <div className="flex justify-end">
        <button
          className="px-3 font-medium text-gray-400 bg-transparent border-none cursor-pointer"
          onClick={() => props.reset()}
        >
          {t("task.ui.resetToDefault")}
        </button>
      </div>
    </Card>
  );
}

export const defaultValue = {
  createdAt: false,
  assignee: false,
  author: false,
  comments: true,
  files: true,
  dueDate: true,
  checklists: true,
};

export function useTaskListFieldPicker(disabled?: boolean) {
  const [active, setActive] = useLocalStorageState("active-task-list-fields", {
    defaultValue: defaultValue,
  });

  function resetToDefault() {
    setActive(defaultValue);
  }

  function handleItemClick(key: TaskListActiveFieldsKey) {
    setActive((prevActiveGroup?: TaskListActiveFields) => {
      if (prevActiveGroup !== undefined) {
        return {
          ...prevActiveGroup,
          [key]: !prevActiveGroup[key],
        };
      }

      return defaultValue;
    });
  }

  return {
    active: disabled ? defaultValue : active ?? defaultValue,
    resetToDefault,
    handleItemClick,
  };
}
