import { browserFirestore } from "@properate/firebase/src";
import { doc, setDoc } from "firebase/firestore";
import { useMemo } from "react";
import { useGetFirestoreDocument } from "@properate/ui";
import { BuildingAliasProps } from "../utils/types";
import type { DeepPartial } from "ts-essentials";

export function useAliasConfig(alias: string | null) {
  const { data } = useGetFirestoreDocument<BuildingAliasProps>(
    useMemo(
      () =>
        alias ? doc(browserFirestore, `alias/${alias.toLowerCase()}`) : null,
      [alias],
    ),
  );

  return data;
}

export async function mutateAliasCondig(
  alias: string,
  data: DeepPartial<BuildingAliasProps>,
) {
  const ref = doc(browserFirestore, `alias/${alias.toLowerCase()}`);

  await setDoc(ref, data, { merge: true });
}
