import { browserFirestore } from "@properate/firebase";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { useMemo } from "react";
import {
  useGetFirestoreCollection,
  useGetFirestoreDocument,
} from "@properate/ui";
import type {
  Alarm,
  ProperateEvent,
  AlarmWithSnapshotId,
} from "@properate/common";

export type AlarmEvent = ProperateEvent & { snapshotId: string };

export function useAlarm(alarmId?: string) {
  const { data } = useGetFirestoreDocument<Alarm>(
    useMemo(
      () =>
        alarmId ? doc(collection(browserFirestore, "alarms"), alarmId) : null,
      [alarmId],
    ),
  );

  return data;
}

export function useAlarmWithLoadingErrorState(alarmId?: string) {
  const { data, error, isLoading } = useGetFirestoreDocument<Alarm>(
    useMemo(
      () =>
        alarmId ? doc(collection(browserFirestore, "alarms"), alarmId) : null,
      [alarmId],
    ),
  );
  return {
    alarm: data,
    error,
    isLoading,
  };
}

export function useActiveEventsByBuilding(buildingId: number) {
  const { data } = useGetFirestoreCollection<Alarm>(
    useMemo(
      () =>
        query(
          collection(browserFirestore, "alarms"),
          where("buildingId", "==", buildingId),
        ),
      [buildingId],
    ),
  );

  return data
    ? data
        .filter((alarm) => alarm.activeEvent)
        .map((alarm) => ({
          ...alarm.activeEvent!,
          name: alarm.name || "",
          description: alarm.description || "",
        }))
    : data;
}

export function useAlarmEventsByBuilding(buildingId: number) {
  const { data } = useGetFirestoreCollection<ProperateEvent>(
    useMemo(
      () =>
        query(
          collection(browserFirestore, "events"),
          where("buildingId", "==", buildingId),
        ),
      [buildingId],
    ),
  );

  return data;
}

export async function mutateAlarm(alarm: Partial<AlarmWithSnapshotId>) {
  const { snapshotId, ...rest } = alarm;

  if (snapshotId) {
    const alarmRef = doc(collection(browserFirestore, "alarms"), snapshotId);

    await setDoc(alarmRef, rest, { merge: true });
  } else {
    await addDoc(collection(browserFirestore, "alarms"), rest);
  }
}

export async function deleteAlarm(alarmId: string) {
  const events = await getDocs(
    query(
      collection(browserFirestore, "events"),
      where("alarmId", "==", alarmId),
    ),
  );

  const batch = writeBatch(browserFirestore);

  events.forEach((event) => batch.delete(event.ref));

  batch.delete(doc(browserFirestore, "alarms", alarmId));

  await batch.commit();
}
