import { noop } from "lodash";
import { createContext, ReactNode, useContext, useState } from "react";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { UserSettings } from "@/utils/types";

type FilterTypes = UserSettings["filters"]["alarmsSieve"];
type FilterType = FilterTypes[number];

type ContextType = {
  search: string;
  filters: FilterTypes;
  toggleFilter: (filter: FilterType) => void;
  setFilters: (filters: FilterTypes) => void;
  setSearch: (search: string) => void;
};

const NewAlarmsTableFiltersContext = createContext<ContextType>({
  search: "",
  filters: [],
  setFilters: noop,
  toggleFilter: noop,
  setSearch: noop,
});

const defaultFilters: FilterTypes = [];

export function NewAlarmsTableFiltersProvider(props: {
  children: ReactNode;
  buildingIds?: number[];
}) {
  const [search, setSearch] = useState("");
  const { data } = useUserSettings();

  const filters: FilterTypes = data?.filters?.alarmsSieve ?? defaultFilters;

  const setFilters = (filters: FilterTypes) =>
    mutateUserSettings({
      filters: {
        alarmsSieve: filters,
      },
    });

  function toggleFilter(filter: FilterType) {
    if (filters.includes(filter)) {
      setFilters(filters.filter((f) => f !== filter));
    } else {
      setFilters([...filters, filter]);
    }
  }

  return (
    <NewAlarmsTableFiltersContext.Provider
      value={{
        search,
        filters,
        toggleFilter,
        setFilters,
        setSearch,
      }}
    >
      {props.children}
    </NewAlarmsTableFiltersContext.Provider>
  );
}

export function useAlarmsTableFiltersContext(requiredProvider = false) {
  const ctx = useContext(NewAlarmsTableFiltersContext);

  if (requiredProvider && !ctx) {
    throw new Error("Missing NewAlarmsTableFiltersContext");
  }

  return ctx;
}
