import * as React from "react";
import { Timeseries, DocumentSearchItem } from "@cognite/sdk";
import { useEffect } from "react";
import { getSystemCodeFromExternalId } from "@properate/common";
import { Col, App, Row } from "antd";
import styled from "styled-components";
import { useTranslations } from "@properate/translations";
import { getAssetById } from "@/utils/helpers";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { DetailsSection } from "@/pages/common/SchemaGraph/SetPointsPane";

const Name = styled.a`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

type Props = {
  timeseries: Timeseries;
};
export const DocumentsPane = ({ timeseries }: Props) => {
  const t = useTranslations();

  const { notification } = App.useApp();
  const { client } = useCogniteClient();

  const [results, setResults] = React.useState<DocumentSearchItem[]>([]);

  useEffect(() => {
    const search = async () => {
      const asset = await getAssetById(client, timeseries.assetId!);

      const searchWords = [
        asset.name,
        getSystemCodeFromExternalId(asset.externalId!),
        getSystemCodeFromExternalId(asset.externalId!).split(".")[0],
      ];

      if (asset.metadata?.device_name) {
        searchWords.push(asset.metadata.device_name);
      }

      const escapedSearch = searchWords.map((word) => `"${word}"`).join("|");

      const result = await client.documents.search({
        search: {
          query: `${escapedSearch}`,
        },
        filter: {
          and: [
            {
              equals: {
                property: ["sourceFile", "datasetId"],
                value: asset.dataSetId!,
              },
            },
            {
              containsAll: {
                property: ["assetIds"],
                values: [asset!.rootId],
              },
            },
          ],
        },
      });

      setResults(
        result.items.filter(
          (item) =>
            !item.item.labels?.find(
              (label) => label.externalId === "properate_report",
            ),
        ),
      );
    };

    search();
  }, [timeseries, client]);

  return (
    <>
      <h2>{t("common.timeseries-graph-modal.documents.title")}</h2>
      <DetailsSection>
        {results.map((result, index) => (
          <Row key={index}>
            <Col span={24}>
              <Name
                onClick={async (event) => {
                  event.stopPropagation();
                  if (result.item.sourceFile.metadata?.urn) {
                    window.open(
                      "/view/" +
                        encodeURIComponent(
                          btoa(result.item.sourceFile.metadata?.urn),
                        ),
                    );
                  } else {
                    await fetch(
                      `https://api.cognitedata.com/api/v1/projects/${process.env.REACT_APP_PROJECT_ID}/documents/${result.item.id}/preview/pdf/temporarylink`,
                      {
                        headers: client.getDefaultRequestHeaders(),
                      },
                    )
                      .then((response) => response.json())
                      .then((data) => {
                        if (data.temporaryLink) {
                          window.open(data.temporaryLink);
                        }
                      })
                      .catch((error) => {
                        notification.error({
                          message: t(
                            "common.timeseries-graph-modal.documents.failed-to-generate-preview-link",
                            {
                              error: (error as any).message,
                            },
                          ),
                        });
                      });
                  }
                }}
              >
                {result.item.sourceFile.name}
              </Name>
            </Col>
          </Row>
        ))}
      </DetailsSection>
    </>
  );
};
