import { Form, Input, Button } from "antd";
import { useTranslations } from "@properate/translations";
import { FormItemTitle } from "./elements";

interface Props {
  title: string;
  onChangeTitle: (title: string) => unknown;
}

export function AnalysisDetailsFormTitle({ title, onChangeTitle }: Props) {
  const t = useTranslations();
  return (
    <Form layout="inline" onFinish={({ title }) => onChangeTitle(title)}>
      <FormItemTitle
        name="title"
        initialValue={title}
        style={{ margin: 0, width: 300 }}
      >
        <Input placeholder={t("analysis.details.name-placeholder")} autoFocus />
      </FormItemTitle>
      <Form.Item>
        <Button
          data-testid="analysis-submit-name"
          type="primary"
          htmlType="submit"
        >
          {t("analysis.details.ok")}
        </Button>
      </Form.Item>
    </Form>
  );
}
