import styled from "styled-components";
import { Radio } from "antd";

export const DetailsSection = styled.div`
  margin-bottom: 8px;
`;

export const ButtonContainer = styled.div<{ marginBottom?: number }>`
  padding: 3px;
  background: ${(props) => props.theme.neutral8};
  border-radius: 5px;
  margin-bottom: ${({ marginBottom = 0 }) => `${marginBottom}px`};
`;

interface DividedRadioGroupProps {
  buttonCount: number;
}

export const RadioButton = styled(Radio.Button)`
  width: 100%;
  border: 0 !important;
  text-align: center;

  & .ant-radio-button {
    border-radius: 5px;
  }

  background: ${(props) => props.theme.neutral10} !important;
  color: ${(props) => props.theme.neutral4} !important;
  &.ant-radio-button-wrapper {
    background: transparent !important;
    box-shadow: none;
  }
  &.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  &.ant-radio-button-wrapper:hover {
    color: ${(props) => props.theme.neutral0} !important;
  }
  &.ant-radio-button-wrapper-checked,
  & .ant-radio-button-wrapper-checked:hover {
    border-color: transparent !important;

    background: ${(props) => props.theme.neutral10} !important;
    color: ${(props) => props.theme.neutral0} !important;
  }
`;

export const DividedRadioGroup = styled(Radio.Group).attrs(
  (props: DividedRadioGroupProps) => ({
    buttonCount: props.buttonCount,
  }),
)<DividedRadioGroupProps>`
  width: 100%;
  & ${RadioButton} {
    width: ${(props) => 100 / (props.buttonCount || 1) + "%"};
  }
`;
