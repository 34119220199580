import { ComponentSearchable } from "@properate/api/src/types";
import useSWRInfinite, { SWRInfiniteResponse } from "swr/infinite";
import { buildFilterQuery, Filter } from "@/pages/Components/utils";
import { componentsIndex } from "@/eepApi";
import { useHandleApiError } from "@/utils/api";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

export interface SortBy {
  key: string;
  order: "asc" | "desc";
}

const PAGE_SIZE = 100;

type FacetDistribution = {
  componentTypeTranslated: Record<string, number>;
  system: Record<string, number>;
  subBuilding: Record<string, number>;
} | null;

type ReturnType = Pick<
  SWRInfiniteResponse,
  "size" | "setSize" | "isLoading" | "mutate"
> & {
  searchableComponentList: ComponentSearchable[];
  facetDistribution: FacetDistribution;
};

export function useSearchAssetList(
  search: string,
  filter: Filter,
  sortBy: SortBy | null,
): ReturnType {
  const handleAPIError = useHandleApiError();
  const currentBuildingId = useCurrentBuildingId();
  const { data, error, isLoading, size, setSize, mutate } = useSWRInfinite(
    (index) => ({
      search,
      offset: index * PAGE_SIZE,
      filter,
      sortBy,
    }),
    async ({ search, offset, filter, sortBy }) => {
      const { facetHits: systemHits } =
        await componentsIndex.searchForFacetValues({
          facetName: "system",
          filter: buildFilterQuery(filter, currentBuildingId),
        });
      const { facetHits: componentTypeTranslatedHits } =
        await componentsIndex.searchForFacetValues({
          facetName: "componentTypeTranslated",
          filter: buildFilterQuery(filter, currentBuildingId),
        });
      const { facetHits: subBuildingHits } =
        await componentsIndex.searchForFacetValues({
          facetName: "subBuilding",
          filter: buildFilterQuery(filter, currentBuildingId),
        });
      const { hits: searchableComponentList } = await componentsIndex.search(
        search,
        {
          offset,
          sort: sortBy ? [`${sortBy.key}:${sortBy.order}`] : undefined,
          filter: buildFilterQuery(filter, currentBuildingId),
          attributesToHighlight: ["*"],
          limit: PAGE_SIZE,
        },
      );
      return {
        searchableComponentList,
        facetDistribution: {
          componentTypeTranslated: componentTypeTranslatedHits?.reduce(
            (p, c) => ({ ...p, [c.value]: c.count }),
            {},
          ),
          subBuilding: subBuildingHits?.reduce(
            (p, c) => ({ ...p, [c.value]: c.count }),
            {},
          ),
          system: systemHits?.reduce(
            (p, c) => ({ ...p, [c.value]: c.count }),
            {},
          ),
        },
      };
    },
    {
      keepPreviousData: true,
    },
  );

  if (error) {
    handleAPIError(error);
  }

  return {
    searchableComponentList: data
      ? data.flatMap(({ searchableComponentList }) => searchableComponentList)
      : [],
    facetDistribution: data
      ? (data[0].facetDistribution as FacetDistribution)
      : null,
    isLoading,
    size,
    setSize,
    mutate,
  };
}
