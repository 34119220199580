import {
  formatTimeseriesName,
  ThirdPartyAlarmVisualTimeseries,
} from "@properate/common";
import { Spin } from "antd";
import { useTranslations } from "@properate/translations";
import { useThirdPartyAlarmTimeseriesWithColor } from "../hooks";
import {
  ButtonVariant,
  NotificationRow,
} from "../../../AlarmNotificationsPage/NotificationRow";
import ColorDot from "../../../components/ColorDot";

export default function VisualTimeseriesListEntry({
  label,
  value,
  onDelete,
}: ThirdPartyAlarmVisualTimeseries & { onDelete: () => void }) {
  const t = useTranslations(
    "alarm-details.alarm-types.third-party-alarm.visual-timeseries",
  );
  const { timeseries, color } = useThirdPartyAlarmTimeseriesWithColor(value);

  return (
    <NotificationRow
      colOne={
        <div className="flex flex-row gap-1 items-center">
          <ColorDot color={color} />
          <span>{t(`type.${label}`)}</span>
        </div>
      }
      colTwo={
        timeseries ? <span>{formatTimeseriesName(timeseries)}</span> : <Spin />
      }
      buttonVariant={ButtonVariant.Remove}
      onClick={() => onDelete()}
    />
  );
}
