import useSWR from "swr";
import { Asset } from "@cognite/sdk";
import { WithRequired } from "@properate/common";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useHandleApiError } from "@/utils/api";

export function useGetTotalEnergyAssets() {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const currentBuildingId = useCurrentBuildingId();

  const {
    data: totalEnergyAssets = [],
    isLoading: isLoadingTotalEnergyAssets,
    error: errorTotalEnergyAssets,
  } = useSWR(
    () => ({
      type: "assets.list",
      query: {
        filter: {
          assetSubtreeIds: [{ id: currentBuildingId }],
          labels: {
            // These assets are connected to timeseries which contain the total (temp corrected) energy usage per organization
            containsAll: [
              {
                externalId: "oe_common_main_meter",
              },
            ],
          },
        },
      },
    }),
    ({ query }) =>
      client.assets
        .list(query)
        .autoPagingToArray({ limit: Infinity }) as Promise<
        Array<WithRequired<Asset, "labels">>
      >,
  );

  const {
    data: totalEnergyTempCorrectedAssets = [],
    isLoading: isLoadingTotalEnergyTempCorrectedAssets,
    error: errorTotalEnergyTempCorrectedAssets,
  } = useSWR(
    () =>
      totalEnergyAssets.length > 0
        ? {
            type: "assets.list",
            query: {
              filter: {
                assetSubtreeIds: totalEnergyAssets.map(({ id }) => ({ id })),
                labels: {
                  // These assets are connected to timeseries which contain the total (temp corrected) energy usage per organization
                  containsAll: [
                    {
                      externalId: "oe_temperature_corrected",
                    },
                  ],
                },
              },
            },
          }
        : null,
    ({ query }) =>
      client.assets
        .list(query)
        .autoPagingToArray({ limit: Infinity }) as Promise<
        Array<WithRequired<Asset, "labels">>
      >,
  );

  if (errorTotalEnergyAssets || errorTotalEnergyTempCorrectedAssets) {
    handleAPIError(
      errorTotalEnergyAssets || errorTotalEnergyTempCorrectedAssets,
    );
  }

  return {
    totalEnergyAssets: totalEnergyAssets.concat(totalEnergyTempCorrectedAssets),
    isLoading:
      isLoadingTotalEnergyAssets || isLoadingTotalEnergyTempCorrectedAssets,
  };
}
