import { useSidebarData } from "@properate/ui";
import {
  NoteMetaField,
  NotesSidebarValues,
  NotesSidebarViewState,
} from "../types";
import { NoteMeta } from "../NoteMeta";

export default function EditFormMeta() {
  const { note, viewState } = useSidebarData<NotesSidebarValues>();
  if (viewState === NotesSidebarViewState.create) {
    return null;
  }
  return (
    <NoteMeta
      note={note}
      showFields={[NoteMetaField.Changelog, NoteMetaField.Source]}
    />
  );
}
