import { Navigate } from "react-router-dom";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useUserSettings } from "@/services/userSettings";

export const LastCalendarMode = () => {
  const buildingId = useCurrentBuildingId();
  const preferences = useUserSettings();

  if (preferences.isLoading || preferences.error) {
    return null;
  }

  const mode = preferences.data?.calendar?.[buildingId]?.mode || "week";

  return <Navigate replace to={`/asset/${buildingId}/calendar/${mode}`} />;
};
