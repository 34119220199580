import { Spin } from "antd";

export function CenteredSpinner({ message }: { message?: string }) {
  return (
    <div
      className={
        "w-full h-full flex flex-col gap-4 justify-center items-center"
      }
    >
      <Spin />
      {message && <p className="text-center text-sm">{message}</p>}
    </div>
  );
}
