import { useCallback, useMemo } from "react";
import { WithSnapshotId } from "@properate/common";
import { Timeseries } from "@cognite/sdk";
import { useRootAssets } from "@/hooks/useRootAssets";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { TimeseriesSettings } from "@/services/timeseriesSettings";
import { SettingsTimeseries } from "../types";
import { useGetAssets } from "./useGetAssets";

function getTimeseriesById(timeseriesList: Timeseries[], id: number) {
  return timeseriesList.find((timeseries) => timeseries.id === id);
}
export function useRemoveSettingsTimeseriesHiddenRootAsset(
  settingsTimeseriesList: SettingsTimeseries[],
  overrideUnits?: WithSnapshotId<TimeseriesSettings> | undefined,
) {
  const { timeseriesList } = useGetTimeseriesList(
    settingsTimeseriesList.map(({ id }) => id),
  );
  const { assets } = useGetAssets(settingsTimeseriesList);
  const rootAssets = useRootAssets();
  const isNotUnderHiddenRootBuilding = useCallback(
    (settingsTimeseries: SettingsTimeseries) => {
      const timeseries = getTimeseriesById(
        timeseriesList,
        settingsTimeseries.id,
      );
      const asset = assets.find((asset) => asset.id === timeseries?.assetId);
      const rootAssetForTimeseries = rootAssets.find(
        (rootAsset) => rootAsset.id === asset?.rootId,
      );
      return Boolean(rootAssetForTimeseries);
    },
    [timeseriesList, assets, rootAssets],
  );

  // Ensures downstream components don't always receive a new array instance
  return useMemo(() => {
    return settingsTimeseriesList
      .map((settingsTimeseries) => {
        const timeseries = getTimeseriesById(
          timeseriesList,
          settingsTimeseries.id,
        );
        const overrideUnit =
          timeseries &&
          overrideUnits &&
          overrideUnits[timeseries.externalId!]?.unit;
        if (typeof overrideUnit === "string") {
          return {
            ...settingsTimeseries,
            unitSelected: overrideUnit || timeseries?.unit || "",
          };
        }
        return settingsTimeseries;
      })
      .filter(isNotUnderHiddenRootBuilding);
  }, [
    settingsTimeseriesList,
    isNotUnderHiddenRootBuilding,
    overrideUnits,
    timeseriesList,
  ]);
}
