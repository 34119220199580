"use client";

import { createContext, useContext } from "react";

type TaskType = "tasks" | "taskTemplates";

export const TaskTypeContext = createContext<TaskType>("tasks");

type Props = {
  children: React.ReactNode;
  taskType: TaskType;
};

export function TaskTypeProvider(props: Props) {
  return (
    <TaskTypeContext.Provider value={props.taskType}>
      {props.children}
    </TaskTypeContext.Provider>
  );
}

export function useTaskType() {
  return useContext(TaskTypeContext);
}
