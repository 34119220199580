import { Modal } from "antd";
import styled from "styled-components";

export const WhiteModal = styled(Modal)`
  .ant-modal-content,
  .ant-modal-header,
  .ant-modal-footer {
    background-color: ${(props) => props.theme.background2} !important;
  }
`;
