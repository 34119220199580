import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";

import { useState } from "react";
import { BuildingContactWithId } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { SearchInput } from "@/components/SearchIndex";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useGetBuildingContacts } from "../hooks/useGetBuildingContacts";
import { BuildingContactModal } from "./BuildingContactModal";
import { BuildingContactsTable } from "./BuildingContactsTable";
import { useContactsSieve } from "./hooks/useContactsSieve";
import { BuildingContactsTableFilters } from "./BuildingContactsTableFilters";

export function BuildingContactsAdminPanel() {
  const t = useTranslations();

  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedContact, setSelectedContact] =
    useState<BuildingContactWithId | null>(null);
  const buildingId = useCurrentBuildingId();
  const contacts = useGetBuildingContacts(buildingId);
  const { search, setSearch, filter, setFilter, filteredContacts } =
    useContactsSieve(contacts.data);

  return (
    <div>
      <PageHeader
        title={t("settings.contacts.title")}
        extra={[
          <BuildingContactsTableFilters
            key="filter"
            value={filter}
            onChange={setFilter}
          />,
          <SearchInput
            key="search"
            allowClear
            placeholder={t("settings.contacts.search-placeholder")}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />,
          <Button
            key="new"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setSelectedContact(null);
              setModalVisibility(true);
            }}
          >
            {t("settings.contacts.new-contact")}
          </Button>,
        ]}
      />
      <BuildingContactModal
        key={selectedContact?.id}
        buildingId={buildingId}
        isVisible={isModalVisible}
        onClose={() => {
          setSelectedContact(null);
          setModalVisibility(false);
        }}
        contacts={contacts.data}
        contact={selectedContact}
      />
      <div style={{ height: 400 }}>
        <BuildingContactsTable
          buildingId={buildingId}
          contacts={filteredContacts}
          search={search}
          scrollY={325}
          onRowClick={(contact) => {
            setSelectedContact(contact);
            setModalVisibility(true);
          }}
        />
      </div>
    </div>
  );
}
