import { MouseEvent } from "react";
import { DoubleDatapoint } from "@cognite/sdk";
import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import { ScaleBand } from "d3-scale";
import { ScaleLinearNumeric } from "@/utils/types";

interface Props {
  offsetLeft: number;
  offsetTop: number;
  datapoints: DoubleDatapoint[];
  scaleX: ScaleBand<number>;
  scaleY: ScaleLinearNumeric;
  graphHeight: number;
  graphWidth: number;
  color: string;
  highlightedTimestamp: number | null;
  onMouseMove: (event: MouseEvent<SVGRectElement>) => unknown;
  onMouseLeave: () => unknown;
}

export function BarChartBars({
  offsetLeft,
  offsetTop,
  datapoints,
  scaleX,
  scaleY,
  graphHeight,
  graphWidth,
  color,
  onMouseMove,
  onMouseLeave,
  highlightedTimestamp,
}: Props) {
  return (
    <Group left={offsetLeft} top={offsetTop}>
      {datapoints.map((datapoint) => {
        const barHeight = graphHeight - scaleY(datapoint.value);
        return (
          <Bar
            key={datapoint.timestamp.valueOf()}
            width={scaleX.bandwidth()}
            height={barHeight}
            x={scaleX(datapoint.timestamp.valueOf())}
            y={graphHeight - barHeight}
            fill={color}
            fillOpacity={
              highlightedTimestamp === datapoint.timestamp.valueOf() ? 0.5 : 1
            }
          />
        );
      })}
      <rect
        width={graphWidth}
        height={graphHeight}
        fill="transparent"
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
    </Group>
  );
}
