import { Timeseries } from "@cognite/sdk";

export function getTimeseriesTitle(timeseries: Timeseries) {
  if (timeseries.name && timeseries.description) {
    return `${timeseries.name} | ${timeseries.description}`;
  }
  if (timeseries.name) {
    return timeseries.name;
  }
  if (timeseries.description) {
    return timeseries.description;
  }
  return null;
}
