import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { useInitializeViewer } from "./Viewer-helpers";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Viewer = () => {
  const navigate = useNavigate();
  const { urn }: any = useParams();
  const initializeViewer = useInitializeViewer();

  useEffect(() => {
    initializeViewer(urn, () => {
      navigate(`/view/upload/${encodeURIComponent(urn)}`);
    });
  }, [urn, navigate, initializeViewer]);
  return <Container id="viewerContainer" />;
};
