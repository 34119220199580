import * as React from "react";
import { Button, Col, Modal, Row, Tooltip } from "antd";
import { CheckOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import { useState } from "react";
import { useTranslations } from "@properate/translations";
import {
  AddToPalette,
  Palette,
} from "@/pages/properateCalendar/components/elements";
import { COLOR_PALETTE } from "@/utils/ProperateColors";

type Props = {
  onChangeColor: (color: string) => void;
  color: string;
};
export const ColorSelector = ({ onChangeColor, color }: Props) => {
  const t = useTranslations();
  const [showSelectColor, setShowSelectColor] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  return (
    <>
      <Tooltip
        placement="left"
        trigger={["click"]}
        open={showSelectColor}
        onOpenChange={setShowSelectColor}
        title={
          <div>
            <Row gutter={[8, 8]}>
              {COLOR_PALETTE.map((col) => (
                <Col span={4} key={col.name}>
                  <Button
                    type="link"
                    onClick={() => {
                      onChangeColor(col.code);
                      setShowSelectColor(false);
                    }}
                  >
                    <Palette title={t(col.displayKey)} color={col.code}>
                      {col.code === color ? <CheckOutlined /> : null}
                    </Palette>
                  </Button>
                </Col>
              ))}
              <Col span={4} key="select">
                <Button
                  type="link"
                  onClick={() => {
                    setShowColorPicker(!showColorPicker);
                  }}
                >
                  <AddToPalette color={color}>
                    <PlusOutlined />
                  </AddToPalette>
                </Button>
              </Col>
            </Row>
          </div>
        }
      >
        <Button
          type="link"
          icon={<MoreOutlined />}
          onClick={() => setShowSelectColor(true)}
        />
      </Tooltip>
      <Modal
        open={showColorPicker}
        footer={null}
        width={268}
        closable={false}
        onCancel={() => setShowColorPicker(false)}
        destroyOnClose
      >
        <SketchPicker
          color={color}
          onChange={(color: { hex: string }) => {
            onChangeColor(color.hex);
            setShowColorPicker(false);
          }}
          width="220px"
        />
      </Modal>
    </>
  );
};
