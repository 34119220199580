import VncDisplay from "react-vnc-display";
import { Alert } from "antd";
import { useParams } from "react-router-dom";
import { useBuildingPageTitle } from "../../hooks/usePageTitle";
import { useBuildingSpec } from "../../services/buildingSpec";

const { ErrorBoundary } = Alert;

const Vnc = () => {
  useBuildingPageTitle("VNC");
  const { id }: any = useParams();

  const buildingSpec = useBuildingSpec(id);
  return (
    <>
      {buildingSpec && (
        <ErrorBoundary>
          <VncDisplay url={buildingSpec?.vlcLink} />
        </ErrorBoundary>
      )}
    </>
  );
};

export default Vnc;
