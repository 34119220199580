import {
  AccordionActionType,
  AccordionEntry,
  SidebarActionType,
  useAccordionActions,
  useAccordionValues,
  useSidebarActions,
} from "@properate/ui";
import { useEffect, useMemo } from "react";
import { Button } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { useNotes } from "@/features/notes";

function makeFilterId(id: number) {
  return `notes-for-components-${id}`;
}
export function ComponentTableNotesColumn({ id }: { id: number }) {
  const accordionDispatch = useAccordionActions();
  const sidebarDispatch = useSidebarActions();
  const { filters } = useAccordionValues();
  const { notes } = useNotes();
  const filterId = makeFilterId(id);
  const isFiltering = filters.get(filterId)?.active ?? false;

  const currentAssetNotes = useMemo(() => {
    return notes
      ? notes
          .filter((note) => {
            return note.assetIds.includes(id);
          })
          .map(({ id }) => id)
      : [];
  }, [notes, id]);

  const disabled = currentAssetNotes.length === 0;

  useEffect(() => {
    if (filterId) {
      accordionDispatch({
        type: AccordionActionType.addFilter,
        filterId,
        filterFunction: (entry: AccordionEntry) =>
          currentAssetNotes.some((id) => id === entry.uniqueId),
        enable: false,
      });
    }
    return () => {
      accordionDispatch({
        type: AccordionActionType.removeFilter,
        filterId,
      });
    };
  }, [accordionDispatch, filterId, currentAssetNotes]);

  function handleToggleFilter(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();

    if (disabled) {
      return;
    }
    if (isFiltering) {
      accordionDispatch({
        type: AccordionActionType.disableFilter,
        filterId,
      });
    } else {
      sidebarDispatch({ type: SidebarActionType.open });
      accordionDispatch({
        type: AccordionActionType.enableFilter,
        filterId,
        disableOthers: true,
      });
    }
  }
  if (disabled) {
    return null;
  }

  return (
    <div
      className={"w-full h-full flex justify-center items-center"}
      onClick={handleToggleFilter}
    >
      <Button
        icon={<MessageOutlined aria-hidden />}
        disabled={disabled}
        shape="circle"
        className={`${
          isFiltering ? "bg-info-bg border-info text-info-fg" : ""
        }`}
        onClick={handleToggleFilter}
      />
    </div>
  );
}
