import { Select } from "antd";
import { useTranslations } from "@properate/translations";
import { SpotPriceTimeSpan } from "@/utils/types";
import { spotPriceOptions } from "../utils";

interface Props {
  value: SpotPriceTimeSpan;
  onChange: (value: SpotPriceTimeSpan) => unknown;
}

export function SpotPriceTimeSpanSelect({ value, onChange }: Props) {
  const t = useTranslations();

  return (
    <Select
      aria-label={t("dashboard.widgets.spot-price.period-to-display")}
      value={value}
      onChange={onChange}
      options={spotPriceOptions.map((option) => ({
        value: option.value,
        label: t(`dashboard.widgets.spot-price.periods.${option.value}`),
      }))}
      style={{ minWidth: 150 }}
    />
  );
}
