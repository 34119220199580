import styled from "styled-components";
import { Input } from "antd";

export const ContrastInput: typeof Input = styled(Input)`
  &.ant-input-affix-wrapper {
    background: ${(props) => props.theme.background2};
    border: 1px solid #d9d9d9;
  }

  &.ant-input-affix-wrapper-focused {
    background: ${(props) => props.theme.background2};
  }

  input {
    background: ${(props) => props.theme.background2};
  }

  &.ant-input-affix-wrapper-focused input {
    background: ${(props) => props.theme.background2};
  }
`;
