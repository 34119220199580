import { Button, Spin } from "antd";
import { formatTimeseriesName } from "@properate/common";
import { EditOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useFormValue } from "../../../FormContext/hooks";
import { useTimeseriesWithDatapoint } from "../../../hooks";
import { ThirdPartyAlarmFields } from "../types";

export default function SelectVisualTimeseriesId({
  selectVisualTimeseries,
}: {
  selectVisualTimeseries: () => void;
}) {
  const t = useTranslations(
    "alarm-details.alarm-types.third-party-alarm.visual-timeseries",
  );
  const [visualTimeseriesId, _setVisualTimeseriesId, visualTimeseriesError] =
    useFormValue<number | undefined>(
      ThirdPartyAlarmFields.AddVisualTimeseriesId,
    );
  const { timeseries: visualTimeseries } = useTimeseriesWithDatapoint({
    timeseriesId: visualTimeseriesId,
  });

  function handleSelectVisualTimeseries() {
    selectVisualTimeseries();
  }

  if (visualTimeseriesId && !visualTimeseries) {
    return <Spin />;
  }

  if (visualTimeseries) {
    return (
      <div className="w-full h-full flex flex-row justify-between text-nowrap flex-nowrap gap-1">
        <p className="truncate m-0 p-0">
          {formatTimeseriesName(visualTimeseries)}
        </p>
        <Button
          size="small"
          shape="circle"
          icon={<EditOutlined />}
          onClick={handleSelectVisualTimeseries}
        />
      </div>
    );
  }

  function renderError() {
    return (
      <span className="h-5 w-full text-error">
        {visualTimeseriesError ?? " "}
      </span>
    );
  }

  return (
    <div className="flex flex-col gap-1 w-full">
      <Button
        size="small"
        className={"!h-[23px]"}
        onClick={handleSelectVisualTimeseries}
      >
        {t("open-timeseries-selector")}
      </Button>
      {renderError()}
    </div>
  );
}
