import { PropsWithChildren, useState } from "react";
import { SelectTimeseries } from "./SelectTimeseries";
import { SelectedTimeseries } from "./SelectedTimeseries";

enum ViewState {
  Select,
  Display,
}

export function TimeseriesSelector({
  name,
  selectLabel,
  displayLabel,
  onClose,
  onOpen,
  children,
}: PropsWithChildren & {
  name: string;
  selectLabel: string;
  displayLabel: string;
  onClose?: () => void;
  onOpen?: () => void;
}) {
  const [viewState, setViewState] = useState<ViewState>(ViewState.Display);

  function handleClose() {
    setViewState(ViewState.Display);
    onClose?.();
  }

  function handleOpen() {
    setViewState(ViewState.Select);
    onOpen?.();
  }

  if (viewState === ViewState.Select) {
    return (
      <SelectTimeseries close={handleClose} name={name} label={selectLabel} />
    );
  }

  return (
    <>
      <SelectedTimeseries
        name={name}
        label={displayLabel}
        activateSelector={handleOpen}
      />
      {children}
    </>
  );
}
