import dayjs from "@properate/dayjs";
import { DatePicker } from "antd";
import { useTranslations } from "@properate/translations";
import { HeatMapViewMode } from "../types";

const endOfNextMonth = dayjs().add(1, "month").endOf("month");

interface Props {
  viewMode: HeatMapViewMode;
  value: number;
  onChange: (value: number) => unknown;
  className?: string;
  isCurrentStartOfWeekMonthChecked?: boolean;
}

export function HeatMapReferenceDateSelection({
  viewMode,
  value,
  onChange,
  className,
  isCurrentStartOfWeekMonthChecked,
}: Props) {
  const t = useTranslations();

  function handleChange(value: dayjs.Dayjs | null) {
    if (value) {
      onChange(value.valueOf());
    }
  }
  return (
    <DatePicker
      disabled={isCurrentStartOfWeekMonthChecked}
      id="reference-date"
      value={dayjs(value)}
      onChange={handleChange}
      picker={viewMode}
      format={viewMode === "month" ? "MMMM YYYY" : "[Uke] W YYYY"}
      disabledDate={(date) => date.isAfter(endOfNextMonth, "day")}
      className={className}
      aria-label={t("analysis.heat-map.month-or-week")}
    />
  );
}
