import { CSSProperties, useState } from "react";
import { Row, Col, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import * as EmailValidator from "email-validator";
import { parsePhoneNumber, ParseError } from "libphonenumber-js";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { InputWithError } from "@/pages/common/SchemaGraph/InputWithError";
import { SubscriptionsPerUser } from "../../types";

interface Props {
  onAdd: (value: string, type: "email" | "tel") => unknown;
  subscriptionsPerUser: SubscriptionsPerUser;
  onChange: (value: string) => unknown;
  error?: string;
  style?: CSSProperties;
}

export const AlarmSubscriptionAddInput = ({
  onAdd,
  subscriptionsPerUser,
  onChange,
  error,
  style,
}: Props) => {
  const t = useTranslations();
  const user = useUser();
  const [value, setValue] = useState("");
  const [validationErrorMessage, setValidationErrorMessage] = useState("");

  function resetValue() {
    setValue("");
  }

  function handleChange(value: string) {
    setValidationErrorMessage("");
    onChange(value);
    setValue(value);
  }

  const valueType = /^[+\d\s]+$/.test(value) ? "tel" : "email";

  const subscriptions = Object.values(subscriptionsPerUser).flatMap(
    ({ email, phone }) => email.concat(phone),
  );

  function addSubscription() {
    if (value.length > 0) {
      if (valueType === "email") {
        if (EmailValidator.validate(value)) {
          if (subscriptions.includes(value)) {
            return setValidationErrorMessage(
              t(
                "common.timeseries-graph-modal.alarm-fields.email-has-been-already-added",
                {
                  value,
                },
              ),
            );
          }
          onAdd(value, "email");
          resetValue();
          return;
        }
        return setValidationErrorMessage(
          t("common.timeseries-graph-modal.alarm-fields.invalid-email"),
        );
      }

      try {
        const valueParsedAsPhone = parsePhoneNumber(value, "NO");
        if (valueParsedAsPhone.isValid()) {
          const valueFinal = valueParsedAsPhone.formatInternational();
          if (subscriptions.includes(valueFinal)) {
            return setValidationErrorMessage(
              t(
                "common.timeseries-graph-modal.alarm-fields.phone-number-has-been-already-added",
                {
                  value: valueFinal,
                },
              ),
            );
          }
          onAdd(valueFinal, "tel");
          return resetValue();
        }
        return setValidationErrorMessage(
          t("common.timeseries-graph-modal.alarm-fields.invalid-phone-number"),
        );
      } catch (error) {
        if (error instanceof ParseError) {
          return setValidationErrorMessage(
            t(
              "common.timeseries-graph-modal.alarm-fields.invalid-phone-number",
            ),
          );
        }
        throw error;
      }
    }
  }

  return (
    <Row gutter={8} style={style}>
      <Col flex={1}>
        <InputWithError
          disabled={user.isViewer}
          placeholder={t(
            "common.timeseries-graph-modal.alarm-fields.email-or-phone-number",
          )}
          value={value}
          error={validationErrorMessage || error}
          onPressEnter={addSubscription}
          autoComplete={valueType}
          allowClear
          onChange={handleChange}
          dataTestId={"subscribe-email-telephone"}
        />
      </Col>
      <Col flex={0}>
        <Button
          type="default"
          shape="circle"
          disabled={value === ""}
          icon={<PlusOutlined />}
          onClick={addSubscription}
        />
      </Col>
    </Row>
  );
};
