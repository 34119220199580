import { useTranslations } from "@properate/translations";
import { App, Avatar, Badge, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useState } from "react";
import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import useSWR from "swr";
import * as React from "react";
import { useTheme } from "styled-components";
import {
  NEW_ALARM_VIEW_STATE_ENTRY,
  NewAlarmViewState,
  useIsCreateNew,
} from "@/pages/alarms/details/hooks/createNewAlarmHooks";
import {
  useFormContext,
  useFormValue,
} from "@/pages/alarms/details/FormContext/hooks";
import { FormContextActionType } from "@/pages/alarms/details/FormContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useFormFieldIdsForCurrentPage } from "@/pages/alarms/details/hooks/useFormFieldIdsForPageId";
import { ResultCallbackFunctionParams } from "@/pages/alarms/details/FormContext/types";
import { deleteAlarmConfiguration, validateIncidentsInAlarms } from "@/eepApi";
import { AlarmUpdateModal } from "@/features/alarms";

type SubmitCallbackFunction = (props: ResultCallbackFunctionParams) => boolean;

function CancelButton() {
  const translate = useTranslations();
  const { dispatch } = useFormContext();
  const navigate = useNavigate();
  const { id } = useCurrentBuilding();

  function handleCancel() {
    dispatch({ type: FormContextActionType.clear });
    navigate(`/asset/${id}/newAlarms/`);
  }

  return (
    <Button
      ghost
      type="primary"
      danger
      htmlType="button"
      onClick={handleCancel}
    >
      {translate("alarm-details.common.navigation.cancel")}
    </Button>
  );
}

function EditButton({
  handleNextOrSubmit,
}: {
  handleNextOrSubmit: () => void;
}) {
  const translate = useTranslations();
  const isCreateNew = useIsCreateNew();

  if (isCreateNew) {
    return null;
  }

  return (
    <Button
      ghost
      type="primary"
      htmlType="button"
      onClick={() => handleNextOrSubmit()}
    >
      {translate("alarm-details.common.navigation.save")}
    </Button>
  );
}

function CreateNewButton({
  handleNextOrSubmit,
}: {
  handleNextOrSubmit: (callback: SubmitCallbackFunction) => void;
}) {
  const translate = useTranslations();
  const isCreateNew = useIsCreateNew();
  const [viewState, setViewState] = useFormValue(NEW_ALARM_VIEW_STATE_ENTRY);

  if (!isCreateNew) {
    return null;
  }

  const handleValidateAndSave: SubmitCallbackFunction = ({ hasError }) => {
    if (hasError) {
      return false;
    }
    if (viewState === NewAlarmViewState.Settings) {
      setViewState(NewAlarmViewState.Notifications);
    } else if (viewState === NewAlarmViewState.Notifications) {
      return true;
    }
    return false;
  };

  return (
    <Button
      ghost
      type="primary"
      htmlType="button"
      onClick={() => handleNextOrSubmit(handleValidateAndSave)}
    >
      {viewState === NewAlarmViewState.Settings
        ? translate("alarm-details.common.navigation.next")
        : translate("alarm-details.common.navigation.save-new")}
    </Button>
  );
}

function DeleteButton({
  showConfirmDelete,
  setShowConfirmDelete,
}: {
  showConfirmDelete: boolean;
  setShowConfirmDelete: Dispatch<SetStateAction<boolean>>;
}) {
  const translate = useTranslations();
  const { dispatch } = useFormContext();
  const navigate = useNavigate();
  const { id } = useCurrentBuilding();
  const isCreateNew = useIsCreateNew();
  const { notification } = App.useApp();
  const { alarmId } = useParams();

  if (isCreateNew) {
    return null;
  }

  const handleConfirmDelete = () => {
    if (alarmId) {
      deleteAlarmConfiguration({ id: alarmId })
        .then(() => {
          notification.info({
            message: translate(
              "alarm-details.common.notification-messages.deleted-alarm",
              { eventCount: 1 },
            ),
          });
          dispatch({ type: FormContextActionType.clear });
          navigate(`/asset/${id}/newAlarms/`);
        })
        .catch((error) => {
          console.error(error);
          notification.error({
            message: translate(
              "alarm-details.common.notification-messages.delete-failed",
            ),
          });
        });
    }
  };

  function handleDelete() {
    setShowConfirmDelete(true);
  }

  if (!showConfirmDelete) {
    return (
      <Button
        type="text"
        danger
        htmlType="button"
        onClick={handleDelete}
        icon={<DeleteOutlined />}
      >
        {translate("alarm-details.common.navigation.delete")}
      </Button>
    );
  }

  return (
    <>
      <Button
        type="default"
        htmlType="button"
        onClick={() => setShowConfirmDelete(false)}
      >
        {translate("alarm-details.common.navigation.cancel-delete")}
      </Button>
      <Button
        ghost
        type="primary"
        danger
        htmlType="button"
        onClick={handleConfirmDelete}
      >
        {translate("alarm-details.common.navigation.confirm-delete")}
      </Button>
    </>
  );
}

export function AlarmDetailsFooter() {
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const { dispatch } = useFormContext();
  const fieldIds = useFormFieldIdsForCurrentPage();
  const [hasError, setHasError] = useState<boolean>(false);
  const t = useTranslations();
  const { alarmId } = useParams() as { alarmId: string };
  const currentBuilding = useCurrentBuilding();
  const theme = useTheme();

  const [isIncidentValidationModalOpen, setIsIncidentValidationModalOpen] =
    useState<boolean>(false);

  const { data, isLoading: isLoadingValidation } = useSWR(
    ["incidentsValidation", alarmId],
    () => {
      return validateIncidentsInAlarms({
        alarmId,
        building_external_id: currentBuilding.externalId!,
      });
    },
  );

  const isLoading = isLoadingValidation;

  const isNewAlarm = alarmId === "new";

  const showIncidentsValidationModal =
    data?.hasAlertConfigurations ||
    !!data?.alarmIncidents.length ||
    (isNewAlarm && data?.hasIncidents);

  function handleCallback({
    entries,
    hasError,
    errorIds,
    callback,
  }: ResultCallbackFunctionParams & {
    callback?: SubmitCallbackFunction;
  }) {
    setHasError(hasError);
    let shouldSubmit = true;

    if (callback) {
      shouldSubmit = callback({ entries, hasError, errorIds });
    }
    if (hasError || !shouldSubmit) {
      return;
    }

    dispatch({
      type: FormContextActionType.submit,
    });
  }

  function handleNextOrSubmit(callback?: SubmitCallbackFunction) {
    dispatch({
      type: FormContextActionType.validateFields,
      ids: fieldIds,
      resultCallback: ({ entries, hasError, errorIds }) =>
        handleCallback({ entries, hasError, errorIds, callback }),
    });
  }

  function renderDeleteButton() {
    return (
      <>
        <DeleteButton
          showConfirmDelete={showConfirmDelete}
          setShowConfirmDelete={setShowConfirmDelete}
        />
      </>
    );
  }

  function renderValidationErrorMessage() {
    if (!hasError) {
      return null;
    }

    return (
      <p className="text-error text-xs m-0 my-auto ">
        {t("alarm-details.common.navigation.validation-error")}
      </p>
    );
  }

  function renderButtons() {
    if (showConfirmDelete) {
      return renderDeleteButton();
    }
    return (
      <>
        {renderDeleteButton()}
        <CancelButton />
        <EditButton handleNextOrSubmit={handleNextOrSubmit} />
        <CreateNewButton handleNextOrSubmit={handleNextOrSubmit} />
        {showIncidentsValidationModal && (
          <Badge
            count={data?.alarmIncidents.length}
            style={{ marginRight: "10px" }}
          >
            <Avatar
              onClick={() => setIsIncidentValidationModalOpen(true)}
              shape="square"
              style={{
                backgroundColor: theme.warningBg,
                color: theme.warningFg,
              }}
              size="default"
              icon={<WarningOutlined />}
            />
          </Badge>
        )}
        <AlarmUpdateModal
          isOpen={isIncidentValidationModalOpen}
          data={data}
          onClose={() => setIsIncidentValidationModalOpen(false)}
        />
        {renderValidationErrorMessage()}
      </>
    );
  }

  return (
    <div className="box-border w-full flex flex-row justify-start align-center gap-3 mt-3">
      {!isLoading && renderButtons()}
    </div>
  );
}
