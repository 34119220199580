import { useState } from "react";
import { Button, Input, Modal } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import { ClipboardButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";

interface Props {
  shareLink: string;
  children: string;
  title: string;
}

export function ShareButtonWithDialog({ shareLink, title, children }: Props) {
  const t = useTranslations();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <>
      <Button icon={<ShareAltOutlined />} onClick={openModal}>
        {t("analysis.share")}
      </Button>
      <Modal
        title={title}
        open={isModalOpen}
        onCancel={closeModal}
        okButtonProps={{ style: { display: "none" } }}
      >
        <p>{children}</p>
        <Input
          value={shareLink}
          readOnly
          addonAfter={<ClipboardButton text={shareLink} />}
        />
      </Modal>
    </>
  );
}
