import styled from "styled-components";

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProductionSummaryValue = styled.div`
  font-size: 1rem;
`;

export const GraphAndSummaryContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 16px;
`;
