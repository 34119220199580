import { SetupPage, TaskTypeProvider } from "@properate/task-manager";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { PageHeader } from "@ant-design/pro-layout";
import { RightOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import FullLayout from "@/layout/FullLayout";

const Container = styled.div<{ $center?: boolean }>`
  padding: 1rem;
  max-width: 1280px;
  width: 100%;
  margin: ${(props) => (props.$center ? "0 auto" : "0")};
`;

type Props = {
  isInsideBuildingLayout?: boolean;
};

export function TaskManagerCreateTaskTemplatePage(props: Readonly<Props>) {
  const t = useTranslations();
  const { id: buildingId } = useParams();
  const navigate = useNavigate();

  const content = (
    <TaskTypeProvider taskType="taskTemplates">
      <Container $center={!props.isInsideBuildingLayout}>
        <SetupPage
          buildings={[]}
          buildingId={Number(buildingId)}
          onCreated={({ snapshotId }) => {
            navigate(
              buildingId
                ? `/asset/${buildingId}/workOrders/template/${snapshotId}`
                : `/org/task-templates/${snapshotId}`,
            );
          }}
        />
      </Container>
    </TaskTypeProvider>
  );

  if (props.isInsideBuildingLayout) {
    return (
      <>
        <PageHeader
          title={
            <>
              <Link to={`/asset/${buildingId}/workOrders`}>
                {t("task.ui.title")}
              </Link>{" "}
              <RightOutlined />
            </>
          }
          subTitle={t("task.template.new-template")}
        />
        {content}
      </>
    );
  }

  return (
    <FullLayout
      pageName={t("task.template.new-task-template")}
      headerRight={<></>}
    >
      {content}
    </FullLayout>
  );
}
