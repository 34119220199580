import { useTranslations } from "@properate/translations";
import { CenteredSpinner } from "@properate/ui";
import { useEffect } from "react";
import { Box, BoxVariant } from "../../components/InfoBox";
import { useAlarmTimeseriesForCurrentSubBuilding } from "../../hooks/useAlarmTimeseriesForCurrentSubBuilding";
import { useFormValue } from "../../FormContext/hooks";
import { TimeseriesThresholdFormFields } from "../TimeseriesThreshold";
import { OffsetDifference, ResponseDelaySelect } from "./SelectOffset";
import { SelectSubBuilding } from "./SelectSubBuilding";

export enum FixedTimeseriesName {
  RoomTempHigh = "RT001 max",
  RoomTempLow = "RT001 min",
  VOC = "RY002 max",
  CO2 = "RY001 max",
  LeakProtection = "RH002 norm",
}

export function FixedTimeseriesThresholdDelayOnly({
  hideOffset = false,
}: Readonly<{
  fixedTimeseries: FixedTimeseriesName;
  hideOffset?: boolean;
}>) {
  const [timeseriesId, setTimeseriesId] = useFormValue<number | undefined>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const [subBuildingId] = useFormValue<number>(
    TimeseriesThresholdFormFields.SubBuilding,
  );
  const {
    timeseriesForCurrentSubBuilding,
    isLoading,
    error,
    currentSubBuildingAvailable,
  } = useAlarmTimeseriesForCurrentSubBuilding();

  useEffect(() => {
    if (timeseriesForCurrentSubBuilding.length === 0) {
      return;
    }
    if (timeseriesId) {
      return;
    }
    setTimeseriesId(timeseriesForCurrentSubBuilding[0].id);
  }, [
    setTimeseriesId,
    subBuildingId,
    timeseriesForCurrentSubBuilding,
    timeseriesId,
  ]);

  const t = useTranslations();

  const timeseries = (timeseriesForCurrentSubBuilding ?? []).find(
    (ts) => ts.id === timeseriesId,
  );

  function renderTimeseriesBasedFields() {
    if (subBuildingId && !currentSubBuildingAvailable) {
      return (
        <Box variant={BoxVariant.Info}>
          <p>{t("alarm-details.common.error-messages.type-unavailable")}</p>
        </Box>
      );
    }
    if (!timeseries) {
      return null;
    }
    return (
      <>
        {hideOffset ? null : <OffsetDifference unit={timeseries.unit ?? ""} />}
        <ResponseDelaySelect />
      </>
    );
  }

  if (error) {
    return <Box variant={BoxVariant.Error}>{error.message}</Box>;
  }
  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <SelectSubBuilding />
      {renderTimeseriesBasedFields()}
    </>
  );
}
