import { Button } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { useTranslations } from "@properate/translations";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  AccessModuleBuilding,
  AccessModuleOrganization,
} from "@/pages/organizationAdmin/utils";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { AccessModules, allAccessModules } from "@/features/organizationAdmin";
import { BuildingInfo } from "@/components/Building/BuildingInfo";
import { AccessModuleTags } from "@/pages/organizationAdmin/AccessModuleTags";

const Table = styled(TableWithoutDefaultSort<AccessModuleBuilding>)`
  table {
    thead * {
      background: transparent !important;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }
`;

export const ExpandedBuildingModules = ({
  buildings,
  modules: organizationModules,
}: AccessModuleOrganization) => {
  const t = useTranslations();
  const navigate = useNavigate();

  const sortModules = (a: AccessModules, b: AccessModules) =>
    t(`organization-admin.access.modules.${a}`).localeCompare(
      t(`organization-admin.access.modules.${b}`),
    );

  const columns: ColumnsType<AccessModuleBuilding> = [
    {
      key: "name",
      title: t("organization-admin.columns.building-name"),
      defaultSortOrder: "ascend",
      width: 300,
      sorter: (a: AccessModuleBuilding, b: AccessModuleBuilding) =>
        a.name.localeCompare(b.name),
      render: (data: AccessModuleBuilding) => (
        <BuildingInfo
          street={data.street}
          place={data.place?.trimStart() || ""}
          alias={data.alias}
          imageUrl={data.image}
        />
      ),
    },
    {
      key: "modules",
      title: " ",
      dataIndex: "modules",
      render: (modules: AccessModules[]) => {
        const isAllDeselected = modules.length === 0;

        if (isAllDeselected) {
          return (
            <div className="leading-7 opacity-50">
              {t(`organization-admin.access.modules.none`)}
            </div>
          );
        }

        const intersectionModules = organizationModules.filter((module) =>
          modules.includes(module),
        );

        const buildingCustomModules = modules.filter(
          (module) => !organizationModules.includes(module),
        );

        const sortedModules = [
          ...[...intersectionModules].sort(sortModules),
          ...[...buildingCustomModules].sort(sortModules),
        ];

        return (
          <AccessModuleTags
            sortedModules={sortedModules}
            customizedModules={allAccessModules}
            modules={organizationModules}
            customizedToolboxText={t(
              `organization-admin.access.module-customized`,
            )}
          />
        );
      },
    },
    {
      key: "actions",
      title: " ",
      width: 80,
      render: (data) => {
        return (
          <div className="flex gap-2">
            <Button
              data-testid={`building-edit/${data.name}`}
              onClick={() => navigate(`building-modules/${data.key}`)}
            >
              {t("organization-admin.columns.access")}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      loading={false}
      rowKey="key"
      dataSource={buildings}
      columns={columns}
      pagination={false}
    />
  );
};
