import { EventHandler, ReactNode } from "react";
import { Button, ButtonProps } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useUser } from "@properate/auth";

export enum ButtonVariant {
  Add,
  Remove,
}

export function NotificationRow({
  colOne,
  colTwo,
  onClick,
  buttonVariant,
  outerClassName = "",
  innerClassName = "",
  button = null,
}: Readonly<{
  colOne: ReactNode;
  colTwo: ReactNode;
  onClick: EventHandler<any>;
  buttonVariant?: ButtonVariant;
  outerClassName?: string;
  innerClassName?: string;
  button?: ReactNode;
}>) {
  const t = useTranslations();
  const user = useUser();

  function renderButton() {
    if (button) {
      return button;
    }
    const commonProps: Partial<ButtonProps> = {
      onClick,
      disabled: !user.isAdmin,
    };
    switch (buttonVariant) {
      case ButtonVariant.Add:
        return (
          <Button
            size="small"
            ghost
            type="primary"
            className="!h-[23px]"
            {...commonProps}
          >
            {t("ui.add")}
          </Button>
        );
      case ButtonVariant.Remove:
        return (
          <Button
            size="small"
            type="default"
            shape="circle"
            icon={<MinusCircleOutlined />}
            {...commonProps}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className={`w-full flex flex-row ${outerClassName}`}>
      <div className={`grid grid-cols-2 flex-grow ${innerClassName}`}>
        {colOne}
        {colTwo}
      </div>
      {renderButton()}
    </div>
  );
}
