import { AlarmRule, ProperateEvent, WithSnapshotId } from "@properate/common";
import { useMemo } from "react";
import {
  Note,
  NoteSidebarFilterButton,
  useNotes,
  useTimeseriesList,
} from "@/features/notes";
import { useGetAlarmEvents } from "../../hooks/useGetAlarmEvents";

function getAlarmPeriods({
  alarmEvents,
}: {
  alarmEvents: WithSnapshotId<ProperateEvent>[];
}): {
  from: WithSnapshotId<ProperateEvent>;
  to: WithSnapshotId<ProperateEvent> | null;
}[] {
  const sortedEvents = alarmEvents
    ? [...alarmEvents].sort((e1, e2) => e1.date.getTime() - e2.date.getTime())
    : [];
  const periods = [];
  for (
    let alarmEventIndex = 0;
    alarmEventIndex < sortedEvents.length;
    alarmEventIndex += 1
  ) {
    const current = sortedEvents[alarmEventIndex];
    if (current.state === "RESOLVED") {
      continue;
    }
    const next =
      sortedEvents.length > alarmEventIndex + 1
        ? sortedEvents[alarmEventIndex + 1]
        : null;
    if (current.state === "ALARM") {
      periods.push({
        from: current,
        to: next,
      });
    }
  }
  return periods;
}

function getTimeFilteredNotes({
  notes,
  alarmEvents,
}: {
  notes: Note[];
  alarmEvents: WithSnapshotId<ProperateEvent>[];
}) {
  const alarmPeriods = getAlarmPeriods({ alarmEvents });
  return (notes ?? []).filter((note) => {
    for (const alarmPeriod of alarmPeriods) {
      if (alarmPeriod.to === null) {
        if (note.endTime > alarmPeriod.from.date.getTime()) {
          // ongoing alarm. If it began before the note ended, these overlap.
          return true;
        }
        continue;
      }
      const alarmStart = alarmPeriod.from.date.getTime();
      const alarmEnd = alarmPeriod.to.date.getTime();
      const noteStart = note.startTime;
      const noteEnd = note.endTime;

      if (alarmStart > noteEnd || noteStart > alarmEnd) {
        continue;
      }
      return true;
    }
  });
}

function useNotesForAlarm({ alarm }: Readonly<{ alarm: AlarmRule }>) {
  const { data: alarmEvents } = useGetAlarmEvents(alarm.alarm_id);
  const { notes } = useNotes();
  const { timeseriesList } = useTimeseriesList();

  return useMemo(() => {
    if (!notes || !alarmEvents || !timeseriesList) {
      return [];
    }
    const timeFilteredNotes = getTimeFilteredNotes({
      notes,
      alarmEvents,
    });
    function getTimeseriesForNote(note: Note) {
      return (timeseriesList ?? []).filter(
        (timeseries) =>
          timeseries.assetId && note.assetIds.includes(timeseries.assetId),
      );
    }
    /*
      1 - for each Note
      2 - loop all timeseries
      3 - and check if this alarm includes the same timeseries
     */
    return timeFilteredNotes.filter((note /* 1 */) =>
      getTimeseriesForNote(note).some(
        ({ id }) => id === alarm.condition.type_specific.base_timeseries_id,
      ),
    );
  }, [
    alarm.condition.type_specific.base_timeseries_id,
    alarmEvents,
    notes,
    timeseriesList,
  ]);
}

export function makeFilterId(alarm: AlarmRule) {
  return `notes-for-alarm-${alarm.alarm_id}`;
}

export function NewAlarmsTableNotesColumn({
  alarm,
}: Readonly<{ alarm: AlarmRule }>) {
  const matchingNotes = useNotesForAlarm({ alarm });
  const filterId = makeFilterId(alarm);

  return <NoteSidebarFilterButton notes={matchingNotes} filterId={filterId} />;
}
