import { ComponentSearchable } from "@properate/api/src/types";
import { App } from "antd";
import { useEffect } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { SorterResult } from "antd/es/table/interface";
import { useNavigate } from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { isErrorWithMessage } from "@/utils/api";
import { deleteUserAsset } from "@/eepApi";
import { useHelp } from "@/context/HelpContext";
import { ComponentsTable, ComponentsTableHeader } from "@/features/components";
import { CompactContent } from "@/components/CompactContent";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "../../features/notes";
import { useSearchAssetList } from "./useSearchAssetList";

export function ComponentsPage() {
  const t = useTranslations();

  useBuildingPageTitle(t("components.components"));

  const { message } = App.useApp();
  const navigate = useNavigate();
  const currentBuilding = useCurrentBuilding();
  const { setHelp } = useHelp();
  const [state, setState] = useUrlState({
    search: "",
    showReturned: false,
    componentType: [] as string[],
    system: null,
    subBuilding: null,
    sortByKey: "system",
    sortByOrder: "asc",
  });

  const {
    searchableComponentList,
    facetDistribution,
    isLoading,
    size,
    setSize,
    mutate,
  } = useSearchAssetList(
    state.search,
    {
      buildingId: currentBuilding.id,
      subBuilding: state.subBuilding,
      system: state.system,
      componentTypeTranslated: state.componentType,
      showReturned: state.showReturned,
    },
    { key: state.sortByKey, order: state.sortByOrder },
  );

  function handleReachedEndOfPage() {
    setSize(size + 1);
  }

  function handleChangeSortBy(sorter: SorterResult<ComponentSearchable>) {
    if (
      sorter.order &&
      sorter.column &&
      typeof sorter.column.key === "string"
    ) {
      const order =
        sorter.order === "ascend" ? ("asc" as const) : ("desc" as const);
      setState((state) => ({
        ...state,
        sortByKey: sorter.column!.key,
        sortByOrder: order,
      }));
      return;
    }
    setState((state) => ({ ...state, sortByKey: null, sortByOrder: null }));
  }

  async function handleConfirmDeleteUserAsset(externalId: string) {
    try {
      await deleteUserAsset(externalId);
      mutate();
    } catch (error) {
      if (isErrorWithMessage(error)) {
        return message.error({
          content: error.message,
          duration: 7,
        });
      }
      console.error(error);
    }
  }

  const handleShowDetails = (id: number) => {
    navigate(`./${id}`);
  };

  useEffect(() => {
    setHelp({
      title: t("components.components"),
      content: (
        <>
          <p>{t("components.help.description-1")}</p>
          <p>{t("components.help.description-2")}</p>
        </>
      ),
    });
  }, [setHelp, t]);

  return (
    <>
      <PageHeader
        title={t("components.components")}
        extra={
          <>
            <ComponentsTableHeader
              toggleShowReturned={(showReturned) =>
                setState((state) => ({ ...state, showReturned }))
              }
              subBuildingFilter={state.subBuilding}
              componentTypeTranslatedFilter={state.componentType}
              onChangeSubBuildingFilter={(subBuilding) => {
                if (!subBuilding) {
                  setState((state) => ({ ...state, subBuilding: undefined }));
                  return;
                }
                setState((state) => ({ ...state, subBuilding }));
              }}
              onChangeSystemFilter={(system) => {
                if (!system) {
                  setState((state) => ({ ...state, system: undefined }));
                  return;
                }
                setState((state) => ({ ...state, system }));
              }}
              onChangeComponentTypeTranslatedFilter={(
                setComponentTypeTranslatedFilter,
              ) =>
                setState((state) => ({
                  ...state,
                  componentType: setComponentTypeTranslatedFilter,
                }))
              }
              onSearch={(search) => {
                if (!search) {
                  setState((state) => ({ ...state, search: undefined }));
                  return;
                }
                setState((state) => ({
                  ...state,
                  search,
                }));
              }}
              facetDistribution={facetDistribution ?? null}
              onAddNew={() => navigate("./new")}
            />
            <ToggleSidebarButton
              hiddenWhenSidebarVisible
              sidebarHiddenContent={t("notes.show-notes-button")}
            />
          </>
        }
      />
      <CompactContent>
        <ComponentsTable
          componentList={searchableComponentList}
          isLoading={isLoading}
          onChangeSortBy={handleChangeSortBy}
          onReachedEndOfPage={handleReachedEndOfPage}
          onConfirmDeleteUserAsset={handleConfirmDeleteUserAsset}
          onClickDetails={handleShowDetails}
        />
        {currentBuilding?.dataSetId && (
          <NotesSidebar
            assetFilterMode={NotesAssetFilterMode.AssetList}
            idSet={
              new Set(searchableComponentList.map((component) => component.id))
            }
            buildings={[
              { id: currentBuilding.dataSetId, name: currentBuilding.name },
            ]}
            noteSource={NoteSource.WEB_COMPONENTS}
          />
        )}
      </CompactContent>
    </>
  );
}
