import useSWR from "swr";
import { buildFilterQuery, Filter } from "@/pages/Components/utils";
import { componentsIndex } from "@/eepApi";
import { useHandleApiError } from "@/utils/api";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

type ReturnType = {
  data: Record<string, number> | undefined;
  isLoading: boolean;
  error: Error | null;
};

export function useFacets(
  facetName:
    | "system"
    | "componentTypeTranslated"
    | "subBuilding"
    | "supplier"
    | "contractor"
    | "manufacturer",
  filter: Filter,
  search?: string,
  facetSearch?: string,
): ReturnType {
  const handleAPIError = useHandleApiError();
  const currentBuildingId = useCurrentBuildingId();

  const { data, error, isLoading } = useSWR(
    [facetName, facetSearch, filter, search],
    async ([facetName, facetSearch, filter, search]: [
      string,
      string | undefined,
      Filter,
      string | undefined,
    ]) => {
      const { facetHits } = await componentsIndex.searchForFacetValues({
        facetName: facetName,
        facetQuery: facetSearch,
        filter: buildFilterQuery(filter, currentBuildingId),
        q: search,
      });
      return facetHits.reduce((p, c) => ({ ...p, [c.value]: c.count }), {});
    },
  );

  if (error) {
    handleAPIError(error);
  }

  return {
    data,
    isLoading,
    error,
  };
}
