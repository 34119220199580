import { Node, useEdges } from "reactflow";
import { useEffect, useState } from "react";
import { useTranslations } from "@properate/translations";
import { getNodeId } from "./helpers/Utils";
import {
  SmallNode,
  NodeOutput,
  NodeOutputLabel,
  NodeInput,
  NodeInputLabel,
  Header,
  Body,
} from "./helpers/NodeComponents";

interface Props {
  operationId: string;
}

const inputIds = "abcdefghijklmnopqrstuvwxyz".split("");
const handleLabels = inputIds.map((id) => id.toUpperCase());

export const getEmptyAddNode = (): Node<Props> => {
  return {
    id: getNodeId("add"),
    type: "add",
    data: {
      operationId: "add",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function AddNode(params: { id: string; data: Props }) {
  const t = useTranslations();
  const edges = useEdges();

  const [connectedHandles, setConnectedHandles] = useState<string[]>([]);

  useEffect(() => {
    const nodeInputEdges = edges.filter((edge) => edge.target === params.id);
    setConnectedHandles(
      nodeInputEdges
        .map((edge) => edge.targetHandle)
        .filter((handler): handler is string => typeof handler === "string"),
    );
  }, [params.id, edges]);

  const visibleHandles =
    connectedHandles.length > 0 ? connectedHandles : inputIds.slice(0, 1);

  const nodeInputs = visibleHandles.map((handle, i) => (
    <NodeInput key={handle} inputId={handle}>
      <NodeInputLabel>{handleLabels[i]}</NodeInputLabel>
    </NodeInput>
  ));

  const nextInputIdIndex = inputIds.findIndex(
    (id) => visibleHandles.indexOf(id) === -1,
  );

  return (
    <SmallNode>
      <Header>{t("calculation-flow.node-types.addition")}</Header>
      <Body>
        {nodeInputs}
        {visibleHandles.length < inputIds.length && (
          <NodeInput inputId={inputIds[nextInputIdIndex]}>
            <NodeInputLabel>
              {visibleHandles.length < 2 ? "B" : ""}
            </NodeInputLabel>
          </NodeInput>
        )}
        <NodeOutput>
          <NodeOutputLabel>
            {visibleHandles.length <= 2
              ? "A + B"
              : `A + ... + ${handleLabels[visibleHandles.length - 1]}`}
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </SmallNode>
  );
}

export default AddNode;
