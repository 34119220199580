import dayjs from "@properate/dayjs";
import { TimeSpan } from "@properate/common";
import useSWRImmutable from "swr/immutable";
import { Datapoints } from "@cognite/sdk";
import { useMemo } from "react";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { SettingsTimeseries } from "../types";

const TWO_YEARS_AGO = dayjs().startOf("day").subtract(2, "year").valueOf();
const TWO_DAYS_FROM_NOW = dayjs().startOf("day").add(2, "day").valueOf();

function mapToFirstDatapointTimestamps(
  datapointWithMetadataList: Datapoints[],
) {
  return datapointWithMetadataList
    .map(({ datapoints }) => datapoints)
    .filter((datapoints) => datapoints.length > 0)
    .map((datapoints) => datapoints[0].timestamp.valueOf());
}

function getEarliestDatapointTimestamp(
  datapointWithMetadataList: Datapoints[],
) {
  const firstTimestamps = mapToFirstDatapointTimestamps(
    datapointWithMetadataList,
  );
  return firstTimestamps.length > 0
    ? Math.min(...firstTimestamps)
    : TWO_YEARS_AGO;
}

function getLatestDatapointTimestamp(
  timeseriesWithLatestDatapointList: Datapoints[],
) {
  const timestamps = mapToFirstDatapointTimestamps(
    timeseriesWithLatestDatapointList,
  );
  return timestamps.length > 0 ? Math.max(...timestamps) : TWO_DAYS_FROM_NOW;
}

// Use this in components where it's more important to get a stable time span
// than it is to receive the latest updates at all times
export function useGetImmutableTimeSpan(
  settingsTimeriesList: SettingsTimeseries[],
): TimeSpan {
  const { client } = useCogniteClient();
  const ids = settingsTimeriesList.map(({ id }) => ({ id }));
  const { data: earliestDatapoints } = useSWRImmutable(
    settingsTimeriesList.length > 0
      ? {
          type: "datapoints.retrieve",
          query: {
            items: ids,
            start: 0,
            limit: 1,
          },
        }
      : null,
    ({ query }) => client.datapoints.retrieve(query),
    {
      keepPreviousData: true,
    },
  );
  const { data: latestDatapoints } = useSWRImmutable(
    settingsTimeriesList.length > 0
      ? {
          type: "datapoints.retrieveLatest",
          query: ids,
        }
      : null,
    ({ query }) => client.datapoints.retrieveLatest(query),
    {
      keepPreviousData: true,
    },
  );
  return useMemo(() => {
    if (earliestDatapoints && latestDatapoints) {
      // If we only have 1 data point, we can't calculate a proper time span
      const hasAtLeastOneTimeseriesWithMoreThanOneDatapoint =
        earliestDatapoints.some(({ id, datapoints }) => {
          const latestDatapointForTimeseries = latestDatapoints.find(
            (latestDatapoint) => latestDatapoint.id === id,
          );
          if (
            datapoints.length > 0 &&
            latestDatapointForTimeseries !== undefined &&
            latestDatapointForTimeseries.datapoints.length > 0
          ) {
            return (
              datapoints[0].timestamp.valueOf() !==
              latestDatapointForTimeseries.datapoints[0].timestamp.valueOf()
            );
          }
          return false;
        });
      if (hasAtLeastOneTimeseriesWithMoreThanOneDatapoint) {
        return [
          getEarliestDatapointTimestamp(earliestDatapoints as Datapoints[]),
          getLatestDatapointTimestamp(latestDatapoints as Datapoints[]),
        ];
      }
    }
    return [TWO_YEARS_AGO, TWO_DAYS_FROM_NOW];
  }, [earliestDatapoints, latestDatapoints]);
}
