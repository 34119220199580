export enum ThirdPartyViewState {
  View,
  SelectVisualTimeseries,
  SelectBaseTimeseries,
}

export enum ThirdPartyAlarmFields {
  BaseTimeseries = "ThirdPartyBaseTimeseries",
  VisualTimeseries = "ThirdPartyVisualTimeseries",
  ZeroIsError = "ThirdPartyZeroError",
  AddVisualTimeseriesType = "ThirdPartyAddVisualTimeseriesType",
  AddVisualTimeseriesId = "ThirdPartyAddVisualTimeseriesId",
}
