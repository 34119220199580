import { Space } from "antd";
import { Timeseries } from "@cognite/sdk";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { TimeseriesSelectionInput } from "@/components/TimeseriesSelectionInput";
import { HeatMapCurrentWeekMonthCheckboxSelection } from "@/features/heatmap";
import { HeatMap, HeatMapAggregate, HeatMapViewMode } from "../types";
import { HeatMapName } from "./HeatMapName";
import { HeatMapViewModeSelection } from "./HeatMapViewModeSelection";
import { HeatMapAggregateSelection } from "./HeatMapAggregateSelection";
import { HeatMapShowWeekNumberSwitch } from "./HeatMapShowWeekNumberSwitch";
import { HeatMapColorPicker } from "./HeatMapColorPicker";
import { HeatMapReferenceDateSelectionCentered } from "./elements";

interface Props {
  name: string;
  viewMode: HeatMapViewMode;
  aggregate: HeatMapAggregate;
  referenceDate: number;
  isCurrentStartOfWeekMonthChecked?: boolean;
  showWeekNumbers: boolean;
  color: string;
  timeseriesId: number;
  onEdit: (value: Partial<HeatMap>) => unknown;
  className?: string;
}

export function HeatMapSettings({
  name,
  viewMode,
  aggregate,
  referenceDate,
  isCurrentStartOfWeekMonthChecked,
  showWeekNumbers,
  color,
  timeseriesId,
  onEdit,
  className,
}: Props) {
  const {
    timeseriesList: [timeseries],
  } = useGetTimeseriesList([timeseriesId]);

  function handleChangeTimeseries(timeseries: Timeseries | undefined) {
    if (timeseries) {
      onEdit({
        timeseriesId: timeseries.id,
      });
    }
  }
  function handleChangeStartOfCurrentWeekMonth({
    target: { checked },
  }: CheckboxChangeEvent) {
    onEdit({
      isCurrentStartOfWeekMonthChecked: checked,
    });
  }

  return (
    <Space direction="vertical" className={className}>
      <HeatMapName value={name} onChange={(name) => onEdit({ name })} />
      <TimeseriesSelectionInput
        value={timeseries}
        onChange={handleChangeTimeseries}
      />
      <HeatMapViewModeSelection
        value={viewMode}
        onChange={(viewMode) => {
          if (isCurrentStartOfWeekMonthChecked) {
            onEdit({
              viewMode,
              isCurrentStartOfWeekMonthChecked: false,
            });
          } else {
            onEdit({
              viewMode,
            });
          }
        }}
      />
      <Space direction="horizontal">
        <HeatMapReferenceDateSelectionCentered
          isCurrentStartOfWeekMonthChecked={isCurrentStartOfWeekMonthChecked}
          viewMode={viewMode}
          value={referenceDate}
          onChange={(referenceDate) =>
            onEdit({
              referenceDate,
            })
          }
        />
        <HeatMapCurrentWeekMonthCheckboxSelection
          viewMode={viewMode}
          isCurrentStartOfWeekMonthChecked={isCurrentStartOfWeekMonthChecked}
          handleChangeCurrentWeekMonth={handleChangeStartOfCurrentWeekMonth}
        />
      </Space>
      <HeatMapAggregateSelection
        value={aggregate}
        onChange={(aggregate) =>
          onEdit({
            aggregate,
          })
        }
      />
      {viewMode === "month" && (
        <HeatMapShowWeekNumberSwitch
          value={showWeekNumbers}
          onChange={(showWeekNumbers) =>
            onEdit({
              showWeekNumbers,
            })
          }
        />
      )}
      <HeatMapColorPicker
        value={color}
        onChange={(color) => onEdit({ color })}
      />
    </Space>
  );
}
