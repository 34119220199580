import { TimeseriesExternalIdSearchable } from "@properate/api/src/types";
import { Button, Popconfirm, Popover, Space, Tag } from "antd";
import { SorterResult } from "antd/es/table/interface";
import {
  DeleteOutlined,
  EditOutlined,
  LineChartOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { ClipboardButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { useWindowSize } from "@/hooks/useWindowSize";
import Highlight from "@/components/Highlight";
import { isManualTimeseries } from "@/utils/timeseries";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

const ESTIMATED_PAGE_HEADER_HEIGHT = 58;
const ESTIMATED_TABLE_HEADER_HEIGHT = 31;

interface Props {
  isLoading: boolean;
  loadingEditExternalId: string | null;
  loadingRemoveExternalId: string | null;
  timeseriesList: TimeseriesExternalIdSearchable[];
  onChangeSortBy: (
    sorter: SorterResult<TimeseriesExternalIdSearchable>,
  ) => unknown;
  onReachedEndOfPage: () => unknown;
  onClickEditDatapoints: (externalId: string) => unknown;
  onConfirmDeleteManualTimeseries: (externalId: string) => unknown;
  onClickDetailsButton: (value: { id: number; unit?: string }) => unknown;
}

export function TimeseriesTable({
  loadingEditExternalId,
  loadingRemoveExternalId,
  isLoading,
  timeseriesList,
  onReachedEndOfPage,
  onChangeSortBy,
  onClickEditDatapoints,
  onConfirmDeleteManualTimeseries,
  onClickDetailsButton,
}: Props) {
  const { height: windowHeight } = useWindowSize();
  const height =
    windowHeight - ESTIMATED_PAGE_HEADER_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT;
  const currentBuildingId = useCurrentBuildingId();
  const { overrideUnits } = useTimeseriesSettings(currentBuildingId);
  const t = useTranslations();

  function handleChangeSort(
    sorter:
      | SorterResult<TimeseriesExternalIdSearchable>
      | SorterResult<TimeseriesExternalIdSearchable>[],
  ) {
    if (!Array.isArray(sorter)) {
      onChangeSortBy(sorter);
    }
  }

  function renderOpenGraphButton(id: number, unit: string | undefined) {
    return (
      <Button
        onClick={() =>
          onClickDetailsButton({
            id,
            unit,
          })
        }
        icon={<LineChartOutlined />}
        aria-label={t("timeseries.chart-button-label")}
      />
    );
  }

  return (
    <TableInfiniteScroll
      height={height}
      rowKey="id"
      loading={isLoading}
      onReachedEndOfPage={onReachedEndOfPage}
      columns={[
        {
          title: t("timeseries.table-column-title.building"),
          key: "subBuilding",
          sorter: true,
          width: 100,
          render: (_, { _formatted }) =>
            _formatted?.subBuilding && (
              <Highlight
                dangerouslySetInnerHTML={{
                  __html: _formatted?.subBuilding,
                }}
              />
            ),
        },
        {
          title: t("timeseries.table-column-title.system"),
          key: "system",
          sorter: true,
          width: 80,
          render: (_, { _formatted }) =>
            _formatted?.system && (
              <Highlight
                dangerouslySetInnerHTML={{ __html: _formatted?.system }}
              />
            ),
        },
        {
          title: t("timeseries.table-column-title.id"),
          key: "externalId",
          sorter: true,
          defaultSortOrder: "ascend",
          width: 360,
          render: (_, { externalId }) =>
            externalId ? (
              <>
                <Highlight
                  dangerouslySetInnerHTML={{
                    __html: externalId,
                  }}
                />
                <ClipboardButton text={externalId} />
              </>
            ) : null,
        },
        {
          title: t("timeseries.table-column-title.name"),
          key: "name",
          sorter: true,
          width: 250,
          render: (_, { _formatted }) => {
            if (!_formatted?.name) {
              return null;
            }
            return (
              <Highlight
                dangerouslySetInnerHTML={{ __html: _formatted?.name }}
              />
            );
          },
        },
        {
          title: t("timeseries.table-column-title.client-id"),
          key: "clientId",
          sorter: true,
          width: 260,
          render: (_, { _formatted }) =>
            typeof _formatted?.clientId === "string" && (
              <Highlight
                dangerouslySetInnerHTML={{ __html: _formatted?.clientId }}
              />
            ),
        },
        {
          title: t("timeseries.table-column-title.label"),
          key: "translatedLabels",
          sorter: true,
          width: 180,
          render: (_, { _formatted }) => {
            return (
              Array.isArray(_formatted?.translatedLabels) && (
                <div style={{ padding: "10px 0" }}>
                  {_formatted?.translatedLabels.map((label: string) => (
                    <Tag key={label}>
                      {
                        <Highlight
                          dangerouslySetInnerHTML={{ __html: label }}
                        />
                      }
                    </Tag>
                  ))}
                </div>
              )
            );
          },
        },
        {
          title: t("timeseries.table-column-title.unit"),
          key: "unit",
          sorter: true,
          width: 80,
          align: "right" as const,
          render: (_, { _formatted, externalId }) =>
            _formatted?.unit && (
              <Highlight
                dangerouslySetInnerHTML={{
                  __html:
                    (overrideUnits && overrideUnits[externalId!]?.unit) ||
                    _formatted?.unit,
                }}
              />
            ),
        },
        {
          title: "",
          key: "actions",
          width: 44,
          render: (_, { id, unit, translatedLabels, externalId }) => {
            if (isManualTimeseries(translatedLabels)) {
              return (
                <Popover
                  content={
                    <Space>
                      {renderOpenGraphButton(id, unit)}
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => onClickEditDatapoints(externalId)}
                        aria-label={t(
                          "timeseries.table-column-title.actions-edit-button-label",
                        )}
                        loading={loadingEditExternalId === externalId}
                      />
                      <Popconfirm
                        title={t(
                          "timeseries.table-column-title.actions-edit-popconfirm-title",
                        )}
                        onConfirm={() =>
                          onConfirmDeleteManualTimeseries(externalId)
                        }
                      >
                        <Button
                          danger
                          icon={<DeleteOutlined />}
                          aria-label={t(
                            "timeseries.table-column-title.actions-delete-button-label",
                          )}
                          loading={loadingRemoveExternalId === externalId}
                        />
                      </Popconfirm>
                    </Space>
                  }
                >
                  <Button icon={<MoreOutlined />} />
                </Popover>
              );
            }
            return renderOpenGraphButton(id, unit);
          },
        },
      ]}
      dataSource={timeseriesList}
      locale={{
        emptyText: t("timeseries.no-timeseries-found"),
      }}
      onChange={(_, __, sorter) => handleChangeSort(sorter)}
    />
  );
}
