import { Node } from "reactflow";
import { useTranslations } from "@properate/translations";
import { getNodeId } from "./helpers/Utils";
import {
  SmallNode,
  NodeOutput,
  NodeInput,
  Header,
  Body,
  NodeOutputLabel,
  NodeInputLabel,
} from "./helpers/NodeComponents";

interface InverseNodeProps {
  operationId: string;
}

export const getEmptyInverseNode = (): Node<InverseNodeProps> => {
  return {
    id: getNodeId("inverse"),
    type: "inverse",
    data: {
      operationId: "inverse",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function InverseNode() {
  const t = useTranslations();

  return (
    <SmallNode>
      <Header>{t("calculation-flow.node-types.inverse")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <NodeOutput>
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <NodeOutputLabel>1 / A</NodeOutputLabel>
        </NodeOutput>
      </Body>
    </SmallNode>
  );
}

export default InverseNode;
