import useSWRMutation from "swr/mutation";
import { RcFile } from "antd/es/upload";
import Compressor from "compressorjs";
import { collection, doc, updateDoc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { message } from "antd";
import { useCallback } from "react";
import { useTranslations } from "@properate/translations";
import axios from "axios";
import { FileUploadResponse } from "@cognite/sdk";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { FloorPlan } from "../types";
import { useFloorPlan } from "../FloorPlanContext";
import { MINIMUM_CANVAS_WIDTH } from "./useFloorPlanBackground";

export function useFloorPlanBackgroundUpdate() {
  const t = useTranslations();
  const floorPlan = useFloorPlan();
  const building = useCurrentBuilding();
  const { optimizeImage } = useImageOptimizer();
  const { client: cogniteClient } = useCogniteClient();

  type Extra = {
    arg: {
      file: RcFile;
    };
  };

  return useSWRMutation(
    [floorPlan, "floor-plan-background"],
    async ([floorPlan], extra: Extra) => {
      const newImageSize = await getImageDimensions(extra.arg.file);
      const currentImageSize = floorPlan.background;
      const optimizedFile = await optimizeImage(extra.arg.file);

      const patch: Omit<Partial<FloorPlan>, "background"> = {};

      if (
        // If the image size has changed we attempt to auto-position the pins
        // into the new image. The floor image is likely structurally the same,
        // but with a different resolution and details.
        currentImageSize.width !== newImageSize.width ||
        currentImageSize.height !== newImageSize.height
      ) {
        patch.pins = [];

        // The pins are relative to the canvas size, not the image size. This
        // function calculates the canvas size based on the image size. See
        // `useFloorPlanBackground` for more information.
        const getCanvasSize = (size: { width: number; height: number }) => {
          const width = Math.max(size.width, MINIMUM_CANVAS_WIDTH);
          const height = size.height * (width / size.width);

          return { width, height };
        };

        const currentCanvas = getCanvasSize(currentImageSize);
        const newCanvas = getCanvasSize(newImageSize);

        for (const index in floorPlan.pins) {
          const pin = floorPlan.pins[index];

          patch.pins[index] = {
            ...pin,
            position: {
              x: (pin.position.x / currentCanvas.width) * newCanvas.width,
              y: (pin.position.y / currentCanvas.height) * newCanvas.height,
            },
          };
        }
      }

      const upload = (await cogniteClient.files.upload({
        name: extra.arg.file.name,
        assetIds: [building.id],
        dataSetId: building.dataSetId,
        mimeType: extra.arg.file.type,
        source: "properate",
        labels: [
          {
            externalId: "internal_schema_background",
          },
        ],
        metadata: {
          width: String(newImageSize.width),
          height: String(newImageSize.height),
        },
      })) as FileUploadResponse;

      await axios.put(upload.uploadUrl, optimizedFile);

      await updateDoc(
        doc(collection(browserFirestore, "floor-plans"), floorPlan.snapshotId),
        {
          background: {
            cogniteFileId: upload.id,
            mimeType: extra.arg.file.type,
            width: newImageSize.width,
            height: newImageSize.height,
          },
          ...patch,
        } satisfies Pick<FloorPlan, "background"> & typeof patch,
      );

      // The old solution never deleted the file from Cognite after replacing it.
      // We're keeping the behaviour here, and address this issue in a scheduled job.
    },
    {
      onSuccess() {
        message.success(t("floor-plan-v2.messages.image-updated"));
      },
      onError() {
        message.error(t("floor-plan-v2.messages.image-updated-failed"));
      },
    },
  );
}

export function getImageDimensions(file: RcFile) {
  type Size = {
    width: number;
    height: number;
  };

  return new Promise<Size>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
}

// todo: convert image to avif
export function useImageOptimizer() {
  const optimizeImage = useCallback(async (userFile: RcFile) => {
    return new Promise((resolve) => {
      new Compressor(userFile, {
        quality: 0.8,
        retainExif: false,
        mimeType: "image/webp",
        success(compressedFile) {
          resolve(compressedFile);
        },
        error() {
          resolve(userFile);
        },
      });
    });
  }, []);

  return {
    optimizeImage,
  };
}
