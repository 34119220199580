import { useUser } from "@properate/auth";
import { useMemo } from "react";
import { useRootAssets } from "@/hooks/useRootAssets";
import { AccessModules, allAccessModules } from "@/features/organizationAdmin";
import { OrganizationsFirebase } from "@/services/organization";
import { useAliasConfig } from "@/services/alias";
import { useBuildingsSpec } from "@/services/buildingSpec";

export type AccessModuleBuilding = {
  key: number;
  name: string;
  modules?: AccessModules[];
  street: string;
  place?: string;
  alias?: string;
  image?: string;
};

export type AccessModuleOrganization = {
  name: string;
  modules: AccessModules[];
  customizedModules: AccessModules[];
  buildings: AccessModuleBuilding[];
};

export const useAllOrganizations = (
  organizationModules: OrganizationsFirebase,
) => {
  const rootAssets = useRootAssets();
  const { ownerAlias } = useUser();
  const aliasConfig = useAliasConfig(ownerAlias);
  const buildingsSpec = useBuildingsSpec();

  const buildingImages = useMemo(
    () =>
      buildingsSpec.reduce<Record<string, string>>(function (map, obj) {
        map[obj.snapshotId] = obj.imageUrl;
        return map;
      }, {}),
    [buildingsSpec],
  );

  const organizationsByName = useMemo(
    () =>
      rootAssets.reduce(
        (acc, asset) => {
          if (!asset.metadata?.owner || !asset.dataSetId) return acc;

          const building = {
            key: asset.dataSetId,
            name: asset.metadata?.Adresse,
            image: buildingImages[asset.id],
            alias: aliasConfig?.buildings?.[asset.id],
            street: asset.metadata?.Adresse,
            place: asset.metadata?.Poststed,
            modules:
              organizationModules[asset.metadata.owner]?.buildings?.[
                asset.dataSetId
              ]?.modules ||
              organizationModules[asset.metadata.owner]?.modules ||
              allAccessModules,
          };

          const isBuildingModulesSelected = Boolean(
            organizationModules[asset.metadata.owner]?.buildings?.[
              asset.dataSetId
            ]?.modules,
          );

          if (acc[asset.metadata.owner]) {
            acc[asset.metadata.owner] = {
              ...acc[asset.metadata.owner],
              customizedModules: !isBuildingModulesSelected
                ? acc[asset.metadata.owner].customizedModules
                : [
                    ...new Set([
                      ...acc[asset.metadata.owner].customizedModules,
                      ...building.modules,
                    ]),
                  ],
              buildings: [...acc[asset.metadata.owner].buildings, building],
            };
          } else {
            acc[asset.metadata.owner] = {
              name: asset.metadata.owner,
              modules:
                organizationModules[asset.metadata.owner]?.modules ||
                allAccessModules,
              customizedModules: !isBuildingModulesSelected
                ? []
                : building.modules,
              buildings: [building],
            };
          }
          return acc;
        },
        {} as {
          [key: string]: AccessModuleOrganization;
        },
      ),
    [aliasConfig?.buildings, buildingImages, organizationModules, rootAssets],
  );

  return useMemo(
    () => Object.values(organizationsByName),
    [organizationsByName],
  );
};
