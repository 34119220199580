import { useTranslations } from "@properate/translations";
import { useSidebarData } from "@properate/ui";
import { NoteLevelPill } from "./NoteLevelPill";
import { dateFormat } from "./utils";
import {
  NoteMetaEntryProps,
  NoteMetaField,
  NoteMetaProps,
  NotesSidebarValues,
} from "./types";
import { NoteMetaAssetList } from "./NoteMetaAssetList";
import { NoteSourceText } from "./NoteSourceText";

function NoteMetaEntry({
  label,
  content,
  ...options
}: Readonly<NoteMetaEntryProps>) {
  return (
    <div
      className={`flex justify-start items-center ${options.className ?? ""}`}
    >
      <dt className={`${options.labelClassName ?? ""}`}>{label}</dt>
      <dd className={`${options.contentClassName ?? "ml-1"}`}>{content}</dd>
    </div>
  );
}

function NoteMetaChangeLog({
  user,
  timestamp,
  label,
}: Readonly<{
  user?: string;
  timestamp?: Date;
  label: string;
}>) {
  if (!user || !timestamp) {
    return null;
  }

  const date = dateFormat(timestamp, true);
  const content = `${user}, ${date}`;
  return <NoteMetaEntry label={label} content={content} />;
}

export function NoteMeta({
  note,
  showFields,
  hideFields,
  fieldOptions,
}: NoteMetaProps) {
  const t = useTranslations();
  const { buildings } = useSidebarData<NotesSidebarValues>();

  function showField(field: NoteMetaField): boolean {
    return (
      (!showFields || showFields.includes(field)) &&
      (!hideFields || !hideFields.includes(field))
    );
  }

  return (
    <dl className="text-muted-foreground flex flex-col gap-2 text-xs my-0">
      {showField(NoteMetaField.Timeseries) && (
        <NoteMetaEntry
          label={t("notes.meta.timeseries")}
          contentClassName={"ml-0"}
          content={
            <NoteMetaAssetList
              assetIds={note.assetIds}
              fieldOptions={fieldOptions}
            />
          }
          labelClassName="sr-only"
        />
      )}
      {showField(NoteMetaField.Level) && (
        <NoteMetaEntry
          label={t("notes.meta.level")}
          content={<NoteLevelPill level={note.level} />}
        />
      )}
      {showField(NoteMetaField.Changelog) && (
        <>
          <NoteMetaChangeLog
            label={t("notes.meta.created-by")}
            user={note?.createdBy}
            timestamp={note.createdTime}
          />
          <NoteMetaChangeLog
            label={t("notes.meta.updated-by")}
            user={note.lastUpdatedBy}
            timestamp={note.lastUpdatedTime}
          />
        </>
      )}
      {showField(NoteMetaField.Source) && (
        <NoteMetaEntry
          label={t("notes.meta.source")}
          content={<NoteSourceText source={note.source} />}
        />
      )}
      {showField(NoteMetaField.Building) && (
        <NoteMetaEntry
          label={t("notes.meta.building")}
          content={
            buildings.find(({ id }) => id === note.dataSetId)?.name ||
            t("ui.unknown")
          }
        />
      )}
    </dl>
  );
}
