import { Button } from "antd";
import { BugOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useNavigate } from "react-router-dom";

export default function SomethingWrong({
  hideBackButton,
  extraMessage,
}: {
  hideBackButton?: boolean;
  extraMessage?: string;
}) {
  const t = useTranslations();
  const navigate = useNavigate();

  function handleBack() {
    navigate("../incidents");
  }

  function renderBackButton() {
    if (hideBackButton) {
      return null;
    }
    return (
      <Button type="default" danger htmlType="button" onClick={handleBack}>
        {t("incident.something-wrong.back")}
      </Button>
    );
  }

  return (
    <div className="h-full w-full flex flex-col items-center justify-center">
      <BugOutlined rotate={30} className={"text-4xl text-error"} />
      <h1 className={"text-xl mt-4 mb-0"}>
        {t("incident.something-wrong.title")}
      </h1>
      <p className="mt-2 text-center">
        {extraMessage && (
          <>
            <span>{extraMessage}</span>
            <br />
          </>
        )}
        {t("incident.something-wrong.subtitle")}
      </p>
      {renderBackButton()}
    </div>
  );
}
