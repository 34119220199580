import { Button } from "antd";
import { AlignLeftIcon } from "lucide-react";
import { useTranslations } from "@properate/translations";
import { useState } from "react";
import { RichTextEditor } from "@properate/ui";
import { useUpdateTask } from "../../hooks/use-update-task";
import { useBuildingContacts } from "./hooks/useBuildingContacts";

type Props = {
  taskId: string;
  buildingId: number;
  description: string | undefined;
  isEditable: boolean;
};

export function DescriptionInput(props: Props) {
  const t = useTranslations();
  const updateTask = useUpdateTask();
  const { data: userEmails = [] } = useBuildingContacts(props.buildingId);
  const [description, setDescription] = useState(props.description ?? "");
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const strippedDescription = description === "<p><br></p>" ? "" : description;

  if (!props.isEditable && !strippedDescription) {
    return null;
  }

  return (
    <div>
      <div className="flex items-center justify-between h-8">
        <div className="flex items-center gap-4">
          <AlignLeftIcon className="w-6 h-6" />
          <div className="text-[13px] tracking-wide">
            {t("task.field.description")}
          </div>
        </div>
        {!isEditingDescription && props.isEditable && (
          <Button onClick={() => setIsEditingDescription(true)}>
            {t("ui.edit")}
          </Button>
        )}
        {isEditingDescription && (
          <Button
            type="primary"
            loading={updateTask.isMutating}
            onClick={async () => {
              await updateTask.trigger({
                where: { taskId: props.taskId },
                data: { description },
              });

              setIsEditingDescription(false);
            }}
          >
            {t("ui.save")}
          </Button>
        )}
      </div>
      <div className="my-4 ml-10">
        {!isEditingDescription && strippedDescription && (
          <div
            className="max-w-full prose"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
        {!isEditingDescription && !strippedDescription && (
          <div className="flex items-center justify-center h-24 rounded-md bg-card">
            {t("task.ui.noDescription")}
          </div>
        )}
        {isEditingDescription && (
          <RichTextEditor
            value={description}
            onChange={setDescription}
            mentionables={userEmails}
          />
        )}
      </div>
    </div>
  );
}
