import { useContext, useEffect, useState } from "react";
import {
  sankey,
  sankeyLinkHorizontal,
  SankeyNode,
  SankeyNodeMinimal,
} from "d3-sankey";
import { Tooltip } from "antd";

import { ThemeContext } from "styled-components";
import { PLink, PNode } from "@/features/energy";

type SankeyDiagramProps = {
  width: number;
  height: number;
  pLinks: PLink[];
  pNodes: PNode[];
};
export const SankeyDiagramV2 = ({
  width,
  height,
  pLinks,
  pNodes,
}: SankeyDiagramProps) => {
  const themeContext = useContext(ThemeContext);
  const [nodes, setNodes] = useState<SankeyNodeMinimal<any, any>[]>([]);
  const [links, setLinks] = useState<SankeyNodeMinimal<any, any>[]>([]);
  const color = [
    themeContext.primary,
    themeContext.accent2,
    themeContext.accent3,
    themeContext.accent4,
    themeContext.accent5,
    themeContext.accent6,
    themeContext.accent7,
    themeContext.accent8,
    themeContext.accent9,
    themeContext.accent10,
    themeContext.accent11,
  ];
  useEffect(() => {
    const d3Sankey = sankey()
      .nodeWidth(20)
      .nodePadding(80)
      .extent([
        [1, 1],
        [width - 250, height - 45],
      ]);
    const { nodes, links } = d3Sankey({
      nodes: pNodes,
      links: pLinks,
    });
    setNodes(nodes);
    setLinks(links);
  }, [pNodes, pLinks, width, height]);

  // bounds
  const padding = 24;
  const header = 0;
  const graphWidth = width > 0 ? width - padding * 2 : 0;
  const graphHeight = height - padding * 2 - header;

  return (
    <>
      {nodes.length > 0 && links.length > 0 ? (
        <svg
          width={graphWidth}
          height={graphHeight}
          style={{ marginTop: 35, overflow: "visible" }}
        >
          <g>
            {links.length > 0 &&
              links.map((link: any) => {
                return (
                  <SankeyLinkElement
                    link={link}
                    key={link.index}
                    color={color[link.source.index]}
                  />
                );
              })}
            {nodes.length > 0 &&
              nodes.map((node: any, i: number) => (
                <Node
                  {...node}
                  color={color[i]}
                  key={node.index}
                  textColor={themeContext.neutral1}
                  unit={node.unit}
                />
              ))}
          </g>
        </svg>
      ) : (
        <></>
      )}
    </>
  );
};

const numberFormat = new Intl.NumberFormat("nb-NO", {
  maximumFractionDigits: 1,
});

const Node = ({
  name,
  value,
  unit,
  x0,
  x1,
  y0,
  y1,
  color,
  textColor,
}: SankeyNode<any, any>) => {
  return (
    <>
      <rect x={x0} y={y0} width={x1 - x0} height={y1 - y0} fill={color} />
      <foreignObject x={x1 + 5} y={(y1 + y0) / 2 - 20} width={250} height={40}>
        <div
          style={{
            color: textColor,
            textOverflow: "ellipsis",
            overflowY: "visible",
            overflowX: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <Tooltip title={name}>{name}</Tooltip>
          <br />
          {value
            ? `${numberFormat.format(value)}${unit === "kWh" ? " kWh" : "%"}`
            : ""}
        </div>
      </foreignObject>
    </>
  );
};

const SankeyLinkElement = ({ link, color }: any) => {
  return (
    <>
      <path
        d={sankeyLinkHorizontal()(link)!}
        style={{
          fill: "none",
          strokeOpacity: ".3",
          stroke: color,
          strokeWidth: Math.max(1, link.width),
        }}
      />
    </>
  );
};
