import { DatapointAggregates, DoubleDatapoints } from "@cognite/sdk";
import dayjs from "@properate/dayjs";
import { useGetTimeseriesListWithData } from "@/hooks/useGetTimeseriesListWithData";
import { useGetTimeseriesListWithLatestData } from "@/hooks/useGetTimeseriesListWithLatestData";
import { SolarCellProductionPeriod } from "../types";
import { getStartTimestampFromPeriod } from "../utils";

function sumDatapointSums(datapoints: DatapointAggregates[]) {
  return datapoints
    .flatMap(({ datapoints }) => datapoints)
    .reduce((countAcc, datapoint) => countAcc + datapoint.sum!, 0);
}

function sumDoubleDatapoints(datapoints: DoubleDatapoints[]) {
  return datapoints
    .flatMap(({ datapoints }) => datapoints)
    .reduce((countAcc, datapoint) => countAcc + datapoint.value!, 0);
}

export function useGetTimeseriesProductionStatistics(
  timeseriesIds: number[],
  period: SolarCellProductionPeriod,
  maxCapacity?: number,
) {
  const {
    timeseriesListWithData: timeseriesListWithDataForWholePeriod,
    isLoading: isLoadingWholePeriod,
  } = useGetTimeseriesListWithData<DatapointAggregates>({
    items: timeseriesIds.map((id) => ({ id })),
    aggregates: ["sum"],
    granularity: "1d",
    start: getStartTimestampFromPeriod(period),
    end: "now",
  });
  const {
    timeseriesListWithData: timeseriesListWithDataForToday,
    isLoading: isLoadingToday,
  } = useGetTimeseriesListWithData<DatapointAggregates>({
    items: timeseriesIds.map((id) => ({ id })),
    aggregates: ["sum"],
    granularity: "1m",
    start: dayjs().startOf("day").valueOf(),
    end: "now",
  });
  const { timeseriesListWithLatestData, isLoading: isLoadingLatestData } =
    useGetTimeseriesListWithLatestData(timeseriesIds.map((id) => ({ id })));
  const productionLastDatapoints = sumDoubleDatapoints(
    timeseriesListWithLatestData,
  );
  return {
    data: {
      productionLastDatapoints,
      productionToday: sumDatapointSums(timeseriesListWithDataForToday),
      productionTotal: sumDatapointSums(timeseriesListWithDataForWholePeriod),
      productionRelative: maxCapacity
        ? (productionLastDatapoints / maxCapacity) * 100
        : null,
    },
    isLoading: isLoadingWholePeriod || isLoadingToday || isLoadingLatestData,
  };
}
