import { Button, Card, Popconfirm, Space } from "antd";
import { Link, useLocation } from "react-router-dom";
import { WithSnapshotId } from "@properate/common";
import { DeleteOutlined, ImportOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { ShareButtonWithDialog } from "@/components/ShareButtonWithDialog/ShareButtonWithDialog";
import { getCurrentStartOfWeekMonthDateOrReferenceDate } from "@/pages/common/utils";
import { HeatMapComponent } from "./HeatMap";
import type { HeatMap } from "../types";

interface Props {
  heatMap: WithSnapshotId<HeatMap>;
  onConfirmRemoval: (snapshotId: string) => unknown;
  width: number;
}

export function HeatMapListCard({ heatMap, onConfirmRemoval, width }: Props) {
  const t = useTranslations();
  const location = useLocation();
  const shareLink = `${window.location.origin}${location.pathname}/${heatMap.snapshotId}`;

  const height = width * 0.6;
  const bodyPadding = 12;

  return (
    <Card
      title={
        <Space>
          <div>{heatMap.name}</div>
          {typeof heatMap.importedFrom === "string" && (
            <ImportOutlined title={t("analysis.heat-map.imported")} />
          )}
        </Space>
      }
      extra={
        <Space>
          <Popconfirm
            title={t("analysis.heat-map.delete-confirmation")}
            okText={t("analysis.heat-map.delete")}
            onConfirm={() => onConfirmRemoval(heatMap.snapshotId)}
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
          <ShareButtonWithDialog
            title={t("analysis.heat-map.share-heatmap")}
            shareLink={shareLink}
          >
            {t("analysis.share-text.gauge")}
          </ShareButtonWithDialog>
        </Space>
      }
      styles={{
        body: {
          padding: bodyPadding,
        },
      }}
      hoverable
    >
      <Link to={heatMap.snapshotId}>
        <HeatMapComponent
          name={heatMap.name}
          aggregate={heatMap.aggregate}
          color={heatMap.color}
          referenceDate={getCurrentStartOfWeekMonthDateOrReferenceDate(
            heatMap.viewMode,
            heatMap.referenceDate,
            heatMap.isCurrentStartOfWeekMonthChecked,
          )}
          isCurrentStartOfWeekMonthChecked={
            heatMap.isCurrentStartOfWeekMonthChecked
          }
          timeseriesId={heatMap.timeseriesId}
          viewMode={heatMap.viewMode}
          showWeekNumbers={heatMap.showWeekNumbers}
          width={width - bodyPadding * 2}
          height={height}
          compact
        />
      </Link>
    </Card>
  );
}
