import { Form, App, Row, DatePicker, Tooltip, Button } from "antd";
import dayjs, { Dayjs } from "@properate/dayjs";
import { FileInfo } from "@cognite/sdk";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { useMemo } from "react";
import {
  useLastHalfYearPresets,
  useLastXQuarterPresets,
  useLastXMonthPreset,
} from "@/utils/date";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { ModalInstance } from "@/utils/types";
import { reports } from "../../eepApi";
import { disabledFutureDates } from "../../utils/helpers";
import { ReactComponent as VentilationSVG } from "./icon/ventilation.svg";

type Props = {
  lang: "en" | "no";
  modal: ModalInstance;
  addFile: (files: FileInfo[]) => void;
};
type RangeValueType = [Dayjs | null, Dayjs | null] | null;

const MINIMUM_DAYS_TO_CREATE_GOOD_REPORT = 60;
export const VentilationBigButtons = ({ lang, modal, addFile }: Props) => {
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const user = useUser();
  const building = useCurrentBuilding();
  const t = useTranslations();

  const handleChange = (dates: RangeValueType) => {
    if (dates && dates[0] && dates[1]) {
      const start = dayjs(dates[0]);
      const end = dayjs(dates[1]);
      const duration = end.diff(start, "days");

      if (duration < MINIMUM_DAYS_TO_CREATE_GOOD_REPORT) {
        notification.warning({
          message: t(
            "reports.ventilation.create.notification-min-days-to-create-report",
            { duration },
          ),
          duration: 0,
        });
      }
    }
  };

  const last3MonthPreset = useLastXMonthPreset(3);
  const last6MonthPreset = useLastXMonthPreset(6);
  const last12MonthPreset = useLastXMonthPreset(12);
  const last3QuarterPresets = useLastXQuarterPresets(3);
  const lastHalfYearPresets = useLastHalfYearPresets();

  const presets = useMemo(
    () => [
      last3MonthPreset,
      last6MonthPreset,
      last12MonthPreset,
      ...last3QuarterPresets,
      ...lastHalfYearPresets,
    ],
    [
      last3MonthPreset,
      last6MonthPreset,
      last12MonthPreset,
      last3QuarterPresets,
      lastHalfYearPresets,
    ],
  );

  return (
    <div
      style={{ padding: 0 }}
      onClick={async () => {
        modal.confirm({
          title: t("reports.ventilation.create.title"),
          icon: null,
          onOk: async () => {
            const validated = await form.validateFields();
            if (!validated.errorFields) {
              try {
                // 2022-01-01T00:00:00
                const data = {
                  building_list: [building!.externalId!],
                  report_type: "ventilation",
                  period_start: validated.period[0]
                    .startOf("hour")
                    .utc()
                    .valueOf(),
                  period_end: validated.period[1]
                    .startOf("hour")
                    .utc()
                    .valueOf(),
                  user: user.email,
                  language: lang,
                };
                const result = await reports(data);

                addFile(result);

                notification.success({
                  message: t(
                    "reports.ventilation.create.notification-ordered-report",
                  ),
                });
              } catch (error: any) {
                console.warn("error", error);
                const errorMessage = String(error.message);
                notification.error({
                  message: t(
                    "reports.ventilation.create.failed-to-generate-report",
                    { errorMessage },
                  ),
                });
              }
              form.resetFields();
              return false;
            }
            return true;
          },
          content: (
            <Form form={form} requiredMark={false} layout="horizontal">
              <p>{t("reports.ventilation.create.brief-description")}</p>
              <p>{t("reports.ventilation.create.detailed-description")}</p>
              <Form.Item
                label={t("reports.ventilation.create.period-label")}
                name="period"
                rules={[
                  {
                    required: true,
                    message: t(
                      "reports.ventilation.create.select-period-rules-label",
                    ),
                  },
                ]}
              >
                <DatePicker.RangePicker
                  disabledDate={disabledFutureDates}
                  onChange={handleChange}
                  presets={presets}
                />
              </Form.Item>
            </Form>
          ),
          onCancel: () => {
            form.resetFields();
          },
        });
      }}
    >
      <Row
        justify="center"
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        data-testid="create-ventilation-report"
      >
        <Tooltip title={t("reports.ventilation.create.title")}>
          <Button
            size="large"
            style={{
              width: "calc(100% - 40px)",
              height: 116,
              margin: "0 10px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <VentilationSVG />
              <div>
                <span>{t("reports.ventilation.create.title")}</span>{" "}
              </div>
            </div>
          </Button>
        </Tooltip>
      </Row>
    </div>
  );
};
