import { Spin } from "antd";
import { CheckOutlined, WarningFilled } from "@ant-design/icons";
import { cn } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import useSWR from "swr";
import { getSystemCodeFromExternalId } from "@properate/common";
import { useEffect, useState } from "react";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useProperateCogniteClient } from "@/context/ProperateCogniteClientContext";
import { useFloorPlanPinTimeseriesLatestValue } from "./hooks/useFloorPlanPinTimeseriesLatestValue";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";
import { useFloorPlanPinTimeseries } from "./hooks/useFloorPlanPinTimeseries";
import { useFloorPlanPinAlarms } from "./hooks/useFloorPlanPinAlarms";
import { useFloorPlanPinPopover } from "./FloorPlanMapPinPopoverContext";
import { useFloorPlanEditMode } from "./FloorPlanContext";
import { useFloorPlanPinTimeseriesId } from "./hooks/useFloorPlanPinTimeseriesId";

export function FloorPlanMapPinValue() {
  const { latestDatapoint, isLoading, error } =
    useFloorPlanPinTimeseriesLatestValue();
  const pin = useFloorPlanPin();

  if (pin.type !== "room") {
    if (isLoading) {
      return (
        <div className="p-1 px-4">
          <Spin size="small" />
        </div>
      );
    }

    if (error) {
      return <div className="py-1 px-4 font-bold text-red-500">--</div>;
    }

    if (!latestDatapoint) {
      return <div className="py-1 px-4 text-muted-foreground">--</div>;
    }
  }

  if (pin.variant === "minimal") {
    return <MinimalView />;
  }

  if (pin.variant === "value") {
    return <ValueView />;
  }

  if (pin.variant === "value+name") {
    return <ValueNameView />;
  }

  console.warn("Unknown view type `%s`.", pin.variant);

  return <ValueView />;
}

function AlarmIcon(props: { forceRender?: boolean }) {
  const pin = useFloorPlanPin();
  const alarms = useFloorPlanPinAlarms();

  const hasInfo = alarms.some((alarm) => alarm.level === "status");
  const hasWarning = alarms.some((alarm) => alarm.level === "warning");
  const hasError = alarms.some((alarm) => alarm.level === "error");
  const hasAlarmSet =
    props.forceRender || (pin.type !== "room" && pin.alarm !== null);

  if (!hasAlarmSet) {
    return null;
  }

  return (
    <div
      className={cn(
        "h-6 w-6 rounded-full text-white text-lg flex items-center justify-center",
        {
          "bg-properate": hasAlarmSet,
          "bg-gray-500": hasInfo,
          "bg-yellow-400": hasWarning,
          "bg-red-600": hasError,
        },
      )}
    >
      {hasError || hasWarning ? "!" : <CheckOutlined className="text-base" />}
    </div>
  );
}

function FormattedValue() {
  const alarms = useFloorPlanPinAlarms();
  const { formattedValue } = useFloorPlanPinTimeseriesLatestValue();

  const isSensorWorking = alarms.every(
    (alarm) => alarm.isSensorWorking !== false,
  );

  return (
    <div className={cn({ "text-yellow-400": !isSensorWorking })}>
      {formattedValue}
    </div>
  );
}

function MinimalView() {
  const [isEditing] = useFloorPlanEditMode();

  return (
    <div className="h-8 px-1 flex items-center group gap-2">
      <AlarmIcon forceRender />
      {!isEditing && (
        <div className="group-hover:block hidden pr-4">
          <FormattedValue />
        </div>
      )}
    </div>
  );
}

function ValueView() {
  const pin = useFloorPlanPin();
  const hasAlarmSet = pin.type !== "room" && pin.alarm !== null;

  return (
    <div
      className={cn("flex items-center h-8 gap-2 px-4", {
        "pl-1": hasAlarmSet,
      })}
    >
      <AlarmIcon />
      <FormattedValue />
    </div>
  );
}

function ValueNameView() {
  const pin = useFloorPlanPin();
  const [isPopoverOpen] = useFloorPlanPinPopover();
  const [translateX, setTranslateX] = useState("-50%");

  useEffect(() => {
    if (pin.position.x < 25) {
      setTranslateX("-25%");
    }
  }, [pin.position.x]);

  if (isPopoverOpen) {
    return <ValueView />;
  }

  return (
    <div className="relative">
      <div
        className={cn(
          "absolute",
          "box-border w-32 p-1 rounded-md",
          "border border-solid border-border shadow-md",
          "bg-background text-foreground",
          "text-left",
        )}
        style={{
          left: "50%",
          bottom: "2.25rem",
          transform: `translateX(${translateX})`,
        }}
      >
        {pin.type === "room" ? (
          <ValueNameViewRoomDescription roomId={pin.roomId} />
        ) : (
          <ValueNameViewTimeseriesDescription />
        )}
      </div>
      <ValueView />
    </div>
  );
}

export function ValueNameViewRoomDescription(props: { roomId: number }) {
  const t = useTranslations();
  const { client } = useCogniteClient();

  const room = useSWR(["room", props.roomId], async () =>
    client.assets
      .retrieve([
        {
          id: props.roomId,
        },
      ])
      .then((assets) => assets.at(0)),
  );

  return (
    <>
      {room.data
        ? room.data.name +
          (room.data.description ? ` ${room.data.description}` : "")
        : t("ui.loading")}
    </>
  );
}

export function PinAlarms() {
  const alarms = useFloorPlanPinAlarms();

  return alarms.map((alarm) => (
    <div
      key={alarm.message}
      className={cn("mb-1", {
        "text-gray-600": alarm.level === "status",
        "text-yellow-400": alarm.level === "warning",
        "text-red-600": alarm.level === "error",
      })}
    >
      {alarm.level === "status" ? <CheckOutlined /> : <WarningFilled />}{" "}
      {alarm.message}
    </div>
  ));
}

export function ValueNameViewTimeseriesDescription(props: {
  hideAlarms?: boolean;
}) {
  const t = useTranslations();
  const client = useProperateCogniteClient();
  const timeseries = useFloorPlanPinTimeseries();
  const timeseriesId = useFloorPlanPinTimeseriesId();

  // We can't return JSX from the SWR hook, so we use a line break token inside the
  // returned string to hint the component to render the string as multiple lines.
  const LINE_BREAK_TOKEN = "\n ";

  const title = useSWR([timeseries.isLoading, timeseries.data], async () => {
    if (timeseries.isLoading) {
      return undefined;
    }

    if (!timeseries.data) {
      return t("floor-plan.timeseries-not-found.message", {
        id: timeseriesId,
      });
    }

    if (timeseries.data.externalId && timeseries.data.assetId) {
      const systemCode = getSystemCodeFromExternalId(
        timeseries.data.externalId,
      );

      if (systemCode.startsWith("200")) {
        const room = await client.getRoomInfo(timeseries.data.assetId);

        if (room) {
          return `${timeseries.data.description}${LINE_BREAK_TOKEN}${room.name}`;
        }
      }
    }

    return `${timeseries.data.description}${LINE_BREAK_TOKEN}${timeseries.data.name}`;
  });

  if (title.data) {
    return (
      <>
        {!props.hideAlarms && <PinAlarms />}
        {title.data.split(LINE_BREAK_TOKEN).map((line) => (
          <div key={line}>{line}</div>
        ))}
      </>
    );
  }

  return t("ui.loading");
}
