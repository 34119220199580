import { useEffect, useMemo, useState } from "react";
import {
  Alarm,
  AlarmWithId,
  AlarmWithRequiredId,
  formatTimeseriesName,
  getSchedule,
} from "@properate/common";
import { useTranslations } from "@properate/translations";
import { Timeseries } from "@cognite/sdk";
import { useNavigate } from "react-router-dom";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useHelp } from "@/context/HelpContext";
import { useRootAssets } from "@/hooks/useRootAssets";
import {
  AlarmsTable,
  AlarmsTableFilters,
  AlarmsTableFiltersProvider,
  useDeleteAlarm,
} from "@/features/alarms";
import FullLayout from "@/layout/FullLayout";
import usePageTitle from "@/hooks/usePageTitle";
import { useWindowSize } from "@/hooks/useWindowSize";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import GraphModal from "../common/SchemaGraph";
import { OrgAlarmsNotesSidebar } from "./OrgAlarmsNotesSidebar";

const ESTIMATED_TABLE_HEADER_HEIGHT = 29;

function OrgAlarmsPage() {
  const t = useTranslations();
  usePageTitle(t("org-alarms.organization-alarms"));
  const navigate = useNavigate();
  const { setHelp } = useHelp();
  const rootAssets = useRootAssets();
  const { trigger: deleteAlarm } = useDeleteAlarm();
  const [openAlarm, setOpenAlarm] = useState<Alarm | AlarmWithId | null>(null);
  const buildingId = openAlarm?.buildingId;
  const buildingIds = useMemo(
    () => rootAssets.map((asset) => asset.id),
    [rootAssets],
  );

  const [isEditingComparisonTimeseries, setIsEditingComparisonTimeseries] =
    useState(false);
  const { height: windowHeight } = useWindowSize();
  const comparisonTimeseries =
    openAlarm?.compareWithTsMax || openAlarm?.compareWithTsMin;

  useEffect(() => {
    setHelp({
      title: t("org-alarms.title"),
      content: t.rich("org-alarms.help", {
        p: (text) => <p>{text}</p>,
      }),
    });
  }, [setHelp, t]);

  const handleSelectTimeseries = async ([timeseries]: Timeseries[]) => {
    if (openAlarm?.type === "CUSTOM_TIMESERIES_MAX") {
      return setOpenAlarm({
        ...openAlarm,
        compareWithTsMax: {
          ids: [timeseries.id],
          name: formatTimeseriesName(timeseries),
          schedule: getSchedule(timeseries),
        },
      });
    }
    if (openAlarm?.type === "CUSTOM_TIMESERIES_MIN") {
      return setOpenAlarm({
        ...openAlarm,
        compareWithTsMin: {
          ids: [timeseries.id],
          name: formatTimeseriesName(timeseries),
          schedule: getSchedule(timeseries),
        },
      });
    }
  };

  return (
    <AlarmsTableFiltersProvider buildingIds={buildingIds}>
      <FullLayout
        pageName={t("org-alarms.title")}
        headerRight={<AlarmsTableFilters />}
        hideSearchInput
        showNotesButton
      >
        <AlarmsTable
          buildingIds={buildingIds}
          onRowClick={(alarm) => {
            navigate(`/asset/${alarm.buildingId}/alarms/${alarm.snapshotId}`);
          }}
          tableHeight={
            windowHeight - ESTIMATED_TABLE_HEADER_HEIGHT - PAGE_LAYOUT_HEIGHT
          }
        />
        {typeof buildingId === "number" && openAlarm && (
          <GraphModal
            timeseriesInfo={{
              id: openAlarm.timeseries ? openAlarm.timeseries.ids[0]! : 0,
              unit: openAlarm.timeseries?.unit,
            }}
            hide={() => setOpenAlarm(null)}
            showAlarm={openAlarm}
            deleteTimeseries={async () => {
              if ("snapshotId" in openAlarm) {
                await deleteAlarm(openAlarm as AlarmWithRequiredId);
              }
              setOpenAlarm(null);
            }}
            comparisonTimeseriesMin={openAlarm.compareWithTsMin}
            comparisonTimeseriesMax={openAlarm.compareWithTsMax}
            onEditMaxTimeseries={() => setIsEditingComparisonTimeseries(true)}
            onEditMinTimeseries={() => setIsEditingComparisonTimeseries(true)}
            expanded
            buildingId={openAlarm.buildingId}
          />
        )}
        {typeof buildingId === "number" && openAlarm && (
          <TimeseriesSelectionModal
            initialFilters={{
              buildingId: openAlarm?.buildingId,
              unit: openAlarm?.timeseries!.unit,
            }}
            hiddenFilters={["building"]}
            onHide={() => setIsEditingComparisonTimeseries(false)}
            onOk={handleSelectTimeseries}
            selectedIds={comparisonTimeseries?.ids || []}
            open={Boolean(openAlarm && isEditingComparisonTimeseries)}
            max={1}
          />
        )}
      </FullLayout>
      <OrgAlarmsNotesSidebar />
    </AlarmsTableFiltersProvider>
  );
}

export default OrgAlarmsPage;
