import useSwr from "swr";
import { App } from "antd";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "@/context/CogniteClientContext";

export function useGetTimeseriesList(ids: number[]) {
  const t = useTranslations();
  const { message } = App.useApp();
  const { client } = useCogniteClient();
  const {
    data: timeseriesList = [],
    isLoading,
    error,
  } = useSwr(
    ids.length > 0
      ? {
          type: "timeseries.retrieve",
          query: ids.map((id) => ({ id })),
          options: {
            ignoreUnknownIds: true,
          },
        }
      : null,
    ({ query, options }) => client.timeseries.retrieve(query, options),
  );
  if (error) {
    const errorMessage = t("common.timeseries-modal.something-went-wrong");
    console.error(errorMessage, error);
    message.error(errorMessage);
  }
  return {
    timeseriesList,
    isLoading,
  };
}
