import { MeiliSearch } from "meilisearch";
import { apiUrl, cdfProject } from "./constants";
import { getAuthToken } from "./auth";

export async function getMeiliSearch() {
  return new MeiliSearch({
    host: apiUrl,
    requestConfig: {
      headers: {
        Authorization: `Bearer ${await getAuthToken()}`,
        "Cdf-project": cdfProject,
      },
    },
  });
}
