import { DoubleDatapoint } from "@cognite/sdk";
import { SetStateAction } from "react";
import { Granularity } from "@/utils/helpers";
import { NoteOperationalPeriods, OperationalPeriods } from "@/features/energy";
import { SelectedNodeForPrefilledNote } from "@/features/notes";

export type TimeseriesType =
  | "hierarchy"
  | "energyMeter"
  | "generated"
  | "sources";

export type EnergyData = {
  datapoints: DoubleDatapoint[];
  aggregatedDatapoints: DoubleDatapoint[];
  key: string;
};
export type PNode = {
  node: number;
  name: string;
  value: number;
  unit: string;
};
export type PLink = {
  source: number;
  target: number;
  value: number;
  unit: string;
};

export type EnergyFlowData = {
  nodes: PNode[];
  links: PLink[];
};

export type EnergyFlowPage = {
  start: Date;
  end: Date;
  organizations: Promise<{ name: string; id: number }[]>;
  id: string;
  tenantRootAssetId: number;
  last7Days: Promise<EnergyFlowData>;
  last365Days: Promise<EnergyFlowData>;
  selectedPeriod: Promise<EnergyFlowData>;
  assetIds: Promise<number[]>;
  type: "energyFlow" | "energySourceFlow";
};

export type Legend = {
  name: string;
  externalId: string;
  expression?: string;
  source?: string;
  total?: number;
  "next-total"?: number;
}[];

export type EnergyPage = {
  assetId: string;
  data: Promise<{ [key: string]: number | string }[]>;
  start: Date;
  end: Date;
  nonOperationalPeriods: OperationalPeriods[];
  holidayPeriods: OperationalPeriods[];
  weather: Promise<DoubleDatapoint[]>;
  usableFloorArea: number;
  granularity: Granularity;
  selectedIds: number[];
  type: TimeseriesType;
  legend: Promise<Legend>;
  hasEPred: Promise<boolean>;
  tenantRootAssetId: number;
  latestDatapointDate: Promise<Date>;
};

export type EnergyComparePage = {
  data: Promise<{ [key: string]: number | string }[]>;
  start: Date;
  end: Date;
  startNext: Date;
  endNext: Date;
  nonOperationalPeriods: OperationalPeriods[];
  nonOperationalPeriodsNext: OperationalPeriods[];
  holidayPeriods: OperationalPeriods[];
  holidayPeriodsNext: OperationalPeriods[];
  usableFloorArea: number;
  granularity: Granularity;
  selectedIds: number[];
  type: TimeseriesType;
  legend: Promise<Legend>;
  legendWithTotal: Promise<Legend>;
  tenantRootAssetId: number;
  assetId: string;
};

export type TooltipData = {
  id?: number;
  timestamp: number;
  energy: number | undefined;
  temperature: number | undefined;
  operational?: string;
  "next-timestamp"?: number;
  "next-energy"?: number;
  "next-temperature"?: number;
  "next-operational"?: string;
  note?: {
    title: string;
    count: number;
  };
};

export type TooltipDataCompare = {
  id?: number;
  timestamp: number;
  "next-timestamp": number;
  energy: number | undefined;
  temperature: number | undefined;
  index?: number;
  operational?: string;
};

export type SumCount = { sum: number; count: number };

export type SelectedEnergyPoints =
  | (SelectedNodeForPrefilledNote & TooltipData)[]
  | null;

export type SetSelectedEnergyPoints = React.Dispatch<
  SetStateAction<SelectedEnergyPoints>
>;

export type EnergyNoteInfo = {
  notesDataPeriods?: NoteOperationalPeriods[];
  isNoteModeOn?: boolean;
  setSelectedPoints?: SetSelectedEnergyPoints;
  selectedPoints?: SelectedEnergyPoints;
};
export const DESCRIPTION_LEGEND_VALUES = {
  operational: "operational",
  nonOperational: "non-operational",
  holidays: "holidays",
  trendLine: "trend-line",
  hasNote: "has-note",
  temperature: "temperature",
  ePred: "ePred",
};
