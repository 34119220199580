import { useTranslations, MessageKey } from "@properate/translations";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { GreyOutOld } from "./elements";

type Props = {
  status?: string;
  searchWords: string[];
};

export function TaskManagerStatusColumn(props: Props) {
  const t = useTranslations();

  if (!props.status) {
    return null;
  }

  return (
    <GreyOutOld past={props.status === "Completed"}>
      <SearchHighlighter
        search={props.searchWords}
        text={t(`task.enum.status.${props.status}` as MessageKey)}
      />
    </GreyOutOld>
  );
}
