import { useGetAlarms } from "@/features/alarms";
import { useAlarmsTableFiltersContext } from "../components/AlarmsTableFilters/AlarmsTableFiltersContext";

export function useGetFilteredAlarms(buildingIds: number[]) {
  const alarms = useGetAlarms(buildingIds);
  const { filter, ...rest } = useAlarmsTableFiltersContext();
  return {
    filteredAlarms: filter(alarms.data),
    alarms,
    filter,
    ...rest,
  };
}
