import { useTranslations } from "@properate/translations";

export const EnergyNoteCreationModeCustomContent = () => {
  const t = useTranslations();

  return (
    <dl className="mx-6 flex flex-col content-center text-center">
      <dt className="font-bold text-2xl mb-6">
        {t("energy.notes.sidebar-custom.title")}
      </dt>
      <dd className="my-2 mx-0 text-l">
        {t("energy.notes.sidebar-custom.select-dot")}
      </dd>
      <dd className="m-0 opacity-60 text-xl font-semibold">
        {t("energy.notes.sidebar-custom.or")}
      </dd>
      <dd className="my-2 mx-0 text-l">
        {t("energy.notes.sidebar-custom.select-range")}
      </dd>
    </dl>
  );
};
