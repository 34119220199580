import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useTranslations } from "@properate/translations";
import { HeatMapViewMode } from "@/features/heatmap";
type Props = {
  viewMode: HeatMapViewMode;
  handleChangeCurrentWeekMonth: ({
    target: { checked },
  }: CheckboxChangeEvent) => void;
  isCurrentStartOfWeekMonthChecked?: boolean;
};
export function HeatMapCurrentWeekMonthCheckboxSelection({
  viewMode,
  handleChangeCurrentWeekMonth,
  isCurrentStartOfWeekMonthChecked,
}: Props) {
  const t = useTranslations();
  return (
    <Checkbox
      checked={isCurrentStartOfWeekMonthChecked}
      onChange={handleChangeCurrentWeekMonth}
    >
      {viewMode === "week"
        ? t("analysis.heat-map.setting-page.current-week")
        : t("analysis.heat-map.setting-page.current-month")}
    </Checkbox>
  );
}
