import dayjs, { Dayjs } from "@properate/dayjs";
import { useTranslations } from "@properate/translations";

export function getDateRange(
  fromDate: Dayjs | Date | string,
  toDate: Dayjs | Date | string,
  unitOfTime: "minutes" | "hours" | "days" | "months",
) {
  const diff = dayjs(toDate).diff(dayjs(fromDate), unitOfTime) + 1; // +1 to include both ends of the range
  const rangeItem = dayjs(fromDate);
  const range = [rangeItem];

  for (let i = 1; i < diff; i++) {
    range.push(range[i - 1].add(1, unitOfTime));
  }

  return range;
}

export function useLastXQuarterPresets(amountOfQuarters: number) {
  const t = useTranslations();

  return Array(amountOfQuarters)
    .fill(null)
    .map((_, i) => {
      const quarterStart = dayjs()
        .startOf("quarter")
        .subtract(i + 1, "quarter");
      const quarterEnd = dayjs().startOf("quarter").subtract(i, "quarter");
      const quarterStartFormatted = quarterStart.format(
        t("reports.quarter-format"),
      );
      return {
        label: quarterStartFormatted,
        value: [quarterStart, quarterEnd] as [dayjs.Dayjs, dayjs.Dayjs],
      };
    });
}

export function useLastHalfYearPresets() {
  const t = useTranslations();

  return dayjs().month() < 6
    ? [
        {
          label: t("reports.second-half-of-year", {
            year: dayjs().subtract(1, "year").year(),
          }),
          value: [
            dayjs().startOf("year").subtract(6, "months"),
            dayjs().startOf("year"),
          ] as [dayjs.Dayjs, dayjs.Dayjs],
        },
        {
          label: t("reports.first-half-of-year", {
            year: dayjs().subtract(1, "year").year(),
          }),
          value: [
            dayjs().startOf("year").subtract(1, "year"),
            dayjs().startOf("year").subtract(6, "months"),
          ] as [dayjs.Dayjs, dayjs.Dayjs],
        },
      ]
    : [
        {
          label: t("reports.first-half-of-year", {
            year: dayjs().year(),
          }),
          value: [
            dayjs().startOf("year"),
            dayjs().startOf("year").add(6, "months"),
          ] as [dayjs.Dayjs, dayjs.Dayjs],
        },
        {
          label: t("reports.second-half-of-year", {
            year: dayjs().subtract(1, "year").year(),
          }),
          value: [
            dayjs().startOf("year").subtract(6, "months"),
            dayjs().startOf("year"),
          ] as [dayjs.Dayjs, dayjs.Dayjs],
        },
      ];
}

export function useLastXMonthPreset(amountOfMonths: number) {
  const t = useTranslations();

  return {
    label:
      amountOfMonths > 1
        ? t("reports.last-n-months", {
            months: amountOfMonths,
          })
        : t("reports.last-month"),
    value: [dayjs().subtract(amountOfMonths, "month"), dayjs()] as [
      dayjs.Dayjs,
      dayjs.Dayjs,
    ],
  };
}

export function getStartAndEndOfTheMonthDates(
  startDate?: string | dayjs.Dayjs,
  endDate?: string | dayjs.Dayjs,
) {
  const startOfMonth = dayjs().subtract(6, "months").startOf("month");
  const endOfMonth = dayjs().endOf("month");

  const start = startDate
    ? dayjs(Number(startDate)).startOf("month")
    : startOfMonth;
  const end = endDate ? dayjs(Number(endDate)).endOf("month") : endOfMonth;

  return { start, end };
}

export function dateFormat(
  date: number | Date | undefined,
  time = false,
  fallback = "",
) {
  if (!date) {
    return fallback;
  }
  if (time) {
    return dayjs(date).format("L LT");
  }
  return dayjs(date).format("L");
}
