import useSWR from "swr";
import { EventFilterRequest, IdEither, CogniteEvent } from "@cognite/sdk";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { mergedPagination } from "./utils";

export function useCogniteEvents(filter: EventFilterRequest | null) {
  const { client } = useCogniteClient();
  return useSWR(filter, async (filter) => {
    if ((filter?.filter?.dataSetIds ?? []).length === 0) {
      return [];
    }
    if ((filter?.filter?.assetIds ?? []).length === 0) {
      return [];
    }
    return await mergedPagination<IdEither, EventFilterRequest, CogniteEvent>(
      filter?.filter?.dataSetIds ?? [],
      100,
      (input) => {
        return {
          ...filter,
          filter: {
            ...filter.filter,
            dataSetIds: input,
          },
        };
      },
      async (filter) => {
        const res = await client.events.list(filter);
        return res.items;
      },
    );
  });
}
