import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useTranslations } from "@properate/translations";
import DaysHoursMinutesForm from "./DaysHoursMinutesForm";

interface Props {
  maxDataValidity: number;
  durationBeforeFallback: number;
  onMaxDataValidityChange: (milliseconds: number) => void;
}

function ErrorHandlingMaxDataValidityInput(params: Props) {
  const t = useTranslations();

  const handleMaxDataValidityCheckboxChange = (e: CheckboxChangeEvent) => {
    params.onMaxDataValidityChange(
      (e.target.checked ? 1 : -1) * Math.abs(params.maxDataValidity),
    );
  };

  const validator = () => {
    if (params.maxDataValidity <= 0)
      return Promise.reject(new Error("Tidsperioden må være positive"));
    else if (
      params.durationBeforeFallback > 0 &&
      params.maxDataValidity <= params.durationBeforeFallback
    )
      return Promise.reject(
        new Error(
          "Tidsperioden for nødtstopp må være høyre enn den for fallback",
        ),
      );
    return Promise.resolve();
  };

  return (
    <p>
      <Checkbox
        onChange={handleMaxDataValidityCheckboxChange}
        checked={params.maxDataValidity >= 0}
      >
        {t("calculation-flow.emergency-stop")}
      </Checkbox>
      {params.maxDataValidity >= 0 && (
        <DaysHoursMinutesForm
          title={t("calculation-flow.node-types.timeseries-before-stopped")}
          milliseconds={params.maxDataValidity}
          onChange={params.onMaxDataValidityChange}
          tooltip={t(
            "calculation-flow.node-types.calculation-automatically-starts-again",
          )}
          name="maxDataValidity"
          dependencies={["durationBeforeFallback"]}
          rules={[
            () => ({
              validator,
            }),
          ]}
        />
      )}
    </p>
  );
}

export default ErrorHandlingMaxDataValidityInput;
