import { useUser } from "@properate/auth";
import { Button } from "antd";
import { useSetSidebarData } from "@properate/ui";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { NotesSidebarValues, NotesSidebarViewState } from "./types";
import { useAssetList } from "./hooks";

export default function NewNoteButton() {
  const { setSidebarData } = useSetSidebarData<NotesSidebarValues>();
  const { assetList } = useAssetList();
  const t = useTranslations();
  const user = useUser();

  return (
    <div className="ml-4">
      <Button
        type="primary"
        ghost
        icon={<PlusOutlined />}
        disabled={user.isViewer || !assetList?.length}
        onClick={() =>
          setSidebarData({
            viewState: NotesSidebarViewState.create,
            note: {},
          })
        }
      >
        {t("notes.new-note-button")}
      </Button>
    </div>
  );
}
