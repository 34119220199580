import { useMemo } from "react";
import { DatapointAggregates } from "@cognite/sdk";
import { AxisRight } from "@visx/axis";
import { Bar } from "@visx/shape";
import { scaleLinear } from "@visx/scale";
import { LinearGradient } from "@visx/gradient";
import { useTheme } from "styled-components";
import { formatCompact } from "@/utils/format";
import { HeatMapAggregate } from "../types";
import { getTickLabelProps } from "../utils";

interface Props {
  timeseriesWithData: DatapointAggregates;
  aggregate: HeatMapAggregate;
  graphWidth: number;
  graphHeight: number;
  offsetTop: number;
  colorRange: [string, string];
  compact: boolean;
  unit?: string;
}

export function HeatMapRightAxis({
  timeseriesWithData,
  aggregate,
  graphWidth,
  graphHeight,
  offsetTop,
  colorRange,
  compact,
  unit,
}: Props) {
  const theme = useTheme();
  const valueScale = useMemo(() => {
    const datapointValues = timeseriesWithData.datapoints.map(
      (datapoint) => datapoint[aggregate]!,
    );
    return scaleLinear({
      range: [graphHeight, offsetTop],
      domain: [Math.min(...datapointValues), Math.max(...datapointValues)],
    });
  }, [graphHeight, offsetTop, aggregate, timeseriesWithData.datapoints]);

  // If multiple heat maps on same page, we need different gradients
  const gradientId = `gradient-${colorRange[1]}-${colorRange[0]}`;

  return (
    <>
      <LinearGradient id={gradientId} from={colorRange[1]} to={colorRange[0]} />
      {/* Using stroke("url#gradient") doesn't work on an axis for some reason, hence the separate <Bar /> */}
      <AxisRight
        scale={valueScale}
        left={graphWidth}
        numTicks={5}
        hideAxisLine
        hideTicks
        tickFormat={formatCompact}
        tickLabelProps={{
          ...getTickLabelProps(theme, compact),
          dx: 5,
        }}
      />
      <text
        x={graphWidth}
        y={offsetTop - 10}
        textAnchor="middle"
        fontSize={compact ? 10 : 12}
      >
        {unit}
      </text>
      <Bar
        width={10}
        height={graphHeight - offsetTop}
        x={graphWidth}
        y={offsetTop}
        fill={`url(#${gradientId})`}
      />
    </>
  );
}
