import { useState } from "react";
import { getItemByProp } from "@/utils/array";
import { TableRow } from "../types";

export function useActiveModalRow(tableRows: TableRow[]) {
  const [activeModalRowId, setActiveModalRowId] = useState<number | null>(null);

  return {
    activeModalRow: activeModalRowId
      ? getItemByProp(tableRows, activeModalRowId, "timeseries.id")
      : null,
    openModal: (id: number) => setActiveModalRowId(id),
    closeModal: () => setActiveModalRowId(null),
  };
}
