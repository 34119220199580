import { Card } from "antd";
import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { API_HOST } from "@/eepApi";
import usePageTitle from "@/hooks/usePageTitle";

type StatusPage = {
  assets: any;
  loginStatus: any;
  firestore: any;
};

/* eslint react/jsx-no-literals: 0 */
// technical page
export const Status = () => {
  usePageTitle("Status");
  const page = useLoaderData() as StatusPage;
  return (
    <div style={{ padding: 20 }}>
      <h1>Status</h1>
      <p style={{ fontSize: 16 }}>
        <ul>
          <li>
            <Suspense
              fallback={
                <SpinnerWithDelay isLoading>
                  <div />
                </SpinnerWithDelay>
              }
            >
              <Await
                resolve={page.assets}
                errorElement={<>Feil ved lesning fra CDF</>}
              >
                {() => {
                  return <>CDF ok</>;
                }}
              </Await>
            </Suspense>
          </li>
          <li>
            <Suspense
              fallback={
                <SpinnerWithDelay isLoading>
                  <div />
                </SpinnerWithDelay>
              }
            >
              <Await
                resolve={page.loginStatus}
                errorElement={<>Feil ved lesning fra API</>}
              >
                {() => {
                  return <>API ok</>;
                }}
              </Await>
            </Suspense>
          </li>
          <li>
            <Suspense
              fallback={
                <SpinnerWithDelay isLoading>
                  <div />
                </SpinnerWithDelay>
              }
            >
              <Await
                resolve={page.firestore}
                errorElement={<>Feil ved lesning fra Firestore</>}
              >
                {() => {
                  return <>Firestore ok</>;
                }}
              </Await>
            </Suspense>
          </li>
        </ul>
      </p>

      <Card style={{ width: 300 }}>
        <p>Versjon: {process.env.REACT_APP_VERSION || "ukjent"}</p>
        {process.env.REACT_APP_VERSION &&
          process.env.REACT_APP_VERSION.indexOf(" ") > 0 && (
            <>
              <p>{`project-id: ${process.env.REACT_APP_PROJECT_ID}`}</p>
              <p>{`firebase: ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`}</p>
              <p>{`api: ${API_HOST}`}</p>
            </>
          )}
      </Card>
    </div>
  );
};
