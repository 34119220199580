import {
  ActiveAlarmPeriod,
  InactiveAlarmPeriod,
  TimeSpan,
} from "@properate/common";
import dayjs from "@properate/dayjs";
import { useAlarmDetailsTimeSpan } from "@/pages/alarms/details/hooks";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { AlarmNotificationFormFields } from "@/pages/alarms/details/AlarmNotificationsPage/types";
import {
  getTimespansForActivePeriods,
  processTimeSpans,
} from "@/pages/alarms/details/hooks/useAlarmActiveTimeSpans/utils";

export function useAlarmActivePeriodsTimeSpans(): TimeSpan[] {
  const [fromDate, toDate] = useAlarmDetailsTimeSpan();
  const [alwaysActive] = useFormValue<boolean>(
    AlarmNotificationFormFields.AlwaysActive,
  );
  const [activePeriods] = useFormValue<ActiveAlarmPeriod[]>(
    AlarmNotificationFormFields.ActivePeriods,
  );
  if (alwaysActive) {
    return [[fromDate, toDate]];
  }
  return getTimespansForActivePeriods({ fromDate, toDate, activePeriods });
}

function useAlarmInactivePeriodsTimeSpans(): TimeSpan[] {
  const [inactivePeriods] = useFormValue<InactiveAlarmPeriod[]>(
    AlarmNotificationFormFields.InactivePeriods,
  );
  return (inactivePeriods ?? []).map(({ start_date, end_date }) => [
    dayjs(start_date).startOf("day").valueOf(),
    dayjs(end_date).endOf("day").valueOf(),
  ]);
}

export function useAlarmActiveTimeSpans(): TimeSpan[] {
  const activePeriods = useAlarmActivePeriodsTimeSpans();
  const inactivePeriods = useAlarmInactivePeriodsTimeSpans();

  return processTimeSpans(activePeriods, inactivePeriods);
}
