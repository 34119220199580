import { BuildingCertification } from "@properate/common";

export const certifications: Array<{
  type: BuildingCertification["type"];
  levels: string[];
}> = [
  {
    type: "WELL Certified™",
    levels: ["Bronze", "Silver", "Gold", "Platinum"],
  },
  {
    type: "WELL Certified™ Core",
    levels: ["Bronze", "Silver", "Gold", "Platinum"],
  },
  {
    type: "ISO14001",
    levels: ["Pass"],
  },
  {
    type: "Miljøfyrtårn",
    levels: ["Pass"],
  },
  {
    type: "BREEAM",
    levels: [
      "Acceptable",
      "Pass",
      "Good",
      "Very Good",
      "Excellent",
      "Outstanding",
    ],
  },
  {
    type: "BREEAM In-Use",
    levels: [
      "Acceptable",
      "Pass",
      "Good",
      "Very Good",
      "Excellent",
      "Outstanding",
    ],
  },
  {
    type: "Energimerke",
    levels: [],
  },
];

export const certificationTypeOptions = certifications.map((certification) => ({
  value: certification.type,
  label: certification.type,
}));
