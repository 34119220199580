import { Select, Space } from "antd";
import { getUnitWithDerivedUnits } from "@properate/common";
import { useTranslations } from "@properate/translations";

interface Props {
  value: string;
  onChange: (value: string) => unknown;
  unitOriginal: string;
}

export function TimeseriesUnitSelect({ value, onChange, unitOriginal }: Props) {
  const t = useTranslations();

  const unitWithDerivedUnits = getUnitWithDerivedUnits(unitOriginal);
  if (unitWithDerivedUnits.length > 1) {
    return (
      <Space direction="vertical">
        <label htmlFor="unitSelected">
          {t("analysis.details.timeseries-modal.select-unit")}
        </label>
        <Select
          id="unitSelected"
          value={value}
          options={unitWithDerivedUnits}
          onChange={onChange}
          style={{ minWidth: 100 }}
        />
      </Space>
    );
  }
  return null;
}
