import { DatapointAggregates } from "@cognite/sdk";
import { useGetTimeseriesListWithData } from "@/hooks/useGetTimeseriesListWithData";
import { DatapointsMultiQueryWithAggregates } from "../types";

export function useGetTimeseriesListWithAggregateData({
  items,
  aggregates,
  ...query
}: DatapointsMultiQueryWithAggregates) {
  const {
    timeseriesListWithData: timeseriesListWithAverageData,
    isLoading: isLoadingAverage,
  } = useGetTimeseriesListWithData<DatapointAggregates>(
    {
      ...query,
      items: items.filter((_, i) => aggregates[i] === "average"),
      aggregates: ["average"],
    },
    {
      useCurrentTimeZone: true,
    },
  );
  const {
    timeseriesListWithData: timeseriesListWithSumData,
    isLoading: isLoadingSum,
  } = useGetTimeseriesListWithData<DatapointAggregates>(
    {
      ...query,
      items: items.filter((_, i) => aggregates[i] === "sum"),
      aggregates: ["sum"],
    },
    {
      useCurrentTimeZone: true,
    },
  );
  const {
    timeseriesListWithData: timeseriesListWithMinData,
    isLoading: isLoadingMin,
  } = useGetTimeseriesListWithData<DatapointAggregates>(
    {
      ...query,
      items: items.filter((_, i) => aggregates[i] === "min"),
      aggregates: ["min"],
    },
    {
      useCurrentTimeZone: true,
    },
  );
  const {
    timeseriesListWithData: timeseriesListWithMaxData,
    isLoading: isLoadingMax,
  } = useGetTimeseriesListWithData<DatapointAggregates>(
    {
      ...query,
      items: items.filter((_, i) => aggregates[i] === "max"),
      aggregates: ["max"],
    },
    {
      useCurrentTimeZone: true,
    },
  );
  return {
    timeseriesListWithData: [
      ...timeseriesListWithAverageData,
      ...timeseriesListWithSumData,
      ...timeseriesListWithMinData,
      ...timeseriesListWithMaxData,
    ],
    isLoading: isLoadingAverage || isLoadingSum || isLoadingMin || isLoadingMax,
  };
}
