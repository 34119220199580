import { browserFirestore } from "@properate/firebase";
import { AlarmEventState, ProperateEvent } from "@properate/common";
import { collection, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useGetFirestoreCollection } from "@properate/ui";

export function useGetAlarmEvents(
  snapshotId?: string,
  state?: AlarmEventState,
) {
  const ref = useMemo(() => {
    if (!snapshotId) {
      return null;
    }

    if (state) {
      return query(
        collection(browserFirestore, "events"),
        where("alarmId", "==", snapshotId),
        where("state", "==", state),
      );
    }

    return query(
      collection(browserFirestore, "events"),
      where("alarmId", "==", snapshotId),
    );
  }, [snapshotId, state]);

  return useGetFirestoreCollection<ProperateEvent>(ref);
}
