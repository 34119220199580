import { Col, Divider, App, Radio, Row, Space } from "antd";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import * as React from "react";
import dayjs from "@properate/dayjs";
import { useTranslations, useLocale } from "@properate/translations";
import {
  ColoredProperateCalendar,
  NewProperateCalendarEvent,
  ProperateCalendarEvent,
} from "@/utils/types";
import SelectDateAndTime from "@/pages/properateCalendar/components/SelectDateAndTime";
import { EditSchedule } from "@/pages/properateCalendar/components/EditSchedule";
import { EditValue } from "@/pages/properateCalendar/components/EditValue";
import hd from "@/utils/holidays";
import { describeEventRecurrence } from "@/pages/properateCalendar/utils";
import { CenteredDiv, StyledModal, TitleButtons } from "./components/elements";

type Props = {
  event: ProperateCalendarEvent;
  calendar: ColoredProperateCalendar;
  updateEvent: (event: ProperateCalendarEvent) => void;
  addEvent: (event: NewProperateCalendarEvent) => void;
  deleteEvent: (event: ProperateCalendarEvent) => void;
  hide: () => void;
  calendarMap: Record<string, ColoredProperateCalendar>;
};

export const EditEventModal = ({
  event,
  hide,
  calendar,
  updateEvent,
  deleteEvent,
  addEvent,
  calendarMap,
}: Props) => {
  const locale = useLocale();
  const t = useTranslations();
  hd.setLanguages(locale);

  const { message } = App.useApp();
  const [start, setStart] = useState(
    dayjs(event.start).tz("Europe/Oslo").toDate(),
  );
  const [end, setEnd] = useState(event.end);
  const [value, setValue] = useState(event.value);
  const [schedule, setSchedule] = useState(event.schedule);
  const [recurring, setRecurring] = React.useState<boolean>(true);

  useEffect(() => {
    setValue(event.value);
    setStart(event.start);
    setEnd(event.end);
  }, [event]);
  const onSave = async () => {
    if (dayjs(end).isBefore(start)) {
      message.error(t("calendar.start-date-must-be-before-end-date"));
      return;
    }
    if (event.schedule === "weekly" && !recurring) {
      addEvent({
        calendar_id: event.calendar_id,
        start,
        end,
        value,
        schedule: "single",
      });
    } else {
      updateEvent({
        ...event,
        start,
        end,
        value,
        schedule: recurring ? schedule : "single",
      });
    }
    hide();
  };
  const onDelete = () => {
    deleteEvent(event);
    hide();
  };

  const getDescription = (ts: ColoredProperateCalendar) => {
    return (
      calendarMap[ts.calendar_id].extended_description ||
      `${ts.system || ""} ${ts.name} ${ts.description}`
    );
  };

  return (
    <StyledModal
      destroyOnClose
      closable={false}
      open={!!event}
      onOk={onSave}
      onCancel={() => {
        hide();
      }}
      title={
        <div style={{ textAlign: "right" }}>
          <TitleButtons
            type="link"
            icon={<DeleteOutlined />}
            onClick={onDelete}
          />
          <Divider type="vertical" />
          <TitleButtons
            type="link"
            icon={<CloseOutlined />}
            onClick={() => {
              hide();
            }}
          />
        </div>
      }
      width={700}
    >
      <div>
        <Row>
          <Col span={2}>
            <CenteredDiv>
              <span
                style={{
                  display: "block",
                  width: 14,
                  height: 14,
                  borderRadius: 4,
                  marginRight: 8,
                  background: calendar.color,
                }}
              />
            </CenteredDiv>
          </Col>
          <Col span={22}>
            <h1 style={{ fontWeight: "normal" }}>{getDescription(calendar)}</h1>
          </Col>
        </Row>

        <SelectDateAndTime
          key={`${start.getTime()}-${end.getTime()}`}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
        />
        <EditSchedule
          schedule={schedule}
          setSchedule={setSchedule}
          holiday={(hd.isHoliday(start) || undefined)?.[0]?.name}
        />
        {event.schedule !== "single" && (
          <Row gutter={[8, 8]} style={{ marginTop: 8 }}>
            <Col span={22} offset={2}>
              <Radio.Group
                onChange={(event) => {
                  setRecurring(event.target.value);
                }}
                value={recurring}
              >
                <Space direction="horizontal">
                  <Radio value={false}>
                    {t("calendar.change-only-this-event")}
                  </Radio>
                  <Radio value>
                    {t("calendar.change-on-value", {
                      value: t(
                        `calendar.event-recurrence.${describeEventRecurrence(
                          event.schedule,
                        )}`,
                      ).toLowerCase(),
                    })}
                  </Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: 8 }}>
          <Col span={22} offset={2}>
            <Divider />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={22} offset={2}>
            <EditValue
              value={value}
              setValue={setValue}
              validValues={calendar.valid_values}
              defaultValue={calendar.default_value}
            />
          </Col>
        </Row>
      </div>
    </StyledModal>
  );
};
