import { useSidebarData } from "@properate/ui";
import { useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";
import { Asset, CogniteInternalId } from "@cognite/sdk";
import { getLabelForAsset } from "./utils";
import { useAssetList, useTimeseriesList } from "./hooks";
import {
  Note,
  NoteMetaProps,
  NotesAssetFilterMode,
  NotesSidebarValues,
} from "./types";

export function NoteMetaAssetList({
  assetIds,
  fieldOptions,
}: Readonly<
  Partial<Pick<Note, "assetIds">> & Pick<NoteMetaProps, "fieldOptions">
>) {
  const { colorOverrides, assetFilterMode } =
    useSidebarData<NotesSidebarValues>();
  const { assetList } = useAssetList();
  const { timeseriesList } = useTimeseriesList();
  const themeContext = useContext(ThemeContext);

  const colorsForAssets = useMemo(() => {
    function getOverrideColor(assetId: CogniteInternalId) {
      if (assetFilterMode === NotesAssetFilterMode.AssetList) {
        return colorOverrides?.[assetId];
      }
      if (!timeseriesList || !colorOverrides) {
        return undefined;
      }
      const timeseries = timeseriesList.find((ts) => ts.assetId === assetId);
      if (!timeseries) {
        return undefined;
      }
      return colorOverrides[timeseries.id];
    }

    if (!assetIds) {
      return {};
    }
    const colors: Record<string, string> = {};
    const fallbackColors = [
      themeContext.accent2,
      themeContext.accent3,
      themeContext.accent4,
      themeContext.accent5,
      themeContext.accent6,
      themeContext.accent7,
      themeContext.accent8,
      themeContext.accent9,
      themeContext.accent10,
    ].reverse();
    let fallbackColorIndex = 0;

    assetIds.forEach((assetId) => {
      const provided = getOverrideColor(assetId);
      if (provided) {
        colors[assetId] = provided;
      } else {
        colors[assetId] =
          fallbackColors[fallbackColorIndex % fallbackColors.length];
        fallbackColorIndex += 1;
      }
    });

    return colors;
  }, [themeContext, assetIds, colorOverrides, assetFilterMode, timeseriesList]);

  const assets = useMemo(() => {
    return (assetList ?? []).filter((asset) => assetIds?.includes(asset.id));
  }, [assetIds, assetList]);

  if (!assets || assets.length === 0) {
    return null;
  }

  function renderAsset(asset: Asset, truncated = false) {
    return (
      <div className="flex items-center justify-start gap-2">
        {truncated ? null : (
          <span
            aria-hidden
            className="h-2 w-2 grow-0 inline-block"
            style={{ backgroundColor: colorsForAssets[asset.id] }}
          />
        )}
        <span className="grow-1">{getLabelForAsset(asset)}</span>
        {truncated && assets.length > 1 && <span>(+{assets.length - 1})</span>}
      </div>
    );
  }

  if (fieldOptions?.truncateTimeseries) {
    return renderAsset(assets[0], true);
  }

  return (
    <ul className="list-none">
      {assets.map((asset) => (
        <li key={`asset_${asset.id}`}>{renderAsset(asset)}</li>
      ))}
    </ul>
  );
}
