import { AlarmRuleTypeName } from "@properate/common";
import { TextInfoBox } from "../components/InfoBox";
import { tKey } from "./common/utils";
import { ThirdPartyAlarmDefinition } from "./ThirdPartyAlarm";
import { AlarmRuleType } from "./index";

export const LockedThirdPartyAlarmDefinition: AlarmRuleType = {
  ...ThirdPartyAlarmDefinition,
  name: AlarmRuleTypeName.LockedThirdPartyAlarm,
  labelTranslationKey: tKey("third-party-alarm.locked.type-label"),
  lockedSchedule: false,
  lockedType: true,
  formComponent: <LockedThirdPartyAlarmInfo />,
};

function LockedThirdPartyAlarmInfo() {
  return (
    <TextInfoBox message={tKey("third-party-alarm.locked.settings-info")} />
  );
}
