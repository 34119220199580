import { Button, Modal } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import {
  keysForAlarmPriority,
  SimplifiedAlarmPriority,
  ValidateIncidentChangesResponse,
} from "@properate/common";
import { useParams } from "react-router-dom";
import { CenteredSpinner } from "@properate/ui";
import { useMemo } from "react";
import { ExportOutlined } from "@ant-design/icons";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { AlarmNotificationFormFields } from "@/pages/alarms/details/AlarmNotificationsPage/types";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

type Props = {
  isOpen: boolean;
  data?: ValidateIncidentChangesResponse;
  onClose: () => void;
  isRemoveModal?: boolean;
};

export const AlarmUpdateModal = ({
  isOpen,
  data,
  onClose,
  isRemoveModal,
}: Props) => {
  const t = useTranslations();
  const { alarmId } = useParams() as { alarmId: string };
  const isNew = alarmId === "new";
  const currentBuildingId = useCurrentBuildingId();

  const [level] = useFormValue<SimplifiedAlarmPriority>(
    AlarmNotificationFormFields.Level,
  );
  const severity = t(`${keysForAlarmPriority[level]}` as MessageKey);

  const [categoryCode] = useFormValue<string>(
    AlarmNotificationFormFields.Category,
  );

  const [subCategoryCode] = useFormValue<string>(
    AlarmNotificationFormFields.SubCategory,
  );

  const category = useMemo(() => {
    const category = t(
      `tfm.system.buildingPartNumber.${categoryCode}` as MessageKey,
    );

    const subCategory = t(
      `tfm.system.buildingPartNumber.${subCategoryCode}` as MessageKey,
    );

    if (category && subCategory && category !== subCategory) {
      return `${category} (${subCategory})`;
    } else if (category) {
      return category;
    } else if (subCategory) {
      return subCategory;
    }

    return "";
  }, [categoryCode, subCategoryCode, t]);

  const removeAlarmContent = (
    <div>{t("alarms.warnings.removed-alarm-alert-configurations-delete")}</div>
  );

  const createAlarmContent = (
    <div>
      {t.rich(
        category
          ? "alarms.warnings.new-alarm-alert-configurations-update"
          : "alarms.warnings.new-alarm-alert-configurations-update-without-category",
        {
          severity: severity,
          category: category,
          strong: (content) => <strong>{content}</strong>,
        },
      )}
    </div>
  );

  const updateAlarmContent = (
    <>
      <div>
        {t.rich("alarms.warnings.updated-alarm-alert-configurations-update", {
          severity: severity,
          category: category,
          strong: (content) => <strong>{content}</strong>,
        })}
      </div>
      <div>
        {t.rich("alarms.warnings.updated-alarm-alert-configurations-delete", {
          severity: severity,
          category: category,
          strong: (content) => <strong>{content}</strong>,
        })}
      </div>
    </>
  );

  const alertConfigurationChanges = (
    <div className="flex flex-col gap-2">
      <div className="font-bold">
        {t("alarms.warnings.alert-configuration-changes")}
      </div>
      {isNew && createAlarmContent}
      {isRemoveModal && removeAlarmContent}
      {!isNew && !isRemoveModal && updateAlarmContent}
    </div>
  );

  const incidentChanges = (
    <div className="flex flex-col gap-1">
      <div className="font-bold">{t("alarms.warnings.incident-changes")}</div>
      <div>
        {isRemoveModal
          ? t("alarms.warnings.removed-alarm-incidents-update")
          : t("alarms.warnings.updated-alarm-incidents-update")}
        :
      </div>
      <div className="max-h-[20vh] overflow-y-scroll">
        {data?.alarmIncidents?.map((incident) => (
          <div key={incident.id} className="flex justify-between mr-2">
            <div className="flex-1">{incident.name}</div>
            <Button
              onClick={() => {
                window.open(
                  `/asset/${currentBuildingId}/incidents/${incident.id}`,
                  "_blank",
                  "noopener,noreferrer",
                );
              }}
              type="link"
              icon={<ExportOutlined />}
            />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      title={t("alarms.warnings.title")}
      onOk={onClose}
      onCancel={onClose}
      footer={(_, { OkBtn }) => <OkBtn />}
    >
      {!data ? (
        <CenteredSpinner />
      ) : (
        <div className="flex flex-col gap-4">
          {data.hasAlertConfigurations && alertConfigurationChanges}
          {!!data.alarmIncidents.length && incidentChanges}
        </div>
      )}
    </Modal>
  );
};
