import { Drawer, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { mutate } from "swr";
import { useTranslations } from "@properate/translations";
import {
  getEnergyCompareLink,
  getEnergyFlowLink,
  getEnergyLink,
  getEnergyStateFromUrl,
} from "@/layout/utils";
import { useRootAssetSearch } from "@/hooks/useRootAssetSearch";
import { BuildingInfo } from "@/components/Building/BuildingInfo";
import { useBuildingsSpec } from "@/services/buildingSpec";
import {
  FilterBuildings,
  useBuildingsSearch,
} from "@/context/BuildingsSearchContext";
import { ReactComponent as Logo } from "./logo.svg";
import { BuildingInfoContainer, BuildingList, BuildingsGrid } from "./elements";

const LogoContainer = styled.div`
  cursor: pointer;
  overflow: hidden;

  & svg {
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

    .bg {
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .fg {
      opacity: 1;
      transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .bg {
      fill: ${(props) => props.theme.purple_100};
    }

    .fg {
      fill: ${(props) => props.theme.primary};
    }
  }

  .dark & svg {
    .bg {
      fill: ${(props) => props.theme.white};
    }

    .fg {
      fill: ${(props) => props.theme.primary};
    }
  }
`;

const SearchInput = styled(Input)`
  &.ant-input-affix-wrapper {
    background: ${(props) => props.theme.background};
  }

  &.ant-input-affix-wrapper-focused {
    background: ${(props) => props.theme.background2};
  }

  input {
    background: ${(props) => props.theme.background};
  }

  &.ant-input-affix-wrapper-focused input {
    background: ${(props) => props.theme.background2};
  }
`;

const getPath = (id: number, tab: string, pathname: string, search: string) => {
  const state = getEnergyStateFromUrl(pathname, search);
  if (tab === "energyConsumption") {
    return `/asset/${id}/${getEnergyLink(
      state ? { ...state, currentBuilding: id.toString() } : null,
    )}`;
  }
  if (tab === "energyCompare") {
    return `/asset/${id}/${getEnergyCompareLink(
      state
        ? {
            ...state,
            currentBuilding: id.toString(),
          }
        : null,
    )}`;
  }
  if (tab === "energyFlow") {
    return `/asset/${id}/${getEnergyFlowLink(
      state
        ? {
            ...state,
            currentBuilding: id.toString(),
          }
        : null,
    )}`;
  }
  return `/asset/${id}/${tab}`;
};

type Props = {
  visible: boolean;
  onHide: () => void;
};
export const BuildingSwitcher = ({ visible, onHide }: Props) => {
  const t = useTranslations();

  const { search, setSearch } = useBuildingsSearch();
  const searchTerm = search?.toLowerCase();
  const rootAssetsFilteredBySearchTerm = useRootAssetSearch(searchTerm);
  const location = useLocation();
  const findCurrentTab = /\/\w+\/\d+\/(\w+(?:-\w+)?)\/?(\w+(?:-\w+)?)?/.exec(
    location.pathname,
  );
  const navigate = useNavigate();
  const buildingsSpec = useBuildingsSpec();

  const tab =
    findCurrentTab && findCurrentTab.length > 1
      ? findCurrentTab[1]
      : "building";

  const path =
    tab === "analysis" && findCurrentTab && findCurrentTab[2]
      ? `${tab}/${findCurrentTab[2]}`
      : `${tab}`;

  function handleCleanupAndHide() {
    mutate(() => true, undefined, false);
    onHide();
  }

  function handlePressEnter() {
    if (rootAssetsFilteredBySearchTerm.length === 1) {
      navigate(`/asset/${rootAssetsFilteredBySearchTerm[0].asset.id}/${path}`);
      handleCleanupAndHide();
    }
  }

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onHide}
      open={visible}
      styles={{ body: { padding: "36px 0px 36px 36px" } }}
      width={869}
    >
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <div style={{ marginTop: "38px", marginBottom: "38px" }}>
        <SearchInput
          prefix={<SearchOutlined />}
          type="search"
          placeholder={t("common.sidebar.search-placeholder")}
          value={searchTerm}
          onChange={(event) =>
            setSearch({
              type: FilterBuildings.filter_buildings,
              payload: event.target.value,
            })
          }
          style={{ width: 317 }}
          size="large"
          onPressEnter={handlePressEnter}
        />
      </div>
      <BuildingList>
        <BuildingsGrid>
          {rootAssetsFilteredBySearchTerm.map(
            ({ asset: { id, metadata, alias } }) => {
              const buildingSpec = buildingsSpec?.find(
                ({ snapshotId }) => Number(snapshotId) === id,
              );
              const toPath = getPath(
                id,
                path,
                location.pathname,
                location.search,
              );
              return (
                <BuildingInfoContainer
                  to={toPath}
                  key={id}
                  onClick={handleCleanupAndHide}
                  state={{
                    ...location.state,
                    building: id,
                    root: undefined,
                    selectedIds: [],
                  }}
                >
                  <BuildingInfo
                    street={metadata!.Adresse}
                    alias={alias}
                    owner={metadata!.owner}
                    place={metadata!.Poststed}
                    imageUrl={buildingSpec?.imageUrl}
                    searchWords={searchTerm.split(" ")}
                  />
                </BuildingInfoContainer>
              );
            },
          )}
        </BuildingsGrid>
      </BuildingList>
    </Drawer>
  );
};
