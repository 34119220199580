"use client";

import { doc, getDoc } from "firebase/firestore";
import useSWRMutation from "swr/mutation";
import { notification } from "antd";
import { browserFirestore } from "@properate/firebase";
import { useTaskType } from "../contexts/task-type-context";
import { Task, TaskChecklist } from "../schemas";
import { useUpdateTask } from "./use-update-task";

export type UpdateTaskChecklistArg = {
  where: {
    taskId: string;
    checklistId: string;
  };
  data: TaskChecklist | null;
};

export function useUpdateTaskChecklist() {
  const updateTask = useUpdateTask();
  const taskType = useTaskType();

  return useSWRMutation(
    "updateTaskChecklist",
    async (_, extra: { arg: UpdateTaskChecklistArg }) /* NOSONAR */ => {
      const ref = doc(browserFirestore, taskType, extra.arg.where.taskId);
      const currentTaskDoc = await getDoc(ref);
      const currentTask = currentTaskDoc.data() as Task;

      let checklists = currentTask.checklists;

      if (extra.arg.data === null) {
        const { [extra.arg.where.checklistId]: _, ...rest } = checklists;
        checklists = rest;
      } else {
        checklists = {
          ...checklists,
          [extra.arg.where.checklistId]: {
            title: extra.arg.data.title,

            // Sometimes race conditions can cause the callbacks within our React code
            // to hold onto null values, so we filter them out here to ensure consistency.
            items: extra.arg.data.items.filter(Boolean),
          },
        };
      }

      await updateTask.trigger({
        where: {
          taskId: extra.arg.where.taskId,
        },
        data: {
          checklists,
        },
      });
    },
    {
      onError(err) {
        console.error(err);

        notification.error({
          message: "Kunne ikke oppdatere oppgaven",
        });
      },
    },
  );
}
