import dayjs from "@properate/dayjs";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { GreyOutOld } from "./elements";

type Props = {
  status: string;
  dueDate?: number;
  searchWords: string[];
};

export function TaskManagerDueDateColumn(props: Props) {
  if (!props.dueDate) {
    return null;
  }

  const time = dayjs(props.dueDate).endOf("day").fromNow();

  return (
    <GreyOutOld past={props.status === "Completed"}>
      <span
        style={{
          color:
            props.status !== "Completed" &&
            dayjs(props.dueDate).isBefore(dayjs())
              ? DEFAULT_GLOBAL_THEME.color.RED
              : "inherit",
        }}
      >
        <SearchHighlighter search={props.searchWords} text={time} />
      </span>
    </GreyOutOld>
  );
}
