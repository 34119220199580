import {
  ChangeEvent,
  Children,
  cloneElement,
  FC,
  HTMLProps,
  isValidElement,
  PropsWithChildren,
  useEffect,
} from "react";
import { useTranslations, MessageKey } from "@properate/translations";
import { useFormContext, useFormDefaultValue, useFormValue } from "./hooks";
import { FormContextActionType } from "./types";

export enum LabelDirection {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export enum ValidationStatus {
  Error = "error",
  Warning = "warning",
}

export type FormContextItemProps = PropsWithChildren &
  HTMLProps<any> & {
    id: string;
    labelDirection?: LabelDirection;
    labelSrOnly?: boolean;
    valueFromEvent?: (event: ChangeEvent<unknown>) => unknown;
    fieldValueFromFormValue?: (formValue: unknown) => unknown;
    changeCallback?: (newValue: unknown) => unknown;
    labelWrapper?: FC<PropsWithChildren>;
    labelWrapperProps?: unknown;
    disabled?: boolean;
    antdInput?: boolean;
    valueProp?: string;
    inputProps?: unknown;
    labelProps?: unknown;
  } & (
    | {
        label: string;
        labelKey?: unknown;
      }
    | {
        label?: unknown;
        labelKey: MessageKey;
      }
  );

export type FormInputProps = {
  id: string;
  onChange: (event: ChangeEvent<unknown>) => unknown;
  status?: ValidationStatus;
  defaultValue?: unknown;
};

export function FormContextItem({
  id,
  labelDirection,
  labelKey,
  label,
  labelSrOnly,
  labelWrapper,
  valueFromEvent,
  changeCallback,
  fieldValueFromFormValue,
  children,
  disabled,
  valueProp,
  antdInput,
  inputProps,
  labelProps,
}: FormContextItemProps) {
  const t = useTranslations();
  const { entries, dispatch, options } = useFormContext();
  const { hasError, errorMessage } = entries[id] || {};
  const [formValue, setFormValue] = useFormValue(id);
  const defaultValue = useFormDefaultValue(id);

  useEffect(() => {
    if (defaultValue !== undefined && formValue === undefined) {
      setFormValue(defaultValue);
    }
  }, [defaultValue, formValue, setFormValue]);

  const numChildren = Children.count(children);
  if (numChildren !== 1) {
    if (numChildren > 1) {
      console.error(
        "Using FormContextItem with more than a single child item! Rendering null;",
      );
    }
    return null;
  }

  function getFieldValue() {
    if (fieldValueFromFormValue) {
      return fieldValueFromFormValue(formValue);
    }
    return formValue;
  }

  function onChildChange(event: ChangeEvent<HTMLInputElement>) {
    let newValue: unknown = null;
    if (valueFromEvent) {
      newValue = valueFromEvent(event);
    } else if (antdInput) {
      newValue = event;
    } else {
      newValue = event?.target?.value;
    }
    if (changeCallback) {
      newValue = changeCallback(newValue);
    }
    setFormValue(newValue);
    if (hasError) {
      dispatch({ type: FormContextActionType.validateField, id });
    }
  }

  function renderChildWithFormProps() {
    const valueField = valueProp ?? "value";
    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          id,
          onChange: onChildChange,
          status: hasError ? ValidationStatus.Error : undefined,
          [valueField]: getFieldValue(),
          disabled: disabled || !!options.disabledForm,
          ...(inputProps ?? {}),
        } as FormInputProps);
      }
      return child;
    });
  }

  function renderErrorMessage() {
    return (
      <span className={"h-5 w-full text-error"}>
        {hasError ? errorMessage : " "}
      </span>
    );
  }

  const flexDir =
    labelDirection === LabelDirection.Horizontal ? "flex-row" : "flex-col";
  const contentWrapperProps = {
    className: labelSrOnly ? "w-full" : `flex gap-2 w-full ${flexDir}`,
    ...(labelProps ?? {}),
  };

  function renderLabel() {
    const labelElement = (
      <label htmlFor={id} className={labelSrOnly ? "sr-only" : ""}>
        {/* @ts-ignore*/}
        {labelKey ? t(labelKey) : label}
      </label>
    );
    if (labelWrapper) {
      const Component = labelWrapper;
      return <Component>{labelElement}</Component>;
    }
    return labelElement;
  }

  return (
    <div className={`flex flex-col gap-1 w-full`}>
      <div {...contentWrapperProps}>
        {renderLabel()}
        {renderChildWithFormProps()}
      </div>
      {renderErrorMessage()}
    </div>
  );
}
