import { createContext, Dispatch, useReducer } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import {
  FormContextAction,
  FormContextEntries,
  FormContextEntry,
  FormContextProviderProps,
  FormContextType,
} from "./types";
import { FormContextForm } from "./FormContextForm";
import { formReducer } from "./formReducer";

export const FormContext = createContext<FormContextType | null>(null);
export const FormDispatchContext =
  createContext<Dispatch<FormContextAction> | null>(null);

export function FormContextProvider({
  children,
  onSubmit,
  entries,
  options,
  className,
}: Readonly<FormContextProviderProps>) {
  const [searchParams] = useSearchParams();
  const t = useTranslations();
  const initialEntries: FormContextEntries = {};
  for (const [id, entry] of Object.entries(entries)) {
    const initialEntry: FormContextEntry<unknown> = { ...entry };
    initialEntry.value ??= initialEntry.defaultValue;
    initialEntries[id] = initialEntry;
  }
  if (options?.useUrlSearchParams) {
    for (const [id, value] of searchParams) {
      if (initialEntries[id] !== undefined) {
        initialEntries[id].value = JSON.parse(value);
      }
    }
  }
  const [values, dispatch] = useReducer(formReducer, {
    entries: initialEntries,
    options: options || {},
    t,
    onSubmit,
  });

  return (
    <FormContext.Provider value={values}>
      <FormDispatchContext.Provider value={dispatch}>
        <FormContextForm className={className}>{children}</FormContextForm>
      </FormDispatchContext.Provider>
    </FormContext.Provider>
  );
}
