import * as React from "react";
import { useEffect } from "react";
import { Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslations } from "@properate/translations";
import { getToken } from "./Viewer-helpers";

const getManifest = (urn: string) => {
  return getToken().then((token) =>
    axios
      .get(
        `https://developer.api.autodesk.com/derivativeservice/v2/manifest/${urn}`,
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.warn(err);
        return undefined;
      }),
  );
};

export const Upload = () => {
  const t = useTranslations();
  const { urn }: any = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = React.useState(
    t("file-preview.preparing-file"),
  );

  useEffect(() => {
    (async () => {
      try {
        await new Promise<void>(function (resolve, reject) {
          (function waitForModel() {
            getManifest(urn)
              .then((data: any) => {
                if (!data) {
                  reject(t("file-preview.not-found"));
                }
                if (data.status === "success") {
                  resolve();
                } else {
                  setMessage(
                    t("file-preview.done-description", {
                      status: data.success,
                    }),
                  );
                  setTimeout(waitForModel, 2000);
                }
              })
              .catch(reject);
          })();
        });
        navigate(`/view/${urn}`);
      } catch (e: any) {
        setMessage(
          t("file-preview.error-retrieving-file", {
            error: e.message,
          }),
        );
      }
    })();
  }, [urn, navigate, t]);

  return (
    <Row justify="space-around" align="middle">
      <Col flex="none">{message}</Col>
    </Row>
  );
};
