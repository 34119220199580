import {
  AlarmRuleTypeName,
  DelayOptions,
  msToMinutes,
  OffsetDirection,
} from "@properate/common";
import { useTranslations } from "@properate/translations";
import { ReactNode } from "react";
import { RichTextFormat } from "@properate/ui";
import { AlarmSettingsFormFields } from "@/pages/alarms/details/AlarmSettingsPage/types";
import { alarmRuleTypes } from "@/pages/alarms/details/AlarmRuleTypes";
import { TimeseriesThresholdFormFields } from "@/pages/alarms/details/AlarmRuleTypes/TimeseriesThreshold";
import { useFormValue } from "../FormContext";
import {
  OffsetMode,
  SelectOffsetType,
} from "../AlarmRuleTypes/common/SelectOffset";
import { useTimeseries } from "./useTimeseriesWithDatapoint";

export function DurationFromOffsetDelay() {
  const t = useTranslations("alarm-details.alarm-types.common.offset");
  const [delay] = useFormValue<number | undefined>(SelectOffsetType.Delay);
  return t(`delay-options.${msToMinutes(delay) as DelayOptions}`);
}

export function ThresholdFromOffsetValueAndTimeseriesUnit({
  fallbackUnit = "",
  overrideTimeseriesId = undefined,
}: {
  fallbackUnit?: string;
  overrideTimeseriesId?: number;
}) {
  const [threshold] = useFormValue(SelectOffsetType.Value);
  const [timeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const { timeseries } = useTimeseries({
    timeseriesId: overrideTimeseriesId ?? timeseriesId,
  });

  return `${threshold} ${timeseries?.unit ?? fallbackUnit}`;
}

export function useAlarmTypeDescription({
  timeseriesId,
  unit,
  noBold,
}: {
  unit?: string;
  timeseriesId?: number;
  noBold?: boolean;
}): ReactNode {
  const t = useTranslations("alarm-details.alarm-types.common.offset");
  const [mode] = useFormValue<OffsetMode>(SelectOffsetType.Mode);
  const [direction] = useFormValue<OffsetDirection>(SelectOffsetType.Direction);
  const [alarmRuleTypeName] = useFormValue<AlarmRuleTypeName>(
    AlarmSettingsFormFields.Type,
  );

  const disabledFormatters = noBold ? ["bold"] : [];

  if (alarmRuleTypes?.[alarmRuleTypeName]?.TypeDescription) {
    const TypeDescription = alarmRuleTypes[alarmRuleTypeName].TypeDescription!;
    return <TypeDescription disabledFormatters={disabledFormatters} />;
  }

  return (
    <RichTextFormat
      message={`alarm-details.alarm-types.common.offset.preview.${mode}`}
      disabledFormatters={disabledFormatters}
      formatOverrides={{
        offset: () => {
          return (
            <ThresholdFromOffsetValueAndTimeseriesUnit
              overrideTimeseriesId={timeseriesId}
              fallbackUnit={unit}
            />
          );
        },
        direction: () => {
          return t(`preview.direction.${direction}`);
        },
        duration: () => {
          return <DurationFromOffsetDelay />;
        },
      }}
    />
  );
}
