import { TimeseriesSearchable } from "@properate/api/src/types";
import { useState } from "react";
import { Tag, TableColumnType, App } from "antd";
import { useHotkeys } from "react-hotkeys-hook";
import { formatMeasurement } from "@properate/common";
import { DoubleDatapoint, Timeseries } from "@cognite/sdk";
import { useTranslations } from "@properate/translations";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useGetTimeseriesListWithLatestData } from "@/hooks/useGetTimeseriesListWithLatestData";
import { useIsLoadingWithDelay } from "@/hooks/useIsLoadingWithDelay";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { Highlight, DisplayBlock } from "./elements";
import type { SorterResult } from "antd/es/table/interface";
import type { Hit } from "meilisearch";

interface TableRow extends Hit<TimeseriesSearchable> {
  latestDatapointWithUnit?: DoubleDatapoint & { unit?: string };
}

interface Props {
  searchableTimeseriesList: TimeseriesSearchable[];
  isLoadingSearchableTimeseriesList: boolean;
  selectedIds: number[];
  onChangeSort: (sort?: string) => unknown;
  onReachedEndOfPage: () => unknown;
  bodyHeight: number;
  disabledIds: number[];
  onChange: (timeseries: Timeseries) => unknown;
  onAdd: (timeseries: Timeseries) => unknown;
  onRemove: (id: number) => unknown;
  max: number;
}

export function TimeseriesSelectionTable({
  searchableTimeseriesList,
  isLoadingSearchableTimeseriesList,
  selectedIds,
  onChangeSort,
  onReachedEndOfPage,
  bodyHeight,
  disabledIds,
  onChange,
  onAdd,
  onRemove,
  max,
}: Props) {
  const t = useTranslations();

  const { client } = useCogniteClient();
  const currentBuilding = useCurrentBuilding();
  const { overrideUnits, convertOverrideUnit } = useTimeseriesSettings(
    currentBuilding.id,
  );
  const { message } = App.useApp();
  const {
    timeseriesListWithLatestData,
    isLoading: isLoadingTimeseriesListWithLatestData,
  } = useGetTimeseriesListWithLatestData(
    searchableTimeseriesList.map((timeseries) => ({ id: timeseries.id })),
  );
  const [isExternalIdVisible, setIsExternalIdVisible] = useState(false);
  useHotkeys("Control+Shift+x", () =>
    setIsExternalIdVisible(
      (isExternalIdVisibleCurrent) => !isExternalIdVisibleCurrent,
    ),
  );
  const isLoadingWithDelay = useIsLoadingWithDelay(
    isLoadingSearchableTimeseriesList || isLoadingTimeseriesListWithLatestData,
  );
  const isSingleSelect = max === 1;

  const tableRows: TableRow[] = searchableTimeseriesList.map((timeseries) => {
    const timeseriesWithLatestData = timeseriesListWithLatestData.find(
      (timeseriesWithLatestData) =>
        timeseriesWithLatestData.id === timeseries.id,
    );
    if (!timeseriesWithLatestData || !timeseriesWithLatestData.datapoints[0]) {
      return timeseries;
    }
    const [latestDatapoint] = timeseriesWithLatestData.datapoints;
    const overrideUnit =
      timeseries &&
      overrideUnits &&
      overrideUnits[timeseries.externalId!]?.unit;

    const valueWithUnit = convertOverrideUnit(
      timeseries.unit,
      overrideUnit,
      latestDatapoint.value,
    );

    return {
      ...timeseries,
      latestDatapointWithUnit: {
        ...latestDatapoint,
        ...valueWithUnit,
        stateDescription: timeseries.stateDescription,
      },
    } as TableRow;
  });

  function handleChange({ field, order }: SorterResult<TableRow>) {
    if (order) {
      const sort = `${field}:${order === "ascend" ? "asc" : "desc"}`;
      return onChangeSort(sort);
    }
    onChangeSort(undefined);
  }

  async function handleSelectTimeseries(id: number, selected: boolean) {
    if (isSingleSelect) {
      const [timeseries] = await client.timeseries.retrieve([{ id }]);
      return onChange(timeseries);
    }
    if (selected) {
      if (selectedIds.length >= max) {
        message.error(
          t("common.timeseries-modal.you-cant-add-more-timeseries", {
            number: selectedIds.length,
          }),
        );
        return;
      }

      const [timeseries] = await client.timeseries.retrieve([{ id }]);
      return onAdd(timeseries);
    }
    return onRemove(id);
  }

  const columns: Array<TableColumnType<TableRow>> = [
    {
      title: t("common.timeseries-modal.table.sub-building"),
      key: "subBuilding",
      dataIndex: "subBuilding",
      width: 150,
      render: (_, { _formatted: { subBuilding } }) => (
        <Highlight dangerouslySetInnerHTML={{ __html: subBuilding }} />
      ),
      sorter: true,
    },
    {
      title: t("common.timeseries-modal.table.system"),
      key: "system",
      dataIndex: "system",
      width: 150,
      render: (_, { _formatted: { system } }) => (
        <Highlight dangerouslySetInnerHTML={{ __html: system }} />
      ),
      sorter: true,
    },
    {
      title: isExternalIdVisible
        ? t("common.timeseries-modal.table.external-id")
        : t("common.timeseries-modal.table.name"),
      key: isExternalIdVisible ? "externalId" : "name",
      dataIndex: isExternalIdVisible ? undefined : "name",
      render: (_, { externalId, _formatted: { name } }) =>
        isExternalIdVisible ? (
          externalId
        ) : (
          <Highlight
            dangerouslySetInnerHTML={{
              __html: name,
            }}
          />
        ),
      sorter: !isExternalIdVisible,
    },
    {
      title: t("common.timeseries-modal.table.labels"),
      key: "translatedLabels",
      width: 280,
      render: (_, { _formatted: { translatedLabels } }) =>
        translatedLabels.length > 0 ? (
          <DisplayBlock>
            {translatedLabels.map((translatedLabel) => (
              <Tag key={translatedLabel}>
                <Highlight
                  dangerouslySetInnerHTML={{ __html: translatedLabel }}
                />
              </Tag>
            ))}
          </DisplayBlock>
        ) : null,
    },
    {
      title: t("common.timeseries-modal.table.last-measurement"),
      dataIndex: "latestDatapointWithUnit",
      key: "latestDatapointWithUnit",
      align: "right",
      render: (
        latestDatapointWithUnit:
          | (DoubleDatapoint & { unit?: string })
          | undefined,
      ) =>
        latestDatapointWithUnit
          ? formatMeasurement(latestDatapointWithUnit)
          : null,
    },
  ];

  return (
    <>
      <TableInfiniteScroll
        height={bodyHeight}
        dataSource={tableRows}
        columns={columns}
        rowKey="id"
        loading={isLoadingWithDelay}
        onReachedEndOfPage={onReachedEndOfPage}
        rowIsHoverable
        rowSelection={{
          type: isSingleSelect ? "radio" : "checkbox",
          selectedRowKeys: selectedIds,
          onSelect: ({ id }, selected) => handleSelectTimeseries(id, selected),
          getCheckboxProps: (record) => ({
            disabled: disabledIds.includes(record.id),
          }),
          hideSelectAll: true,
          columnWidth: 32,
        }}
        onChange={(_, __, sorter) =>
          handleChange(sorter as SorterResult<TableRow>)
        }
        locale={{
          emptyText: t("common.timeseries-modal.table.no-timeseries"),
        }}
        onRow={({ id }) => {
          return {
            "data-testid": `timeseries-selection-row/${id}`,
            onClick: () => {
              const shouldBeSelected = !selectedIds.includes(id);
              const isDisabled = disabledIds.includes(id);
              if (!isDisabled) {
                handleSelectTimeseries(id, shouldBeSelected);
              }
            },
          };
        }}
      />
    </>
  );
}
