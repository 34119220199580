import { PageHeader } from "@ant-design/pro-layout";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Asset, CogniteClient } from "@cognite/sdk";
import { Step } from "react-joyride";
import { Button } from "antd";
import { useTranslations } from "@properate/translations";
import {
  TaskManagerHeading,
  TaskManagerTable,
  TaskManagerTableFiltersProvider,
} from "@/features/task-manager";
import { useWindowSize } from "@/hooks/useWindowSize";
import { JoyrideWrapper } from "@/components/JoyrideWrapper/JoyrideWrapper";
import { useOnboarding } from "@/utils/onboarding";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useHelp } from "@/context/HelpContext";
import { getAssetById } from "@/utils/helpers";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { CompactContent } from "@/components/CompactContent";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

export const WorkOrders = () => {
  const t = useTranslations();

  const STEPS: Step[] = [
    {
      placement: "top",
      target: ".support-text-new-workorder-button",
      content: t("task.onboarding.new-task-document-step"),
    },
    {
      placement: "top",
      target: ".ant-table-header",
      content: t("task.onboarding.select-task-step"),
    },
  ];

  useBuildingPageTitle(t("task.ui.title"));
  const { height: windowHeight } = useWindowSize();
  const navigate = useNavigate();
  const { client } = useCogniteClient();
  const { id }: any = useParams();
  const [building, setBuilding] = useState<Asset>();
  const [onboardingSteps, setOnboardingStepCompleted] = useOnboarding(STEPS);
  const { setHelp } = useHelp();

  useEffect(() => {
    setHelp({
      title: t("task.ui.task"),
      content: t.rich("task.help.view-page", {
        p: (text) => <p>{text}</p>,
      }),
    });
  }, [setHelp, t]);

  useEffect(() => {
    const get = async (client: CogniteClient) => {
      const a = await getAssetById(client, parseInt(id));
      setBuilding(a);
    };

    get(client);
  }, [client, id]);

  const buildingIds = useMemo(
    () => (building ? [building.id] : []),
    [building],
  );

  return (
    <TaskManagerTableFiltersProvider>
      <PageHeader
        title={t("task.ui.title")}
        extra={
          <TaskManagerHeading>
            <Button onClick={() => navigate("/org/task-templates")}>
              {t("task.ui.goToTaskTemplates")}
            </Button>
            <Button
              type="primary"
              onClick={() => navigate(`/asset/${id}/workOrders/create`)}
              data-testid="add-task-button"
            >
              {t("task.ui.newTask")}
            </Button>
          </TaskManagerHeading>
        }
      />
      <CompactContent>
        {building && (
          <TaskManagerTable
            buildingIds={buildingIds}
            onRowClick={(event) =>
              navigate(`/asset/${id}/workOrders/${event.snapshotId}`)
            }
            tableHeight={
              windowHeight - PAGE_LAYOUT_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT
            }
          />
        )}
        {onboardingSteps && onboardingSteps.length > 0 && (
          <JoyrideWrapper
            content={onboardingSteps[0]}
            onClose={setOnboardingStepCompleted}
          />
        )}
      </CompactContent>
    </TaskManagerTableFiltersProvider>
  );
};
