import { FunctionComponent } from "react";
import { Table } from "antd";
import { useTranslations } from "@properate/translations";
import { DataFrame } from "../types";
import { formatTableDate, move_to_start } from "./utils";

interface Props {
  dataframe: DataFrame;
  height: number;
  width: number;
}

export const DataFrameTable: FunctionComponent<Props> = ({
  dataframe,
  height,
  width,
}) => {
  const t = useTranslations();

  if (dataframe.datapoints.length === 0) return;

  const dataSource = dataframe.datapoints.map((row, key) => {
    return {
      key,
      ...row,
    };
  });

  const dataframe_columns = move_to_start(
    Object.keys(dataframe.datapoints[0]),
    "timestamp",
  );

  const numberFormat = new Intl.NumberFormat("nb-NO", {
    maximumFractionDigits: 4,
  });

  const columns = dataframe_columns.map((column, key) => {
    return {
      key,
      dataIndex: key,
      title: column === "timestamp" ? t("calculation-flow.date") : column,
      ...(column === "timestamp"
        ? {
            render: (_: any, value: (typeof dataSource)[0]) => {
              return <>{formatTableDate(value[column])}</>;
            },
          }
        : {
            render: (_: any, value: any) => {
              return <>{numberFormat.format(value[column])}</>;
            },
          }),
      sorter: (a: any, b: any) => a[column] - b[column],
    };
  });

  return (
    <div style={{ width, height }}>
      <Table
        dataSource={dataSource}
        columns={columns}
        virtual
        pagination={false}
        scroll={{ x: "100%", y: height - 54 }} // 54 is the height of the table header
      />
    </div>
  );
};
