import { Button, Form, FormInstance, FormListFieldData, Select } from "antd";
import { useTranslations } from "@properate/translations";
import { DeleteOutlined } from "@ant-design/icons";
import { AlertDay, allKeysForAlertDays } from "@properate/common";
import { FormValues } from "./utils";

type Props = {
  form: FormInstance<FormValues>;
  fields: FormListFieldData[];
  onAdd: (exception: string) => void;
  onRemove: (key: number) => void;
};

export function AlertRuleDetailsExceptions(props: Props) {
  const t = useTranslations();
  const exceptions = Form.useWatch("group_rules_exceptions", props.form) ?? [];

  function handleAdd() {
    for (const day of Object.values(AlertDay)) {
      if (!exceptions.includes(day)) {
        return props.onAdd(day);
      }
    }
  }

  return (
    <div>
      {props.fields.map(
        (field) =>
          exceptions[field.key] && (
            <ExceptionItem
              key={field.key}
              field={field}
              exception={exceptions[field.key]}
              exceptions={exceptions}
              onRemove={props.onRemove}
            />
          ),
      )}
      <Button type="primary" ghost onClick={handleAdd} className="float-right">
        + {t("alert-rule.add-exception-rule")}
      </Button>
    </div>
  );
}

type ExceptionItemProps = {
  field: FormListFieldData;
  exception: string;
  exceptions: AlertDay[];
  onRemove: (key: number) => void;
};

function ExceptionItem(props: ExceptionItemProps) {
  const t = useTranslations();

  return (
    <div className="flex gap-2">
      <Form.Item
        name={[props.field.key]}
        label={t("alert-rule.field-trigger-label")}
        labelAlign="left"
        className="w-96"
      >
        <Select placeholder={t("alert-rule.field-day-placeholder")}>
          {Object.values(AlertDay).map((day) => (
            <Select.Option
              key={day}
              value={day}
              disabled={props.exceptions.includes(day)}
            >
              {t(allKeysForAlertDays[day])}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Button
        type="text"
        icon={<DeleteOutlined />}
        onClick={() => props.onRemove(props.field.key)}
      />
    </div>
  );
}
