import { Node } from "reactflow";
import { useTranslations } from "@properate/translations";
import { getNodeId } from "./helpers/Utils";
import {
  SmallNode,
  NodeInput,
  Header,
  Body,
  NodeOutput,
  NodeOutputLabel,
  NodeInputLabel,
} from "./helpers/NodeComponents";

interface ProductNodeProps {
  operationId: string;
}

export const getEmptyProductNode = (): Node<ProductNodeProps> => {
  return {
    id: getNodeId("product"),
    type: "product",
    data: {
      operationId: "product",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function ProductNode() {
  const t = useTranslations();

  return (
    <SmallNode>
      <Header>{t("calculation-flow.node-types.multiplication")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <NodeInput inputId="b">
          <NodeInputLabel>B</NodeInputLabel>
        </NodeInput>
        <NodeOutput>
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <NodeOutputLabel>A * B</NodeOutputLabel>
        </NodeOutput>
      </Body>
    </SmallNode>
  );
}

export default ProductNode;
