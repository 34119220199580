import { useState } from "react";
import { Modal, Select } from "antd";
import { WithSnapshotId } from "@properate/common";
import { useTranslations, MessageKey } from "@properate/translations";
import { Gauge } from "@/features/gauges";
import { Analysis } from "@/features/analysis";
import { HeatMap } from "@/features/heatmap";
import { DashboardStandardWidget, DashboardWidget } from "@/utils/types";
import {
  getAnalysisOptions,
  getGaugeOptions,
  getHeatMapOptions,
  sortWidgetOptions,
} from "./options";
import { WidgetSelectContainer } from "./elements";

interface Props {
  open: boolean;
  availableWidgets: DashboardStandardWidget[];
  availableGauges: WithSnapshotId<Gauge>[];
  availableAnalyses: WithSnapshotId<Analysis>[];
  availableHeatMaps: WithSnapshotId<HeatMap>[];
  onClickOK: (value: DashboardWidget[]) => unknown;
  onCancel: () => unknown;
}

export function NewWidgetModal({
  open,
  availableWidgets,
  availableGauges,
  availableAnalyses,
  availableHeatMaps,
  onClickOK,
  onCancel,
}: Props) {
  const t = useTranslations();

  const [selectedWidgets, setSelectedWidgets] = useState<
    DashboardStandardWidget[]
  >([]);
  const [selectedSimpleAnalysisIds, setSelectedSimpleAnalysisIds] = useState<
    string[]
  >([]);
  const [selectedScatterplotAnalysisIds, setSelectedScatterplotAnalysisIds] =
    useState<string[]>([]);
  const [selectedGaugeIds, setSelectedGaugeIds] = useState<string[]>([]);
  const [selectedHeatMapIds, setSelectedHeatMapIds] = useState<string[]>([]);
  const widgetOptions = sortWidgetOptions(
    availableWidgets.map((widget) => ({
      value: widget,
      label: t(
        `dashboard.widgets.new-widget.widget-labels.${widget}` as MessageKey,
      ),
    })),
  );
  const gaugeOptions = getGaugeOptions(availableGauges);
  const simpleAnalysisOptions = getAnalysisOptions(
    availableAnalyses,
    "simple",
    t("analysis.no-name"),
  );
  const scatterplotAnalysisOptions = getAnalysisOptions(
    availableAnalyses,
    "scatterplot",
    t("analysis.no-name"),
  );
  const heatMapOptions = getHeatMapOptions(availableHeatMaps);

  function handleOK() {
    onClickOK([
      ...selectedWidgets.map((widget) => ({
        type: "standard" as const,
        id: widget,
      })),
      ...selectedSimpleAnalysisIds
        .concat(selectedScatterplotAnalysisIds)
        .map((analysisId) => ({
          type: "analysis" as const,
          id: analysisId,
        })),

      ...selectedGaugeIds.map((gaugeId) => ({
        type: "gauge" as const,
        id: gaugeId,
      })),

      ...selectedHeatMapIds.map((heatMapId) => ({
        type: "heatMap" as const,
        id: heatMapId,
      })),
    ]);
    setSelectedWidgets([]);
    setSelectedSimpleAnalysisIds([]);
    setSelectedScatterplotAnalysisIds([]);
    setSelectedGaugeIds([]);
    setSelectedHeatMapIds([]);
  }

  function handleCancel() {
    onCancel();
    setSelectedWidgets([]);
    setSelectedSimpleAnalysisIds([]);
    setSelectedScatterplotAnalysisIds([]);
    setSelectedGaugeIds([]);
    setSelectedHeatMapIds([]);
  }

  return (
    <Modal
      title={t("dashboard.widgets.new-widget.select-new-widget")}
      open={open}
      onOk={handleOK}
      onCancel={handleCancel}
    >
      <WidgetSelectContainer>
        <div>{t("dashboard.widgets.new-widget.select-standard-widget")}</div>
        <Select
          mode="multiple"
          options={widgetOptions}
          value={selectedWidgets}
          onChange={setSelectedWidgets}
          placeholder={t("dashboard.widgets.new-widget.select-widget")}
          optionFilterProp="label"
          style={{ width: "100%" }}
        />
        <div>
          {t("dashboard.widgets.new-widget.create-widget-for-timeseries-graph")}
        </div>
        <Select
          mode="multiple"
          options={simpleAnalysisOptions}
          value={selectedSimpleAnalysisIds}
          onChange={setSelectedSimpleAnalysisIds}
          placeholder={t(
            "dashboard.widgets.new-widget.select-timeseries-graph",
          )}
          optionFilterProp="label"
          style={{ width: "100%" }}
        />
        <div>
          {t("dashboard.widgets.new-widget.create-widget-for-x-y-graph")}
        </div>
        <Select
          mode="multiple"
          options={scatterplotAnalysisOptions}
          value={selectedScatterplotAnalysisIds}
          onChange={setSelectedScatterplotAnalysisIds}
          placeholder={t("dashboard.widgets.new-widget.select-x-y-graph")}
          optionFilterProp="label"
          style={{ width: "100%" }}
        />
        <div>{t("dashboard.widgets.new-widget.create-widget-for-gauge")}</div>
        <Select
          mode="multiple"
          options={gaugeOptions}
          value={selectedGaugeIds}
          onChange={setSelectedGaugeIds}
          placeholder={t("dashboard.widgets.new-widget.select-gauge")}
          optionFilterProp="label"
          style={{ width: "100%" }}
        />
        <div>{t("dashboard.widgets.new-widget.create-widget-for-heatmap")}</div>
        <Select
          mode="multiple"
          options={heatMapOptions}
          value={selectedHeatMapIds}
          onChange={setSelectedHeatMapIds}
          placeholder={t("dashboard.widgets.new-widget.select-heatmap")}
          optionFilterProp="label"
          style={{ width: "100%" }}
        />
      </WidgetSelectContainer>
    </Modal>
  );
}
