import styled from "styled-components";

export const HandleContainer = styled.span`
  cursor: grab;
  border-radius: 4px;
  &:hover,
  .moving & {
    background: ${(props) => props.theme.neutral7};
  }
`;
