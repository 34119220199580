import styled from "styled-components";

export const GaugeCircle = styled.div<{ $backgroundColor: string }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const GaugeNumberContainer = styled.div<{ $color: string }>`
  font-size: 40px;
  color: ${({ $color }) => $color};
`;
