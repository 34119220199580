import styled from "styled-components";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  svg {
    width: 100%;
    height: auto;
  }

  svg text {
    fill: ${({ theme }) => theme.neutral0};
  }

  .item {
    transition: filter 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .item:hover,
  .item.hover {
    filter: brightness(180%);
    cursor: pointer;
  }

  &.view .item .status {
    visibility: hidden;
    pointer-events: none;
  }

  &.edit .item.value .status {
    display: none;
  }

  html.light & {
    .item:hover,
    .item.hover {
      filter: brightness(80%);
    }

    .fg {
      fill: #858c94;
    }

    .fg1 {
      fill: #182334;
    }

    .fgStroke {
      stroke: #858c94;
    }

    .fgStroke1 {
      stroke: #182334;
    }

    .bg {
      fill: ${({ theme }) => theme.neutral9};
    }

    .bg1 {
      fill: ${({ theme }) => theme.neutral9};
    }

    .bg2 {
      fill: #858c94;
    }

    .status {
      rect {
        fill: ${(props) => props.theme.background};
        stroke: #182334;
      }

      path {
        fill: #182334;
      }
    }
    .status.setPoint path {
      fill: ${(props) => props.theme.accent2};
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;
