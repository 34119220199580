import { Button, InputNumber, Select } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { MessageKey, useTranslations } from "@properate/translations";
import { useEffect, useState } from "react";
import { DeepPartial } from "ts-essentials";
import {
  AlarmRule,
  type CommonAlarmRuleConditionFields,
  CommonAlarmRuleOffsetFields,
  minutesToMs,
  OffsetDirection,
  PartialAlarmRule,
} from "@properate/common";
import { useUser } from "@properate/auth";
import { useAlarmTypeDescription } from "@/pages/alarms/details/hooks";
import {
  FormContextActionType,
  FormContextItem,
  SubmitValue,
} from "../../FormContext";
import { LabelDirection } from "../../FormContext/FormContextItem";
import {
  FormContextEntry,
  FormContextProviderEntries,
} from "../../FormContext/types";
import { numberValidator, stringValidator } from "../../FormContext/validators";
import {
  useFormContext,
  useFormEntry,
  useFormValue,
} from "../../FormContext/hooks";
import { getSubmitValueEntry } from "../../FormContext/utils";
import { requiredSelect, requiredString, tKey } from "./utils";

export enum OffsetMode {
  Threshold = "threshold",
  Compare = "compare",
}

export type OffsetProps = {
  unit: string;
  hideDirection?: boolean;
};

export enum SelectOffsetType {
  Value = "OffsetValue",
  Direction = "OffsetDirection",
  Delay = "OffsetDelay",
  Mode = "OffsetMode",
}

export function getSelectOffsetFieldDelay({
  alarmRule,
}: {
  alarmRule: DeepPartial<AlarmRule>;
}): FormContextEntry<unknown> {
  return {
    defaultValue: alarmRule?.condition?.delay_ms ?? undefined,
    getValidator: (t) =>
      numberValidator(
        t(requiredSelect, {
          fieldName: t(tKey(`common.offset.delay`)).toLowerCase(),
        }),
      ),
  };
}

export function getSelectOffsetFieldDefinitions({
  alarmRule,
  mode,
}: {
  alarmRule: PartialAlarmRule;
  mode: OffsetMode;
}): FormContextProviderEntries {
  const typeSpecific = alarmRule?.condition
    ?.type_specific as Partial<CommonAlarmRuleOffsetFields>;
  return {
    [SelectOffsetType.Value]: {
      defaultValue: typeSpecific?.offset ?? undefined,
      getValidator: (t) =>
        numberValidator(
          t(requiredString, {
            fieldName: t(tKey(`common.offset.diff.${mode}`)).toLowerCase(),
          }),
        ),
    },
    [SelectOffsetType.Direction]: {
      defaultValue: typeSpecific?.direction ?? undefined,
      getValidator: (t) =>
        stringValidator(
          t(requiredSelect, {
            fieldName: t(tKey(`common.offset.direction`)).toLowerCase(),
          }),
        ),
    },
    [SelectOffsetType.Delay]: getSelectOffsetFieldDelay({ alarmRule }),
    [SelectOffsetType.Mode]: {
      defaultValue: mode,
    },
  };
}

export function getTypeSpecificOffsetAlarmRuleFields({
  entries,
}: {
  entries: SubmitValue;
}): Partial<CommonAlarmRuleOffsetFields> {
  return {
    offset: getSubmitValueEntry<number>(entries, SelectOffsetType.Value),
    direction: getSubmitValueEntry<OffsetDirection>(
      entries,
      SelectOffsetType.Direction,
    ),
  };
}

export function getConditionOffsetAlarmRuleFields({
  entries,
}: {
  entries: SubmitValue;
}): Partial<CommonAlarmRuleConditionFields> {
  return {
    delay_ms: getSubmitValueEntry<number>(entries, SelectOffsetType.Delay),
  };
}

export function OffsetDirectionSelect({
  labels = {
    [OffsetDirection.BaseGTCompare]: tKey(
      `common.offset.direction-type.base-larger`,
    ),
    [OffsetDirection.BaseLTCompare]: tKey(
      `common.offset.direction-type.comparison-larger`,
    ),
  },
  labelKey = tKey(`common.offset.direction`),
}: Readonly<{
  labels?: Record<OffsetDirection, MessageKey>;
  labelKey?: MessageKey;
}>) {
  const t = useTranslations();
  const options = [
    OffsetDirection.BaseGTCompare,
    OffsetDirection.BaseLTCompare,
  ].map((key) => ({ label: t(labels[key]), value: key }));

  return (
    <FormContextItem
      labelKey={labelKey}
      id={SelectOffsetType.Direction}
      labelDirection={LabelDirection.Vertical}
      antdInput
      inputProps={{
        options,
      }}
    >
      <Select />
    </FormContextItem>
  );
}

export function OffsetDifference({ unit }: OffsetProps) {
  const [mode] = useFormValue<OffsetMode>(SelectOffsetType.Mode);
  return (
    <FormContextItem
      labelKey={mode ? tKey(`common.offset.diff.${mode}`) : ("" as MessageKey)}
      id={SelectOffsetType.Value}
      labelDirection={LabelDirection.Vertical}
      antdInput
      inputProps={{
        suffix: unit,
        className: "w-full",
        controls: false,
      }}
    >
      <InputNumber />
    </FormContextItem>
  );
}

export function ResponseDelaySelect() {
  const t = useTranslations();
  const responseDelayValues = [0, 5, 10, 15, 30, 60, 120];
  const responseDelayOptions = responseDelayValues.map((value: number) => ({
    label: t(tKey(`common.offset.delay-options.${value}`)),
    value: minutesToMs(value),
  }));

  return (
    <FormContextItem
      labelKey={tKey(`common.offset.delay`)}
      id={SelectOffsetType.Delay}
      labelDirection={LabelDirection.Vertical}
      antdInput
      inputProps={{
        options: responseDelayOptions,
      }}
    >
      <Select />
    </FormContextItem>
  );
}

function PreviewOffset({ onEdit, unit }: { onEdit: () => void } & OffsetProps) {
  const t = useTranslations();
  const user = useUser();

  const description = useAlarmTypeDescription({ unit });
  function tkey(key: string) {
    return tKey(`common.offset.${key}`);
  }

  return (
    <>
      <h2 className={"w-full flex justify-between align-center m-0"}>
        {t(tkey(`header`))}
        {user.isAdmin && (
          <Button size={"small"} onClick={onEdit}>
            {t("ui.edit")}
          </Button>
        )}
      </h2>
      <p>{description}</p>
    </>
  );
}

export function SelectOffset({ unit, hideDirection }: Readonly<OffsetProps>) {
  const [showHelp, setShowHelp] = useState(false);
  const [difference] = useFormValue(SelectOffsetType.Value);
  const [direction] = useFormValue(SelectOffsetType.Direction);
  const [delay] = useFormValue(SelectOffsetType.Delay);
  const hasValues =
    difference !== undefined && direction !== undefined && delay !== undefined;
  const [isEdit, setIsEdit] = useState(!hasValues);
  const t = useTranslations();
  const { dispatch } = useFormContext();
  const valueEntry = useFormEntry(SelectOffsetType.Value);
  const directionEntry = useFormEntry(SelectOffsetType.Direction);
  const delayEntry = useFormEntry(SelectOffsetType.Delay);

  useEffect(() => {
    const isValid =
      !valueEntry?.hasError &&
      !directionEntry?.hasError &&
      !delayEntry?.hasError;
    if (!isValid && !isEdit) {
      setIsEdit(true);
    }
  }, [valueEntry, directionEntry, delayEntry, isEdit]);

  if (!isEdit) {
    return <PreviewOffset onEdit={() => setIsEdit(true)} unit={unit} />;
  }

  function validateAndCloseEdit() {
    const ids = [SelectOffsetType.Value, SelectOffsetType.Delay];
    if (!hideDirection) {
      ids.push(SelectOffsetType.Direction);
    }
    dispatch({
      type: FormContextActionType.validateFields,
      ids,
    });
    setIsEdit(false);
  }

  return (
    <>
      <h2 className={"w-full flex justify-between align-center m-0"}>
        {t(tKey(`common.offset.header`))}
        <Button
          icon={<QuestionCircleOutlined />}
          shape="circle"
          onClick={() => setShowHelp((prev) => !prev)}
        />
      </h2>
      {showHelp && <p className="m-0">{t(tKey(`common.offset.help`))}</p>}

      <div
        className={`grid gap-2 grid-flow-col ${
          hideDirection ? "grid-cols-5" : "grid-cols-7"
        }`}
      >
        <div className="col-span-2">
          <OffsetDifference unit={unit} />
        </div>

        {!hideDirection && (
          <div className="col-span-2">
            <OffsetDirectionSelect />
          </div>
        )}
        <div className="col-span-2">
          <ResponseDelaySelect />
        </div>
        <Button
          type="primary"
          ghost
          className={"self-end mb-6"}
          onClick={validateAndCloseEdit}
        >
          {t("ui.save")}
        </Button>
      </div>
    </>
  );
}
