import useSWR from "swr";
import {
  AlarmRule,
  Category,
  SimplifiedAlarmPriority,
  SubCategory,
} from "@properate/common";
import { useCallback } from "react";
import { deleteAlarmConfiguration, listAlarmConfiguration } from "@/eepApi";

export function useGetNewAlarms(
  buildingId?: number,
  fromUser?: boolean,
  search?: string,
  categories?: Category[],
  subCategories?: SubCategory[],
  priorities?: SimplifiedAlarmPriority[],
) {
  const { data, error, isLoading, mutate } = useSWR(
    [
      "getAlarms",
      buildingId ?? "all",
      !!fromUser,
      search,
      categories,
      subCategories,
      priorities,
    ],
    async () => {
      let items: AlarmRule[] = [];
      let cursor = undefined;
      if (buildingId) {
        do {
          const result = await listAlarmConfiguration({
            search,
            filter: {
              building_id: buildingId,
              only_alarms_for_user: !!fromUser,
              categories: categories?.length ? categories : undefined,
              sub_categories: subCategories?.length ? subCategories : undefined,
              priorities: priorities?.length ? priorities : undefined,
            },
            language: "no",
            limit: 1000,
            cursor,
          });
          items = items.concat(result.items);
          cursor = result.next_cursor;
        } while (cursor);
        return items;
      }
      do {
        const result = await listAlarmConfiguration({
          language: "no",
          limit: 1000,
        });
        items = items.concat(result.items);
        cursor = result.next_cursor;
      } while (cursor);
      return items;
    },
  );

  const deleteAlarm = useCallback(
    async (id: string) => {
      await deleteAlarmConfiguration({ id });
      if (data) {
        await mutate(
          data.filter((item: AlarmRule) => item.alarm_id !== id),
          false,
        );
      } else {
        throw new Error("Data is not available");
      }
    },
    [mutate, data],
  );

  return {
    data: (data || []) as AlarmRule[],
    isLoading,
    error,
    deleteAlarm,
  };
}
