import { Outlet } from "react-router-dom";
import { Alert, Button } from "antd";
import { getAuth, signOut } from "firebase/auth";
import { getApp } from "firebase/app";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import * as Sentry from "@sentry/react";
import { useRootAssets } from "@/hooks/useRootAssets";
import { usePreviewChannel } from "@/hooks/usePreviewChannel";
import keycloak from "@/keycloak";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";

export function Root() {
  const t = useTranslations();

  usePreviewChannel();
  const rootAssets = useRootAssets();
  const user = useUser();

  async function logout() {
    await signOut(getAuth(getApp()));
    localStorage.removeItem("user");
    keycloak.logout();
    if (process.env.NODE_ENV === "production") {
      Sentry.setUser(null);
    }
  }

  if (user.isLoading)
    return (
      <SpinnerWithDelay
        className="absolute right-0 left-0 top-0 bottom-0 flex justify-center items-center"
        isLoading
      >
        <div />
      </SpinnerWithDelay>
    );

  return rootAssets.length > 0 && user?.hasAccess ? (
    <Outlet />
  ) : (
    <div className="h-screen flex">
      <Alert
        className="m-auto"
        style={{ maxWidth: 400 }}
        message={
          <div>
            {t("layout.user-has-no-access", {
              user: user.email,
            })}{" "}
            <Button type="link" onClick={logout} style={{ padding: 0 }}>
              {t("layout.logout")}
            </Button>{" "}
            {t("layout.alternative-login")}{" "}
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <a href="mailto:support@properate.com">support@properate.com</a>
          </div>
        }
        type="error"
      />
    </div>
  );
}
