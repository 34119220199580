import { AxisBottom } from "@visx/axis";
import { formatUnit } from "@properate/common";
import { ScaleLinearNumeric } from "@/utils/types";

interface Props {
  scale: ScaleLinearNumeric;
  color: string;
  unit: string;
  graphHeight: number;
  tickSize: number;
}

export function ScatterplotGraphXAxis({
  scale,
  color,
  unit,
  graphHeight,
  tickSize,
}: Props) {
  return (
    <AxisBottom
      // Leave a small margin at the bottom to allow the graph to be easier to read
      top={graphHeight + 5}
      label={formatUnit(unit)}
      scale={scale}
      stroke={color}
      tickStroke={color}
      tickLabelProps={{
        fill: color,
        fontSize: tickSize,
      }}
      labelProps={{
        fill: color,
        // Avoid overlapping w/ tick labels
        dy: "0.5em",
      }}
    />
  );
}
