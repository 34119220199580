import { useEffect, useMemo, useState } from "react";
import { ParentSize } from "@visx/responsive";
import { TimeSpan } from "@properate/common";
import { useSetSidebarData } from "@properate/ui";
import { Asset } from "@cognite/sdk";
import { useGetTimeseriesListWithData } from "@/hooks/useGetTimeseriesListWithData";
import {
  NoteBuilding,
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
  NotesSidebarValues,
} from "@/features/notes";
import {
  AnalysisLegendData,
  getOptimalGranularity,
  mapToSimplePointsWithMetadataList,
  ScatterplotGraph,
  SimpleGraph,
  TimeseriesNavigator,
} from "../../";
import { AnalysisDetails } from "../AnalysisDetails/AnalysisDetails";
import { TimeseriesTable } from "../TimeseriesTable/TimeseriesTable";
import {
  Analysis,
  DatapointAverages,
  SelectedAnalysisPoints,
  SetSelectedAnalysisPoints,
  SettingsTimeseries,
  SettingsTimeseriesScatterplot,
} from "../../types";
import {
  isScatterplotAnalysis,
  isScatterplotAnalysisWithSettingsTimeseriesX,
} from "../../utils";
import { useLegendDataForGraphLegend } from "./utils";
import { PageMainContent } from "./elements";

interface Props {
  analysis: Analysis;
  timeSpan: TimeSpan;
  zoomedTimeSpan: TimeSpan;
  settingsTimeseriesList: SettingsTimeseries[];
  onChangeAnalysis: (analysis: Partial<Analysis>) => unknown;
  onChangeZoomedTimeSpan: (value: TimeSpan) => unknown;
  onClickAddTimeseriesY: () => unknown;
  onClickChangeTimeseriesX: () => unknown;
  settingsTimeseriesXId: number | null;
  selectedBuildings: Asset[];
  setSelectedPoints: SetSelectedAnalysisPoints;
  selectedPoints: SelectedAnalysisPoints;
}

export function AnalysisContent({
  analysis,
  timeSpan,
  zoomedTimeSpan,
  settingsTimeseriesList,
  onChangeAnalysis,
  onChangeZoomedTimeSpan,
  onClickAddTimeseriesY,
  onClickChangeTimeseriesX,
  settingsTimeseriesXId,
  selectedBuildings,
  selectedPoints,
  setSelectedPoints,
}: Props) {
  const [highlightedTimeseriesIds, setHighlightedTimeseriesIds] = useState<
    number[]
  >([]);
  const isScatterplot = isScatterplotAnalysis(analysis);
  // Ensures downstream components don't receive a new array instance on each render
  const settingsTimeseriesListVisible = useMemo(() => {
    return settingsTimeseriesList.filter(({ hidden }) => !hidden);
  }, [settingsTimeseriesList]);

  function handleClickHighlight(timeseriesId: number) {
    setHighlightedTimeseriesIds((highlightedTimeseriesIdsCurrent) => {
      if (highlightedTimeseriesIdsCurrent.includes(timeseriesId)) {
        return highlightedTimeseriesIdsCurrent.filter(
          (highlightedTimeseriesId) => highlightedTimeseriesId !== timeseriesId,
        );
      }
      return highlightedTimeseriesIds.concat(timeseriesId);
    });
  }

  const { timeseriesListWithData: timeseriesListWithDataX } =
    useGetTimeseriesListWithData<DatapointAverages>({
      items:
        settingsTimeseriesXId !== null ? [{ id: settingsTimeseriesXId }] : [],
      start: timeSpan[0],
      end: timeSpan[1],
      aggregates: ["average"],
      granularity: getOptimalGranularity(timeSpan, 1, 25),
    });

  const { timeseriesListWithData: timeseriesListWithDataY } =
    useGetTimeseriesListWithData<DatapointAverages>({
      items: settingsTimeseriesList.map(({ id }) => ({ id })),
      start: timeSpan[0],
      end: timeSpan[1],
      aggregates: ["average", "min", "max"],
      granularity: getOptimalGranularity(
        timeSpan,
        settingsTimeseriesList.length,
        25,
      ),
    });

  const simplePointsWithMetadataListX =
    timeseriesListWithDataX.length > 0 &&
    isScatterplotAnalysisWithSettingsTimeseriesX(analysis)
      ? mapToSimplePointsWithMetadataList(timeseriesListWithDataX, [
          {
            ...analysis.settingsTimeseriesX,
            hidden: false,
          },
        ])
      : [];

  const simplePointsWithMetadadataList = mapToSimplePointsWithMetadataList(
    timeseriesListWithDataY,
    settingsTimeseriesList,
  );

  const notesBuildings = useMemo(() => {
    return selectedBuildings.map(
      ({ dataSetId, name }) =>
        ({
          id: dataSetId,
          name,
        }) as NoteBuilding,
    );
  }, [selectedBuildings]);

  const timeseriesIds = useMemo(
    () =>
      [
        ...(settingsTimeseriesXId !== null ? [settingsTimeseriesXId] : []),
        ...settingsTimeseriesList.map(({ id }) => id),
      ] as number[],
    [settingsTimeseriesXId, settingsTimeseriesList],
  );

  const notesIdSet = useMemo(() => {
    return new Set(
      highlightedTimeseriesIds?.length
        ? highlightedTimeseriesIds
        : timeseriesIds,
    );
  }, [highlightedTimeseriesIds, timeseriesIds]);

  const { setSidebarData } = useSetSidebarData<NotesSidebarValues>();

  useEffect(() => {
    setSidebarData({
      startTime: new Date(zoomedTimeSpan[0]),
      endTime: new Date(zoomedTimeSpan[1]),
    });
  }, [zoomedTimeSpan[0], zoomedTimeSpan[1]]); // eslint-disable-line react-hooks/exhaustive-deps

  const noteSidebarColorOverrides = useMemo(
    () =>
      [
        ...settingsTimeseriesList,
        ...(isScatterplotAnalysisWithSettingsTimeseriesX(analysis) &&
        analysis.settingsTimeseriesX
          ? [analysis.settingsTimeseriesX]
          : []),
      ].reduce(
        (acc, timeseries) => {
          acc[timeseries.id] = timeseries.color;
          return acc;
        },
        {} as Exclude<NotesSidebarValues["colorOverrides"], undefined>,
      ),
    [analysis, settingsTimeseriesList],
  );

  const legendData = useLegendDataForGraphLegend(
    selectedBuildings,
    zoomedTimeSpan,
    isScatterplot,
  );

  return (
    <>
      <PageMainContent>
        {/* min-height: 0 fixes an issue w/ ParentSize, see https://github.com/airbnb/visx/issues/441 */}
        <div style={{ flexGrow: 1, minHeight: 0 }}>
          <ParentSize>
            {({ width, height }) =>
              isScatterplotAnalysis(analysis) ? (
                <ScatterplotGraph
                  zoomedTimeSpan={zoomedTimeSpan}
                  settingsTimeseriesList={
                    settingsTimeseriesListVisible as SettingsTimeseriesScatterplot[]
                  }
                  width={width}
                  height={height}
                  settingsTimeseriesX={analysis.settingsTimeseriesX}
                  granularity={analysis.granularity}
                  mergeUnits={analysis.mergeUnits}
                  interactive
                  highlightedTimeseriesIds={highlightedTimeseriesIds}
                  legendData={legendData}
                  selectedPoints={selectedPoints}
                  setSelectedPoints={setSelectedPoints}
                />
              ) : (
                <SimpleGraph
                  timeSpan={timeSpan}
                  zoomedTimeSpan={zoomedTimeSpan}
                  settingsTimeseriesList={settingsTimeseriesListVisible}
                  width={width}
                  height={height}
                  highlightedTimeseriesIds={highlightedTimeseriesIds}
                  onChangeZoomedTimeSpan={onChangeZoomedTimeSpan}
                  mergeUnits={analysis.mergeUnits}
                  legendData={legendData}
                  interactive
                  selectedPoints={selectedPoints}
                  setSelectedPoints={setSelectedPoints}
                />
              )
            }
          </ParentSize>
        </div>
        {legendData && (
          <AnalysisLegendData {...legendData} isScatterplot={isScatterplot} />
        )}
        <div>
          <ParentSize>
            {({ width }) => (
              <TimeseriesNavigator
                simplePointsWithMetadataList={simplePointsWithMetadadataList}
                timeSpan={timeSpan}
                zoomedTimeSpan={zoomedTimeSpan}
                parentWidth={width}
                onChangeZoomedTimeSpan={onChangeZoomedTimeSpan}
                {...(simplePointsWithMetadataListX.length > 0
                  ? {
                      simplePointsWithMetadataX:
                        simplePointsWithMetadataListX[0],
                    }
                  : null)}
              />
            )}
          </ParentSize>
        </div>
        <div
          className="component"
          style={{
            flexBasis: "250px",
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            gap: "8px",
          }}
        >
          <AnalysisDetails
            analysis={analysis}
            onChangeAnalysis={onChangeAnalysis}
            onClickAddTimeseriesY={onClickAddTimeseriesY}
            onClickChangeTimeseriesX={onClickChangeTimeseriesX}
          />
          {settingsTimeseriesList.length > 0 ||
          isScatterplotAnalysisWithSettingsTimeseriesX(analysis) ? (
            <TimeseriesTable
              analysis={analysis}
              zoomedTimeSpan={zoomedTimeSpan}
              highlightedTimeseriesIds={highlightedTimeseriesIds}
              onClickHighlight={handleClickHighlight}
              onChangeAnalysis={onChangeAnalysis}
            />
          ) : null}
        </div>
      </PageMainContent>
      {!!notesBuildings.length && (
        <NotesSidebar
          noteSource={NoteSource.WEB_ANALYSIS}
          buildings={notesBuildings}
          assetFilterMode={NotesAssetFilterMode.TimeseriesList}
          idSet={notesIdSet}
          colorOverrides={noteSidebarColorOverrides}
        />
      )}
    </>
  );
}
