import { Segmented } from "antd";
import { AlarmSeverity as AlarmSeverityType } from "@properate/common";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { AlarmSubtitle } from "./elements";

interface Props {
  value: AlarmSeverityType;
  onChange: (value: AlarmSeverityType) => unknown;
}

export const AlarmSeverity = ({ value, onChange }: Props) => {
  const t = useTranslations();
  const user = useUser();
  return (
    <>
      <AlarmSubtitle>
        {t("common.timeseries-graph-modal.alarm-fields.severity")}
      </AlarmSubtitle>
      <Segmented
        block
        value={value ?? "WARNING"}
        onChange={onChange}
        disabled={user.isViewer}
        options={[
          {
            label: t(
              "common.timeseries-graph-modal.alarm-fields.severity-levels.info",
            ),
            value: "INFO",
          },
          {
            label: t(
              "common.timeseries-graph-modal.alarm-fields.severity-levels.warning",
            ),
            value: "WARNING",
          },
          {
            label: t(
              "common.timeseries-graph-modal.alarm-fields.severity-levels.error",
            ),
            value: "ERROR",
          },
          {
            label: t(
              "common.timeseries-graph-modal.alarm-fields.severity-levels.critical",
            ),
            value: "CRITICAL",
          },
        ]}
      />
    </>
  );
};
