import { Button, Space } from "antd";
import { ReactNode } from "react";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import { Handle } from "@/components/Draggable/Handle";
import { WidgetHeaderContainer } from "./elements";

interface Props {
  text: string;
  onClickRemoveButton?: () => unknown;
  onClickSettingsButton?: () => unknown;
  isDraggable?: boolean;
  addon?: ReactNode;
}

export function WidgetHeader({
  text,
  onClickRemoveButton,
  onClickSettingsButton,
  isDraggable = false,
  addon,
}: Props) {
  return (
    <WidgetHeaderContainer>
      <Space>
        {isDraggable && <Handle />}
        {text}
        {onClickSettingsButton && (
          <Button
            icon={<SettingOutlined />}
            type="link"
            onClick={onClickSettingsButton}
          />
        )}
        {addon}
      </Space>
      {onClickRemoveButton && (
        <Button
          icon={<CloseOutlined />}
          size="small"
          danger
          type="text"
          onClick={onClickRemoveButton}
        />
      )}
    </WidgetHeaderContainer>
  );
}
