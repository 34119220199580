import { SWRResponse } from "swr";
import { useMemo } from "react";
import { useSidebarData } from "@properate/ui";
import { Note, NotesSidebarValues } from "../types";
import { useCogniteEvents } from "./useCogniteEvents";
import { useCogniteEventFilter } from "./useCogniteEventFilter";

export function useNotes(): Pick<
  SWRResponse<Note[]>,
  "isLoading" | "isValidating" | "error"
> & {
  notes: Note[] | null;
} {
  const { filter, error: filterError } = useCogniteEventFilter();
  const { assetFilterMode } = useSidebarData<NotesSidebarValues>();
  const {
    data: cogniteEvents,
    isLoading,
    isValidating,
    error,
  } = useCogniteEvents(filter);
  const notes = useMemo(() => {
    if (!assetFilterMode) {
      return null;
    }
    return !cogniteEvents
      ? null
      : cogniteEvents.map((event) => {
          return {
            id: event.id,
            dataSetId: event.dataSetId,
            content: event.metadata?.content ?? "",
            startTime: event.startTime,
            endTime: event.endTime,
            assetIds: event.assetIds ?? [],
            level: event.subtype,
            source: event.source,
            createdBy: event.metadata?.created_by ?? "",
            createdTime: event.createdTime,
            lastUpdatedBy: event.metadata?.last_updated_by ?? "",
            lastUpdatedTime: event.lastUpdatedTime,
          } as Note;
        });
  }, [cogniteEvents, assetFilterMode]);

  return { notes, isLoading, isValidating, error: filterError || error };
}
