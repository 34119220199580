import { WithSnapshotId } from "@properate/common";
import { Gauge } from "@/features/gauges";
import { DashboardStandardWidget } from "@/utils/types";
import { Analysis, isScatterplotAnalysis } from "@/features/analysis";
import { HeatMap } from "@/features/heatmap";

export function sortWidgetOptions(
  availableWidgets: { value: DashboardStandardWidget; label: string }[],
) {
  return availableWidgets.sort(({ label: labelOne }, { label: labelTwo }) =>
    labelOne.localeCompare(labelTwo),
  );
}

export function getAnalysisOptions(
  availableAnalyses: WithSnapshotId<Analysis>[],
  type: "simple" | "scatterplot",
  customTitle: string,
) {
  return availableAnalyses
    .filter((analysis) => {
      if (type === "scatterplot") {
        return isScatterplotAnalysis(analysis);
      }
      return !isScatterplotAnalysis(analysis);
    })
    .map((analysis) => ({
      value: analysis.snapshotId,
      label: analysis.title || customTitle,
    }))
    .sort(({ label: labelOne }, { label: labelTwo }) =>
      labelOne.localeCompare(labelTwo),
    );
}

export function getGaugeOptions(availableGauges: WithSnapshotId<Gauge>[]) {
  return availableGauges
    .map((gauge) => ({
      value: gauge.snapshotId,
      label: gauge.name,
    }))
    .sort(({ label: labelOne }, { label: labelTwo }) =>
      labelOne.localeCompare(labelTwo),
    );
}

export function getHeatMapOptions(
  availableHeatMaps: WithSnapshotId<HeatMap>[],
) {
  return availableHeatMaps
    .map((heatMap) => ({
      value: heatMap.snapshotId,
      label: heatMap.name,
    }))
    .sort(({ label: labelOne }, { label: labelTwo }) =>
      labelOne.localeCompare(labelTwo),
    );
}
