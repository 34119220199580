import useSWR from "swr";
import { Timeseries } from "@cognite/sdk";
import { useHandleApiError } from "@/utils/api";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { SettingsTimeseries } from "../types";

interface TimeseriesWithAssetId extends Timeseries {
  assetId: number;
}

export function useGetAssets(settingsTimeseriesList: SettingsTimeseries[]) {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const { timeseriesList, isLoading } = useGetTimeseriesList(
    settingsTimeseriesList.map(({ id }) => id),
  );
  const assetIds = timeseriesList
    .filter((timeseries): timeseries is TimeseriesWithAssetId =>
      Boolean(timeseries.assetId),
    )
    .map((timeseries) => timeseries.assetId);
  const { data: assets = [], error } = useSWR(
    assetIds.length > 0
      ? {
          type: "assets.retrieve",
          assetIds,
        }
      : null,
    ({ assetIds }) =>
      client.assets.retrieve(
        assetIds.map((assetId) => ({
          id: assetId,
        })),
      ),
  );
  if (error) {
    handleAPIError(error);
  }
  return {
    assets,
    isLoading,
  };
}
