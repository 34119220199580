export const granularityOptions = [
  {
    value: "1m",
    labelKey: "1-min",
  },
  {
    value: "5m",
    labelKey: "5-min",
  },
  {
    value: "10m",
    labelKey: "10-min",
  },
  {
    value: "15m",
    labelKey: "15-min",
  },
  {
    value: "30m",
    labelKey: "30-min",
  },
  {
    value: "1h",
    labelKey: "1-hour",
  },
  {
    value: "24h",
    labelKey: "1-day",
  },
  {
    value: "164h",
    labelKey: "1-week",
  },
  {
    value: "720h",
    labelKey: "1-month",
  },
];
