import { PageHeader } from "@ant-design/pro-layout";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "@properate/auth";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useMemo, useState } from "react";
import { GaugeFormWithView, Gauge } from "@/features/gauges";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { createGauge } from "@/services/gauge";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import {
  NoteBuilding,
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";

export function GaugeNew() {
  const t = useTranslations();

  useBuildingPageTitle(t("analysis.gauge.details.new-gauge"));
  const currentBuildingId = useCurrentBuildingId();
  const currentBuilding = useCurrentBuilding();
  const currentUser = useUser();
  const navigate = useNavigate();
  const [localTimeseriesId, setLocalTimeseriesId] = useState<number>();

  const notesBuildings = useMemo(() => {
    return [
      {
        id: currentBuilding.dataSetId,
        name: currentBuilding.name,
      } as NoteBuilding,
    ];
  }, [currentBuilding.dataSetId, currentBuilding.name]);

  const notesIdSet = useMemo(() => {
    return new Set(localTimeseriesId ? [localTimeseriesId] : []);
  }, [localTimeseriesId]);

  async function handleSubmit(
    gauge: Omit<Gauge, "created" | "owner" | "buildingId">,
  ) {
    await createGauge({
      ...gauge,
      buildingId: currentBuildingId,
      owner: currentUser.email,
      created: Date.now(),
    });
    navigate(`/asset/${currentBuildingId}/analysis/gauge`);
  }

  return (
    <>
      <PageHeader
        title={
          <Link to={`/asset/${currentBuildingId}/analysis/gauge`}>
            {t("analysis.gauge.name")}
          </Link>
        }
        extra={
          <ToggleSidebarButton
            key="notes"
            hiddenWhenSidebarVisible
            sidebarHiddenContent={t("notes.show-notes-button")}
            disabled={!localTimeseriesId}
          />
        }
      />
      <GaugeFormWithView
        onSubmit={handleSubmit}
        viewMode="bar"
        setLocalTimeseriesId={setLocalTimeseriesId}
      />
      {currentBuilding.dataSetId && (
        <NotesSidebar
          noteSource={NoteSource.WEB_ANALYSIS}
          buildings={notesBuildings}
          assetFilterMode={NotesAssetFilterMode.TimeseriesList}
          idSet={notesIdSet}
        />
      )}
    </>
  );
}
