import { Input } from "antd";
import { useTranslations } from "@properate/translations";
import { FormItem } from "./elements";

interface Props {
  onChange: (value: string) => unknown;
  defaultValue?: string;
}

export function TimeseriesSelectionSearch({
  onChange,
  defaultValue = "",
}: Props) {
  const t = useTranslations();

  return (
    <FormItem $flexBasis={600}>
      <label htmlFor="timeseries-search">
        {t("common.timeseries-modal.search")}
      </label>
      <Input
        defaultValue={defaultValue}
        id="timeseries-search"
        allowClear
        placeholder={t("common.timeseries-modal.search-placeholder")}
        onChange={({ currentTarget: { value } }) => onChange(value)}
        type="search"
      />
    </FormItem>
  );
}
