import { BarStack } from "@visx/shape";
import { PositionScale } from "@visx/shape/lib/types/base";
import { ScaleOrdinal } from "d3-scale";
import { useMemo } from "react";
import Color from "color";

type Props = {
  graphPoints: Record<string, number | string>[];
  legend: string[];
  xScale: PositionScale;
  yScale: PositionScale;
  colorScale: ScaleOrdinal<string, string>;
  highlightedLegend: string | null;
};

const getTimestamp = (d: Record<string, number>) => d.timestamp;

const renderBars =
  (highlightedLegend: string | null, legend: string[]) =>
  (
    barStacks: {
      bars: {
        index: number;
        x: number;
        y: number;
        width: number;
        height: number;
        color: string;
      }[];
      index: number;
    }[],
  ) => {
    return barStacks.map((barStack) =>
      barStack.bars.map((bar) => {
        return (
          <rect
            key={`bar-stack-${barStack.index}-${bar.index}`}
            x={bar.x}
            y={bar.y}
            height={bar.height}
            width={bar.width}
            fill={
              highlightedLegend && legend[barStack.index] === highlightedLegend
                ? Color(bar.color).darken(0.4).hex()
                : bar.color
            }
          />
        );
      }),
    );
  };
export const EnergyBars = ({
  graphPoints,
  legend,
  xScale,
  yScale,
  colorScale,
  highlightedLegend,
}: Props) => {
  const dataWithTotal = useMemo(
    () =>
      graphPoints.filter((d) => d.total !== undefined) as Record<
        string,
        number
      >[],
    [graphPoints],
  );

  return (
    <BarStack<Record<string, number>, string>
      data={dataWithTotal}
      keys={legend}
      x={getTimestamp}
      xScale={xScale}
      yScale={yScale}
      color={colorScale}
    >
      {renderBars(highlightedLegend, legend)}
    </BarStack>
  );
};
