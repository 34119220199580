import { browserFirestore } from "@properate/firebase";
import { BuildingSpec } from "@properate/common";
import { collection, doc } from "firebase/firestore";
import { useMemo } from "react";
import { useGetFirestoreDocument } from "@properate/ui";

export function useGetBuildingSpec(buildingId?: string | number) {
  const ref = useMemo(
    () =>
      buildingId
        ? doc(collection(browserFirestore, "buildings"), String(buildingId))
        : null,
    [buildingId],
  );

  return useGetFirestoreDocument<BuildingSpec>(ref);
}
