import { useEffect, useState } from "react";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { CenteredSpinner } from "@properate/ui";

export const IncidentTimer = ({
  sendToNextAt,
  refetchIncident,
}: {
  sendToNextAt: number;
  refetchIncident: VoidFunction;
}) => {
  const t = useTranslations();
  const [time, setTime] = useState<number>();

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | null = null;
    if (sendToNextAt > Date.now()) {
      intervalId = setInterval(() => {
        const sendToNextIn = sendToNextAt - Date.now();
        setTime(sendToNextIn > 0 ? sendToNextIn : 0);
      }, 1000);
    }

    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [sendToNextAt]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (time === 0) {
      refetchIncident();
    }
  }, [time]); // eslint-disable-line react-hooks/exhaustive-deps

  return time ? (
    <div className="flex gap-2 items-center">
      {t("incident.will-be-send-to-next-in")}
      <div className="text-red-700 font-bold text-lg">
        {dayjs(time).format("mm:ss")}
      </div>
    </div>
  ) : (
    <CenteredSpinner />
  );
};
