"use client";

import { notification } from "antd";
import { collection, deleteDoc, doc } from "firebase/firestore";
import useSWRMutation from "swr/mutation";
import { useTranslations } from "@properate/translations";
import { browserFirestore } from "@properate/firebase";
import { useTaskType } from "../contexts/task-type-context";

export type DeleteTaskArg = {
  where: {
    taskId: string;
  };
};

export function useDeleteTask() {
  const taskType = useTaskType();
  const t = useTranslations();

  return useSWRMutation(
    "deleteTask",
    async (_, extra: { arg: DeleteTaskArg }) => {
      await deleteDoc(
        doc(collection(browserFirestore, taskType), extra.arg.where.taskId),
      );
    },
    {
      onSuccess() {
        notification.success({
          message: t("task.notifications.delete.success", {
            taskType,
          }),
        });
      },
      onError(err) {
        console.error(err);

        notification.error({
          message: t("task.notifications.delete.error", {
            taskType,
          }),
        });
      },
    },
  );
}
