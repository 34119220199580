import { useMemo } from "react";
import { SensorInfo, SensorList } from "@properate/common";
import { useFloorPlanPin } from "../FloorPlanMapPinContext";
import { Pin, PinAlarm } from "../types";
import { useFloorPlan } from "../FloorPlanContext";

export function convertPinToTimeseriesInfo(
  timeseriesId: number,
  pin: Pin,
  alarm: PinAlarm | null,
): SensorInfo {
  return {
    id: timeseriesId,
    view: pin.variant === "value+name" ? "all" : pin.variant,
    unit: pin.unit ?? undefined,
    alias: pin.alias ?? undefined,
    alarmType: alarm?.level,
    max: alarm?.max ?? undefined,
    maxView: alarm?.maxLabel ?? undefined,
    min: alarm?.min ?? undefined,
    minView: alarm?.minLabel ?? undefined,
  };
}

export function useFloorPlanSensors() {
  const pin = useFloorPlanPin();
  const floorPlan = useFloorPlan();

  return useMemo(() => {
    if (pin.type === "room") {
      if (pin.alarms) {
        return Object.entries(pin.alarms).reduce<Record<string, SensorList>>(
          (acc, [timeseriesId, alarm]) => {
            return {
              ...acc,
              [timeseriesId]: {
                timeseriesInfo: [
                  convertPinToTimeseriesInfo(
                    Number.parseInt(timeseriesId, 10),
                    pin,
                    alarm,
                  ),
                ],
              },
            };
          },
          {},
        );
      }

      return {};
    }

    /**
     * The RoomInfo component uses the `sensors` property to render the alarm
     * status of each sensor, which is computed based on the alarm property on
     * each floor-plan pin.
     *
     * Here we convert the pin structure into a timeseries info object which can
     * be used inside the RoomInfo component.
     *
     * The index is just a unique identifier, on the previous floor plan implementation
     * this was generated when saving the pin, and would look like these:
     *
     * `floor-sensor-gas_19bc1800-055a-4abb-8dce-158d00e50a09`
     * `floor-sensor-motion_b5003506-c4a7-46db-9d08-205334d7bf95`
     * `floor-sensor-temperature_3281e6f2-9209-48c2-a2e6-230e91de340f`
     */
    return Object.values(floorPlan.pins).reduce<Record<string, SensorList>>(
      (acc, pin, index) => {
        if (pin.type === "room") {
          return acc;
        }

        return {
          ...acc,
          [index.toString()]: {
            timeseriesInfo: [
              convertPinToTimeseriesInfo(pin.timeseriesId, pin, pin.alarm),
            ],
          },
        };
      },
      {},
    );
  }, [pin, floorPlan.pins]);
}
