import styled from "styled-components";

// Even though this has the webkit prefix, it works in all browsers for a while.
// And it's only cosmetic, so it's not a problem if it doesn't work in some browsers.
export const LineClamp = styled.div<{ lineCount?: number }>`
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.lineCount ?? 2};
`;
