import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import file_icon from "file-icon-vectors/dist/icons/vivid/blank.svg";
import pdf_icon from "file-icon-vectors/dist/icons/vivid/pdf.svg";
import image_icon from "file-icon-vectors/dist/icons/vivid/image.svg";
import excel_icon from "file-icon-vectors/dist/icons/vivid/xls.svg";
import ppt_icon from "file-icon-vectors/dist/icons/vivid/ppt.svg";
import word_icon from "file-icon-vectors/dist/icons/vivid/docx.svg";
import archive_icon from "file-icon-vectors/dist/icons/vivid/zip.svg";
import text_icon from "file-icon-vectors/dist/icons/vivid/txt.svg";
import axios from "axios";
import { getType } from "mime/lite";
import { useCogniteClient } from "../../context/CogniteClientContext";

import { getToken } from "../../pages/autodeskViewer/Viewer-helpers";

type ThumbnailProps = {
  fileId: string | number;
  fileName: string;
  urn?: string;
};

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const THUMBNAILS = {
  pdf: pdf_icon,
  image: image_icon,
  excel: excel_icon,
  ppt: ppt_icon,
  word: word_icon,
  zip: archive_icon,
  text: text_icon,
  file: file_icon,
};

export const Thumbnail = ({ fileId, fileName, urn }: ThumbnailProps) => {
  const mimeType = getType(fileName) || "application/octet-stream";
  const fileType = getFileType(mimeType);

  if (["image/jpeg", "image/png", "image/png"].includes(mimeType)) {
    return <ImageThumbnail fileId={fileId} fileName={fileName} />;
  } else if (urn) {
    return <AutodeskThumbnail urn={urn} fileName={fileName} />;
  }
  return <Img src={THUMBNAILS[fileType]} />;
};

const AutodeskThumbnail = ({
  urn,
  fileName,
}: {
  urn: string;
  fileName: string;
}) => {
  const [thumbnail, setThumbnail] = useState<ReactNode>();

  useEffect(() => {
    // make sure the component does not update after cleanup is called
    let isMounted = true;
    const getThumbnail = async () => {
      // eslint-disable-next-line import/no-named-as-default-member
      const cancelTokenSource = axios.CancelToken.source();
      try {
        const source = await axios
          .get(
            `https://developer.api.autodesk.com/modelderivative/v2/designdata/${urn}/thumbnail`,
            {
              cancelToken: cancelTokenSource.token,
              responseType: "arraybuffer",
              headers: { Authorization: `Bearer ${await getToken()}` },
            },
          )
          .then((response) => {
            const blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            return window.URL.createObjectURL(blob);
          });

        if (isMounted) {
          setThumbnail(<Img src={`${source}`} />);
        }
      } catch (error: any) {
        if (!axios.isCancel(error) && isMounted) {
          setThumbnail(
            <Img
              src={
                THUMBNAILS[
                  getFileType(getType(fileName) || "application/octet-stream")
                ]
              }
            />,
          );
        }
      }
      return function cleanup() {
        isMounted = false;
        cancelTokenSource.cancel("Cancelling in cleanup");
      };
    };
    getThumbnail();
  }, [urn, fileName]);
  return <>{thumbnail}</>;
};

const ImageThumbnail = ({ fileId, fileName }: ThumbnailProps) => {
  const [thumbnail, setThumbnail] = useState<ReactNode>();
  const { client } = useCogniteClient();

  useEffect(() => {
    // make sure the component does not update after cleanup is called
    let isMounted = true;
    const getThumbnail = async () => {
      // eslint-disable-next-line import/no-named-as-default-member
      const cancelTokenSource = axios.CancelToken.source();
      try {
        const source = await axios
          .get(
            `https://api.cognitedata.com/api/v1/projects/${client.project}/files/icon?id=${fileId}`,
            {
              cancelToken: cancelTokenSource.token,
              responseType: "arraybuffer",
              headers: client.getDefaultRequestHeaders(),
            },
          )
          .then((response) => {
            const blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            return window.URL.createObjectURL(blob);
          });

        if (isMounted) {
          setThumbnail(<Img src={`${source}`} />);
        }
      } catch (error: any) {
        if (!axios.isCancel(error) && isMounted) {
          setThumbnail(
            <Img
              src={
                THUMBNAILS[
                  getFileType(getType(fileName) || "application/octet-stream")
                ]
              }
            />,
          );
        }
      }
      return function cleanup() {
        isMounted = false;
        cancelTokenSource.cancel("Cancelling in cleanup");
      };
    };
    getThumbnail();
  }, [client, fileId, fileName]);
  return <>{thumbnail}</>;
};

const getFileType = (mimeType: string | undefined) => {
  switch (mimeType) {
    case "application/pdf":
      return "pdf";
    case "image/gif":
    case "image/jpeg":
    case "image/png":
      return "image";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
      return "excel";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/vnd.ms-powerpoint":
      return "ppt";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "tapplication/msword":
      return "word";
    case "application/zip":
      return "zip";
    case "text/plain":
      return "text";
    default:
      return "file";
  }
};
