import { Step } from "react-joyride";
import { mutateUserSettings, useUserSettings } from "../services/userSettings";

export type Onboarding = Record<string, string | number | Date>;

export function useOnboarding(steps: Step[]) {
  const { data: preferences } = useUserSettings();
  const onboarding = preferences?.onboarding ?? [];

  let onboardingSteps: Step[] = [];

  if (preferences && !preferences.hintsDisabled) {
    onboardingSteps =
      onboarding.length > 0
        ? steps.filter((step) => !onboarding.includes(step.target as string))
        : steps;
  }

  const setOnboardingStepCompleted = async (target: string) => {
    await mutateUserSettings({
      onboarding: [...onboarding, target],
    });
  };

  return [onboardingSteps, setOnboardingStepCompleted] as const;
}
