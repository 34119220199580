import { Space, Popconfirm, Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { RightOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { ShareButtonWithDialog } from "@/components/ShareButtonWithDialog/ShareButtonWithDialog";

interface Props {
  name: string;
  onConfirmDeletion: () => unknown;
}

export function HeatMapHeader({ name, onConfirmDeletion }: Props) {
  const t = useTranslations();

  const currentBuildingId = useCurrentBuildingId();
  const location = useLocation();
  const shareLink = `${window.location.origin}${location.pathname}`;

  const title =
    name !== undefined ? (
      <Space>
        <Link to={`/asset/${currentBuildingId}/analysis/heat-map`}>
          {t("analysis.heat-map.name")}
        </Link>
        <RightOutlined />
      </Space>
    ) : (
      t("analysis.heat-map.name")
    );

  return (
    <PageHeader
      title={title}
      subTitle={name}
      extra={
        <Space>
          <ShareButtonWithDialog
            title={t("analysis.heat-map.share-heatmap")}
            shareLink={shareLink}
          >
            {t("analysis.share-text.heatmap")}
          </ShareButtonWithDialog>
          <Popconfirm
            title={t("analysis.heat-map.delete-confirmation")}
            onConfirm={onConfirmDeletion}
            okText={t("analysis.heat-map.delete")}
          >
            <Button
              data-testid="delete-button"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
          <ToggleSidebarButton
            key="notes"
            hiddenWhenSidebarVisible
            sidebarHiddenContent={t("notes.show-notes-button")}
          />
        </Space>
      }
    />
  );
}
