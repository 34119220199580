import { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Input, Radio, RadioChangeEvent, Row } from "antd";
import {
  formatUnit,
  getValueForMeasurement,
  restoreValueForMeasurement,
  SensorInfo,
  DERIVED_UNITS,
} from "@properate/common";
import { Timeseries } from "@cognite/sdk";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { Alarm } from "@/pages/common/SchemaGraph/elements";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { ButtonContainer, DetailsSection, RadioButton } from "./styles";

type Props = {
  timeseriesInfo: SensorInfo;
  setTimeseriesInfo: Function;
  timeseries?: Timeseries;
};
export const MonitorPane = ({
  timeseriesInfo,
  setTimeseriesInfo,
  timeseries,
}: Props) => {
  const t = useTranslations();

  const user = useUser();
  const building = useCurrentBuilding();
  const { overrideUnits } = useTimeseriesSettings(building.id);
  const overrideUnit =
    timeseries && overrideUnits && overrideUnits[timeseries.externalId!]?.unit;
  const [editMonitorViewMax, setEditMonitorViewMax] = useState(
    !!timeseriesInfo.maxView,
  );
  const [editMonitorViewMin, setEditMonitorViewMin] = useState(
    !!timeseriesInfo.minView,
  );
  const [min, setMin] = useState<string | undefined>(
    typeof timeseriesInfo.min === "number"
      ? getValueForMeasurement({
          value: timeseriesInfo.min,
          unit:
            (timeseries &&
              overrideUnits &&
              overrideUnits[timeseries.externalId!]?.unit) ||
            timeseries?.unit ||
            "",
        })?.toString()
      : undefined,
  );
  const [max, setMax] = useState<string | undefined>(
    typeof timeseriesInfo.max === "number"
      ? getValueForMeasurement({
          value: timeseriesInfo.max,
          unit: overrideUnit || timeseries?.unit || "",
        })?.toString()
      : undefined,
  );

  useEffect(() => {
    if (timeseries && timeseriesInfo.min) {
      setMin(
        (typeof overrideUnit === "string" && timeseries?.unit !== overrideUnit
          ? DERIVED_UNITS[timeseries?.unit || ""][overrideUnit].to(
              timeseriesInfo.min,
            )
          : getValueForMeasurement({
              value: timeseriesInfo.min,
              unit:
                (overrideUnits &&
                  overrideUnits[timeseries.externalId!]?.unit) ||
                timeseries?.unit ||
                "",
            })
        )?.toString(),
      );
    }
  }, [timeseriesInfo.min, overrideUnit, timeseries, overrideUnits]);

  useEffect(() => {
    if (timeseries && timeseriesInfo.max) {
      setMax(
        (typeof overrideUnit === "string" && timeseries?.unit !== overrideUnit
          ? DERIVED_UNITS[timeseries?.unit || ""][overrideUnit].to(
              timeseriesInfo.max,
            )
          : getValueForMeasurement({
              value: timeseriesInfo.max,
              unit: overrideUnit || timeseries?.unit || "",
            })
        )?.toString(),
      );
    }
  }, [
    timeseriesInfo.max,
    overrideUnit,
    timeseries?.unit,
    timeseries,
    timeseriesInfo.unit,
  ]);

  const handleEditMonitorViewMax = () => {
    setEditMonitorViewMax(true);
  };
  const handleEditMonitorViewMin = () => {
    setEditMonitorViewMin(true);
  };
  const handleEditMonitorViewDeleteMax = () => {
    setMax(undefined);
    setEditMonitorViewMin(false);
  };
  const handleEditMonitorViewDeleteMin = () => {
    setMin(undefined);
    setEditMonitorViewMin(false);
  };
  const handleAlarmTypeChange = (event: RadioChangeEvent) => {
    setTimeseriesInfo({
      ...timeseriesInfo,
      alarmType: event.target.value,
    });
  };
  const setValue = ({
    label,
    eventValue,
  }: {
    label: "max" | "min";
    eventValue: string;
  }) => {
    const value = eventValue.replace(",", ".");
    if (label === "max") {
      setMax(value);
    } else {
      setMin(value);
    }
    if (!value) {
      setTimeseriesInfo({
        ...timeseriesInfo,
        [label]: undefined,
      });
      return;
    }

    const formattedValue =
      typeof overrideUnit === "string" && timeseries?.unit !== overrideUnit
        ? DERIVED_UNITS[timeseries?.unit || ""][overrideUnit].from(
            parseFloat(value),
          )
        : restoreValueForMeasurement({
            value: parseFloat(value),
            unit: overrideUnit || timeseries?.unit || "",
          });
    setTimeseriesInfo({
      ...timeseriesInfo,
      [label]: formattedValue,
    });
  };
  const handleMaxValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue({ label: "max", eventValue: event.target.value });
  };
  const handleMinValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue({ label: "min", eventValue: event.target.value });
  };

  const handleMonitorViewChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: "maxView" | "minView",
  ) => {
    if (!event.target.value) {
      setTimeseriesInfo({
        ...timeseriesInfo,
        [type]: undefined,
      });
      if (event.type === "click") {
        // clear icon is clicked
        if (type === "maxView") {
          setEditMonitorViewMax(false);
        } else {
          setEditMonitorViewMin(false);
        }
      }
    } else {
      setTimeseriesInfo({
        ...timeseriesInfo,
        [type]: event.target.value,
      });
    }
  };
  const handleMonitorViewMaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleMonitorViewChange(event, "maxView");
  };
  const handleMonitorViewMinChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleMonitorViewChange(event, "minView");
  };
  return (
    <>
      <DetailsSection>
        <h2>{t("common.timeseries-graph-modal.monitor-fields.monitor")}</h2>
        <ButtonContainer marginBottom={8}>
          <Radio.Group
            disabled={user.isViewer}
            defaultValue={timeseriesInfo?.alarmType || "warning"}
            buttonStyle="solid"
            style={{ width: "100%" }}
            onChange={handleAlarmTypeChange}
          >
            <Row>
              <Col span={8}>
                <RadioButton value="status">
                  {t("common.timeseries-graph-modal.monitor-fields.status")}
                </RadioButton>
              </Col>
              <Col span={8}>
                <RadioButton value="warning">
                  {t("common.timeseries-graph-modal.monitor-fields.warning")}
                </RadioButton>
              </Col>
              <Col span={8}>
                <RadioButton value="error">
                  {t("common.timeseries-graph-modal.monitor-fields.error")}
                </RadioButton>
              </Col>
            </Row>
          </Radio.Group>
        </ButtonContainer>
        <Row gutter={8} style={{ marginBottom: 8 }}>
          <Col span={8}>
            {t("common.timeseries-graph-modal.monitor-fields.max")}:
          </Col>
          <Col span={16}>
            <Input
              disabled={user.isViewer}
              placeholder={`Max`}
              defaultValue={max}
              allowClear
              addonAfter={formatUnit(overrideUnit || timeseries?.unit || "")}
              onChange={handleMaxValueChange}
            />
          </Col>
        </Row>
        {max && (
          <Row gutter={8} style={{ marginBottom: 8 }}>
            <Col span={8}>
              {t("common.timeseries-graph-modal.monitor-fields.show")}:
            </Col>
            <Col span={16}>
              <Row>
                <Col flex="auto">
                  {editMonitorViewMax ? (
                    <Input
                      placeholder={t(
                        "common.timeseries-graph-modal.monitor-fields.show-placeholder",
                      )}
                      defaultValue={timeseriesInfo.maxView}
                      allowClear
                      onChange={handleMonitorViewMaxChange}
                    />
                  ) : (
                    <Alarm alarmType={timeseriesInfo.alarmType}>
                      {max.trim().length > 0 &&
                        t(
                          "common.timeseries-graph-modal.monitor-fields.over-value",
                          {
                            value: `${max}${formatUnit(
                              overrideUnit || timeseries?.unit || "",
                            )}`,
                          },
                        )}
                    </Alarm>
                  )}
                </Col>
                <Col flex="none">
                  {editMonitorViewMax ? (
                    <Button
                      size="small"
                      icon={<DeleteOutlined />}
                      danger
                      onClick={handleEditMonitorViewDeleteMax}
                    />
                  ) : (
                    <Button
                      size="small"
                      icon={<EditOutlined />}
                      disabled={user.isViewer}
                      onClick={handleEditMonitorViewMax}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Row gutter={8} style={{ marginBottom: 8 }}>
          <Col span={8} style={{ lineHeight: "28px" }}>
            {t("common.timeseries-graph-modal.monitor-fields.min")}:
          </Col>
          <Col span={16}>
            <Input
              disabled={user.isViewer}
              placeholder={t(
                "common.timeseries-graph-modal.monitor-fields.min",
              )}
              defaultValue={min}
              allowClear
              addonAfter={formatUnit(
                typeof overrideUnit === "string"
                  ? overrideUnit
                  : timeseries?.unit || "",
              )}
              onChange={handleMinValueChange}
            />
          </Col>
        </Row>
        {min && (
          <Row gutter={8} style={{ marginBottom: 8 }}>
            <Col span={8}>
              {t("common.timeseries-graph-modal.monitor-fields.show")}:
            </Col>
            <Col span={16}>
              <Row>
                <Col flex="auto">
                  {editMonitorViewMin ? (
                    <Input
                      placeholder={t(
                        "common.timeseries-graph-modal.monitor-fields.show-placeholder-min",
                      )}
                      defaultValue={timeseriesInfo.minView}
                      allowClear
                      onChange={handleMonitorViewMinChange}
                    />
                  ) : (
                    <Alarm alarmType={timeseriesInfo.alarmType}>
                      {min.trim().length > 0 &&
                        t(
                          "common.timeseries-graph-modal.monitor-fields.under-value",
                          {
                            value: `${min}${formatUnit(
                              overrideUnit || timeseries?.unit || "",
                            )}`,
                          },
                        )}
                    </Alarm>
                  )}
                </Col>
                <Col flex="none">
                  {editMonitorViewMin ? (
                    <Button
                      size="small"
                      icon={<DeleteOutlined />}
                      danger
                      onClick={handleEditMonitorViewDeleteMin}
                    />
                  ) : (
                    <Button
                      size="small"
                      icon={<EditOutlined />}
                      disabled={user.isViewer}
                      onClick={handleEditMonitorViewMin}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </DetailsSection>
    </>
  );
};
