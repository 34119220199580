import { Form, Button, InputNumber } from "antd";
import { useTranslations } from "@properate/translations";
import { FormItemArea } from "@/pages/buildingSettings/elements";

interface Props {
  area?: number;
  onChangeArea: (area: number) => unknown;
  isLoading: boolean;
}
export function BuildingAreaFormInput({
  area,
  onChangeArea,
  isLoading,
}: Props) {
  const t = useTranslations();

  return (
    <Form layout="inline" onFinish={({ area }) => onChangeArea(area)}>
      <FormItemArea
        name="area"
        initialValue={area}
        style={{ margin: 0, width: 120 }}
      >
        <InputNumber
          disabled={isLoading}
          style={{ width: "100%" }}
          min={0}
          placeholder="662"
          decimalSeparator=","
        />
      </FormItemArea>
      <Form.Item>
        <Button disabled={isLoading} type="primary" htmlType="submit">
          {t("settings.update.ok")}
        </Button>
      </Form.Item>
    </Form>
  );
}
