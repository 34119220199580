import { useEffect, useState } from "react";
import { Timeseries } from "@cognite/sdk";
import { Skeleton, Tooltip } from "antd";
import styled from "styled-components";
import dayjs from "@properate/dayjs";
import { getSystemCodeFromExternalId, DERIVED_UNITS } from "@properate/common";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { useProperateCogniteClient } from "@/context/ProperateCogniteClientContext";
import { Handle } from "@/components/Draggable/Handle";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { mapSchemaValue } from "@/pages/common/utils";
import error from "../SchemaView/TechnicalSchema/icons/error.svg";
import GraphModal from "../SchemaGraph";
import {
  formatMeasurementForSchema,
  formatUnit,
  getAlarmClass,
  isValid,
} from "../SchemaView/TechnicalSchema/utils";

type Props = {
  id: number;
  alias?: string;
  view?: "minimal" | "value" | "all";
  min?: number;
  max?: number;
  minView?: string;
  maxView?: string;
  alarmType?: "warning" | "error" | "status";
  selected?: boolean;
  onSelect?: Function;
  small?: boolean;
  onlyAlarm?: boolean;
  onChangeTimeseriesInfo?: Function;
  onDeleteTimeseries?: Function;
};
const Body = styled.div`
  // padding: 0 24px;
`;
const KpiComponent = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 0 0 24px -1px;
  padding-left: 1px;
  background: transparent;
  position: relative;
  cursor: pointer;

  & + &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: ${(props) => props.theme.neutral7};
    top: 0;
    left: -8px;
  }

  h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 0;
    color: ${(props) => props.theme.neutral1};
    font-size: 14px;
    text-transform: none;
  }

  &.small {
    margin-bottom: 0;

    h3 {
      font-size: 10px;
    }

    .value {
      font-size: 17px;
    }

    ${Body} {
      padding: 0 16px;
    }
  }

  .value {
    font-weight: bold;
    color: ${(props) => props.theme.neutral4};
    font-size: 24px;
  }

  .icon {
    height: 24px;
    display: inline-block;
    vertical-align: -5px;
  }

  &.small .icon {
    vertical-align: bottom;
  }

  .icon::before {
    position: relative;
    top: -1px;
  }

  &.onlyAlarm {
    display: none;
  }

  &.onlyAlarm.error {
    display: inline-block;
  }

  &.error .icon::before {
    content: url(${error}) !important;
  }

  &.selected {
    color: ${DEFAULT_GLOBAL_THEME.defaultColor};

    h3,
    h4 {
      color: ${DEFAULT_GLOBAL_THEME.defaultColor};
    }

    border-color: ${DEFAULT_GLOBAL_THEME.defaultColor};
  }
`;
const Alarm = styled.span``;

const getAlarm = (
  value: number,
  alarmType: "warning" | "error" | "status",
  unit: string,
  max?: number,
  min?: number,
  maxView = `Over ${max}${formatUnit(unit || "")}`,
  minView = `Under ${min}${formatUnit(unit || "")}`,
) => {
  const aboveMax = max !== undefined && value > max;
  const belowMin = min !== undefined && value < min;
  if (aboveMax) {
    return (
      <Alarm
        style={
          alarmType === "error"
            ? { color: "#FF5129" }
            : alarmType === "warning"
            ? { color: "#FFD80B" }
            : {}
        }
      >
        {maxView}{" "}
      </Alarm>
    );
  } else if (belowMin) {
    return (
      <Alarm
        style={
          alarmType === "error"
            ? { color: "#FF5129" }
            : alarmType === "warning"
            ? { color: "#FFD80B" }
            : {}
        }
      >
        {minView}{" "}
      </Alarm>
    );
  }
  return undefined;
};

const Value = ({
  value,
  alarmType,
  max,
  min,
  maxView,
  minView,
}: {
  value: {
    value: any;
    unit: string;
    textValue: string | false | undefined;
    timestamp: Date;
  };
  alarmType: "warning" | "error" | "status";
  max?: number;
  min?: number;
  maxView?: string;
  minView?: string;
}) => {
  return (
    <span
      className={`value`}
      style={!value || isValid(value.timestamp) ? {} : { color: "#ffd80b" }}
    >
      {value &&
        isValid(value.timestamp) &&
        (value.textValue
          ? value.textValue
          : formatMeasurementForSchema(value))}{" "}
      <span className="icon" />
      {value &&
        getAlarm(
          value?.value,
          alarmType || "warning",
          value?.unit || "",
          max,
          min,
          maxView,
          minView,
        )}
      {value && !isValid(value.timestamp) && (
        <Tooltip
          title={`Siste datapunkt kom for ${dayjs(value.timestamp).fromNow()}`}
        >
          <span style={{ whiteSpace: "nowrap" }}>
            {value.textValue || formatMeasurementForSchema(value)}
          </span>
        </Tooltip>
      )}
      {!value && <Skeleton.Button active />}
    </span>
  );
};
export const SchemaKpi = ({
  id,
  alias,
  min,
  max,
  minView,
  maxView,
  view,
  alarmType,
  selected,
  onSelect,
  small,
  onlyAlarm,
  onChangeTimeseriesInfo,
  onDeleteTimeseries,
}: Props) => {
  const [convertedMin, setConvertedMin] = useState<number>();
  const [convertedMax, setConvertedMax] = useState<number>();
  const [showGraph, setShowGraph] = useState<any>();
  const currentBuildingId = useCurrentBuildingId();
  const { overrideUnits } = useTimeseriesSettings(currentBuildingId);

  const [value, setValue] = useState<any>();
  const [timeseries, setTimeseries] = useState<Timeseries>();
  const properateCogniteClient = useProperateCogniteClient();

  const onClick = () => {
    onSelect ? onSelect() : setShowGraph(true);
  };

  useEffect(() => {
    let mounted = true;
    const get = async () => {
      try {
        const timeseries = await properateCogniteClient.getTimeseries(id);

        if (mounted && timeseries) {
          const system = getSystemCodeFromExternalId(timeseries.externalId!);
          const roomInfo = system.startsWith("200")
            ? await properateCogniteClient.getRoomInfo(timeseries.assetId!)
            : undefined;
          setTimeseries({
            ...timeseries,
            name: roomInfo?.name || timeseries.name!,
          });
        }
      } catch (error: any) {
        console.warn(`timeseries not found, aborting: ${id}`);
        setValue("--");
      }
    };
    get();
    return () => {
      mounted = false;
    };
  }, [id, properateCogniteClient]);

  useEffect(() => {
    let mounted = true;
    let timeoutId: any = 0;
    const update = async (id: number) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      try {
        const value = await properateCogniteClient.getLatestValue(id);

        const overrideUnit =
          value && overrideUnits && overrideUnits[value.externalId!]?.unit;

        const convertUnit = overrideUnit && overrideUnit !== value.unit;

        const convert =
          convertUnit && DERIVED_UNITS[value.unit!]?.[overrideUnit]
            ? DERIVED_UNITS[value.unit!][overrideUnit].to
            : (x: any) => x;

        const point = mapSchemaValue(value, overrideUnits, timeseries);

        if (mounted) {
          setValue(point);
          setConvertedMin(convert(min!));
          setConvertedMax(convert(max!));
          timeoutId = setTimeout(() => update(id), 60000);
        }
      } catch (error: any) {
        console.warn(error);
        console.warn(`Update failed - aborting: { id: ${id}`);
      }
    };

    if (timeseries?.id) {
      update(timeseries.id);
    }

    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [overrideUnits, timeseries, properateCogniteClient, min, max]);

  return (
    <>
      <KpiComponent
        onClick={onClick}
        className={`${onlyAlarm ? "onlyAlarm" : ""} ${small ? "small" : ""} ${
          selected ? "selected" : ""
        } ${
          (value &&
            getAlarmClass(
              value.value,
              alarmType || "warning",
              convertedMax,
              convertedMin,
            )) ||
          ""
        } kpi-component`}
        key={id}
      >
        <Body>
          {timeseries && (
            <header>
              <h3 title={timeseries.description + " " + timeseries.name}>
                <Handle />
                {timeseries.description}
                <br />
                {timeseries.name}
              </h3>
            </header>
          )}
          <Value
            value={value}
            alarmType={alarmType || "warning"}
            maxView={maxView}
            minView={minView}
          />
        </Body>
      </KpiComponent>
      {showGraph && (
        <GraphModal
          timeseriesInfo={{
            id,
            max,
            min,
            view,
            alias,
            alarmType: alarmType || "warning",
          }}
          showAlerts
          setTimeseriesInfo={onChangeTimeseriesInfo}
          deleteTimeseries={() => {
            onDeleteTimeseries!();
            setShowGraph(undefined);
          }}
          hide={() => setShowGraph(undefined)}
          showDocuments
          buildingId={currentBuildingId}
        />
      )}
    </>
  );
};
