import { CSSProperties, ReactNode } from "react";
import { GraphTitleStyled } from "./elements";

export const GraphTitle = ({
  title,
  style,
}: {
  title: ReactNode;
  style?: CSSProperties;
}) => {
  return <GraphTitleStyled style={style}>{title}</GraphTitleStyled>;
};
