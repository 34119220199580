import { formatUnit } from "@properate/common";
import { Avatar } from "antd";
import dayjs from "@properate/dayjs";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { useTranslations } from "@properate/translations";
import { formatNonScientific } from "@/utils/format";
import { MessageKey } from "@/utils/types";
import { ScatterplotPoint } from "../../types";
import { getAggregateDisplayValue, TOOLTIP_DATE_FORMAT } from "../../utils";
import { TooltipContainer, TooltipRow } from "./elements";

interface Props {
  scatterplotPoint: ScatterplotPoint;
  isNotesVisible: boolean;
}

export function ScatterplotGraphTooltip({
  scatterplotPoint: {
    x,
    y,
    timestampX,
    timestampY,
    metadata: { aggregateY, aggregateX, unitX, unitY, colorY },
    note,
  },
  isNotesVisible,
}: Props) {
  const t = useTranslations();
  const themeContext = useContext(ThemeContext);

  function getDisplayValue(isX: boolean) {
    const aggregatedDisplayValue = t(
      `analysis.xy-graph.aggregate.${getAggregateDisplayValue(
        isX ? aggregateX : aggregateY,
      )}` as MessageKey,
    );

    return `${formatNonScientific(isX ? x : y)} ${formatUnit(
      isX ? unitX : unitY,
    )} (${aggregatedDisplayValue})`;
  }
  return (
    <TooltipContainer>
      <TooltipRow>
        <Avatar
          size="small"
          style={{ flexShrink: 0, backgroundColor: colorY }}
        />
        <strong>{dayjs(timestampY).format(TOOLTIP_DATE_FORMAT)}</strong>
        <span>{getDisplayValue(false)}</span>
      </TooltipRow>
      <TooltipRow>
        <span>{t("analysis.xy-graph.timeseries-by-timeseries")}</span>
        <strong>{dayjs(timestampX).format(TOOLTIP_DATE_FORMAT)}</strong>
        <span>{getDisplayValue(true)}</span>
      </TooltipRow>
      {!!note && isNotesVisible && (
        <TooltipRow>
          <div className="max-w-md flex">
            <svg width={15} height={15} style={{ marginRight: 5 }}>
              <rect
                fill="transparent"
                stroke={themeContext.accent12}
                strokeWidth={5}
                width={15}
                height={15}
              />
            </svg>
            <div className="flex-1 truncate ...">{note.title}</div>
          </div>
          {note.count - 1 > 0 && <span>(+{note.count - 1})</span>}
        </TooltipRow>
      )}
    </TooltipContainer>
  );
}
