import { useNavigate, useParams } from "react-router-dom";
import { Alert, Spin } from "antd";
import {
  DisplayPage,
  TaskTypeProvider,
  getTaskTemplate,
} from "@properate/task-manager";
import { useUser } from "@properate/auth";
import styled, { css } from "styled-components";
import useSWR from "swr";
import FullLayout from "@/layout/FullLayout";

const Container = styled.div<{ $center?: boolean }>`
  padding: 1rem;
  max-width: 1280px;

  ${(props) =>
    props.$center &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    `}
`;

type Params = {
  id: string;
  taskTemplateId: string;
};

function TaskManagerTaskTemplatePageImpl() {
  const { id, taskTemplateId } = useParams() as Params;

  const taskTemplate = useSWR(
    ["task-template", taskTemplateId],
    async () => await getTaskTemplate(taskTemplateId),
  );

  const navigate = useNavigate();
  const user = useUser();

  if (taskTemplate.isLoading) {
    return (
      <Container $center>
        <Spin />
      </Container>
    );
  }

  if (taskTemplate.error) {
    return (
      <Container>
        <Alert type="error" message="Feil oppsto" />
      </Container>
    );
  }

  if (!taskTemplate.data) {
    return (
      <Container>
        <Alert type="error" message="Kunne ikke finne oppgave mal" />
      </Container>
    );
  }

  return (
    <TaskTypeProvider taskType="taskTemplates">
      <Container>
        <DisplayPage
          taskId={taskTemplateId}
          task={taskTemplate.data}
          userEmail={user.email}
          onDeleted={() => {
            navigate(id ? `/asset/${id}/workOrders` : "/org/tasks");
          }}
        />
      </Container>
    </TaskTypeProvider>
  );
}

type Props = {
  fullLayout?: boolean;
};

export function TaskManagerTaskTemplatePage(props: Props) {
  if (!props.fullLayout) {
    return <TaskManagerTaskTemplatePageImpl />;
  }

  return (
    <FullLayout pageName="Oppgave mal" headerRight={<></>}>
      <TaskManagerTaskTemplatePageImpl />
    </FullLayout>
  );
}
