import { PropsWithChildren } from "react";
import { Checkbox, Input, Select } from "antd";
import { useTranslations } from "@properate/translations";
import { parsePhoneNumber } from "libphonenumber-js";
import { getUserNameWithEmail } from "@properate/common";
import { AlertGroupMemberUI } from "@/features/alarms";

export type EditableColumnTypes =
  | "defaultUserEmail"
  | "notificationEmail"
  | "notificationPhone"
  | "notifyByEmail"
  | "notifyByPhone"
  | "name";

type EditableColumn = PropsWithChildren<{
  record: AlertGroupMemberUI;
  type: EditableColumnTypes;
  editing?: boolean;
  onChangeValue: (
    value: string | boolean | { value: string; label?: string },
  ) => void;
  filteredUserEmails: { label: string; value: string }[];
}>;

const selectOptionRenderer = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) =>
  getUserNameWithEmail({
    name: label,
    email: value,
  });

export const GroupEditableCell = ({
  children,
  editing,
  type,
  record,
  onChangeValue,
  filteredUserEmails,
  ...restProps
}: EditableColumn) => {
  const t = useTranslations();

  if (!editing) {
    return <td {...restProps}>{children}</td>;
  }

  return (
    <td {...restProps}>
      {type === "defaultUserEmail" && (
        <Select
          labelInValue
          labelRender={(props) =>
            selectOptionRenderer({
              label: props.label as string,
              value: props.value as string,
            })
          }
          optionRender={(option) => {
            return selectOptionRenderer({
              label: option.label as string,
              value: option.value as string,
            });
          }}
          className="w-full"
          placeholder={t("alert-group.user-default-email")}
          options={filteredUserEmails}
          value={
            {
              value: record.defaultUserEmail,
              label: record.name,
            } || undefined
          }
          onChange={(value) => onChangeValue(value)}
          showSearch
        />
      )}
      {type === "notificationEmail" && (
        <Input
          value={record.notificationEmail}
          placeholder={t("alert-group.user-email")}
          onChange={(event) => onChangeValue(event.target.value)}
        />
      )}
      {type === "notifyByEmail" && (
        <Checkbox
          checked={!!record.notifyByEmail}
          onChange={(event) => onChangeValue(event.target.checked)}
        />
      )}
      {type === "notificationPhone" && (
        <Input
          onBlur={() => {
            if (record.notificationPhone) {
              try {
                const parsedPhone = parsePhoneNumber(
                  record.notificationPhone,
                  "NO",
                );

                if (parsedPhone.isValid()) {
                  onChangeValue(parsedPhone.formatInternational());
                }
              } catch (e) {
                // Ignore
              }
            }
          }}
          value={record.notificationPhone}
          placeholder={t("alert-group.user-phone")}
          onChange={(event) => onChangeValue(event.target.value)}
        />
      )}
      {type === "notifyByPhone" && (
        <Checkbox
          checked={!!record.notifyByPhone}
          onChange={(event) => onChangeValue(event.target.checked)}
        />
      )}
    </td>
  );
};
