import { Space, Switch } from "antd";
import { useTranslations } from "@properate/translations";

interface Props {
  value: boolean;
  onChange: (value: boolean) => unknown;
}

export function HeatMapShowWeekNumberSwitch({ value, onChange }: Props) {
  const t = useTranslations();

  return (
    <Space>
      <label htmlFor="showWeekNumber">
        {t("analysis.heat-map.show-week-number")}:
      </label>
      <Switch id="showWeekNumber" checked={value} onChange={onChange} />
    </Space>
  );
}
