import { CategoryOption, TimeseriesFilters } from "../types";

//Meilisearch conditions operators like NOT, AND and OR must be written  uppercase in filter expression
export const DEFAULT_CATEGORY_OPTIONS: CategoryOption[] = [
  {
    value: "default",
    query: "(labels = real_value OR labels = set_point)",
    label: "Alle",
  },
  {
    value: "cooling",
    query:
      "(labels = real_value OR labels = set_point) AND (category = 35 OR category = 370 OR category = 735)",
    label: "Kjøling",
  },
  {
    value: "energy",
    query:
      "(labels = real_value OR labels = set_point) AND (labels = energy_meter OR labels = energy_meter OR labels = epred)",
    label: "Energi",
  },
  {
    value: "gas",
    query:
      "(labels = real_value OR labels = set_point) AND labels = gas_detector AND (category = 20 OR category = 200 OR category = 563)",
    label: "Gass",
  },
  {
    value: "heating",
    query:
      "(labels = real_value OR labels = set_point) AND (category = 320 OR category = 732)",
    label: "Oppvarming",
  },
  {
    value: "humidity",
    query:
      "(labels = real_value OR labels = set_point) AND labels = humidity_sensor AND (category = 20 OR category = 200 OR category = 563)",
    label: "Luftfuktighet",
  },
  {
    value: "label",
    query:
      "(labels = real_value OR labels = set_point) AND labels = motion AND (category = 20 OR category = 200 OR category = 563)",
    label: "Bevegelse",
  },
  {
    value: "indoorClimate",
    query: "(labels = real_value OR labels = set_point) AND (category = 200)",
    label: "Inneklima",
  },
  {
    value: "sanitary",
    query:
      "(labels = real_value OR labels = set_point) AND (category = 310 OR category = 320 OR category = 330)",
    label: "Sanitær",
  },
  {
    value: "technical",
    query:
      "(labels = real_value OR labels = set_point) AND (category = 310 OR category = 320 OR category = 35 OR category = 360 OR category = 732 OR category = 735 OR category = 370)",
    label: "Teknisk",
  },
  {
    value: "temperature",
    query:
      "(labels = real_value OR labels = set_point) AND labels = temperature",
    label: "Temperatur",
  },
  {
    value: "ventilation",
    query: "labels = real_value AND category = 360",
    label: "Ventilasjon",
  },
  {
    value: "weather",
    query: "labels = real_value AND labels = weather AND labels = frost",
    label: "Vær",
  },
  {
    value: "setPoint",
    query: "labels = set_point AND labels = writable AND labels != schedule",
    label: "Settpunkt",
  },
].sort((optionOne, optionTwo) =>
  optionOne.label.localeCompare(optionTwo.label),
);

export function getDefaultFilters(categoryOptions: CategoryOption[]) {
  return {
    system: null,
    category: categoryOptions[0].value,
    subBuilding: null,
    unit: null,
  };
}

export function buildFilterQuery(
  filter: Partial<TimeseriesFilters>,
  categoryOptions: CategoryOption[],
) {
  return Object.entries(filter).reduce((queryAcc, [filterKey, filterValue]) => {
    if (filterKey === "category") {
      const categoryQuery = categoryOptions.find(
        (categoryOption) => categoryOption.value === filterValue,
      )?.query;
      if (!categoryQuery) {
        throw new Error(
          `No query was found for category with value ${filterValue}`,
        );
      }
      return queryAcc ? `${queryAcc} AND ${categoryQuery}` : categoryQuery;
    }
    if (filterValue) {
      if (filterKey === "system") {
        const systemFilter = `(${filterValue
          .split(",")
          .map((system) => `system = "${system}"`)
          .join(" OR ")})`;
        return queryAcc ? `${queryAcc} AND ${systemFilter}` : systemFilter;
      }
      return queryAcc
        ? `${queryAcc} AND ${filterKey} = "${filterValue}"`
        : `${filterKey} = "${filterValue}"`;
    }
    return queryAcc;
  }, "");
}
