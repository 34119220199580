import { useMemo, useState } from "react";
import { ScaleLinearNumeric } from "@/utils/types";
import { getItemByProp } from "@/utils/array";
import { useTimeseriesList } from "@/features/notes";
import {
  ScaleWithTimeseriesId,
  ScaleWithUnit,
  ScatterplotPoint,
  ScatterplotPointsWithMetadata,
  SelectedAnalysisPoints,
  SetSelectedAnalysisPoints,
} from "../../types";
import { isScalesWithUnit } from "../../utils";
import { ScatterplotGraphDataCircle } from "./ScatterplotGraphDataCircle";
import { ScatterplotGraphTooltipContainer } from "./ScatterplotGraphTooltipContainer";

interface Props {
  scatterplotPointsWithMetadataList: ScatterplotPointsWithMetadata[];
  scaleX: ScaleLinearNumeric;
  scalesWithMetadataY: ScaleWithTimeseriesId[] | ScaleWithUnit[];
  interactive: boolean;
  highlightedTimeseriesIds: number[];
  isNotesVisible?: boolean;
  setSelectedPoints?: SetSelectedAnalysisPoints;
  selectedPoints?: SelectedAnalysisPoints;
  granularity: string;
}

export function ScatterplotGraphData({
  scatterplotPointsWithMetadataList,
  scaleX,
  scalesWithMetadataY,
  interactive,
  highlightedTimeseriesIds,
  isNotesVisible = false,
  selectedPoints,
  setSelectedPoints,
  granularity,
}: Props) {
  const [activeScatterplotPoint, setActiveScatterplotPoint] =
    useState<ScatterplotPoint | null>(null);

  const { timeseriesList } = useTimeseriesList();

  const assetListIdsByTimeseriesIds = useMemo(
    () =>
      timeseriesList?.reduce(
        (acc, { assetId, id }) => {
          if (assetId) {
            acc[id] = assetId;
          }
          return acc;
        },
        {} as Record<number, number>,
      ) || {},
    [timeseriesList],
  );

  const scatterplotPoints = useMemo(() => {
    return scatterplotPointsWithMetadataList.flatMap(
      ({ scatterplotPoints }) => scatterplotPoints,
    );
  }, [scatterplotPointsWithMetadataList]);

  const scatterplotPointCircles = scatterplotPointsWithMetadataList.flatMap(
    ({ scatterplotPoints, metadata: { colorY, unitY, timeseriesIdY } }) => {
      const isHighlighted = highlightedTimeseriesIds.includes(timeseriesIdY);
      const { scale: scaleY } = isScalesWithUnit(scalesWithMetadataY)
        ? getItemByProp(scalesWithMetadataY, unitY, "unit")
        : getItemByProp(scalesWithMetadataY, timeseriesIdY, "timeseriesId");
      return scatterplotPoints.map((scatterplotPoint) => (
        <ScatterplotGraphDataCircle
          scatterplotPoint={scatterplotPoint}
          key={`${
            scatterplotPoint.metadata.timeseriesIdY
          }-${scatterplotPoint.timestampY.valueOf()}`}
          color={colorY}
          scaleX={scaleX}
          scaleY={scaleY}
          isHighlighted={isHighlighted}
          isActive={
            scatterplotPoint === activeScatterplotPoint ||
            selectedPoints?.[0]?.timestamp ===
              scatterplotPoint.timestampX.valueOf()
          }
          hasNote={isNotesVisible && !!scatterplotPoint.note}
        />
      ));
    },
  );

  return (
    <>
      {scatterplotPointCircles}
      {interactive && scatterplotPoints.length > 0 && (
        <ScatterplotGraphTooltipContainer
          scaleX={scaleX}
          scalesWithMetadataY={scalesWithMetadataY}
          scatterplotPoints={scatterplotPoints}
          onHoverScatterplotPoint={setActiveScatterplotPoint}
          onLeaveHoveredScatterplotPoint={() => setActiveScatterplotPoint(null)}
          isNotesVisible={isNotesVisible}
          setSelectedPoints={setSelectedPoints}
          assetListIdsByTimeseriesIds={assetListIdsByTimeseriesIds}
          timeseriesX={
            scatterplotPointsWithMetadataList[0].metadata.timeseriesIdX
          }
          granularity={granularity}
        />
      )}
    </>
  );
}
