import { PlusOutlined } from "@ant-design/icons";
import { useUser } from "@properate/auth";
import { ASSET_DESCRIPTIONS } from "@properate/common";
import { AutoComplete, Button, Select, Space, Switch } from "antd";
import { FacetDistribution } from "meilisearch";
import { useState } from "react";
import { useTranslations } from "@properate/translations";
import { useFacets } from "@/pages/Components/useFacets";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { ContrastSearchInput } from "./elements";

interface Props {
  subBuildingFilter: string | null;
  componentTypeTranslatedFilter: string[];
  facetDistribution: FacetDistribution | null;
  toggleShowReturned: (value: boolean) => unknown;
  onChangeSubBuildingFilter: (value: string) => unknown;
  onChangeSystemFilter: (value: string) => unknown;
  onChangeComponentTypeTranslatedFilter: (value: string[]) => unknown;
  onSearch: (value: string) => unknown;
  onAddNew: () => unknown;
}

const getSystemDescription = (system: string) => {
  const category = Number(system.split(".")[0]);
  return ASSET_DESCRIPTIONS[category] || "";
};

export function ComponentsTableHeader({
  subBuildingFilter,
  toggleShowReturned,
  componentTypeTranslatedFilter,
  facetDistribution,
  onChangeSubBuildingFilter,
  onChangeSystemFilter,
  onChangeComponentTypeTranslatedFilter,
  onSearch,
  onAddNew,
}: Readonly<Props>) {
  const t = useTranslations();

  const user = useUser();
  const currentBuilding = useCurrentBuilding();
  const [systemQuery, setSystemQuery] = useState<string>("");

  const { data: systemFacets } = useFacets(
    "system",
    {
      buildingId: currentBuilding.id,
      subBuilding: subBuildingFilter,
      system: null,
      componentTypeTranslated: componentTypeTranslatedFilter,
      showReturned: true,
    },
    undefined,
    systemQuery,
  );

  return (
    <Space>
      <Switch
        checkedChildren={t("components.hide-returned")}
        unCheckedChildren={t("components.show-returned")}
        onChange={toggleShowReturned}
      />
      <Select
        key="selectedBuilding"
        placeholder={t("components.filter-by-building")}
        value={subBuildingFilter}
        onChange={onChangeSubBuildingFilter}
        style={{ width: 150 }}
        allowClear
      >
        {facetDistribution?.subBuilding &&
          Object.entries(facetDistribution.subBuilding)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((building) => (
              <Select.Option value={building[0]} key={building[0]}>
                {building[0]} ({building[1]})
              </Select.Option>
            ))}
      </Select>
      <AutoComplete
        key="selectedSystems"
        placeholder={t("components.filter-by-system")}
        options={
          systemFacets &&
          Object.entries(systemFacets)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((system) => {
              return {
                value: system[0],
                label: `${system[0]} ${getSystemDescription(system[0])} (${
                  system[1]
                })`,
              };
            })
        }
        style={{
          width: 250,
        }}
        onSelect={onChangeSystemFilter}
        onSearch={(text) => {
          setSystemQuery(text);
          onChangeSystemFilter(text);
        }}
        onClear={() => {
          setSystemQuery("");
          onChangeSystemFilter("");
        }}
        allowClear
      />
      <Select
        key="selectType"
        mode="multiple"
        placeholder={t("components.filter-by-type")}
        value={componentTypeTranslatedFilter}
        onChange={onChangeComponentTypeTranslatedFilter}
        style={{ width: 280 }}
        allowClear
      >
        {facetDistribution?.componentTypeTranslated &&
          Object.entries(facetDistribution.componentTypeTranslated)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((label) => (
              <Select.Option value={label[0]} key={label[0]}>
                {label[0]} ({label[1]})
              </Select.Option>
            ))}
      </Select>
      <ContrastSearchInput
        key="search"
        placeholder={t("components.search")}
        allowClear
        style={{ width: 300 }}
        onChange={(event) => onSearch(event.target.value)}
      />
      <Button
        icon={<PlusOutlined />}
        onClick={onAddNew}
        disabled={user.isViewer}
        data-testid="add-component-button"
      >
        {t("components.add")}
      </Button>
    </Space>
  );
}
