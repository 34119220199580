import styled from "styled-components";

export const HintKpis = styled.div`
  display: flex;
  width: 238px;
  height: 130px;
  color: ${(props) => props.theme.neutral1};
  background: ${(props) => props.theme.neutral10};
  border: 3px dashed white;
  stroke-width: 4;
  padding: 24px;

  &:hover {
    border-color: #12cf74;
    cursor: pointer;
  }

  border-radius: 12px;
  margin-bottom: 24px;
`;
