import { Radio, Select } from "antd";
import { useTranslations } from "@properate/translations";
import { CategoryOption } from "../../types";
import { getMinWidth } from "../../utils";
import { FilterFormItem, FilterLabel, RadioGroupVertical } from "./elements";

interface Props {
  value: string;
  onChange: (value: string) => unknown;
  options: CategoryOption[];
}

export function TimeseriesSelectionCategoryFilter({
  onChange,
  value,
  options,
}: Props) {
  const t = useTranslations();

  const shouldUseRadioButtons = options.length <= 16;

  const optionsSorted = options.slice().sort((categoryOne, categoryTwo) => {
    if (categoryOne.value === "all") {
      return -1;
    }
    if (categoryTwo.value === "all") {
      return 1;
    }
    return categoryOne.label.localeCompare(categoryTwo.label);
  });
  return (
    <FilterFormItem>
      <FilterLabel htmlFor="timeseries-category">
        {t("common.timeseries-modal.category")}
      </FilterLabel>
      {shouldUseRadioButtons ? (
        <RadioGroupVertical
          onChange={({ target: { value } }) => onChange(value)}
          value={value}
        >
          {optionsSorted.map((category) => (
            <Radio
              key={category.value}
              value={category.value}
              data-testid={category.value}
            >
              {category.label}
            </Radio>
          ))}
        </RadioGroupVertical>
      ) : (
        <Select
          style={{ minWidth: getMinWidth(options) }}
          value={value}
          options={optionsSorted}
          onChange={onChange}
        />
      )}
    </FilterFormItem>
  );
}
