import { AlarmRuleTypeName } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { AlarmSettingsFormFields } from "@/pages/alarms/details/AlarmSettingsPage/types";
import { alarmRuleTypes } from "@/pages/alarms/details/AlarmRuleTypes";
import { useAlarmDeviationsWithNotes } from "@/pages/alarms/details/hooks/useCogniteAlarmEvents";

export function DeviationsTableView() {
  const t = useTranslations();
  const [type] = useFormValue<AlarmRuleTypeName>(AlarmSettingsFormFields.Type);
  const props = useAlarmDeviationsWithNotes();

  const table = alarmRuleTypes[type]?.getDeviationsTable(props);

  return (
    <div className="w-full h-full flex flex-col flex-nowrap gap-3 min-h-[40vh]">
      <h2 className="m-0 p-0">
        {t("alarm-details.deviations-page.list.header")}
      </h2>
      {table}
    </div>
  );
}
