import { useTranslations } from "@properate/translations";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { useAlarmsStatistics } from "./utils";

export const AlertsAlarmStatistics = () => {
  const t = useTranslations();

  const {
    data: alarmsInAlertConfigurationStatistics,
    isLoadingAlarmsStatistics,
  } = useAlarmsStatistics();

  if (!isLoadingAlarmsStatistics && !alarmsInAlertConfigurationStatistics) {
    return null;
  }

  return (
    <SpinnerWithDelay className="my-4" isLoading={isLoadingAlarmsStatistics}>
      <div className="flex mb-4 divide-x divide-y-0 divide-border divide-solid">
        <div key="total-alarms" className="pr-4">
          <div className="font-medium text-m">
            {t("alert-rule.total-alarms")}
          </div>
          <div className="font-bold text-2xl">
            {alarmsInAlertConfigurationStatistics?.totalAlarmsLength || 0}
          </div>
        </div>
        <div key="included-alarms" className="px-4">
          <div className="font-medium text-m">
            {t("alert-rule.included-alarms")}
          </div>
          <div className="font-bold text-2xl">
            {alarmsInAlertConfigurationStatistics?.assignedAlarmsLength || 0}
          </div>
        </div>
        <div key="not-included-alarms" className="pl-4">
          <div className="font-medium text-m">
            {t("alert-rule.not-included-alarms")}
          </div>
          <div className="font-bold text-2xl">
            {alarmsInAlertConfigurationStatistics?.unassignedAlarmsLength || 0}
          </div>
        </div>
      </div>
    </SpinnerWithDelay>
  );
};
