import styled from "styled-components";
import { SketchPicker } from "react-color";

const Z_INDEX_COVER = 1;

export const ColorPickerHandle = styled.button<{
  $color: string;
  $size: number;
  $disabled: boolean;
}>`
  border: none;
  width: 100%;
  height: 100%;
  background-color: ${({ $color }) => $color};
  border-radius: 2px;
  position: relative;
  z-index: ${Z_INDEX_COVER + 1};
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  ${({ $size }) => `
    width: ${$size}px;
    height: ${$size}px;
  `}
`;

export const ColorPickerCover = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${Z_INDEX_COVER};
`;

export const ColorPickerStyled = styled(SketchPicker)<{
  $positionHandle: DOMRect;
}>`
  position: fixed;
  z-index: ${Z_INDEX_COVER + 1};
  ${({ $positionHandle }) => getColorPickerPositioning($positionHandle)}
`;

// This hardcoded approach won't work if we allow the sketchpicker's size to be customized
const COLOR_PICKER_HEIGHT = 310;
const COLOR_PICKER_WIDTH = 220;

function getColorPickerPositioning($positionHandle: DOMRect) {
  const positionHandleDistanceRight = window.innerWidth - $positionHandle.right;
  if (
    $positionHandle.left > COLOR_PICKER_WIDTH / 2 &&
    positionHandleDistanceRight > COLOR_PICKER_WIDTH / 2
  ) {
    const colorPickerLeft =
      $positionHandle.left - COLOR_PICKER_WIDTH / 2 + $positionHandle.width / 2;
    if ($positionHandle.top > COLOR_PICKER_HEIGHT) {
      // Top middle
      return `
        top: ${$positionHandle.top - COLOR_PICKER_HEIGHT}px;
        left: ${colorPickerLeft}px;
      `;
    }
    // Bottom middle
    return `
      top: ${$positionHandle.bottom}px;
      left: ${colorPickerLeft}px;
    `;
  }
  const colorPickerTop =
    $positionHandle.top - COLOR_PICKER_HEIGHT / 2 + $positionHandle.height / 2;
  if ($positionHandle.left < COLOR_PICKER_WIDTH / 2) {
    // Right middle
    return `
      top: ${colorPickerTop}px;
      left: ${$positionHandle.right}px;
    `;
  }
  // Left middle
  return `
    top: ${colorPickerTop}px;
    left: ${$positionHandle.left - COLOR_PICKER_WIDTH};
  `;
}
