import { Col } from "antd";
import { ParentSize } from "@visx/responsive";
import { WithSnapshotId } from "@properate/common";
import { HeatMap } from "../types";
import { HeatMapListCard } from "./HeatMapListCard";
import { RowWithGutterAndPadding } from "./elements";

interface Props {
  heatMaps: Array<WithSnapshotId<HeatMap>>;
  onConfirmRemoval: (snapshotId: string) => unknown;
}

export function HeatMapList({ heatMaps, onConfirmRemoval }: Props) {
  return (
    <RowWithGutterAndPadding>
      {heatMaps.map((heatMap) => (
        <Col key={heatMap.snapshotId} xs={24} lg={12}>
          <ParentSize>
            {({ width }) => (
              <HeatMapListCard
                heatMap={heatMap}
                onConfirmRemoval={onConfirmRemoval}
                width={width}
              />
            )}
          </ParentSize>
        </Col>
      ))}
    </RowWithGutterAndPadding>
  );
}
