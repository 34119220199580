import { Select } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { granularityOptions } from "./granularityOptions";

interface Props {
  granularity: string;
  onChangeGranularity: (value: string) => unknown;
}

export function GranularitySelection({
  granularity,
  onChangeGranularity,
}: Props) {
  const t = useTranslations();

  const options = granularityOptions.map((option) => ({
    value: option.value,
    label: t(`analysis.xy-graph.granularity.${option.labelKey}` as MessageKey),
  }));

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
      <label htmlFor="granularity-selection">
        {t("analysis.xy-graph.granularity-title")}
      </label>
      <Select
        id="granularity-selection"
        value={granularity}
        options={options}
        onChange={onChangeGranularity}
        style={{ width: "100px" }}
      />
    </div>
  );
}
