import { browserFirestore } from "@properate/firebase";
import { BuildingCertification } from "@properate/common";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  writeBatch,
} from "firebase/firestore";
import useSWRMutation from "swr/mutation";

type TriggerArg = {
  buildingId: number;
  certification: BuildingCertification;
  patch: Partial<BuildingCertification>;
};

export function useUpdateBuildingCertification() {
  return useSWRMutation(
    "building-certifications",
    async (_, extra: { arg: TriggerArg }) => {
      const { buildingId, certification, patch } = extra.arg;
      const patchedContact = { ...certification, ...patch };

      const ref = doc(
        collection(browserFirestore, "buildings"),
        buildingId.toString(),
      );

      const batch = writeBatch(browserFirestore);

      batch.set(
        ref,
        { certifications: arrayRemove(certification) },
        { merge: true },
      );

      batch.set(
        ref,
        { certifications: arrayUnion(patchedContact) },
        { merge: true },
      );

      await batch.commit();
    },
  );
}
