import { Radio } from "antd";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { FilterFormItem, FilterLabel, RadioGroupVertical } from "./elements";

interface Props {
  label: string;
  value: string | null;
  options: string[];
  onChange: (value: string) => unknown;
  isLoading: boolean;
}

export function TimeseriesSelectionFilter({
  label,
  value,
  options,
  onChange,
  isLoading,
}: Props) {
  const optionsSorted = [...options]
    .sort((optionOne, optionTwo) => optionOne.localeCompare(optionTwo))
    .map((option) => {
      return {
        value: option,
        label: option,
      };
    });
  const hasOneOption = optionsSorted.length === 1;

  return (
    <FilterFormItem>
      <FilterLabel htmlFor={label}>{label}</FilterLabel>
      <SpinnerWithDelay isLoading={isLoading}>
        <RadioGroupVertical
          id={label}
          {...(hasOneOption
            ? null
            : {
                value,
                onChange: ({ target: { value } }) => onChange(value),
              })}
        >
          {optionsSorted.map(({ value, label }) => (
            <Radio
              key={value}
              {...(hasOneOption ? { checked: true } : { value })}
            >
              {label}
            </Radio>
          ))}
        </RadioGroupVertical>
      </SpinnerWithDelay>
    </FilterFormItem>
  );
}
