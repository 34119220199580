import { useRootAssets } from "@/hooks/useRootAssets";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { getItemByProp } from "@/utils/array";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { SettingsTimeseries, TableRow } from "../types";
import { useGetAssets } from "./useGetAssets";
import { useGetParentAssets } from "./useGetParentAssets";

interface TableRowsResponse {
  tableRows: TableRow[];
  hasTimeseriesFromDifferentBuilding: boolean;
}

export function useGetTableRows(
  settingsTimeseriesList: SettingsTimeseries[],
): TableRowsResponse {
  const currentBuildingId = useCurrentBuildingId();
  const { timeseriesList } = useGetTimeseriesList(
    settingsTimeseriesList.map(({ id }) => id),
  );
  const { assets } = useGetAssets(settingsTimeseriesList);
  const { parentAssets } = useGetParentAssets(settingsTimeseriesList);
  const rootAssets = useRootAssets();

  if (
    timeseriesList.length === 0 ||
    assets.length === 0 ||
    rootAssets.length === 0
  ) {
    return {
      tableRows: [],
      hasTimeseriesFromDifferentBuilding: false,
    };
  }
  const hasTimeseriesFromDifferentBuilding = assets.some(
    (asset) => asset.rootId !== currentBuildingId,
  );
  return {
    tableRows: settingsTimeseriesList.map((settingsTimeseries) => {
      const timeseries = timeseriesList.find(
        (timeseries) => timeseries.id === settingsTimeseries.id,
      );
      // The timeseries can have been removed
      if (!timeseries) {
        return {
          timeseries: null,
          room: null,
          settingsTimeseries,
        };
      }
      // We retrieve all assets for a list of timeseries above, so the asset will always be there
      const assetForTimeseries = getItemByProp(assets, timeseries.assetId);
      const room =
        parentAssets
          .filter(
            (asset) =>
              asset.labels?.some(
                (label) =>
                  label.externalId === "room" ||
                  label.externalId === "floor" ||
                  label.externalId === "sub_floor" ||
                  label.externalId === "sub_building",
              ),
          )
          .find(
            (parentAsset) => parentAsset.id === assetForTimeseries.parentId,
          ) || null;
      if (hasTimeseriesFromDifferentBuilding) {
        // All assets we use should have a root asset
        const rootBuildingForTimeseries = getItemByProp(
          rootAssets,
          assetForTimeseries.rootId,
        );
        return {
          timeseries,
          settingsTimeseries,
          room,
          mainBuildingAddress: rootBuildingForTimeseries.metadata?.Adresse,
        };
      }
      return {
        timeseries,
        room,
        settingsTimeseries,
      };
    }),
    hasTimeseriesFromDifferentBuilding,
  };
}
