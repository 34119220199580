import {
  energyKPIs,
  energyKPIConfiguration,
  indoorClimateKPIs,
  indoorClimateKPIConfiguration,
  waterKPIs,
  waterKPIConfiguration,
} from "@/utils/kpi";

export interface KPIOption {
  label: string;
  value: string;
}

export interface KPIOptionWithGroup extends KPIOption {
  groupLabel: string;
}

export const energyKPIOptions: KPIOptionWithGroup[] = energyKPIs.map(
  (energyKPI) => ({
    groupLabel: energyKPIConfiguration[energyKPI].asOption.groupLabel,
    value: energyKPI,
    label: energyKPIConfiguration[energyKPI].asOption.shortLabel,
  }),
);

export const indoorClimateOptions: KPIOptionWithGroup[] = indoorClimateKPIs.map(
  (indoorClimateKPI) => ({
    groupLabel:
      indoorClimateKPIConfiguration[indoorClimateKPI].asOption.groupLabel,
    value: indoorClimateKPI,
    label: indoorClimateKPIConfiguration[indoorClimateKPI].asOption.shortLabel,
  }),
);

export const waterKPIOptions: KPIOptionWithGroup[] = waterKPIs.map(
  (waterKPI) => ({
    groupLabel: waterKPIConfiguration[waterKPI].asOption.groupLabel,
    value: waterKPI,
    label: waterKPIConfiguration[waterKPI].asOption.shortLabel,
  }),
);
