export const gaugeTypeOptions = [
  { value: "average", label: "average" },
  { value: "max", label: "max" },
  { value: "min", label: "min" },
  { value: "sum", label: "sum" },
  {
    value: "latest",
    label: "latest-datapoint",
  },
];

export const sinceOptions = [
  {
    value: "5m-ago",
    label: "5-min",
  },
  {
    value: "15m-ago",
    label: "15-min",
  },
  {
    value: "30m-ago",
    label: "30-min",
  },
  {
    value: "1h-ago",
    label: "1-hour",
  },
  {
    value: "2h-ago",
    label: "2-hour",
  },
  {
    value: "4h-ago",
    label: "4-hour",
  },
  {
    value: "12h-ago",
    label: "12-hour",
  },
  {
    value: "24h-ago",
    label: "24-hour",
  },
];

export const zoneAmountOptions = [
  {
    value: 2,
    labelKey: "2-zones",
  },
  {
    value: 3,
    labelKey: "3-zones",
  },
];
