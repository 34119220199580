import { useRouteLoaderData } from "react-router-dom";
import { Asset } from "@cognite/sdk";

export interface RootAsset extends Asset {
  externalId: string;
}

export function useRootAssets(): RootAsset[] {
  const rootAssets = useRouteLoaderData("root") as Asset[] | null;
  if (!rootAssets) {
    throw new Error(
      `We could not find any root assets. This component is not nested under the RouteProvider`,
    );
  }
  return rootAssets as RootAsset[];
}
