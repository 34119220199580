import { TimeSpan } from "@properate/common";
import dayjs from "@properate/dayjs";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { AlarmDetailsZoomedTimespanFormIds } from "@/pages/alarms/details/components/AlarmTimeseriesGraph/AlarmTimeseriesNavigator";

export function useAlarmDetailsTimeSpan(): TimeSpan {
  const [fromDate] = useFormValue<number>(
    AlarmDetailsZoomedTimespanFormIds.FromTime,
  );
  const [toDate] = useFormValue<number>(
    AlarmDetailsZoomedTimespanFormIds.ToTime,
  );
  if (!fromDate || !toDate || fromDate >= toDate) {
    return [dayjs().subtract(1, "year").valueOf(), dayjs().valueOf()];
  }
  return [fromDate, toDate];
}
