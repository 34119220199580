import { KeyboardEvent } from "react";
import {
  CalculatorGrid,
  DigitButton,
  ExpressionInput,
  OperationButton,
} from "./elements";

interface Props {
  value?: string;
  onChange?: (value: string | undefined) => unknown;
  className?: string;
}

const DATAPOINT_OPERAND = "DP";
const DECIMAL_POINT = ".";
const FACULTY = "!";
const POW = "^";
const MODULO = "%";
const LOGARITHM = "log";
const SQUARE_ROOT = "sqrt";
const ABSOLUTE = "abs";
const SIMPLE_OPERATORS = ["+", "-", "/", "*"];
const DIGITS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const BRACES = ["(", ")"];

export function DatapointCalculator({
  value: expression = "",
  onChange,
  className,
}: Props) {
  function addToExpression(value: string) {
    onChange?.(`${expression}${value}`);
  }

  function removeFromExpression() {
    if (expression.length === 1) {
      clear();
    } else if (expression.length > 0) {
      onChange?.(expression.slice(0, -1));
    }
  }

  function clearEntry() {
    removeFromExpression();
  }

  function clear() {
    onChange?.(undefined);
  }

  function handleKeydown({ key }: KeyboardEvent<HTMLInputElement>) {
    if (
      DIGITS.includes(key) ||
      SIMPLE_OPERATORS.includes(key) ||
      BRACES.includes(key) ||
      key === POW ||
      key === FACULTY ||
      key === MODULO
    ) {
      addToExpression(key);
      return;
    }
    if (key === DECIMAL_POINT || key === ",") {
      addToExpression(DECIMAL_POINT);
      return;
    }
    if (key.toLowerCase() === "q") {
      addToExpression(`${SQUARE_ROOT}(`);
      return;
    }
    if (key.toLowerCase() === "l") {
      addToExpression(`${LOGARITHM}(`);
      return;
    }
    if (key.toLowerCase() === "a") {
      addToExpression(`${ABSOLUTE}(`);
      return;
    }
    if (key.toLowerCase() === "d") {
      addToExpression(DATAPOINT_OPERAND);
      return;
    }
    if (key === "Backspace") {
      removeFromExpression();
    }
  }

  return (
    <CalculatorGrid className={className}>
      <ExpressionInput
        value={expression}
        readOnly
        placeholder="DP+5*min(DP,2)"
        onKeyDown={handleKeydown}
      />
      <OperationButton onClick={() => addToExpression(MODULO)}>
        {MODULO}
      </OperationButton>

      <OperationButton
        style={{ gridColumn: "span 2" }}
        onClick={() => addToExpression("min(DP, ")}
      >
        {/* eslint-disable-line react/jsx-no-literals */}min(DP, x)
      </OperationButton>
      <OperationButton
        style={{ gridColumn: "span 2" }}
        onClick={() => addToExpression("max(DP, ")}
      >
        {/* eslint-disable-line react/jsx-no-literals */}max(DP, x)
      </OperationButton>

      <OperationButton onClick={() => addToExpression(FACULTY)}>
        x{FACULTY}
      </OperationButton>
      <OperationButton onClick={() => addToExpression("(")}>(</OperationButton>
      <OperationButton onClick={() => addToExpression(")")}>)</OperationButton>
      <OperationButton danger onClick={clear}>
        C
      </OperationButton>
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <OperationButton danger onClick={clearEntry}>
        CE
      </OperationButton>

      <OperationButton onClick={() => addToExpression(`${LOGARITHM}(`)}>
        {LOGARITHM}
      </OperationButton>
      <DigitButton onClick={() => addToExpression("7")}>7</DigitButton>
      <DigitButton onClick={() => addToExpression("8")}>8</DigitButton>
      <DigitButton onClick={() => addToExpression("9")}>9</DigitButton>
      <OperationButton onClick={() => addToExpression("/")}>/</OperationButton>

      <OperationButton onClick={() => addToExpression(`${SQUARE_ROOT}(`)}>
        {SQUARE_ROOT}
      </OperationButton>
      <DigitButton onClick={() => addToExpression("4")}>4</DigitButton>
      <DigitButton onClick={() => addToExpression("5")}>5</DigitButton>
      <DigitButton onClick={() => addToExpression("6")}>6</DigitButton>
      <OperationButton onClick={() => addToExpression("*")}>*</OperationButton>

      <OperationButton onClick={() => addToExpression(POW)}>
        x<sup>y</sup>
      </OperationButton>
      <DigitButton onClick={() => addToExpression("1")}>1</DigitButton>
      <DigitButton onClick={() => addToExpression("2")}>2</DigitButton>
      <DigitButton onClick={() => addToExpression("3")}>3</DigitButton>
      <OperationButton onClick={() => addToExpression("-")}>-</OperationButton>

      <OperationButton onClick={() => addToExpression(`${ABSOLUTE}(`)}>
        abs
      </OperationButton>
      <DigitButton onClick={() => addToExpression("0")}>0</DigitButton>
      <OperationButton onClick={() => addToExpression(DECIMAL_POINT)}>
        {DECIMAL_POINT}
      </OperationButton>
      <OperationButton onClick={() => addToExpression(DATAPOINT_OPERAND)}>
        {DATAPOINT_OPERAND}
      </OperationButton>
      <OperationButton onClick={() => addToExpression("+")}>+</OperationButton>
    </CalculatorGrid>
  );
}
