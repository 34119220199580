import { browserFirestore } from "@properate/firebase";
import { BuildingTenant, BuildingTenantWithId } from "@properate/common";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  writeBatch,
} from "firebase/firestore";
import useSWRMutation from "swr/mutation";

type TriggerArg = {
  buildingId: number;
  tenant: BuildingTenantWithId;
  patch: Partial<BuildingTenant>;
};

export function useUpdateBuildingTenant() {
  return useSWRMutation(
    "building-tenants",
    async (_, extra: { arg: TriggerArg }) => {
      const { buildingId, tenant, patch } = extra.arg;
      const patchedTenant = { ...tenant, ...patch };

      const ref = doc(
        collection(browserFirestore, "buildings"),
        buildingId.toString(),
      );

      const batch = writeBatch(browserFirestore);

      batch.set(ref, { tenants: arrayRemove(tenant) }, { merge: true });
      batch.set(ref, { tenants: arrayUnion(patchedTenant) }, { merge: true });

      await batch.commit();
    },
  );
}
