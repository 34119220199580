import { ExternalId, Timeseries } from "@cognite/sdk";
import { useUser } from "@properate/auth";
import { Button, Form, Space } from "antd";
import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  TimeseriesSelectionModal,
  TimeseriesHideableFilter,
} from "@/features/timeseries";
import { useCogniteClient } from "@/context/CogniteClientContext";

const FormItem = Form.Item;

interface Props {
  externalIds: ExternalId[];
  onChange: (externalIds: ExternalId[]) => void;
  hiddenFilters?: TimeseriesHideableFilter[];
}

function MultiTimeseriesSelector({
  externalIds,
  onChange,
  hiddenFilters,
}: Props) {
  const { client } = useCogniteClient();
  const user = useUser();

  const [
    isTimeseriesSelectionModalVisible,
    setIsTimeseriesSelectionModalVisible,
  ] = useState(false);
  const [selectedTimeseries, setSelectedTimeseries] = useState<Timeseries[]>(
    [],
  );

  const onTimeseriesChange = (ts: Timeseries[]) => {
    setSelectedTimeseries(ts);
    onChange(
      ts
        .map((ts) => ts.externalId)
        .filter((extId): extId is string => !!extId)
        .map((extId) => {
          return { externalId: extId };
        }),
    );
  };

  useEffect(() => {
    const loadTimeseries = async (externalIds: ExternalId[]) => {
      setSelectedTimeseries(await client.timeseries.retrieve(externalIds));
    };
    if (externalIds.length > 0) loadTimeseries(externalIds);
    else setSelectedTimeseries([]);
  }, [client.timeseries, externalIds]);

  return (
    <>
      <FormItem>
        <Space>
          <Button
            onClick={() => setIsTimeseriesSelectionModalVisible(true)}
            disabled={user.isViewer}
          >
            <EditOutlined />
          </Button>
        </Space>
      </FormItem>
      <TimeseriesSelectionModal
        hiddenFilters={hiddenFilters}
        open={isTimeseriesSelectionModalVisible}
        onOk={onTimeseriesChange}
        onHide={() => setIsTimeseriesSelectionModalVisible(false)}
        selectedIds={selectedTimeseries.map((ts) => ts.id)}
      />
    </>
  );
}

export default MultiTimeseriesSelector;
