import { Space } from "antd";
import { useTranslations } from "@properate/translations";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import {
  Gauge,
  Since,
  GaugeType,
  GaugeZones,
  GaugeViewMode,
} from "../../types";
import { getGaugeDescription } from "../../utils";
import { GaugeFormWithView } from "../GaugeFormWithView/GaugeFormWithView";

interface Props {
  viewMode: GaugeViewMode;
  timeseriesId: number;
  zones: GaugeZones;
  type: GaugeType;
  max: number;
  min: number;
  name: string;
  since?: Since;
  mathExpression?: string;
  onEdit: (value: Partial<Gauge>) => unknown;
}

export function GaugeDetails({
  viewMode,
  timeseriesId,
  zones,
  type,
  max,
  min,
  name,
  since,
  mathExpression,
  onEdit,
}: Props) {
  const t = useTranslations();

  const {
    timeseriesList: [timeseries],
    isLoading,
  } = useGetTimeseriesList([timeseriesId]);
  const currentBuildingId = useCurrentBuildingId();
  const { overrideUnits, isLoading: isLoadingOverrideUnit } =
    useTimeseriesSettings(currentBuildingId);
  const overrideUnit =
    timeseries && overrideUnits && overrideUnits[timeseries.externalId!]?.unit;

  return (
    <Space direction="vertical">
      <h2>{getGaugeDescription(type, t, since)}</h2>
      <SpinnerWithDelay isLoading={isLoading}>
        {!isLoadingOverrideUnit && (
          <GaugeFormWithView
            viewMode={viewMode}
            max={max}
            min={min}
            name={name}
            since={since}
            timeseries={timeseries}
            zones={zones}
            type={type}
            unit={overrideUnit ?? timeseries?.unit}
            mathExpression={mathExpression}
            onSubmit={onEdit}
          />
        )}
      </SpinnerWithDelay>
    </Space>
  );
}
