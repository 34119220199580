import { CogniteClient } from "@cognite/sdk";
import _ from "lodash";

export const getTimeseriesForAssets = async (
  client: CogniteClient,
  assetIds: number[],
) => {
  const getChunk = async (ids: number[]) => {
    return (
      await client!.timeseries
        .list({
          filter: {
            assetIds: ids,
          },
        })
        .autoPagingToArray({ limit: -1 })
    ).filter((ts) => ts.externalId!.startsWith("TS"));
  };
  return (
    await Promise.all(_.chunk(assetIds, 100).map((chunk) => getChunk(chunk)))
  ).flat();
};
