"use client";

import { Select } from "antd";
import { useUpdateTask } from "../../hooks/use-update-task";
import { useTaskStatusTranslations } from "../../hooks/intl/use-task-status-translations";
import { ETaskStatus } from "../../schemas";

type Props = {
  taskId: string;
  value: ETaskStatus;
};

export function StatusInput(props: Props) {
  const updateTask = useUpdateTask();
  const tTaskStatus = useTaskStatusTranslations();

  function handleUpdateStatus(status: ETaskStatus) {
    updateTask.trigger({
      where: {
        taskId: props.taskId,
      },
      data: {
        status,
      },
    });
  }

  const statusOptions = tTaskStatus.keys.map((key) => ({
    value: key,
    label: tTaskStatus.record[key],
  }));

  return (
    <Select
      className="w-full overflow-hidden"
      value={props.value}
      disabled={updateTask.isMutating}
      onChange={handleUpdateStatus}
    >
      {statusOptions.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          <div className="flex items-center gap-2">{option.label}</div>
        </Select.Option>
      ))}
    </Select>
  );
}
