import { Button } from "antd";
import { useState } from "react";
import { CogniteEvent, EventFilterRequest, ListResponse } from "@cognite/sdk";
import { useSetSidebarData } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { deleteNote } from "@/eepApi";
import { repeatUntilSuccessful } from "./utils";
import {
  NoteMetaProps,
  NotesSidebarValues,
  NotesSidebarViewState,
} from "./types";
import { useCogniteEventFilter, useCogniteEvents } from "./hooks";

export function NoteActions({ note }: NoteMetaProps) {
  const t = useTranslations();
  const [loading, setLoading] = useState(false);
  const { client } = useCogniteClient();
  const { filter } = useCogniteEventFilter();
  const { data, isLoading, mutate } = useCogniteEvents(filter);
  const { setSidebarData } = useSetSidebarData<NotesSidebarValues>();

  async function handleDeleteNote() {
    if (!data || !filter) {
      return;
    }
    if (!note.id || !note.dataSetId) {
      throw new Error("Cannot delete note without id and dataSetId");
    }
    setLoading(true);
    await deleteNote(note.id, note.dataSetId);
    const { success, result } = await repeatUntilSuccessful<
      ListResponse<CogniteEvent[]>,
      EventFilterRequest,
      number
    >(
      client.events.list,
      filter,
      (events, id) =>
        !events?.items.some((event: CogniteEvent) => event.id === id),
      note.id,
    );
    if (success) {
      mutate(result.items);
    }
    setLoading(false);
  }

  function handleEditNote() {
    setSidebarData({
      viewState: NotesSidebarViewState.edit,
      note,
      noteSource: note.source,
    });
  }

  return (
    <div className={"w-full flex justify-end gap-2"}>
      <Button
        type="default"
        size="small"
        danger
        htmlType="button"
        loading={loading || isLoading}
        onClick={handleDeleteNote}
      >
        {t("notes.delete")}
      </Button>
      <Button
        type="default"
        size="small"
        htmlType="button"
        onClick={handleEditNote}
      >
        {t("notes.edit")}
      </Button>
    </div>
  );
}
