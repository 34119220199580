import { AlarmWithId } from "@properate/common";
import { noop } from "lodash";
import { createContext, ReactNode, useContext, useState } from "react";
import { useUser } from "@properate/auth";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { UserSettings } from "@/utils/types";
import { getFlattenedSubscriptions } from "../AlarmsTable/utils/getFlattenedSubscriptions";

type FilterTypes = UserSettings["filters"]["alarmsSieve"];
type FilterType = FilterTypes[number];

type ContextType = {
  search: string;
  filters: FilterTypes;
  toggleFilter: (filter: FilterType) => void;
  filter(alarms: AlarmWithId[] | undefined): AlarmWithId[];
  setFilters: (filters: FilterTypes) => void;
  setSearch: (search: string) => void;
};

const AlarmsTableFiltersContext = createContext<ContextType>({
  search: "",
  filters: [],
  setFilters: noop,
  toggleFilter: noop,
  setSearch: noop,
  filter(alarms: AlarmWithId[] | undefined) {
    if (!alarms) {
      return [];
    }

    return alarms;
  },
});

const defaultFilters: FilterTypes = ["withActiveEvents"];

export function AlarmsTableFiltersProvider(props: {
  children: ReactNode;
  buildingIds?: number[];
}) {
  const user = useUser();
  const [search, setSearch] = useState("");
  const { data } = useUserSettings();

  const filters: FilterTypes = data?.filters?.alarmsSieve ?? defaultFilters;

  const setFilters = (filters: FilterTypes) =>
    mutateUserSettings({
      filters: {
        alarmsSieve: filters,
      },
    });

  function filter(alarms: AlarmWithId[] | undefined) {
    if (!alarms) {
      return [];
    }

    const searchTerm = search.toLowerCase();

    return alarms.filter((alarm) => {
      if (
        filters.includes("fromUser") &&
        alarm.owner !== user.email &&
        !alarm.subscriptions?.find((s) => s.emails?.includes(user.email))
      ) {
        return false;
      }

      if (filters.includes("withActiveEvents") && !alarm.activeEvent) {
        return false;
      }

      if (searchTerm) {
        return (
          alarm.name?.toLowerCase().includes(searchTerm) ||
          (props.buildingIds?.length &&
            (alarm.buildingAddress || "").toLowerCase().includes(searchTerm)) ||
          alarm.description?.toLowerCase().includes(searchTerm) ||
          alarm.timeseries?.name?.toLowerCase().includes(searchTerm) ||
          alarm.owner?.toLowerCase().includes(searchTerm) ||
          getFlattenedSubscriptions(alarm)
            .join(", ")
            .toLowerCase()
            .includes(searchTerm)
        );
      }

      return true;
    });
  }

  function toggleFilter(filter: FilterType) {
    if (filters.includes(filter)) {
      setFilters(filters.filter((f) => f !== filter));
    } else {
      setFilters([...filters, filter]);
    }
  }

  return (
    <AlarmsTableFiltersContext.Provider
      value={{
        search,
        filters,
        toggleFilter,
        setFilters,
        filter,
        setSearch,
      }}
    >
      {props.children}
    </AlarmsTableFiltersContext.Provider>
  );
}

export function useAlarmsTableFiltersContext(requiredProvider = false) {
  const ctx = useContext(AlarmsTableFiltersContext);

  if (requiredProvider && !ctx) {
    throw new Error("Missing AlarmsTableFiltersProvider");
  }

  return ctx;
}
