import { useContext, createContext, ReactNode, useMemo } from "react";
import { useCogniteClient } from "@/context/CogniteClientContext";
import {
  cogniteBatch,
  ProperateCogniteClient,
} from "@/context/api/cogniteBatch";

export const ProperateCogniteClientContext =
  createContext<ProperateCogniteClient | null>(null);

interface Props {
  children: ReactNode;
}

export function ProperateCogniteClientProvider({ children }: Props) {
  const { client } = useCogniteClient();
  const api = useMemo(() => cogniteBatch(client), [client]);

  return (
    <ProperateCogniteClientContext.Provider value={api}>
      {children}
    </ProperateCogniteClientContext.Provider>
  );
}

export const useProperateCogniteClient = (): ProperateCogniteClient => {
  const properateCogniteClient = useContext(ProperateCogniteClientContext);
  if (!properateCogniteClient) {
    throw new Error(
      '"useProperateCogniteClient" was called from a component that does not have a ProperateCogniteClientProvider parent',
    );
  }
  return properateCogniteClient;
};
