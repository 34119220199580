import { useSetSidebarData, useSidebarData } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { Button } from "antd";
import { NotesSidebarValues, NotesSidebarViewState } from "./types";
import SomethingWrong from "./SomethingWrong";
import { CenterContent } from "./utils";
import NotesSidebarTitlePortal from "./NotesSidebarTitlePortal";

export function CustomContent() {
  const { customContent, viewState } = useSidebarData<NotesSidebarValues>();
  const { setSidebarData } = useSetSidebarData<NotesSidebarValues>();
  const t = useTranslations();

  if (viewState !== NotesSidebarViewState.customContent) {
    const errorMessage = t("notes.custom-content.errors.invalid-viewstate");
    console.error(errorMessage);
    return <SomethingWrong extraMessage={errorMessage} />;
  }

  if (!customContent) {
    const errorMessage = t(
      "notes.custom-content.errors.missing-custom-content",
    );
    console.error(errorMessage);
    return <SomethingWrong extraMessage={errorMessage} />;
  }

  return (
    <>
      <NotesSidebarTitlePortal>
        <Button
          danger
          ghost
          onClick={() =>
            setSidebarData({ viewState: NotesSidebarViewState.list })
          }
        >
          {t("notes.custom-content.cancel")}
        </Button>
      </NotesSidebarTitlePortal>
      <CenterContent>{customContent}</CenterContent>
    </>
  );
}
