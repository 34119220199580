import { useTranslations } from "@properate/translations";
import { Button, Divider, Input } from "antd";
import { useMemo, useState } from "react";
import dayjs from "@properate/dayjs";
import useSWR from "swr";
import { getUserNameWithEmail } from "@properate/common";
import { listUsers } from "@/eepApi";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { Comment } from "./utils";

type Props = {
  sendComment: (
    comment: string,
    callback?: VoidFunction,
    shouldUpdateIncidentLastComment?: boolean,
  ) => Promise<void>;
  isLoadingComments: boolean;
  isLoadingEvent: boolean;
  comments: Comment[];
};

export const IncidentActivity = ({
  sendComment,
  isLoadingComments,
  isLoadingEvent,
  comments,
}: Props) => {
  const t = useTranslations();

  const [comment, setComment] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { data: users } = useSWR("listUsers", () => listUsers());

  const userNames = useMemo(
    () =>
      users?.reduce(
        (acc, user) => {
          acc[user.email] = getUserNameWithEmail(user);
          return acc;
        },
        {} as Record<string, string>,
      ) || {},
    [users],
  );

  const onSendComment = async () => {
    setIsSending(true);
    await sendComment(
      comment,
      () => {
        setComment("");
      },
      true,
    );
    setIsSending(false);
  };

  if (isLoadingComments) {
    return (
      <SpinnerWithDelay isLoading>
        <div />
      </SpinnerWithDelay>
    );
  }

  return (
    <div className="lg:h-auto overflow-hidden flex flex-col min-h-[200px]">
      <h2 className="mb-2">{t("incident.activity")}</h2>
      <div className="flex flex-col">
        <Input.TextArea
          rows={4}
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          placeholder={"Comment"}
          className="max-h-[200px]"
        />
        <Button
          type="primary"
          className="ml-auto mt-2"
          onClick={onSendComment}
          loading={isSending || isLoadingEvent}
          disabled={!comment}
        >
          {t("incident.add")}
        </Button>
      </div>
      <div
        className={`overflow-y-auto ${
          comments.length ? "h-[40vh]" : ""
        } lg:h-auto`}
      >
        {comments.map((comment) => (
          <div key={comment.id} className="flex flex-col">
            <div className="flex gap-1 text-xs items-center mb-1 opacity-60">
              <div className="font-semibold">
                {userNames[comment.createdBy] || comment.createdBy}
              </div>
              <div>{dayjs(comment.createdTime).format("DD.MM.YYYY HH:mm")}</div>
            </div>
            <div>{comment.content}</div>
            {<Divider className="my-4" />}
          </div>
        ))}
      </div>
    </div>
  );
};
