import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { useNavigate } from "react-router-dom";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  AlarmDetailsPageId,
  makeAlarmDetailsPageUrl,
  NEW_ALARM_URL_FRAGMENT,
} from "@/pages/alarms/details/hooks/createNewAlarmHooks";

export default function CreateNewAlarmButton() {
  const user = useUser();
  const translate = useTranslations();
  const navigate = useNavigate();
  const { id } = useCurrentBuilding();

  function navigateToCreateNew() {
    navigate(
      makeAlarmDetailsPageUrl(
        `/asset/${id}/newAlarms/${NEW_ALARM_URL_FRAGMENT}`,
        AlarmDetailsPageId.Settings,
      ),
    );
  }

  return (
    <Button
      disabled={user.isViewer}
      data-testid={"new-alarm"}
      onClick={navigateToCreateNew}
      icon={<PlusOutlined />}
      type="primary"
    >
      {translate("alarm-details.common.navigation.create-new")}
    </Button>
  );
}
