import { Select, SelectProps } from "antd";
import { captureMessage } from "@sentry/react";
import { Asset } from "@cognite/sdk";
import { useLocale, useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useFloorPlanAssets } from "./hooks/useFloorPlanAssets";
import { FloorPlan } from "./types";
import { getFloorNumber, getFloorType, sortFloors } from "./lib/floors";

export function useFloorPlanFloorOptions() {
  const t = useTranslations();
  const locale = useLocale();
  const assets = useFloorPlanAssets();
  const buildingId = useCurrentBuildingId();

  const sortedFloors = assets.data?.floors
    ? [...assets.data.floors].sort((a, b) =>
        sortFloors(a.externalId, b.externalId),
      )
    : [];

  function getFloorAssetDisplayName(floor: Asset): string {
    if (!floor.externalId) {
      return floor.description || floor.name;
    }

    const type = getFloorType(floor.externalId);
    const floorNumber = getFloorNumber(floor.externalId);

    if (type === null || floorNumber === null) {
      return floor.description || floor.name || floor.externalId || "-";
    }

    if (floorNumber === 0) {
      captureMessage(
        "Found a floor with the floor number equals to 0. This is likely a bad entry from the backend.",
        {
          extra: {
            asset: floor,
          },
        },
      );

      return "-";
    }

    // In English we want to show the first floor as `Ground floor`, and the second as `1st floor`.
    const delta = locale === "en" ? -1 : 0;
    const isMezzanine = floor.externalId.endsWith("M") ? 1 : 0;

    // Remove the decimal part for mezzanine floors, ex. 1.5 -> 1
    const n = Math.floor(floorNumber);

    return type === "floor"
      ? t(`floor-plan-v2.floors.floor`, { n: n + delta, isMezzanine })
      : t(`floor-plan-v2.floors.sub_floor`, { n, isMezzanine });
  }

  const options = sortedFloors.reduce(
    (floors, floor) => {
      if (floor.description && floor.externalId) {
        const description = getFloorAssetDisplayName(floor);

        floors[description] = floors[description]
          ? `${floors[description]},${floor.externalId}`
          : floor.externalId;
      }

      return floors;
    },
    {} as Record<string, string>,
  );

  return {
    options,
    getFloorName(record: FloorPlan) {
      if (!record.floor || !assets.data) {
        return "";
      }

      for (const floor of assets.data.floors) {
        if (floor.externalId && record.floor.includes(floor.externalId)) {
          return getFloorAssetDisplayName(floor);
        }
      }

      captureMessage("Sub-building is not available for building.", {
        extra: {
          floor: record.floor,
          buildingId,
          availableFloors: options,
        },
      });

      return "";
    },
  };
}

export function FloorPlanFloorSelect(props: SelectProps<string>) {
  const floors = useFloorPlanFloorOptions();

  return (
    <Select {...props}>
      {props.children}
      {Object.entries(floors.options).map(([floor, externalIds]) => (
        <Select.Option key={floor} value={externalIds}>
          {floor}
        </Select.Option>
      ))}
    </Select>
  );
}
