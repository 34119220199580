import { AlarmRuleTypeName, PartialAlarmRule } from "@properate/common";
import {
  CreateAlarmRuleCommonProps,
  FormContextProviderEntries,
  SubmitItem,
} from "../FormContext/types";
import { stringValidator } from "../FormContext/validators";
import { requiredSelect, requiredString } from "../AlarmRuleTypes/common/utils";
import {
  getAlarmRuleFieldsForAlarmRuleType,
  getFormFieldsForAlarmRuleType,
} from "../AlarmRuleTypes";
import { AlarmSettingsFormFields } from "./types";

export function getAlarmSettingsFormFields(
  alarmRule: PartialAlarmRule,
): FormContextProviderEntries {
  const alarmRuleType = alarmRule.type ?? undefined;

  return {
    [AlarmSettingsFormFields.Name]: {
      defaultValue: alarmRule?.name ?? "",
      getValidator: (t) =>
        stringValidator(
          t(requiredString, {
            fieldName: t(
              "alarm-details.settings-page.name-label",
            ).toLowerCase(),
          }),
        ),
    },
    [AlarmSettingsFormFields.Instruction]: {
      defaultValue: alarmRule?.instruction ?? "",
    },
    [AlarmSettingsFormFields.Type]: {
      defaultValue: alarmRuleType,
      getValidator: (t) =>
        stringValidator(
          t(requiredSelect, {
            fieldName: t(
              "alarm-details.settings-page.type-label",
            ).toLowerCase(),
          }),
        ),
    },
    ...getFormFieldsForAlarmRuleType(alarmRule, alarmRuleType),
  };
}

export function getAlarmSettingsAlarmRuleFields({
  entries,
}: CreateAlarmRuleCommonProps): PartialAlarmRule {
  const alarmRuleType = (
    entries[AlarmSettingsFormFields.Type] as SubmitItem<AlarmRuleTypeName>
  ).value;

  return {
    name: (entries[AlarmSettingsFormFields.Name] as SubmitItem<string>).value,
    instruction: (
      entries[AlarmSettingsFormFields.Instruction] as SubmitItem<string>
    ).value,
    type: alarmRuleType,
    ...getAlarmRuleFieldsForAlarmRuleType({
      entries,
      alarmRuleType,
    }),
  };
}
