import { Empty } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import { WithSnapshotId } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { Analysis } from "../../types";
import { AnalysisListCard } from "./AnalysisListCard";
import { ErrorBoundaryCard } from "./ErrorBoundaryCard";
import { AnalysesContainer } from "./elements";

interface Props {
  analyses: Array<WithSnapshotId<Analysis>>;
  onConfirmRemoval: (snapshotId: string) => unknown;
}

export function AnalysisList({ analyses, onConfirmRemoval }: Props) {
  const t = useTranslations();

  return analyses.length > 0 ? (
    <AnalysesContainer>
      {analyses.map((analysis) => (
        <ErrorBoundary
          key={analysis.snapshotId}
          fallback={<ErrorBoundaryCard analysis={analysis} />}
        >
          <AnalysisListCard
            analysis={analysis}
            onConfirmRemoval={onConfirmRemoval}
          />
        </ErrorBoundary>
      ))}
    </AnalysesContainer>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={t("analysis.empty")}
    />
  );
}
