import {
  createContext,
  useReducer,
  useContext,
  Dispatch,
  ReactNode,
} from "react";

export enum FilterBuildings {
  "filter_buildings",
}
export type ActionType = {
  type: FilterBuildings.filter_buildings;
  payload: string;
};
interface BuildingsSearchType {
  search: string;
  setSearch: Dispatch<ActionType>;
}
interface Props {
  children: ReactNode;
}
const initialState = {
  search: "",
};
const reducer = (state: typeof initialState, action: ActionType) => {
  if (action.type === FilterBuildings.filter_buildings) {
    return { ...state, search: action.payload };
  }
  return state;
};

const BuildingsSearchContext = createContext<BuildingsSearchType | undefined>(
  undefined,
);
export function BuildingsSearchProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <BuildingsSearchContext.Provider
      value={{ search: state.search, setSearch: dispatch }}
    >
      {children}
    </BuildingsSearchContext.Provider>
  );
}
export function useBuildingsSearch() {
  const buildingsSearchContext = useContext(BuildingsSearchContext);
  if (!buildingsSearchContext) {
    throw new Error(
      '"useBuildingsSearch" was called from a component that does not have a BuildingsSearchProvider parent',
    );
  }
  return buildingsSearchContext;
}
