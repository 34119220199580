import { WithNull, WithRequired } from "@properate/common";
import { Analysis, ScatterplotAnalysis } from "../types";

export function isScatterplotAnalysis(
  analysis: Analysis,
): analysis is ScatterplotAnalysis {
  return "settingsTimeseriesX" in analysis;
}

export function isScatterplotAnalysisWithoutSettingsTimeseriesX(
  analysis: Analysis,
): analysis is WithNull<ScatterplotAnalysis, "settingsTimeseriesX"> {
  return (
    isScatterplotAnalysis(analysis) && analysis.settingsTimeseriesX === null
  );
}

export function isScatterplotAnalysisWithSettingsTimeseriesX(
  analysis: Analysis,
): analysis is WithRequired<ScatterplotAnalysis, "settingsTimeseriesX"> {
  return (
    isScatterplotAnalysis(analysis) && analysis.settingsTimeseriesX !== null
  );
}

export function isScatterplotAnalysisWithEmptySettingsTimeseriesList(
  analysis: Analysis,
): analysis is ScatterplotAnalysis {
  return (
    isScatterplotAnalysis(analysis) &&
    analysis.settingsTimeseriesList.length === 0
  );
}

export const LEFT_AXIS_ESTIMATED_WIDTH = 65;
