import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { Await, useLoaderData } from "react-router-dom";
import { useTranslations, MessageKey } from "@properate/translations";
import { Suspense } from "react";
import {
  Column,
  LastWeekAndLast365DaysContainer,
  StartEndRange,
  GraphTitleRange,
  EnergyFlowPage,
} from "@/features/energy";
import { DATA_PERIOD } from "@/utils/helpers";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { GraphTitleStyled } from "../GraphTitle/elements";
import { SankeyDiagramV2 } from "./SankeyDiagramV2";

export const EnergyFlowV2 = () => {
  const t = useTranslations();
  const page = useLoaderData() as EnergyFlowPage;

  return (
    <Suspense
      fallback={
        <div className="h-full flex justify-center items-center">
          <SpinnerWithDelay isLoading>
            <div />
          </SpinnerWithDelay>
        </div>
      }
    >
      <Await
        resolve={Promise.all([
          page.last7Days,
          page.last365Days,
          page.selectedPeriod,
        ])}
      >
        {([last7Days, last365Days, selectedPeriod]) => (
          <>
            <LastWeekAndLast365DaysContainer>
              <Column>
                <GraphTitleStyled>
                  {t(
                    `energy.energy-flow-periods.${DATA_PERIOD.LAST_WEEK.name}` as MessageKey,
                  )}
                </GraphTitleStyled>
                <AutoSizer>
                  {({ width, height }: Size) => (
                    <SankeyDiagramV2
                      pLinks={last7Days.links}
                      pNodes={last7Days.nodes}
                      width={width}
                      height={height - 35}
                    />
                  )}
                </AutoSizer>
              </Column>
              <Column>
                <GraphTitleStyled>
                  {t(
                    `energy.energy-flow-periods.${DATA_PERIOD.LAST_YEAR_WEEKLY.name}` as MessageKey,
                  )}
                </GraphTitleStyled>
                <AutoSizer>
                  {({ width, height }: Size) => (
                    <SankeyDiagramV2
                      pLinks={last365Days.links}
                      pNodes={last365Days.nodes}
                      width={width}
                      height={height - 35}
                    />
                  )}
                </AutoSizer>
              </Column>
            </LastWeekAndLast365DaysContainer>
            <StartEndRange>
              <GraphTitleRange startDate={page.start} endDate={page.end} />
              <AutoSizer>
                {({ width, height }: Size) => (
                  <SankeyDiagramV2
                    pLinks={selectedPeriod.links}
                    pNodes={selectedPeriod.nodes}
                    width={width}
                    height={height - 65}
                  />
                )}
              </AutoSizer>
            </StartEndRange>
          </>
        )}
      </Await>
    </Suspense>
  );
};
