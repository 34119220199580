import { browserFirestore } from "@properate/firebase";
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  deleteDoc,
  setDoc,
  SetOptions,
} from "firebase/firestore";
import { Gauge } from "@/features/gauges";

const collectionGauge = collection(
  browserFirestore,
  "gauge",
) as CollectionReference<Gauge>;

export async function createGauge(gauge: Gauge) {
  return addDoc(collectionGauge, gauge);
}

export async function updateGauge(
  snapshotId: string,
  gauge: Partial<Gauge>,
  setOptions: SetOptions = {},
) {
  return setDoc(doc(collectionGauge, snapshotId), gauge, setOptions);
}

export async function deleteGauge(snapshotId: string) {
  return deleteDoc(doc(collectionGauge, snapshotId));
}
