import * as React from "react";
import { useEffect } from "react";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "../../context/CogniteClientContext";

export const WaitForUrn = () => {
  const t = useTranslations();
  const { client } = useCogniteClient();
  const { fileId }: any = useParams();
  useEffect(() => {
    const checkForUrn = async () => {
      const file = await client.files.retrieve([{ id: parseInt(fileId) }]);
      if (file[0].metadata?.urn) {
        window.location.href = `/view/upload/${encodeURIComponent(
          btoa(file[0].metadata.urn),
        )}`;
      } else {
        setTimeout(checkForUrn, 1000);
      }
    };

    checkForUrn();
  }, [fileId, client]);

  return (
    <Row justify="space-around" align="middle">
      <Col flex="none">{t("files.preparing-file")}</Col>
    </Row>
  );
};
