"use client";

import {
  UpdateData,
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import {
  getCurrentUser,
  getArrayDiffOp,
  browserFirestore,
} from "@properate/firebase";
import useSWRMutation from "swr/mutation";
import { notification } from "antd";
import { usePossiblyUser } from "@properate/auth";
import { genId } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useTaskType } from "../contexts/task-type-context";
import { Task, TaskComment, TaskUpdate } from "../schemas";

export type UpdateTaskArg = {
  where: {
    taskId: string;
  };
  data: TaskUpdate;
};

export function useUpdateTask() {
  const t = useTranslations();
  const user = usePossiblyUser();
  const taskType = useTaskType();

  return useSWRMutation(
    "updateTask",
    async (_, extra: { arg: UpdateTaskArg }) /* NOSONAR */ => {
      if (!user.email) {
        throw new Error("Not authenticated");
      }

      const firebaseUser = await getCurrentUser();
      const ref = doc(browserFirestore, taskType, extra.arg.where.taskId);
      const { comments, components, ...patch } = extra.arg.data;

      // @ts-expect-error todo: figure out the correct type for UpdateData<Task["checklists"]>
      const updateData: UpdateData<Task> = {
        ...patch,
        lastModifiedBy: user.email,
        updatedAt: Date.now(),
      };

      const currentTaskDoc = await getDoc(ref);
      const currentTask = currentTaskDoc.data() as Task;

      if (components) {
        updateData.components = getArrayDiffOp(
          components,
          currentTask.components ?? [],
        );
      }

      if (comments) {
        if ("push" in comments) {
          const comment: TaskComment = {
            id: genId(),
            uid: firebaseUser.uid,
            taskItemId: comments.push.data.taskItemId,
            author: user.email,
            content: comments.push.data.content,
            createdAt: Date.now(),
          };

          updateData.comments = arrayUnion(comment);
        } else if ("remove" in comments) {
          const commentToRemove = currentTask.comments.find(
            (comment) => comment.id === comments.remove.where.id,
          );

          updateData.comments = arrayRemove(commentToRemove);
        }
      }

      await updateDoc(ref, updateData);
    },
    {
      onError(err) {
        console.error(err);

        notification.error({
          message: t("task.notifications.update.error", {
            taskType: "tasks",
          }),
        });
      },
    },
  );
}
