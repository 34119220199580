import Dataloader from "dataloader";
import { getSetPointStatus } from "@/eepApi";

export type SetPointStatusJs = {
  fault?: string;
  outOfService: boolean;
  priority: number;
  value: number;
  protocol: string;
  lastUpdated: Date;
};

export type ProperateApiClient = {
  getSetPointStatus: (
    externalId: string,
  ) => Promise<SetPointStatusJs | undefined>;
};

export const apiBatch = (): ProperateApiClient => {
  const fetchValue = async (externalIds: readonly string[]) => {
    valueLoader.clearAll();
    const map = await getSetPointStatus({ external_ids: externalIds });
    return externalIds.map((externalId) =>
      externalId in map && Object.keys(map[externalId]).length > 0
        ? ({
            fault:
              map[externalId].reliability !== "no-fault-detected"
                ? map[externalId].reliability
                : undefined,
            outOfService: map[externalId]["out-of-service"],
            priority:
              map[externalId]["priority-array"] &&
              map[externalId]["priority-array"]!.length > 0
                ? map[externalId]["priority-array"]!.map(({ index }) =>
                    Number(index),
                  ).sort((a, b) => a - b)[0]
                : 16,
            value: map[externalId]["present-value"],
            protocol: map[externalId].protocol,
            lastUpdated: new Date(map[externalId].last_updated),
          } as SetPointStatusJs)
        : undefined,
    );
  };
  const valueLoader = new Dataloader(fetchValue, {
    maxBatchSize: 1000,
    batchScheduleFn: (callback) => setTimeout(callback, 300),
  });

  return {
    getSetPointStatus: async (externalId: string) => {
      return valueLoader.load(externalId);
    },
  };
};
