import { get } from "lodash";

/**
 * The item for a given prop should always exist.
 * If they don't, it points to a programming error rather than a user error
 */
export function getItemByProp<T extends Record<string, any>>(
  items: T[],
  propValue: unknown,
  path = "id",
): T {
  const item = items.find((item) => get(item, path) === propValue);
  if (!item) {
    throw new Error(
      `Item with prop "${path}" and value "${propValue}" could not be found!`,
    );
  }
  return item;
}

/**
 * Split an array into two arrays based on a condition. If an element matches @condition
 * it will be part of the first array, otherwise it will be part of the second array
 */
export function splitArray<T>(
  array: T[],
  condition: (element: T) => boolean,
): [T[], T[]] {
  return array.reduce<[T[], T[]]>(
    ([firstArrayAcc, secondArrayAcc], element) => {
      if (condition(element)) {
        return [firstArrayAcc.concat(element), secondArrayAcc];
      }
      return [firstArrayAcc, secondArrayAcc.concat(element)];
    },
    [[], []],
  );
}
