import { useTheme } from "styled-components";
import { DoubleDatapoint } from "@cognite/sdk";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { BarChart } from "@/components/BarChart/BarChart";
import { useCombinedDatapoints } from "../hooks/useCombinedTimeseries";
import { SolarCellProductionPeriod } from "../types";

function getTooltipDateFormat(period: SolarCellProductionPeriod) {
  switch (period) {
    case "lastWeek":
      return "D. MMM HH:mm";
    case "last30Days":
      return "D. MMM";
    case "lastYear":
      return "[Uke] w YYYY";
  }
}
interface Props {
  timeseriesIds: number[];
  period: SolarCellProductionPeriod;
  width: number;
  height: number;
}

export function SolarCellProductionGraph({
  timeseriesIds,
  period,
  width,
  height,
}: Props) {
  const theme = useTheme();
  const { datapoints, isLoading } = useCombinedDatapoints(
    timeseriesIds,
    period,
  );

  function filterOutDatapointsWithNullValues(datapoints: DoubleDatapoint[]) {
    const hasOnlyNullValues = datapoints.every((datapoint) => !datapoint.value);
    if (hasOnlyNullValues) {
      return [];
    }
    return datapoints;
  }

  return (
    <SpinnerWithDelay isLoading={isLoading}>
      {width > 0 && height > 0 && (
        <BarChart
          datapoints={filterOutDatapointsWithNullValues(datapoints)}
          unit="kWh"
          color={theme.primary}
          width={width}
          height={height}
          tooltipDateFormat={getTooltipDateFormat(period)}
        />
      )}
    </SpinnerWithDelay>
  );
}
