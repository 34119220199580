import styled from "styled-components";

export const PopoverContent = styled.div.attrs({
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    event.stopPropagation(),
})<{ width: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width / 2}px;
  margin: -8px -12px;
  padding: 8px 12px;
  overflow: auto;
`;
