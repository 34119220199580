import { Tag } from "antd";
import { ReactNode } from "react";

type Props = {
  disabled?: boolean;
  checked: boolean;
  onClick: () => void;
  children: ReactNode;
};

const DATA_TEST_ID: Record<string, string> = {
  Alle: "show-all-alarms-button",
  "Bare aktive avvik": "show-active-alarms-button",
  Mine: "show-my-alarms-button",
};

export function NewAlarmsTableFiltersToggle(props: Props) {
  if (props.disabled) {
    return <Tag>{props.children}</Tag>;
  }

  return (
    <Tag.CheckableTag
      data-testid={DATA_TEST_ID[props!.children as string]}
      checked={props.checked}
      onClick={props.onClick}
    >
      {props.children}
    </Tag.CheckableTag>
  );
}
