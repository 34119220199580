import { Button, Input } from "antd";
import styled from "styled-components";

export const CalculatorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
`;

export const ExpressionInput = styled(Input)`
  grid-column: 1 / -1;
`;

export const OperationButton = styled(Button)`
  background-color: ${({ theme }) => theme.neutral7};
`;

export const DigitButton = styled(Button)`
  background-color: ${({ theme }) => theme.neutral8};
`;
