import * as React from "react";
import { AlarmRuleTypeName } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { DescriptionList, DescriptionListEntryType } from "@properate/ui";
import { useParams } from "react-router-dom";
import { dateFormat } from "@/utils/date";
import { useFormValue } from "../FormContext/hooks";
import { AlarmSettingsFormFields } from "../AlarmSettingsPage/types";
import { alarmRuleTypes } from "../AlarmRuleTypes";
import { useAlarmRule } from "../hooks/useAlarmRules";

function GeneralAlarmMetaFields() {
  const t = useTranslations();
  const [name] = useFormValue<string>(AlarmSettingsFormFields.Name);
  const [instruction] = useFormValue<string>(
    AlarmSettingsFormFields.Instruction,
  );
  const [type] = useFormValue<AlarmRuleTypeName>(AlarmSettingsFormFields.Type);
  const { alarmId } = useParams();
  const { alarmRule } = useAlarmRule(alarmId);

  const generalAlarmMetaFields =
    alarmRuleTypes[type]?.summaryContents?.generalAlarmMetaFields ?? null;

  function makeNameEmailStr(
    name: string | undefined,
    email: string | undefined,
    timestamp: number | undefined,
  ) {
    const fields: string[] = [];
    if (name) {
      fields.push(name);
    }
    if (email) {
      fields.push(`<${email}>`);
    }
    if (timestamp) {
      fields.push(dateFormat(timestamp, true));
    }
    return fields.join(" ");
  }

  const entries: DescriptionListEntryType[] = [
    {
      term: t("alarm-details.deviations-page.summary.name"),
      description: name,
    },
    {
      term: t("alarm-details.deviations-page.summary.instruction"),
      description: instruction,
    },
  ];
  const postEntries: DescriptionListEntryType[] = alarmRule
    ? [
        {
          term: t("alarm-details.deviations-page.summary.created-by"),
          description: makeNameEmailStr(
            alarmRule.created_by.name,
            alarmRule.created_by.email,
            alarmRule.created_at,
          ),
        },
        {
          term: t("alarm-details.deviations-page.summary.updated-by"),
          description: makeNameEmailStr(
            alarmRule.updated_by.name,
            alarmRule.updated_by.email,
            alarmRule.updated_at,
          ),
        },
      ]
    : [];

  return (
    <DescriptionList
      termColon
      ignoreEmptyDescriptions
      entries={entries}
      postChildrenEntries={postEntries}
    >
      {generalAlarmMetaFields}
    </DescriptionList>
  );
}

export function TypeSpecificAlarmMetaFields() {
  const [type] = useFormValue<AlarmRuleTypeName>(AlarmSettingsFormFields.Type);
  return alarmRuleTypes[type]?.summaryContents?.typeSpecificSummary ?? null;
}

export function AlarmSummary() {
  return (
    <div className="w-full h-full flex flex-col gap-2">
      <GeneralAlarmMetaFields />
      <TypeSpecificAlarmMetaFields />
    </div>
  );
}
