"use client";

import { useTranslations } from "@properate/translations";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Spin,
} from "antd";
import { useState } from "react";
import dayjs, { Dayjs } from "@properate/dayjs";
import useSWR from "swr";
import { ClipboardButton } from "@properate/ui";
import { Task } from "../../schemas";
import { useDeleteTask } from "../../hooks/use-delete-task";
import { useUpdateTask } from "../../hooks/use-update-task";
import { useTaskType } from "../../contexts/task-type-context";

type Props = {
  taskId: string;
  task: Task;
  userEmail?: string;
  isEditable: boolean;
  onDeleted: () => void;
};

export function Actions(props: Props) {
  const t = useTranslations();
  const deleteTask = useDeleteTask();
  const updateTask = useUpdateTask();
  const actions: JSX.Element[] = [];
  const taskType = useTaskType();

  async function handleDelete() {
    await deleteTask.trigger({
      where: {
        taskId: props.taskId,
      },
    });

    props.onDeleted();
  }

  if (taskType === "tasks" && typeof props.userEmail === "string") {
    if (props.task.usersToRemind.includes(props.userEmail)) {
      actions.push(
        <Button
          key="remove-reminder"
          danger
          onClick={() =>
            updateTask.trigger({
              where: {
                taskId: props.taskId,
              },
              data: {
                usersToRemind: props.task.usersToRemind.filter(
                  (user) => user !== props.userEmail,
                ),
              },
            })
          }
        >
          {t("task.ui.removeReminder")}
        </Button>,
      );
    } else {
      actions.push(
        <Button
          key="set-reminder"
          onClick={() =>
            updateTask.trigger({
              where: {
                taskId: props.taskId,
              },
              data: {
                usersToRemind: [
                  ...props.task.usersToRemind,
                  props.userEmail as string,
                ],
              },
            })
          }
        >
          {t("task.ui.setReminder")}
        </Button>,
      );
    }

    if (props.task.followers.includes(props.userEmail)) {
      actions.push(
        <Button
          key="remove-follow"
          danger
          onClick={() =>
            updateTask.trigger({
              where: {
                taskId: props.taskId,
              },
              data: {
                followers: props.task.followers.filter(
                  (user) => user !== props.userEmail,
                ),
              },
            })
          }
        >
          {t("task.ui.unfollow")}
        </Button>,
      );
    } else {
      actions.push(
        <Button
          key="set-follow"
          onClick={() =>
            updateTask.trigger({
              where: {
                taskId: props.taskId,
              },
              data: {
                followers: [...props.task.followers, props.userEmail as string],
              },
            })
          }
        >
          {t("task.ui.follow")}
        </Button>,
      );
    }
  }

  if (taskType === "tasks") {
    actions.push(<ShareButton key="share" {...props} />);

    if (props.userEmail) {
      actions.push(<RecurrenceButton key="recurrence" {...props} />);
    }
  }

  if (props.isEditable) {
    actions.push(
      <Popconfirm
        key="delete"
        title={t("task.ui.delete", { taskType })}
        description={t("task.ui.deleteConfirmation", { taskType })}
        onConfirm={handleDelete}
      >
        <Button danger loading={deleteTask.isMutating}>
          {t("ui.delete")}
        </Button>
      </Popconfirm>,
    );
  }

  if (actions.length > 0) {
    return (
      <div className="col-span-2">
        <div className="mb-2 text-[13px] tracking-wide">{t("ui.actions")}</div>
        <div className="flex flex-wrap gap-2">{actions}</div>
      </div>
    );
  }

  return null;
}

function ShareButton(props: Props) {
  const t = useTranslations();
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>{t("task.ui.share")}</Button>
      {isOpen && <ShareModal {...props} onClose={() => setOpen(false)} />}
    </>
  );
}

function ShareModal(props: Props & { onClose: () => void }) {
  const t = useTranslations();

  const [expiration, setExpiration] = useState(() =>
    Math.max(
      24 * 7,
      dayjs(props.task.dueDate).add(1, "week").diff(dayjs(), "hours"),
    ),
  );

  const apiUrl =
    process.env.REACT_APP_EXTERNAL_UI_URL ??
    process.env.NEXT_APP_EXTERNAL_UI_URL ??
    "https://link.properate.com";

  const {
    data: url = "",
    isLoading,
    error,
  } = useSWR(["external-ui", "task", props.task.snapshotId], async () => {
    const res = await fetch(`${apiUrl}/generate-access-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: `/${props.task.buildingId}/task/${props.task.snapshotId}`,
        expiration,
      }),
    });

    const data = res.json() as Promise<{ url: string }>;
    const { url } = await data;

    return url.replace("https://link.properate.com", apiUrl);
  });

  let input = <Spin />;

  if (error) {
    input = (
      <Alert type="error" message={t("task.ui.failedToGenerateShareLink")} />
    );
  } else if (!isLoading) {
    input = (
      <>
        <Input className="flex-1" id="link" readOnly value={url} />
        <ClipboardButton text={url} />
      </>
    );
  }

  return (
    <Modal title={t("task.ui.share")} open onCancel={props.onClose}>
      <div className="space-y-2">
        <div className="flex items-center gap-2">
          <label htmlFor="link">{t("task.ui.linkToTask")}</label>
          {input}
        </div>
        <div>
          {t("task.ui.linkIsValidTil")}{" "}
          {dayjs().add(expiration, "h").format("DD. MMM")}
          <Popover
            trigger="click"
            title="Endre linken gyldighet"
            content={
              <Select onChange={setExpiration} className="w-full">
                <Select.Option value={24 * 7}>
                  {"1 "}
                  {t("ui.week", { count: 1 })}
                </Select.Option>
                <Select.Option value={24 * 14}>
                  {"2 "}
                  {t("ui.week", { count: 2 })}
                </Select.Option>
                <Select.Option value={24 * 30}>
                  {"2 "}
                  {t("ui.month", { count: 2 })}
                </Select.Option>
                <Select.Option value={24 * 90}>
                  {"3 "}
                  {t("ui.month", { count: 3 })}
                </Select.Option>
                <Select.Option value={24 * 180}>
                  {"6 "}
                  {t("ui.month", { count: 6 })}
                </Select.Option>
              </Select>
            }
          >
            <Button type="link">{t("ui.edit")}</Button>
          </Popover>
        </div>
      </div>
    </Modal>
  );
}

function RecurrenceButton(props: Props) {
  const t = useTranslations();
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {props.task.recurrence
          ? t("task.ui.changeRecurrence")
          : t("task.ui.makeRecurring")}
      </Button>
      {isOpen && <RecurrenceModal {...props} onClose={() => setOpen(false)} />}
    </>
  );
}

function RecurrenceModal(props: Props & { onClose: () => void }) {
  const t = useTranslations();
  const updateTask = useUpdateTask();

  type Fields = {
    interval: number;
    type: "day" | "week" | "month" | "year";
    weekDays: number[];
    monthDays: number[];
    startsAt: Dayjs;
    endsAt?: Dayjs;
  };

  const [form] = Form.useForm<Fields>();
  const interval = Form.useWatch("interval", form) ?? 1;
  const type = Form.useWatch("type", form) ?? "week";

  async function handleSubmit() {
    try {
      const fields = await form.validateFields();

      updateTask.trigger(
        {
          where: {
            taskId: props.taskId,
          },
          data: {
            recurrence: {
              ...fields,
              startsAt: Number(fields.startsAt),
              endsAt: fields.endsAt ? Number(fields.endsAt) : undefined,
            },
          },
        },
        {
          onSuccess() {
            props.onClose();
          },
        },
      );
    } catch {
      // Form validation failed; AntD will display errors on the fields
    }
  }

  return (
    <Modal
      open
      title={t("task.ui.makeRecurring")}
      onCancel={props.onClose}
      onOk={handleSubmit}
      okButtonProps={{
        loading: updateTask.isMutating,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={
          props.task.recurrence
            ? ({
                ...props.task.recurrence,
                startsAt: dayjs(props.task.recurrence.startsAt),
                endsAt: props.task.recurrence.endsAt
                  ? dayjs(props.task.recurrence.endsAt)
                  : undefined,
              } as Fields)
            : {
                type: "week",
                interval: 1,
                weekDays: [],
                monthDays: [],
                startsAt: dayjs(),
              }
        }
      >
        <div className="flex">
          <Form.Item label={t("task.ui.repeatsEvery")} name="interval" required>
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            required
            name="type"
            className="flex-1 mt-[30px] capitalize"
          >
            <Select>
              <Select.Option value="day">
                {t("ui.day", { count: interval })}
              </Select.Option>
              <Select.Option value="week">
                {t("ui.week", { count: interval })}
              </Select.Option>
              <Select.Option value="month">
                {t("ui.month", { count: interval })}
              </Select.Option>
            </Select>
          </Form.Item>
        </div>
        {type === "week" && (
          <Form.Item label={t("task.ui.repeatsOn")} name="weekDays" required>
            <Select mode="multiple">
              <Select.Option value={1}>{t("ui.monday")}</Select.Option>
              <Select.Option value={2}>{t("ui.tuesday")}</Select.Option>
              <Select.Option value={3}>{t("ui.wednesday")}</Select.Option>
              <Select.Option value={4}>{t("ui.wednesday")}</Select.Option>
              <Select.Option value={5}>{t("ui.friday")}</Select.Option>
              <Select.Option value={6}>{t("ui.saturday")}</Select.Option>
              <Select.Option value={0}>{t("ui.sunday")}</Select.Option>
            </Select>
          </Form.Item>
        )}
        {(type === "month" || type === "year") && (
          <Form.Item label={t("task.ui.repeatsOn")} name="monthDays" required>
            <Select mode="multiple">
              <Select.Option key={0} value={0}>
                {t("task.ui.lastDayOfMonth")}
              </Select.Option>
              {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                <Select.Option key={day} value={day}>
                  {day}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <div className="grid grid-cols-2 gap-4">
          <Form.Item label={t("task.ui.startsAt")} name="startsAt">
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item label={t("task.ui.endsAt")} name="endsAt">
            <DatePicker className="w-full" />
          </Form.Item>
        </div>
        {props.task.startDate || props.task.dueDate ? (
          <Alert
            showIcon
            type="warning"
            message={t("task.ui.recurrenceStartEndDateWarn")}
          />
        ) : null}
      </Form>
    </Modal>
  );
}
