import {
  getComponentFromExternalId,
  getSystemCodeFromExternalId,
} from "@properate/common";
import { Collapse, Statistic } from "antd";
import { useTranslations } from "@properate/translations";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { KpiCard } from "@/components/CardKpi";
import { SubComponentItem } from "@/pages/Components/details/elements";
import {
  getComponentTimeseriesTitle,
  getComponentTitle,
  useSubComponentsAndTimeseriesWithLatestValues,
} from "./utils";

export const SubComponents = ({
  assetId,
  component,
}: {
  assetId: number;
  component?: string;
}) => {
  const t = useTranslations();

  const { timeSeries, subComponents, isLoading } =
    useSubComponentsAndTimeseriesWithLatestValues(assetId);

  if (!isLoading && !timeSeries?.length && !subComponents?.length) {
    return null;
  }

  return (
    <SpinnerWithDelay isLoading={isLoading}>
      <Collapse
        className="mb-4"
        items={[
          ...(timeSeries?.length
            ? [
                {
                  key: "sensors",
                  label: t("components.details.sensors"),
                  children: (
                    <div className="flex flex-wrap -mb-6">
                      {timeSeries.map((timeseriesItem) => (
                        <KpiCard key={timeseriesItem.id}>
                          <div className="ant-card-body">
                            <Statistic
                              title={getComponentTimeseriesTitle(
                                timeseriesItem,
                                component,
                              )}
                              value={timeseriesItem.value}
                            />
                          </div>
                        </KpiCard>
                      ))}
                    </div>
                  ),
                },
              ]
            : []),
          ...(subComponents?.length
            ? [
                {
                  key: "subComponents",
                  label: t("components.details.sub-components"),
                  children: (
                    <div className="flex flex-wrap -mb-6">
                      {subComponents.map((component) => {
                        return (
                          <SubComponentItem
                            className="hover:underline"
                            to={`../components/${component.id}`}
                            key={component.id}
                          >
                            <div>
                              {getComponentTitle(
                                {
                                  description: component.description || "",
                                  system: getSystemCodeFromExternalId(
                                    component.externalId!,
                                  ),
                                  component: getComponentFromExternalId(
                                    component.externalId!,
                                  ),
                                },
                                t,
                              )}
                            </div>
                          </SubComponentItem>
                        );
                      })}
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
    </SpinnerWithDelay>
  );
};
