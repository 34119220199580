import styled from "styled-components";
import { InfoCircleFilled } from "@ant-design/icons";
import { SpotPriceData } from "./SpotPriceData";

export const SpotPriceDataTopRightCorner = styled(SpotPriceData)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const InfoCircleIcon = styled(InfoCircleFilled)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.warning};
`;
