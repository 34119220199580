import { useUser } from "@properate/auth";
import { Node as ReactFlowNode, useReactFlow } from "reactflow";
import { Checkbox, Form, InputNumber } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useTranslations } from "@properate/translations";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import {
  NormalNode,
  NodeOutput,
  NodeInput,
  Header,
  Body,
  NodeInputLabel,
  NodeContent,
  NodeOutputLabel,
} from "./helpers/NodeComponents";
import { GranularitySelect, Granularity } from "./components/GranularitySelect";
import { AggregateSelect, Aggregate } from "./components/AggregateSelect";

const FormItem = Form.Item;

interface Props {
  operationId: string;
  aggregate: Aggregate;
  granularity_multiplier: number;
  granularity: Granularity;
}

export const getEmptyAggregateNode = (): ReactFlowNode<Props> => {
  return {
    id: getNodeId("aggregate"),
    type: "aggregate",
    data: {
      operationId: "aggregate",
      aggregate: "mean",
      granularity_multiplier: 1,
      granularity: "D",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function AggregateNode(params: { id: string; data: Props }) {
  const t = useTranslations();
  const user = useUser();
  const reactFlowInstance = useReactFlow();

  const handleAggregateChange = (aggregate: Aggregate) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "aggregate",
      aggregate,
    );
  };

  const handleGranularityMultiplierChange = (
    granularityMultiplier: number | null,
  ) => {
    if (granularityMultiplier !== null)
      updateReactFlowNodeData(
        reactFlowInstance,
        params.id,
        "granularity_multiplier",
        Math.sign(params.data.granularity_multiplier) * granularityMultiplier,
      );
  };

  const handleGranularityChange = (granularity: Granularity) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "granularity",
      granularity,
    );
  };

  const handleAggregationDirectionChange = (e: CheckboxChangeEvent) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "granularity_multiplier",
      (e.target.checked ? -1 : 1) *
        Math.abs(params.data.granularity_multiplier),
    );
  };
  return (
    <NormalNode>
      <Header>{t("calculation-flow.node-types.aggregate")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <NodeContent>
          <Form layout="vertical" disabled={user.isViewer}>
            <FormItem label={t("calculation-flow.node-types.aggregate")}>
              <AggregateSelect
                value={params.data.aggregate}
                onChange={handleAggregateChange}
              />
            </FormItem>
            <FormItem label={t("calculation-flow.node-types.granularity")}>
              <InputNumber
                value={Math.abs(params.data.granularity_multiplier)}
                onChange={handleGranularityMultiplierChange}
                controls={false}
                width="50%"
                min={1}
                precision={0}
              />
              <GranularitySelect
                value={params.data.granularity}
                onChange={handleGranularityChange}
                style={{ width: "50%" }}
              />
            </FormItem>
            <Checkbox
              checked={!(params.data.granularity_multiplier > 0)}
              onChange={handleAggregationDirectionChange}
            >
              {t("calculation-flow.node-types.aggregate-forward-in-time")}
            </Checkbox>
          </Form>
        </NodeContent>
        <NodeOutput>
          <NodeOutputLabel>
            {t("calculation-flow.node-types.aggregate-of-A")}
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </NormalNode>
  );
}

export default AggregateNode;
