import { browserFirestore } from "@properate/firebase";
import { collection, doc, setDoc } from "firebase/firestore";
import { DeepPartial } from "ts-essentials";
import { useMemo } from "react";
import {
  useGetFirestoreCollection,
  useGetFirestoreDocument,
} from "@properate/ui";
import type { BuildingSpec, WithSnapshotId } from "@properate/common";

const buildingsCollection = collection(browserFirestore, "buildings");

export function useBuildingSpec(id?: string | number) {
  const { data } = useGetFirestoreDocument<BuildingSpec>(
    useMemo(() => (id ? doc(buildingsCollection, String(id)) : null), [id]),
  );

  return data;
}

export function useBuildingsSpec() {
  const { data } = useGetFirestoreCollection<BuildingSpec>(buildingsCollection);

  return data;
}

export async function mutateBuildingSpec(
  id: string | number,
  data: DeepPartial<BuildingSpec>,
) {
  const ref = doc(browserFirestore, `buildings/${id}`);

  await setDoc(ref, data, { merge: true });
}

export function useBuildingsSpecMap() {
  const buildings = useBuildingsSpec();

  return useMemo(() => {
    if (!buildings) {
      return;
    }

    return buildings.reduce(
      (acc, building) => {
        acc[building.snapshotId] = building;
        return acc;
      },
      {} as Record<string, WithSnapshotId<BuildingSpec>>,
    );
  }, [buildings]);
}
