import { useMemo } from "react";
import { DatapointAggregates, DoubleDatapoints } from "@cognite/sdk";
import { useHandleApiError } from "@/utils/api";
import { useGetTimeseriesListWithData } from "@/hooks/useGetTimeseriesListWithData";
import { splitArray } from "@/utils/array";
import {
  DatapointCounts,
  DatapointAggregateCount,
  DatapointsMultiQueryWithAggregates,
} from "../types";
import { getMaxGranularity, getOptimalGranularity } from "../utils";

function shouldQueryRawDatapoints(
  datapoints: DatapointAggregateCount[],
  maxRawDatapoints = 600,
) {
  const amountOfDatapoints = datapoints.reduce(
    (amountOfDatapointsAcc, { count }) => amountOfDatapointsAcc + count,
    0,
  );
  return amountOfDatapoints <= maxRawDatapoints;
}

interface Options {
  maxRawDatapoints?: number;
  disabled?: boolean;
}

export function useGetTimeseriesListWithRawAggregateData<
  T extends DatapointAggregates,
>(
  {
    items,
    start,
    end,
    aggregates,
    granularity,
  }: DatapointsMultiQueryWithAggregates,
  options?: Options,
) {
  const handleAPIError = useHandleApiError();
  const maxGranularity = getMaxGranularity([start, end]);
  const { timeseriesListWithData: timeseriesListWithCounts = [], error } =
    useGetTimeseriesListWithData<DatapointCounts>(
      {
        items,
        start,
        end,
        aggregates: ["count" as const],
        granularity: maxGranularity,
      },
      {
        keepPreviousData: true,
        disabled: options?.disabled ?? false,
      },
    );
  const rawDataTimeseriesIds = useMemo(
    () =>
      timeseriesListWithCounts
        .filter(({ datapoints }) =>
          shouldQueryRawDatapoints(datapoints, options?.maxRawDatapoints),
        )
        .map(({ id }) => id),
    [timeseriesListWithCounts, options?.maxRawDatapoints],
  );
  const [rawItems, aggregateItems] = useMemo(
    () => splitArray(items, ({ id }) => rawDataTimeseriesIds.includes(id)),
    [items, rawDataTimeseriesIds],
  );
  const {
    timeseriesListWithData: timeseriesListWithRawData = [],
    isLoading: isLoadingRaw,
  } = useGetTimeseriesListWithData<DoubleDatapoints>({
    items: rawItems.map(({ id }) => ({ id })),
    start,
    end,
  });
  const {
    timeseriesListWithData: timeseriesListWithAggregateData = [],
    isLoading: isLoadingAggregates,
  } = useGetTimeseriesListWithData<T>({
    items: aggregateItems.map(({ id }) => ({ id })),
    start,
    end,
    aggregates,
    granularity:
      granularity ||
      getOptimalGranularity([start, end], aggregateItems.length, 25),
  });
  if (error) {
    handleAPIError(error);
  }
  return {
    raw: {
      timeseriesListWithData: timeseriesListWithRawData,
    },
    aggregate: {
      timeseriesListWithData: timeseriesListWithAggregateData,
    },
    isLoading: isLoadingRaw || isLoadingAggregates,
  };
}
