import { ReactNode, useContext } from "react";
import { CenteredSpinner } from "@properate/ui";
import { FormContext } from "@/pages/alarms/details/FormContext/FormContextProvider";
import { FormContextType } from "@/pages/alarms/details/FormContext/types";

type Props = {
  children: ReactNode;
};

export const IncidentAlarmComponentWrapper = ({ children }: Props) => {
  const alarmContext = useContext(FormContext) as FormContextType;
  return alarmContext ? children : <CenteredSpinner />;
};
