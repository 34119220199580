import useSWRInfinite from "swr/infinite";
import { App } from "antd";
import { DoubleDatapoints, LatestDataBeforeRequest } from "@cognite/sdk";
import { useEffect } from "react";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "@/context/CogniteClientContext";

const RETRIEVE_LATEST_LIMIT = 100;

interface Options {
  keepPreviousData?: boolean;
}

export function useGetTimeseriesListWithLatestData(
  items: LatestDataBeforeRequest[],
  options?: Options,
) {
  const t = useTranslations();
  const { message } = App.useApp();
  const { client } = useCogniteClient();
  const {
    data: timeseriesListWithLatestData = [],
    error,
    isLoading,
    size,
    setSize,
  } = useSWRInfinite(
    (index) => {
      if (items.length > 0) {
        const start = index * RETRIEVE_LATEST_LIMIT;
        const end = (index + 1) * RETRIEVE_LATEST_LIMIT;
        return {
          type: "datapoints.retrieveLatest",
          items: items.slice(start, end),
        };
      }
      return null;
    },
    ({ items }) =>
      client.datapoints.retrieveLatest(items) as Promise<DoubleDatapoints[]>,
    {
      keepPreviousData: options?.keepPreviousData ?? false,
    },
  );
  useEffect(() => {
    if (size * RETRIEVE_LATEST_LIMIT < items.length) {
      setSize(size + 1);
    }
  }, [size, setSize, items]);

  if (error) {
    const errorMessage = t("common.timeseries-modal.something-went-wrong");
    console.error(errorMessage, error);
    message.error(errorMessage);
  }
  return {
    timeseriesListWithLatestData: timeseriesListWithLatestData.flat(),
    isLoading,
    error,
  };
}
