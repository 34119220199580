import { useOrganizationSettings } from "@/services/organizationSettings";
import { defaultFeatureFlags } from "@/services/featureAccess/types";

export default function useOrganizationFeatureAccess() {
  const { data: organizationSettings } = useOrganizationSettings();

  return {
    ...defaultFeatureFlags,
    ...(organizationSettings?.featureAccess || {}),
  };
}
