import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, Spin } from "antd";
import { DisplayPage, useGetTask } from "@properate/task-manager";
import { useUser } from "@properate/auth";
import styled, { css } from "styled-components";
import { PageHeader } from "@ant-design/pro-layout";
import { RightOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import useUserFeatureAccess from "@/services/featureAccess/useUserFeatureAccess";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";

const Container = styled.div<{ $center?: boolean }>`
  padding: 1rem;
  max-width: 1280px;

  ${(props) =>
    props.$center &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    `}
`;

type Params = {
  id: string;
  taskId: string;
};

export function TaskManagerTaskPage() {
  const t = useTranslations();

  useBuildingPageTitle(t("task.ui.task"));
  const featureFlags = useUserFeatureAccess();
  const { id, taskId } = useParams() as Params;
  const task = useGetTask(taskId);
  const navigate = useNavigate();
  const user = useUser();

  if (task.isLoading) {
    return (
      <Container $center>
        <Spin />
      </Container>
    );
  }

  if (task.error) {
    return (
      <Container>
        <Alert type="error" message={t("task.ui.error-occurred")} />
      </Container>
    );
  }

  if (!task.data) {
    return (
      <Container>
        <Alert type="error" message={t("task.ui.cant-find-task")} />
      </Container>
    );
  }

  return (
    <div className="flex flex-col overflow-hidden h-screen">
      <PageHeader
        title={
          <>
            <Link data-testid="go-back" to={`/asset/${id}/workOrders`}>
              {t("task.ui.title")}
            </Link>{" "}
            <RightOutlined />
          </>
        }
        subTitle={t("task.ui.task")}
      />
      <div className="w-full overflow-auto">
        <Container>
          <DisplayPage
            taskId={taskId}
            task={task.data}
            userEmail={user.email}
            onDeleted={() => navigate(`/asset/${id}/workOrders`)}
            onClickFile={(fileId) =>
              navigate(`/asset/${id}/workOrders/${taskId}/files/${fileId}`)
            }
            onCreateComponent={
              featureFlags.canAccessComponentsPage
                ? () => navigate(`/asset/${id}/components/new`)
                : undefined
            }
          />
        </Container>
      </div>
    </div>
  );
}
