import { extent } from "d3-array";
import * as React from "react";
import { useCallback, useContext, useMemo } from "react";
import dayjs from "@properate/dayjs";
import { scaleBand, scaleLinear } from "@visx/scale";
import { defaultStyles, useTooltipInPortal } from "@visx/tooltip";
import { Group } from "@visx/group";
import { localPoint } from "@visx/event";
import { formatMeasurement } from "@properate/common";
import Color from "color";
import { ThemeContext } from "styled-components";
import { Granularity } from "@/utils/helpers";
import { TooltipData } from "@/features/energy";
import { DifferenceChartContent } from "./DifferenceChartContent";
import { getDiff } from "./utils";

type Props = {
  width: number;
  height: number;
  graphPoints: { [key: string]: number | string }[];
  start: Date;
  end: Date;
  granularity: Granularity;
  tooltipData?: TooltipData;
  setTooltipData: (data: TooltipData | undefined) => void;
  showTemperature: boolean;
};
const margin = {
  top: 10,
  right: 30,
  bottom: 10,
  left: 30,
};
const getTooltipContent = (node?: TooltipData) => {
  if (!node) {
    return <></>;
  }
  return (
    <>
      {formatMeasurement({
        value: getDiff(node.energy, node["next-energy"]),
        unit: "kWh",
      })}
      <br />
      {formatMeasurement({
        value: getDiff(node.temperature, node["next-temperature"]),
        unit: "°C",
      })}
    </>
  );
};
export const DifferenceChart = ({
  width,
  height,
  graphPoints,
  start,
  end,
  granularity,
  tooltipData,
  setTooltipData,
  showTemperature,
}: Props) => {
  const themeContext = useContext(ThemeContext);
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const yScale = useMemo(() => {
    const domain = extent(graphPoints, (point) =>
      getDiff(point.total as number, point["next-total"] as number),
    ) as [number, number];
    const max = Math.max(Math.abs(domain[0]), Math.abs(domain[1]));
    return scaleLinear<number>({
      range: [innerHeight, 0],
      domain: [-max, max],
    });
  }, [graphPoints, innerHeight]);

  const temperatureScale = useMemo(() => {
    const temperatureMax = Math.max(
      ...graphPoints.map((point) =>
        Math.max(
          (point.temperature as number) || Number.MIN_VALUE,
          (point["next-temperature"] as number) || Number.MIN_VALUE,
        ),
      ),
    );
    const temperatureMin = Math.min(
      ...graphPoints.map((point) =>
        Math.min(
          (point.temperature as number) || Number.MAX_VALUE,
          (point["next-temperature"] as number) || Number.MAX_VALUE,
        ),
      ),
    );

    const max = Math.max(Math.abs(temperatureMin), Math.abs(temperatureMax));
    return scaleLinear<number>({
      range: [innerHeight, 0],
      domain: [-max, max],
    });
  }, [graphPoints, innerHeight]);

  const timestamps = useMemo(
    () =>
      Array.from(
        {
          length: dayjs(end).diff(dayjs(start), granularity) + 1,
        },
        (_, i) => dayjs(start).add(i, granularity).valueOf(),
      ),
    [end, granularity, start],
  );

  const granularityBands = useMemo(() => {
    return scaleBand<number>()
      .domain(timestamps)
      .range([0, innerWidth])
      .padding(0.1);
  }, [innerWidth, timestamps]);

  const xMetricsScale = useMemo(() => {
    const xMargin = granularityBands(timestamps[0].valueOf()) || 0 / 2;

    return scaleLinear<number>()
      .domain(
        extent([
          ...timestamps,
          dayjs(timestamps[timestamps.length - 1])
            .add(1, granularity)
            .valueOf(),
        ]) as number[],
      )
      .range([xMargin, innerWidth - xMargin]);
  }, [innerWidth, granularity, granularityBands, timestamps]);

  const handleTooltip = useCallback(
    (
      event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x } = localPoint(event) || { x: 0 };

      const eachBand = granularityBands.step();
      const index = Math.floor((x - margin.left) / eachBand);

      setTooltipData({
        energy: graphPoints[index]?.total as number,
        "next-energy": graphPoints[index]?.["next-total"] as number,
        temperature: graphPoints[index]?.temperature as number,
        "next-temperature": graphPoints[index]?.["next-temperature"] as number,
        timestamp: graphPoints[index]?.timestamp as number,
        "next-timestamp": graphPoints[index]?.["next-timestamp"] as number,
        operational: graphPoints[index]?.operational as string,
        "next-operational": graphPoints[index]?.["next-operational"] as string,
      });
    },
    [granularityBands, setTooltipData, graphPoints],
  );

  const highlight = useMemo(() => {
    if (
      !tooltipData ||
      !tooltipData.timestamp ||
      !tooltipData.energy ||
      !tooltipData["next-energy"]
    ) {
      return null;
    }
    const difference = getDiff(tooltipData.energy, tooltipData["next-energy"]);
    if (typeof difference !== "number") {
      return null;
    }
    const barHeight =
      difference >= 0
        ? yScale(0)! - yScale(difference)!
        : yScale(difference)! - yScale(0)!;
    const barWidth = granularityBands.bandwidth();
    const barX = granularityBands(tooltipData.timestamp);
    const barY = difference >= 0 ? yScale(difference) : yScale(0);
    return (
      <rect
        x={barX}
        y={barY}
        width={barWidth}
        height={barHeight}
        fill={Color(themeContext.primary).darken(0.4).hex()}
      />
    );
  }, [tooltipData, yScale, granularityBands, themeContext.primary]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <svg width={width} height={height} ref={containerRef}>
        <Group left={margin.left} top={margin.top}>
          <DifferenceChartContent
            data={graphPoints}
            yScale={yScale}
            temperatureScale={temperatureScale}
            granularityBands={granularityBands}
            showTemperature={showTemperature}
            xMetricsScale={xMetricsScale}
            granularity={granularity}
          />
          {highlight}
          <rect
            x={0}
            y={0}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => setTooltipData(undefined)}
          />
        </Group>
      </svg>
      {tooltipData && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={yScale(0) - 10}
          left={
            margin.left +
            10 +
            granularityBands(tooltipData.timestamp)! +
            granularityBands.bandwidth() / 2
          }
          style={{ ...defaultStyles, textAlign: "right" }}
        >
          {getTooltipContent(tooltipData)}
        </TooltipInPortal>
      )}
    </div>
  );
};
