import dayjs from "@properate/dayjs";
import { LineClamp } from "@/components/LineClamp";
import { SearchHighlighter } from "@/components/SearchHighlighter";

export const DATE_FORMAT = "DD MMM YYYY";

type Props = {
  date: Date | null;
  search: string;
};

export function BuildingTenantsTableDateColumn(props: Props) {
  if (props.date === null) {
    return null;
  }

  const formattedDate = dayjs(props.date).format(DATE_FORMAT);

  return (
    <LineClamp title={formattedDate}>
      <SearchHighlighter search={props.search} text={formattedDate} />
    </LineClamp>
  );
}
