import { SharedAxisProps, AxisLeft, AxisRight } from "@visx/axis";
import { Scale } from "@visx/brush/lib/types";
import { formatUnit } from "@properate/common";
import { formatMax6Digits } from "@/utils/format";

interface Props {
  unit: string;
  color: string;
  scale: Scale<number>;
  graphWidth: number;
  graphHeight: number;
  index: number;
  tickSize: number;
}

export function SimpleGraphYAxis({
  unit,
  color,
  scale,
  graphWidth,
  graphHeight,
  index,
  tickSize,
}: Props) {
  const isLeftAlignedAxis = index % 2 === 0;
  const isAxisOnLeftSide = index % 4 <= 1;
  const tickAdjustX = 5;
  const axisProps: SharedAxisProps<Scale> = {
    scale,
    numTicks: graphHeight > 520 ? 10 : 5,
    tickStroke: color,
    tickFormat: formatMax6Digits,
    tickLabelProps: {
      fill: color,
      fontSize: tickSize,
      style: {
        userSelect: "none",
      },
    },
    hideZero: true,
    left: isAxisOnLeftSide ? 0 : graphWidth,
  };

  function getTextX() {
    // 16 is set by @visx/axis
    const offset = 16 + tickAdjustX;
    if (isAxisOnLeftSide) {
      return isLeftAlignedAxis ? -offset : offset;
    }
    return isLeftAlignedAxis ? graphWidth - offset : graphWidth + offset;
  }

  function getTextY() {
    const offset = 20;
    return index <= 3 ? -offset : graphHeight + offset;
  }

  return (
    <g>
      {isLeftAlignedAxis ? (
        <AxisLeft {...axisProps} />
      ) : (
        <AxisRight {...axisProps} />
      )}
      <text
        x={getTextX()}
        y={getTextY()}
        fontSize={14}
        fill={color}
        textAnchor="middle"
        style={{ userSelect: "none" }}
      >
        {formatUnit(unit)}
      </text>
    </g>
  );
}
