import { PropsWithChildren, useEffect } from "react";
import { App } from "antd";

export default function RootContainer({ children }: PropsWithChildren) {
  const outerId = "id_outer_root";
  const innerId = "id_inner_root_app";

  useEffect(() => {
    /**
     * Something on our site sets the style-attribute on these root-nodes with overflow:initial, which breaks the
     * sidebar (notes).
     *
     * I've not been able to identify where overflow:initial is added, but it happens on the Portfolio-page, when
     * firebase-data is received.
     *
     * This is a workaround for this issue, where we observe any attribute-mutations made on the outer and inner
     * root-nodes, and remove the style-attribute if it's added.
     */
    const outerElement = document.getElementById(outerId);
    const innerElement = document.getElementById(innerId);
    if (!outerElement || !innerElement) {
      console.error("could not find elements! aborting observer");
      return;
    }

    const observerConfig = {
      attributes: true,
    };

    const handleStyleMutations = (
      mutations: MutationRecord[],
      element: HTMLElement,
    ) => {
      for (const mutation of mutations) {
        if (
          mutation.type !== "attributes" ||
          mutation.attributeName !== "style"
        ) {
          continue;
        }
        element.removeAttribute("style");
      }
    };

    const outerObserver = new MutationObserver((mutations) =>
      handleStyleMutations(mutations, outerElement),
    );
    const innerObserver = new MutationObserver((mutations) =>
      handleStyleMutations(mutations, innerElement),
    );

    outerObserver.observe(outerElement, observerConfig);
    innerObserver.observe(innerElement, observerConfig);

    return () => {
      outerObserver.disconnect();
      innerObserver.disconnect();
    };
  }, []);

  return (
    <div
      className="flex w-screen h-screen overflow-auto box-border"
      id={outerId}
    >
      <div
        className="flex max-w-full flex-col shrink h-full w-full overflow-auto box-border"
        id={innerId}
      >
        <App>{children}</App>
      </div>
      <div
        className="h-full box-border py-2"
        id="default-sidebar-portal-location"
      />
    </div>
  );
}
