import { useUser } from "@properate/auth";
import { CSSProperties } from "react";
import { Button, Space } from "antd";
import { useTranslations } from "@properate/translations";

interface Props {
  onClickAddLabels?: () => void;
  onClickAddResources?: () => void;
  onClickDeleteFiles?: () => void;
  onClickDownloadFiles?: () => void;
  onClickRestoreFiles?: () => void;
  onClickPurgeFiles?: () => void;
  style?: CSSProperties;
}

export function FileTableGroupActions({
  onClickAddLabels,
  onClickAddResources,
  onClickDeleteFiles,
  onClickDownloadFiles,
  onClickRestoreFiles,
  onClickPurgeFiles,
  style,
}: Props) {
  const user = useUser();
  const t = useTranslations();

  return (
    <Space style={style}>
      {onClickAddLabels && (
        <Button onClick={onClickAddLabels} disabled={user.isViewer}>
          {t("files.actions.edit-labels")}
        </Button>
      )}
      {onClickAddResources && (
        <Button onClick={onClickAddResources} disabled={user.isViewer}>
          {t("files.actions.edit-system")}
        </Button>
      )}
      {onClickDeleteFiles && (
        <Button onClick={onClickDeleteFiles} disabled={user.isViewer}>
          {t("files.actions.delete")}
        </Button>
      )}
      {onClickDownloadFiles && (
        <Button onClick={onClickDownloadFiles}>
          {t("files.actions.download")}
        </Button>
      )}{" "}
      {onClickRestoreFiles && (
        <Button onClick={onClickRestoreFiles}>
          {t("files.actions.restore")}
        </Button>
      )}
      {onClickPurgeFiles && (
        <Button danger onClick={onClickPurgeFiles}>
          {t("files.actions.delete-permanently")}
        </Button>
      )}
    </Space>
  );
}
