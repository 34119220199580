import { TimeSpan } from "@properate/common";
import {
  DatapointAggregates,
  DatapointInfo,
  DoubleDatapoints,
} from "@cognite/sdk";
import { cogniteClient } from "@/services/cognite-client";
import { getMaxGranularity } from "@/features/analysis";
import { CDF_CONSTANTS } from "./cdf";

function getTimeFromDate(datapoint: DatapointInfo, date: number) {
  return Math.abs(datapoint.timestamp.valueOf() - date);
}

export function getOnePercentThreshold(timeSpan: TimeSpan) {
  return Math.round((timeSpan[1] - timeSpan[0]) * 0.01);
}

// Assumes data points are sorted by timestamp in an ascending manner
export function getDatapointClosestToDate<T extends DatapointInfo>(
  points: T[],
  date: Date | number,
  threshold = Number.MAX_SAFE_INTEGER,
): T | null {
  const dateInMS = date.valueOf();
  const pointClosestToDate = points.find((datapoint, i) => {
    const timeFromDateCurrent = getTimeFromDate(datapoint, dateInMS);
    if (timeFromDateCurrent < threshold) {
      const nextPoint = points.at(i + 1);
      if (nextPoint) {
        const timeFromDateNext = getTimeFromDate(nextPoint, dateInMS);
        return timeFromDateNext > timeFromDateCurrent;
      }
      return true;
    }
    return false;
  });
  return pointClosestToDate || null;
}

export async function getAmountOfDatapointsPerTimeseries(
  timeseriesIds: number[],
  start: number,
  end: number,
) {
  const timeseriesWithCountsList = (await cogniteClient.datapoints.retrieve({
    items: timeseriesIds.map((id) => ({ id })),
    aggregates: ["count"],
    granularity: getMaxGranularity([start, end]),
    start,
    end,
  })) as DatapointAggregates[];
  return timeseriesWithCountsList.map(({ id, datapoints }) => ({
    id,
    amountOfDatapoints: datapoints.reduce(
      (totalCountCurrent, { count }) => totalCountCurrent + count!,
      0,
    ),
  }));
}

export async function getTimeseriesListWithAllRawData(
  amountOfDatapointsPerTimeseries: Array<{
    id: number;
    amountOfDatapoints: number;
  }>,
  start: number,
  end: number,
) {
  const timeseriesWithAmountOfQueriesList = amountOfDatapointsPerTimeseries.map(
    ({ id, amountOfDatapoints }) => {
      return {
        id,
        // Use array so it's easier to loop
        amountOfQueries: Math.ceil(
          amountOfDatapoints / CDF_CONSTANTS.datapoints.limits.query.raw,
        ),
      };
    },
  );
  const timeseriesListWithDatapoints = await Promise.all(
    timeseriesWithAmountOfQueriesList.map(async ({ id, amountOfQueries }) => {
      return Array(amountOfQueries)
        .fill(null)
        .reduce<Promise<DoubleDatapoints> | null>(
          async (timeseriesWithAllDatapointsPromise) => {
            const timeseriesWithAllDatapoints =
              await timeseriesWithAllDatapointsPromise;
            const lastDatapoint =
              timeseriesWithAllDatapoints?.datapoints.at(-1);
            const [timeseriesWithDatapoints] =
              (await cogniteClient.datapoints.retrieve({
                items: [{ id }],
                start: lastDatapoint
                  ? lastDatapoint.timestamp.getTime() + 1
                  : start,
                end,
                limit: CDF_CONSTANTS.datapoints.limits.query.raw,
              })) as [DoubleDatapoints];
            return {
              ...timeseriesWithDatapoints,
              datapoints: timeseriesWithAllDatapoints
                ? timeseriesWithAllDatapoints.datapoints.concat(
                    timeseriesWithDatapoints.datapoints,
                  )
                : timeseriesWithDatapoints.datapoints,
            };
          },
          null,
        );
    }),
  );
  return timeseriesListWithDatapoints.filter(
    (timeseriesWithDatapoints): timeseriesWithDatapoints is DoubleDatapoints =>
      timeseriesWithDatapoints !== null,
  );
}
