import { PageHeader } from "@ant-design/pro-layout";
import styled from "styled-components";

export const StickyPageHeader = styled(PageHeader)`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.background};
`;
