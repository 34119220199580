import { TimeSpan } from "@properate/common";
import {
  getDatapointClosestToDate,
  getOnePercentThreshold,
} from "@/utils/datapoints";
import { SimplePoint, SimplePointsWithMetadata } from "../../types";

type Props = {
  simplePointsWithMetadataList: SimplePointsWithMetadata[];
  date: Date;
  zoomedTimeSpan: TimeSpan;
};
export const filterClosestSimplePointsWithMetadataList = ({
  simplePointsWithMetadataList,
  zoomedTimeSpan,
  date,
}: Props) => {
  return simplePointsWithMetadataList.reduce(
    (acc, item) => {
      const simplePointClosestToDate = getDatapointClosestToDate(
        item.simplePoints,
        date,
        getOnePercentThreshold(zoomedTimeSpan),
      );
      if (!simplePointClosestToDate) return acc;

      const newItem = {
        simplePointClosestToDate,
        item,
      };
      return [...acc, newItem];
    },
    [] as {
      simplePointClosestToDate: SimplePoint;
      item: SimplePointsWithMetadata;
    }[],
  );
};
