import { Space, Button } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { useUser } from "@properate/auth";
import { SubscriptionsPerUser } from "../../types";

interface Props {
  subscriptionsPerUser: SubscriptionsPerUser;
  onChange: (value: SubscriptionsPerUser) => unknown;
}

export const AlarmSubscriptions = ({
  subscriptionsPerUser,
  onChange,
}: Props) => {
  const user = useUser();
  return (
    <Space direction="vertical" size={4}>
      {Object.entries(subscriptionsPerUser).flatMap(
        ([email, { email: emailSubscriptions, phone: phoneSubscriptions }]) => {
          const isButtonDisabled = email === user.email ? false : !user.isAdmin;
          return emailSubscriptions
            .concat(phoneSubscriptions)
            .map((emailOrPhone) => (
              <div key={emailOrPhone}>
                {` ${emailOrPhone} `}
                <Button
                  size="small"
                  icon={<MinusOutlined />}
                  shape="circle"
                  disabled={isButtonDisabled}
                  onClick={() =>
                    onChange({
                      ...subscriptionsPerUser,
                      [email]: {
                        email: emailSubscriptions.filter(
                          (email) => email !== emailOrPhone,
                        ),
                        phone: phoneSubscriptions.filter(
                          (phone) => phone !== emailOrPhone,
                        ),
                      },
                    })
                  }
                />
              </div>
            ));
        },
      )}
    </Space>
  );
};
