import { Col, Row, DatePicker } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import dayjs from "@properate/dayjs";
import {
  CenteredDiv,
  DotDivider,
} from "@/pages/properateCalendar/components/elements";
import SelectTime from "@/pages/properateCalendar/components/SelectTime";

type Props = {
  start: Date;
  setStart: (date: Date) => void;
  end: Date;
  setEnd: (date: Date) => void;
};

const SelectDateAndTime = ({ start, setStart, end, setEnd }: Props) => {
  return (
    <Row style={{ marginTop: 8 }}>
      <Col span={2}>
        <CenteredDiv>
          <CalendarOutlined />
        </CenteredDiv>
      </Col>
      <Col span={22}>
        <DatePicker
          onChange={(date) => {
            const hourStart = dayjs(start).tz("Europe/Oslo").hour();
            const minuteStart = dayjs(start).tz("Europe/Oslo").minute();
            setStart(
              dayjs(date)
                .tz("Europe/Oslo")
                .hour(hourStart)
                .minute(minuteStart)
                .second(0)
                .toDate(),
            );
            const hourEnd = dayjs(end).tz("Europe/Oslo").hour();
            const minuteEnd = dayjs(end).tz("Europe/Oslo").minute();
            const newEnd = dayjs(date)
              .tz("Europe/Oslo")
              .hour(hourEnd)
              .minute(minuteEnd)
              .second(0)
              .toDate();
            setEnd(newEnd);
          }}
          defaultValue={dayjs(start).tz("Europe/Oslo")}
          format={(value) =>
            `${value.tz("Europe/Oslo").format("dddd, Do MMMM")}`
          }
          size="large"
          allowClear={false}
        />
        <DotDivider />
        <SelectTime
          start={start}
          end={end}
          setEnd={setEnd}
          setStart={setStart}
        />
      </Col>
    </Row>
  );
};

export default SelectDateAndTime;
