import { ProperateHighlighter } from "../properateHighlighter/ProperateHighlighter";
import { BuildingInfoContainer, BuildingPic, BuildingOwner } from "./elements";

interface Props {
  street: string;
  place: string;
  owner?: string;
  alias?: string;
  imageUrl?: string;
  searchWords?: string[];
}
const EMPTY_IMAGE =
  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
export const BuildingInfo = ({
  owner = "",
  street,
  place,
  alias,
  imageUrl,
  searchWords = [],
}: Props) => {
  const label = alias ? `${owner}\n${alias}\n${street}` : `${owner}\n${street}`;
  return (
    <BuildingInfoContainer>
      <BuildingPic
        src={imageUrl || EMPTY_IMAGE}
        width={100}
        height={75}
        alt={label}
        aria-label={label}
      />
      <div>
        {owner && (
          <BuildingOwner data-testid={`building-owner/${owner}`}>
            <ProperateHighlighter
              searchWords={searchWords}
              textToHighlight={owner}
              title={owner}
            />
          </BuildingOwner>
        )}
        {alias && (
          <div data-testid={`building-alias/${alias}`}>
            <ProperateHighlighter
              searchWords={searchWords}
              textToHighlight={alias}
              title={alias}
            />
          </div>
        )}
        <div>
          <ProperateHighlighter
            searchWords={searchWords}
            textToHighlight={street}
            title={street}
          />
        </div>
        <div>
          <ProperateHighlighter
            searchWords={searchWords}
            textToHighlight={place}
            title={place}
          />
        </div>
      </div>
    </BuildingInfoContainer>
  );
};
