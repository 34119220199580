import { forwardRef } from "react";
import Joyride, { BeaconRenderProps, CallBackProps, Step } from "react-joyride";
import { Tooltip } from "antd";
import { useTranslations } from "@properate/translations";
import { DEFAULT_GLOBAL_THEME } from "../../theme/graph";
import { Beacon } from "./elements";

interface Props {
  content: Step;
  disableScrolling?: boolean;
  onClose: (target: string) => unknown;
}

const JoyrideBeacon = forwardRef<HTMLDivElement, BeaconRenderProps>(
  (props, ref) => {
    const t = useTranslations();

    return (
      <Tooltip title={t("layout.joyride.click-here-hint")} ref={ref}>
        <Beacon
          className="react-joyride__beacon"
          type="button"
          data-test-id="button-beacon"
          {...props}
          title={undefined} // we override the default (wrong) value, we have a better tooltip wrapped around this
          aria-label={undefined} // we override the default (wrong) value
        >
          <span className="joyride-beacon-inner" />
          <span className="joyride-beacon-outer" />
        </Beacon>
      </Tooltip>
    );
  },
);
JoyrideBeacon.displayName = "JoyrideBeacon";

export const JoyrideWrapper = ({
  content,
  onClose,
  disableScrolling,
}: Props) => {
  const t = useTranslations();

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action } = data;

    if (action === "close") {
      onClose(content.target as string);
    }
  };

  return (
    <Joyride
      beaconComponent={JoyrideBeacon}
      stepIndex={0}
      callback={handleJoyrideCallback}
      locale={{
        back: t("layout.joyride.back"),
        close: t("layout.joyride.close"),
        last: t("layout.joyride.last"),
        next: t("layout.joyride.next"),
        skip: t("layout.joyride.skip"),
      }}
      steps={[content]}
      key={content.target as string}
      continuous={false}
      showSkipButton={false}
      disableOverlayClose
      floaterProps={{ hideArrow: true }}
      disableScrolling={disableScrolling}
      styles={{
        tooltipContainer: {
          textAlign: "left",
        },
        options: {
          primaryColor: DEFAULT_GLOBAL_THEME.color.ORANGE,
          zIndex: 999,
        },
      }}
    />
  );
};
