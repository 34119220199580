"use client";

import {
  DocumentReference,
  getDocFromCache,
  getDocsFromCache,
  onSnapshot,
  Query,
} from "firebase/firestore";
import { useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Ref = Query<any> | DocumentReference;

type Arg = Ref | null;
type Args = Arg | Array<Ref>;

type WithSnapshotId<T> = T & { snapshotId: string };

/**
 * This function is used to normalize the query argument to an array.
 */
const toQueries = (query: Args) => {
  if (query === null) {
    return [];
  }

  return Array.isArray(query) ? query : [query];
};

/**
 * This function is used to normalize the data returned from the cache to an array.
 */
const getFromCache = async (ref: NonNullable<Arg>, multi: boolean) => {
  if (multi) {
    return getDocsFromCache(ref as Query).then((cache) => cache.docs);
  }

  return getDocFromCache(ref as DocumentReference)
    .then((cache) => {
      return [cache];
    })
    .catch((error) => {
      if (error.code === "unavailable") {
        return Promise.resolve([]);
      }
      throw Error("Could not get from cache", { cause: error });
    });
};

/**
 * Converts Firestore Timestamps to Date objects.
 *
 * @see https://firebase.google.com/docs/reference/js/firestore_.timestamp
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const recursivelyConvertFirestoreTimestampsToDate = (data: any): any => {
  if (!data) {
    return data;
  }

  if (
    typeof data.toDate === "function" &&
    typeof data.nanoseconds === "number" &&
    typeof data.seconds === "number"
  ) {
    return data.toDate();
  }

  if (Array.isArray(data)) {
    return data.map(recursivelyConvertFirestoreTimestampsToDate);
  }

  if (typeof data === "object") {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        recursivelyConvertFirestoreTimestampsToDate(value),
      ]),
    );
  }

  return data;
};

/**
 * Retrieves data from firestore.
 *
 * Utilizes Firebase cache, and returns SWR-ish data-structure.
 *
 * @param query The query to execute against the firestore.
 * @param multi Whether the query is a collection or a document.
 */
function useGetFirestoreData<T extends object>(query: Args, multi: boolean) {
  const [data, setData] = useState<WithSnapshotId<T>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | string | null>(null);

  if (error) {
    console.error(error);
  }

  /**
   * Fetch any data matching provided query from local Firestore cache. Normalize data so we always return an array.
   * Update state variables `data`, `isLoading` and `error` based on results, to trigger updated values to caller.
   */
  function fetchFromCache() {
    Promise.all(
      toQueries(query).map((ref) =>
        getFromCache(ref, multi).then((docs) =>
          docs.reduce<Array<WithSnapshotId<T>>>((docs, doc) => {
            const data = recursivelyConvertFirestoreTimestampsToDate(
              doc.data(),
            );

            if (!data) {
              // Skip documents that don't exist anymore.
              return docs;
            }

            data.snapshotId = doc.id;

            docs.push(data);

            return docs;
          }, []),
        ),
      ),
    )
      .then((data) => setData(data.flat()))
      .catch(setError)
      .finally(() => setIsLoading(false));
  }

  /**
   * Subscribe to any changes in the provided query. These changes will automatically populate the local Firebase-cache,
   * so we run {@link fetchFromCache} on change to update state-variables for return to caller.
   */
  useEffect(() => {
    const unsubscribes = toQueries(query).map((ref) =>
      onSnapshot(
        ref as Query,
        () => fetchFromCache(),
        (error) => {
          setIsLoading(false);
          setError(error);
        },
      ),
    );

    return () => {
      for (const unsubscribe of unsubscribes) {
        unsubscribe();
      }
    };
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  // Return SWR-ish data to simplify/unify with other data-fetching hooks.
  return {
    data,
    isLoading,
    error,
  };
}

export function useGetFirestoreCollection<T extends object>(query: Args) {
  return useGetFirestoreData<T>(query, true);
}

export function useGetFirestoreDocument<T extends object>(query: Arg) {
  const { data, isLoading, error } = useGetFirestoreData<T>(query, false);

  return {
    data: data?.[0], // There will only ever be at most one document.
    isLoading,
    error,
  };
}
