import { useEffect, useState } from "react";

export function useDebounced<T>(value: T, wait = 1000): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedValue(value), wait);
    return () => clearTimeout(timeoutId);
  }, [value, wait]);
  return debouncedValue;
}
