import { useSetSidebarData } from "@properate/ui";
import { useEffect } from "react";
import { NotesSidebarValues } from "@/features/notes";

export const ENERGY_NOTE_FILTER_ID = "notes-for-energy";

export const useEnergyNoteByDateFiltering = (start: Date, end: Date) => {
  const { setSidebarData } = useSetSidebarData<NotesSidebarValues>();

  useEffect(() => {
    setSidebarData({
      startTime: start,
      endTime: end,
    });
  }, [start, end]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const getColorsForAssets = (colors: string[], selectedIds: number[]) => {
  return selectedIds.reduce(
    (acc, currentValue, currentIndex) => {
      const colorIndex =
        currentIndex + 1 > colors.length
          ? currentIndex % colors.length
          : currentIndex;
      acc[currentValue] = colors[colorIndex];
      return acc;
    },
    {} as Record<string, string>,
  );
};
