"use client";

import { ChevronDownIcon } from "lucide-react";
import { cn } from "../../lib/cn";
import {
  AccordionActionType,
  AccordionEntryId,
  AccordionItemProps,
  AccordionOptions,
} from "./types";
import { useAccordionActions, useAccordionItem } from "./hooks";

function AccordionContent({
  open,
  primaryClosedContent,
  primaryOpenContent,
  textContent,
  secondaryContent,
}: Pick<
  AccordionItemProps,
  | "open"
  | "textContent"
  | "primaryOpenContent"
  | "primaryClosedContent"
  | "secondaryContent"
>) {
  function renderTextContent() {
    if (textContent === undefined) {
      return null;
    }
    return (
      <p
        className={`font-sans mt-0 leading-5 ${open && "whitespace-pre-line"} ${
          !open && "truncate"
        }`}
      >
        {textContent}
      </p>
    );
  }

  function renderComplexContent() {
    if (textContent !== undefined) {
      return null;
    }
    return open ? primaryOpenContent : primaryClosedContent;
  }

  return (
    <>
      {renderTextContent()}
      {renderComplexContent()}
      {open && secondaryContent}
    </>
  );
}

export function BasicAccordionItem({
  open,
  title,
  textContent,
  primaryOpenContent,
  primaryClosedContent,
  secondaryContent,
  setOpen,
  htmlId,
  hasPaddings = true,
  ...options
}: AccordionItemProps) {
  const finalOptions: AccordionOptions = {
    openClassName: "bg-background-emphasised",
    closedClassName: "bg-background-secondary",
    ...options,
  };

  return (
    <div
      className={`${hasPaddings ? "px-4 py-2" : ""} accordion-item ${
        options.className ?? ""
      } ${open ? finalOptions.openClassName : finalOptions.closedClassName}`}
    >
      <div
        className={`flex justify-between ${
          options.titleWrapperClassName ?? ""
        }`}
      >
        <h3
          className={`text-sm font-medium my-2 ${options.titleClassName ?? ""}`}
        >
          {title}
        </h3>
        <button
          className={cn(
            "flex-none flex items-center border-none bg-transparent accordion-icon",
            {
              "accordion-icon-inverted": open,
            },
          )}
          type="button"
          onClick={() => setOpen(!open)}
          aria-expanded={open ? "true" : "false"}
          aria-controls={`${htmlId}_content`}
          id={`${htmlId}_trigger`}
        >
          <ChevronDownIcon strokeWidth={1.5} />
        </button>
      </div>
      <section
        className={cn("text-sm accordion-body", {
          "max-h-fit my-2": open,
          "max-h-[1.25rem] mb-2": !open && textContent,
        })}
        id={`${htmlId}_content`}
        aria-labelledby={`${htmlId}_trigger`}
      >
        <AccordionContent
          open={open}
          textContent={textContent}
          primaryOpenContent={primaryOpenContent}
          primaryClosedContent={primaryClosedContent}
          secondaryContent={secondaryContent}
        />
      </section>
    </div>
  );
}

export function AccordionItem({
  itemId,
  htmlId,
}: Readonly<{ itemId: AccordionEntryId; htmlId: string }>) {
  const { item, options } = useAccordionItem(itemId);
  const dispatch = useAccordionActions();
  if (!item) {
    return null;
  }

  return (
    <BasicAccordionItem
      open={item.open}
      setOpen={(open) =>
        dispatch({ type: AccordionActionType.setIsOpen, open, itemId: itemId })
      }
      title={item.title}
      textContent={item.textContent}
      primaryOpenContent={item.primaryOpenContent}
      primaryClosedContent={item.primaryClosedContent}
      secondaryContent={item.secondaryContent}
      uniqueId={itemId}
      htmlId={htmlId}
      {...options}
    />
  );
}
