import { useState } from "react";
import { Step } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { JoyrideWrapper } from "@/components/JoyrideWrapper/JoyrideWrapper";
import { useOnboarding } from "@/utils/onboarding";
import { useUserSettings } from "@/services/userSettings";
import usePageTitle from "@/hooks/usePageTitle";
import FullLayout from "../../layout/FullLayout";
import PortfolioPage from "./PortfolioPage";
import { useBuildingsWithKPIs } from "./hooks/useBuildingsWithKPIs";
import { useFilteredBuildings } from "./hooks/useFilteredBuildings";

const Portfolio = () => {
  const t = useTranslations();
  usePageTitle(t("portfolio-view.title"));

  const STEPS: Step[] = [
    {
      placement: "top",
      target: ".ant-table-header",
      content: t("portfolio-view.onboarding.personalize-portfolio-step"),
    },
    {
      placement: "top",
      target: ".hint-help-question",
      content: t("portfolio-view.onboarding.click-on-button"),
    },
  ];

  const navigate = useNavigate();
  const [onboardingSteps, setOnboardingStepCompleted] = useOnboarding(STEPS);
  const [isKPIModalOpen, setIsKPIModalOpen] = useState(false);
  const { data: preferences } = useUserSettings();
  const DEFAULT_KPIS_FOR_NEW_USERS = [
    "consumptionThisYear",
    "consumptionThisYearPerSquareMeter",
    "consumptionLast30Days",
    "consumptionLast30DaysPerSquareMeter",
    "maxHourlyConsumptionLast30Days",
    "percentageEnergyUseInWorkHoursLastWeek",
    "percentageEnergyUseInWorkHoursLast30Days",
  ] as const;
  const portfolioSettings =
    preferences &&
    (preferences.portfolio || { columns: DEFAULT_KPIS_FOR_NEW_USERS });
  const portfolioSettingsColumns = portfolioSettings?.columns || [];

  const data = useBuildingsWithKPIs(portfolioSettingsColumns);
  const { filteredBuildings } = useFilteredBuildings(data);

  function handlePressEnter() {
    if (filteredBuildings.length === 1) {
      navigate(`/asset/${filteredBuildings[0].key}/building`);
    }
  }

  return (
    <FullLayout
      showSettings={isKPIModalOpen}
      onToggleSettings={setIsKPIModalOpen}
      onPressEnter={handlePressEnter}
    >
      <PortfolioPage
        data={filteredBuildings}
        portfolioSettingsColumns={portfolioSettingsColumns}
        isKPIModalOpen={isKPIModalOpen}
        setIsKPIModalOpen={setIsKPIModalOpen}
      />
      {onboardingSteps && onboardingSteps.length > 0 && (
        <JoyrideWrapper
          content={onboardingSteps[0]}
          onClose={setOnboardingStepCompleted}
        />
      )}
    </FullLayout>
  );
};

export default Portfolio;
