import {
  FormContextAction,
  FormContextActionType,
  FormContextType,
  ResultCallbackFunctionParams,
  SubmitValue,
  ValidateAllFormContextAction,
} from "./types";
import {
  addMissingEntries,
  makeEntriesCopy,
  resetAllEntries,
  validateAllEntries,
  validateField,
} from "./utils";

export function formReducer(
  { entries, options, t, onSubmit }: FormContextType,
  action: FormContextAction,
): FormContextType {
  const common = { t, options, onSubmit };

  switch (action.type) {
    case FormContextActionType.change:
      return {
        ...common,
        entries: {
          ...entries,
          [action.id]: {
            ...entries[action.id],
            value: action.value,
          },
        },
      };
    case FormContextActionType.validateField:
      return {
        ...common,
        entries: validateField({ entries, action, t }),
      };
    case FormContextActionType.validateFields:
      return {
        ...common,
        entries: {
          ...entries,
          ...validateAllEntries({
            entries: Object.keys(entries)
              .filter((id) => action.ids.includes(id))
              .reduce(
                (filteredEntries, id) =>
                  Object.assign(filteredEntries, { [id]: entries[id] }),
                {},
              ),
            action,
            t,
          }),
        },
      };
    case FormContextActionType.validate:
      return {
        ...common,
        entries: validateAllEntries({ entries, action, t }),
      };
    case FormContextActionType.clear:
      return {
        ...common,
        entries: resetAllEntries(makeEntriesCopy(entries)),
      };
    case FormContextActionType.clearFields:
      return {
        ...common,
        entries: {
          ...entries,
          ...resetAllEntries(makeEntriesCopy(entries, action.ids)),
        },
      };
    case FormContextActionType.addFieldsIfMissing:
      return {
        ...common,
        entries: addMissingEntries({ entries, newEntries: action.entries }),
      };
    case FormContextActionType.submit:
      return {
        ...common,
        entries: validateAllEntries({
          entries,
          action: {
            ...action,
            resultCallback: ({
              entries,
              hasError,
              errorIds,
            }: ResultCallbackFunctionParams) => {
              if (!hasError) {
                onSubmit(entries as SubmitValue);
              } else {
                console.error(
                  "Unhandled validation-error before submit! ErrorIds: ",
                  errorIds,
                );
              }
            },
          } as ValidateAllFormContextAction,
          t,
        }),
      };
  }
}
