import { ThirdPartyAlarmVisualTimeseries } from "@properate/common";
import { Empty } from "antd";
import { useTranslations } from "@properate/translations";
import { useFormValue } from "../../../FormContext/hooks";
import { ThirdPartyAlarmFields } from "../types";

export default function NoSelectedVisualTimeseries() {
  const t = useTranslations(
    "alarm-details.alarm-types.third-party-alarm.visual-timeseries",
  );

  const [visualTimeseries] = useFormValue<ThirdPartyAlarmVisualTimeseries[]>(
    ThirdPartyAlarmFields.VisualTimeseries,
  );

  if (visualTimeseries && visualTimeseries.length > 0) {
    return null;
  }

  return (
    <div className="w-full flex justify-center items-center box-border">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("empty")} />
    </div>
  );
}
