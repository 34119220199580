import { Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { cn } from "@properate/ui";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import TimeseriesGraphForAlarmType from "./TimeseriesGraphForAlarmType";

export function AlarmTimeseriesGraph() {
  const [showGraph, setShowGraph] = useFormValue<boolean>(
    AlarmGraphFormFields.GraphVisible,
  );

  function toggleGraph() {
    setShowGraph(!showGraph);
  }

  return (
    <div
      className={cn(
        `col-span-2 w-full rounded-lg bg-background-secondary p-4 overflow-hidden box-border transition-all`,
        showGraph ? "h-[40vh]" : "h-[58px] overflow-y-hidden",
      )}
    >
      <div
        className={cn(
          "w-full p-0 m-0 z-20 bg-transparent flex justify-center items-center scale-150",
          showGraph ? "mb-[-30px]" : "",
        )}
      >
        <Button
          type="dashed"
          shape="circle"
          icon={showGraph ? <UpOutlined /> : <DownOutlined />}
          onClick={toggleGraph}
        />
      </div>
      {showGraph && <TimeseriesGraphForAlarmType />}
    </div>
  );
}

export enum AlarmGraphFormFields {
  EventsVisible = "AlarmGraph--EventsVisible",
  GraphVisible = "AlarmGraph--GraphVisible",
}

export const AlarmGraphFormDefaults = {
  [AlarmGraphFormFields.EventsVisible]: {
    defaultValue: true,
  },
  [AlarmGraphFormFields.GraphVisible]: {
    defaultValue: true,
  },
};
