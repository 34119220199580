import { ReactNode } from "react";
import { Spin } from "antd";
import { useIsLoadingWithDelay } from "@/hooks/useIsLoadingWithDelay";

interface Props {
  isLoading: boolean;
  children: ReactNode;
  centerInParent?: boolean;
  delay?: number;
  style?: React.CSSProperties;
  className?: string;
}

export function SpinnerWithDelay({
  isLoading,
  delay,
  children,
  centerInParent = false,
  style,
  className,
}: Props) {
  const isLoadingWithDelay = useIsLoadingWithDelay(isLoading, delay);
  if (isLoadingWithDelay) {
    return (
      <Spin
        style={
          centerInParent
            ? {
                height: "100%",
                width: "100%",
                display: "grid",
                placeContent: "center",
                ...style,
              }
            : style
        }
        className={className}
      />
    );
  }
  if (isLoading) {
    return null;
  }
  return <>{children}</>;
}
