import styled from "styled-components";
import { Link } from "react-router-dom";

export const SubComponentItem = styled(Link)`
  display: inline-block;
  vertical-align: top;
  padding: 0 24px;
  font-weight: bold;
  position: relative;
  margin-bottom: 24px;

  & + &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: ${(props) => props.theme.neutral7};
    top: 0;
    left: -1px;
  }
`;
