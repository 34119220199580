import { Modal, Select, Space, Radio, InputNumber, Form } from "antd";
import { formatTimeseriesName } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { useGetSolarCellTimeseriesList } from "../hooks/useGetSolarCellTimeseries";
import { SolarCellProductionPeriod } from "../types";
import { periodOptions } from "./options";

interface Props {
  open: boolean;
  timeseriesIds: number[] | undefined;
  period: SolarCellProductionPeriod | undefined;
  maxCapacity: number | undefined;
  onCancel: () => unknown;
  onOK: (value: {
    timeseriesIds: number[] | undefined;
    period: SolarCellProductionPeriod;
    maxCapacity: number | undefined;
  }) => unknown;
}

export function SolarCellProductionSettingsModal({
  open,
  timeseriesIds = [],
  period = "lastWeek",
  maxCapacity,
  onCancel,
  onOK,
}: Props) {
  const t = useTranslations();

  const [form] = Form.useForm();
  const { solarCellTimeseriesList, isLoading } =
    useGetSolarCellTimeseriesList();

  function handleOK() {
    form.submit();
  }

  async function handleSubmit({
    period,
    maxCapacity,
    timeseriesIds,
  }: {
    timeseriesIds: number[];
    period: SolarCellProductionPeriod;
    maxCapacity: number;
  }) {
    onOK({
      timeseriesIds,
      period,
      maxCapacity,
    });
  }

  function handleCancel() {
    onCancel();
  }

  return (
    <Modal
      open={open}
      title={t("dashboard.widgets.solar-production.settings")}
      onCancel={handleCancel}
      onOk={handleOK}
    >
      <SpinnerWithDelay isLoading={isLoading} centerInParent>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form
            initialValues={{ maxCapacity, period, timeseriesIds }}
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="timeseriesIds"
              label={t("dashboard.widgets.solar-production.timeseries")}
              rules={[{ required: true }]}
            >
              <Select
                id="solar-cell-timeseries"
                placeholder={t(
                  "dashboard.widgets.solar-production.choose-timeseries",
                )}
                mode="multiple"
                options={solarCellTimeseriesList.map((timeseries) => ({
                  label: formatTimeseriesName(timeseries),
                  value: timeseries.id,
                }))}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="period"
              label={t("dashboard.widgets.solar-production.period")}
            >
              <Radio.Group>
                {periodOptions.map((period) => (
                  <Radio key={period.value} value={period.value}>
                    {t(period.labelKey)}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="maxCapacity"
              label={t("dashboard.widgets.solar-production.max-capacity")}
              rules={[{ required: true }]}
            >
              <InputNumber addonAfter="kWh" decimalSeparator="," />
            </Form.Item>
          </Form>
        </Space>
      </SpinnerWithDelay>
    </Modal>
  );
}
