import styled from "styled-components";

export const RelativeContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 385px;
  // min-height: 189px;
  align-content: flex-start;

  & .settings {
    position: absolute;
    top: 0;
    right: 10px;
  }

  h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 40px;
  }
`;

export const KpiCommonWrapperContainer = styled.div`
  max-height: 70px;
  overflow: hidden;
`;

export const KpiComponentContainer = styled.div`
  overflow: hidden;
`;

export const KpiComponentCompactViewContainer = styled.div`
  padding: 24px 0px 24px 0px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SchemaKpiTableText = styled.span`
  font-size: 15px;
`;
