export const BB_KEYS = [
  "KN",
  "FF",
  "FR",
  "IE",
  "IK",
  "IV",
  "JP",
  "JV",
  "JW",
  "KA",
  "KM",
  "LC",
  "LI",
  "LK",
  "LR",
  "LV",
  "LX",
  "LZ",
  "MO",
  "NB",
  "NT",
  "NU",
  "OC",
  "OE",
  "OF",
  "OM",
  "OS",
  "OU",
  "QD",
  "QE",
  "QP",
  "QT",
  "QV",
  "QX",
  "RA",
  "RB",
  "RD",
  "RE",
  "RF",
  "RG",
  "RH",
  "RJ",
  "RP",
  "RR",
  "RT",
  "RU",
  "RW",
  "RY",
  "SB",
  "SC",
  "SM",
  "SQ",
  "SS",
  "SX",
  "XG",
  "XH",
  "XM",
  "XO",
  "XQ",
  "XS",
  "XX",
  "XZ",
];
