import dayjs from "@properate/dayjs";
import axios from "axios";
import useSWR from "swr";

interface SunriseSunsetResponse {
  properties: {
    sunrise: {
      time: string;
    };
    sunset: {
      time: string;
    };
  };
}

function extractTime(date: string) {
  return dayjs(date, "YYYY-MM-DDTHH:mmZ").format("HH:mm");
}

export function useSunriseSunset(lat: number, lon: number) {
  const today = dayjs().format("YYYY-MM-DD");
  const timezoneOffset = dayjs().format("Z");
  const { data, isLoading } = useSWR(
    `https://api.met.no/weatherapi/sunrise/3.0/sun?lat=${lat}&lon=${lon}&date=${today}&offset=${timezoneOffset}`,
    async (url) => {
      const response = await axios.get<SunriseSunsetResponse>(url);
      return response.data;
    },
  );

  return {
    sunrise: data ? extractTime(data.properties.sunrise.time) : null,
    sunset: data ? extractTime(data.properties.sunset.time) : null,
    isLoading,
  };
}
