import axios, { AxiosResponse } from "axios";
import { cogniteClient } from "@/services/cognite-client";

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getCognitePreviewImage = async (
  cogniteFileId: number,
  {
    controller,
  }: {
    controller?: AbortController;
  },
) => {
  let tryCount = 0;

  const request = async (): Promise<{
    data?: string;
    error?: any;
  }> => {
    const secondsUntilNextMinute = 60 - new Date().getSeconds();
    try {
      const response: AxiosResponse<ArrayBuffer> = await axios.get(
        `https://api.cognitedata.com/api/v1/projects/${
          process.env.REACT_APP_PROJECT_ID
        }/documents/${cogniteFileId}/preview/image/pages/${1}`,
        {
          headers: {
            ...cogniteClient.getDefaultRequestHeaders(),
            Accept: "image/png",
          },
          responseType: "arraybuffer",
          ...(controller && { signal: controller.signal }),
        },
      );

      const arrayBufferView = new Uint8Array(response.data);
      const blob = new Blob([arrayBufferView], { type: "image/png" });
      const urlCreator = window.URL || window.webkitURL;
      return {
        data: urlCreator.createObjectURL(blob),
      };
    } catch (error: any) {
      const { response } = error;
      if (response?.status === 429 && tryCount < 2) {
        console.debug(
          `Too many requests (429). Trying again: #${tryCount + 2}`,
          error,
        );

        tryCount++;
        await sleep(secondsUntilNextMinute * 1000);
        return await request();
      }
      console.debug(`Preview not found`, error);

      return {
        error: response?.data || error,
      };
    }
  };

  return request();
};
