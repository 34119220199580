import { useParams } from "react-router-dom";
import { useGetFirestoreDocument } from "@properate/ui";
import { useMemo } from "react";
import { collection, doc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { Result, Spin } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ProperateApiProvider } from "@/context/ProperateApiContext";
import { FloorPlan } from "./types";
import { FloorPlanMap } from "./FloorPlanMap";
import { FloorPlanProvider } from "./FloorPlanContext";
import { FloorPlanHeader } from "./FloorPlanHeader";

export function FloorPlan() {
  const { snapshotId = "" } = useParams();
  const floorPlan = useGetFloorPlan(snapshotId);

  if (floorPlan.isLoading) {
    return (
      <div className="m-auto">
        <Spin />
      </div>
    );
  }

  if (floorPlan.error) {
    return (
      <div className="m-auto">
        <Result status="error" />
      </div>
    );
  }

  if (floorPlan.data === undefined) {
    return (
      <div className="m-auto">
        <Result status="404" />
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <ProperateApiProvider>
        <FloorPlanProvider floorPlan={floorPlan.data}>
          <div className="flex flex-col h-screen">
            <FloorPlanHeader />
            <div className="flex-1 pl-4 overflow-hidden">
              <FloorPlanMap key={floorPlan.data.snapshotId} />
            </div>
          </div>
        </FloorPlanProvider>
      </ProperateApiProvider>
    </DndProvider>
  );
}

function useGetFloorPlan(snapshotId: string) {
  const { data, error, isLoading } = useGetFirestoreDocument<FloorPlan>(
    useMemo(
      () => doc(collection(browserFirestore, "floor-plans"), snapshotId),
      [snapshotId],
    ),
  );

  return {
    data: data?.softDeleted ? undefined : data,
    error,
    isLoading,
  };
}
