import styled from "styled-components";

const Highlight = styled.span`
  em {
    background-color: #ffc069;
    font-style: normal;
  }
`;

export default Highlight;
