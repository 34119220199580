import { browserFirestore } from "@properate/firebase";
import { useGetFirestoreCollection } from "@properate/ui";
import { collection } from "firebase/firestore";

type CogniteAsset = {
  id: number;
  name: string;
  metadata?: Record<string, string>;
  dataSetId?: number;
};

type FirebaseBuilding = {
  imageUrl: string;
  name: string;
};

const ref = collection(browserFirestore, "buildings");

export const useGetBuildingsWithImages = (cogniteAssets: CogniteAsset[]) => {
  const firestoreBuildings = useGetFirestoreCollection<FirebaseBuilding>(ref);

  if (firestoreBuildings.error || firestoreBuildings.isLoading) {
    return cogniteAssets
      ? cogniteAssets.map(({ id, name, metadata, dataSetId }) => ({
          id,
          name,
          address: metadata?.Adresse ?? "",
          imageUrl: "",
          dataSetId,
        }))
      : [];
  }

  const firestoreBuildingsData = firestoreBuildings.data;

  if (firestoreBuildingsData) {
    return cogniteAssets.map((cogniteAsset) => {
      const firestoreBuilding = firestoreBuildingsData.find(
        (systemBuilding) =>
          Number(systemBuilding.snapshotId) === cogniteAsset.id,
      );

      const { id, metadata, dataSetId } = cogniteAsset;
      const address = metadata?.Adresse ?? "";

      let name = cogniteAsset.name;
      let imageUrl = "";

      if (firestoreBuilding) {
        name = firestoreBuilding.name;
        imageUrl = firestoreBuilding.imageUrl;
      }

      return { id, name, address, imageUrl, dataSetId };
    });
  }

  return [];
};
