let _refreshToken: (() => Promise<unknown>) | undefined;
let _getToken: (() => string | undefined) | undefined;

export function setupApiAuth(
  getToken: typeof _getToken,
  refreshToken: typeof _refreshToken,
) {
  _refreshToken = refreshToken;
  _getToken = getToken;
}

export async function getAuthToken(shouldRefresh = false) {
  if (!_getToken || !_refreshToken) {
    console.warn("API authorization token not set. Call `setupApiAuth` first.");

    return "";
  }

  try {
    if (shouldRefresh) {
      await _refreshToken();
    }

    return _getToken();
  } catch (error) {
    console.error("API authorization failed", error);

    return "";
  }
}
