import { BuildingContactWithId } from "@properate/common";
import { useState } from "react";
import { MessageKey, useTranslations } from "@properate/translations";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { UserSettings } from "@/utils/types";

export const filters = [
  {
    label: "owner",
    check: (contact: BuildingContactWithId) =>
      ["owner", "contact-person", "operations-manager", "caretaker"].includes(
        contact.type,
      ),
  },
  {
    label: "properate",
    check: (contact: BuildingContactWithId) =>
      ["key-account-manager", "technical-account-manager"].includes(
        contact.type,
      ),
  },
  {
    label: "technical",
    check: (contact: BuildingContactWithId) =>
      [
        "technician-ventilation",
        "technician-refrigeration",
        "technician-automation",
        "technician-plumbing",
        "technician-fire-safety",
        "technician-electricity",
        "technician",
      ].includes(contact.type),
  },
] as const;

const allowedFilters: UserSettings["filters"]["contactsSieve"][] = [
  "all",
  "owner",
  "properate",
  "technical",
];

export function useContactsSieve(contacts: BuildingContactWithId[]) {
  const t = useTranslations();
  const [search, setSearch] = useState("");
  const { data } = useUserSettings();
  const filter = allowedFilters.includes(data?.filters?.contactsSieve)
    ? data?.filters?.contactsSieve
    : "all";
  function filterContacts(
    contacts: BuildingContactWithId[],
    search: string,
    filterLabel: string,
  ) {
    return contacts.filter((contact) => {
      if (search) {
        const searchTerm = search.toLowerCase();

        const hasSearchTerm =
          contact.name.toLowerCase().includes(searchTerm) ||
          contact.email?.toLowerCase().includes(searchTerm) ||
          contact.phone?.toLowerCase().includes(searchTerm) ||
          contact.note?.toLowerCase().includes(searchTerm) ||
          t(`settings.contacts.types.${contact.type}` as MessageKey)
            .toLowerCase()
            .includes(searchTerm);

        if (!hasSearchTerm) {
          return false;
        }
      }

      const filter = filters.find((filter) => filter.label === filterLabel);

      return filter ? filter.check(contact) : true;
    });
  }

  return {
    search,
    setSearch,
    filter,
    setFilter: (label: UserSettings["filters"]["contactsSieve"]) =>
      mutateUserSettings({
        filters: {
          contactsSieve: label,
        },
      }),
    filteredContacts: filterContacts(contacts, search, filter),
  };
}
