import { min } from "d3-array";
import { useEffect, useMemo, useState } from "react";
import { scaleBand, scaleTime } from "@visx/scale";

import dayjs from "@properate/dayjs";
import styled from "styled-components";

import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { useSetSidebarData } from "@properate/ui";
import { useAlarmEventsByBuilding } from "@/services/alarms";
import { useGetAlarms } from "@/features/alarms";
import { NotesSidebarValues } from "@/features/notes";
import { GraphScrollArea } from "../../components/GraphNavigator/GraphNavigator";
import { convertToIncidents } from "./utils";

import AlarmChart from "./AlarmChart";

const brushMargin = { top: 0, bottom: 0, left: 0, right: 0 };
const chartSeparation = 30;

export type AlarmProps = {
  containerWidth: number;
  containerHeight: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  compact?: boolean;
  id: number;
  openAlarm: Function;
};

const GraphContainer = styled.div`
  svg text {
    user-select: none;
  }

  svg text::selection {
    background: none;
  }

  padding: 20px;
  color: ${({ theme }) => theme.neutral4};
  background-color: ${({ theme }) => theme.neutral9};
  margin-bottom: 20px;
  display: inline-block;
`;

const AlarmGraph = ({
  compact = false,
  containerWidth,
  containerHeight,
  openAlarm,
  margin = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  id,
}: AlarmProps) => {
  const [position, setPosition] = useState(1);
  const [highlight, setHighlight] = useState<number>();
  const [range, setRange] = useState<number>();
  const { setSidebarData } = useSetSidebarData<NotesSidebarValues>();

  const width = containerWidth - 40;
  const height = containerHeight - 40;

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });

  const { data: alarms } = useGetAlarms([id]);
  const events = useAlarmEventsByBuilding(id);

  const incidents = useMemo(
    () => alarms && events && convertToIncidents(alarms, events),
    [alarms, events],
  );

  const [filteredPeriod, setFilteredPeriod] = useState<[Date, Date]>();

  const innerHeight = height - margin.top - margin.bottom;
  const topChartBottomMargin = compact
    ? chartSeparation / 2
    : chartSeparation + 10;
  const topChartHeight = 0.8 * innerHeight - topChartBottomMargin;

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = Math.max(topChartHeight, 0);

  useEffect(() => {
    let mounted = true;
    if (events) {
      const minDate: Date =
        events &&
        min([
          ...(events ? events.map((data: any) => data.date) : []),
          dayjs().subtract(1, "month").toDate(),
        ]);
      const today = dayjs();
      const range =
        (today && minDate && today.valueOf() - minDate.valueOf()) || 0;

      // default to last 7 days
      if (mounted) {
        setRange(range);
      }
    }
    return () => {
      mounted = false;
    };
  }, [events]);

  useEffect(() => {
    if (range) {
      const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;

      const highlightRange = Math.min(range, sevenDaysInMs);
      const divideHighlightRangeWithRange = highlightRange / range;

      setHighlight(divideHighlightRangeWithRange);
      setPosition(1 - divideHighlightRangeWithRange);
    }
  }, [range]);

  useEffect(() => {
    let mounted = true;
    if (typeof highlight === "number" && typeof range === "number" && events) {
      const minEventStart = min([
        ...events.map((data) => data.date.valueOf()),
        dayjs().subtract(1, "month").valueOf(),
      ]);
      if (minEventStart !== undefined) {
        const start = minEventStart + range * position;
        const end = start + range * highlight;
        if (mounted) {
          const startDate = new Date(start);
          const endDate = new Date(end);
          setFilteredPeriod([startDate, endDate]);

          setSidebarData({
            startTime: startDate,
            endTime: endDate,
          });
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [events, highlight, position, range]);

  // scales
  const dateScale = useMemo(
    () =>
      events &&
      scaleTime<number>({
        range: [0, xMax - 28],
        domain: [
          min([
            ...(events ? events.map((data: any) => data.date) : []),
            dayjs().subtract(1, "month").toDate(),
          ]),
          new Date(),
        ] as [Date, Date],
      }),
    [xMax, events],
  );

  const filteredDateScale = useMemo(
    () =>
      events &&
      scaleTime<number>({
        range: [0, xMax],
        domain: filteredPeriod,
      }),
    [xMax, events, filteredPeriod],
  );

  const filteredCategoryScale = useMemo(
    () =>
      events &&
      scaleBand({
        range: [yMax, 0],
        domain: [...new Set(events.map((e: any) => e.alarmId)).values()],
        padding: 0.4,
      }),
    [yMax, events],
  );

  const categoryScale = useMemo(
    () =>
      events &&
      scaleBand({
        range: [40, 0],
        domain: [...new Set(events.map((e: any) => e.alarmId)).values()],
        padding: 0.2,
      }),
    [events],
  );

  if (containerWidth === 0 || containerHeight === 0) {
    return <></>;
  }

  return (
    <GraphContainer>
      <svg width={width} height={height - 54} ref={containerRef}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height - 140}
          fill="transparent"
          rx={0}
        />
        {incidents && filteredDateScale && (
          <AlarmChart
            alarms={alarms || []}
            hideLeftAxis
            hideBottomAxis={compact}
            data={incidents}
            width={width}
            margin={{ ...margin, bottom: topChartBottomMargin }}
            yMax={yMax}
            xScale={filteredDateScale}
            yScale={filteredCategoryScale}
            hideTooltip={hideTooltip}
            showTooltip={showTooltip}
            showMarkers
            onSelect={(event) => {
              openAlarm(alarms!.find((a) => a.snapshotId === event.alarmId));
            }}
          />
        )}
      </svg>
      {incidents && highlight && (
        <GraphScrollArea
          width={width}
          position={position}
          highlight={highlight}
          onUpdate={(position, highlight) => {
            setHighlight(highlight);
            setPosition(position);
          }}
        >
          <AlarmChart
            hideBottomAxis
            hideLeftAxis
            data={incidents}
            width={width - 28}
            yMax={40}
            xScale={dateScale}
            yScale={categoryScale}
            margin={brushMargin}
            showMarkers
          />
        </GraphScrollArea>
      )}
      {tooltipOpen && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          {((tooltipData as string) || "").split("\n").map((line, i) => (
            <div key={i}>{line}</div>
          ))}
        </TooltipInPortal>
      )}
    </GraphContainer>
  );
};

export default AlarmGraph;
