import { useEffect, useState } from "react";

import styled from "styled-components";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Modal, Tabs } from "antd";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslations } from "@properate/translations";
import { TimeseriesType } from "@/features/energy";
import { SelectEnergyCorrected } from "./SelectEnergyCorrected";
import { SelectEnergyInstant } from "./SelectEnergyInstant";
import { SelectEnergyHierarchy } from "./SelectEnergyHierarchy";
import { SelectEnergySources } from "./SelectEnergySources";

type SelectMeterProps = {
  showActiveMeter: boolean;
  setShowActiveMeter: (active: boolean) => void;
  defaultSelectedHierarchyTimeseries: number[];
  defaultSelectedEnergySources: number[];
  defaultSelectedMeterTimeseries: number[];
  defaultCorrectedMeterTimeseries: number[];
  timeseriesType: TimeseriesType;
  root: number;
  selectTimeseries: ({
    type,
    timeseries,
    root,
  }: {
    type: TimeseriesType;
    timeseries: number[];
    root: number;
  }) => void;
};

const SelectModal = styled(Modal)`
  .ant-modal-body {
    min-height: 500px;
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
`;

export const SelectEnergy = ({
  showActiveMeter,
  setShowActiveMeter,
  defaultSelectedHierarchyTimeseries,
  defaultSelectedEnergySources,
  defaultSelectedMeterTimeseries,
  defaultCorrectedMeterTimeseries,
  timeseriesType: _timeseriesType,
  root,
  selectTimeseries,
}: SelectMeterProps) => {
  const t = useTranslations();

  const [selectedHierarchy, setSelectedHierarchy] = useState(
    defaultSelectedHierarchyTimeseries,
  );
  const [selectedMeter, setSelectedMeter] = useState(
    defaultSelectedMeterTimeseries,
  );
  const [selectedCorrectedMeter, setSelectedCorrectedMeter] = useState(
    defaultCorrectedMeterTimeseries,
  );
  const [selectedEnergySources, setSelectedEnergySources] = useState(
    defaultSelectedEnergySources,
  );

  const [selectedOwnerId, setSelectedOwnerId] = useState(root);

  useEffect(() => {
    setSelectedOwnerId(root);
  }, [root]);

  useEffect(() => {
    setSelectedHierarchy(defaultSelectedHierarchyTimeseries);
  }, [defaultSelectedHierarchyTimeseries]);

  useEffect(() => {
    setSelectedMeter(defaultSelectedMeterTimeseries);
  }, [defaultSelectedMeterTimeseries]);

  useEffect(() => {
    setSelectedCorrectedMeter(defaultCorrectedMeterTimeseries);
  }, [defaultCorrectedMeterTimeseries]);

  useEffect(() => {
    setSelectedEnergySources(defaultSelectedEnergySources);
  }, [defaultSelectedEnergySources]);

  const [timeseriesType, setTimeseriesType] =
    useState<TimeseriesType>(_timeseriesType);

  useEffect(() => {
    setTimeseriesType(_timeseriesType);
  }, [_timeseriesType]);

  const [showExternalId, setShowExternalId] = useState(false);

  useHotkeys("Control+Shift+x", () => setShowExternalId(!showExternalId), [
    showExternalId,
  ]);

  const onChangeEnergyMeter = (change: CheckboxChangeEvent) => {
    if (change.target.checked) {
      setSelectedMeter([...selectedMeter, change.target.value.id]);
    } else {
      setSelectedMeter(
        selectedMeter.filter((id) => id !== change.target.value.id),
      );
    }
  };

  const onChangeCorrectedMeter = (change: number) => {
    setSelectedCorrectedMeter([change]);
  };

  const items = [
    {
      label: t("energy.meter-types.energy-hierarchy"),
      key: "hierarchy",
      children: (
        <SelectEnergyHierarchy
          selectedHierarchy={selectedHierarchy}
          onSelectEnergyHierarchy={setSelectedHierarchy}
          showExternalId={showExternalId}
          selectedOwnerId={selectedOwnerId}
          onSelectOwnerId={setSelectedOwnerId}
        />
      ),
    },
    {
      label: t("energy.meter-types.energy-sources"),
      key: "sources",
      children: (
        <SelectEnergySources
          selectedSources={selectedEnergySources}
          onSelectEnergySources={setSelectedEnergySources}
          showExternalId={showExternalId}
        />
      ),
    },
    {
      label: t("energy.meter-types.energy-gauges"),
      key: "energyMeter",
      children: (
        <SelectEnergyInstant
          onChangeEnergyMeter={onChangeEnergyMeter}
          selectedMeters={selectedMeter}
          showExternalId={showExternalId}
        />
      ),
    },
    {
      label: t("energy.meter-types.temperature-corrected"),
      key: "generated",
      children: (
        <SelectEnergyCorrected
          selectedOwnerId={selectedOwnerId}
          onSelectOwnerId={setSelectedOwnerId}
          showExternalId={showExternalId}
          onSelectMeter={onChangeCorrectedMeter}
          selectedMeter={selectedCorrectedMeter}
        />
      ),
    },
  ];

  const handleOk = () => {
    if (timeseriesType === "hierarchy") {
      selectTimeseries({
        timeseries: selectedHierarchy,
        type: timeseriesType,
        root: selectedOwnerId,
      });
    }
    if (timeseriesType === "energyMeter") {
      selectTimeseries({
        timeseries: selectedMeter,
        type: timeseriesType,
        root: selectedOwnerId,
      });
    }
    if (timeseriesType === "generated") {
      selectTimeseries({
        timeseries: selectedCorrectedMeter,
        type: timeseriesType,
        root: selectedOwnerId,
      });
    }
    if (timeseriesType === "sources") {
      selectTimeseries({
        timeseries: selectedEnergySources,
        type: timeseriesType,
        root: selectedOwnerId,
      });
    }
    setShowActiveMeter(false);
  };

  const onChangeTab = (key: string) => {
    setTimeseriesType(key as TimeseriesType);
  };

  return (
    <SelectModal
      width={760}
      title={t("energy.active-meter")}
      open={showActiveMeter}
      onOk={handleOk}
      destroyOnClose
      onCancel={() => setShowActiveMeter(false)}
    >
      <Tabs activeKey={timeseriesType} onChange={onChangeTab} items={items} />
    </SelectModal>
  );
};
