"use client";

import { useState, MouseEvent, useEffect } from "react";
import { Button } from "antd";
import copy from "copy-to-clipboard";
import { CheckIcon, FilesIcon } from "lucide-react";

interface Props {
  text: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export function ClipboardButton({ text, onClick }: Props) {
  const [copied, setCopied] = useState(false);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    copy(text);
    setCopied(true);
    if (onClick) {
      onClick(event);
    }
  }

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    if (copied) {
      timeoutId = setTimeout(() => setCopied(false), 2000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [copied]);

  return (
    <Button
      icon={
        copied ? (
          <CheckIcon className="w-4 h-4 text-center align-middle" />
        ) : (
          <FilesIcon className="w-4 h-4 text-center align-middle" />
        )
      }
      disabled={!text}
      type="link"
      onClick={handleClick}
    />
  );
}
