"use client";

import { Button, Checkbox, Divider, Input, Popconfirm, Popover } from "antd";
import { useTranslations } from "@properate/translations";
import {
  CheckSquareIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  GripVerticalIcon,
  MessageSquareIcon,
  TrashIcon,
} from "lucide-react";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  genId,
  cn,
  CustomDndProvider,
  useRunOrTimeout,
  RichTextEditor,
} from "@properate/ui";
import { usePossiblyUser } from "@properate/auth";
import { Task, TaskChecklist } from "../../schemas";
import { useUpdateTaskChecklist } from "../../hooks/use-update-task-checklist";
import { useTaskType } from "../../contexts/task-type-context";
import { useUpdateTask } from "../../hooks/use-update-task";
import { ActivityMetadata, Comment } from "./activities";
import { useBuildingContacts } from "./hooks/useBuildingContacts";

type ChecklistsProps = {
  task: Task;
  isEditable: boolean;
};

export function Checklists(props: ChecklistsProps) {
  const t = useTranslations();
  const { trigger: updateChecklist, isMutating } = useUpdateTaskChecklist();
  const commentsByTaskId = useCommentsByTaskId(props.task.comments);

  function handleAdd() {
    updateChecklist({
      where: {
        taskId: props.task.snapshotId,
        checklistId: genId(),
      },
      data: {
        title: "",
        items: [
          {
            id: genId(),
            checked: false,
            content: "",
          },
        ],
      },
    });
  }

  if (Object.keys(props.task.checklists).length === 0) {
    if (!props.isEditable) {
      return null;
    }

    return (
      <div>
        <div className="flex items-center justify-between h-8 mb-4">
          <div className="flex items-center gap-4">
            <CheckSquareIcon className="w-6 h-6" />
            <div className="text-sm tracking-wide">
              {t("task.ui.checklist")}
            </div>
          </div>
          <div className="flex gap-2">
            <Button onClick={handleAdd} loading={isMutating}>
              {t("task.ui.addChecklist")}
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-center h-24 ml-10 rounded-md bg-card">
          {t("task.ui.noChecklists")}
        </div>
      </div>
    );
  }

  // sort by the snapshot id, just to make sure the order is consistent on every render
  const checklists = Object.entries(props.task.checklists).sort(([a], [b]) =>
    a.localeCompare(b),
  );

  return (
    <CustomDndProvider>
      <div className="space-y-8">
        <div className="md:hidden">
          <Button loading={isMutating} onClick={handleAdd} block>
            {t("task.ui.addChecklist")}
          </Button>
        </div>
        {checklists.map(([id, checklist], i) => (
          <Checklist
            key={id}
            id={id}
            taskId={props.task.snapshotId}
            buildingId={props.task.buildingId}
            checklist={checklist}
            isEditable={props.isEditable}
            isFirst={i === 0}
            handleAddChecklist={handleAdd}
            isAddingChecklist={isMutating}
            commentsByTaskId={commentsByTaskId}
          />
        ))}
      </div>
    </CustomDndProvider>
  );
}

const genItemInputId = (id: string) => `checklist-item-${id}`;

type ChecklistProps = {
  id: string;
  taskId: string;
  buildingId: number;
  checklist: TaskChecklist;
  isEditable: boolean;
  isFirst: boolean;
  handleAddChecklist: () => void;
  isAddingChecklist: boolean;
  commentsByTaskId: ReturnType<typeof useCommentsByTaskId>;
};

function Checklist(props: ChecklistProps) {
  const t = useTranslations();
  const { trigger: updateChecklist } = useUpdateTaskChecklist();
  const runOrTimeout = useRunOrTimeout();

  const total = props.checklist.items.length;
  const checked = props.checklist.items.filter((task) => task.checked).length;
  const progress = (checked / total) * 100;

  function handleDelete() {
    updateChecklist({
      where: {
        taskId: props.taskId,
        checklistId: props.id,
      },
      data: null,
    });
  }

  async function handleAddItem() {
    const id = genId();

    await updateChecklist({
      where: {
        taskId: props.taskId,
        checklistId: props.id,
      },
      data: {
        ...props.checklist,
        items: [
          ...props.checklist.items,
          {
            id,
            checked: false,
            content: "",
          },
        ],
      },
    });

    runOrTimeout(2_000, () => {
      const input = document.getElementById(genItemInputId(id));

      if (input) {
        input.focus();
      }

      return !!input;
    });
  }

  if (props.checklist.items.length === 0 && !props.isEditable) {
    return null;
  }

  return (
    <div>
      <div className="flex items-center justify-between h-8 gap-2">
        <div className="flex items-center flex-1 gap-4">
          <CheckSquareIcon className="w-6 h-6" />
          <Input
            defaultValue={props.checklist.title}
            readOnly={!props.isEditable}
            placeholder={t("task.ui.checklist")}
            onBlur={(event) =>
              updateChecklist({
                where: {
                  taskId: props.taskId,
                  checklistId: props.id,
                },
                data: {
                  ...props.checklist,
                  title: event.target.value,
                },
              })
            }
          />
        </div>
        {props.isEditable && (
          <div className="flex items-center gap-2">
            <Button onClick={handleDelete}>{t("ui.delete")}</Button>
            {props.isFirst && (
              <div className="items-center hidden gap-2 md:flex">
                <Divider type="vertical" />
                <Button
                  loading={props.isAddingChecklist}
                  onClick={props.handleAddChecklist}
                >
                  {t("task.ui.addChecklist")}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {props.checklist.items.length > 0 && (
        <div className="flex items-center gap-2 my-4 ml-10">
          <div className="w-full h-3 rounded-md bg-card">
            <div
              className="h-full transition-all rounded-md bg-properate"
              style={{ width: `${progress}%` }}
            />
          </div>
          <div>{Math.round(progress)}%</div>
        </div>
      )}
      <div className="flex flex-col gap-6 ml-10">
        <ChecklistItemCommentPopoverContextProvider>
          {props.checklist.items.map((task, index) => (
            <ChecklistItem
              key={task.id}
              taskId={props.taskId}
              buildingId={props.buildingId}
              checklist={props.checklist}
              checklistId={props.id}
              id={task.id}
              index={index}
              content={task.content}
              checked={task.checked}
              isEditable={props.isEditable}
              onPressEnter={handleAddItem}
              comments={props.commentsByTaskId[task.id] ?? emptyArr}
            />
          ))}
        </ChecklistItemCommentPopoverContextProvider>
      </div>
      {props.isEditable && (
        <div className="mt-4 ml-10">
          <Button onClick={handleAddItem}>{t("ui.addItem")}</Button>
        </div>
      )}
    </div>
  );
}

type ChecklistItemProps = {
  taskId: string;
  buildingId: number;
  checklist: TaskChecklist;
  checklistId: string;
  id: string;
  index: number;
  content: string;
  checked: boolean;
  isEditable: boolean;
  onPressEnter: () => void;
  comments: Task["comments"];
};

type DragItem = {
  id: string;
  index: number;
};

function ChecklistItem(props: ChecklistItemProps) {
  const t = useTranslations();
  const user = usePossiblyUser();
  const { trigger: updateChecklist } = useUpdateTaskChecklist();
  const { data: userEmails = [] } = useBuildingContacts(props.buildingId);
  const [collapseComments, setCollapseComments] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const taskType = useTaskType();
  const updateTask = useUpdateTask();
  const [comment, setComment] = useState("");
  const commentPopover = useContext(CommentPopoverContext);

  const [{ handlerId }, drop] = CustomDndProvider.useDrop<
    DragItem,
    void,
    { handlerId: string | symbol | null }
  >({
    accept: "checklist-item",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!containerRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = containerRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset() ?? { y: 0 };

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      const dragItem = props.checklist.items[dragIndex];
      const hoverItem = props.checklist.items[hoverIndex];

      updateChecklist({
        where: {
          taskId: props.taskId,
          checklistId: props.checklistId,
        },
        data: {
          ...props.checklist,
          items: props.checklist.items.map((item, index) => {
            if (index === dragIndex) {
              return hoverItem;
            }

            if (index === hoverIndex) {
              return dragItem;
            }

            return item;
          }),
        },
      });

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = CustomDndProvider.useDrag({
    type: "checklist-item",
    item: () => ({ id: props.id, index: props.index }) as DragItem,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  drop(containerRef);
  drag(dragRef);

  const handleComment = async () => {
    updateTask.trigger(
      {
        where: {
          taskId: props.taskId,
        },
        data: {
          comments: {
            push: {
              data: {
                taskItemId: props.id,
                content: `${comment}`,
              },
            },
          },
        },
      },
      {
        onSuccess() {
          setComment("");
          commentPopover.setOpenId("");
        },
      },
    );
  };

  async function handleDeleteComment(activityId: string) {
    await updateTask.trigger({
      where: {
        taskId: props.taskId,
      },
      data: {
        comments: {
          remove: {
            where: {
              id: activityId,
            },
          },
        },
      },
    });
  }

  return (
    <div className="space-y-2">
      <div
        key={props.id}
        ref={containerRef}
        className={cn("flex items-center gap-2 p-2 -m-2 rounded-md", {
          "opacity-50": isDragging,
        })}
      >
        <Checkbox
          checked={props.checked}
          onChange={(event) =>
            updateChecklist({
              where: {
                taskId: props.taskId,
                checklistId: props.checklistId,
              },
              data: {
                ...props.checklist,
                items: props.checklist.items.map((task, i) =>
                  i === props.index
                    ? { ...task, checked: event.target.checked }
                    : task,
                ),
              },
            })
          }
        />
        <Input
          id={genItemInputId(props.id)}
          defaultValue={props.content}
          readOnly={!props.isEditable}
          className="flex-1"
          onBlur={(event) =>
            updateChecklist({
              where: {
                taskId: props.taskId,
                checklistId: props.checklistId,
              },
              data: {
                ...props.checklist,
                items: props.checklist.items.map((task, i) =>
                  i === props.index
                    ? { ...task, content: event.target.value }
                    : task,
                ),
              },
            })
          }
          onKeyDownCapture={(event) => {
            if (event.key === "Enter" && event.currentTarget.value !== "") {
              event.preventDefault();
              props.onPressEnter();
            }
          }}
        />
        {taskType === "tasks" && (
          <>
            {props.comments.length > 0 && (
              <Button
                type="text"
                onClick={() => setCollapseComments(!collapseComments)}
                icon={
                  collapseComments ? (
                    <ChevronUpIcon className="w-4 h-4 mt-1" />
                  ) : (
                    <ChevronDownIcon className="w-4 h-4 mt-1" />
                  )
                }
              />
            )}
            <Popover
              open={commentPopover.openId === props.id}
              content={
                <div className="flex flex-col gap-1 just">
                  <RichTextEditor
                    value={comment}
                    onChange={setComment}
                    placeholder={t("task.placeholder.comment")}
                    mentionables={userEmails}
                  />
                  <div className="flex justify-end gap-2">
                    <Button onClick={() => commentPopover.setOpenId("")}>
                      {t("ui.cancel")}
                    </Button>
                    <Button
                      type="primary"
                      loading={updateTask.isMutating}
                      onClick={handleComment}
                    >
                      {t("ui.add")}
                    </Button>
                  </div>
                </div>
              }
            >
              <Button
                type="text"
                onClick={() => {
                  commentPopover.setOpenId(props.id);

                  let attempts = 0;

                  const interval = setInterval(() => {
                    const editor: HTMLElement | null =
                      window.document.querySelector(".ant-popover .ql-editor");

                    if (editor) {
                      editor.focus();
                      clearInterval(interval);
                    } else {
                      if (attempts === 5) {
                        clearInterval(interval);
                      }

                      attempts++;
                    }
                  }, 200);
                }}
                icon={<MessageSquareIcon className="w-4 h-4 mt-1" />}
              />
            </Popover>
          </>
        )}
        {props.isEditable && (
          <>
            <Popconfirm
              title={t("ui.confirm")}
              onConfirm={() =>
                updateChecklist({
                  where: {
                    taskId: props.taskId,
                    checklistId: props.checklistId,
                  },
                  data: {
                    ...props.checklist,
                    items: props.checklist.items.filter(
                      (_, i) => i !== props.index,
                    ),
                  },
                })
              }
            >
              <Button
                type="text"
                icon={<TrashIcon className="w-4 h-4 mt-1" />}
              />
            </Popconfirm>
            <Button
              type="text"
              ref={dragRef}
              data-handler-id={handlerId}
              icon={<GripVerticalIcon className="w-4 h-4 mt-1" />}
            />
          </>
        )}
      </div>
      {props.comments.length > 0 && !collapseComments && (
        <div className="ml-6 space-y-2">
          {props.comments.map((comment) => (
            <ActivityMetadata
              key={comment.id}
              author={comment.author}
              createdAt={comment.createdAt}
              actions={
                comment.author === user.email && (
                  <Popconfirm
                    trigger="click"
                    title={t("task.ui.deleteComment")}
                    description={t("task.ui.deleteCommentConfirmation")}
                    onConfirm={() => handleDeleteComment(comment.id)}
                  >
                    <div className="text-red-500 flex items-center gap-2 cursor-pointer">
                      <TrashIcon className="w-3 h-3 font-red-500" />
                      {t("ui.delete")}
                    </div>
                  </Popconfirm>
                )
              }
            >
              <Comment description={comment.content} />
            </ActivityMetadata>
          ))}
        </div>
      )}
    </div>
  );
}

const CommentPopoverContext = createContext({
  openId: "",
  setOpenId: (_id: string) => {
    return;
  },
});

function ChecklistItemCommentPopoverContextProvider(props: PropsWithChildren) {
  const [openId, setOpenId] = useState("");

  return (
    <CommentPopoverContext.Provider
      value={{
        openId,
        setOpenId: (id) => setOpenId(id),
      }}
    >
      {props.children}
    </CommentPopoverContext.Provider>
  );
}

function useCommentsByTaskId(comments: Task["comments"]) {
  return useMemo(() => {
    const result: Record<string, Task["comments"]> = {};

    for (const comment of comments) {
      const [, taskId] = comment.taskItemId
        ? [null, comment.taskItemId]
        : /task:\s*([^\s}]+)/.exec(comment.content) ?? [];

      if (taskId) {
        if (!result[taskId]) {
          result[taskId] = [];
        }

        result[taskId].push(comment);
      }
    }

    return result;
  }, [comments]);
}

const emptyArr: void[] = []; // stable array reference
