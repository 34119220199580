import { Timeseries } from "@cognite/sdk";
import { DetailsList, DetailHighlight } from "./elements";

interface Props {
  timeseries: Timeseries;
}

export function TimeseriesDetails({ timeseries }: Props) {
  return (
    <DetailsList>
      {Object.entries({
        "External ID": timeseries.externalId,
        ...timeseries.metadata,
      }).map(([key, value]) => (
        <li key={key}>
          <DetailHighlight>{key}</DetailHighlight>
          &nbsp;-&nbsp;{value}
        </li>
      ))}
    </DetailsList>
  );
}
