import axios from "axios";
import { App } from "antd";
import { MessageKey, useTranslations } from "@properate/translations";
import { FUNCTION_URL_BASE } from "@/utils/helpers";
/* global Autodesk */
// Get token from server
let lastAccessData: any = null;

export const getToken = async () => {
  const { data } =
    lastAccessData && lastAccessData.expiresAt > Date.now()
      ? { data: lastAccessData }
      : await axios.get(FUNCTION_URL_BASE + "getAccessToken");
  lastAccessData = { ...data, expiresAt: Date.now() + data.expires_in * 1000 };
  return data.access_token;
};

export const triggerGetUrn = async (fileId: string) => {
  return await axios.get(FUNCTION_URL_BASE + "triggerGetUrn?fileId=" + fileId);
};

export const useInitializeViewer = () => {
  const t = useTranslations();
  const { notification: notificationInstance } = App.useApp();

  return async (urn: string, showUpload: VoidFunction) => {
    const token = await getToken();

    const viewerOptions = {
      env: "AutodeskProduction",
      accessToken: token,
      api: "derivativeV2",
    };

    const viewerContainer = document.getElementById("viewerContainer");
    // @ts-ignore
    const viewer = new Autodesk.Viewing.Private.GuiViewer3D(
      viewerContainer,
      {},
    );

    // @ts-ignore
    Autodesk.Viewing.Initializer(viewerOptions, () => {
      viewer.start();
      // @ts-ignore
      Autodesk.Viewing.Document.load(
        `urn:${urn}`,
        (doc: any) => {
          const defaultModel = doc.getRoot().getDefaultGeometry();
          viewer.loadDocumentNode(doc, defaultModel);
        },
        (error: any) => {
          if (error === 7) {
            showUpload();
          } else {
            notificationInstance.error({
              message: t(`file-preview.error`),
              description: t(
                `file-preview.autodesk-errors-by-code.${error.toString()}` as MessageKey,
              ),
            });
          }
        },
      );
    });
  };
};
