import { useMemo } from "react";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useOrganizationModulesByName } from "@/services/organization";
import { allAccessModules } from "@/features/organizationAdmin";
import { useRootAssets } from "@/hooks/useRootAssets";

export const useBuildingModulesAccess = () => {
  const currentBuilding = useCurrentBuilding();
  const { data, isLoading, error } = useOrganizationModulesByName(
    currentBuilding?.metadata?.owner || "",
  );

  return useMemo(
    () => ({
      isLoaded: !isLoading || !!error,
      accessModules: isLoading
        ? []
        : (currentBuilding?.dataSetId &&
            data?.buildings?.[currentBuilding?.dataSetId]?.modules) ||
          data?.modules ||
          allAccessModules,
    }),
    [currentBuilding, data, isLoading, error],
  );
};
export const useOrganizationModulesAccess = () => {
  const rootAssets = useRootAssets();
  const organizations = Object.keys(
    Object.groupBy(rootAssets, (item) => item.metadata?.owner || ""),
  );
  const hasOnlyOneOrganizationAccess = organizations.length === 1;
  const { data: organizationData, isLoading } = useOrganizationModulesByName(
    organizations[0] || "",
  );

  return {
    accessModules:
      (hasOnlyOneOrganizationAccess && organizationData) || isLoading
        ? organizationData?.modules
        : allAccessModules,
    isLoading,
  };
};
