import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { Badge, Tooltip } from "antd";
import { CalculationFlow } from "../types";

interface Props {
  calculationFlow: CalculationFlow;
}

export const StatusBadge = ({ calculationFlow }: Props) => {
  const t = useTranslations();
  const notifications = calculationFlow.notifications;
  const errorCount = notifications.filter((n) => n.type === "error").length;
  const warningCount = notifications.filter((n) => n.type === "warning").length;
  const infoCount = notifications.filter((n) => n.type === "info").length;
  const isActive = calculationFlow.frequency > 0;

  const status = !isActive
    ? "default"
    : errorCount > 0
    ? "error"
    : warningCount > 0
    ? "warning"
    : infoCount > 0
    ? "processing"
    : "success";

  const title =
    notifications.length > 0 ? (
      <ul>
        {notifications.map((notification, index) => (
          <li key={index}>
            {`* ${dayjs(notification.timestamp).format("DD. MMMM HH:mm")}: ${
              notification.type
            }: ${notification.message} `}
          </li>
        ))}
      </ul>
    ) : calculationFlow.last_run === null ? (
      t("calculation-flow.table.never-calculated")
    ) : (
      t("calculation-flow.table.last-calculation", {
        days: dayjs
          .duration(dayjs().diff(dayjs(calculationFlow.last_run)))
          .humanize(),
      })
    );

  return (
    <Tooltip title={title}>
      <Badge status={status} />
    </Tooltip>
  );
};
