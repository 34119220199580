"use client";

import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import useSWRMutation from "swr/mutation";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { notification } from "antd";
import { useTranslations } from "@properate/translations";
import { browserFirestore, getCurrentUser } from "@properate/firebase";
import { genId } from "@properate/ui";
import { TaskFile } from "../schemas";

export type UploadTaskFileArg = {
  where: {
    taskId: string;
  };
  data: {
    files: File[];
  };
};

export function useUploadTaskFile() {
  const t = useTranslations();

  return useSWRMutation(
    "uploadTaskFile",
    async (_, extra: { arg: UploadTaskFileArg }) => {
      const user = await getCurrentUser();
      const docRef = doc(browserFirestore, "tasks", extra.arg.where.taskId);
      const files: TaskFile[] = [];
      const storage = getStorage();

      for (const file of extra.arg.data.files) {
        const id = genId();
        const refPath = `tasks/${extra.arg.where.taskId}/${id}/${file.name}`;
        const fileRef = ref(storage, refPath);

        await uploadBytes(fileRef, file, {
          contentType: file.type,
        });

        const url = await getDownloadURL(fileRef);

        files.push({
          id,
          ref: refPath,
          uid: user.uid,
          author: user.email,
          name: file.name,
          size: file.size,
          type: file.type,
          url,
          thumbnailUrl: file.type.startsWith("image/") ? url : null,
          cogniteFileId: null,
          createdAt: Date.now(),
        });
      }

      await updateDoc(docRef, {
        files: arrayUnion(...files),
        lastModifiedBy: user.email,
        updatedAt: Date.now(),
      });
    },
    {
      onError(err) {
        console.error(err);

        notification.error({
          message: t("task.file.upload.error"),
        });
      },
    },
  );
}
