import { useState, MouseEvent } from "react";
import { TimeSpan } from "@properate/common";
import { localPoint } from "@visx/event";
import { getGraphMargins } from "../utils";

const graphMargins = getGraphMargins("simple");

export function usePanning(
  timeSpan: TimeSpan,
  zoomedTimeSpan: TimeSpan,
  width: number,
  onChangeZoomedTimeSpan?: (value: TimeSpan) => unknown,
) {
  const [isDragging, setIsDragging] = useState(false);
  const [lastMovementX, setLastMovementX] = useState<number | null>(null);

  function getLocalX(event: MouseEvent<SVGElement>) {
    const point = localPoint(event);
    return point ? point.x - graphMargins.left : null;
  }

  function handleMouseDown(event: MouseEvent<SVGElement>) {
    if (onChangeZoomedTimeSpan) {
      const x = getLocalX(event);
      if (x) {
        setLastMovementX(x);
        setIsDragging(true);
      }
    }
  }

  // Look at https://www.tradingview.com/chart/ - move screen, but don't fetch new timeseries before movement is done
  function handleMouseMove(event: MouseEvent<SVGElement>) {
    if (isDragging && onChangeZoomedTimeSpan) {
      const x = getLocalX(event);
      if (x && typeof lastMovementX === "number") {
        const movementPercentage = -(x - lastMovementX) / width;
        const zoomedTimeSpanDiff = zoomedTimeSpan[1] - zoomedTimeSpan[0];
        const movementInMs = Math.round(
          zoomedTimeSpanDiff * movementPercentage,
        );
        const zoomedTimeSpanUpdated: TimeSpan = [
          Math.max(zoomedTimeSpan[0] + movementInMs, timeSpan[0]),
          Math.min(zoomedTimeSpan[1] + movementInMs, timeSpan[1]),
        ];
        const zoomedTimeSpanUpdatedDiff =
          zoomedTimeSpanUpdated[1] - zoomedTimeSpanUpdated[0];
        if (zoomedTimeSpanUpdatedDiff === zoomedTimeSpanDiff) {
          onChangeZoomedTimeSpan(zoomedTimeSpanUpdated);
          setLastMovementX(x);
        }
      }
    }
  }

  function handleMouseUp() {
    if (onChangeZoomedTimeSpan) {
      setIsDragging(false);
      setLastMovementX(null);
    }
  }

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isDragging,
  };
}
