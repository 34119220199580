import { useState } from "react";
import { Timeseries } from "@cognite/sdk";
import { Button, App } from "antd";
import {
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons/lib";
import { sortString } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useCogniteClient } from "../../../context/CogniteClientContext";
import { mutateBuildingSpec } from "../../../services/buildingSpec";
import { TimeseriesSelectionModalData } from "./types";

type DataType = {
  key: number;
  systemCode: string;
  subBuilding: string;
  intakeTime?: number;
  intake?: number;
  hideIntake: boolean;
  outtakeTime?: number;
  outtake?: number;
  hideOuttake: boolean;
  externalIdOuttake: string;
  externalIdIntake: string;
  intakeOuttake: string;
  intakeName: string;
  outtakeName: string;
};

export type IntakeOuttakeType = {
  id: number;
  externalId?: string;
  name?: string;
  description?: string;
  hideIntake?: boolean;
  hideOuttake?: boolean;
  intakeOuttake?: string;
};

export type IndoorClimateSettingsProps = {
  data?: DataType[];
  id: number;
  intakeSeries: IntakeOuttakeType[];
  outtakeSeries: IntakeOuttakeType[];
  tableHeight: number;
};

export const getSubBuildingAndSystemCode = (externalId: string) => {
  const type = /\w+=[n.\dK]+/.exec(externalId);
  if (type && type.length > 0) {
    return type[0];
  }
  return "";
};

const IndoorClimateSettings = ({
  id,
  data,
  intakeSeries,
  outtakeSeries,
}: IndoorClimateSettingsProps) => {
  const t = useTranslations();
  const { client } = useCogniteClient();
  const { notification } = App.useApp();
  const [timeseriesSelectionModalData, setTimeseriesSelectionModalData] =
    useState<TimeseriesSelectionModalData | null>(null);

  const getTimeserie = async (id: number, intakeOuttake: string) => {
    const ts = await client.timeseries.retrieve([{ id }]);

    return {
      id: ts[0].id,
      externalId: ts[0].externalId,
      name: ts[0].name,
      description: ts[0].description,
      intakeOuttake,
    };
  };

  const updateIntake = async (timeseries: Timeseries) => {
    const filterIntakeSeries = intakeSeries
      ?.filter(
        (ts) => ts.externalId !== timeseriesSelectionModalData!.externalId,
      )
      .map((ts) => ({
        id: ts.id,
        externalId: ts.externalId,
        name: ts.name,
        description: ts.description,
        hideIntake: ts.hideIntake,
        intakeOuttake: ts.intakeOuttake,
      }));

    const ts = await getTimeserie(timeseries.id, "intake");

    if (filterIntakeSeries && ts) {
      await mutateBuildingSpec(id, {
        indoorClimate: {
          intake: [...filterIntakeSeries, ts],
        },
      });
    }
  };

  const addIntake = async (timeseries: Timeseries) => {
    const ts = await getTimeserie(timeseries.id, "intake");

    if (intakeSeries && ts) {
      await mutateBuildingSpec(id, {
        indoorClimate: {
          intake: [...intakeSeries, ts],
        },
      });
    }
  };

  const addOuttake = async (timeseries: Timeseries) => {
    const ts = await getTimeserie(timeseries.id, "outtake");

    if (outtakeSeries && ts) {
      await mutateBuildingSpec(id, {
        indoorClimate: {
          outtake: [...outtakeSeries, ts],
        },
      });
    }
  };

  const updateOuttake = async (asset: Timeseries) => {
    const filterOuttakeSeries = outtakeSeries
      ?.filter(
        (ts) => ts.externalId !== timeseriesSelectionModalData!.externalId,
      )
      .map((ts) => ({
        id: ts.id,
        externalId: ts.externalId,
        name: ts.name,
        description: ts.description,
        hideOuttake: ts.hideOuttake,
        intakeOuttake: ts.intakeOuttake,
      }));

    const ts = await getTimeserie(asset.id, "outtake");

    if (filterOuttakeSeries && ts) {
      await mutateBuildingSpec(id, {
        indoorClimate: {
          outtake: [...filterOuttakeSeries, ts],
        },
      });
    }
  };

  const showHideIntake = async (intake: DataType) => {
    const index = intakeSeries?.findIndex(
      (ts) => ts.externalId === intake.externalIdIntake,
    );

    intakeSeries[index].hideIntake =
      typeof intake?.hideIntake === "undefined" ? false : !intake.hideIntake;

    if (intakeSeries) {
      await mutateBuildingSpec(id, {
        indoorClimate: {
          intake: intakeSeries,
        },
      });
    }
  };

  const showHideOuttake = async (outtake: DataType) => {
    const index =
      outtakeSeries &&
      outtakeSeries?.findIndex(
        (ts) => ts.externalId === outtake.externalIdOuttake,
      );
    outtakeSeries[index].hideOuttake =
      typeof outtake?.hideOuttake === "undefined"
        ? false
        : !outtake.hideOuttake;

    if (outtakeSeries) {
      await mutateBuildingSpec(id, {
        indoorClimate: {
          outtake: outtakeSeries,
        },
      });
    }
  };

  const getExistingSystemCode = ({ externalId }: Timeseries) => {
    const chosenTimeseriesBuildingAndSystemCode = externalId
      ? getSubBuildingAndSystemCode(externalId)
      : "";

    if (timeseriesSelectionModalData!.type === "intake") {
      return intakeSeries
        .map((ts) => getSubBuildingAndSystemCode(ts.externalId!))
        .find((subBuildingAndSystemCode) => {
          return (
            chosenTimeseriesBuildingAndSystemCode === subBuildingAndSystemCode
          );
        });
    }
    return outtakeSeries
      .map((ts) => getSubBuildingAndSystemCode(ts.externalId!))
      .find((subBuildingAndSystemCode) => {
        return (
          chosenTimeseriesBuildingAndSystemCode === subBuildingAndSystemCode
        );
      });
  };

  return (
    <>
      <TableWithoutDefaultSort
        dataSource={data}
        pagination={false}
        columns={[
          {
            title: t("dashboard.widgets.indoor-climate.sub-building"),
            dataIndex: "subBuilding",
            showSorterTooltip: true,
            width: 100,
            sorter: (
              { subBuilding: subBuildingOne },
              { subBuilding: subBuildingTwo },
            ) => sortString(subBuildingOne, subBuildingTwo),
          },
          {
            title: t("dashboard.widgets.indoor-climate.system-code"),
            dataIndex: "systemCode",
            width: 120,
            defaultSortOrder: "ascend",
            sorter: (
              { systemCode: systemCodeOne },
              { systemCode: systemCodeTwo },
            ) => sortString(systemCodeOne, systemCodeTwo),
          },
          {
            title: t("dashboard.widgets.indoor-climate.supply-air"),
            dataIndex: "intakeName",
            width: 300,
            render: (_, rowData) =>
              rowData.hideIntake ? "" : rowData.intakeName,
            sorter: (
              { intakeName: intakeNameOne },
              { intakeName: intakeNameTwo },
            ) => sortString(intakeNameOne, intakeNameTwo),
          },
          {
            dataIndex: "actionIntake",
            render: (_, rowData) => (
              <div style={{ display: "flex" }}>
                <Button
                  icon={<PlusOrEditButton edit={!!rowData.hideIntake} />}
                  onClick={() =>
                    setTimeseriesSelectionModalData({
                      id: rowData.key,
                      externalId: rowData.externalIdIntake,
                      type: "intake",
                      systemCode: rowData.systemCode,
                      state: "edit",
                    })
                  }
                />
                {rowData.externalIdIntake && (
                  <Button
                    icon={<MinusButton hide={!!rowData.hideIntake} />}
                    onClick={() => showHideIntake(rowData)}
                  />
                )}
              </div>
            ),
          },
          {
            title: t("dashboard.widgets.indoor-climate.deduction"),
            dataIndex: "outtakeName",
            render: (_, rowData) =>
              rowData.hideOuttake ? "" : rowData.outtakeName,
            sorter: (
              { outtakeName: outtakeNameOne },
              { outtakeName: outtakeNameTwo },
            ) => sortString(outtakeNameOne, outtakeNameTwo),
          },
          {
            dataIndex: "actionOuttake",
            render: (_, rowData) => (
              <div style={{ display: "flex" }}>
                <Button
                  icon={<PlusOrEditButton edit={!!rowData.hideOuttake} />}
                  onClick={() =>
                    setTimeseriesSelectionModalData({
                      id: rowData.key,
                      externalId: rowData.externalIdOuttake,
                      type: "outtake",
                      systemCode: rowData.systemCode,
                      state: "edit",
                    })
                  }
                />
                {rowData.externalIdOuttake && (
                  <Button
                    icon={<MinusButton hide={!!rowData.hideOuttake} />}
                    onClick={() => showHideOuttake(rowData)}
                  />
                )}
              </div>
            ),
          },
        ]}
        footer={() => (
          <>
            <Button
              style={{ marginLeft: 520 }}
              icon={<PlusOutlined />}
              onClick={() =>
                setTimeseriesSelectionModalData({
                  externalId: undefined,
                  type: "intake",
                  state: "add",
                })
              }
            />
            <Button
              style={{ marginLeft: 310 }}
              icon={<PlusOutlined />}
              onClick={() =>
                setTimeseriesSelectionModalData({
                  externalId: undefined,
                  type: "outtake",
                  state: "add",
                })
              }
            />
          </>
        )}
      />

      <TimeseriesSelectionModal
        open={Boolean(timeseriesSelectionModalData)}
        initialFilters={{
          category: "ventilation",
          system: timeseriesSelectionModalData?.systemCode,
        }}
        hiddenFilters={["building"]}
        selectedIds={
          timeseriesSelectionModalData?.id
            ? [timeseriesSelectionModalData.id]
            : []
        }
        onOk={async ([timeseries]) => {
          if (timeseriesSelectionModalData!.state === "edit") {
            if (timeseriesSelectionModalData!.type === "intake") {
              await updateIntake(timeseries);
              return setTimeseriesSelectionModalData(null);
            }
            await updateOuttake(timeseries);
            setTimeseriesSelectionModalData(null);
            return;
          }
          const existingSystemCode = getExistingSystemCode(timeseries);
          if (existingSystemCode) {
            return notification.info({
              message: t(
                "dashboard.widgets.indoor-climate.system-code-already-exists",
                {
                  code: existingSystemCode,
                },
              ),
            });
          }
          if (timeseriesSelectionModalData!.type === "intake") {
            await addIntake(timeseries);
            return setTimeseriesSelectionModalData(null);
          }
          await addOuttake(timeseries);
          setTimeseriesSelectionModalData(null);
        }}
        onHide={() => setTimeseriesSelectionModalData(null)}
        max={1}
      />
    </>
  );
};

const MinusButton = ({ hide }: any) => {
  return hide ? <></> : <MinusOutlined />;
};
const PlusOrEditButton = ({ edit }: any) => {
  return edit ? <PlusOutlined /> : <EditOutlined />;
};

export default IndoorClimateSettings;
