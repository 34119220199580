import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "antd";
import { Asset } from "@cognite/sdk";
import { useHotkeys } from "react-hotkeys-hook";
import { BuildingSpec } from "@properate/common";
import { arrayMoveImmutable } from "array-move";
import { useGetBuildingTasks } from "@properate/task-manager";
import { useTranslations } from "@properate/translations";
import { useKPIsByBuilding } from "@/services/kpis";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import {
  AlarmKPI,
  CertificationKPI,
  DashboardWidget,
  KPI,
} from "@/utils/types";
import { KPIModal } from "@/components/KPIModal/KPIModal";
import { CompactContent } from "@/components/CompactContent";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { BuildingArealCalculatedArea } from "@/eepApi";
import { DashboardWidgets } from "./DashboardWidgets";
import { HintKpis } from "./elements";
import { SortableKPIList } from "./sortable/SortableKPIList";

interface Props {
  building: Asset;
  buildingSpec: BuildingSpec;
  buildingAreal: BuildingArealCalculatedArea;
}

const DEFAULT_KPIS = [
  "motion",
  "consumptionLast30Days",
  "maxHourlyConsumptionLast30Days",
  "maxHourlyConsumptionLast7Days",
  "percentageEnergyUseInWorkHoursLastWeek",
  "meanTemperature",
  "maxCo2",
  "temperature",
  "maxWindSpeed",
  "relativeHumidity",
] as const;

const DEFAULT_WIDGETS: DashboardWidget[] = [
  { type: "standard", id: "energyUseThisWeek" },
  { type: "standard", id: "weather" },
  { type: "standard", id: "accumulatedEnergyUse" },
  { type: "standard", id: "alarms" },
  { type: "standard", id: "indoorClimate" },
];

export function Dashboard({ building, buildingSpec, buildingAreal }: Props) {
  const t = useTranslations();

  const [isKPIModalOpen, setIsKPIModalOpen] = useState(false);
  useHotkeys("Control+Shift+s", () => setIsKPIModalOpen(!isKPIModalOpen));

  const kpis = useKPIsByBuilding(building.id);
  const { data: preferences } = useUserSettings();

  const buildingSettings = preferences?.buildings?.[building.id];
  const [selectedKPIsSorted, setSelectedKPIsSorted] = useState(
    buildingSettings?.dashboard &&
      Array.isArray(buildingSettings.dashboard.kpis)
      ? buildingSettings.dashboard.kpis
      : DEFAULT_KPIS,
  );
  const selectedWidgets =
    buildingSettings?.dashboard &&
    Array.isArray(buildingSettings.dashboard.widgets)
      ? buildingSettings.dashboard.widgets
      : DEFAULT_WIDGETS;

  useEffect(() => {
    if (
      buildingSettings?.dashboard &&
      Array.isArray(buildingSettings.dashboard.kpis)
    ) {
      setSelectedKPIsSorted(buildingSettings.dashboard.kpis);
    }
  }, [buildingSettings?.dashboard, setSelectedKPIsSorted]);

  async function updateSelectedValues(selectedValues: KPI[]) {
    setSelectedKPIsSorted(
      selectedValues as Exclude<KPI, AlarmKPI | CertificationKPI>[],
    );
    await mutateUserSettings({
      buildings: {
        [building.id]: {
          dashboard: {
            kpis: selectedValues as Exclude<KPI, AlarmKPI | CertificationKPI>[],
          },
        },
      },
    });
  }

  async function handleSortEnd({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) {
    document.body.style.cursor = "auto";
    if (oldIndex === newIndex) {
      return;
    }
    const selectedKPIsReSorted = arrayMoveImmutable(
      selectedKPIsSorted,
      oldIndex,
      newIndex,
    );
    setSelectedKPIsSorted(selectedKPIsReSorted);
    await mutateUserSettings({
      buildings: {
        [building.id]: {
          dashboard: {
            kpis: selectedKPIsReSorted,
          },
        },
      },
    });
  }

  const buildingIds = useMemo(() => [building.id], [building.id]);
  const tasks = useGetBuildingTasks(buildingIds);

  const openTasks = useMemo(
    () =>
      tasks.data
        ? tasks.data.filter((task) => task.status === "InProgress").length
        : 0,
    [tasks],
  );

  const overdueTasks = useMemo(
    () =>
      tasks.data
        ? tasks.data.filter(
            (task) =>
              task.status === "InProgress" &&
              task.dueDate &&
              task.dueDate < Number(new Date()),
          ).length
        : 0,
    [tasks],
  );

  const allKPIValues: Record<string, number> = {
    ...kpis?.energy,
    ...kpis?.indoor_climate,
    ...kpis?.activity,
    ...kpis?.weather,
    ...kpis?.water,
    openTasks,
    overdueTasks,
  };

  return (
    <CompactContent>
      <KPIModal
        open={isKPIModalOpen}
        title={t("dashboard.choice-for-building-screen")}
        disabledKPIGroups={["alarms", "certifications"]}
        onHide={() => setIsKPIModalOpen(false)}
        selectedValues={selectedKPIsSorted}
        onUpdateSelectedValues={updateSelectedValues}
      />
      <SpinnerWithDelay isLoading={preferences === undefined} centerInParent>
        <>
          <Row gutter={[24, 24]} justify="space-between">
            <Col span={24}>
              <div style={{ overflow: "hidden" }}>
                {selectedKPIsSorted.length > 0 ? (
                  <>
                    <SortableKPIList
                      axis="xy"
                      allKPIValues={allKPIValues}
                      selectedKPIs={selectedKPIsSorted}
                      usableFloorArea={buildingAreal?.area}
                      onSortEnd={handleSortEnd}
                      onSortStart={() => {
                        document.body.style.cursor = "grabbing";
                      }}
                      useDragHandle
                      onClickSettingsButton={() => setIsKPIModalOpen(true)}
                    />
                  </>
                ) : (
                  <Row gutter={[8, 8]} justify="start">
                    <Col xxl={4} xl={4} lg={3} md={3} sm={3}>
                      <HintKpis onClick={() => setIsKPIModalOpen(true)}>
                        <p>{t("dashboard.kpi-select-description")}</p>
                      </HintKpis>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          <DashboardWidgets
            selectedWidgets={selectedWidgets}
            buildingSpec={buildingSpec}
          />
        </>
      </SpinnerWithDelay>
    </CompactContent>
  );
}
