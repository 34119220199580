export const getSystemTranslationKey = (system: string) => {
  const systemNumber = parseInt(
    system.substring(
      0,
      system.indexOf(".") > 0 ? system.indexOf(".") : system.length,
    ),
  );
  const isSystemNumber = !isNaN(systemNumber);

  return isSystemNumber
    ? `tfm.system.buildingPartNumber.${systemNumber.toString()}`
    : "";
};
