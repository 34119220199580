import { AxisBottom, AxisLeft, AxisTop } from "@visx/axis";
import { GridColumns } from "@visx/grid";
import { useContext, useMemo } from "react";
import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import { localPoint } from "@visx/event";
import { Point } from "@visx/brush/lib/types";
import dayjs from "@properate/dayjs";
import { timeDay, timeMonth, timeYear } from "d3-time";

import { TickFormatter } from "@visx/axis/lib/types";
import { ThemeContext } from "styled-components";
import { AlarmWithSnapshotId } from "@properate/common";
import { AlarmIncident } from "./utils";

export default function AlarmChart({
  data,
  width,
  yMax,
  margin,
  xScale,
  yScale,
  hideBottomAxis = false,
  hideLeftAxis = false,
  top,
  left,
  showTooltip,
  hideTooltip,
  onSelect,
  children,
  alarms,
  showMarkers,
}: {
  data: AlarmIncident[];
  xScale: any;
  yScale: any;
  width: number;
  yMax: number;
  margin: { top: number; right: number; bottom: number; left: number };
  hideBottomAxis?: boolean;
  hideLeftAxis?: boolean;
  top?: number;
  left?: number;
  showTooltip?: Function;
  hideTooltip?: VoidFunction;
  onSelect?: (event: AlarmIncident) => void;
  children?: React.ReactNode;
  alarms?: AlarmWithSnapshotId[];
  showMarkers?: boolean;
}) {
  const themeContext = useContext(ThemeContext);
  // Initialize some variables
  const axisColor = themeContext.neutral4;
  const axisBottomTickLabelProps = {
    fill: axisColor,
  };
  const axisLeftTickLabelProps = {
    fill: axisColor,
  };

  const monthsTickLabelProps = {
    fill: themeContext.neutral4,
  };
  const alarmsInUse: any = useMemo(() => {
    return (
      data &&
      alarms &&
      [
        ...data
          .reduce((prev, current) => {
            prev.add(current.alarmId);
            return prev;
          }, new Set<string>())
          .values(),
      ].reduce(
        (prev, alarm) => {
          const a = alarms?.find((a) => a.snapshotId === alarm);
          prev[alarm] = a?.timeseries?.color;
          return prev;
        },
        {} as Record<string, string | undefined>,
      )
    );
  }, [data, alarms]);

  const tickFormat: TickFormatter<Date> = (v: Date) => {
    const m = dayjs(v);
    if (m.dayOfYear() === 1) {
      return m.format("YYYY");
    } else if (m.date() === 1) {
      return m.format("D. MMM");
    }
    return m.format("D.");
  };

  const gridTicks = useMemo(() => {
    const months = timeMonth.range(xScale.domain()[0], xScale.domain()[1], 1);
    const years = timeYear.range(xScale.domain()[0], xScale.domain()[1], 1);
    const halfMonth = timeDay
      .range(xScale.domain()[0], xScale.domain()[1], 1)
      .filter((d) => [1, 15].includes(dayjs(d).date()));
    const days = timeDay.range(xScale.domain()[0], xScale.domain()[1], 1);
    return days.length <= 15
      ? days
      : months.length <= 3
      ? halfMonth
      : months.length <= 12
      ? months
      : years;
  }, [xScale]);

  return (
    <Group left={left || margin.left} top={top || margin.top}>
      {data.map((event) => {
        const barY = yScale(event.alarmId);
        const barX = xScale(event.date);
        const barHeight = yScale.bandwidth();
        const barWidth = xScale(event.end || new Date()) - barX;

        return (
          <Bar
            key={`bar-${event.snapshotId}`}
            x={barX}
            y={barY}
            width={barWidth}
            height={barHeight}
            fill={event.color}
            onMouseMove={
              showTooltip
                ? (e) => {
                    const { x, y } = localPoint(e) as Point;

                    showTooltip({
                      tooltipLeft: x,
                      tooltipTop: y,
                      tooltipData: `${event.name}\n${event.description}`,
                    });
                  }
                : undefined
            }
            onMouseOut={hideTooltip}
            onClick={
              onSelect
                ? () => {
                    onSelect(event);
                  }
                : undefined
            }
          />
        );
      })}
      {showMarkers && (
        <g>
          <GridColumns
            scale={xScale}
            stroke={"#ccc"}
            height={yMax}
            tickValues={gridTicks}
          />
          <AxisTop
            top={15}
            scale={xScale}
            tickFormat={tickFormat as any}
            stroke={axisColor}
            hideAxisLine
            hideTicks
            tickLabelProps={monthsTickLabelProps}
            tickValues={gridTicks}
          />
        </g>
      )}
      {!hideBottomAxis && (
        <AxisBottom
          top={yMax}
          scale={xScale}
          numTicks={width > 520 ? 10 : 5}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={axisBottomTickLabelProps}
        />
      )}
      {!hideLeftAxis && (
        <AxisLeft
          left={24}
          scale={yScale}
          numTicks={alarmsInUse ? Object.values(alarmsInUse).length : 0}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={axisLeftTickLabelProps}
        />
      )}
      {children}
    </Group>
  );
}
