import { AlarmRuleTypeName } from "@properate/common";
import { useFormContext } from "@/pages/alarms/details/FormContext/hooks";
import {
  AlarmDetailsPageId,
  useAlarmPageId,
} from "@/pages/alarms/details/hooks/createNewAlarmHooks";
import {
  FormContextEntries,
  FormContextEntry,
} from "@/pages/alarms/details/FormContext/types";
import { AlarmSettingsFormFields } from "@/pages/alarms/details/AlarmSettingsPage/types";
import { AlarmNotificationFormFields } from "@/pages/alarms/details/AlarmNotificationsPage/types";
import { getFormFieldsForAlarmRuleType } from "@/pages/alarms/details/AlarmRuleTypes";

export function getSettingsFieldIds(entries: Readonly<FormContextEntries>) {
  const selectedType = entries[
    AlarmSettingsFormFields.Type
  ] as FormContextEntry<AlarmRuleTypeName>;

  const alarmRuleTypeFormFields = getFormFieldsForAlarmRuleType(
    {},
    selectedType?.value,
  );
  const typeFieldIds = Object.keys(alarmRuleTypeFormFields);

  return [
    AlarmSettingsFormFields.Name,
    AlarmSettingsFormFields.Instruction,
    AlarmSettingsFormFields.Type,
    ...typeFieldIds,
  ];
}

export function getNotificationsFieldIds() {
  return [
    AlarmNotificationFormFields.AlwaysActive,
    AlarmNotificationFormFields.ActivePeriods,
    AlarmNotificationFormFields.ActivePeriodDay,
    AlarmNotificationFormFields.ActivePeriodTimeRange,
    AlarmNotificationFormFields.InactivePeriods,
    AlarmNotificationFormFields.InactiveDateRange,
    AlarmNotificationFormFields.HolidayAlarms,
    AlarmNotificationFormFields.Level,
    AlarmNotificationFormFields.RequiresReceipt,
    AlarmNotificationFormFields.Recipients,
    AlarmNotificationFormFields.RecipientName,
    AlarmNotificationFormFields.RecipientContact,
    AlarmNotificationFormFields.NotificationPause,
  ];
}

export function getDeviationFieldIds() {
  return [];
}

export function useFormFieldIdsForPageId(
  alarmPageId?: AlarmDetailsPageId,
): string[] {
  const { entries } = useFormContext();
  if (!alarmPageId) {
    return [];
  }

  switch (alarmPageId) {
    case AlarmDetailsPageId.Settings:
      return getSettingsFieldIds(entries);
    case AlarmDetailsPageId.Notifications:
      return getNotificationsFieldIds();
    case AlarmDetailsPageId.Deviations:
      return getDeviationFieldIds();
  }
}

export function useFormFieldIdsForCurrentPage(): string[] {
  const alarmPageId = useAlarmPageId();
  return useFormFieldIdsForPageId(alarmPageId);
}
