import dayjs from "@properate/dayjs";
import { TimeSpanOption } from "@/features/analysis";
import { TranslationFunction } from "@/utils/types";

const now = Date.now();
const currentYearStart = dayjs(now).startOf("year");
const currentYearStartLabel = currentYearStart.year();
const previousYearStart = dayjs(now).startOf("year").subtract(1, "year");
const previousYearStartLabel = previousYearStart.year();
const previousQuarterStart = dayjs(now)
  .subtract(1, "quarter")
  .startOf("quarter");
const antecedentQuarterStart = previousQuarterStart.subtract(1, "quarter");

export function getTimeSpanOptions(
  t: TranslationFunction,
  notBefore?: number,
): TimeSpanOption[] {
  const previousQuarterLabel = t("common.timespan-options.previous-quarter", {
    quarter: previousQuarterStart.quarter(),
    year: previousQuarterStart.year(),
  });

  const antecedentQuarterLabel = t(
    "common.timespan-options.antecedent-quarter",
    {
      quarter: antecedentQuarterStart.quarter(),
      year: antecedentQuarterStart.year(),
    },
  );

  const timeSpanOptions: TimeSpanOption[] = [
    {
      label: t("common.timespan-options.last-365-days"),
      value: () => [dayjs().subtract(1, "year").startOf("day"), dayjs()],
    },
    {
      label: currentYearStartLabel.toString(),
      value: () => [dayjs().startOf("year"), dayjs().endOf("day")],
    },
    {
      label: previousYearStartLabel.toString(),
      value: () => [
        dayjs(now).startOf("year").subtract(1, "year"),
        dayjs(now).startOf("year").subtract(1, "year").endOf("year"),
      ],
    },
    {
      label: t("common.timespan-options.last-6-months"),
      value: () => [dayjs().subtract(6, "month").startOf("day"), dayjs()],
    },
    {
      label: t("common.timespan-options.last-3-months"),
      value: () => [dayjs().subtract(3, "month").startOf("day"), dayjs()],
    },
    {
      label: previousQuarterLabel,
      value: () => [
        dayjs(now).subtract(1, "quarter").startOf("quarter"),
        dayjs(now).subtract(1, "quarter").endOf("quarter"),
      ],
    },
    {
      label: antecedentQuarterLabel,
      value: () => [
        previousQuarterStart.subtract(1, "quarter"),
        previousQuarterStart.subtract(1, "quarter").endOf("quarter"),
      ],
    },
    {
      label: t("common.timespan-options.last-month"),
      value: () => [dayjs().subtract(1, "month").startOf("day"), dayjs()],
    },
    {
      label: t("common.timespan-options.previous-month"),
      value: () => [
        dayjs(now).subtract(1, "month").startOf("month"),
        dayjs(now).subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: t("common.timespan-options.last-2-weeks"),
      value: () => [dayjs().subtract(2, "week").startOf("day"), dayjs()],
    },
    {
      label: t("common.timespan-options.last-week"),
      value: () => [dayjs().subtract(1, "week").startOf("day"), dayjs()],
    },
    {
      label: t("common.timespan-options.last-24-hours"),
      value: () => [dayjs().subtract(24, "hours"), dayjs()],
    },
    {
      label: t("common.timespan-options.today"),
      value: () => [dayjs().startOf("day"), dayjs()],
    },
    {
      label: t("common.timespan-options.last-12-hours"),
      value: () => [dayjs().subtract(12, "hours"), dayjs()],
    },
    {
      label: t("common.timespan-options.last-6-hours"),
      value: () => [dayjs().subtract(6, "hours"), dayjs()],
    },
    {
      label: t("common.timespan-options.last-3-hours"),
      value: () => [dayjs().subtract(3, "hours"), dayjs()],
    },
    {
      label: t("common.timespan-options.last-hour"),
      value: () => [dayjs().subtract(1, "hours"), dayjs()],
    },
  ];
  if (notBefore) {
    return timeSpanOptions.filter(({ value }) => {
      const timeSpanOptionStart =
        typeof value === "function" ? value()[0] : value[0];
      return dayjs(timeSpanOptionStart).isSameOrAfter(notBefore);
    });
  }
  return timeSpanOptions;
}
