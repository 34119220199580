import { Empty } from "antd";
import { useTranslations } from "@properate/translations";
import { AlarmRuleTypeName } from "@properate/common";
import { useFormValue } from "../FormContext/hooks";
import { alarmRuleTypes } from "../AlarmRuleTypes";
import { AlarmSettingsFormFields } from "./types";

export function AlarmSettingsFieldsForType() {
  const [type] = useFormValue<AlarmRuleTypeName>(AlarmSettingsFormFields.Type);
  const t = useTranslations();

  const Component = alarmRuleTypes[type]?.formComponent;

  if (!Component) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("alarm-details.settings-page.type-fields.empty")}
        />
      </div>
    );
  }
  return Component;
}
