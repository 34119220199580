import { AuthedUser, useUser } from "@properate/auth";
import dayjs from "@properate/dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { sortString, sortEnum, sortDate, sortNumber } from "@properate/ui";
import {
  useGetBuildingTasks,
  Task,
  ChecklistsProgressDisplay,
} from "@properate/task-manager";
import { useTranslations, MessageKey } from "@properate/translations";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { LineClamp } from "@/components/LineClamp";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useBuildingsSpecMap } from "@/services/buildingSpec";
import { useTaskManagerTableFiltersContext } from "../TaskManagerTableFilters/TaskManagerTableContext";
import { TaskManagerPriority } from "./TaskManagerPriorityColumn";
import { TaskManagerStatusColumn } from "./TaskManagerStatusColumn";
import { TaskManagerDueDateColumn } from "./TaskManagerDueDateColumn";
import { GreyOutOld, Spacer } from "./elements";

export const filters = [
  {
    value: "inProgress",
    check: (task: Task) => task.status === "InProgress",
  },
  {
    value: "expiresSoon",
    check: (task: Task) =>
      task.status !== "Completed" &&
      (task.dueDate
        ? dayjs(task.dueDate).isBefore(dayjs().add(5, "day"))
        : false),
  },
  {
    value: "expired",
    check: (task: Task) =>
      task.status !== "Completed" && dayjs(task.dueDate).isBefore(dayjs()),
  },
  {
    value: "completed",
    check: (task: Task) => task.status === "Completed",
  },
  {
    value: "mine",
    check: (task: Task, user: AuthedUser) => task.author === user.email,
  },
  {
    value: "all",
    check: () => true,
  },
] as const;

type Props = {
  buildingIds: number[];
  onRowClick?: (task: Task) => void;
  tableHeight: number;
};

export function TaskManagerTable({
  buildingIds,
  onRowClick,
  tableHeight,
}: Props) {
  const t = useTranslations();
  const { data: tasks = [], isLoading } = useGetBuildingTasks(buildingIds);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>();
  const { search, filter } = useTaskManagerTableFiltersContext();
  const user = useUser();
  const buildingsRecord = useBuildingsSpecMap();

  useEffect(() => {
    if (tasks && buildingsRecord && Object.keys(buildingsRecord).length > 0) {
      setFilteredTasks(
        tasks.filter((task) => {
          if (search) {
            const searchTerm = search.toLowerCase();

            const hasSearchTerm =
              task.type?.toLowerCase().includes(searchTerm) ||
              task.title?.toLowerCase().includes(searchTerm) ||
              (task.subtype &&
                t(`tfm.system.buildingPartNumber.${task.subtype}` as MessageKey)
                  ?.toLowerCase()
                  .includes(searchTerm)) ||
              t(`task.enum.priority.${task.priority}` as MessageKey)
                ?.toLowerCase()
                .includes(searchTerm) ||
              t(`task.enum.status.${task.status}` as MessageKey)
                ?.toLowerCase()
                .includes(searchTerm) ||
              task.assignee?.toLowerCase().includes(searchTerm) ||
              (task.dueDate
                ? dayjs(task.dueDate)
                    .endOf("day")
                    .fromNow()
                    .includes(searchTerm)
                : false) ||
              buildingIds.length <= 1 ||
              (buildingsRecord &&
                buildingsRecord[task.buildingId] &&
                buildingsRecord[task.buildingId].name
                  .toLowerCase()
                  .includes(searchTerm));
            if (!hasSearchTerm) {
              return false;
            }
          }
          if (filter.includes("mine") && task.author !== user.email) {
            return false;
          }

          const checks = filters.filter(
            ({ value }) =>
              value !== "mine" && value !== "all" && filter.includes(value),
          );

          return checks.length
            ? checks.some(({ check }) => check(task, user))
            : true;
        }),
      );
    }
  }, [search, tasks, filter, user, buildingIds, buildingsRecord, t]);

  const searchWords = search.split(" ");

  const columns: ColumnsType<Task> = [
    {
      title: t("task.ui.task"),
      key: "title",
      width: 300,
      sorter: (a, b) => sortString(a.title, b.title),
      render: (_, task) => (
        <GreyOutOld past={task.status === "Completed"}>
          <Spacer>
            <SearchHighlighter search={searchWords} text={task.title} />
            <div>
              <ChecklistsProgressDisplay
                checklists={task.checklists}
                taskId={task.snapshotId}
              />
            </div>
          </Spacer>
        </GreyOutOld>
      ),
    },
    {
      title: t("task.ui.type"),
      key: "type",
      width: 200,
      sorter: (a, b) => sortString(a.type, b.type),
      render: (_, task) =>
        task.type && (
          <GreyOutOld past={task.status === "Completed"}>
            <SearchHighlighter search={searchWords} text={task.type} />
          </GreyOutOld>
        ),
    },
    {
      title: t("task.ui.resource"),
      key: "subtype",
      width: 200,
      sorter: (a, b) => sortNumber(a.subtype, b.subtype),
      render: (_, task) => (
        <GreyOutOld past={task.status === "Completed"}>
          <SearchHighlighter
            search={searchWords}
            text={[
              task.subtype,
              t(`tfm.system.buildingPartNumber.${task.subtype}` as MessageKey),
            ].join(" ")}
          />
        </GreyOutOld>
      ),
    },
    {
      title: t("task.ui.priority"),
      key: "priority",
      width: 100,
      sorter: (a, b) =>
        sortEnum(a.priority, b.priority, ["Low", "Medium", "High"]),
      render: (_, task) => (
        <TaskManagerPriority
          status={task.status}
          priority={task.priority}
          searchWords={searchWords}
        />
      ),
    },
    {
      title: t("task.ui.status"),
      key: "status",
      width: 80,
      sorter: (a, b) =>
        sortEnum(a.status, b.status, ["In progress", "Completed"]),
      render: (_, task) => (
        <TaskManagerStatusColumn
          status={task.status}
          searchWords={searchWords}
        />
      ),
    },
    {
      title: t("task.ui.assignee"),
      key: "assignee",
      width: 200,
      sorter: (a, b) => sortString(a.assignee, b.assignee),
      render: (_, task) =>
        task.assignee && (
          <GreyOutOld past={task.status === "Completed"}>
            <SearchHighlighter search={searchWords} text={task.assignee} />
          </GreyOutOld>
        ),
    },
    {
      title: t("task.ui.deadline"),
      key: "endTime",
      width: 130,
      sorter: (a, b) =>
        sortDate(
          a.dueDate ? new Date(a.dueDate) : undefined,
          b.dueDate ? new Date(b.dueDate) : undefined,
        ),
      render: (_, task) => (
        <TaskManagerDueDateColumn
          status={task.status}
          dueDate={task.dueDate}
          searchWords={searchWords}
        />
      ),
    },
  ];

  if (buildingIds.length > 1) {
    columns.splice(0, 0, {
      title: t("task.ui.address"),
      key: "buildingAddress",
      width: 180,
      sorter: (a, b) =>
        sortString(
          buildingsRecord?.[a.buildingId]?.name,
          buildingsRecord?.[b.buildingId]?.name,
        ),
      render: (_, task) => {
        return (
          buildingsRecord?.[task.buildingId]?.name && (
            <Link
              to={`/asset/${task.buildingId}/building`}
              onClick={(task) => task.stopPropagation()}
            >
              <LineClamp title={buildingsRecord?.[task.buildingId].name}>
                <SearchHighlighter
                  search={search}
                  text={buildingsRecord?.[task.buildingId].name}
                />
              </LineClamp>
            </Link>
          )
        );
      },
    });
  }

  return (
    <TableWithoutDefaultSort
      rowKey="snapshotId"
      dataSource={filteredTasks}
      columns={columns}
      pagination={false}
      loading={isLoading}
      scroll={{
        y: tableHeight,
      }}
      rowIsHoverable={onRowClick !== undefined}
      data-testid="task-manager-table"
      onRow={
        onRowClick
          ? (task) => ({
              onClick: () => {
                onRowClick(task);
              },
            })
          : undefined
      }
    />
  );
}
