import { useEffect } from "react";
import { Step } from "react-joyride";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useHelp } from "@/context/HelpContext";
import { JoyrideWrapper } from "@/components/JoyrideWrapper/JoyrideWrapper";
import { useOnboarding } from "@/utils/onboarding";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { useBuildingSpec } from "@/services/buildingSpec";
import { Dashboard } from "@/features/dashboard";
import { useCurrentBuildingAreal } from "@/hooks/useCurrentBuildingAreal";

export function BuildingOverview() {
  const t = useTranslations();
  useBuildingPageTitle(t("dashboard.overview"));
  const currentBuilding = useCurrentBuilding();
  const { setHelp } = useHelp();
  const STEPS: Step[] = [
    {
      placement: "top",
      target: ".hint-settings",
      content: t("dashboard.onboarding.step-1"),
    },
    {
      placement: "top",
      target: ".kpis-dashboard",
      content: t("dashboard.onboarding.step-2"),
    },
  ];
  const [onboardingSteps, setOnboardingStepCompleted] = useOnboarding(STEPS);
  const user = useUser();

  useEffect(() => {
    setHelp({
      title: t("dashboard.building-overview"),
      content: (
        <>
          <p>
            {t.rich("dashboard.help-text", {
              p: (content) => <p>{content}</p>,
            })}
          </p>
        </>
      ),
    });
  }, [setHelp, t]);

  const buildingSpec = useBuildingSpec(currentBuilding.id);
  const currentBuildingAreal = useCurrentBuildingAreal(currentBuilding.id);

  return buildingSpec ? (
    <>
      <Dashboard
        building={currentBuilding}
        buildingSpec={buildingSpec}
        buildingAreal={currentBuildingAreal}
      />
      {user.isAdmin && onboardingSteps.length > 0 && (
        <JoyrideWrapper
          content={onboardingSteps[0]}
          onClose={setOnboardingStepCompleted}
        />
      )}
    </>
  ) : null;
}
