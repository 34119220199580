import { Line } from "@visx/shape";
import { formatUnit } from "@properate/common";
import { useTheme } from "styled-components";
import { formatNonScientific } from "@/utils/format";
import type { ScaleLinear } from "d3-scale";

interface Props {
  x1: number;
  x2: number;
  textX: number;
  value: number;
  scale: ScaleLinear<number, number, never>;
  unit?: string;
  size: "small" | "medium" | "large";
}

export function GaugeBarValue({
  x1,
  x2,
  textX,
  value,
  scale,
  unit,
  size,
}: Props) {
  const valueLineThickness = 2;
  const valueLineY = scale(value) - valueLineThickness / 2;
  const theme = useTheme();

  return (
    <>
      <Line
        from={{ x: x1, y: valueLineY }}
        to={{ x: x2, y: valueLineY }}
        strokeWidth={valueLineThickness}
        stroke={theme.neutral4}
      />
      <text
        fill={theme.neutral4}
        x={textX}
        y={valueLineY}
        alignmentBaseline="central"
        fontSize={size === "large" ? 16 : size === "medium" ? 13 : 10}
      >
        {`${formatNonScientific(value)}${formatUnit(unit)}`}
      </text>
    </>
  );
}
