"use client";

import { useState } from "react";
import { Button } from "antd";
import { useTranslations } from "@properate/translations";
import { RichTextEditor } from "@properate/ui";
import { useUpdateTask } from "../../hooks/use-update-task";
import { useBuildingContacts } from "./hooks/useBuildingContacts";

type Props = {
  taskId: string;
  buildingId: number;
};

export function CommentInput(props: Props) {
  const t = useTranslations();
  const [comment, setComment] = useState("");
  const updateTask = useUpdateTask();
  const { data: userEmails = [] } = useBuildingContacts(props.buildingId);

  function handleAddComment() {
    updateTask.trigger(
      {
        where: {
          taskId: props.taskId,
        },
        data: {
          comments: {
            push: {
              data: {
                content: comment,
              },
            },
          },
        },
      },
      {
        onSuccess() {
          setComment("");
        },
      },
    );
  }

  function hasNoComment() {
    return (
      !comment ||
      // remove html tags and check if the comment is empty
      comment.replace(/<[^>]*>/g, "").trim().length === 0
    );
  }

  return (
    <RichTextEditor
      variant="simple"
      className="fixed bottom-0 left-0 right-0 z-50 border-0 border-t border-solid rounded-none shadow md:z-auto md:relative md:border md:rounded-md md:shadow-none"
      value={comment}
      onChange={setComment}
      placeholder={t("task.placeholder.comment")}
      mentionables={userEmails}
      toolbarActions={
        <Button
          disabled={hasNoComment()}
          loading={updateTask.isMutating}
          onClick={handleAddComment}
        >
          {t("ui.add")}
        </Button>
      }
    />
  );
}
