"use client";

import dayjs from "@properate/dayjs";
import { useEffect, useState } from "react";

type Props = {
  date: Date | string | number;
};

export function TimeAgo(props: Props) {
  const [display, setDisplay] = useState(() => dayjs(props.date).fromNow());

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplay(dayjs(props.date).fromNow());
    }, 1_000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [props.date]);

  return <time suppressHydrationWarning>{display}</time>;
}
