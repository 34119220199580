import { useEffect } from "react";
import { Divider } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useTranslations } from "@properate/translations";
import { CompactContent } from "@/components/CompactContent";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { mutateBuildingSpec, useBuildingSpec } from "@/services/buildingSpec";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { BuildingContactsAdminPanel } from "@/components/building-contacts";
import { BuildingCertificationsAdminPanel } from "@/components/building-certifications";
import { BuildingTenantsAdminPanel } from "@/components/building-tenants";
import UpdateInfo from "./UpdateInfo";

const BuildingSettings = () => {
  const t = useTranslations();

  useBuildingPageTitle(t("settings.title"));
  const currentBuildingId = useCurrentBuildingId();
  const building = useCurrentBuilding();
  const buildingSpec = useBuildingSpec(currentBuildingId);

  useEffect(() => {
    if (building && buildingSpec && !buildingSpec?.name) {
      mutateBuildingSpec(currentBuildingId, {
        name: building?.metadata?.Adresse + ", " + building?.metadata?.Poststed,
      });
    }
  }, [currentBuildingId, building, buildingSpec]);

  return (
    <>
      <PageHeader title={t("settings.title")} />
      <CompactContent>
        <div style={{ padding: "0 24px 24px 24px" }}>
          {buildingSpec && <UpdateInfo spec={buildingSpec} />}
        </div>
        <Divider />
        <BuildingContactsAdminPanel />
        <Divider />
        <BuildingTenantsAdminPanel />
        <Divider />
        <BuildingCertificationsAdminPanel />
      </CompactContent>
      {/* adds gap at the end of the page */}
      <footer style={{ height: 200 }} />
    </>
  );
};

export default BuildingSettings;
