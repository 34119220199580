import { Radio, Space } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { HeatMapAggregate } from "../types";
import { aggregateOptions } from "../utils";

interface Props {
  value: HeatMapAggregate;
  onChange: (value: HeatMapAggregate) => unknown;
}

export function HeatMapAggregateSelection({ value, onChange }: Props) {
  const t = useTranslations();

  return (
    <Space>
      <label htmlFor="aggregate">{t("analysis.heat-map.aggregate")}:</label>
      <Radio.Group
        id="aggregate"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        {aggregateOptions.map((aggregateOption) => (
          <Radio key={aggregateOption.value} value={aggregateOption.value}>
            {t(
              `analysis.heat-map.aggregate-options.${aggregateOption.value}` as MessageKey,
            )}
          </Radio>
        ))}
      </Radio.Group>
    </Space>
  );
}
