import { BuildingCertification } from "@properate/common";
import dayjs from "@properate/dayjs";

type Arg = Pick<BuildingCertification, "expiresAt">;

export function isExpired(certification: Arg) {
  return (
    !!certification.expiresAt &&
    dayjs(certification.expiresAt).isBefore(dayjs())
  );
}

export function isAboutToExpire(certification: Arg) {
  return (
    !isExpired(certification) &&
    dayjs(certification.expiresAt).isSameOrBefore(dayjs().add(3, "month"))
  );
}
