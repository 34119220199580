export interface Filter {
  buildingId: number;
  system: string | null;
  subBuilding: string | null;
  translatedLabels: string[];
}

export function buildFilter(filter: string[] | string | null, name: string) {
  if (Array.isArray(filter)) {
    return filter.map((value) => ` AND ${name} = "${value}"`).join("");
  }
  return typeof filter === "string" ? ` AND ${name} = "${filter}"` : "";
}

export function buildFilterQuery(filter: Filter, currentBuildingId: number) {
  return `buildingId = ${currentBuildingId}${buildFilter(
    filter.translatedLabels,
    "translatedLabels",
  )}${buildFilter(filter.system, "system")}${buildFilter(
    filter.subBuilding,
    "subBuilding",
  )}`;
}
