import { useEffect, useRef } from "react";
import { DatapointAggregates } from "@cognite/sdk";
import { convertElementPropsToUnit, isSpecialPctUnit } from "@properate/common";
import { useSetSidebarData } from "@properate/ui";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { useGetTimeseriesListWithData } from "@/hooks/useGetTimeseriesListWithData";
import { useWindowSize } from "@/hooks/useWindowSize";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { NotesSidebarValues } from "@/features/notes";
import { HeatMap, HeatMapAggregate, HeatMapViewMode } from "../types";
import { getTimeSpanFromViewMode } from "../utils";
import { HeatMapRect } from "./HeatMapRect";
import { HeatMapSettingsStyled } from "./elements";

interface Props {
  name: string;
  timeseriesId: number;
  referenceDate: number;
  aggregate: HeatMapAggregate;
  viewMode: HeatMapViewMode;
  showWeekNumbers: boolean;
  color: string;
  compact?: boolean;
  width?: number;
  height?: number;
  onEdit?: (value: Partial<HeatMap>) => unknown;
  isCurrentStartOfWeekMonthChecked?: boolean;
}

function convertDatapointsWithOverrideOrSpecialUnit(
  timeseriesData: DatapointAggregates,
  aggregate: HeatMapAggregate,
  overrideUnit?: string,
) {
  const from = timeseriesData.unit;
  const to = overrideUnit || "%";

  const datapoints = convertElementPropsToUnit(
    timeseriesData.datapoints,
    { from, to },
    [aggregate],
  );

  return { ...timeseriesData, datapoints, unit: to };
}

export function HeatMapComponent({
  name,
  timeseriesId,
  referenceDate,
  isCurrentStartOfWeekMonthChecked,
  aggregate,
  viewMode,
  showWeekNumbers,
  color,
  compact = false,
  width,
  height,
  onEdit,
}: Props) {
  const timeSpan = getTimeSpanFromViewMode(referenceDate, viewMode);
  const currentBuildingId = useCurrentBuildingId();
  const { overrideUnits } = useTimeseriesSettings(currentBuildingId);
  const { timeseriesListWithData, isLoading } =
    useGetTimeseriesListWithData<DatapointAggregates>(
      {
        items: [{ id: timeseriesId }],
        start: timeSpan[0],
        end: timeSpan[1],
        aggregates: [aggregate],
        granularity: viewMode === "week" ? "1h" : "24h",
      },
      {
        refresh: true,
      },
    );
  const timeseriesWithDatapointsAggregates = timeseriesListWithData.at(0);
  const externalId = timeseriesWithDatapointsAggregates?.externalId;

  const { setSidebarData } = useSetSidebarData<NotesSidebarValues>();

  useEffect(() => {
    setSidebarData({
      startTime: new Date(timeSpan[0]),
      endTime: new Date(timeSpan[1]),
    });
  }, [timeSpan[0], timeSpan[1]]); // eslint-disable-line react-hooks/exhaustive-deps

  const unit = timeseriesWithDatapointsAggregates?.unit;
  const overrideUnit = overrideUnits && overrideUnits[externalId!]?.unit;

  const isTimeseriesWithOverrideOrSpecialPctUnit =
    (typeof unit === "string" && isSpecialPctUnit(unit)) ||
    typeof overrideUnit === "string";

  const timeseriesWithData = isTimeseriesWithOverrideOrSpecialPctUnit
    ? convertDatapointsWithOverrideOrSpecialUnit(
        timeseriesWithDatapointsAggregates as DatapointAggregates,
        aggregate,
        overrideUnit,
      )
    : timeseriesWithDatapointsAggregates;

  const settingsRef = useRef<HTMLDivElement | null>(null);
  const { height: windowHeight, width: windowWidth } = useWindowSize();

  function getHeatmapCalendarHeight() {
    return (
      height ??
      windowHeight -
        PAGE_LAYOUT_HEIGHT -
        (settingsRef.current?.clientHeight ?? 0)
    );
  }

  function getHeatmapCalendarWidth() {
    return (
      width ??
      windowWidth -
        (document.querySelector(".ant-layout-sider-children")?.clientWidth ?? 0)
    );
  }

  return (
    <SpinnerWithDelay isLoading={isLoading} centerInParent>
      {typeof onEdit === "function" && (
        <div ref={settingsRef}>
          <HeatMapSettingsStyled
            name={name}
            aggregate={aggregate}
            color={color}
            referenceDate={referenceDate}
            isCurrentStartOfWeekMonthChecked={isCurrentStartOfWeekMonthChecked}
            showWeekNumbers={showWeekNumbers}
            timeseriesId={timeseriesId}
            viewMode={viewMode}
            onEdit={onEdit}
          />
        </div>
      )}
      {timeseriesWithData && (
        <HeatMapRect
          timeseriesWithData={timeseriesWithData}
          aggregate={aggregate}
          viewMode={viewMode}
          referenceDate={referenceDate}
          timeSpan={timeSpan}
          height={getHeatmapCalendarHeight()}
          width={getHeatmapCalendarWidth()}
          color={color}
          compact={compact}
          showWeekNumbers={showWeekNumbers}
        />
      )}
    </SpinnerWithDelay>
  );
}
