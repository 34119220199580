import { DeleteOutlined } from "@ant-design/icons";
import { BuildingCertification } from "@properate/common";
import dayjs from "@properate/dayjs";
import { Badge, Button, Popconfirm } from "antd";
import { sortDate, sortString } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { LineClamp } from "@/components/LineClamp";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useRemoveBuildingCertification } from "../hooks/useRemoveBuildingCertification";
import { BuildingCertificationBadge } from "./BuildingCertificationBadge";
import { FlexCell } from "./elements";
import { isAboutToExpire, isExpired } from "./utils/expiration";
import { getCertificationLabel } from "./utils/getCertificationLabel";

type Props = {
  buildingId: number;
  search: string;
  certifications: BuildingCertification[];
  scrollY?: number;
  onRowClick: (certification: BuildingCertification) => void;
};

export function BuildingCertificationsTable(props: Props) {
  const t = useTranslations();

  const removeCertification = useRemoveBuildingCertification();

  return (
    <TableWithoutDefaultSort
      rowKey="id"
      dataSource={props.certifications}
      pagination={false}
      scroll={{ y: props.scrollY }}
      onRow={(certification) => ({
        onClick: () => {
          props.onRowClick(certification);
        },
      })}
      columns={[
        {
          title: t("settings.certificates.table.certificate"),
          key: "certification",
          width: 300,
          sorter: (a, b) => sortString(a.type + a.level, b.type + b.level),
          render(_, certification) {
            const text = getCertificationLabel(certification);

            return (
              <FlexCell>
                <BuildingCertificationBadge
                  size={32}
                  certification={certification}
                />
                <LineClamp title={text}>
                  <SearchHighlighter search={props.search} text={text} />
                </LineClamp>
              </FlexCell>
            );
          },
        },
        {
          title: t("settings.certificates.table.notes"),
          key: "note",
          sorter: (a, b) => sortString(a.note, b.note),
          render(_, certification) {
            return (
              <LineClamp title={certification.note}>
                <SearchHighlighter
                  search={props.search}
                  text={certification.note}
                />
              </LineClamp>
            );
          },
        },
        {
          title: t("settings.certificates.table.awarded-date"),
          key: "awardedAt",
          width: 120,
          sorter: (a, b) => sortDate(a.awardedAt, b.awardedAt),
          render(_, certification) {
            const text = dayjs(certification.awardedAt).format("DD.MM.YYYY");

            return (
              <LineClamp title={text}>
                <SearchHighlighter search={props.search} text={text} />
              </LineClamp>
            );
          },
        },
        {
          title: t("settings.certificates.table.expiry-date"),
          key: "expiresAt",
          width: 120,
          sorter: (a, b) => sortDate(a.expiresAt, b.expiresAt),
          render(_, certification) {
            if (certification.expiresAt) {
              const text = dayjs(certification.expiresAt).format("DD.MM.YYYY");

              return (
                <FlexCell>
                  <LineClamp title={text}>
                    <SearchHighlighter search={props.search} text={text} />
                  </LineClamp>
                  {isExpired(certification) && <Badge status="error" />}
                  {isAboutToExpire(certification) && <Badge status="warning" />}
                </FlexCell>
              );
            }
          },
        },
        {
          title: "",
          width: 48,
          key: "action",
          render: (_, certification) => (
            <div onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                title={t("settings.certificates.are-you-sure-to-delete")}
                placement="left"
                okText={t("settings.certificates.yes")}
                onConfirm={() =>
                  removeCertification.trigger({
                    buildingId: props.buildingId,
                    certification,
                  })
                }
              >
                <Button type="link" icon={<DeleteOutlined />} />
              </Popconfirm>
            </div>
          ),
        },
      ]}
    />
  );
}
