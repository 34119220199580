import { Circle } from "@visx/shape";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { ScaleLinearNumeric } from "@/utils/types";
import { ScatterplotPoint } from "../../types";

interface Props {
  scatterplotPoint: ScatterplotPoint;
  scaleX: ScaleLinearNumeric;
  scaleY: ScaleLinearNumeric;
  color: string;
  isHighlighted: boolean;
  isActive: boolean;
  hasNote: boolean;
}

const getStrokeWidth = (isActive: boolean, hasNote: boolean) => {
  if (hasNote) {
    return 2;
  }
  if (isActive) {
    return 1;
  }
  return 0;
};

export function ScatterplotGraphDataCircle({
  scatterplotPoint,
  scaleX,
  scaleY,
  color,
  isHighlighted,
  isActive,
  hasNote,
}: Props) {
  const themeContext = useContext(ThemeContext);

  return (
    <Circle
      cx={scaleX(scatterplotPoint.x)}
      cy={scaleY(scatterplotPoint.y)}
      r={isHighlighted || isActive ? 6 : 4}
      strokeWidth={getStrokeWidth(isActive, hasNote)}
      stroke={hasNote ? themeContext.accent12 : "black"}
      fill={color}
      fillOpacity={isActive ? 1 : 0.5}
    />
  );
}
