import { useEffect, useMemo, useState } from "react";
import { Button, Col, Modal, Progress, Row, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons/lib";
import { UploadFile } from "antd/lib/upload/interface";
import { useUser } from "@properate/auth";
import { useActionData, useNavigate, useSubmit } from "react-router-dom";
import { RcFile } from "antd/es/upload/interface";
import { useTranslations } from "@properate/translations";

export const UploadButton = () => {
  const navigate = useNavigate();
  const actionData = useActionData() as EventTarget[];
  const submit = useSubmit();
  const user = useUser();
  const t = useTranslations();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const uploadedFileCount = useMemo(
    () =>
      fileList.reduce((acc, file) => {
        if (file.status === "done") {
          acc++;
        }
        return acc;
      }, 0),
    [fileList],
  );
  const listener = (event: Event) => {
    // update progress on file in filelist
    setFileList((fileList) =>
      fileList.map((file) => {
        if (file.name === (event as CustomEvent).detail.name) {
          return {
            ...file,
            percent:
              ((event as CustomEvent).detail.loaded /
                (event as CustomEvent).detail.total) *
              100,
            status:
              (event as CustomEvent).detail.loaded ==
              (event as CustomEvent).detail.total
                ? "done"
                : "uploading",
          };
        }
        return file;
      }),
    );
  };
  useEffect(() => {
    if (actionData) {
      actionData.forEach((data) => data.addEventListener("progress", listener));

      return () => {
        actionData.forEach((data) =>
          data.removeEventListener("progress", listener),
        );
      };
    }
  }, [actionData]);

  const handleClose = () => {
    setFileList([]);
    navigate(".");
  };

  const handleUpload = (newFileList: RcFile[]) => {
    setFileList(
      newFileList.map((file) => ({
        uid: crypto.randomUUID(),
        name: file.name,
        percent: 0,
        status: "uploading",
      })),
    );
    const formData = new FormData();
    newFileList.forEach((file) => {
      formData.append("file", file);
    });
    submit(formData, {
      method: "PUT",
      encType: "multipart/form-data",
    });
  };

  return (
    <>
      <Upload
        showUploadList={false}
        name="file"
        multiple
        fileList={[]}
        beforeUpload={(file, uploadingFileList) => {
          if (uploadingFileList.indexOf(file) === 0) {
            handleUpload(uploadingFileList);
          }
          return false;
        }}
      >
        <Button
          className="file-new"
          type="primary"
          icon={<PlusOutlined />}
          disabled={user.isViewer}
        >
          {t("files.upload")}
        </Button>
      </Upload>
      {fileList.length > 0 && (
        <Modal
          open
          onCancel={(event) => {
            event.stopPropagation();
            setFileList([]);
          }}
          footer={
            fileList.length === uploadedFileCount
              ? [
                  <Button key="close" onClick={handleClose}>
                    {t("files.close")}
                  </Button>,
                ]
              : null
          }
          closable={false}
          title={t("files.uploading")}
          destroyOnClose
        >
          <div
            id="scrollableDiv"
            style={{
              maxHeight: 200,
              overflow: "auto",
            }}
          >
            {fileList.map((file: any) => (
              <Row key={file.uid} gutter={[12, 12]}>
                <Col
                  span={12}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {file.name}
                </Col>
                <Col span={12}>
                  <Progress percent={file.percent} showInfo={false} />
                </Col>
              </Row>
            ))}
          </div>
          <Row>
            <Col span={24} style={{ marginTop: 20 }}>
              {fileList.length === uploadedFileCount ? (
                <>
                  {t("files.files-uploaded", {
                    filesCount: uploadedFileCount,
                  })}
                </>
              ) : (
                t("files.files-status-uploaded", {
                  uploadedCount: uploadedFileCount,
                  totalCount: fileList.length,
                })
              )}
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};
