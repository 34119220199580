import { FunctionComponent } from "react";
import { Form, Select } from "antd";
import { useTranslations } from "@properate/translations";
import { useRootAssets } from "@/hooks/useRootAssets";

interface Props {
  buildingId: number;
  onChange: (buildingId: number) => void;
}

export const BuildingSelect: FunctionComponent<Props> = ({
  buildingId,
  onChange,
}) => {
  const t = useTranslations();
  const rootAssets = useRootAssets();

  const buildingOptions = rootAssets.map((asset) => {
    return {
      value: asset.id,
      label: `${asset.metadata?.owner}, ${asset.metadata?.Adresse}, ${asset.metadata?.Postnummer} ${asset.metadata?.Poststed}`,
    };
  });

  const onBuildingChange = (buildingId: number) => {
    onChange(buildingId);
  };

  return (
    <Form.Item label={t("calculation-flow.building")}>
      <Select
        value={buildingId}
        showSearch
        placeholder={t("calculation-flow.select-building")}
        optionFilterProp="children"
        onChange={onBuildingChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={buildingOptions}
      />
    </Form.Item>
  );
};
