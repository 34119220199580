"use client";

import { useTranslations } from "@properate/translations";
import dayjs from "@properate/dayjs";
import { Task } from "../../../schemas";

type TaskChange = {
  field: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  oldValue: any;
  newValue: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */
};

export function useChangeDescription() {
  const t = useTranslations();

  const FieldTranslations = {
    assetId: t("task.field.components"),
    tfm: "TFM",
    type: "Type",
    subtype: "Subtype",
    components: t("task.field.components"),
    description: t("task.field.description"),
    author: t("task.field.author"),
    assignee: t("task.field.assignee"),
    participants: t("task.field.participants"),
    title: t("task.field.title"),
    status: t("task.field.status"),
    checklists: t("task.field.checklists"),
    priority: t("task.field.priority"),
    visibility: t("task.field.visibility"),
    startDate: t("task.field.startDate"),
    dueDate: t("task.field.dueDate"),
    files: t("task.field.files"),
    building: t("task.field.building"),
    followers: t("task.field.followers"),
    comments: t("task.field.comments"),
    component: t("task.field.component"),
  };

  const ValueTranslations = {
    Medium: t("task.enum.priority.Medium"),
    High: t("task.enum.priority.High"),
    Low: t("task.enum.priority.Low"),
    InProgress: t("task.enum.status.InProgress"),
    Completed: t("task.enum.status.Completed"),
  };

  function getChangeDescriptionImpl(change: TaskChange, task: Task) {
    if (change.field === "title") {
      return t("task.change.titleChanged");
    }

    if (change.field === "description") {
      return t("task.change.descriptionChanged");
    }

    if (change.field === "dueDate") {
      return `${t("task.change.deadlineChangedTo")} ${dayjs(
        change.newValue,
      ).format("DD.MM.YYYY")}`;
    }

    if (change.field === "checklist") {
      for (const item of change.newValue) {
        for (const oldItem of change.oldValue) {
          if (item.id === oldItem.id && item.checked !== oldItem.checked) {
            const op =
              item.checked === true
                ? t("task.change.checked")
                : t("task.change.unmarked");
            return `${op} ${item.content}`;
          }
        }
      }

      return t("task.change.checklistChanged");
    }

    if (change.field === "files") {
      if (change.newValue !== null) {
        const file = task.files.find((f) => f.id === change.newValue.id);

        if (file) {
          return (
            <>
              {t("task.change.attached")} &quot;{change.newValue.name}&quot;
              {file.type.startsWith("image/") &&
                typeof file.thumbnailUrl === "string" &&
                file.thumbnailUrl !== "" && (
                  <a href={file.url} rel="noopener noreferrer" target="_blank">
                    {/* Firebase Storage supports streaming images and Next.js does not */}
                    <img
                      src={file.thumbnailUrl}
                      alt={file.name}
                      style={{ maxWidth: "100%" }}
                    />
                  </a>
                )}
              {file.type.startsWith("video/") && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video controls poster={file.thumbnailUrl ?? undefined}>
                  <source src={file.url} type="video/mp4" />
                </video>
              )}
            </>
          );
        }

        return `${t("task.change.attached")} "${change.newValue.name}"`;
      }

      if (change.oldValue !== undefined) {
        return `${t("task.change.file")} "${change.oldValue.name}" ${t(
          "task.change.deleted",
        )}`;
      }
    }

    const field =
      change.field in FieldTranslations
        ? FieldTranslations[change.field as keyof typeof FieldTranslations]
        : change.field;

    const value =
      change.newValue in ValueTranslations
        ? ValueTranslations[change.newValue as keyof typeof ValueTranslations]
        : change.newValue;

    const uppercasedField = field.charAt(0).toUpperCase() + field.slice(1);

    return typeof value === "string"
      ? `${uppercasedField} ${t(
          "task.change.changedTo",
        )} ${value.toLowerCase()}`
      : `${uppercasedField} ${t("task.change.removed")}`;
  }

  function getChangeDescription(
    change: {
      changes: Record<string, { from: unknown; to: unknown }>;
    },
    task: Task,
  ) {
    try {
      const [[key, { from, to }]] = Object.entries(change.changes);
      return getChangeDescriptionImpl(
        {
          field: key,
          newValue: to,
          oldValue: from,
        },
        task,
      );
    } catch (error) {
      console.error(error);

      return t("task.change.unknownChange");
    }
  }

  return {
    getChangeDescription,
  };
}
