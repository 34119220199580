import { Link } from "react-router-dom";
import { Button, Result } from "antd";
import { useTranslations } from "@properate/translations";

interface Props {
  status: 403 | 404;
}

export function ErrorResult({ status }: Props) {
  const t = useTranslations();

  const title =
    status === 403 ? t("pages.error.403.title") : t("pages.notFound.title");
  const subTitle =
    status === 403
      ? t("pages.error.403.description")
      : t("pages.notFound.description");
  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        <Button type="primary">
          <Link to="/">{t("pages.returnToAllBuildings")}</Link>
        </Button>
      }
    />
  );
}
