import { BuildingTenant, BuildingTenantWithId } from "@properate/common";
import { Button, Checkbox, Form, Input, Modal, DatePicker } from "antd";
import { parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { useAddBuildingTenant } from "../hooks/useAddBuildingTenant";
import { useUpdateBuildingTenant } from "../hooks/useUpdateBuildingTenant";
import { useRemoveBuildingTenant } from "../hooks/useRemoveBuildingTenant";
import type { BaseSelectRef } from "rc-select";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  buildingId: number;
  tenants: BuildingTenantWithId[];
  tenant: BuildingTenantWithId | null;
};

type FormValues = {
  businessName: string;
  contactName: string;
  email: string;
  phone: string;
  tenancy: [Dayjs, Dayjs];
  area: string;
  note: string;
};

export function BuildingTenantModal(props: Props) {
  const t = useTranslations();

  const addTenant = useAddBuildingTenant();
  const updateTenant = useUpdateBuildingTenant();
  const removeTenant = useRemoveBuildingTenant();
  const [keepCreating, setKeepCreating] = useState(false);
  const typeFieldRef = useRef<BaseSelectRef>(null);
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (props.isVisible) {
      typeFieldRef.current?.focus();
    }
  }, [props.isVisible]);

  function handleSubmit(formValues: FormValues) {
    const values: BuildingTenant = {
      businessName: formValues.businessName,
      contactName: formValues.contactName,
      email: formValues.email,
      phone: formValues.phone,
      tenancyStart: formValues.tenancy[0].toDate(),
      tenancyEnd: formValues.tenancy[1].toDate(),
      area: formValues.area ? parseFloat(formValues.area).toFixed(2) : "",
      note: formValues.note,
    };

    if (props.tenant) {
      updateTenant.trigger({
        buildingId: props.buildingId,
        tenant: props.tenant,
        patch: values,
      });
    } else {
      addTenant.trigger({
        buildingId: props.buildingId,
        tenant: values,
      });
    }

    form.resetFields();
    typeFieldRef.current?.focus();

    if (!keepCreating) {
      props.onClose();
    }
  }

  function handleRemove() {
    if (props.tenant) {
      removeTenant.trigger({
        buildingId: props.buildingId,
        tenant: props.tenant,
      });
      props.onClose();
    }
  }

  let defaultFormValues: FormValues | undefined;

  if (props.tenant) {
    const { tenancyStart, tenancyEnd, ...tenant } = props.tenant ?? {};

    defaultFormValues = {
      ...tenant,
      tenancy: [dayjs(tenancyStart), dayjs(tenancyEnd)],
    };
  }

  return (
    <Modal
      title={
        props.tenant
          ? t("settings.tenants.update-tenant")
          : t("settings.tenants.add-new-tenant")
      }
      open={props.isVisible}
      onCancel={props.onClose}
      destroyOnClose
      footer={[
        !props.tenant && (
          <Checkbox
            key="keep-creating"
            onChange={() => setKeepCreating(!keepCreating)}
            checked={keepCreating}
          >
            {t("settings.tenants.add-many")}
          </Checkbox>
        ),
        <Button key="cancel" onClick={props.onClose}>
          {t("settings.tenants.cancel")}
        </Button>,
        props.tenant && (
          <Button key="delete" danger onClick={handleRemove}>
            {t("settings.tenants.remove")}
          </Button>
        ),
        <Button key="submit" type="primary" htmlType="submit" form="modal-form">
          {t("settings.tenants.add")}
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        id="modal-form"
        initialValues={defaultFormValues}
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("settings.tenants.organization-name")}
          name="businessName"
          rules={[{ required: true, min: 3, max: 100 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("settings.tenants.contact-name")}
          name="contactName"
          rules={[{ required: true, min: 3, max: 100 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("settings.tenants.email")}
          name="email"
          rules={[
            (form) => ({
              type: "email",
              required: !form.getFieldValue("phone"),
            }),
          ]}
          dependencies={["phone"]}
        >
          <Input type="mail" />
        </Form.Item>
        <Form.Item
          label={t("settings.tenants.phone")}
          name="phone"
          rules={[
            (form) => ({
              required: !form.getFieldValue("email"),
            }),
          ]}
          dependencies={["email"]}
          normalize={(value) => {
            try {
              return parsePhoneNumber(value, "NO").formatInternational();
            } catch {
              // The errors at this point are regarding the input not being a number
              // that we can format as an international phone number; that's always
              // the case when we start typing.
              return value;
            }
          }}
        >
          <Input type="phone" />
        </Form.Item>
        <Form.Item
          label={t("settings.tenants.tenancy-period")}
          name="tenancy"
          rules={[{ required: true }]}
        >
          <DatePicker.RangePicker />
        </Form.Item>
        <Form.Item
          label={t("settings.tenants.area-in-m2")}
          name="area"
          rules={[{ min: 0 }]}
        >
          <Input type="number" step="0.01" />
        </Form.Item>
        <Form.Item label={t("settings.tenants.notes")} name="note">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}
