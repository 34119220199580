import { useTranslations } from "@properate/translations";
import { Button, DatePicker, Empty } from "antd";
import dayjs, { Dayjs } from "@properate/dayjs";
import { InactiveAlarmPeriod } from "@properate/common";
import { useUser } from "@properate/auth";
import { useFormContext, useFormValue } from "../FormContext/hooks";
import { FormContextActionType, FormContextItem } from "../FormContext";
import { AlarmNotificationFormFields } from "./types";
import { ButtonVariant, NotificationRow } from "./NotificationRow";

const { RangePicker: DateRangePicker } = DatePicker;
const tBase = "alarm-details.notifications-page.inactive";

function NoInactiveTimes() {
  const t = useTranslations(tBase);
  const [inactivePeriods] = useFormValue<InactiveAlarmPeriod[]>(
    AlarmNotificationFormFields.InactivePeriods,
  );
  if (inactivePeriods?.length > 0) {
    return null;
  }
  return (
    <div className={"w-full flex flex-row justify-center items-center"}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="" />
      <p className="opacity-50">{t(`empty-message`)}</p>
    </div>
  );
}

function InactiveRows() {
  const [inactivePeriods, setInactiveRows] = useFormValue<
    InactiveAlarmPeriod[]
  >(AlarmNotificationFormFields.InactivePeriods);

  if (!inactivePeriods || inactivePeriods.length === 0) {
    return null;
  }

  return inactivePeriods.map((period, index) => (
    <NotificationRow
      key={`inactive-period-${index}`}
      colOne={<span>{dayjs(period.start_date).format("YYYY-MM-DD")}</span>}
      colTwo={<span>{dayjs(period.end_date).format("YYYY-MM-DD")}</span>}
      buttonVariant={ButtonVariant.Remove}
      onClick={() => setInactiveRows(inactivePeriods.toSpliced(index, 1))}
    />
  ));
}

function AddInactiveRow() {
  const t = useTranslations();
  const user = useUser();
  const [inactivePeriods, setInactivePeriods] = useFormValue<
    InactiveAlarmPeriod[]
  >(AlarmNotificationFormFields.InactivePeriods);
  const [inactiveDateRange, setInactiveDateRange] = useFormValue<Dayjs[]>(
    AlarmNotificationFormFields.InactiveDateRange,
  );
  const { dispatch } = useFormContext();

  function handleAddInactive() {
    if (!inactiveDateRange?.length || inactiveDateRange?.length !== 2) {
      dispatch({
        type: FormContextActionType.validateField,
        id: AlarmNotificationFormFields.InactiveDateRange,
      });
      return;
    }
    const startDate = inactiveDateRange[0].valueOf();
    const endDate = inactiveDateRange[1].valueOf();
    const newInactivePeriods = [
      ...(inactivePeriods ?? []),
      {
        start_date: startDate,
        end_date: endDate,
      },
    ];
    setInactivePeriods(newInactivePeriods);
    setInactiveDateRange([]);
  }

  return (
    <div className="w-full flex flex-row gap-2">
      <FormContextItem
        id={AlarmNotificationFormFields.InactiveDateRange}
        labelKey={`${tBase}.aria-daterange`}
        labelSrOnly
        antdInput
      >
        <DateRangePicker size="small" className="w-full" />
      </FormContextItem>
      <Button
        size="small"
        type="primary"
        ghost
        onClick={handleAddInactive}
        className="!h-[23px]"
        disabled={!user.isAdmin}
      >
        {t("ui.add")}
      </Button>
    </div>
  );
}

export function InactiveTimes() {
  const t = useTranslations(tBase);
  return (
    <div className="w-full flex flex-col gap-2">
      <h2 className="m-0">{t(`header`)}</h2>
      <NoInactiveTimes />
      <InactiveRows />
      <AddInactiveRow />
    </div>
  );
}
