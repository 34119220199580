import {
  ActiveAlarmPeriod,
  AlertDay,
  getDayJsDayFromAlertDay,
  TimeSpan,
} from "@properate/common";
import dayjs from "@properate/dayjs";

/**
 * Simplifies the list of resulting time spans by merging overlapping or contiguous periods.
 * @param timeSpans An array of timespans to simplify.
 * @returns A simplified array of timespans.
 */
export function simplifyTimeSpans(timeSpans: TimeSpan[]): TimeSpan[] {
  if (timeSpans.length === 0) return [];

  // Sort timespans by start time
  timeSpans.sort((a, b) => a[0] - b[0]);

  const result: TimeSpan[] = [];
  let currentSpan = timeSpans[0];

  for (const span of timeSpans.slice(1)) {
    if (span[0] <= currentSpan[1]) {
      // Overlapping or contiguous intervals, merge them
      currentSpan[1] = Math.max(currentSpan[1], span[1]);
    } else {
      result.push(currentSpan);
      currentSpan = span;
    }
  }

  result.push(currentSpan);

  return result;
}

/**
 * Processes a list of active and inactive timespans to return the resulting active timespans.
 * @param activeList A list of active timespans.
 * @param inactiveList A list of inactive timespans.
 * @returns An array of resulting active timespans accounting for inactive periods.
 */
export function processTimeSpans(
  activeList: TimeSpan[],
  inactiveList: TimeSpan[],
): TimeSpan[] {
  let result: TimeSpan[] = activeList;

  for (const inactive of inactiveList) {
    const newResult: TimeSpan[] = [];

    for (const active of result) {
      const [startA, endA] = active;
      const [startI, endI] = inactive;

      if (endA <= startI || endI <= startA) {
        // No overlap, keep the active timespan
        newResult.push(active);
      } else {
        if (startA < startI) {
          newResult.push([startA, startI]);
        }
        if (endI < endA) {
          newResult.push([endI, endA]);
        }
      }
    }

    result = newResult;
  }

  return simplifyTimeSpans(result);
}

export function getNextDayJsDayFromAlertDay(
  alertDay: AlertDay,
  fromDate: number | undefined,
): dayjs.Dayjs {
  const day = fromDate ? dayjs(fromDate) : dayjs();
  const current = day.day();

  if (alertDay === AlertDay.Weekends) {
    if (current === 0 || current === 6) {
      // If it's Sunday or Saturday, return "today"
      return day;
    }
    // return next saturday.
    return getNextDayJsDayFromAlertDay(AlertDay.Saturday, fromDate);
  }
  if (alertDay === AlertDay.Weekdays) {
    if (current === 0 || current === 6) {
      // If it's Sunday or Saturday, return next Monday.
      return getNextDayJsDayFromAlertDay(AlertDay.Monday, fromDate);
    }
    // return "today".
    return day;
  }

  let dayNumber = getDayJsDayFromAlertDay(alertDay);
  if (current > dayNumber) {
    dayNumber += 7;
  }
  return day.day(dayNumber);
}

function getTimedDayjsDate(date: number, time: string): dayjs.Dayjs {
  const [hour, minute] = time.split(":", 2);
  return dayjs(date)
    .startOf("day") // zero the day (milliseconds, seconds, minutes, hours..)
    .hour(parseInt(hour, 10)) // set hour
    .minute(parseInt(minute, 10)); // set minute
}

export function getTimespansForActivePeriods({
  fromDate,
  toDate,
  activePeriods,
}: {
  fromDate: number;
  toDate: number;
  activePeriods: ActiveAlarmPeriod[];
}): TimeSpan[] {
  const result: TimeSpan[] = [];
  const toDateDayjs = dayjs(toDate);
  const fromDateDayjs = dayjs(fromDate);

  function sanitizeResult(start: dayjs.Dayjs, end: dayjs.Dayjs) {
    let startTime = start;
    let endTime = end;
    if (startTime.isBefore(fromDateDayjs)) {
      startTime = fromDateDayjs;
    }
    if (endTime.isAfter(toDateDayjs)) {
      endTime = toDateDayjs;
    }
    if (!startTime.isAfter(endTime)) {
      result.push([startTime.valueOf(), endTime.valueOf()]);
    }
  }

  for (const activePeriod of activePeriods ?? []) {
    let currentDay = dayjs(fromDate);
    while (currentDay.startOf("day").isBefore(toDate)) {
      const currentNumeric = currentDay.valueOf();
      const day = getNextDayJsDayFromAlertDay(activePeriod.day, currentNumeric);
      const dayNumeric = day.valueOf();
      if (activePeriod.all_day) {
        sanitizeResult(day.startOf("day"), day.endOf("day"));
      } else if (
        typeof activePeriod.start_time === "string" &&
        typeof activePeriod.end_time === "string"
      ) {
        sanitizeResult(
          getTimedDayjsDate(dayNumeric, activePeriod.start_time),
          getTimedDayjsDate(dayNumeric, activePeriod.end_time),
        );
      }

      currentDay = day.add(1, "day");
    }
  }
  return result;
}
