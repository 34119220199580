import { AxisLeft } from "@visx/axis";
import { formatUnit } from "@properate/common";
import { formatMax6Digits } from "@/utils/format";
import { ScaleLinearNumeric } from "@/utils/types";
import { LEFT_AXIS_ESTIMATED_WIDTH } from "../../utils";

interface Props {
  scale: ScaleLinearNumeric;
  color: string;
  index: number;
  unit: string;
  tickSize: number;
}

export function ScatterplotGraphYAxis({
  scale,
  color,
  index,
  unit,
  tickSize,
}: Props) {
  const offsetLeft = index * LEFT_AXIS_ESTIMATED_WIDTH;
  return (
    <AxisLeft
      left={offsetLeft}
      stroke={color}
      scale={scale}
      label={formatUnit(unit)}
      labelProps={{
        fill: color,
      }}
      tickFormat={formatMax6Digits}
      tickStroke={color}
      tickLabelProps={{
        fill: color,
        fontSize: tickSize,
      }}
    />
  );
}
