import { Select, Space } from "antd";
import { Aggregate } from "@cognite/sdk";
import { useTranslations } from "@properate/translations";
import { MessageKey } from "@/utils/types";
import { aggregateOptions } from "../../utils";

interface Props {
  value: Aggregate;
  onChange: (value: Aggregate) => unknown;
}

export function TimeseriesAggregate({ value, onChange }: Props) {
  const t = useTranslations();

  const options = aggregateOptions.map((option) => ({
    value: option.value,
    label: t(`analysis.xy-graph.aggregate.${option.labelKey}` as MessageKey),
  }));

  return (
    <Space direction="vertical">
      <label htmlFor="aggregate">
        {t("analysis.details.timeseries-modal.select-aggregate-type")}
      </label>
      <Select defaultValue={value} onChange={onChange} options={options} />
    </Space>
  );
}
