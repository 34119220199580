import { useUser } from "@properate/auth";
import { Button } from "antd";
import { useState } from "react";
import { useTranslations } from "@properate/translations";
import { CalculationFlow } from "../types";
import { CopyModal } from "./CopyModal";

interface Props {
  calculationFlow: CalculationFlow;
}

export const CopyButton = ({ calculationFlow }: Props) => {
  const t = useTranslations();
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const handleCopy = () => setCopyModalVisible(true);
  const user = useUser();

  return (
    <>
      <Button
        onClick={handleCopy}
        disabled={user.isViewer || calculationFlow.id === null}
      >
        {t("calculation-flow.make-a-copy")}
      </Button>
      <CopyModal
        calculationFlow={calculationFlow}
        open={copyModalVisible}
        onClose={() => setCopyModalVisible(false)}
        onOk={() => setCopyModalVisible(false)}
      />
    </>
  );
};
