import { useState } from "react";
import { Timeseries } from "@cognite/sdk";
import { useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import { WithSnapshotId } from "@properate/common";
import { useTheme } from "styled-components";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { HeatMapListHeader, HeatMapList, HeatMap } from "@/features/heatmap";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { createHeatMap } from "@/services/heatMap";
import {
  removeWidget,
  useUserSettingsForBuilding,
} from "@/services/userSettings";
import { getExtendedTimeseriesDescription } from "@/utils/helpers";
import { useCogniteClient } from "@/context/CogniteClientContext";

export function HeatMapListPage() {
  const t = useTranslations();

  useBuildingPageTitle(t("analysis.heat-map.title"));

  const { client } = useCogniteClient();
  const theme = useTheme();
  const heatMaps = useLoaderData() as Array<WithSnapshotId<HeatMap>>;
  const currentBuildingId = useCurrentBuildingId();
  const navigate = useNavigate();
  const submit = useSubmit();
  const currentUser = useUser();
  const [isTimeseriesSelectionModalOpen, setIsTimeseriesSelectionModalOpen] =
    useState(false);
  const { data: userSettingsForBuilding } = useUserSettingsForBuilding();

  async function generateName(timeseries: Timeseries) {
    const timeseriesExtendedDescription =
      await getExtendedTimeseriesDescription(client, timeseries);
    if (timeseriesExtendedDescription !== "") {
      if (typeof currentUser.name === "string") {
        return t("analysis.heat-map.new-name-full", {
          description: timeseriesExtendedDescription,
          user: currentUser.name,
        });
      }
      return t("analysis.heat-map.new-name-description", {
        description: timeseriesExtendedDescription,
      });
    }
    if (typeof currentUser.name === "string") {
      return t("analysis.heat-map.new-name-user", {
        user: currentUser.name,
      });
    }
    return t("analysis.heat-map.new-name");
  }

  async function handleOk([timeseries]: Timeseries[]) {
    const { id } = await createHeatMap({
      buildingId: currentBuildingId,
      owner: currentUser.email,
      created: Date.now(),
      timeseriesId: timeseries.id,
      name: await generateName(timeseries),
      viewMode: "month",
      aggregate: "average",
      referenceDate: Date.now(),
      color: theme.primary,
      showWeekNumbers: false,
    });
    navigate(id);
  }

  function handleConfirmRemoval(snapshotId: string) {
    const widgets = userSettingsForBuilding?.dashboard?.widgets;
    if (widgets) {
      removeWidget(currentBuildingId, widgets, snapshotId);
    }
    submit(
      { heatMapsCurrent: JSON.stringify(heatMaps), snapshotId },
      { method: "delete" },
    );
  }

  return (
    <>
      <HeatMapListHeader
        onClickNew={() => setIsTimeseriesSelectionModalOpen(true)}
      />
      {heatMaps.length > 0 && (
        <HeatMapList
          heatMaps={heatMaps}
          onConfirmRemoval={handleConfirmRemoval}
        />
      )}
      <TimeseriesSelectionModal
        selectedIds={[]}
        onOk={handleOk}
        open={isTimeseriesSelectionModalOpen}
        onHide={() => setIsTimeseriesSelectionModalOpen(false)}
        hiddenFilters={["building"]}
        initialFilters={{
          buildingId: currentBuildingId,
        }}
        max={1}
      />
    </>
  );
}
