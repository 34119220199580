import { UserSettings } from "@/utils/types";

export const isMobile = () => {
  if (typeof window !== "undefined") {
    return window.innerWidth < 1024;
  }
  return false;
};

export const isSidebarCollapsedResponsive = (preferences?: UserSettings) => {
  if (isMobile()) {
    if (typeof preferences?.isMobileSidebarCollapsed !== "boolean") {
      return true;
    }
    return preferences?.isMobileSidebarCollapsed;
  }

  return !!preferences?.isSidebarCollapsed;
};
