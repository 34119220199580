import { useBuildingsWithKPIs } from "@/pages/portfolio/hooks/useBuildingsWithKPIs";
import { BuildingArealCalculatedArea } from "@/eepApi";

export function useCurrentBuildingAreal(buildingId: number) {
  const buildingData = useBuildingsWithKPIs([]);

  const currBuilding = buildingData.find(
    (building) => building.key === buildingId,
  )?.building;

  return (currBuilding?.buildingAreal as BuildingArealCalculatedArea) || {};
}
