import { Select } from "antd";
import { useTranslations } from "@properate/translations";
import { SpotPriceGranularity, SpotPriceTimeSpan } from "@/utils/types";
import { getGranularityOptions } from "../utils";

interface Props {
  value: SpotPriceGranularity;
  onChange: (value: SpotPriceGranularity) => unknown;
  timeSpan: SpotPriceTimeSpan;
}

export function SpotPriceGranularitySelect({
  value,
  onChange,
  timeSpan,
}: Props) {
  const t = useTranslations();

  const options = getGranularityOptions(timeSpan).map((option) => ({
    value: option.value,
    label: t(option.labelKey),
  }));
  return (
    <Select
      aria-label={t("dashboard.widgets.spot-price.granularity")}
      value={value}
      onChange={onChange}
      options={options}
      disabled={options.length < 2}
      style={{ minWidth: 150 }}
    />
  );
}
