import { AccordionEntryId } from "@properate/ui";
import { useNotes, useTimeseriesList } from "@/features/notes";

export function useNoteCheckByLabelAndNoteIdForEnergyLegend() {
  const { notes } = useNotes();
  const { timeseriesList } = useTimeseriesList();

  return (entryId: AccordionEntryId, timeSeriesExternalId: string) => {
    const currentId = timeseriesList?.find(
      (ts) => ts.externalId === timeSeriesExternalId,
    )?.assetId;

    if (currentId) {
      return !!notes?.some(
        (note) => note.assetIds.includes(currentId) && entryId === note.id,
      );
    }
    return false;
  };
}
