import { Asset } from "@cognite/sdk";
import { WithRequired } from "@properate/common";
import { AssetWithOrganizationAndLabels } from "./types";

export function getOrganizations(
  totalEnergyAssets: Array<WithRequired<Asset, "labels">>,
) {
  return Array.from(
    new Set(
      totalEnergyAssets
        .filter(
          (
            totalEnergyAsset,
          ): totalEnergyAsset is AssetWithOrganizationAndLabels =>
            totalEnergyAsset.metadata !== undefined &&
            "organization" in totalEnergyAsset.metadata,
        )
        .sort((totalEnergyAssetOne, totalEnergyAssetTwo) => {
          if (
            totalEnergyAssetOne.externalId &&
            totalEnergyAssetOne.externalId.includes("+Common=100.001")
          ) {
            return -1;
          }
          if (
            totalEnergyAssetTwo.externalId &&
            totalEnergyAssetTwo.externalId.includes("+Common=100.001")
          ) {
            return 1;
          }
          return totalEnergyAssetOne.metadata.organization.localeCompare(
            totalEnergyAssetTwo.metadata.organization,
          );
        })
        .map((totalEnergyAsset) => totalEnergyAsset.metadata.organization),
    ),
  );
}
