import { ChangeEvent } from "react";
import { Col, Form, Input, Row } from "antd";
import { useTranslations } from "@properate/translations";
import { Aggregate, AggregateSelect } from "../AggregateSelect";
import { ColumnDefinition } from "./types";

interface Props {
  value: ColumnDefinition;
  onChange: (value: ColumnDefinition) => void;
  showLabels: boolean;
  showAggregates: boolean;
}

export const ColumnDefinitionInput = ({
  value,
  onChange,
  showLabels,
  showAggregates,
}: Props) => {
  const t = useTranslations();

  const handleNameChange = (name: string) => {
    onChange({ ...value, name });
  };

  const handleAggregateChange = (aggregate: Aggregate) => {
    onChange({ ...value, aggregate });
  };

  const handleSummaryAggregateChange = (summaryAggregate: Aggregate) => {
    onChange({ ...value, summaryAggregate });
  };

  const nameColumnsSpan = showAggregates ? 8 : 16;

  return (
    <>
      {showLabels && (
        <Row>
          <Col span={nameColumnsSpan}>{t("calculation-flow.column-name")}:</Col>
          {showAggregates && (
            <Col span={8}>{t("calculation-flow.aggregate")}:</Col>
          )}
          <Col span={8}>{t("calculation-flow.summary-row")}:</Col>
        </Row>
      )}
      <Row>
        <Col span={nameColumnsSpan}>
          <Form.Item>
            <Input
              value={value.name}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleNameChange(event.target.value)
              }
            />
          </Form.Item>
        </Col>
        {showAggregates && (
          <Col span={8}>
            <Form.Item>
              <AggregateSelect
                value={value.aggregate}
                onChange={handleAggregateChange}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={8}>
          <Form.Item>
            <AggregateSelect
              value={value.summaryAggregate}
              onChange={handleSummaryAggregateChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
