import { useSidebarData } from "@properate/ui";
import { useTimeseriesList } from "@/features/notes";
import { NotesAssetFilterMode, NotesSidebarProps } from "../types";

export function useAssetIdSet() {
  const { timeseriesList, error, isLoading, ...rest } = useTimeseriesList();
  const { assetFilterMode, idSet } = useSidebarData<NotesSidebarProps>();

  function getIdSet() {
    if (assetFilterMode === NotesAssetFilterMode.AssetList) {
      return idSet;
    }
    if (!timeseriesList || error || isLoading) {
      return null;
    }
    return new Set(
      timeseriesList
        .map((timeseries) => timeseries.assetId)
        .filter((assetId) => assetId !== undefined),
    ) as Set<number>;
  }

  return {
    assetIdSet: getIdSet(),
    error,
    isLoading,
    ...rest,
  };
}
