import { NumberValue } from "d3-scale";

const numberFormatterStandard = new Intl.NumberFormat("nb-NO");
const numberFormatterCompact = new Intl.NumberFormat("nb-NO", {
  notation: "compact",
});
const numberFormatterScientific = new Intl.NumberFormat("nb-NO", {
  notation: "scientific",
});

const MAX_DIGITS = 6;

export function formatMax6Digits(number: NumberValue) {
  // Very large numbers
  if (number.toString().includes("e")) {
    return numberFormatterScientific.format(number.valueOf());
  }
  const digits = number.toString().replace(/\.|-/g, "").length;
  if (digits > MAX_DIGITS) {
    return numberFormatterScientific.format(number.valueOf());
  }
  return formatNonScientific(number.valueOf());
}

export function formatNonScientific(number: NumberValue) {
  if (number.valueOf() >= 1 || number.valueOf() <= -1) {
    return numberFormatterStandard.format(number.valueOf());
  }
  return numberFormatterCompact.format(number.valueOf());
}

export function formatMultiState(stateDescription: Record<number, string>) {
  return (value: NumberValue) => {
    return stateDescription[value.valueOf()] || `${value.valueOf()}`;
  };
}

export function formatCompact(number: NumberValue) {
  return numberFormatterCompact.format(number.valueOf());
}
