import { ReactNode } from "react";
import { Statistic, Tooltip } from "antd";
import styled from "styled-components";
import { Handle } from "@/components/Draggable/Handle";
import { ConditionalWrapper } from "../ConditionalWrapper";

type CardKpiProps = {
  title: string;
  description?: string;
  value?: number;
  suffix: string;
  precision: number;
};

export const KpiCard = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 0 0 24px -1px;
  padding-left: 1px;
  background: transparent;
  font-weight: bold;
  position: relative;
  .ant-card-body {
    padding: 0 24px;
  }

  & + &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: ${(props) => props.theme.neutral7};
    top: 0;
    left: -1px;
  }
`;

export const CardKpi = ({
  title,
  description,
  value,
  suffix,
  precision,
}: CardKpiProps) => {
  return (
    <ConditionalWrapper
      condition={typeof description === "string"}
      wrapper={(children: ReactNode) => (
        <Tooltip placement="top" title={description}>
          {children}
        </Tooltip>
      )}
    >
      <KpiCard>
        <div className="ant-card-body">
          <Statistic
            title={
              <>
                <Handle /> {title}
              </>
            }
            value={
              typeof value !== "number"
                ? "--"
                : new Intl.NumberFormat("nb-NO", {
                    maximumFractionDigits: precision || 1,
                  }).format(value)
            }
            precision={precision}
            suffix={typeof value !== "number" ? "" : suffix}
          />
        </div>
      </KpiCard>
    </ConditionalWrapper>
  );
};
