import dayjs from "@properate/dayjs";
import { CSSProperties } from "react";
import { GraphTitleStyled } from "./elements";

export const GraphTitleRange = ({
  startDate,
  endDate,
  style,
}: {
  startDate: Date;
  endDate: Date;
  style?: CSSProperties;
}) => {
  return (
    <GraphTitleStyled style={style}>
      {`${dayjs(startDate).format("DD.MM.YYYY")} - ${dayjs(endDate).format(
        "DD.MM.YYYY",
      )}`}
    </GraphTitleStyled>
  );
};
