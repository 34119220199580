import { browserFirestore } from "@properate/firebase";
import { BuildingContact } from "@properate/common";
import { arrayUnion, collection, doc, setDoc } from "firebase/firestore";
import useSWRMutation from "swr/mutation";

type TriggerArg = {
  buildingId: number;
  contact: BuildingContact;
};

export function useAddBuildingContact() {
  return useSWRMutation(
    `building-contacts`,
    async (_, extra: { arg: TriggerArg }) => {
      const { buildingId, contact } = extra.arg;

      const newContact = {
        id: crypto.randomUUID(), // Generate an ID to be used as a key in the UI
        ...contact,
      };

      await setDoc(
        doc(collection(browserFirestore, "buildings"), buildingId.toString()),
        { contacts: arrayUnion(newContact) },
        { merge: true },
      );
    },
  );
}
