import { browserFirestore } from "@properate/firebase";
import { BuildingTenant } from "@properate/common";
import { arrayRemove, collection, doc, setDoc } from "firebase/firestore";
import useSWRMutation from "swr/mutation";

type TriggerArg = {
  buildingId: number;
  tenant: BuildingTenant;
};

export function useRemoveBuildingTenant() {
  return useSWRMutation(
    `building-tenants`,
    async (_, extra: { arg: TriggerArg }) => {
      const { buildingId, tenant } = extra.arg;

      await setDoc(
        doc(collection(browserFirestore, "buildings"), buildingId.toString()),
        { tenants: arrayRemove(tenant) },
        { merge: true },
      );
    },
  );
}
