import styled from "styled-components";
import { Input, InputProps } from "antd";

export const SearchInput = styled(Input.Search)<InputProps>`
  .ant-input-affix-wrapper {
    background: ${(props) => props.theme.background};
  }

  .ant-input-affix-wrapper-focused {
    background: ${(props) => props.theme.background2};
  }

  input {
    background: ${(props) => props.theme.background};
  }

  .ant-input-affix-wrapper-focused input {
    background: ${(props) => props.theme.background2};
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):not(:hover),
  .ant-input-group-addon button {
    border-color: ${(props) => props.theme.neutral7};
  }
`;
