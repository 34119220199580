import { useTranslations } from "@properate/translations";
import { ETaskStatus } from "../../schemas";

export function useTaskStatusTranslations() {
  const t = useTranslations();

  const record: Record<ETaskStatus, string> = {
    InProgress: t("task.enum.status.InProgress"),
    Completed: t("task.enum.status.Completed"),
    CompletedWithExceptions: t("task.enum.status.CompletedWithExceptions"),
    FollowUpNeeded: t("task.enum.status.FollowUpNeeded"),
  };

  const keys = Object.keys(record) as ETaskStatus[];

  return {
    record,
    keys,
  };
}
