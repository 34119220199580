import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const FormItem = styled.div<{ $flexBasis?: number }>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${({ $flexBasis }) => ($flexBasis ? `flex-basis: ${$flexBasis}px` : "")};
`;
