import useSWR from "swr";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "./src/index";

const getTaskChangelog = httpsCallable(
  getFunctions(app, "europe-west1"),
  "getTaskChangelog",
);

export function useTaskChangelog(task: { snapshotId: string }) {
  return useSWR(
    ["task", "changelog", task],
    async () => {
      const { data } = await getTaskChangelog({
        taskId: task.snapshotId,
      });

      return data as Array<{
        author: string;
        timestamp: number;
        changes: Record<
          string,
          {
            from: unknown;
            to: unknown;
          }
        >;
      }>;
    },
    {
      keepPreviousData: true,
    },
  );
}

const removeVideoCall = httpsCallable(
  getFunctions(app, "europe-west1"),
  "removeVideo",
);

export function removeVideo(data: {
  folder: string;
  itemId: string;
  fileId: string;
  cogniteFileId?: number | null;
}) {
  return removeVideoCall(data);
}
