"use client";

import { Radio } from "antd";
import { useUpdateTask } from "../../hooks/use-update-task";
import { useTaskPriorityTranslations } from "../../hooks/intl/use-task-priority-translations";
import { ETaskPriority } from "../../schemas";

type Props = {
  taskId: string;
  value: ETaskPriority;
  disabled?: boolean;
};

export function PriorityInput(props: Props) {
  const updateTask = useUpdateTask();
  const tTaskPriority = useTaskPriorityTranslations();

  function handleUpdatePriority(priority: ETaskPriority) {
    updateTask.trigger({
      where: {
        taskId: props.taskId,
      },
      data: {
        priority,
      },
    });
  }

  const priorityOptions = tTaskPriority.keys.map((key) => ({
    value: key,
    label: tTaskPriority.record[key],
  }));

  return (
    <Radio.Group
      value={props.value}
      onChange={(event) =>
        handleUpdatePriority(event.target.value as ETaskPriority)
      }
      disabled={!!(updateTask.isMutating || props.disabled)}
      className="flex"
    >
      {priorityOptions.map((option) => (
        <Radio.Button
          key={option.value}
          value={option.value}
          className="flex-1 text-center"
        >
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}
