import { PropsWithChildren, ReactNode } from "react";

export type DescriptionListEntryType = {
  term: ReactNode;
  description: ReactNode;
};

export type DescriptionListStyleOverrides = {
  termColon?: boolean;
  overrideTermClassName?: string;
  overrideDescriptionClassName?: string;
  overrideWrapperClassName?: string;
  overrideEntryWrapperClassName?: string;
  extraTermClassName?: string;
  extraDescriptionClassName?: string;
  extraWrapperClassName?: string;
  extraEntryWrapperClassName?: string;
};

export type DescriptionListProps = {
  entries: DescriptionListEntryType[];
  postChildrenEntries?: DescriptionListEntryType[];
  ignoreEmptyDescriptions?: boolean;
} & DescriptionListStyleOverrides &
  PropsWithChildren;

export type DescriptionListEntryProps = DescriptionListEntryType &
  Omit<
    DescriptionListStyleOverrides,
    "overrideWrapperClassName" | "extraWrapperClassName"
  >;

function getClassName(
  base?: string,
  override?: string,
  extra?: string,
): string {
  return override ?? `${base ?? ""} ${extra ?? ""}`;
}

export function DescriptionListEntry({
  term,
  description,
  overrideTermClassName,
  overrideDescriptionClassName,
  overrideEntryWrapperClassName,
  extraTermClassName,
  extraDescriptionClassName,
  extraEntryWrapperClassName,
  termColon,
}: DescriptionListEntryProps) {
  return (
    <div
      className={getClassName(
        "w-full flex flex-nowrap gap-2 justify-start items-start text-xs",
        overrideEntryWrapperClassName,
        extraEntryWrapperClassName,
      )}
    >
      <dt
        className={getClassName(
          "m-0 p-0 font-bold text-muted-foreground",
          overrideTermClassName,
          extraTermClassName,
        )}
      >
        {term}
        {termColon ? ":" : ""}
      </dt>
      <dd
        className={getClassName(
          "m-0 p-0",
          overrideDescriptionClassName,
          extraDescriptionClassName,
        )}
      >
        {description}
      </dd>
    </div>
  );
}

export function DescriptionList({
  entries,
  postChildrenEntries,
  ignoreEmptyDescriptions,
  children,
  overrideWrapperClassName,
  extraWrapperClassName,
  ...rest
}: DescriptionListProps) {
  function renderEntries(entryList: DescriptionListEntryType[], key: string) {
    const filteredEntries = ignoreEmptyDescriptions
      ? entryList.filter((entry) => !!entry.description)
      : entryList;
    return (
      <>
        {filteredEntries.map(({ term, description }, index) => (
          <DescriptionListEntry
            key={`${key}_${index}`}
            term={term}
            description={description}
            {...rest}
          />
        ))}
      </>
    );
  }

  return (
    <dl
      className={getClassName(
        "flex flex-col flex-nowrap gap-2 w-full",
        overrideWrapperClassName,
        extraWrapperClassName,
      )}
    >
      {renderEntries(entries, "entries")}
      {children}
      {renderEntries(postChildrenEntries ?? [], "postChildrenEntries")}
    </dl>
  );
}
