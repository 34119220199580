import { useContext } from "react";
import { SidebarContext, SidebarDispatchContext } from "./SidebarContext";
import { SidebarAction, SidebarActionType, SidebarValues } from "./types";

export function useSidebarValues<T>() {
  return useContext(SidebarContext) as SidebarValues<T>;
}

export function useSidebarActions() {
  return useContext(SidebarDispatchContext) as React.Dispatch<SidebarAction>;
}

export function useSidebarData<T>() {
  return useSidebarValues<T>().data as T;
}

export function useSetSidebarData<T>() {
  const dispatch = useSidebarActions();
  return {
    setSidebarData: (data: Record<string, unknown> & Partial<T>) => {
      dispatch({
        type: SidebarActionType.mergeData,
        data: {
          ...data,
        },
      });
    },
  };
}
