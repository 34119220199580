import { useLocation } from "react-router-dom";

export function useAnalysisTypeRoute() {
  const location = useLocation();
  if (location.pathname.includes("simple")) {
    return "simple";
  }
  if (location.pathname.includes("scatterplot")) {
    return "scatterplot";
  }
  console.warn(
    "You are using this hook outside of an analysis route. Is that what you intended?",
  );
  return null;
}
