import { Input, InputProps } from "antd";
import styled from "styled-components";

type InputErrorProps = InputProps & {
  error?: string;
  marginBottom?: number;
};

const ErrorContainer = styled.div<InputErrorProps>`
  position: relative;
  margin-bottom: ${({ error, marginBottom = 0 }) =>
    `${marginBottom + (error ? 7 : 0)}px;`}
  & input,
  & input:hover,
  & .ant-input-affix-wrapper,
  & .ant-input-affix-wrapper:hover {
    border-color: ${(props) => (props.error ? props.theme.error : "")};
  }

  &::after {
    content: "${(props) => props.error}";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    color: ${(props) => (props.error ? props.theme.error : "")};
  }
`;
interface Props {
  onChange: (value: string) => unknown;
  placeholder: string;
  value?: string | number;
  defaultValue?: string | number;
  error?: string;
  type?: string;
  suffix?: string;
  onPressEnter?: () => void;
  autoComplete?: string;
  allowClear?: boolean;
  disabled?: boolean;
  marginBottom?: number;
  dataTestId?: string;
}

export const InputWithError = ({
  placeholder,
  value,
  defaultValue,
  error,
  type = "text",
  suffix,
  onPressEnter,
  onChange,
  autoComplete = "off",
  allowClear = false,
  disabled,
  marginBottom,
  dataTestId,
}: Props) => {
  return (
    <ErrorContainer marginBottom={marginBottom} error={error}>
      <Input
        disabled={disabled}
        type={type}
        suffix={suffix}
        placeholder={placeholder}
        data-testid={dataTestId}
        defaultValue={defaultValue}
        value={value}
        autoComplete={autoComplete}
        allowClear={allowClear}
        onChange={(e) => onChange(e.target.value)}
        onPressEnter={onPressEnter}
      />
    </ErrorContainer>
  );
};
