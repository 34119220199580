import { Timeseries, DatapointAggregates } from "@cognite/sdk";
import { CDF_CONSTANTS } from "@/utils/cdf";
import { useHandleApiError } from "@/utils/api";
import { PROPERATE_THEME_LIGHT } from "@/utils/ProperateColors";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { GaugeZones } from "../types";

export function getDefaultGaugeZones(min: number, max: number): GaugeZones {
  const range = max - min;
  const oneThirdOfRange = Math.round(range / 3);
  const thresholdZoneLower = oneThirdOfRange > 1 ? min + oneThirdOfRange : min;
  const thresholdZoneMiddle = oneThirdOfRange > 1 ? max - oneThirdOfRange : max;
  return {
    lower: {
      color: PROPERATE_THEME_LIGHT.info,
      threshold: thresholdZoneLower,
    },
    middle: {
      color: PROPERATE_THEME_LIGHT.warning,
      threshold: thresholdZoneMiddle,
    },
    upper: {
      color: PROPERATE_THEME_LIGHT.error,
    },
  };
}

export function getActiveZone(zones: GaugeZones, value: number) {
  if (value < zones.lower.threshold) {
    return zones.lower;
  }
  if (zones.middle !== undefined && value < zones.middle.threshold) {
    return zones.middle;
  }
  return zones.upper;
}

export function useGaugeMinMax() {
  const handleAPIError = useHandleApiError();
  const { client } = useCogniteClient();

  return async (timeseries: Timeseries) => {
    try {
      const [timeseriesWithMinMax] = (await client.datapoints.retrieve({
        items: [{ id: timeseries.id }],
        aggregates: ["min" as const, "max" as const],
        granularity: `${CDF_CONSTANTS.datapoints.limits.granularity.day}d`,
        start: 0,
        end: "now",
      })) as DatapointAggregates[];
      if (timeseriesWithMinMax && timeseriesWithMinMax.datapoints.length > 0) {
        const [datapoint] = timeseriesWithMinMax.datapoints;
        return {
          min: datapoint.min ?? 0,
          max: datapoint.max ?? 100,
        };
      }
      return {
        min: 0,
        max: 100,
      };
    } catch (error) {
      handleAPIError(error);
      return {
        min: 0,
        max: 100,
      };
    }
  };
}
