import { Space, Tag } from "antd";
import { useTranslations } from "@properate/translations";
import { UserSettings } from "@/utils/types";
import { filters } from "./hooks/useContactsSieve";

type Props = {
  value: UserSettings["filters"]["contactsSieve"];
  onChange: (value: UserSettings["filters"]["contactsSieve"]) => void;
};

export function BuildingContactsTableFilters(props: Props) {
  const t = useTranslations();

  return (
    <Space key="filter" size={[0, 8]} wrap>
      <Tag.CheckableTag
        checked={props.value === "all"}
        onChange={() => props.onChange("all")}
      >
        {t("settings.contacts.all")}
      </Tag.CheckableTag>
      {filters.map(({ label }) => (
        <Tag.CheckableTag
          key={label}
          checked={label === props.value}
          onChange={() => props.onChange(label)}
        >
          {t(`settings.contacts.${label}`)}
        </Tag.CheckableTag>
      ))}
    </Space>
  );
}
