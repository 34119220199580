import { useUser } from "@properate/auth";
import { Node, useReactFlow } from "reactflow";
import { Form, InputNumber } from "antd";
import { useTranslations } from "@properate/translations";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import {
  SmallNode,
  NodeOutput,
  NodeInput,
  Header,
  Body,
  NodeInputLabel,
  NodeOutputLabel,
  NodeContent,
} from "./helpers/NodeComponents";

const FormItem = Form.Item;

interface PowerNodeProps {
  operationId: string;
  exponent: number;
}

export const getEmptyPowerNode = (): Node<PowerNodeProps> => {
  return {
    id: getNodeId("power"),
    type: "power",
    data: {
      operationId: "power",
      exponent: 2,
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function PowerNode(params: { id: string; data: PowerNodeProps }) {
  const t = useTranslations();
  const reactFlowInstance = useReactFlow();
  const user = useUser();

  const handleChange = (exponent: number | null) => {
    if (exponent)
      updateReactFlowNodeData(
        reactFlowInstance,
        params.id,
        "exponent",
        exponent,
      );
  };

  return (
    <SmallNode>
      <Header>{t("calculation-flow.node-types.power")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <NodeContent>
          <Form layout="vertical" disabled={user.isViewer}>
            <FormItem label={t("calculation-flow.node-types.exponent-n")}>
              <InputNumber
                value={params.data.exponent}
                onChange={handleChange}
                controls={false}
                decimalSeparator=","
              />
            </FormItem>
          </Form>
        </NodeContent>
        <NodeOutput>
          <NodeOutputLabel>
            A<sup>n</sup>
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </SmallNode>
  );
}

export default PowerNode;
