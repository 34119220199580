"use client";

import { Card, Checkbox, Col, Form, Row, TreeSelect } from "antd";
import { useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ASSET_DESCRIPTIONS } from "@properate/common";
import { useTranslations, MessageKey } from "@properate/translations";
import { useAllSystemOptions } from "../hooks/use-all-system-options";
import type { DefaultOptionType } from "rc-tree-select/lib/TreeSelect";

const FormItem = Form.Item;

interface Props {
  buildingId: number;
  value?: string;
  configuration?: {
    allLevelsSelectable?: boolean;
    showNonExistingSystemsCheckbox?: boolean;
    isRequired?: boolean;
  };
}

export function MeterSystemSelect({
  buildingId,
  value,
  configuration: {
    allLevelsSelectable = false,
    showNonExistingSystemsCheckbox = true,
    isRequired = true,
  } = {},
}: Props) {
  const t = useTranslations();
  const { systems: options, error } = useAllSystemOptions({
    buildingId,
  });
  const [showNonExistingSystems, setShowNonExistingSystems] = useState(false);

  const tfm0Options = Object.keys(ASSET_DESCRIPTIONS).filter(
    (tfm) => tfm.length == 1,
  );
  const tfm1Options = Object.keys(ASSET_DESCRIPTIONS).filter(
    (tfm) => tfm.length == 2,
  );
  const tfm2Options = Object.keys(ASSET_DESCRIPTIONS).filter(
    (tfm) => tfm.length == 3,
  );

  const filteredTfm0Options = tfm0Options.filter(
    (tfm0) =>
      showNonExistingSystems ||
      options.map((option) => option.substring(0, 1)).includes(tfm0),
  );

  if (error) {
    console.error(error);
  }

  // 2 level
  const tfmTreeData = filteredTfm0Options.map((tfm0) => {
    const filteredTfm1Options = tfm1Options.filter(
      (tfm1) =>
        tfm1.substring(0, 1) == tfm0 &&
        (showNonExistingSystems ||
          options
            .map((option) => {
              return option.substring(0, 2);
            })
            .includes(tfm1)),
    );

    const title0 = t(`tfm.system.buildingPartNumber.${tfm0}` as MessageKey);

    return {
      title: `${tfm0}: ${title0}`,
      value: tfm0,
      selectable: allLevelsSelectable,
      // 21 level
      children: filteredTfm1Options.map((tfm1) => {
        const filteredTfm2Options = tfm2Options.filter(
          (tfm2) =>
            tfm2.substring(0, 2) == tfm1 &&
            (showNonExistingSystems ||
              options
                .map((option) => {
                  return option.substring(0, 3);
                })
                .includes(tfm2)),
        );

        const title1 = t(`tfm.system.buildingPartNumber.${tfm1}` as MessageKey);

        return {
          title: `${tfm1}: ${title1}`,
          selectable: allLevelsSelectable,
          value: tfm1,
          // 211 level
          children: filteredTfm2Options.map((tfm2) => {
            const title2 = t(
              `tfm.system.buildingPartNumber.${tfm2}` as MessageKey,
            );
            const tfm3Options = [...Array(200).keys()].map((nnn) => {
              return tfm2 + "." + String(nnn).padStart(3, "0");
            });
            const filteredTfm3Options = tfm3Options.filter(
              (code3) => showNonExistingSystems || options.includes(code3),
            );
            return {
              title: `${tfm2}: ${title2}`,
              selectable: allLevelsSelectable,
              value: tfm2,
              key: tfm2,
              children:
                // Suffix level
                filteredTfm3Options.map((value) => {
                  return {
                    title: value,
                    value: value,
                    key: value,
                  };
                }),
            };
          }),
        };
      }),
    };
  });

  const handleSystemTreeFilter = (search: string, item: DefaultOptionType) => {
    return (
      item !== undefined &&
      item.title !== undefined &&
      String(item.title).toLowerCase().indexOf(search.toLowerCase()) >= 0
    );
  };

  const handleNonExistingSystemsChange = (e: CheckboxChangeEvent) => {
    setShowNonExistingSystems(e.target.checked);
  };

  return (
    <Row>
      <Col span={24}>
        <Card>
          {showNonExistingSystemsCheckbox && (
            <Row gutter={16}>
              <Col span={4} />
              <Col span={20}>
                <Checkbox onChange={handleNonExistingSystemsChange}>
                  {t("tfm.system.show-not-entered-systems")}
                </Checkbox>
              </Col>
            </Row>
          )}
          <FormItem
            label={t("tfm.system.system")}
            name="system"
            rules={[
              ...(isRequired
                ? [
                    {
                      required: true,
                      message: t("tfm.system.you-must-choose-a-system"),
                    },
                  ]
                : []),
            ]}
            style={{ marginTop: "12px" }}
            wrapperCol={{ span: 20 }}
          >
            <TreeSelect
              allowClear={!isRequired}
              defaultValue={value}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={tfmTreeData}
              placeholder={t("tfm.system.select-system")}
              filterTreeNode={handleSystemTreeFilter}
              showSearch
            />
          </FormItem>
        </Card>
      </Col>
    </Row>
  );
}
