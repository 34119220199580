import { formatMeasurement } from "@properate/common";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { Button, Col, Modal, Row, Space } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  HighlightTwoTone,
  InfoOutlined,
} from "@ant-design/icons";
import { ScaleOrdinal } from "d3-scale";
import dayjs from "@properate/dayjs";
import { useCallback, useContext, useEffect } from "react";
import { ThemeContext } from "styled-components";
import Color from "color";
import {
  AccordionActionType,
  AccordionEntry,
  useAccordionActions,
} from "@properate/ui";
import { MessageKey, useTranslations } from "@properate/translations";
import { DESCRIPTION_LEGEND_VALUES, Legend } from "@/features/energy";
import { ENERGY_NOTE_FILTER_ID } from "../../utils";
import { useNoteCheckByLabelAndNoteIdForEnergyLegend } from "./utils";

type Props = {
  legend: Legend;
  start: Date;
  end: Date;
  nextStart?: Date;
  nextEnd?: Date;
  legendScale: ScaleOrdinal<string, string>;
  operationalScale: ScaleOrdinal<string, string>;
  showTemperature: boolean;
  setShowTemperature: (showTemperature: boolean) => void;
  showNotes: boolean;
  setShowNotes: (showNotes: boolean) => void;
  showNonOperational: boolean;
  setShowNonOperational: (showNonOperational: boolean) => void;
  showHolidays: boolean;
  setShowHolidays: (showHolidays: boolean) => void;
  showEPred: boolean;
  setShowEPred: (showEPred: boolean) => void;
  onHighlight: (highlight: string | null) => void;
  highlight: string | null;
};
export const GraphLegend = ({
  legend,
  start,
  end,
  nextStart,
  nextEnd,
  legendScale,
  operationalScale,
  showTemperature,
  setShowTemperature,
  showNotes,
  setShowNotes,
  showNonOperational,
  setShowNonOperational,
  showHolidays,
  setShowHolidays,
  showEPred,
  setShowEPred,
  onHighlight,
  highlight,
}: Props) => {
  const t = useTranslations();
  const themeContext = useContext(ThemeContext);
  const accordionDispatch = useAccordionActions();
  const filterByLabelAndNoteId = useNoteCheckByLabelAndNoteIdForEnergyLegend();

  const removeFilter = useCallback(() => {
    accordionDispatch({
      type: AccordionActionType.removeFilter,
      filterId: ENERGY_NOTE_FILTER_ID,
    });
  }, [accordionDispatch]);

  useEffect(() => {
    return removeFilter;
  }, [removeFilter]);

  const toggleHighlight = (label: string, externalId: string) => () => {
    if (highlight === label) {
      onHighlight(null);
      removeFilter();
    } else {
      accordionDispatch({
        type: AccordionActionType.addFilter,
        filterId: ENERGY_NOTE_FILTER_ID,
        filterFunction: (entry: AccordionEntry) =>
          filterByLabelAndNoteId(entry.uniqueId, externalId),
        enable: true,
      });
      onHighlight(label);
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const handleShowLegendInfo = (label: string) => () => {
    const l = legend.find((l) => l.name === label);

    const columns = [];
    if (l?.source) {
      columns.push(
        <Col span={4} key="source">
          {t("energy.source")}:
        </Col>,
      );
      columns.push(
        <Col span={20} key="source-value">
          {l?.source}
        </Col>,
      );
    }
    if (l?.expression) {
      columns.push(
        <Col span={4} key="expression">
          {t("energy.expression")}:
        </Col>,
      );
      columns.push(
        <Col span={20} key="expression-value">
          {l?.expression}
        </Col>,
      );
    }
    columns.push(
      <Col span={4} key="id">
        {t("energy.id")}:
      </Col>,
    );
    columns.push(
      <Col span={20} key="id-value">
        {l?.externalId}
      </Col>,
    );
    columns.push(
      <Col span={8} key="total">
        {`${dayjs(start).format("DD.MM.YYYY")} - ${dayjs(end).format(
          "DD.MM.YYYY",
        )}`}
      </Col>,
    );
    columns.push(
      <Col span={16} key="total-value">
        {formatMeasurement({
          value: l?.total || 0,
          unit: "kWh",
        })}
      </Col>,
    );

    if (l?.["next-total"]) {
      columns.push(
        <Col span={8} key="next-total">
          {`${dayjs(nextStart).format("DD.MM.YYYY")} - ${dayjs(nextEnd).format(
            "DD.MM.YYYY",
          )}`}
        </Col>,
      );
      columns.push(
        <Col span={16} key="next-total-value">
          {formatMeasurement({
            value: l?.["next-total"] || 0,
            unit: "kWh",
          })}
        </Col>,
      );
    }
    Modal.info({
      width: 600,
      icon: null,
      title: label,
      content: <Row gutter={[8, 8]}>{columns}</Row>,
    });
  };

  const toggleVisibility = (label: string) => () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    if (label === DESCRIPTION_LEGEND_VALUES.temperature) {
      setShowTemperature(!showTemperature);
      return;
    }

    if (label === DESCRIPTION_LEGEND_VALUES.nonOperational) {
      setShowNonOperational(!showNonOperational);
      return;
    }

    if (label === DESCRIPTION_LEGEND_VALUES.holidays) {
      setShowHolidays(!showHolidays);
    }

    if (label === DESCRIPTION_LEGEND_VALUES.ePred) {
      setShowEPred(!showEPred);
    }

    if (label === DESCRIPTION_LEGEND_VALUES.hasNote) {
      setShowNotes(!showNotes);
    }
  };

  const getIconForLabel = (label: string) => {
    if (label === DESCRIPTION_LEGEND_VALUES.temperature) {
      return showTemperature ? <EyeOutlined /> : <EyeInvisibleOutlined />;
    }

    if (label === DESCRIPTION_LEGEND_VALUES.nonOperational) {
      return showNonOperational ? <EyeOutlined /> : <EyeInvisibleOutlined />;
    }

    if (label === DESCRIPTION_LEGEND_VALUES.holidays) {
      return showHolidays ? <EyeOutlined /> : <EyeInvisibleOutlined />;
    }

    if (label === DESCRIPTION_LEGEND_VALUES.ePred) {
      return showEPred ? <EyeOutlined /> : <EyeInvisibleOutlined />;
    }

    if (label === DESCRIPTION_LEGEND_VALUES.hasNote) {
      return showNotes ? <EyeOutlined /> : <EyeInvisibleOutlined />;
    }

    return null;
  };

  return (
    <div
      style={{
        flex: "none",
        width: "100%",
      }}
    >
      <div style={{ width: "100%", display: "flex", fontSize: 12 }}>
        <div style={{ flex: "1 auto" }}>
          <LegendOrdinal
            scale={legendScale}
            direction="row"
            labelMargin="0 15px 0 0"
          >
            {(labels) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {labels.map((label, i) => (
                  <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                    <svg width={15} height={15} style={{ marginRight: 5 }}>
                      <rect
                        fill={
                          label.text === highlight
                            ? Color(label.value).darken(0.4).hex()
                            : label.value
                        }
                        width={15}
                        height={15}
                      />
                    </svg>
                    <LegendLabel align="left" margin="0 0 0 4px">
                      <Space>
                        {label.text}
                        <Button
                          size="small"
                          icon={
                            <HighlightTwoTone
                              twoToneColor={
                                label.text === highlight
                                  ? themeContext.primary
                                  : themeContext.neutral6
                              }
                            />
                          }
                          type="default"
                          onClick={toggleHighlight(
                            label.text,
                            legend[label.index].externalId,
                          )}
                        />
                        <Button
                          size="small"
                          icon={<InfoOutlined />}
                          type="default"
                          onClick={handleShowLegendInfo(label.text)}
                        />
                      </Space>
                    </LegendLabel>
                  </LegendItem>
                ))}
              </div>
            )}
          </LegendOrdinal>
        </div>
        <div style={{ flex: "0" }}>
          <LegendOrdinal
            scale={operationalScale}
            direction="row"
            labelMargin="0 15px 0 0"
          >
            {(labels) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                  width: 250,
                }}
              >
                {labels.map((label, i) => (
                  <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                    <svg width={15} height={15} style={{ marginRight: 5 }}>
                      <rect fill={label.value} width={15} height={15} />
                    </svg>
                    <LegendLabel align="left" margin="0 0 0 4px">
                      {t(`energy.legend-values.${label.text}` as MessageKey)}{" "}
                      <Button
                        size="small"
                        icon={getIconForLabel(label.text)}
                        type="link"
                        onClick={toggleVisibility(label.text)}
                      />
                    </LegendLabel>
                  </LegendItem>
                ))}
              </div>
            )}
          </LegendOrdinal>
        </div>
      </div>
    </div>
  );
};
