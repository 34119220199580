import { Circle } from "@visx/shape";
import { Group } from "@visx/group";
import React, { useContext } from "react";
import { ScaleLinear, ScaleOrdinal } from "d3-scale";
import { ThemeContext } from "styled-components";
import { TooltipData } from "@/features/energy";

export const ETChartHighlightedCircle = ({
  tooltipData,
  margin,
  latestDatapointDate,
  xScale,
  yScale,
  showEnergyPerArea,
  usableFloorArea,
  colorScale,
  radius = 6,
}: {
  tooltipData: TooltipData;
  margin: { top: number; right: number; bottom: number; left: number };
  latestDatapointDate: Date;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  showEnergyPerArea: boolean;
  usableFloorArea: number;
  colorScale: ScaleOrdinal<string, string>;
  radius?: number;
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Group left={margin.left} top={margin.top}>
      {tooltipData.temperature !== undefined &&
      tooltipData.energy !== undefined &&
      tooltipData.operational !== undefined &&
      tooltipData.timestamp &&
      latestDatapointDate &&
      tooltipData.timestamp <= latestDatapointDate.valueOf() ? (
        <Circle
          key={tooltipData.timestamp}
          cx={xScale(tooltipData.temperature)}
          cy={yScale(
            showEnergyPerArea
              ? tooltipData.energy / usableFloorArea
              : tooltipData.energy,
          )}
          r={radius}
          fill={colorScale(tooltipData.operational)}
          fillOpacity={1}
          strokeOpacity={1}
          stroke={themeContext.neutral5}
          style={{ strokeWidth: 2 }}
        />
      ) : null}
    </Group>
  );
};
