import useSWR from "swr";
import { roomsIndex } from "@/eepApi";
import { useFloorPlan } from "../FloorPlanContext";
import { useFloorPlanPin } from "../FloorPlanMapPinContext";
import { FloorPlan } from "../types";

async function fetchRoomTimeseriesId([_, roomId, sensorType]: [
  string,
  number,
  FloorPlan["roomsView"],
]) {
  const room = await roomsIndex.getDocument(roomId);

  if (sensorType === "motion") {
    return room.motion?.value;
  }

  return room[sensorType ?? "temperature"]?.mean;
}

/**
 * Get the timeseries ID to use for displaying the pin.
 *
 * @returns The timeseries ID to use for displaying the pin.
 */
export function useFloorPlanPinTimeseriesId() {
  const pin = useFloorPlanPin();
  const floorPlan = useFloorPlan();

  const timeseriesId = useSWR(
    pin.type === "room" ? ["room", pin.roomId, floorPlan.roomsView] : null,
    fetchRoomTimeseriesId,
  );

  if (pin.type !== "room") {
    return pin.timeseriesId;
  }

  return timeseriesId.data ?? null;
}
