import * as React from "react";
import { ScaleOrdinal } from "d3-scale";
import { useTooltipInPortal } from "@visx/tooltip";
import { MouseEventHandler, useMemo } from "react";
import { scaleBand, scaleLinear } from "@visx/scale";
import _ from "lodash";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { SidebarActionType, useSidebarActions } from "@properate/ui";
import dayjs from "@properate/dayjs";
import { extent } from "d3-array";
import {
  EnergyNoteInfo,
  OperationalPeriods,
  TooltipContent,
  TooltipData,
} from "@/features/energy";
import { Granularity } from "@/utils/helpers";
import { MARGIN } from "./utils";
import { EnergyGraphContent } from "./EnergyGraphContent";

type Props = {
  width: number;
  height: number;
  bands: Date[];
  max: number;
  nonOperationalPeriods: OperationalPeriods[];
  holidayPeriods: OperationalPeriods[];
  tickValues?: number[];
  graphPoints: Record<string, number | string>[];
  onShowTooltip: ({ tooltipData }: { tooltipData: TooltipData }) => void;
  onHideTooltip: () => void;
  tooltipOpen: boolean;
  tooltipData?: TooltipData;
  showNonOperational: boolean;
  showHolidays: boolean;
  showTemperature: boolean;
  showNotes: boolean;
  showEPred: boolean;
  colorScale: ScaleOrdinal<string, string>;
  operationalScale: ScaleOrdinal<string, string>;
  legend: string[];
  highlightedLegend: string | null;
  noteInfo?: EnergyNoteInfo;
  granularity?: Granularity;
};
export const EnergyGraph = ({
  width,
  height,
  bands,
  max,
  nonOperationalPeriods,
  holidayPeriods,
  tickValues,
  graphPoints,
  onShowTooltip,
  onHideTooltip,
  tooltipOpen,
  tooltipData,
  showNonOperational,
  showHolidays,
  showTemperature,
  showNotes,
  showEPred,
  colorScale,
  operationalScale,
  legend,
  highlightedLegend,
  noteInfo: {
    notesDataPeriods = [],
    isNoteModeOn = false,
    setSelectedPoints,
    selectedPoints,
  } = {},
  granularity,
}: Props) => {
  const { containerRef, TooltipInPortal } = useTooltipInPortal();
  const innerWidth = width - MARGIN.left - MARGIN.right;
  const innerHeight = height - MARGIN.top - MARGIN.bottom;
  const sidebarDispatch = useSidebarActions();

  const xScale = useMemo(
    () => scaleBand().domain(bands).range([0, innerWidth]).padding(0.05),
    [bands, innerWidth],
  );

  const xMargin = xScale(bands[0].valueOf()) || 0 / 2;

  const xMetricsScale = useMemo(
    () =>
      scaleLinear<number>()
        .domain(
          extent([
            ...bands,
            dayjs(bands[bands.length - 1])
              .add(1, granularity)
              .valueOf(),
          ]) as number[],
        )
        .range([xMargin, innerWidth - xMargin]),
    [bands, innerWidth, granularity, xMargin],
  );

  const filteredData = useMemo(
    () => graphPoints.filter((d) => xScale(d.timestamp) !== undefined),
    [graphPoints, xScale],
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [innerHeight, 0],
        domain: [0, max] as [number, number],
        nice: true,
      }),
    [innerHeight, max],
  );

  const handleMouseMove: MouseEventHandler<SVGRectElement> = (event) => {
    const point = localPoint(event);

    const x = point!.x - MARGIN.left;

    const index = Math.min(
      Math.floor(x / (xScale.bandwidth() + xScale.bandwidth() * 0.05)),
      filteredData.length - 1,
    );
    const timestamp = filteredData[index]?.timestamp as number;
    const nextTimestamp = filteredData[index]?.["next-timestamp"] as number;

    const notes = notesDataPeriods.filter((noteRange) => {
      return (
        noteRange.start <= timestamp &&
        dayjs(noteRange.end).subtract(1, granularity).valueOf() >= timestamp
      );
    });

    onShowTooltip({
      tooltipData: {
        energy: filteredData[index]?.total as number,
        "next-energy": filteredData[index]?.["next-total"] as number,
        temperature: filteredData[index]?.temperature as number,
        "next-temperature": filteredData[index]?.["next-temperature"] as number,
        timestamp,
        "next-timestamp": nextTimestamp,
        operational: filteredData[index]?.operational as string,
        "next-operational": filteredData[index]?.["next-operational"] as string,
        ...(notes &&
          notes?.length > 0 && {
            note: {
              title: notes?.[0]?.noteTitle,
              count: notes?.length,
            },
          }),
      },
    });
  };

  const handleMouseClick: MouseEventHandler<SVGRectElement> = (event) => {
    if (!isNoteModeOn) return;

    const point = localPoint(event);

    const x = point!.x - MARGIN.left;

    const index = Math.min(
      Math.floor(x / (xScale.bandwidth() + xScale.bandwidth() * 0.05)),
      filteredData.length - 1,
    );
    const timestamp = filteredData[index]?.timestamp as number;

    if (setSelectedPoints) {
      const data = {
        energy: filteredData[index]?.total as number,
        temperature: filteredData[index]?.temperature as number,
        timestamp,
        operational: filteredData[index]?.operational as string,
      };

      setSelectedPoints((points) => {
        if (points?.[0] && !points[1]) {
          return points[0].timestamp > data.timestamp
            ? [data, points[0]]
            : [points[0], data];
        }
        return [data];
      });
    }

    sidebarDispatch({
      type: SidebarActionType.open,
    });
  };

  const handleMouseLeave: MouseEventHandler<SVGRectElement> = useMemo(
    () => _.debounce(onHideTooltip, 200),
    [onHideTooltip],
  );
  if (height === 0) {
    return <></>;
  }

  const bandWidth = Math.max(xScale.bandwidth(), 1);

  return (
    <div ref={containerRef}>
      <svg width={width} height={height + 10}>
        <EnergyGraphContent
          innerWidth={innerWidth}
          innerHeight={innerHeight}
          nonOperationalPeriods={nonOperationalPeriods}
          notesDataPeriods={notesDataPeriods}
          holidayPeriods={holidayPeriods}
          tickValues={tickValues}
          graphPoints={filteredData}
          showNonOperational={showNonOperational}
          showHolidays={showHolidays}
          showTemperature={showTemperature}
          showNotes={showNotes}
          showEPred={showEPred}
          colorScale={colorScale}
          operationalScale={operationalScale}
          legend={legend}
          highlightedLegend={highlightedLegend}
          xScale={xScale}
          xMetricsScale={xMetricsScale}
          yScale={yScale}
          granularity={granularity}
        />
        <Group left={MARGIN.left} top={MARGIN.top}>
          {typeof tooltipData?.energy === "number" &&
            typeof tooltipData?.timestamp === "number" && (
              <rect
                x={xScale(tooltipData.timestamp)}
                y={yScale(tooltipData.energy)}
                height={innerHeight - yScale(tooltipData.energy)}
                width={bandWidth}
                fill={"rgba(0, 0, 0, 0.2)"}
              />
            )}
        </Group>
        <Group left={MARGIN.left} top={MARGIN.top}>
          {selectedPoints &&
            typeof selectedPoints?.[0]?.timestamp === "number" && (
              <rect
                x={xScale(selectedPoints?.[0].timestamp)}
                y={0}
                height={innerHeight}
                width={
                  selectedPoints[1]?.timestamp
                    ? (xScale(selectedPoints[1].timestamp) || 0) -
                      (xScale(selectedPoints[0].timestamp) || 0) +
                      bandWidth
                    : bandWidth
                }
                fill={"rgba(0, 0, 0, 0.2)"}
                stroke={"rgba(0, 0, 0, 0.5)"}
              />
            )}
        </Group>
        <rect
          x={MARGIN.left}
          y={MARGIN.top}
          width={innerWidth}
          height={innerHeight}
          onClick={handleMouseClick}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          fill="transparent"
          cursor="pointer"
        />
      </svg>
      {tooltipOpen &&
        tooltipData?.timestamp &&
        xScale(tooltipData.timestamp) && (
          <TooltipInPortal
            key={Math.random()}
            left={xScale(tooltipData.timestamp)! + MARGIN.left + 10}
            top={innerHeight / 2 - 20 + MARGIN.top}
          >
            <TooltipContent {...tooltipData} />
          </TooltipInPortal>
        )}
    </div>
  );
};
