import { SearchForFacetValuesParams } from "meilisearch/src/types";
import { z } from "zod";
import { SearchParams } from "meilisearch";
import http, { isHttpError } from "./lib/http";
import { UserSchema } from "./schemas";
import {
  ComponentSearchable,
  RoomInfoType,
  SearchIndex,
  TimeseriesExternalIdSearchable,
  TimeseriesSearchable,
} from "./types";
import { getMeiliSearch } from "./lib/meilisearch";

export * from "./types";
export { setupApiAuth } from "./lib/auth";
export { default as api } from "./lib/http";

export async function fetchLoginStatus() {
  try {
    const data = await http.get("login/status").json();
    const users = z.record(z.string(), UserSchema).parse(data);

    return {
      data: Object.values(users)[0],
    };
  } catch (error) {
    if (isHttpError(error, 401)) {
      return { error: "access-denied" };
    }

    return { error: "other" };
  }
}

export async function fetchUsers() {
  const data = await http.get("users/list").json();

  return z.record(z.string(), UserSchema).parse(data);
}

export const timeseriesIndex: SearchIndex<TimeseriesSearchable> = {
  async search(query?: string | null, options?: SearchParams) {
    const c = await getMeiliSearch();
    return c.index("timeseries").search<TimeseriesSearchable>(query, options);
  },
  async getDocument(documentId: string | number) {
    const c = await getMeiliSearch();
    return c.index("timeseries").getDocument(documentId);
  },
  async searchForFacetValues(params: SearchForFacetValuesParams) {
    const c = await getMeiliSearch();
    return c.index("timeseries").searchForFacetValues(params);
  },
};

export const componentsIndex = {
  search: async (query?: string | null, options?: SearchParams) => {
    const c = await getMeiliSearch();
    return c.index("components").search<ComponentSearchable>(query, options);
  },
  getDocument: async (documentId: string | number) => {
    const c = await getMeiliSearch();
    return c.index("components").getDocument(documentId);
  },
  searchForFacetValues: async (params: SearchForFacetValuesParams) => {
    const c = await getMeiliSearch();
    return c.index("components").searchForFacetValues(params);
  },
};

export const timeseriesExternalIdsIndex = {
  async search(query?: string | null, options?: SearchParams) {
    const c = await getMeiliSearch();
    return c
      .index("timeseriesExternalIds")
      .search<TimeseriesExternalIdSearchable>(query, options);
  },
  async searchForFacetValues(params: SearchForFacetValuesParams) {
    const c = await getMeiliSearch();
    return c.index("timeseriesExternalIds").searchForFacetValues(params);
  },
};

export const assetsIndex = {
  async search(query?: string | null, options?: SearchParams) {
    const c = await getMeiliSearch();
    return c.index("assets").search(query, options);
  },
};

export const roomsIndex = {
  async search(query?: string | null, options?: SearchParams) {
    const c = await getMeiliSearch();
    return c.index("rooms").search(query, options);
  },
  async getDocument(documentId: number): Promise<RoomInfoType> {
    const c = await getMeiliSearch();
    return c.index("rooms").getDocument(documentId);
  },
};
