import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { Modal, Form, Select, Button, Tabs, TabsProps, Input } from "antd";
import { useTranslations } from "@properate/translations";
import {
  MILLISECONDS_IN_DAY,
  MILLISECONDS_IN_HOUR,
  MILLISECONDS_IN_MINUTE,
} from "../constants";
import { CalculationFlow, ScheduleItem } from "../types";
import { ScheduleTable } from "./ScheduleTable";

const FormItem = Form.Item;

interface Props {
  open: boolean;
  calculationFlow: CalculationFlow;
  onCancel: () => void;
  onOk: (calculationFlow: CalculationFlow) => void;
}

export const SettingsModal: FunctionComponent<Props> = ({
  open,
  calculationFlow,
  onCancel,
  onOk,
}) => {
  const t = useTranslations();
  const [newCalculationFlow, setNewCalculationFlow] = useState(calculationFlow);

  useEffect(() => {
    if (!open) {
      setNewCalculationFlow(calculationFlow);
    }
  }, [calculationFlow, newCalculationFlow, open]);

  const handleFrequencyChange = (frequency: number) => {
    const new_freq = Math.sign(newCalculationFlow.frequency) * frequency;
    setNewCalculationFlow({ ...newCalculationFlow, frequency: new_freq });
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewCalculationFlow({
      ...newCalculationFlow,
      description: event.target.value,
    });
  };

  const handlePlanChange = (newPlan: ScheduleItem[]) => {
    setNewCalculationFlow({ ...newCalculationFlow, plan: newPlan });
  };

  const handleExceptionsChange = (newExceptions: ScheduleItem[]) => {
    setNewCalculationFlow({
      ...newCalculationFlow,
      exceptions: newExceptions,
    });
  };

  const tabItems: TabsProps["items"] = [
    {
      label: t("calculation-flow.general-settings"),
      key: "general",
      children: (
        <Form>
          <FormItem label={t("calculation-flow.calculation-frequency")}>
            <Select
              onChange={handleFrequencyChange}
              value={Math.abs(newCalculationFlow.frequency)}
              options={[
                {
                  value: 5 * MILLISECONDS_IN_MINUTE,
                  label: t("calculation-flow.frequency.5-minutes"),
                },
                {
                  value: 10 * MILLISECONDS_IN_MINUTE,
                  label: t("calculation-flow.frequency.10-minutes"),
                },
                {
                  value: 15 * MILLISECONDS_IN_MINUTE,
                  label: t("calculation-flow.frequency.15-minutes"),
                },
                {
                  value: 30 * MILLISECONDS_IN_MINUTE,
                  label: t("calculation-flow.frequency.30-minutes"),
                },
                {
                  value: 1 * MILLISECONDS_IN_HOUR,
                  label: t("calculation-flow.frequency.1-hour"),
                },
                {
                  value: 3 * MILLISECONDS_IN_HOUR,
                  label: t("calculation-flow.frequency.3-hours"),
                },
                {
                  value: 6 * MILLISECONDS_IN_HOUR,
                  label: t("calculation-flow.frequency.6-hours"),
                },
                {
                  value: 12 * MILLISECONDS_IN_HOUR,
                  label: t("calculation-flow.frequency.12-hours"),
                },
                {
                  value: 1 * MILLISECONDS_IN_DAY,
                  label: t("calculation-flow.frequency.1-day"),
                },
                {
                  value: 7 * MILLISECONDS_IN_DAY,
                  label: t("calculation-flow.frequency.1-week"),
                },
              ]}
            />
          </FormItem>
          <FormItem label={t("calculation-flow.description")}>
            <Input.TextArea
              rows={4}
              onChange={handleDescriptionChange}
              value={newCalculationFlow.description}
            />
          </FormItem>
        </Form>
      ),
    },
    {
      label: t("calculation-flow.plan"),
      key: "plan",
      children: (
        <Form>
          {t("calculation-flow.plan-description")}
          <ScheduleTable
            scheduleItems={newCalculationFlow.plan || []}
            onChange={handlePlanChange}
          />
        </Form>
      ),
    },
    {
      label: t("calculation-flow.exceptions"),
      key: "unntak",
      children: (
        <Form>
          {t("calculation-flow.exceptions-description")}
          <ScheduleTable
            scheduleItems={newCalculationFlow.exceptions || []}
            onChange={handleExceptionsChange}
          />
        </Form>
      ),
    },
  ];

  return (
    <Modal
      title={t("calculation-flow.settings")}
      open={open}
      onCancel={onCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => onOk(newCalculationFlow)}
        >
          {t("calculation-flow.ok")}
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="general" items={tabItems} />
    </Modal>
  );
};
