import { useParams } from "react-router-dom";
import { useUser } from "@properate/auth";
import { useRootAssets } from "@/hooks/useRootAssets";
import { AccessModules, allAccessModules } from "@/features/organizationAdmin";
import {
  updateBuildingAccessModules,
  useOrganizationModulesByName,
} from "@/services/organization";
import { OrganizationAdminModules } from "@/pages/organizationAdmin/OrganizationAdminModules";
import { useAliasConfig } from "@/services/alias";
import { useBuildingSpec } from "@/services/buildingSpec";
import { BuildingInfo } from "@/components/Building/BuildingInfo";

export const OrganizationAdminBuildingModules = () => {
  const { organizationId, dataSetId } = useParams();
  const rootAssets = useRootAssets();

  const { ownerAlias } = useUser();
  const aliasConfig = useAliasConfig(ownerAlias);

  const selectedBuilding = rootAssets.find(
    (asset) => asset.dataSetId === Number(dataSetId),
  );

  const buildingsSpec = useBuildingSpec(selectedBuilding?.id);

  const organizationName = organizationId
    ? decodeURIComponent(organizationId)
    : selectedBuilding?.metadata?.owner || "";

  const { data: organizationData, isLoading } =
    useOrganizationModulesByName(organizationName);

  const organizationModules = organizationData?.modules || allAccessModules;

  const savedModules =
    (!!dataSetId && organizationData?.buildings[Number(dataSetId)]?.modules) ||
    organizationModules;

  const onUpdate = (values: AccessModules[]) => {
    const shouldBeRemoved =
      values.length === organizationModules.length &&
      values.every((module) => organizationModules.includes(module));

    return updateBuildingAccessModules(
      organizationName,
      Number(dataSetId),
      values,
      organizationData?.snapshotId,
      shouldBeRemoved,
    );
  };

  const buildingAddress = selectedBuilding?.metadata?.Adresse || "";
  const buildingAlias =
    selectedBuilding && aliasConfig?.buildings?.[selectedBuilding.id];

  const fullBuildingInfo = selectedBuilding ? (
    <BuildingInfo
      street={buildingAddress}
      place={selectedBuilding.metadata?.Poststed?.trimStart() || ""}
      alias={buildingAlias}
      imageUrl={buildingsSpec?.imageUrl}
    />
  ) : null;

  return (
    <OrganizationAdminModules
      breadcrumb={buildingAlias || buildingAddress}
      savedModules={savedModules}
      organizationName={organizationName}
      buildingContent={fullBuildingInfo}
      isLoading={isLoading}
      onUpdate={onUpdate}
    />
  );
};
