import { browserFirestore } from "@properate/firebase";
import { BuildingTenant } from "@properate/common";
import { arrayUnion, collection, doc, setDoc } from "firebase/firestore";
import useSWRMutation from "swr/mutation";

type TriggerArg = {
  buildingId: number;
  tenant: BuildingTenant;
};

export function useAddBuildingTenant() {
  return useSWRMutation(
    `building-tenants`,
    async (_, extra: { arg: TriggerArg }) => {
      const { buildingId, tenant } = extra.arg;

      const newTenant = {
        id: crypto.randomUUID(), // Generate an ID to be used as a key in the UI
        ...tenant,
      };

      await setDoc(
        doc(collection(browserFirestore, "buildings"), buildingId.toString()),
        { tenants: arrayUnion(newTenant) },
        { merge: true },
      );
    },
  );
}
