import { AlarmRuleTypeName } from "@properate/common";
import { useAlarmTimeseriesForSubBuildings } from "@/pages/alarms/details/hooks/useAlarmTimeseriesForSubBuildings";
import { alarmRuleTypes } from "@/pages/alarms/details/AlarmRuleTypes";
import { FixedTimeseriesName } from "@/pages/alarms/details/AlarmRuleTypes/common/FixedTimeseriesThresholdDelayOnly";
import { TimeseriesWithAlarmAssetName } from "@/pages/alarms/utils/getAlarmTimeseries";

export type AvailableAlarmRuleTypes = Partial<
  Record<
    AlarmRuleTypeName,
    {
      available: boolean;
      subBuildings?: {
        name: string;
        id: number;
        timeseries: TimeseriesWithAlarmAssetName[];
        available: boolean;
      }[];
    }
  >
>;

export type AvailableAlarmRuleTypesResult = {
  availableAlarmRuleTypes: AvailableAlarmRuleTypes;
  isLoading: boolean;
  error: Error | null;
};

export function useAvailableAlarmRuleTypes(): AvailableAlarmRuleTypesResult {
  const { alarmTimeseries, isLoading, error } =
    useAlarmTimeseriesForSubBuildings();

  const result: AvailableAlarmRuleTypes = {};

  function findSubBuildingAvailability(
    requiredTimeseries: FixedTimeseriesName[],
  ) {
    const subBuildings = (alarmTimeseries ?? []).map(
      ({ subBuildingName, subBuildingId, timeseries }) => {
        const filteredTimeseries = timeseries.filter(({ name }) =>
          requiredTimeseries.includes(name as FixedTimeseriesName),
        );
        return {
          name: subBuildingName,
          id: subBuildingId,
          timeseries: filteredTimeseries,
          available: filteredTimeseries.length > 0,
        };
      },
    );

    return {
      available: subBuildings.some(({ available }) => available),
      subBuildings,
    };
  }

  for (const [alarmRuleTypeName, alarmRuleType] of Object.entries(
    alarmRuleTypes,
  )) {
    if (!alarmRuleType.availability) {
      result[alarmRuleTypeName as AlarmRuleTypeName] = {
        available: true,
      };
      continue;
    }
    if (alarmRuleType.availability.subBuildingMustHaveNamedTimeseries) {
      result[alarmRuleTypeName as AlarmRuleTypeName] =
        findSubBuildingAvailability(
          alarmRuleType.availability.subBuildingMustHaveNamedTimeseries,
        );
      continue;
    }
    throw new Error(
      `Cannot determine availability for alarm rule type ${alarmRuleTypeName}`,
    );
  }
  return { availableAlarmRuleTypes: result, isLoading, error };
}
