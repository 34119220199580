import dayjs from "@properate/dayjs";
import { Holiday, ProperateCalendarEvent, Schedule } from "@/utils/types";

export const formatValue = (value: string) => {
  return `Verdi: ${value}`;
};

export const describeEventRecurrence = (schedule: Schedule) => {
  switch (schedule) {
    case "weekly":
      return "every-week";
    case "yearly":
      return "every-year";
    case "yearly_on_this_holiday":
      return "every-year-on-pub-holiday";
    default:
      return "only-this-event";
  }
};

export const getNextHour = (date: Date) => {
  const nextHour = dayjs(date)
    .hour(dayjs().hour())
    .startOf("hour")
    .add(1, "hour");
  return nextHour.isBefore(dayjs(date).endOf("day"))
    ? nextHour.toDate()
    : dayjs(date).startOf("day").add(1, "day").toDate();
};

export function isHoliday(
  event: ProperateCalendarEvent | Holiday,
): event is Holiday {
  return (event as Holiday).name !== undefined;
}
