import { Node } from "reactflow";
import { useTranslations } from "@properate/translations";
import { getNodeId } from "./helpers/Utils";
import {
  SmallNode,
  NodeOutput,
  NodeInput,
  Header,
  Body,
  NodeOutputLabel,
  NodeInputLabel,
} from "./helpers/NodeComponents";

interface SubNodeProps {
  operationId: string;
}

export const getEmptySubNode = (): Node<SubNodeProps> => {
  return {
    id: getNodeId("sub"),
    type: "sub",
    data: {
      operationId: "sub",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function SubNode() {
  const t = useTranslations();

  return (
    <SmallNode>
      <Header>{t("calculation-flow.node-types.subtraction")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <NodeInput inputId="b">
          <NodeInputLabel>B</NodeInputLabel>
        </NodeInput>
        <NodeOutput>
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <NodeOutputLabel>A - B</NodeOutputLabel>
        </NodeOutput>
      </Body>
    </SmallNode>
  );
}

export default SubNode;
