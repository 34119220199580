import { useEffect, useState } from "react";
import { Row } from "antd";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslations } from "@properate/translations";
import { useHelp } from "../../context/HelpContext";
import { useBuildingPageTitle } from "../../hooks/usePageTitle";
import { useBuildingSpec } from "../../services/buildingSpec";

const IFrameFill = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
`;

const Sd = () => {
  const t = useTranslations();
  useBuildingPageTitle(t("3rd-party.title"));
  const { id }: any = useParams();
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const { setHelp } = useHelp();
  const buildingSpec = useBuildingSpec(id);
  useEffect(() => {
    setHelp({
      title: t("3rd-party.title"),
      content: (
        <>
          <p>{t("3rd-party.help-description")}</p>
        </>
      ),
    });
  }, [t, setHelp]);
  const DEFAULT_SD_LINK = "https://properate.com/";
  return (
    <div style={{ display: "flex", flex: "1 1 auto" }}>
      {loadingError && (
        <Row justify="center" align="middle">
          <a href={buildingSpec?.sdLink || DEFAULT_SD_LINK}>
            {buildingSpec?.sdLink || DEFAULT_SD_LINK}
          </a>
        </Row>
      )}
      {buildingSpec && !loadingError && (
        <IFrameFill
          src={buildingSpec?.sdLink || DEFAULT_SD_LINK}
          onLoad={(e) => {
            try {
              console.warn((e.target as HTMLFrameElement).contentDocument);
            } catch (error: any) {
              setLoadingError(true);
            }
          }}
        />
      )}
    </div>
  );
};

export default Sd;
