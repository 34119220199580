import { useUser } from "@properate/auth";
import { AlarmDetailsGridLayout } from "@/pages/alarms/details/components/layout";
import { AlarmDetailsFooter } from "../components/AlarmDetailsFooter";
import { CommonAlarmSettingsFields } from "./CommonAlarmSettingsFields";
import { AlarmSettingsFieldsForType } from "./AlarmSettingsFieldsForType";

export function AlarmSettingsPage() {
  const user = useUser();

  return (
    <AlarmDetailsGridLayout
      firstColumn={<CommonAlarmSettingsFields />}
      secondColumn={<AlarmSettingsFieldsForType />}
      footer={user.isAdmin ? <AlarmDetailsFooter /> : null}
    />
  );
}

export { getAlarmSettingsFormFields } from "./getAlarmSettingsFormFields";
