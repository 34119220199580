import { Timeseries } from "@cognite/sdk";
import { Button } from "antd";
import { PropsWithChildren, useEffect, useState } from "react";
import {
  useTranslations,
  useTranslationsWithFallback,
} from "@properate/translations";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { MessageKey } from "@/utils/types";
import {
  CategoryOption,
  TimeseriesHideableFilter,
  TimeseriesInitialFilters,
} from "../../types";
import { DEFAULT_CATEGORY_OPTIONS } from "../../utils";
import { useSubBuildings } from "../../hooks";
import {
  MULTIPLE_TIMESERIES_MODALS_ERROR,
  NO_TIMESERIES_MODAL_ERROR,
} from "../../hooks/useTableHeight";
import { TimeseriesSelectionModalContent } from "./TimeseriesSelectionModalContent";
import { TimeseriesSelectionModalFooter } from "./TimeseriesSelectionModalFooter";
import { WhiteModal } from "./elements";

interface Props {
  open: boolean;
  onHide: () => unknown;
  selectedIds: number[];
  initialFilters?: TimeseriesInitialFilters;
  hiddenFilters?: TimeseriesHideableFilter[];
  categoryOptions?: CategoryOption[]; // First option is assumed to be the default
  onOk: (timeseriesList: Timeseries[]) => unknown;
  disabledIds?: number[];
  max?: number;
  min?: number;
  disableClear?: boolean;
  initialSearch?: string;
}

export function TimeseriesSelectionModal({
  initialFilters,
  open,
  onHide,
  selectedIds,
  hiddenFilters = [],
  disabledIds = [],
  categoryOptions: categoryOptionsArray,
  max = Number.MAX_SAFE_INTEGER,
  min = 0,
  onOk,
  disableClear = false,
  initialSearch = "",
}: Props) {
  const t = useTranslationsWithFallback();
  const categoryOptions =
    categoryOptionsArray ||
    DEFAULT_CATEGORY_OPTIONS.map((item) => ({
      ...item,
      label: t.fallback([
        ("common.timeseries-modal.filter-categories." +
          item.label) as MessageKey,
      ]),
    }));

  const subBuildings = useSubBuildings({
    buildingId: initialFilters?.buildingId,
  });

  const [selectedIdsLocal, setSelectedIdsLocal] = useState(selectedIds);

  useEffect(() => {
    setSelectedIdsLocal(selectedIds);
  }, [selectedIds, setSelectedIdsLocal]);

  const { timeseriesList: selectedTimeseries } =
    useGetTimeseriesList(selectedIdsLocal);

  function handleChange(timeseries: Timeseries) {
    if (selectedIdsLocal.includes(timeseries.id)) {
      setSelectedIdsLocal([]);
    } else {
      setSelectedIdsLocal([timeseries.id]);
    }
  }

  function handleAdd(timeseries: Timeseries) {
    setSelectedIdsLocal([...selectedIdsLocal, timeseries.id]);
  }

  function handleRemove(id: number) {
    setSelectedIdsLocal(
      selectedIdsLocal.filter((selectedId) => selectedId !== id),
    );
  }

  function getTitle() {
    const categoryDescription =
      initialFilters?.category &&
      t(
        `common.setpoints-query-labels.${initialFilters.category}` as MessageKey,
      );

    if (!categoryDescription) {
      return t("common.timeseries-modal.select-timeseries", {
        number: "",
        category: "",
      });
    } else if (max === 1) {
      return t("common.timeseries-modal.add-timeseries", {
        number: "",
        category: categoryDescription,
      });
    } else if (min === max) {
      return t("common.timeseries-modal.select-timeseries", {
        number: min,
        category: categoryDescription,
      });
    }

    return t("common.timeseries-modal.select-timeseries", {
      number: "",
      category: categoryDescription,
    });
  }

  function getButtonTitle() {
    if (selectedIdsLocal.length < min) {
      return t("common.timeseries-modal.you-should-select-timeseries-first", {
        number: min === 1 ? 0 : min,
      });
    }
    return undefined;
  }

  function handleClickOk() {
    if (selectedTimeseries.length > 0) {
      onOk(selectedTimeseries);
    }
    onHide();
  }

  function handleClickRemove() {
    onOk([]);
    onHide();
  }

  return (
    <WhiteModal
      title={<h3>{getTitle()}</h3>}
      width={1500}
      centered
      open={open}
      onCancel={onHide}
      footer={
        <TimeseriesSelectionModalFooter
          okButton={
            <Button
              data-testid={"add-timeseries-ok-button"}
              type="primary"
              onClick={handleClickOk}
              disabled={selectedIdsLocal.length < min}
              title={getButtonTitle()}
            >
              {t("common.timeseries-modal.ok")}
            </Button>
          }
          onClickRemove={handleClickRemove}
          isRemoveVisible={disableClear === false && selectedIds.length > 0}
        />
      }
      bodyStyle={{
        height: "min(1200px, 80vh)",
      }}
      // This is used in "useTableHeight"
      className="timeseries-selection-modal"
      destroyOnClose
    >
      <ErrorRecoveryBoundary>
        <TimeseriesSelectionModalContent
          initialFilters={initialFilters}
          hiddenFilters={hiddenFilters}
          selectedIds={selectedIdsLocal}
          disabledIds={disabledIds}
          categoryOptions={categoryOptions}
          subBuildings={subBuildings?.data}
          onChange={handleChange}
          onAdd={handleAdd}
          onRemove={handleRemove}
          max={max}
          initialSearch={initialSearch}
        />
      </ErrorRecoveryBoundary>
    </WhiteModal>
  );
}

function ErrorRecoveryBoundary(props: PropsWithChildren) {
  const t = useTranslations();
  const [errorCount, setErrorCount] = useState(0);

  if (errorCount === 5) {
    return <>{t("pages.error.default.description")}</>;
  }

  return (
    <ErrorBoundary
      FallbackComponent={RetryRenderAfterDelay}
      onError={() => setErrorCount((prev) => prev + 1)}
    >
      {props.children}
    </ErrorBoundary>
  );
}

function RetryRenderAfterDelay({ resetErrorBoundary, error }: FallbackProps) {
  useEffect(() => {
    if (
      // Sometimes there is a race condition where the timeseries table gets mounted before
      // the AntD modal is fully added to the DOM, which causes the table to throw an error.
      // This is a retry mechanism to catch this race condition.
      error.message === NO_TIMESERIES_MODAL_ERROR ||
      error.message === MULTIPLE_TIMESERIES_MODALS_ERROR
    ) {
      const timeout = setTimeout(() => {
        resetErrorBoundary();
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [resetErrorBoundary, error.message]);

  return <></>;
}
