import { Radio } from "antd";

interface Props {
  options: string[];
  value?: string;
  onChange?: (value: string) => unknown;
}

export function RadioGroupUnits({ value, onChange, options }: Props) {
  return (
    <Radio.Group value={value} onChange={(e) => onChange?.(e.target.value)}>
      {options.map((unitOption) => (
        <Radio key={unitOption} value={unitOption}>
          {unitOption}
        </Radio>
      ))}
    </Radio.Group>
  );
}
