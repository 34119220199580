import { Space, Tooltip } from "antd";
import { useTranslations } from "@properate/translations";
import { WasteCardKpiType } from "@/pages/waste/types";
import { KpiCard, StyledStatistic, TooltipDescription } from "./../../elements";

const getType = (value: number) => {
  if (value < 60) {
    return "error";
  }
  if (value >= 60 && value < 70) {
    return "warning";
  }
  if (value >= 70 && value < 75) {
    return "ok";
  }
  return "good";
};

export const SortDegreeCardKpi = ({
  title,
  value,
  unit,
  prefix,
}: WasteCardKpiType) => {
  const t = useTranslations();
  if (typeof value !== "number") {
    return (
      <KpiCard>
        <div className="ant-card-body">
          <StyledStatistic title={title} value="--" type="ok" />
        </div>
      </KpiCard>
    );
  }

  const DESCRIPTION_KPI_SORT_DEGREE_TOOLTIP = (
    <Space direction="vertical">
      <TooltipDescription type="good">
        {t("waste.degree-kpi.good")}
      </TooltipDescription>
      <TooltipDescription type="ok">
        {t("waste.degree-kpi.ok")}
      </TooltipDescription>
      <TooltipDescription type="warning">
        {t("waste.degree-kpi.warning")}
      </TooltipDescription>
      <TooltipDescription type="error">
        {t("waste.degree-kpi.error")}
      </TooltipDescription>
      <section>
        <h4>{t("waste.degree-kpi.limit-values-description")}</h4>
        <p>{t("waste.degree-kpi.waste-management")}</p>
      </section>
    </Space>
  );

  return (
    <Tooltip placement="top" title={DESCRIPTION_KPI_SORT_DEGREE_TOOLTIP}>
      <KpiCard>
        <div className="ant-card-body">
          <StyledStatistic
            title={title}
            value={new Intl.NumberFormat("nb-NO", {
              maximumFractionDigits: 0,
            }).format(value)}
            precision={0}
            prefix={prefix}
            suffix={unit}
            type={getType(value)}
          />
        </div>
      </KpiCard>
    </Tooltip>
  );
};
