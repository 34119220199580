import { browserFirestore } from "@properate/firebase";
import { collection, deleteField, doc, updateDoc } from "firebase/firestore";
import {
  SelectedSensors,
  SensorProps,
} from "@/components/TechnicalSchema/types";

export async function deleteTimeseries(
  id: string,
  sensors: SensorProps,
  sensorId: string,
  timeseriesId: number,
  selectedSensors: SelectedSensors,
  type: string,
) {
  const newSensor = {
    ...sensors[sensorId],
    timeseriesInfo: sensors[sensorId].timeseriesInfo.filter(
      (ts: any) => ts.id !== timeseriesId,
    ),
  };
  if (newSensor.timeseriesInfo.length === 0) {
    // first remove it from the selected ones
    if (selectedSensors.find((s: any) => s.id === sensorId)) {
      await updateDoc(doc(collection(browserFirestore, type), id), {
        selectedSensors: selectedSensors.filter((s: any) => s.id !== sensorId),
      });
    }
    await updateDoc(doc(collection(browserFirestore, type), id), {
      [`sensor.${sensorId}`]: deleteField(),
    });
  } else {
    await updateDoc(doc(collection(browserFirestore, type), id), {
      sensors: {
        ...sensors,
        [sensorId]: newSensor,
      },
    });
  }
}

export async function setTimeseries(
  id: string,
  sensorId: string,
  sensor: SensorProps,
  val: any,
  type: string,
) {
  await updateDoc(doc(collection(browserFirestore, type), id), {
    [`sensor.${sensorId}`]: {
      ...sensor,
      timeseriesInfo: [
        ...sensor[sensorId].timeseriesInfo.filter((ts) => ts.id !== val.id),
        val,
      ],
    },
  });
}

export function getSensors(sensors: SensorProps) {
  return Object.entries(sensors).reduce(
    (prev, current) => {
      return [
        ...prev,
        ...(current[1]
          ? current[1].timeseriesInfo.map((ts: any) => ({
              sensor: ts,
              id: current[0],
            }))
          : []),
      ];
    },
    [] as { id: string; sensor: SensorProps }[],
  );
}

export function getCurrentSensors(
  selectedSensors: SelectedSensors,
  sensors: SensorProps,
) {
  return selectedSensors && selectedSensors.length > 0
    ? selectedSensors
        .map((s: { id: string | number; timeseries: number }) => {
          const selectedSensors = sensors[s.id]?.timeseriesInfo.find(
            (ts: { id: number }) => ts.id === s.timeseries,
          );
          if (selectedSensors) {
            return { sensor: selectedSensors, id: s.id };
          }
          return null;
        })
        .slice(0, 20)
        .filter((val): val is Exclude<typeof val, null> => !!val)
    : [];
}
