import { Table, TableProps } from "antd";
import styles from "./TableWithoutDefaultSort.module.css";

export interface TableWithoutDefaultSortProps<RecordType>
  extends Omit<TableProps<RecordType>, "sortDirections"> {
  rowIsHoverable?: boolean;
}

export function TableWithoutDefaultSort<RecordType extends object = any>({
  rowIsHoverable = false,
  ...props
}: TableWithoutDefaultSortProps<RecordType>) {
  return (
    <Table
      size="small"
      sortDirections={["ascend", "descend", "ascend"]}
      rowClassName={
        props.rowClassName !== undefined
          ? props.rowClassName
          : rowIsHoverable
          ? styles["clickable-row"]
          : ""
      }
      {...props}
    />
  );
}
