import ky, { HTTPError } from "ky";
import { apiUrl, cdfProject } from "./constants";
import { getAuthToken } from "./auth";

export { HTTPError } from "ky";

const http = ky.create({
  prefixUrl: apiUrl,
  retry: {
    // Adds 401 to the default list of status codes to retry.
    // see: https://github.com/sindresorhus/ky#retry
    statusCodes: [401, 408, 413, 429, 500, 502, 503, 504],
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        request.headers.set("Authorization", `Bearer ${await getAuthToken()}`);
        request.headers.set("Cdf-project", cdfProject);

        return request;
      },
    ],
    beforeRetry: [
      async ({ request }) => {
        request.headers.set(
          "Authorization",
          `Bearer ${await getAuthToken(true)}`,
        );
      },
    ],
  },
});

export function isHttpError(error: unknown, statusCode?: number) {
  return (
    error instanceof HTTPError &&
    error.response &&
    (!statusCode || error.response.status === statusCode)
  );
}

export function isHttpErrorMessage(message: string, statusCode?: number) {
  return (
    message.startsWith("Request failed with status code") &&
    (!statusCode || message.includes(statusCode.toString()))
  );
}

export default http;
