import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useRootAssets } from "@/hooks/useRootAssets";

export function RequiresBuildingMembership(props: {
  children: React.ReactNode;
}) {
  const t = useTranslations();

  const rootAssets = useRootAssets();
  const currentBuildingId = useCurrentBuildingId();

  const isMember = rootAssets.some(
    (asset) => asset.rootId === currentBuildingId,
  );

  return isMember ? <>{props.children}</> : <div>{t("pages.notAllowed")}</div>;
}
