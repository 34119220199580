import { Empty } from "antd";
import { Timeseries } from "@cognite/sdk";
import { useTranslations } from "@properate/translations";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { MessageKey } from "@/utils/types";
import { GaugeType, GaugeViewMode, GaugeZones, Since } from "../../types";
import { formatSince } from "../../utils";
import { useGetGaugeValue } from "../../hooks/useGetGaugeValue";
import { GaugeBar } from "./GaugeViewMode/GaugeBar";
import { GaugeLight } from "./GaugeViewMode/GaugeLight";
import { GaugeNumber } from "./GaugeViewMode/GaugeNumber";
import { GaugeSpeedometer } from "./GaugeViewMode/GaugeSpeedometer";

interface Props {
  viewMode: GaugeViewMode;
  min: number;
  max: number;
  zones: GaugeZones;
  type: GaugeType;
  timeseries: Timeseries;
  since?: Since;
  unit?: string;
  mathExpression?: string;
  size?: "small" | "medium" | "large";
}

export function GaugeView({
  viewMode,
  min,
  max,
  zones,
  type,
  timeseries,
  since,
  unit,
  mathExpression,
  size = "large",
}: Props) {
  const t = useTranslations();
  const { value, isLoading } = useGetGaugeValue(
    timeseries,
    type !== "latest" ? type : undefined,
    since,
    unit,
    mathExpression,
  );

  function getGaugeViewComponent() {
    if (typeof value === "number") {
      switch (viewMode) {
        case "bar":
          return (
            <GaugeBar
              min={min}
              max={max}
              zones={zones}
              value={value}
              unit={unit}
              size={size}
            />
          );
        case "light":
          return <GaugeLight zones={zones} value={value} />;
        case "number":
          return <GaugeNumber zones={zones} value={value} unit={unit} />;
        case "speedometer":
          return (
            <GaugeSpeedometer
              zones={zones}
              value={value}
              unit={unit}
              min={min}
              max={max}
              size={size}
            />
          );
      }
    }
  }

  const { labelKey, amount, error } = formatSince(since);

  const valueSince = error
    ? since
    : t(`analysis.gauge.details.last-time.${labelKey}` as MessageKey, {
        amount,
      });

  return (
    <SpinnerWithDelay isLoading={isLoading}>
      {value === null ? (
        <Empty
          style={{ maxWidth: 400 }}
          description={t("analysis.gauge.details.no-data-points-at", {
            datapoint: typeof since === "string" ? valueSince : undefined,
          })}
        />
      ) : (
        getGaugeViewComponent()
      )}
    </SpinnerWithDelay>
  );
}
