import {
  useContext,
  createContext,
  ReactNode,
  useMemo,
  useEffect,
  useState,
} from "react";
import { cogniteClient } from "@/services/cognite-client";
import { keycloakInit } from "@/keycloak";
import type { CogniteClient } from "@cognite/sdk";

// Only for use in tests
export const CogniteClientContext = createContext<{
  client: CogniteClient;
  isAuthenticated: boolean;
} | null>(null);

interface Props {
  children: ReactNode;
}

export function CogniteClientContextProvider({ children }: Props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const value = useMemo(
    () => ({
      client: cogniteClient,
      isAuthenticated,
    }),
    [isAuthenticated],
  );

  useEffect(() => {
    async function authenticate() {
      await keycloakInit;
      await cogniteClient.authenticate();
      setIsAuthenticated(true);
    }

    authenticate();
  }, [setIsAuthenticated]);

  return (
    <CogniteClientContext.Provider value={value}>
      {children}
    </CogniteClientContext.Provider>
  );
}

export function useCogniteClient() {
  const cogniteClientContext = useContext(CogniteClientContext);
  if (!cogniteClientContext) {
    throw new Error(
      '"useCogniteClient" was called from a component that does not have a CogniteClientContextProvider parent',
    );
  }
  return cogniteClientContext;
}
