import useSWR from "swr";
import {
  getAlarmTimeseries,
  TimeseriesWithAlarmAssetName,
} from "../../utils/getAlarmTimeseries";
import { useSubBuildingsNameAndId } from "./useSubBuildingsNameAndId";

export function useAlarmTimeseriesForSubBuildings() {
  const {
    isLoading: subBuildingsLoading,
    error: subBuildingsError,
    subBuildings,
  } = useSubBuildingsNameAndId();

  const {
    data: alarmTimeseries,
    isLoading,
    error,
  } = useSWR(["alarmTimeseries", subBuildings], async ([_, subBuildingIds]) => {
    if (!subBuildingIds) {
      return null;
    }
    const result: {
      subBuildingId: number;
      subBuildingName: string;
      timeseries: TimeseriesWithAlarmAssetName[];
    }[] = await Promise.all(
      subBuildingIds.map(async ({ id, name }) => ({
        subBuildingId: id,
        subBuildingName: name,
        timeseries: await getAlarmTimeseries(id),
      })),
    );
    return result;
  });

  return {
    isLoading: subBuildingsLoading || isLoading,
    error: subBuildingsError || error,
    alarmTimeseries,
    subBuildings,
  };
}
