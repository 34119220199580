import { utils, writeFile } from "xlsx";
import dayjs from "@properate/dayjs";
import { DoubleDatapoints } from "@cognite/sdk";

export function downloadTimeseriesListWithDataAsXLSX(
  timeseriesListWithData: DoubleDatapoints[],
  title: string,
) {
  const workBook = utils.book_new();
  const workSheetRowsPerTimeseries = timeseriesListWithData.map(
    ({ externalId, datapoints, unit }) => {
      return datapoints.map((datapoint) => ({
        ...(externalId ? { ID: externalId } : null),
        Dato: dayjs(datapoint.timestamp).format("DD.MM.YYYY HH:mm:ss"),
        [`Verdi ${unit}`]: datapoint.value,
      }));
    },
  );
  workSheetRowsPerTimeseries
    .filter(
      (worksheetRowsForTimeseries) => worksheetRowsForTimeseries.length > 0,
    )
    .forEach((worksheetRow, index) => {
      const workSheet = utils.json_to_sheet(worksheetRow);
      utils.book_append_sheet(
        workBook,
        workSheet,
        // There's a limit on 32 characters for the sheet name, so we can't use external ID
        index.toString(),
      );
    });
  writeFile(workBook, `${title}-${dayjs().format("DDMMYYYYHHmm")}.xlsx`);
}
