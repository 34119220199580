import { GridColumns } from "@visx/grid";
import dayjs from "@properate/dayjs";
import { LinePath } from "@visx/shape";
import { scaleTime } from "@visx/scale";
import { useTheme } from "styled-components";
import { Group } from "@visx/group";
import { useMemo } from "react";
import { getItemByProp } from "@/utils/array";
import { ScaleWithTimeseriesId, SimplePointsWithMetadata } from "../../types";
import {
  getDayTimestampsBetweenStartAndEnd,
  getHourTimestampsBetweenStartAndEnd,
  getMonthTimestampsBetweenStartAndEnd,
  getQuarterTimestampsBetweenStartAndEnd,
  mapSimplePointsToScalesWithMetadata,
} from "../../utils";

interface Props {
  simplePointsWithMetadataList: SimplePointsWithMetadata[];
  width: number;
  timeSpanStart: number;
  timeSpanEnd: number;
  simplePointsWithMetadataX?: SimplePointsWithMetadata;
}

const HEIGHT = 40;
const SVG_HEIGHT = HEIGHT + 19;

export const TimeseriesNavigatorOverviewGraph = ({
  simplePointsWithMetadataList,
  width,
  timeSpanStart,
  timeSpanEnd,
  simplePointsWithMetadataX,
}: Props) => {
  const { neutral4 } = useTheme();
  const simplePointsWithMetadataListFinal = simplePointsWithMetadataList.concat(
    simplePointsWithMetadataX || [],
  );
  const scalesWithMetadata = mapSimplePointsToScalesWithMetadata(
    simplePointsWithMetadataListFinal,
    [HEIGHT, 0],
  ) as ScaleWithTimeseriesId[];
  const showHours = dayjs(timeSpanEnd).diff(dayjs(timeSpanStart), "day") < 2;
  const showDays =
    !showHours && dayjs(timeSpanEnd).diff(dayjs(timeSpanStart), "month") < 2;
  const showMonths =
    !showDays && dayjs(timeSpanEnd).diff(dayjs(timeSpanStart), "month") < 12;

  const timestamps = useMemo(() => {
    if (showHours) {
      return getHourTimestampsBetweenStartAndEnd([timeSpanStart, timeSpanEnd]);
    }
    if (showDays) {
      return getDayTimestampsBetweenStartAndEnd([timeSpanStart, timeSpanEnd]);
    }
    if (showMonths) {
      return getMonthTimestampsBetweenStartAndEnd([timeSpanStart, timeSpanEnd]);
    }
    return getQuarterTimestampsBetweenStartAndEnd([timeSpanStart, timeSpanEnd]);
  }, [showHours, showDays, showMonths, timeSpanStart, timeSpanEnd]);

  const showYears = !showMonths && timestamps.length > 20;

  const timestampsFinal = useMemo(() => {
    if (showHours && timestamps.length > 20) {
      return timestamps.filter(
        (timestamp) => dayjs(timestamp).hour() % 4 === 0,
      );
    }
    if (showDays && timestamps.length > 20) {
      return timestamps.filter(
        (timestamp) => dayjs(timestamp).date() % 5 === 0,
      );
    }
    if (showMonths && timestamps.length > 20) {
      return timestamps.filter((timestamp) => dayjs(timestamp).date() === 1);
    }
    if (showYears) {
      return timestamps.filter(
        (timestamp) => dayjs(timestamp).dayOfYear() === 1,
      );
    }
    return timestamps;
  }, [showHours, showDays, showMonths, showYears, timestamps]);

  const timeScale = scaleTime({
    range: [0, width],
    domain: [timeSpanStart, timeSpanEnd],
  });

  function scaleValue(id: number, value: number): number {
    const { scale } = getItemByProp(scalesWithMetadata, id, "timeseriesId");
    return scale(value)!;
  }

  function formatTimestamp(timestamp: number) {
    if (showHours) {
      return dayjs(timestamp).format("HH:mm");
    }
    if (showDays) {
      return dayjs(timestamp).format("MMM D");
    }
    if (showMonths) {
      return dayjs(timestamp).format("MMM D");
    }
    if (showYears) {
      return dayjs(timestamp).format("YYYY");
    }
    return dayjs(timestamp).format("MMM YYYY");
  }

  return width > 0 ? (
    <svg width={width} height={SVG_HEIGHT}>
      <rect width={width} height={HEIGHT} className="component" />
      <Group top={5}>
        {simplePointsWithMetadataListFinal.map((simplePointsWithMetadata) => {
          const isForXAxis =
            simplePointsWithMetadata === simplePointsWithMetadataX;
          return (
            <LinePath
              key={simplePointsWithMetadata.metadata.timeseriesId}
              data={simplePointsWithMetadata.simplePoints}
              x={(simplePoint) => timeScale(simplePoint.timestamp)}
              y={(simplePoint) =>
                scaleValue(
                  simplePointsWithMetadata.metadata.timeseriesId,
                  simplePoint.y,
                )
              }
              stroke={simplePointsWithMetadata.metadata.color}
              strokeWidth={isForXAxis ? 2.5 : 1.5}
              opacity={isForXAxis ? 1 : 0.5}
            />
          );
        })}
        {timestampsFinal.map((timestamp) => (
          <text
            x={timeScale(timestamp) + 5}
            y={HEIGHT + 5}
            fill={neutral4}
            style={{ fontSize: "10px" }}
            key={timestamp}
          >
            {formatTimestamp(timestamp)}
          </text>
        ))}
      </Group>
      {timestampsFinal.length > 0 && (
        <GridColumns
          height={SVG_HEIGHT}
          scale={timeScale}
          tickValues={timestampsFinal}
          stroke={neutral4}
          strokeWidth={1}
        />
      )}
    </svg>
  ) : null;
};
