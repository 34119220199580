import useSWR from "swr";
import { Asset } from "@cognite/sdk";
import { WithRequired } from "@properate/common";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useHandleApiError } from "@/utils/api";

export function useGetSolarCellTimeseriesList() {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();
  const currentBuildingId = useCurrentBuildingId();
  const {
    data: timeseriesAssets,
    error: errorTimeseriesAssets,
    isLoading: isLoadingAssets,
  } = useSWR(
    {
      query: {
        filter: {
          assetSubtreeIds: [{ id: currentBuildingId }],
          labels: {
            containsAll: [
              { externalId: "oe_solar_pw" },
              { externalId: "real_value" },
              { externalId: "instantaneous" },
            ],
          },
        },
      },
      type: "assets.list",
    },
    ({ query }) => client.assets.list(query).autoPagingToArray(),
  );
  const {
    data: timeseriesList = [],
    error: errorTimeseriesList,
    isLoading: isLoadingTimeseriesList,
  } = useSWR(
    timeseriesAssets && timeseriesAssets.length > 0
      ? {
          query: {
            filter: {
              assetExternalIds: timeseriesAssets
                .filter(
                  (asset): asset is WithRequired<Asset, "externalId"> =>
                    asset.externalId !== undefined,
                )
                .map(({ externalId }) => externalId),
            },
          },
          type: "timeseries.list",
        }
      : null,
    ({ query }) =>
      client.timeseries
        .list(query)
        .autoPagingToArray({ limit: Infinity })
        .then((timeseries) =>
          timeseries.filter((ts) => ts.externalId?.startsWith("TS_")),
        ),
  );
  if (errorTimeseriesAssets || errorTimeseriesList) {
    handleAPIError(errorTimeseriesAssets || errorTimeseriesList);
  }
  return {
    solarCellTimeseriesList: timeseriesList,
    isLoading: isLoadingAssets || isLoadingTimeseriesList,
  };
}
