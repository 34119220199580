import { browserFirestore } from "@properate/firebase";
import {
  isRouteErrorResponse,
  useRouteError,
  Link,
  Params,
  useParams,
} from "react-router-dom";
import { Result, Button } from "antd";
import { terminate, clearIndexedDbPersistence } from "firebase/firestore";
import { useTranslations } from "@properate/translations";
import { ErrorResult } from "./ErrorResult";

export function Error() {
  const t = useTranslations();
  const error = useRouteError();
  const { id } = useParams() as Params;
  console.error(error);
  if (
    isRouteErrorResponse(error) &&
    (error.status === 403 || error.status === 404)
  ) {
    return <ErrorResult status={error.status} />;
  }
  const subTitleErrorMessage =
    typeof error === "object" && error !== null && "message" in error
      ? t("common.error.error-message", { error: error.message as string })
      : "";
  return (
    <Result
      status="error"
      title={t("common.error.unknown-error")}
      subTitle={t("common.error.something-went-wrong", {
        error: subTitleErrorMessage,
      })}
      extra={
        <>
          <Button
            type="default"
            onClick={async () => {
              await terminate(browserFirestore);
              await clearIndexedDbPersistence(browserFirestore);
              window.location.reload();
            }}
          >
            {t("common.error.clear-cache")}
          </Button>
          <Button type="primary">
            <Link to={`/asset/${id}/building`}>
              {t("common.error.go-back-to-building")}
            </Link>
          </Button>
          <Button type="primary">
            <Link to="/">{t("common.error.go-back-to-all-buildings")}</Link>
          </Button>
        </>
      }
    />
  );
}
