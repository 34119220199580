import { SortableContainer } from "react-sortable-hoc";
import { Row } from "antd";
import { BuildingSpec, WithSnapshotId } from "@properate/common";
import { useMemo } from "react";
import { Gauge } from "@/features/gauges";
import { HeatMap } from "@/features/heatmap";
import { DashboardStandardWidget, DashboardWidget } from "@/utils/types";
import { Analysis } from "@/features/analysis";
import { AddWidgetsWidget } from "../widget-add/AddWidgetsWidget";
import { SortableWidget } from "./SortableWidget";

interface Props {
  selectedWidgets: DashboardWidget[];
  selectedGauges: WithSnapshotId<Gauge>[];
  selectedAnalyses: WithSnapshotId<Analysis>[];
  selectedHeatMaps: WithSnapshotId<HeatMap>[];
  buildingSpec: BuildingSpec;
  widgetHeight: number;
  organizationsToTotalEnergyTimeseriesIds: Record<string, number>;
  organizationsToTotalEnergyTempCorrectedETModTimeseriesIds: Record<
    string,
    number
  >;
  organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds: Record<
    string,
    number
  >;
  organizations: string[];
  availableStandardWidgets: DashboardStandardWidget[];
  availableGauges: WithSnapshotId<Gauge>[];
  availableAnalyses: WithSnapshotId<Analysis>[];
  availableHeatMaps: WithSnapshotId<HeatMap>[];
  onRemoveWidget: (widget: DashboardWidget) => unknown;
  onAddWidgets: (widget: DashboardWidget[]) => unknown;
}

export const SortableList = SortableContainer<Props>(
  ({
    selectedWidgets,
    selectedGauges,
    selectedAnalyses,
    selectedHeatMaps,
    buildingSpec,
    widgetHeight,
    organizationsToTotalEnergyTimeseriesIds,
    organizationsToTotalEnergyTempCorrectedETModTimeseriesIds,
    organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds,
    organizations,
    onRemoveWidget,
    availableStandardWidgets,
    availableGauges,
    availableAnalyses,
    availableHeatMaps,
    onAddWidgets,
  }: Props) => {
    const isAddWidgetsWidgetAvailable =
      availableStandardWidgets.length > 0 ||
      availableGauges.length > 0 ||
      availableAnalyses.length > 0;

    const validWidgets = useMemo(() => {
      return selectedWidgets.filter(
        ({ id, type }) =>
          type === "standard" ||
          (type === "gauge" &&
            selectedGauges.some(({ snapshotId }) => snapshotId === id)) ||
          (type === "analysis" &&
            selectedAnalyses.some(({ snapshotId }) => snapshotId === id)) ||
          (type === "heatMap" &&
            selectedHeatMaps.some(({ snapshotId }) => snapshotId === id)),
      );
    }, [selectedWidgets, selectedGauges, selectedAnalyses, selectedHeatMaps]);
    return (
      <>
        <Row gutter={[24, 24]}>
          {validWidgets.map((widget, index) => (
            <SortableWidget
              key={widget.id}
              index={index}
              widget={widget}
              selectedGauges={selectedGauges}
              selectedAnalyses={selectedAnalyses}
              selectedHeatMaps={selectedHeatMaps}
              buildingSpec={buildingSpec}
              height={widgetHeight}
              organizations={organizations}
              organizationsToTotalEnergyTimeseriesIds={
                organizationsToTotalEnergyTimeseriesIds
              }
              organizationsToTotalEnergyTempCorrectedETModTimeseriesIds={
                organizationsToTotalEnergyTempCorrectedETModTimeseriesIds
              }
              organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds={
                organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds
              }
              onRemoveWidget={() => onRemoveWidget(widget)}
            />
          ))}
          {isAddWidgetsWidgetAvailable && (
            <AddWidgetsWidget
              height={widgetHeight}
              availableWidgets={availableStandardWidgets}
              availableGauges={availableGauges}
              availableAnalyses={availableAnalyses}
              availableHeatMaps={availableHeatMaps}
              onAddWidgets={onAddWidgets}
            />
          )}
        </Row>
      </>
    );
  },
);
