import { BuildingCertification } from "@properate/common";
import dayjs from "@properate/dayjs";
import { useState } from "react";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { UserSettings } from "@/utils/types";
import { isAboutToExpire, isExpired } from "../utils/expiration";

export const filters = [
  {
    label: "expired",
    check: isExpired,
  },
  {
    label: "expiring-soon",
    check: isAboutToExpire,
  },
] as const;

function filterCertifications(
  certifications: BuildingCertification[],
  search: string,
  filterLabel: string,
) {
  return certifications.filter((cert) => {
    if (search) {
      const searchTerm = search.toLowerCase();

      if (cert.type.toLowerCase().includes(searchTerm)) {
        return true;
      }

      if (
        cert.type !== "ISO14001" &&
        cert.type !== "Miljøfyrtårn" &&
        cert.level.toLowerCase().includes(searchTerm)
      ) {
        return true;
      }

      if (dayjs(cert.awardedAt).format("DD.MM.YYYY").includes(searchTerm)) {
        return true;
      }

      return (
        cert.expiresAt &&
        dayjs(cert.expiresAt).format("DD.MM.YYYY").includes(searchTerm)
      );
    }

    const filter = filters.find((filter) => filter.label === filterLabel);

    return filter ? filter.check(cert) : true;
  });
}

const allowFilters: UserSettings["filters"]["certificationsSieve"][] = [
  "all",
  "expired",
  "expiring-soon",
];

export function useCertificationsSieve(
  certifications: BuildingCertification[],
) {
  const [search, setSearch] = useState("");

  const { data } = useUserSettings();
  const filter = allowFilters.includes(data?.filters?.certificationsSieve)
    ? data?.filters?.certificationsSieve
    : "all";

  return {
    search,
    setSearch,
    filter,
    setFilter: (filter: UserSettings["filters"]["certificationsSieve"]) =>
      mutateUserSettings({
        filters: {
          certificationsSieve: filter,
        },
      }),
    filteredCertifications: filterCertifications(
      certifications,
      search,
      filter,
    ),
  };
}
