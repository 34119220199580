import { useParams } from "react-router-dom";
import {
  updateOrganizationAccessModules,
  useOrganizationModulesByName,
} from "@/services/organization";
import { OrganizationAdminModules } from "@/pages/organizationAdmin/OrganizationAdminModules";
import { AccessModules, allAccessModules } from "@/features/organizationAdmin";

export const OrganizationAdminOrganizationModules = () => {
  const { organizationId } = useParams();

  const organizationName = organizationId
    ? decodeURIComponent(organizationId)
    : "";

  const { data: organizationData, isLoading } =
    useOrganizationModulesByName(organizationName);

  const savedModules = organizationData?.modules || allAccessModules;

  const onUpdate = (values: AccessModules[]) => {
    return updateOrganizationAccessModules(
      organizationName,
      values,
      organizationData?.snapshotId,
    );
  };

  return (
    <OrganizationAdminModules
      breadcrumb={organizationName}
      savedModules={savedModules}
      organizationName={organizationName}
      isLoading={isLoading}
      onUpdate={onUpdate}
    />
  );
};
