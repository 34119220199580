import dayjs from "@properate/dayjs";
import { TimeSpan } from "@properate/common";
import { MessageKey, useLocale } from "@properate/translations";
import { getItemByProp } from "@/utils/array";
import { HeatMapViewMode, HeatMapAggregate } from "./types";
import type { DefaultTheme } from "styled-components";

export function getTimeSpanFromViewMode(
  referenceDate: number,
  viewMode: HeatMapViewMode,
): TimeSpan {
  const referenceDateDayjs = dayjs(referenceDate);
  if (viewMode === "week") {
    return [
      referenceDateDayjs.startOf("isoWeek").valueOf(),
      referenceDateDayjs.endOf("isoWeek").valueOf(),
    ];
  }
  return [
    referenceDateDayjs.startOf("month").valueOf(),
    referenceDateDayjs.endOf("month").valueOf(),
  ];
}

export function useWeekdayNames() {
  const locale = useLocale();
  const now = dayjs().locale(locale);
  return Array(7)
    .fill(null)
    .map((_, i) => {
      const dayFormatted = now.weekday(i).format("dddd");
      return `${dayFormatted[0].toUpperCase()}${dayFormatted.slice(1)}`;
    });
}

export function getIsoWeekNumbers(referenceDate: number) {
  const firstDateOfCalendar = dayjs(referenceDate)
    .startOf("month")
    .startOf("isoWeek");
  const lastDateOfCalendar = dayjs(referenceDate)
    .endOf("month")
    .endOf("isoWeek");
  return [
    ...new Set(
      Array(lastDateOfCalendar.diff(firstDateOfCalendar, "day"))
        .fill(null)
        .map((_, i) => firstDateOfCalendar.add(i, "day").isoWeek().toString()),
    ),
  ];
}

export function getHoursOfTheDay() {
  const now = dayjs();
  return Array(24)
    .fill(null)
    .map((_, i) => now.hour(i).format("HH:[00]"));
}

export const aggregateOptions: Array<{
  value: HeatMapAggregate;
}> = [
  {
    value: "average",
  },
  {
    value: "min",
  },
  {
    value: "max",
  },
  {
    value: "sum",
  },
];

export const viewModeOptions: Array<{ value: HeatMapViewMode }> = [
  {
    value: "week",
  },
  {
    value: "month",
  },
];

export function getLabelKeyForAggregate(
  aggregate: HeatMapAggregate,
): MessageKey {
  return `analysis.heat-map.aggregate-options.${
    getItemByProp(aggregateOptions, aggregate, "value").value
  }`;
}

export function getTickLabelProps(theme: DefaultTheme, compact: boolean) {
  return {
    fontSize: compact ? 10 : 12,
    fill: theme.neutral4,
  };
}
