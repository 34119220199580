"use client";

import { useEffect } from "react";
import { AccordionActionType, AccordionProps } from "./types";
import { useAccordionActions, useAccordionValues } from "./hooks";

export default function SynchronizeAccordionData({
  entries: inputEntries,
}: Pick<AccordionProps, "entries">) {
  const { entries } = useAccordionValues();
  const dispatch = useAccordionActions();

  useEffect(() => {
    const openIds = entries
      .filter(({ open }) => !!open)
      .map(({ uniqueId }) => uniqueId);

    const newEntries = inputEntries.map((entry) => {
      if (openIds.includes(entry.uniqueId)) {
        return {
          ...entry,
          open: true,
        };
      }
      return entry;
    });

    dispatch({
      type: AccordionActionType.setEntries,
      entries: newEntries,
    });
  }, [inputEntries]); // eslint-disable-line react-hooks/exhaustive-deps, prettier/prettier

  return null;
}
