import { Button, Card, Popconfirm, Space } from "antd";
import { Link, useLocation } from "react-router-dom";
import { WithSnapshotId } from "@properate/common";
import { DeleteOutlined, ImportOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { ShareButtonWithDialog } from "@/components/ShareButtonWithDialog/ShareButtonWithDialog";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { GaugeView } from "../GaugeDetails/GaugeView";
import { getGaugeDescription } from "../../utils";
import { Gauge } from "../../types";
import { GaugeListCardContent } from "./elements";

interface Props {
  gauge: WithSnapshotId<Gauge>;
  onDelete: (snapshotId: string) => unknown;
}

export function GaugeListCard({ gauge, onDelete }: Props) {
  const t = useTranslations();

  const {
    timeseriesList: [timeseries],
    isLoading,
  } = useGetTimeseriesList([gauge.timeseriesSettings.id]);
  const location = useLocation();
  const shareLink = `${window.location.origin}${location.pathname}/${gauge.snapshotId}`;
  const currentBuildingId = useCurrentBuildingId();
  const { overrideUnits, isLoading: isLoadingOverrideUnit } =
    useTimeseriesSettings(currentBuildingId);
  const overrideUnit =
    timeseries && overrideUnits && overrideUnits[timeseries.externalId!]?.unit;

  return (
    <Card
      data-testid={`gauge-card/${gauge.name}`}
      title={
        <Space>
          <div>
            {`${gauge.name} (${getGaugeDescription(
              gauge.type,
              t,
              gauge.since,
            )})`}
          </div>
          {typeof gauge.importedFrom === "string" && (
            <ImportOutlined title={t("analysis.imported")} />
          )}
        </Space>
      }
      extra={
        <Space>
          <Popconfirm
            title={t("analysis.gauge.delete-confirmation")}
            okText={t("analysis.delete")}
            onConfirm={() => onDelete(gauge.snapshotId)}
          >
            <Button
              data-testid={`delete-button/${gauge.name}`}
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
          <ShareButtonWithDialog
            title={t("analysis.gauge.share-gauge")}
            shareLink={shareLink}
          >
            {t("analysis.share-text.gauge")}
          </ShareButtonWithDialog>
        </Space>
      }
      hoverable
    >
      <Link to={gauge.snapshotId}>
        <GaugeListCardContent>
          {!isLoading && !isLoadingOverrideUnit && (
            <GaugeView
              viewMode={gauge.viewMode || "bar"}
              max={gauge.max}
              min={gauge.min}
              zones={gauge.zones}
              timeseries={timeseries}
              type={gauge.type}
              unit={overrideUnit ?? timeseries?.unit}
              mathExpression={gauge.mathExpression}
              size="small"
            />
          )}
        </GaugeListCardContent>
      </Link>
    </Card>
  );
}
