import { CogniteClient } from "@cognite/sdk";
import keycloak from "@/keycloak";

export const cogniteClient = new CogniteClient({
  appId: process.env.REACT_APP_PRODUCTION ? "Properate prod" : "Properate dev",
  project: process.env.REACT_APP_PROJECT_ID!,
  async getToken() {
    await keycloak.updateToken(30);
    return keycloak.token!;
  },
});
