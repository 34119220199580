import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlarmWithSnapshotId } from "@properate/common";
import { useAlarm } from "@/services/alarms";

/**
 * This hook is used to control the alarm that is shown in the modal.
 *
 * If the route contains an alarmId, the alarm is fetched from the server. (show, edit, delete)
 * If the route does not contain an alarmId, the alarm is stored in the state. (create)
 */
export function useShowAlarm() {
  const { id, alarmId } = useParams();
  const navigate = useNavigate();

  const routeAlarm = useAlarm(alarmId);
  const [showAlarm, setShowAlarm] = useState<AlarmWithSnapshotId | undefined>();

  useEffect(() => {
    if (routeAlarm) {
      setShowAlarm(routeAlarm);
    }
  }, [routeAlarm]);

  /**
   * Opens the alarm modal.
   *
   * If the alarm has an id we fetch it from the server, otherwise use it locally.
   * It will not have an id if it is a new alarm, as we save inside the modal.
   */
  const openAlarmModal = (alarm: AlarmWithSnapshotId) => {
    alarm.snapshotId
      ? navigate(`/asset/${id}/alarms/${alarm.snapshotId}`)
      : setShowAlarm(alarm);
  };

  /**
   * Closes the alarm modal.
   *
   * If it is a local alarm, we clear the state. Otherwise we navigate to the alarm
   * list, which will change the routeAlarm and as side-effect, clear the state.
   */
  const closeAlarmModal = () => {
    if (alarmId) {
      navigate(`/asset/${id}/alarms`);
    }

    setShowAlarm(undefined);
  };

  /**
   * Updates the local state of the alarm, useful because we have multiple modals.
   *
   * If the alarm is not local, please use the alarm service to update it permanently.
   */
  const updateAlarmModalLocally = (alarm: Partial<AlarmWithSnapshotId>) =>
    setShowAlarm({ ...showAlarm!, ...alarm });

  return {
    showAlarm,
    openAlarmModal,
    closeAlarmModal,
    updateAlarmModalLocally,
  };
}
