import { collection } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { useGetFirestoreCollection } from "@properate/ui";
import { TaskTemplate } from "../schemas";

const ref = collection(browserFirestore, "taskTemplates");

export function useGetTaskTemplates() {
  const result = useGetFirestoreCollection<TaskTemplate>(ref);

  return {
    data: result.data ?? [],
    isLoading: result.isLoading,
    error: result.error,
  };
}
