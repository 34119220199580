import { useEffect, useState } from "react";
import { useTranslations } from "@properate/translations";
import { AlarmGraphFormFields } from "@/pages/alarms/details/components/AlarmTimeseriesGraph";
import { useFormValue } from "../../../FormContext/hooks";
import { SelectTimeseries } from "../../../components/TimeseriesSelector";
import { tKey } from "../../common/utils";
import { ThirdPartyViewState, ThirdPartyAlarmFields } from "../types";
import ViewThirdPartyAlarm from "./ViewThirdPartyAlarm";

export function ThirdPartyAlarmInfo() {
  const t = useTranslations();
  const [baseTimeseriesId] = useFormValue<number | undefined>(
    ThirdPartyAlarmFields.BaseTimeseries,
  );
  const [showGraph, setShowGraph] = useFormValue<boolean>(
    AlarmGraphFormFields.GraphVisible,
  );
  const [viewState, setViewState] = useState<ThirdPartyViewState>(
    baseTimeseriesId
      ? ThirdPartyViewState.View
      : ThirdPartyViewState.SelectBaseTimeseries,
  );

  useEffect(() => {
    if (!baseTimeseriesId && showGraph) {
      setShowGraph(false);
    }
  }, [baseTimeseriesId]);

  function closeSelector() {
    if (baseTimeseriesId) {
      setViewState(ThirdPartyViewState.View);
      setShowGraph(true);
      return;
    }
    setShowGraph(false);
    setViewState(ThirdPartyViewState.SelectBaseTimeseries);
  }

  function getActivateSelector(newViewState: ThirdPartyViewState) {
    return () => {
      setShowGraph(false);
      setViewState(newViewState);
    };
  }

  switch (viewState) {
    case ThirdPartyViewState.SelectBaseTimeseries:
      return (
        <SelectTimeseries
          close={closeSelector}
          name={ThirdPartyAlarmFields.BaseTimeseries}
          label={t(tKey(`third-party-alarm.select-base`))}
        />
      );
    case ThirdPartyViewState.SelectVisualTimeseries:
      return (
        <SelectTimeseries
          close={closeSelector}
          name={ThirdPartyAlarmFields.AddVisualTimeseriesId}
          label={t(
            tKey(`third-party-alarm.visual-timeseries.select-timeseries`),
          )}
        />
      );
    case ThirdPartyViewState.View:
      return (
        <ViewThirdPartyAlarm
          selectBaseTimeseries={getActivateSelector(
            ThirdPartyViewState.SelectBaseTimeseries,
          )}
          selectVisualTimeseries={getActivateSelector(
            ThirdPartyViewState.SelectVisualTimeseries,
          )}
        />
      );
  }
}
