import { Select } from "antd";
import { useBuildingContacts } from "./hooks/useBuildingContacts";

type Props = {
  multi?: boolean;
  buildingId: number;
  value?: string | string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
};

export function AssigneeInput({ buildingId, multi, ...props }: Props) {
  const { data: userEmails = [], isLoading } = useBuildingContacts(buildingId);

  return (
    <Select
      {...props}
      allowClear
      mode={multi ? "multiple" : undefined}
      loading={isLoading}
      showSearch
      className="w-full overflow-hidden"
      disabled={props.disabled}
      onChange={(value: unknown) => {
        if (props.onChange) {
          const arrValue = Array.isArray(value) ? value : [value];
          props.onChange(arrValue as string[]);
        }
      }}
      filterOption={(input, option) => {
        if (typeof option?.value === "string") {
          return option.value.toLowerCase().includes(input.toLowerCase());
        }

        return true;
      }}
    >
      {userEmails.map((user) => (
        <Select.Option value={user} key={user}>
          {user}
        </Select.Option>
      ))}
    </Select>
  );
}
