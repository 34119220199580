import { browserFirestore } from "@properate/firebase";
import { doc, setDoc } from "firebase/firestore";
import { useMemo } from "react";
import { useGetFirestoreDocument } from "@properate/ui";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { getCurrentUser } from "./lib/getCurrentUser";
import type { DashboardWidget, UserSettings } from "@/utils/types";
import type { DeepPartial } from "ts-essentials";

export function useUserSettings() {
  const user = getCurrentUser();

  return useGetFirestoreDocument<UserSettings>(
    useMemo(() => doc(browserFirestore, `user/${user}`), [user]),
  );
}

export function useUserSettingsForBuilding() {
  const currentBuildingId = useCurrentBuildingId();
  const { data, isLoading, error } = useUserSettings();
  if (!data?.buildings) {
    return {};
  }
  return { data: data.buildings[currentBuildingId], isLoading, error };
}

export async function mutateUserSettings(data: DeepPartial<UserSettings>) {
  const user = getCurrentUser();
  const ref = doc(browserFirestore, `user/${user}`);

  await setDoc(ref, data, { merge: true });
}

export async function removeWidget(
  currentBuildingId: number,
  widgets: DashboardWidget[],
  widgetToRemoveSnapshotId: string,
) {
  return mutateUserSettings({
    buildings: {
      [currentBuildingId]: {
        dashboard: {
          widgets: widgets.filter(
            (widget) => widget.id !== widgetToRemoveSnapshotId,
          ),
        },
      },
    },
  });
}
