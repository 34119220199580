import useSWRMutation from "swr/mutation";
import { collection, doc, updateDoc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { message } from "antd";
import { useTranslations } from "@properate/translations";
import { useUser } from "@properate/auth";
import { useFloorPlan } from "../FloorPlanContext";

export function useFloorPlanDelete() {
  const t = useTranslations();
  const user = useUser();
  const floorPlan = useFloorPlan();

  return useSWRMutation(
    [floorPlan.snapshotId],
    async ([snapshotId]) => {
      const collectionRef = collection(browserFirestore, "floor-plans");

      updateDoc(doc(collectionRef, snapshotId), {
        softDeleted: {
          deletedAt: new Date(),
          deletedBy: user.email,
        },
      });
    },
    {
      onSuccess() {
        message.success(t("floor-plan-v2.messages.deleted"));
      },
      onError() {
        message.error(t("floor-plan-v2.messages.deleted-failed"));
      },
    },
  );
}
