import { browserFirestore } from "@properate/firebase";
import { BuildingContact, BuildingContactWithId } from "@properate/common";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  writeBatch,
} from "firebase/firestore";
import useSWRMutation from "swr/mutation";

type TriggerArg = {
  buildingId: number;
  contact: BuildingContactWithId;
  patch: Partial<BuildingContact>;
};

export function useUpdateBuildingContact() {
  return useSWRMutation(
    "building-contacts",
    async (_, extra: { arg: TriggerArg }) => {
      const { buildingId, contact, patch } = extra.arg;
      const patchedContact = { ...contact, ...patch };

      const ref = doc(
        collection(browserFirestore, "buildings"),
        buildingId.toString(),
      );

      const batch = writeBatch(browserFirestore);

      batch.set(ref, { contacts: arrayRemove(contact) }, { merge: true });
      batch.set(ref, { contacts: arrayUnion(patchedContact) }, { merge: true });

      await batch.commit();
    },
  );
}
