import { browserFirestore } from "@properate/firebase";
import { sortString, useGetFirestoreCollection } from "@properate/ui";
import { collection, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { FloorPlan } from "../types";
import { useFloorPlan } from "../FloorPlanContext";
import { sortFloors } from "../lib/floors";

export function useFloorPlans() {
  const buildingId = useCurrentBuildingId();

  const ref = useMemo(
    () =>
      query(
        collection(browserFirestore, "floor-plans"),
        where("buildingId", "==", buildingId),
      ),
    [buildingId],
  );

  const {
    data = [],
    isLoading,
    error,
  } = useGetFirestoreCollection<FloorPlan>(ref);

  return {
    data: data
      .filter((item) => !item.softDeleted)
      .sort((a, b) => sortString(a.snapshotId, b.snapshotId)),
    isLoading,
    error,
  };
}

export function useFloorPlanNavigation() {
  const { data: floorPlans = [] } = useFloorPlans();
  const floorPlan = useFloorPlan();

  const sortedWithFloorTS = floorPlans
    .filter((item) => item.floor)
    .sort((a, b) => sortFloors(a.floor, b.floor) * -1);

  const sortedWithName = floorPlans
    .filter((item) => !item.floor)
    .sort(
      (a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        }) * -1,
    );

  const sortedFloorPlans = [...sortedWithFloorTS, ...sortedWithName];

  const currentIndex = sortedFloorPlans.findIndex(
    (item) => item.snapshotId === floorPlan.snapshotId,
  );

  const lastIndex = sortedFloorPlans.length - 1;

  const nextIndex = currentIndex < lastIndex ? currentIndex + 1 : 0;
  const previousIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex;

  return {
    next: sortedFloorPlans[nextIndex],
    previous: sortedFloorPlans[previousIndex],
    all: sortedFloorPlans ?? [],
  };
}
