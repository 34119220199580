import { Aggregate } from "@cognite/sdk";

export const aggregateOptions = [
  {
    value: "average",
    labelKey: "average",
  },
  {
    value: "sum",
    labelKey: "sum",
  },
  {
    value: "min",
    labelKey: "min",
  },
  {
    value: "max",
    labelKey: "max",
  },
];

export function getAggregateDisplayValue(aggregate: Aggregate) {
  const aggregateOption = aggregateOptions.find(
    ({ value }) => value === aggregate,
  );
  if (!aggregateOption) {
    throw new Error(`Aggregate ${aggregate} is not a known aggregate option`);
  }
  return aggregateOption.labelKey;
}
