import useSWR from "swr";
import { useCogniteClient } from "@/context/CogniteClientContext";

export const useCogniteImage = (cogniteFileId?: number) => {
  const { client: cogniteClient } = useCogniteClient();
  const { data, error } = useSWR(
    ["cognite-image", cogniteFileId],
    ([_, fileId]) => {
      if (!fileId) {
        return "";
      }
      return cogniteClient.files
        .getDownloadUrls([{ id: fileId }])
        .then(([fileLink]) =>
          fetch(fileLink.downloadUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const urlCreator = window.URL || window.webkitURL;
              return urlCreator.createObjectURL(blob);
            }),
        );
    },
    { revalidateOnFocus: false },
  );
  return { data: data, error: error };
};
