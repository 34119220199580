import { Form, Modal, Select } from "antd";
import { useRootAssets } from "@/hooks/useRootAssets";

type Props = {
  onSelect: (buildingId: number) => void;
  onClose: () => void;
};
/* eslint react/jsx-no-literals: 0 */
// will be removed in the future
export function BuildingSelectionModal(props: Props) {
  const [form] = Form.useForm();
  const rootAssets = useRootAssets();

  return (
    <Modal open onOk={form.submit} onCancel={() => props.onClose()}>
      <h2>Velg bygg</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => props.onSelect(values.buildingId)}
      >
        <Form.Item label="Bygg" name="buildingId" rules={[{ required: true }]}>
          <Select
            autoFocus
            showSearch
            options={rootAssets}
            optionFilterProp="name"
            fieldNames={{ label: "name", value: "id" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
