import { MeterSystemSelect } from "@properate/ui";
import { useEffect } from "react";
import { Alert, Button, Col, Form, Input, Radio, Row } from "antd";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import {
  DEFAULT_CATEGORY_OPTIONS,
  useAvailableFilterOptions,
} from "@/features/timeseries";
import { MessageKey } from "@/utils/types";
import { NewMeter, MeterType, SourceOption } from "./types";
import { RadioGroupUnits } from "./RadioGroupUnits";
import { getDefaultUnit, unitOptionsByMeterType } from "./utils";

const FormItem = Form.Item;

export interface MeterSelectionFormUiOptions {
  okButtonLabel?: string;
  helpText?: string;
}

export interface MeterSelectionFormProps {
  meter: NewMeter;
  onFinish: (
    meterValues: Pick<
      NewMeter,
      | "description"
      | "source"
      | "isAccumulated"
      | "unit"
      | "system"
      | "subBuilding"
    >,
  ) => void;
  descriptionHidden: boolean;
  isLoading: boolean;
  onCancel: () => void;
  uiOptions?: MeterSelectionFormUiOptions;
}

export function MeterSelectionForm({
  meter,
  onFinish,
  descriptionHidden,
  isLoading,
  onCancel,
  uiOptions = {},
}: MeterSelectionFormProps) {
  const t = useTranslations();

  const buildingId = useCurrentBuildingId();
  const sourceOptions: Record<MeterType, SourceOption[]> = {
    energy: [
      {
        value: "electricity",
        label: t("timeseries.meter-selection-form.electricity-label"),
      },
      {
        value: "district heating",
        label: t("timeseries.meter-selection-form.district-heating-label"),
      },
      {
        value: "district cooling",
        label: t("timeseries.meter-selection-form.district-cooling-label"),
      },
      {
        value: "oil",
        label: t("timeseries.meter-selection-form.oil-label"),
      },
      {
        value: "gas",
        label: t("timeseries.meter-selection-form.gas-label"),
      },
    ],
    water: [
      {
        value: "water",
        label: t("timeseries.meter-selection-form.water-label"),
      },
    ],
  };
  const sourceOptionsForMeterType = sourceOptions[meter.meterType];

  const [form] = Form.useForm<NewMeter>();
  const isAccumulated = Form.useWatch("isAccumulated", form);

  const unitOptions =
    unitOptionsByMeterType[meter.meterType][
      isAccumulated ? "accumulated" : "instantaneous"
    ];

  const finalUiOptions = {
    ...{
      okButtonLabel: "",
      helpText: "",
    },
    ...uiOptions,
  };

  useEffect(() => {
    form.setFieldsValue({
      unit: getDefaultUnit(meter.meterType, isAccumulated),
    });
  }, [form, meter.meterType, isAccumulated]);

  const { subBuildings } = useAvailableFilterOptions(
    {
      category: "default",
      subBuilding: meter.subBuilding,
      unit: null,
      system: null,
      buildingId,
    },
    DEFAULT_CATEGORY_OPTIONS.map((item) => ({
      ...item,
      label: t(
        ("common.timeseries-modal.filter-categories." +
          item.label) as MessageKey,
      ),
    })),
  );
  const subBuildingOptions = subBuildings.filter((subBuilding) => {
    return subBuilding !== "Common";
  });

  useEffect(() => {
    if (subBuildingOptions.length !== 1) {
      return;
    }

    form.setFieldValue("subBuilding", subBuildingOptions[0]);
  }, [subBuildingOptions, form]);

  function renderFormContents() {
    return (
      <>
        {!descriptionHidden && (
          <FormItem<NewMeter>
            label={t("timeseries.meter-selection-form.description-label")}
            name="description"
            rules={[
              {
                required: true,
                message: t(
                  "timeseries.meter-selection-form.description-rule-label",
                ),
              },
            ]}
          >
            <Input />
          </FormItem>
        )}
        {sourceOptionsForMeterType.length > 1 && (
          <FormItem<NewMeter>
            name="source"
            label={t("timeseries.meter-selection-form.meter-source-label")}
          >
            <Radio.Group>
              {sourceOptionsForMeterType.map((sourceOption) => (
                <Radio key={sourceOption.value} value={sourceOption.value}>
                  {sourceOption.label}
                </Radio>
              ))}
            </Radio.Group>
          </FormItem>
        )}
        <FormItem<NewMeter>
          label={t("timeseries.meter-selection-form.meter-type-label")}
          name="isAccumulated"
        >
          <Radio.Group>
            <Radio value>
              {t("timeseries.meter-selection-form.meter-instantaneous-label")}
            </Radio>
            <Radio value={false}>
              {t("timeseries.meter-selection-form.meter-torque-label")}
            </Radio>
          </Radio.Group>
        </FormItem>
        <FormItem<NewMeter>
          name="unit"
          label={t("timeseries.meter-selection-form.meter-unit-label")}
          rules={[
            {
              required: true,
              message: t(
                "timeseries.meter-selection-form.meter-unit-rule-description-label",
              ),
            },
          ]}
        >
          <RadioGroupUnits options={unitOptions} />
        </FormItem>
        <FormItem<NewMeter>
          label={t("timeseries.meter-selection-form.meter-building-label")}
          name="subBuilding"
          rules={[
            {
              required: true,
              message: t(
                "timeseries.meter-selection-form.meter-building-rule-description-label",
              ),
            },
          ]}
        >
          <Radio.Group>
            {subBuildingOptions.map((option) => (
              <Radio key={option} value={option}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
        </FormItem>
        <MeterSystemSelect buildingId={buildingId} />
      </>
    );
  }

  function renderFooter() {
    return (
      <div className="ant-modal-footer">
        <Button
          type="default"
          htmlType="button"
          onClick={() => onCancel()}
          disabled={isLoading}
        >
          {t("timeseries.meter-selection-form.cancel-button-label")}
        </Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          {finalUiOptions.okButtonLabel || "Ok"}
        </Button>
      </div>
    );
  }

  function renderHelpText() {
    if (!finalUiOptions.helpText) {
      return null;
    }
    return (
      <Row>
        <Col span={24}>
          <Alert
            description={finalUiOptions.helpText}
            type="info"
            showIcon
            closable
            style={{ marginBottom: "20px" }}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Form
      initialValues={meter}
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 21 }}
      labelAlign="left"
      requiredMark={false}
    >
      {renderHelpText()}
      {renderFormContents()}
      {renderFooter()}
    </Form>
  );
}
