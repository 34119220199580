export default function ColorDot({ color }: { color?: string }) {
  if (!color) return null;

  return (
    <div
      className={`w-1.5 h-1.5 border border-solid rounded-full`}
      style={{
        backgroundColor: color,
        borderColor: color,
      }}
    />
  );
}
