import { useTranslations, MessageKey } from "@properate/translations";
import { WEATHER_SYMBOLS } from "@/components/WeatherSymbols";

export function WeatherSymbol({
  x,
  y,
  symbol,
}: {
  x: number;
  y: number;
  symbol: string;
}) {
  const t = useTranslations();

  return (
    <g transform={`translate(${x - 16},${y + 20})`}>
      <title>
        {symbol
          ? t(
              `common.weather-description.${
                symbol.split("_")[0]
              }` as MessageKey,
            )
          : null}
      </title>
      <image href={WEATHER_SYMBOLS[symbol]} height="24" width="24" />
    </g>
  );
}
