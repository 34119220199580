import { BuildingContactWithId } from "@properate/common";
import { useGetBuildingSpec } from "@/features/buildings";

export function useGetBuildingContacts(buildingId?: string | number) {
  const spec = useGetBuildingSpec(buildingId);
  const data: BuildingContactWithId[] = spec.data?.contacts ?? [];

  return {
    data,
    isLoading: spec.isLoading,
    error: spec.error,
  };
}
