import { AlarmWithId } from "@properate/common";
import { Popover } from "antd";
import { useTranslations } from "@properate/translations";
import { AlarmEventsList } from "../AlarmEventsList/AlarmEventsList";
import { PopoverContent } from "./elements";

type Props = {
  alarm: AlarmWithId;
};

export function AlarmsTableEventsColumn({ alarm }: Props) {
  const t = useTranslations();
  return (
    <div className={"w-full h-full flex justify-center items-center"}>
      <Popover
        placement="left"
        title={t("org-alarms.table-columns.events")}
        content={
          <PopoverContent width={400}>
            <AlarmEventsList alarm={alarm} />
          </PopoverContent>
        }
      >
        <span data-testid={`alarm-event-count-${alarm.description}`}>
          {alarm.eventCount}
        </span>
      </Popover>
    </div>
  );
}
