import { TimeSpan } from "@properate/common";
import dayjs from "@properate/dayjs";
import { MessageKey } from "@properate/translations/src";
import { SpotPriceTimeSpan, SpotPriceGranularity } from "@/utils/types";
import type { DoubleDatapoint } from "@cognite/sdk";

export function fromSpotPriceTimeSpanToTimeSpan(
  spotPriceTimeSpan: SpotPriceTimeSpan,
): [number, number | string] {
  switch (spotPriceTimeSpan) {
    case "todayAndTomorrow":
      return [
        dayjs().startOf("day").valueOf(),
        dayjs().add(1, "day").endOf("day").valueOf(),
      ];
    case "last24Hours":
      return [dayjs().subtract(24, "hour").startOf("hour").valueOf(), "now"];
    case "thisWeek":
      return [
        dayjs().startOf("week").valueOf(),
        dayjs().endOf("week").valueOf(),
      ];
    case "last7Days":
      return [dayjs().subtract(7, "day").startOf("day").valueOf(), "now"];
  }
}

export function fromSpotPriceTimeSpanToTimeSpanAbsolute(
  spotPriceTimeSpan: SpotPriceTimeSpan,
): TimeSpan {
  const timeSpan = fromSpotPriceTimeSpanToTimeSpan(spotPriceTimeSpan);
  if (timeSpan[1] === "now") {
    return [timeSpan[0], Date.now()];
  }
  return timeSpan as TimeSpan;
}

export const spotPriceOptions: Array<{
  value: SpotPriceTimeSpan;
}> = [
  {
    value: "todayAndTomorrow",
  },
  {
    value: "last24Hours",
  },
  {
    value: "thisWeek",
  },
  {
    value: "last7Days",
  },
];

export function getLabelKeyForSpotPriceOption(value: SpotPriceTimeSpan) {
  return `dashboard.widgets.spot-price.periods.${
    spotPriceOptions.find((spotPriceOption) => spotPriceOption.value === value)!
      .value
  }`;
}

export function getGranularityOptions(timeSpan: SpotPriceTimeSpan): Array<{
  value: SpotPriceGranularity;
  labelKey: MessageKey;
}> {
  switch (timeSpan) {
    case "last24Hours":
    case "todayAndTomorrow":
      return [
        {
          value: "1h",
          labelKey: "dashboard.widgets.spot-price.per-hour",
        },
      ];
    case "last7Days":
    case "thisWeek":
      return [
        {
          value: "1h",
          labelKey: "dashboard.widgets.spot-price.per-hour",
        },
        {
          value: "24h",
          labelKey: "dashboard.widgets.spot-price.per-day",
        },
      ];
  }
}

export function getDateFormatForGranularity(granularity: SpotPriceGranularity) {
  switch (granularity) {
    case "1h":
      return "dddd HH:mm";
    case "24h":
      return "D. MMM";
  }
}

export function isCurrentDatapoint(
  datapoint: DoubleDatapoint,
  granularity: SpotPriceGranularity,
) {
  const timestamp = dayjs(datapoint.timestamp);
  const now = dayjs();
  return granularity === "24h"
    ? now.startOf("day").isSame(timestamp)
    : now.startOf("hour").isSame(timestamp);
}

export function getHasMissingData(
  timeSpan: SpotPriceTimeSpan,
  datapoints: DoubleDatapoint[],
) {
  if (datapoints.length === 0) {
    return true;
  }
  if (timeSpan === "todayAndTomorrow") {
    const tomorrow = dayjs().add(1, "day");
    return dayjs(datapoints.at(-1)!.timestamp).day() !== tomorrow.day();
  }
  if (timeSpan === "thisWeek") {
    const today = dayjs();
    const isSunday = today.day() === 6;
    if (isSunday) {
      return false;
    }
    return dayjs(datapoints.at(-1)!.timestamp).day() !== 6;
  }
  return false;
}

export function getPercentageOfDomainWithData(
  datapoints: DoubleDatapoint[],
  timeSpan: SpotPriceTimeSpan,
  granularity: SpotPriceGranularity,
) {
  if (timeSpan === "todayAndTomorrow") {
    return datapoints.length / 48;
  }
  if (timeSpan === "thisWeek") {
    return granularity === "1h"
      ? datapoints.length / (24 * 7)
      : datapoints.length / 7;
  }
  // other time spans don't span future dates
  return 1;
}
