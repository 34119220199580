import { Timeseries } from "@cognite/sdk";
import { useGetRoomAssetForTimeseries } from "@/hooks/useGetRoomAssetForTimeseries";
import { formatExtendedTimeseriesDescription } from "@/utils/helpers";
import { SpinnerWithDelay } from "../SpinnerWithDelay/SpinnerWithDelay";

interface Props {
  timeseries: Timeseries;
}

export function TimeseriesExtendedDescription({ timeseries }: Props) {
  const { roomAsset, isLoading } = useGetRoomAssetForTimeseries(timeseries);

  return (
    <SpinnerWithDelay isLoading={isLoading}>
      {formatExtendedTimeseriesDescription(timeseries, roomAsset)}
    </SpinnerWithDelay>
  );
}
