import { useUser } from "@properate/auth";
import { Node as ReactFlowNode, useReactFlow } from "reactflow";
import { Form, InputNumber } from "antd";
import { useTranslations } from "@properate/translations";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import {
  NormalNode,
  NodeOutput,
  NodeOutputLabel,
  NodeInput,
  Header,
  Body,
  NodeInputLabel,
  NodeContent,
} from "./helpers/NodeComponents";

const FormItem = Form.Item;

interface RootNodeProps {
  operationId: string;
  n: number;
}

export const getEmptyRootNode = (): ReactFlowNode<RootNodeProps> => {
  return {
    id: getNodeId("root"),
    type: "root",
    data: {
      operationId: "root",
      n: 2,
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function RootNode(params: { id: string; data: RootNodeProps }) {
  const t = useTranslations();

  const user = useUser();
  const reactFlowInstance = useReactFlow();

  const handleChange = (n: number | null) => {
    if (n) updateReactFlowNodeData(reactFlowInstance, params.id, "n", n);
  };

  return (
    <NormalNode>
      <Header>{t("calculation-flow.node-types.root")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <NodeContent>
          <Form layout="vertical" disabled={user.isViewer}>
            <FormItem
              label={t("calculation-flow.node-types.square-root-example")}
            >
              <InputNumber
                value={params.data.n}
                onChange={handleChange}
                controls={false}
                decimalSeparator=","
              />
            </FormItem>
          </Form>
        </NodeContent>
        <NodeOutput>
          <NodeOutputLabel>
            {t("calculation-flow.node-types.square-root-description")}
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </NormalNode>
  );
}

export default RootNode;
