import { FunctionComponent, useEffect, useState } from "react";
import { Dayjs } from "@properate/dayjs";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { Timeseries } from "../types";
import { TimeseriesPlot } from "./TimeseriesPlot";

interface Props {
  timeseries: Timeseries;
  start: Dayjs;
  end: Dayjs;
}

export const SimulationCardPlot: FunctionComponent<Props> = ({
  timeseries,
  start,
  end,
}) => {
  const { client } = useCogniteClient();
  const [max, setMax] = useState<number | null>(null);
  const [min, setMin] = useState<number | null>(null);

  useEffect(() => {
    const loadTimeseries = async () => {
      const [ts] = await client.timeseries.retrieve(
        [{ externalId: timeseries.externalId }],
        { ignoreUnknownIds: true },
      );
      if (ts === undefined) return;
      timeseries.unit = ts.unit;
      if (ts?.metadata?.max_value) setMax(Number(ts.metadata.max_value));
      if (ts?.metadata?.min_value) setMin(Number(ts.metadata.min_value));
    };
    loadTimeseries();
  }, [client.timeseries, timeseries]);

  return (
    <TimeseriesPlot
      data={timeseries}
      start={start}
      end={end}
      min={min}
      max={max}
    />
  );
};
