import {
  useLoaderData,
  useNavigate,
  useParams,
  useSubmit,
} from "react-router-dom";
import { useMemo } from "react";
import { useTranslations } from "@properate/translations";
import { HeatMap, HeatMapComponent, HeatMapHeader } from "@/features/heatmap";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { deleteHeatMap } from "@/services/heatMap";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import {
  removeWidget,
  useUserSettingsForBuilding,
} from "@/services/userSettings";
import { CompactContent } from "@/components/CompactContent";
import {
  NoteBuilding,
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { getCurrentStartOfWeekMonthDateOrReferenceDate } from "@/pages/common/utils";

export function HeatMapPage() {
  const t = useTranslations();

  useBuildingPageTitle(t("analysis.heat-map.name"));
  const currentBuildingId = useCurrentBuildingId();
  const currentBuilding = useCurrentBuilding();
  const { snapshotId } = useParams() as { snapshotId: string };
  const submit = useSubmit();
  const navigate = useNavigate();
  const heatMap = useLoaderData() as HeatMap;
  const { data: userSettingsForBuilding } = useUserSettingsForBuilding();

  const notesBuildings = useMemo(() => {
    return [
      {
        id: currentBuilding.dataSetId,
        name: currentBuilding.name,
      } as NoteBuilding,
    ];
  }, [currentBuilding.dataSetId, currentBuilding.name]);

  const timeseriesSetIds = useMemo(() => {
    return new Set([heatMap.timeseriesId]);
  }, [heatMap.timeseriesId]);

  async function handleConfirmDeletion() {
    const widgets = userSettingsForBuilding?.dashboard?.widgets;
    if (widgets) {
      removeWidget(currentBuildingId, widgets, snapshotId);
    }
    await deleteHeatMap(snapshotId);
    navigate(`/asset/${currentBuildingId}/analysis/heat-map`);
  }

  async function handleEdit(update: Partial<HeatMap>) {
    submit(
      {
        snapshotId,
        updatedDoc: JSON.stringify({
          ...heatMap,
          ...update,
        }),
      },
      {
        method: "post",
      },
    );
  }

  return (
    <>
      <HeatMapHeader
        name={heatMap.name}
        onConfirmDeletion={handleConfirmDeletion}
      />
      <CompactContent>
        <HeatMapComponent
          name={heatMap.name}
          timeseriesId={heatMap.timeseriesId}
          referenceDate={getCurrentStartOfWeekMonthDateOrReferenceDate(
            heatMap.viewMode,
            heatMap.referenceDate,
            heatMap.isCurrentStartOfWeekMonthChecked,
          )}
          isCurrentStartOfWeekMonthChecked={
            heatMap.isCurrentStartOfWeekMonthChecked
          }
          aggregate={heatMap.aggregate}
          viewMode={heatMap.viewMode}
          showWeekNumbers={heatMap.showWeekNumbers}
          color={heatMap.color}
          onEdit={handleEdit}
        />
      </CompactContent>
      {currentBuilding.dataSetId && (
        <NotesSidebar
          noteSource={NoteSource.WEB_ANALYSIS}
          buildings={notesBuildings}
          assetFilterMode={NotesAssetFilterMode.TimeseriesList}
          idSet={timeseriesSetIds}
        />
      )}
    </>
  );
}
