import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Row, Space } from "antd";
import { produce } from "immer";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslations, MessageKey } from "@properate/translations";
import { TrendLinePoint } from "@/utils/types";
import { Granularity } from "@/utils/helpers";
import { mutateTrendLines } from "@/services/trendLines";

export const TrendLineModal = ({
  open,
  setOpen,
  trendLines,
  granularity,
  assetId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  granularity: Granularity;
  trendLines: TrendLinePoint[];
  assetId: string;
}) => {
  const t = useTranslations();

  const [trendLine, setTrendLine] = useState<TrendLinePoint[]>(trendLines);
  const [temperature, setTemperature] = useState<number>();
  const [energy, setEnergy] = useState<number>();

  useEffect(() => {
    setTrendLine(trendLines);
  }, [trendLines]);

  return (
    <Modal
      title={t(`energy.trend-line-titles.${granularity}` as MessageKey)}
      open={open}
      width={690}
      onCancel={() => setOpen(false)}
      onOk={async () => {
        try {
          await mutateTrendLines(assetId, { [granularity]: trendLine });
        } catch (e) {
          console.error(e);
        }
        setOpen(false);
      }}
    >
      <Form layout="inline">
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          {trendLine && trendLine.length > 0
            ? trendLine.map((tp, index) => (
                <Row gutter={[8, 8]} key={index}>
                  <Form.Item label={t("energy.trend-line.temperature")}>
                    <Input
                      placeholder="10"
                      type="number"
                      suffix={"°C"}
                      value={tp.temperature}
                      onChange={(event) =>
                        setTrendLine(
                          produce(trendLine, (draftState) => {
                            draftState[index].temperature = parseInt(
                              event.target.value,
                            );
                          }),
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item label={t("energy.trend-line.consumption")}>
                    <Input
                      placeholder="12"
                      suffix="kWh"
                      value={tp.energy}
                      onChange={(event) =>
                        setTrendLine(
                          produce(trendLine, (draftState) => {
                            draftState[index].energy = parseInt(
                              event.target.value,
                            );
                          }),
                        )
                      }
                    />
                  </Form.Item>
                  <Button
                    type="default"
                    shape="circle"
                    icon={<MinusOutlined />}
                    onClick={() =>
                      setTrendLine(trendLine.filter((arr, i) => i !== index))
                    }
                  />
                </Row>
              ))
            : null}
          <Row gutter={[8, 8]}>
            <Form.Item label={t("energy.trend-line.temperature")}>
              <Input
                autoFocus
                placeholder="10"
                type="number"
                suffix={"°C"}
                value={temperature}
                onChange={(event) =>
                  setTemperature(
                    event.target.value.length === 0
                      ? undefined
                      : parseInt(event.target.value),
                  )
                }
              />
            </Form.Item>
            <Form.Item label={t("energy.trend-line.consumption")}>
              <Input
                placeholder="12"
                suffix="kWh"
                value={energy}
                onChange={(event) =>
                  setEnergy(
                    event.target.value.length === 0
                      ? undefined
                      : parseInt(event.target.value),
                  )
                }
              />
            </Form.Item>
            <Button
              onClick={() => {
                setTrendLine([
                  ...(trendLine ?? []),
                  {
                    temperature: temperature as number,
                    energy: energy as number,
                  },
                ]);
                setEnergy(undefined);
                setTemperature(undefined);
              }}
              disabled={temperature === undefined || energy === undefined}
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
            />
          </Row>
        </Space>
      </Form>
    </Modal>
  );
};
