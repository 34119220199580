import { AlarmRuleTypeName, formatTimeseriesName } from "@properate/common";
import { useTranslations } from "@properate/translations";
import useSWR from "swr";
import { CenteredSpinner } from "@properate/ui";
import { Select } from "antd";
import { Asset, CogniteInternalId, Timeseries } from "@cognite/sdk";
import { PropsWithChildren } from "react";
import {
  FormContextItem,
  useFormValue,
} from "@/pages/alarms/details/FormContext";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  getAssetsWithChildLabels,
  getTimeseriesForAssets,
} from "@/utils/helpers";
import { Box, BoxVariant } from "@/pages/alarms/details/components/InfoBox";
import { AlarmsSeparator } from "@/pages/alarms/details/components/layout";
import { SelectOffset } from "@/pages/alarms/details/AlarmRuleTypes/common/SelectOffset";
import { useTimeseries } from "@/pages/alarms/details/hooks";
import {
  CompareTimeseriesDefinition,
  CompareTimeseriesFormFields,
} from "./CompareTimeseries";
import { tKey } from "./common/utils";
import { AlarmRuleType } from "./index";

export const CompareTimeseriesPredictedEnergyConsumptionDefinition: AlarmRuleType =
  {
    ...CompareTimeseriesDefinition,
    name: AlarmRuleTypeName.CompareTimeseriesPredictedEnergyConsumption,
    labelTranslationKey: tKey(
      "compare-timeseries.predicted-energy-consumption.type-label",
    ),
    formComponent: <PredictedEnergyConsumptionFormFields />,
  };

function mapChildTimeseriesFromAssets({
  parentTimeseries,
  childTimeseries,
  childAssets,
}: {
  parentTimeseries: Timeseries[];
  childTimeseries: Timeseries[];
  childAssets: Asset[];
}): Record<CogniteInternalId, Timeseries[]> {
  const result: Record<CogniteInternalId, Timeseries[]> = {};

  for (const parentTs of parentTimeseries) {
    const assets = childAssets.filter(
      (asset) => parentTs.assetId === asset.parentId,
    );
    result[parentTs.id] = childTimeseries.filter((child) =>
      assets.some((asset) => child.assetId === asset.id),
    );
  }

  return result;
}

function useEpredBaseTimeseries() {
  const { client } = useCogniteClient();
  const { id: buildingId } = useCurrentBuilding();

  return useSWR(
    ["alarm-epred-timeseries", buildingId],
    async ([_label, buildingId]) => {
      if (!buildingId) {
        return null;
      }
      const { assets, childAssets } = await getAssetsWithChildLabels(
        client,
        buildingId,
        ["epred"],
      );
      if ((assets ?? []).length === 0) {
        return { ePredTimeseries: [], childTimeseries: [] };
      }
      const ePredTimeseries = await getTimeseriesForAssets(
        client,
        assets.map((asset) => asset.id),
      );
      const allChildTimeseries = await getTimeseriesForAssets(
        client,
        childAssets.map((asset) => asset.id),
      );
      const childTimeseries = mapChildTimeseriesFromAssets({
        parentTimeseries: ePredTimeseries,
        childTimeseries: allChildTimeseries,
        childAssets,
      });
      return { ePredTimeseries, childTimeseries };
    },
  );
}

function FormLabelHeader({ children }: PropsWithChildren) {
  return <h2 className="mb-1">{children}</h2>;
}

function PredictedEnergyConsumptionFormFields() {
  const t = useTranslations();
  const [baseTimeseriesId] = useFormValue<number | undefined>(
    CompareTimeseriesFormFields.BaseTimeseries,
  );
  const { timeseries: baseTimeseries } = useTimeseries({
    timeseriesId: baseTimeseriesId,
  });
  const [comparisonTimeseriesId, setComparisonTimeseriesId] = useFormValue<
    number | undefined
  >(CompareTimeseriesFormFields.ComparisonTimeseries);

  const { data, isLoading, error } = useEpredBaseTimeseries();

  const { ePredTimeseries, childTimeseries } = data ?? {};

  if (error) {
    return (
      <Box variant={BoxVariant.Error}>
        <p>{t("alarm-details.common.error-messages.timeseries-loading")}</p>
      </Box>
    );
  }
  if (isLoading || !ePredTimeseries) {
    return <CenteredSpinner />;
  }
  if ((ePredTimeseries ?? []).length === 0) {
    return (
      <Box variant={BoxVariant.Info}>
        <p>{t("alarm-details.common.error-messages.type-unavailable")}</p>
      </Box>
    );
  }

  function handleBaseTimeseriesChange(newBaseTimeseriesId: unknown) {
    if (newBaseTimeseriesId !== baseTimeseriesId) {
      // reset comparison when base changes
      setComparisonTimeseriesId(undefined);
    }
    return newBaseTimeseriesId;
  }

  function renderTimeseriesSelect(timeseriesList: Timeseries[]) {
    return (
      <Select
        showSearch
        options={timeseriesList.map((timeseries) => ({
          label: formatTimeseriesName(timeseries),
          value: timeseries.id,
        }))}
      />
    );
  }

  function renderSelectBase() {
    return (
      <FormContextItem
        labelKey="alarm-details.alarm-types.compare-timeseries.base"
        id={CompareTimeseriesFormFields.BaseTimeseries}
        labelWrapper={FormLabelHeader}
        changeCallback={handleBaseTimeseriesChange}
        antdInput
      >
        {renderTimeseriesSelect(ePredTimeseries!)}
      </FormContextItem>
    );
  }

  function renderSelectComparison() {
    if (!baseTimeseriesId) {
      return null;
    }
    return (
      <>
        <AlarmsSeparator direction={"horizontal"} />
        <FormContextItem
          labelKey="alarm-details.alarm-types.compare-timeseries.compare"
          id={CompareTimeseriesFormFields.ComparisonTimeseries}
          labelWrapper={FormLabelHeader}
          antdInput
        >
          {renderTimeseriesSelect(childTimeseries![baseTimeseriesId])}
        </FormContextItem>
      </>
    );
  }

  function renderOffset() {
    if (!baseTimeseriesId || !comparisonTimeseriesId) {
      return null;
    }

    return (
      <>
        <AlarmsSeparator direction={"horizontal"} />
        <SelectOffset unit={baseTimeseries?.unit ?? ""} />
      </>
    );
  }

  return (
    <>
      {renderSelectBase()}
      {renderSelectComparison()}
      {renderOffset()}
    </>
  );
}
