import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Input, Radio, Row } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import {
  getSystemCodeFromExternalId,
  formatSubBuildingFromExternalId,
  formatUnit,
  DERIVED_UNITS,
  SensorInfo,
} from "@properate/common";
import { Timeseries, Asset } from "@cognite/sdk";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { getStateDescription } from "@/pages/common/utils";
import { getAssetById } from "@/utils/helpers";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { AnalysisCreateRedirectButton } from "@/components/AnalysisCreateRedirectButton/AnalysisCreateRedirectButton";
import { useCogniteClient } from "../../../context/CogniteClientContext";
import {
  ButtonContainer,
  DetailsSection,
  DividedRadioGroup,
  RadioButton,
} from "./styles";
import { InputWithError } from "./InputWithError";

type Props = {
  timeseries?: Timeseries;
  showSettings?: Function;
  alarmOwner?: string;
  alarmName?: string;
  setAlarmName?: (name: string | undefined) => void;
  errors?: Record<string, string>;
  alarmDescription?: string;
  setAlarmDescription?: (name: string | undefined) => void;
  overrideUnit?: string;
  view?: string;
  setView?: (view: SensorInfo) => void;
  buildingId: number;
  relatedSensors?: Asset[];
};
export const DetailsPane = ({
  timeseries,
  showSettings,
  alarmOwner,
  alarmName,
  setAlarmName,
  errors,
  alarmDescription,
  setAlarmDescription,
  overrideUnit,
  view = "value",
  setView,
  buildingId,
  relatedSensors,
}: Props) => {
  const t = useTranslations();
  const { client } = useCogniteClient();
  const user = useUser();
  const { handleOverrideUnit } = useTimeseriesSettings(buildingId);
  const [metadata, setMetadata] = useState<
    { key: string; value: string | undefined }[]
  >([]);
  useEffect(() => {
    const get = async () => {
      const system = getSystemCodeFromExternalId(timeseries!.externalId!);
      const subBuilding = formatSubBuildingFromExternalId(
        timeseries!.externalId!,
      );
      const meta: { key: string; value: string | undefined }[] = [
        {
          key: t("common.timeseries-graph-modal.settings-fields.system"),
          value: system,
        },
      ];
      if (subBuilding.toLowerCase() !== "common") {
        meta.push({
          key: t("common.timeseries-graph-modal.settings-fields.sub-building"),
          value: subBuilding,
        });
      }
      if (timeseries?.id) {
        const asset = await getAssetById(client, timeseries.assetId!);
        if (system.startsWith("200")) {
          const room = await getAssetById(client, asset.parentId!);
          if (room.labels!.some((label) => label.externalId === "room")) {
            meta.push({
              key: t("common.timeseries-graph-modal.settings-fields.room"),
              value: `${room.name}${
                room.description ? " " + room.description : ""
              }`,
            });
            meta.push({
              key: t("common.timeseries-graph-modal.settings-fields.floor"),
              value: room.metadata?.floor,
            });
          } else if (
            room.labels!.some((label) => label.externalId === "floor")
          ) {
            meta.push({
              key: t("common.timeseries-graph-modal.settings-fields.floor"),
              value: room.name,
            });
          }
          meta.push({
            key: t("common.timeseries-graph-modal.settings-fields.type"),
            value: room.metadata?.specie,
          });

          const { client_id, source, room_name, room_no, floor, ...rest } =
            asset.metadata || {};
          meta.push({
            key: t("common.timeseries-graph-modal.settings-fields.client-id"),
            value: asset.metadata?.client_id,
          });
          meta.push({
            key: t("common.timeseries-graph-modal.settings-fields.source"),
            value: asset.metadata?.source,
          });
          Object.entries(rest).forEach(([key, value]) => {
            meta.push({ key: key.replaceAll("_", " "), value });
          });
        } else {
          const {
            client_id,
            source,
            device_id,
            device_name,
            object_name,
            object_type,
            ...rest
          } = asset.metadata || {};
          meta.push({
            key: t("common.timeseries-graph-modal.settings-fields.client-id"),
            value: asset.metadata?.client_id,
          });
          meta.push({
            key: t("common.timeseries-graph-modal.settings-fields.source"),
            value: asset.metadata?.source,
          });
          meta.push({
            key: t("common.timeseries-graph-modal.settings-fields.device-id"),
            value: asset.metadata?.device_id,
          });
          meta.push({
            key: t("common.timeseries-graph-modal.settings-fields.device-name"),
            value: asset.metadata?.device_name,
          });
          meta.push({
            key: t("common.timeseries-graph-modal.settings-fields.object-name"),
            value: asset.metadata?.object_name,
          });
          meta.push({
            key: t("common.timeseries-graph-modal.settings-fields.object-type"),
            value: asset.metadata?.object_type,
          });
          Object.entries(rest).forEach(([key, value]) => {
            meta.push({ key: key.replaceAll("_", " "), value });
          });
        }

        setMetadata(meta);
      }
    };
    if (timeseries) {
      get();
    }
  }, [timeseries, client, t]);

  const derivedUnitKeys = timeseries
    ? Object.keys(DERIVED_UNITS[timeseries.unit || ""] || {})
    : [];

  return (
    <div style={{ maxHeight: 500, overflow: "auto" }}>
      <DetailsSection>
        <h2>
          {t("common.timeseries-graph-modal.details")}{" "}
          {showSettings && (
            <Button
              type="link"
              onClick={() => {
                showSettings();
              }}
              icon={<SettingOutlined />}
            />
          )}
        </h2>
        {metadata &&
          metadata
            .filter((entry) => !!entry.value)
            .filter((entry) => entry.key !== "state description")
            .map((entry, index) => (
              <Row key={index}>
                <Col span={8}>
                  <strong>{entry.key}:</strong>
                </Col>
                <Col span={14} data-testid={entry.key}>
                  {entry.value}
                </Col>
              </Row>
            ))}
        {metadata &&
          metadata
            .filter((entry) => !!entry.value)
            .filter((entry) => entry.key === "state description")
            .map((entry, index) => (
              <Row key={index}>
                <Col span={8}>
                  <strong>
                    {t(
                      "common.timeseries-graph-modal.settings-fields.valid-values",
                    )}
                    :
                  </strong>
                </Col>
                <Col span={14}>
                  {Object.entries(getStateDescription(entry.value) || {})
                    .map(([key, value]) => `${value} (${key})`)
                    .join()}
                </Col>
              </Row>
            ))}
        <Row>
          <Col span={8}>
            <strong>
              {t("common.timeseries-graph-modal.settings-fields.id")}:
            </strong>
          </Col>
          <Col span={14}>{timeseries?.externalId}</Col>
        </Row>
        {!!relatedSensors?.length && (
          <Row>
            <Col span={8}>
              <strong>
                {t("common.timeseries-graph-modal.settings-fields.sensors")}
              </strong>
            </Col>
            <Col span={14}>
              {relatedSensors.map((sensor) => sensor.externalId).join(" ")}
            </Col>
          </Row>
        )}
        {alarmOwner && (
          <>
            <Row>
              <Col span={8}>
                <strong>
                  {t(
                    "common.timeseries-graph-modal.settings-fields.created-by",
                  )}
                  :
                </strong>
              </Col>
              <Col
                span={14}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <span title={alarmOwner}>{alarmOwner}</span>
              </Col>
            </Row>
          </>
        )}
      </DetailsSection>
      {setAlarmName && (
        <DetailsSection>
          <h2>{t("common.timeseries-graph-modal.settings-fields.name")}</h2>
          <InputWithError
            disabled={user.isViewer}
            error={errors?.name}
            placeholder={t(
              "common.timeseries-graph-modal.settings-fields.short-alarm-name",
            )}
            value={alarmName}
            onChange={setAlarmName}
            marginBottom={8}
            dataTestId={"input-alarm-name"}
          />
        </DetailsSection>
      )}
      {setAlarmDescription && (
        <DetailsSection>
          <h2>
            {t("common.timeseries-graph-modal.settings-fields.description")}
          </h2>
          <Input.TextArea
            disabled={user.isViewer}
            data-testid={"alarm-description"}
            placeholder={t(
              "common.timeseries-graph-modal.settings-fields.description-placeholder",
            )}
            value={alarmDescription}
            autoComplete="off"
            onChange={(e) => {
              setAlarmDescription(e.target.value);
            }}
            rows={4}
          />
        </DetailsSection>
      )}
      {timeseries && (
        <AnalysisCreateRedirectButton
          style={{ marginBottom: 15 }}
          buildingId={buildingId}
          timeseries={timeseries}
          userEmail={user.email}
        />
      )}
      {timeseries && derivedUnitKeys.length >= 1 && (
        <DetailsSection>
          <h2>{t("common.timeseries-graph-modal.settings-fields.unit")}</h2>
          <ButtonContainer marginBottom={8}>
            <DividedRadioGroup
              value={overrideUnit ?? timeseries.unit ?? ""}
              buttonStyle="solid"
              onChange={(event) =>
                handleOverrideUnit(
                  buildingId,
                  timeseries.externalId!,
                  event.target.value,
                  overrideUnit,
                )
              }
              buttonCount={1 + derivedUnitKeys.length}
            >
              <RadioButton value={timeseries.unit ?? ""}>
                {formatUnit(timeseries.unit)}
              </RadioButton>
              {derivedUnitKeys.map((key) => (
                <RadioButton value={key} key={key}>
                  {key}
                </RadioButton>
              ))}
            </DividedRadioGroup>
          </ButtonContainer>
        </DetailsSection>
      )}
      {view && setView && (
        <DetailsSection>
          <h2>{t("common.timeseries-graph-modal.settings-fields.show")}</h2>

          <ButtonContainer marginBottom={8}>
            <Radio.Group
              disabled={user.isViewer}
              value={view}
              buttonStyle="solid"
              style={{ width: "100%" }}
              onChange={(event) => {
                setView(event.target.value);
              }}
            >
              <Row>
                <Col span={8}>
                  <RadioButton value="minimal">
                    {t("common.timeseries-graph-modal.settings-fields.minimal")}
                  </RadioButton>
                </Col>
                <Col span={8}>
                  <RadioButton value="value">
                    {t("common.timeseries-graph-modal.settings-fields.value")}
                  </RadioButton>
                </Col>
                <Col span={8}>
                  <RadioButton value="all">
                    {t(
                      "common.timeseries-graph-modal.settings-fields.all-info",
                    )}
                  </RadioButton>
                </Col>
              </Row>
            </Radio.Group>
          </ButtonContainer>
        </DetailsSection>
      )}
    </div>
  );
};
