import { DescriptionListAccordionBox } from "@properate/ui";
import { ReactNode } from "react";
import { useTranslations } from "@properate/translations";
import {
  formatTimeseriesName,
  getSystemCodeFromExternalId,
} from "@properate/common";
import { useTimeseries } from "@/pages/alarms/details/hooks";

export type TimeseriesAccordionBoxProps = {
  timeseriesId: number;
  title: ReactNode;
  htmlId?: string;
};

export function TimeseriesAccordionBox({
  timeseriesId,
  title,
  htmlId,
}: TimeseriesAccordionBoxProps) {
  const { timeseries } = useTimeseries({ timeseriesId });
  const t = useTranslations(
    "alarm-details.deviations-page.summary.timeseries-accordion",
  );

  const alwaysVisibleEntries = [
    {
      term: t("name"),
      description: timeseries ? formatTimeseriesName(timeseries) : "",
    },
    {
      term: t("system"),
      description: timeseries?.externalId
        ? getSystemCodeFromExternalId(timeseries?.externalId)
        : "",
    },
    ...(timeseries?.metadata?.object_name
      ? [
          {
            term: t("object"),
            description: timeseries?.metadata?.object_name,
          },
        ]
      : []),
  ];

  const toggleableEntries = [
    {
      term: t("room-name"),
      description: timeseries?.metadata?.room_name,
    },
    {
      term: t("id"),
      description: timeseries?.externalId,
    },
    {
      term: t("client-id"),
      description: timeseries?.metadata?.client_id,
    },
    {
      term: t("source"),
      description: timeseries?.metadata?.source,
    },
  ];

  return (
    <DescriptionListAccordionBox
      htmlId={htmlId ?? `timeseries_${timeseriesId}`}
      ignoreEmptyDescriptions
      termColon
      title={title}
      alwaysVisibleEntries={alwaysVisibleEntries}
      toggleableEntries={toggleableEntries}
    />
  );
}
