import { AccordionProps } from "./types";
import AccordionEntries from "./AccordionEntries";
import SynchronizeAccordionData from "./SynchronizeAccordionData";
import SynchronizeAccordionOptions from "./SynchronizeAccordionOptions";
import { AccordionFilterWarning } from "./AccordionFilterWarning";

export function Accordion({
  entries,
  htmlId,
  ...inputOptions
}: AccordionProps) {
  return (
    <div>
      <SynchronizeAccordionOptions {...inputOptions} />
      <SynchronizeAccordionData entries={entries} />
      <AccordionFilterWarning />
      <AccordionEntries htmlId={htmlId} />
    </div>
  );
}
