import { useState } from "react";
import { Button, Form, Input, Space } from "antd";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { SchemaTitle } from "@/components/SchemaTitle";

interface Props {
  onFinish: (value: string) => unknown;
  text: string | undefined;
}

export function ShowEdit({ text, onFinish }: Props) {
  const t = useTranslations();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);

  function handleFinish({ text }: { text: string }) {
    if (typeof text === "string" && text.trim().length > 0) {
      onFinish(text);
    }
    setEdit(false);
  }

  return (
    <>
      {edit ? (
        <Form
          form={form}
          name="text"
          layout="inline"
          initialValues={{ text }}
          onFinish={handleFinish}
        >
          <Form.Item name="text">
            <Input placeholder={t("calculation-flow.name")} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
              icon={<CheckOutlined />}
            />
          </Form.Item>
        </Form>
      ) : (
        <Space>
          <SchemaTitle>{text}</SchemaTitle>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              setEdit(true);
            }}
          />
        </Space>
      )}
    </>
  );
}
