import { useEffect, useState } from "react";
import { App } from "antd";
import { DatapointAggregates, DoubleDatapoints } from "@cognite/sdk";
import { convertElementPropsToUnit } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { isErrorWithMessage } from "@/utils/api";
import { getItemByProp } from "@/utils/array";
import { downloadTimeseriesListWithDataAsXLSX } from "@/utils/xlsx";
import { useIsLoadingWithDelay } from "@/hooks/useIsLoadingWithDelay";
import {
  getAmountOfDatapointsPerTimeseries,
  getTimeseriesListWithAllRawData,
} from "@/utils/datapoints";
import { SettingsTimeseries } from "../types";

function convertDatapoints<T extends DoubleDatapoints | DatapointAggregates>(
  timeseriesWithData: T,
  settingsTimeseriesList: Pick<SettingsTimeseries, "id" | "unitSelected">[],
): T {
  const { datapoints, unit, id } = timeseriesWithData;
  const { unitSelected } = getItemByProp(settingsTimeseriesList, id);

  return {
    ...timeseriesWithData,
    unit: unitSelected,
    datapoints: convertElementPropsToUnit(
      datapoints,
      { from: unit, to: unitSelected },
      ["value", "average", "min", "max"],
    ),
  };
}

export function useDownloadTimeseriesData(
  title: string,
  settingsTimeseriesList: Pick<SettingsTimeseries, "id" | "unitSelected">[],
  query: {
    start: number;
    end: number;
  },
) {
  const t = useTranslations();

  const [isTriggered, setIsTriggered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingWithDelay = useIsLoadingWithDelay(isLoading);
  const { notification } = App.useApp();

  useEffect(() => {
    async function download() {
      try {
        setIsLoading(true);
        setIsTriggered(false);
        const amountOfDatapointsPerTimeseries =
          await getAmountOfDatapointsPerTimeseries(
            settingsTimeseriesList.map(({ id }) => id),
            query.start,
            query.end,
          );
        const totalAmountOfDatapoints = amountOfDatapointsPerTimeseries.reduce(
          (totalAmountOfDatapointsAcc, { amountOfDatapoints }) =>
            totalAmountOfDatapointsAcc + amountOfDatapoints,
          0,
        );
        if (totalAmountOfDatapoints > 200_000) {
          notification.warning({
            message: t(
              "analysis.details.warning-downloading-large-amount-of-data",
            ),
          });
        }
        const timeseriesListWithAllRawData =
          await getTimeseriesListWithAllRawData(
            amountOfDatapointsPerTimeseries,
            query.start,
            query.end,
          );
        const timeseriesListWithAllRawConvertedData =
          timeseriesListWithAllRawData.map((timeseriesWithRawData) =>
            convertDatapoints(timeseriesWithRawData, settingsTimeseriesList),
          );
        downloadTimeseriesListWithDataAsXLSX(
          timeseriesListWithAllRawConvertedData,
          title,
        );
      } catch (error) {
        if (isErrorWithMessage(error)) {
          notification.error({
            message: error.message,
          });
        }
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    if (isTriggered) {
      download();
    }
  }, [
    settingsTimeseriesList,
    query.start,
    query.end,
    isTriggered,
    title,
    setIsLoading,
    setIsTriggered,
    notification,
    t,
  ]);

  function triggerDownload() {
    setIsTriggered(true);
  }

  return {
    isLoading: isLoadingWithDelay,
    triggerDownload,
  };
}
