import { createPortal } from "react-dom";
import { useSidebarValues } from "./hooks";
import { SidebarHeader } from "./SidebarHeader";
import { SidebarProps } from "./types";

export function Sidebar({ className }: SidebarProps) {
  const sidebarValues = useSidebarValues();

  if (sidebarValues === null) {
    // TODO: Log this error somewhere appropriate!
    console.error(
      "Cannot render sidebar! Must be a child of <SidebarProvider />!",
    );
    return null;
  }

  if (!sidebarValues.isOpen) {
    return null;
  }

  const portalElement = document.getElementById(sidebarValues.portalDomId);
  if (!portalElement) {
    // TODO: Log this error somewhere appropriate!
    console.error(
      `Cannot render sidebar. Portal-location (element with id "${sidebarValues.portalDomId}") not found!`,
    );
    return null;
  }

  return createPortal(
    <div
      className={`flex flex-col h-full bg-background-secondary w-60 lg:w-96 border rounded-l-2xl border-solid border-r-0 border-border font-sans py-3 box-border ${
        className || ""
      }`}
    >
      <SidebarHeader />
      <div className={"flex flex-col flex-grow overflow-y-auto"}>
        {sidebarValues.content}
      </div>
    </div>,
    portalElement,
  );
}
