import { useMemo } from "react";
import {
  NoteBuilding,
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "../../features/notes";
import { useGetFilteredAlarms } from "../../features/alarms";
import { useRootAssets } from "../../hooks/useRootAssets";

/**
 * Collect required data and render the NotesSidebar for OrgAlarmsPage.
 * This is a separate component in order to be rendered as a child of the
 * AlarmsTableFiltersProvider, and dynamically update/filter Notes based on the
 * active filters for the Alarms-table on the OrgAlarmsPage.
 */
export function OrgAlarmsNotesSidebar() {
  const rootAssets = useRootAssets();
  const buildingIds = useMemo(
    () => rootAssets.map((asset) => asset.id),
    [rootAssets],
  );
  const noteBuildings = useMemo(
    () =>
      rootAssets
        .map((rootAsset) =>
          rootAsset?.dataSetId
            ? {
                id: rootAsset.dataSetId,
                name: rootAsset.metadata?.Adresse || rootAsset.name,
              }
            : null,
        )
        .filter((valueOrNull) => valueOrNull !== null) as NoteBuilding[],
    [rootAssets],
  );
  const { filteredAlarms } = useGetFilteredAlarms(buildingIds);
  const timeseriesIds: number[] = [];
  filteredAlarms.forEach((alarm) =>
    timeseriesIds.push(...(alarm?.timeseries?.ids ?? [])),
  );

  if (noteBuildings.length === 0) {
    return null;
  }
  return (
    <NotesSidebar
      noteSource={NoteSource.WEB_ORG_ALARMS}
      assetFilterMode={NotesAssetFilterMode.TimeseriesList}
      idSet={new Set(timeseriesIds)}
      buildings={noteBuildings}
      className={"ml-4"}
    />
  );
}
