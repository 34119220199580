import { AlarmRule } from "@properate/common";
import { ReactNode } from "react";
import { getAlarmSettingsFormFields } from "@/pages/alarms/details/AlarmSettingsPage";
import { AlarmDetailsTimespanFormDefaults } from "@/pages/alarms/details/components/AlarmDetailsTimespanSelector";
import {
  AlarmDetailsNavigatorFormDefaults,
  AlarmDetailsZoomedTimespanFormIds,
} from "@/pages/alarms/details/components/AlarmTimeseriesGraph/AlarmTimeseriesNavigator";
import { FormContextProvider } from "@/pages/alarms/details/FormContext";
import { AlarmGraphFormDefaults } from "@/pages/alarms/details/components/AlarmTimeseriesGraph";
import { getAlarmNotificationsFormFields } from "@/pages/alarms/details/AlarmNotificationsPage";
import {
  getTimespanBeforeAndAfterPeriod,
  getIncidentFormFields,
} from "./utils";

type Props = {
  alarmRule?: AlarmRule;
  deviationId?: number;
  children: ReactNode;
  startTimespan?: number;
};

export const IncidentAlarmContextWrapper = ({
  alarmRule,
  children,
  deviationId,
  startTimespan,
}: Props) => {
  if (!alarmRule) return children;
  const zoomedTimespan = getTimespanBeforeAndAfterPeriod(
    startTimespan!,
    6 * 60,
  );

  return (
    <FormContextProvider
      entries={{
        ...getIncidentFormFields({
          alarmId: alarmRule.alarm_id,
          deviationId,
        }),
        ...getAlarmSettingsFormFields(alarmRule),
        ...getAlarmNotificationsFormFields(alarmRule),
        ...AlarmDetailsTimespanFormDefaults,
        ...AlarmDetailsNavigatorFormDefaults,
        ...AlarmGraphFormDefaults,
        [AlarmDetailsZoomedTimespanFormIds.FromTime]: {
          defaultValue: zoomedTimespan.from,
        },
        [AlarmDetailsZoomedTimespanFormIds.ToTime]: {
          defaultValue: zoomedTimespan.to,
        },
      }}
      onSubmit={() => {
        // noop
      }}
      className="flex gap-4 flex-col flex-1 h-full"
    >
      {children}
    </FormContextProvider>
  );
};
