import { useMemo } from "react";
import { Group } from "@visx/group";
import { TimeSpan } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import {
  LegendDataForGraphLegend,
  SelectedAnalysisPoints,
  SetSelectedAnalysisPoints,
  SettingsTimeseriesScatterplot,
} from "../../types";
import {
  getGraphMargins,
  LEFT_AXIS_ESTIMATED_WIDTH,
  mapGranularityToMS,
  mapToScatterplotPointsWithMetadataList,
  mapScatterplotPointsToScalesWithMetadata,
  mapToScale,
} from "../../utils";
import { useGetTimeseriesListWithAggregateData } from "../../hooks";
import { ScatterplotGraphAxes } from "./ScatterplotGraphAxes";
import { ScatterplotGraphData } from "./ScatterplotGraphData";

interface Props {
  width: number;
  height: number;
  zoomedTimeSpan: TimeSpan;
  settingsTimeseriesX: Omit<SettingsTimeseriesScatterplot, "hidden"> | null;
  settingsTimeseriesList: SettingsTimeseriesScatterplot[];
  granularity: string;
  mergeUnits: boolean;
  interactive?: boolean;
  highlightedTimeseriesIds?: number[];
  tickSize?: number;
  legendData?: LegendDataForGraphLegend;
  setSelectedPoints?: SetSelectedAnalysisPoints;
  selectedPoints?: SelectedAnalysisPoints;
}

function getXAxisRangeStart(timeseriesYAxisAmount: number) {
  return timeseriesYAxisAmount > 1
    ? (timeseriesYAxisAmount - 1) * LEFT_AXIS_ESTIMATED_WIDTH
    : 0;
}

const graphMargins = getGraphMargins("scatterplot");

export function ScatterplotGraph({
  width,
  height,
  zoomedTimeSpan,
  settingsTimeseriesX,
  settingsTimeseriesList,
  granularity,
  mergeUnits,
  interactive = false,
  highlightedTimeseriesIds = [],
  tickSize = 12,
  legendData,
  setSelectedPoints,
  selectedPoints,
}: Props) {
  const t = useTranslations();

  const graphWidth = width - graphMargins.left - graphMargins.right;
  const graphHeight = height - graphMargins.top - graphMargins.bottom;
  const timeseriesIdsY = useMemo(
    () => settingsTimeseriesList.map(({ id }) => ({ id })),
    [settingsTimeseriesList],
  );
  const {
    timeseriesListWithData: timeseriesListWithDataY,
    isLoading: isLoadingTimeseriesListWithDataY,
  } = useGetTimeseriesListWithAggregateData({
    items: timeseriesIdsY,
    start: zoomedTimeSpan[0],
    end: zoomedTimeSpan[1],
    aggregates: settingsTimeseriesList.map(({ aggregate }) => aggregate),
    granularity,
  });
  const {
    timeseriesListWithData: timeseriesListWithDataX,
    isLoading: isLoadingTimeseriesListWithDataX,
  } = useGetTimeseriesListWithAggregateData({
    items: settingsTimeseriesX ? [{ id: settingsTimeseriesX.id }] : [],
    start: zoomedTimeSpan[0],
    end: zoomedTimeSpan[1],
    aggregates: settingsTimeseriesX ? [settingsTimeseriesX.aggregate] : [],
    granularity,
  });
  const isLoading =
    isLoadingTimeseriesListWithDataX || isLoadingTimeseriesListWithDataY;

  const scatterplotPointsWithMetadataList = useMemo(() => {
    return settingsTimeseriesX
      ? mapToScatterplotPointsWithMetadataList(
          timeseriesListWithDataX,
          timeseriesListWithDataY,
          settingsTimeseriesX,
          settingsTimeseriesList,
          mapGranularityToMS(granularity),
          legendData?.notePeriods,
        )
      : [];
  }, [
    settingsTimeseriesX,
    timeseriesListWithDataX,
    timeseriesListWithDataY,
    settingsTimeseriesList,
    granularity,
    legendData?.notePeriods,
  ]);

  const scalesWithMetadataY = useMemo(() => {
    return mapScatterplotPointsToScalesWithMetadata(
      scatterplotPointsWithMetadataList,
      [graphHeight, 0],
      { mergeUnits },
    );
  }, [scatterplotPointsWithMetadataList, graphHeight, mergeUnits]);

  const xAxisRangeStart = getXAxisRangeStart(scalesWithMetadataY.length);
  const scaleX = useMemo(
    () =>
      mapToScale(
        scatterplotPointsWithMetadataList.flatMap(
          ({ scatterplotPoints }) => scatterplotPoints,
        ),
        [xAxisRangeStart, graphWidth],
        (point) => point.x,
      ),
    [scatterplotPointsWithMetadataList, xAxisRangeStart, graphWidth],
  );

  return (
    <SpinnerWithDelay
      isLoading={isLoading}
      style={{
        width: width,
        height: height,
        display: "grid",
        placeContent: "center",
      }}
    >
      {scatterplotPointsWithMetadataList.length > 0 ? (
        <svg width={width} height={height}>
          <Group left={graphMargins.left} top={graphMargins.top}>
            <ScatterplotGraphAxes
              graphHeight={graphHeight}
              colorX={scatterplotPointsWithMetadataList[0].metadata.colorX}
              unitX={scatterplotPointsWithMetadataList[0].metadata.unitX}
              scaleX={scaleX}
              scalesWithMetadataY={scalesWithMetadataY}
              tickSize={tickSize}
            />
            <ScatterplotGraphData
              scatterplotPointsWithMetadataList={
                scatterplotPointsWithMetadataList
              }
              scaleX={scaleX}
              scalesWithMetadataY={scalesWithMetadataY}
              interactive={interactive}
              highlightedTimeseriesIds={highlightedTimeseriesIds}
              isNotesVisible={legendData?.visibility.notes}
              setSelectedPoints={setSelectedPoints}
              selectedPoints={selectedPoints}
              granularity={granularity}
            />
          </Group>
        </svg>
      ) : (
        <div style={{ minWidth: "35ch" }}>
          {t("analysis.no-data-for-this-period")}
        </div>
      )}
    </SpinnerWithDelay>
  );
}
