import { useMemo } from "react";
import { AxisLeft } from "@visx/axis";
import { scaleBand } from "@visx/scale";
import { useTheme } from "styled-components";
import {
  getHoursOfTheDay,
  getIsoWeekNumbers,
  getTickLabelProps,
} from "../utils";
import { HeatMapViewMode } from "../types";

const hoursOfTheDay = getHoursOfTheDay();

interface Props {
  graphHeight: number;
  offsetTop: number;
  referenceDate: number;
  viewMode: HeatMapViewMode;
  compact: boolean;
}

export function HeatMapLeftAxis({
  graphHeight,
  offsetTop,
  referenceDate,
  viewMode,
  compact,
}: Props) {
  const theme = useTheme();
  const scale = useMemo(() => {
    return scaleBand({
      range: [offsetTop, graphHeight],
      domain:
        viewMode === "week" ? hoursOfTheDay : getIsoWeekNumbers(referenceDate),
    });
  }, [graphHeight, offsetTop, viewMode, referenceDate]);

  return (
    <AxisLeft
      scale={scale}
      numTicks={
        viewMode === "week" ? scale.domain().length / 3 : scale.domain().length
      }
      hideAxisLine
      hideTicks
      tickLabelProps={getTickLabelProps(theme, compact)}
    />
  );
}
