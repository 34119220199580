import { useState } from "react";

import {
  App,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import dayjs from "@properate/dayjs";
import {
  InfoCircleTwoTone,
  MinusOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons/lib";
import { produce } from "immer";
import {
  BUILDING_CATEGORIES,
  BUILDING_TYPES,
  BuildingSpec,
  OperationalHoursType,
  PERIOD_VALUES,
  WithSnapshotId,
} from "@properate/common";
import { useUser } from "@properate/auth";
import { useTranslations, MessageKey } from "@properate/translations";
import { mutateBuildingSpec } from "@/services/buildingSpec";
import { mutateAliasCondig, useAliasConfig } from "@/services/alias";
import UploadBuildingImage from "@/components/uploadBuildingImage/UploadBuildingImage";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { DEFAULT_MESSAGE_DURATION } from "@/utils/helpers";
import { parseError } from "@/pages/common/utils";
import {
  BuildingArealCalculatedArea,
  postBuildingSquareMeter,
  postRemoveCorrectedValue,
} from "@/eepApi";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { BuildingAreaFormInput } from "@/pages/buildingSettings/BuildingAreaFormInput";
import { useCurrentBuildingAreal } from "@/hooks/useCurrentBuildingAreal";
import useGetBuildingsAreal from "@/hooks/useGetBuildingsAreal";

const GUTTER: [number, number] = [12, 12];

interface UpdateInfoProps {
  spec: WithSnapshotId<BuildingSpec>;
}

const UpdateInfo = ({ spec }: UpdateInfoProps) => {
  const t = useTranslations();

  const { ownerAlias } = useUser();
  const aliasConfig = useAliasConfig(ownerAlias);
  const { externalId, id } = useCurrentBuilding();
  const usableFloorArea = useCurrentBuildingAreal(id);

  const alias = aliasConfig?.buildings?.[Number(spec.snapshotId)];

  const [operationalPeriod, setOperationalPeriod] = useState<string>();
  const [operationalPeriodHours, setOperationalPeriodHours] =
    useState<string[]>();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [loading, setLoading] = useState(false);

  const { message } = App.useApp();

  const { data: buildingsAreal, mutate: mutateBuildingsAreal } =
    useGetBuildingsAreal();

  function updateBuildingArea(usableFloorArea: BuildingArealCalculatedArea) {
    if (!mutateBuildingsAreal) {
      return;
    }
    const newBuildingsAreal = {
      ...buildingsAreal,
      [externalId as string]: usableFloorArea,
    };
    mutateBuildingsAreal(newBuildingsAreal, false);
  }

  async function handleUpdateBuildingAreal(value: number) {
    setIsEditingTitle(false);
    if (value === usableFloorArea?.area) {
      return;
    }

    try {
      setLoading(true);
      const res = await postBuildingSquareMeter(externalId!, {
        arealInSquareMeters: value,
      });
      const data = res.data;
      updateBuildingArea(data);
      message.open({
        type: "success",
        content: t("settings.update.area-of-use-updated"),
        duration: DEFAULT_MESSAGE_DURATION,
      });
      setIsEditingTitle(false);
      setLoading(false);
    } catch (error) {
      message.open({
        type: "error",
        content: t("settings.update.cant-update-area-of-use", {
          error: parseError(error),
        }),
        duration: DEFAULT_MESSAGE_DURATION,
      });
      setLoading(false);
      console.error(`Kunne ikke oppdatere bruksareal (BRA): ${error}`);
    }
  }

  async function handleRefreshBuildingAreal() {
    try {
      setIsEditingTitle(false);
      setLoading(true);
      const res = await postRemoveCorrectedValue(externalId!);
      const data = res.data;
      updateBuildingArea(data);
      message.open({
        type: "success",
        content: t("settings.update.area-of-use-reset"),
        duration: DEFAULT_MESSAGE_DURATION,
      });
      setLoading(false);
    } catch (error) {
      message.open({
        type: "error",
        content: t("settings.update.cant-reset-area-of-use", {
          error: parseError(error),
        }),
        duration: DEFAULT_MESSAGE_DURATION,
      });
      setLoading(false);
      console.error(`Could not reset usable area (BRA): ${error}`);
    }
  }

  return (
    <>
      <Row gutter={GUTTER}>
        <Col span={6}>
          <UploadBuildingImage
            imageUrl={spec.imageUrl}
            onSelect={async (image: string) => {
              await mutateBuildingSpec(spec.snapshotId, {
                imageUrl: image,
              });
            }}
            buildingId={spec.snapshotId}
          />
        </Col>
        <Col span={12}>
          {ownerAlias === "all" && (
            <Row gutter={GUTTER}>
              <Col span={6}>{t("settings.update.alias")}</Col>
              <Col span={18}>
                <Input
                  value={alias}
                  onChange={async (event) =>
                    aliasConfig &&
                    (await mutateAliasCondig(ownerAlias, {
                      buildings: {
                        [spec.snapshotId]: event.target.value,
                      },
                    }))
                  }
                />
              </Col>
            </Row>
          )}
          <Row gutter={GUTTER}>
            <Col span={6}>{t("settings.update.year-of-construction")}</Col>
            <Col span={18}>
              <InputNumber
                style={{ width: "100%" }}
                placeholder="1900"
                min={1100}
                max={dayjs().year()}
                value={spec.yearBuilt}
                onChange={(value) =>
                  mutateBuildingSpec(spec.snapshotId, {
                    yearBuilt: Number(value),
                  })
                }
                decimalSeparator=","
              />
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={6}>{t("settings.update.area-of-use")}</Col>
            <Col span={18}>
              <Space>
                {isEditingTitle ? (
                  <>
                    <BuildingAreaFormInput
                      area={usableFloorArea?.area}
                      onChangeArea={(event) => handleUpdateBuildingAreal(event)}
                      isLoading={loading}
                    />
                    <Button
                      disabled={
                        loading ||
                        usableFloorArea?.area ===
                          usableFloorArea?.calculated_corrected_area
                      }
                      onClick={handleRefreshBuildingAreal}
                    >
                      {t("settings.update.reset")}
                    </Button>
                  </>
                ) : (
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => setIsEditingTitle(true)}
                  >
                    {typeof usableFloorArea?.area === "number"
                      ? `${usableFloorArea?.area} m²`
                      : null}{" "}
                    <SpinnerWithDelay
                      isLoading={loading}
                      style={{ marginLeft: 10 }}
                    >
                      <EditOutlined aria-hidden />
                    </SpinnerWithDelay>
                  </Button>
                )}
                {!isEditingTitle ? (
                  <Tooltip title={t("settings.update.area-of-use-description")}>
                    <InfoCircleTwoTone />
                  </Tooltip>
                ) : null}
              </Space>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={6}>{t("settings.update.building-category")}</Col>
            <Col span={18}>
              <Select
                style={{ width: "100%" }}
                defaultValue={spec.buildingCategory}
                onChange={(cat) =>
                  mutateBuildingSpec(spec.snapshotId, {
                    buildingCategory: cat,
                  })
                }
              >
                {BUILDING_CATEGORIES.map((cat) => (
                  <Select.Option value={cat} key={cat}>
                    {t(
                      `common.building-category.${cat.toLowerCase()}` as MessageKey,
                    )}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={6}>{t("settings.update.building-type")}</Col>
            <Col span={18}>
              <Select
                style={{ width: "100%" }}
                defaultValue={spec.buildingType}
                onChange={(cat) =>
                  mutateBuildingSpec(spec.snapshotId, {
                    buildingType: cat,
                  })
                }
              >
                {BUILDING_TYPES.map((cat) => (
                  <Select.Option value={cat} key={cat}>
                    {t(
                      `common.building-type.${cat.toLowerCase()}` as MessageKey,
                    )}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={6}>{t("settings.update.open-hours")}</Col>
            <Col span={18}>
              {spec.operationalHours &&
                spec.operationalHours.map(
                  (op: OperationalHoursType, index: number) => (
                    <Row gutter={GUTTER} key={index}>
                      <Col span={21}>
                        {t(
                          `common.day-periods.${op.period.toLowerCase()}` as MessageKey,
                        )}
                        : {op.start} - {op.end}
                      </Col>
                      <Col span={3}>
                        <Button
                          shape="circle"
                          icon={
                            <MinusOutlined
                              onClick={() =>
                                mutateBuildingSpec(spec.snapshotId, {
                                  operationalHours: produce(
                                    spec.operationalHours,
                                    (draftState: any) =>
                                      draftState.filter(
                                        (draft: any) =>
                                          draft.period !== op.period,
                                      ),
                                  ),
                                })
                              }
                            />
                          }
                          type="primary"
                        />
                      </Col>
                    </Row>
                  ),
                )}
              <Row gutter={GUTTER}>
                <Col span={9}>
                  <Select
                    style={{ width: "100%" }}
                    defaultValue={operationalPeriod}
                    onChange={(period) => setOperationalPeriod(period)}
                  >
                    {PERIOD_VALUES.filter(
                      (cat) =>
                        !spec.operationalHours ||
                        !spec.operationalHours.find(
                          (op: OperationalHoursType) => op.period === cat,
                        ),
                    ).map((cat) => (
                      <Select.Option value={cat} key={cat}>
                        {t(
                          `common.day-periods.${cat.toLowerCase()}` as MessageKey,
                        )}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col span={12}>
                  <DatePicker.RangePicker
                    picker="time"
                    format="HH:mm"
                    minuteStep={5}
                    defaultValue={
                      operationalPeriodHours
                        ? [
                            dayjs(operationalPeriodHours[0], "HH:mm"),
                            dayjs(operationalPeriodHours[1], "HH:mm"),
                          ]
                        : undefined
                    }
                    onChange={(hours) =>
                      setOperationalPeriodHours(
                        hours
                          ? hours.map((m) => m!.format("HH:mm"))
                          : undefined,
                      )
                    }
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    disabled={
                      !(
                        operationalPeriod &&
                        operationalPeriodHours &&
                        operationalPeriodHours.length > 0
                      )
                    }
                    onClick={() =>
                      mutateBuildingSpec(spec.snapshotId, {
                        operationalHours: produce(
                          spec.operationalHours,
                          (draftState: any) => {
                            const update = {
                              period: operationalPeriod as string,
                              start: operationalPeriodHours![0],
                              end: operationalPeriodHours![1],
                            };
                            draftState = [...(draftState || []), update];
                            return draftState;
                          },
                        ),
                      })
                    }
                    shape="circle"
                    icon={<PlusOutlined />}
                    type="primary"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Checkbox
                    checked={spec.closedPublicHolidays}
                    onChange={(e) => {
                      mutateBuildingSpec(spec.snapshotId, {
                        closedPublicHolidays: e.target.checked,
                      });
                    }}
                  >
                    {t("settings.update.closed-on-holidays")}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={6}>{t("settings.update.3-party-app")}</Col>
            <Col span={18}>
              <Input
                addonBefore="https://"
                value={
                  spec.sdLink ? spec.sdLink.replace(/^https:\/\//, "") : ""
                }
                onChange={(event) =>
                  mutateBuildingSpec(spec.snapshotId, {
                    sdLink:
                      "https://" + event.target.value.replace("https://", ""),
                  })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UpdateInfo;
