import { PropsWithChildren } from "react";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";

export function FloorPlanMapPinRoomStrategy(props: PropsWithChildren) {
  const pin = useFloorPlanPin();

  if (pin.type !== "room") {
    return null;
  }

  return (
    <div role="button" tabIndex={0} className="flex cursor-pointer">
      {props.children}
    </div>
  );
}
