import { ExternalId, Timeseries } from "@cognite/sdk";
import { Button, Form, Space } from "antd";
import { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  TimeseriesSelectionModal,
  TimeseriesHideableFilter,
} from "@/features/timeseries";

const FormItem = Form.Item;

interface Props {
  externalId: ExternalId | null;
  onChange: (externalId: ExternalId | null) => void;
  hiddenFilters?: TimeseriesHideableFilter[];
  disabled?: boolean;
}

function TimeSeriesSelectionForm({
  externalId,
  onChange,
  hiddenFilters,
  disabled,
}: Props) {
  const [
    isTimeseriesSelectionModalVisible,
    setIsTimeseriesSelectionModalVisible,
  ] = useState(false);

  const onTimeseriesChange = (ts: Timeseries | null) => {
    setSelectedTimeseries(ts);
    onChange(
      ts?.externalId !== undefined ? { externalId: ts.externalId } : null,
    );
  };

  const [selectedTimeseries, setSelectedTimeseries] =
    useState<Timeseries | null>(null);

  return (
    <>
      <FormItem required>
        <Space>
          {externalId !== null ? externalId.externalId : ""}
          <Button
            onClick={() => setIsTimeseriesSelectionModalVisible(true)}
            disabled={disabled}
          >
            <EditOutlined />
          </Button>
        </Space>
      </FormItem>
      <TimeseriesSelectionModal
        hiddenFilters={hiddenFilters}
        open={isTimeseriesSelectionModalVisible}
        onOk={([timeseries]) => onTimeseriesChange(timeseries)}
        onHide={() => setIsTimeseriesSelectionModalVisible(false)}
        selectedIds={selectedTimeseries ? [selectedTimeseries.id] : []}
        max={1}
      />
    </>
  );
}

export default TimeSeriesSelectionForm;
