import dayjs from "@properate/dayjs";
import { Statistic } from "antd";
import { formatUnit } from "@properate/common";
import { CSSProperties } from "styled-components";
import { ClipboardButton } from "@properate/ui";
import { formatNonScientific } from "@/utils/format";

interface Props {
  description: string;
  value: number | Date;
  unit?: string;
  style?: CSSProperties;
}

function formatValue(value: number | Date) {
  if (typeof value === "number") {
    return formatNonScientific(value);
  }
  return dayjs(value).format("DD/MM/YYYY HH:mm:ss");
}

function getClipboardButtonText(value: number | Date, unit?: string) {
  const valueFormatted = formatValue(value);
  if (unit) {
    return `${valueFormatted}${formatUnit(unit)}`;
  }
  return valueFormatted;
}

export function StatisticalValue({ description, value, unit, style }: Props) {
  return (
    <Statistic
      title={
        <>
          <span>{description}</span>
          <ClipboardButton text={getClipboardButtonText(value, unit)} />
        </>
      }
      value={formatValue(value)}
      suffix={formatUnit(unit)}
      style={style}
    />
  );
}
