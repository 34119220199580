import { useTranslations } from "@properate/translations";
import { Switch } from "antd";
import { FormContextItem } from "../FormContext";
import { AlarmNotificationFormFields } from "./types";

export function Holidays() {
  const tBase = "alarm-details.notifications-page.holidays";
  const t = useTranslations(tBase);
  return (
    <div className="w-full flex flex-col gap-2">
      <h2 className="m-0">{t(`header`)}</h2>
      <FormContextItem
        id={AlarmNotificationFormFields.HolidayAlarms}
        labelKey={`${tBase}.alarms-active`}
        labelProps={{ className: "w-full flex justify-between" }}
        valueProp={"checked"}
        antdInput
      >
        <Switch />
      </FormContextItem>
    </div>
  );
}
