// src/index.ts
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import weekOfYear from "dayjs/plugin/weekOfYear.js";
import isMoment from "dayjs/plugin/isMoment.js";
import localeData from "dayjs/plugin/localeData.js";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
import weekYear from "dayjs/plugin/weekYear.js";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore.js";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter.js";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
import relativeTime from "dayjs/plugin/relativeTime.js";
import dayOfYear from "dayjs/plugin/dayOfYear.js";
import isoWeek from "dayjs/plugin/isoWeek.js";
import duration from "dayjs/plugin/duration.js";
import isBetween from "dayjs/plugin/isBetween.js";
import weekday from "dayjs/plugin/weekday.js";
import quarterOfYear from "dayjs/plugin/quarterOfYear.js";
import pluralGetSet from "dayjs/plugin/pluralGetSet.js";
import isLeapyear from "dayjs/plugin/isLeapYear.js";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear.js";
import localizedFormat from "dayjs/plugin/localizedFormat.js";
import minMax from "dayjs/plugin/minMax.js";
import "dayjs/locale/nb.js";
var { extend, locale } = dayjs;
extend(isSameOrBefore);
extend(isSameOrAfter);
extend(advancedFormat);
extend(customParseFormat);
extend(weekYear);
extend(weekOfYear);
extend(isMoment);
extend(localeData);
extend(utc);
extend(timezone);
extend(relativeTime);
extend(dayOfYear);
extend(isoWeek);
extend(duration);
extend(isBetween);
extend(weekday);
extend(quarterOfYear);
extend(pluralGetSet);
extend(isLeapyear);
extend(isoWeeksInYear);
extend(localizedFormat);
extend(minMax);
locale("nb");
dayjs.tz.setDefault("Europe/Oslo");
var src_default = dayjs;
export {
  src_default as default
};
